import React from 'react';
import { observer } from 'mobx-react';
import { CardInfoToolBar, CardMainLayout, Catcher, FieldInfo, LinkComponent, cardLayoutOptions } from '../../';
import { IShowForm } from '../../../interfaces';
import { useRateInfo } from '../../../hooks';

export const ShowRateInfo: React.FC<IShowForm> = observer(({ setEditMode }: IShowForm) => {
    const rateInfo = useRateInfo();
    const editMode = !rateInfo.outerAssignment ? setEditMode : undefined;

    return (
        <Catcher>
            <CardMainLayout
                idSpinners={['GET_RATE_UNIT_SELECT']}
                name={rateInfo.name}
                columnOption={cardLayoutOptions.salesRateInfo}
            >
                <FieldInfo title={'Name'}>{rateInfo.name}</FieldInfo>
                <FieldInfo title={'Company'}>
                    <LinkComponent
                        currentListComponent={'CompaniesList'}
                        id={rateInfo.companyId}
                        name={rateInfo.company}
                    />
                </FieldInfo>
                <FieldInfo title={'Currency'}>{rateInfo.currency}</FieldInfo>
                <FieldInfo title={'Notes'}>{rateInfo.notes}</FieldInfo>
                <FieldInfo title={'Default data rate'} titleSuffix={'p/GB'}>
                    {rateInfo.defaultRateDataUnit} {rateInfo.calculatedRateData}
                </FieldInfo>
                <FieldInfo title={'Default sms rate'} titleSuffix={'p/GB'}>
                    {rateInfo.defaultRateSmsUnit} {rateInfo.calculatedRateSms}
                </FieldInfo>
                <FieldInfo title={'Default voice mobile rate'} titleSuffix={'p/GB'}>
                    {rateInfo.defaultRateVoiceMobileUnit} {rateInfo.calculatedRateVoiceMobile}
                </FieldInfo>
                <FieldInfo title={'Default voice fix net rate'} titleSuffix={'p/GB'}>
                    {rateInfo.defaultRateVoiceNetUnit} {rateInfo.calculatedRateVoiceNet}
                </FieldInfo>
                <CardInfoToolBar setEditMode={editMode} isBackToList />
            </CardMainLayout>
        </Catcher>
    );
});
