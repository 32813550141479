import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Form, Row } from 'antd';
import { FieldData } from 'rc-field-form/es/interface';
import { appConfig } from '../../../settings';
import { IEditForm, IFormFields } from '../../../interfaces';
import {
    useCompanyInfo,
    useCompanyIsMNOHost,
    useCompanyOperatorListId,
    useCompanySalesRateList,
    useIsMyCompany,
    useProfile,
    useProfileIsRight,
    useRouterStore,
} from '../../../hooks';
import {
    addNotification,
    apiMessagesDeleteAllMessageBylListId,
    apiMessagesSaveMessages,
    getProfileInfo,
    removeCompanyRateById,
    saveCompany,
    setCompanyForm,
} from '../../../middleware';
import { debounce, onFormFieldsChange } from '../../../instruments';
import { tI, tNM, tP } from '../../../translate';
import { Catcher, ShowComponentTitle } from '../../../Components';
import {
    EditCompanyBillingInfo,
    EditCompanyContactInfo,
    EditCompanyCustomDocs,
    EditCompanyCustomize,
    EditCompanyCustomizeAbout,
    EditCompanyFinancial,
    EditCompanyInfo,
    EditCompanyLogo,
    EditCompanySalesRateList,
    EditCompanySubscriberVisibilityGranted,
} from './../Components';
import Styles from '../../styles/m_itemCardStyles.less';

export const EditCompanyForm: React.FC<IEditForm> = observer(({ setEditMode, isNew = false }: IEditForm) => {
    const [isSending, setSending] = useState(false);
    const [isRateList, setIsRateList] = useState(false);
    const [editingIsStarted, setEditingIsStarted] = useState(false);
    const [form] = Form.useForm();
    const companyInfo = useCompanyInfo();
    const { type: cdrSquashIntervalType, value: cdrSquashIntervalValue } = appConfig.defaultInterval;
    const { id, customerPrivacy, whiteLabel, parentId, customAboutEnabled, slug } = companyInfo;
    const { whiteLabel: myWhiteLabel, companyId: profileCompanyId, isMainMaternalCompany } = useProfile();
    const isChild = parentId === profileCompanyId;
    const salesRateList = useCompanySalesRateList();
    const idsSalesRateList = salesRateList.map((c) => c.id);
    const operatorList = useCompanyOperatorListId();
    const initialValues = {
        cdrSquashIntervalValue,
        cdrSquashIntervalType,
        ...companyInfo,
        salesRateList,
        operatorList,
    };
    const isMyCompany = useIsMyCompany();
    const routerStore = useRouterStore();
    const { companyId } = useProfile();
    const { id: currentCompanyId } = companyInfo;
    const isLord = useProfileIsRight('LORD');
    const isMNOHost = useCompanyIsMNOHost();

    const checkValid = (): void => {
        form.validateFields();
    };

    useEffect(() => {
        if (customerPrivacy && !isLord && !isMyCompany && !editingIsStarted) {
            addNotification({
                type: 'warning',
                message: 'Not authorized!',
                description: `Your company has not been granted permission to view this company information`,
                duration: 5,
            });

            routerStore.goTo('Company', { params: { id } });
        }
    }, [customerPrivacy]);

    useEffect(() => {
        form.setFieldsValue(initialValues);
    }, [JSON.stringify(initialValues)]);

    const onFieldsChange = (changedFields: FieldData[]): void => {
        setEditingIsStarted(true);
        onFormFieldsChange({
            changedFields,
            initialValues,
            listIds: {
                salesRateList: idsSalesRateList,
            },
            setForm: setCompanyForm,
        });
    };

    const deleteCompanyRateById = (id: string): void => {
        apiMessagesDeleteAllMessageBylListId(id);
        removeCompanyRateById(id);
    };

    const updateCompany = async (): Promise<void> => {
        setSending(true);
        const { isError, errors = [] } = await saveCompany(isNew);
        if (isError) {
            apiMessagesSaveMessages(errors);
            checkValid();
        } else {
            setEditMode && setEditMode(false);
        }

        if (isMyCompany) {
            const profileResponse = await getProfileInfo();
            if (profileResponse.isError) {
                addNotification({
                    type: 'error',
                    message: tNM('Get Profile error'),
                    description: JSON.stringify(profileResponse.resp),
                    duration: 0,
                    isTranslated: true,
                    isUsersError: true,
                });
            }
        }

        setSending(false);
    };

    const onValueChange = (fields: IFormFields): void => {
        const name = Object.keys(fields)[0];

        if (name === 'salesRateList') {
            setIsRateList(true);
        } else {
            setIsRateList(false);
            form.validateFields([name]).then();
        }
    };

    return (
        <Catcher>
            <div className={Styles.main}>
                <ShowComponentTitle text={`${tI('Edit')} ${tP('Company')} #${id || ''}`} />
                <Form.Provider>
                    <Form
                        form={form}
                        name="companyEdit"
                        onFieldsChange={
                            isRateList ? onFieldsChange : debounce(onFieldsChange, appConfig.delayFormUpdateState)
                        }
                        scrollToFirstError={true}
                        layout={'vertical'}
                        className={'formStyle'}
                        initialValues={initialValues}
                        validateTrigger={'onBlur'}
                        onValuesChange={onValueChange}
                    >
                        <Row>
                            <EditCompanyInfo
                                save={updateCompany}
                                isSending={isSending}
                                isMyCompany={isMyCompany}
                                isNew={isNew}
                            />
                            <EditCompanyContactInfo />
                            <EditCompanyBillingInfo />
                            {isNew || isLord || isMainMaternalCompany || isChild ? (
                                <EditCompanyFinancial checkValid={checkValid} />
                            ) : null}
                            {(isMainMaternalCompany || companyId === currentCompanyId) && isMNOHost ? (
                                <EditCompanySubscriberVisibilityGranted />
                            ) : null}
                            {!isMNOHost ? <EditCompanySalesRateList deleteItem={deleteCompanyRateById} /> : null}
                            {(whiteLabel && myWhiteLabel) || isLord ? <EditCompanyLogo /> : null}
                            {isLord && !isNew ? <EditCompanyCustomize /> : null}
                            {(isLord && !isNew && slug) || currentCompanyId === '2' ? <EditCompanyCustomDocs /> : null}
                        </Row>
                    </Form>
                    {isLord && !isNew && customAboutEnabled ? <EditCompanyCustomizeAbout /> : null}
                </Form.Provider>
            </div>
        </Catcher>
    );
});
