import { action, makeObservable, observable } from 'mobx';
import { FeatureFlagsList } from '../interfaces';

export class FeatureFlagStore {
    private constructor() {
        makeObservable(this);
    }

    private static instance: FeatureFlagStore;
    public static getInstance(): FeatureFlagStore {
        if (!FeatureFlagStore.instance) {
            FeatureFlagStore.instance = new FeatureFlagStore();
        }
        return FeatureFlagStore.instance;
    }

    @observable featureFlags: FeatureFlagsList = [];
    @action setFeatureFlagsList(featureFlags: FeatureFlagsList): void {
        this.featureFlags = featureFlags;
    }

    @action cleanStore(): void {
        this.featureFlags = [];
    }
}
