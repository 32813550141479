import React, { MouseEvent } from 'react';
import { observer } from 'mobx-react';
import { Catcher, LoadingApiByIds } from '../../';
import { HorizontalUsageChart } from './';
import { useDashboardTelecomUsageChart, useRouterStore } from '../../../hooks';
import { tI, tP } from '../../../translate';
import Styles from '../m_dashboarStyles.less';

export const TelecomUsage: React.FC = observer(() => {
    const data = useDashboardTelecomUsageChart();
    const routerStore = useRouterStore();
    const title = `${tP('Top Operator')} ${tI('by Usage')}`;
    const goToTelecomsList = (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>): void => {
        event.preventDefault();
        routerStore.goTo('TelecomsList');
    };

    const titleLink = (): JSX.Element => (
        <a onClick={goToTelecomsList} href={`/operators`}>
            {title}
        </a>
    );

    const goToTelecom = (id: string): void => {
        routerStore.goTo('Operator', { params: { id } });
    };

    return (
        <Catcher>
            <LoadingApiByIds idSpinners={['GET_USAGE_TELECOM_LIST']} />
            <div className={Styles.chart}>
                <HorizontalUsageChart
                    sourceData={data}
                    title={title}
                    titleLink={titleLink()}
                    onSelectRow={goToTelecom}
                />
            </div>
        </Catcher>
    );
});
