import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Form, Row } from 'antd';
import { FieldData } from 'rc-field-form/es/interface';
import { IAntDFormChangeFieldName, IEditForm, IFormFields } from '../../../interfaces';
import { useCountryInfo, useZonesSelect } from '../../../hooks';
import {
    apiMessagesDeleteMessageByFieldName,
    apiMessagesGetFormRules,
    apiMessagesGetFormRulesFieldBlank,
    apiMessagesSaveMessages,
    patchCountry,
} from '../../../middleware';
import { cleanPathFields } from '../../../instruments';
import { tF } from '../../../translate';
import {
    CardInfoToolBar,
    CardMainLayout,
    Catcher,
    FormSelect,
    InputDebounce,
    cardLayoutOptions,
} from '../../../Components';
import Styles from '../../styles/m_itemCardStyles.less';

export const EditCountryForm: React.FC<IEditForm> = observer(({ setEditMode }: IEditForm) => {
    const [form] = Form.useForm();
    const initialValues = useCountryInfo();
    const isNew = !initialValues.id;
    const zoneSelect = useZonesSelect;
    const [isSending, setSending] = useState(false);
    const [isChanged, setChanged] = useState(false);

    useEffect(() => {
        if (!isChanged) {
            form.setFieldsValue(initialValues);
        }
    }, [initialValues]);

    const onFieldsChange = (changedFields: FieldData[]): void => {
        const fieldNameChange =
            changedFields && changedFields[0] && (changedFields[0].name as IAntDFormChangeFieldName);
        if (fieldNameChange) {
            apiMessagesDeleteMessageByFieldName(fieldNameChange);
        }

        setChanged(true);
    };

    const onFinish = async (fields: IFormFields): Promise<void> => {
        setSending(true);
        const cleanFields = cleanPathFields(fields, initialValues);
        const { isError, errors } = await patchCountry(initialValues.id, cleanFields);

        if (isError) {
            apiMessagesSaveMessages(errors);
            form.validateFields();
        } else {
            setEditMode && setEditMode(false);
        }
        setSending(false);
    };

    const nameTitle = tF('Name');
    const iso3Title = tF('ISO3');
    const mccTitle = tF('MCC');

    return (
        <Catcher>
            <article className={Styles.main}>
                <Row>
                    <CardMainLayout
                        columnOption={cardLayoutOptions.cardInfoEdit}
                        idSpinners={['GET_COUNTRY']}
                        isEdit
                        name={initialValues.name}
                    >
                        <Form
                            form={form}
                            name="countryInfo"
                            initialValues={initialValues}
                            onFieldsChange={onFieldsChange}
                            onFinish={onFinish}
                            scrollToFirstError={true}
                            layout={'vertical'}
                        >
                            <Form.Item name="name" label={nameTitle} rules={apiMessagesGetFormRulesFieldBlank()}>
                                <InputDebounce placeholder={nameTitle} isFirstInput />
                            </Form.Item>
                            <Form.Item name="iso3" label={iso3Title} rules={apiMessagesGetFormRules}>
                                <InputDebounce placeholder={iso3Title} />
                            </Form.Item>
                            <Form.Item name="mcc" label={mccTitle} rules={apiMessagesGetFormRules}>
                                <InputDebounce placeholder={mccTitle} />
                            </Form.Item>
                            <FormSelect
                                fieldName={'zoneId'}
                                label={tF('Zone')}
                                rules={apiMessagesGetFormRules}
                                useSelectHook={zoneSelect}
                                isNotClear
                            />
                            <CardInfoToolBar isLoading={isSending} isNew={isNew} isSubmitButton />
                        </Form>
                    </CardMainLayout>
                </Row>
            </article>
        </Catcher>
    );
});
