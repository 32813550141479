import { action, makeObservable, observable, reaction } from 'mobx';
import { appConfig } from '../settings';
import { IPackagesForSale, IPackagesSold, ISelect } from '../interfaces';
import { getCompaniesSelect } from '../middleware';

export class PackagesStore {
    private constructor() {
        makeObservable(this);
        reaction(
            () => this.forcedUpdateTime,
            () => {
                getCompaniesSelect().then();
            },
            {
                delay: appConfig.APIDelay,
            },
        );
    }

    private static instance: PackagesStore;
    public static getInstance(): PackagesStore {
        if (!PackagesStore.instance) {
            PackagesStore.instance = new PackagesStore();
        }
        return PackagesStore.instance;
    }

    @observable packagesForSale: IPackagesForSale = [];
    @action setPackagesForSale(packages: IPackagesForSale): void {
        this.packagesForSale = packages;
    }

    @observable forSaleTotal = 0;
    @action setForSaleTotal(total: number): void {
        this.forSaleTotal = total;
    }

    @observable packagesSold: IPackagesSold = [];
    @action setPackagesSold(packages: IPackagesSold): void {
        this.packagesSold = packages;
    }

    @observable soldTotal = 0;
    @action setSoldTotal(total: number): void {
        this.soldTotal = total;
    }

    @observable select: ISelect[] = [];
    @action setPackagesSelect(list: ISelect[]): void {
        this.select = list;
    }

    @observable forcedUpdateTime = 0;
    @action setForcedUpdateTime(): void {
        this.forcedUpdateTime = Date.now();
    }

    @action cleanStore(): void {
        this.packagesForSale = [];
        this.packagesSold = [];
        this.forSaleTotal = 0;
        this.forSaleTotal = 0;
        this.soldTotal = 0;
        this.select = [];
    }
}
