import dayjs from 'dayjs';
import { IGeoLocation } from '../interfaces';

type myMapOptions = google.maps.MapOptions & {
    maxZoom: number;
    minZoom: number;
};

const googleMapOptions: myMapOptions = {
    fullscreenControl: false,
    mapTypeControl: false,
    maxZoom: 14,
    minZoom: 2,
    rotateControl: true,
    scaleControl: true,
    streetViewControl: false,
    zoomControl: false,
    styles: [
        {
            featureType: 'administrative',
            elementType: 'geometry.fill',
            stylers: [{ visibility: 'off' }],
        },
    ],
    restriction: {
        latLngBounds: {
            north: 85,
            south: -85,
            west: -179,
            east: 179,
        },
        strictBounds: false,
    },
};
const googleMapCenter: IGeoLocation = {
    lat: 37.7933,
    lng: -8.9467,
};

export const appConfig = {
    about: {
        id: '2',
        address: `Leeuwenveldseweg 18 \n1382 LX Weesp \nThe Netherlands`,
        email: 'info@geniox.com',
        iban: 'NL79RABO 0325 2799 85',
        name: 'GENIOX Mobile',
        phoneNumber: '+31 294 78 59 92',
        registration: 'Schiphol Rijk: 70176973',
        swift: 'RABONL2U',
        vat: 'NL856180051B01',
        website: 'www.geniox.com',
    },
    APIDelay: 300, // ms
    APIMessageDelay: 500, // ms
    checkServerNotificationsDelay: 20, // x * uiStoreDelay (now 2 => 2sec)
    checkServerNotificationsPageSize: 10,
    checkVersionDelay: 60, // x * uiStoreDelay
    defaultTitle: 'Geniox',
    defaultWebsite: 'https://www.geniox.com',
    defaultOCSHost: 'ocs.geniox.com',
    delayDoReadingPrivacyPolicy: 2000, // ms
    delayFormUpdateState: 0, // ms
    delayInputDebounce: 500, // ms
    delayTokenAccessNeedUpdate: 10 * 60 * 1000, // ms
    delayTokenCheck: 60 * 1000, // ms
    // delayTokenAccessNeedUpdate: 2 * 24 * 60 * 60 * 1000 + 23 * 60 * 60 * 1000 + 58 * 60 * 1000, // ms (if exp = 3 day)
    dollar: 'US$',
    euro: '€',
    fakeId: 'fakeId',
    formatDate: 'YYYY-MM-DD',
    formatDateTime: 'YYYY-MM-DD HH:mm',
    headerApiParams: 'X-API-Host',
    defaultInterval: {
        value: 2,
        type: 'h',
    },
    intervalCDRTypeSelect: [
        {
            type: 'm',
            name: 'minutes',
            multiplier: 60,
            maxValue: 60,
            dividend: 0,
            disabled: true,
        },
        {
            type: 'h',
            name: 'hours',
            multiplier: 60 * 60,
            maxValue: 0, //24,
            dividend: 0, //24,
            listValues: [1, 2, 3, 4, 6, 8, 12, 24],
        },
        {
            type: 'd',
            name: 'days',
            multiplier: 24 * 60 * 60,
            maxValue: 4,
            dividend: 0,
            disabled: true,
        },
    ],
    isDarkTheme: false, // Default Light Theme
    maximumMonthsForRequest: 6, // months
    maximumMonthsForRequestForCDRs: 3, // months
    newIdPrefix: 'new:',
    notificationDuration: 5, // sec.
    trackingPollingTimer: 30,
    uiStoreDelay: 1000, // ms
    notificationUsersTypes: ['info', 'success', 'warning', 'warn'],
    notificationAllTypeForAppSignal: ['error'],
    notificationAllRole: 'LORD',
    pageDefaultParams: {
        pageNumber: 1,
        pageSize: 25,
        includeViewPageSize: 5,
        pageSizeValues: [5, 10, 25, 50, 100],
        startThisMonth: dayjs().startOf('month').format('YYYY-MM-DD'),
        today: dayjs().format('YYYY-MM-DD'),
    },
    entity: [
        { id: 'Card', name: 'Card' },
        { id: 'Subscriber', name: 'Subscriber' },
        { id: 'Operator', name: 'Operator' },
        { id: 'Order', name: 'Order' },
        { id: 'CardSet', name: 'CardSet' },
        { id: 'User', name: 'User' },
    ],
    top10: 10,
    top5: 5,
    chartCostTitle: 'Cost',
    transitionColorThemeTime: 300, // ms
    updateViewInterval: 5000, // ms
    currency: [
        { id: '10', currencyCode: 'AZN', abbreviation: '₼', shortCode: '₼', crossCourse: 0.59 },
        { id: '47', currencyCode: 'EUR', abbreviation: '€', shortCode: '€', crossCourse: 1.1 },
        { id: '152', currencyCode: 'USD', abbreviation: '$', shortCode: '$', crossCourse: 1 },
    ],
    sessionGrantedTypes: [
        { id: '1', name: 'currency', disabled: true },
        { id: '2', name: 'MB' },
    ],
    alarmConfigCurrency: [
        { id: '1', name: 'EUR' },
        { id: '2', name: 'USD' },
    ],
    bytesTypes: [
        { id: '0', name: 'B', disabled: false },
        { id: '1', name: 'KB', disabled: false },
        { id: '2', name: 'MB', disabled: false },
        { id: '3', name: 'GB', disabled: false },
    ],
    uploadFilesStatus: [
        {
            id: 'processing',
            text: 'Processing',
        },
        {
            id: 'processed',
            text: 'Processed',
        },
        {
            id: 'failed',
            text: 'Failed',
        },
    ],
    externalAPICompanyIds: ['1098'],
    widget: {
        div: 'freshworks-container',
        id: 80000000359,
        scriptUrl: 'https://euc-widget.freshworks.com/widgets/80000000359.js',
    },
    downloadFile: {
        terms: {
            title: 'General Terms and Conditions',
            file: '/static/pdf/General Terms and Conditions GENIOX v2.1.pdf',
            fileLabel: 'Download PDF',
        },
        policy: {
            title: 'Data Processing Agreement Customers',
            file: '/static/pdf/Data Processing Agreement Customers of GENIOX v2.2 WEB.pdf',
            fileLabel: 'Download PDF',
        },
        manual: {
            title: 'Manual OCS Portal',
            file: '/static/pdf/Geniox_OCS_Manual_2023_2.8.pdf',
            fileLabel: 'Download PDF',
        },
    },
    languageDefault: 'en',
    languages: [
        { id: 'en', name: 'English' },
        { id: 'es', name: 'Spanish' },
        { id: 'fr', name: 'French' },
    ],
    last5CDRMonthLimit: 2, // Month
    googleMapInit: {
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyBtw2-ez_pOvHZT3zK0UFQtkQtu_4wpMNE',
    },
    googleMapOptions,
    googleMapCenter,
    googleRoutChunkSize: 25,
    googleOverQueryLimitWait: 2000, // ms
};

export type IDownloadFile = {
    title: string;
    file: string;
    fileLabel?: string;
    enableDownload?: boolean;
    target?: string;
};

export type IIntervalTypeItem = {
    type: string;
    name: string;
    multiplier: number;
    dividend: number;
    isDefault?: boolean;
};

export type IAnyColorTheme = {
    '--transitionTheme': string;
    '--card-border-radius': string;
};

export type IColorTheme = {
    '--card-border-color': string;
    '--disabled-input': string;
    '--item-delete-background-color': string;
    '--item-new-background-color': string;
    '--main-alert-color': string;
    '--main-background-color': string;
    '--main-delete-color': string;
    '--main-green-color': string;
    '--main-light-color': string;
    '--main-link-color': string;
    '--main-primary-button-color': string;
    '--main-primary-color': string;
    '--main-primary-disabled-color': string;
    '--main-primary-hover-color': string;
    '--main-red-color': string;
    '--main-text-color': string;
    '--main-text-disable-color': string;
    '--modal-box-shadow': string;
    '--select-selection-item': string;
    '--table-bg-2-row': string;
    '--table-bg-thead': string;
    '--table-row-select-bg-2-row': string;
};

export type IColorThemes = {
    any: IAnyColorTheme;
    light: IColorTheme;
    dark: IColorTheme;
};

export const colorThemes: IColorThemes = {
    any: {
        '--transitionTheme': `${appConfig.transitionColorThemeTime}ms linear`,
        '--card-border-radius': '5px',
    },
    light: {
        '--card-border-color': '#d7d7d7',
        '--disabled-input': '#fafafa',
        '--item-delete-background-color': '#fad2d2',
        '--item-new-background-color': '#83c377',
        '--main-alert-color': '#ff4d4f',
        '--main-background-color': '#FFF',
        '--main-delete-color': '#ff4d4f',
        '--main-green-color': '#00FF00',
        '--main-light-color': '#fdff03',
        '--main-link-color': '#40a9ff',
        '--main-primary-button-color': '#FFFFFF',
        '--main-primary-color': '#1890ff',
        '--main-primary-disabled-color': '#DDD',
        '--main-primary-hover-color': '#40a9ff',
        '--main-red-color': '#FF0000',
        '--main-text-color': 'rgba(0, 0, 0, 0.65)',
        '--main-text-disable-color': '#999',
        '--modal-box-shadow':
            '0 3px 6px -4px rgb(0, 0, 0, 12%), 0 6px 16px 0 rgb(0, 0, 0, 8%), 0 9px 28px 8px rgb(0, 0, 0,  5%)',
        '--select-selection-item': '#f2f2f2',
        '--table-bg-2-row': '#fafafa',
        '--table-bg-thead': '#f2f2f2',
        '--table-row-select-bg-2-row': '#f5f5f5',
    },
    dark: {
        '--card-border-color': '#3c3c3c',
        '--disabled-input': '#00263d',
        '--item-delete-background-color': '#130006',
        '--item-new-background-color': '#083100',
        '--main-alert-color': '#ff4d4f',
        '--main-background-color': '#001529',
        '--main-delete-color': '#ff4d4f',
        '--main-green-color': '#00FF00',
        '--main-light-color': '#7a7b01',
        '--main-link-color': '#40a9ff',
        '--main-primary-button-color': '#FFFFFF',
        '--main-primary-color': '#1890ff',
        '--main-primary-disabled-color': '#001020',
        '--main-primary-hover-color': '#095cb5',
        '--main-red-color': '#FF0000',
        '--main-text-color': '#FFF',
        '--main-text-disable-color': '#999',
        '--modal-box-shadow': '0 3px 6px -4px #00263d, 0 6px 16px 0 #00263d, 0 9px 28px 8px #00263d',
        '--select-selection-item': '#00263d',
        '--table-bg-2-row': '#00263d',
        '--table-bg-thead': '#002030',
        '--table-row-select-bg-2-row': '#003851',
    },
};
