import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Checkbox, Form, Row } from 'antd';
import { FieldData } from 'rc-field-form/es/interface';
import { IAntDFormChangeFieldName, IEditForm, IFormFields } from '../../../interfaces';
import { useCurrencyInfo, useUiIsLoading } from '../../../hooks';
import {
    apiMessagesDeleteMessageByFieldName,
    apiMessagesGetFormRules,
    apiMessagesGetFormRulesFieldBlank,
    apiMessagesSaveMessages,
    patchCurrency,
} from '../../../middleware';
import { cleanPathFields } from '../../../instruments';
import { tF } from '../../../translate';
import { CardInfoToolBar, CardMainLayout, Catcher, InputDebounce } from '../../../Components';
import Styles from '../../styles/m_itemCardStyles.less';

export const EditCurrency: React.FC<IEditForm> = observer(({ setEditMode }: IEditForm) => {
    const isLoading = useUiIsLoading();
    const [form] = Form.useForm();
    const initialValues = useCurrencyInfo();
    const isNew = !initialValues.id;
    const [isSending, setSending] = useState(false);
    const [isChanged, setChanged] = useState(false);

    useEffect(() => {
        if (!isLoading && !isChanged) {
            form.setFieldsValue(initialValues);
        }
    }, [initialValues]);

    const onFieldsChange = (changedFields: FieldData[]): void => {
        const fieldNameChange =
            changedFields && changedFields[0] && (changedFields[0].name as IAntDFormChangeFieldName);
        if (fieldNameChange) {
            apiMessagesDeleteMessageByFieldName(fieldNameChange);
        }

        setChanged(true);
    };

    const onFinish = async (fields: IFormFields): Promise<void> => {
        setSending(true);
        const cleanFields = cleanPathFields(fields, initialValues);
        const { isError, errors } = await patchCurrency(initialValues.id, cleanFields);

        if (isError) {
            apiMessagesSaveMessages(errors);
            form.validateFields();
        } else {
            setEditMode && setEditMode(false);
            setEditMode && setEditMode(false);
        }
        setSending(false);
    };

    const abbreviationTitle = tF('Abbreviation');
    const descriptionTitle = tF('Description');
    const symbolTitle = tF('Symbol');

    return (
        <Catcher>
            <article className={Styles.main}>
                <Row>
                    <CardMainLayout idSpinners={['GET_CURRENCY']} isEdit name={initialValues.abbreviation}>
                        <Form
                            form={form}
                            name="currencyInfo"
                            initialValues={initialValues}
                            onFieldsChange={onFieldsChange}
                            onFinish={onFinish}
                            scrollToFirstError={true}
                            layout={'vertical'}
                        >
                            <Form.Item
                                name="abbreviation"
                                label={abbreviationTitle}
                                rules={apiMessagesGetFormRulesFieldBlank()}
                            >
                                <InputDebounce placeholder={abbreviationTitle} isFirstInput />
                            </Form.Item>
                            <Form.Item name="description" label={descriptionTitle} rules={apiMessagesGetFormRules}>
                                <InputDebounce placeholder={descriptionTitle} />
                            </Form.Item>
                            <Form.Item name="selectable" valuePropName="checked" rules={apiMessagesGetFormRules}>
                                <Checkbox>{tF('Selectable')}</Checkbox>
                            </Form.Item>
                            <Form.Item name="symbol" label={symbolTitle} rules={apiMessagesGetFormRules}>
                                <InputDebounce placeholder={symbolTitle} />
                            </Form.Item>
                            <CardInfoToolBar isLoading={isSending} isNew={isNew} isSubmitButton />
                        </Form>
                    </CardMainLayout>
                </Row>
            </article>
        </Catcher>
    );
});
