import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { observer } from 'mobx-react';
import { ConfigProvider } from 'antd';
import { Locale } from 'antd/lib/locale';
import enUS from 'antd/lib/locale/en_US';
import esES from 'antd/lib/locale/es_ES';
import frFR from 'antd/lib/locale/fr_FR';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';
import { ILanguageId } from '../interfaces';
import { useUiLanguage } from '../hooks';
import i18next from '../translate/i18n';
import { Catcher } from '../Components/';

interface IAntProvider {
    children: JSX.Element;
}

const calcLocation = (lng: ILanguageId): Locale => {
    switch (lng) {
        case 'es':
            return esES;
        case 'fr':
            return frFR;
        default:
            return enUS;
    }
};

export const LanguageProvider: React.FC<IAntProvider> = observer(({ children }: IAntProvider) => {
    const languageId = useUiLanguage();
    dayjs.locale(languageId);
    return (
        <Catcher>
            <I18nextProvider i18n={i18next}>
                <ConfigProvider locale={calcLocation(languageId)}>{children}</ConfigProvider>
            </I18nextProvider>
        </Catcher>
    );
});
