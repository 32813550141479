import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Checkbox, Form } from 'antd';
import {
    CardInfoToolBar,
    CardMainLayout,
    Catcher,
    FormSelect,
    InputDebounce,
    TextAreaDebounce,
    cardLayoutOptions,
} from '../../';
import { IEditFormComponentInfo } from '../../../interfaces';
import { useCardSetInfo, useCompaniesSelect, useIsAbleToManageCardSetGeolocation } from '../../../hooks';
import { apiMessagesGetFormRules } from '../../../middleware';
import { tF } from '../../../translate';

export const EditCardSetFieldsInfo: React.FC<IEditFormComponentInfo> = observer(
    ({ isSending, save, isNew }: IEditFormComponentInfo) => {
        const [currentName, setCurrentName] = useState('');
        const companiesSelectHook = useCompaniesSelect;
        const { name, isHashed } = useCardSetInfo();
        const isGeolocationManageable = useIsAbleToManageCardSetGeolocation();
        const nameTitle = tF('Name');
        const nameNotes = tF('Notes');

        useEffect(() => {
            if (name !== undefined && currentName === '' && !isNew) {
                setCurrentName(name);
            }
        }, [name]);

        return (
            <Catcher>
                <CardMainLayout
                    idSpinners={['GET_CARD_SET', 'GET_COMPANIES_SELECT', 'POST_CARD_SET', 'PATCH_CARD_SET']}
                    isEdit
                    name={currentName}
                    columnOption={cardLayoutOptions.cardSetInfo}
                >
                    <Form.Item name="name" label={nameTitle} rules={apiMessagesGetFormRules}>
                        <InputDebounce placeholder={nameTitle} isFirstInput disabled={isHashed} />
                    </Form.Item>
                    <FormSelect
                        fieldName={'companyId'}
                        label={tF('Company')}
                        rules={apiMessagesGetFormRules}
                        useSelectHook={companiesSelectHook}
                    />
                    {isGeolocationManageable ? (
                        <Form.Item name="geolocationEnabled" valuePropName="checked" rules={apiMessagesGetFormRules}>
                            <Checkbox>{tF('Activate Geolocation for this card set')}</Checkbox>
                        </Form.Item>
                    ) : null}
                    <Form.Item name="notes" label={nameNotes} rules={apiMessagesGetFormRules}>
                        <TextAreaDebounce placeholder={nameNotes} autoSize={true} />
                    </Form.Item>
                    <CardInfoToolBar dataTest="EditCardSetInfo" onSave={save} isLoading={isSending} isNew={isNew} />
                </CardMainLayout>
            </Catcher>
        );
    },
);
