/* eslint-disable */
import { ProfileStore } from '../stores/profile';
import { mapAPIToUser } from './';
import {
    IRealRole,
    IRoleRight,
    isCompanyTypeIdMNOHost,
    isCompanyTypeIdMainMaternalCompany,
    isRoleRight,
} from '../settings';
import { IGetApiResponse, ITokenInfo, apiManager, tokenManager } from '../REST';
import {
    IAboutInfo,
    IAssignedCardSets,
    IFeatureFlags,
    IRelationshipItem,
    IRequestAPIBody,
    IRequestIncludedItem,
    IUser,
    IUserAPI,
    IUserAlarms,
    IUserAlarmsAPIGET,
} from '../interfaces';
import { globalHexId, mapApi2UiField, unionStringArr } from '../instruments';

export function setProfileAboutStore(about: IAboutInfo): void {
    const ProfileInstance = ProfileStore.getInstance();
    ProfileInstance.setAbout(about);
}

export function setProfileStore(profile: IUser): void {
    const ProfileInstance = ProfileStore.getInstance();
    ProfileInstance.setProfile(profile);
}

const tokenInfo2Profile = (tokenInfo: ITokenInfo): IUser => {
    return { id: tokenInfo.user_id ? tokenInfo.user_id.toString() : '', role: tokenInfo.role };
};

export function setProfileTokenUserInfo(tokenInfo: ITokenInfo): void {
    const ProfileInstance = ProfileStore.getInstance();
    const currentProfile = ProfileInstance.profile;
    ProfileInstance.setProfile({
        ...currentProfile,
        ...tokenInfo2Profile(tokenInfo),
    });
}

export async function getProfileInfo(): Promise<IGetApiResponse> {
    const response = await apiManager.getApi('GET_USER_PROFILE');
    const { isError, resp } = response;

    if (!isError) {
        const profileResp = resp as IUserAPI;
        const { aboutInfo, ...profile }: IUser = mapAPIToUser(profileResp);
        profile.isMainMaternalCompany = isCompanyTypeIdMainMaternalCompany(profile.companyTypeId);
        profile.isCompanyTypeIdMNOHost = isCompanyTypeIdMNOHost(profile.companyTypeId);
        const tokenInfo = await tokenManager.getAccessTokenInfo();
        const tokenInfoProfile = tokenInfo2Profile(tokenInfo);

        setProfileStore({ ...profile, ...tokenInfoProfile });
        setProfileAboutStore(aboutInfo || {});
        await getFeatureFlagRules();
    }
    return response;
}

export function getProfileUser(): IUser {
    const ProfileInstance = ProfileStore.getInstance();
    return ProfileInstance.profile;
}
export function getProfileCurrentCompanyId(): string {
    const { companyId } = getProfileUser();
    return companyId || '';
}

export function getProfileIsCompanyTypeIdMNOHost(): boolean {
    const { isCompanyTypeIdMNOHost } = getProfileUser();
    return !!isCompanyTypeIdMNOHost;
}

export function getProfileUserRole(): IRealRole {
    const { role } = getProfileUser();
    return role;
}

export function getProfileUserIsRoleRight(right: IRoleRight): boolean {
    return isRoleRight([right], getProfileUserRole());
}

// ------------ MyAlarm ------------
export function getProfileAlarmsInfo(): IUserAlarms {
    const ProfileInstance = ProfileStore.getInstance();
    return ProfileInstance.alarms;
}

export function setProfileAlarms(alarmInfo: IUserAlarms): void {
    const ProfileInstance = ProfileStore.getInstance();
    ProfileInstance.setUserAlarms(alarmInfo);
}

export function formProfileAlarms(resp: IUserAlarmsAPIGET): IUserAlarms {
    const { data, included } = resp;
    const eventArr = data?.attributes?.events;
    const notifications: { [key: string]: boolean } = {};
    eventArr?.forEach((value) => {
        notifications[value] = true;
    });
    const cardLimit = data?.attributes?.card_limit || 0;
    const cardSetLimit = data?.attributes?.card_set_limit || 0;
    const foundCardSets = included?.filter((i) => i.type === 'card_set');
    const cardSetIds = foundCardSets?.map((i) => i.id);
    const cardSetRecordMap: { [key: string]: string } = {};

    included?.forEach((value) => {
        if (value.type === 'company_configs_card_set') {
            const cardSetId = value?.relationships?.card_set?.data?.id;
            if (cardSetId) {
                cardSetRecordMap[cardSetId] = value.id;
            }
        }
    });

    return {
        ...notifications,
        cardLimit,
        cardSetLimit,
        cardSetIds,
        cardSetRecordMap,
    };
}

export async function sendProfileAlarms(values: IUserAlarms): Promise<boolean> {
    const companyId = getProfileCurrentCompanyId();
    const alarmInfo = getProfileAlarmsInfo();
    const currentCardSets = alarmInfo.cardSetIds || [];
    const cardSetRecordMap = alarmInfo.cardSetRecordMap || {};
    const newCardSets = values.cardSetIds || [];
    const cardSetsIds = unionStringArr(newCardSets, currentCardSets);

    const assignedCardSetsArr: IAssignedCardSets[] = cardSetsIds?.map((cardSetId) => {
        const destroy = currentCardSets.includes(cardSetId) && !newCardSets?.includes(cardSetId);
        const isAlreadyAssigned = currentCardSets.includes(cardSetId) && newCardSets?.includes(cardSetId);
        const foundCardSet = cardSetRecordMap[cardSetId];

        return {
            id: destroy || isAlreadyAssigned ? `${foundCardSet}` : globalHexId.getId(),
            type: 'company_configs_card_set',
            cardSetId: `${cardSetId}`,
            destroy,
            isAlreadyAssigned,
        };
    });

    const filteredAlreadyAssignedArr: IAssignedCardSets[] = assignedCardSetsArr?.filter((value: IAssignedCardSets) => {
        return !value.isAlreadyAssigned;
    });

    const relationshipsCompanyCarSet: IRelationshipItem[] = filteredAlreadyAssignedArr?.map(
        (value: IAssignedCardSets) => {
            return {
                id: value.id,
                type: value.type,
                destroy: value.destroy,
            };
        },
    );

    const included: IRequestIncludedItem[] = filteredAlreadyAssignedArr
        ?.filter((value: IAssignedCardSets) => {
            return !value.destroy;
        })
        ?.map((value) => {
            return {
                id: value.id,
                type: value.type,
                attributes: {
                    card_set_id: value.cardSetId,
                },
            };
        });

    const cardSetLimit = values.cardSetLimit || 0;
    const cardLimit = values.cardLimit || 0;

    delete values.cardSetIds;
    delete values.cardSetLimit;
    delete values.cardLimit;

    const notificationsEntries = Object.entries(values).filter((value) => {
        return value[1];
    });

    const notifications = notificationsEntries.map((value) => {
        return value[0];
    });

    const data: any = {
        type: 'company_config',
        attributes: {
            events: [...notifications],
            card_set_limit: +cardSetLimit || null,
            card_limit: +cardLimit || null,
        },
        relationships: {
            company_configs_card_sets: {
                data: relationshipsCompanyCarSet,
            },
        },
    };

    const body: IRequestAPIBody = {
        data,
        included,
    };

    const { isError } = await apiManager.getApi('PATCH_PROFILE_ALARM', { id: companyId }, body);

    return isError;
}

export async function getProfileAlarm(id: string): Promise<void> {
    const { isError, resp } = await apiManager.getApi('GET_PROFILE_ALARM', { id });

    if (!isError) {
        setProfileAlarms(formProfileAlarms(resp as IUserAlarmsAPIGET));
    }
}

export function cleanProfileStore(): void {
    const ProfileInstance = ProfileStore.getInstance();
    ProfileInstance.cleanStore();
}

// ------------ FeatureFlagRules ------------

export function setFeatureFlagRules(flags: IFeatureFlags) {
    const ProfileStoreInstance = ProfileStore.getInstance();
    ProfileStoreInstance.setProfileFeatureFlags(flags);
}

export async function getFeatureFlagRules(): Promise<void> {
    const { resp } = await apiManager.getApi('GET_FEATURE_FLAG_RULES');
    const flags: IFeatureFlags = mapApi2UiField(resp);
    setFeatureFlagRules(flags);
}

export function getFeatureFlags(): IFeatureFlags {
    const ProfileStoreInstance = ProfileStore.getInstance();
    return ProfileStoreInstance.profileFeatureFlags;
}
export function getFeatureFlagIsBigQuery(): boolean {
    const flags = getFeatureFlags();
    return !!flags.useBigquery;
}
