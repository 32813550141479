import React, { useEffect, useState } from 'react';
import {
    ApiOutlined,
    ControlOutlined,
    FileAddOutlined,
    FileExcelOutlined,
    FlagOutlined,
    GatewayOutlined,
    NotificationOutlined,
    NumberOutlined,
    UploadOutlined,
    UserSwitchOutlined,
} from '@ant-design/icons';
import { CardLayout, Catcher, FieldInfoAdvanced, GoPageButton, LinkButton, ReloadAppButton } from '../';
import appInfo from '../../application.json';
import cx from 'classnames';
import { useRouterStore } from '../../hooks';
import { getDeployVersion } from '../../middleware';
import { tI, tP } from '../../translate';
import Styles from '../styles/m_itemCardStyles.less';
import SupportStyles from './m_styles.less';

export const SupportPage: React.FC = () => {
    const [deployVersion, setDeployVersion] = useState<string | undefined>();
    const isOkVersion = appInfo.version === deployVersion;
    const routerStore = useRouterStore();

    const getVersion = async () => {
        const { deployVersion } = await getDeployVersion();
        if (deployVersion) {
            setDeployVersion(deployVersion);
        }
    };

    useEffect(() => {
        getVersion();
    }, []);

    return (
        <Catcher>
            <div className={cx(Styles.main, SupportStyles.container)} style={{ display: 'none' }}>
                {/*example LinkButton & GoPageButton*/}
                <CardLayout
                    dataTest={'AppVersions'}
                    icon={<ControlOutlined className={SupportStyles.cardIcon} />}
                    title={'Applications versions'}
                >
                    <p className={Styles.paragraph}>{'LinkButton'}</p>
                    <LinkButton
                        dataTest={'Test'}
                        title={'Add'}
                        icon={<UploadOutlined />}
                        href={'/About'}
                        onClick={() => routerStore.goTo('About')}
                    />
                    <p className={Styles.paragraph}>{'LinkButton CUSTOM'}</p>
                    <LinkButton
                        dataTest={'Test'}
                        title={'Add'}
                        pageTitle={'About'}
                        icon={<NumberOutlined />}
                        href={'/About'}
                        type={'primary'}
                        onClick={() => routerStore.goTo('About')}
                    />
                    <p className={Styles.paragraph}>{'GoPageButton'}</p>
                    <GoPageButton componentName={'About'} />
                    <p className={Styles.paragraph}>{'GoPageButton CUSTOM'}</p>
                    <GoPageButton componentName={'About'} title={'Add'} icon={<NumberOutlined />} />
                </CardLayout>
            </div>
            <div className={cx(Styles.main, SupportStyles.container)}>
                <CardLayout
                    dataTest={'AppVersions'}
                    icon={<ControlOutlined className={SupportStyles.cardIcon} />}
                    title={'Applications versions'}
                >
                    <FieldInfoAdvanced title={'Loaded version'}>{appInfo.version}</FieldInfoAdvanced>
                    <FieldInfoAdvanced title={'Deployed version'}>{deployVersion}</FieldInfoAdvanced>
                    {deployVersion ? (
                        <p
                            className={isOkVersion ? Styles.paragraphOk : Styles.paragraphWarn}
                            data-test={'AppVersionsFileInfo'}
                        >
                            {isOkVersion
                                ? tI('You use last version.')
                                : tI('Your app version is outdated. You need to reload the page.')}
                        </p>
                    ) : null}
                    {deployVersion && !isOkVersion ? (
                        <p className={Styles.paragraph} data-test={'AppVersionsButton'}>
                            {ReloadAppButton}
                        </p>
                    ) : null}
                </CardLayout>
                <CardLayout
                    dataTest={'UploadCardDeviceFile'}
                    icon={<UploadOutlined className={SupportStyles.cardIcon} />}
                    title={'Upload Card Device'}
                >
                    <p className={Styles.paragraph} data-test={'UploadCardDeviceFileInfo'}>
                        {tI('Here you can upload card device files')}
                    </p>
                    <GoPageButton componentName={'UploadCardDevice'} />
                </CardLayout>
                <CardLayout
                    dataTest={'UploadCardDeviceFile'}
                    icon={<NumberOutlined className={SupportStyles.cardIcon} />}
                    title={'Hash Companies Info'}
                >
                    <p className={Styles.paragraph} data-test={'UploadCardDeviceFileInfo'}>
                        {tI('Here you can hash companies info')}
                    </p>
                    <GoPageButton componentName={'HashCompaniesInfo'} />
                </CardLayout>
                <CardLayout
                    dataTest={'ImpersonateUser'}
                    icon={<UserSwitchOutlined className={SupportStyles.cardIcon} />}
                    title={'Impersonate User'}
                >
                    <p className={Styles.paragraph} data-test={'ImpersonateUserInfo'}>
                        {tI('Here you can impersonate users to see what they see')}
                    </p>
                    <GoPageButton componentName={'ImpersonateUser'} />
                </CardLayout>
                <CardLayout
                    dataTest={'ExternalAPIList'}
                    icon={<ApiOutlined className={SupportStyles.cardIcon} />}
                    title={'External API List'}
                >
                    <GoPageButton componentName={'ExternalAPIList'} />
                </CardLayout>
                <CardLayout
                    dataTest={'ESPlusNotifications'}
                    icon={<NotificationOutlined className={SupportStyles.cardIcon} />}
                    title={'ES2+ Notifications'}
                >
                    <GoPageButton componentName={'ESPlusNotifications'} title={'List'} />
                    <GoPageButton componentName={'ESPlusNotificationsLogs'} title={'Logs'} />
                    <GoPageButton componentName={'ESPlusNotificationQRcodes'} title={'QR codes'} />
                </CardLayout>
                <CardLayout
                    dataTest={'ExternalAPIList'}
                    icon={<FileExcelOutlined className={SupportStyles.cardIcon} />}
                    title={'Operator Interactions'}
                >
                    <p className={Styles.paragraph} data-test={'ImpersonateUserInfo'}>
                        {tP('Operator Interactions Files List')}
                    </p>
                    <GoPageButton componentName={'OperatorInteractionsFilesList'} />
                    <p className={Styles.paragraph} data-test={'ImpersonateUserInfo'}>
                        {tP('Operator Interactions Responses List')}
                    </p>
                    <GoPageButton componentName={'OperatorInteractionsResponsesList'} />
                </CardLayout>
                <CardLayout
                    dataTest={'FeatureFlags'}
                    icon={<FlagOutlined className={SupportStyles.cardIcon} />}
                    title={'Feature Flags'}
                >
                    <GoPageButton componentName={'FeatureFlags'} />
                </CardLayout>
                <CardLayout
                    dataTest={'GatewayConfig'}
                    icon={<GatewayOutlined className={SupportStyles.cardIcon} />}
                    title={'Gateway Config'}
                >
                    <GoPageButton componentName={'GatewayConfig'} />
                </CardLayout>
                <CardLayout
                    dataTest={'ScpFileUpload'}
                    icon={<FileAddOutlined className={SupportStyles.cardIcon} />}
                    title={'Scp File Upload'}
                >
                    <GoPageButton componentName={'ScpFileUpload'} />
                </CardLayout>
            </div>
        </Catcher>
    );
};
