import React from 'react';
import { Col } from 'antd';
import { cardLayoutOptions } from './cardLayoutOptions';
import { ICardLayout } from '../../interfaces';
import { formatDataTestAttribute } from '../../instruments/testing';
import { tP } from '../../translate';
import { Catcher, LoadingApi, LoadingApiByIds, TitleCard } from '../../Components';
import Styles from '../styles/m_itemCardStyles.less';

export const CardLayout: React.FC<ICardLayout> = ({
    children,
    columnOption,
    count,
    extraTitleComponent,
    icon,
    idSpinners,
    isMainCard,
    isTable,
    onClickAddButton,
    OtherButton,
    title,
    titleLeft,
    titleRight,
    titleToolBar,
    unboundTitle,
    dataTest = '',
}: ICardLayout) => {
    return (
        <Catcher>
            <Col
                {...(columnOption ? columnOption : isTable ? cardLayoutOptions.cardTable : cardLayoutOptions.cardInfo)}
            >
                <section className={Styles.cardLayout}>
                    {!idSpinners ? (
                        <LoadingApi />
                    ) : idSpinners.length ? (
                        <LoadingApiByIds idSpinners={idSpinners} />
                    ) : null}
                    {title || unboundTitle || titleRight || titleLeft || titleToolBar || icon || onClickAddButton ? (
                        <TitleCard
                            count={count}
                            dataTest={dataTest}
                            extraComponent={extraTitleComponent}
                            icon={icon}
                            idSpinners={idSpinners}
                            isMainCard={isMainCard}
                            left={titleLeft}
                            onClickAddButton={onClickAddButton}
                            OtherButton={OtherButton}
                            right={titleRight}
                            title={title ? tP(title) : undefined}
                            titleToolBar={titleToolBar}
                            unboundTitle={unboundTitle}
                        />
                    ) : null}
                    <article
                        className={isTable ? Styles.cardTableContent : Styles.cardContent}
                        data-test={formatDataTestAttribute(dataTest, 'CardLayout', 'Children')}
                    >
                        {children ? children : null}
                    </article>
                </section>
            </Col>
        </Catcher>
    );
};
