import React from 'react';
import { observer } from 'mobx-react';
import { IFieldLayout, IShowForm } from '../../../interfaces';
import { useSubscriberInfo } from '../../../hooks';
import { dateText2Utc } from '../../../instruments';
import {
    CardInfoToolBar,
    CardMainLayout,
    Catcher,
    FieldInfoAdvanced,
    LinkComponent,
    cardLayoutOptions,
} from '../../../Components';

export const ShowSubscriberInfo: React.FC<IShowForm> = observer(({ setEditMode }: IShowForm) => {
    const info = useSubscriberInfo();
    const layout: IFieldLayout = {
        labelCol: {
            flex: '0 1 80px',
        },
    };

    return (
        <Catcher>
            <CardMainLayout
                idSpinners={['GET_SUBSCRIBER']}
                name={info.imsi}
                columnOption={cardLayoutOptions.cardSubscribersInfo}
            >
                <FieldInfoAdvanced layout={layout} title={'IMSI'}>
                    {info.imsi}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced layout={layout} title={'ICCID'}>
                    {info.iccId}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced layout={layout} title={'MSISDN'}>
                    {info.msisdn}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced layout={layout} title={'First seen'}>
                    {info.firstSeen ? dateText2Utc(info.firstSeen) : null}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced layout={layout} title={'Last seen'}>
                    {info.lastSeen ? dateText2Utc(info.lastSeen) : null}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced layout={layout} title={'Country'}>
                    <LinkComponent currentListComponent={'CountryList'} id={info.countryId} name={info.country} />
                </FieldInfoAdvanced>
                <FieldInfoAdvanced layout={layout} title={'Operator'}>
                    <LinkComponent currentListComponent={'OperatorsList'} id={info.operatorId} name={info.operator} />
                </FieldInfoAdvanced>
                <FieldInfoAdvanced layout={layout} title={'Status'}>
                    {info.statusNew}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced layout={layout} title={'Printed ID'}>
                    {info.printedId}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced layout={layout} title={'IMSI'}>
                    {info.imsi}
                </FieldInfoAdvanced>
                <CardInfoToolBar setEditMode={setEditMode} />
            </CardMainLayout>
        </Catcher>
    );
});
