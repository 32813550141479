import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Catcher } from '../';
import { IShowFormComponent } from '../../interfaces';
import { useRouterStore } from '../../hooks';
import {
    abortAllFetch,
    addNotification,
    cleanPackageRelationSelect,
    cleanPackageStore,
    getPackageById,
    getPackageRelationSelect,
} from '../../middleware';
import { EditPackage, ShowPackage } from './Components';
import Styles from '../styles/m_itemCardStyles.less';

export const PackageRoot: React.FC<IShowFormComponent> = observer(({ isEdit, isNew }: IShowFormComponent) => {
    const routerStore = useRouterStore();
    const [id, setId] = useState<string>();

    const fetchPackageById = async (id: string, _isEdit = false): Promise<void> => {
        const isError = await getPackageById(id);
        if (isError) {
            addNotification({
                type: 'error',
                message: 'Not found',
                description: `Package id - "${id}" not found`,
                duration: 5,
                isUsersError: true,
            });
            routerStore.goTo('PackagesSell');
        } else if (_isEdit) {
            getPackageRelationSelect();
        }
    };

    const setEdit = (isEdit?: boolean): void => {
        if (id) {
            if (isEdit) {
                getPackageRelationSelect();
                fetchPackageById(id, true);
                routerStore.goTo('PackageEdit', { params: { id } });
            } else if (isNew) {
                routerStore.goTo('PackagesSell');
            } else {
                fetchPackageById(id);
                routerStore.goTo('PackageRoot', { params: { id } });
            }
        } else {
            routerStore.goTo('PackagesSell');
        }
    };

    useEffect(() => {
        const { params } = routerStore.routerState;
        if (params.id) {
            setId(params.id);
        }
    });

    useEffect(() => {
        if (isEdit || isNew) {
            getPackageRelationSelect();
        }
        return (): void => {
            abortAllFetch(); // ToDo: 07.04.2021 - On remake use abortSelectedFetch()
            cleanPackageRelationSelect();
            cleanPackageStore();
        };
    }, []);

    useEffect(() => {
        if (id) {
            fetchPackageById(id, isEdit);
        }
    }, [id]);

    return (
        <Catcher>
            <div className={Styles.main}>
                {isEdit || isNew ? (
                    <EditPackage setEditMode={setEdit} isNew={isNew} />
                ) : (
                    <ShowPackage setEditMode={setEdit} />
                )}
            </div>
        </Catcher>
    );
});
