import React, { Fragment, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Divider, Form, Input } from 'antd';
import { Catcher } from '../../';
import { ToggleList } from './';
import { ToggleItem } from '../../../interfaces';
import { IMayAlarmPanel } from './interfaces';
import { apiMessagesGetFormRulesFieldBlank, rulesIsNumber, rulesMoreThanZero } from '../../../middleware';

export const MonthlyCardPanel: React.FC<IMayAlarmPanel> = observer(({ isLoading, changeError }: IMayAlarmPanel) => {
    const [selectItemCount, setSelectItemCount] = useState<number>(0);
    const validationCardLimit = [...apiMessagesGetFormRulesFieldBlank(), rulesIsNumber, rulesMoreThanZero];
    const monthlyCardBalance: ToggleItem[] = [
        {
            label: 'Send alarm when card reaches 50% of card limit',
            name: 'notification_card_threshold_50_reached',
        },
        {
            label: 'Send alarm when card reaches 75% of card limit',
            name: 'notification_card_threshold_75_reached',
        },
        {
            label: 'Send alarm when card reaches 100% of card limit',
            name: 'notification_card_threshold_100_reached',
        },
        {
            label: 'Suspend card when card reaches 100% of card limit',
            name: 'suspend_card_threshold_100_reached',
        },
    ];

    useEffect(() => {
        changeError && changeError('monthlyCardPanel', 'Need to select at least one item', !selectItemCount);
    }, [selectItemCount]);

    return (
        <Catcher>
            <Fragment>
                <Form.Item name="cardLimit" rules={validationCardLimit}>
                    <Input
                        // addonBefore={
                        //     <FormSelect
                        //         fieldName={'cardCurrency'}
                        //         isNotClear={true}
                        //         rules={validateBlank}
                        //         useSelectHook={useAlarmConfigCurrency}
                        //         disabled={isLoading}
                        //         isAddonBefore={true}
                        //     />
                        // }
                        placeholder="Limit"
                        disabled={isLoading}
                    />
                </Form.Item>
                <Divider />
                <div id="profileAlarms_monthlyCardPanel">
                    <Form.Item name={'monthlyCardPanel'}>
                        <ToggleList
                            toggleArr={monthlyCardBalance}
                            isLoading={isLoading}
                            setSelectCount={setSelectItemCount}
                        />
                    </Form.Item>
                </div>
            </Fragment>
        </Catcher>
    );
});
