import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import { HistoryAdapter } from 'mobx-state-router';
import { Main } from './Pages/Main';
import { history } from './Pages/history';
// import * as serviceWorker from './serviceWorker';
import RootStore from './stores';
import { LanguageProvider } from './translate/LanguageProvider';
import './index.css';
import './customStyle/ant.less';
import './customStyle/main.less';

const rootStore: RootStore = new RootStore();
const historyAdapter: HistoryAdapter = new HistoryAdapter(rootStore.routerStore, history);
historyAdapter.observeRouterStateChanges();

ReactDOM.render(
    <Provider RootStore={rootStore}>
        <LanguageProvider>
            <Main />
        </LanguageProvider>
    </Provider>,
    document.getElementById('root'),
);

// serviceWorker.unregister();
