import React from 'react';
import { observer } from 'mobx-react';
import { Pagination } from 'antd';
import { Catcher, Loading } from '../';
import { appConfig } from '../../settings';
import { IGetApiType } from '../../REST';
import { useUiIsSpinnerFound, useUiPageNumber, useUiPageSize, useUiTotal } from '../../hooks';
import { setUiForcedUpdateTime, setUiPageNumber } from '../../middleware';
import { tI } from '../../translate';
import Styles from './m_styles.less';

interface ISkyPagination {
    customUsePaginationCount?: () => number;
    customUsePaginationIdSpinners?: () => IGetApiType[];
    idSpinners?: IGetApiType[];
}

export const SkyPaginationTotal = (total: number, range: Array<number>): JSX.Element => {
    const currentPages: string =
        range[0] < 1 ? `${total} ${tI('items')}` : `${range[0]} - ${range[1]} ${tI('of')} ${total} ${tI('items')}`;
    return <div>{currentPages}</div>;
};

export const SkyPagination: React.FC<ISkyPagination> = observer(
    ({ customUsePaginationCount, customUsePaginationIdSpinners, idSpinners = [] }: ISkyPagination) => {
        const total = customUsePaginationCount ? customUsePaginationCount?.() : useUiTotal();
        const onChange = setUiForcedUpdateTime;
        const pageSize = useUiPageSize();
        const current = useUiPageNumber();
        const customPaginationIdSpinners = customUsePaginationIdSpinners?.();
        const isLoading = useUiIsSpinnerFound(customPaginationIdSpinners || idSpinners);

        const pageSizeOptions = appConfig.pageDefaultParams.pageSizeValues.map((i: number) => i.toString());
        const onChangePage = (page: number): void => {
            setUiPageNumber(page);
            onChange();
        };

        return (
            <Catcher>
                <div className={Styles.main}>
                    <div className={Styles.wrapper}>
                        <Loading isLoading={isLoading} onlySpinner />
                        <Pagination
                            className={'customPagination'}
                            current={current}
                            disabled={isLoading}
                            pageSize={pageSize}
                            total={total}
                            pageSizeOptions={pageSizeOptions}
                            onChange={onChangePage}
                            showTotal={SkyPaginationTotal}
                            showSizeChanger={false}
                        />
                    </div>
                </div>
            </Catcher>
        );
    },
);
