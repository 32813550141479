import { action, makeObservable, observable, reaction } from 'mobx';
import { AuthStore } from './auth';
import { appConfig } from '../settings';
import { IServerNotificationItem } from '../interfaces';
import { getServerNotifications } from '../middleware';

export class AlarmsStore {
    private constructor() {
        makeObservable(this);
        if (appConfig.uiStoreDelay && !process.env.REACT_APP_DISABLE_NOTIFICATIONS_POLLING) {
            setInterval(() => this.checkTimer(), appConfig.uiStoreDelay);
        }
        reaction(
            () => (+this.isShowServerNotificationsRead).toString() + this.serverNotificationsUnReadListCount.toString(),
            () => {
                getServerNotifications(this.isShowServerNotificationsRead).then();
            },
        );
    }

    private static instance: AlarmsStore;
    public static getInstance(): AlarmsStore {
        if (!AlarmsStore.instance) {
            AlarmsStore.instance = new AlarmsStore();
        }
        return AlarmsStore.instance;
    }

    @observable showCount = appConfig.checkServerNotificationsPageSize;
    @action setShowCountIncrease(): void {
        this.showCount = this.showCount + appConfig.checkServerNotificationsPageSize;
    }
    @action setShowCountReset(): void {
        this.showCount = appConfig.checkServerNotificationsPageSize;
    }
    @observable isShowServerNotificationsRead = false;
    @action setServerNotificationsIsShowRead(isRead: boolean): void {
        this.isShowServerNotificationsRead = isRead;
    }

    @observable serverNotificationsUnRead: IServerNotificationItem[] = [];
    @observable serverNotificationsRead: IServerNotificationItem[] = [];
    @action setServerNotifications(list: IServerNotificationItem[], isRead: boolean): void {
        if (isRead) {
            this.serverNotificationsRead = list;
        } else {
            this.serverNotificationsUnRead = list;
        }
    }
    @observable isConnectServerNotifications = true; // for webSocket
    @observable serverNotificationsUnReadCount = 0;
    @observable serverNotificationsReadCount = 0;
    @action setServerNotificationsCount(count: number, isRead: boolean): void {
        if (isRead) {
            this.serverNotificationsReadCount = count;
            if (count < this.serverNotificationsReadListCount) {
                this.serverNotificationsReadListCount = count;
            }
        } else {
            this.serverNotificationsUnReadCount = count;
            if (count < this.serverNotificationsUnReadListCount) {
                this.serverNotificationsUnReadListCount = count;
            }
        }
    }
    @observable serverNotificationsUnReadListCount = appConfig.checkServerNotificationsPageSize;
    @observable serverNotificationsReadListCount = appConfig.checkServerNotificationsPageSize;

    @observable isPopupOpen = false;
    @action setIsPopupOpen(isOpen: boolean): void {
        this.isPopupOpen = isOpen;
    }

    checkTimer = (): void => {
        if (this.checkServerNotificationsDelay <= 0) {
            const AuthStoreInstance = AuthStore.getInstance();
            const { appStatus } = AuthStoreInstance;
            if (appStatus === 'READY') {
                getServerNotifications(false);
            }

            this.checkServerNotificationsDelay = appConfig.checkServerNotificationsDelay;
        } else {
            this.checkServerNotificationsDelay--;
        }
    };

    checkServerNotificationsDelay = appConfig.checkServerNotificationsDelay;
}
