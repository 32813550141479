import React from 'react';
import { Checkbox, Form, Switch } from 'antd';
import { NamePath, Rule } from 'rc-field-form/lib/interface';
import { IDivMainProps } from '../../interfaces';
import { ITrField, ITrInterfaces, tF, tI } from '../../translate';
import { Catcher } from '../Catcher';

type IFormCheckedField = IDivMainProps & {
    children?: never;
    dataTest?: string;
    description?: ITrInterfaces;
    isSwitch?: boolean;
    label: ITrField;
    name: NamePath;
    rules?: Rule[];
};

export const FormCheckedField: React.FC<IFormCheckedField> = ({
    className,
    description,
    isSwitch,
    label,
    name,
    rules,
}: IFormCheckedField) => {
    const descriptionText = description ? ` (${tI(description)})` : '';
    return (
        <Catcher>
            <Form.Item
                name={name}
                valuePropName="checked"
                rules={rules}
                className={className}
                label={`${tF(label)}${descriptionText}`}
            >
                {isSwitch ? <Switch /> : <Checkbox />}
            </Form.Item>
        </Catcher>
    );
};
