import React, { ReactNode } from 'react';
import { CancelButton, CancelButtonGoList, Catcher, EditButton, SaveButton } from '../';
import cx from 'classnames';
import { IGetApiType } from '../../REST';
import { IDivMainProps } from '../../interfaces';
import { formatDataTestAttribute } from '../../instruments/testing';
import { ITrButton } from '../../translate';
import Styles from '../styles/m_itemCardStyles.less';

interface ICardInfoToolBar extends IDivMainProps {
    children?: ReactNode;
    dataTest?: string;
    isBackToList?: boolean;
    isDisabledCancel?: boolean;
    isDisabledSave?: boolean;
    isHideCancel?: boolean;
    isLoading?: boolean;
    isNew?: boolean;
    isSubmitButton?: boolean;
    labelSave?: ITrButton;
    loadingTypes?: IGetApiType[];
    onCancel?: () => void;
    onSave?: () => void;
    setEditMode?: (val?: boolean) => void;
    isDanger?: boolean;
}

export const CardInfoToolBar: React.FC<ICardInfoToolBar> = ({
    children,
    className,
    dataTest = '',
    isBackToList,
    isDisabledCancel,
    isDisabledSave,
    isDanger,
    isHideCancel,
    isLoading,
    isNew,
    isSubmitButton,
    labelSave,
    loadingTypes,
    onCancel,
    onSave,
    setEditMode,
    ...props
}: ICardInfoToolBar) => {
    return (
        <Catcher>
            <div
                className={cx(className, Styles.cardToolBar)}
                {...props}
                data-test={formatDataTestAttribute(dataTest, 'CardInfoToolBar', 'Container')}
            >
                {isHideCancel ? null : isBackToList ? (
                    <CancelButtonGoList
                        dataTest={formatDataTestAttribute(dataTest, 'CardInfoToolBar', 'CancelButtonGoList')}
                    />
                ) : (
                    <CancelButton
                        dataTest={formatDataTestAttribute(dataTest, 'CardInfoToolBar', 'CancelButton')}
                        onClick={onCancel}
                        isDisable={isDisabledCancel}
                    />
                )}
                {children}
                {setEditMode ? (
                    <EditButton
                        setEditMode={setEditMode}
                        loadingTypes={loadingTypes}
                        isLoading={isLoading}
                        dataTest={formatDataTestAttribute(dataTest, 'CardInfoToolBar', 'EditButton')}
                    />
                ) : null}
                {onSave || isSubmitButton ? (
                    <SaveButton
                        buttonType={isSubmitButton ? 'submit' : 'button'}
                        dataTest={formatDataTestAttribute(dataTest, 'CardInfoToolBar', 'SaveButton')}
                        isDisabled={isDisabledSave}
                        isLoading={isLoading}
                        isNew={isNew}
                        label={labelSave}
                        loadingTypes={loadingTypes}
                        onClick={onSave}
                        isDanger={isDanger}
                    />
                ) : null}
            </div>
        </Catcher>
    );
};
