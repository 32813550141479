import { OperatorStore } from '../stores/operator';
import { getCallDataRecordsLast5 } from './callDataRecords';
import dayjs, { Dayjs } from 'dayjs';
import { IGetApiResponse, IRequestAPIBody, apiManager } from '../REST';
import {
    IBigQueryUsage,
    IOperatorAPI,
    IOperatorAPIActivity,
    IOperatorAPIActivityGuestRecord,
    IOperatorAPIIncludeCountry,
    IOperatorActivity,
    IOperatorActivityGuestRecord,
    IOperatorInfo,
    IPatchItem,
} from '../interfaces';
import { globalHexId, mapApi2UiField, mapUi2ApiField } from '../instruments';

export function setOperatorCurrentMonthActivity(activity: IOperatorActivity): void {
    const StoreInstance = OperatorStore.getInstance();
    StoreInstance.setActivityCurrentMonth(activity);
}

export function setOperatorPrevMonthActivity(activity: IOperatorActivity): void {
    const StoreInstance = OperatorStore.getInstance();
    StoreInstance.setActivityPrevMonth(activity);
}

export async function getOperatorActivity(id: string, prev: number, isBigQuery: boolean): Promise<void> {
    if (isBigQuery) {
        // remove after big query implementation
        let startDate: Dayjs;
        let endDate: Dayjs;

        if (prev === 1) {
            const lastMonth = dayjs().subtract(1, 'month');
            startDate = lastMonth.startOf('month');
            endDate = lastMonth.endOf('month');
        } else {
            startDate = dayjs().startOf('month');
            endDate = dayjs();
        }
        const { resp } = await apiManager.getApi('GET_USAGE_TELECOM_LIST', {
            searchParamsList: { operatorId: id, startDate: `${startDate}`, endDate: `${endDate}` },
            isBigQuery,
        });
        const bigQueryResp = resp as Array<IBigQueryUsage>;

        const guestRecords: IOperatorActivityGuestRecord[] =
            bigQueryResp.map((g: IBigQueryUsage) => {
                const newRecords: IOperatorActivityGuestRecord = {
                    operatorId: g.operator_id,
                    operatorName: g.name,
                    originalUnitType: 'byte',
                    originalUnits: String(g.volume),
                };
                return newRecords;
            }) || [];

        const sumOfVolume = bigQueryResp.reduce((accumulator, currentValue) => {
            const volume = currentValue.volume || 0;

            return accumulator + volume;
        }, 0);

        const sumOfCDR = bigQueryResp.reduce((accumulator, currentValue) => {
            const count = currentValue.count || 0;

            return accumulator + count;
        }, 0);

        const activity: IOperatorActivity = {
            count: sumOfCDR,
            originalUnits: String(sumOfVolume),
            startDate: String(startDate),
            endDate: String(endDate),
            guestRecords: guestRecords,
        };

        if (prev === 0) {
            setOperatorCurrentMonthActivity(activity);
        } else {
            setOperatorPrevMonthActivity(activity);
        }
    } else {
        const { resp } = await apiManager.getApi('GET_OPERATOR_ACTIVITY', { id: id, searchParamsList: { prev: prev } });
        const typingResp = resp as IOperatorAPIActivity;
        const guestRecords: IOperatorActivityGuestRecord[] =
            typingResp.data?.attributes?.guest_records?.map((g: IOperatorAPIActivityGuestRecord) => {
                const newRecords: IOperatorActivityGuestRecord = {
                    operatorId: g.operator_id,
                    operatorName: g.operator_name,
                    originalUnitType: g.original_unit_type,
                    originalUnits: g.original_units,
                };
                return newRecords;
            }) || [];

        const activity: IOperatorActivity = {
            id: typingResp?.data?.id,
            count: typingResp.data?.attributes?.count,
            originalUnits: typingResp.data?.attributes?.original_units,
            startDate: typingResp.data?.attributes?.start_date,
            endDate: typingResp.data?.attributes?.end_date,
            guestRecords: guestRecords,
        };

        if (prev === 0) {
            setOperatorCurrentMonthActivity(activity);
        } else {
            setOperatorPrevMonthActivity(activity);
        }
    }
}

function setOperator(apiResponse: IGetApiResponse): void {
    const { resp, isError } = apiResponse;
    if (!isError) {
        const typingResp = resp as IOperatorAPI;
        const attributes = typingResp.data?.attributes || {};

        const countryId = typingResp.data?.relationships?.country?.data?.id;
        const countryFound = countryId && typingResp.included?.find((i) => i.type === 'country' && i.id === countryId);
        const countryName = (countryFound as IOperatorAPIIncludeCountry)?.attributes?.name;

        const operatorInfo: IOperatorInfo = {
            id: typingResp.data?.id,
            countryId,
            country: countryName,
            ...mapApi2UiField(attributes),
        };

        const StoreInstance = OperatorStore.getInstance();
        StoreInstance.setOperatorInfo(operatorInfo);
    }
}

export function getOperatorShowRelationSelect(id: string, isBigQuery: boolean): void {
    getOperatorActivity(id, 0, isBigQuery);
    getOperatorActivity(id, 1, isBigQuery);
    getCallDataRecordsLast5({
        operatorId: id,
    });
}

export function getOperatorEditRelationSelect(): void {
    // getCountriesSelect();
}
function createOperatorInfo(): void {
    const operatorInfo: IOperatorInfo = {
        id: globalHexId.getId(),
    };

    const StoreInstance = OperatorStore.getInstance();
    StoreInstance.setOperatorInfo(operatorInfo);
}

export async function getOperator(id?: string): Promise<boolean> {
    if (!id) {
        createOperatorInfo();
        return true;
    } else {
        const apiResponse = await apiManager.getApi('GET_OPERATOR', { id: id });
        if (apiResponse.isError) {
            return false;
        } else {
            setOperator(apiResponse);
            return true;
        }
    }
}

export function cleanOperatorStore(): void {
    const StoreInstance = OperatorStore.getInstance();
    StoreInstance.cleanStore();
    // cleanCountriesStore();
}

export function setOperatorForm(form: IOperatorInfo): void {
    const StoreInstance = OperatorStore.getInstance();
    StoreInstance.setOperatorInfo(form);
}

export const sendOperatorInfo = async (isNew: boolean): Promise<IPatchItem> => {
    const StoreInstance = OperatorStore.getInstance();
    const info = { ...StoreInstance.info };
    const { id } = info;
    delete info.id;
    delete info.country;
    delete info.guestRecordsCount;
    delete info.hostRecordsCount;

    const body: IRequestAPIBody = {
        data: {
            id,
            type: 'operator',
            attributes: mapUi2ApiField(info),
        },
    };

    const { resp, isError } = await apiManager.getApi(
        isNew ? 'POST_OPERATOR' : 'PATCH_OPERATOR',
        isNew ? {} : { id: id },
        body,
    );
    const { errors } = await resp;
    return { isError, errors };
    // return { isError: false, errors: [] };
};
