import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Divider, Form, Input, Row, Select } from 'antd';
import { Rule, RuleObject } from 'antd/es/form';
import { ChangePassword } from './';
import {
    IAntDFormChangeFieldName,
    IEditForm,
    IFormFieldChange,
    IFormFieldOnFinish,
    IFormFieldValue,
    IFormValidatorResponse,
    ISelect,
    IUser,
} from '../../../interfaces';
import {
    useCompaniesSelect,
    useIsImpersonating,
    usePagesStoreCurrentPage,
    useProfile,
    useProfileIsRight,
    useUiIsLoading,
    useUser,
    useUserTypes,
} from '../../../hooks';
import {
    apiMessagesDeleteMessageByFieldName,
    apiMessagesGetFormRules,
    apiMessagesSaveMessages,
    cleanUserRelationSelect,
    getUserRelationSelect,
    saveUser,
} from '../../../middleware';
import { cleanPathFields, fullName } from '../../../instruments';
import { ITrPage, tF, tI, tP, tV } from '../../../translate';
import {
    CardInfoToolBar,
    CardMainLayout,
    Catcher,
    InputDebounce,
    TextAreaDebounce,
    filterOption,
} from '../../../Components';

export const EditUser: React.FC<IEditForm> = observer(({ setEditMode, isNew = false }: IEditForm) => {
    const isImpersonating = useIsImpersonating();
    const isLoading = useUiIsLoading();
    const isAdmin = useProfileIsRight('ADMIN');
    const [form] = Form.useForm();
    const { Option } = Select;
    const initialUserValues = useUser();
    const isMe = useProfile().id === initialUserValues.id;
    const isEditDisabled = isImpersonating && isMe;

    const [isSending, setSending] = useState(false);

    const checkValid = (): void => {
        form.validateFields();
    };

    const validatePasswordConfirmation: Array<Rule> = [
        ({ getFieldValue }): RuleObject => ({
            validator(rule: RuleObject, value: IFormFieldValue): IFormValidatorResponse {
                if (!value || getFieldValue('passwordNew') === value) {
                    return Promise.resolve();
                }

                return Promise.reject(tV('Password and conform password should be the same!'));
            },
        }),
        ...apiMessagesGetFormRules,
    ];

    useEffect(() => {
        getUserRelationSelect();
        return (): void => {
            cleanUserRelationSelect();
        };
    }, []);

    useEffect(() => {
        form.setFieldsValue(initialUserValues);
    }, [initialUserValues]);

    const onFieldsChangeUser = (changedFields: IFormFieldChange[]): void => {
        const fieldNameChange = changedFields && changedFields[0] && changedFields[0].name;
        if (fieldNameChange) {
            apiMessagesDeleteMessageByFieldName(fieldNameChange as IAntDFormChangeFieldName);
        }
    };

    const onFinishChangeUser = async (fields: IFormFieldOnFinish): Promise<void> => {
        setSending(true);
        const cleanFields = cleanPathFields(fields as IUser, initialUserValues);
        const { isError, errors } = await saveUser(initialUserValues.id, cleanFields, isNew);

        if (isError) {
            apiMessagesSaveMessages(errors);
            checkValid();
        } else {
            setEditMode && setEditMode(false);
        }
        setSending(false);
    };

    const companiesSelectList = useCompaniesSelect();
    const companiesOptionsJSX = companiesSelectList.map(
        (s: ISelect): JSX.Element => (
            <Option key={s.id} value={s.id}>
                <div>{s.name}</div>
            </Option>
        ),
    );

    const userTypesSelectList = useUserTypes();
    const userTypesOptionsJSX = userTypesSelectList.map(
        (s: ISelect): JSX.Element => (
            <Option key={s.id} value={s.id}>
                <div>{s.name}</div>
            </Option>
        ),
    );
    const validateAPIAndEmail: Array<Rule> = [
        {
            type: 'email',
            message: tV('The input is not valid Email!'),
        },
        ...apiMessagesGetFormRules,
    ];

    const companyTitle = tF('Company');
    const confirmPasswordTitle = tF('Confirm password');
    const emailTitle = tF('Email');
    const firstNameTitle = tF('First name');
    const functionTitle = tF('Function');
    const lastNameTitle = tF('Last name');
    const notesTitle = tF('Notes');
    const passwordTitle = tF('Password');
    const phoneTitle = tF('Phone number');
    const userTypeTitle = tF('User Type');

    const userName = `${initialUserValues.firstName} ${initialUserValues.lastName}`;
    const { title } = usePagesStoreCurrentPage();
    const showTitle = isNew
        ? tP('New User')
        : isMe
        ? tP(title as ITrPage)
        : initialUserValues.firstName || initialUserValues.lastName
        ? `${userName} (${tI('Edit')})`
        : `${tP(title as ITrPage)} ${initialUserValues.id ? '#' + initialUserValues.id : ''}`;

    useEffect(() => {
        if (document.title !== showTitle) {
            document.title = showTitle;
        }
    }, [showTitle]);

    return (
        <Catcher>
            <Row>
                <CardMainLayout
                    idSpinners={[
                        'GET_USER',
                        'PATCH_USER',
                        'POST_USER',
                        'PATCH_PASSWORD',
                        'GET_COMPANIES_SELECT',
                        'GET_USER_TYPE_SELECT',
                    ]}
                    isEdit
                    name={isMe ? tP('Profile') : fullName(initialUserValues.firstName, initialUserValues.lastName)}
                >
                    <Form
                        form={form}
                        name="user"
                        initialValues={initialUserValues}
                        onFieldsChange={onFieldsChangeUser}
                        onFinish={onFinishChangeUser}
                        scrollToFirstError={true}
                        layout={'vertical'}
                        className={'formStyle'}
                    >
                        <Form.Item name="firstName" label={firstNameTitle} rules={apiMessagesGetFormRules}>
                            <InputDebounce
                                autoComplete={'off'}
                                placeholder={firstNameTitle}
                                isFirstInput
                                disabled={isEditDisabled}
                            />
                        </Form.Item>
                        <Form.Item name="lastName" label={lastNameTitle} rules={apiMessagesGetFormRules}>
                            <InputDebounce autoComplete={'off'} placeholder={lastNameTitle} disabled={isEditDisabled} />
                        </Form.Item>
                        <Form.Item name="func" label={functionTitle} rules={apiMessagesGetFormRules}>
                            <InputDebounce autoComplete={'off'} placeholder={functionTitle} disabled={isEditDisabled} />
                        </Form.Item>
                        {isAdmin && !isMe ? (
                            <Form.Item name="companyId" label={companyTitle} rules={apiMessagesGetFormRules}>
                                <Select
                                    loading={isLoading}
                                    placeholder={companyTitle}
                                    allowClear
                                    showSearch
                                    filterOption={filterOption}
                                    disabled={isEditDisabled}
                                >
                                    {companiesOptionsJSX}
                                </Select>
                            </Form.Item>
                        ) : null}
                        <Form.Item name="email" label={emailTitle} rules={validateAPIAndEmail}>
                            <InputDebounce autoComplete={'off'} placeholder={emailTitle} disabled={isEditDisabled} />
                        </Form.Item>
                        <Form.Item name="phoneNumber" label={phoneTitle} rules={apiMessagesGetFormRules}>
                            <InputDebounce autoComplete={'off'} placeholder={phoneTitle} disabled={isEditDisabled} />
                        </Form.Item>

                        {isAdmin && !isMe ? (
                            <>
                                <Divider />
                                <Form.Item
                                    name="passwordNew"
                                    label={passwordTitle}
                                    rules={apiMessagesGetFormRules}
                                    hasFeedback
                                >
                                    <Input.Password autoComplete={'new-password'} disabled={isEditDisabled} />
                                </Form.Item>
                                <Form.Item
                                    name="passwordConfirmation"
                                    label={confirmPasswordTitle}
                                    dependencies={['passwordNew']}
                                    hasFeedback
                                    rules={validatePasswordConfirmation}
                                >
                                    <Input.Password autoComplete={'new-password'} disabled={isEditDisabled} />
                                </Form.Item>
                            </>
                        ) : null}
                        <Divider />
                        <Form.Item name="notes" label={notesTitle} rules={apiMessagesGetFormRules}>
                            <TextAreaDebounce
                                autoComplete={'off'}
                                placeholder={notesTitle}
                                autoSize={true}
                                disabled={isEditDisabled}
                            />
                        </Form.Item>
                        {isAdmin && !isMe ? (
                            <>
                                <Divider />
                                <Form.Item name="userTypeId" label={userTypeTitle} rules={apiMessagesGetFormRules}>
                                    <Select
                                        loading={isLoading}
                                        placeholder={userTypeTitle}
                                        allowClear
                                        showSearch
                                        filterOption={filterOption}
                                        disabled={isEditDisabled}
                                    >
                                        {userTypesOptionsJSX}
                                    </Select>
                                </Form.Item>
                            </>
                        ) : null}
                        <CardInfoToolBar
                            isBackToList={isMe}
                            isLoading={isSending}
                            isNew={isNew}
                            isSubmitButton={!isEditDisabled}
                        />
                    </Form>
                </CardMainLayout>
                {isMe ? <ChangePassword /> : null}
            </Row>
        </Catcher>
    );
});
