import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import countriesBorder from '../../static/data/countriesBorder.json';
import countriesShortInfo from '../../static/data/countriesShortInfo.json';
import { useProfile, useProfileFeatureFlagsIsBigQuery } from './useProfileStores';
import { useUiCurrentUnitType } from './useUiStore';
import { useUnitTypeByType } from './useUnitTypesStores';
import { appConfig } from '../../settings';
import {
    DashboardUnitTypes,
    IBigQueryUsage,
    ICardSetRevenue,
    ICardSetRevenueItem,
    ICardSetUsage,
    ICardsRevenue,
    ICardsRevenueItem,
    ICardsUsage,
    IChartData,
    IChartTitle,
    ICompanyRevenue,
    ICompanyRevenueItem,
    ICompanyUsage,
    ICost,
    ICountryRevenue,
    ICountryRevenueItem,
    ICountryUsage,
    ICountryUsageListItem,
    ICurrenciesRevenue,
    IDashboardActivityRoll,
    IDashboardActivityRollIsNotBigQuery,
    IDashboardCountryUsageList,
    IDashboardFinancesUsage,
    IFilterParams,
    IFinancesCost,
    IFinancesRollCards,
    IFinancesRollCardsHook,
    IFinancesUsageCostItemAPI,
    IFinancesUsageOriginalRevenueItemAPI,
    IGetApiType,
    IGoogleGeoPosition,
    IJSONCountryInfo,
    IJsonCountryBorder,
    IJsonCountryBorderPoint,
    IJsonCountryBorderPoints,
    IOperatorRevenueItem,
    IOverlayView,
    IRevenueItem,
    ISelect,
    IStatItem,
    ITelecomRevenue,
    ITelecomUsage,
    ITextCurrenciesRevenue,
    ITimelineRevenue,
    ITimelineRevenueItem,
    ITimelineUsage,
    ITimelineZoneItem,
    IUsageActivityUnitType,
    IUseDashboardCountryUsageCountryInfo,
    IUseDashboardCountryUsageCountryPolygon,
    IUseDashboardCountryUsageCountryPolygonsList,
    IZoneRevenue,
    IZoneUsage,
} from '../../interfaces';
import { getUnitTypesSelect } from '../../middleware';
import {
    IMakeRowTitle,
    byteRange1000,
    getApiTypeWithUnitType,
    jsonFieldToNumber,
    mapRevenues2Chart,
    moneyRange,
    showOriginalUnitByType,
    sourceDataRemoveCosts,
} from '../../instruments';
import { ITrPage, tF, tI, tP } from '../../translate';

export const getUsageChartTitle = (itemName: ITrPage): IChartTitle => {
    return [tP(itemName), tI('Usage'), { role: 'annotation' }, { role: 'annotationText' }];
};

const getRevenueChartTitle = (itemName: ITrPage) => {
    return [
        tP(itemName),
        { role: 'annotationText' },
        tF('Cost'),
        { role: 'annotation' },
        tI('USD revenue'),
        { role: 'annotation' },
        tI('EUR revenue'),
        { role: 'annotation' },
    ];
};

const useDashboardUsageDataByAPI = (apiTypeId: IGetApiType, currentUnitType?: ISelect): Array<any> => {
    const apiName = getApiTypeWithUnitType(apiTypeId, currentUnitType?.id);
    return useContext(MobXProviderContext).RootStore.dashboardStore.usageData.get(apiName) || [];
};

// ------------ Activity Usage ------------

export function useDashboardActivityRollIsNotBigQuery(): IDashboardActivityRollIsNotBigQuery {
    const activityUsage = useContext(MobXProviderContext).RootStore.dashboardStore.activityRollIsNotBigQuery;
    return {
        cost: activityUsage.cost ? +activityUsage.cost : 0,
        costRatio: activityUsage.cost_ratio ? +activityUsage.cost_ratio : 0,
        uniqCards: activityUsage.uniq_cards ? +activityUsage.uniq_cards : 0,
        uniqCardsRatio: activityUsage.uniq_cards_ratio ? +activityUsage.uniq_cards_ratio : 0,
        originalUnits: activityUsage.original_units ? +activityUsage.original_units : 0,
        originalUnitsRatio: +activityUsage.original_units_ratio ? +activityUsage.original_units_ratio : 0,
        sms: activityUsage.sms ? +activityUsage.sms : 0,
        smsRatio: activityUsage.sms_ratio ? +activityUsage.sms_ratio : 0,
        voice: activityUsage.voice ? +activityUsage.voice : 0,
        voiceRatio: activityUsage.voice_ratio ? +activityUsage.voice_ratio : 0,
    };
}
export function useDashboardActivityUsage(): IDashboardActivityRoll {
    const activityUsage = useContext(MobXProviderContext).RootStore.dashboardStore.usageActivity;
    const data: IDashboardActivityRoll = {
        cardsCount: activityUsage.cardsCount,
        cardsRatio: activityUsage.cardsRatio,
        costRatio: activityUsage.costRatio,
        costs: activityUsage.costs,
    };

    activityUsage.statsByUnitType.forEach((i: IUsageActivityUnitType) => {
        switch (i.unitType) {
            case 'sms':
                data.sms = i.volume;
                data.smsRatio = i.volumeRatio;
                break;
            case 'sec':
                data.voice = i.volume;
                data.voiceRatio = i.volumeRatio;
                break;
            case 'byte':
                data.originalUnits = i.volume;
                data.originalUnitsRatio = i.volumeRatio;
                break;
            // case 'unit':
            //     break;
            default:
                break;
        }
    });
    return data;
}

// ------------ Finances Usage ------------
function getFinancesTextCost(cost: IFinancesCost): string {
    const value = cost?.value ? +cost.value : 0;
    const findAbbreviation = appConfig.currency.find((c) => c.currencyCode === cost?.currency);
    return `${findAbbreviation ? findAbbreviation.abbreviation : ''}${value.toFixed(2)}`;
}

export function useDashboardFinancesIsNotBigQueryUsage(): IDashboardFinancesUsage {
    const financesUsage = useContext(MobXProviderContext).RootStore.dashboardStore.financesIsNotBigQueryUsage;
    const unassignedUnits = Math.abs(+financesUsage?.unassigned_units);
    const originalUnits = +financesUsage?.original_units - unassignedUnits || '0';

    return {
        cards: {
            total: financesUsage?.cards?.total || 0,
            active: financesUsage?.cards?.active || 0,
        },
        unassignedUnits: `${unassignedUnits}`,
        originalUnits: `${originalUnits}`,
        costs: Array.isArray(financesUsage.cost)
            ? financesUsage?.cost?.map((i: IFinancesUsageCostItemAPI) => ({
                  currency: i.currency_code,
                  value: getFinancesTextCost({ value: i.amount, currency: i.currency_code }),
              }))
            : [{ currency: '', value: 0 }],
        revenues: Array.isArray(financesUsage.revenue)
            ? financesUsage?.revenue?.map((i: IFinancesUsageOriginalRevenueItemAPI) => ({
                  currency: i.currency_code,
                  value: getFinancesTextCost({ value: i.amount, currency: i.currency_code }),
              }))
            : [{ currency: '', value: 0 }],
    };
}
export function useDashboardFinancesRollIsBigQueryCards(): IFinancesRollCards {
    return useContext(MobXProviderContext).RootStore.dashboardStore.financesRollCards;
}
export function useDashboardFinancesRollCards(): IFinancesRollCardsHook {
    const isBigQuery = useProfileFeatureFlagsIsBigQuery();
    const bigQueryCards = useDashboardFinancesRollIsBigQueryCards();
    const notBigQueryCards = useDashboardFinancesIsNotBigQueryUsage();
    const cardsInfo: IFinancesRollCardsHook = { active: 0, inactive: 0, total: 0, pieActive: 0 };
    if (isBigQuery) {
        cardsInfo.active = bigQueryCards.active;
        cardsInfo.inactive = bigQueryCards.inactive;
        cardsInfo.total = bigQueryCards.active + bigQueryCards.inactive;
    } else {
        cardsInfo.active = notBigQueryCards.cards.active;
        cardsInfo.inactive = notBigQueryCards.cards.total - notBigQueryCards.cards.active;
        cardsInfo.total = notBigQueryCards.cards.total;
    }
    cardsInfo.pieActive =
        cardsInfo.active && (cardsInfo.active / cardsInfo.total) * 100 < 0.1
            ? cardsInfo.total / 1000
            : cardsInfo.active;
    return cardsInfo;
}
export function useDashboardFinancesRollStats(): Array<IStatItem> {
    return useContext(MobXProviderContext).RootStore.dashboardStore.financesRollStats;
}
export function useDashboardFinancesRollVolumes(): string {
    return useContext(MobXProviderContext).RootStore.dashboardStore.financesRollVolume;
}
// ------------ CountryUsage ------------
export function useDashboardCountryUsageForMapList(): IDashboardCountryUsageList {
    const isBigQuery = useProfileFeatureFlagsIsBigQuery();
    const unitType = getUnitTypesSelect(DashboardUnitTypes.DATA);
    const countryUsageList = useContext(MobXProviderContext).RootStore.dashboardStore.usageData.get(
        `GET_USAGE_COUNTRY_LIST-${unitType?.id}`,
    );
    if (!countryUsageList || (!!countryUsageList && !Array.isArray(countryUsageList))) {
        return [];
    }

    if (isBigQuery) {
        return countryUsageList.map((item: IBigQueryUsage) => {
            return {
                countryId: item.country_id,
                countryName: item.name,
                countryIso3: item.iso3,
                originalUnits: item.volume,
            };
        });
    }

    return countryUsageList.map((item: ICountryUsage) => {
        return {
            countryId: item.country_id,
            countryName: item.country_name,
            countryIso3: item.country_iso3,
            originalUnits: item.original_units,
        };
    });
}

// function useCountriesFullInfoList(): Array<IJSONCountryInfo> {
//     return Object.entries(countriesFullInfo).map((country: any) => {
//         // if (country[1].alpha3 !== country[1].ioc) {
//         //     console.log(Date.now(), '-(', country[1].name, ')-- alpha3->', country[1].alpha3, `-ioc->`, country[1].ioc);
//         // }
//         return {
//             alpha3: country[1].alpha3,
//             ioc: country[1].ioc,
//             geo: {
//                 center: {
//                     lat: +country[1].geo.latitude_dec,
//                     lng: +country[1].geo.longitude_dec,
//                 },
//             },
//         };
//     });
// }

function useCountriesShortInfoList(): Array<IJSONCountryInfo> {
    return countriesShortInfo;
}

export function useDashboardCountriesUsageForMapInfo(): Array<IUseDashboardCountryUsageCountryInfo> {
    const countriesInfoList = useCountriesShortInfoList();

    const countryUsageList = useDashboardCountryUsageForMapList();
    return countryUsageList.map((c: ICountryUsageListItem) => {
        const countryInfo = countriesInfoList.find(
            (country: IJSONCountryInfo) => country.alpha3 === c.countryIso3 || country.ioc === c.countryIso3,
        );

        const border: IGoogleGeoPosition[] = (
            (countriesBorder.features
                .find((mc) => mc.id === c.countryIso3)
                ?.geometry?.coordinates.flat(1) as IJsonCountryBorderPoints) || []
        ).map((point) => {
            const p = point as IJsonCountryBorderPoint;

            return { lat: p[1], lng: p[0] };
        });

        const geo = {
            center: {
                lat: jsonFieldToNumber(countryInfo?.geo?.center.lat),
                lng: jsonFieldToNumber(countryInfo?.geo?.center.lng),
            },

            border,
        };

        return {
            id: Number(c.countryId),
            code: c.countryIso3,
            name: c.countryName,
            originalUnits: c.originalUnits,
            textUnits: c.originalUnits && byteRange1000(c.originalUnits),
            geo,
        };
    });
}

export function useDashboardCountriesUsageForMapPolygons(): IUseDashboardCountryUsageCountryPolygonsList {
    const countryUsageList = useDashboardCountryUsageForMapList();
    const polygons: IUseDashboardCountryUsageCountryPolygonsList = [];
    const colorIntensity = (x: number): number => x ** (1 / 5) / 100;

    countryUsageList.forEach((c: ICountryUsageListItem) => {
        const fillColor = `rgba(0, 0, 255, ${c.originalUnits ? colorIntensity(+c.originalUnits) : 0})`;
        const polygon: IUseDashboardCountryUsageCountryPolygon = {
            id: c.countryId || 0,
            polygonId: `${c.countryId}`,
            code: c.countryIso3,
            name: c.countryName,
            originalUnits: c.originalUnits,
            textUnits: c.originalUnits && byteRange1000(c.originalUnits),
            fillColor,
        };
        const country = countriesBorder.features.find((mc) => mc.id === c.countryIso3);

        if (country?.geometry?.coordinates.length && country?.geometry?.type === 'Polygon') {
            const border: Array<IGoogleGeoPosition> =
                country.geometry.coordinates?.[0].map((p: any) => ({ lat: p[1], lng: p[0] })) || [];
            if (border.length) {
                polygons.push({ ...polygon, geo: { border } });
            }
        } else if (country?.geometry?.coordinates.length && country?.geometry?.type === 'MultiPolygon') {
            const countryBorder = (country.geometry.coordinates.flat(1) as IJsonCountryBorder) || [];
            countryBorder.forEach((borders, index: number): void => {
                const manyBorders = (borders as IJsonCountryBorderPoints) || [];
                polygons.push({
                    ...polygon,
                    polygonId: `${c.countryId}-${index}`,
                    geo: {
                        border: manyBorders.map((b: IJsonCountryBorderPoint) => ({ lat: b[1], lng: b[0] })),
                    },
                });
            });
        } else {
            process.env.NODE_ENV === 'development' && console.log(Date.now(), 'NOT POLYGON', polygon.name, country);
        }
    });

    return polygons;
}

export function useDashboardCountryUsageChart(apiTypeId: IGetApiType, currentUnitType?: ISelect): IChartData {
    const isBigQuery = useProfileFeatureFlagsIsBigQuery();
    const countryUsageList = useDashboardUsageDataByAPI(apiTypeId, currentUnitType);
    if (!countryUsageList.length) {
        return [];
    }

    if (isBigQuery) {
        const countryUsageData: IChartData = countryUsageList
            .map((i: IBigQueryUsage) => i)
            .sort((a: IBigQueryUsage, b: IBigQueryUsage) => Number(b.volume) - Number(a.volume))
            .filter((item: IBigQueryUsage, index: number) => index < appConfig.top10)
            .map((item: IBigQueryUsage) => {
                const showUnit = showOriginalUnitByType(`${item.volume}`, currentUnitType);
                return [item.name, Number(item.volume), showUnit, item.country_id];
            });
        countryUsageData.unshift(getUsageChartTitle('Country'));

        return countryUsageData;
    }

    const countryUsageData: IChartData = countryUsageList
        .map((i: ICountryUsage) => i)
        .sort((a: ICountryUsage, b: ICountryUsage) => Number(b.original_units) - Number(a.original_units))
        .filter((item: ICountryUsage, index: number) => index < appConfig.top10)
        .map((item: ICountryUsage) => {
            const showUnit = showOriginalUnitByType(item.original_units, currentUnitType);
            return [item.country_name, Number(item.original_units), showUnit, item.country_id];
        });
    countryUsageData.unshift(getUsageChartTitle('Country'));
    return countryUsageData;
}

// ------------ CardSetUsage ------------
export function useDashboardCardSetUsageChart(apiTypeId: IGetApiType, currentUnitType?: ISelect): IChartData {
    const isBigQuery = useProfileFeatureFlagsIsBigQuery();
    const cardSetUsageList = useDashboardUsageDataByAPI(apiTypeId, currentUnitType);
    if (!cardSetUsageList.length) {
        return [];
    }

    if (isBigQuery) {
        const cardSetUsageData: IChartData = cardSetUsageList.map((item: IBigQueryUsage) => {
            const showUnit = showOriginalUnitByType(`${item.volume}`, currentUnitType);
            return [item.name, Number(item.volume), showUnit, item.card_set_id];
        });
        cardSetUsageData.unshift(getUsageChartTitle('Card Set'));
        return cardSetUsageData;
    }

    const cardSetUsageData: IChartData = cardSetUsageList.map((item: ICardSetUsage) => {
        const showUnit = showOriginalUnitByType(item.original_units, currentUnitType);
        return [item.card_set_name, Number(item.original_units), showUnit, item.card_set_id];
    });
    cardSetUsageData.unshift(getUsageChartTitle('Card Set'));
    return cardSetUsageData;
}

// ------------ Cards Usage ------------

export function useDashboardCardsUsageChart(apiTypeId: IGetApiType, currentUnitType?: ISelect): IChartData {
    const isBigQuery = useProfileFeatureFlagsIsBigQuery();
    const cardsUsageList = useDashboardUsageDataByAPI(apiTypeId, currentUnitType);
    if (!cardsUsageList.length) {
        return [];
    }

    if (isBigQuery) {
        const cardsUsageData: IChartData = cardsUsageList.map((item: IBigQueryUsage) => {
            const title = item.card_id ? `${item.name ? item.name + '-' : ''}#${item.card_id}` : 'Unidentified';

            const showUnit = showOriginalUnitByType(`${item.volume}`, currentUnitType);
            return [title, Number(item.volume), showUnit, item.card_id];
        });
        cardsUsageData.unshift(getUsageChartTitle('Card'));
        return cardsUsageData;
    }

    const cardsUsageData: IChartData = cardsUsageList.map((item: ICardsUsage) => {
        const title = item.card_printed_id
            ? item.card_printed_id?.slice(-6)
            : item.card_id
            ? `#${item.card_id}`
            : 'Unidentified';

        const showUnit = showOriginalUnitByType(item.original_units, currentUnitType);
        return [title, Number(item.original_units), showUnit, item.card_id];
    });
    cardsUsageData.unshift(getUsageChartTitle('Card'));
    return cardsUsageData;
}

// ------------ Cards Usage ------------
export function useDashboardTimelineUsageChart(): IChartData {
    const isBigQuery = useProfileFeatureFlagsIsBigQuery();
    const timelineUsageList = useContext(MobXProviderContext).RootStore.dashboardStore.timelineUsageList;
    const currentUnitType = useUiCurrentUnitType();

    if (isBigQuery) {
        const timelineUsageData = timelineUsageList.map((item: IBigQueryUsage) => {
            const showUnit = showOriginalUnitByType(`${item.volume}`, currentUnitType);
            return [item.month, Number(item.volume), showUnit];
        });
        timelineUsageData.unshift(['', 'Usage', { role: 'annotation' }]);
        return timelineUsageData;
    }

    const timelineUsageData = timelineUsageList.map((item: ITimelineUsage) => {
        const showUnit = showOriginalUnitByType(item.original_units, currentUnitType);
        return [item.month, Number(item.original_units), showUnit];
    });
    timelineUsageData.unshift(['', 'Usage', { role: 'annotation' }]);
    return timelineUsageData;
}

// ------------ CompanyUsage ------------
export function useDashboardCompanyUsageChart(): IChartData {
    const isBigQuery = useProfileFeatureFlagsIsBigQuery();
    const companyUsageList = useContext(MobXProviderContext).RootStore.dashboardStore.companyUsageList;
    const currentUnitType = useUiCurrentUnitType();

    if (isBigQuery) {
        const companyUsageData = companyUsageList.map((item: IBigQueryUsage) => {
            const showUnit = showOriginalUnitByType(`${item.volume}`, currentUnitType);
            return [item.name, Number(item.volume), showUnit, item.company_id];
        });
        companyUsageData.unshift(getUsageChartTitle('Company'));
        return companyUsageData;
    }

    const companyUsageData = companyUsageList.map((item: ICompanyUsage) => {
        const showUnit = showOriginalUnitByType(item.original_units, currentUnitType);
        return [item.company_name, Number(item.original_units), showUnit, item.company_id];
    });
    companyUsageData.unshift(getUsageChartTitle('Company'));
    return companyUsageData;
}

// ------------ ZoneUsage ------------
export function useDashboardZoneUsageChart(): IChartData {
    const isBigQuery = useProfileFeatureFlagsIsBigQuery();
    const zoneUsageList = useContext(MobXProviderContext).RootStore.dashboardStore.zoneUsageList;
    const currentUnitType = useUiCurrentUnitType();

    if (isBigQuery) {
        const zoneUsageData = zoneUsageList.map((item: IBigQueryUsage) => {
            const showUnit = showOriginalUnitByType(`${item.volume}`, currentUnitType);
            return [item.name, Number(item.volume), showUnit, item.zone_id];
        });
        zoneUsageData.unshift(getUsageChartTitle('Zone'));
        return zoneUsageData;
    }

    const zoneUsageData = zoneUsageList.map((item: IZoneUsage) => {
        const showUnit = showOriginalUnitByType(item.original_units, currentUnitType);
        return [item.zone_name, Number(item.original_units), showUnit, item.zone_id];
    });
    zoneUsageData.unshift(getUsageChartTitle('Zone'));
    return zoneUsageData;
}

// ------------ ZoneUsage ------------
export function useDashboardTelecomUsageChart(): IChartData {
    const isBigQuery = useProfileFeatureFlagsIsBigQuery();
    const telecomUsageList = useContext(MobXProviderContext).RootStore.dashboardStore.telecomUsageList;
    const currentUnitType = useUiCurrentUnitType();

    if (isBigQuery) {
        const telecomUsageData = telecomUsageList.map((item: IBigQueryUsage) => {
            const showUnit = showOriginalUnitByType(`${item.volume}`, currentUnitType);
            return [item.name, Number(item.volume), showUnit, item.operator_id];
        });
        telecomUsageData.unshift(getUsageChartTitle('Telecom'));
        return telecomUsageData;
    }

    const telecomUsageData = telecomUsageList.map((item: ITelecomUsage) => {
        const showUnit = showOriginalUnitByType(item.original_units, currentUnitType);
        return [item.operator_name, Number(item.original_units), showUnit, item.operator_id];
    });
    telecomUsageData.unshift(getUsageChartTitle('Telecom'));
    return telecomUsageData;
}
// ------------ Tools Revenue ------------

function getTextCost({ cost = '0', currencyCode = '', isRange = false }: ICost): string {
    const value = Number(cost);
    const findAbbreviation = appConfig.currency.find((c) => c.currencyCode === currencyCode);
    const textValue = isRange && value > 9999.99 ? moneyRange(value) : value.toFixed(2);
    return `${findAbbreviation ? findAbbreviation.abbreviation : ''}${textValue}`;
}

function getTextCurrenciesRevenue(revenue: Array<IRevenueItem> = []): ITextCurrenciesRevenue {
    const findUsd = revenue.find((a: IRevenueItem) => a.currency_code === 'USD');
    const findEur = revenue.find((a: IRevenueItem) => a.currency_code === 'EUR');
    return {
        textUsd: getTextCost({ cost: findUsd ? findUsd.amount : '0', currencyCode: 'USD', isRange: true }),
        textEur: getTextCost({ cost: findEur ? findEur.amount : '0', currencyCode: 'EUR', isRange: true }),
    };
}

function getCurrenciesRevenue(revenue: Array<IRevenueItem> = []): ICurrenciesRevenue {
    const findUsd = revenue.find((a: IRevenueItem) => a.currency_code === 'USD');
    const findEur = revenue.find((a: IRevenueItem) => a.currency_code === 'EUR');
    return { usd: findUsd ? Number(findUsd.amount) : 0, eur: findEur ? Number(findEur.amount) : 0 };
}
// ------------ Company Revenue ------------
export function useDashboardCompaniesRevenueIsNotBigQueryChart(): IChartData {
    const companyRevenueList = useContext(MobXProviderContext).RootStore.dashboardStore.companyRevenueIsNotBigQueryList;
    const companiesRevenueData = companyRevenueList.map((item: ICompanyRevenue) => {
        const textCost = getTextCost({ cost: item.amount, currencyCode: item.abbreviation, isRange: true });
        const { usd, eur } = getCurrenciesRevenue(item.revenue);
        const { textUsd, textEur } = getTextCurrenciesRevenue(item.revenue);
        return [item.company_name, item.company_id, Number(item.amount), textCost, usd, textUsd, eur, textEur];
    });
    companiesRevenueData.unshift(getRevenueChartTitle('Company'));
    const { isCompanyTypeIdMNOHost } = useProfile();
    return isCompanyTypeIdMNOHost ? sourceDataRemoveCosts(companiesRevenueData) : companiesRevenueData;
}
export function useDashboardCompaniesRevenueIsBigQueryChart(): IChartData {
    const { isCompanyTypeIdMNOHost } = useProfile();
    const companyRevenueList = useContext(MobXProviderContext).RootStore.dashboardStore.companyRevenueIsBigQueryList;
    const makeRowTitle: IMakeRowTitle = (i: ICompanyRevenueItem) => {
        return [i.name ? i.name : i.companyId ? `#${i.companyId}` : 'Unidentified', i.companyId ? i.companyId : ''];
    };
    return mapRevenues2Chart({
        isHideCost: isCompanyTypeIdMNOHost,
        makeRowTitle: makeRowTitle,
        revenues: companyRevenueList,
        title: 'Company',
    });
}

export function useDashboardCompaniesRevenueChart(): IChartData {
    const isBigQuery = useProfileFeatureFlagsIsBigQuery();
    const dataIsBigQuery = useDashboardCompaniesRevenueIsBigQueryChart();
    const dataIsNotBigQuery = useDashboardCompaniesRevenueIsNotBigQueryChart();
    return isBigQuery ? dataIsBigQuery : dataIsNotBigQuery;
}

// ------------ CardSet Revenue ------------
export function useDashboardCardSetRevenueIsNotBigQueryChart(): IChartData {
    const cardSetRevenueList = useContext(MobXProviderContext).RootStore.dashboardStore.cardSetRevenueIsNotBigQueryList;
    const cardSetRevenueData = cardSetRevenueList.map((item: ICardSetRevenue) => {
        const textCost = getTextCost({ cost: item.amount, currencyCode: item.abbreviation, isRange: true });
        const { usd, eur } = getCurrenciesRevenue(item.revenue);
        const { textUsd, textEur } = getTextCurrenciesRevenue(item.revenue);
        return [item.card_set_name, item.card_set_id, Number(item.amount), textCost, usd, textUsd, eur, textEur];
    });
    cardSetRevenueData.unshift(getRevenueChartTitle('Card Set'));
    const { isCompanyTypeIdMNOHost } = useProfile();
    return isCompanyTypeIdMNOHost ? sourceDataRemoveCosts(cardSetRevenueData) : cardSetRevenueData;
}

export function useDashboardCardSetRevenueIsBigQueryChart(): IChartData {
    const { isCompanyTypeIdMNOHost } = useProfile();
    const cardSetRevenueList = useContext(MobXProviderContext).RootStore.dashboardStore.cardSetRevenueIsBigQueryList;
    const makeRowTitle: IMakeRowTitle = (i: ICardSetRevenueItem) => {
        return [
            i.name ? i.name : i.cardSetId ? i.cardSetId.toString() : 'Unidentified',
            i.cardSetId ? i.cardSetId : '',
        ];
    };
    return mapRevenues2Chart({
        isHideCost: isCompanyTypeIdMNOHost,
        makeRowTitle: makeRowTitle,
        revenues: cardSetRevenueList,
        title: 'Card Set',
    });
}

export function useDashboardCardSetRevenueChart(): IChartData {
    const isBigQuery = useProfileFeatureFlagsIsBigQuery();
    const dataIsBigQuery = useDashboardCardSetRevenueIsBigQueryChart();
    const dataIsNotBigQuery = useDashboardCardSetRevenueIsNotBigQueryChart();
    return isBigQuery ? dataIsBigQuery : dataIsNotBigQuery;
}
// ------------ EditPurchaseZoneRate Revenue ------------
export function useDashboardCountryRevenueIsNotBigQueryChart(): IChartData {
    const countryRevenueList = useContext(MobXProviderContext).RootStore.dashboardStore.countryRevenueIsNotBigQueryList;
    const countryRevenueData = countryRevenueList.map((item: ICountryRevenue) => {
        const textCost = getTextCost({ cost: item.amount, currencyCode: item.abbreviation, isRange: true });
        const { usd, eur } = getCurrenciesRevenue(item.revenue);
        const { textUsd, textEur } = getTextCurrenciesRevenue(item.revenue);
        return [item.country_name, item.country_id, Number(item.amount), textCost, usd, textUsd, eur, textEur];
    });
    countryRevenueData.unshift(getRevenueChartTitle('Country'));
    const { isCompanyTypeIdMNOHost } = useProfile();
    return isCompanyTypeIdMNOHost ? sourceDataRemoveCosts(countryRevenueData) : countryRevenueData;
}

export function useDashboardCountryRevenueIsBigQueryChart(): IChartData {
    const { isCompanyTypeIdMNOHost } = useProfile();
    const countryRevenueList = useContext(MobXProviderContext).RootStore.dashboardStore.countryRevenueIsBigQueryList;
    const makeRowTitle: IMakeRowTitle = (i: ICountryRevenueItem) => {
        return [
            i.name ? i.name : i.iso3 ? i.iso3 : i.countryId ? `#${i.countryId}` : 'Unidentified',
            i.countryId ? i.countryId : '',
        ];
    };
    return mapRevenues2Chart({
        isHideCost: isCompanyTypeIdMNOHost,
        makeRowTitle: makeRowTitle,
        revenues: countryRevenueList,
        title: 'Country',
    });
}
export function useDashboardCountryRevenueChart(): IChartData {
    const isBigQuery = useProfileFeatureFlagsIsBigQuery();
    const dataIsBigQuery = useDashboardCountryRevenueIsBigQueryChart();
    const dataIsNotBigQuery = useDashboardCountryRevenueIsNotBigQueryChart();
    return isBigQuery ? dataIsBigQuery : dataIsNotBigQuery;
}

// ------------ Cards Revenue ------------
export function useDashboardCardsRevenueIsNotBigQueryChart(): IChartData {
    const cardsRevenueList = useContext(MobXProviderContext).RootStore.dashboardStore.cardsRevenueIsNotBigQueryList;
    const cardsRevenueData = cardsRevenueList.map((item: ICardsRevenue) => {
        const textCost = getTextCost({ cost: item.amount, currencyCode: item.abbreviation, isRange: true });
        const { usd, eur } = getCurrenciesRevenue(item.revenue);
        const { textUsd, textEur } = getTextCurrenciesRevenue(item.revenue);
        const title = item.card_printed_id
            ? item.card_printed_id?.slice(-6)
            : item.card_id
            ? `#${item.card_id}`
            : 'Unidentified';
        return [title, item.card_id, Number(item.amount), textCost, usd, textUsd, eur, textEur];
    });
    cardsRevenueData.unshift(getRevenueChartTitle('Card'));
    const { isCompanyTypeIdMNOHost } = useProfile();
    return isCompanyTypeIdMNOHost ? sourceDataRemoveCosts(cardsRevenueData) : cardsRevenueData;
}

export function useDashboardCardsRevenueIsBigQueryChart(): IChartData {
    const { isCompanyTypeIdMNOHost } = useProfile();
    const cardsRevenueList = useContext(MobXProviderContext).RootStore.dashboardStore.cardsRevenueIsBigQueryList;

    const makeRowTitle: IMakeRowTitle = (i: ICardsRevenueItem) => {
        return [
            // i.printedId ? i.printedId.slice(-6) : i.name ? i.name : i.eid ? i.eid.slice(-6) : 'Unidentified',
            i.cardId && i.name
                ? `${i.name}-#${i.cardId}`
                : i.name
                ? i.name
                : i.printedId
                ? i.printedId.slice(-6)
                : i.eid
                ? i.eid.slice(-6)
                : i.cardId
                ? `#${i.cardId}`
                : 'Unidentified',
            i.cardId ? i.cardId : '',
        ];
    };
    return mapRevenues2Chart({
        isHideCost: isCompanyTypeIdMNOHost,
        makeRowTitle: makeRowTitle,
        revenues: cardsRevenueList,
        title: 'Card',
    });
}

export const useDashboardCardsRevenueChart = (): IChartData => {
    const isBigQuery = useProfileFeatureFlagsIsBigQuery();
    const dataIsBigQuery = useDashboardCardsRevenueIsBigQueryChart();
    const dataIsNotBigQuery = useDashboardCardsRevenueIsNotBigQueryChart();
    return isBigQuery ? dataIsBigQuery : dataIsNotBigQuery;
};

// ------------ Zone Revenue ------------
export function useDashboardZoneRevenueIsNotBigQueryChart(): IChartData {
    const zoneRevenueList = useContext(MobXProviderContext).RootStore.dashboardStore.zoneRevenueIsNotBigQueryList;
    const zoneRevenueData = zoneRevenueList.map((item: IZoneRevenue) => {
        const textCost = getTextCost({ cost: item.amount, currencyCode: item.abbreviation, isRange: true });
        const { usd, eur } = getCurrenciesRevenue(item.revenue);
        const { textUsd, textEur } = getTextCurrenciesRevenue(item.revenue);
        return [item.zone_name, item.zone_id, Number(item.amount), textCost, usd, textUsd, eur, textEur];
    });
    zoneRevenueData.unshift(getRevenueChartTitle('Zone'));
    const { isCompanyTypeIdMNOHost } = useProfile();
    return isCompanyTypeIdMNOHost ? sourceDataRemoveCosts(zoneRevenueData) : zoneRevenueData;
}

export function useDashboardZoneRevenueIsBigQueryChart(): IChartData {
    const { isCompanyTypeIdMNOHost } = useProfile();
    const zoneRevenueList = useContext(MobXProviderContext).RootStore.dashboardStore.zoneRevenueIsBigQueryList;
    const makeRowTitle: IMakeRowTitle = (i: ITimelineZoneItem) => {
        return [i.name ? i.name : i.zoneId ? `#${i.zoneId}` : 'Unidentified', i.zoneId ? i.zoneId : ''];
    };
    return mapRevenues2Chart({
        isHideCost: isCompanyTypeIdMNOHost,
        makeRowTitle: makeRowTitle,
        revenues: zoneRevenueList,
        title: 'Zone',
    });
}

export const useDashboardZoneRevenueChart = (): IChartData => {
    const isBigQuery = useProfileFeatureFlagsIsBigQuery();
    const dataIsBigQuery = useDashboardZoneRevenueIsBigQueryChart();
    const dataIsNotBigQuery = useDashboardZoneRevenueIsNotBigQueryChart();
    return isBigQuery ? dataIsBigQuery : dataIsNotBigQuery;
};

// ------------ Operator/Telecom Revenue ------------
export function useDashboardTelecomRevenueIsNotBigQueryChart(): IChartData {
    const telecomRevenueList = useContext(MobXProviderContext).RootStore.dashboardStore.telecomRevenueIsNotBigQueryList;
    const telecomRevenueData = telecomRevenueList.map((item: ITelecomRevenue) => {
        const textCost = getTextCost({ cost: item.amount, currencyCode: item.abbreviation, isRange: true });
        const { usd, eur } = getCurrenciesRevenue(item.revenue);
        const { textUsd, textEur } = getTextCurrenciesRevenue(item.revenue);
        return [item.operator_name, item.operator_id, Number(item.amount), textCost, usd, textUsd, eur, textEur];
    });
    telecomRevenueData.unshift(getRevenueChartTitle('Operator'));
    const { isCompanyTypeIdMNOHost } = useProfile();
    return isCompanyTypeIdMNOHost ? sourceDataRemoveCosts(telecomRevenueData) : telecomRevenueData;
}

export function useDashboardTelecomRevenueIsBigQueryChart(): IChartData {
    const { isCompanyTypeIdMNOHost } = useProfile();
    const telecomRevenueList = useContext(MobXProviderContext).RootStore.dashboardStore.telecomRevenueIsBigQueryList;
    const makeRowTitle: IMakeRowTitle = (i: IOperatorRevenueItem) => {
        return [i.name ? i.name : i.operatorId ? `#${i.operatorId}` : 'Unidentified', i.operatorId ? i.operatorId : ''];
    };
    return mapRevenues2Chart({
        isHideCost: isCompanyTypeIdMNOHost,
        makeRowTitle: makeRowTitle,
        revenues: telecomRevenueList,
        title: 'Operator',
    });
}

export const useDashboardTelecomRevenueChart = (): IChartData => {
    const isBigQuery = useProfileFeatureFlagsIsBigQuery();
    const dataIsBigQuery = useDashboardTelecomRevenueIsBigQueryChart();
    const dataIsNotBigQuery = useDashboardTelecomRevenueIsNotBigQueryChart();
    return isBigQuery ? dataIsBigQuery : dataIsNotBigQuery;
};

// ------------ Timeline Revenue ------------
export function useDashboardTimelineRevenueIsNotBigQueryChart(): IChartData {
    const timelineRevenueList =
        useContext(MobXProviderContext).RootStore.dashboardStore.timelineRevenueIsNotBigQueryList;
    const timelineRevenueData = timelineRevenueList.map((item: ITimelineRevenue) => {
        const textCost = getTextCost({ cost: item.amount, currencyCode: item.abbreviation, isRange: true });
        const { usd, eur } = getCurrenciesRevenue(item.revenue);
        const { textUsd, textEur } = getTextCurrenciesRevenue(item.revenue);
        return [item.month, Number(item.amount), textCost, usd, textUsd, eur, textEur];
    });
    timelineRevenueData.unshift(getRevenueChartTitle('Country'));
    const { isCompanyTypeIdMNOHost } = useProfile();
    return isCompanyTypeIdMNOHost ? sourceDataRemoveCosts(timelineRevenueData) : timelineRevenueData;
}

export function useDashboardTimelineRevenueIsBigQueryChart(): IChartData {
    const { isCompanyTypeIdMNOHost } = useProfile();
    const timelineRevenueList = useContext(MobXProviderContext).RootStore.dashboardStore.timelineRevenueIsBigQueryList;
    const makeRowTitle: IMakeRowTitle = (i: ITimelineRevenueItem) => {
        return [
            i.month ? i.month : i.startDate ? i.startDate : 'Unidentified',
            i.month ? i.month : i.startDate ? i.startDate : '',
        ];
    };
    return mapRevenues2Chart({
        isHideCost: isCompanyTypeIdMNOHost,
        makeRowTitle: makeRowTitle,
        revenues: timelineRevenueList,
        title: 'Revenue',
    });
}

export const useDashboardTimelineRevenueChart = (): IChartData => {
    const isBigQuery = useProfileFeatureFlagsIsBigQuery();
    const dataIsBigQuery = useDashboardTimelineRevenueIsBigQueryChart();
    const dataIsNotBigQuery = useDashboardTimelineRevenueIsNotBigQueryChart();
    return isBigQuery ? dataIsBigQuery : dataIsNotBigQuery;
};

export function useDashboardFilterParamsFinance(): IFilterParams {
    return useContext(MobXProviderContext).RootStore.dashboardStore.filterParamsFinance;
}

export function useDashboardDashboardType(): string {
    return useContext(MobXProviderContext).RootStore.dashboardStore.dashboardType;
}
export function useDashboardOverlayView(): IOverlayView {
    return useContext(MobXProviderContext).RootStore.dashboardStore.mapOverlayView;
}

export function useDashboardGraphTitleByUnitType(type?: string): string {
    switch (type) {
        case 'Data':
            return tI('by Data Usage');
        case 'SMS':
            return tI('by Sms Usage');
        case 'Voice':
            return tI('by Voice Usage');
        default:
            return tI('by Data Usage');
    }
}

export function useDashboardUnitTypeObj(unitType: DashboardUnitTypes): ISelect | undefined {
    const dashboardType = useDashboardDashboardType();
    const mainType = useUnitTypeByType(unitType);
    const currentType = useUiCurrentUnitType();
    return dashboardType === 'Main' ? mainType : currentType;
}
