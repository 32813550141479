import React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import cx from 'classnames';
import { IActivityLogItem } from '../../interfaces';
import { useActivityLogTable, useUiIsLoading } from '../../hooks';
import { tF } from '../../translate';
import { Catcher, LightSearch, LinkComponent, TableRawDataField } from '../../Components';
import {
    ActivityLogFieldValue,
    ActivityLogUpdateInfo,
    ActivityLogViewInfo,
    ActivityLogViewInfoRecords,
} from './Components';
import '../../customStyle/table.less';
import Styles from '../styles/m_viewStyles.less';

export const ActivityLogTableList: React.FC = observer(() => {
    const isLoading = useUiIsLoading();
    const list = useActivityLogTable();

    const tableColumns = [
        {
            title: 'Action',
            dataIndex: 'actionLight',
        },
        {
            title: 'Entity Id',
            dataIndex: 'entityId',
        },
        {
            title: tF('Created at'),
            dataIndex: 'createdAt',
        },
        {
            title: 'Performed by',
            dataIndex: 'performedBy',
        },
        {
            title: 'RAW Data',
            dataIndex: 'rawData',
        },
    ];
    const tableData = list.map((log: IActivityLogItem) => {
        const { id, action, entity, raw, userData, userId, logableId } = log;
        return {
            ...log,
            key: id,
            actionLight: <LightSearch text={action} />,
            entityId: (
                <span>
                    <LightSearch text={entity} />
                    {logableId ? (
                        <span>
                            <span>: </span>
                            <ActivityLogFieldValue entity={entity} value={logableId} prop={'id'} />
                        </span>
                    ) : null}
                </span>
            ),
            performedBy: (
                <LinkComponent
                    currentListComponent={'UsersList'}
                    id={userId}
                    isLight
                    name={
                        userData?.firstName && userData?.lastName
                            ? userData?.firstName + ' ' + userData?.lastName
                            : 'Unknown user '
                    }
                />
            ),
            rawData: <TableRawDataField data={raw} isLight />,
        };
    });

    const expandedRowRender = (log: IActivityLogItem): JSX.Element => {
        const { action } = log;

        switch (action) {
            case 'created':
            case 'destroyed':
            case 'impersonation/end':
            case 'impersonation/start':
                return <ActivityLogViewInfo log={log} />;
            case 'bulk_destroyed':
            case 'imported':
                return <ActivityLogViewInfoRecords log={log} />;
            case 'assigned':
            case 'updated':
                return <ActivityLogUpdateInfo log={log} />;
            default:
                return <div>Unknown action</div>;
        }
    };

    const expandable = {
        expandedRowRender: expandedRowRender,
    };

    return (
        <Catcher>
            <div className={cx(Styles.tablePlace)}>
                <Table
                    className={'table-theme'}
                    columns={tableColumns}
                    expandable={expandable}
                    dataSource={tableData}
                    loading={isLoading}
                    pagination={false}
                    size={'middle'}
                />
            </div>
        </Catcher>
    );
});
