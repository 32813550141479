import React from 'react';
import { Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Catcher } from '../';
import { history } from '../../Pages/history';
import { tB } from '../../translate';
import Styles from '../styles/m_itemCardStyles.less';

type ICancelButton = {
    dataTest?: string;
    isDisable?: boolean;
    onClick?: () => void;
};

export const CancelButton: React.FC<ICancelButton> = ({ dataTest, isDisable, onClick }: ICancelButton) => {
    const goBack = (): void => {
        history.goBack();
    };

    return (
        <Catcher>
            <Button
                className={Styles.mainButton}
                data-test={dataTest}
                disabled={isDisable}
                icon={<ArrowLeftOutlined />}
                onClick={onClick ? onClick : goBack}
                type="primary"
            >
                {tB('Cancel')}
            </Button>
        </Catcher>
    );
};
