import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import { Catcher, FileTypeIcons, LinkComponent, ReportStatusIcons, StopProcessButton } from '../../';
import { appConfig } from '../../../settings';
import { apiConfig } from '../../../REST';
import { IExportType, IReportStatus, IReportsList } from '../../../interfaces';
import { useReportsTable } from '../../../hooks';
import { updateReportsList } from '../../../middleware';
import { tF, tI } from '../../../translate';
import '../../../customStyle/table.less';
import StylesIcon from '../../IconComponents/m_styles.less';
import StylesLink from '../../LinkComponent/m_styles.less';
import Styles from '../../styles/m_viewStyles.less';

export const ReportsTableList: React.FC = observer(() => {
    const list = useReportsTable();
    useEffect(() => {
        const interval = setInterval(() => {
            updateReportsList();
        }, appConfig.updateViewInterval);
        return (): void => clearInterval(interval);
    }, []);

    const tableColumns = [
        {
            title: tF('Status'),
            dataIndex: 'statusIcon',
        },
        {
            title: tF('Created at'),
            dataIndex: 'createdAt',
        },
        {
            title: tF('User'),
            dataIndex: 'userLink',
        },
        {
            title: tF('File'),
            dataIndex: 'fileLink',
        },
    ];
    const tableData = list.map((r: IReportsList) => {
        const fileType = r.fileType as IExportType;
        const title = fileType ? `${tI('Download')} ${fileType.toUpperCase()} ${tI('file')}` : '';
        const IconType = fileType ? <FileTypeIcons fileType={fileType} className={StylesIcon.icon} /> : null;
        const showStopButton = !['done', 'failed', 'canceled'].includes(r.status as IReportStatus);
        return {
            key: r.id,
            statusIcon: <ReportStatusIcons status={r.status} />,
            ...r,
            userLink: <LinkComponent currentListComponent={'UsersList'} id={r.userId} isLight name={r.name} />,
            fileLink: r.file ? (
                <a className={StylesLink.linkItem} href={`${apiConfig.apiUrl()}${r.file}`} title={title}>
                    {IconType}
                </a>
            ) : (
                showStopButton && <StopProcessButton id={r.id} />
            ),
        };
    });

    const expandedRowRender = (record: IReportsList): JSX.Element => {
        return (
            <div className={Styles.expandedRow}>
                <div className={Styles.expandedRowLine}>
                    <div className={Styles.expandedRowLineLabel}>{tF('User')}</div>
                    <div className={Styles.expandedRowLineValue}>
                        <LinkComponent
                            currentListComponent={'UsersList'}
                            id={record.userId}
                            isLight
                            name={record.name}
                        />
                    </div>
                </div>
                {record.startDate ? (
                    <div className={Styles.expandedRowLine}>
                        <div className={Styles.expandedRowLineLabel}>{tF('Payload From')}</div>
                        <div className={Styles.expandedRowLineValue}>{record.startDate}</div>
                    </div>
                ) : null}
                {record.endDate ? (
                    <div className={Styles.expandedRowLine}>
                        <div className={Styles.expandedRowLineLabel}>{tF('Payload To')}</div>
                        <div className={Styles.expandedRowLineValue}>{record.endDate}</div>
                    </div>
                ) : null}
                {record.cardSetId ? (
                    <div className={Styles.expandedRowLine}>
                        <div className={Styles.expandedRowLineLabel}>{tF('Card Set')}</div>
                        <div className={Styles.expandedRowLineValue}>
                            <LinkComponent
                                currentListComponent={'CardSetsList'}
                                id={record.cardSetId}
                                name={record.cardSet}
                            />
                        </div>
                    </div>
                ) : null}
                {record.companyId ? (
                    <div className={Styles.expandedRowLine}>
                        <div className={Styles.expandedRowLineLabel}>{tF('Company')}</div>

                        <div className={Styles.expandedRowLineValue}>
                            <LinkComponent
                                currentListComponent={'CompaniesList'}
                                id={record.companyId}
                                name={record.company}
                            />
                        </div>
                    </div>
                ) : null}
            </div>
        );
    };

    const expandable = {
        expandedRowRender: expandedRowRender,
    };

    return (
        <Catcher>
            <div className={Styles.tablePlace}>
                <Table
                    className={'table-theme'}
                    columns={tableColumns}
                    dataSource={tableData}
                    expandable={expandable}
                    pagination={false}
                    size={'middle'}
                />
            </div>
        </Catcher>
    );
});
