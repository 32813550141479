import React from 'react';
import { Button, Tooltip } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { addNotification, deleteReport, setUiForcedUpdateTime } from '../../middleware';
import { tF } from '../../translate';
import Styles from '../ReportsPage/Components/m_styles.less';

type StopProcessButtonProps = {
    id: string;
};

export const StopProcessButton = ({ id }: StopProcessButtonProps): JSX.Element => {
    const handleStopProcess = async (): Promise<void> => {
        const { isError } = await deleteReport(id);
        if (isError) {
            addNotification({
                type: 'error',
                message: 'An error has occurred while canceling the process',
                isUsersError: true,
            });
        }
        setUiForcedUpdateTime();
    };

    return (
        <Tooltip title={tF('Stop this process')} placement="bottom">
            <Button
                className={Styles.stopButton}
                size="small"
                danger
                icon={<CloseOutlined />}
                onClick={handleStopProcess}
            />
        </Tooltip>
    );
};
