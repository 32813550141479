import React from 'react';
import { DatePicker } from 'antd';
import cx from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import { RangeValue } from 'rc-picker/lib/interface';
import { appConfig } from '../../../settings';
import { IDateRange, IFilterParams } from '../../../interfaces';
import { getAvailableMinDate, getDefaultDateRate } from '../../../instruments';
import { tCustom, tI } from '../../../translate';
import { Catcher } from '../../Catcher';
import Styles from '../m_styles.less';

interface IFilterDateRangeProps {
    dateRange?: IDateRange;
    maximumMonths?: number;
    filterParams?: IFilterParams;
    onChange?: (val: IDateRange) => void;
    labelSpacing?: number | string;
}

export const FilterDateRange: React.FC<IFilterDateRangeProps> = ({
    onChange,
    filterParams,
    dateRange,
    maximumMonths,
    labelSpacing = 0,
}: IFilterDateRangeProps) => {
    const { RangePicker } = DatePicker;
    const startDate = dateRange?.startDate ? dayjs(dateRange.startDate, appConfig.formatDate) : null;
    const endDate = dateRange?.endDate ? dayjs(dateRange.endDate, appConfig.formatDate) : null;
    const availableMinDate = getAvailableMinDate();
    const toDate = dayjs();

    const _setDateRange = (_: RangeValue<Dayjs>, dates: Array<string>): void => {
        const { startDate, endDate } = getDefaultDateRate(filterParams);
        onChange && onChange({ startDate: dates[0] || startDate, endDate: dates[1] || endDate });
    };

    const disabledDate = (current: Dayjs): boolean => {
        if (filterParams?.isDateRangeMinDayOn && current.isBefore(availableMinDate)) {
            return true;
        } else if (filterParams?.isDateRangeMaxDayToDay && current.isAfter(toDate)) {
            return true;
        }
        return false;
    };

    const lastXMonthName = tCustom(`Last X month`, { month: maximumMonths ?? appConfig.maximumMonthsForRequest });

    return (
        <Catcher>
            <div className={Styles.labelFieldPrefix} style={{ padding: labelSpacing }}>
                {tI('Date Filter')}
            </div>
            <RangePicker
                className={cx(Styles.fieldFull, Styles.dateRange)}
                disabledDate={disabledDate}
                onChange={_setDateRange}
                value={[startDate, endDate]}
                placeholder={[tI('from'), tI('to')]}
                ranges={{
                    [tI('Previous Month')]: [
                        dayjs().subtract(1, 'months').startOf('month'),
                        dayjs().subtract(1, 'months').endOf('month'),
                    ],
                    [tI('Today')]: [dayjs(), dayjs()],
                    [tI('This Week')]: [
                        dayjs().startOf('week'),
                        dayjs().endOf(filterParams?.isDateRangeMaxDayToDay ? 'day' : 'week'),
                    ],
                    [tI('This Month')]: [
                        dayjs().startOf('month'),
                        dayjs().endOf(filterParams?.isDateRangeMaxDayToDay ? 'day' : 'month'),
                    ],
                    [lastXMonthName]: [
                        dayjs().subtract(appConfig.maximumMonthsForRequest, 'M').startOf('M'),
                        dayjs().endOf(filterParams?.isDateRangeMaxDayToDay ? 'day' : 'month'),
                    ],
                }}
            />
        </Catcher>
    );
};
