/* eslint-disable */
import { DashboardStore } from '../stores/dashboard';
import {
    abortSelectedFetch,
    cleanCompaniesStore,
    cleanUnitTypeUserStore,
    getAllUnitTypesSelect,
    getCardSetsSelect,
    getCompaniesSelect,
    getFeatureFlagIsBigQuery,
    getOperatorsSelect,
    getUnitTypes,
    getUnitTypesSelect,
} from './';
import dayjs from 'dayjs';
import { appConfig } from '../settings';
import { IBodyItemResponse, IBodyRanksResponse, apiManager } from '../REST';
import {
    DashboardUnitTypes,
    IActivityRollIsNotBigQuery,
    IBigQueryActivityUsage,
    IBigQueryUsage,
    ICardSetRevenue,
    ICardSetRevenueItem,
    ICardSetUsage,
    ICardsRevenue,
    ICardsRevenueItem,
    ICardsUsage,
    ICompanyRevenue,
    ICompanyRevenueItem,
    ICompanyUsage,
    ICountryRevenue,
    ICountryRevenueItem,
    IDashboardType,
    IFinancesRollAPI,
    IFinancesRollCards,
    IFinancesUsageAPI,
    IGetApiType,
    IOperatorRevenueItem,
    IOverlayView,
    IParamsList,
    IRankCardRevenueItemAPI,
    IRankCardSetRevenueItemAPI,
    IRankCompanyRevenueItemAPI,
    IRankCountryRevenueItemAPI,
    IRankOperatorRevenueItemAPI,
    IRankTimelineRevenueItemAPI,
    IRankZoneRevenueItemAPI,
    ISelect,
    IStatItem,
    ITelecomRevenue,
    ITelecomUsage,
    ITimelineRevenue,
    ITimelineRevenueItem,
    ITimelineUsage,
    ITimelineZoneItem,
    IUsageActivityRoll,
    IUsageActivityRollAPI,
    IUsageMap,
    IZoneRevenue,
    IZoneUsage,
} from '../interfaces';
import {
    getApiTypeWithUnitType,
    mapApi2UiField,
    mapApi2UiList,
    mapRank2RevenueItem,
    mapStatApiItems,
    sortByString,
} from '../instruments';

// ------------ Activity Usage ------------
type ActiveUsageResponseTypeArr = ActiveUsageResponseType[];
type ActiveUsageResponseType = {
    resp: IBodyItemResponse;
    attributes: { name: string; value?: string | undefined; disabled?: boolean | undefined };
};
type ActiveUsageBody = {
    [id: string]: IActivityRollIsNotBigQuery | string | undefined | number;
};

function setDashboardActivityRollIsNotBigQueryRoll(data: IActivityRollIsNotBigQuery): void {
    const DashboardStoreInstance = DashboardStore.getInstance();
    DashboardStoreInstance.setActivityRollIsNotBigQuery(data);
}

function setDashboardActivityUsage(data: IUsageActivityRoll): void {
    const DashboardStoreInstance = DashboardStore.getInstance();
    DashboardStoreInstance.setUsageActivity(data);
}

function transformBigQueryToActiveUsage(resp: IBodyItemResponse): IActivityRollIsNotBigQuery {
    const biqQueryResp = resp as IBigQueryActivityUsage;
    const { cards_ratio, cards_count, stats_by_unit_type } = biqQueryResp;
    const { ...statsByUnitType } = stats_by_unit_type[0] || {};

    return {
        uniq_cards: cards_count,
        uniq_cards_ratio: `${cards_ratio}`,
        cost: `${statsByUnitType.cost}`,
        cost_ratio: `${statsByUnitType.cost_ratio}`,
        original_units: `${statsByUnitType.volume}`,
        original_units_ratio: `${statsByUnitType.volume_ratio}`,
    };
}

export async function getActivityRollAsExternal(params: IParamsList = {}): Promise<void> {
    const isBigQuery = getFeatureFlagIsBigQuery();
    const { resp } = await apiManager.getApi('GET_ACTIVITY_ROLL', { searchParamsList: params, isBigQuery });
    const { data } = resp as IBodyItemResponse;

    if (isBigQuery) {
        setDashboardActivityRollIsNotBigQueryRoll(transformBigQueryToActiveUsage(resp as IBodyItemResponse));
    } else {
        data?.attributes && setDashboardActivityRollIsNotBigQueryRoll(data.attributes);
    }
}

function formActiveUsageAllUnitTypes(responses: ActiveUsageResponseTypeArr): IActivityRollIsNotBigQuery {
    let activityUsage: ActiveUsageBody = {};

    responses.forEach(({ attributes, resp }) => {
        if (attributes?.name === 'Data') {
            activityUsage = {
                ...activityUsage,
                ...resp?.data?.attributes,
            };
        } else {
            const formedName = attributes?.name?.toLowerCase();
            const formedNameRatio = `${formedName}_ratio`;

            activityUsage[formedName] = resp?.data?.attributes?.original_units;
            activityUsage[formedNameRatio] = resp?.data?.attributes?.original_units_ratio;
        }
    });

    return activityUsage;
}

export const getDashboardActivityRollIsNotBigQueryRoll = async (): Promise<void> => {
    const select = getAllUnitTypesSelect();
    if (select.length) {
        const responses: ActiveUsageResponseTypeArr = await Promise.all(
            select.map(async ({ id, value, ...attributes }: ISelect) => {
                const response = await apiManager.getApi('GET_ACTIVITY_ROLL', {
                    searchParamsList: { unitTypeIds: id },
                });
                const resp = response?.resp as IBodyItemResponse;

                return {
                    resp,
                    attributes,
                };
            }),
        );
        setDashboardActivityRollIsNotBigQueryRoll(formActiveUsageAllUnitTypes(responses));
    }
};
export const getDashboardActivityRollIsBigQueryRoll = async (): Promise<void> => {
    const response = await apiManager.getApi('GET_ACTIVITY_ROLL');
    const resp = response.resp as IUsageActivityRollAPI;
    setDashboardActivityUsage({
        ...mapApi2UiField(resp),
        costs: mapStatApiItems(resp.costs),
        statsByUnitType: mapApi2UiList(resp.stats_by_unit_type),
    });
};
export async function getDashboardActivityRoll(): Promise<void> {
    const isBigQuery = getFeatureFlagIsBigQuery();
    if (isBigQuery) {
        await getDashboardActivityRollIsBigQueryRoll();
    } else {
        await getDashboardActivityRollIsNotBigQueryRoll();
    }
}
// ------------ Finances Roll ------------
function setDashboardFinancesIsNotBigQueryUsage(data: IFinancesUsageAPI): void {
    const DashboardStoreInstance = DashboardStore.getInstance();
    DashboardStoreInstance.setFinancesIsNotBigQueryUsage(data);
}

export async function getDashboardFinancesIsNotBigQueryUsage(params: IParamsList): Promise<void> {
    const { resp } = await apiManager.getApi('GET_FINANCE_ROLL', { searchParamsList: params });
    const { data } = resp as IBodyItemResponse;
    data?.attributes && setDashboardFinancesIsNotBigQueryUsage(data.attributes);
}

function setDashboardFinancesRollCards(cards: IFinancesRollCards): void {
    const DashboardStoreInstance = DashboardStore.getInstance();
    DashboardStoreInstance.setFinancesRollCards(cards);
}
function setDashboardFinancesRollStats(data: Array<IStatItem>): void {
    const DashboardStoreInstance = DashboardStore.getInstance();
    DashboardStoreInstance.setFinancesRollStats(data);
}

function setDashboardFinancesRollVolume(volumes: string): void {
    const DashboardStoreInstance = DashboardStore.getInstance();
    DashboardStoreInstance.setFinancesRollVolume(volumes);
}

export async function getDashboardFinancesIsBigQueryUsage(params: IParamsList): Promise<void> {
    const { resp } = await apiManager.getApi('GET_FINANCE_ROLL', { searchParamsList: params, isBigQuery: true });
    const { cards, costs_and_revenues, volume } = resp as IFinancesRollAPI;
    setDashboardFinancesRollCards({ active: cards?.active || 0, inactive: cards?.inactive || 0 });
    setDashboardFinancesRollStats(mapStatApiItems(costs_and_revenues));
    setDashboardFinancesRollVolume(volume ? volume : '0');
}

export async function getDashboardFinancesUsage(params: IParamsList): Promise<void> {
    const isBigQuery = getFeatureFlagIsBigQuery();
    if (isBigQuery) {
        await getDashboardFinancesIsBigQueryUsage(params);
    } else {
        await getDashboardFinancesIsNotBigQueryUsage(params);
    }
}

// ------------ CountryUsage ------------

export async function getDashboardCountryUsageList(params: IParamsList): Promise<void> {
    const isBigQuery = getFeatureFlagIsBigQuery();
    const unitTypeId = params?.unitTypeIds || getUnitTypesSelect(DashboardUnitTypes.DATA)?.id;
    const usageDataName = getApiTypeWithUnitType(`GET_USAGE_COUNTRY_LIST`, unitTypeId);
    const { resp } = await apiManager.getApi(
        'GET_USAGE_COUNTRY_LIST',
        { searchParamsList: params, isBigQuery },
        undefined,
        undefined,
        usageDataName,
    );

    if (isBigQuery) {
        setDashboardUsageData(usageDataName, resp as Array<IBigQueryUsage>);
    } else {
        const { data } = resp as IBodyRanksResponse;
        const ranks = data?.attributes?.ranks || [];
        // .sort((a: ICountryUsage, b: ICountryUsage) => Number(b.original_units) - Number(a.original_units))
        // .filter((item: ICountryUsage, index: number) => index < appConfig.top10) || [];
        setDashboardUsageData(usageDataName, ranks);
    }
}

// ------------ CardSetUsage ------------
export async function getDashboardCardSetUsageList(params: IParamsList): Promise<void> {
    const isBigQuery = getFeatureFlagIsBigQuery();
    const unitTypeId = params?.unitTypeIds || getUnitTypesSelect(DashboardUnitTypes.DATA)?.id;
    const usageDataName = getApiTypeWithUnitType(`GET_USAGE_CARD_SETS_LIST`, unitTypeId);
    const { resp } = await apiManager.getApi(
        'GET_USAGE_CARD_SETS_LIST',
        { searchParamsList: params, isBigQuery },
        undefined,
        undefined,
        usageDataName,
    );

    if (isBigQuery) {
        const data = resp as Array<IBigQueryUsage>;
        const ranks =
            data
                .sort((a: IBigQueryUsage, b: IBigQueryUsage) => Number(b.volume) - Number(a.volume))
                .filter((_: ICardsUsage, index: number) => index < appConfig.top5) || [];

        setDashboardUsageData(usageDataName, ranks);
    } else {
        const { data } = resp as IBodyRanksResponse;
        const ranks =
            data?.attributes?.ranks
                .sort((a: ICardSetUsage, b: ICardSetUsage) => Number(b.original_units) - Number(a.original_units))
                .filter((_: ICardSetUsage, index: number) => index < appConfig.top5) || [];
        setDashboardUsageData(usageDataName, ranks);
    }
}

// ------------ Cards Usage ------------
export async function getDashboardCardsUsageList(params: IParamsList): Promise<void> {
    const isBigQuery = getFeatureFlagIsBigQuery();
    const unitTypeId = params?.unitTypeIds || getUnitTypesSelect(DashboardUnitTypes.DATA)?.id;
    const usageDataName = getApiTypeWithUnitType(`GET_USAGE_CARDS_LIST`, unitTypeId);
    const { resp } = await apiManager.getApi(
        'GET_USAGE_CARDS_LIST',
        { searchParamsList: params, isBigQuery },
        undefined,
        undefined,
        usageDataName,
    );

    if (isBigQuery) {
        const data = resp as Array<IBigQueryUsage>;
        const ranks =
            data
                .sort((a: IBigQueryUsage, b: IBigQueryUsage) => Number(b.volume) - Number(a.volume))
                .filter((item: ICardsUsage, index: number) => index < appConfig.top10) || [];

        setDashboardUsageData(usageDataName, ranks);
    } else {
        const { data } = resp as IBodyRanksResponse;
        const ranks =
            data?.attributes?.ranks
                .sort((a: ICardsUsage, b: ICardsUsage) => Number(b.original_units) - Number(a.original_units))
                .filter((item: ICardsUsage, index: number) => index < appConfig.top10) || [];
        setDashboardUsageData(usageDataName, ranks);
    }
}

// ------------ Timeline Usage ------------
function setDashboardTimelineUsageList(data: Array<ITimelineUsage>): void {
    const DashboardStoreInstance = DashboardStore.getInstance();
    DashboardStoreInstance.setTimelineUsageList(data);
}

export async function getDashboardTimelineUsageList(params: IParamsList = {}): Promise<void> {
    const isBigQuery = getFeatureFlagIsBigQuery();
    const { resp } = await apiManager.getApi('GET_USAGE_TIMELINE_LIST', { searchParamsList: params, isBigQuery });
    if (isBigQuery) {
        const data = resp as Array<IBigQueryUsage>;
        const ranks =
            data.sort((a: IBigQueryUsage, b: IBigQueryUsage) => +dayjs(a.start_date) - +dayjs(b.start_date)) || [];
        setDashboardTimelineUsageList(ranks);
    } else {
        const { data } = resp as IBodyRanksResponse;
        const ranks =
            data?.attributes?.ranks.sort((a: ITimelineUsage, b: ITimelineUsage) => +dayjs(a.time) - +dayjs(b.time)) ||
            [];
        setDashboardTimelineUsageList(ranks);
    }
}

// ------------ CompanyUsage ------------
function setDashboardCompanyUsageList(data: Array<ICompanyUsage>): void {
    const DashboardStoreInstance = DashboardStore.getInstance();
    DashboardStoreInstance.setCompanyUsageList(data);
}

export async function getDashboardCompanyUsageList(params: IParamsList): Promise<void> {
    const isBigQuery = getFeatureFlagIsBigQuery();
    const { resp } = await apiManager.getApi('GET_USAGE_COMPANY_LIST', { searchParamsList: params, isBigQuery });
    if (isBigQuery) {
        const data = resp as Array<IBigQueryUsage>;
        const ranks =
            data
                .sort((a: IBigQueryUsage, b: IBigQueryUsage) => Number(b.volume) - Number(a.volume))
                .filter((_: ICompanyUsage, index: number) => index < appConfig.top10) || [];
        setDashboardCompanyUsageList(ranks);
    } else {
        const { data } = resp as IBodyRanksResponse;
        const ranks =
            data?.attributes?.ranks
                .sort((a: ICompanyUsage, b: ICompanyUsage) => Number(b.original_units) - Number(a.original_units))
                .filter((_: ICompanyUsage, index: number) => index < appConfig.top10) || [];
        setDashboardCompanyUsageList(ranks);
    }
}

// ------------ TelecomUsage ------------
function setDashboardTelecomUsageList(data: Array<ITelecomUsage>): void {
    const DashboardStoreInstance = DashboardStore.getInstance();
    DashboardStoreInstance.setTelecomUsageList(data);
}

export async function getDashboardTelecomUsageList(params: IParamsList): Promise<void> {
    const isBigQuery = getFeatureFlagIsBigQuery();
    const { resp } = await apiManager.getApi('GET_USAGE_TELECOM_LIST', { searchParamsList: params, isBigQuery });
    if (isBigQuery) {
        const data = resp as Array<IBigQueryUsage>;
        const ranks = data.sort((a: IBigQueryUsage, b: IBigQueryUsage) => Number(b.volume) - Number(a.volume)) || [];

        setDashboardTelecomUsageList(ranks);
    } else {
        const { data } = resp as IBodyRanksResponse;
        const ranks =
            data?.attributes?.ranks.sort(
                (a: ITelecomUsage, b: ITelecomUsage) => Number(b.original_units) - Number(a.original_units),
            ) || [];

        setDashboardTelecomUsageList(ranks);
    }
}

// ------------ ZoneUsage ------------
function setDashboardZoneUsageList(data: Array<IZoneUsage>): void {
    const DashboardStoreInstance = DashboardStore.getInstance();
    DashboardStoreInstance.setZoneUsageList(data);
}

export async function getDashboardZoneUsageList(params: IParamsList): Promise<void> {
    const isBigQuery = getFeatureFlagIsBigQuery();
    const { resp } = await apiManager.getApi('GET_USAGE_ZONE_LIST', { searchParamsList: params, isBigQuery });
    if (isBigQuery) {
        const data = resp as Array<IBigQueryUsage>;
        const ranks = data.sort((a: IBigQueryUsage, b: IBigQueryUsage) => Number(b.volume) - Number(a.volume)) || [];
        setDashboardZoneUsageList(ranks);
    } else {
        const { data } = resp as IBodyRanksResponse;
        const ranks =
            data?.attributes?.ranks.sort(
                (a: IZoneUsage, b: IZoneUsage) => Number(b.original_units) - Number(a.original_units),
            ) || [];
        setDashboardZoneUsageList(ranks);
    }
}

// ------------ CompanyRevenue ------------

function setDashboardCompanyRevenueIsNotBigQueryList(data: Array<ICompanyRevenue>): void {
    const DashboardStoreInstance = DashboardStore.getInstance();
    DashboardStoreInstance.setCompaniesRevenueIsNotBigQueryList(data);
}

export async function getDashboardCompanyRevenueIsNotBigQueryList(params: IParamsList): Promise<void> {
    const { resp } = await apiManager.getApi('GET_REVENUE_COMPANY_LIST', { searchParamsList: params });
    const { data } = resp as IBodyRanksResponse;
    const ranks =
        data?.attributes?.ranks
            .sort((a: ICompanyRevenue, b: ICompanyRevenue) => Number(b.amount) - Number(a.amount))
            .filter((_: ICompanyRevenue, index: number) => index < appConfig.top5) || [];
    setDashboardCompanyRevenueIsNotBigQueryList(ranks);
}

function setDashboardCompanyRevenueIsBigQueryList(data: Array<ICompanyRevenueItem>): void {
    const DashboardStoreInstance = DashboardStore.getInstance();
    DashboardStoreInstance.setCompaniesRevenueIsBigQueryList(data);
}

export async function getDashboardCompanyRevenueIsBigQueryList(params: IParamsList): Promise<void> {
    const { resp } = await apiManager.getApi('GET_REVENUE_COMPANY_LIST', {
        searchParamsList: params,
        isBigQuery: true,
    });
    const data: Array<ICompanyRevenueItem> = mapRank2RevenueItem(resp as Array<IRankCompanyRevenueItemAPI>);
    setDashboardCompanyRevenueIsBigQueryList(data);
}

export async function getDashboardCompanyRevenue(params: IParamsList): Promise<void> {
    const isBigQuery = getFeatureFlagIsBigQuery();
    if (isBigQuery) {
        await getDashboardCompanyRevenueIsBigQueryList(params);
    } else {
        await getDashboardCompanyRevenueIsNotBigQueryList(params);
    }
}

// ------------ CardSetRevenue ------------
function setDashboardCardSetRevenueIsNotBigQueryList(data: Array<ICardSetRevenue>): void {
    const DashboardStoreInstance = DashboardStore.getInstance();
    DashboardStoreInstance.setCardSetRevenueIsNotBigQueryList(data);
}
export async function getDashboardCardSetRevenueIsNotBigQueryList(params: IParamsList): Promise<void> {
    const { resp } = await apiManager.getApi('GET_REVENUE_CARD_SET_LIST', { searchParamsList: params });
    const { data } = resp as IBodyRanksResponse;
    const ranks =
        data?.attributes?.ranks
            .sort((a: ICardSetRevenue, b: ICardSetRevenue) => Number(b.amount) - Number(a.amount))
            .filter((_: ICardSetRevenue, index: number) => index < appConfig.top5) || [];
    setDashboardCardSetRevenueIsNotBigQueryList(ranks);
}

function setDashboardCardSetRevenueIsBigQueryList(data: Array<ICardSetRevenueItem>): void {
    const DashboardStoreInstance = DashboardStore.getInstance();
    DashboardStoreInstance.setCardSetRevenueIsBigQueryList(data);
}

export async function getDashboardCardSetRevenueIsBigQueryList(params: IParamsList): Promise<void> {
    const { resp } = await apiManager.getApi('GET_REVENUE_CARD_SET_LIST', {
        searchParamsList: params,
        isBigQuery: true,
    });
    const data: Array<ICardSetRevenueItem> = mapRank2RevenueItem(resp as Array<IRankCardSetRevenueItemAPI>);
    setDashboardCardSetRevenueIsBigQueryList(data);
}

export async function getDashboardCardSetRevenueList(params: IParamsList): Promise<void> {
    const isBigQuery = getFeatureFlagIsBigQuery();
    if (isBigQuery) {
        await getDashboardCardSetRevenueIsBigQueryList(params);
    } else {
        await getDashboardCardSetRevenueIsNotBigQueryList(params);
    }
}

// ------------ Cards Revenue ------------
function setDashboardCardsRevenueIsNotBigQueryList(data: Array<ICardsRevenue>): void {
    const DashboardStoreInstance = DashboardStore.getInstance();
    DashboardStoreInstance.setCardsRevenueIsNotBigQueryList(data);
}

export async function getDashboardCardsRevenueIsNotBigQueryList(params: IParamsList): Promise<void> {
    const { resp } = await apiManager.getApi('GET_REVENUE_CARDS_LIST', { searchParamsList: params });
    const { data } = resp as IBodyRanksResponse;
    const ranks =
        data?.attributes?.ranks
            .sort((a: ICardsRevenue, b: ICardsRevenue) => Number(b.amount) - Number(a.amount))
            .filter((_: ICardsRevenue, index: number) => index < appConfig.top5) || [];
    setDashboardCardsRevenueIsNotBigQueryList(ranks);
}

function setDashboardCardsRevenueIsBigQueryList(data: Array<ICardsRevenueItem>): void {
    const DashboardStoreInstance = DashboardStore.getInstance();
    DashboardStoreInstance.setCardsRevenueIsBigQueryList(data);
}

export async function getDashboardCardsRevenueIsBigQueryList(params: IParamsList): Promise<void> {
    const { resp } = await apiManager.getApi('GET_REVENUE_CARDS_LIST', { searchParamsList: params, isBigQuery: true });
    const data: Array<ICardsRevenueItem> = mapRank2RevenueItem(resp as Array<IRankCardRevenueItemAPI>);
    setDashboardCardsRevenueIsBigQueryList(data);
}

export async function getDashboardCardsRevenueList(params: IParamsList): Promise<void> {
    const isBigQuery = getFeatureFlagIsBigQuery();
    if (isBigQuery) {
        await getDashboardCardsRevenueIsBigQueryList(params);
    } else {
        await getDashboardCardsRevenueIsNotBigQueryList(params);
    }
}

// ------------ EditPurchaseZoneRate Revenue ------------
function setDashboardCountryRevenueIsNotBigQueryList(data: Array<ICountryRevenue>): void {
    const DashboardStoreInstance = DashboardStore.getInstance();
    DashboardStoreInstance.setCountryRevenueIsNotBigQueryList(data);
}
export async function getDashboardCountryRevenueIsNotBigQueryList(params: IParamsList): Promise<void> {
    const { resp } = await apiManager.getApi('GET_REVENUE_COUNTRY_LIST', { searchParamsList: params });
    const { data } = resp as IBodyRanksResponse;
    const ranks =
        data?.attributes?.ranks
            .sort((a: ICountryRevenue, b: ICountryRevenue) => Number(b.amount) - Number(a.amount))
            .filter((_: ICountryRevenue, index: number) => index < appConfig.top5) || [];
    setDashboardCountryRevenueIsNotBigQueryList(ranks);
}

function setDashboardCountryRevenueIsBigQueryList(data: Array<ICountryRevenueItem>): void {
    const DashboardStoreInstance = DashboardStore.getInstance();
    DashboardStoreInstance.setCountryRevenueIsBigQueryList(data);
}

export async function getDashboardCountryRevenueIsBigQueryList(params: IParamsList): Promise<void> {
    const { resp } = await apiManager.getApi('GET_REVENUE_COUNTRY_LIST', {
        searchParamsList: params,
        isBigQuery: true,
    });
    const data: Array<ICountryRevenueItem> = mapRank2RevenueItem(resp as Array<IRankCountryRevenueItemAPI>);
    setDashboardCountryRevenueIsBigQueryList(data);
}

export async function getDashboardCountryRevenueList(params: IParamsList): Promise<void> {
    const isBigQuery = getFeatureFlagIsBigQuery();
    if (isBigQuery) {
        await getDashboardCountryRevenueIsBigQueryList(params);
    } else {
        await getDashboardCountryRevenueIsNotBigQueryList(params);
    }
}

// ------------ Zone Revenue ------------
function setDashboardZoneRevenueIsNotBigQueryList(data: Array<IZoneRevenue>): void {
    const DashboardStoreInstance = DashboardStore.getInstance();
    DashboardStoreInstance.setZoneRevenueIsNotBigQueryList(data);
}

export async function getDashboardZoneRevenueIsNotBigQueryList(params: IParamsList): Promise<void> {
    const { resp } = await apiManager.getApi('GET_REVENUE_ZONE_LIST', { searchParamsList: params });
    const { data } = resp as IBodyRanksResponse;
    const ranks =
        data?.attributes?.ranks
            .sort((a: IZoneRevenue, b: IZoneRevenue) => Number(b.amount) - Number(a.amount))
            .filter((_: IZoneRevenue, index: number) => index < appConfig.top5) || [];
    setDashboardZoneRevenueIsNotBigQueryList(ranks);
}

function setDashboardZoneRevenueIsBigQueryList(data: Array<ITimelineZoneItem>): void {
    const DashboardStoreInstance = DashboardStore.getInstance();
    DashboardStoreInstance.setZoneRevenueIsBigQueryList(data);
}

export async function getDashboardZoneRevenueIsBigQueryList(params: IParamsList): Promise<void> {
    const { resp } = await apiManager.getApi('GET_REVENUE_ZONE_LIST', { searchParamsList: params, isBigQuery: true });
    const data: Array<ITimelineZoneItem> = mapRank2RevenueItem(resp as Array<IRankZoneRevenueItemAPI>);
    setDashboardZoneRevenueIsBigQueryList(data);
}

export async function getDashboardZoneRevenueList(params: IParamsList): Promise<void> {
    const isBigQuery = getFeatureFlagIsBigQuery();
    if (isBigQuery) {
        await getDashboardZoneRevenueIsBigQueryList(params);
    } else {
        await getDashboardZoneRevenueIsNotBigQueryList(params);
    }
}

// ------------ Operator/Telecom Revenue ------------
function setDashboardTelecomRevenueIsNotBigQueryList(data: Array<ITelecomRevenue>): void {
    const DashboardStoreInstance = DashboardStore.getInstance();
    DashboardStoreInstance.setTelecomRevenueIsNotBigQueryList(data);
}

export async function getDashboardTelecomRevenueIsNotBigQueryList(params: IParamsList): Promise<void> {
    const { resp } = await apiManager.getApi('GET_REVENUE_TELECOM_LIST', { searchParamsList: params });
    const { data } = resp as IBodyRanksResponse;
    const ranks =
        data?.attributes?.ranks
            .sort((a: ITelecomRevenue, b: ITelecomRevenue) => Number(b.amount) - Number(a.amount))
            .filter((_: ITelecomRevenue, index: number) => index < appConfig.top5) || [];
    setDashboardTelecomRevenueIsNotBigQueryList(ranks);
}

function setDashboardTelecomRevenueIsBigQueryList(data: Array<IOperatorRevenueItem>): void {
    const DashboardStoreInstance = DashboardStore.getInstance();
    DashboardStoreInstance.setTelecomRevenueIsBigQueryList(data);
}

export async function getDashboardTelecomRevenueIsBigQueryList(params: IParamsList): Promise<void> {
    const { resp } = await apiManager.getApi('GET_REVENUE_TELECOM_LIST', {
        searchParamsList: params,
        isBigQuery: true,
    });
    const data: Array<IOperatorRevenueItem> = mapRank2RevenueItem(resp as Array<IRankOperatorRevenueItemAPI>);
    setDashboardTelecomRevenueIsBigQueryList(data);
}

export async function getDashboardTelecomRevenueList(params: IParamsList): Promise<void> {
    const isBigQuery = getFeatureFlagIsBigQuery();
    if (isBigQuery) {
        await getDashboardTelecomRevenueIsBigQueryList(params);
    } else {
        await getDashboardTelecomRevenueIsNotBigQueryList(params);
    }
}

// ------------ TimelineRevenue ------------
function setDashboardTimelineRevenueIsNotBigQueryList(data: Array<ITimelineRevenue>): void {
    const DashboardStoreInstance = DashboardStore.getInstance();
    DashboardStoreInstance.setTimelineRevenueNotBigQueryList(data);
}
export async function getDashboardTimelineCompanyRevenueIsNotBigQueryList(params: IParamsList = {}): Promise<void> {
    const { resp } = await apiManager.getApi('GET_REVENUE_TIMELINE_LIST', { searchParamsList: params });
    const { data } = resp as IBodyRanksResponse;
    const ranks =
        data?.attributes?.ranks.sort((a: ITimelineRevenue, b: ITimelineRevenue) => +dayjs(a.time) - +dayjs(b.time)) ||
        [];
    setDashboardTimelineRevenueIsNotBigQueryList(ranks);
}

function setDashboardTimelineRevenueIsBigQueryList(data: Array<ITimelineRevenueItem>): void {
    const DashboardStoreInstance = DashboardStore.getInstance();
    DashboardStoreInstance.setTimelineRevenueIsBigQueryList(data);
}
export async function getDashboardTimelineCompanyRevenueIsBigQueryList(params: IParamsList = {}): Promise<void> {
    const { resp } = await apiManager.getApi('GET_REVENUE_TIMELINE_LIST', {
        searchParamsList: params,
        isBigQuery: true,
    });
    const data: Array<ITimelineRevenueItem> = mapRank2RevenueItem(resp as Array<IRankTimelineRevenueItemAPI>).sort(
        (a: ITimelineRevenueItem, b: ITimelineRevenueItem) => sortByString(a.startDate, b.startDate),
    );
    setDashboardTimelineRevenueIsBigQueryList(data);
}

export async function getDashboardTimelineCompanyRevenueList(params: IParamsList): Promise<void> {
    const isBigQuery = getFeatureFlagIsBigQuery();
    if (isBigQuery) {
        await getDashboardTimelineCompanyRevenueIsBigQueryList(params);
    } else {
        await getDashboardTimelineCompanyRevenueIsNotBigQueryList(params);
    }
}

// ------------ Main ------------

export async function getDashboardRelationSelect(): Promise<void> {
    getCompaniesSelect(true);
    await getUnitTypes();
}

export async function getFinanceRelationSelect(params: IParamsList): Promise<void> {
    getCompaniesSelect(true);
    getCardSetsSelect({ companyId: params?.companyId }, true);
    getOperatorsSelect(false);
    await getUnitTypes();
}

export function setDashboardType(type: IDashboardType): void {
    const DashboardStoreInstance = DashboardStore.getInstance();
    DashboardStoreInstance.setDashboardType(type);
}

export function setDashboardForcedUpdateTime(): void {
    const DashboardStoreInstance = DashboardStore.getInstance();
    DashboardStoreInstance.setForcedUpdateTime();
}

export function cleanDashboardRelationStore(): void {
    cleanUnitTypeUserStore();
    cleanCompaniesStore();
}

export function cleanDashboardStore(): void {
    const DashboardStoreInstance = DashboardStore.getInstance();
    DashboardStoreInstance.cleanStore();
    cleanDashboardRelationStore();
}

export function cleanFinanceHeader(): void {
    const DashboardStoreInstance = DashboardStore.getInstance();
    DashboardStoreInstance.cleanFinanceHeader();
}

export function setDashboardUsageData(dataName: string, value: IUsageMap): void {
    const DashboardStoreInstance = DashboardStore.getInstance();
    DashboardStoreInstance.setUsageData(dataName, value);
}

export function setDashboardOverlayView(overlayView: IOverlayView): void {
    const DashboardStoreInstance = DashboardStore.getInstance();
    DashboardStoreInstance.setMapOverlayView(overlayView);
}

export const abortAllDashboardFetch = (ignoreTypes: IGetApiType[] = []) => {
    const usedApiTypes: IGetApiType[] = [
        'GET_COMPANY_STATISTICS',
        'GET_FINANCE_ROLL',
        'GET_REVENUE_CARD_SET_LIST',
        'GET_REVENUE_CARDS_LIST',
        'GET_REVENUE_COMPANY_LIST',
        'GET_REVENUE_COUNTRY_LIST',
        'GET_REVENUE_TELECOM_LIST',
        'GET_REVENUE_TIMELINE_LIST',
        'GET_REVENUE_ZONE_LIST',
        'GET_ACTIVITY_ROLL',
        'GET_USAGE_CARD_SETS_LIST',
        'GET_USAGE_CARDS_LIST',
        'GET_USAGE_COMPANY_LIST',
        'GET_USAGE_COUNTRY_LIST',
        'GET_USAGE_TELECOM_LIST',
        'GET_USAGE_TIMELINE_LIST',
        'GET_USAGE_ZONE_LIST',
    ];
    const types = usedApiTypes.filter((u) => !ignoreTypes.includes(u));
    abortSelectedFetch(types);
};
