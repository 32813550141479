import { ActivityLogStore } from '../stores/activityLog';
import { cleanCompaniesStore, getUsersRelationSelect, getUsersSelect, setUiTotal } from './';
import dayjs from 'dayjs';
import { apiManager } from '../REST';
import {
    IActivityLogItem,
    IActivityLogItemMetaAttributes,
    IActivityLogListAPI,
    IActivityLogLists,
    IParamsList,
} from '../interfaces';
import { mapApi2UiField, mapApi2UiList, objIsNotEmpty } from '../instruments';

function setActivityLogList(resp: IActivityLogListAPI) {
    const ActivityLogInstance = ActivityLogStore.getInstance();

    const activityLogList =
        resp.data?.map((raw): IActivityLogItem => {
            const {
                attributes: { meta, ...mainInfo },
            } = raw;

            let metaInfo: IActivityLogItemMetaAttributes = {};
            const metaLists: IActivityLogLists = { lists: {} };
            if (meta?.attributes) {
                metaInfo = mapApi2UiField(meta?.attributes);
            }
            if (meta?.cards_added?.length) {
                metaLists.lists = {
                    ...metaLists.lists,
                    cardsAdded: mapApi2UiList(meta?.cards_added),
                };
            }
            if (meta?.records) {
                if (Array.isArray(meta.records)) {
                    metaLists.lists = {
                        ...metaLists.lists,
                        records: mapApi2UiList(meta?.records),
                    };
                }
            }
            if (objIsNotEmpty(metaLists.lists)) {
                metaInfo = {
                    ...metaInfo,
                    ...metaLists,
                };
            }

            const mainInfoParsed = mapApi2UiField(mainInfo);
            const user = resp.included?.find((i) => +i.id === +mainInfoParsed.userId && i.type === 'user');
            const userData = mapApi2UiField(user?.attributes || {});

            if ('card_set_package_instance_joins_id' in metaInfo) {
                delete metaInfo.card_set_package_instance_joins_id;
            }

            return {
                id: raw.id,
                ...mainInfoParsed,
                createdAt: String(dayjs(mainInfoParsed.createdAt)),
                meta: metaInfo,
                userData,
                entity: mainInfoParsed.entity.replace(/([a-z])([A-Z])/g, '$1 $2'),
                raw,
            };
        }) || [];

    ActivityLogInstance.setActivityLogList(activityLogList);
}

// function arrayStringMakeSelect(data: Array<string>): ISelectList {
//     return (
//         data
//             ?.map((n: any) => ({
//                 id: n.id,
//                 name: n.attributes.name || '',
//             }))
//             .sort(sortByName) || []
//     );
// }

// function setEntitySelect(list: ISelectList): void {
//     const ActivityLogStoreInstance = ActivityLogStore.getInstance();
//     ActivityLogStoreInstance.setEntitySelect(list);
// }
export function getActivityLogRelationSelect(): void {
    getUsersRelationSelect();
    getUsersSelect({ isActivityLogUsers: false, pageSize: 100 });
}

export async function getActivityLogList(params: IParamsList): Promise<void> {
    const { resp } = await apiManager.getApi('GET_ACTIVITY_LOGS', { searchParamsList: params });
    const { meta } = resp;
    meta && setUiTotal(meta.records_count);

    setActivityLogList(resp as IActivityLogListAPI);
}

// export async function getEntitySelect(): Promise<void> {
//     const { resp } = await apiManager.getApi('GET_ENTITY_SELECT');
//     resp?.data && setEntitySelect(arrayStringMakeSelect(resp.data as string[]));
// }

export function setActivityLogForcedUpdateTime(): void {
    const ActivityLogStoreInstance = ActivityLogStore.getInstance();
    ActivityLogStoreInstance.setForcedUpdateTime();
}

export function cleanActivityLogStore(): void {
    cleanCompaniesStore();
    const ActivityLogStoreInstance = ActivityLogStore.getInstance();
    ActivityLogStoreInstance.cleanStore();
}
