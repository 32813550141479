import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Form, Row } from 'antd';
import { FieldData } from 'rc-field-form/es/interface';
import { appConfig } from '../../../settings';
import { IEditForm } from '../../../interfaces';
import { usePurchaseInfo, usePurchaseRateFormList } from '../../../hooks';
import { apiMessagesSaveMessages, sendPurchaseRateData, setPurchaseForm } from '../../../middleware';
import { debounce, onFormFieldsChange } from '../../../instruments';
import { Catcher } from '../../Catcher';
import { EditPurchaseFieldsInfo, EditPurchaseZonesList } from './../Components';
import Styles from '../../styles/m_itemCardStyles.less';

export const EditPurchaseForms: React.FC<IEditForm> = observer(({ setEditMode, isNew = false }: IEditForm) => {
    const [isSending, setSending] = useState(false);
    const [form] = Form.useForm();
    const initialInfo = usePurchaseInfo();
    const { rateByZone, idByZone } = usePurchaseRateFormList();
    const initialValues = { ...initialInfo, ...rateByZone };
    const checkValid = async (): Promise<void> => {
        await form.validateFields();
    };

    const onFieldsChange = (changedFields: FieldData[]): void => {
        // removePurchaseFormError({
        //     changedFields,
        //     initialValues,
        //     listIds: idByZone,
        //     checkValid,
        // });
        onFormFieldsChange({
            changedFields,
            initialValues,
            listIds: idByZone,
            setForm: setPurchaseForm,
            checkValid,
        });
    };

    const initialForm = (): void => {
        if (initialValues.id) {
            form.setFieldsValue(initialValues);
        }
    };

    useEffect(() => {
        initialForm();
    }, [initialValues]);

    const updateStore = (): void => {
        setPurchaseForm({ ...initialInfo, ...form.getFieldsValue() });
    };

    const savePurchaseRate = async (): Promise<void> => {
        setSending(true);
        updateStore();
        const { isError, errors = [] } = await sendPurchaseRateData(isNew);
        if (isError) {
            apiMessagesSaveMessages(errors);
            checkValid();
        } else {
            setEditMode && setEditMode(false);
        }

        setSending(false);
    };

    return (
        <Catcher>
            <div className={Styles.main}>
                <Form.Provider>
                    <Form
                        form={form}
                        name="purchaseRateList"
                        initialValues={initialValues}
                        scrollToFirstError={true}
                        layout={'vertical'}
                        className={'formStyle'}
                        onFieldsChange={debounce(onFieldsChange, appConfig.delayFormUpdateState)}
                    >
                        <Row>
                            <EditPurchaseFieldsInfo save={savePurchaseRate} isSending={isSending} isNew={isNew} />
                        </Row>
                        <EditPurchaseZonesList updateForm={updateStore} />
                    </Form>
                </Form.Provider>
            </div>
        </Catcher>
    );
});
