import React from 'react';
import cx from 'classnames';
import { IUploadFilesStatusType } from '../../../../interfaces';
import { getUploadFilesStatusTitle } from '../../../../middleware';
import Styles from '../../../styles/m_viewStyles.less';

type ResponsesStatusProps = {
    status?: IUploadFilesStatusType;
};
export const FileStatus: React.FC<ResponsesStatusProps> = ({ status }: ResponsesStatusProps) => {
    const statusTitle = getUploadFilesStatusTitle(status);
    return (
        <span className={Styles.fileStatus}>
            <span
                className={cx({
                    [Styles.processing]: status === 'processing',
                    [Styles.processed]: status === 'processed',
                    [Styles.failed]: status === 'failed',
                })}
            >
                {statusTitle}
            </span>
        </span>
    );
};
