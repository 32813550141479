import React from 'react';
import { observer } from 'mobx-react';
import { Catcher } from '../';
import { Loading, LoadingText } from './';
import { ILoadingApiByTypeAndUnit } from './interfaces';
import { useUiIsSpinnerTypeUnit } from '../../hooks';

export const LoadingApiByTypeAndUnit: React.FC<ILoadingApiByTypeAndUnit> = observer(
    ({ apiTypeId, unitTypeId, size, tip = LoadingText(), isLoading, onlySpinner }: ILoadingApiByTypeAndUnit) => {
        const isLocalLoading = isLoading || useUiIsSpinnerTypeUnit(apiTypeId, unitTypeId);

        return (
            <Catcher>
                {isLocalLoading ? <Loading isLoading={true} size={size} tip={tip} onlySpinner={onlySpinner} /> : null}
            </Catcher>
        );
    },
);
