import { action, makeObservable, observable, reaction } from 'mobx';
import { UiStore } from './ui';
import { appConfig } from '../settings';
import { IBalanceLogItem, IFilterParams } from '../interfaces';
import { getBalanceLogList } from '../middleware';
import { apiParamsCleaner } from '../instruments';

export class BalanceLogStore {
    private constructor() {
        makeObservable(this);
        reaction(
            () => this.forcedUpdateTime,
            () => {
                const UiStoreInstance = UiStore.getInstance();
                const params = UiStoreInstance.params;
                const cleanParams = apiParamsCleaner(params, this.filterParams);
                getBalanceLogList(cleanParams).then();
            },
            {
                delay: appConfig.APIDelay,
            },
        );
    }

    private static instance: BalanceLogStore;
    public static getInstance(): BalanceLogStore {
        if (!BalanceLogStore.instance) {
            BalanceLogStore.instance = new BalanceLogStore();
        }
        return BalanceLogStore.instance;
    }

    @observable list: IBalanceLogItem[] = [];
    @action setBalanceLogList(balanceLogList: IBalanceLogItem[]): void {
        this.list = balanceLogList;
    }

    @observable forcedUpdateTime = 0;
    @action setForcedUpdateTime(): void {
        this.forcedUpdateTime = Date.now();
    }

    @observable filterParams: IFilterParams = {
        companyId: true,
        dateRange: true,
        defaultDateRangeType: 'day',
        isDateRangeCleanedDefaultURL: true,
        isDateRangeMaxDayToDay: true,
        pageNumber: true,
        pageSize: true,
        search: true,
    };

    @action cleanStore(): void {
        this.list = [];
    }
}
