import React from 'react';
import { observer } from 'mobx-react';
import { CardInfoToolBar, CardMainLayout, Catcher, FieldInfo, cardLayoutOptions } from '../../';
import { IShowForm } from '../../../interfaces';
import { usePurchaseInfo } from '../../../hooks';

export const ShowInfo: React.FC<IShowForm> = observer(({ setEditMode }: IShowForm) => {
    const rateInfo = usePurchaseInfo();

    return (
        <Catcher>
            <CardMainLayout
                idSpinners={['GET_PURCHASE_RATE_LIST']}
                name={rateInfo.name}
                columnOption={cardLayoutOptions.purchaseRate}
            >
                <FieldInfo title={'Name'}>{rateInfo.name}</FieldInfo>
                <FieldInfo title={'Operator'}>{rateInfo.operator}</FieldInfo>
                <FieldInfo title={'Currency'}>{rateInfo.currency}</FieldInfo>
                <CardInfoToolBar setEditMode={setEditMode} isBackToList />
            </CardMainLayout>
        </Catcher>
    );
});
