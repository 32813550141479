import { DiameterSessionsStore } from '../stores/diameterSessions';
import { setUiTotal } from './ui';
import { apiManager } from '../REST';
import {
    IDiameterSessionsListAPI,
    IDiameterSessionsListItemAPI,
    IIncludeSubscriberDiameterSessionsItemAPI,
    IItemAPI,
    IParamsList,
    ISelect,
    ISelectList,
} from '../interfaces';
import { mapApi2UiField, sortByName } from '../instruments';

function setDiameterSessionsListStore(apiList: IDiameterSessionsListAPI): void {
    if (apiList.data) {
        const operators = apiList?.included?.filter((i) => i.type === 'operator') || [];
        const subscribers = apiList?.included?.filter((i) => i.type === 'subscriber') || [];
        const StoreInstance = DiameterSessionsStore.getInstance();
        StoreInstance.setList(
            apiList.data.map((item: IDiameterSessionsListItemAPI) => {
                const operatorId = item.relationships?.operator?.data?.id;
                const operator = (operators.find((i) => i.id === operatorId) as IItemAPI)?.attributes?.name;

                const subscriberId = item.relationships?.subscriber?.data?.id;
                const subscriber = subscribers.find(
                    (i) => i.id === subscriberId,
                ) as IIncludeSubscriberDiameterSessionsItemAPI;
                const msisdn = subscriber?.attributes?.msisdn;
                const cardId = subscriber?.relationships?.card?.data?.id;
                const attributes = item?.attributes ? mapApi2UiField(item.attributes) : {};
                return {
                    id: item.id,
                    operatorId,
                    operator,
                    subscriberId,
                    msisdn,
                    cardId,
                    ...attributes,
                };
            }),
        );
    }
}

function setDiameterSessionsSelectStore(apiList: IDiameterSessionsListAPI): void {
    if (apiList.data) {
        const StoreInstance = DiameterSessionsStore.getInstance();
        const apiSessionsSelect: ISelectList = apiList.data.map((item: IDiameterSessionsListItemAPI) => {
            const attributes = item?.attributes ? mapApi2UiField(item.attributes) : {};
            const id = attributes?.sessionId?.toString() || '';
            return {
                id: id,
                name: id,
            };
        });
        const sessionsSelect = [...StoreInstance.sessionsSelect];
        apiSessionsSelect.forEach((s: ISelect) => {
            const findStore = sessionsSelect.find((f: ISelect) => f.id === s.id);
            if (!findStore) {
                sessionsSelect.push(s);
            }
        });
        StoreInstance.setSessionsSelect(sessionsSelect.sort(sortByName));
    }
}

export function setDiameterSessionUpdateTime(): void {
    const StoreInstance = DiameterSessionsStore.getInstance();
    StoreInstance.setForcedUpdateTime();
}

export async function getDiameterSessionsList(params: IParamsList): Promise<void> {
    const { resp } = await apiManager.getApi('GET_DIAMETER_SESSIONS_LIST', { searchParamsList: params });
    resp && setDiameterSessionsSelectStore(resp as IDiameterSessionsListAPI);
    resp && setDiameterSessionsListStore(resp as IDiameterSessionsListAPI);
    const { meta } = resp;
    meta && setUiTotal(meta.records_count);
}

export function cleanDiameterSessionsStore(): void {
    const StoreInstance = DiameterSessionsStore.getInstance();
    StoreInstance.cleanStore();
}
