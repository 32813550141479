import React from 'react';
import { observer } from 'mobx-react';
import { Dropdown, MenuProps, Tooltip } from 'antd';
import { BankOutlined, LogoutOutlined, NotificationOutlined, UserOutlined } from '@ant-design/icons';
import { useProfile, useRouterStore, useUiLanguage } from '../../hooks';
import { authLogOutUser } from '../../middleware';
import { tB, tP } from '../../translate';
import Styles from './m_styles.less';

export const ProfileMenu: React.FC = observer(() => {
    const { firstName, lastName, email, companyId } = useProfile();
    const languageId = useUiLanguage();
    const routerStore = useRouterStore();

    const goTo = (routeName: string): void => {
        routerStore.goTo(routeName);
    };

    const goToCompany = (): void => {
        if (companyId) {
            routerStore.goTo('MyCompany');
        }
    };

    const items: MenuProps['items'] = [
        {
            label: `${firstName} ${lastName}`,
            key: 'name',
            disabled: true,
        },
        {
            label: email,
            key: 'email',
            disabled: true,
        },
        {
            type: 'divider',
        },
        {
            label: tP('Profile'),
            key: 'profile',
            icon: <UserOutlined />,
            onClick: () => goTo('Profile'),
        },
        {
            label: tP('My Company'),
            key: 'myCompany',
            icon: <BankOutlined />,
            onClick: goToCompany,
        },
        {
            label: tP('My Alarms'),
            key: 'myAlarm',
            icon: <NotificationOutlined />,
            onClick: () => goTo('MyAlarms'),
        },
        {
            type: 'divider',
        },
        {
            label: tP('Log out'),
            key: 'logOut',
            icon: <LogoutOutlined />,
            danger: true,
            onClick: authLogOutUser,
        },
    ];

    return (
        <>
            <Tooltip title={tB('Profile menu')} placement="bottomRight">
                <Dropdown menu={{ items }} trigger={['click']}>
                    <div className={Styles.avatarPlace} title={`${firstName} ${lastName}`} data-test="profileMenu">
                        <UserOutlined className={Styles.avatarIcon} />
                    </div>
                </Dropdown>
            </Tooltip>
        </>
    );
});
