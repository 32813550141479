import React, { Fragment, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Divider, Form, Input } from 'antd';
import { Catcher, FormSelect } from '../../';
import { ToggleList } from './';
import { ToggleItem } from '../../../interfaces';
import { IMayAlarmPanel } from './interfaces';
import { useCardSetsSelect } from '../../../hooks';
import { apiMessagesGetFormRulesFieldBlank, rulesIsNumber, rulesMoreThanZero } from '../../../middleware';
import { tF } from '../../../translate';

type StringObj = {
    [key: string]: string;
};

export const MonthlyCardSetPanel: React.FC<IMayAlarmPanel> = observer(({ isLoading, changeError }: IMayAlarmPanel) => {
    const [selectItemCount, setSelectItemCount] = useState<number>(0);
    const select = useCardSetsSelect();
    const selectObj: StringObj = {};
    select.forEach((value) => {
        selectObj[value.id] = value.name;
    });
    const validationCardSetLimit = [...apiMessagesGetFormRulesFieldBlank(), rulesIsNumber, rulesMoreThanZero];
    const MonthlyCardSetBalance: ToggleItem[] = [
        {
            label: `Send alarm when card in card sets reaches 50% of card limit`,
            name: 'notification_card_set_threshold_50_reached',
        },
        {
            label: `Send alarm when card in card sets reaches 75% of card limit`,
            name: 'notification_card_set_threshold_75_reached',
        },
        {
            label: `Send alarm when card in card sets reaches 100% of card limit`,
            name: 'notification_card_set_threshold_100_reached',
        },
        {
            label: `Suspend card in card sets when card reaches 100 of card limit`,
            name: 'suspend_card_set_threshold_100_reached',
        },
    ];

    useEffect(() => {
        changeError && changeError('monthlyCardSetPanel', 'Need to select at least one item', !selectItemCount);
    }, [selectItemCount]);

    return (
        <Catcher>
            <Fragment>
                <Form.Item name="cardSetLimit" rules={validationCardSetLimit}>
                    <Input
                        // addonBefore={
                        //     <FormSelect
                        //         fieldName={'cardSetCurrency'}
                        //         isNotClear={true}
                        //         rules={validateBlank}
                        //         useSelectHook={useAlarmConfigCurrency}
                        //         disabled={isLoading}
                        //         isAddonBefore={true}
                        //     />
                        // }
                        placeholder="Limit"
                        disabled={isLoading}
                    />
                </Form.Item>
                <FormSelect
                    fieldName={'cardSetIds'}
                    label={tF('Choose card sets')}
                    mode={'multiple'}
                    rules={apiMessagesGetFormRulesFieldBlank()}
                    useSelectHook={useCardSetsSelect}
                    isNotClear={true}
                    disabled={isLoading}
                />
                <Divider />
                <div id="profileAlarms_monthlyCardSetPanel">
                    <Form.Item name={'monthlyCardSetPanel'}>
                        <ToggleList
                            toggleArr={MonthlyCardSetBalance}
                            isLoading={isLoading}
                            setSelectCount={setSelectItemCount}
                        />
                    </Form.Item>
                </div>
            </Fragment>
        </Catcher>
    );
});
