import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { useProfileIsRight } from './useProfileStores';
import { IPackageInfo, IPackageSoldInfo, ISelect, ISelectList } from '../../interfaces';

export function usePackageInfo(): IPackageInfo {
    return useContext(MobXProviderContext).RootStore.packageStore.packageInfo;
}

export function usePackageSoldInfo(): IPackageSoldInfo {
    return useContext(MobXProviderContext).RootStore.packageStore.packageSoldInfo;
}

export function usePackagePaymentTypes(): ISelectList {
    const isLord = useProfileIsRight('LORD');
    const availableTypes = isLord ? ['2', '3'] : ['1', '2'];

    return useContext(MobXProviderContext).RootStore.packageStore.paymentTypes.filter((type: ISelect) => {
        return availableTypes.includes(type.id);
    });
}
