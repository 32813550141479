import React, { ChangeEvent, useEffect, useState } from 'react';
import { Input } from 'antd';
import { InputProps } from 'antd/lib/input/Input';
import { Catcher } from '../';
import { appConfig } from '../../settings';
import { useInputDebounce, useInputRefFocus } from '../../hooks';

export interface IInputDebounce extends InputProps {
    delay?: number;
    isFirstInput?: boolean;
}

export const InputDebounce: React.FC<IInputDebounce> = ({
    delay = appConfig.delayInputDebounce,
    onChange,
    value,
    isFirstInput,
    ...props
}: IInputDebounce) => {
    const inputRef = useInputRefFocus();
    const firstInputRef = isFirstInput ? inputRef : null;
    const [localValue, setLocalValue] = useState<typeof value>();
    const [localOnChangeEvent, setLocalOnChangeEvent] = useState<ChangeEvent<HTMLInputElement>>();

    const debouncedValue: typeof value = useInputDebounce<typeof value>(localValue, delay);

    useEffect(() => {
        setLocalValue(value);
    }, [value]);

    useEffect(() => {
        if (onChange && localOnChangeEvent && (!!value || !!debouncedValue) && value !== debouncedValue) {
            const changeEvent: ChangeEvent<HTMLInputElement> = {
                ...localOnChangeEvent,
                target: { ...localOnChangeEvent.target, value: localValue ? localValue.toString() : '' },
            };
            onChange(changeEvent);
        }
    }, [debouncedValue]);

    const localOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setLocalValue(event.target.value);
        setLocalOnChangeEvent({ ...event });
    };

    return (
        <Catcher>
            <Input
                {...props}
                onBlur={(): void => undefined}
                onChange={localOnChange}
                ref={firstInputRef}
                value={localValue}
            />
        </Catcher>
    );
};
