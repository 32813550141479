import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { Form, Switch } from 'antd';
import { Catcher } from '../../';
import { IMayAlarmPanel } from './interfaces';
import { useCheckAlarmControlToggle } from '../../../hooks';

interface IPanelControl extends IMayAlarmPanel {
    Content: React.FC<IMayAlarmPanel>;
    isSending?: boolean;
    label: string;
    togglesNames: string[];
    resetFields: (arr: string[]) => void;
}

export const PanelControl: React.FC<IPanelControl> = observer(
    ({ isLoading, isSending, label, Content, togglesNames, resetFields, changeError }) => {
        const [isLimitDisabled, setIsLimitDisabled] = useState<boolean>(true);
        const isLoadingEnabled = isLoading || isSending;
        const isOpen = useCheckAlarmControlToggle(togglesNames);
        const switchRef = useRef<HTMLDivElement>(null);

        const changeIsLimitDisabled = (value: boolean): void => {
            setIsLimitDisabled(!value);
            resetFields(togglesNames);
        };

        const handleRef = (): void => {
            if (switchRef.current) {
                switchRef.current.click();
            }
        };

        useEffect(() => {
            if (isOpen) {
                handleRef();
            }
        }, [isOpen]);

        return (
            <Catcher>
                <Form.Item className={'switchField'} label={label} valuePropName="checked">
                    <Switch
                        loading={isLoadingEnabled}
                        onChange={changeIsLimitDisabled}
                        defaultChecked={false}
                        ref={switchRef}
                    />
                </Form.Item>
                {!isLimitDisabled ? <Content isLoading={isLoadingEnabled} changeError={changeError} /> : null}
            </Catcher>
        );
    },
);
