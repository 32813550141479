import React, { MouseEvent, ReactNode } from 'react';
import { Col } from 'antd';
import { CaretDownOutlined, CaretUpOutlined, MinusOutlined } from '@ant-design/icons';
import { Catcher, LoadingApiByIds } from '../../';
import { IColumnOption, IGetApiType } from '../../../interfaces';
import { useRouterStore } from '../../../hooks';
import { tI } from '../../../translate';
import Styles from '../m_dashboarStyles.less';

interface IInfoCard {
    idSpinners?: IGetApiType[];
    isBigQuery?: boolean;
    layoutOptions?: IColumnOption;
    more?: ReactNode;
    radio?: number;
    title?: string;
    value?: ReactNode;
}

const FinanceLink: React.FC = () => {
    const routerStore = useRouterStore();
    const clickLinkMore = (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>): void => {
        event.preventDefault();
        routerStore.goTo('Finances');
    };
    return (
        <a onClick={clickLinkMore} href={`/finances`}>
            {tI('More...')}
        </a>
    );
};

export const DashboardInfoCard: React.FC<IInfoCard> = ({
    idSpinners = ['GET_ACTIVITY_ROLL'],
    isBigQuery = false,
    more = <FinanceLink />,
    radio = 0,
    title = ' ',
    value = ' ',
}: IInfoCard) => {
    const description = tI('From last Month');
    const showRadio = isBigQuery ? `${Math.abs(radio * 100).toFixed(2)}%` : Math.abs(radio).toFixed(2);
    return (
        <Catcher>
            <Col className={Styles.cardPlace}>
                <div className={Styles.infoCard} data-test="dashboardInfoCard">
                    <LoadingApiByIds idSpinners={idSpinners} />
                    <div className={Styles.info}>
                        <div className={Styles.title}>{title}</div>
                        <div className={Styles.value} data-test="dashboardInfoCardValue">
                            {value}
                        </div>
                        <div className={Styles.footer}>
                            <div className={Styles.radioValue} title={description}>
                                <div
                                    className={radio < 0 ? Styles.radioNegative : Styles.radioPositive}
                                    title={description}
                                >
                                    {radio === 0 ? (
                                        <MinusOutlined />
                                    ) : radio > 0 ? (
                                        <CaretUpOutlined />
                                    ) : (
                                        <CaretDownOutlined />
                                    )}
                                    {showRadio}
                                </div>
                            </div>
                            <div className={Styles.description} title={description}>
                                {description}
                            </div>
                            <div className={Styles.more}>{more}</div>
                        </div>
                    </div>
                </div>
            </Col>
        </Catcher>
    );
};
