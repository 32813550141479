import React from 'react';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import { PlusOutlined, RightCircleOutlined } from '@ant-design/icons';
import cx from 'classnames';
import { addPurchaseByZone } from '../../../middleware';
import { tB, tI } from '../../../translate';
import Styles from '../../styles/m_itemCardStyles.less';
import PurchaseListStyles from '../m_styles.less';

interface IEditRateZoneTitle {
    zoneId: string;
    name: string;
    visibleChange(): void;
    isVisible: boolean;
    updateForm?: () => void;
    length: number;
}

export const EditPurchaseZoneTitle: React.FC<IEditRateZoneTitle> = observer(
    ({ zoneId, name, visibleChange, isVisible, updateForm, length }: IEditRateZoneTitle) => {
        const addPurchaseZoneRate = (): void => {
            updateForm && updateForm();
            addPurchaseByZone(zoneId);
            !isVisible && visibleChange();
        };

        return (
            <div className={cx(Styles.title, { [Styles.titleOnly]: !isVisible })}>
                <div className={Styles.collapseIconPlace} title={isVisible ? tI('Hide') : tI('Show')}>
                    <RightCircleOutlined
                        onClick={visibleChange}
                        className={cx(Styles.collapseIcon, {
                            [Styles.hideIcon]: isVisible,
                            [Styles.showIcon]: !isVisible,
                        })}
                    />
                </div>
                <div className={cx(Styles.toolBar, PurchaseListStyles.addCountryToolBar)}>
                    <div className={Styles.labelUrl} onClick={visibleChange}>
                        {name} - {tI('countries')}({length})
                    </div>
                    <Button
                        onClick={addPurchaseZoneRate}
                        icon={<PlusOutlined />}
                        type="primary"
                        className={Styles.addButton}
                    >
                        {tB('Add')}
                    </Button>
                </div>
            </div>
        );
    },
);
