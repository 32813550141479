import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Col, Layout, Row } from 'antd';
import { Catcher, MainMenu } from '../';
import cx from 'classnames';
import { IDivMainProps } from '../../interfaces';
import Styles from './m_styles.less';

export const DesktopMenu: React.FC<IDivMainProps> = observer(({ className }: IDivMainProps) => {
    const [collapsed, setCollapsed] = useState(false);
    const { Sider } = Layout;
    const onChangeCollapse = (): void => {
        setCollapsed(!collapsed);
    };

    return (
        <Catcher>
            <Row>
                <Col xs={0} sm={24}>
                    <Sider collapsed={collapsed} collapsedWidth={80} className={cx(Styles.main, className)} width={270}>
                        <MainMenu menuType={'DesktopMenu'} onChangeCollapse={onChangeCollapse} collapsed={collapsed} />
                    </Sider>
                </Col>
            </Row>
        </Catcher>
    );
});
