import React, { MouseEvent } from 'react';
import { observer } from 'mobx-react';
import { Table, Tooltip } from 'antd';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { Catcher, LinkComponent, LoadingApiByIds, columnToolbar } from '../../';
import { IPackageInfo } from '../../../interfaces';
import { usePackagesForSale, useRouterStore } from '../../../hooks';
import { tF, tI } from '../../../translate';
import Styles from '../../SubComponents/m_styles.less';

export const TableForBuy: React.FC = observer(() => {
    const data = usePackagesForSale();

    const tableColumns = [
        {
            title: tF('Package Name'),
            dataIndex: 'packageLink',
        },
        {
            title: tF('Offered By'),
            dataIndex: 'companyLink',
        },
        {
            title: tF('Price'),
            dataIndex: 'price',
        },
        {
            title: tF('Currency'),
            dataIndex: 'currencyName',
        },
        {
            title: tF('Data volume'),
            dataIndex: 'dataVolumeName',
        },
        {
            title: tF('Duration'),
            dataIndex: 'monthDurationName',
        },
        {
            title: tF('Zone'),
            dataIndex: 'zoneLink',
        },
        {
            title: tF('Credit Remains'),
            dataIndex: 'creditRemainsName',
        },
        columnToolbar,
    ];

    const routerStore = useRouterStore();

    const goTo = (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>, id: string): void => {
        event.preventDefault();
        routerStore.goTo('PackageInstanceBuy', { params: { id } }).then();
    };

    const tableData = data?.map((p: IPackageInfo) => {
        const { name, id, companyId, companyName, ...rows } = p;

        return {
            key: p.id,
            ...rows,
            packageLink: <LinkComponent currentListComponent={'PackagesUnitsLogsList'} id={id} name={name} />,
            companyLink: <LinkComponent currentListComponent={'CompaniesList'} id={companyId} name={companyName} />,
            zoneLink: <LinkComponent currentListComponent={'ZonesList'} id={p.zoneId} name={p.zoneName} />,
            toolbar: p?.id ? (
                <Tooltip title={`${tI('Buy')} ${p?.name}`}>
                    <a onClick={(e): void => goTo(e, p.id ? p.id : '')}>
                        <ShoppingCartOutlined className={Styles.button} />
                    </a>
                </Tooltip>
            ) : null,
        };
    });

    return (
        <Catcher>
            <div>
                <LoadingApiByIds idSpinners={['GET_PACKAGES_TO_BUY']} />
                <Table
                    className={'table-theme'}
                    columns={tableColumns}
                    dataSource={tableData}
                    pagination={false}
                    size={'middle'}
                />
            </div>
        </Catcher>
    );
});
