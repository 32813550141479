import React from 'react';
import { observer } from 'mobx-react';
import { Form, Select, Table } from 'antd';
import { IOperatorsTableListItem, ISelect } from '../../../interfaces';
import { useOperatorNetworksGatewaySelect, useOperatorsTable, useUiIsLoading, useUiSearch } from '../../../hooks';
import {
    addNotification,
    cleanOperatorStore,
    sendOperatorInfo,
    setOperatorForm,
    setOperatorsForcedUpdateTime,
} from '../../../middleware';
import { lightText } from '../../../instruments';
import { tF } from '../../../translate';
import { Catcher, FormSelectLight, LinkComponent } from '../../../Components';
import '../../../customStyle/table.less';
import Styles from '../../styles/m_viewStyles.less';

export const GateConfigTable: React.FC = observer(() => {
    const isLoading = useUiIsLoading();
    const operatorsList = useOperatorsTable();
    const search = useUiSearch();
    const select = useOperatorNetworksGatewaySelect();
    const { Option } = Select;

    const tableColumns = [
        {
            title: tF('Name'),
            dataIndex: 'nameLink',
        },
        {
            title: tF('Tadig'),
            dataIndex: 'tadig',
        },
        {
            title: tF('MCC/MNC'),
            dataIndex: 'mccMnc',
        },
        {
            title: 'Selected Gateway',
            dataIndex: 'selectedGateway',
        },
    ];

    const handleChangeGateway = async (value: string, id: string) => {
        const gateway = value as string;
        setOperatorForm({ id, gateway });
        const { isError } = await sendOperatorInfo(false);

        if (isError) {
            addNotification({
                type: 'error',
                message: 'There was an error while assigning the gateway',
            });
        } else {
            addNotification({
                type: 'success',
                message: 'The gateway has been successfully assigned',
            });
        }

        cleanOperatorStore();
        setOperatorsForcedUpdateTime();
    };

    const tableData = operatorsList.map((o: IOperatorsTableListItem) => {
        const name = o.name ? o.name : `# ${o.id}`;

        const selectJSX = select?.map(
            (s: ISelect): JSX.Element => (
                <Option key={s.id} value={s.id} disabled={s.disabled}>
                    <div>{s.name}</div>
                </Option>
            ),
        );

        return {
            key: o.id,
            ...o,
            nameLink: <LinkComponent currentListComponent={'OperatorsList'} id={o.id} isLight name={name} />,
            tadig: lightText(o.tadig, search),
            mccMnc: lightText(o.mccMnc, search),
            selectedGateway: (
                <Form style={{ width: '300px' }}>
                    <FormSelectLight
                        initialValue={o.gateway}
                        selectJSX={selectJSX}
                        onChange={(e) => handleChangeGateway(e, o.id)}
                        fieldName={'gateway'}
                    />
                </Form>
            ),
        };
    });

    return (
        <Catcher>
            <div className={Styles.tablePlace}>
                <Table
                    className={'table-theme'}
                    columns={tableColumns}
                    dataSource={tableData}
                    loading={isLoading}
                    pagination={false}
                    size={'middle'}
                />
            </div>
        </Catcher>
    );
});
