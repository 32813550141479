import { getCurrencyShortCodeOrCodeById } from './getCurrencyShortCodeById';
import { sortByString } from './index';
import { mapApi2UiField } from './mapField';
import { IStatItem, IStatItemAPI } from '../interfaces';

export const mapStatApiItems = (statsApi?: Array<IStatItemAPI>): Array<IStatItem> => {
    return (
        statsApi
            ?.map((i: IStatItemAPI) => {
                const item: IStatItem = mapApi2UiField(i);
                if (!item.currencySymbol && item.currencyId) {
                    item.currencySymbol = getCurrencyShortCodeOrCodeById(item.currencyId.toString());
                }
                return item;
            })
            .sort((a: IStatItem, b: IStatItem) => sortByString(a.currencySymbol, b.currencySymbol)) || []
    );
};
