import React, { useEffect, useState } from 'react';
import { Row } from 'antd';
import { Catcher, ExternalAPIFlow, rowGutter } from '../../';
import { IGetApiType } from '../../../REST';
import { useRouterStore } from '../../../hooks';
import { abortSelectedFetch, cleanSubscriberStore, getSubscriber } from '../../../middleware';
import Styles from '../../Dashboard/m_dashboarStyles.less';
import StylesCard from '../../styles/m_itemCardStyles.less';

export const ExternalAPIEdit: React.FC = () => {
    const routerStore = useRouterStore();
    const [id, setId] = useState('');
    const usedApiTypes: IGetApiType[] = ['GET_NETWORKS_SELECT', 'GET_SUBSCRIBER', 'POST_SUBSCRIBERS_API_REQUEST'];

    const goOut = (): void => {
        abortSelectedFetch(usedApiTypes);
        cleanSubscriberStore();
    };

    const goToList = (): void => {
        routerStore.goTo('ExternalAPIList');
        goOut();
    };

    const _getSubscriber = async (id: string): Promise<void> => {
        const isGetSubscriber = await getSubscriber(id);
        if (!isGetSubscriber) {
            goToList();
        }
    };

    useEffect(() => {
        if (routerStore.routerState.params.id) {
            setId(routerStore.routerState.params.id);
        }
    }, [routerStore.routerState.params.id]);

    useEffect(() => {
        if (id) {
            _getSubscriber(id);
        }
    }, [id]);

    return (
        <Catcher>
            <article className={StylesCard.main}>
                <Row gutter={rowGutter.card} className={Styles.cardsRow}>
                    <ExternalAPIFlow />
                </Row>
            </article>
        </Catcher>
    );
};
