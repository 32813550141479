import React from 'react';
import { observer } from 'mobx-react';
import { Catcher, ListWrapper } from '../';
import { IGetApiType } from '../../REST';
import { useCardsFilterParams, useProfileIsRight } from '../../hooks';
import { cleanCardsStore, getCardsRelationSelect } from '../../middleware';
import { CardTableList } from './Components';

export const CardList: React.FC = observer(() => {
    const uploadApiType = 'POST_CARDS_LIST_CSV';
    const idSpinners: IGetApiType[] = ['GET_CARD_LIST_COUNT', 'GET_CARD_LIST', uploadApiType];
    const filterParams = useCardsFilterParams();
    const isLord = useProfileIsRight('LORD');

    return (
        <Catcher>
            <ListWrapper
                cleanStore={cleanCardsStore}
                filterParams={filterParams}
                getRelationSelect={getCardsRelationSelect}
                idSpinners={idSpinners}
                uploadApiType={isLord ? uploadApiType : ''}
            >
                <CardTableList />
            </ListWrapper>
        </Catcher>
    );
});
