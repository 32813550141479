import { action, computed, makeObservable, observable } from 'mobx';
import { UiStore } from './ui';
import { IGetApiType } from '../REST';
import { IAboutInfo, IFeatureFlags, IUser, IUserAlarms } from '../interfaces';

export class ProfileStore {
    private constructor() {
        makeObservable(this);
    }

    private static instance: ProfileStore;
    public static getInstance(): ProfileStore {
        if (!ProfileStore.instance) {
            ProfileStore.instance = new ProfileStore();
        }
        return ProfileStore.instance;
    }

    initProfileStore: IUser = {
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        userType: '',
        func: '',
    };

    @observable profile: IUser = this.initProfileStore;
    @action setProfile(profile: IUser): void {
        this.profile = profile;
    }

    @observable about: IAboutInfo = {};
    @action setAbout(about: IAboutInfo): void {
        this.about = about;
    }

    @observable spinners: IGetApiType[] = ['GET_USER_PROFILE'];
    @computed get isSpinning(): boolean {
        return UiStore.getInstance().isFoundSpinner(this.spinners);
    }

    @observable alarms: IUserAlarms = {};
    @action setUserAlarms(alarms: IUserAlarms): void {
        this.alarms = alarms;
    }

    @observable profileFeatureFlags: IFeatureFlags = {};
    @action setProfileFeatureFlags(featureFlags: IFeatureFlags): void {
        this.profileFeatureFlags = featureFlags;
    }

    @action cleanStore(): void {
        this.profile = this.initProfileStore;
        this.about = {};
        this.alarms = {};
        this.profileFeatureFlags = {};
    }
}
