import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Catcher, Loading } from '../';
import { useRouterStore } from '../../hooks';

export const Zone: React.FC = observer(() => {
    const routerStore = useRouterStore();
    const [zoneId, setZoneId] = useState<string>();
    // const usedApiTypes: IGetApiType[] = ['GET_ZONE'];

    useEffect(() => {
        const { params, routeName } = routerStore.routerState;
        if (routeName === 'Zone' && params.id) {
            setZoneId(params.id);
            routerStore.goTo('CountryList', { queryParams: { zoneId: params.id } }).then();
        }
        return (): void => {
            // abortSelectedFetch(usedApiTypes);
        };
    }, [routerStore.routerState.routeName]);

    return (
        <Catcher>
            <div>
                {'Zone id'}: {zoneId}
            </div>
            <Loading isLoading tip={'Routing to company list with zone filter.'} />
        </Catcher>
    );
});
