import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Checkbox, Form, Row } from 'antd';
import { FieldData, Rule } from 'rc-field-form/es/interface';
import { IAntDFormChangeFieldNameLong, IEditForm, IPackageForm, IPackageInfo } from '../../../interfaces';
import {
    useBytesSelect,
    useCompaniesChildrenSelect,
    useCompaniesSelect,
    useCountriesSelect,
    useCurrencySelect,
    useOperatorsNetworksSelect,
    useOperatorsSelect,
    usePackageInfo,
    useProfile,
    useProfileIsRight,
    useRouterState,
    useZonesSelect,
} from '../../../hooks';
import {
    apiMessagesGetFormRules,
    apiMessagesGetFormRulesFieldBlank,
    apiMessagesSaveMessages,
    cleanApiMessages,
    getCompaniesChildrenSelect,
    getCountriesSelect,
    getNetworksSelect,
    getOperatorsSelect,
    rulesIsNumber,
    savePackage,
} from '../../../middleware';
import { onFieldErrorHandle } from '../../../instruments';
import { tF } from '../../../translate';
import {
    CardInfoToolBar,
    CardMainLayout,
    Catcher,
    FormSelect,
    InputDebounce,
    TextAreaDebounce,
    cardLayoutOptions,
} from '../../../Components';
import { InputWithCheckbox } from './../Components';

interface IPackageInitialState extends IPackageInfo {
    assignedCompaniesId: string[];
    assignedCountriesId?: string[];
    assignedOperatorsId?: string[];
    assignedNetworksId?: string[];
}

export const EditPackage: React.FC<IEditForm> = observer(({ setEditMode, isNew = false }: IEditForm) => {
    const [form] = Form.useForm();
    const [isSending, setSending] = useState(false);
    const [currentZoneId, setCurrentZoneId] = useState('');
    const [isCountryChanged, setIsCountriesChanged] = useState(false);
    const [isZoneChanged, setIsZoneChanged] = useState(false);
    const [currentCountryMccArr, setCountryMccArr] = useState<(string | undefined)[]>([]);
    const [newCompanyId, setNewCompanyId] = useState('');
    const routerState = useRouterState();
    const countrySelect = useCountriesSelect();
    const {
        assignedCompanies,
        assignedCountries,
        assignedOperators,
        assignedNetworks,
        monthDuration,
        zoneId,
        ...packageInfo
    } = usePackageInfo();
    const { companyId } = packageInfo;
    const isLord = useProfileIsRight('LORD');
    const { companyId: profileCompanyId } = useProfile();
    const initialValues: IPackageInitialState = {
        zoneId,
        monthDuration,
        assignedCountriesId: assignedCountries?.assignedId,
        assignedOperatorsId: assignedOperators?.assignedId,
        assignedNetworksId: assignedNetworks?.assignedId,
        ...packageInfo,
    } as IPackageInitialState;
    const validateNumber: Array<Rule> = [rulesIsNumber, ...apiMessagesGetFormRules];

    if (isNew) {
        initialValues.companyId = profileCompanyId;
    }

    useEffect(() => {
        if (!isNew && newCompanyId === companyId) {
            initialValues.assignedCompaniesId = assignedCompanies?.assignedId || [];
        }

        if (isZoneChanged || isCountryChanged) {
            delete initialValues.assignedCountriesId;
            delete initialValues.assignedOperatorsId;
            delete initialValues.assignedNetworksId;
        }
    });

    const checkValid = (): void => {
        form.validateFields();
    };

    const handleZoneChange = (ids: Array<string> | string): void => {
        setCountryMccArr([]);
        setCurrentZoneId(Array.isArray(ids) ? ids[0] : ids);
        setIsZoneChanged(true);
    };

    const changeMccArr = (initFetch = false): void => {
        let idArr: string[] = [];

        if (!initFetch) {
            idArr = form.getFieldsValue(['assignedCountriesId'])?.assignedCountriesId || [];
        } else {
            idArr = assignedCountries?.assignedId || [];
        }

        const mccArr = countrySelect.filter((i) => idArr?.includes(i.id)).map((i) => i.mcc);

        setCountryMccArr(mccArr);
    };

    const handleCountriesChange = (): void => {
        changeMccArr();
        setIsCountriesChanged(true);
    };

    useEffect(() => {
        form.setFieldsValue(initialValues);
    }, [JSON.stringify(initialValues)]);

    useEffect(() => {
        getNetworksSelect();
    }, []);

    useEffect(() => {
        setNewCompanyId(companyId || '');
    }, [companyId]);

    useEffect(() => {
        setCurrentZoneId(zoneId || '');
    }, [zoneId]);

    useEffect(() => {
        changeMccArr(true);
    }, [JSON.stringify(assignedCountries?.assignedId)]);

    useEffect(() => {
        form.resetFields(['assignedCountriesId']);
        form.resetFields(['assignedOperatorsId']);
        getCountriesSelect({ zoneId: currentZoneId || zoneId });
    }, [currentZoneId, zoneId]);

    useEffect(() => {
        form.resetFields(['assignedOperatorsId']);
        getOperatorsSelect(false, false, currentCountryMccArr as string[]);
    }, [JSON.stringify(currentCountryMccArr)]);

    useEffect(() => {
        form.resetFields(['assignedCompaniesId']);
        if (newCompanyId) {
            getCompaniesChildrenSelect(newCompanyId, true);
        } else if (companyId) {
            getCompaniesChildrenSelect(companyId, true);
        } else if (profileCompanyId) {
            getCompaniesChildrenSelect(profileCompanyId, true);
        }
    }, [newCompanyId]);

    async function save(): Promise<void> {
        setSending(true);
        const { params } = routerState;
        const values = form.getFieldsValue();
        const formValues: IPackageForm = { ...values, id: params?.id } as IPackageForm;
        const { isError, errors = [] } = await savePackage(formValues, isNew);
        if (isError) {
            cleanApiMessages();
            apiMessagesSaveMessages(errors);
            checkValid();
        } else {
            setEditMode && setEditMode(false);
        }
        setSending(false);
    }

    function onFieldChange(fields: FieldData[]): void {
        if (fields[0]?.name) {
            const { name } = fields[0];
            const fieldName = name as IAntDFormChangeFieldNameLong;
            onFieldErrorHandle(fieldName[0]);
            if (fieldName[0] === 'companyId') {
                setNewCompanyId(fields[0]?.value);
            }
        }
    }

    const nameTitle = tF('Package name');
    const priceTitle = tF('Price');
    const dataVolumeTitle = tF('Data volume');
    const durationDaysTitle = tF('Duration (days)');
    const noteTitle = tF('Notes');

    return (
        <Catcher>
            <Row>
                <CardMainLayout
                    columnOption={cardLayoutOptions.packageCard}
                    idSpinners={[
                        'GET_COMPANIES_CHILDREN_SELECT',
                        'GET_COMPANIES_SELECT',
                        'GET_COMPANIES_SELECT',
                        'GET_CURRENCY_SELECT',
                        'GET_CURRENCY_SELECT',
                        'GET_ONE_PACKAGE',
                        'GET_ZONE_SELECT',
                        'GET_ZONE_SELECT',
                    ]}
                    name={initialValues.name}
                    dataTest={'EditPackage'}
                >
                    <Form
                        form={form}
                        name="package"
                        initialValues={initialValues}
                        onFinish={save}
                        onFieldsChange={onFieldChange}
                        scrollToFirstError={true}
                        layout={'vertical'}
                        className={'formStyle'}
                    >
                        <Form.Item name="name" label={nameTitle} rules={apiMessagesGetFormRulesFieldBlank()}>
                            <InputDebounce placeholder={nameTitle} isFirstInput data-test={'EditPackageNameInput'} />
                        </Form.Item>
                        <FormSelect
                            fieldName={'companyId'}
                            label={tF('Company')}
                            rules={apiMessagesGetFormRules}
                            useSelectHook={useCompaniesSelect}
                            disabled={!isLord}
                            isNotClear={true}
                            dataTest={'EditPackageCompany'}
                        />
                        <FormSelect
                            fieldName={'assignedCompaniesId'}
                            label={tF('Assign package to company or companies')}
                            mode={'multiple'}
                            rules={apiMessagesGetFormRulesFieldBlank()}
                            useSelectHook={useCompaniesChildrenSelect}
                            isNotClear={true}
                            dataTest={'EditPackageAssignedCompanies'}
                        />
                        <Form.Item name="price" label={priceTitle} rules={validateNumber}>
                            <InputDebounce
                                placeholder={priceTitle}
                                data-test={'EditPackagePriceInput'}
                                addonBefore={
                                    <FormSelect
                                        fieldName={'currencyId'}
                                        isNotClear={true}
                                        rules={apiMessagesGetFormRulesFieldBlank()}
                                        useSelectHook={useCurrencySelect}
                                        isAddonBefore={true}
                                        dataTest={'EditPackagePriceCurrency'}
                                    />
                                }
                            />
                        </Form.Item>
                        <Form.Item name="dataVolume" label={dataVolumeTitle} rules={validateNumber}>
                            <InputDebounce
                                data-test={'EditPackageDataVolumeInput'}
                                addonBefore={
                                    <FormSelect
                                        fieldName={'dataVolumeSelect'}
                                        isNotClear={true}
                                        rules={apiMessagesGetFormRulesFieldBlank()}
                                        useSelectHook={useBytesSelect}
                                        isAddonBefore={true}
                                        dataTest={'EditPackageDataVolume'}
                                    />
                                }
                                placeholder={dataVolumeTitle}
                            />
                        </Form.Item>
                        <InputWithCheckbox
                            elementName={'duration'}
                            initCheckValue={monthDuration}
                            inputProps={{
                                placeholder: durationDaysTitle,
                            }}
                            checkBoxName={'monthDuration'}
                            checkBoxLabel={tF('Until the end of the month')}
                            validatorsArr={[...validateNumber, ...apiMessagesGetFormRulesFieldBlank()]}
                            dataTest={'EditPackageMonthDuration'}
                        />
                        <FormSelect
                            fieldName={'zoneId'}
                            label={tF('Zone')}
                            onChange={handleZoneChange}
                            rules={apiMessagesGetFormRulesFieldBlank()}
                            useSelectHook={useZonesSelect}
                            dataTest={'EditPackageZone'}
                        />
                        <FormSelect
                            fieldName={'assignedCountriesId'}
                            label={tF('Assign countries')}
                            onChange={handleCountriesChange}
                            mode={'multiple'}
                            useSelectHook={useCountriesSelect}
                            isNotClear={true}
                            dataTest={'EditPackageAssignedCountries'}
                        />
                        <FormSelect
                            fieldName={'assignedOperatorsId'}
                            label={tF('Assign operators')}
                            mode={'multiple'}
                            useSelectHook={useOperatorsSelect}
                            isNotClear={true}
                            dataTest={'EditPackageAssignedOperators'}
                        />
                        <FormSelect
                            fieldName={'assignedNetworksId'}
                            label={tF('Assign networks')}
                            mode={'multiple'}
                            useSelectHook={useOperatorsNetworksSelect}
                            isNotClear={true}
                            dataTest={'EditPackageAssignedNetworks'}
                        />
                        <Form.Item name="notes" label={noteTitle} rules={apiMessagesGetFormRules}>
                            <TextAreaDebounce placeholder={noteTitle} data-test={'EditPackageNotesInput'} />
                        </Form.Item>
                        <Form.Item name="creditRemains" valuePropName="checked" rules={apiMessagesGetFormRules}>
                            <Checkbox data-test={'EditPackageCreditRemainsInput'}>{tF('Credit Remains')}</Checkbox>
                        </Form.Item>
                        <CardInfoToolBar isLoading={isSending} isNew={isNew} isSubmitButton dataTest={'EditPackage'} />
                    </Form>
                </CardMainLayout>
            </Row>
        </Catcher>
    );
});
