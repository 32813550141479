import type { RangePickerProps } from 'antd/es/date-picker';
import { api2UiValue } from './mapField';
import dayjs, { Dayjs } from 'dayjs';
import { FieldData, NamePath } from 'rc-field-form/es/interface';
import { IAntDFormChangeFieldNameLong, IAntDFormChangeFieldNameShort, IListIds } from '../interfaces';
import {
    apiMessagesDeleteListItemMessage,
    apiMessagesDeleteMessageByFieldName,
    apiMessagesDeleteMessageByListFieldId,
} from '../middleware';

interface IOnFieldsChange {
    changedFields: FieldData[];
    initialValues: any;
    listIds?: IListIds;
    setForm?(updatedValues: any): void;
    checkValid?(): void;
    rateFormId?: string;
}

export const onFieldErrorHandle = (changedFiledName: string): void => {
    apiMessagesDeleteMessageByFieldName([changedFiledName]);
};

export const onFormFieldsChange = ({
    changedFields,
    initialValues,
    listIds,
    setForm = (): void => {
        console.warn('Not set setForm function!');
    },
    checkValid,
    rateFormId,
}: IOnFieldsChange): void => {
    if (changedFields.length === 1) {
        const updatedValues = Object.assign({}, initialValues);
        const field: FieldData = changedFields[0];

        const value =
            field.value === undefined ||
            field.value === null ||
            typeof field.value === 'boolean' ||
            dayjs.isDayjs(field.value)
                ? field.value
                : field.value.toString();

        const fieldName: NamePath = field.name;
        if (Array.isArray(fieldName)) {
            if (fieldName.length === 3) {
                if (listIds) {
                    const name = fieldName as IAntDFormChangeFieldNameLong;
                    apiMessagesDeleteMessageByListFieldId({ field: name, listIds });
                    const arr: string = name[0];
                    const i: number = name[1];
                    const n: string = name[2];
                    updatedValues[arr] = updatedValues[arr].map((item: object, index: number) => {
                        if (index === i) {
                            return Object.assign({}, item, { [n]: api2UiValue(n, value) });
                        } else {
                            return item;
                        }
                    });
                } else {
                    console.warn('Field "' + fieldName + '" not set listIds');
                }
            } else if (fieldName.length === 1 && rateFormId) {
                const name = fieldName as IAntDFormChangeFieldNameShort;
                const longFiled: IAntDFormChangeFieldNameLong = ['salesRateListEntry', 0, name[0]];
                apiMessagesDeleteMessageByListFieldId({
                    field: longFiled,
                    listIds: {
                        salesRateListEntry: [rateFormId],
                    },
                });
                const nameText = name[0];
                updatedValues[nameText] = api2UiValue(nameText, value);
            } else if (fieldName.length === 1) {
                const name = fieldName as IAntDFormChangeFieldNameShort;
                apiMessagesDeleteMessageByFieldName(name);
                const nameText = name[0];
                updatedValues[nameText] = api2UiValue(nameText, value);
            } else {
                console.warn('Field "' + fieldName + '" not found format field ');
            }
            if (JSON.stringify(initialValues) !== JSON.stringify(updatedValues)) {
                setForm(updatedValues);
                if (checkValid) {
                    checkValid();
                }
            }
        } else {
            console.warn('Field "' + fieldName + '" is not Array');
        }
    }
};

export const removePurchaseFormError = ({ changedFields, listIds, checkValid, rateFormId }: IOnFieldsChange): void => {
    if (changedFields.length === 1) {
        const field: FieldData = changedFields[0];
        const fieldName: NamePath = field.name;
        if (Array.isArray(fieldName)) {
            if (fieldName.length === 3) {
                if (listIds) {
                    const name = fieldName as IAntDFormChangeFieldNameLong;
                    const listId = listIds[name[0]][name[1]];
                    apiMessagesDeleteListItemMessage({ listName: 'purchaseRateListEntry', listId, listField: name[2] });
                } else {
                    console.warn('Field "' + fieldName + '" not set listIds');
                }
            } else if (fieldName.length === 1 && rateFormId) {
                const name = fieldName as IAntDFormChangeFieldNameShort;
                const longFiled: IAntDFormChangeFieldNameLong = ['purchaseRateListEntry', 0, name[0]];
                apiMessagesDeleteMessageByListFieldId({
                    field: longFiled,
                    listIds: {
                        purchaseRateListEntry: [rateFormId],
                    },
                });
                checkValid && checkValid();
            } else if (fieldName.length === 1) {
                const name = fieldName as IAntDFormChangeFieldNameShort;
                apiMessagesDeleteMessageByFieldName(name);
            } else {
                console.warn('Field "' + fieldName + '" not found format field ');
            }
        } else {
            console.warn('Field "' + fieldName + '" is not Array');
        }
    }
};

export const removeSingleFormError = (changedFields: FieldData[]): void => {
    if (changedFields.length === 1) {
        const fieldName = changedFields[0]?.name;
        if (Array.isArray(fieldName) && fieldName.length === 1) {
            const name = fieldName as IAntDFormChangeFieldNameShort;
            apiMessagesDeleteMessageByFieldName(name);
        } else {
            console.warn('Field "' + fieldName + '" not found format field ');
        }
    }
};

export const disablePreviousDatesOLD = (current: Dayjs): boolean => current && current < dayjs().startOf('day');

export const disablePreviousDates: RangePickerProps['disabledDate'] = (current) => {
    return current && current < dayjs().startOf('day');
};
