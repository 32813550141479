import React from 'react';
import { Form, Row } from 'antd';
import { Catcher } from '../../';
import { EditRareFormMain, EditSalesRateList } from './';
import { IEditForm } from '../../../interfaces';
import Styles from '../../styles/m_itemCardStyles.less';

export const EditRareForms: React.FC<IEditForm> = ({ setEditMode, isNew = false, loadingTypes }: IEditForm) => {
    return (
        <Catcher>
            <div className={Styles.main}>
                <Form.Provider>
                    <EditRareFormMain setEditMode={setEditMode} isNew={isNew} loadingTypes={loadingTypes} />

                    <Row>
                        <EditSalesRateList />
                    </Row>
                </Form.Provider>
            </div>
        </Catcher>
    );
};
