import React, { ChangeEvent, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Col, Input, Pagination, Row, Select } from 'antd';
import { Catcher, TitleCard, cardLayoutOptions, filterOption, rowGutter } from '../../';
import cx from 'classnames';
import { appConfig } from '../../../settings';
import { ILocalPagination } from '../../../interfaces';
import { useCompaniesSelect, useProfileIsRight, useRouterStore, useUiPageSize } from '../../../hooks';
import { addNotification } from '../../../middleware';
import { tI, tP } from '../../../translate';
import FilterStyles from '../../FilterToolbar/m_styles.less';
import Styles from '../../styles/m_viewStyles.less';

export const PackageTableComponent: React.FC<ILocalPagination> = observer(
    ({ Table, useTotalHook, fetchRequest, title, newComponent }: ILocalPagination) => {
        const routerStore = useRouterStore();
        const total = useTotalHook();
        const defaultPageSize = useUiPageSize();
        const [pageNumber, changeCurrentPage] = useState(1);
        const [pageSize, changePageSize] = useState(defaultPageSize);
        const [search, changeSearch] = useState('');
        const [isSearching, changeIsSearching] = useState(false);
        const [companyId, changeCompanyId] = useState('');
        const isLord = useProfileIsRight('LORD');
        const companies = useCompaniesSelect();

        const { Option } = Select;
        const { Search } = Input;

        const itemColOptions = cardLayoutOptions.filterToolBarFieldCol;

        const pageSizeOptionsJSX = appConfig.pageDefaultParams.pageSizeValues.map(
            (i: number): JSX.Element => (
                <Option key={i} value={i}>
                    <div>{i}</div>
                </Option>
            ),
        );

        const companiesSelectJSX = companies.map((value) => {
            return (
                <Option key={value.id} value={value.id}>
                    <div>{value.name}</div>
                </Option>
            );
        });

        const pageSizeOptions = appConfig.pageDefaultParams.pageSizeValues.map((i: number) => i.toString());

        const onPageChange = (pageNumber: number): void => {
            changeCurrentPage(pageNumber);
        };

        const onChangeSearchInput = (search = ''): void => {
            changeSearch(search);
        };

        const onSearch = (): void => {
            changeIsSearching(true);
        };

        const onChangePageSize = (pageSize: number): void => {
            changePageSize(pageSize);
        };

        const onChangeCompany = (id: string): void => {
            changeCompanyId(id);
        };

        const fetchPackages = async (): Promise<void> => {
            const params = {
                searchParamsList: {
                    pageNumber,
                    pageSize,
                    search,
                    companyId,
                },
            };
            const isError = await fetchRequest(params);

            if (isError) {
                addNotification({
                    type: 'error',
                    message: 'There was an error while fetching data.',
                    isUsersError: true,
                });
            }

            changeIsSearching(false);
        };

        useEffect(() => {
            fetchPackages();
        }, []);

        useEffect(() => {
            fetchPackages();
        }, [pageSize, pageNumber, companyId]);

        useEffect(() => {
            if (isSearching) {
                fetchPackages();
            }
        }, [isSearching]);

        useEffect(() => {
            if (pageNumber !== 1 && Math.ceil(total / pageSize) < pageNumber) {
                changeCurrentPage(Math.max(Math.ceil(total / pageSize), 1));
            }
        }, [total]);

        const filterCompanyTitle = tI('Company Filter');
        const searchTitle = tI('Search');

        return (
            <Catcher>
                <TitleCard
                    title={title ? tP(title) : undefined}
                    right={` (${total})`}
                    onClickAddButton={
                        newComponent
                            ? (): void => {
                                  routerStore.goTo(newComponent);
                              }
                            : undefined
                    }
                    isTableCard
                />
                <div className={cx(FilterStyles.filterToolbarPlace, 'formStyle')}>
                    <Row
                        className={FilterStyles.filterToolbar}
                        justify={'space-between'}
                        gutter={rowGutter.filterToolBarFieldCol}
                    >
                        <Col className={FilterStyles.col} {...itemColOptions}>
                            <div className={FilterStyles.labelFieldPrefix}>{tI('Show')}</div>
                            <Select
                                className={FilterStyles.fieldPageSize}
                                value={pageSize}
                                onChange={onChangePageSize}
                                placeholder={tI('Page size')}
                            >
                                {pageSizeOptionsJSX}
                            </Select>
                            <div className={FilterStyles.labelFieldSuffix}>{tI('entries')}</div>
                        </Col>
                        {isLord ? (
                            <Col className={FilterStyles.col} {...itemColOptions}>
                                <div className={FilterStyles.labelFieldPrefix}>{filterCompanyTitle}</div>
                                <Select
                                    allowClear
                                    className={FilterStyles.fieldFull}
                                    filterOption={filterOption}
                                    getPopupContainer={(trigger): HTMLElement => trigger.parentElement}
                                    onChange={onChangeCompany}
                                    placeholder={filterCompanyTitle}
                                    showSearch
                                >
                                    {companiesSelectJSX}
                                </Select>
                            </Col>
                        ) : null}
                        <Col className={FilterStyles.col} {...itemColOptions}>
                            <div className={FilterStyles.labelFieldPrefix}>{searchTitle}</div>
                            <Search
                                allowClear
                                placeholder={searchTitle}
                                onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                                    onChangeSearchInput(e.target.value)
                                }
                                onSearch={onSearch}
                                className={cx(FilterStyles.fieldFull, 'input-search')}
                            />
                        </Col>
                    </Row>
                </div>
                <div className={Styles.tablePlace}>
                    <Table pageSize={pageSize} pageNumber={pageNumber} search={search} />
                </div>
                <Pagination
                    className={'customPagination'}
                    current={pageNumber}
                    pageSize={pageSize}
                    total={total}
                    pageSizeOptions={pageSizeOptions}
                    onChange={onPageChange}
                    showTotal={(total: number, range: Array<number>): JSX.Element => (
                        <div>
                            {range[0]}-{range[1]} {tI('of')} {total} {tI('items')}
                        </div>
                    )}
                    showSizeChanger={false}
                />
            </Catcher>
        );
    },
);
