import React from 'react';
import { observer } from 'mobx-react';
import { Row } from 'antd';
import { ESPlusNotificationsList } from './ESPlusNotificationsList';
import { Es2CancelOrderForm } from './Es2CancelOrderForm';
import { Es2ConfirmOrderForm } from './Es2ConfirmOrderForm';
import { Es2DownloadForm } from './Es2DownloadForm';
import { Es2ReleaseOrder } from './Es2ReleaseOrder';
import { Catcher } from '../../Catcher';
import Styles from '../../styles/m_itemCardStyles.less';

export const ESPlusNotifications: React.FC = observer(() => {
    return (
        <Catcher>
            <div className={Styles.main}>
                <ESPlusNotificationsList />
                <Row>
                    <Es2DownloadForm />
                    <Es2ConfirmOrderForm />
                    <Es2ReleaseOrder />
                    <Es2CancelOrderForm />
                </Row>
            </div>
        </Catcher>
    );
});
