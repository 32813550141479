import React from 'react';
import { observer } from 'mobx-react';
import { ICardWithIdSpinners } from '../../interfaces';
import { useIncludeCombinationCardSetCardsLocationList } from '../../hooks';
import { getCenter, getZoom, isRealLocation } from '../../instruments';
import { tI } from '../../translate';
import { CardLayout, Catcher } from '../../Components';
import { CustomMarkersClusterer, ShowCardsSetLocationMarkerInfo } from './Components';
import BaseMap from './Components/BaseMap';
import Styles from './m_googleMapsStyles.less';

export const CardSetsLocationGoogleMap: React.FC<ICardWithIdSpinners> = observer(
    ({ idSpinners, columnOption, title }: ICardWithIdSpinners) => {
        const list = useIncludeCombinationCardSetCardsLocationList();
        const center = getCenter(list);
        const zoom = getZoom(list);
        const badLocationItemCount = list.filter((i) => !isRealLocation(i)).length;
        const titleRightBadLocation = !!badLocationItemCount
            ? `, ${tI('location errors')}: ${badLocationItemCount}`
            : '';
        const itemCount = list.length;
        const titleRight = !!itemCount ? `(${tI('locations')}: ${itemCount}${titleRightBadLocation})` : '';

        return (
            <Catcher>
                <CardLayout
                    columnOption={columnOption}
                    idSpinners={idSpinners}
                    isTable
                    title={title}
                    titleRight={titleRight}
                >
                    <div className={Styles.cardLocationMap}>
                        <BaseMap center={center} zoom={zoom}>
                            <CustomMarkersClusterer
                                markerList={list}
                                showMarkerInfo={(marker, markerProps) =>
                                    ShowCardsSetLocationMarkerInfo({
                                        marker,
                                        position: marker.position,
                                        ...markerProps,
                                    })
                                }
                            />
                        </BaseMap>
                    </div>
                </CardLayout>
            </Catcher>
        );
    },
);
