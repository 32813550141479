import React from 'react';
import { Form } from 'antd';
import { CardLayout, Catcher, InputDebounce, cardLayoutOptions } from '../../';
import { apiMessagesGetFormRules } from '../../../middleware';
import { tF } from '../../../translate';

export const EditCompanyBillingInfo: React.FC = () => {
    const departmentTitle = tF('Billing to department');
    const emailTitle = tF('Billing to email');
    const vatNumberTitle = tF('Vat number');
    const vatPercentageTitle = tF('Vat percentage');
    const bankNameTitle = tF('Bank name');
    const ibanTitle = tF('Iban bank account number');
    const swiftTitle = tF('Swift bic bank code');
    const cardTypeTitle = tF('Credit card type');
    const cardNumberTitle = tF('Credit card number');
    const cardNameTitle = tF('Name of credit card');
    const paymentTermsTitle = tF('Payment terms');

    return (
        <Catcher>
            <CardLayout
                title={'Billing Information'}
                idSpinners={['GET_COMPANY']}
                columnOption={cardLayoutOptions.cardEditCompany}
                dataTest={'EditCompanyBillingInfo'}
            >
                <Form.Item
                    name="billingToDepartment"
                    label={departmentTitle}
                    rules={apiMessagesGetFormRules}
                    data-test={'EditCompanyBillingInfoDepartment'}
                >
                    <InputDebounce placeholder={departmentTitle} data-test={'EditCompanyBillingInfoDepartmentInput'} />
                </Form.Item>
                <Form.Item
                    name="billingToEmail"
                    label={emailTitle}
                    rules={apiMessagesGetFormRules}
                    data-test={'EditCompanyBillingInfoToEmail'}
                >
                    <InputDebounce placeholder={emailTitle} data-test={'EditCompanyBillingInfoToEmailInput'} />
                </Form.Item>
                <Form.Item
                    name="vatNumber"
                    label={vatNumberTitle}
                    rules={apiMessagesGetFormRules}
                    data-test={'EditCompanyBillingInfoVatNumber'}
                >
                    <InputDebounce placeholder={vatNumberTitle} data-test={'EditCompanyBillingInfoVatNumberInput'} />
                </Form.Item>
                <Form.Item
                    name="vatPercentage"
                    label={vatPercentageTitle}
                    rules={apiMessagesGetFormRules}
                    data-test={'EditCompanyBillingInfoVatPercentage'}
                >
                    <InputDebounce
                        placeholder={vatPercentageTitle}
                        data-test={'EditCompanyBillingInfoVatPercentageInput'}
                    />
                </Form.Item>
                <Form.Item
                    name="bankName"
                    label={bankNameTitle}
                    rules={apiMessagesGetFormRules}
                    data-test={'EditCompanyBillingInfoBankName'}
                >
                    <InputDebounce placeholder={bankNameTitle} data-test={'EditCompanyBillingInfoBankNameInput'} />
                </Form.Item>
                <Form.Item
                    name="ibanBankAccountNumber"
                    label={ibanTitle}
                    rules={apiMessagesGetFormRules}
                    data-test={'EditCompanyBillingInfoBankAccountNumber'}
                >
                    <InputDebounce placeholder={ibanTitle} data-test={'EditCompanyBillingInfoBankAccountNumberInput'} />
                </Form.Item>
                <Form.Item
                    name="swiftBicBankCode"
                    label={swiftTitle}
                    rules={apiMessagesGetFormRules}
                    data-test={'EditCompanyBillingInfoSwiftBic'}
                >
                    <InputDebounce placeholder={swiftTitle} data-test={'EditCompanyBillingInfoSwiftBicInput'} />
                </Form.Item>
                <Form.Item
                    name="creditCardType"
                    label={cardTypeTitle}
                    rules={apiMessagesGetFormRules}
                    data-test={'EditCompanyBillingInfoCreditCardType'}
                >
                    <InputDebounce
                        placeholder={cardTypeTitle}
                        data-test={'EditCompanyBillingInfoCreditCardTypeInput'}
                    />
                </Form.Item>
                <Form.Item
                    name="creditCardNumber"
                    label={cardNumberTitle}
                    rules={apiMessagesGetFormRules}
                    data-test={'EditCompanyBillingInfoCreditCardNumber'}
                >
                    <InputDebounce
                        placeholder={cardNumberTitle}
                        data-test={'EditCompanyBillingInfoCreditCardNumberInput'}
                    />
                </Form.Item>
                <Form.Item
                    name="nameOfCreditCard"
                    label={cardNameTitle}
                    rules={apiMessagesGetFormRules}
                    data-test={'EditCompanyBillingInfoNameOfCreditCard'}
                >
                    <InputDebounce
                        placeholder={cardNameTitle}
                        data-test={'EditCompanyBillingInfoNameOfCreditCardInput'}
                    />
                </Form.Item>
                <Form.Item
                    name="paymentTerms"
                    label={paymentTermsTitle}
                    rules={apiMessagesGetFormRules}
                    data-test={'EditCompanyBillingInfoPaymentTerms'}
                >
                    <InputDebounce
                        placeholder={paymentTermsTitle}
                        data-test={'EditCompanyBillingInfoPaymentTermsInput'}
                    />
                </Form.Item>
            </CardLayout>
        </Catcher>
    );
};
