import React, { MouseEvent, ReactNode, useEffect, useState } from 'react';
import { Select, Tooltip } from 'antd';
import { CloseCircleFilled, FileSearchOutlined } from '@ant-design/icons';
import { StringMap } from '@react-force/utils';
import cx from 'classnames';
import { IDivMainProps, IMyWindow, IPage, IRouterParams } from '../../interfaces';
import { usePagesStoreSearchPages, useRouterStore } from '../../hooks';
import { setRouterStoreCurrentComponent } from '../../middleware';
import { lightText } from '../../instruments';
import { ITrPage, tI, tP } from '../../translate';
import { Catcher, MenuIcons } from '../../Components';
import Styles from './m_styles.less';

interface IOptionPage extends IPage {
    key: string;
    search: string;
    showTitle: ReactNode;
    searchId?: string;
    searchString?: string;
}

type ISearchParam = {
    first: string;
    last: string;
};

interface ISearchSelector extends IDivMainProps {
    children?: never;
    onCancel?: () => void;
}

export const SearchSelector: React.FC<ISearchSelector> = ({ className, onCancel }: ISearchSelector) => {
    const { Option } = Select;

    const [searchString, setSearchString] = useState<string>();
    const [isMetaKey, setIsMetaKey] = useState<boolean>(false);

    const routerStore = useRouterStore();
    const allSearchPages = usePagesStoreSearchPages();

    const onSearch = (search: string): void => {
        setSearchString(search.replace(/ +/g, ' ').trim());
    };

    const optionPages: Array<IOptionPage> = [];
    const searchArr = searchString ? searchString.split(' ') : [];
    const searchArrLength = searchArr.length;
    const searchParams: Array<ISearchParam> = [];

    for (let i = 1; i < searchArrLength; i++) {
        const first = searchArr.slice(0, i).join(' ');
        const last = searchArr.slice(i).join(' ');
        searchParams.push({ first, last });
    }

    allSearchPages.forEach((p: IPage) => {
        if (!searchString) {
            if (!p.url.includes(':id')) {
                optionPages.push({
                    ...p,
                    key: `${p.componentName}`,
                    search: '',
                    showTitle: tP(p.title as ITrPage),
                });
            }
        } else {
            if (
                p.title &&
                tP(p.title as ITrPage)
                    .toUpperCase()
                    .includes(searchString.toUpperCase()) &&
                (p.forcedUpdate || !p.url.includes(':id'))
            ) {
                optionPages.push({
                    ...p,
                    key: `${p.componentName}-${searchString}`,
                    search: searchString,
                    showTitle: lightText(tP(p.title as ITrPage), searchString),
                });
            }
            if (searchArrLength > 1) {
                searchParams.forEach((params: ISearchParam) => {
                    if (
                        p.title &&
                        tP(p.title as ITrPage)
                            .toUpperCase()
                            .includes(params.first.toUpperCase())
                    ) {
                        const foundId = params.last.split(' ')[0];
                        if (p.url.includes(':id') && !isNaN(parseInt(params.last))) {
                            optionPages.push({
                                ...p,
                                key: `${p.componentName}-${params.first}-${foundId}-${params.last}`,
                                search: params.first,
                                searchId: foundId,
                                showTitle: lightText(tP(p.title as ITrPage), params.first),
                                url: p.url.replace(':id', foundId),
                            });
                        }
                        if (p.forcedUpdate) {
                            optionPages.push({
                                ...p,
                                key: `${p.componentName}-${params.first}-${foundId}-${params.last}`,
                                search: params.first,
                                searchString: params.last,
                                showTitle: lightText(tP(p.title as ITrPage), params.first),
                                url: `${p.url}?search=${params.last}`,
                            });
                        }
                    }
                });
            }
        }
    });

    if (searchString && !optionPages.length) {
        allSearchPages
            .filter((p: IPage) => p.forcedUpdate && !p.url.includes(':id'))
            .forEach((p: IPage) => {
                optionPages.push({
                    ...p,
                    key: `${p.componentName}-${searchString}`,
                    search: searchString,
                    searchString,
                    showTitle: tP(p.title as ITrPage),
                    url: `${p.url}?search=${searchString}`,
                });
            });
    }

    const onClickItem = (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>): void => {
        if (!(event.ctrlKey || event.metaKey || event.altKey || event.shiftKey)) {
            event.preventDefault();
        }
        onCancel && onCancel();
    };

    const options = optionPages.map((p: IOptionPage) => {
        return (
            <Option key={p.key} value={p.key} className={Styles.option}>
                <a href={p.url} onClick={onClickItem} data-test="MainSearchSelectorItem">
                    <span className={cx('text-color', Styles.optionPage)}>{`${tI('Page')}:`}</span>
                    <span className={cx('primary-color', Styles.optionIcon)}>
                        {p.menuIcon ? <MenuIcons iconName={p.menuIcon} /> : null}
                    </span>
                    <span className={cx('primary-color', Styles.optionTitle)}>{p.showTitle}</span>
                    {p.searchId ? (
                        <span className={Styles.optionSearch}>
                            id: <span className={Styles.lightText}>{p.searchId}</span>
                        </span>
                    ) : null}
                    {p.searchString ? (
                        <span className={cx('text-color', Styles.optionSearch)}>
                            {tI('Search')}: <span className={Styles.lightText}>{p.searchString}</span>
                        </span>
                    ) : null}
                </a>
            </Option>
        );
    });

    const onChange = (key: string): void => {
        const selectedItem = optionPages.find((p: IOptionPage) => p.key === key);
        if (selectedItem) {
            if (!isMetaKey) {
                const params: StringMap = selectedItem.searchId ? { id: selectedItem.searchId } : {};
                const queryParams: IRouterParams = selectedItem.searchString
                    ? { search: selectedItem.searchString }
                    : {};
                routerStore.goTo(selectedItem.componentName, { ...params, ...queryParams });
                setRouterStoreCurrentComponent({ routeName: selectedItem.componentName, params, queryParams });
            } else {
                window.open(selectedItem.url, '_blank');
            }
        }

        onCancel && onCancel();
    };

    const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
        if (event.key === 'Escape') {
            onCancel && onCancel();
        } else if (event.key === 'Meta') {
            setIsMetaKey(true);
        }
    };

    const onKeyUp = (event: React.KeyboardEvent<HTMLDivElement>): void => {
        event.key === 'Meta' && setIsMetaKey(false);
    };

    const onBlur = (): void => {
        onCancel && onCancel();
    };

    return (
        <Catcher>
            <div className={cx(Styles.searchPanel, className)} data-test="MainSearchSelectorPlace">
                <Select
                    allowClear
                    autoFocus
                    className={Styles.selector}
                    clearIcon={<CloseCircleFilled title={tI('Clean the search')} />}
                    data-test={'MainSearchInput'}
                    filterOption={false}
                    onBlur={onBlur}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    onKeyUp={onKeyUp}
                    onSearch={onSearch}
                    showSearch
                    value={''}
                >
                    {options}
                </Select>
            </div>
        </Catcher>
    );
};

export const MainSearch: React.FC = () => {
    const [isVisiblePanel, setIsVisiblePanel] = useState<boolean>(false);
    const [isMacOs, setIsMacOs] = useState<boolean>(false);

    const handleKeyPress = (event: KeyboardEvent): void => {
        if (event.code === 'KeyK' && event.metaKey) {
            event.preventDefault();
            setIsVisiblePanel(true);
        }
    };

    useEffect(() => {
        const myWindow: IMyWindow = window;
        const isWindowMacOs =
            !!myWindow.navigator?.userAgentData?.platform && myWindow.navigator.userAgentData.platform === 'macOS';
        setIsMacOs(isWindowMacOs);

        window.addEventListener('keydown', handleKeyPress);
        return (): void => window.removeEventListener('keydown', handleKeyPress);
    }, []);

    const tooltipText = `${tI('Main search')}: ${!isVisiblePanel ? 'Off' : 'On'}  ${
        isVisiblePanel ? '' : `(${isMacOs ? 'cmd+K' : 'Ctrl+K'})`
    }`;

    return (
        <Catcher>
            <div className={Styles.main}>
                <div className={Styles.center}>
                    {isVisiblePanel ? <SearchSelector onCancel={(): void => setIsVisiblePanel(false)} /> : null}
                </div>
                <div
                    className={cx(Styles.right, { [Styles.isActive]: isVisiblePanel })}
                    data-test="MainSearchButtonPlace"
                >
                    <Tooltip title={tooltipText} placement="bottomRight">
                        <FileSearchOutlined
                            className={Styles.icon}
                            onClick={(): void => setIsVisiblePanel(!isVisiblePanel)}
                        />
                    </Tooltip>
                </div>
            </div>
        </Catcher>
    );
};
