import React from 'react';
import { Tooltip } from 'antd';
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    PauseCircleOutlined,
    PlayCircleOutlined,
    PoweroffOutlined,
    QuestionCircleOutlined,
    RightCircleOutlined,
    SyncOutlined,
    WarningOutlined,
    WifiOutlined,
} from '@ant-design/icons';
import { Catcher, ShowRawDataInfo } from '../../';
import cx from 'classnames';
import { ISubscriberStatusView } from '../../../interfaces';
import { tI } from '../../../translate';
import Styles from '../../styles/m_itemCardStyles.less';

const showStatus = (subStatus: string): ISubscriberStatusView => {
    switch (subStatus) {
        case 'live':
            return { status: tI('LIVE'), className: Styles.green, Icon: WifiOutlined };
        case 'active':
            return { status: tI('ACTIVE'), className: Styles.blue, Icon: PlayCircleOutlined };
        case 'active_this_month':
            return {
                status: tI('ACTIVE'),
                tooltip: tI('Active this month'),
                className: Styles.blue,
                Icon: RightCircleOutlined,
            };
        case 'ready':
            return { status: tI('READY'), className: Styles.yellow, Icon: CheckCircleOutlined };
        case 'pre_active':
            return { status: tI('PRE ACTIVE'), className: Styles.orange, Icon: PauseCircleOutlined };
        case 'unknown':
            return { status: tI('UNKNOWN'), className: Styles.red, Icon: QuestionCircleOutlined };
        case 'suspended':
            return { status: tI('SUSPENDED'), className: Styles.red, Icon: PoweroffOutlined };
        case 'terminated':
            return { status: tI('TERMINATED'), className: Styles.red, Icon: CloseCircleOutlined };
        case 'unmanaged':
            return { status: tI('UNMANAGED'), className: Styles.red, Icon: WarningOutlined };
        default:
            return { status: tI('UNKNOWN'), className: Styles.red, Icon: QuestionCircleOutlined };
    }
};

type IShowSubscriberStatus = {
    dispatchLock?: boolean;
    subStatus?: string;
    transactionError?: any;
    children?: never;
};

export const ShowSubscriberStatus: React.FC<IShowSubscriberStatus> = ({
    dispatchLock,
    subStatus,
    transactionError,
}: IShowSubscriberStatus) => {
    const { className, Icon, status, tooltip } = showStatus(subStatus || '');

    return (
        <Catcher>
            <div className={Styles.subscriberStatus}>
                <div className={Styles.icon}>
                    {dispatchLock ? (
                        <SyncOutlined spin />
                    ) : (
                        <>
                            <div className={cx(Styles.trafficLite, className)} />
                            {Icon ? (
                                <Tooltip placement="topLeft" title={tooltip || status}>
                                    <Icon className={className} />
                                </Tooltip>
                            ) : null}
                        </>
                    )}
                </div>
                <div className={Styles.info}>
                    <div className={Styles.status}>{status}</div>
                    <ShowRawDataInfo transactionInfo={transactionError} isError />
                </div>
            </div>
        </Catcher>
    );
};
