import React from 'react';
import { observer } from 'mobx-react';
import { Catcher, DownloadFilesButtons, GoPageButton, ListWrapper } from '../../';
import { useOperatorInteractionsResponsesFilterParams } from '../../../hooks';
import {
    cleanOperatorInteractionsStore,
    getOperatorInteractionsResponsesListRelationSelect,
} from '../../../middleware';
import { ResponsesTable } from './Components';

const LeftButtons: React.FC = () => {
    return <GoPageButton componentName={'OperatorInteractionsFilesList'} title={'Files List'} />;
};

const RightButtons: React.FC = () => {
    const filterParams = useOperatorInteractionsResponsesFilterParams();
    return <DownloadFilesButtons csvType={'GET_OPERATOR_INTERACTIONS_RESPONSES_CSV'} filterParams={filterParams} />;
};

export const OperatorInteractionsResponsesList: React.FC = observer(() => {
    const filterParams = useOperatorInteractionsResponsesFilterParams();
    return (
        <Catcher>
            <ListWrapper
                cleanStore={cleanOperatorInteractionsStore}
                filterParams={filterParams}
                getRelationSelect={getOperatorInteractionsResponsesListRelationSelect}
                LeftButtons={LeftButtons}
                RightButtons={RightButtons}
            >
                <ResponsesTable />
            </ListWrapper>
        </Catcher>
    );
});
