import React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import { CardLayout, Catcher, cardLayoutOptions, viewOption } from '../../';
import { IPurchaseRateFormListItem } from '../../../interfaces';
import { usePurchaseInfo, usePurchaseListByZone, useUiIsLoading } from '../../../hooks';
import { tF } from '../../../translate';
import StylesItem from '../m_styles.less';

interface IZone {
    name: string;
    zoneId: string;
}
export const ShowPurchasesZoneTable: React.FC<IZone> = observer(({ zoneId, name }: IZone) => {
    const isLoading = useUiIsLoading();
    const { currency } = usePurchaseInfo();
    const list = usePurchaseListByZone(zoneId);
    if (!list.length) {
        return null;
    }

    const tableColumns = [
        {
            title: tF('Country'),
            dataIndex: 'country',
            ellipsis: true,
        },
        {
            title: tF('Operator'),
            dataIndex: 'network',
            ellipsis: true,
        },
        {
            title: `${tF('Data')} (p/MB)`,
            dataIndex: 'rateDataShow',
            className: StylesItem.rateColumnVal,
            // width: viewOption.rateTable.widthRateVal,
        },
        {
            title: tF('SMS'),
            dataIndex: 'rateSmsShow',
            className: StylesItem.rateColumnVal,
            // width: viewOption.rateTable.widthRateVal,
        },
        {
            title: `${tF('INC')} (KB)`,
            dataIndex: 'incrementData',
            className: StylesItem.rateColumnVal,
            // width: viewOption.rateTable.incrementData,
        },
        {
            title: `${tF('INC')} ${tF('Voice Mobile')}`,
            dataIndex: 'incrementVoiceMobile',
            className: StylesItem.rateColumnVal,
            // width: viewOption.rateTable.incrementData,
        },
        {
            title: tF('Unit - Minute to Mobile'),
            dataIndex: 'rateVoiceMobileShow',
            className: StylesItem.rateColumnVal,
            width: viewOption.rateTable.incrementData,
        },
        {
            title: `${tF('INC')} ${tF('Voice Net')}`,
            dataIndex: 'incrementVoiceNet',
            className: StylesItem.rateColumnVal,
            // width: viewOption.rateTable.incrementData,
        },
        {
            title: tF('Unit - Minute to Net'),
            dataIndex: 'rateVoiceNet',
            className: StylesItem.rateColumnVal,
            width: viewOption.rateTable.incrementData,
        },
    ];

    const tableData = list.map((c: IPurchaseRateFormListItem) => {
        const key = c.id;

        return {
            key,
            country: c.country,
            network: c.network,
            rateDataShow: `${c.rateData} ${currency}`,
            rateSmsShow: `${c.rateSms} ${currency}`,
            incrementData: c.incrementData,
            incrementVoiceMobile: c.incrementVoiceMobile,
            rateVoiceMobileShow: `${c.rateVoiceMobile}`,
            incrementVoiceNet: c.incrementVoiceNet,
            rateVoiceNet: `${c.rateVoiceNet}`,
        };
    });

    return (
        <Catcher>
            <CardLayout
                columnOption={cardLayoutOptions.cardTableOperators}
                count={list.length}
                idSpinners={['GET_PURCHASE_RATE_LIST']}
                isTable
                unboundTitle={name ? name : ''}
            >
                <Table
                    className={'table-theme sub-table'}
                    columns={tableColumns}
                    dataSource={tableData}
                    loading={isLoading}
                    pagination={false}
                    size={'small'}
                />
            </CardLayout>
        </Catcher>
    );
});
