import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Form } from 'antd';
import { PhoneOutlined } from '@ant-design/icons';
import { IExternalAPIFrontFpbxSettings, IFieldLayout, IGetApiType } from '../../../interfaces';
import { useExternalAPIFpbxSettings, useSubscriberInfo } from '../../../hooks';
import { getExternalAPIFpbxSettings, setExternalAPIFpbxSettings } from '../../../middleware';
import { equalObjects, trimStringFormFields } from '../../../instruments';
import { ITrField } from '../../../translate';
import {
    CardInfoToolBar,
    CardMainLayout,
    Catcher,
    FieldInfoAdvanced,
    FormStringDebounceField,
    cardLayoutOptions,
} from '../../../Components';

const loadingTypes: IGetApiType[] = ['POST_SUBSCRIBERS_API_REQUEST'];

export const FpbxSettings: React.FC = observer(() => {
    const layout: IFieldLayout = {
        labelCol: {
            flex: '0 1 195px',
        },
        valCol: {
            flex: '1 1 270px',
        },
    };

    const [isEditMode, setEditMode] = useState<boolean | undefined>(false);
    const [isChanged, setChanged] = useState<boolean>(false);
    const [isValid, setValid] = useState<boolean>(false);
    const [isSending, setSending] = useState<boolean>(false);
    const [form] = Form.useForm();
    const initialValues = useExternalAPIFpbxSettings();
    const { id: subscriberId } = useSubscriberInfo();

    useEffect(() => {
        if (subscriberId) {
            getExternalAPIFpbxSettings(subscriberId);
        }
    }, [subscriberId]);

    useEffect(() => {
        form.resetFields();
        setChanged(false);
    }, [initialValues]);

    const checkValid = (): void => {
        form.validateFields().then(
            () => {
                setValid(true);
            },
            () => {
                setValid(!form.getFieldsError().filter(({ errors }) => errors.length).length);
            },
        );
    };

    const onValuesChange = () => {
        setChanged(!equalObjects(initialValues, trimStringFormFields(form.getFieldsValue())));
        checkValid();
    };

    const onFinish = async (formFields: IExternalAPIFrontFpbxSettings): Promise<void> => {
        setSending(true);
        await setExternalAPIFpbxSettings(subscriberId, formFields);
        setSending(false);
        setEditMode(false);
        getExternalAPIFpbxSettings(subscriberId);
    };

    return (
        <Catcher>
            <CardMainLayout
                columnOption={cardLayoutOptions.cardSubscribersInfo}
                icon={<PhoneOutlined />}
                idSpinners={loadingTypes}
                isNotDocumentTitle
                name={'FPBX SETTINGS'}
            >
                <Form
                    form={form}
                    name={'numbersForm'}
                    initialValues={initialValues}
                    onFinish={onFinish}
                    onValuesChange={onValuesChange}
                    layout={'horizontal'}
                    className={'formStyle'}
                >
                    {isEditMode ? (
                        <FormStringDebounceField label={'Username' as ITrField} name={'username'} />
                    ) : (
                        <FieldInfoAdvanced layout={layout} title={'Username' as ITrField}>
                            {initialValues.username}
                        </FieldInfoAdvanced>
                    )}
                    {isEditMode ? (
                        <FormStringDebounceField label={'Password' as ITrField} name={'password'} />
                    ) : (
                        <FieldInfoAdvanced layout={layout} title={'Password' as ITrField}>
                            {initialValues.password}
                        </FieldInfoAdvanced>
                    )}

                    {isEditMode ? (
                        <FormStringDebounceField label={'Host' as ITrField} name={'host'} />
                    ) : (
                        <FieldInfoAdvanced layout={layout} title={'Host' as ITrField}>
                            {initialValues.host}
                        </FieldInfoAdvanced>
                    )}

                    {isEditMode ? (
                        <FormStringDebounceField label={'Port' as ITrField} name={'port'} />
                    ) : (
                        <FieldInfoAdvanced layout={layout} title={'Port' as ITrField}>
                            {initialValues.port}
                        </FieldInfoAdvanced>
                    )}

                    {isEditMode ? (
                        <FormStringDebounceField label={'Outbound Channel' as ITrField} name={'outboundChannel'} />
                    ) : (
                        <FieldInfoAdvanced layout={layout} title={'Outbound Channel' as ITrField}>
                            {initialValues.outboundChannel}
                        </FieldInfoAdvanced>
                    )}
                    <CardInfoToolBar
                        isDisabledCancel={isSending}
                        isDisabledSave={!isValid || !isChanged || isSending}
                        isHideCancel={!isEditMode}
                        isSubmitButton={isEditMode}
                        loadingTypes={loadingTypes}
                        onCancel={() => setEditMode(false)}
                        setEditMode={isEditMode ? undefined : setEditMode}
                    />
                </Form>
            </CardMainLayout>
        </Catcher>
    );
});
