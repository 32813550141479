import { action, autorun, computed, makeObservable, observable, reaction } from 'mobx';
import { appConfig } from '../settings';
import { IGetApiType } from '../REST';
import {
    IComponentName,
    IDateRange,
    IFilterBoolean,
    IFilterParams,
    IFilterSelect,
    IFilterSelectComposed,
    ILanguageId,
    INotificationStoreItem,
    INumberOrUndefined,
    IParamsList,
    IUiIdSpinner,
    SelectType,
} from '../interfaces';
import {
    checkVersion,
    getAPIAppSignalKey,
    getUiLocalStorageDarkTheme,
    saveUiLocalStorageDarkTheme,
    saveUiLocalStorageLanguage,
    setColorProperty,
    setUiForcedUpdateTime,
} from '../middleware';

export class UiStore {
    private constructor() {
        makeObservable(this);
        if (appConfig.uiStoreDelay) {
            setInterval(() => this.checkTimer(), appConfig.uiStoreDelay);
        }
        autorun(async () => {
            await getAPIAppSignalKey();
            const isDarkTheme = await getUiLocalStorageDarkTheme();
            this.isDarkTheme = isDarkTheme;
            setColorProperty(isDarkTheme);
        });
        reaction(
            () => this.languageId,
            () => {
                saveUiLocalStorageLanguage(this.languageId).then();
            },
            {
                delay: appConfig.APIDelay,
            },
        );
        reaction(
            () => this.isDarkTheme,
            () => {
                saveUiLocalStorageDarkTheme(this.isDarkTheme).then();
            },
            {
                delay: appConfig.APIDelay,
            },
        );
        reaction(
            () => this.isDarkTheme,
            () => {
                this.isChangedTheme = true;
                setTimeout(() => {
                    this.isChangedTheme = false;
                }, appConfig.transitionColorThemeTime);
            },
        );
    }

    private static instance: UiStore;
    public static getInstance(): UiStore {
        if (!UiStore.instance) {
            UiStore.instance = new UiStore();
        }
        return UiStore.instance;
    }

    // ------------ AppSignal ------------
    @observable appSignalKey: string | undefined;
    @action setAppSignalKey(key: string): void {
        this.appSignalKey = key;
    }

    // ------------ Notification ------------
    @observable notification: INotificationStoreItem[] = [];
    @action addNotification(item: INotificationStoreItem): INotificationStoreItem {
        const itemFound = this.notification.find(
            (i: INotificationStoreItem) => i.message === item.message && i.description === item.description,
        );
        if (itemFound) {
            const newItem: INotificationStoreItem = {
                ...itemFound,
                count: Number(itemFound.count) + 1,
            };
            this.notification = [
                ...this.notification.filter(
                    (i: INotificationStoreItem) => i.message !== item.message && i.description === item.description,
                ),
                newItem,
            ];
            return newItem;
        } else {
            item.count = 1;
            this.notification = [...this.notification, item];
            return item;
        }
    }
    @action removeNotification(key: string): void {
        this.notification = this.notification.filter((i) => i.key !== key);
    }

    // ------------ Check Version ------------
    checkTimer = (): void => {
        if (appConfig.checkVersionDelay && process.env.NODE_ENV === 'production') {
            if (this.checkVersionDelay <= 0) {
                checkVersion();
                this.checkVersionDelay = appConfig.checkVersionDelay;
            } else {
                this.checkVersionDelay--;
            }
        }
    };

    checkVersionDelay = appConfig.checkVersionDelay;

    // ------------ Spinners ------------
    @observable spinners: IUiIdSpinner[] = [];
    @action addSpinner(idSpinner: IUiIdSpinner): IUiIdSpinner {
        this.spinners = [...this.spinners, ...[idSpinner]];
        return idSpinner;
    }
    @action removeSpinner(idSpinner: string): void {
        this.spinners = this.spinners.filter((s) => s !== idSpinner);
    }

    @action removeSpinnerByApiType(apiType: string): void {
        this.spinners = this.spinners.filter((s) => !s.includes(apiType));
    }

    @computed get isSpinning(): boolean {
        return !!this.spinners.length;
    }

    @computed get spinnerCount(): number {
        return this.spinners.length;
    }
    isFoundSpinner(spinners: IGetApiType[]): boolean {
        let isLoading = false;
        this.spinners.forEach((spinner: IUiIdSpinner) => {
            spinners.forEach((id: IUiIdSpinner) => {
                if (spinner.includes(`${id} -`)) {
                    isLoading = true;
                }
            });
        });

        return isLoading;
    }

    // ------------ Page List Params ------------
    @observable total = 0;
    @action setTotal(total: number): void {
        if (this.pageNumber !== 1 && Math.ceil(total / this.pageSize) < this.pageNumber) {
            this.pageNumber = Math.max(Math.ceil(total / this.pageSize), 1);
            setUiForcedUpdateTime();
        }
        this.total = total;
    }

    @observable initPageList = {
        cardSetId: undefined,
        commandId: undefined,
        companyId: undefined,
        companyTypeId: undefined,
        dateRange: { startDate: undefined, endDate: undefined },
        entityId: undefined,
        fileId: undefined,
        gatewayId: undefined,
        isActiveThisMonth: undefined,
        isDelete: undefined,
        operatorId: undefined,
        packageId: undefined,
        pageNumber: appConfig.pageDefaultParams.pageNumber,
        pageSize: appConfig.pageDefaultParams.pageSize,
        paymentType: undefined,
        search: undefined,
        sessionId: undefined,
        source: undefined,
        unitTypeArrId: [],
        unitTypeIds: '1',
        uploadFileStatusId: undefined,
        userId: undefined,
        withDeleted: undefined,
        zoneId: undefined,
    };

    @observable pageSize: number = appConfig.pageDefaultParams.pageSize;
    @action setPageSize(pageSize: INumberOrUndefined = appConfig.pageDefaultParams.pageSize): void {
        if (this.total && this.pageNumber !== 1 && Math.ceil(this.total / pageSize) < this.pageNumber) {
            this.pageNumber = Math.max(Math.ceil(this.total / pageSize), 1);
        }
        this.pageSize = pageSize;
    }

    @observable pageNumber: number = this.initPageList.pageNumber;
    @action setPageNumber(page: INumberOrUndefined = this.initPageList.pageNumber): void {
        this.pageNumber = page;
    }

    @observable search: IFilterSelect = this.initPageList.search;
    @action setSearch(search: IFilterSelect = this.initPageList.search): void {
        this.search = search;
    }

    @observable dateRange: IDateRange = this.initPageList.dateRange;
    @action setDateRange(dateRange: IDateRange = this.initPageList.dateRange): void {
        this.dateRange = dateRange;
    }

    @observable companyId: IFilterSelectComposed = this.initPageList.companyId;
    @action setCompanyId(id: IFilterSelectComposed = this.initPageList.companyId): void {
        this.companyId = id;
    }

    @observable cardSetId: IFilterSelectComposed = this.initPageList.cardSetId;
    @action setCardSetId(id: IFilterSelectComposed = this.initPageList.cardSetId): void {
        this.cardSetId = id;
    }

    @observable packageId: IFilterSelectComposed = this.initPageList.packageId;
    @action setPackageId(id: IFilterSelectComposed = this.initPageList.packageId): void {
        this.packageId = id;
    }

    @observable operatorId: IFilterSelectComposed = this.initPageList.operatorId;
    @action setOperatorId(id: IFilterSelectComposed = this.initPageList.operatorId): void {
        this.operatorId = id;
    }

    @observable zoneId: IFilterSelectComposed = this.initPageList.zoneId;
    @action setCurrentZoneId(id: IFilterSelectComposed = this.initPageList.zoneId): void {
        this.zoneId = id;
    }

    @observable userId: IFilterSelectComposed = this.initPageList.userId;
    @action setCurrentUserId(id: IFilterSelectComposed = this.initPageList.userId): void {
        this.userId = id;
    }

    @observable entityId: IFilterSelectComposed = this.initPageList.entityId;
    @action setCurrentEntityId(id: IFilterSelectComposed = this.initPageList.entityId): void {
        this.entityId = id;
    }

    @observable unitTypeIds: IFilterSelectComposed = this.initPageList.unitTypeIds;
    @action setUnitTypeIds(ids: IFilterSelectComposed = this.initPageList.unitTypeIds): void {
        this.unitTypeIds = ids;
    }

    @observable gatewayId: IFilterSelectComposed = this.initPageList.gatewayId;
    @action setCurrentGatewayId(id: IFilterSelectComposed = this.initPageList.gatewayId): void {
        this.gatewayId = id;
    }
    @observable commandId: IFilterSelectComposed = this.initPageList.commandId;
    @action setCurrentCommandId(id: IFilterSelectComposed = this.initPageList.commandId): void {
        this.commandId = id;
    }

    @observable fileId: IFilterSelectComposed = this.initPageList.fileId;
    @action setCurrentFileId(id: IFilterSelectComposed = this.initPageList.fileId): void {
        this.fileId = id;
    }

    @observable uploadFileStatusId: IFilterSelectComposed = this.initPageList.uploadFileStatusId;
    @action setCurrentUploadFileStatusId(id: IFilterSelectComposed = this.initPageList.uploadFileStatusId): void {
        this.uploadFileStatusId = id;
    }

    @observable source: IFilterSelectComposed = this.initPageList.source;
    @action setCurrentSource(source: IFilterSelectComposed = this.initPageList.source): void {
        this.source = source;
    }

    @observable parentId: IFilterSelectComposed = undefined;
    @action setParentId(id: IFilterSelectComposed): void {
        this.parentId = id;
    }

    @observable companyTypeId: IFilterSelectComposed = undefined;
    @action setCompanyType(id: IFilterSelectComposed): void {
        this.companyTypeId = id;
    }

    @observable paymentType: IFilterSelectComposed = undefined;
    @action setPaymentType(paymentType: IFilterSelectComposed): void {
        this.paymentType = paymentType;
    }

    @observable sessionId: IFilterSelectComposed = undefined;
    @action setSessionId(id: IFilterSelectComposed): void {
        this.sessionId = id;
    }
    @observable isDelete: IFilterBoolean = undefined;
    @action setIsDelete(isDelete: IFilterBoolean): void {
        this.isDelete = isDelete;
    }
    @observable withDeleted: IFilterBoolean = undefined;
    @action setWithDeleted(withDeleted: IFilterBoolean): void {
        this.withDeleted = withDeleted;
    }
    @observable isActiveThisMonth: IFilterBoolean = undefined;
    @action setUiIsActiveThisMonth(isActiveThisMonth: IFilterBoolean): void {
        this.isActiveThisMonth = isActiveThisMonth;
    }
    @observable lastViewComponentName: IComponentName = 'Dashboard';
    @action setLastViewComponentName(componentName: IComponentName): void {
        this.lastViewComponentName = componentName;
    }

    @computed get params(): IParamsList {
        return {
            cardSetId: this.cardSetId,
            commandId: this.commandId,
            companyId: this.companyId,
            companyTypeId: this.companyTypeId,
            endDate: this.dateRange.endDate,
            entityId: this.entityId,
            fileId: this.fileId,
            gatewayId: this.gatewayId,
            isActiveThisMonth: this.isActiveThisMonth,
            isDelete: this.isDelete,
            operatorId: this.operatorId,
            packageId: this.packageId,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
            paymentType: this.paymentType,
            search: this.search,
            sessionId: this.sessionId,
            source: this.source,
            startDate: this.dateRange.startDate,
            unitTypeIds: this.unitTypeIds,
            uploadFileStatusId: this.uploadFileStatusId,
            userId: this.userId,
            withDeleted: this.withDeleted,
            zoneId: this.zoneId,
        };
    }
    // ------------ UiLocalStorage ------------

    @action setInitPageList(filterParams?: IFilterParams): void {
        this.cardSetId = this.initPageList.cardSetId;
        this.commandId = this.initPageList.commandId;
        this.companyId = this.initPageList.companyId;
        this.companyTypeId = this.initPageList.companyTypeId;
        this.dateRange = this.initPageList.dateRange;
        this.entityId = this.initPageList.entityId;
        this.fileId = this.initPageList.fileId;
        this.gatewayId = this.initPageList.gatewayId;
        this.isActiveThisMonth = this.initPageList.isActiveThisMonth;
        this.isDelete = this.initPageList.isDelete;
        this.operatorId = this.initPageList.operatorId;
        this.operatorId = this.initPageList.operatorId;
        this.packageId = this.initPageList.packageId;
        this.packageId = this.initPageList.packageId;
        this.pageNumber = this.initPageList.pageNumber;
        this.paymentType = this.initPageList.paymentType;
        this.search = this.initPageList.search;
        this.sessionId = this.initPageList.sessionId;
        this.source = this.initPageList.source;
        this.total = 0;
        this.unitTypeIds =
            filterParams?.unitTypeIds === SelectType.SINGLE
                ? this.initPageList.unitTypeIds
                : this.initPageList.unitTypeArrId;
        this.fileId = this.initPageList.fileId;
        this.uploadFileStatusId = this.initPageList.uploadFileStatusId;
        this.userId = this.initPageList.userId;
        this.withDeleted = this.initPageList.withDeleted;
        this.zoneId = this.initPageList.zoneId;
    }

    @observable languageId: ILanguageId = appConfig.languageDefault as ILanguageId;
    @action setLanguage(languageId: ILanguageId): void {
        this.languageId = languageId;
    }

    @observable isDarkTheme: boolean = appConfig.isDarkTheme;
    @action setDarkTheme(isDark: boolean): void {
        this.isDarkTheme = isDark;
    }

    @observable isChangedTheme = false;
}
