import React from 'react';
import { observer } from 'mobx-react';
import { Catcher, ListWrapper } from '../';
import { useUsersFilterParams } from '../../hooks';
import { cleanUsersStore, getUsersRelationSelect } from '../../middleware';
import { UsersTableList } from './Components';

export const UsersList: React.FC = observer(() => {
    const filterParams = useUsersFilterParams();

    return (
        <Catcher>
            <ListWrapper
                cleanStore={cleanUsersStore}
                filterParams={filterParams}
                getRelationSelect={getUsersRelationSelect}
            >
                <UsersTableList />
            </ListWrapper>
        </Catcher>
    );
});
