import { apiConfig } from './config';

export class WebSocketService {
    private connection: WebSocket | null;
    private onOpen = (): void => {
        console.warn(Date.now(), `--(Open CONNECT)-  ->`);
    };
    private onClose = (): void => {
        console.warn(Date.now(), `--(Close CONNECT)-  ->`);
    };
    private onMessage = (message: string): void => {
        console.warn(Date.now(), `--(Received Message)-  ->`, message);
    };
    constructor() {
        this.connection = null;
    }

    public isInit(): boolean {
        return !this.connection;
    }

    public init(): void {
        this.connection = new WebSocket(apiConfig.webSocketUrl());
        this.connection.onopen = (): void => {
            this.onOpen();
        };
        this.connection.onclose = (): void => {
            this.onClose();
        };
        this.connection.onmessage = (b: MessageEvent): void => {
            this.onMessage(b.data);
        };
    }

    public close(): void {
        this.connection && this.connection.close();
        this.connection = null;
    }

    public sendMessage(text: string): void {
        if (this.connection) {
            this.connection.send(text);
        } else {
            console.warn(Date.now(), `--(Not have server connection)-  ->`);
        }
    }

    public onOpenConnect(onOpen: () => void): void {
        this.onOpen = onOpen;
    }
    public onCloseConnect(onClose: () => void): void {
        this.onClose = onClose;
    }
    public onReceivedMessage(onMessage: (message: string) => void): void {
        this.onMessage = onMessage;
    }
}
