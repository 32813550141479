import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { IFilterParams, IMyPurchaseRateListTableItem, IPurchaseRateListTableItem } from '../../interfaces';

export function usePurchaseRatesList(): IPurchaseRateListTableItem[] {
    return useContext(MobXProviderContext).RootStore.purchasesStore.list;
}

export function usePurchaseRatesFilterParams(): IFilterParams {
    return useContext(MobXProviderContext).RootStore.purchasesStore.filterParams;
}

export function useMyPurchaseRatesList(): IMyPurchaseRateListTableItem[] {
    return useContext(MobXProviderContext).RootStore.purchasesStore.myList;
}
