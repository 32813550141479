import { IApiErrorMessages, IIncludeTypeAPI, IItemAPI, IParamsList, IRevenues } from '../interfaces';
import { IMapField } from '../instruments';

export type IApiUrl = string;
export type IHeaderType = 'access' | 'refresh' | 'uploadFile' | 'none';

export type IGetRestApi = {
    apiType: IGetApiType;
    body?: IRequestAPIBody;
    headerType: IHeaderType;
    method: IAPIMethod;
    url: IApiUrl;
};

export type IPostFileRestApi = {
    apiType: IGetApiType;
    body?: IRequestAPIBody;
    file?: Blob;
    headerType: IHeaderType;
    method: IAPIMethod;
    url: IApiUrl;
};

export type IAbortControllers = {
    [id: string]: AbortController;
};

export type IGetApiParams = {
    url: IApiUrl;
    method: IAPIMethod;
    headerType: IHeaderType;
    apiType: IGetApiType;
    body?: IRequestAPIBody;
    file?: File;
    okStatus: Array<number>;
    errorStatus: Array<number>;
    isGetFile?: boolean;
    isPostFile?: boolean;
    isNotLoader?: boolean;
    isNotCheckToken?: boolean;
};

export type IApiParams = {
    searchParamsList?: IParamsList;
    idList?: Array<string>;
    mcc?: Array<string>;
    scope?: Array<string>;
    id?: string;
    companyId?: string;
    subscriptionId?: string;
    isBigQuery?: boolean; // ToDo: 30.07.2024 -  check is need  isBigQuery
};

export type IAPIMethod = 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'PUT';

export const HTTP_STATUS = {
    END_POINT_ERROR_404: 404,
    GET_NOT_FOUND_404: 404,
    GET_OK_200: 200,
    DELETE_OK_204: 204,
    INCORRECT_REQUEST: 0,
    PATCH_DATA_ERROR_422: 422,
    PATCH_DATA_OK_200: 200,
    POST_DATA_ACCEPTED_202: 202,
    POST_DATA_ERROR_422: 422,
    POST_DATA_OK_201: 201,
    SUCCESS_WITHOUT_BODY_204: 204,
    SERVER_ERROR_500: 500,
    POLICY_ERROR_400: 400,
    TOKEN_GET_POST_PATCH_ERROR_401: 401,
    TOKEN_LOGIN_ERROR_404: 404,
    TOKEN_LOGIN_OK_201: 201,
    TOKEN_LOGOUT_OK_500: 500,
    TOKEN_LOGOUT_OK_204: 204,
    TOKEN_UPDATE_OK_201: 201,
};

export type IGetApiType =
    | 'APP_SIGNAL'
    | 'APPLICATION'
    | 'DELETE_BLOB'
    | 'DELETE_CARD'
    | 'DELETE_CARD_SET'
    | 'DELETE_CARD_SUBSCRIBERS'
    | 'DELETE_CARDS'
    | 'DELETE_CARDS_CSV'
    | 'DELETE_COMPANY'
    | 'DELETE_COMPANY_API_KEY'
    | 'DELETE_COUNTRY'
    | 'DELETE_CURRENCY'
    | 'DELETE_OPERATOR'
    | 'DELETE_PACKAGE'
    | 'DELETE_PACKAGE_INSTANCE'
    | 'DELETE_PURCHASE_RATE_LIST'
    | 'DELETE_REPORT'
    | 'DELETE_SALES_RATE_LIST'
    | 'DELETE_SUBSCRIBER'
    | 'DELETE_USER'
    | 'DELETE_USER_IMPERSONATION'
    | 'EDIT_SOLD_PACKAGE'
    | 'GET_ACCEPTANCE_DOCUMENTS'
    | 'GET_ACTIVITY_LOGS'
    | 'GET_BALANCE_LOG_LIST'
    | 'GET_BOUGHT_PACKAGES'
    | 'GET_BOUGHT_PACKAGES_SELECT'
    | 'GET_CALL_DATA_RECORDS_COUNT'
    | 'GET_CALL_DATA_RECORDS_LAST_5'
    | 'GET_CALL_DATA_RECORDS_LIST'
    | 'GET_CALL_DATA_SQUASHED_RECORDS_COUNT'
    | 'GET_CALL_DATA_SQUASHED_RECORDS_LIST'
    | 'GET_CARD'
    | 'GET_CARD_DEVICE_FILES'
    | 'GET_CARD_LIST'
    | 'GET_CARD_LIST_COUNT'
    | 'GET_CARD_SET'
    | 'GET_CARD_SET_COMBINATION'
    | 'GET_CARD_SET_COMBINATION_SUBSCRIBERS'
    | 'GET_CARD_SET_CSV'
    | 'GET_CARD_SETS_LIST'
    | 'GET_CARD_SETS_SELECT'
    | 'GET_CARD_STATISTICS'
    | 'GET_COMBINATION_CARDS_SELECT'
    | 'GET_COMPANIES_CHILDREN_SELECT'
    | 'GET_COMPANIES_HIERARCHY'
    | 'GET_COMPANIES_LIST'
    | 'GET_COMPANIES_SELECT'
    | 'GET_COMPANY'
    | 'GET_COMPANY_ABOUT'
    | 'GET_COMPANY_API_KEY'
    | 'GET_COMPANY_LOGO'
    | 'GET_COMPANY_STATISTICS'
    | 'GET_COMPANY_TYPES_SELECT'
    | 'GET_COUNTRIES_LIST'
    | 'GET_COUNTRIES_SELECT'
    | 'GET_COUNTRY'
    | 'GET_COUNTRY_ACTIVE_CARDS'
    | 'GET_CURRENCY'
    | 'GET_CURRENCY_LIST'
    | 'GET_CURRENCY_SELECT'
    | 'GET_DIAMETER_SESSIONS_LIST'
    | 'GET_ENTITY_SELECT'
    | 'GET_ES_PLUS_NOTIFICATIONS_LIST'
    | 'GET_ES_PLUS_NOTIFICATIONS_LIST_CSV'
    | 'GET_ES_PLUS_NOTIFICATIONS_LOGS_LIST'
    | 'GET_ES_PLUS_NOTIFICATIONS_LOGS_LIST_CSV'
    | 'GET_ES_PLUS_NOTIFICATIONS_QR_CODES_LIST'
    | 'GET_ES_PLUS_NOTIFICATIONS_QR_CODES_LIST_CSV'
    | 'GET_FEATURE_FLAG_LIST_ALL'
    | 'GET_FEATURE_FLAG_RULES'
    | 'GET_GEOTRACKING_CARD_LIST'
    | 'GET_GEOTRACKING_CARD_LOCATION_HISTORY'
    | 'GET_MY_PURCHASES_LIST'
    | 'GET_NETWORKS_SELECT'
    | 'GET_ONE_PACKAGE'
    | 'GET_ONE_PACKAGE_INSTANCE'
    | 'GET_ONE_PACKAGE_TO_BUY'
    | 'GET_ONE_SOLD_PACKAGE'
    | 'GET_OPERATOR'
    | 'GET_OPERATOR_ACTIVITY'
    | 'GET_OPERATOR_INTERACTIONS_COMMANDS_SELECT'
    | 'GET_OPERATOR_INTERACTIONS_FILES'
    | 'GET_OPERATOR_INTERACTIONS_FILES_SELECT'
    | 'GET_OPERATOR_INTERACTIONS_GATEWAYS_SELECT'
    | 'GET_OPERATOR_INTERACTIONS_RESPONSE'
    | 'GET_OPERATOR_INTERACTIONS_RESPONSES'
    | 'GET_OPERATOR_INTERACTIONS_RESPONSES_CSV'
    | 'GET_OPERATORS_GATEWAYS_SELECT'
    | 'GET_OPERATORS_LIST'
    | 'GET_OPERATORS_SELECT'
    | 'GET_OPERATORS_SELECT_ALL'
    | 'GET_ORDER'
    | 'GET_ORDER_PAYMENT_SELECT'
    | 'GET_ORDER_PRODUCT_SELECT'
    | 'GET_ORDERS_LIST'
    | 'GET_PACKAGE_INSTANCES'
    | 'GET_PACKAGES'
    | 'GET_PACKAGES_SOLD'
    | 'GET_PACKAGES_TO_BUY'
    | 'GET_PACKAGES_UNITS_LOGS_LIST'
    | 'GET_PAYMENT_SELECT'
    | 'GET_PROFILE_ALARM'
    | 'GET_PURCHASE_RATE_LIST'
    | 'GET_PURCHASE_RATES_LIST'
    | 'GET_RATE_UNIT_SELECT'
    | 'GET_REPORT_LIST'
    | 'GET_FINANCE_ROLL'
    | 'GET_REVENUE_CARD_SET_LIST'
    | 'GET_REVENUE_CARDS_LIST'
    | 'GET_REVENUE_COMPANY_LIST'
    | 'GET_REVENUE_COUNTRY_LIST'
    | 'GET_REVENUE_TELECOM_LIST'
    | 'GET_REVENUE_TIMELINE_LIST'
    | 'GET_REVENUE_ZONE_LIST'
    | 'GET_SALES_RATE_CSV'
    | 'GET_SALES_RATE_LIST'
    | 'GET_SALES_RATES_LIST'
    | 'GET_SALES_RATES_SELECT'
    | 'GET_SERVER_NOTIFICATIONS'
    | 'GET_SUBSCRIBER'
    | 'GET_SUBSCRIBERS_BY_CARD_ID'
    | 'GET_SUBSCRIBERS_LIST'
    | 'GET_SUBSCRIBERS_LIST_CSV'
    | 'GET_SUBSCRIBERS_STATUS'
    | 'GET_UNIT_TYPE_SELECT'
    | 'GET_ACTIVITY_ROLL'
    | 'GET_USAGE_BIGQUERY_FLAG'
    | 'GET_USAGE_CARD_SETS_LIST'
    | 'GET_USAGE_CARDS_LIST'
    | 'GET_USAGE_COMPANY_LIST'
    | 'GET_USAGE_COUNTRY_LIST'
    | 'GET_USAGE_TELECOM_LIST'
    | 'GET_USAGE_TIMELINE_LIST'
    | 'GET_USAGE_ZONE_LIST'
    | 'GET_USER'
    | 'GET_USER_PROFILE'
    | 'GET_USER_TYPE_SELECT'
    | 'GET_USERS_BY_COMPANY_SELECT'
    | 'GET_USERS_LIST'
    | 'GET_ZONE_LIST'
    | 'GET_ZONE_SELECT'
    | 'LOG_IN'
    | 'LOG_OUT'
    | 'PASSWORD_UPDATE'
    | 'PASSWORD_UPDATE_EMAIL'
    | 'PATCH_CARD'
    | 'PATCH_CARD_SET'
    | 'PATCH_COMPANY'
    | 'PATCH_COMPANY_ABOUT'
    | 'PATCH_COMPANY_SETTINGS'
    | 'PATCH_COUNTRY'
    | 'PATCH_CURRENCY'
    | 'PATCH_FEATURE_FLAGS'
    | 'PATCH_OPERATOR'
    | 'PATCH_PACKAGE'
    | 'PATCH_PACKAGE_INSTANCE'
    | 'PATCH_PASSWORD'
    | 'PATCH_PROFILE_ALARM'
    | 'PATCH_PURCHASE_RATE_LIST'
    | 'PATCH_SALES_RATE_LIST'
    | 'PATCH_SERVER_NOTIFICATION'
    | 'PATCH_SUBSCRIBER'
    | 'PATCH_USER'
    | 'POST_ACCEPTANCE_DOCUMENTS'
    | 'POST_BLOB'
    | 'POST_CALL_DATA_RECORDS'
    | 'POST_CARD_DEVICE_FILES'
    | 'POST_CARD_SET'
    | 'POST_CARD_SET_LIST_CSV'
    | 'POST_CARDS_LIST_CSV'
    | 'POST_COMPANY'
    | 'POST_COMPANY_API_KEY'
    | 'POST_COUNTRY'
    | 'POST_OPERATOR'
    | 'POST_OPERATOR_INTERACTIONS_FILES'
    | 'POST_ORDER'
    | 'POST_ORDER_CHECKOUT'
    | 'POST_PACKAGE'
    | 'POST_PACKAGE_ORDER'
    | 'POST_PURCHASE_RATE_CSV'
    | 'POST_PURCHASE_RATE_LIST'
    | 'POST_SALES_RATE_CSV'
    | 'POST_SALES_RATE_LIST'
    | 'POST_SUBSCRIBERS_API_REQUEST'
    | 'POST_SUBSCRIBERS_STATUS'
    | 'POST_UPLOAD_ACCEPTANCE_DOCUMENTS'
    | 'POST_USER'
    | 'POST_USER_IMPERSONATION'
    | 'REFRESH_TOKEN'
    | 'CREATE_COMPANY_SUBSCRIPTION'
    | 'DELETE_COMPANY_SUBSCRIPTION'
    | 'UPDATE_COMPANY_SUBSCRIPTION'
    | 'GET_COMPANY_SUBSCRIPTION'
    | 'POST_COMPANY_SUBSCRIPTION_ORDER'
    | 'POST_ES2_PLUS_CANCEL_ORDER'
    | 'POST_ES2_PLUS_CONFIRM_ORDER'
    | 'POST_ES2_PLUS_DOWNLOAD_ORDER'
    | 'POST_ES2_PLUS_RELEASE_ORDER'
    | 'POST_SCP_FILE';

export type ILoginRequest = {
    email: string;
    password: string;
    remember: boolean;
};

export type ITokenResponse = {
    access?: string;
    refresh?: string;
    isError: boolean;
};

export type IGetApiResponseFile = {
    content: Blob;
    filename?: string;
    isAPIComplete: boolean;
};

export type IGetApiResponse = {
    file?: IGetApiResponseFile;
    isError: boolean;
    isPolicyError?: boolean;
    resp: IBodyItemResponse | IBodyListResponse;
};

export type IMeta = {
    number?: number;
    records_count?: number;
    size?: number;
    total_count?: number;
};

export type IBodyResponse = {
    access?: string;
    apiHost?: string;
    docs_accepted?: boolean;
    company_id?: string;
    errors?: IApiErrorMessages;
    included?: IItemAPI[];
    meta?: IMeta;
    refresh?: string;
    version?: string;
    key?: string;
};

export type IRankItem = {
    [id: string]: string | number | IRevenues;
};
export interface IBodyRanksResponse extends IBodyResponse {
    data?: {
        attributes?: {
            ranks: IRankItem[];
        };
    };
}

export interface IGetApiResponseCount {
    count?: number;
}

export interface IGetBigQueryFlag {
    use_bigquery: boolean;
}
export interface IBodyItemResponse extends IBodyResponse, IGetApiResponseCount {
    data?: IItemAPI;
    ReasonCode?: string;
    header?: object;
}

export interface IBodyListResponse extends IBodyResponse, IGetApiResponseCount {
    data?: IItemAPI[];
}

export type ILogoRespAPI = {
    dark_logo_url?: string;
    logo_url?: string;
    website?: string;
    custom_logo_link_url?: string;
};
export type ILogoResp = {
    logoBlack?: string;
    logoWhite?: string;
    website?: string;
    customLogoLink?: string;
};

// ToDo: 30.05.2022 - Big test API

export type IRequestMainItem = {
    id?: string;
    type: IIncludeTypeAPI;
};

export type IRequestRelationshipMainItem = IRequestMainItem & {
    id: string;
};

export interface IRelationshipItem extends IRequestRelationshipMainItem {
    destroy?: boolean;
    parentId?: string;
}

export type IRequestRelationships = {
    [type in IIncludeTypeAPI]?: {
        data?: IRequestRelationshipMainItem | IRequestRelationshipMainItem[];
    };
};

export type IRequestMainAttributesItem = IRequestMainItem & {
    attributes?: IAttributes;
};

export type IRequestIncludedItem = IRequestRelationshipMainItem & {
    attributes?: IAttributes;
};

export type IAttributes = IMapField;

export type IRequestAuthBody = {
    auth?: ILoginRequest;
    email?: string;
    password?: string;
    redirect_path?: string;
    reset_token?: string;
};

export type IRequestDataRelationships = {
    data?: {
        relationships?: IRequestRelationships;
    };
};

export type IRequestDataFileBody = {
    [id: string]: string;
};
export type IRequestDataFile = {
    gateway?: string;
};
export type IRequestDataItems = {
    data?: IRequestMainAttributesItem | IRequestMainAttributesItem[];
};

export type IRequestData = IRequestDataFile & IRequestDataItems & IRequestDataRelationships;

export type IRequestAPIBody = IRequestData &
    IRequestAuthBody & {
        included?: IRequestIncludedItem[];
    };

export type IApiManagerAppSignal = {
    apiParams: IGetApiParams;
    apiType: IGetApiType;
};
export type IApiManagerAppSignalError = IApiManagerAppSignal & {
    error: Error;
};

export type IApiManagerAppSignalResponse = IApiManagerAppSignal & {
    response: Response;
};
