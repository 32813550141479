import React from 'react';
import { observer } from 'mobx-react';
import { ESPlusNotificationsLogsList } from './EsPlusNotificationsLogsList';
import { Catcher } from '../../Catcher';
import Styles from '../../styles/m_itemCardStyles.less';

export const ESPlusNotificationsLogs: React.FC = observer(() => {
    return (
        <Catcher>
            <div className={Styles.main}>
                <ESPlusNotificationsLogsList />
            </div>
        </Catcher>
    );
});
