import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { CallDataRecordsBetaStore } from '../../stores/callDataRecordsBeta';
import { ICallDataRecordsListItem, IFilterParams, IGetApiType } from '../../interfaces';

export function useCallDataRecordsBetaStore(): CallDataRecordsBetaStore {
    return useContext(MobXProviderContext).RootStore.callDataRecordsStoreBeta;
}
export function useCallDataRecordsFilterParamsBeta(): IFilterParams {
    return useCallDataRecordsBetaStore().filterParams;
}
export function useCallDataRecordsBetaListCount(): number {
    const { squashedCount } = useCallDataRecordsBetaStore();
    return squashedCount;
}

export function useCallDataRecordsBetaPaginationIdSpinners(): IGetApiType[] {
    return ['GET_CALL_DATA_SQUASHED_RECORDS_COUNT'];
}

export function useCallDataRecordsBetaSquashedCount(): number {
    return useCallDataRecordsBetaStore().squashedCount;
}

export function useCallDataRecordsListBeta(): ICallDataRecordsListItem[] {
    return useCallDataRecordsBetaStore().list;
}
