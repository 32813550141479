import { action, computed, makeObservable, observable } from 'mobx';
import { appConfig } from '../settings';
import { IToken, ITokenType } from '../REST';
import { AuthTokenTypes, IAcceptanceDocumentsItem, IAppStatus } from '../interfaces';
import { checkTokens } from '../middleware';

export class AuthStore {
    private constructor() {
        makeObservable(this);
        if (appConfig.delayTokenCheck) {
            setInterval(() => this.checkTokenTimerExecution(), appConfig.delayTokenCheck);
        }
    }

    checkTokenTimerExecution = (): void => {
        if (this.appStatus === 'READY') {
            checkTokens();
        }
    };

    private static instance: AuthStore;
    public static getInstance(): AuthStore {
        if (!AuthStore.instance) {
            AuthStore.instance = new AuthStore();
        }
        return AuthStore.instance;
    }

    @observable appStatus: IAppStatus = 'INITIALIZATION';
    @action setAppStatus(status: IAppStatus): void {
        this.appStatus = status;
    }

    @observable acceptanceDocumentsList: IAcceptanceDocumentsItem[] = [];
    @action setAcceptanceDocumentsList(list: IAcceptanceDocumentsItem[]): void {
        this.acceptanceDocumentsList = list;
    }
    @computed get isAcceptanceAllDocuments(): boolean {
        return this.acceptanceDocumentsList.length
            ? this.acceptanceDocumentsList
                  .map((i: IAcceptanceDocumentsItem) => !!i.isAccept)
                  .reduce((result: boolean, item: boolean) => result && item)
            : false;
    }
    @action setAcceptItem(index: number): void {
        this.acceptanceDocumentsList = this.acceptanceDocumentsList.map((i: IAcceptanceDocumentsItem, ind) => {
            return ind !== index ? i : { ...i, isAccept: true };
        });
    }

    @observable tokens: AuthTokenTypes = {};
    @action saveToken(token: IToken, tokenType: ITokenType = 'access'): void {
        this.tokens[tokenType] = token;
    }
}
