import React, { ChangeEvent, useEffect, useState } from 'react';
import { Input } from 'antd';
import { SearchProps } from 'antd/lib/input/Search';
import cx from 'classnames';
import { useInputDebounce } from '../../../hooks';
import { ITrInterfaces, tI } from '../../../translate';
import Styles from '../m_styles.less';

const { Search } = Input;

interface IFilterSearch extends SearchProps {
    onChange?: never;
    onInput?: never;
    onSearch: (value: string | undefined) => void;
    title?: ITrInterfaces;
    value?: string;
}

export const FilterSearch: React.FC<IFilterSearch> = ({
    className,
    onSearch,
    title = 'Search',
    value,
    ...props
}: IFilterSearch) => {
    const showTitle = tI(title);
    const [search, setSearch] = useState<string>('');

    const debouncedSearch: string | undefined = useInputDebounce<string | undefined>(search);

    useEffect(() => {
        setSearch(value ? value : '');
    }, [value]);

    useEffect(() => {
        if ((!!value || !!debouncedSearch) && value !== debouncedSearch) {
            onSearch(debouncedSearch);
        }
    }, [debouncedSearch]);

    const onLocalChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setSearch(e.target.value);
    };

    return (
        <>
            <div className={Styles.labelFieldPrefix}>{showTitle}</div>
            <Search
                allowClear
                className={cx(Styles.fieldFull, 'input-search', className)}
                data-test={'SearchInput'}
                onChange={onLocalChange}
                placeholder={showTitle}
                value={search}
                {...props}
            />
        </>
    );
};
