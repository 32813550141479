import React from 'react';
import { Loading } from '../';

export const Initialization: React.FC = (): JSX.Element => {
    return (
        <div>
            <Loading isLoading={true} tip={'Initialization'} />
        </div>
    );
};
