import React from 'react';
import { Catcher, FieldInfoAdvanced } from '../../../';
import { IOperatorInteractionsResponsesData, IOperatorInteractionsResponsesError } from '../../../../interfaces';
import { ITrField } from '../../../../translate';
import Styles from './m_ShowResponsesDataStyles.less';

type IShowDataProps = {
    data?: IOperatorInteractionsResponsesData;
};

const ShowData: React.FC<IShowDataProps> = ({ data }: IShowDataProps) => {
    return (
        <Catcher>
            {data?.number ? (
                <FieldInfoAdvanced title={'Number' as ITrField}>
                    <div className={Styles.dataInfo}>{data.number}</div>
                </FieldInfoAdvanced>
            ) : null}
            {data?.numberlistrequestid ? (
                <FieldInfoAdvanced title={'Number list request id' as ITrField}>
                    <div className={Styles.dataInfo}>{data.numberlistrequestid}</div>
                </FieldInfoAdvanced>
            ) : null}
            {data?.plandate ? (
                <FieldInfoAdvanced title={'Plan date' as ITrField}>
                    <div className={Styles.dataInfo}>{data.plandate}</div>
                </FieldInfoAdvanced>
            ) : null}
        </Catcher>
    );
};

type IConvertErrorObj = {
    code?: string;
    description?: string;
};

function convertError2Obj(str?: string): IConvertErrorObj {
    const ccc: IConvertErrorObj = {};
    try {
        const aaa = str?.replaceAll('=>', ':') || '{}';
        const bbb = JSON.parse(aaa);
        ccc.code = bbb.error.errorcode;
        ccc.description = bbb.error.errordescription;
    } catch (e) {
        console.error('Convert data:', str, `->`, e);
    }
    return ccc;
}

type IShowErrorProps = {
    data?: IOperatorInteractionsResponsesError;
};
const ShowError: React.FC<IShowErrorProps> = ({ data }: IShowErrorProps) => {
    const objErr =
        typeof data?.error === 'string'
            ? convertError2Obj(data?.error)
            : { code: data?.error?.errorcode, description: data?.error?.errordescription };

    return (
        <Catcher>
            {objErr?.code ? (
                <FieldInfoAdvanced title={'Code' as ITrField}>
                    <div className={Styles.dataError}>{objErr?.code}</div>
                </FieldInfoAdvanced>
            ) : null}
            {objErr?.description ? (
                <FieldInfoAdvanced title={'Description' as ITrField}>
                    <div className={Styles.dataError}>{objErr?.description}</div>
                </FieldInfoAdvanced>
            ) : null}
        </Catcher>
    );
};

type IShowResponsesDataProps = {
    data?: IOperatorInteractionsResponsesData & IOperatorInteractionsResponsesError;
};

export const ShowResponsesData: React.FC<IShowResponsesDataProps> = ({ data }: IShowResponsesDataProps) => {
    if (!data) {
        return null;
    } else {
    }

    return (
        <Catcher>
            <div className={Styles.main}>{!!data.error ? <ShowError data={data} /> : <ShowData data={data} />}</div>
        </Catcher>
    );
};
