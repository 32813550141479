import React from 'react';
import { observer } from 'mobx-react';
import { Checkbox, Col, Row } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox/Checkbox';
import {
    Catcher,
    FilterDateRange,
    FilterLazyLoadingSelect,
    FilterPageSize,
    FilterSearch,
    FilterSelect,
    ReloadButton,
    cardLayoutOptions,
    rowGutter,
} from '../';
import cx from 'classnames';
import {
    IDateRange,
    IDivMainProps,
    IFilterParams,
    IFilterSelect,
    IFilterSelectComposed,
    IGetApiType,
} from '../../interfaces';
import {
    // useUiSource,
    useCardSetsSelect,
    useCompaniesSelect,
    useCompanyTypesSelect,
    useDiameterSessionsSelect,
    useEntitySelect,
    useOperatorInteractionsCommandsSelect,
    useOperatorInteractionsFilesSelect,
    useOperatorInteractionsGetewaysSelect,
    useOperatorInteractionsUploadFileStatusSelect,
    useOperatorsSelect,
    usePackagesSelect,
    usePaymentTypesSelect,
    useProfileIsRight,
    useUiCardSetId,
    useUiCompanyId,
    useUiCompanyTypeId,
    useUiDateRange,
    useUiEntityId,
    useUiIsActiveThisMonth,
    useUiIsDelete,
    useUiOperatorId,
    useUiOperatorInteractionsCommandId,
    useUiOperatorInteractionsGatewayId,
    useUiOperatorInteractionsUploadFileId,
    useUiOperatorInteractionsUploadFileStatusId,
    useUiPackageId,
    useUiPaymentType,
    useUiSearch,
    useUiSessionId,
    useUiUnitTypeIds,
    useUiUserId,
    useUiWithDeleted,
    useUiZoneId,
    useUnitTypesSelect,
    useUsersSelectWithTotal,
    useZonesSelect,
} from '../../hooks';
import {
    getUsersSelect, // setUiSource,
    setUiCardSetId,
    setUiCommandId,
    setUiCompanyId,
    setUiCompanyType,
    setUiDateRange,
    setUiEntityId,
    setUiFileId,
    setUiForcedUpdateTime,
    setUiGatewayId,
    setUiIsActiveThisMonth,
    setUiIsDelete,
    setUiOperatorId,
    setUiPackageId,
    setUiPaymentType,
    setUiSearch,
    setUiSessionId,
    setUiUnitTypeIds,
    setUiUploadFileStatusId,
    setUiUserId,
    setUiWithDeleted,
    setUiZoneId,
} from '../../middleware';
import { tF, tI } from '../../translate';
import '../../customStyle/input.less';
import Styles from './m_styles.less';

interface IFilterToolbarProps extends IDivMainProps {
    BottomBar?: React.FC;
    filterParams?: IFilterParams;
    idSpinners?: IGetApiType[];
    isReloadButton?: boolean;
    months?: number;
    onChange?: () => void;
}

export const FilterToolbar: React.FC<IFilterToolbarProps> = observer(
    ({
        BottomBar,
        className,
        filterParams,
        idSpinners,
        isReloadButton,
        months,
        onChange = setUiForcedUpdateTime,
        ...props
    }: IFilterToolbarProps) => {
        // const source = useUiSource();
        const cardSetId = useUiCardSetId();
        const companyId = useUiCompanyId();
        const companyTypeId = useUiCompanyTypeId();
        const dateRange = useUiDateRange();
        const entityId = useUiEntityId();
        const isActiveThisMonth = useUiIsActiveThisMonth();
        const isDelete = useUiIsDelete();
        const isLord = useProfileIsRight('LORD');
        const operatorId = useUiOperatorId();
        const operatorInteractionsCommandId = useUiOperatorInteractionsCommandId();
        const operatorInteractionsGetewayId = useUiOperatorInteractionsGatewayId();
        const operatorInteractionsUploadFileId = useUiOperatorInteractionsUploadFileId();
        const operatorInteractionsUploadFileStatusId = useUiOperatorInteractionsUploadFileStatusId();
        const packageId = useUiPackageId();
        const paymentType = useUiPaymentType();
        const search = useUiSearch();
        const sessionId = useUiSessionId();
        const unitTypeIds = useUiUnitTypeIds();
        const userId = useUiUserId();
        const withDeleted = useUiWithDeleted();
        const zoneId = useUiZoneId();

        const companiesSelectHook = useCompaniesSelect;
        const companyTypeSelectHook = useCompanyTypesSelect;
        const entityHook = useEntitySelect;
        const operatorInteractionsCommandsHook = useOperatorInteractionsCommandsSelect;
        const operatorInteractionsFilesHook = useOperatorInteractionsFilesSelect;
        const operatorInteractionsGetewaysHook = useOperatorInteractionsGetewaysSelect;
        const operatorInteractionsUploadFileStatusHook = useOperatorInteractionsUploadFileStatusSelect;
        const operatorsSelectHook = useOperatorsSelect;
        const packageSelectHook = usePackagesSelect;
        const paymentTypeSelectHook = usePaymentTypesSelect;
        const sessionHook = useDiameterSessionsSelect;
        const setsSelectHook = useCardSetsSelect;
        const unitTypesHook = useUnitTypesSelect;
        const userHook = useUsersSelectWithTotal;
        const zoneHook = useZonesSelect;

        const onChangeDataRange = (dateRange: IDateRange): void => {
            setUiDateRange(dateRange);
            onChange();
        };
        const onChangePageSize = (): void => {
            onChange();
        };
        const onChangeSearchInput = (search: IFilterSelect): void => {
            setUiSearch(search);
            onChange();
        };
        const onChangeCompany = (id: IFilterSelectComposed): void => {
            setUiCompanyId(id);
            onChange();
        };
        const onChangeCompanyType = (id: IFilterSelectComposed): void => {
            setUiCompanyType(id);
            onChange();
        };
        const onChangePaymentType = (paymentType: IFilterSelectComposed): void => {
            setUiPaymentType(paymentType);
            onChange();
        };
        const onChangePackage = (id: IFilterSelectComposed): void => {
            setUiPackageId(id);
            onChange();
        };
        const onChangeOperator = (id: IFilterSelectComposed): void => {
            setUiOperatorId(id);
            onChange();
        };
        const onChangeCardSet = (id: IFilterSelectComposed): void => {
            setUiCardSetId(id);
            onChange();
        };
        const onChangeSession = (id: IFilterSelectComposed): void => {
            setUiSessionId(id);
            onChange();
        };
        const onChangeUser = (id: IFilterSelectComposed): void => {
            setUiUserId(id);
            onChange();
        };
        const onChangeEntity = (id: IFilterSelectComposed): void => {
            setUiEntityId(id);
            onChange();
        };
        const onChangeUnitType = (id: IFilterSelectComposed): void => {
            setUiUnitTypeIds(id);
            onChange();
        };
        const onChangeGatewayId = (id: IFilterSelectComposed): void => {
            setUiGatewayId(id);
            onChange();
        };
        const onChangeCommandId = (id: IFilterSelectComposed): void => {
            setUiCommandId(id);
            onChange();
        };
        const onChangeFile = (id: IFilterSelectComposed): void => {
            setUiFileId(id);
            onChange();
        };
        const onChangeUploadFileStatus = (id: IFilterSelectComposed): void => {
            setUiUploadFileStatusId(id);
            onChange();
        };
        const onChangeZone = (id: IFilterSelectComposed): void => {
            setUiZoneId(id);
            onChange();
        };

        // const onChangeSource = (val: IFilterSelectComposed): void => {
        //     setUiSource(val);
        //     onChange();
        // };
        const onChangeIsDelete = (isDelete: boolean): void => {
            setUiIsDelete(isDelete);
            onChange();
        };
        const onChangeWithDeleted = (withDeleted: boolean): void => {
            setUiWithDeleted(withDeleted);
            onChange();
        };

        const onChangeIsActiveThingMonth = (isActiveThisMonth: boolean): void => {
            setUiIsActiveThisMonth(isActiveThisMonth);
            onChange();
        };

        const itemColOptions = cardLayoutOptions.filterToolBarFieldCol;

        return (
            <Catcher>
                <div className={cx(className, Styles.filterToolbarPlace, 'formStyle')} {...props}>
                    <Row
                        className={Styles.filterToolbar}
                        justify={'space-between'}
                        gutter={rowGutter.filterToolBarFieldCol}
                        data-test="FilterToolbar"
                    >
                        {filterParams?.pageSize ? (
                            <Col className={Styles.col} {...itemColOptions} data-test="PageSize">
                                <FilterPageSize onChange={onChangePageSize} />
                            </Col>
                        ) : null}
                        {filterParams?.unitTypeIds ? (
                            <Col className={Styles.col} {...itemColOptions} data-test="UnitType">
                                <FilterSelect
                                    idSpinners={['GET_UNIT_TYPE_SELECT']}
                                    isBlockAllowClear={filterParams.isUnitTypeIdsBlockClear}
                                    mode={filterParams?.unitTypeIds}
                                    onChange={onChangeUnitType}
                                    title={tI('Unit Type Filter')}
                                    useSelectHook={unitTypesHook}
                                    value={unitTypeIds}
                                />
                            </Col>
                        ) : null}
                        {filterParams?.dateRange ? (
                            <Col className={Styles.col} {...itemColOptions} data-test="DataRange">
                                <FilterDateRange
                                    onChange={onChangeDataRange}
                                    dateRange={dateRange}
                                    maximumMonths={months}
                                    filterParams={filterParams}
                                />
                            </Col>
                        ) : null}
                        {filterParams?.companyId ? (
                            <Col className={Styles.col} {...itemColOptions} data-test="FilterCompany">
                                <FilterSelect
                                    idSpinners={['GET_COMPANIES_SELECT']}
                                    isBlockAllowClear={filterParams.isCompanyIdBlockClear}
                                    onChange={onChangeCompany}
                                    title={tI('Company Filter')}
                                    useSelectHook={companiesSelectHook}
                                    value={companyId}
                                />
                            </Col>
                        ) : null}
                        {filterParams?.packageId ? (
                            <Col className={Styles.col} {...itemColOptions} data-test="FilterPackage">
                                <FilterSelect
                                    idSpinners={['GET_PACKAGES_TO_BUY']}
                                    isBlockAllowClear={filterParams.isPackageIdBlockClear}
                                    onChange={onChangePackage}
                                    title={tI('Package Filter')}
                                    useSelectHook={packageSelectHook}
                                    value={packageId}
                                />
                            </Col>
                        ) : null}
                        {filterParams?.companyTypeId ? (
                            <Col className={Styles.col} {...itemColOptions} data-test="FilterCompanyType">
                                <FilterSelect
                                    isBlockAllowClear={filterParams.isCompanyTypeIdBlockClear}
                                    onChange={onChangeCompanyType}
                                    title={tI('Company Filter')}
                                    useSelectHook={companyTypeSelectHook}
                                    value={companyTypeId}
                                />
                            </Col>
                        ) : null}
                        {filterParams?.paymentType ? (
                            <Col className={Styles.col} {...itemColOptions} data-test="FilterPaymentType">
                                <FilterSelect
                                    isBlockAllowClear={filterParams.isPaymentTypeBlockClear}
                                    onChange={onChangePaymentType}
                                    title={tI('Payment Filter')}
                                    useSelectHook={paymentTypeSelectHook}
                                    value={paymentType}
                                />
                            </Col>
                        ) : null}
                        {filterParams?.operatorId && isLord ? (
                            <Col className={Styles.col} {...itemColOptions} data-test="FilterOperator">
                                <FilterSelect
                                    idSpinners={['GET_OPERATORS_SELECT']}
                                    isBlockAllowClear={filterParams.isOperatorIdBlockClear}
                                    onChange={onChangeOperator}
                                    title={tI('Operator Filter')}
                                    useSelectHook={operatorsSelectHook}
                                    value={operatorId}
                                />
                            </Col>
                        ) : null}
                        {filterParams?.cardSetId ? (
                            <Col className={Styles.col} {...itemColOptions} data-test="FilterSet">
                                <FilterSelect
                                    idSpinners={['GET_CARD_SETS_SELECT']}
                                    isBlockAllowClear={filterParams.isCardSetIdBlockClear}
                                    onChange={onChangeCardSet}
                                    title={tI('Card Set Filter')}
                                    useSelectHook={setsSelectHook}
                                    value={cardSetId}
                                    virtual={true}
                                />
                            </Col>
                        ) : null}
                        {filterParams?.sessionId ? (
                            <Col className={Styles.col} {...itemColOptions} data-test="FilterSession">
                                <FilterSelect
                                    idSpinners={['GET_DIAMETER_SESSIONS_LIST']}
                                    isBlockAllowClear={filterParams.isSessionIdBlockClear}
                                    onChange={onChangeSession}
                                    title={tI('Session Filter')}
                                    useSelectHook={sessionHook}
                                    value={sessionId}
                                />
                            </Col>
                        ) : null}
                        {filterParams?.zoneId ? (
                            <Col className={Styles.col} {...itemColOptions} data-test="FilterZone">
                                <FilterSelect
                                    idSpinners={['GET_ZONE_SELECT']}
                                    isBlockAllowClear={filterParams.isZoneIdBlockClear}
                                    onChange={onChangeZone}
                                    title={tI('Zone Filter')}
                                    useSelectHook={zoneHook}
                                    value={zoneId}
                                />
                            </Col>
                        ) : null}
                        {filterParams?.userId ? (
                            <Col className={Styles.col} {...itemColOptions} data-test="FilterUser">
                                <FilterLazyLoadingSelect
                                    idSpinners={['GET_USERS_LIST']}
                                    isBlockAllowClear={filterParams.isUserIdBlockClear}
                                    onChange={onChangeUser}
                                    useSelectHook={userHook}
                                    title={tI('User Filter')}
                                    value={userId}
                                    fetchFunc={async (props) => getUsersSelect({ isActivityLogUsers: false, ...props })}
                                />
                            </Col>
                        ) : null}
                        {filterParams?.entityId ? (
                            <Col className={Styles.col} {...itemColOptions} data-test="FilterEntity">
                                <FilterSelect
                                    idSpinners={['GET_ENTITY_SELECT']}
                                    onChange={onChangeEntity}
                                    title={tI('Entity Filter')}
                                    useSelectHook={entityHook}
                                    value={entityId}
                                />
                            </Col>
                        ) : null}
                        {filterParams?.fileId ? (
                            <Col className={Styles.col} {...itemColOptions} data-test="FilterEntity">
                                <FilterSelect
                                    idSpinners={['GET_OPERATOR_INTERACTIONS_FILES_SELECT']}
                                    onChange={onChangeFile}
                                    title={tI('File Filter')}
                                    useSelectHook={operatorInteractionsFilesHook}
                                    value={operatorInteractionsUploadFileId}
                                />
                            </Col>
                        ) : null}
                        {filterParams?.gatewayId ? (
                            <Col className={Styles.col} {...itemColOptions} data-test="FilterEntity">
                                <FilterSelect
                                    idSpinners={['GET_OPERATOR_INTERACTIONS_GATEWAYS_SELECT']}
                                    onChange={onChangeGatewayId}
                                    title={tI('Gateway Filter')}
                                    useSelectHook={operatorInteractionsGetewaysHook}
                                    value={operatorInteractionsGetewayId}
                                />
                            </Col>
                        ) : null}
                        {filterParams?.commandId ? (
                            <Col className={Styles.col} {...itemColOptions} data-test="FilterEntity">
                                <FilterSelect
                                    idSpinners={['GET_OPERATOR_INTERACTIONS_COMMANDS_SELECT']}
                                    onChange={onChangeCommandId}
                                    title={tI('Command Filter')}
                                    useSelectHook={operatorInteractionsCommandsHook}
                                    value={operatorInteractionsCommandId}
                                />
                            </Col>
                        ) : null}
                        {filterParams?.uploadFileStatusId ? (
                            <Col className={Styles.col} {...itemColOptions} data-test="FilterEntity">
                                <FilterSelect
                                    idSpinners={[]}
                                    onChange={onChangeUploadFileStatus}
                                    title={tI('Status Filter')}
                                    useSelectHook={operatorInteractionsUploadFileStatusHook}
                                    value={operatorInteractionsUploadFileStatusId}
                                />
                            </Col>
                        ) : null}
                        {/*{filterParams?.source ? (*/}
                        {/*    <Col className={Styles.col} {...itemColOptions} data-test="FilterSource">*/}
                        {/*        <FilterSelect*/}
                        {/*            idSpinners={[]}*/}
                        {/*            mode="multiple"*/}
                        {/*            onChange={onChangeSource}*/}
                        {/*            title={tI('Filter Unit Type')}*/}
                        {/*            useSelectHook={unitTypesHook}*/}
                        {/*            value={source}*/}
                        {/*        />*/}
                        {/*    </Col>*/}
                        {/*) : null}*/}
                        {filterParams?.search ? (
                            <Col className={Styles.col} {...itemColOptions} data-test="Search">
                                <FilterSearch value={search} onSearch={onChangeSearchInput} />
                            </Col>
                        ) : null}
                        {typeof filterParams?.isDelete !== 'undefined' ? (
                            <Col className={Styles.col} {...itemColOptions} data-test="OnlyDeleted">
                                <div className={cx(Styles.labelFieldPrefix, Styles.labelFieldPrefixDeletedAt)}>
                                    {tF('Show Only Deleted')}
                                </div>
                                <div>
                                    <Checkbox
                                        checked={isDelete}
                                        disabled={false}
                                        onChange={(e: CheckboxChangeEvent): void => onChangeIsDelete(e.target.checked)}
                                    />
                                </div>
                            </Col>
                        ) : null}
                        {typeof filterParams?.withDeleted !== 'undefined' ? (
                            <Col className={Styles.col} {...itemColOptions} data-test="ShowDeleted">
                                <div className={cx(Styles.labelFieldPrefix)}>{tF('Show Deleted')}</div>
                                <div>
                                    <Checkbox
                                        checked={withDeleted}
                                        disabled={false}
                                        onChange={(e: CheckboxChangeEvent): void =>
                                            onChangeWithDeleted(e.target.checked)
                                        }
                                    />
                                </div>
                            </Col>
                        ) : null}
                        {typeof filterParams?.isActiveThisMonth !== 'undefined' ? (
                            <Col className={Styles.col} {...itemColOptions} data-test="ActiveThisMonth">
                                <div className={cx(Styles.labelFieldPrefix)}>{tF('Active')}</div>
                                <div>
                                    <Checkbox
                                        checked={isActiveThisMonth}
                                        disabled={false}
                                        onChange={(e: CheckboxChangeEvent): void =>
                                            onChangeIsActiveThingMonth(e.target.checked)
                                        }
                                    />
                                </div>
                            </Col>
                        ) : null}
                        {isReloadButton ? <ReloadButton onChange={onChange} idSpinners={idSpinners} /> : null}
                    </Row>
                    {BottomBar ? <BottomBar /> : null}
                </div>
            </Catcher>
        );
    },
);
