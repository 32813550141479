import React from 'react';
import { observer } from 'mobx-react';
import { Table, Tooltip } from 'antd';
import { CheckCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import cx from 'classnames';
import { ISubscribersListItem } from '../../../interfaces';
import { useSubscribersTable, useUiIsLoading, useUiSearch } from '../../../hooks';
import { lightText } from '../../../instruments';
import { ITrInterfaces, tF, tI, tP } from '../../../translate';
import { Catcher, LinkComponent, TableItemToolbar, columnToolbar } from '../../../Components';
import '../../../customStyle/table.less';
import Styles from '../../styles/m_viewStyles.less';

export const ExternalAPISubscribersTableList: React.FC = observer(() => {
    const isLoading = useUiIsLoading();
    const list = useSubscribersTable();
    const search = useUiSearch();

    const tableColumns = [
        { ...columnToolbar, title: tP('Settings') },
        {
            title: tF('Active'),
            dataIndex: 'activeThisMonth',
        },
        {
            title: tF('IMSI'),
            dataIndex: 'imsiLink',
        },
        {
            title: tF('Operator'),
            dataIndex: 'operatorLink',
        },
        {
            title: tF('ICCID'),
            dataIndex: 'iccId',
        },
        {
            title: tF('MSISDN'),
            dataIndex: 'msisdn',
        },
    ];
    const tableData = list.map((c: ISubscribersListItem) => {
        const imsi = c.imsi ? c.imsi : `# ${c.id}`;
        const activeThisMonth = (
            <Tooltip placement="top" title={tI(c.activeThisMonth ? 'Active this month' : 'Not active this month')}>
                {c.activeThisMonth ? (
                    <CheckCircleOutlined className={cx(Styles.statusIcon, Styles.statusIconActive)} />
                ) : (
                    <MinusCircleOutlined className={cx(Styles.statusIcon, Styles.statusIconPassive)} />
                )}
            </Tooltip>
        );

        return {
            key: c.id,
            ...c,
            imsiLink: <LinkComponent currentListComponent={'SubscribersList'} id={c.id} isLight name={c.imsi} />,
            operatorLink: (
                <LinkComponent currentListComponent={'OperatorsList'} id={c.operatorId} isLight name={c.operator} />
            ),
            iccId: lightText(c.iccId, search),
            msisdn: lightText(c.msisdn, search),
            toolbar: (
                <TableItemToolbar id={c.id} itemName={`"${imsi}" ${tI('settings' as ITrInterfaces)}`} isBigButtons />
            ),
            activeThisMonth,
        };
    });

    return (
        <Catcher>
            <div className={Styles.tablePlace}>
                <Table
                    className={'table-theme'}
                    columns={tableColumns}
                    dataSource={tableData}
                    loading={isLoading}
                    pagination={false}
                    size={'middle'}
                />
            </div>
        </Catcher>
    );
});
