import React from 'react';
import { FileExcelOutlined, FilePdfOutlined, FileUnknownOutlined } from '@ant-design/icons';
import { IExportType } from '../../interfaces';

type IFileTypeIcons = {
    fileType: IExportType;
    className?: string;
};

export const FileTypeIcons: React.FC<IFileTypeIcons> = ({ fileType, className }: IFileTypeIcons): JSX.Element => {
    switch (fileType) {
        case 'pdf':
            return <FilePdfOutlined className={className} />;
        case 'csv':
            return <FileExcelOutlined className={className} />;
        default:
            return <FileUnknownOutlined className={className} />;
    }
};
