/* eslint-disable */
import { ProfileStore } from '../stores/profile';
import { ReportsStore } from '../stores/reports';
import {
    cleanCardSetsStore,
    cleanCountriesStore,
    cleanUsersStore,
    getCardSetsSelect,
    getCompaniesSelect,
    getUsersByCompanySelect,
    setUiTotal,
} from './';
import { IRequestAPIBody, apiManager } from '../REST';
import {
    IIncludeUserItemAPI,
    IParamsList,
    IPatchItem,
    IReportsList,
    IReportsListAPI,
    IReportsListItemAPI,
    IRequestRelationshipMainItem,
} from '../interfaces';
import { dateText2Utc, mapApi2UiField } from '../instruments';

function setReportsStore(apiUsers: IReportsListAPI): void {
    if (apiUsers.data) {
        const users: IIncludeUserItemAPI[] =
            apiUsers?.included?.filter((i: IRequestRelationshipMainItem) => i.type === 'user') || [];
        const StoreInstance = ReportsStore.getInstance();

        const list: IReportsList[] = apiUsers.data.map((report: IReportsListItemAPI) => {
            const attributes = mapApi2UiField(report.attributes || {});
            const createdAt = dateText2Utc(attributes.createdAt);
            delete attributes.payload;
            const payload = mapApi2UiField(report.attributes?.payload || {});
            const userId = report.relationships?.user?.data?.id;
            const user = mapApi2UiField(
                users.find((u: IRequestRelationshipMainItem) => u.id === userId)?.attributes || {},
            );
            return {
                id: report.id,
                ...attributes,
                createdAt,
                ...payload,
                userId,
                ...user,
            };
        });
        StoreInstance.setReportsList(list);
    }
}

export function setReportsForcedUpdateTime(): void {
    const StoreInstance = ReportsStore.getInstance();
    StoreInstance.setForcedUpdateTime();
}

export function updateReportsList(): void {
    const StoreInstance = ReportsStore.getInstance();
    const isProcessing = !!StoreInstance.list.filter(
        (r: IReportsList) => r.status === 'enqueued' || r.status === 'in_progress',
    ).length;
    if (isProcessing) {
        setReportsForcedUpdateTime();
    }
}

export async function deleteReport(id: string): Promise<IPatchItem> {
    const body = { status: 'canceled' } as IRequestAPIBody;

    const { isError } = await apiManager.getApi('DELETE_REPORT', { id }, body);
    return { isError };
}

export async function getReportsList(params: IParamsList): Promise<void> {
    const { resp } = await apiManager.getApi('GET_REPORT_LIST', { searchParamsList: params });
    resp && setReportsStore(resp as IReportsListAPI);
    const { meta } = resp;
    meta && setUiTotal(meta.records_count);
}

export function getReportsRelationSelect(): void {
    const ProfileInstance = ProfileStore.getInstance();
    getCompaniesSelect();
    getCardSetsSelect({});
    if (ProfileInstance.profile?.companyId) {
        getUsersByCompanySelect(ProfileInstance.profile.companyId);
    }
}

export function cleanReportsStore(): void {
    cleanCountriesStore();
    cleanCardSetsStore();
    cleanUsersStore();
    const StoreInstance = ReportsStore.getInstance();
    StoreInstance.cleanStore();
}
