import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Collapse, Form, Modal } from 'antd';
import { Rule } from 'antd/es/form';
import { CardLayout, cardLayoutOptions } from '../../Layout';
import { CardInfoToolBar } from '../../SubComponents';
import { IBodyItemResponse, IErrorFields, IEsNotificationsReleaseOrderBody } from '../../../interfaces';
import { useUiIsSpinnerFound } from '../../../hooks';
import { addNotification, apiMessagesGetFormRules, postEsNotificationsReleaseOrder } from '../../../middleware';
import { tB } from '../../../translate';
import { Catcher } from '../../Catcher';
import { InputDebounce } from '../../Forms';

const { Panel } = Collapse;

export const Es2ReleaseOrder: React.FC = observer(() => {
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const isLoading = useUiIsSpinnerFound(['POST_ES2_PLUS_RELEASE_ORDER']);

    const ruleRequired: Array<Rule> = [{ required: true, message: 'Required' }, ...apiMessagesGetFormRules];

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleRelease = () => {
        form.submit();
    };

    const handleCheckForm = async () => {
        try {
            await form.validateFields();

            setIsModalOpen(true);
        } catch (err: any) {
            console.error(err);
        }
    };

    const onFinish = async (fields: any): Promise<void> => {
        const body = {
            header: {
                functionCallIdentifier: fields.functionCallIdentifier,
                functionRequesterIdentifier: fields.functionRequesterIdentifier,
            },
            iccid: fields.iccid,
        } as IEsNotificationsReleaseOrderBody;

        const { isError, resp } = await postEsNotificationsReleaseOrder(body);

        const { header, ReasonCode } = resp as IBodyItemResponse;

        if (isError) {
            addNotification({
                type: 'error',
                message: 'Error!',
                description: `Something went wrong`,
                duration: 5,
            });

            return;
        }

        if (ReasonCode) {
            addNotification({
                type: 'error',
                message: 'Error!',
                description: JSON.stringify(header),
                duration: 10,
            });
        } else {
            addNotification({
                type: 'success',
                message: 'Released',
                description: `Your order has been released successfully`,
                duration: 5,
            });
        }

        setIsModalOpen(false);
    };

    return (
        <Catcher>
            <CardLayout
                title={'Release Order'}
                idSpinners={['POST_ES2_PLUS_RELEASE_ORDER']}
                dataTest={'EditCompanyContactInfo'}
                columnOption={cardLayoutOptions.cardEditCompany}
            >
                <Form
                    form={form}
                    name="release_order"
                    initialValues={{ functionCallIdentifier: 'TX-567', functionRequesterIdentifier: '2' }}
                    onFinish={onFinish}
                    scrollToFirstError={true}
                    layout={'vertical'}
                    className={'formStyle'}
                >
                    <Form.Item name="iccid" label={'iccid'} rules={ruleRequired}>
                        <InputDebounce placeholder={'iccid'} />
                    </Form.Item>
                    <Collapse style={{ marginTop: '10px', marginBottom: '10px' }} bordered={false}>
                        <Panel key={'1'} header={'Headers'}>
                            <Form.Item name="functionCallIdentifier" label={'functionCallIdentifier'}>
                                <InputDebounce autoComplete={'off'} placeholder={'functionCallIdentifier'} />
                            </Form.Item>
                            <Form.Item name="functionRequesterIdentifier" label={'functionRequesterIdentifier'}>
                                <InputDebounce autoComplete={'off'} placeholder={'functionRequesterIdentifier'} />
                            </Form.Item>
                        </Panel>
                    </Collapse>
                    <CardInfoToolBar
                        isLoading={isLoading}
                        isHideCancel={true}
                        labelSave={'Release'}
                        isDanger={true}
                        onSave={handleCheckForm}
                    />
                </Form>
            </CardLayout>
            <Modal
                title="Release confirm"
                open={isModalOpen}
                wrapClassName={'modal'}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        {tB('Cancel')}
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleRelease} danger>
                        {tB('Release')}
                    </Button>,
                ]}
                width={1000}
            >
                <h3>Are you sure? This option may create a duplicate profile</h3>
            </Modal>
        </Catcher>
    );
});
