import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Popconfirm } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import cx from 'classnames';
import { IGetApiResponse, apiConfig } from '../../../REST';
import { IItemAPI } from '../../../interfaces';
import { useBlobIds, useCompanyInfo } from '../../../hooks';
import {
    addNotification,
    deleteBlob,
    setCompanyInfoStoreCleanLogoBlobId,
    setCompanyInfoStoreCleanLogoUrl,
    setCompanyInfoStoreLogoBlobId,
} from '../../../middleware';
import { fileBlob2Base64 } from '../../../instruments';
import { formatDataTestAttribute } from '../../../instruments/testing';
import { tI, tT } from '../../../translate';
import { CardLayout, Catcher, UploadFile, cardLayoutOptions } from '../../../Components';
import Styles from '../../styles/m_itemCardStyles.less';

interface ILogoPreview {
    isLogoDark: boolean;
    handleDeleteLogo(isDarkLogo: boolean): void;
    logo: string;
    dataTest?: string;
}

export const LogoPreview: React.FC<ILogoPreview> = ({
    isLogoDark,
    handleDeleteLogo,
    logo,
    dataTest = '',
}: ILogoPreview) => {
    return (
        <div className={Styles.logoPreview}>
            <div className={Styles.label} data-test={formatDataTestAttribute(dataTest, 'LogoPreview', 'Title')}>
                {tI('Preview')}
            </div>
            <div
                className={cx(
                    Styles.logoPlace,
                    Styles.logoPlaceBorder,
                    isLogoDark ? Styles.logoPlaceDark : Styles.logoPlaceWhite,
                )}
            >
                <div className={Styles.deleteButtonContainer}>
                    <Popconfirm
                        placement="top"
                        title={'Are you sure you want to delete the logo?'}
                        onConfirm={(): void => handleDeleteLogo(isLogoDark)}
                        okText="Yes"
                        cancelText="No"
                        data-test={formatDataTestAttribute(dataTest, 'LogoPreview', 'Popconfirm')}
                    >
                        <Button
                            className={Styles.deleteButton}
                            type="primary"
                            shape="circle"
                            danger
                            icon={<CloseOutlined />}
                            size={'small'}
                            data-test={formatDataTestAttribute(dataTest, 'LogoPreview', 'CloseButton')}
                        />
                    </Popconfirm>
                </div>
                <img
                    alt={tI('Logo')}
                    className={Styles.logoImg}
                    src={logo}
                    data-test={formatDataTestAttribute(dataTest, 'LogoPreview', 'Logo')}
                />
            </div>
        </div>
    );
};

export const EditCompanyLogo: React.FC = observer(() => {
    const [newLogo, setNewLogo] = useState<string>('');
    const [newDarkLogo, setNewDarkLogo] = useState<string>('');
    const [isDarkLogo, setIsDark] = useState<boolean>(false);
    const { id, logoUrl, darkLogoUrl } = useCompanyInfo();
    const { logoBlobId, darkLogoBlobId } = useBlobIds();
    const isNewLogo = !!logoBlobId && !!newLogo;
    const isNewDarkLogo = !!darkLogoBlobId && !!newDarkLogo;

    const handleClick = (isDarkLogo: boolean): void => {
        setIsDark(isDarkLogo);
    };

    const onDownload = async (apiResponse: IGetApiResponse): Promise<void> => {
        const { isError, resp } = apiResponse;
        const { id } = resp?.data as IItemAPI;
        const blobId = !isDarkLogo ? logoBlobId : darkLogoBlobId;
        let isDeleteError = false;

        blobId !== '' && (isDeleteError = await deleteBlob(blobId));

        if (isDeleteError) {
            addNotification({
                type: 'error',
                message: 'There was an error while deleting an old logo.',
                isUsersError: true,
            });

            return;
        }

        if (isError) {
            setNewLogo('');
            setCompanyInfoStoreCleanLogoBlobId(isDarkLogo);
            addNotification({
                type: 'error',
                message: 'Upload Error!',
                isUsersError: true,
            });
        } else {
            setCompanyInfoStoreCleanLogoUrl(isDarkLogo);
            setCompanyInfoStoreCleanLogoBlobId(isDarkLogo);
            setCompanyInfoStoreLogoBlobId(id, isDarkLogo);
            addNotification({
                type: 'success',
                message: 'Upload successful!',
            });
        }
    };

    const setFile = async (file: File): Promise<void> => {
        const file64 = await fileBlob2Base64(file);
        if (typeof file64 === 'string') {
            isDarkLogo ? setNewDarkLogo(file64) : setNewLogo(file64);
        }
    };
    const _logoUrl = `${apiConfig.apiUrl()}${logoUrl}`;
    const _darkLogoUrl = `${apiConfig.apiUrl()}${darkLogoUrl}`;

    useEffect(() => {
        return (): void => {
            setCompanyInfoStoreCleanLogoBlobId(true);
            setCompanyInfoStoreCleanLogoBlobId(false);
        };
    }, []);

    const handleDeleteLogo = async (isLogoDark?: boolean): Promise<void> => {
        const isNew = !isLogoDark ? isNewLogo : isNewDarkLogo;
        const setLogo = !isLogoDark ? setNewLogo : setNewDarkLogo;
        const blobId = !isLogoDark ? logoBlobId : darkLogoBlobId;
        let isError = true;

        if (blobId) {
            isError = await deleteBlob(blobId);
        }

        if (!isError) {
            !isNew && setCompanyInfoStoreCleanLogoUrl(isLogoDark);
            setLogo('');
            setCompanyInfoStoreCleanLogoBlobId(isLogoDark);
            addNotification({
                type: 'success',
                message: 'Deleted successfully!',
            });
        } else {
            addNotification({
                type: 'error',
                message: 'Deletion error!',
                isUsersError: true,
            });
        }
    };

    return (
        <Catcher>
            <CardLayout
                title={'Logo'}
                idSpinners={['GET_COMPANY']}
                columnOption={cardLayoutOptions.cardCompanyLogo}
                dataTest={'EditCompanyLogo'}
            >
                <p className={Styles.paragraph} data-test={'EditCompanyLogoInfo'}>
                    {tT(
                        'We recommend using image files of less than 250 KB for best results, though the limit for an individual image upload is 3 MB. The dimensions of the original image you upload to our portal can have a big impact on how it displays. We recommend using images that are 3000 pixels high and 800 pixels wide. File types compatible are only .jpg, .jpeg, or .png (.pdf, .psd, and .doc files are NOT compatible).',
                    )}
                </p>
                <div className={Styles.logoContainer} style={{ marginBottom: '15px' }}>
                    <div className={Styles.logoField}>
                        <div className={Styles.label} data-test={'EditCompanyLogoLogoTitle'}>
                            {tI(
                                !logoUrl && !isNewLogo
                                    ? 'Please upload logo for White background'
                                    : isNewLogo
                                    ? 'New Logo'
                                    : 'Current Logo',
                            )}
                        </div>
                        <div className={Styles.buttonPlace} onClick={(): void => handleClick(false)}>
                            <UploadFile
                                apiType={'POST_BLOB'}
                                id={id}
                                onDownload={onDownload}
                                fileType={'picture'}
                                setFile={setFile}
                            />
                        </div>
                    </div>
                    {logoUrl || isNewLogo ? (
                        <LogoPreview
                            isLogoDark={false}
                            handleDeleteLogo={handleDeleteLogo}
                            logo={isNewLogo ? newLogo : _logoUrl}
                            dataTest={'EditCompanyLogo'}
                        />
                    ) : null}
                </div>
                <div className={Styles.logoContainer}>
                    <div className={Styles.logoField}>
                        <div className={Styles.label} data-test={'EditCompanyLogoDarkLogoTitle'}>
                            {tI(
                                !darkLogoUrl && !isNewDarkLogo
                                    ? 'Please upload logo for Dark background'
                                    : isNewDarkLogo
                                    ? 'New Dark Logo'
                                    : 'Current Dark Logo',
                            )}
                        </div>
                        <div className={Styles.buttonPlace} onClick={(): void => handleClick(true)}>
                            <UploadFile
                                apiType={'POST_BLOB'}
                                id={id}
                                onDownload={onDownload}
                                fileType={'picture'}
                                setFile={setFile}
                            />
                        </div>
                    </div>
                    {darkLogoUrl || isNewDarkLogo ? (
                        <LogoPreview
                            isLogoDark={true}
                            handleDeleteLogo={handleDeleteLogo}
                            logo={isNewDarkLogo ? newDarkLogo : _darkLogoUrl}
                            dataTest={'EditCompanyDarkLogo'}
                        />
                    ) : null}
                </div>
            </CardLayout>
        </Catcher>
    );
});
