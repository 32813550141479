import React, { Component, ErrorInfo, PropsWithChildren, ReactNode } from 'react';
import { appSignalSendMessage } from '../../instruments';
import { tNM } from '../../translate';
import Styles from './m_styles.less';

interface ICatcherState {
    error: boolean;
}

interface ICatcherProps extends PropsWithChildren {
    children?: ReactNode;
}

export class Catcher extends Component<ICatcherProps, ICatcherState> {
    state = {
        error: false,
    };
    // ToDo: Переделать на Hooks когда появится хуковый componentDidCatch

    componentDidCatch(error: Error, stack: ErrorInfo): void {
        appSignalSendMessage({
            action: 'Catcher',
            error,
            message: 'Catch crash component',
        });
        console.error(`Catcher componentDidCatch -> `, this.props.children);
        console.error('ERROR:', error.message);
        console.error('STACKTRACE:', stack.componentStack);
        this.setState({
            error: true,
        });
    }

    render() {
        const { error } = this.state;
        const { children } = this.props;
        if (error) {
            return (
                <section className={Styles.catcher}>
                    <span>{tNM('An unknown error has occurred.')}</span>
                    <p>{tNM('Our developers are already working on customizing the fix.')}</p>
                </section>
            );
        }

        return children;
    }
}
