import { action, makeObservable, observable } from 'mobx';
import { IPurchaseRateFormInfo, IPurchaseRateFormListItem } from '../interfaces';

export class PurchaseStore {
    private constructor() {
        makeObservable(this);
    }

    private static instance: PurchaseStore;
    public static getInstance(): PurchaseStore {
        if (!PurchaseStore.instance) {
            PurchaseStore.instance = new PurchaseStore();
        }
        return PurchaseStore.instance;
    }

    @observable info: IPurchaseRateFormInfo = { id: '' };
    @action setInfo(info: IPurchaseRateFormInfo): void {
        this.info = info;
    }

    @observable list: IPurchaseRateFormListItem[] = [];
    @action setList(list: IPurchaseRateFormListItem[]): void {
        this.list = list;
    }
    @action addListItem(item: IPurchaseRateFormListItem): void {
        this.list = [item, ...this.list];
    }
    @action removeListItem(id: string): void {
        this.list = this.list.filter((i) => i.id !== id);
    }

    @action cleanStore(): void {
        this.list = [];
        this.info = { id: '' };
    }
}
