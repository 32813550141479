import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { useCompanyInfo, useIncludedCurrencyList } from '../';
import {
    ICompaniesHashSettingList,
    ICompaniesHierarchyAPI,
    ICompaniesHierarchyIsNotBigQuery,
    ICompaniesHierarchyTableIsBigQuery,
    ICompaniesHierarchyTableIsNotBigQuery,
    ICompaniesList,
    ICurrenciesSelectItem,
    IFilterParams,
    ISelectList,
    IStatItem,
} from '../../interfaces';
import { getCurrencyShortCodeOrCodeById, mapStatApiItems, stringCount, stringMoney } from '../../instruments';
import { IShowMoneyItem, showMoneyList } from '../../Components';
import { ShowRevenueList } from '../../Components/CompaniesHierarchy';

export function useCompaniesTable(): ICompaniesList {
    return useContext(MobXProviderContext).RootStore.companiesStore.list;
}

export function useCompaniesSelect(): ISelectList {
    return useContext(MobXProviderContext).RootStore.companiesStore.select;
}

export function useCompaniesHashSettingsList(): ICompaniesHashSettingList {
    return useContext(MobXProviderContext).RootStore.companiesStore.companiesHashSettingsList;
}

export function useCompanyTypesSelect(): ISelectList {
    return useContext(MobXProviderContext).RootStore.companiesStore.companyTypeSelect;
}

export function usePaymentTypesSelect(): ISelectList {
    return useContext(MobXProviderContext).RootStore.companiesStore.paymentTypeSelect;
}

export function useCompaniesChildrenSelect(): ISelectList {
    return useContext(MobXProviderContext).RootStore.companiesStore.childrenSelect;
}

export function useMyCompanySelect(): ISelectList {
    const { parent = '', parentId = '' } = useCompanyInfo();

    return [{ id: parentId, name: parent, disabled: false }];
}

// ------------ HierarchyTableList ------------

type IUseCompaniesHierarchyIsHaveChildren = {
    findChildren(parentId: string): boolean;
};

export function useCompaniesHierarchyIsHaveChildrenIsNotBigQuery(): IUseCompaniesHierarchyIsHaveChildren {
    const companiesHierarchyList =
        useContext(MobXProviderContext).RootStore.companiesStore.companiesHierarchyListIsNotBigQuery;
    const findChildren = (parentId: string): boolean => {
        return !!companiesHierarchyList.find((c: ICompaniesHierarchyIsNotBigQuery) => c.parentId === parentId);
    };

    return {
        findChildren,
    };
}
const treeCompaniesHierarchyTableIsNotBigQuery = (
    list: Array<ICompaniesHierarchyTableIsNotBigQuery>,
): Array<ICompaniesHierarchyTableIsNotBigQuery> => {
    return list
        .reduce((a: Array<ICompaniesHierarchyTableIsNotBigQuery>, c: ICompaniesHierarchyTableIsNotBigQuery) => {
            c.children = list.filter((i: ICompaniesHierarchyTableIsNotBigQuery) => i.parentId === c.key);
            a.push(c);
            return a;
        }, [])
        .filter((i) => !i.parentId);
};

export function useCompaniesHierarchyTableIsNotBigQuery(): Array<ICompaniesHierarchyTableIsNotBigQuery> {
    const companiesHierarchyList =
        useContext(MobXProviderContext).RootStore.companiesStore.companiesHierarchyListIsNotBigQuery;
    const currencyList = useIncludedCurrencyList();
    const lineList = companiesHierarchyList.map((c: ICompaniesHierarchyIsNotBigQuery) => {
        const currencyFind = currencyList.find(
            (l: ICurrenciesSelectItem) => l.id === c.relationships?.currency?.data?.id,
        );
        const currency = currencyFind ? currencyFind.abbreviation : '';
        return {
            key: c.id,
            name: c.attributes?.name,
            balance: stringMoney(c.attributes?.balance, currency),
            paymentType: c.attributes?.payment_type,
            cardsCount: stringCount(c.attributes?.cards_count),
            cost: stringMoney(c.attributes?.cost, currency),
            nodesCount: c.attributes?.nodes_count,
            nodesCountShow: stringCount(c.attributes?.nodes_count),
            parentId: c.parentId,
            RevenueList: ShowRevenueList({ revenue: c.attributes?.revenue }),
        };
    });
    return treeCompaniesHierarchyTableIsNotBigQuery(lineList);
}

export function useCompaniesHierarchyIsHaveChildrenIsBigQuery(): IUseCompaniesHierarchyIsHaveChildren {
    const companiesHierarchyList =
        useContext(MobXProviderContext).RootStore.companiesStore.companiesHierarchyListIsBigQuery;
    const findChildren = (parentId: string): boolean => {
        return !!companiesHierarchyList.find((c: ICompaniesHierarchyAPI) => c.parentId === parentId);
    };

    return {
        findChildren,
    };
}

const treeCompaniesHierarchyTableIsBigQuery = (
    list: Array<ICompaniesHierarchyTableIsBigQuery>,
): Array<ICompaniesHierarchyTableIsBigQuery> => {
    return list
        .reduce((a: Array<ICompaniesHierarchyTableIsBigQuery>, c: ICompaniesHierarchyTableIsBigQuery) => {
            c.children = list.filter((i: ICompaniesHierarchyTableIsBigQuery) => i.parentId === c.key);
            a.push(c);
            return a;
        }, [])
        .filter((i) => !i.parentId);
};

export function useCompaniesHierarchyTableIsBigQuery(): Array<ICompaniesHierarchyTableIsBigQuery> {
    const companiesHierarchyList =
        useContext(MobXProviderContext).RootStore.companiesStore.companiesHierarchyListIsBigQuery;
    const lineList: Array<ICompaniesHierarchyTableIsBigQuery> = companiesHierarchyList.map(
        (c: ICompaniesHierarchyAPI) => {
            const currencySymbol =
                c.currency?.currency_symbol || c.currency?.currency_abbreviation || c.currency?.currency_id
                    ? getCurrencyShortCodeOrCodeById(c.currency.currency_id ? c.currency.currency_id.toString() : '')
                    : '';
            const stats = mapStatApiItems(c.costs_and_revenues);
            const item: ICompaniesHierarchyTableIsBigQuery = {
                balance: stringMoney(c.balance, currencySymbol),
                cardsCount: stringCount(c.cards_count),
                childrenCount: c.children_count || 0,
                key: c.id,
                name: c.name || '',
                parentId: c.parentId,
                paymentType: c.payment_type?.name || '',
                costList: showMoneyList(
                    stats.map((i: IStatItem): IShowMoneyItem => ({ value: i.cost, symbol: i.currencySymbol })),
                ),
                revenueList: showMoneyList(
                    stats.map((i: IStatItem): IShowMoneyItem => ({ value: i.revenue, symbol: i.currencySymbol })),
                ),
            };
            return item;
        },
    );
    return treeCompaniesHierarchyTableIsBigQuery(lineList);
}

export function useCompaniesFilterParams(): IFilterParams {
    return useContext(MobXProviderContext).RootStore.companiesStore.filterParams;
}
