import React from 'react';
import { observer } from 'mobx-react';
import { Divider } from 'antd';
import { BankOutlined } from '@ant-design/icons';
import { CardInfoToolBar, CardMainLayout, Catcher, FieldInfoAdvanced, cardLayoutOptions } from '../../';
import { PackageInfoBox, PackageItemList } from './';
import { IFieldLayout, IShowForm } from '../../../interfaces';
import { usePackageInfo, useProfile, useProfileIsRight } from '../../../hooks';
import Styles from '../../styles/m_itemCardStyles.less';

export const ShowPackage: React.FC<IShowForm> = observer(({ setEditMode }: IShowForm) => {
    const isAdmin = useProfileIsRight('ADMIN');
    const { companyId: myCompanyId } = useProfile();
    const isLord = useProfileIsRight('LORD');
    const packageInfo = usePackageInfo();
    const { name, companyId, assignedCompanies } = packageInfo;
    const layout: IFieldLayout = {
        labelCol: {
            flex: '0 1 175px',
        },
        valCol: {
            flex: '0 1 175px',
        },
    };

    return (
        <Catcher>
            <CardMainLayout columnOption={cardLayoutOptions.packageCard} idSpinners={['GET_ONE_PACKAGE']} name={name}>
                <PackageInfoBox packageInfo={packageInfo} />
                <FieldInfoAdvanced
                    icon={<BankOutlined className={Styles.labelIcon} />}
                    layout={layout}
                    title={'Assigned Companies'}
                >
                    <PackageItemList valueList={assignedCompanies?.assignedNameArr} />
                </FieldInfoAdvanced>
                <Divider />
                <CardInfoToolBar
                    setEditMode={isLord || (isAdmin && companyId === myCompanyId) ? setEditMode : undefined}
                    isBackToList
                />
            </CardMainLayout>
        </Catcher>
    );
});
