import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import { RuleObject } from 'antd/es/form';
import {
    CheckOutlined,
    CloseOutlined,
    LockOutlined,
    MailOutlined,
    UnlockOutlined,
    UserOutlined,
} from '@ant-design/icons';
import { Catcher, makeRuleEmail, makeRuleFirstName, makeRuleLastName, makeRulePassword, rowGutter } from '../../';
import { SignUpOk } from './SignUpOk';
import { FieldData } from 'rc-field-form/es/interface';
import { appConfig } from '../../../settings';
import { IFormFieldValue, IFormValidatorResponse, IValidationField } from '../../../interfaces';
import { useRouterStore } from '../../../hooks';
import { tB, tF, tI, tP, tT, tV } from '../../../translate';
import '../../../customStyle/form.less';
import '../../../customStyle/modal.less';
import Styles from '../m_styles.less';
import '../styles.less';

export const SignUpForm: React.FC = observer(() => {
    const [form] = Form.useForm();
    const [initialValues] = useState({
        firstName: '',
        lastName: '',
        businessEmail: '',
        email: '',
        password: '',
        confirm: '',
        remember: false,
    });
    const [validatedFields] = useState(['firstName', 'lastName', 'businessEmail', 'email', 'password', 'confirm']);
    const [isValid, setValid] = useState(false);
    const [isSending, setSending] = useState(false);

    const refPrivacyPolicyBody = useRef<HTMLDivElement>(null);
    const [isOpenPrivacyPolicy, setOpenPrivacyPolicy] = useState(false);
    const [isReadPrivacyPolicy, setReadPrivacyPolicy] = useState(false);
    const [isAcceptPrivacyPolicy, setAcceptPrivacyPolicy] = useState(false);

    const refGTCBody = useRef<HTMLDivElement>(null);
    const [isOpenGTC, setOpenGTC] = useState(false);
    const [isReadGTC, setReadGTC] = useState(false);
    const [isAcceptGTC, setAcceptGTC] = useState(false);

    const [isOpenSignUpOk, setOpenSignUpOk] = useState(false);

    const routerStore = useRouterStore();
    const goToLogin = (): void => {
        setOpenPrivacyPolicy(false);
        setOpenGTC(false);
        setOpenSignUpOk(false);
        routerStore.goTo('Login');
    };

    const acceptPrivacyPolicy = (): void => {
        setAcceptPrivacyPolicy(true);
        setOpenPrivacyPolicy(false);
    };
    const handleScrollPrivacyPolicy = (e: any): void => {
        const isBottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (isBottom) {
            setReadPrivacyPolicy(true);
        }
    };

    const acceptGTC = (): void => {
        setAcceptGTC(true);
        setOpenGTC(false);
    };

    const handleScrollGTC = (e: any): void => {
        const isBottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (isBottom) {
            setReadGTC(true);
        }
    };

    useEffect(() => {
        if (isOpenGTC && !isReadGTC) {
            setTimeout(() => {
                const clientHeight = refGTCBody?.current?.clientHeight;
                const scrollHeight = refGTCBody?.current?.scrollHeight;
                if (clientHeight && scrollHeight && scrollHeight <= clientHeight) {
                    setReadGTC(true);
                }
            }, appConfig.delayDoReadingPrivacyPolicy);
        }
    }, [isOpenGTC]);

    useEffect(() => {
        if (isOpenPrivacyPolicy && !isReadPrivacyPolicy) {
            setTimeout(() => {
                const clientHeight = refPrivacyPolicyBody?.current?.clientHeight;
                const scrollHeight = refPrivacyPolicyBody?.current?.scrollHeight;
                if (clientHeight && scrollHeight && scrollHeight <= clientHeight) {
                    setReadPrivacyPolicy(true);
                }
            }, appConfig.delayDoReadingPrivacyPolicy);
        }
    }, [isOpenPrivacyPolicy]);

    const onFieldsChange = (changedFields: FieldData[], allFields: Array<any>): void => {
        setValid(
            allFields
                .filter((f: IValidationField) => validatedFields.includes(f.name[0]))
                .map((f: IValidationField) => f.touched && !f.errors.length)
                .reduce((res: boolean, item: boolean) => res && item),
        );
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onFinish = async (): Promise<void> => {
        setSending(true);
        setValid(false);
        setOpenSignUpOk(true);
        setSending(false);
        setValid(false);
    };

    const firstNameTitle = tF('First name');
    const lastNameTitle = tF('Last name');
    const businessEmailTitle = tF('Business Email Address');
    const passwordTitle = tF('Password');

    return (
        <Catcher>
            <Form
                form={form}
                name="signUp"
                className={'formStyle'}
                initialValues={initialValues}
                onFieldsChange={onFieldsChange}
                onFinish={onFinish}
                layout={'vertical'}
            >
                <Row gutter={rowGutter.signUpForm}>
                    <Col xs={12}>
                        <Form.Item name="firstName" rules={makeRuleFirstName()} label={firstNameTitle}>
                            <Input placeholder={firstNameTitle} />
                        </Form.Item>
                        <Form.Item name="lastName" rules={makeRuleLastName()} label={lastNameTitle}>
                            <Input placeholder={lastNameTitle} />
                        </Form.Item>
                        <Form.Item name="businessEmail" rules={makeRuleEmail()} label={businessEmailTitle}>
                            <Input
                                prefix={<MailOutlined className="site-form-item-icon" />}
                                placeholder={businessEmailTitle}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={12}>
                        <Form.Item name="username" rules={makeRuleEmail()} label={tF('Username. Use email')}>
                            <Input
                                prefix={<UserOutlined className="site-form-item-icon" />}
                                placeholder={tI('username')}
                            />
                        </Form.Item>
                        <Form.Item name="password" rules={makeRulePassword()} label={passwordTitle}>
                            <Input.Password
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder={passwordTitle}
                            />
                        </Form.Item>
                        <Form.Item
                            name="confirm"
                            rules={[
                                {
                                    required: true,
                                    message: tV('Please confirm your password!'),
                                },
                                ({ getFieldValue }) => ({
                                    validator(rule: RuleObject, value: IFormFieldValue): IFormValidatorResponse {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(
                                            tV('The new password should not coincide with the old password!'),
                                        );
                                    },
                                }),
                            ]}
                            label={tF('Confirm password')}
                            dependencies={['password']}
                        >
                            <Input.Password
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder={passwordTitle}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row className={Styles.acceptPolicyPanel}>
                    <Col xs={12}>
                        <a href={'#'} onClick={() => setOpenGTC(true)}>
                            General Terms & Conditions
                        </a>
                    </Col>
                    <Col xs={12}>
                        <div className={Styles.forgotLink}>
                            <a href={'#'} onClick={() => setOpenPrivacyPolicy(true)}>
                                {tF('Privacy Policy')}
                            </a>
                        </div>
                    </Col>
                </Row>

                <Form.Item>
                    <Button
                        icon={<UnlockOutlined />}
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                        block
                        disabled={!isValid || !isAcceptPrivacyPolicy || !isAcceptGTC}
                        loading={isSending}
                    >
                        {tB('Accept')}
                    </Button>
                </Form.Item>
                <div className={Styles.description}>{tT('signFormText')}</div>
            </Form>
            <Modal
                title={tP('Privacy Policy')}
                open={isOpenPrivacyPolicy}
                onCancel={(): void => setOpenPrivacyPolicy(false)}
                footer={[
                    <Button
                        key={'Accept'}
                        type="primary"
                        onClick={acceptPrivacyPolicy}
                        icon={<CheckOutlined />}
                        disabled={!isReadPrivacyPolicy}
                    >
                        {tB('Accept')}
                    </Button>,
                    <Button key={'Close'} onClick={(): void => setOpenPrivacyPolicy(false)} icon={<CloseOutlined />}>
                        {tB('Close')}
                    </Button>,
                ]}
                wrapClassName={'custom-modal'}
            >
                <div className={'scroll-body'} onScroll={handleScrollPrivacyPolicy} ref={refPrivacyPolicyBody}>
                    {/*<PrivacyPolicy />*/}
                </div>
            </Modal>
            <Modal
                title={tP('General Terms & Conditions')}
                open={isOpenGTC}
                onCancel={(): void => setOpenGTC(false)}
                footer={[
                    <Button
                        key={'Accept'}
                        type="primary"
                        onClick={acceptGTC}
                        icon={<CheckOutlined />}
                        disabled={!isReadGTC}
                    >
                        {tB('Accept')}
                    </Button>,
                    <Button key={'Close'} onClick={(): void => setOpenGTC(false)} icon={<CloseOutlined />}>
                        {tB('Close')}
                    </Button>,
                ]}
                wrapClassName={'custom-modal'}
            >
                <div className={'scroll-body'} onScroll={handleScrollGTC} ref={refGTCBody}>
                    {/*<GeneralTermsConditions />*/}
                </div>
            </Modal>
            <Modal
                title={tP('Thank you for signing up')}
                open={isOpenSignUpOk}
                onCancel={goToLogin}
                footer={[
                    <Button key={'Close'} onClick={goToLogin} icon={<CloseOutlined />}>
                        {tB('Close')}
                    </Button>,
                ]}
                wrapClassName={'custom-modal'}
            >
                <div className={'scroll-body'}>
                    <SignUpOk />
                </div>
            </Modal>
        </Catcher>
    );
});
