import { CountriesStore } from '../stores/countries';
import { setUiTotal } from './ui';
import { getZonesSelect } from './zones';
import { IBodyListResponse, apiManager } from '../REST';
import { ICountriesListItemAPI, IItemAPI, IParamsList, IRelationshipItem } from '../interfaces';
import { mapApi2UiField, sortByName } from '../instruments';

export function setCountriesSelect(data: IItemAPI[]): void {
    const CountriesStoreInstance = CountriesStore.getInstance();
    CountriesStoreInstance.setCountriesSelect(
        data
            .map((i) => {
                return { id: i.id, name: i.attributes?.name || i.id, mcc: i.attributes?.mcc };
            })
            .sort(sortByName),
    );
}

export function setCountriesWithZoneList(data: IItemAPI[]): void {
    const CountriesStoreInstance = CountriesStore.getInstance();
    CountriesStoreInstance.setCountriesWithZoneList(
        data
            .map((i) => {
                return {
                    id: i.id,
                    name: i.attributes.name,
                    zoneId: (i.relationships?.zone?.data as IRelationshipItem)?.id,
                };
            })
            .sort(sortByName),
    );
}

export function setCountriesList(resp: IBodyListResponse): void {
    const { data, included } = resp;
    if (data) {
        const CountriesStoreInstance = CountriesStore.getInstance();
        CountriesStoreInstance.setCountriesList(
            (data as ICountriesListItemAPI[]).map((c: ICountriesListItemAPI) => {
                const foundZone = included?.find((z) => z.id === c.relationships?.zone?.data?.id);
                return {
                    id: c.id,
                    ...mapApi2UiField(c.attributes || {}),
                    zoneId: c.relationships?.zone?.data?.id,
                    zone: foundZone?.attributes?.name,
                };
            }),
        );
    }
}

export function setCountriesForcedUpdateTime(): void {
    const CountriesStoreInstance = CountriesStore.getInstance();
    CountriesStoreInstance.setForcedUpdateTime();
}

export function getCountriesRelationSelect(): void {
    getZonesSelect();
}

export async function getCountriesList(params: IParamsList): Promise<void> {
    const { resp } = await apiManager.getApi('GET_COUNTRIES_LIST', { searchParamsList: params });
    setCountriesList(resp as IBodyListResponse);
    const { meta } = resp;
    meta && setUiTotal(meta.records_count);
}

export async function getCountriesWithZoneList(params: IParamsList = {}): Promise<void> {
    const { resp } = await apiManager.getApi('GET_COUNTRIES_SELECT', { searchParamsList: params });
    const { data } = resp as IBodyListResponse;
    data && setCountriesWithZoneList(data);
}

export async function getCountriesSelect(params: IParamsList = {}): Promise<void> {
    const { resp } = await apiManager.getApi('GET_COUNTRIES_SELECT', { searchParamsList: params });
    const { data } = resp as IBodyListResponse;
    data && setCountriesSelect(data);
}

export async function deleteCountry(id: string): Promise<void> {
    const { isError } = await apiManager.getApi('DELETE_COUNTRY', { id });
    if (!isError) {
        setCountriesForcedUpdateTime();
    }
}

export function cleanCountriesStore(): void {
    const CountriesStoreInstance = CountriesStore.getInstance();
    CountriesStoreInstance.cleanStore();
}
