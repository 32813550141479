import React, { ReactNode } from 'react';
import { stringMoney } from '../../instruments';
import { Catcher } from '../Catcher';

export interface IShowMoneyItem {
    value?: string;
    symbol?: string;
}
interface IShowMoneyList {
    items?: Array<IShowMoneyItem>;
    classNameItem?: string;
}
export const ShowMoneyList: React.FC<IShowMoneyList> = ({ items, classNameItem }: IShowMoneyList) => {
    return (
        <Catcher>
            {items?.map((r: IShowMoneyItem, i: number) => {
                return (
                    <div key={i} className={classNameItem}>
                        {stringMoney(r.value, r.symbol)}
                    </div>
                );
            })}
        </Catcher>
    );
};

export const showMoneyList = (items: Array<IShowMoneyItem>): ReactNode => {
    return <ShowMoneyList items={items} />;
};
