import React from 'react';
import { observer } from 'mobx-react';
import { Catcher, ListWrapper } from '../';
import { useBalanceLogFilterParams } from '../../hooks';
import { cleanBalanceLogStore, getBalanceLogRelationSelect } from '../../middleware';
import { BalanceLogTableList } from './Components/BalanceLogTableList';

export const BalanceLogList: React.FC = observer(() => {
    const filterParams = useBalanceLogFilterParams();

    return (
        <Catcher>
            <ListWrapper
                cleanStore={cleanBalanceLogStore}
                filterParams={filterParams}
                getRelationSelect={getBalanceLogRelationSelect}
            >
                <BalanceLogTableList />
            </ListWrapper>
        </Catcher>
    );
});
