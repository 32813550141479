import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { Form, Input, Select, Table } from 'antd';
import { Rule } from 'antd/es/form';
import { FormListFieldData } from 'antd/es/form/FormList';
import cx from 'classnames';
import { ICountryWithZone, IFormValidatorResponse, IRuleAny, ITempTableDataSourceItem } from '../../../interfaces';
import { useCountriesWithZoneListByZoneId, usePurchaseInfo, usePurchaseListByZone } from '../../../hooks';
import { apiMessagesGetListError, makeZoneName, removePurchaseItem } from '../../../middleware';
import { isNewId } from '../../../instruments';
import { tF } from '../../../translate';
import {
    Catcher,
    FormSelectLight,
    FormSelectNetworksByCountry,
    InputNumberDebounce,
    RemoveButton,
} from '../../../Components';
import Styles from '../../styles/m_itemCardStyles.less';
import ViewStyles from '../../styles/m_viewStyles.less';

interface ICountry {
    zoneId: string;
    updateForm?: () => void;
}

export const EditPurchaseZoneRate: React.FC<ICountry> = observer(({ zoneId, updateForm }: ICountry) => {
    const { Option } = Select;
    const list = usePurchaseListByZone(zoneId);
    const { currency } = usePurchaseInfo();
    const recordIds = list.map((i) => i.id);
    const countrySelect = useCountriesWithZoneListByZoneId(zoneId);
    const countrySelectJSX = useMemo(() => {
        return countrySelect.map(
            (s: ICountryWithZone): JSX.Element => (
                <Option key={s.id} value={s.id}>
                    <div>{s.name}</div>
                </Option>
            ),
        );
    }, [zoneId]);

    const removeItem = (id: string): void => {
        updateForm && updateForm();
        removePurchaseItem(id);
    };

    const listName = makeZoneName(zoneId);

    const apiMessagesListRules: Array<Rule> = [
        {
            validator(rule: IRuleAny): IFormValidatorResponse {
                return apiMessagesGetListError({
                    listName: 'purchaseRateListEntry',
                    idsList: recordIds,
                    fieldName: rule.field,
                });
            },
        },
    ];

    const tableColumns = [
        {
            title: tF('Country'),
            dataIndex: 'country',
        },
        {
            title: tF('Operator'),
            dataIndex: 'network',
        },
        {
            title: `${tF('Data')} (p/MB)`,
            dataIndex: 'rateData',
        },
        {
            title: tF('SMS'),
            dataIndex: 'rateSms',
        },
        {
            title: `${tF('INC')} (KB)`,
            dataIndex: 'incrementData',
        },
        {
            title: `${tF('INC')} ${tF('Voice Mobile')}`,
            dataIndex: 'incrementVoiceMobile',
        },
        {
            title: tF('Unit - Minute to Mobile'),
            dataIndex: 'rateVoiceMobile',
        },
        {
            title: `${tF('INC')} ${tF('Voice Net')}`,
            dataIndex: 'incrementVoiceNet',
        },
        {
            title: tF('Unit - Minute to Net'),
            dataIndex: 'rateVoiceNet',
        },

        {
            title: '',
            dataIndex: 'toolBar',
            className: cx(ViewStyles.columnToolbarDelete, ViewStyles.columnShortToolbarDelete),
        },
    ];

    const makeTableDataSource = (fields: FormListFieldData[]): Array<ITempTableDataSourceItem> => {
        return fields.map((field: FormListFieldData) => {
            const currentRecord = list?.[field.key];
            const currentRecordId = currentRecord?.id;
            if (!currentRecordId) {
                return {};
            }

            const isNew = isNewId(currentRecordId);

            return {
                ...field,

                country: (
                    <div className={Styles.tableGroupSelect}>
                        {isNew ? (
                            <FormSelectLight
                                fieldName={[field.name, 'countryId']}
                                isGetPopupContainerEnabled={false}
                                isShortForm
                                onChange={updateForm}
                                rules={apiMessagesListRules}
                                selectJSX={countrySelectJSX}
                            />
                        ) : (
                            <Form.Item name={[field.name, 'country']}>
                                <Input disabled />
                            </Form.Item>
                        )}
                    </div>
                ),
                network: (
                    <div className={Styles.tableGroupSelect}>
                        {isNew ? (
                            <FormSelectNetworksByCountry
                                countryId={currentRecord?.countryId}
                                currentNetworkId={currentRecord?.networkId}
                                fieldName={[field.name, 'networkId']}
                                isGetPopupContainerEnabled={false}
                                rules={apiMessagesListRules}
                            />
                        ) : (
                            <Form.Item name={[field.name, 'network']}>
                                <Input disabled />
                            </Form.Item>
                        )}
                    </div>
                ),
                rateData: (
                    <div className={Styles.tableGroupVal}>
                        <Form.Item
                            name={[field.name, 'rateData']}
                            rules={apiMessagesListRules}
                            className={Styles.rateValue}
                        >
                            <InputNumberDebounce step={0.1} min={0} />
                        </Form.Item>
                        <div className={Styles.showCurrency}>{currency}</div>
                    </div>
                ),
                rateSms: (
                    <div className={Styles.tableGroupVal}>
                        <Form.Item
                            name={[field.name, 'rateSms']}
                            rules={apiMessagesListRules}
                            className={Styles.rateValue}
                        >
                            <InputNumberDebounce step={0.1} min={0} />
                        </Form.Item>
                        <div className={Styles.showCurrency}>{currency}</div>
                    </div>
                ),
                incrementData: (
                    <div className={Styles.tableGroupVal}>
                        <Form.Item
                            name={[field.name, 'incrementData']}
                            rules={apiMessagesListRules}
                            className={Styles.rateValue}
                        >
                            <InputNumberDebounce />
                        </Form.Item>
                    </div>
                ),
                incrementVoiceMobile: (
                    <div className={Styles.tableGroupVal}>
                        <Form.Item
                            name={[field.name, 'incrementVoiceMobile']}
                            rules={apiMessagesListRules}
                            className={Styles.rateValue}
                        >
                            <InputNumberDebounce />
                        </Form.Item>
                    </div>
                ),
                rateVoiceMobile: (
                    <div className={Styles.tableGroupVal}>
                        <Form.Item
                            name={[field.name, 'rateVoiceMobile']}
                            rules={apiMessagesListRules}
                            className={Styles.rateValue}
                        >
                            <InputNumberDebounce />
                        </Form.Item>
                    </div>
                ),
                incrementVoiceNet: (
                    <div className={Styles.tableGroupVal}>
                        <Form.Item
                            name={[field.name, 'incrementVoiceNet']}
                            rules={apiMessagesListRules}
                            className={Styles.rateValue}
                        >
                            <InputNumberDebounce />
                        </Form.Item>
                    </div>
                ),
                rateVoiceNet: (
                    <div className={Styles.tableGroupVal}>
                        <Form.Item
                            name={[field.name, 'rateVoiceNet']}
                            rules={apiMessagesListRules}
                            className={Styles.rateValue}
                        >
                            <InputNumberDebounce />
                        </Form.Item>
                    </div>
                ),
                toolBar: (
                    <div className={Styles.removeSection}>
                        <RemoveButton onClick={(): void => removeItem(currentRecordId)} isHiddenTitle isGhost />
                    </div>
                ),
            };
        });
    };

    return (
        <Catcher>
            <Form.List name={listName}>
                {(fields: FormListFieldData[], {}): JSX.Element => {
                    return (
                        <Table
                            className={'table-theme sub-table'}
                            columns={tableColumns}
                            dataSource={makeTableDataSource(fields)}
                            pagination={false}
                            size={'small'}
                        />
                    );
                }}
            </Form.List>
        </Catcher>
    );
});
