import React from 'react';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import { UnorderedListOutlined } from '@ant-design/icons';
import { CardLayout, Catcher, FieldInfo, cardLayoutOptions } from '../../index';
import cx from 'classnames';
import { IExternalAPILog } from '../../../interfaces';
import { useCollapseIcon, useExternalAPILogs } from '../../../hooks';
import { clearExternalAPILogs } from '../../../middleware';
import { ITrField, ITrPage } from '../../../translate';
import Styles from '../../styles/m_itemCardStyles.less';

type ILogItemProps = {
    log: IExternalAPILog;
    children?: never;
};
const LogItem: React.FC<ILogItemProps> = ({ log }: ILogItemProps) => {
    const { isVisible, collapseIcon } = useCollapseIcon();

    return (
        <div className={Styles.collapsedItem}>
            <div className={Styles.collapsedItemTitle}>
                {collapseIcon}
                <FieldInfo className={Styles.collapsedItemTitleField} isRevertBold title={'Date'}>
                    {log.date ? log.date.toISOString() : '???'}
                </FieldInfo>
                <FieldInfo className={Styles.collapsedItemTitleField} isRevertBold title={'Command'}>
                    {log.command}
                </FieldInfo>
                <FieldInfo className={Styles.collapsedItemTitleField} isRevertBold title={'Status'}>
                    {log.rawResponse.status}
                </FieldInfo>
                {log.ocsResponseError?.errorCode ? (
                    <FieldInfo
                        className={cx(Styles.collapsedItemTitleField, Styles.dangerText)}
                        isRevertBold
                        title={'Error Code' as ITrField}
                    >
                        {log.ocsResponseError.errorCode}
                    </FieldInfo>
                ) : null}
            </div>

            {isVisible ? (
                <div className={Styles.subFieldsPlace}>
                    {log.ocsResponseError?.errorCode ? (
                        <FieldInfo isRevertBold title={'External Error Code' as ITrField}>
                            <span className={Styles.dangerText}>{log.ocsResponseError?.errorCode}</span>
                        </FieldInfo>
                    ) : null}
                    {log.ocsResponseError?.errorDescription ? (
                        <FieldInfo isRevertBold title={'External Error Description' as ITrField}>
                            <span className={Styles.dangerText}>{log.ocsResponseError?.errorDescription}</span>
                        </FieldInfo>
                    ) : null}
                    <FieldInfo isRevertBold title={'External Curl' as ITrField}>
                        {log.rawResponse.requestCurl}
                    </FieldInfo>
                    <FieldInfo isRevertBold title={'External Status' as ITrField}>
                        {log.rawResponse.status}
                    </FieldInfo>
                    <FieldInfo isRevertBold title={'External headers' as ITrField}>
                        {JSON.stringify(log.rawResponse.headers)}
                    </FieldInfo>
                    <FieldInfo isRevertBold title={'External body' as ITrField}>
                        {log.rawResponse?.body}
                    </FieldInfo>
                    <FieldInfo isRevertBold title={'OCS Request' as ITrField}>
                        {JSON.stringify(log.ocsRequest, null, '\t')}
                    </FieldInfo>
                    <FieldInfo isRevertBold title={'OCS Response' as ITrField}>
                        {JSON.stringify(log.ocsResponse, null, '\t')}
                    </FieldInfo>
                </div>
            ) : null}
        </div>
    );
};
export const ExternalAPILogs: React.FC = observer(() => {
    const logs = useExternalAPILogs();

    return (
        <Catcher>
            <CardLayout
                title={'Logs' as ITrPage}
                columnOption={cardLayoutOptions.cardTableFull}
                icon={<UnorderedListOutlined />}
                OtherButton={logs.length ? <Button onClick={() => clearExternalAPILogs()}>Clear</Button> : null}
                isTable
            >
                {logs.map((log: IExternalAPILog) => (
                    <LogItem key={`${log.date}-${log.command}`} log={log} />
                ))}
            </CardLayout>
        </Catcher>
    );
});
