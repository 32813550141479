import { action, makeObservable, observable, reaction } from 'mobx';
import { UiStore } from './ui';
import { appConfig } from '../settings';
import { IDiameterSessionsListItem, IFilterParams, ISelectList } from '../interfaces';
import { getDiameterSessionsList } from '../middleware';
import { apiParamsCleaner } from '../instruments';

export class DiameterSessionsStore {
    private constructor() {
        makeObservable(this);
        reaction(
            () => this.forcedUpdateTime,
            () => {
                const UiStoreInstance = UiStore.getInstance();
                const params = UiStoreInstance.params;
                const cleanParams = apiParamsCleaner(params, this.filterParams);
                getDiameterSessionsList(cleanParams).then();
            },
            {
                delay: appConfig.APIDelay,
            },
        );
    }

    private static instance: DiameterSessionsStore;
    public static getInstance(): DiameterSessionsStore {
        if (!DiameterSessionsStore.instance) {
            DiameterSessionsStore.instance = new DiameterSessionsStore();
        }
        return DiameterSessionsStore.instance;
    }

    @observable list: IDiameterSessionsListItem[] = [];
    @action setList(list: IDiameterSessionsListItem[]): void {
        this.list = list;
    }

    @observable sessionsSelect: ISelectList = [];
    @action setSessionsSelect(list: ISelectList): void {
        this.sessionsSelect = list;
    }

    @action cleanStore(): void {
        this.list = [];
        this.sessionsSelect = [];
    }

    @observable forcedUpdateTime = 0;
    @action setForcedUpdateTime(): void {
        this.forcedUpdateTime = Date.now();
    }

    @observable filterParams: IFilterParams = {
        pageNumber: true,
        pageSize: true,
        search: true,
        sessionId: true,
    };
}
