import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { appConfig, isCompanyTypeIdMNOHost } from '../../settings';
import { ICatalogsUnitItem, ISelect, ISelectList } from '../../interfaces';

export const useUnitList = (): ICatalogsUnitItem[] => {
    return useContext(MobXProviderContext).RootStore.catalogsStore.unitList;
};

export function useCatalogCompanyTypeSelect(): ISelectList {
    return useContext(MobXProviderContext).RootStore.catalogsStore.companyTypes;
}
export function useCatalogCompanyTypeFilterNotMNOSelect(): ISelectList {
    return useContext(MobXProviderContext).RootStore.catalogsStore.companyTypes.filter(
        (i: ISelect) => !isCompanyTypeIdMNOHost(i.id),
    );
}

export function useCatalogPaymentTypesSelect(): ISelectList {
    return useContext(MobXProviderContext).RootStore.catalogsStore.paymentTypes;
}
export function useCatalogProductTypesSelect(): ISelectList {
    return useContext(MobXProviderContext).RootStore.catalogsStore.productTypes;
}

export function useCatalogSessionGrantedTypesSelect(currency = 'currency'): ISelectList {
    return appConfig.sessionGrantedTypes.map((i: ISelect) => {
        if (i.name === 'currency') {
            return { ...i, name: currency };
        } else {
            return i;
        }
    });
}
