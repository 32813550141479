import React from 'react';
import { Divider } from 'antd';
import { IDeviceAttributeBandDetail, IDeviceInfo } from '../../../interfaces';
import { dateText2Utc } from '../../../instruments';
import { FieldInfo } from '../../Forms';
import Styles from '../../styles/m_itemCardStyles.less';
import StylesBandDetails from './m_bandDetails.less';

type IBandDetails = {
    bandDetails: IDeviceAttributeBandDetail[];
};

export const BandDetails: React.FC<IBandDetails> = ({ bandDetails }: IBandDetails): JSX.Element => {
    const bandDetailsList = bandDetails.map((bandDetailsItem: IDeviceAttributeBandDetail, bandDetailsIndex) => {
        const bandSubDetailsList = bandDetailsItem.bandInfo?.map((bandDetailsSubItem, bandDetailsSubIndex) => {
            const bandSubDetailsInfoList = bandDetailsSubItem.subBandInfo?.map(
                (bandDetailsSubItemInfo, bandDetailsSubItemInfoIndex) => {
                    const bandSubDetailsInfoNetworkPerformanceList = bandDetailsSubItemInfo.networkPerformance?.map(
                        (networkPerformance, networkPerformanceIndex) => {
                            return (
                                <div key={networkPerformanceIndex} className={StylesBandDetails.bandDetails}>
                                    <div className={StylesBandDetails.field}>
                                        <div className={StylesBandDetails.name}>{networkPerformance.configuration}</div>
                                        <div className={StylesBandDetails.value}>{networkPerformance.value}</div>
                                    </div>
                                </div>
                            );
                        },
                    );

                    return (
                        <div key={bandDetailsSubItemInfoIndex} className={StylesBandDetails.bandDetails}>
                            {bandDetailsSubItemInfo.subBand?.length ? (
                                <div className={StylesBandDetails.field}>
                                    <div className={StylesBandDetails.name}>subBand</div>
                                    <div className={StylesBandDetails.value}>
                                        {bandDetailsSubItemInfo.subBand?.join(', ')}
                                    </div>
                                </div>
                            ) : null}
                            {bandDetailsSubItemInfo.networkPerformance?.length ? (
                                <>
                                    <div className={StylesBandDetails.field}>
                                        <div className={StylesBandDetails.name}>networkPerformance</div>
                                    </div>
                                    {bandSubDetailsInfoNetworkPerformanceList}
                                </>
                            ) : null}
                        </div>
                    );
                },
            );
            return (
                <div key={bandDetailsSubIndex} className={StylesBandDetails.bandDetails}>
                    <div className={StylesBandDetails.field}>
                        <div className={StylesBandDetails.name}>bandName</div>
                        <div className={StylesBandDetails.value}>{bandDetailsSubItem.bandName}</div>
                    </div>
                    {bandSubDetailsInfoList}
                </div>
            );
        });

        return (
            <div key={bandDetailsIndex} className={StylesBandDetails.bandDetails}>
                <div className={StylesBandDetails.field}>
                    <div className={StylesBandDetails.name}>categoryName</div>
                    <div className={StylesBandDetails.value}>{bandDetailsItem.categoryName}</div>
                </div>
                {bandSubDetailsList}
            </div>
        );
    });

    return (
        <div className={StylesBandDetails.bandDetailsContainer}>
            <div className={StylesBandDetails.title}>Band Details</div>
            {bandDetailsList}
        </div>
    );
};

type IDevicePopover = {
    info: IDeviceInfo;
};
export const DevicePopover: React.FC<IDevicePopover> = ({ info }: IDevicePopover): JSX.Element => {
    return (
        <div className={Styles.cardDevicePopoverWrapper}>
            {info.imei ? <FieldInfo title={'IMEI'}>{info.imei}</FieldInfo> : null}
            {info.imei ? <Divider /> : null}
            {info.name ? <FieldInfo title={'Name'}>{info.name}</FieldInfo> : null}
            {info.deviceModelName ? <FieldInfo title={'Device model name'}>{info.deviceModelName}</FieldInfo> : null}
            {info.manufacturer ? <FieldInfo title={'Device manufacturer'}>{info.manufacturer}</FieldInfo> : null}
            {info.marketingName ? <FieldInfo title={'Marketing name'}>{info.marketingName}</FieldInfo> : null}
            {info.brandName ? <FieldInfo title={'Brand name'}>{info.brandName}</FieldInfo> : null}
            {info.organisationId ? <FieldInfo title={'Organisation ID'}>{info.organisationId}</FieldInfo> : null}
            {info.deviceType ? <FieldInfo title={'Device type'}>{info.deviceType}</FieldInfo> : null}
            {info.dateName ? <FieldInfo title={'Date name'}>{info.dateName}</FieldInfo> : null}
            {info.date ? <FieldInfo title={'Date'}>{dateText2Utc(info.date)}</FieldInfo> : null}
            {info.tac || info.allocationDate ? <Divider /> : null}
            {info.tac ? <FieldInfo title={'TAC'}>{info.tac}</FieldInfo> : null}
            {info.allocationDate ? (
                <FieldInfo title={'TAC allocation date'}>{dateText2Utc(info.allocationDate)}</FieldInfo>
            ) : null}
            {info.bluetooth || info.nfc || info.wlan ? <Divider /> : null}
            {info.bluetooth ? <FieldInfo title={'Bluetooth'}>{info.bluetooth}</FieldInfo> : null}
            {info.nfc ? <FieldInfo title={'NFC'}>{info.nfc}</FieldInfo> : null}
            {info.wlan ? <FieldInfo title={'WLAN'}>{info.wlan}</FieldInfo> : null}
            {info.removableUicc ||
            info.removableEuicc ||
            info.nonRemovableUicc ||
            info.nonRemovableEuicc ||
            info.simSlot ? (
                <Divider />
            ) : null}
            {info.removableUicc ? <FieldInfo title={'Removable UICC'}>{info.removableUicc}</FieldInfo> : null}
            {info.removableEuicc ? <FieldInfo title={'Removable EUICC'}>{info.removableEuicc}</FieldInfo> : null}
            {info.nonRemovableUicc ? <FieldInfo title={'Non removable UICC'}>{info.nonRemovableUicc}</FieldInfo> : null}
            {info.nonRemovableEuicc ? (
                <FieldInfo title={'Non removable EUICC'}>{info.nonRemovableEuicc}</FieldInfo>
            ) : null}
            {info.simSlot ? <FieldInfo title={'SIM Slot'}>{info.simSlot}</FieldInfo> : null}
            {info.operatingSystem || info.oem ? <Divider /> : null}
            {info.operatingSystem ? <FieldInfo title={'Operating System'}>{info.operatingSystem}</FieldInfo> : null}
            {info.oem ? <FieldInfo title={'OEM'}>{info.oem}</FieldInfo> : null}
            {info.bandDetails ? <Divider /> : null}
            {info.bandDetails ? <BandDetails bandDetails={info.bandDetails} /> : null}
        </div>
    );
};
