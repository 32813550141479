import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { IFilterParams, IPackagesUnitsLogsItem } from '../../interfaces';

export function usePackagesUnitsLogsTable(): IPackagesUnitsLogsItem[] {
    return useContext(MobXProviderContext).RootStore.packagesUnitsLogsStore.list;
}

export function usePackagesUnitsLogsFilterParams(): IFilterParams {
    return useContext(MobXProviderContext).RootStore.packagesUnitsLogsStore.filterParams;
}
