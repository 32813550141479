import React from 'react';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { IGetApiType } from '../../../REST';
import { useUiIsSpinnerFound } from '../../../hooks';
import { tB } from '../../../translate';

interface IReloadButton {
    className?: string;
    idSpinners?: IGetApiType[];
    isLoading?: boolean;
    onChange?: () => void;
}

export const ReloadButton: React.FC<IReloadButton> = observer(
    ({ className, idSpinners, isLoading, onChange }: IReloadButton) => {
        const isLocalLoading = isLoading || (!!idSpinners?.length && useUiIsSpinnerFound(idSpinners));
        return (
            <Button
                onClick={onChange}
                icon={<ReloadOutlined />}
                className={className}
                disabled={isLocalLoading || !onChange}
            >
                {tB('Reload')}
            </Button>
        );
    },
);
