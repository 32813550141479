import React from 'react';
import { observer } from 'mobx-react';
import { Form } from 'antd';
import { CardInfoToolBar, CardMainLayout, Catcher, FieldAlert, InputDebounce } from '../../';
import { IEditFormComponentInfo } from '../../../interfaces';
import { useOperatorInfo } from '../../../hooks';
import {
    apiMessagesDeleteMessageByFieldName,
    apiMessagesGetFormRules,
    apiMessagesGetFormRulesAndLen,
} from '../../../middleware';
import { tF } from '../../../translate';

export const EditOperatorInfo: React.FC<IEditFormComponentInfo> = observer(
    ({ save, isSending }: IEditFormComponentInfo) => {
        const { id, name } = useOperatorInfo();
        const deleteCountryIdError = (): void => {
            apiMessagesDeleteMessageByFieldName(['countryId']);
        };
        const nameTitle = tF('Name');
        const mccTitle = tF('MCC');
        const mncTitle = tF('MNC');
        const tadigTitle = tF('Tadig');

        return (
            <Catcher>
                <CardMainLayout idSpinners={['GET_OPERATOR']} isEdit name={name}>
                    <Form.Item name="name" label={nameTitle} rules={apiMessagesGetFormRules}>
                        <InputDebounce placeholder={nameTitle} isFirstInput />
                    </Form.Item>
                    <Form.Item name="mcc" label={mccTitle} rules={apiMessagesGetFormRulesAndLen(3)}>
                        <InputDebounce placeholder={mccTitle} onChange={deleteCountryIdError} />
                    </Form.Item>
                    <FieldAlert fieldName={'countryId'} isFieldNameShown={false} />
                    <Form.Item name="mnc" label={mncTitle} rules={apiMessagesGetFormRulesAndLen(2)}>
                        <InputDebounce placeholder={mncTitle} />
                    </Form.Item>
                    <Form.Item name="tadig" label={tadigTitle} rules={apiMessagesGetFormRulesAndLen(5)}>
                        <InputDebounce placeholder={tadigTitle} />
                    </Form.Item>
                    <CardInfoToolBar dataTest="EditOperatorInfo" onSave={save} isLoading={isSending} isNew={!id} />
                </CardMainLayout>
            </Catcher>
        );
    },
);
