import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { Col, Popover } from 'antd';
import { Catcher, LoadingApiByIds } from '../../';
import { columnOption } from '../columnOption';
import cx from 'classnames';
import { IStatItem } from '../../../interfaces';
import { useDashboardFinancesRollStats, useProfile } from '../../../hooks';
import { getCurrencyCodeById } from '../../../instruments';
import { tF, tP } from '../../../translate';
import CardStyles from '../../styles/m_itemCardStyles.less';
import DashboardStyles from '../m_dashboarStyles.less';

type IShowValue = {
    currencyAbbreviation?: string;
    currencyId?: number;
    currencySymbol?: string;
    value?: string;
};

export const FinancesRevenuesIsBigQueryCosts: React.FC = observer(() => {
    const { isCompanyTypeIdMNOHost } = useProfile();
    const stats = useDashboardFinancesRollStats();

    const renderItem = (data: Array<IShowValue>, title: string): ReactElement => {
        if (!data?.length) {
            return (
                <>
                    <div className={DashboardStyles.title}>{title}</div>
                    <div className={DashboardStyles.value}>{`0,00`}</div>
                </>
            );
        }

        return (
            <Catcher>
                <div className={DashboardStyles.title}>{title}</div>
                {data?.map((i: IShowValue, index: number) => {
                    const currencyCode = i.currencyId ? getCurrencyCodeById(i.currencyId) : '';
                    const currencySymbol = i.currencySymbol ? i.currencySymbol : currencyCode;
                    const currencyValue = `${currencySymbol} ${(i && i.value && +i.value >= 0 ? +i.value : 0).toFixed(
                        2,
                    )}`;
                    const contextPopover = (
                        <div className={cx(CardStyles.cardDevicePopoverWrapper, CardStyles.cardFileInfoPopoverWrapper)}>
                            {currencyValue}
                        </div>
                    );
                    return (
                        <Popover key={index} placement="top" title={currencyCode} content={contextPopover}>
                            <div className={DashboardStyles.value}>{currencyValue}</div>
                        </Popover>
                    );
                })}
            </Catcher>
        );
    };

    const columnSize = isCompanyTypeIdMNOHost ? columnOption.financesInfoCard : columnOption.financesRevenuesCosts;
    const revenues: Array<IShowValue> = stats.map(
        (i: IStatItem): IShowValue => ({
            currencyAbbreviation: i.currencyAbbreviation,
            currencyId: i.currencyId,
            currencySymbol: i.currencySymbol,
            value: i.revenue,
        }),
    );
    const costs: Array<IShowValue> = stats.map(
        (i: IStatItem): IShowValue => ({
            currencyAbbreviation: i.currencyAbbreviation,
            currencyId: i.currencyId,
            currencySymbol: i.currencySymbol,
            value: i.cost,
        }),
    );

    return (
        <Catcher>
            <Col className={DashboardStyles.cardPlace} {...columnSize}>
                <div className={cx(DashboardStyles.infoCard, DashboardStyles.financesCard)}>
                    <LoadingApiByIds idSpinners={['GET_FINANCE_ROLL']} />
                    <div className={cx(DashboardStyles.info, DashboardStyles.infoRight)}>
                        {renderItem(revenues, tP('Revenue'))}
                    </div>
                    {!isCompanyTypeIdMNOHost ? (
                        <div className={cx(DashboardStyles.info, DashboardStyles.infoLeft)}>
                            {renderItem(costs, tF('Cost'))}
                        </div>
                    ) : null}
                </div>
            </Col>
        </Catcher>
    );
});
