import React, { useState } from 'react';
import { Marker, MarkerProps, OverlayView } from '@react-google-maps/api';
import { useProfileIsRight } from '../../../hooks';

interface IMarkerWithLabel extends MarkerProps {
    children?: JSX.Element | JSX.Element[];
}

export const MarkerWithInfoBox: React.FC<IMarkerWithLabel> = ({
    position,
    children,
    label,
    ...props
}: IMarkerWithLabel) => {
    const [isInfoBoxShow, setInfoBoxShown] = useState(false);
    const isLord = useProfileIsRight('LORD');

    return (
        <Marker
            onMouseOver={(): void => setInfoBoxShown(true)}
            onMouseOut={(): void => setInfoBoxShown(false)}
            position={position}
            {...props}
        >
            {isInfoBoxShow ? (
                <OverlayView position={position} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
                    {children}
                </OverlayView>
            ) : null}
            {label && isLord ? (
                <OverlayView position={position} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
                    {typeof label === 'string' ? (
                        label
                    ) : (
                        <div className={label.className} style={{ fontSize: label.fontSize }}>
                            <span>{label.text}</span>
                        </div>
                    )}
                </OverlayView>
            ) : null}
        </Marker>
    );
};
