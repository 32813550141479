import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Catcher } from '../';
import { IGetApiType } from '../../REST';
import { IShowFormComponent } from '../../interfaces';
import { useRouterStore } from '../../hooks';
import { abortSelectedFetch, addNotification, cleanCurrencyStore, getCurrencyById } from '../../middleware';
import { tI, tNM } from '../../translate';
import { EditCurrency, ShowCurrency } from './Components';

export const Currency: React.FC<IShowFormComponent> = observer(({ isEdit }: IShowFormComponent) => {
    const routerStore = useRouterStore();
    const [id, setId] = useState('');
    const usedApiTypes: IGetApiType[] = ['GET_CURRENCY'];

    const goOut = (): void => {
        abortSelectedFetch(usedApiTypes);
        cleanCurrencyStore();
    };

    const goToList = (): void => {
        routerStore.goTo('CurrenciesList').then();
        goOut();
    };
    const _getCurrencyById = async (id: string): Promise<void> => {
        const isGetCurrency = await getCurrencyById(id);
        if (!isGetCurrency) {
            addNotification({
                type: 'error',
                message: tNM('Not Found'),
                description: `${tI('Currency id')} - "${id}" ${tI('not found')}`,
                duration: 5,
                isTranslated: true,
            });
            goToList();
        }
    };

    useEffect(() => {
        return (): void => {
            goOut();
        };
    }, []);

    useEffect(() => {
        if (id) {
            _getCurrencyById(id);
        }
    }, [id]);

    useEffect(() => {
        if (routerStore.routerState.params.id) {
            setId(routerStore.routerState.params.id);
        }
    }, [routerStore.routerState.params.id]);

    const setEdit = (isEdit?: boolean): void => {
        if (isEdit) {
            routerStore.goTo('CurrencyEdit', { params: { id } });
        } else {
            routerStore.goTo('Currency', { params: { id } });
            _getCurrencyById(id);
        }
    };

    return (
        <Catcher>{isEdit ? <EditCurrency setEditMode={setEdit} /> : <ShowCurrency setEditMode={setEdit} />}</Catcher>
    );
});
