import React, { ReactNode } from 'react';
import { Button, Popover } from 'antd';
import { CopyOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Catcher, LightSearch } from '../index';
import cx from 'classnames';
import { addNotification } from '../../middleware';
import { tB, tNM } from '../../translate';
import Styles from '../styles/m_itemCardStyles.less';

type IShowTransactionErrorInfo = {
    isError?: boolean;
    isLight?: boolean;
    transactionInfo?: any;
};

export const ShowRawDataInfo: React.FC<IShowTransactionErrorInfo> = ({
    isError,
    isLight,
    transactionInfo,
}: IShowTransactionErrorInfo) => {
    if (!transactionInfo) {
        return null;
    }

    const onClickTransactionError = (): void => {
        navigator.clipboard.writeText(JSON.stringify(transactionInfo)).then(() => {
            addNotification({
                type: 'info',
                message: isError
                    ? 'The error is copied to the exchange buffer.'
                    : 'The data is copied to the exchange buffer.',
            });
        });
    };
    const transactionErrorPopoverTitle = (): ReactNode => {
        return (
            <div className={Styles.transactionErrorPopoverTitle}>
                <div className={cx(Styles.title, isError ? Styles.errorTitle : Styles.okTitle)}>
                    {tNM(isError ? 'Error!' : 'Data')}
                </div>
                <Button
                    className={Styles.button}
                    onClick={onClickTransactionError}
                    size={'small'}
                    type={'primary'}
                    icon={<CopyOutlined />}
                >
                    {tB('Copy Clipboard')}
                </Button>
            </div>
        );
    };
    const transactionErrorPopoverContent = (): ReactNode => {
        const data = JSON.stringify(transactionInfo, null, `\t`);
        return (
            <div className={Styles.transactionErrorPopoverContent}>
                {data.split(`\n`).map((str, ind) => (
                    <p key={ind}>{isLight ? <LightSearch text={str} /> : str}</p>
                ))}
            </div>
        );
    };

    return (
        <Catcher>
            <Popover
                placement="top"
                title={transactionErrorPopoverTitle}
                content={transactionErrorPopoverContent}
                trigger="hover"
            >
                <ExclamationCircleOutlined onClick={onClickTransactionError} className={Styles.errorIcon} />
            </Popover>
        </Catcher>
    );
};
