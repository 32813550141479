import { action, makeObservable, observable, reaction } from 'mobx';
import { UiStore } from './ui';
import { appConfig } from '../settings';
import { ICountriesList, ICountryWithZoneList, IFilterParams, ISelectListCountries } from '../interfaces';
import { getCountriesList } from '../middleware';
import { apiParamsCleaner } from '../instruments';

export class CountriesStore {
    private constructor() {
        makeObservable(this);
        reaction(
            () => this.forcedUpdateTime,
            () => {
                const UiStoreInstance = UiStore.getInstance();
                const params = UiStoreInstance.params;
                const cleanParams = apiParamsCleaner(params, this.filterParams);
                getCountriesList(cleanParams).then();
            },
            {
                delay: appConfig.APIDelay,
            },
        );
    }

    private static instance: CountriesStore;
    public static getInstance(): CountriesStore {
        if (!CountriesStore.instance) {
            CountriesStore.instance = new CountriesStore();
        }
        return CountriesStore.instance;
    }

    @observable countriesWithZoneList: ICountryWithZoneList = [];
    @action setCountriesWithZoneList(list: ICountryWithZoneList): void {
        this.countriesWithZoneList = list;
    }

    @observable select: ISelectListCountries = [];
    @action setCountriesSelect(select: ISelectListCountries): void {
        this.select = select;
    }

    @observable list: ICountriesList = [];
    @action setCountriesList(list: ICountriesList): void {
        this.list = list;
    }

    @observable forcedUpdateTime = 0;
    @action setForcedUpdateTime(): void {
        this.forcedUpdateTime = Date.now();
    }

    @observable filterParams: IFilterParams = {
        pageNumber: true,
        pageSize: true,
        search: true,
        zoneId: true,
    };

    @action cleanStore(): void {
        this.select = [];
        this.countriesWithZoneList = [];
        this.list = [];
    }
}
