import { IIntervalTypeItem, appConfig } from '../settings';

type IIntervalAPI2Ui = {
    interval?: number;
    type?: string;
    name?: string;
    multiplier?: number;
};

export const intervalAPI2Ui = (sec?: number): IIntervalAPI2Ui => {
    if (!sec) {
        return {};
    }
    const sortSelect = appConfig.intervalCDRTypeSelect.sort(
        (a: IIntervalTypeItem, b: IIntervalTypeItem) => b.multiplier - a.multiplier,
    );
    const intervalType = (sortSelect.find((i: IIntervalTypeItem) => !(sec % i.multiplier)) ||
        sortSelect.find((i: IIntervalTypeItem) => i.isDefault)) as IIntervalTypeItem;
    const interval = Math.ceil(sec / intervalType.multiplier);
    return { ...intervalType, type: intervalType.type, interval, name: intervalType.name };
};

export const intervalUi2API = (interval?: number, type?: string): number | undefined => {
    if (typeof interval === 'undefined') {
        return interval;
    }
    const intervalType = (appConfig.intervalCDRTypeSelect.find((i: IIntervalTypeItem) => i.type === type) ||
        appConfig.intervalCDRTypeSelect.find((i: IIntervalTypeItem) => i.isDefault)) as IIntervalTypeItem;

    return interval * intervalType.multiplier;
};
