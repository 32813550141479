import React, { MouseEvent, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Form, Modal } from 'antd';
import {
    CheckCircleOutlined,
    CheckOutlined,
    CloseOutlined,
    ExclamationCircleOutlined,
    LogoutOutlined,
    UnlockOutlined,
} from '@ant-design/icons';
import { Catcher } from '../../';
import cx from 'classnames';
import { apiConfig } from '../../../REST';
import { IAcceptanceDocumentsItem } from '../../../interfaces';
import { useAuthAcceptanceDocumentsList, useAuthIsAcceptanceAllDocuments } from '../../../hooks';
import { authLogOutUser, sendAuthAcceptanceDocuments, setAuthAcceptDocumentsItem } from '../../../middleware';
import { tB, tT } from '../../../translate';
import '../../../customStyle/form.less';
import '../../../customStyle/modal.less';
import Styles from '../m_styles.less';
import '../styles.less';

export const TermsFormFields: React.FC = observer(() => {
    const refScrollDiv = useRef<HTMLDivElement>(null);
    const refIFameDiv = useRef<HTMLIFrameElement>(null);
    const [openDocumentIndex, setOpenDocumentIndex] = useState(-1);
    const [modalTitle, setModalTitle] = useState('');
    const [iFrameBody, setIFrameBody] = useState('');
    const [isOnTimeOut, setIsOnTimeOut] = useState({
        ['privacy_policy']: false,
        ['terms_and_conditions']: false,
    });
    const isAcceptanceAll = useAuthIsAcceptanceAllDocuments();
    const list = useAuthAcceptanceDocumentsList();
    const apiUrl = apiConfig.apiUrl();

    const closeDocument = (): void => {
        setOpenDocumentIndex(-1);
    };

    const setOpenDocument = (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>, index: number): void => {
        event.preventDefault();
        setIFrameBody('');
        setOpenDocumentIndex(index);
    };

    const listJSX = list.map((p: IAcceptanceDocumentsItem, index: number) => {
        return (
            <div key={p.id} className={Styles.policyItem}>
                {p.isAccept ? (
                    <CheckCircleOutlined className={cx(Styles.checkIcon, Styles.okIcon)} />
                ) : (
                    <ExclamationCircleOutlined className={cx(Styles.checkIcon, Styles.notIcon)} />
                )}
                <a
                    className={Styles.name}
                    href={'#'}
                    onClick={(event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>): void =>
                        setOpenDocument(event, index)
                    }
                >
                    {p.name}
                </a>
            </div>
        );
    });

    const onLoadDocument = (): void => {
        if (openDocumentIndex > -1) {
            setModalTitle((openDocumentIndex > -1 && list[openDocumentIndex]?.name) || '');
            const contentFileUrl = (openDocumentIndex > -1 && list[openDocumentIndex]?.contentFileUrl) || '';

            if (contentFileUrl.length) {
                setIFrameBody(`${apiUrl}${contentFileUrl}`);
            }
        }

        setTimeout(() => {
            setIsOnTimeOut((prevState) => {
                const domain = list[openDocumentIndex].domain || '';
                return {
                    ...prevState,
                    [domain]: true,
                };
            });
        }, 2000);
    };

    const currentDocument = list[openDocumentIndex]?.domain;
    const isAllowedAccept = isOnTimeOut[currentDocument || 'privacy_policy'];

    const setAcceptItem = (): void => {
        setAuthAcceptDocumentsItem(openDocumentIndex);
        closeDocument();
    };

    const onAccept = async (): Promise<void> => {
        sendAuthAcceptanceDocuments();
    };

    const logOut = (): void => {
        authLogOutUser();
    };
    const textDescription = tT('termsDescription');

    return (
        <Catcher>
            <div className={Styles.description}>{textDescription}</div>
            <div className={Styles.acceptPolicyPanel}>{listJSX}</div>
            <Form.Item>
                <Button
                    icon={<UnlockOutlined />}
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    block
                    disabled={!isAcceptanceAll}
                    onClick={onAccept}
                >
                    {tB('Continue')}
                </Button>
            </Form.Item>
            <Form.Item>
                <Button onClick={logOut} icon={<LogoutOutlined />} type="primary" danger block>
                    {tB('Cancel')}
                </Button>
            </Form.Item>
            <Modal
                key={openDocumentIndex}
                title={modalTitle}
                open={openDocumentIndex > -1}
                onCancel={closeDocument}
                footer={[
                    <Button
                        type="primary"
                        key={'Accept'}
                        onClick={setAcceptItem}
                        icon={<CheckOutlined />}
                        disabled={!isAllowedAccept}
                    >
                        {tB('Accept')}
                    </Button>,
                    <Button key={'Close'} onClick={closeDocument} icon={<CloseOutlined />}>
                        {tB('Close')}
                    </Button>,
                ]}
                wrapClassName={cx('custom-modal', 'terms-modal')}
                width={'60vw'}
            >
                <div className={'scroll-body'} ref={refScrollDiv}>
                    <iframe
                        src={iFrameBody}
                        className={Styles.termsIframe}
                        height={`100%`}
                        onLoad={onLoadDocument}
                        ref={refIFameDiv}
                    />
                </div>
            </Modal>
        </Catcher>
    );
});
