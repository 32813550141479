import React from 'react';
import { observer } from 'mobx-react';
import { Catcher, ListWrapper } from '../';
import { useCountriesFilterParams } from '../../hooks';
import { cleanCountriesStore, getCountriesRelationSelect } from '../../middleware';
import { CountriesTableList } from './Components/';

export const CountriesList: React.FC = observer(() => {
    const filterParams = useCountriesFilterParams();
    return (
        <Catcher>
            <ListWrapper
                cleanStore={cleanCountriesStore}
                filterParams={filterParams}
                getRelationSelect={getCountriesRelationSelect}
            >
                <CountriesTableList />
            </ListWrapper>
        </Catcher>
    );
});
