import React, { ReactNode } from 'react';
import { camelCaseToNormalCase } from '../../../instruments';
import { LightSearch } from '../../../Components';
import Styles from '../../styles/m_viewStyles.less';

interface IActivityExpandedRowLine {
    children: ReactNode;
    title: string;
}

export function ActivityExpandedRowLine({ children, title }: IActivityExpandedRowLine) {
    return (
        <div className={Styles.expandedRowLine}>
            <div className={Styles.expandedRowLineLabel}>
                <LightSearch text={camelCaseToNormalCase(title)} />
            </div>
            <div className={Styles.expandedRowLineValue}>{children}</div>
        </div>
    );
}
