import React from 'react';
import { ActivityExpandedRowLine } from './ActivityExpandedRowLine';
import { ActivityLogFieldValue } from './ActivityLogFieldValue';
import { Catcher } from '../../Catcher';

interface IActivityLogLists {
    lists: object;
}

export const ActivityLogLists: React.FC<IActivityLogLists> = ({ lists }: IActivityLogLists) => {
    return (
        <Catcher>
            {Object.entries(lists).map(([listKey, listArray]) => {
                if (typeof listArray === 'object' && Array.isArray(listArray) && listArray?.length) {
                    return (
                        <ActivityExpandedRowLine title={listKey} key={listKey}>
                            <ActivityLogRecords records={listArray} />
                        </ActivityExpandedRowLine>
                    );
                } else {
                    return null;
                }
            })}
        </Catcher>
    );
};

interface IActivityLogRecords {
    records: any[];
}

const ActivityLogRecords: React.FC<IActivityLogRecords> = ({ records }: IActivityLogRecords) => {
    return (
        <Catcher>
            {records.map((item, itemInd) => {
                return (
                    <ActivityExpandedRowLine title={(itemInd + 1).toString()} key={itemInd}>
                        {Object.entries(item).map(([recordKey, recordValue], recordInd) => {
                            return (
                                <ActivityExpandedRowLine title={recordKey} key={recordKey + recordInd.toString()}>
                                    {Array.isArray(recordValue) ? (
                                        <ActivityLogRecords records={recordValue} />
                                    ) : (
                                        <ActivityLogFieldValue value={recordValue} prop={recordKey} key={recordInd} />
                                    )}
                                </ActivityExpandedRowLine>
                            );
                        })}
                    </ActivityExpandedRowLine>
                );
            })}
        </Catcher>
    );
};
