export type IRoleRight = 'ROOOOT' | 'ROOT' | 'LORD' | 'ADMIN' | 'RESELLER' | 'CUSTOMER_CARE' | 'USER' | 'FINANCE';
export type IRole = 'RooT' | 'root' | 'lord' | 'admin' | 'reseller' | 'customer_care' | 'user' | 'finance';
export type IRealRole = IRole | undefined;

type IRoleRightMap = {
    [id in IRole]: Array<IRoleRight>;
};

export class RoleRightMap {
    private readonly _map: IRoleRightMap = {
        RooT: ['ROOOOT', 'ROOT', 'LORD', 'ADMIN', 'RESELLER', 'CUSTOMER_CARE', 'USER'],
        root: ['ROOT', 'LORD', 'ADMIN', 'RESELLER', 'CUSTOMER_CARE', 'USER'],
        lord: ['LORD', 'ADMIN', 'RESELLER', 'CUSTOMER_CARE', 'USER'],
        admin: ['ADMIN', 'CUSTOMER_CARE', 'USER'],
        customer_care: ['CUSTOMER_CARE', 'USER'],
        reseller: ['RESELLER', 'USER'],
        finance: ['FINANCE', 'USER'],
        user: ['USER'],
    };
    private readonly _role: IRole;
    constructor(role?: IRole) {
        this._role = role ? role : 'user';
    }

    public getRight(): Array<IRoleRight> {
        return this._map[this._role];
    }
    public isRight(right: IRoleRight): boolean {
        return this._map[this._role]?.includes(right);
    }
}

export const isRoleRight = (right?: Array<IRoleRight>, role?: IRole): boolean => {
    if (!right) {
        return true;
    } else {
        const rightMap = new RoleRightMap(role);
        let isRight = false;
        right.forEach((r) => {
            if (rightMap.isRight(r)) {
                isRight = true;
            }
        });
        return isRight;
    }
};
