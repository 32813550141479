import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { Col } from 'antd';
import { Catcher, LoadingApiByIds } from '../../';
import { columnOption } from '../columnOption';
import cx from 'classnames';
import { ICurrencyValue } from '../../../interfaces';
import { useDashboardFinancesIsNotBigQueryUsage, useProfile } from '../../../hooks';
import { tF, tP } from '../../../translate';
import Styles from '../m_dashboarStyles.less';

export const FinancesRevenuesIsNotBigQueryCosts: React.FC = observer(() => {
    const { isCompanyTypeIdMNOHost } = useProfile();
    const { revenues, costs } = useDashboardFinancesIsNotBigQueryUsage();
    const renderItem = (data: ICurrencyValue[] | undefined, title: string): ReactElement => {
        if (!data?.length) {
            return (
                <>
                    <div className={Styles.title}>{title}</div>
                    <div className={Styles.value}>{`€0,00`}</div>
                </>
            );
        }

        return (
            <>
                <div className={Styles.title}>{title}</div>
                {data?.map((i: ICurrencyValue, index) => {
                    const currencyValue = i && (i.value || 0);
                    const value = +currencyValue <= 0 ? '€0,00' : i.value;

                    return (
                        <div key={index} className={Styles.value}>
                            {value}
                        </div>
                    );
                })}
            </>
        );
    };

    const columnSize = isCompanyTypeIdMNOHost ? columnOption.financesInfoCard : columnOption.financesRevenuesCosts;

    return (
        <Catcher>
            <Col className={Styles.cardPlace} {...columnSize}>
                <div className={cx(Styles.infoCard, Styles.financesCard)}>
                    <LoadingApiByIds idSpinners={['GET_FINANCE_ROLL']} />
                    <div className={cx(Styles.info, Styles.infoRight)}>{renderItem(revenues, tP('Revenue'))}</div>
                    {!isCompanyTypeIdMNOHost ? (
                        <div className={cx(Styles.info, Styles.infoLeft)}>{renderItem(costs, tF('Cost'))}</div>
                    ) : null}
                </div>
            </Col>
        </Catcher>
    );
});
