import React from 'react';
import { observer } from 'mobx-react';
import { RouterStore } from 'mobx-state-router';
import { Catcher } from '../../';
import { CountryOverlayView } from './';
import { Marker } from '@react-google-maps/api';
import { IUseDashboardCountryUsageCountryInfo } from '../../../interfaces';
import { useDashboardCountriesUsageForMapInfo, useRouterStore } from '../../../hooks';
import { setDashboardOverlayView } from '../../../middleware';

export const MarkersCountryTraffic: React.FC = observer((): JSX.Element => {
    const routerStore = useRouterStore();
    const countriesInfo = useDashboardCountriesUsageForMapInfo();
    const goToCountry = (id: number, routerStore: RouterStore): void => {
        routerStore.goTo('Country', { params: { id: id.toString() } }).then();
    };

    const markers: JSX.Element[] = countriesInfo
        .map((country: IUseDashboardCountryUsageCountryInfo) => {
            if (!country?.geo?.center?.lat || !country?.geo?.center?.lng) {
                return <div key={undefined} />;
            } else {
                return (
                    <Marker
                        key={country.id}
                        position={{ lat: country.geo.center.lat, lng: country.geo?.center?.lng }}
                        onMouseOver={(): void => setDashboardOverlayView(<CountryOverlayView id={country.id} />)}
                        onMouseOut={(): void => setDashboardOverlayView(null)}
                        onClick={(): void => goToCountry(country.id, routerStore)}
                    />
                );
            }
        })
        .filter((i) => i.key);

    return <Catcher>{markers}</Catcher>;
});
