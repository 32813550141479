import React, { ReactNode } from 'react';
import { Button } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { tB } from '../../translate';
import { Catcher } from '../Catcher';

const onClickButtonReloadApp = (): void => {
    window.location.reload();
};

export const ReloadAppButton: ReactNode = (
    <Catcher>
        <Button onClick={onClickButtonReloadApp} type={'primary'} icon={<ReloadOutlined />}>
            {tB('Reload')}
        </Button>
    </Catcher>
);
