import React from 'react';
import { observer } from 'mobx-react';
import { Row } from 'antd';
import { IOperatorInteractionsResponsesError } from '../../../../interfaces';
import { useOperatorInteractionsResponse } from '../../../../hooks';
import { objIsNotEmpty } from '../../../../instruments';
import { ITrField, tF } from '../../../../translate';
import { CardInfoToolBar, CardMainLayout, Catcher, FieldInfoAdvanced, ShowRawDataInfo } from '../../../../Components';
import { FileInfo, FileStatus, ShowResponsesData } from './../Components';
import Styles from '../../../styles/m_itemCardStyles.less';

export const ShowOperatorInteractionsResponse: React.FC = observer(() => {
    const item = useOperatorInteractionsResponse();
    const isError = !!(item.data as IOperatorInteractionsResponsesError)?.error;

    return (
        <Catcher>
            <article className={Styles.main}>
                <Row>
                    <CardMainLayout idSpinners={['GET_OPERATOR_INTERACTIONS_RESPONSE']}>
                        <FieldInfoAdvanced title={'ICCID'}>{item.iccId}</FieldInfoAdvanced>
                        <FieldInfoAdvanced title={'OperationId' as ITrField}>{item.operationId}</FieldInfoAdvanced>
                        <FieldInfoAdvanced title={'Operation name' as ITrField}>{item.operationName}</FieldInfoAdvanced>
                        <FieldInfoAdvanced title={'Status'}>
                            <FileStatus status={item.status} />
                        </FieldInfoAdvanced>
                        <FieldInfoAdvanced title={'Data'}>
                            <ShowRawDataInfo transactionInfo={item.data} isError={isError} />
                        </FieldInfoAdvanced>
                        <ShowResponsesData data={item.data} />
                        <FieldInfoAdvanced title={'Gateway'}>{item.gateway}</FieldInfoAdvanced>
                        <FieldInfoAdvanced title={'MSISDN'}>{item.msisdn}</FieldInfoAdvanced>
                        <FieldInfoAdvanced title={'Created at'}>{item.createdAt}</FieldInfoAdvanced>
                        {objIsNotEmpty(item.file) ? (
                            <>
                                <div className={Styles.showField}>{tF('File')}:</div>
                                <div className={Styles.subFieldsPlace}>
                                    <FileInfo fileInfo={item.file} />
                                </div>
                                )
                            </>
                        ) : null}
                        <CardInfoToolBar isBackToList />
                    </CardMainLayout>
                </Row>
            </article>
        </Catcher>
    );
});
