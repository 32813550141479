import React from 'react';
import { observer } from 'mobx-react';
import { CardInfoToolBar, CardMainLayout, Catcher, FieldInfo, LinkComponent } from '../../';
import { IShowForm } from '../../../interfaces';
import { useOperatorInfo, useOperatorSpinners } from '../../../hooks';

export const ShowOperatorInfo: React.FC<IShowForm> = observer(({ setEditMode }: IShowForm) => {
    const operatorInfo = useOperatorInfo();
    const mccMnc = `${operatorInfo?.mcc ? operatorInfo.mcc : ''}/${operatorInfo?.mnc ? operatorInfo.mnc : ''}`;
    const loadingTypes = useOperatorSpinners();

    return (
        <Catcher>
            <CardMainLayout idSpinners={loadingTypes} name={operatorInfo.name}>
                <FieldInfo title={'Name'} dataTest="operatorInfoName">
                    {operatorInfo.name}
                </FieldInfo>
                <FieldInfo title={'Tadig'} dataTest="operatorInfoTadig">
                    {operatorInfo.tadig}
                </FieldInfo>
                <FieldInfo title={'MCC/MNC'} dataTest="operatorInfoMCC/MNC">
                    {mccMnc}
                </FieldInfo>
                <FieldInfo title={'Country'} dataTest="operatorInfoCountry">
                    <LinkComponent
                        currentListComponent={'CountryList'}
                        id={operatorInfo.countryId}
                        name={operatorInfo.country}
                    />
                </FieldInfo>
                <FieldInfo title={'CDRs received by this network'} dataTest="operatorInfoCdrsReceived">
                    {operatorInfo.hostRecordsCount}
                </FieldInfo>
                <FieldInfo title={'CDRs with usage on this network'} dataTest="operatorInfoCdrsUsage">
                    {operatorInfo.guestRecordsCount}
                </FieldInfo>
                <FieldInfo title={'Gateway'} dataTest="operatorInfoGateway">
                    {operatorInfo.gateway}
                </FieldInfo>
                <CardInfoToolBar dataTest="ShowOperatorInfo" setEditMode={setEditMode} isBackToList />
            </CardMainLayout>
        </Catcher>
    );
});
