import { UiStore } from '../stores/ui';
import { saveAs } from 'file-saver';
import { apiManager } from '../REST';
import { IGetApiType } from '../REST';
import { IFilterParams } from '../interfaces';
import { apiParamsCleaner } from '../instruments';

export async function getDownloadFile(type: IGetApiType, filterParams: IFilterParams, id?: string): Promise<void> {
    const UiStoreInstance = UiStore.getInstance();
    const params = UiStoreInstance.params;
    const cleanParams = apiParamsCleaner(params, filterParams);
    const resp = await apiManager.getApi(type, { searchParamsList: cleanParams, id });
    const { file } = resp;
    if (!!file && file.isAPIComplete) {
        const blob = new Blob([file.content], { type: file.content.type });
        saveAs(blob, file?.filename);
    }
}
