import { action, computed, makeObservable, observable } from 'mobx';
import { IGetApiType } from '../REST';
import { ICardSetInfo } from '../interfaces';

export class CardSetStore {
    private constructor() {
        makeObservable(this);
    }

    private static instance: CardSetStore;
    public static getInstance(): CardSetStore {
        if (!CardSetStore.instance) {
            CardSetStore.instance = new CardSetStore();
        }
        return CardSetStore.instance;
    }

    @observable cardSetInfo: ICardSetInfo = { id: '' };
    @action setCardInfo(cardSetInfo: ICardSetInfo): void {
        this.cardSetInfo = cardSetInfo;
    }

    @observable spinnersInfo: IGetApiType[] = [
        'DELETE_CARD_SET',
        'GET_CARD_SET',
        'GET_COMBINATION_CARDS_SELECT',
        'GET_COMPANIES_SELECT',
        'PATCH_CARD_SET',
        'POST_CARD_SET',
    ];
    @observable spinnersRelation: IGetApiType[] = [
        'GET_CALL_DATA_RECORDS_LAST_5',
        'GET_CARD_SET_COMBINATION',
        'GET_COMPANY_STATISTICS',
        'GET_ACTIVITY_ROLL',
    ];

    @computed get spinners(): IGetApiType[] {
        return [...this.spinnersInfo, ...this.spinnersRelation];
    }

    @action cleanStore(): void {
        this.cardSetInfo = { id: '' };
    }
}
