import React, { FC, MouseEvent } from 'react';
import { observer } from 'mobx-react';
import { RouterStore } from 'mobx-state-router';
import { Menu, MenuProps } from 'antd';
import { LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Catcher, MenuIcons } from '../';
import { getParenIdOfChildren } from '../../Pages/pagesBook';
import { MenuInfo } from 'rc-menu/lib/interface';
import { IComponentName, IPage } from '../../interfaces';
import { usePagesStoreMenu, useProfile, useRouterStore, useUiLanguage } from '../../hooks';
import { authLogOutUser, setRouterStoreCurrentComponent } from '../../middleware';
import { tP } from '../../translate';

type IMenuType = 'DesktopMenu' | 'MobileMenu';
interface IMainMenuProps {
    onChangeCollapse?(): void;
    menuType: IMenuType;
    collapsed?: boolean;
}

type IMenuItem = Required<MenuProps>['items'][number];

type IMakeMenuItems = {
    menu: IPage[];
    collapsed?: boolean;
    onChangeCollapse?(): void;
    profileLabel: string;
    routerStore: RouterStore;
    selectKeys?: string[];
    type?: IMenuType;
};

export const makeMenuItems = ({
    menu,
    collapsed,
    onChangeCollapse,
    profileLabel,
    routerStore,
    selectKeys,
    type,
}: IMakeMenuItems): MenuProps['items'] => {
    const menuItems: MenuProps['items'] = [];

    if (type === 'DesktopMenu') {
        menuItems.push({
            key: 'collapse',
            label: !collapsed ? tP('Collapse') : tP('Uncollapse'),
            icon: collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />,
        });
        menuItems.push({ type: 'divider' });
    }

    menu.forEach((item: IPage): void => {
        const goTo = (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>): void => {
            if (!(event.ctrlKey || event.metaKey || event.altKey || event.shiftKey || item.url === '#')) {
                event.preventDefault();
                routerStore.goTo(item.componentName);
                setRouterStoreCurrentComponent({ routeName: item.componentName });
            }
        };

        if (item.isMenuSeparator) {
            menuItems.push({ type: 'divider' });
        } else {
            const title = item.title === 'MY_PROFILE_NAME' ? profileLabel : item.title ? tP(item.title) : '';
            const url = item.url || '#';
            let menuItem: IMenuItem = {
                key: item.id,
                label: (
                    <a href={url} onClick={goTo}>
                        {title}
                    </a>
                ),
                icon: item.menuIcon ? <MenuIcons iconName={item.menuIcon} /> : null,
            };

            if (item.subMenu && Array.isArray(item.subMenu) && item.subMenu.length) {
                menuItem = {
                    ...menuItem,
                    children: makeMenuItems({
                        menu: item.subMenu,
                        collapsed,
                        onChangeCollapse,
                        profileLabel,
                        routerStore,
                        selectKeys,
                    }),
                };
            }
            menuItems.push(menuItem);
        }
    });

    if (type === 'MobileMenu') {
        menuItems.push({ type: 'divider' });
        menuItems.push({
            key: 'logOut',
            label: <a href={'#'}>{tP('Log out')}</a>,
            icon: <LogoutOutlined />,
        });
    }

    return menuItems;
};

export const MainMenu: FC<IMainMenuProps> = observer(({ onChangeCollapse, collapsed }) => {
    const routerStore = useRouterStore();
    const menu = usePagesStoreMenu();
    const { firstName, lastName } = useProfile();
    const parentComponentId = getParenIdOfChildren(routerStore.routerState.routeName as IComponentName);
    const selectKeys = parentComponentId ? [parentComponentId] : [];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const languageId = useUiLanguage();

    const goToComponentKey = (key: string): void => {
        if (typeof key !== 'undefined') {
            switch (key) {
                case 'collapse':
                    onChangeCollapse && onChangeCollapse();
                    break;
                case 'logOut':
                    authLogOutUser().then();
                    break;
                default:
                    break;
            }
        }
    };

    return (
        <Catcher>
            <Menu
                onClick={(e: MenuInfo): void => goToComponentKey(e.key.toString())}
                selectedKeys={selectKeys}
                mode="inline"
                items={makeMenuItems({
                    menu: menu,
                    profileLabel: `${firstName} ${lastName}`,
                    type: 'DesktopMenu',
                    routerStore,
                    selectKeys,
                    collapsed,
                    onChangeCollapse,
                })}
            />
        </Catcher>
    );
});
