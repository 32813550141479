import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Collapse, Form, Row } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { CardInfoToolBar, CardMainLayout, Catcher, cardLayoutOptions } from '../';
import cx from 'classnames';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { IFormFields, IGetApiType, ToggleItem } from '../../interfaces';
import { useProfile, useProfileAlarms, useProfileIsLoading } from '../../hooks';
import { addNotification, getCardSetsSelect, getProfileAlarm, sendProfileAlarms } from '../../middleware';
import { tB, tF, tT } from '../../translate';
import { MonthlyCardPanel, MonthlyCardSetPanel, PanelControl, ToggleList } from './Components';
import Styles from '../styles/m_itemCardStyles.less';

export const MyAlarms: React.FC = observer(() => {
    const { Panel } = Collapse;
    const [form] = Form.useForm();
    const formName = 'profileAlarms';
    const componentSpinners: IGetApiType[] = ['GET_USER_PROFILE'];
    const [isSending, setSending] = useState(false);
    const isLoading = useProfileIsLoading();
    const { companyId = '' } = useProfile();
    const initialValues = useProfileAlarms();
    const isDisabled = isLoading || isSending;
    const companyBalanceAlarms: ToggleItem[] = [
        {
            label: '1st balance warning',
            name: 'notification_company_threshold_medium_reached',
        },
        {
            label: '2nd balance warning',
            name: 'notification_company_threshold_high_reached',
        },
        {
            label: 'No Balance',
            name: 'notification_company_balance_limit_reached',
        },
    ];

    const IMEILockAlarms: ToggleItem[] = [
        {
            label: 'Send an alarm when a different IMEI is recorded for IMEI locked cards',
            name: 'notification_card_imei_lock_violation',
        },
    ];

    useEffect(() => {
        getProfileAlarm(companyId);
    }, []);

    useEffect(() => {
        form.setFieldsValue(initialValues);
    }, [JSON.stringify(initialValues)]);

    useEffect(() => {
        getCardSetsSelect({ companyId }, true);
    }, [companyId]);

    const findAnError = (errorInfo: ValidateErrorEntity): void => {
        errorInfo.errorFields.forEach((value) => {
            const errorFieldName = value.name;
            const elementWithError = document.getElementById(`${formName}_${errorFieldName}`);
            const collapse = elementWithError?.closest('.ant-collapse-item');
            if (!collapse?.classList.contains('ant-collapse-item-active')) {
                const button = collapse?.getElementsByClassName('ant-collapse-header')[0] as HTMLElement;
                button && button.click();
            }
        });
        setTimeout(() => {
            const firstErrorFieldName = errorInfo.errorFields[0].name;
            const elementWithError = document.getElementById(`${formName}_${firstErrorFieldName}`);
            elementWithError?.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }, 200);
    };

    const isFormValid = (): boolean => form.getFieldsError().some((item) => item.errors.length > 0);

    const onFinish = async (data: IFormFields): Promise<void> => {
        if (!isFormValid()) {
            setSending(true);
            const isError = await sendProfileAlarms(data);

            if (!isError) {
                addNotification({
                    type: 'info',
                    message: 'Alarm config has been saved!',
                    duration: 5,
                });

                await getProfileAlarm(companyId);
            }

            form.resetFields();

            setSending(false);
        } else {
            const errorFields = form.getFieldsError().filter((item) => item.errors.length > 0);
            const errorInfo: ValidateErrorEntity = {
                outOfDate: false,
                errorFields: errorFields,
                values: form.getFieldsValue(),
            };
            findAnError(errorInfo);
        }
    };

    const resetFields = (arr: string[]): void => {
        form.resetFields([...arr]);
    };

    const changeError = (name: string, errorMessage: string, isError: boolean): void => {
        if (isError) {
            form.setFields([
                {
                    name,
                    errors: [`${errorMessage}`],
                },
            ]);
        } else {
            form.setFields([
                {
                    name,
                    errors: [],
                },
            ]);
        }
    };

    return (
        <Catcher>
            <article className={Styles.main}>
                <Row>
                    <CardMainLayout
                        columnOption={cardLayoutOptions.cardInfoEdit}
                        title={'Alarms'}
                        idSpinners={componentSpinners}
                    >
                        <p>
                            {tT('You can define the alarms you want to receive below.')} <br />
                            {tT('Please enter the requested information and configure the different options below.')}
                        </p>
                        <Form
                            form={form}
                            name="profileAlarms"
                            initialValues={initialValues}
                            onFinish={onFinish}
                            onFinishFailed={findAnError}
                            layout={'horizontal'}
                            className={'formStyle'}
                        >
                            <Collapse>
                                <Panel key={1} header={tF('Company Balance')} forceRender={true}>
                                    <ToggleList toggleArr={companyBalanceAlarms} isLoading={isDisabled} />
                                </Panel>
                                <Panel key={2} header={tF('IMEI Lock')} forceRender={true}>
                                    <ToggleList toggleArr={IMEILockAlarms} isLoading={isDisabled} />
                                </Panel>
                                <Panel
                                    key={3}
                                    header={tF('Monthly Card Balance limits for all cards')}
                                    forceRender={true}
                                >
                                    <PanelControl
                                        label={tF('Set monthly limit for all company cards limit')}
                                        isSending={isSending}
                                        isLoading={isLoading}
                                        Content={MonthlyCardPanel}
                                        togglesNames={[
                                            'notification_card_threshold_50_reached',
                                            'notification_card_threshold_75_reached',
                                            'notification_card_threshold_100_reached',
                                            'suspend_card_threshold_100_reached',
                                        ]}
                                        resetFields={resetFields}
                                        changeError={changeError}
                                    />
                                </Panel>
                                <Panel
                                    key={4}
                                    header={tF('Card monthly Balance limits for all cards in card set')}
                                    forceRender={true}
                                >
                                    <PanelControl
                                        label={tF('Set monthly limit for all cards in card set(s) limit')}
                                        isSending={isSending}
                                        isLoading={isLoading}
                                        Content={MonthlyCardSetPanel}
                                        togglesNames={[
                                            'notification_card_set_threshold_50_reached',
                                            'notification_card_set_threshold_75_reached',
                                            'notification_card_set_threshold_100_reached',
                                            'suspend_card_set_threshold_100_reached',
                                        ]}
                                        resetFields={resetFields}
                                        changeError={changeError}
                                    />
                                </Panel>
                            </Collapse>
                            <CardInfoToolBar>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    icon={<SaveOutlined />}
                                    className={cx(Styles.mainButton, {
                                        [Styles.buttonsDisable]: isSending,
                                    })}
                                    disabled={isDisabled}
                                    loading={isDisabled}
                                >
                                    {tB('Update')}
                                </Button>
                            </CardInfoToolBar>
                        </Form>
                    </CardMainLayout>
                </Row>
            </article>
        </Catcher>
    );
});
