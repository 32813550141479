import React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import { Catcher, LinkComponent } from '../../';
import { IZoneTable } from '../../../interfaces';
import { useUiIsLoading, useZonesTable } from '../../../hooks';
import { tF } from '../../../translate';
import Styles from '../../styles/m_viewStyles.less';

export const ZonesTableList: React.FC = observer(() => {
    const isLoading = useUiIsLoading();
    const zonesList = useZonesTable();

    const tableColumns = [
        {
            title: tF('Name'),
            dataIndex: 'linkName',
        },
        {
            title: tF('Countries'),
            dataIndex: 'countryCount',
        },
    ];
    const tableData = zonesList.map((z: IZoneTable) => {
        return {
            key: z.id,
            ...z,
            linkName: <LinkComponent currentListComponent={'ZonesList'} id={z.id} name={z.name} isLight />,
        };
    });

    return (
        <Catcher>
            <div className={Styles.tablePlace}>
                <Table
                    className={'table-theme'}
                    columns={tableColumns}
                    dataSource={tableData}
                    loading={isLoading}
                    pagination={false}
                    size={'middle'}
                />
            </div>
        </Catcher>
    );
});
