import React from 'react';
import { tT } from '../../../translate';

export const SignUpOk: React.FC = () => {
    return (
        <>
            <p>{tT('signUpOkText1')}</p>
            <p>{tT('signUpOkText2')}</p>
        </>
    );
};
