import React, { useEffect } from 'react';
import { appConfig } from '../../settings';
import { IMyWindow } from '../../interfaces';
import { Catcher } from '../../Components';

export const TicketingSystem: React.FC = () => {
    const initWidget = (): void => {
        const myWindow: IMyWindow = window;
        if (typeof myWindow.FreshworksWidget !== 'function') {
            myWindow.fwSettings = { widget_id: appConfig.widget.id };
            const script = document.createElement('script');
            script.src = appConfig.widget.scriptUrl;
            script.async = true;
            document.body.appendChild(script);
        }
    };

    const stopWidget = (): void => {
        const myWindow: IMyWindow = window;
        delete myWindow.fwSettings;
        const widgetContainer = document.getElementById(appConfig.widget.div);
        if (widgetContainer) {
            widgetContainer.remove();
        }
    };

    useEffect(() => {
        initWidget();
        return (): void => {
            stopWidget();
        };
    }, []);

    return <Catcher>{null}</Catcher>;
};
