import React, { ReactNode } from 'react';
import { observer } from 'mobx-react';
import { Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Catcher } from '../../..';
import { FileStatus } from './FileStatus';
import { IOperatorInteractionsFile } from '../../../../interfaces';
import { useOperatorInteractionsFilesTable } from '../../../../hooks';
import { tF } from '../../../../translate';
import Styles from '../../../styles/m_viewStyles.less';

interface IDataType {
    key: string;
    id: string;
    fileName: string;
    fileNameView: ReactNode;
    status: string;
    statusView: ReactNode;
    gateway: string;
    statistics: string;
    createdAt: string;
}

export const FilesTable: React.FC = observer(() => {
    const list = useOperatorInteractionsFilesTable();

    const tableColumns: ColumnsType<IDataType> = [
        {
            title: tF('ID'),
            dataIndex: 'id',
            // sorter: (a: IDataType, b: IDataType) => +a.id - +b.id,
            width: 70,
            className: Styles.columnRight,
        },
        {
            title: tF('File'),
            dataIndex: 'fileNameView',
            // sorter: (a: IDataType, b: IDataType) => sortByString(a.fileName, b.fileName),
        },
        {
            title: tF('Status'),
            dataIndex: 'statusView',
            // sorter: (a: IDataType, b: IDataType) => sortByString(a.status, b.status),
            // filters: appConfig.uploadFilesStatus.map((i) => ({ value: i.id, text: i.text })),
            // onFilter: (value: string | number | boolean, record: IDataType) =>
            //     record.status.startsWith(value.toString()),
            // filterSearch: false,
        },
        {
            title: 'Gateway',
            dataIndex: 'gateway',
        },
        {
            title: 'Statistics',
            dataIndex: 'statistics',
        },
        {
            title: tF('Created at'),
            dataIndex: 'createdAt',
            // sorter: (a: IDataType, b: IDataType) => sortByString(a.createdAt, b.createdAt),
        },
    ];
    const tableData: IDataType[] = list.map((f: IOperatorInteractionsFile) => {
        return {
            key: f.id,
            id: f.id,
            fileName: f.fileName || '',
            fileNameView: (
                <Tooltip placement="topLeft" title={f.file}>
                    {f.fileName}
                </Tooltip>
            ),
            status: f.status?.toString() || '',
            statusView: <FileStatus status={f.status} />,
            createdAt: f.createdAt || '',
            gateway: f.gateway?.toString() || '',
            statistics: f.statistics?.toString() || '',
        };
    });

    return (
        <Catcher>
            <div className={Styles.tablePlace}>
                <Table
                    className={'table-theme'}
                    columns={tableColumns}
                    dataSource={tableData}
                    size={'middle'}
                    pagination={false}
                />
            </div>
        </Catcher>
    );
});
