import React from 'react';
import { observer } from 'mobx-react';
import { Table, Tooltip } from 'antd';
import { CardLayout, Catcher, cardLayoutOptions, viewOption } from '../../';
import { ShowRateCountryTable } from './';
import cx from 'classnames';
import { ICountryWithZone, IRateCountryViewTableRecord } from '../../../interfaces';
import {
    useCountriesWithZoneListByZoneId,
    useRateCountryInitialValues,
    useRateInfo,
    useUiIsLoading,
} from '../../../hooks';
import { tF, tI } from '../../../translate';
import '../../../customStyle/table.less';
import StylesItem from '../m_styles.less';

interface IZone {
    operatorId: string;
    zoneId: string;
    name: string;
}
export const ShowRateZoneTable: React.FC<IZone> = observer(({ zoneId, name, operatorId }: IZone) => {
    const isLoading = useUiIsLoading();
    const {
        defaultRateData,
        defaultRateDataUnit,
        defaultRateSms,
        defaultRateSmsUnit,
        defaultRateVoiceMobile,
        defaultRateVoiceMobileUnit,
        defaultRateVoiceNet,
        defaultRateVoiceNetUnit,
    } = useRateInfo();
    const countrySelect = useCountriesWithZoneListByZoneId(zoneId);
    const tableColumns = [
        {
            title: '',
            dataIndex: 'networksCountShow',
            className: StylesItem.rateColumnVal,
            width: viewOption.rateTable.widthNetCount,
        },
        {
            title: tF('Country'),
            dataIndex: 'country',
            className: StylesItem.rateColumnCountry,
            width: viewOption.rateTable.widthRateCountry,
        },
        {
            title: tF('Data'),
            dataIndex: 'rateDataShow',
            className: StylesItem.rateColumnVal,
            width: viewOption.rateTable.widthRateVal,
        },
        {
            title: tF('SMS'),
            dataIndex: 'rateSmsShow',
            className: StylesItem.rateColumnVal,
            width: viewOption.rateTable.widthRateVal,
        },
        {
            title: tF('Voice Mobile'),
            dataIndex: 'rateVoiceMobileShow',
            className: StylesItem.rateColumnVal,
            width: viewOption.rateTable.widthRateVal,
        },
        {
            title: tF('Voice Fix Net'),
            dataIndex: 'rateVoiceNetShow',
            className: StylesItem.rateColumnVal,
            width: viewOption.rateTable.widthRateVal,
        },
    ];

    const tableData = countrySelect
        .filter((c) => c.zoneId === zoneId)
        .map((c: ICountryWithZone) => {
            const key = c.id;
            const item = useRateCountryInitialValues(c.id, operatorId);
            const networksCount = item.networks?.length || 0;
            const rateDataUnit = item.rateDataUnit;
            const rateData = rateDataUnit === '%' ? item.rateData : item.calculatedRateData;
            const rateVoiceMobileUnit = item.rateVoiceMobileUnit;
            const rateVoiceMobile = rateVoiceMobileUnit === '%' ? item.rateVoiceMobile : item.calculatedRateVoiceMobile;
            const rateVoiceNetUnit = item.rateVoiceNetUnit;
            const rateVoiceNet = rateVoiceNetUnit === '%' ? item.rateVoiceNet : item.calculatedRateVoiceNet;
            const rateSmsUnit = item.rateSmsUnit;
            const rateSms = rateSmsUnit === '%' ? item.rateSms : item.calculatedRateSms;
            let isCustom = !!networksCount;
            if (
                rateData !== defaultRateData ||
                rateDataUnit !== defaultRateDataUnit ||
                rateSms !== defaultRateSms ||
                rateSmsUnit !== defaultRateSmsUnit ||
                rateVoiceMobile !== defaultRateVoiceMobile ||
                rateVoiceMobileUnit !== defaultRateVoiceMobileUnit ||
                rateVoiceNet !== defaultRateVoiceNet ||
                rateVoiceNetUnit !== defaultRateVoiceNetUnit
            ) {
                isCustom = true;
            }
            const NetworksCountShow = !!networksCount ? (
                <Tooltip title={`${networksCount} ${tI('custom networks')}`}>
                    <span className={!networksCount ? StylesItem.defaultRate : StylesItem.customRate}>
                        {networksCount}
                    </span>
                </Tooltip>
            ) : null;
            return {
                key,
                countryId: c.id,
                country: <span className={!isCustom ? StylesItem.defaultRate : StylesItem.customRate}>{c.name}</span>,
                operatorId,
                networksCount,
                networksCountShow: NetworksCountShow,
                rateDataShow: (
                    <p className={rateData === defaultRateData ? StylesItem.defaultRate : StylesItem.customRate}>
                        {rateData} {rateDataUnit}
                    </p>
                ),
                rateSmsShow: (
                    <span className={rateSms === defaultRateSms ? StylesItem.defaultRate : StylesItem.customRate}>
                        {rateSms} {rateSmsUnit}
                    </span>
                ),
                rateVoiceMobileShow: (
                    <span
                        className={
                            rateVoiceMobile === defaultRateVoiceMobile ? StylesItem.defaultRate : StylesItem.customRate
                        }
                    >
                        {rateVoiceMobile} {rateVoiceMobileUnit}
                    </span>
                ),
                rateVoiceNetShow: (
                    <span
                        className={
                            rateVoiceNet === defaultRateVoiceNet ? StylesItem.defaultRate : StylesItem.customRate
                        }
                    >
                        {rateVoiceNet} {rateVoiceNetUnit}
                    </span>
                ),
            };
        });

    const expandedRowRender = (record: IRateCountryViewTableRecord): JSX.Element => {
        return <ShowRateCountryTable record={record} />;
    };

    return (
        <Catcher>
            <CardLayout
                columnOption={cardLayoutOptions.cardTableOperators}
                count={countrySelect.length}
                idSpinners={['GET_SALES_RATE_LIST']}
                isTable
                titleRight={`${name}`}
            >
                <Table
                    className={cx('table-theme')}
                    columns={tableColumns}
                    dataSource={tableData}
                    expandable={{
                        expandedRowRender,
                        rowExpandable: (record: IRateCountryViewTableRecord): boolean => !!record.networksCount,
                    }}
                    loading={isLoading}
                    pagination={false}
                    size={'small'}
                />
            </CardLayout>
        </Catcher>
    );
});
