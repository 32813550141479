import { action, makeObservable, observable, reaction } from 'mobx';
import { UiStore } from './ui';
import { appConfig } from '../settings';
import {
    IEsNotificationsList,
    IEsNotificationsLogsList,
    IEsNotificationsQrcodesList,
    IFilterParams,
} from '../interfaces';
import { useEsNotificationsQrcodesTable } from '../hooks';
import { getEsNotificationsList, getEsNotificationsQrCodeList, getEsPlusNotificationsLogsList } from '../middleware';
import { apiParamsCleaner } from '../instruments';

export class EsNotificationsStore {
    private constructor() {
        makeObservable(this);
        reaction(
            () => this.forcedUpdateTime,
            () => {
                const UiStoreInstance = UiStore.getInstance();
                const params = UiStoreInstance.params;
                const cleanParams = apiParamsCleaner(params, this.filterParams);
                getEsNotificationsList(cleanParams).then();
            },
            {
                delay: appConfig.APIDelay,
            },
        );
        reaction(
            () => this.forcedUpdateTimeLogs,
            () => {
                const UiStoreInstance = UiStore.getInstance();
                const params = UiStoreInstance.params;
                const cleanParams = apiParamsCleaner(params, this.filterParamsLogs);
                getEsPlusNotificationsLogsList(cleanParams).then();
            },
            {
                delay: appConfig.APIDelay,
            },
        );
        reaction(
            () => this.forcedUpdateTimeQrcodes,
            () => {
                const UiStoreInstance = UiStore.getInstance();
                const params = UiStoreInstance.params;
                const cleanParams = apiParamsCleaner(params, this.filterParamsLogs);
                getEsNotificationsQrCodeList(cleanParams).then();
            },
            {
                delay: appConfig.APIDelay,
            },
        );
    }

    private static instance: EsNotificationsStore;
    public static getInstance(): EsNotificationsStore {
        if (!EsNotificationsStore.instance) {
            EsNotificationsStore.instance = new EsNotificationsStore();
        }
        return EsNotificationsStore.instance;
    }

    @observable list: IEsNotificationsList = [];
    @action setEsNotificationsList(companiesList: IEsNotificationsList): void {
        this.list = companiesList;
    }

    @observable qrcodesList: IEsNotificationsQrcodesList = [];
    @action setEsNotificationsQrcodesList(ippList: IEsNotificationsQrcodesList): void {
        this.qrcodesList = ippList;
    }

    @observable logsList: IEsNotificationsLogsList = [];
    @action setEsNotificationsLogsList(logsList: IEsNotificationsLogsList): void {
        this.logsList = logsList;
    }

    @observable forcedUpdateTime = 0;
    @action setForcedUpdateTime(): void {
        this.forcedUpdateTime = Date.now();
    }

    @observable forcedUpdateTimeLogs = 0;
    @action setForcedUpdateTimeLogs(): void {
        this.forcedUpdateTimeLogs = Date.now();
    }

    @observable forcedUpdateTimeQrcodes = 0;
    @action setForcedUpdateTimeQrcodes(): void {
        this.forcedUpdateTimeQrcodes = Date.now();
    }

    @observable filterParams: IFilterParams = {
        pageNumber: true,
        pageSize: true,
        search: true,
    };

    @observable filterParamsLogs: IFilterParams = {
        pageNumber: true,
        pageSize: true,
        search: true,
    };

    @action cleanStore(): void {
        this.list = [];
        this.qrcodesList = [];
        this.logsList = [];
    }
}
