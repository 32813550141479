import React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import { IUserList } from '../../../interfaces';
import { usePagesStoreThisListRight, useUiIsLoading, useUiSearch, useUsersTable } from '../../../hooks';
import { deleteUser } from '../../../middleware';
import { lightText } from '../../../instruments';
import { tF, tI } from '../../../translate';
import { Catcher, LinkComponent, TableItemToolbar, columnToolbar } from '../../../Components';
import '../../../customStyle/table.less';
import Styles from '../../styles/m_viewStyles.less';

const useColumns = () => {
    return [
        {
            title: tF('First name'),
            dataIndex: 'firstNameLink',
        },
        {
            title: tF('Last name'),
            dataIndex: 'lastNameLink',
        },
        {
            title: tF('Email'),
            dataIndex: 'emailLink',
        },
        {
            title: tF('Company'),
            dataIndex: 'companyLink',
        },
        {
            title: tF('Type'),
            dataIndex: 'userType',
        },
        columnToolbar,
    ];
};

const useTableData = () => {
    const list = useUsersTable();
    const search = useUiSearch();
    const right = usePagesStoreThisListRight();

    return list.map((u: IUserList) => {
        const deleteItem = (): void => {
            deleteUser(u.id);
        };
        const email = u.email ? u.email : `# ${u.id}`;

        return {
            key: u.id,
            ...u,
            userType: lightText(u.userType, search),
            firstNameLink: <LinkComponent currentListComponent={'UsersList'} id={u.id} name={u.firstName} isLight />,
            lastNameLink: <LinkComponent currentListComponent={'UsersList'} id={u.id} name={u.lastName} isLight />,
            emailLink: <LinkComponent currentListComponent={'UsersList'} id={u.id} name={u.email} isLight />,
            companyLink: (
                <LinkComponent currentListComponent={'CompaniesList'} id={u.companyId} name={u.companyName} isLight />
            ),
            toolbar: (
                <TableItemToolbar itemName={`"${email}" ${tI('user')}`} id={u.id} deleteItem={deleteItem} {...right} />
            ),
        };
    });
};

export const UsersTableList: React.FC = observer(() => {
    const isLoading = useUiIsLoading();
    const tableColumns = useColumns();
    const tableData = useTableData();

    return (
        <Catcher>
            <div className={Styles.tablePlace} data-test="UsersTableList">
                <Table
                    className={'table-theme'}
                    columns={tableColumns}
                    dataSource={tableData}
                    loading={isLoading}
                    pagination={false}
                    size={'middle'}
                />
            </div>
        </Catcher>
    );
});
