import React from 'react';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { IOrderView } from '../../../interfaces';
import { useUiIsLoading } from '../../../hooks';
import { stringMoney } from '../../../instruments';
import { tB } from '../../../translate';
import { CardInfoToolBar, CardMainLayout, Catcher, FieldInfo, LinkComponent } from '../../../Components';

interface IShowOrder {
    order: IOrderView;
    refreshOrder(): void;
}

export const ShowOrder: React.FC<IShowOrder> = observer(({ order, refreshOrder }: IShowOrder) => {
    const isLoading = useUiIsLoading();
    return (
        <Catcher>
            <CardMainLayout idSpinners={['GET_ORDER']}>
                <FieldInfo title={'Company'}>
                    <LinkComponent currentListComponent={'CompaniesList'} id={order.companyId} name={order.company} />
                </FieldInfo>
                <FieldInfo title={'Date'}>{order.updatedAt}</FieldInfo>
                <FieldInfo title={'Bought By'}>{order.user}</FieldInfo>
                <FieldInfo title={'Transaction id'}>{order.transactionId}</FieldInfo>
                <FieldInfo title={'Payment Method'}>{order.paymentTypeId}</FieldInfo>
                <FieldInfo title={'Description'}>{order.description}</FieldInfo>
                <FieldInfo title={'Cost'}>{stringMoney(order.cost, order.currency)}</FieldInfo>
                <FieldInfo title={'Status'}>{order.status}</FieldInfo>
                <FieldInfo title={'Notes'}>{order.notes}</FieldInfo>
                <CardInfoToolBar isBackToList>
                    <Button
                        type="primary"
                        onClick={refreshOrder}
                        icon={<SyncOutlined />}
                        disabled={isLoading}
                        loading={isLoading}
                    >
                        {tB('Refresh')}
                    </Button>
                </CardInfoToolBar>
            </CardMainLayout>
        </Catcher>
    );
});
