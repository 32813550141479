import React from 'react';
import { observer } from 'mobx-react';
import { CompaniesHierarchyIsBigQuery, CompaniesHierarchyIsNotBigQuery } from './';
import { useProfileFeatureFlagsIsBigQuery } from '../../hooks';
import '../../customStyle/table.less';

export const CompaniesHierarchy: React.FC = observer(() => {
    const isBigQuery = useProfileFeatureFlagsIsBigQuery();
    return isBigQuery ? <CompaniesHierarchyIsBigQuery /> : <CompaniesHierarchyIsNotBigQuery />;
});
