import { RouterPagesStore } from '../stores/routerPages';
import { IComponentName, IPage, IRouterState } from '../interfaces';

export function setRouterStoreUseExternalUrlManager(value: boolean): void {
    const RouterPagesStoreInstance = RouterPagesStore.getInstance();
    RouterPagesStoreInstance.setUseExternalUrlManager(value);
}

export function setRouterStoreUseExternalPageTitleManager(value: boolean): void {
    const RouterPagesStoreInstance = RouterPagesStore.getInstance();
    RouterPagesStoreInstance.setUseExternalPageTitleManager(value);
}

export function getRouterStoreCurrentComponent(): IRouterState {
    const RouterPagesStoreInstance = RouterPagesStore.getInstance();
    return RouterPagesStoreInstance.currentComponent;
}

export function setRouterStoreCurrentComponent(component: IRouterState): void {
    const RouterPagesStoreInstance = RouterPagesStore.getInstance();
    RouterPagesStoreInstance.setCurrentComponent(component);
}

export function getRouterStoreRequestedPage(): IRouterState {
    const RouterPagesStoreInstance = RouterPagesStore.getInstance();
    return RouterPagesStoreInstance.requestedPage;
}

export function setRouterStoreRequestedPage(page: IRouterState): void {
    const { routeName, params, queryParams } = page;
    const RouterPagesStoreInstance = RouterPagesStore.getInstance();
    return RouterPagesStoreInstance.setRequestedPage({
        routeName,
        params,
        queryParams: queryParams,
    });
}

export function setRouterStoreRequestedUrl(url: string): void {
    const RouterPagesStoreInstance = RouterPagesStore.getInstance();
    return RouterPagesStoreInstance.setRequestedUrl(url);
}

export function getRouterStoreAvailableComponent(): IRouterState[] {
    const RouterPagesStoreInstance = RouterPagesStore.getInstance();
    return RouterPagesStoreInstance.availableComponents;
}

export function isAvailablePage(page: IComponentName): boolean {
    const RouterPagesStoreInstance = RouterPagesStore.getInstance();
    return !!RouterPagesStoreInstance.availablePages.find((p: IPage) => p.componentName === page);
}
