import React, { ReactNode, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { EditRateCountryForm } from './EditRateCountryForm';
import {
    useApiMessagesLastUpdated,
    useIsCountryListLoadingFinished,
    useRateCountryInitialValues,
} from '../../../hooks';
import { addAlreadyLoaded } from '../../../middleware';

interface IEditRateCountry {
    countryId: string;
    expandedCountryRowKey?: string;
    name: string;
    operatorId: string;
    setExpandedCountryRowKey?: (id: string) => void;
}

export const EditRateCountry: React.FC<IEditRateCountry> = observer(
    ({ countryId, expandedCountryRowKey, name, operatorId, setExpandedCountryRowKey }: IEditRateCountry) => {
        const [countryForm, setCountryForm] = useState<ReactNode>(null);
        const initialValues = useRateCountryInitialValues(countryId, operatorId);
        const isLoadingFinished = useIsCountryListLoadingFinished();

        const initialValuesJSON = JSON.stringify(initialValues);
        const apiMessagesLastUpdated = useApiMessagesLastUpdated();

        useEffect(() => {
            setTimeout(() => {
                setCountryForm(() => (
                    <EditRateCountryForm
                        apiMessagesLastUpdated={apiMessagesLastUpdated}
                        countryName={name}
                        expandedCountryRowKey={expandedCountryRowKey}
                        initialValues={initialValues}
                        key={countryId}
                        setExpandedCountryRowKey={setExpandedCountryRowKey}
                    />
                ));

                if (!isLoadingFinished) {
                    addAlreadyLoaded();
                }
            });
        }, [initialValuesJSON, expandedCountryRowKey, apiMessagesLastUpdated]);

        return <>{countryForm}</>;
    },
);
