import { action, makeObservable, observable } from 'mobx';
import {
    IIncludeLists,
    IIncludeMainItem,
    IIncludeTypeUi,
    IIncludedStoreCurrency,
    IIncludedStoreCurrencyList,
} from '../interfaces';
import { isNewId } from '../instruments';

export class IncludedStore {
    private constructor() {
        makeObservable(this);
    }

    private static instance: IncludedStore;
    public static getInstance(): IncludedStore {
        if (!IncludedStore.instance) {
            IncludedStore.instance = new IncludedStore();
        }
        return IncludedStore.instance;
    }

    @observable currencies: IIncludedStoreCurrencyList = [];
    @action addCurrency(currencyList: IIncludedStoreCurrencyList): void {
        const newCurrencies: IIncludedStoreCurrencyList = [...this.currencies];
        currencyList.forEach((cl: IIncludedStoreCurrency) => {
            if (!newCurrencies.find((nc: IIncludedStoreCurrency) => nc.id === cl.id)) {
                newCurrencies.push(cl);
            }
        });
        this.currencies = newCurrencies;
    }

    @observable lists: IIncludeLists = {};
    @action setList(type: IIncludeTypeUi, list: IIncludeMainItem[]): void {
        this.lists[type] = list;
    }

    @action addListItem(type: IIncludeTypeUi, item: IIncludeMainItem): void {
        this.lists[type] = !!this.lists[type] ? [item, ...this.lists[type]] : [item];
    }

    @action removeListItem(type: IIncludeTypeUi, id: string): void {
        if (isNewId(id)) {
            this.lists[type] = this.lists[type].filter((i) => i.id !== id);
        } else {
            this.lists[type] = this.lists[type].map((i) => {
                if (i.id === id) {
                    return { ...i, destroy: true, isChanged: true };
                } else {
                    return i;
                }
            });
        }
    }

    @action clearList(type: IIncludeTypeUi): void {
        this.lists[type] = [];
    }

    @observable combinationCardSetTotal = 0;
    @action setCombinationCardSetTotalAmount(total: number): void {
        //TODO review this part
        this.combinationCardSetTotal = total;
    }

    @action
    cleanStore(): void {
        this.currencies = [];
        this.currencies = [];
        this.lists = {};
        this.combinationCardSetTotal = 0;
    }
}
