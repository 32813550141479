import React, { MouseEvent } from 'react';
import { observer } from 'mobx-react';
import { Catcher, LoadingApiByIds } from '../../';
import { HorizontalRevenueChart } from './';
import { useDashboardCountryRevenueChart, useProfile, useRouterStore } from '../../../hooks';
import { tI, tP } from '../../../translate';
import Styles from '../m_dashboarStyles.less';

export const CountryRevenue: React.FC = observer(() => {
    const data = useDashboardCountryRevenueChart();
    const routerStore = useRouterStore();
    const { isCompanyTypeIdMNOHost } = useProfile();
    const title = `${tP('Top Countries')} ${tI(isCompanyTypeIdMNOHost ? 'by Revenue' : 'by Cost/Revenue')}`;
    const goToCardList = (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>): void => {
        event.preventDefault();
        routerStore.goTo('CountryList');
    };

    const titleLink = (): JSX.Element => (
        <a onClick={goToCardList} href={`/country`}>
            {title}
        </a>
    );

    const goToCountry = (id: string): void => {
        routerStore.goTo('Country', { params: { id } });
    };

    return (
        <Catcher>
            <LoadingApiByIds idSpinners={['GET_REVENUE_COUNTRY_LIST']} />
            <div className={Styles.chart}>
                <HorizontalRevenueChart
                    sourceData={data}
                    title={title}
                    titleLink={titleLink()}
                    onSelectRow={goToCountry}
                />
            </div>
        </Catcher>
    );
});
