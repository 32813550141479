import React from 'react';
import { observer } from 'mobx-react';
import { Checkbox, Divider, Form } from 'antd';
import { Rule } from 'antd/es/form';
import { CardLayout, Catcher, FormSelect, InputDebounce, cardLayoutOptions } from '../../';
import { IIntervalTypeItem, appConfig } from '../../../settings';
import { ISelectList } from '../../../interfaces';
import {
    useCatalogPaymentTypesSelect,
    useCatalogSessionGrantedTypesSelect,
    useCompanyInfo,
    useCurrencyCodeById,
    useCurrencySelect,
    useProfile,
    useProfileIsRight,
} from '../../../hooks';
import {
    apiMessagesGetFormRules,
    rulesDividend,
    rulesInList,
    rulesIsNumber,
    rulesNumberMinMax,
} from '../../../middleware';
import { tF, tI, tV } from '../../../translate';

interface ISessionGrantedTypeSelector {
    currency?: string;
    dataTest?: string;
}
export const SessionGrantedTypeSelector: React.FC<ISessionGrantedTypeSelector> = observer(
    ({ currency, dataTest = '' }: ISessionGrantedTypeSelector) => {
        const selectList = useCatalogSessionGrantedTypesSelect(currency);
        const sessionGrantedTypesSelectHook = (): ISelectList => selectList;

        return (
            <Catcher>
                <FormSelect
                    dataTest={dataTest}
                    fieldName={'sessionGrantedTypeId'}
                    isAddonBefore={true}
                    isNotClear={true}
                    rules={apiMessagesGetFormRules}
                    useSelectHook={sessionGrantedTypesSelectHook}
                />
            </Catcher>
        );
    },
);

interface IEditCompanyFinancial {
    checkValid: () => void;
}

export const EditCompanyFinancial: React.FC<IEditCompanyFinancial> = observer(
    // ({ checkValid }: IEditCompanyFinancial) => {
    () => {
        const paymentTypeSelectHook = useCatalogPaymentTypesSelect;
        const currencySelect = useCurrencySelect;
        const { currencyId, id } = useCompanyInfo();

        const currency = useCurrencyCodeById(currencyId);
        const isLord = useProfileIsRight('LORD');
        const isAdmin = useProfileIsRight('ADMIN');
        const myCompanyId = useProfile().companyId;

        const validateNumber: Array<Rule> = [rulesIsNumber, ...apiMessagesGetFormRules];

        const validateAPIAndCurrency: Array<Rule> = [
            { required: true, message: tV('Please select Company Currency.') },
            ...apiMessagesGetFormRules,
        ];
        const validateAPIAndPaymentType: Array<Rule> = [
            { required: true, message: tV('Please select Payment Type.') },
            ...apiMessagesGetFormRules,
        ];
        const validateAPIAndBalanceLimit: Array<Rule> = [
            { required: true, message: tV('Please input Balance Limit.') },
            ...validateNumber,
        ];

        const validateCDRInterval: Array<Rule> = [
            { required: true, message: tV('Please input CDR Interval.') },
            rulesIsNumber,
            ({ getFieldValue }): Rule => {
                const type = getFieldValue('cdrSquashIntervalType');
                const maxValue =
                    appConfig.intervalCDRTypeSelect.find((i: IIntervalTypeItem) => i.type === type)?.maxValue || 60;
                return {
                    ...rulesNumberMinMax(0, maxValue),
                };
            },
            ({ getFieldValue }) => {
                const type = getFieldValue('cdrSquashIntervalType');
                const interval = appConfig.intervalCDRTypeSelect.find((i: IIntervalTypeItem) => i.type === type);
                return {
                    ...rulesDividend(interval?.dividend, interval?.name),
                };
            },
            ({ getFieldValue }) => {
                const type = getFieldValue('cdrSquashIntervalType');
                const interval = appConfig.intervalCDRTypeSelect.find((i: IIntervalTypeItem) => i.type === type);
                return {
                    ...rulesInList(
                        interval?.listValues?.map((i) => i.toString()),
                        interval?.name,
                    ),
                };
            },
            ...apiMessagesGetFormRules,
        ];

        return (
            <Catcher>
                <CardLayout
                    title={'Financial'}
                    idSpinners={['GET_COMPANY']}
                    columnOption={cardLayoutOptions.cardEditCompany}
                    dataTest={'EditCompanyFinancial'}
                >
                    <FormSelect
                        fieldName={'currencyId'}
                        label={tF('Company Currency')}
                        rules={validateAPIAndCurrency}
                        useSelectHook={currencySelect}
                        dataTest={'EditCompanyFinancialCurrency'}
                    />
                    <FormSelect
                        fieldName={'paymentTypeId'}
                        label={tF('Payment type')}
                        rules={validateAPIAndPaymentType}
                        useSelectHook={paymentTypeSelectHook}
                        dataTest={'EditCompanyFinancialPaymentType'}
                    />
                    <Form.Item
                        name="spendingLimit"
                        label={`${tF('Balance limit')} (${tI('Should be negative for Post-Paid')})`}
                        rules={validateAPIAndBalanceLimit}
                        data-test={'EditCompanyFinancialSpendingLimit'}
                    >
                        <InputDebounce
                            addonBefore={currency}
                            placeholder={tF('Balance limit')}
                            data-test={'EditCompanyFinancialSpendingLimitInput'}
                        />
                    </Form.Item>
                    <Form.Item
                        name="thresholdMedium"
                        label={`${tF('1st balance warning')} (${tI('Should be negative for Post-Paid')})`}
                        rules={validateNumber}
                        data-test={'EditCompanyFinancialThresholdMedium'}
                    >
                        <InputDebounce
                            addonBefore={currency}
                            placeholder={tF('1st balance warning')}
                            data-test={'EditCompanyFinancialThresholdMediumInput'}
                        />
                    </Form.Item>
                    <Form.Item
                        name="thresholdHigh"
                        label={`${tF('2nd balance warning')} (${tI('Should be negative for Post-Paid')})`}
                        rules={validateNumber}
                        data-test={'EditCompanyFinancialThresholdHigh'}
                    >
                        <InputDebounce
                            addonBefore={currency}
                            placeholder={tF('2nd balance warning')}
                            data-test={'EditCompanyFinancialThresholdHighInput'}
                        />
                    </Form.Item>
                    {isLord || (isAdmin && id !== myCompanyId) ? (
                        <>
                            <Form.Item
                                name="sessionValidityTime"
                                label={`${tF('Session Validity Time')} (${tI('minutes')})`}
                                rules={validateNumber}
                                data-test={'EditCompanyFinancialSessionValidityTime'}
                            >
                                <InputDebounce
                                    placeholder={tF('Session Validity Time')}
                                    data-test={'EditCompanyFinancialSessionValidityTimeInput'}
                                />
                            </Form.Item>
                            <Form.Item
                                name="sessionGrantedUnit"
                                label={tF('Session Granted Unit')}
                                rules={validateNumber}
                                data-test={'EditCompanyFinancialSessionGrantedUnit'}
                            >
                                <InputDebounce
                                    addonBefore={
                                        <SessionGrantedTypeSelector
                                            dataTest={'EditCompanyFinancialSessionGrantedUnit'}
                                            currency={currency}
                                        />
                                    }
                                    placeholder={tF('Session Granted Unit')}
                                    data-test={'EditCompanyFinancialSessionGrantedUnitInput'}
                                />
                            </Form.Item>
                        </>
                    ) : null}
                    {isLord ? (
                        <>
                            <Divider />
                            <Form.Item
                                name="cdrSquashIntervalValue"
                                label={`${tF('CDR interval')}`}
                                dependencies={['cdrSquashIntervalType']}
                                rules={validateCDRInterval}
                                data-test={'EditCompanyFinancialCdrSquashInterval'}
                            >
                                <InputDebounce
                                    addonBefore={
                                        // <IntervalTypeSelect
                                        //     data-test={'EditCompanyFinancialCdrSquashIntervalType'}
                                        //     name={'cdrSquashIntervalType'}
                                        //     rules={apiMessagesGetFormRules}
                                        //     onChange={(): void => checkValid()}
                                        // />
                                        'hours'
                                    }
                                    placeholder={tF('CDR interval')}
                                />
                            </Form.Item>
                        </>
                    ) : null}
                    {isLord && id ? (
                        <>
                            <Divider />
                            <Form.Item
                                name="zeroCostData"
                                valuePropName="checked"
                                rules={apiMessagesGetFormRules}
                                data-test={'EditCompanyFinancialZeroCostData'}
                            >
                                <Checkbox data-test={'EditCompanyFinancialZeroCostDataCheckBox'}>
                                    {tF('Zero cost Data')}
                                </Checkbox>
                            </Form.Item>
                            <Form.Item
                                name="zeroCostSms"
                                valuePropName="checked"
                                rules={apiMessagesGetFormRules}
                                data-test={'EditCompanyFinancialZeroCostSms'}
                            >
                                <Checkbox data-test={'EditCompanyFinancialZeroCostSmsCheckbox'}>
                                    {tF('Zero cost SMS')}
                                </Checkbox>
                            </Form.Item>
                            <Form.Item
                                name="zeroCostVoice"
                                valuePropName="checked"
                                rules={apiMessagesGetFormRules}
                                data-test={'EditCompanyFinancialZeroCostVoice'}
                            >
                                <Checkbox data-test={'EditCompanyFinancialZeroCostVoiceCheckBox'}>
                                    {tF('Zero cost Voice')}
                                </Checkbox>
                            </Form.Item>
                        </>
                    ) : null}
                </CardLayout>
            </Catcher>
        );
    },
);
