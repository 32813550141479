import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Divider } from 'antd';
import { Catcher, FilterUrlManager } from '../';
import { usePackagesForSaleTotal, usePackagesSoldTotal } from '../../hooks';
import {
    cleanPackageSoldRelationSelect,
    cleanPackagesStore,
    getBoughtPackages,
    getPackagesToBuy,
    setPackagesForcedUpdateTime,
} from '../../middleware';
import { PackageTableComponent, TableForBought, TableForBuy } from './Components';
import Styles from '../styles/m_viewStyles.less';

export const PackagesBuy: React.FC = observer(() => {
    useEffect(() => {
        return (): void => {
            cleanPackagesStore();
        };
    }, []);

    return (
        <Catcher>
            <FilterUrlManager
                cleanStore={cleanPackageSoldRelationSelect}
                setForcedUpdateTime={setPackagesForcedUpdateTime}
            />
            <div className={Styles.main}>
                <div className={Styles.content}>
                    <PackageTableComponent
                        Table={TableForBuy}
                        useTotalHook={usePackagesForSaleTotal}
                        fetchRequest={getPackagesToBuy}
                        title={'Packages Available to Buy'}
                    />
                    <Divider />
                    <PackageTableComponent
                        Table={TableForBought}
                        useTotalHook={usePackagesSoldTotal}
                        fetchRequest={getBoughtPackages}
                        title={'My Bought Packages'}
                    />
                </div>
            </div>
        </Catcher>
    );
});
