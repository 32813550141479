import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Form } from 'antd';
import { Rule, RuleObject } from 'antd/es/form';
import { BulbOutlined, FieldNumberOutlined, NumberOutlined, OrderedListOutlined } from '@ant-design/icons';
import {
    CardInfoToolBar,
    CardMainLayout,
    Catcher,
    FieldInfoAdvanced,
    TextAreaDebounce,
    cardLayoutOptions,
} from '../../';
import {
    GetExternalAPICommand,
    IFieldLayout,
    IFormFieldValue,
    IFormValidatorResponse,
    IGetApiType,
} from '../../../interfaces';
import { useExternalAPINumber, useSubscriberInfo } from '../../../hooks';
import { externalAPINumber, getExternalAPINumbers } from '../../../middleware';
import { ITrField } from '../../../translate';
import Styles from '../../styles/m_itemCardStyles.less';

type IFormValues = {
    numbers?: string;
};

const loadingTypes: IGetApiType[] = ['POST_SUBSCRIBERS_API_REQUEST'];

const commaStrings2ArrayStrings = (str: string): Array<string> =>
    str
        .split(',')
        .map((i: string) => i.trim())
        .filter((i: string) => !!i);

const ruleIsNumbersArray: Array<Rule> = [
    (): RuleObject => ({
        message: 'Check numbers',
        validator(_: RuleObject, value: IFormFieldValue): IFormValidatorResponse {
            let isValid = true;
            const arr = commaStrings2ArrayStrings(value);
            arr.forEach((i: string) => {
                if (Number(i).toString() !== i) {
                    isValid = false;
                    console.warn(`"${i}" is not a number`);
                }
            });

            if (isValid) {
                return Promise.resolve();
            } else {
                return Promise.reject();
            }
        },
    }),
];

type ICheckNumbersStringsProps = {
    currentValue?: string;
    initValue?: string;
};
type ICheckNumbersStrings = {
    newItem: Array<string>;
    deletedItem: Array<string>;
    isChanged: boolean;
};
const checkNumbersStrings = ({
    currentValue = '',
    initValue = '',
}: ICheckNumbersStringsProps): ICheckNumbersStrings => {
    const newItem: Array<string> = [];
    const deletedItem: Array<string> = [];
    let isChanged = false;
    const initValues = commaStrings2ArrayStrings(initValue);
    const currentValues = commaStrings2ArrayStrings(currentValue);
    currentValues.forEach((i: string) => {
        if (!initValues?.includes(i)) {
            isChanged = true;
            newItem.push(i);
        }
    });
    initValues.forEach((i: string) => {
        if (!currentValues?.includes(i)) {
            isChanged = true;
            deletedItem.push(i);
        }
    });

    return { newItem, deletedItem, isChanged };
};

export const Numbers: React.FC = observer(() => {
    const layout: IFieldLayout = {
        labelCol: {
            flex: '0 1 195px',
        },
        valCol: {
            flex: '1 1 270px',
        },
    };

    const [isEditMode, setEditMode] = useState<boolean | undefined>(false);
    const [isChanged, setChanged] = useState<boolean>(false);
    const [isValid, setValid] = useState<boolean>(false);
    const [isSending, setSending] = useState<boolean>(false);
    const [form] = Form.useForm();
    const { id: subscriberId } = useSubscriberInfo();
    const numbersInfo = useExternalAPINumber();
    const initialNumbersString = numbersInfo.numbers?.join(', ');
    const initialValues: IFormValues = {
        numbers: initialNumbersString,
    };

    const onFinish = async ({ numbers }: IFormValues) => {
        setSending(true);
        const {
            isChanged: isFormChanged,
            deletedItem,
            newItem,
        } = checkNumbersStrings({ currentValue: numbers, initValue: initialNumbersString });
        if (isValid && isChanged && isFormChanged) {
            for (const item of deletedItem) {
                await externalAPINumber({
                    subscriberId,
                    number: Number(item),
                    command: GetExternalAPICommand.DELETE_NUMBER,
                });
            }
            for (const item of newItem) {
                await externalAPINumber({
                    subscriberId,
                    number: Number(item),
                    command: GetExternalAPICommand.ADD_NUMBER,
                });
            }
        }
        await getExternalAPINumbers(subscriberId);
        setSending(false);
        setEditMode(false);
    };

    const onValuesChange = async ({ numbers }: IFormValues) => {
        form.validateFields().then(
            () => {
                setValid(true);
            },
            () => {
                setValid(!form.getFieldsError().filter(({ errors }) => errors.length).length);
            },
        );
        const { isChanged } = checkNumbersStrings({ currentValue: numbers, initValue: initialNumbersString });
        setChanged(isChanged);
    };
    const resetForm = () => {
        form.resetFields();
        setChanged(false);
    };

    useEffect(() => {
        subscriberId && getExternalAPINumbers(subscriberId);
    }, [subscriberId]);

    useEffect(() => {
        form.resetFields();
        setChanged(false);
    }, [initialNumbersString]);

    return (
        <Catcher>
            <CardMainLayout
                columnOption={cardLayoutOptions.cardSubscribersInfo}
                icon={<OrderedListOutlined />}
                idSpinners={loadingTypes}
                isNotDocumentTitle
                name={'Mobile numbers'}
            >
                <Form
                    form={form}
                    name={'numbersForm'}
                    initialValues={initialValues}
                    onFinish={onFinish}
                    onValuesChange={onValuesChange}
                    layout={'horizontal'}
                    className={'formStyle'}
                >
                    <FieldInfoAdvanced
                        icon={<NumberOutlined className={Styles.labelIcon} />}
                        layout={layout}
                        title={'Account Id' as ITrField}
                    >
                        {numbersInfo.accountId}
                    </FieldInfoAdvanced>
                    <FieldInfoAdvanced
                        icon={<FieldNumberOutlined className={Styles.labelIcon} />}
                        layout={layout}
                        title={'Account Number' as ITrField}
                    >
                        {numbersInfo.accountNumber}
                    </FieldInfoAdvanced>
                    <FieldInfoAdvanced
                        icon={<BulbOutlined className={Styles.labelIcon} />}
                        layout={layout}
                        title={'Number List Request Id' as ITrField}
                    >
                        {numbersInfo.numberListRequestId}
                    </FieldInfoAdvanced>
                    <FieldInfoAdvanced
                        icon={<OrderedListOutlined className={Styles.labelIcon} />}
                        isFieldTextArea={isEditMode}
                        layout={layout}
                        title={'Mobile numbers' as ITrField}
                    >
                        {isEditMode ? (
                            <>
                                <Form.Item name={'numbers'} rules={ruleIsNumbersArray}>
                                    <TextAreaDebounce
                                        placeholder={'Mobile numbers (example: 97000001597, 97000002372, ....)'}
                                        disabled={isSending}
                                    />
                                </Form.Item>
                            </>
                        ) : (
                            <div className={Styles.listWrap}>
                                {numbersInfo.numbers?.length
                                    ? numbersInfo.numbers.map((item, index) => (
                                          <span key={index} className={Styles.listItem}>
                                              {item}
                                          </span>
                                      ))
                                    : null}
                            </div>
                        )}
                    </FieldInfoAdvanced>
                    <CardInfoToolBar
                        isDisabledCancel={isSending}
                        isDisabledSave={!isValid || !isChanged || isSending}
                        isHideCancel={!isEditMode}
                        isSubmitButton={isEditMode}
                        loadingTypes={loadingTypes}
                        onCancel={() => setEditMode(false)}
                        setEditMode={isEditMode ? undefined : setEditMode}
                    >
                        {isEditMode ? (
                            <Button htmlType="reset" onClick={resetForm} disabled={!isChanged || isSending}>
                                Reset
                            </Button>
                        ) : null}
                    </CardInfoToolBar>
                </Form>
            </CardMainLayout>
        </Catcher>
    );
});
