import React, { MouseEvent } from 'react';
import { observer } from 'mobx-react';
import { Catcher, LoadingApiByTypeAndUnit } from '../../';
import { HorizontalUsageChart } from './';
import { DashboardUnitTypes, ICartItemData } from '../../../interfaces';
import {
    useDashboardCardSetUsageChart,
    useDashboardGraphTitleByUnitType,
    useDashboardUnitTypeObj,
    useRouterStore,
} from '../../../hooks';
import { tP } from '../../../translate';
import Styles from '../m_dashboarStyles.less';

interface ICardSetUsage {
    useDashboardUsageData?: () => ICartItemData[];
    unitType?: DashboardUnitTypes;
}

export const CardSetUsage: React.FC<ICardSetUsage> = observer(
    ({ unitType = DashboardUnitTypes.DATA }: ICardSetUsage) => {
        const unitTypeObj = useDashboardUnitTypeObj(unitType);
        const subTitle = useDashboardGraphTitleByUnitType(unitTypeObj?.name || '');
        const title = `${tP('Top Card Sets')} ${subTitle}`;
        const apiTypeId = `GET_USAGE_CARD_SETS_LIST`;
        const data = useDashboardCardSetUsageChart(apiTypeId, unitTypeObj);
        const routerStore = useRouterStore();
        const goToCardList = (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>): void => {
            event.preventDefault();
            routerStore.goTo('CardSetsList');
        };

        const titleLink = (): JSX.Element => (
            <a onClick={goToCardList} href={`/card_sets`}>
                {title}
            </a>
        );

        const goToCardSet = (id: string): void => {
            routerStore.goTo('CardSet', { params: { id } });
        };

        return (
            <Catcher>
                <LoadingApiByTypeAndUnit apiTypeId={apiTypeId} unitTypeId={unitTypeObj?.id} />
                <div className={Styles.chart}>
                    <HorizontalUsageChart
                        sourceData={data}
                        title={title}
                        titleLink={titleLink()}
                        unitTypeObj={unitTypeObj}
                        onSelectRow={goToCardSet}
                    />
                </div>
            </Catcher>
        );
    },
);
