import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import {
    ICardDeviceFile,
    ICardGeolocationInfo,
    ICardInfo,
    ICardLocationMapInfo,
    ICardStatistics,
    ICardSubscriber,
    IDeviceAttribute,
    IDeviceHistory,
} from '../../interfaces';
import { byteAccuredConvertion, byteRange1000, isObjectEmpty, minutesRange, smsRange } from '../../instruments';
import { tCustom, tF } from '../../translate';

export function useCardInfo(): ICardInfo {
    return useContext(MobXProviderContext).RootStore.cardStore.cardInfo;
}

export function useCardCardId(): string {
    const cardInfo = useContext(MobXProviderContext).RootStore.cardStore.cardInfo;
    return cardInfo.id;
}

export function useCardStatistics(): ICardStatistics {
    const cardInfo = useCardInfo();
    const statistics: ICardStatistics = useContext(MobXProviderContext).RootStore.cardStore.statistics;
    const usageLimit = byteAccuredConvertion((cardInfo?.usageLimit ?? 0) * 1000);
    const cost = (statistics?.cost as number)?.toFixed(2) || 0;
    const byte = byteRange1000(statistics?.byte);
    const sec = minutesRange(statistics?.sec);
    const sms = smsRange(statistics?.sms);

    return {
        cost,
        byte,
        sec,
        sms,
        usageLimit,
    };
}

export function useCardDeviceInfo(): IDeviceAttribute {
    return useContext(MobXProviderContext).RootStore.cardStore.deviceInfo;
}

export function useCardSubscribers(): ICardSubscriber[] {
    return useContext(MobXProviderContext).RootStore.cardStore.subscribers;
}

export function useGeolocation(): ICardGeolocationInfo {
    return useContext(MobXProviderContext).RootStore.cardStore.geolocationInfo;
}

export function useIsGeolocationEnabled(): boolean {
    const geolocationInfo = useGeolocation();
    return !isObjectEmpty(geolocationInfo);
}

export function useCardLocationList(): ICardLocationMapInfo[] {
    const geolocationInfo = useGeolocation();
    const cardInfo = useCardInfo();
    return [
        {
            id: cardInfo?.id,
            name: cardInfo?.name || cardInfo?.id || tF('Unknown'),
            lng: geolocationInfo?.location?.y || 0,
            lat: geolocationInfo?.location?.x || 0,
        },
    ];
}

export function useGeolocationString(): string {
    const geolocationInfo = useGeolocation();
    if (!useIsGeolocationEnabled()) {
        return '';
    }
    const options = {
        lac: geolocationInfo?.lac,
        cid: geolocationInfo?.cid,
        accuracy: geolocationInfo?.accuracy,
        x: geolocationInfo?.location?.x,
        y: geolocationInfo?.location?.y,
    };

    return tCustom('Geolocation info', options);
}

export function useDeviceHistory(): IDeviceHistory {
    return useContext(MobXProviderContext).RootStore.cardStore.deviceHistory;
}

export function useDeviceHistoryTop5(): IDeviceHistory {
    return useDeviceHistory()?.slice(0, 5);
}

export function useCardDeviceFilesList(): ICardDeviceFile[] {
    return useContext(MobXProviderContext).RootStore.cardStore.cardDeviceFilesList;
}
