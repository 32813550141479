import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { IOrderExternalStatus, IOrdersListItem } from '../../interfaces';
import { useOrdersTable, useProfile, useRouterStore } from '../../hooks';
import { addNotification, getOrdersList, isAvailablePage } from '../../middleware';
import { tNM } from '../../translate';

export const OrderExternal: React.FC = observer(() => {
    const [status, setStatus] = useState<IOrderExternalStatus>('INIT');
    const [foundOrder, setFoundOrder] = useState<IOrdersListItem>({ id: '' });
    const { companyId: myCompanyId } = useProfile();
    const routerStore = useRouterStore();
    const list = useOrdersTable();

    const getOrders = async (orderId: string): Promise<void> => {
        const ordersCount = await getOrdersList({ transactionId: orderId });
        if (!!ordersCount) {
            setStatus('IS_FOUND');
        } else {
            setStatus('NOT_FOUND');
        }
    };

    useEffect(() => {
        const { queryParams } = routerStore.routerState;
        const { orderId } = queryParams;
        if (orderId) {
            setStatus('FOUND_ORDER');
            getOrders(orderId).then();
        } else {
            setStatus('NOT_GET_ORDER_ID');
        }
    }, []);

    useEffect(() => {
        switch (status) {
            case 'NOT_GET_ORDER_ID':
            case 'NOT_FOUND':
                console.warn('Order not found. Status ' + status);
                addNotification({
                    type: 'error',
                    message: tNM(`Order not found`),
                    description: status,
                    duration: 5,
                    isTranslated: true,
                    isUsersError: true,
                });
                if (myCompanyId) {
                    routerStore.goTo('Company', { params: { id: myCompanyId } });
                } else {
                    console.warn(status, { ...foundOrder });
                }
                break;
            case 'IS_FOUND':
                if (!!list.length) {
                    const foundOrder = list[0];
                    setFoundOrder(foundOrder);
                }
                break;
            default:
                break;
        }
    }, [status, list.length]);

    useEffect(() => {
        if (foundOrder?.id) {
            if (isAvailablePage('OrderView')) {
                routerStore.goTo('OrderView', { params: { id: foundOrder.id } });
            } else if (foundOrder?.companyId) {
                routerStore.goTo('Company', { params: { id: foundOrder.companyId } });
            } else if (myCompanyId) {
                routerStore.goTo('Company', { params: { id: myCompanyId } });
            } else {
                console.warn(status, { ...foundOrder });
                addNotification({
                    type: 'error',
                    message: status,
                    // ToDo: 03.05.2023 -  description: { ...foundOrder },
                    duration: 5,
                    isTranslated: true,
                    isUsersError: true,
                });
            }
        }
    }, [foundOrder]);

    return null;
});
