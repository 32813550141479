import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Button, Upload } from 'antd';
import { UploadListType } from 'antd/lib/upload/interface';
import { UploadOutlined } from '@ant-design/icons';
import { Catcher } from '../';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { IGetApiResponse, IGetApiType, IRequestAPIBody, apiManager, restApi } from '../../REST';
import { IDivMainProps } from '../../interfaces';
import { useUiIsLoading, useUiIsSpinnerFound } from '../../hooks';
import { addNotification } from '../../middleware';
import { ITrButton, tB, tNM } from '../../translate';

type IUploadFile = IDivMainProps & {
    accept?: string;
    apiType: IGetApiType;
    body?: IRequestAPIBody;
    customOnLoadFile?: (loaded: UploadRequestOption) => Promise<void>;
    disabled?: boolean;
    fileType?: UploadListType;
    id?: string;
    idSpinners?: IGetApiType[];
    onDownload?: (resp: IGetApiResponse) => void;
    setFile?: (file: File) => void;
    text?: ITrButton;
};

export const UploadFile: React.FC<IUploadFile> = observer(
    ({
        accept = '',
        apiType,
        body,
        className,
        customOnLoadFile,
        disabled,
        fileType = 'text',
        id,
        idSpinners,
        onDownload,
        setFile,
        text,
    }: IUploadFile) => {
        const isLoadingAPI = idSpinners ? useUiIsSpinnerFound(idSpinners) : useUiIsLoading();

        const abortFetch = (): void => {
            restApi.fetchAbort(apiType).then();
        };
        useEffect(() => {
            return abortFetch();
        }, []);

        const onLoadFile = async (loaded: UploadRequestOption): Promise<void> => {
            try {
                const file: File = loaded.file as File;
                const apiResponse = await apiManager.getApi(apiType, { id: id }, body, file);
                onDownload && onDownload(apiResponse);
                setFile && setFile(file);
            } catch (e) {
                addNotification({
                    type: 'error',
                    message: tNM('Error load file!'),
                    description: JSON.stringify(e),
                    duration: 0,
                    isTranslated: true,
                    isUsersError: true,
                });
            }
        };
        return (
            <Catcher>
                <Upload
                    accept={accept}
                    listType={fileType}
                    customRequest={customOnLoadFile ?? onLoadFile}
                    showUploadList={false}
                    multiple={false}
                    disabled={isLoadingAPI || disabled}
                    className={className}
                >
                    <Button icon={<UploadOutlined />} loading={isLoadingAPI} data-test="UploadFileButton">
                        {text ? tB(text) : `${tB('Upload')} ${fileType === 'text' ? 'CSV' : 'Img'}`}
                    </Button>
                </Upload>
            </Catcher>
        );
    },
);
