import React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import { ICompaniesListItem } from '../../../interfaces';
import { useCompaniesTable, useProfileIsRight, useUiIsLoading, useUiSearch, useUiWithDeleted } from '../../../hooks';
import { addNotification, deleteCompany } from '../../../middleware';
import { lightText } from '../../../instruments';
import { tCustom, tF, tI, tNM } from '../../../translate';
import {
    Catcher,
    LinkComponent,
    TableItemDeleteColumn,
    TableItemToolbar,
    columnDeleted,
    columnToolbar,
} from '../../../Components';
import Styles from '../../styles/m_viewStyles.less';

export const CompaniesTableList: React.FC = observer(() => {
    const isLoading = useUiIsLoading();
    const companiesList = useCompaniesTable();
    const isLord = useProfileIsRight('LORD');
    const withDeleted = useUiWithDeleted();
    const search = useUiSearch();

    const tableColumns = [
        {
            title: tF('Name'),
            dataIndex: 'linkName',
        },
        {
            title: tF('Company Type'),
            dataIndex: 'companyTypeName',
        },
        {
            title: tF('Parent Company'),
            dataIndex: 'companyParentLink',
        },
        {
            title: tF('Balance'),
            dataIndex: 'balance',
            className: Styles.columnRight,
        },
        {
            title: tF('Payment type'),
            dataIndex: 'paymentTypeId',
        },
        {
            title: tF('Cards'),
            dataIndex: 'cardsCount',
            className: Styles.columnRight,
        },
        columnDeleted(isLord && withDeleted),
        columnToolbar,
    ];
    const tableData = companiesList.map((c: ICompaniesListItem) => {
        const deleteItem = async (): Promise<void> => {
            const { isError } = await deleteCompany(c.id);

            if (isError) {
                addNotification({
                    type: 'error',
                    message: tNM('Deletion error!'),
                    description: tCustom(
                        'Company can not be deleted since there are cards and/or users assigned to this company. Please unassigned the cards and/or users before proceeding to delete the company.',
                        { companyName: c.name },
                    ),
                    duration: 5,
                    isTranslated: true,
                });
            }
        };
        const name = c.name ? c.name : `# ${c.id}`;

        return {
            key: c.id,
            ...c,
            linkName: (
                <LinkComponent
                    currentListComponent={'CompaniesList'}
                    id={c.id}
                    name={c.name}
                    isLight
                    deletedAt={c.deletedAt}
                />
            ),
            companyTypeName: lightText(c.companyTypeName, search),
            companyParentLink: (
                <LinkComponent
                    currentListComponent={'CompaniesList'}
                    deletedAt={c.deletedAt}
                    id={c.companyParentId}
                    isLight
                    name={c.companyParentName}
                />
            ),
            deleted: <TableItemDeleteColumn deletedAt={c.deletedAt} />,
            toolbar: (
                <TableItemToolbar
                    itemName={`"${name}" ${tI('company')}`}
                    id={c.id}
                    deleteItem={deleteItem}
                    deletedAt={c.deletedAt}
                />
            ),
            balance: (
                <span
                    className={
                        (Number(c.balance) > 0 && c.paymentTypeId?.toLowerCase().includes('post-paid')) ||
                        (Number(c.balance) < 0 && c.paymentTypeId?.toLowerCase().includes('pre-paid'))
                            ? Styles.redItem
                            : ''
                    }
                >
                    {c.balance}
                </span>
            ),
        };
    });

    return (
        <Catcher>
            <div className={Styles.tablePlace}>
                <Table
                    dataSource={tableData}
                    columns={tableColumns}
                    pagination={false}
                    className={'table-theme'}
                    size={'middle'}
                    loading={isLoading}
                />
            </div>
        </Catcher>
    );
});
