import React, { MouseEvent } from 'react';
import Styles from './m_styles.less';

interface ILinkComponent {
    name?: string | null;
    onClick: () => void;
}

export const GoComponent: React.FC<ILinkComponent> = ({ name = '', onClick }: ILinkComponent) => {
    const goTo = (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>): void => {
        event.preventDefault();
        onClick();
    };
    return (
        <a onClick={goTo} href={'#'} className={Styles.linkItem}>
            {name}
        </a>
    );
};
