import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Catcher } from '../';
import { history } from '../../Pages/history';
import { IGetApiType } from '../../REST';
import { IShowFormComponent } from '../../interfaces';
import { useCompanyInfo, useCustomerPrivacyCheck, useProfile, useRouterStore } from '../../hooks';
import {
    abortSelectedFetch,
    addNotification,
    cleanCompanyRelationSelect,
    cleanCompanyStore,
    getCompanyById,
    getCompanyRelationSelect,
    getCompanyRelationSelectById,
} from '../../middleware';
import { tI, tNM } from '../../translate';
import { EditCompanyForm, ShowCompany } from './Components';

interface ICompanyProps extends IShowFormComponent {
    isMe?: boolean;
}

export const Company: React.FC<ICompanyProps> = observer(({ isMe, isEdit, isNew }: ICompanyProps) => {
    const routerStore = useRouterStore();
    const [id, setId] = useState('');
    const { customerPrivacy = true } = useCompanyInfo();
    const isAvailable = useCustomerPrivacyCheck(customerPrivacy);
    const myCompanyId = useProfile().companyId || '';

    const usedApiTypes: IGetApiType[] = [
        'GET_COMPANIES_SELECT',
        'GET_COMPANY',
        'GET_COMPANY_TYPES_SELECT',
        'GET_COUNTRIES_SELECT',
        'GET_CURRENCY_SELECT',
        'GET_PAYMENT_SELECT',
        'GET_SALES_RATES_SELECT',
        'GET_USERS_BY_COMPANY_SELECT',
        'GET_FEATURE_FLAG_RULES',
    ];

    const goOut = (): void => {
        abortSelectedFetch(usedApiTypes);
        cleanCompanyRelationSelect();
        cleanCompanyStore();
    };

    const goToList = (): void => {
        routerStore.goTo('CompaniesList');
        goOut();
    };

    const _getCompanyById = async (id: string, _isEdit = false): Promise<void> => {
        const isGetCompany = await getCompanyById(id);
        if (!isGetCompany) {
            addNotification({
                type: 'error',
                message: tNM('Not Found'),
                description: `${tI('Company id')} - "${id}" ${tI('not found')}`,
                duration: 5,
                isTranslated: true,
                isUsersError: true,
            });
            goToList();
        } else if (_isEdit) {
            getCompanyRelationSelectById(id);
        }
    };

    const setEdit = (isEdit?: boolean): void => {
        if (isAvailable && !isNew && isEdit) {
            addNotification({
                type: 'warning',
                message: 'Not authorized!',
                description: `Your company has not been granted permission to view this company information`,
                duration: 5,
            });
        } else if (isNew) {
            goToList();
        } else if (isEdit) {
            _getCompanyById(id, true);
            if (isMe || id === myCompanyId) {
                routerStore.goTo('MyCompanyEdit');
            } else {
                routerStore.goTo('CompanyEdit', { params: { id } });
            }
        } else {
            _getCompanyById(id);
            if (isMe || id === myCompanyId) {
                routerStore.goTo('MyCompany');
            } else {
                routerStore.goTo('Company', { params: { id } });
            }
        }
    };

    useEffect(() => {
        if (isEdit || isNew) {
            getCompanyRelationSelect();
        }
        return (): void => {
            goOut();
        };
    }, []);

    useEffect(() => {
        if (id) {
            _getCompanyById(id, isEdit);
        }
    }, [id, isEdit]);

    useEffect(() => {
        if (
            routerStore.routerState.routeName === 'MyCompany' ||
            routerStore.routerState.routeName === 'MyCompanyEdit'
        ) {
            setId(myCompanyId);
        } else if (
            routerStore.routerState.params.id &&
            (routerStore.routerState.routeName === 'Company' || routerStore.routerState.routeName === 'CompanyEdit')
        ) {
            setId(routerStore.routerState.params.id);
        }
    }, [routerStore.routerState.routeName, routerStore.routerState.params.id]);

    useEffect(() => {
        if (id && id === myCompanyId) {
            if (isEdit) {
                history.replace('/my_company/edit');
            } else {
                history.replace('/my_company');
            }
        }
    }, [id]);

    return (
        <Catcher>
            {isEdit || isNew ? (
                <EditCompanyForm setEditMode={setEdit} isNew={isNew} />
            ) : (
                <ShowCompany setEditMode={setEdit} />
            )}
        </Catcher>
    );
});
