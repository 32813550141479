import React, { ReactNode, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Spin } from 'antd';
import CurrentPositionMarker from '../../../static/img/pin.svg';
import WiFiMarker from '../../../static/img/wifi-access-marker.svg';
import BaseMap from './BaseMap';
import { DirectionsRenderer, Polyline } from '@react-google-maps/api';
import { appConfig } from '../../../settings';
import {
    IGeoLocation,
    IGeotrackingCardLocationHistoryItem,
    IGeotrackingCardLocationHistoryList,
    IRoureResult,
} from '../../../interfaces';
import {
    useGeotrackingCardHistoryLocation,
    useGeotrackingCardIsMakingRoutes,
    useGeotrackingCardRoutesList,
    useGeotrackingCardRoutesPlainList,
    useIsUpdatingTrackingInfo,
} from '../../../hooks';
import { getCenter, getZoom, isRealLocation } from '../../../instruments';
import { tF, tI } from '../../../translate';
import { CardLayout, Catcher, IComponentWithIdSpinners, Loading, cardLayoutOptions } from '../../../Components';
import { MarkerWithInfoBox } from '../Components';
import Styles from '../m_googleMapsStyles.less';

declare const google: any;

export const GeoTrackingData: React.FC<IComponentWithIdSpinners> = observer(
    ({ idSpinners }: IComponentWithIdSpinners) => {
        const [zoom, setZoom] = useState(2);
        const [center, setCenter] = useState<IGeoLocation>(appConfig.googleMapCenter);
        const history = useGeotrackingCardHistoryLocation();
        const isMakingRoutes = useGeotrackingCardIsMakingRoutes();
        const routesList = useGeotrackingCardRoutesList();
        const isUpdatingTrackingInfo = useIsUpdatingTrackingInfo();
        const routesPlainList = useGeotrackingCardRoutesPlainList();
        const iconMarker = new google.maps.MarkerImage(WiFiMarker, null, null, null, new google.maps.Size(35, 35));
        const currentPositionMarker = new google.maps.MarkerImage(
            CurrentPositionMarker,
            null,
            null,
            null,
            new google.maps.Size(38, 38),
        );

        useEffect(() => {
            if (history.length) {
                const goodLocationItems = history.filter((i) => isRealLocation(i));
                const center = getCenter(goodLocationItems);
                const zoom = getZoom(goodLocationItems);
                setCenter(center);
                setZoom(zoom);
            }
        }, [history]);

        const badLocationItemsCount = history.filter((i) => !isRealLocation(i)).length;
        const badLocationTitleRight = !!badLocationItemsCount
            ? `, ${tI('location errors')}: ${badLocationItemsCount}`
            : '';

        const markers =
            history
                .filter((i) => isRealLocation(i))
                .map((value, index) => {
                    return (
                        <MarkerWithInfoBox
                            key={index}
                            position={{
                                lat: value.lat as number,
                                lng: value.lng as number,
                            }}
                            options={{ icon: index === 0 ? currentPositionMarker : iconMarker }}
                            label={{
                                text: value.measurement || '',
                                className: Styles.overlayView,
                                fontSize: '8px',
                            }}
                        >
                            <div className={Styles.overlayView}>
                                <div className={Styles.overlayViewContent} style={{ display: 'flex' }}>
                                    {index === 0 ? (
                                        <span className={Styles.popupText}>{tF('Last known position')}</span>
                                    ) : null}
                                    <span className={Styles.popupText}>
                                        {`${tF('Recorded in')} ${value?.dateName}` || tF('Unknown')}
                                    </span>
                                </div>
                            </div>
                        </MarkerWithInfoBox>
                    );
                }) || [];

        const polylinePlainLists = routesPlainList.map(
            (list: IGeotrackingCardLocationHistoryList, listIndex: number) => {
                return list
                    .filter((p, i) => {
                        const currentLocation = {
                            lat: p?.lat === undefined ? 777 : p?.lat,
                            lng: p?.lng === undefined ? 777 : p?.lng,
                        };
                        const nextLocation = {
                            lat: list[i + 1]?.lat === undefined ? 777 : list[i + 1]?.lat,
                            lng: list[i + 1]?.lng === undefined ? 777 : list[i + 1]?.lng,
                        };

                        return isRealLocation(currentLocation) && isRealLocation(nextLocation);
                    })
                    .map((item: IGeotrackingCardLocationHistoryItem, itemIndex: number) => {
                        return (
                            <Polyline
                                key={`${listIndex} ${itemIndex}`}
                                path={[list?.[itemIndex + 1], item]}
                                options={{
                                    strokeColor: '#1890ff',
                                    strokeWeight: 3,
                                    icons: [
                                        {
                                            icon: { path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW },
                                            offset: '100%',
                                        },
                                    ],
                                }}
                            />
                        );
                    });
            },
        );

        const routesPlainLineList = polylinePlainLists.flat().filter((i) => !!i);

        const routesPlainTitle = !!routesPlainLineList.length
            ? `, ${tI('flights')}: ${routesPlainLineList.length}`
            : '';

        const myRouteShow = routesList
            .filter(() => !isMakingRoutes.length)
            .map((rout: IRoureResult, index: number): ReactNode => {
                return (
                    <DirectionsRenderer
                        key={index}
                        directions={rout as any}
                        options={{ suppressMarkers: true, preserveViewport: true }}
                    />
                );
            });

        const routesTitle = !!myRouteShow.length ? `, ${tI('routes')}: ${myRouteShow.length}` : '';

        const titleRight = !!history.length
            ? `(${tI('locations')}: ${history.length}${badLocationTitleRight}${routesTitle}${routesPlainTitle})`
            : '';

        return (
            <Catcher>
                <CardLayout
                    columnOption={cardLayoutOptions.cardTableFull}
                    idSpinners={idSpinners}
                    isTable
                    title={'Card tracking'}
                    titleRight={titleRight}
                >
                    <Loading isLoading={!!isMakingRoutes.length} tip={'Route calculation'} />
                    <div className={Styles.geoTrackingMap}>
                        {isUpdatingTrackingInfo ? (
                            <div
                                className={`${Styles.updatingPopup} ${
                                    isUpdatingTrackingInfo ? Styles.updatingPopup_visible : ''
                                }`}
                            >
                                <Spin className={Styles.updatingPopup__icon} />
                                <span>Updating tracking info</span>
                            </div>
                        ) : null}
                        <BaseMap zoom={zoom} center={center} options={{ maxZoom: 17 }}>
                            {markers}
                            {polylinePlainLists}
                            {myRouteShow}
                        </BaseMap>
                    </div>
                </CardLayout>
            </Catcher>
        );
    },
);
