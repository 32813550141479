import React from 'react';
import { observer } from 'mobx-react';
import { CallDataRecordsTableList, CardLayout, Catcher } from '../../';
import { IShowCardColumn } from '../../../interfaces';

export const CallDataRecordsLast5: React.FC<IShowCardColumn> = observer(
    ({ columnOption, idSpinners }: IShowCardColumn) => {
        return (
            <Catcher>
                <CardLayout columnOption={columnOption} title={'Last 5 CDRs'} isTable idSpinners={idSpinners}>
                    <CallDataRecordsTableList isIncluded={true} />
                </CardLayout>
            </Catcher>
        );
    },
);
