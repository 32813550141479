import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { IGetApiType } from '../../REST';
import { IOperatorActivity, IOperatorInfo } from '../../interfaces';

export function useOperatorInfo(): IOperatorInfo {
    return useContext(MobXProviderContext).RootStore.operatorStore.info;
}

export function useOperatorActivityPrevMonth(): IOperatorActivity {
    return useContext(MobXProviderContext).RootStore.operatorStore.operatorActivityPrevMonth;
}

export function useOperatorActivityCurrentMonth(): IOperatorActivity {
    return useContext(MobXProviderContext).RootStore.operatorStore.operatorActivityCurrentMonth;
}

export function useOperatorSpinners(): IGetApiType[] {
    return useContext(MobXProviderContext).RootStore.operatorStore.spinners;
}
