import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Catcher } from '../';
import { history } from '../../Pages/history';
import { IGetApiType, IShowFormComponent } from '../../interfaces';
import { useRouterStore } from '../../hooks';
import {
    abortSelectedFetch,
    cleanSubscriberStore,
    getSubscriber,
    getSubscriberEditRelationSelect,
} from '../../middleware';
import { EditSubscriberForm, ShowSubscriberView } from './Components';
import Styles from '../styles/m_itemCardStyles.less';

export const Subscriber: React.FC<IShowFormComponent> = observer(({ isEdit, isNew }: IShowFormComponent) => {
    const routerStore = useRouterStore();
    const [id, setId] = useState('');
    const usedApiTypes: IGetApiType[] = ['GET_NETWORKS_SELECT', 'GET_SUBSCRIBER'];

    const goOut = (): void => {
        abortSelectedFetch(usedApiTypes);
        cleanSubscriberStore();
    };

    const goToList = (): void => {
        routerStore.goTo('SubscribersList');
        goOut();
    };

    const _getSubscriber = async (id: string): Promise<void> => {
        const isGetSubscriber = await getSubscriber(id);
        if (!isGetSubscriber) {
            goToList();
        }
    };

    useEffect(() => {
        return (): void => {
            goOut();
        };
    }, []);

    useEffect(() => {
        if (isEdit || isNew) {
            getSubscriberEditRelationSelect();
        }
        if (id) {
            _getSubscriber(id);
        }
    }, [id]);

    useEffect(() => {
        if (routerStore.routerState.params.id) {
            setId(routerStore.routerState.params.id);
        }
    }, [routerStore.routerState.params.id]);

    const setEdit = (isEdit?: boolean): void => {
        if (isNew) {
            routerStore.goTo('SubscribersList');
        } else if (isEdit) {
            abortSelectedFetch(usedApiTypes);
            _getSubscriber(id);
            getSubscriberEditRelationSelect();
            routerStore.goTo('SubscriberEdit', { params: { id } });
        } else {
            history.goBack();
        }
    };

    return (
        <Catcher>
            <article className={Styles.main}>
                {isEdit || isNew ? (
                    <EditSubscriberForm setEditMode={setEdit} />
                ) : (
                    <ShowSubscriberView setEditMode={setEdit} />
                )}
            </article>
        </Catcher>
    );
});
