import React from 'react';
import { Catcher } from '../';
import { ReportsList } from './Components';

export const ReportsPage: React.FC = () => {
    return (
        <Catcher>
            <ReportsList />
        </Catcher>
    );
};
