import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { IDiameterSessionsListItem, IFilterParams, ISelectList } from '../../interfaces';

export function useDiameterSessionsList(): IDiameterSessionsListItem[] {
    return useContext(MobXProviderContext).RootStore.diameterSessionsStore.list;
}

export function useDiameterSessionsListParams(): IFilterParams {
    return useContext(MobXProviderContext).RootStore.diameterSessionsStore.filterParams;
}

export function useDiameterSessionsSelect(): ISelectList {
    return useContext(MobXProviderContext).RootStore.diameterSessionsStore.sessionsSelect;
}
