import React, { MouseEvent } from 'react';
import { observer } from 'mobx-react';
import { Catcher, LoadingApiByTypeAndUnit } from '../../';
import { HorizontalUsageChart } from './';
import { DashboardUnitTypes, ICartItemData } from '../../../interfaces';
import {
    useDashboardCardsUsageChart,
    useDashboardGraphTitleByUnitType,
    useDashboardUnitTypeObj,
    useRouterStore,
} from '../../../hooks';
import { tP } from '../../../translate';
import Styles from '../m_dashboarStyles.less';

interface ICardUsage {
    useDashboardUsageData?: () => ICartItemData[];
    unitType?: DashboardUnitTypes;
}

export const CardsUsage: React.FC<ICardUsage> = observer(({ unitType = DashboardUnitTypes.DATA }: ICardUsage) => {
    const unitTypeObj = useDashboardUnitTypeObj(unitType);
    const subTitle = useDashboardGraphTitleByUnitType(unitTypeObj?.name || '');
    const title = `${tP('Top Cards')} ${subTitle}`;
    const apiTypeId = `GET_USAGE_CARDS_LIST`;
    const data = useDashboardCardsUsageChart(apiTypeId, unitTypeObj);
    const routerStore = useRouterStore();
    const goToCardList = (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>): void => {
        event.preventDefault();
        routerStore.goTo('CardList');
    };

    const titleLink = (): JSX.Element => (
        <a onClick={goToCardList} href={`/cards`}>
            {title}
        </a>
    );

    const goToCard = (id: string): void => {
        routerStore.goTo('Card', { params: { id } });
    };

    return (
        <Catcher>
            <LoadingApiByTypeAndUnit apiTypeId={apiTypeId} unitTypeId={unitTypeObj?.id} />
            <div className={Styles.chart}>
                <HorizontalUsageChart
                    sourceData={data}
                    title={title}
                    unitTypeObj={unitTypeObj}
                    titleLink={titleLink()}
                    onSelectRow={goToCard}
                />
            </div>
        </Catcher>
    );
});
