import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Catcher } from '../';
import { IGetApiType, IShowFormComponent } from '../../interfaces';
import { useProfile, useProfileIsRight, useRouterStore, useUiLanguage } from '../../hooks';
import {
    abortSelectedFetch,
    addNotification,
    cleanUserStore,
    getCompanyApiKeysList,
    getUserById,
} from '../../middleware';
import { tI, tNM } from '../../translate';
import { EditUser, ShowUser } from './Components';
import Styles from '../styles/m_itemCardStyles.less';

interface IUserProps extends IShowFormComponent {
    isMe?: boolean;
}

export const User: React.FC<IUserProps> = observer(({ isMe, isNew, isEdit }: IUserProps) => {
    const languageId = useUiLanguage();
    const routerStore = useRouterStore();
    const [id, setId] = useState('');
    const myId = useProfile().id;
    const isAdmin = useProfileIsRight('ADMIN');

    const usedApiTypes: IGetApiType[] = ['GET_COMPANIES_SELECT', 'GET_USER', 'GET_USER_TYPE_SELECT'];

    const goOut = (): void => {
        abortSelectedFetch(usedApiTypes);
        cleanUserStore();
    };

    const goToList = (): void => {
        routerStore.goTo('UsersList').then();
        goOut();
    };

    const _getUserById = async (id: string): Promise<void> => {
        const isGetUser = await getUserById(id);
        if (!isGetUser) {
            addNotification({
                type: 'error',
                message: tNM('Not Found'),
                description: `${tI('User id')} - "${id}" ${tI('not found')}`,
                duration: 5,
                isTranslated: true,
            });
            goToList();
        }
    };

    const _getCompanyApiKeyList = async (): Promise<void> => {
        getCompanyApiKeysList();
    };

    const setEdit = (isEdit?: boolean): void => {
        if (isNew) {
            goToList();
        } else if (isEdit) {
            if (isMe || id === myId) {
                routerStore.goTo('ProfileEdit').then();
            } else {
                routerStore.goTo('UserEdit', { params: { id } }).then();
            }
        } else {
            if (isMe || id === myId) {
                routerStore.goTo('Profile').then();
            } else {
                routerStore.goTo('User', { params: { id } }).then();
            }
        }
    };
    useEffect(() => {
        return (): void => {
            goOut();
        };
    }, []);

    useEffect(() => {
        if (id) {
            _getUserById(id);
        }
    }, [id]);

    useEffect(() => {
        if (isMe && isAdmin) {
            _getCompanyApiKeyList();
        }
    }, [isMe, isAdmin]);

    useEffect(() => {
        if (routerStore.routerState.routeName === 'Profile' || routerStore.routerState.routeName === 'ProfileEdit') {
            setId(myId);
        } else if (
            routerStore.routerState.params.id &&
            (routerStore.routerState.routeName === 'User' || routerStore.routerState.routeName === 'UserEdit')
        ) {
            setId(routerStore.routerState.params.id);

            if (routerStore.routerState.params.id === myId) {
                if (isEdit) {
                    routerStore.goTo('ProfileEdit');
                } else {
                    routerStore.goTo('Profile');
                }
            }
        }
    }, [routerStore.routerState.routeName, routerStore.routerState.params.id]);

    return (
        <Catcher>
            <div className={Styles.main}>
                {isEdit || isNew ? (
                    <EditUser setEditMode={setEdit} isNew={isNew} />
                ) : (
                    <ShowUser setEditMode={setEdit} />
                )}
            </div>
        </Catcher>
    );
});
