import React from 'react';
import { Button, Tooltip } from 'antd';
import { CustomerServiceOutlined } from '@ant-design/icons';
import { Catcher } from '../';
import { IDivMainProps } from '../../interfaces';
import { useProfileIsRight, useRouterStore } from '../../hooks';
import { tI } from '../../translate';
import Styles from './m_styles.less';

export const SupportButton: React.FC<IDivMainProps> = () => {
    const routerStore = useRouterStore();
    const isRoot = useProfileIsRight('ROOT');
    return isRoot ? (
        <Catcher>
            <Tooltip title={tI('Support Page')} placement="bottomRight">
                <Button
                    className={Styles.supportButton}
                    onClick={() => routerStore.goTo('SupportPage')}
                    icon={<CustomerServiceOutlined />}
                />
            </Tooltip>
        </Catcher>
    ) : null;
};
