/* eslint-disable */
import { CountriesStore } from '../stores/countries';
import { OperatorsStore } from '../stores/operators';
import { RateStore } from '../stores/rate';
import {
    cleanApiMessages,
    cleanCatalogsStore,
    cleanCountriesStore,
    cleanCurrenciesStore,
    cleanIncludedStore,
    cleanOperatorsStore,
    cleanZonesStore,
    getCatalogsUnitList,
    getCompaniesSelect,
    getCountriesWithZoneList,
    getCurrenciesSelect,
    getIncludeAssignedCompaniesAPI,
    getNetworksSelect,
    getOperatorsSelect,
    getUnitLabel,
    getZonesSelect,
    setIncludedAssignedCompaniesAPI,
    updateIncludedList,
} from './';
import { setRatesForcedUpdateTime } from './rates';
import { appConfig } from '../settings';
import { IGetApiResponse, apiManager } from '../REST';
import {
    IBodyItemResponse,
    ICountryWithZone,
    IIncludeAssignedCompanyItem,
    IIncludeItemCurrencyAPI,
    IIncludeItemUnitAPI,
    IIncludeNameItemAPI,
    IIncludeRateAttributesAPI,
    IIncludeRateRateListAPI,
    IIncludeTypeAPI,
    IIncludeUnitItemAPI,
    IItemAPI,
    IPatchItem,
    IRateCountryFormInitialValues,
    IRateIncludeItemAPI,
    IRateInfo,
    IRateItemAPI,
    IRateListAPI,
    IRateNetworkItem,
    IRelationshipItem,
    IRequestAPIBody,
    IRequestRelationships,
    IUseRateForm,
} from '../interfaces';
import { equalObjects, globalHexId, isNewId, mapApi2UiField, mapUi2ApiField, sortByName } from '../instruments';

export function cleanRateStore(): void {
    const StoreInstance = RateStore.getInstance();
    StoreInstance.cleanStore();
}

function setRateInfo(info: IRateInfo): void {
    const StoreInstance = RateStore.getInstance();
    const { rateInfo } = StoreInstance;
    if (!info.id && !!rateInfo.id) {
        info.id = rateInfo.id;
    }
    if (JSON.stringify(info) !== JSON.stringify(rateInfo)) {
        StoreInstance.setRateInfo(info);
    }
}

function setRateNetworksListOriginal(list: IRateNetworkItem[]): void {
    const StoreInstance = RateStore.getInstance();
    StoreInstance.setRateNetworksListOriginal(list);
}
function setRateNetworksList(list: IRateNetworkItem[]): void {
    const StoreInstance = RateStore.getInstance();
    const { rateNetworksList } = StoreInstance;
    if (JSON.stringify(list) !== JSON.stringify(rateNetworksList)) {
        StoreInstance.setRateNetworksList(list);
    }
}

function updateRateAssignedCompany(list: IIncludeAssignedCompanyItem[]): void {
    updateIncludedList('assignedCompanies', list);
}

export function setRateForm(form: IUseRateForm): void {
    const info = { ...form };
    delete info.assignedCompanies;
    setRateInfo(info);
    const assignedCompanies = form.assignedCompanies || [];
    updateRateAssignedCompany(assignedCompanies);
}

function createRateInfo(): void {
    const newRate: IRateInfo = {
        id: globalHexId.getId(),
    };
    setRateInfo(newRate);
}

export function setCountriesRateListOriginal(list: IRateCountryFormInitialValues[] = []): void {
    const StoreInstance = RateStore.getInstance();
    StoreInstance.setCountriesRateListOriginal(list);
}

export function createRateList(defaultList: IRateCountryFormInitialValues[] = []): void {
    const operatorsSelect = OperatorsStore.getInstance().operatorsSelect;
    const countriesSelect = CountriesStore.getInstance().countriesWithZoneList;
    const list: IRateCountryFormInitialValues[] = [];
    operatorsSelect.forEach((o) => {
        countriesSelect.sort(sortByName).forEach((c: ICountryWithZone) => {
            const foundItem = defaultList.find((i) => i.operatorId === o.id && i.countryId === c.id);

            const rateItem: IRateCountryFormInitialValues = foundItem
                ? foundItem
                : { id: globalHexId.getId(), operatorId: o.id, countryId: c.id };
            list.push(rateItem);
        });
    });
    const StoreInstance = RateStore.getInstance();

    StoreInstance.setCountriesRateList(list);
}

const makeRateInfo = (data?: IRateItemAPI, included?: IRateIncludeItemAPI[]) => {
    const attributes = data?.attributes && mapApi2UiField(data?.attributes);
    const itemId = data?.id || '';
    const companyId = data?.relationships?.company?.data?.id || '';
    const company =
        (included?.filter((i: IRelationshipItem) => i.type === 'company') as IIncludeNameItemAPI[])?.find(
            (i: IIncludeNameItemAPI) => i.id === companyId,
        )?.attributes?.name || '';

    const currencyId = data?.relationships?.currency?.data?.id || '';
    const currency =
        (included?.filter((i: IRelationshipItem) => i.type === 'currency') as IIncludeItemCurrencyAPI[])?.find(
            (i: IIncludeItemCurrencyAPI) => i.id === currencyId,
        )?.attributes?.abbreviation || '';

    const defaultRateDataUnitId = data?.relationships?.default_rate_data_unit?.data?.id || '';
    const defaultRateDataUnitVal =
        (included?.filter((i: IRelationshipItem) => i.type === 'rate_unit') as IIncludeUnitItemAPI[])?.find(
            (i: IIncludeUnitItemAPI) => i.id === defaultRateDataUnitId,
        )?.attributes?.unit || '';
    const defaultRateDataUnit = getUnitLabel(defaultRateDataUnitVal, currency);

    const defaultRateSmsUnitId = data?.relationships?.default_rate_sms_unit?.data?.id || '';
    const defaultRateSmsUnitVal =
        (included?.filter((i: IRelationshipItem) => i.type === 'rate_unit') as IIncludeUnitItemAPI[])?.find(
            (i: IIncludeUnitItemAPI) => i.id === defaultRateSmsUnitId,
        )?.attributes?.unit || '';
    const defaultRateSmsUnit = getUnitLabel(defaultRateSmsUnitVal, currency);

    const defaultRateVoiceMobileUnitId = data?.relationships?.default_rate_voice_mobile_unit?.data?.id || '';
    const defaultRateVoiceMobileUnitVal =
        (included?.filter((i: IRelationshipItem) => i.type === 'rate_unit') as IIncludeUnitItemAPI[])?.find(
            (i: IIncludeUnitItemAPI) => i.id === defaultRateVoiceMobileUnitId,
        )?.attributes?.unit || '';
    const defaultRateVoiceMobileUnit = getUnitLabel(defaultRateVoiceMobileUnitVal, currency);

    const defaultRateVoiceNetUnitId = data?.relationships?.default_rate_voice_net_unit?.data?.id || '';
    const defaultRateVoiceNetUnitVal =
        (included?.filter((i: IRelationshipItem) => i.type === 'rate_unit') as IIncludeUnitItemAPI[])?.find(
            (i: IIncludeUnitItemAPI) => i.id === defaultRateVoiceNetUnitId,
        )?.attributes?.unit || '';
    const defaultRateVoiceNetUnit = getUnitLabel(defaultRateVoiceNetUnitVal, currency);

    const info: IRateInfo = {
        id: itemId,
        ...attributes,
        companyId,
        company,
        currencyId,
        currency,
        defaultRateDataUnitId,
        defaultRateDataUnit,
        defaultRateSmsUnitId,
        defaultRateSmsUnit,
        defaultRateVoiceMobileUnitId,
        defaultRateVoiceMobileUnit,
        defaultRateVoiceNetUnitId,
        defaultRateVoiceNetUnit,
    };
    setRateInfo(info);
};

const makeRateRateList = (data?: IRateItemAPI, included?: IRateIncludeItemAPI[]) => {
    const rateUnitInclude = included?.filter((i: IRateIncludeItemAPI) => i.type === 'rate_unit') || [];
    const rateCountriesInclude = included?.filter((i: IRateIncludeItemAPI) => i.type === 'sales_rate_list_entry') || [];
    const rateOperatorsInclude = included?.filter((i: IRateIncludeItemAPI) => i.type === 'operator') || [];
    const currencyId = data?.relationships?.currency?.data?.id || '';
    const currency =
        (included?.filter((i: IRelationshipItem) => i.type === 'currency') as IIncludeItemCurrencyAPI[]).find(
            (i: IIncludeItemCurrencyAPI) => i.id === currencyId,
        )?.attributes?.abbreviation || '';

    const rateCountriesNetworksList: IRateNetworkItem[] =
        data?.relationships?.sales_rate_list_entries?.data?.map((s) => {
            const findRateCountriesInclude = rateCountriesInclude.find((i) => i.id === s.id) as IIncludeRateRateListAPI;

            const findAttributes =
                findRateCountriesInclude?.attributes && mapApi2UiField(findRateCountriesInclude.attributes);

            const countryId = findRateCountriesInclude?.relationships?.country?.data?.id;
            const operatorId = findRateCountriesInclude?.relationships?.operator?.data?.id || appConfig.fakeId;

            const networkId = findRateCountriesInclude?.relationships?.network?.data?.id;
            const networkName =
                (rateOperatorsInclude.find((o) => o.id === networkId) as IItemAPI)?.attributes.name || '';

            const rateDataUnitId = findRateCountriesInclude?.relationships?.rate_data_unit?.data?.id;
            const rateDataUnitVal = (rateUnitInclude.find((r) => r.id === rateDataUnitId) as IIncludeItemUnitAPI)
                ?.attributes.unit;
            const rateDataUnit = rateDataUnitVal ? getUnitLabel(rateDataUnitVal, currency) : rateDataUnitVal;

            const rateVoiceMobileUnitId = findRateCountriesInclude?.relationships?.rate_voice_mobile_unit?.data?.id;
            const rateVoiceMobileUnitVal = (
                rateUnitInclude.find((r) => r.id === rateVoiceMobileUnitId) as IIncludeItemUnitAPI
            )?.attributes.unit;
            const rateVoiceMobileUnit = rateVoiceMobileUnitVal
                ? getUnitLabel(rateVoiceMobileUnitVal, currency)
                : rateVoiceMobileUnitVal;

            const rateVoiceNetUnitId = findRateCountriesInclude?.relationships?.rate_voice_net_unit?.data?.id;
            const rateVoiceNetUnitVal = (
                rateUnitInclude.find((r) => r.id === rateVoiceNetUnitId) as IIncludeItemUnitAPI
            )?.attributes.unit;
            const rateVoiceNetUnit = rateVoiceNetUnitVal
                ? getUnitLabel(rateVoiceNetUnitVal, currency)
                : rateVoiceNetUnitVal;

            const rateSmsUnitId = findRateCountriesInclude?.relationships?.rate_sms_unit?.data?.id;
            const rateSmsUnitVal = (rateUnitInclude.find((r) => r.id === rateSmsUnitId) as IIncludeItemUnitAPI)
                ?.attributes.unit;
            const rateSmsUnit = rateSmsUnitVal ? getUnitLabel(rateSmsUnitVal, currency) : rateSmsUnitVal;

            const rateItem: IRateNetworkItem = {
                id: s.id,
                ...findAttributes,
                countryId,
                operatorId,
                networkId,
                networkName,
                rateDataUnitId,
                rateDataUnit,
                rateSmsUnitId,
                rateSmsUnit,
                rateVoiceMobileUnitId,
                rateVoiceMobileUnitVal,
                rateVoiceMobileUnit,
                rateVoiceNetUnitId,
                rateVoiceNetUnitVal,
                rateVoiceNetUnit,
            };

            return rateItem;
        }) || [];
    const rateCountriesList = rateCountriesNetworksList.filter((i) => !i.networkId);
    createRateList(rateCountriesList);
    setCountriesRateListOriginal(rateCountriesList);
    const rateNetworksList = rateCountriesNetworksList.filter((i) => i.networkId);
    setRateNetworksList(rateNetworksList);
    setRateNetworksListOriginal(rateNetworksList);
};

export function setRate(apiResponse: IGetApiResponse) {
    const { resp, isError } = apiResponse;
    if (!isError) {
        setIncludedAssignedCompaniesAPI(resp as IBodyItemResponse);
        const { data, included } = resp as IRateListAPI;
        makeRateInfo(data, included);
        makeRateRateList(data, included);
    }
}

export async function getRate(id?: string): Promise<boolean> {
    if (!id) {
        createRateInfo();
        return true;
    } else {
        const apiResponse = await apiManager.getApi('GET_SALES_RATE_LIST', { id });
        if (apiResponse.isError) {
            return false;
        } else {
            setRate(apiResponse);
            return true;
        }
    }
}

export async function deleteRateList(id: string): Promise<void> {
    const { isError } = await apiManager.getApi('DELETE_SALES_RATE_LIST', { id });
    if (!isError) {
        setRatesForcedUpdateTime();
    }
}

export async function getRateShowRelationSelect(): Promise<void> {
    cleanApiMessages();
    await Promise.all([getCountriesWithZoneList(), getZonesSelect(), getOperatorsSelect(true)]);
}

export async function getRateEditRelationSelect(): Promise<void> {
    await Promise.all([
        getRateShowRelationSelect(),
        getCompaniesSelect(true),
        getNetworksSelect(),
        getCurrenciesSelect(),
        getCatalogsUnitList(),
    ]);
}

export function cleanRateRelationSelect(): void {
    cleanApiMessages();
    cleanZonesStore();
    cleanCountriesStore();
    cleanOperatorsStore();
    cleanCatalogsStore();
    cleanCurrenciesStore();
    cleanIncludedStore();
}

export const updateRateCountryValue = (value: IRateCountryFormInitialValues): void => {
    const StoreInstance = RateStore.getInstance();
    StoreInstance.updateCountryRateValue(value);
};

export const updateRateNetworksValues = (networks: IRateNetworkItem[]): void => {
    if (networks && networks.length) {
        const StoreInstance = RateStore.getInstance();
        let isUpdated = false;
        const newNetworks = StoreInstance.rateNetworksList.map((n: IRateNetworkItem) => {
            const storeNetworkId = n.id;
            const foundFormNetwork = networks.find((f: IRateNetworkItem) => f.id === storeNetworkId);
            if (foundFormNetwork) {
                const updatedStoreNetwork = { ...n, ...foundFormNetwork };
                if (JSON.stringify(updatedStoreNetwork) !== JSON.stringify(n)) {
                    isUpdated = true;
                    return updatedStoreNetwork;
                } else {
                    return n;
                }
            } else {
                return n;
            }
        });
        if (isUpdated) {
            StoreInstance.setRateNetworksList(newNetworks);
        }
    }
};

export function updateCompanyRateForm(form: IRateCountryFormInitialValues): void {
    const StoreInstance = RateStore.getInstance();
    if (form.networks && form.networks.length) {
        const networks = [...form.networks];
        if (networks && networks.length) {
            updateRateNetworksValues(networks);
        }
    }
    const companyRateId = form.id;
    const foundCompanyRate = StoreInstance.rateCountriesList.find((i) => i.id === companyRateId);
    if (foundCompanyRate) {
        delete form.networks;
        const updatedCompanyRate = { ...foundCompanyRate, ...form };
        if (JSON.stringify(updatedCompanyRate) !== JSON.stringify(foundCompanyRate)) {
            updateRateCountryValue(updatedCompanyRate);
        }
    } else {
        console.warn('CompanyRate->', form, 'not found in Store');
    }
}

export function addRateNetworkRateItem(item: IRateNetworkItem): void {
    const StoreInstance = RateStore.getInstance();
    StoreInstance.addNetworkRateItem(item);
}

export const removeRateNetworkValueById = (id: string): void => {
    if (id) {
        const StoreInstance = RateStore.getInstance();
        StoreInstance.removeNetworkById(id);
    }
};

export const sendRateData = async (isNew: boolean): Promise<IPatchItem> => {
    const StoreInstance = RateStore.getInstance();
    const { rateInfo, rateCountriesList, rateCountriesListOriginal, rateNetworksList, rateNetworksListOriginal } =
        StoreInstance;
    const { id: rateInfoId, ...rateInfoStore } = rateInfo;
    const rateInfoAPI = mapUi2ApiField(rateInfoStore);
    delete rateInfoAPI.company;
    delete rateInfoAPI.company_id;
    delete rateInfoAPI.currency;
    delete rateInfoAPI.currency_id;
    delete rateInfoAPI.defaultRateDataUnit;
    delete rateInfoAPI.defaultRateSmsUnit;
    delete rateInfoAPI.defaultRateVoiceMobileUnit;
    delete rateInfoAPI.defaultRateVoiceNetUnit;
    delete rateInfoAPI.default_rate_data_unit_id;
    delete rateInfoAPI.default_rate_sms_unit_id;
    delete rateInfoAPI.default_rate_voice_mobile_unit_id;
    delete rateInfoAPI.default_rate_voice_net_unit_id;

    const Relationships = new makeRelationships();
    Relationships.addRelation('company', rateInfo.companyId);
    Relationships.addRelation('currency', rateInfo.currencyId);
    Relationships.addRelation('rate_unit', rateInfo.defaultRateDataUnitId, 'default_rate_data_unit');
    Relationships.addRelation('rate_unit', rateInfo.defaultRateSmsUnitId, 'default_rate_sms_unit');
    Relationships.addRelation('rate_unit', rateInfo.defaultRateVoiceMobileUnitId, 'default_rate_voice_mobile_unit');
    Relationships.addRelation('rate_unit', rateInfo.defaultRateVoiceNetUnitId, 'default_rate_voice_net_unit');

    const { assignedCompanyRelationships, assignedCompanyIncludes } = getIncludeAssignedCompaniesAPI();

    const includedAPI: IRateIncludeItemAPI[] = [...assignedCompanyIncludes];

    const rateCountryListAPI: IRelationshipItem[] = rateCountriesList
        .filter(
            (i: IRateCountryFormInitialValues) =>
                !!i.rateData ||
                !!i.rateDataUnitId ||
                !!i.rateSms ||
                !!i.rateSmsUnitId ||
                !!i.rateVoiceMobile ||
                !!i.rateVoiceMobileUnitId ||
                !!i.rateVoiceNet ||
                !!i.rateVoiceNetUnitId,
        )
        .filter((i: IRateCountryFormInitialValues) => {
            if (isNewId(i.id)) {
                return true;
            } else {
                const foundOriginal = rateCountriesListOriginal.find(
                    (o: IRateCountryFormInitialValues) => o.id === i.id,
                );
                if (!foundOriginal) {
                    return true;
                } else {
                    return !equalObjects(foundOriginal, i);
                }
            }
        })
        .map((i: IRateCountryFormInitialValues) => {
            const relation: IRelationshipItem = {
                id: i.id,
                type: 'sales_rate_list_entry',
            };
            const rateUi: IRateCountryFormInitialValues = {
                ...i,
                operatorId: i.operatorId === appConfig.fakeId ? undefined : i.operatorId,
                rateData: i.rateData,
                rateDataUnitId: i.rateDataUnitId,
                rateSms: i.rateSms,
                rateSmsUnitId: i.rateSmsUnitId,
                rateVoiceMobile: i.rateVoiceMobile,
                rateVoiceMobileUnitId: i.rateVoiceMobileUnitId,
                rateVoiceNet: i.rateVoiceNet,
                rateVoiceNetUnitId: i.rateVoiceNetUnitId,
            };

            const rateAPI = mapUi2ApiField(rateUi);
            delete rateAPI.networkName;
            delete rateAPI.rateDataUnit;
            delete rateAPI.rateSmsUnit;
            delete rateAPI.rateVoiceMobileUnit;
            delete rateAPI.rateVoiceNetUnit;

            const included: IIncludeRateRateListAPI = {
                attributes: rateAPI as IIncludeRateAttributesAPI,
                ...relation,
            };

            includedAPI.push(included);

            return relation;
        });

    const rateNetworkRemoveListAPI: IRelationshipItem[] = rateNetworksList
        .filter((i: IRateNetworkItem) => i.destroy)
        .filter((i: IRateNetworkItem) => !isNewId(i.id))
        .map((i: IRateNetworkItem) => ({ id: i.id, type: 'sales_rate_list_entry', destroy: true }));

    const rateNetworkListAPI: IRelationshipItem[] = rateNetworksList
        .filter((i: IRateNetworkItem) => !(i.destroy && !isNewId(i.id)))
        .filter(
            (i: IRateNetworkItem) =>
                !!i.rateData ||
                !!i.rateDataUnitId ||
                !!i.rateSms ||
                !!i.rateSmsUnitId ||
                !!i.rateVoiceMobile ||
                !!i.rateVoiceMobileUnitId ||
                !!i.rateVoiceNet ||
                !!i.rateVoiceNetUnitId,
        )
        .filter((i: IRateNetworkItem) => {
            if (isNewId(i.id)) {
                return true;
            } else {
                const foundOriginal = rateNetworksListOriginal.find((o: IRateNetworkItem) => o.id === i.id);
                if (!foundOriginal) {
                    return true;
                } else {
                    return !equalObjects(foundOriginal, i);
                }
            }
        })
        .map((i: IRateNetworkItem) => {
            const relation: IRelationshipItem = {
                id: i.id,
                type: 'sales_rate_list_entry',
            };
            const rateUi: IRateCountryFormInitialValues = {
                ...i,
                operatorId: i.operatorId === appConfig.fakeId ? undefined : i.operatorId,
                rateData: i.rateData,
                rateDataUnitId: i.rateDataUnitId,
                rateSms: i.rateSms,
                rateSmsUnitId: i.rateSmsUnitId,
                rateVoiceMobile: i.rateVoiceMobile,
                rateVoiceMobileUnitId: i.rateVoiceMobileUnitId,
                rateVoiceNet: i.rateVoiceNet,
                rateVoiceNetUnitId: i.rateVoiceNetUnitId,
            };
            const rateAPI = mapUi2ApiField(rateUi);
            const included: IIncludeRateRateListAPI = {
                ...relation,
                attributes: rateAPI as IIncludeRateAttributesAPI,
            };
            includedAPI.push(included);

            return relation;
        });

    const body: IRequestAPIBody = {
        data: {
            id: rateInfoId,
            type: 'sales_rate_list',
            attributes: rateInfoAPI,
            relationships: {
                ...Relationships.list(),
                assigned_companies: {
                    data: assignedCompanyRelationships,
                },
                sales_rate_list_entries: {
                    data: [...rateCountryListAPI, ...rateNetworkRemoveListAPI, ...rateNetworkListAPI],
                },
            },
        },
        included: includedAPI,
    };

    const { resp, isError } = await apiManager.getApi(
        isNew ? 'POST_SALES_RATE_LIST' : 'PATCH_SALES_RATE_LIST',
        isNew ? {} : { id: rateInfoId },
        body,
    );
    const { errors } = await resp;
    return { isError, errors };
};

export const setCountryLoadingStats = (newValue: number, paramName: 'expectedToLoad' | 'alreadyLoaded'): void => {
    const StoreInstance = RateStore.getInstance();
    StoreInstance.changeLoadingStats(newValue, paramName);
};

export const addAlreadyLoaded = (): void => {
    const StoreInstance = RateStore.getInstance();
    StoreInstance.addAlreadyLoaded();
};

class makeRelationships {
    private relationships: IRequestRelationships = {};

    public addRelation(type: IIncludeTypeAPI, id?: string, relation?: IIncludeTypeAPI): void {
        if (id) {
            this.relationships[relation || type] = {
                data: {
                    id,
                    type,
                },
            };
        }
    }
    public list(): IRequestRelationships {
        return this.relationships;
    }
}
