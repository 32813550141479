import { action, makeObservable, observable } from 'mobx';
import { IUser, IUserTypes } from '../interfaces';

export class UserStore {
    private constructor() {
        makeObservable(this);
    }

    private static instance: UserStore;
    public static getInstance(): UserStore {
        if (!UserStore.instance) {
            UserStore.instance = new UserStore();
        }
        return UserStore.instance;
    }

    initStore: IUser = {
        id: '',
    };

    @observable user: IUser = this.initStore;
    @action setUser(user: IUser): void {
        this.user = user;
    }

    @observable userTypes: IUserTypes[] = [];
    @action setUserTypes(userTypes: IUserTypes[]): void {
        this.userTypes = userTypes;
    }

    @action cleanStore(): void {
        this.user = this.initStore;
        this.userTypes = [];
    }
}
