import React from 'react';
import { CancelButton, Catcher } from '../';
import { history } from '../../Pages/history';
import { usePagesStoreListName, useRouterStore } from '../../hooks';
import { isAvailablePage } from '../../middleware';

interface ICancelButtonListProps {
    dataTest?: string;
    isDisable?: boolean;
}

export const CancelButtonGoList: React.FC<ICancelButtonListProps> = ({
    dataTest,
    isDisable,
}: ICancelButtonListProps) => {
    const routerStore = useRouterStore();
    const listName = usePagesStoreListName();
    const isRightList = !!listName && isAvailablePage(listName);

    const goToList = (): void => {
        if (isRightList && listName) {
            routerStore.goTo(listName);
        } else {
            history.goBack();
        }
    };

    return (
        <Catcher>
            <CancelButton dataTest={dataTest} onClick={goToList} isDisable={isDisable} />
        </Catcher>
    );
};
