import React from 'react';
import { observer } from 'mobx-react';
import { Catcher } from '../index';
import { IReportFilesButtons, ReportFilesButtons } from './ReportFilesButtons';

export const ReportFilesToolBar: React.FC<IReportFilesButtons> = observer(
    ({ filterParams, type, isPdf, isCsv, entityType }: IReportFilesButtons) => {
        return (
            <Catcher>
                {isPdf || isCsv ? (
                    <ReportFilesButtons
                        filterParams={filterParams}
                        type={type}
                        isCsv={isCsv}
                        isPdf={isPdf}
                        entityType={entityType}
                    />
                ) : null}
            </Catcher>
        );
    },
);
