import { BalanceLogStore } from '../stores/balaceLog';
import { cleanCompaniesStore, getCompaniesSelect } from './companies';
import { setUiTotal } from './ui';
import { apiManager } from '../REST';
import {
    IBalanceLogItem,
    IBalanceLogItemAPI,
    IBalanceLogListAPI,
    IParamsList,
    IRequestRelationshipMainItem,
} from '../interfaces';
import { getUiField, mapApi2UiField } from '../instruments';

export function setBalanceLogList(resp: IBalanceLogListAPI): void {
    const companies = resp.included?.filter((i: IRequestRelationshipMainItem) => i.type === 'company') || [];
    const list: IBalanceLogItem[] =
        resp?.data?.map((r: IBalanceLogItemAPI) => {
            const balanceAdjustableId = r.relationships?.balance_adjustable?.data?.id;
            const description = getUiField(r.relationships?.balance_adjustable?.data?.type || '');
            const companyId = r.relationships?.company?.data?.id;
            const companyFound = companies.find((i) => i.id === companyId);
            const company = companyFound?.attributes?.name;
            return {
                id: r.id,
                ...mapApi2UiField(r.attributes),
                balanceAdjustableId,
                companyId,
                company,
                description,
            };
        }) || [];
    const BalanceLogStoreInstance = BalanceLogStore.getInstance();
    BalanceLogStoreInstance.setBalanceLogList(list);
}

export function getBalanceLogRelationSelect(): void {
    getCompaniesSelect();
}

export async function getBalanceLogList(params: IParamsList): Promise<void> {
    const { resp } = await apiManager.getApi('GET_BALANCE_LOG_LIST', { searchParamsList: params });
    const { data, meta } = resp;
    meta && setUiTotal(meta.records_count);
    data && setBalanceLogList(resp as IBalanceLogListAPI);
}

export function setBalanceLogForcedUpdateTime(): void {
    const BalanceLogStoreInstance = BalanceLogStore.getInstance();
    BalanceLogStoreInstance.setForcedUpdateTime();
}

export function cleanBalanceLogStore(): void {
    cleanCompaniesStore();
    const BalanceLogStoreInstance = BalanceLogStore.getInstance();
    BalanceLogStoreInstance.cleanStore();
}
