/* eslint-disable */
import { cleanCompanyStore, getCompanyById } from './company';
import { IRequestAPIBody, apiManager } from '../REST';
import {
    IBodyItemResponse,
    ICurrenciesListItemAPI,
    IGetOrder,
    IIncludeUserItemAPI,
    IItemAPI,
    IPostItem,
    IPostOrderAPI,
    IPostOrderAttributes,
    IPostPackageOrderAttributes,
    IRelationshipItem,
} from '../interfaces';
import { dateText2Utc, getAPIUserFullName, mapApi2UiField, mapUi2ApiField } from '../instruments';

export async function getOrder(id: string): Promise<IGetOrder> {
    const { resp, isError } = await apiManager.getApi('GET_ORDER', { id: id });
    const { data } = resp as IBodyItemResponse;
    const attributes = mapApi2UiField(data?.attributes || {});
    const companiesList = resp?.included?.filter((i) => i.type === 'company');
    const usersList = resp?.included?.filter((i) => i.type === 'user');
    const currenciesList = resp?.included?.filter((i) => i.type === 'currency');
    const companyId = (data?.relationships?.company?.data as IRelationshipItem)?.id;
    const company = (companiesList?.find((o) => o.id === companyId) as IItemAPI)?.attributes?.name;
    const userId = (data?.relationships?.user?.data as IRelationshipItem)?.id;
    const user = usersList?.find((o) => o.id === userId) as IIncludeUserItemAPI;
    const currencyId = (data?.relationships?.currency?.data as IRelationshipItem)?.id;
    const currency = (currenciesList?.find((o) => o.id === currencyId) as ICurrenciesListItemAPI)?.attributes
        ?.abbreviation;

    return {
        isError,
        order: {
            id,
            ...attributes,
            companyId,
            company,
            currencyId,
            currency,
            userId,
            user: getAPIUserFullName(user),
            updatedAt: dateText2Utc(data?.attributes?.updated_at),
        },
    };
}

export function getOrderRelations(id: string): void {
    getCompanyById(id);
}

export function cleanOrderTopUpRelation(): void {
    cleanCompanyStore();
}

export const postOrder = async (companyId: string, attributes: IPostOrderAttributes): Promise<IPostItem> => {
    const body: IRequestAPIBody = {
        data: {
            type: 'order',
            attributes: mapUi2ApiField(attributes),
        },
    };

    const { resp, isError } = await apiManager.getApi('POST_ORDER', { id: companyId }, body);
    const response = resp as IBodyItemResponse;
    const { errors } = await response;
    return { isError, errors, resp: response };
};

export const postPackageOrder = async (
    packageId: string,
    attributes: IPostPackageOrderAttributes,
): Promise<IPostItem> => {
    const body: IPostOrderAPI = {
        data: {
            type: 'order',
            attributes: mapUi2ApiField(attributes),
        },
    };

    const { resp, isError } = await apiManager.getApi('POST_PACKAGE_ORDER', { id: packageId }, body);
    const response = resp as IBodyItemResponse;
    const { errors } = await response;
    return { isError, errors, resp: response };
};

export const checkOutOrder = async (ordersId: string): Promise<IPostItem> => {
    const { resp, isError } = await apiManager.getApi('POST_ORDER_CHECKOUT', { id: ordersId });
    const response = resp as IBodyItemResponse;
    const { errors } = await response;
    return { isError, errors, resp: response };
};
