import React from 'react';
import { observer } from 'mobx-react';
import { Form } from 'antd';
import { CardLayout, Catcher, FormSelect, InputDebounce, cardLayoutOptions } from '../../';
import { useCountriesSelect } from '../../../hooks';
import { apiMessagesGetFormRules } from '../../../middleware';
import { tF } from '../../../translate';

export const EditCompanyContactInfo: React.FC = observer(() => {
    const countriesSelect = useCountriesSelect;
    const buildingTitle = tF('Address Building');
    const streetTitle = tF('Address Street');
    const numberTitle = tF('Address Number');
    const zipTitle = tF('Zip code');
    const cityTitle = tF('City');
    const countryTitle = tF('Country');

    return (
        <Catcher>
            <CardLayout
                title={'Contact Information'}
                idSpinners={['GET_COMPANY']}
                columnOption={cardLayoutOptions.cardEditCompany}
                dataTest={'EditCompanyContactInfo'}
            >
                <Form.Item
                    name="addressBuilding"
                    label={buildingTitle}
                    rules={apiMessagesGetFormRules}
                    data-test={'ContactInformationAddressBuilding'}
                >
                    <InputDebounce placeholder={buildingTitle} data-test={'ContactInformationAddressBuildingInput'} />
                </Form.Item>
                <Form.Item
                    name="addressStreet"
                    label={streetTitle}
                    rules={apiMessagesGetFormRules}
                    data-test={'ContactInformationAddressStreet'}
                >
                    <InputDebounce placeholder={streetTitle} data-test={'ContactInformationAddressStreetInput'} />
                </Form.Item>
                <Form.Item
                    name="addressNumber"
                    label={numberTitle}
                    rules={apiMessagesGetFormRules}
                    data-test={'ContactInformationAddressNumber'}
                >
                    <InputDebounce placeholder={numberTitle} data-test={'ContactInformationAddressNumberInput'} />
                </Form.Item>
                <Form.Item
                    name="zipCode"
                    label={zipTitle}
                    rules={apiMessagesGetFormRules}
                    data-test={'ContactInformationzipCode'}
                >
                    <InputDebounce placeholder={zipTitle} data-test={'ContactInformationzipCodeInput'} />
                </Form.Item>
                <Form.Item
                    name="city"
                    label={cityTitle}
                    rules={apiMessagesGetFormRules}
                    data-test={'ContactInformationCity'}
                >
                    <InputDebounce placeholder={cityTitle} data-test={'ContactInformationCityInput'} />
                </Form.Item>
                <FormSelect
                    fieldName={'countryId'}
                    label={countryTitle}
                    rules={apiMessagesGetFormRules}
                    useSelectHook={countriesSelect}
                    dataTest={'ContactInformationCountry'}
                />
            </CardLayout>
        </Catcher>
    );
});
