import React from 'react';
import { observer } from 'mobx-react';
import { Catcher, ImpersonateUserTable, ListWrapper } from '../../';
import { useUsersFilterParams } from '../../../hooks';
import { cleanUsersStore, getUsersRelationSelect } from '../../../middleware';
import Styles from '../../styles/m_itemCardStyles.less';

export const ImpersonateUser: React.FC = observer(() => {
    const filterParams = useUsersFilterParams();

    return (
        <Catcher>
            <div className={Styles.main}>
                <ListWrapper
                    cleanStore={cleanUsersStore}
                    filterParams={filterParams}
                    idSpinners={['POST_USER_IMPERSONATION']}
                    getRelationSelect={getUsersRelationSelect}
                >
                    <ImpersonateUserTable />
                </ListWrapper>
            </div>
        </Catcher>
    );
});
