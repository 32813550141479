import { action, makeObservable, observable, reaction } from 'mobx';
import { ISelect } from '../interfaces';

export class UnitTypesStore {
    private constructor() {
        makeObservable(this);
        reaction(
            () => this.select,
            () => {
                this.select.forEach((unitType) => {
                    this.unitTypeMap.set(unitType.name, unitType);
                });
            },
        );
    }

    private static instance: UnitTypesStore;
    public static getInstance(): UnitTypesStore {
        if (!UnitTypesStore.instance) {
            UnitTypesStore.instance = new UnitTypesStore();
        }
        return UnitTypesStore.instance;
    }

    @observable select: ISelect[] = [
        {
            id: '1',
            name: 'Data',
            value: 'byte',
        },
    ];
    @action setUnitTypeSelect(list: ISelect[]): void {
        this.select = list;
    }

    @observable unitTypeMap = new Map<string, ISelect>();

    @action cleanStore(): void {
        this.select = [];
    }
}
