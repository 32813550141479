import React from 'react';
import { ApartmentOutlined, EnvironmentOutlined, FileTextOutlined, PhoneOutlined } from '@ant-design/icons';
import { Catcher, FieldInfoAdvanced } from '../../';
import { PackageItemList } from './';
import { IPackageInfo } from '../../../interfaces';
import Styles from '../../styles/m_itemCardStyles.less';

interface IPackageInfoBox {
    packageInfo: IPackageInfo;
}

export const PackageInfoBox: React.FC<IPackageInfoBox> = ({ packageInfo }: IPackageInfoBox) => {
    const {
        dataVolumeName,
        name,
        currencyName,
        monthDurationName,
        price = 0,
        creditRemainsName,
        assignedCountries,
        assignedOperators,
        assignedNetworks,
        zoneName,
        notes,
    } = packageInfo;

    const layout = {
        labelCol: {
            flex: '0 1 170px',
        },
    };

    return (
        <Catcher>
            <FieldInfoAdvanced layout={layout} title={'Name'}>
                {name}
            </FieldInfoAdvanced>
            <FieldInfoAdvanced layout={layout} title={'Data volume'}>
                {dataVolumeName}
            </FieldInfoAdvanced>
            <FieldInfoAdvanced layout={layout} title={'Duration'}>
                {monthDurationName}
            </FieldInfoAdvanced>
            <FieldInfoAdvanced layout={layout} title={'Price'}>
                {currencyName} {price}
            </FieldInfoAdvanced>
            <FieldInfoAdvanced layout={layout} title={'Zone'}>
                {zoneName}
            </FieldInfoAdvanced>
            <FieldInfoAdvanced layout={layout} title={'Credit Remains'}>
                {creditRemainsName}
            </FieldInfoAdvanced>
            <FieldInfoAdvanced icon={<FileTextOutlined className={Styles.labelIcon} />} layout={layout} title={'Notes'}>
                {notes}
            </FieldInfoAdvanced>
            <FieldInfoAdvanced
                icon={<EnvironmentOutlined className={Styles.labelIcon} />}
                layout={layout}
                title={'Assigned Countries'}
            >
                <PackageItemList valueList={assignedCountries?.assignedNameArr} />
            </FieldInfoAdvanced>
            <FieldInfoAdvanced
                icon={<PhoneOutlined className={Styles.labelIcon} />}
                layout={layout}
                title={'Assigned Operators'}
            >
                <PackageItemList valueList={assignedOperators?.assignedNameArr} />
            </FieldInfoAdvanced>
            <FieldInfoAdvanced
                icon={<ApartmentOutlined className={Styles.labelIcon} />}
                layout={layout}
                title={'Assigned Networks'}
            >
                <PackageItemList valueList={assignedNetworks?.assignedNameArr} />
            </FieldInfoAdvanced>
        </Catcher>
    );
};
