import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { IFilterParams, IReportsList } from '../../interfaces';

export function useReportsTable(): IReportsList[] {
    return useContext(MobXProviderContext).RootStore.reportsStore.listAdvanced;
}

export function useReportsFilterParams(): IFilterParams {
    return useContext(MobXProviderContext).RootStore.reportsStore.filterParams;
}
