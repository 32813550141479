import React from 'react';
import { observer } from 'mobx-react';
import { Popover } from 'antd';
import { CheckCircleOutlined, InfoCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { IFieldLayout, ISelect, IShowForm } from '../../../interfaces';
import { useCardDeviceInfo, useCardInfo, useCardSetsSelect, useGeolocationString, useProfile } from '../../../hooks';
import { cleanObjectValue, dateText2Utc, objIsNotEmpty } from '../../../instruments';
import {
    CardInfoToolBar,
    CardMainLayout,
    Catcher,
    FieldInfoAdvanced,
    LinkComponent,
    cardLayoutOptions,
} from '../../../Components';
import { DevicePopover } from '../Components';

const layout: IFieldLayout = {
    labelCol: {
        flex: '0 1 150px',
    },
    valCol: {
        flex: '1 1 180px',
    },
};

export const ShowCardInfo: React.FC<IShowForm> = observer(({ loadingTypes, setEditMode }: IShowForm) => {
    const cardInfo = useCardInfo();
    const cardDeviceInfo = useCardDeviceInfo();
    const isCardDeviceInfo = objIsNotEmpty(cleanObjectValue(cardDeviceInfo));
    const geolocationString = useGeolocationString();

    const sets = useCardSetsSelect();
    const { isCompanyTypeIdMNOHost } = useProfile();
    const CardSetListLinks = sets.map((s: ISelect) => (
        <LinkComponent currentListComponent={'CardSetsList'} id={s.id} name={s.name} key={s.id} />
    ));

    // ToDo: 19.08.2021 - Printed ID must be the ICCID of the subscriber allowed in the creation of the company.

    const contentDevicePopover = <DevicePopover info={cardDeviceInfo} />;

    return (
        <Catcher>
            <CardMainLayout columnOption={cardLayoutOptions.cardInfo} idSpinners={['GET_CARD']} name={cardInfo.name}>
                <FieldInfoAdvanced layout={layout} title={'Name'} isHidden={isCompanyTypeIdMNOHost}>
                    {cardInfo.name}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced layout={layout} title={'Printed ID'}>
                    {cardInfo.printedId}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced layout={layout} title={'EID'}>
                    {cardInfo.eid}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced layout={layout} title={'Batch Id'}>
                    {cardInfo.batchId}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced layout={layout} title={'PLMN List'} isHidden={isCompanyTypeIdMNOHost}>
                    {cardInfo.plmnList}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced layout={layout} title={'IMEI'}>
                    {cardInfo.imei}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced layout={layout} title={'Locked IMEI'}>
                    {cardInfo.lockImei ? (
                        <CheckCircleOutlined title={'Locked'} />
                    ) : (
                        <WarningOutlined title={'Unlocked'} />
                    )}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced layout={layout} title={'Device manufacturer'}>
                    {cardDeviceInfo.manufacturer}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced layout={layout} title={'Device name'}>
                    {isCardDeviceInfo ? (
                        <Popover placement="right" title="Device Information" content={contentDevicePopover}>
                            <a>
                                {cardDeviceInfo.name || cardDeviceInfo.marketingName} <InfoCircleOutlined />
                            </a>
                        </Popover>
                    ) : null}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced layout={layout} title={'Device type'}>
                    {cardDeviceInfo.deviceType}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced layout={layout} title={'Status'} isHidden={isCompanyTypeIdMNOHost}>
                    {cardInfo.status}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced layout={layout} title={'First seen'} isHidden={isCompanyTypeIdMNOHost}>
                    {cardInfo.firstSeen ? dateText2Utc(cardInfo.firstSeen) : ''}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced layout={layout} title={'Last seen'} isHidden={isCompanyTypeIdMNOHost}>
                    {cardInfo.lastSeen ? dateText2Utc(cardInfo.lastSeen) : ''}
                </FieldInfoAdvanced>

                {geolocationString ? (
                    <FieldInfoAdvanced title={'Geolocation'}>{geolocationString}</FieldInfoAdvanced>
                ) : null}

                <FieldInfoAdvanced layout={layout} title={'Card Sets'} isHidden={isCompanyTypeIdMNOHost}>
                    {CardSetListLinks}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced layout={layout} title={'Notes'} isHidden={isCompanyTypeIdMNOHost}>
                    {cardInfo.notes}
                </FieldInfoAdvanced>
                <CardInfoToolBar setEditMode={setEditMode} loadingTypes={loadingTypes} isBackToList />
            </CardMainLayout>
        </Catcher>
    );
});
