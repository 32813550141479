import React from 'react';
import { IGeotrackingCardListItem } from '../../../interfaces';
import { getMarkerName } from '../../../instruments';
import Styles from '../m_googleMapsStyles.less';

interface IGoogleCardLocationMapShowMarker {
    marker: IGeotrackingCardListItem;
}

export const ShowCardsLocationMarkerInfo = ({ marker }: IGoogleCardLocationMapShowMarker): JSX.Element => {
    const name = getMarkerName(marker);
    return (
        <div className={Styles.overlayView}>
            <div className={Styles.overlayViewContent}>
                <div className={Styles.overlayViewContentTitle} title={JSON.stringify(marker)}>
                    {name}
                </div>
                {marker.id ? <div className={Styles.overlayViewContentItem}>Id: {marker.id}</div> : null}
                {marker.lat && marker.lng ? (
                    <div className={Styles.overlayViewContentItem}>
                        Lat: {marker.lat} Lng: {marker.lng}
                    </div>
                ) : null}
            </div>
        </div>
    );
};
