import { action, makeObservable, observable, reaction } from 'mobx';
import { UiStore } from './ui';
import { appConfig } from '../settings';
import { ICardSetsListItem, IFilterParams, ISelect } from '../interfaces';
import { getCardSetsList } from '../middleware';
import { apiParamsCleaner } from '../instruments';

export class CardSetsStore {
    private constructor() {
        makeObservable(this);
        reaction(
            () => this.forcedUpdateTime,
            () => {
                const UiStoreInstance = UiStore.getInstance();
                const params = UiStoreInstance.params;
                const cleanParams = apiParamsCleaner(params, this.filterParams);
                getCardSetsList(cleanParams).then();
            },
            {
                delay: appConfig.APIDelay,
            },
        );
    }

    private static instance: CardSetsStore;
    public static getInstance(): CardSetsStore {
        if (!CardSetsStore.instance) {
            CardSetsStore.instance = new CardSetsStore();
        }
        return CardSetsStore.instance;
    }

    @observable list: ICardSetsListItem[] = [];
    @action setCardSetsList(list: ICardSetsListItem[]): void {
        this.list = list;
    }

    @observable select: ISelect[] = [];
    @action setCardSetsSelect(list: ISelect[]): void {
        this.select = list;
    }

    @observable forcedUpdateTime = 0;
    @action setForcedUpdateTime(): void {
        this.forcedUpdateTime = Date.now();
    }

    @observable filterParams: IFilterParams = {
        companyId: true,
        isDelete: true,
        pageNumber: true,
        pageSize: true,
        search: true,
    };

    @action cleanStore(): void {
        this.select = [];
        this.list = [];
    }
}
