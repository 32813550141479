import { action, computed, makeObservable, observable } from 'mobx';
import { UiStore } from './ui';
import { IGetApiType } from '../REST';
import { IOperatorActivity, IOperatorInfo } from '../interfaces';

export class OperatorStore {
    private constructor() {
        makeObservable(this);
    }

    private static instance: OperatorStore;
    public static getInstance(): OperatorStore {
        if (!OperatorStore.instance) {
            OperatorStore.instance = new OperatorStore();
        }
        return OperatorStore.instance;
    }

    @observable info: IOperatorInfo = {};
    @action setOperatorInfo(info: IOperatorInfo): void {
        this.info = info;
    }

    @observable operatorActivityCurrentMonth: IOperatorActivity = {};
    @action setActivityCurrentMonth(activity: IOperatorActivity): void {
        this.operatorActivityCurrentMonth = activity;
    }
    @observable operatorActivityPrevMonth: IOperatorActivity = {};
    @action setActivityPrevMonth(activity: IOperatorActivity): void {
        this.operatorActivityPrevMonth = activity;
    }

    @observable spinnersInfo: IGetApiType[] = ['GET_OPERATOR', 'POST_OPERATOR', 'PATCH_OPERATOR'];
    @observable spinnersRelation: IGetApiType[] = ['GET_OPERATOR_ACTIVITY', 'GET_CALL_DATA_RECORDS_LAST_5'];

    @computed get spinners(): IGetApiType[] {
        return [...this.spinnersInfo, ...this.spinnersRelation];
    }

    @computed get isSpinningInfo(): boolean {
        return UiStore.getInstance().isFoundSpinner(this.spinnersInfo);
    }

    @computed get isSpinning(): boolean {
        return UiStore.getInstance().isFoundSpinner(this.spinners);
    }

    @action cleanStore(): void {
        this.info = {};
        this.operatorActivityCurrentMonth = {};
        this.operatorActivityPrevMonth = {};
    }
}
