import React, { ReactText } from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import { Catcher, LinkComponent, LoadingApiByIds } from '../../';
import { ShowSubscriberStatus } from './';
import cx from 'classnames';
import { ICardSubscriber, ISubscriberTable } from '../../../interfaces';
import { useCardSubscribers } from '../../../hooks';
import { tF } from '../../../translate';
import Styles from '../../styles/m_viewStyles.less';

export const SubscribersTable: React.FC<ISubscriberTable> = observer(
    ({ selectedRowKeys, setSelectedRowKeys }: ISubscriberTable) => {
        const subscribers = useCardSubscribers();

        const tableColumns = [
            {
                title: tF('Status'),
                dataIndex: 'showStatus',
                className: Styles.columnCenter,
            },
            {
                title: tF('Operator'),
                dataIndex: 'operatorLink',
            },
            {
                title: tF('IMSI'),
                dataIndex: 'imsiLink',
            },
            {
                title: tF('Roaming'),
                dataIndex: 'roamingImsi',
            },
            {
                title: tF('ICCID'),
                dataIndex: 'iccId',
            },
            {
                title: tF('MSISDN'),
                dataIndex: 'msisdn',
            },
            {
                title: tF('Zone'),
                dataIndex: 'zoneLink',
            },
        ];

        const tableData = subscribers.map((s: ICardSubscriber) => {
            return {
                key: s.id,
                showStatus: <ShowSubscriberStatus dispatchLock={s.dispatchLock} subStatus={s.statusNew} />,
                operatorLink: (
                    <LinkComponent currentListComponent={'OperatorsList'} id={s.operatorId} name={s.operator} />
                ),
                imsiLink: <LinkComponent currentListComponent={'SubscribersList'} id={s.id} name={s.imsi} />,
                zoneLink: <LinkComponent currentListComponent={'ZonesList'} id={s.zoneId} name={s.zone} />,
                ...s,
            };
        });
        const onSelectChange = (selectItems: ReactText[]): void => {
            const dispatchLockSubscribers = subscribers
                .filter((s: ICardSubscriber) => s.id && s.dispatchLock)
                .map((s: ICardSubscriber) => s.id);
            const select = selectItems.map((i) => i.toString()).filter((i) => !dispatchLockSubscribers.includes(i));
            setSelectedRowKeys(select);
        };
        const rowSelection = {
            selectedRowKeys,
            onChange: onSelectChange,
        };

        return (
            <Catcher>
                <div>
                    <LoadingApiByIds
                        idSpinners={[
                            'GET_SUBSCRIBERS_BY_CARD_ID',
                            'DELETE_CARD_SUBSCRIBERS',
                            'POST_SUBSCRIBERS_STATUS',
                            'GET_SUBSCRIBERS_STATUS',
                        ]}
                    />
                    <Table
                        className={cx('table-theme', 'subscribers-table')}
                        columns={tableColumns}
                        dataSource={tableData}
                        pagination={false}
                        rowSelection={rowSelection}
                        size={'middle'}
                    />
                </div>
            </Catcher>
        );
    },
);
