import React, { MouseEvent } from 'react';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/es/button/button';
import {
    CheckOutlined,
    CloseOutlined,
    PauseCircleOutlined,
    PlayCircleOutlined,
    PlusOutlined,
    SyncOutlined,
} from '@ant-design/icons';
import { getComponentInfo } from '../../Pages/pagesBook';
import cx from 'classnames';
import { IComponentName } from '../../interfaces';
import { useRouterStore } from '../../hooks';
import { isAvailablePage } from '../../middleware';
import { ITrButton, ITrNotificationMessage, ITrPage, tB, tNM, tP } from '../../translate';
import { MenuIcons } from '../../Components';
import Styles from './m_styles.less';

type IStylesType = typeof Styles;
type ICustomButtonType = keyof IStylesType;

type ICustomButton = {
    children?: never;
    disabled?: boolean;
    disabledHint?: ITrNotificationMessage;
    icon?: React.ReactNode;
    loading?: boolean;
    onClick?: React.MouseEventHandler<HTMLElement>;
    title?: ITrButton;
    type: ICustomButtonType;
    danger?: boolean;
    isGhost?: boolean;
};

export const notSelectItem: ITrNotificationMessage = 'Select item to button activate';

const getButtonTitleHint = (
    title?: ITrButton,
    disabled?: boolean,
    disabledHint?: ITrNotificationMessage,
): IGetButtonTitleHint => {
    const buttonTitle = title ? tB(title) : undefined;
    const hint = disabled && disabledHint ? tNM(disabledHint) : buttonTitle;
    return { buttonTitle, hint };
};

const makeTitledButton = (title?: ITrButton, type?: ICustomButtonType): string => {
    if (title) {
        return tB(title);
    } else {
        let showTitle: ITrButton | undefined;
        switch (type) {
            case 'activate':
                showTitle = 'Activate';
                break;
            case 'block':
                showTitle = 'Suspend';
                break;
            case 'cancel':
                showTitle = 'Cancel';
                break;
            case 'refresh':
                showTitle = 'Refresh';
                break;
            case 'unblock':
                showTitle = 'Resume';
                break;
            default:
                break;
        }
        return showTitle ? tB(showTitle) : '';
    }
};

const makeIconButton = (type?: ICustomButtonType): React.ReactNode | undefined => {
    switch (type) {
        case 'activate':
            return <CheckOutlined />;
        case 'block':
            return <PauseCircleOutlined />;
        case 'cancel':
            return <CloseOutlined />;
        case 'refresh':
            return <SyncOutlined />;
        case 'unblock':
            return <PlayCircleOutlined />;
        default:
    }
};

export const CustomButton: React.FC<ICustomButton> = ({
    disabled,
    disabledHint,
    icon,
    loading,
    onClick,
    title,
    danger,
    type,
    isGhost,
}: ICustomButton) => {
    const { hint } = getButtonTitleHint(title, disabled, disabledHint);
    return (
        <Button
            className={cx(Styles.main, { [Styles.disable]: disabled }, Styles[type])}
            disabled={disabled}
            icon={icon ? icon : makeIconButton(type)}
            loading={loading}
            onClick={onClick}
            danger={danger}
            ghost={isGhost}
            title={hint}
        >
            {makeTitledButton(title, type)}
        </Button>
    );
};

type IButton = {
    children?: never;
    dataTest?: string;
    disabled?: boolean;
    disabledHint?: ITrNotificationMessage;
    icon?: React.ReactNode;
    isGhost?: boolean;
    isHiddenTitle?: boolean;
    loading?: boolean;
    onClick?: React.MouseEventHandler<HTMLElement>;
    title?: ITrButton;
    type?: 'primary' | 'link' | 'text' | 'ghost' | 'default' | 'dashed' | undefined;
};

type IGetButtonTitleHint = {
    buttonTitle?: string;
    hint?: string;
};
export const AddButton: React.FC<IButton> = observer(
    ({ disabled, disabledHint, isGhost, isHiddenTitle, loading, onClick, title = 'Add', dataTest = '' }: IButton) => {
        const { buttonTitle, hint } = getButtonTitleHint(title, disabled, disabledHint);
        return onClick ? (
            <Button
                className={Styles.addButton}
                data-test={dataTest}
                disabled={disabled}
                ghost={isGhost}
                icon={<PlusOutlined />}
                loading={loading}
                onClick={onClick}
                title={hint}
                type="primary"
            >
                {isHiddenTitle ? null : buttonTitle}
            </Button>
        ) : null;
    },
);

export const RemoveButton: React.FC<IButton> = ({
    dataTest = '',
    disabled,
    disabledHint,
    icon,
    isGhost,
    isHiddenTitle,
    loading,
    onClick,
    title = 'Remove',
    type,
}: IButton) => {
    const { buttonTitle, hint } = getButtonTitleHint(title, disabled, disabledHint);
    return onClick ? (
        <Button
            className={isHiddenTitle ? Styles.removeButton : cx({ [Styles.disable]: disabled })}
            danger
            disabled={disabled}
            ghost={isGhost}
            icon={icon ?? <CloseOutlined />}
            loading={loading}
            onClick={onClick}
            type={type}
            data-test={dataTest}
            title={hint}
        >
            {isHiddenTitle ? null : buttonTitle}
        </Button>
    ) : null;
};

type ILinkButton = ButtonProps & {
    dataTest?: string;
    onClick?: () => void;
    pageTitle?: ITrPage;
    title?: ITrButton;
};

export const LinkButton: React.FC<ILinkButton> = ({
    dataTest,
    className,
    onClick,
    pageTitle,
    title,
    type = 'primary',
    ...props
}: ILinkButton) => {
    const goTo = (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>): void => {
        if (!(event.ctrlKey || event.metaKey || event.altKey || event.shiftKey)) {
            event.preventDefault();
            onClick && onClick();
        }
    };
    return (
        <Button
            data-test={dataTest}
            onClick={goTo}
            type={type}
            className={cx(Styles.marginButton, className)}
            {...props}
        >
            {pageTitle ? tP(pageTitle) : title ? tB(title) : undefined}
        </Button>
    );
};

type IGoPageButton = ILinkButton & {
    componentName: IComponentName;
    goToOptions?: { [key: string]: any };
    onClick?: never;
    pageTitle?: never;
};

export const GoPageButton: React.FC<IGoPageButton> = ({
    componentName,
    goToOptions = {},
    icon,
    title,
    ...props
}: IGoPageButton) => {
    const routerStore = useRouterStore();
    const component = getComponentInfo(componentName);
    const isAvailable = isAvailablePage(componentName);

    const onClick = () => {
        routerStore.goTo(componentName, goToOptions);
    };

    return (
        <LinkButton
            disabled={!isAvailable}
            href={component?.url}
            icon={icon || (component?.menuIcon && <MenuIcons iconName={component.menuIcon} />)}
            onClick={onClick}
            pageTitle={title ? undefined : (component.title as ITrPage)}
            title={title}
            {...props}
        />
    );
};
