import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { useUnitTypesSelect } from './useUnitTypesStores';
import { colorThemes } from '../../settings';
import {
    IChartData,
    IChartTitle,
    IChartTitleItem,
    IComponentName,
    IDateRange,
    IFilterBoolean,
    IFilterSelect,
    IFilterSelectComposed,
    IGetApiType,
    ILanguageId,
    IParamsList,
    ISelect,
    IUiIdSpinner,
} from '../../interfaces';
import { getApiTypeHeader, getApiTypeWithUnitType } from '../../instruments';

// ------------ Spinner ------------
export function useUiSpinnerCount(): number {
    return useContext(MobXProviderContext).RootStore.uiStore.spinnerCount;
}

export function useUiIsLoading(): boolean {
    return useContext(MobXProviderContext).RootStore.uiStore.isSpinning;
}

export function useUiSpinnerList(): IUiIdSpinner[] {
    return useContext(MobXProviderContext).RootStore.uiStore.spinners;
}
export function useUiIsSpinnerFound(ids: IGetApiType[]): boolean {
    // ToDo: 24.05.2021 - NOT USE for big components
    const spinnersIds = useUiSpinnerList();
    let isLoading = false;
    spinnersIds.forEach((spinner: IUiIdSpinner) => {
        ids.forEach((id) => {
            if (spinner.includes(getApiTypeHeader(id))) {
                isLoading = true;
            }
        });
    });

    return isLoading;
}

export function useUiIsSpinnerTypeUnit(apiTypeId: IGetApiType, unitTypeId: IFilterSelectComposed): boolean {
    const spinnersIds = useUiSpinnerList();
    const spinnerId = getApiTypeWithUnitType(apiTypeId, unitTypeId);

    return spinnersIds.includes(spinnerId);
}

// ------------ Page List ------------
export function useUiTotal(): number {
    return useContext(MobXProviderContext).RootStore.uiStore.total;
}
export function useUiPageNumber(): number {
    return useContext(MobXProviderContext).RootStore.uiStore.pageNumber;
}
export function useUiPageSize(): number {
    return useContext(MobXProviderContext).RootStore.uiStore.pageSize;
}
export function useUiDateRange(): IDateRange {
    return useContext(MobXProviderContext).RootStore.uiStore.dateRange;
}
export function useUiSearch(): IFilterSelect {
    return useContext(MobXProviderContext).RootStore.uiStore.search;
}
export function useUiCompanyId(): IFilterSelect {
    return useContext(MobXProviderContext).RootStore.uiStore.companyId;
}
export function useUiCompanyTypeId(): IFilterSelect {
    return useContext(MobXProviderContext).RootStore.uiStore.companyTypeId;
}
export function useUiPaymentType(): IFilterSelect {
    return useContext(MobXProviderContext).RootStore.uiStore.paymentType;
}
export function useUiPackageId(): IFilterSelect {
    return useContext(MobXProviderContext).RootStore.uiStore.packageId;
}
export function useUiOperatorId(): IFilterSelect {
    return useContext(MobXProviderContext).RootStore.uiStore.operatorId;
}
export function useUiCardSetId(): IFilterSelect {
    return useContext(MobXProviderContext).RootStore.uiStore.cardSetId;
}
export function useUiSessionId(): IFilterSelect {
    return useContext(MobXProviderContext).RootStore.uiStore.sessionId;
}
export function useUiZoneId(): IFilterSelect {
    return useContext(MobXProviderContext).RootStore.uiStore.zoneId;
}
export function useUiUserId(): IFilterSelect {
    return useContext(MobXProviderContext).RootStore.uiStore.userId;
}
export function useUiOperatorInteractionsUploadFileStatusId(): IFilterSelect {
    return useContext(MobXProviderContext).RootStore.uiStore.uploadFileStatusId;
}
export function useUiOperatorInteractionsUploadFileId(): IFilterSelect {
    return useContext(MobXProviderContext).RootStore.uiStore.fileId;
}
export function useUiOperatorInteractionsGatewayId(): IFilterSelect {
    return useContext(MobXProviderContext).RootStore.uiStore.gatewayId;
}
export function useUiOperatorInteractionsCommandId(): IFilterSelect {
    return useContext(MobXProviderContext).RootStore.uiStore.commandId;
}
export function useUiEntityId(): IFilterSelect {
    return useContext(MobXProviderContext).RootStore.uiStore.entityId;
}
export function useUiUnitTypeIds(): IFilterSelect {
    return useContext(MobXProviderContext).RootStore.uiStore.unitTypeIds;
}
export function useUiSource(): IFilterSelect {
    return useContext(MobXProviderContext).RootStore.uiStore.source;
}
export function useUiIsDelete(): IFilterBoolean {
    return useContext(MobXProviderContext).RootStore.uiStore.isDelete;
}
export function useUiWithDeleted(): IFilterBoolean {
    return useContext(MobXProviderContext).RootStore.uiStore.withDeleted;
}
export function useUiIsActiveThisMonth(): IFilterBoolean {
    return useContext(MobXProviderContext).RootStore.uiStore.isActiveThisMonth;
}
export function useUiLastViewComponentName(): IComponentName {
    return useContext(MobXProviderContext).RootStore.uiStore.lastViewComponentName;
}

export function useUiViewInfo(): IParamsList {
    const {
        cardSetId,
        commandId,
        companyId,
        companyTypeId,
        dateRange: { startDate, endDate },
        entityId,
        fileId,
        gatewayId,
        isDelete,
        operatorId,
        pageNumber,
        pageSize,
        paymentType,
        routeName,
        search,
        sessionId,
        unitTypeIds,
        uploadFileStatusId,
        userId,
        withDeleted,
        zoneId,
    } = useContext(MobXProviderContext).RootStore.uiStore;
    return {
        cardSetId,
        commandId,
        companyId,
        companyTypeId,
        endDate,
        entityId,
        fileId,
        gatewayId,
        isDelete,
        operatorId,
        pageNumber,
        pageSize,
        paymentType,
        routeName,
        search,
        sessionId,
        startDate,
        unitTypeIds,
        uploadFileStatusId,
        userId,
        withDeleted,
        zoneId,
    };
}

export function useUiDarkTheme(): boolean {
    return useContext(MobXProviderContext).RootStore.uiStore.isDarkTheme;
}

export function useUiLanguage(): ILanguageId {
    return useContext(MobXProviderContext).RootStore.uiStore.languageId;
}

export function useUiChangedTheme(): boolean {
    return useContext(MobXProviderContext).RootStore.uiStore.isChangedTheme;
}

export function useUiInitPageList() {
    return useContext(MobXProviderContext).RootStore.uiStore.initPageList;
}

export function useUiCurrentUnitType(): ISelect | undefined {
    const currentUnitTypeId = useContext(MobXProviderContext).RootStore.uiStore.unitTypeIds;
    const unitTypesSelect = useUnitTypesSelect();

    return unitTypesSelect?.find((i: ISelect) => i.id === currentUnitTypeId);
}

type IAxis = {
    format?: string;
    gridlines?: { color: string };
    maxValue?: number;
    minValue?: number;
    textStyle?: { color: string };
    ticks?: number[];
    title?: string;
    viewWindow?: {
        max?: number;
        min?: number;
    };
    // [otherOptionKey: string]: any;
};

type IUiChartsSettingsAnnotations = {
    alwaysOutside?: boolean;
    highContrast?: boolean;
    textStyle?: {
        auraColor?: string;
        fontName?: string;
        fontSize?: number;
        bold?: boolean;
        italic?: boolean;
        color?: string;
        opacity?: number;
    };
};
export type IUiChartsSettings = {
    backgroundColor: string;
    chartBackground: string;
    textColor: string;
    annotationsOutside?: IUiChartsSettingsAnnotations;
    annotationsInside?: IUiChartsSettingsAnnotations;
    hAxis?: IAxis;
    vAxis?: IAxis;
    animation?: {
        duration: number;
        easing: 'out';
        startup: boolean;
    };
};

export function useChartsSettings(): IUiChartsSettings {
    const isDark = useContext(MobXProviderContext).RootStore.uiStore.isDarkTheme;

    const backgroundColor = isDark
        ? colorThemes.dark['--main-background-color']
        : colorThemes.light['--main-background-color'];
    const textColor = isDark ? colorThemes.dark['--main-text-color'] : colorThemes.light['--main-text-color'];

    return {
        backgroundColor,
        chartBackground: 'transparent',
        textColor,
        annotationsOutside: {
            alwaysOutside: true,
            highContrast: true,
            textStyle: {
                auraColor: backgroundColor,
            },
        },
        annotationsInside: {
            alwaysOutside: false,
            highContrast: true,
            textStyle: {
                auraColor: backgroundColor,
                color: textColor,
            },
        },
        hAxis: {
            textStyle: { color: textColor },
            gridlines: {
                color: textColor,
            },
        },
        vAxis: {
            format: 'short',
            textStyle: { color: textColor },
            gridlines: {
                color: textColor,
            },
        },
        animation: {
            duration: 700,
            easing: 'out',
            startup: true,
        },
    };
}

export const getCartColorTheme = (sourceData?: IChartData): Array<string> | undefined => {
    if (sourceData && sourceData[0]?.length) {
        const chartTitle = sourceData[0] as IChartTitle;
        const columnCount = chartTitle.filter(
            (i: IChartTitleItem): boolean => typeof i !== 'string' && i.role !== 'annotationText',
        ).length;
        switch (columnCount) {
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
                return ['#0075e7', '#2f8e00', '#ac0033', '#d404f9', '#fc9100'];
            case 6:
            case 8:
            case 10:
                return [
                    '#0075e7',
                    '#bfdbf4',
                    '#2f8e00',
                    '#9fea9f',
                    '#ac0033',
                    '#e19daf',
                    '#d404f9',
                    '#eaabf5',
                    '#fc9100',
                    '#f8c075',
                ];
            default:
                return undefined;
        }
    } else {
        return undefined;
    }
};
