import { equalValue } from './index';
import { appConfig } from '../settings';
import { IGeoLocation, INumberOrUndefined } from '../interfaces';
import { tF } from '../translate';

export const isRealLocation = (location: IGeoLocation): boolean => {
    return (
        Math.abs(location.lat) <= 90 &&
        Math.abs(location.lat) >= -90 &&
        Math.abs(location.lng) <= 180 &&
        Math.abs(location.lng) >= -180 &&
        !(location.lat === 0 && location.lng === 0)
    );
};

export const getCenter = (list: IGeoLocation[]): IGeoLocation => {
    const geoList = list.filter((i: IGeoLocation) => !!i.lat && !!i.lng);
    if (geoList.length > 1) {
        return geoList.reduce((a: IGeoLocation, b: IGeoLocation): IGeoLocation => {
            return { lat: (a.lat + b.lat) / 2, lng: (a.lng + b.lng) / 2 };
        });
    } else if (geoList.length === 1 && isRealLocation(geoList[0])) {
        return { lat: geoList[0].lat, lng: geoList[0].lng };
    } else {
        return appConfig.googleMapCenter;
    }
};

export const getZoom = (list: IGeoLocation[]): number => {
    if (equalValue(getCenter(list), appConfig.googleMapCenter)) {
        return appConfig.googleMapOptions.minZoom;
    }

    let latMin: INumberOrUndefined,
        latMax: INumberOrUndefined,
        latDiff: INumberOrUndefined,
        lngMin: INumberOrUndefined,
        lngMax: INumberOrUndefined,
        lngDiff: INumberOrUndefined;

    list.forEach((i: IGeoLocation) => {
        latMin = typeof latMin === 'undefined' ? i.lat : Math.min(latMin, i.lat);
        latMax = typeof latMax === 'undefined' ? i.lat : Math.max(latMax, i.lat);
        lngMin = typeof lngMin === 'undefined' ? i.lng : Math.min(lngMin, i.lng);
        lngMax = typeof lngMax === 'undefined' ? i.lng : Math.max(lngMax, i.lng);
    });

    if (
        typeof latMin !== 'undefined' &&
        typeof latMax !== 'undefined' &&
        typeof lngMin !== 'undefined' &&
        typeof lngMax !== 'undefined'
    ) {
        latDiff = latMax - latMin;
        lngDiff = lngMax - lngMin;

        const diff = Math.max(lngDiff, latDiff);
        const dif2 = Math.sqrt(180 / ((diff / 360) * 110));

        if (dif2 > appConfig.googleMapOptions.maxZoom) {
            return appConfig.googleMapOptions.maxZoom;
        } else if (dif2 < appConfig.googleMapOptions.minZoom) {
            return appConfig.googleMapOptions.minZoom;
        } else {
            return Math.floor(dif2);
        }
    }

    return appConfig.googleMapOptions.minZoom;
};

type IGetMarkerName = {
    id?: string;
    name?: string;
};

export const getMarkerName = (m: IGetMarkerName): string => {
    return m?.name || m?.id || tF('Unknown');
};
