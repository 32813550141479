import { UnitTypesStore } from '../stores/unitType';
import { apiManager } from '../REST';
import { DashboardUnitTypes, IItemAPI, ISelect } from '../interfaces';
import { mapApi2UiField, sortByName } from '../instruments';

export function setUnitTypesSelect(data: IItemAPI[]): void {
    const UnitTypesInstance = UnitTypesStore.getInstance();

    UnitTypesInstance.setUnitTypeSelect(
        data
            .map((i) => {
                return {
                    id: i.id,
                    name: i.attributes.name || i.id,
                    ...mapApi2UiField(i.attributes || {}),
                };
            })
            .sort(sortByName),
    );
}

export async function getUnitTypes(): Promise<void> {
    const { resp, isError } = await apiManager.getApi('GET_UNIT_TYPE_SELECT');
    !isError && resp && setUnitTypesSelect(resp.data as IItemAPI[]);
}

export function getUnitTypesSelect(type: DashboardUnitTypes): ISelect | undefined {
    const UnitTypeStoreInstance = UnitTypesStore.getInstance();
    return UnitTypeStoreInstance.unitTypeMap.get(type);
}

export function getUnitTypesSelectById(id?: string): ISelect | undefined {
    const UnitTypeStoreInstance = UnitTypesStore.getInstance();

    let foundType;

    for (const value of Object.values(UnitTypeStoreInstance.select)) {
        if (id === value.id) {
            foundType = value;
        }
    }

    return foundType;
}

export function getAllUnitTypesSelect(): ISelect[] {
    const UnitTypeStoreInstance = UnitTypesStore.getInstance();
    return UnitTypeStoreInstance.select;
}

export function cleanUnitTypeUserStore(): void {
    const UnitTypesInstance = UnitTypesStore.getInstance();
    UnitTypesInstance.cleanStore();
}
