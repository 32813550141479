import React from 'react';
import { observer } from 'mobx-react';
import { Col, Row, Table } from 'antd';
import { Catcher, LinkComponent } from '../../';
import { IMyPurchaseRateListTableItem } from '../../../interfaces';
import { useMyPurchaseRatesList, useUiIsLoading } from '../../../hooks';
import { tF, tP } from '../../../translate';
import '../../../customStyle/table.less';
import Styles from '../../styles/m_viewStyles.less';

export const MyPurchaseListTable: React.FC = observer(() => {
    const isLoading = useUiIsLoading();
    const list = useMyPurchaseRatesList();
    if (!list.length) {
        return null;
    }

    const tableColumns = [
        {
            title: tF('Name'),
            dataIndex: 'nameLink',
        },
        {
            title: tF('Company'),
            dataIndex: 'companyLink',
        },
        {
            title: tF('Start Date'),
            dataIndex: 'startDate',
            width: 180,
        },
        {
            title: tF('End Date'),
            dataIndex: 'endDate',
            width: 180,
        },
    ];
    const tableData = list.map((p: IMyPurchaseRateListTableItem) => {
        const key = p.id;

        return {
            key,
            nameLink: <LinkComponent currentListComponent={'SalesRateLists'} id={p.id} isLight name={p.name} />,
            companyLink: (
                <LinkComponent currentListComponent={'CompaniesList'} id={p.companyId} isLight name={p.company} />
            ),
            ...p,
        };
    });

    return (
        <Catcher>
            <Row className={Styles.header} justify={'space-between'}>
                <Col xs={24} lg={8} className={Styles.title}>
                    {tP('My Purchase Rates')} ({list.length})
                </Col>
            </Row>
            <div className={Styles.tablePlace}>
                <Table
                    dataSource={tableData}
                    columns={tableColumns}
                    pagination={false}
                    className={'table-theme'}
                    size={'middle'}
                    loading={isLoading}
                />
            </div>
            <div className={Styles.footer} />
        </Catcher>
    );
});
