import React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import { GroupOutlined } from '@ant-design/icons';
import { IFeatureFlagAttributes } from '../../../interfaces';
import { useFeatureFlagsList, usePagesStoreThisListRight } from '../../../hooks';
import { tF } from '../../../translate';
import {
    CardLayout,
    Catcher,
    LinkComponent,
    TableItemToolbar,
    cardLayoutOptions,
    columnToolbar,
    showIconBoolOrNumber,
} from '../../../Components';

export const FeatureFlagList: React.FC = observer(() => {
    const list = useFeatureFlagsList();
    const right = usePagesStoreThisListRight();
    const tableColumns = [
        {
            title: tF('Name'),
            dataIndex: 'linkName',
        },
        {
            title: 'User Ids',
            dataIndex: 'userIds',
        },
        {
            title: 'Company Ids',
            dataIndex: 'companyIds',
        },
        {
            title: 'Value',
            dataIndex: 'value',
        },
        columnToolbar,
    ];

    const tableData = list.map((item: IFeatureFlagAttributes) => ({
        key: item.key,
        linkName: <LinkComponent currentListComponent={'FeatureFlags'} id={item.key} name={item.key} isOpenAsEdit />,
        userIds: item.conditions?.userIds,
        companyIds: item.conditions?.companyIds,
        value: showIconBoolOrNumber(item.value),
        toolbar: <TableItemToolbar id={item.key} isView={false} isDelete={false} {...right} />,
    }));

    return (
        <Catcher>
            <CardLayout
                columnOption={cardLayoutOptions.cardDeviceFilesTable}
                count={list.length}
                icon={<GroupOutlined />}
                idSpinners={['GET_FEATURE_FLAG_LIST_ALL', 'PATCH_COMPANY_SETTINGS']}
                isTable
                title={'Feature Flags'}
            >
                <Table
                    dataSource={tableData}
                    columns={tableColumns}
                    pagination={false}
                    className={'table-theme'}
                    size={'small'}
                />
            </CardLayout>
        </Catcher>
    );
});
