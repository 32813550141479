import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { ICardSetsListItem, IFilterParams, ISelectList } from '../../interfaces';

export function useCardSetsSelect(): ISelectList {
    return useContext(MobXProviderContext).RootStore.cardSetsStore.select;
}

export function useCardSetsList(): ICardSetsListItem[] {
    return useContext(MobXProviderContext).RootStore.cardSetsStore.list;
}

export function useCardSetsFilterParams(): IFilterParams {
    return useContext(MobXProviderContext).RootStore.cardSetsStore.filterParams;
}
