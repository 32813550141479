import React from 'react';
import { observer } from 'mobx-react';
import { Row } from 'antd';
import { Catcher } from '../../';
import { ShowInfo, ShowPurchasesList } from './';
import { IShowForm } from '../../../interfaces';
import Styles from '../../styles/m_itemCardStyles.less';

export const ShowPurchase: React.FC<IShowForm> = observer(({ setEditMode }: IShowForm) => {
    return (
        <Catcher>
            <div className={Styles.main}>
                <Row>
                    <ShowInfo setEditMode={setEditMode} />
                </Row>
                <ShowPurchasesList />
            </div>
        </Catcher>
    );
});
