import React from 'react';
import { Form } from 'antd';
import { InputDebounce } from './';
import { NamePath, Rule } from 'rc-field-form/lib/interface';
import { IDivMainProps } from '../../interfaces';
import { ITrField, tF } from '../../translate';
import { Catcher } from '../Catcher';

type IFormStringDebounceField = IDivMainProps & {
    children?: never;
    dataTest?: string;
    label: ITrField;
    name: NamePath;
    rules?: Rule[];
};

export const FormStringDebounceField: React.FC<IFormStringDebounceField> = ({
    className,
    label,
    name,
    rules,
}: IFormStringDebounceField) => {
    const title = tF(label);
    return (
        <Catcher>
            <Form.Item name={name} rules={rules} className={className} label={title}>
                <InputDebounce placeholder={title} />
            </Form.Item>
        </Catcher>
    );
};
