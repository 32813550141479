import { CatalogsStore } from '../stores/catalogs';
import { addNotification } from './ui';
import { isValidLocalCompanyTypeDictionary } from '../settings';
import { IBodyListResponse, apiManager } from '../REST';
import { ICatalogsUnitItem, IItemAPI, ISelect } from '../interfaces';
import { sortByName } from '../instruments';

export function cleanCatalogsStore(): void {
    const StoreInstance = CatalogsStore.getInstance();
    StoreInstance.cleanStore();
}

function setUnitStore(apiList: IBodyListResponse): void {
    if (apiList.data) {
        const list: ICatalogsUnitItem[] = apiList.data.map((i: IItemAPI) => {
            const item: ICatalogsUnitItem = {
                id: i.id,
                unit: i.attributes.unit,
            };
            return item;
        });
        const StoreInstance = CatalogsStore.getInstance();
        StoreInstance.setUnitList(list);
    }
}

export const getUnitLabel = (unit = '', currency = 'Cur.'): string => {
    let viewCode;
    switch (unit) {
        case 'percent':
            viewCode = '%';
            break;
        case 'amount':
            viewCode = currency;
            break;
        default:
            viewCode = '????';
            break;
    }
    return viewCode;
};

export async function getCatalogsUnitList(): Promise<void> {
    const { resp } = await apiManager.getApi('GET_RATE_UNIT_SELECT');
    resp && setUnitStore(resp as IBodyListResponse);
}

export function makeSelectList(apiList: IBodyListResponse, isOnlyAvailable?: boolean): ISelect[] {
    let data = apiList?.data;

    if (isOnlyAvailable) {
        data = data?.filter((i: IItemAPI) => !i.attributes.deleted_at);
    }

    return (
        data
            ?.map((i: IItemAPI) => {
                const item: ISelect = {
                    id: i.id,
                    name: i.attributes.name,
                };
                return item;
            })
            .sort(sortByName) || []
    );
}

function setCatalogsCompanyTypeSelect(apiList: IBodyListResponse): void {
    const list = makeSelectList(apiList);
    if (isValidLocalCompanyTypeDictionary(list)) {
        const StoreInstance = CatalogsStore.getInstance();
        StoreInstance.setCompanyTypes(list);
    } else {
        console.error('Invalid Company Types Dictionary');
        addNotification({
            type: 'error',
            message: `Invalid Company Types Dictionary`,
            duration: 0,
        });
    }
}

export async function getCatalogsCompanyTypeSelect(): Promise<void> {
    const { resp } = await apiManager.getApi('GET_COMPANY_TYPES_SELECT');
    resp && setCatalogsCompanyTypeSelect(resp as IBodyListResponse);
}

function setCatalogsPaymentTypeSelect(apiList: IBodyListResponse): void {
    const StoreInstance = CatalogsStore.getInstance();
    StoreInstance.setPaymentTypes(makeSelectList(apiList));
}

export async function getCatalogsPaymentTypeSelect(): Promise<void> {
    const { resp } = await apiManager.getApi('GET_PAYMENT_SELECT');
    resp && setCatalogsPaymentTypeSelect(resp as IBodyListResponse);
}
export async function getCatalogsOrderPaymentTypesSelect(): Promise<void> {
    const { resp } = await apiManager.getApi('GET_ORDER_PAYMENT_SELECT');
    resp && setCatalogsPaymentTypeSelect(resp as IBodyListResponse);
}

function setCatalogsProductTypeSelect(apiList: IBodyListResponse): void {
    const StoreInstance = CatalogsStore.getInstance();
    StoreInstance.setPaymentTypes(makeSelectList(apiList));
}

export async function getCatalogsOrderProductTypesSelect(): Promise<void> {
    const { resp } = await apiManager.getApi('GET_ORDER_PRODUCT_SELECT');
    resp && setCatalogsProductTypeSelect(resp as IBodyListResponse);
}
