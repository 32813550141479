import { IItemAPI } from './included';
import { appConfig } from '../settings';
import { IMeta, IRelationshipItem } from '../REST';

export const uploadFilesStatusTypeKeys: string[] = appConfig.uploadFilesStatus.map((i) => i.id);
export type IUploadFilesStatusType = (typeof uploadFilesStatusTypeKeys)[number];
export interface IOperatorInteractionsFileListAPI {
    data?: IOperatorInteractionsFileAPI[];
    meta?: IMeta;
}

export type IOperatorInteractionsFileAPI = {
    id: string;
    type: 'file';
    attributes?: {
        created_at?: string;
        file?: string;
        gateway?: any;
        statistics?: any;
        status?: string;
    };
};
export type IOperatorInteractionsFile = {
    id: string;
    createdAt?: string;
    file?: string;
    fileName?: string;
    gateway?: any;
    statistics?: any;
    status?: IUploadFilesStatusType;
};

export interface IOperatorInteractionsResponseListAPI {
    data?: IOperatorInteractionsResponseItemAPI[];
    included?: IItemAPI[];
    meta?: IMeta;
}

export type IOperatorInteractionsResponsesData = {
    number?: string;
    numberlistrequestid?: string;
    plandate?: string;
};

export type IOperatorInteractionsResponsesErrorObj = {
    errorcode?: string;
    errordescription?: string;
};
export type IOperatorInteractionsResponsesError = {
    error?: string | IOperatorInteractionsResponsesErrorObj;
};

export interface IOperatorInteractionsResponseAPI {
    data?: IOperatorInteractionsResponseItemAPI;
    included?: IItemAPI[];
}

export type IOperatorInteractionsResponseItemAPI = {
    id: string;
    type: 'response';
    attributes?: {
        status?: string;
        gateway?: string;
        iccid?: string;
        msisdn?: string | null;
        operation_name?: string;
        operation_id?: string;
        created_at?: string;
        data?: IOperatorInteractionsResponsesData | IOperatorInteractionsResponsesError;
    };
    relationships?: {
        file?: {
            data?: IRelationshipItem;
        };
    };
};

export type IOperatorInteractionsResponseFile = {
    createdAt?: string;
    file?: string;
    fileName?: string;
    gateway?: string;
    statistics?: string;
    status?: string;
};
export type IOperatorInteractionsResponse = {
    id: string;
    file?: IOperatorInteractionsResponseFile;
    status?: IUploadFilesStatusType;
    gateway?: string;
    iccId?: string;
    msisdn?: string;
    operationName?: string;
    operationId?: string;
    createdAt?: string;
    data?: IOperatorInteractionsResponsesData | IOperatorInteractionsResponsesError;
};
