import { Rule } from 'antd/es/form';
import { tV } from '../../translate';

export const makeRuleEmail = (): Array<Rule> => [
    { type: 'email', message: tV('The input is not valid Email!') },
    { required: true, message: tV('Please input your Email!') },
];

export const makeRuleFieldBlank = (): Array<Rule> => [{ required: true, message: tV(`Cannot be Empty.`) }];
export const makeRuleFirstName = (): Array<Rule> => [{ required: true, message: tV('Please input First Name!') }];
export const makeRuleLastName = (): Array<Rule> => [{ required: true, message: tV('Please input Last Name!') }];
export const makeRulePassword = (): Array<Rule> => [{ required: true, message: tV('Please input your password!') }];
