/* eslint-disable */
import { IncludedStore } from '../stores/included';
import dayjs from 'dayjs';
import { appConfig } from '../settings';
import {
    IBodyItemResponse,
    IBodyListResponse,
    ICardGeolocationInfo,
    IIncludeAssignedCompanyIncludeItemPostAPI,
    IIncludeAssignedCompanyItem,
    IIncludeAssignedCompanyItemAPI,
    IIncludeAssignedCompanyPostAPI,
    IIncludeCombinationCardSetCardItem,
    IIncludeCombinationCardSetCardItemGetAPI,
    IIncludeCombinationCardSetCardItemPostAPI,
    IIncludeCombinationCardSetCardSubscribersItem,
    IIncludeCombinationCardSetCardsPostAPI,
    IIncludeDeviceLocationItemAPI,
    IIncludeMainItem,
    IIncludeNameItemAPI,
    IIncludeOperatorInfoItemAPI,
    IIncludeTypeUi,
    IIncludedStoreCurrencyList,
    IItemAPI,
    IRelationshipItem,
    IRelationshipsItemPOSTAPI,
} from '../interfaces';
import { mapApi2UiField } from '../instruments';

export function addIncludedCurrencyList(currencyList: IIncludedStoreCurrencyList): void {
    const IncludedStoreInstance = IncludedStore.getInstance();
    IncludedStoreInstance.addCurrency(currencyList);
}

// ------------ NEW Include ------------

export function cleanIncludedStore(): void {
    const StoreInstance = IncludedStore.getInstance();
    StoreInstance.cleanStore();
}
export function getIncludeTypeList(type: IIncludeTypeUi): IIncludeMainItem[] {
    const StoreInstance = IncludedStore.getInstance();
    return StoreInstance.lists[type] || [];
}
export function getIncludeTypeListActive(type: IIncludeTypeUi): IIncludeMainItem[] {
    return getIncludeTypeList(type).filter((i) => !i.destroy);
}

export function setIncludedList(type: IIncludeTypeUi, list: IIncludeMainItem[]): void {
    const StoreInstance = IncludedStore.getInstance();
    const oldList = StoreInstance.lists[type];
    if (JSON.stringify(list) !== JSON.stringify(oldList)) {
        StoreInstance.setList(type, list);
    }
}
export function updateIncludedList(type: IIncludeTypeUi, updatedList: IIncludeMainItem[]): void {
    if (updatedList.length) {
        const list = getIncludeTypeList(type);
        const newList: IIncludeMainItem[] = list.map((includeItem) => {
            const foundInclude = updatedList.find((i) => i.id === includeItem.id);
            if (foundInclude) {
                if (JSON.stringify(includeItem) === JSON.stringify(foundInclude)) {
                    return includeItem;
                } else {
                    return { ...foundInclude, isChanged: true };
                }
            } else {
                return includeItem;
            }
        });
        setIncludedList(type, newList);
    }
}

export function addIncludedList(type: IIncludeTypeUi, item: IIncludeMainItem): void {
    const StoreInstance = IncludedStore.getInstance();
    StoreInstance.addListItem(type, { ...item, isChanged: true });
}

export function removeIncludedList(type: IIncludeTypeUi, id: string): void {
    const StoreInstance = IncludedStore.getInstance();
    StoreInstance.removeListItem(type, id);
}

export function clearIncludedList(type: IIncludeTypeUi): void {
    const StoreInstance = IncludedStore.getInstance();
    StoreInstance.clearList(type);
}

// ------------ AssignedCompanies ------------
export const setIncludedAssignedCompaniesAPI = (resp: IBodyItemResponse): void => {
    const { data, included } = resp;
    const assignedCompaniesInclude = included?.filter((i: IRelationshipItem) => i.type === 'assigned_company') || [];
    const companiesInclude = included?.filter((i: IRelationshipItem) => i.type === 'company') || [];
    const assignedCompanies: IIncludeAssignedCompanyItem[] =
        (data?.relationships?.assigned_companies?.data as IRelationshipItem[])?.map((c: IRelationshipItem) => {
            const recordId = c.id;
            const fondRecord = assignedCompaniesInclude.find((i) => i.id === recordId);
            const fondRecordAttributes = fondRecord?.attributes && mapApi2UiField(fondRecord?.attributes);
            const companyId = (fondRecord as IIncludeAssignedCompanyItemAPI)?.relationships?.company?.data?.id || '';
            const company =
                (companiesInclude.find((i) => i.id === companyId) as IIncludeNameItemAPI)?.attributes?.name || '';
            return {
                id: recordId,
                ...fondRecordAttributes,
                startDate: fondRecordAttributes?.startDate ? dayjs(fondRecordAttributes?.startDate) : undefined,
                endDate: fondRecordAttributes?.endDate ? dayjs(fondRecordAttributes?.endDate) : undefined,
                companyId,
                company,
            };
        }) || [];
    setIncludedList('assignedCompanies', assignedCompanies);
};

export const getIncludedAssignedCompanies = (): IIncludeAssignedCompanyItem[] => {
    return getIncludeTypeList('assignedCompanies') as IIncludeAssignedCompanyItem[];
};

export const getIncludedAssignedCompaniesActive = (): IIncludeAssignedCompanyItem[] => {
    return getIncludeTypeListActive('assignedCompanies') as IIncludeAssignedCompanyItem[];
};

export const getIncludeAssignedCompaniesAPI = (): IIncludeAssignedCompanyPostAPI => {
    const assignedCompanies = getIncludedAssignedCompanies();
    const assignedCompanyIncludes: IIncludeAssignedCompanyIncludeItemPostAPI[] = [];
    const assignedCompanyRelationships: IRelationshipsItemPOSTAPI[] = assignedCompanies
        .filter((i: IIncludeAssignedCompanyItem) => i.isChanged)
        .map((i: IIncludeAssignedCompanyItem) => {
            const rel: IRelationshipsItemPOSTAPI = {
                id: i.id,
                type: 'assigned_company',
                destroy: i.destroy,
            };
            if (!i.destroy) {
                const inc: IIncludeAssignedCompanyIncludeItemPostAPI = {
                    id: i.id,
                    type: 'assigned_company',
                    attributes: {
                        company_id: i.companyId,
                        start_date: i.startDate?.isValid()
                            ? dayjs(i.startDate).format(appConfig.formatDate)
                            : undefined,
                        end_date: i.endDate?.isValid() ? dayjs(i.endDate).format(appConfig.formatDate) : undefined,
                    },
                };
                assignedCompanyIncludes.push(inc);
            }
            return rel;
        });

    return { assignedCompanyRelationships, assignedCompanyIncludes };
};

// ------------ CardSet CombinationCardSetCard ------------
export const setIncludedCombinationCardSetTotalAmount = (total: number): void => {
    const StoreInstance = IncludedStore.getInstance();
    StoreInstance.setCombinationCardSetTotalAmount(total);
};

export const setIncludedCombinationCardSetCardAPI = (resp: IBodyItemResponse): void => {
    const { data, included, meta } = resp as IBodyListResponse;
    const deviceLocations: IIncludeDeviceLocationItemAPI[] =
        included?.filter((i: IRelationshipItem) => i.type === 'device_location') || [];
    const cardsList: IIncludeCombinationCardSetCardItem[] =
        data?.map((cc: IItemAPI) => {
            const card = cc as IIncludeCombinationCardSetCardItemGetAPI;
            const attributes = card.attributes ? mapApi2UiField(card.attributes) : {};
            const id = `${attributes.cardCombinationId}`;
            const cardId = card.id;
            const deviceLocationId = card?.relationships?.device_location?.data?.id;
            const foundDeviceInfo = deviceLocations.find(
                (i: IRelationshipItem) => i.id === deviceLocationId,
            )?.attributes;
            const deviceInfo: ICardGeolocationInfo | null = foundDeviceInfo ? mapApi2UiField(foundDeviceInfo) : null;
            const name = attributes?.name;
            const shortId = attributes?.shortId;
            const showName = name && shortId ? `${name} - ${shortId}` : name ? name : shortId ? shortId : card.id;
            const isActive = attributes?.isActive;
            const includeCombinationCardSetCardItem: IIncludeCombinationCardSetCardItem = {
                ...attributes,
                id,
                name,
                shortId,
                isActive,
                cardId,
                showName,
                startDate: attributes?.startDate ? dayjs(attributes?.startDate).utc() : undefined,
                endDate: attributes?.endDate ? dayjs(attributes?.endDate).utc() : undefined,
                deviceInfo,
            };

            return includeCombinationCardSetCardItem;
        }) || [];
    setIncludedList('combinationCardSetCards', cardsList);
    setIncludedCombinationCardSetTotalAmount(meta?.records_count || 0);
};

export const setIncludedCombinationCardSetSubscribersAPI = (resp: IBodyListResponse): void => {
    const { data, included } = resp;
    const operatorsInclude: IIncludeOperatorInfoItemAPI[] =
        included?.filter((i: IRelationshipItem) => i.type === 'operator') || [];
    const subscribersList: IIncludeCombinationCardSetCardSubscribersItem[] = [];
    data?.forEach((s) => {
        const imsi = s?.attributes?.imsi;
        const operator = s?.relationships?.operator?.data as IRelationshipItem;
        const foundOperator = operatorsInclude.find((i) => i.id === operator.id);
        const operatorName = foundOperator?.attributes?.name;
        const subscriber: IIncludeCombinationCardSetCardSubscribersItem = {
            id: s.id,
            subscriberId: s.id,
            imsi,
            operatorId: operator.id,
            operatorName,
        };
        subscribersList.push(subscriber);
    });
    setIncludedList('combinationCardSetCardSubscribers', subscribersList);
};

export const getIncludedCombinationCardSetCard = (): IIncludeCombinationCardSetCardItem[] => {
    return getIncludeTypeList('combinationCardSetCards') as IIncludeCombinationCardSetCardItem[];
};

export const getIncludedCombinationCardSetCardActive = (): IIncludeCombinationCardSetCardItem[] => {
    return getIncludeTypeListActive('combinationCardSetCards') as IIncludeCombinationCardSetCardItem[];
};

export const getIncludedCombinationCardSetCardsAPI = (
    geolocationEnabled?: boolean,
): IIncludeCombinationCardSetCardsPostAPI => {
    const cards = getIncludedCombinationCardSetCard();
    const combinationCardSetCardsIncludes: IIncludeCombinationCardSetCardItemPostAPI[] = [];
    const combinationCardSetCardsRelationships: IRelationshipsItemPOSTAPI[] = cards
        .filter((c: IIncludeCombinationCardSetCardItem) => c.isActive)
        .filter((c: IIncludeCombinationCardSetCardItem) => c.isChanged)
        .map((c: IIncludeCombinationCardSetCardItem) => {
            const rel: IRelationshipsItemPOSTAPI = {
                id: c.id,
                type: 'card_combination',
                destroy: c.destroy,
            };
            if (!c.destroy) {
                const inc: IIncludeCombinationCardSetCardItemPostAPI = {
                    id: c.id,
                    type: 'card_combination',
                    attributes: {
                        card_id: c.cardId,
                        start_date: c.startDate?.isValid()
                            ? dayjs(c.startDate).format(appConfig.formatDate)
                            : undefined,
                        end_date: c.endDate?.isValid() ? dayjs(c.endDate).format(appConfig.formatDate) : undefined,
                        geolocation_enabled: c.geolocationEnabled,
                    },
                };

                if (!geolocationEnabled) {
                    delete inc.attributes?.geolocation_enabled;
                }

                combinationCardSetCardsIncludes.push(inc);
            }
            return rel;
        });

    return { combinationCardSetCardsRelationships, combinationCardSetCardsIncludes };
};
