import React from 'react';
import { observer } from 'mobx-react';
import { ApartmentOutlined } from '@ant-design/icons';
import { Catcher, GoPageButton, ListWrapper } from '../';
import { isCompanyTypesIsParentsCheckValid } from '../../settings';
import { useCompaniesFilterParams, useProfile } from '../../hooks';
import { cleanCompaniesStore, getCompaniesRelationSelect } from '../../middleware';
import { CompaniesTableList } from './Components';

const LeftButtons: React.FC = observer(() => {
    return <GoPageButton componentName={'CompaniesHierarchy'} icon={<ApartmentOutlined />} title={'Hierarchy View'} />;
});

export const CompaniesList: React.FC = observer(() => {
    const filterParams = useCompaniesFilterParams();
    const { companyTypeId } = useProfile();
    const isUserNewCompanyValid = isCompanyTypesIsParentsCheckValid(companyTypeId);

    return (
        <Catcher>
            <ListWrapper
                cleanStore={cleanCompaniesStore}
                filterParams={filterParams}
                isLockNewPage={!isUserNewCompanyValid}
                getRelationSelect={getCompaniesRelationSelect}
                LeftButtons={LeftButtons}
            >
                <CompaniesTableList />
            </ListWrapper>
        </Catcher>
    );
});
