import React from 'react';
import { observer } from 'mobx-react';
import { Catcher, LoadingApiByIds } from '../../';
import { VerticalRevenueChart } from './';
import { useDashboardTimelineRevenueChart } from '../../../hooks';
import { tP } from '../../../translate';
import Styles from '../m_dashboarStyles.less';

export const TimelineRevenue: React.FC = observer(() => {
    const data = useDashboardTimelineRevenueChart();

    return (
        <Catcher>
            <LoadingApiByIds idSpinners={['GET_REVENUE_TIMELINE_LIST']} />
            <div className={Styles.chart}>
                <VerticalRevenueChart sourceData={data} title={tP('Cost/Revenue last months')} />
            </div>
        </Catcher>
    );
});
