import React from 'react';
import { observer } from 'mobx-react';
import { Select } from 'antd';
import { tI } from '../../../translate';
import { Catcher } from '../../Catcher';
import { ILazyLoadingSelectProps, LazyLoadingSelect } from '../../Forms';
import Styles from '../m_styles.less';

export const FilterLazyLoadingSelect: React.FC<ILazyLoadingSelectProps> = observer(
    ({
        fetchFunc,
        idSpinners = [],
        isBlockAllowClear,
        mode,
        onChange,
        pageLimit = 100,
        title = tI('Filter'),
        useSelectHook,
        value,
        virtual = true,
    }: ILazyLoadingSelectProps) => {
        return (
            <Catcher>
                <div className={Styles.labelFieldPrefix} title={title}>
                    {title}
                </div>
                <LazyLoadingSelect
                    fetchFunc={fetchFunc}
                    useSelectHook={useSelectHook}
                    className={Styles.fieldFull}
                    title={title}
                    idSpinners={idSpinners}
                    isBlockAllowClear={isBlockAllowClear}
                    mode={mode}
                    onChange={onChange}
                    value={value}
                    virtual={virtual}
                    pageLimit={pageLimit}
                />
            </Catcher>
        );
    },
);
