import React, { useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';
import { ChartWrapperOptions } from 'react-google-charts/dist/types';
import { observer } from 'mobx-react';
import { Empty } from 'antd';
import { IChartData } from '../../../interfaces';
import { getCartColorTheme, useChartsSettings, useUiCurrentUnitType } from '../../../hooks';
import { calcChartTicks } from '../../../instruments';
import { Catcher } from '../../Catcher';
import { ChartTypeSelector } from './../Components';
import Styles from '../m_dashboarStyles.less';

interface IBarChartProps {
    sourceData: IChartData;
    title?: string;
    titleLink?: JSX.Element | null;
}

type IChartType = 'ComboChart' | 'LineChart' | 'PieChart';

export const VerticalUsageChart: React.FC<IBarChartProps> = observer(
    ({ sourceData, title = '', titleLink = null }: IBarChartProps) => {
        const [selectedChartType, setSelectedChartType] = useState('bar');
        const [chartType, setChartType] = useState<IChartType>('ComboChart');
        const { animation, annotationsOutside, backgroundColor, chartBackground, hAxis, textColor, vAxis } =
            useChartsSettings();
        const currentUnitType = useUiCurrentUnitType();

        const defaultOptions = {
            backgroundColor: chartBackground,
        };

        const [options, setOptions] = useState<Partial<ChartWrapperOptions>>(defaultOptions);

        useEffect(() => {
            const maxValue = sourceData.reduce((mm, i) => Math.max(mm, typeof i[1] === 'number' ? i[1] : 0), 0);
            const minValue = sourceData.reduce(
                (mm, i) => Math.min(mm, typeof i[1] === 'number' ? i[1] : maxValue),
                maxValue,
            );
            const calcMin = Math.max(minValue - (maxValue - minValue) / 10, 0);
            const calcMax = maxValue + (maxValue - minValue) / 10;

            switch (selectedChartType) {
                case 'bar':
                    setChartType('ComboChart');
                    setOptions({
                        annotations: annotationsOutside,
                        backgroundColor: chartBackground,
                        seriesType: 'bars',
                        legend: { position: 'none' },
                        chartArea: {
                            width: '85%',
                            height: '75%',
                        },
                        colors: getCartColorTheme(sourceData),
                        hAxis,
                        vAxis: {
                            ...vAxis,
                            ticks: calcChartTicks(calcMin, maxValue, 5, currentUnitType),
                        },
                        animation,
                    });
                    break;
                case 'pie':
                    setChartType('PieChart');
                    setOptions({
                        backgroundColor: chartBackground,
                        legend: {
                            position: 'right',
                            textStyle: { color: textColor },
                        },
                        chartArea: {
                            width: '85%',
                            height: '85%',
                        },
                        pieSliceTextStyle: { color: backgroundColor },
                        pieSliceBorderColor: 'transparent',
                        hAxis,
                        vAxis,
                        animation,
                    });
                    break;
                case 'line':
                    setChartType('LineChart');
                    setOptions({
                        pointSize: 6,
                        backgroundColor: chartBackground,
                        legend: 'none',
                        chartArea: {
                            width: '85%',
                            height: '75%',
                        },
                        colors: getCartColorTheme(sourceData),
                        hAxis,
                        vAxis: {
                            ...vAxis,
                            minValue: calcMin,
                            maxValue: calcMax,
                            ticks: calcChartTicks(calcMin, maxValue, 5, currentUnitType),
                        },
                        animation,
                    });
                    break;
                default:
                    setChartType('ComboChart');
                    setOptions(defaultOptions);
                    break;
            }
        }, [sourceData, selectedChartType, backgroundColor]);

        return (
            <Catcher>
                <div className={Styles.chartTitle}>{titleLink ? titleLink : title}</div>
                <div className={Styles.chartTypeSelector}>
                    <ChartTypeSelector
                        defaultChart={selectedChartType}
                        onChangeChartType={setSelectedChartType}
                        availableTypes={['bar', 'pie', 'line']}
                    />
                </div>

                {sourceData.length < 2 ? (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                ) : (
                    <Chart chartType={chartType} data={sourceData} height={'100%'} width={'100%'} options={options} />
                )}
            </Catcher>
        );
    },
);
