import { action, makeObservable, observable, reaction } from 'mobx';
import { appConfig } from '../settings';
import {
    IDateRange,
    IFilterParams,
    IGeolocationSearchParams,
    IGeotrackingCardListItem,
    IGeotrackingCardLocationHistoryList,
    IGeotrackingLastCenter,
    IRoureResult,
} from '../interfaces';
import { makeRoutes } from '../middleware';
import { isRealLocation } from '../instruments';

export class GeotrackingStore {
    private constructor() {
        makeObservable(this);
        reaction(
            () => JSON.stringify(this.cardHistoryLocation),
            () => {
                // this.clearTrackingTimer();
                this.cardRoutesList = [];
                this.cardRoutesPlainList = [];
                const list = this.cardHistoryLocation.filter((i) => isRealLocation(i));
                if (list.length > 1) {
                    makeRoutes(list).then();
                }
            },
            {
                delay: appConfig.APIDelay,
            },
        );
    }

    private static instance: GeotrackingStore;
    public static getInstance(): GeotrackingStore {
        if (!GeotrackingStore.instance) {
            GeotrackingStore.instance = new GeotrackingStore();
        }
        return GeotrackingStore.instance;
    }

    @observable cardsList: IGeotrackingCardListItem[] = [];
    @action setGeotrackingCardList(cardsList: IGeotrackingCardListItem[]): void {
        this.cardsList = cardsList;
    }

    @observable cardHistoryLocation: IGeotrackingCardLocationHistoryList = [];
    @action setCardHistoryLocation(cardHistoryLocation: IGeotrackingCardLocationHistoryList): void {
        this.cardHistoryLocation = cardHistoryLocation;
    }

    @observable isMakingRoutes: boolean[] = [];
    @action addIsMakingRoutesLoader(): void {
        this.isMakingRoutes = [...this.isMakingRoutes, true];
    }
    @action removeIsMakingRoutesLoader(): void {
        const newLoaderArr = [...this.isMakingRoutes];
        newLoaderArr.pop();
        this.isMakingRoutes = newLoaderArr;
    }
    @observable cardRoutesList: IRoureResult[] = [];
    @action setCardRoutesList(routesList: IRoureResult[]): void {
        this.cardRoutesList = routesList;
    }
    @action addCardRoute(route: IRoureResult): void {
        this.cardRoutesList = [...this.cardRoutesList, route];
    }

    @observable cardRoutesPlainList: IGeotrackingCardLocationHistoryList[] = [];
    @action setCardRoutesPlainList(routesPlainList: IGeotrackingCardLocationHistoryList[]): void {
        this.cardRoutesPlainList = routesPlainList;
    }
    @action addCardPlainRoute(routePlain: IGeotrackingCardLocationHistoryList): void {
        if (this.cardRoutesPlainList.length) {
            const lastRoute = this.cardRoutesPlainList[this.cardRoutesPlainList.length - 1];
            const lastPoint = lastRoute[lastRoute.length - 1];
            if (lastPoint?.lat === routePlain[0]?.lat && lastPoint?.lng === routePlain[0]?.lng) {
                this.cardRoutesPlainList[this.cardRoutesPlainList.length - 1] = [...lastRoute, routePlain[1]];
            } else {
                this.cardRoutesPlainList.push(routePlain);
            }
        } else {
            this.cardRoutesPlainList.push(routePlain);
        }
    }

    @observable isGeotrackingMode = false;
    @action setIsGeotrackingMode(isGeotrackingMode: boolean): void {
        this.isGeotrackingMode = isGeotrackingMode;
    }

    @observable filterParamsGeoTracking: IFilterParams = {
        cardSetId: true,
        dateRange: true,
        search: true,
        isDateRangeCleanedDefaultURL: true,
        isDateRangeMaxDayToDay: true,
        isDateRangeMinDayOn: true,
    };

    @observable searchParams: IGeolocationSearchParams = {
        search: '',
        cardSetId: '',
    };
    @action setSearchParams(key: string, value: string): void {
        this.searchParams[key] = value;
    }

    @observable trackSearchParams: IDateRange = { startDate: undefined, endDate: undefined };
    @action setTrackSearchParams(trackSearchParams: IDateRange): void {
        this.trackSearchParams = trackSearchParams;
    }

    @observable trackedCardId: string | null = null;
    @action setTrackedCardId(trackedCardId: string | null): void {
        this.trackedCardId = trackedCardId;
    }

    @observable lastCenter: IGeotrackingLastCenter = {};
    @action setLastCenter(lastCenter: IGeotrackingLastCenter): void {
        this.lastCenter = lastCenter;
    }

    @observable isUpdatingTrackingInfo = false;
    @action setIsUpdatingTrackingInfo(isUpdatingTrackingInfo: boolean): void {
        this.isUpdatingTrackingInfo = isUpdatingTrackingInfo;
    }

    @action cleanStore(): void {
        this.cardHistoryLocation = [];
        this.cardRoutesList = [];
        this.cardRoutesPlainList = [];
        this.cardsList = [];
        this.isGeotrackingMode = false;
        this.searchParams = {};
        this.trackedCardId = null;
        this.trackSearchParams = {};
    }

    // timerId: ReturnType<typeof setTimeout> | undefined;

    // setUpTrackingTimer = (): void => {
    //     const tick = async (): Promise<void> => {
    //         const AuthStoreInstance = AuthStore.getInstance();
    //         const { appStatus } = AuthStoreInstance;
    //         if (appStatus === 'READY') {
    //             const trackedCardId = this.trackedCardId as string;
    //             const searchParams = this.trackSearchParams;
    //             const prevCenter = this.lastCenter;
    //             console.log(new Date().toISOString(), '-(setUpTrackingTimer)->', 'STAR', `<--`);
    //
    //             this.setIsUpdatingTrackingInfo(true);
    //
    //             await getGeotrackingCardLocationHistory({
    //                 id: trackedCardId,
    //                 startDate: searchParams.startDate,
    //                 endDate: searchParams.endDate,
    //             });
    //
    //             if (!deepEqual(prevCenter, this.lastCenter)) {
    //                 addNotification({
    //                     type: 'info',
    //                     message: 'New location has been detected!',
    //                     duration: 5,
    //                 });
    //             }
    //
    //             console.log(new Date().toISOString(), '-(setUpTrackingTimer)->', 'END', `<--`);
    //             this.setIsUpdatingTrackingInfo(false);
    //         }
    //
    //         this.timerId = setTimeout(tick, 30000);
    //     };
    //
    //     this.timerId = setTimeout(tick, 30000);
    // };

    // clearTrackingTimer = (): void => {
    //     clearTimeout(this.timerId as ReturnType<typeof setTimeout>);
    //     this.timerId = undefined;
    // };
}
