import React from 'react';
import { observer } from 'mobx-react';
import { useIsGeotrackingMode } from '../../../hooks';
import { IComponentWithIdSpinners } from '../../../Components';
import { GeoTrackingData, GeotrackingCardList } from '../Components';

export const GoogleGeoTrackingMap: React.FC<IComponentWithIdSpinners> = observer(
    ({ idSpinners }: IComponentWithIdSpinners) => {
        const isGeotrackingMode = useIsGeotrackingMode();

        return !isGeotrackingMode ? (
            <GeotrackingCardList idSpinners={idSpinners} />
        ) : (
            <GeoTrackingData idSpinners={idSpinners} />
        );
    },
);
