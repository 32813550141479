import React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import { ICountryListItem } from '../../../interfaces';
import { useCountriesTable, useUiIsLoading, useUiSearch } from '../../../hooks';
import { lightText } from '../../../instruments';
// import { deleteCountry } from '../../../middleware';
import { tF, tI } from '../../../translate';
import { Catcher, LinkComponent, TableItemToolbar, columnToolbar } from '../../../Components';
import Styles from '../../styles/m_viewStyles.less';

export const CountriesTableList: React.FC = observer(() => {
    const isLoading = useUiIsLoading();
    const countriesList = useCountriesTable();
    const search = useUiSearch();

    const tableColumns = [
        {
            title: tF('Name'),
            dataIndex: 'nameLink',
        },
        {
            title: tF('Zone'),
            dataIndex: 'zoneLink',
        },
        {
            title: tF('ISO3'),
            dataIndex: 'iso3',
        },
        {
            title: tF('MCC'),
            dataIndex: 'mcc',
        },
        {
            title: tF('Dial Code'),
            dataIndex: 'dialCode',
        },
        columnToolbar,
    ];
    const tableData = countriesList.map((c: ICountryListItem) => {
        // const deleteItem = (): void => {
        //     deleteCountry(c.id);
        // };
        const name = c.name ? c.name : `# ${c.id}`;

        return {
            key: c.id,
            ...c,
            nameLink: <LinkComponent currentListComponent={'CountryList'} id={c.id} isLight name={c.name} />,
            zoneLink: (
                <LinkComponent
                    currentListComponent={'ZonesList'}
                    id={c.zoneId}
                    isFilterCurrentView
                    isLight
                    name={c.zone}
                />
            ),
            dialCode: <span>+{c.dialCode}</span>,
            iso3: <span>{lightText(c.iso3, search)}</span>,
            mcc: <span>{lightText(c.mcc, search)}</span>,
            toolbar: (
                <TableItemToolbar
                    itemName={`"${name}" ${tI('country')}`}
                    id={c.id}
                    // deleteItem={deleteItem} // wait API for delete
                />
            ),
        };
    });

    return (
        <Catcher>
            <div className={Styles.tablePlace}>
                <Table
                    dataSource={tableData}
                    columns={tableColumns}
                    pagination={false}
                    className={'table-theme'}
                    size={'middle'}
                    loading={isLoading}
                />
            </div>
        </Catcher>
    );
});
