import React from 'react';
import { observer } from 'mobx-react';
import { Catcher, ListWrapper } from '../../';
import { GateConfigTable } from './GateConfigTable';
import { useOperatorsFilterParams } from '../../../hooks';
import { cleanOperatorsStore, getOperatorGatewaySelect } from '../../../middleware';
import Styles from '../../styles/m_itemCardStyles.less';

export const GatewayConfig: React.FC = observer(() => {
    const filterParams = useOperatorsFilterParams();

    return (
        <Catcher>
            <div className={Styles.main}>
                <ListWrapper
                    cleanStore={cleanOperatorsStore}
                    filterParams={filterParams}
                    getRelationSelect={getOperatorGatewaySelect}
                >
                    <GateConfigTable />
                </ListWrapper>
            </div>
        </Catcher>
    );
});
