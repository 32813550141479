import React from 'react';
import { Spin } from 'antd';
import { LoadingText } from './LoadingText';
import cx from 'classnames';
import { ILoading } from './interfaces';
import { tNM } from '../../translate';
import Styles from './m_styles.less';

export const Loading: React.FC<ILoading> = ({
    isLoading = false,
    size,
    tip = LoadingText(),
    onlySpinner,
}: ILoading) => {
    const trTip = tNM(tip);
    return isLoading ? (
        <section className={cx(Styles.main)}>
            <div className={cx(Styles.containerCenter)}>
                <div className={Styles.spinner}>
                    <Spin size={size} tip={!onlySpinner ? trTip : undefined} />
                </div>
            </div>
        </section>
    ) : null;
};
