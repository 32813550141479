import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { ISelectList, IUser } from '../../interfaces';

export function useUser(): IUser {
    return useContext(MobXProviderContext).RootStore.userStore.user;
}
export function useUserTypes(): ISelectList {
    return useContext(MobXProviderContext).RootStore.userStore.userTypes;
}
