import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Row } from 'antd';
import { Catcher } from '../';
import { appConfig } from '../../settings';
import { IOrderView } from '../../interfaces';
import { useRouterStore } from '../../hooks';
import { getOrder } from '../../middleware';
import { ShowOrder } from './Components/';
import Styles from '../styles/m_itemCardStyles.less';

let orderX: IOrderView = { id: '' };

export const OrderView: React.FC = observer(() => {
    const routerStore = useRouterStore();
    const [id, setId] = useState('');
    const [order, setOrder] = useState<IOrderView>({ id });

    useEffect(() => {
        const { params } = routerStore.routerState;
        if (params.id) {
            setId(params.id);
        }
    });

    const _getOrder = async (id: string): Promise<void> => {
        const { isError, order } = await getOrder(id);
        if (isError) {
            routerStore.goTo('OrdersList');
        } else {
            orderX = order;
            setOrder(order);
        }
    };
    const refreshOrder = (id: string): void => {
        _getOrder(id);
    };

    const autoRefreshOrder = (): void => {
        if (!!orderX.id && orderX?.status === 'ORDER_CREATED') {
            refreshOrder(orderX.id);
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            autoRefreshOrder();
        }, appConfig.updateViewInterval);
        return (): void => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (id) {
            refreshOrder(id);
        }
    }, [id]);

    return (
        <Catcher>
            <article className={Styles.main}>
                <Row>
                    <ShowOrder order={order} refreshOrder={(): void => refreshOrder(order.id)} />
                </Row>
            </article>
        </Catcher>
    );
});
