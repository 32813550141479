import React, { ChangeEvent, useEffect, useState } from 'react';
import TextArea, { TextAreaProps } from 'antd/lib/input/TextArea';
import { Catcher } from '../';
import { appConfig } from '../../settings';
import { useInputDebounce } from '../../hooks';

interface ITextAreaDebounce extends TextAreaProps {
    delay?: number;
}

export const TextAreaDebounce: React.FC<ITextAreaDebounce> = ({
    delay = appConfig.delayInputDebounce,
    onChange,
    value,
    ...props
}: ITextAreaDebounce) => {
    const [localValue, setLocalValue] = useState<typeof value>();
    const [localOnChangeEvent, setLocalOnChangeEvent] = useState<ChangeEvent<HTMLTextAreaElement>>();

    const debouncedValue: typeof value = useInputDebounce<typeof value>(localValue, delay);

    useEffect(() => {
        setLocalValue(value);
    }, [value]);

    useEffect(() => {
        if (onChange && localOnChangeEvent && (!!value || !!debouncedValue) && value !== debouncedValue) {
            const changeEvent: ChangeEvent<HTMLTextAreaElement> = {
                ...localOnChangeEvent,
                target: { ...localOnChangeEvent.target, value: localValue ? localValue.toString() : '' },
            };
            onChange(changeEvent);
        }
    }, [debouncedValue]);

    const localOnChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
        setLocalValue(event.target.value);
        setLocalOnChangeEvent({ ...event });
    };

    return (
        <Catcher>
            <TextArea {...props} onBlur={(): void => undefined} onChange={localOnChange} value={localValue} autoSize />
        </Catcher>
    );
};
