import React from 'react';
import { observer } from 'mobx-react';
import { Catcher, ListWrapper } from '../';
import { usePackagesUnitsLogsFilterParams, useProfileIsRight } from '../../hooks';
import { cleanPackagesUnitsLogsStore, getPackagesUnitsLogsRelationSelect } from '../../middleware';
import { PackagesUnitsLogsTableList } from './Components/';

export const PackagesUnitsLogs: React.FC = observer(() => {
    const filterParams = usePackagesUnitsLogsFilterParams();
    const isLord = useProfileIsRight('LORD');

    return (
        <Catcher>
            <ListWrapper
                cleanStore={cleanPackagesUnitsLogsStore}
                filterParams={filterParams}
                isNotFilterToolbar={!isLord}
                getRelationSelect={getPackagesUnitsLogsRelationSelect}
            >
                <PackagesUnitsLogsTableList />
            </ListWrapper>
        </Catcher>
    );
});
