import React from 'react';
import { observer } from 'mobx-react';
import { Catcher, DownloadFilesButtons, ListWrapper } from '../';
import { useSubscribersFilterParams } from '../../hooks';
import { cleanSubscribersStore, getSubscribersRelationSelect } from '../../middleware';
import { SubscribersTableList } from './Components';

export const SubscribersList: React.FC = observer(() => {
    const filterParams = useSubscribersFilterParams();

    const DownloadCSVFlow = (): JSX.Element => {
        return <DownloadFilesButtons csvType={'GET_SUBSCRIBERS_LIST_CSV'} />;
    };

    return (
        <Catcher>
            <ListWrapper
                cleanStore={cleanSubscribersStore}
                filterParams={filterParams}
                getRelationSelect={getSubscribersRelationSelect}
                RightButtons={DownloadCSVFlow}
            >
                <SubscribersTableList />
            </ListWrapper>
        </Catcher>
    );
});
