import { GeotrackingStore } from '../stores/geotracking';
import { setCardsSelect } from './cards';
import dayjs from 'dayjs';
import { appConfig } from '../settings';
import { apiManager } from '../REST';
import {
    IDateRange,
    IGETGeotrackingCardDataItemAPI,
    IGeolocationSearchParams,
    IGeotrackingCardListGETAPI,
    IGeotrackingCardListItem,
    IGeotrackingCardLocationHistoryGETAPI,
    IGeotrackingCardLocationHistoryItem,
    IGeotrackingCardLocationHistoryItemAPI,
    IGeotrackingCardLocationHistoryList,
    IGeotrackingLastCenter,
    IIncludeDeviceLocationItemAPI,
    IParamsList,
    IRoureRequest,
    IRoureResult,
    IRouteStatus,
    IWaypoint,
} from '../interfaces';
import { appSignalSendMessage, isRealLocation } from '../instruments';
import { tF } from '../translate';

function formGeotrackingCardList(resp: IGeotrackingCardListGETAPI): IGeotrackingCardListItem[] {
    const { data, included } = resp;

    const deviceLocation: IIncludeDeviceLocationItemAPI[] =
        included?.filter((i: IIncludeDeviceLocationItemAPI) => i.type === 'device_location') || [];

    const deviceLocationMap = {} as { [key: string]: IIncludeDeviceLocationItemAPI };

    deviceLocation.forEach((i: IIncludeDeviceLocationItemAPI) => {
        deviceLocationMap[i.id] = i;
    });

    const filteredData = data?.filter(
        (i: IGETGeotrackingCardDataItemAPI) => i?.relationships?.device_location?.data?.id,
    );

    return filteredData?.map((i: IGETGeotrackingCardDataItemAPI) => {
        const deviceLocationId = i.relationships?.device_location?.data?.id || '';
        const deviceLocationInfo = deviceLocationMap[deviceLocationId] || {};
        return {
            id: i.id,
            name: i?.attributes?.name || i.id || tF('Unknown'),
            lat: deviceLocationInfo?.attributes?.location?.x,
            lng: deviceLocationInfo?.attributes?.location?.y,
            createdAt: deviceLocationInfo?.attributes?.created_at,
        };
    }) as IGeotrackingCardListItem[];
}

function setGeotrackingCardList(data: IGeotrackingCardListItem[]): void {
    const GeotrackingInstance = GeotrackingStore.getInstance();
    GeotrackingInstance.setGeotrackingCardList(data);
}

export async function getGeotrackingCardList(searchParams: IGeolocationSearchParams = {}): Promise<void> {
    const params = {
        ...searchParams,
    } as IParamsList;

    const apiResponse = await apiManager.getApi('GET_GEOTRACKING_CARD_LIST', {
        searchParamsList: params,
        scope: ['with_location'],
    });

    if (!apiResponse.isError) {
        const resp = apiResponse.resp as IGeotrackingCardListGETAPI;

        setGeotrackingCardList(formGeotrackingCardList(resp));
        setCardsSelect(resp?.data || []);
    }
}

export function formCardHistoryLocation(
    resp: IGeotrackingCardLocationHistoryGETAPI,
): IGeotrackingCardLocationHistoryList {
    const { data } = resp;

    return (
        data?.map((i: IGeotrackingCardLocationHistoryItemAPI): IGeotrackingCardLocationHistoryItem => {
            return {
                lat: i?.attributes?.location?.x || 0,
                lng: i?.attributes?.location?.y || 0,
                accuracy: i.attributes.accuracy,
                date: i.attributes.created_at,
                dateName: dayjs(i.attributes?.created_at).format('MMM Do YYYY, h:mm:ss a'),
                measurement: i?.attributes?.measurement || 'unknown',
            };
        }) || []
    );
}

export function setCardHistoryLocation(data: IGeotrackingCardLocationHistoryList): void {
    const GeotrackingInstance = GeotrackingStore.getInstance();
    GeotrackingInstance.setCardHistoryLocation(data);
}

export function setTrackedCardId(id: string | null): void {
    const GeotrackingInstance = GeotrackingStore.getInstance();
    GeotrackingInstance.setTrackedCardId(id);
}

export function setIsGeotrackingMode(isGeotrackingMode: boolean): void {
    const GeotrackingInstance = GeotrackingStore.getInstance();
    GeotrackingInstance.setIsGeotrackingMode(isGeotrackingMode);
}

export function setLastCenter(data: IGeotrackingLastCenter): void {
    const GeotrackingInstance = GeotrackingStore.getInstance();
    GeotrackingInstance.setLastCenter(data);
}

export function setGeoTrackingLastCenter(data: IGeotrackingCardLocationHistoryList): void {
    setLastCenter({ lat: data?.[0]?.lat, lng: data?.[0]?.lng });
}

export async function getGeotrackingCardLocationHistory(searchParams: IParamsList = {}): Promise<void> {
    const { id, ...restParams } = searchParams;

    const { resp, isError } = await apiManager.getApi('GET_GEOTRACKING_CARD_LOCATION_HISTORY', {
        id,
        searchParamsList: {
            fromDate: restParams.startDate,
            toDate: restParams.endDate,
        },
    });

    if (!isError) {
        const formedHistoryLocation = formCardHistoryLocation(resp as IGeotrackingCardLocationHistoryGETAPI);
        setCardHistoryLocation(formedHistoryLocation);
        setGeoTrackingLastCenter(formedHistoryLocation);
        setIsGeotrackingMode(true);
    }
}

export function setGeoTrackingSearchParams(key: string, value: string): void {
    const GeotrackingInstance = GeotrackingStore.getInstance();
    GeotrackingInstance.setSearchParams(key, value);
}

export function setGeoTrackingTrackSearchParams(date: IDateRange): void {
    const GeotrackingInstance = GeotrackingStore.getInstance();
    GeotrackingInstance.setTrackSearchParams(date);
}

const addGeotrackingIsMakingRoutesLoader = (): void => {
    const GeotrackingInstance = GeotrackingStore.getInstance();
    GeotrackingInstance.addIsMakingRoutesLoader();
};

const removeGeotrackingIsMakingRoutesLoader = (): void => {
    const GeotrackingInstance = GeotrackingStore.getInstance();
    GeotrackingInstance.removeIsMakingRoutesLoader();
};

const addGeotrackingCardRoute = (route: IRoureResult): void => {
    const GeotrackingInstance = GeotrackingStore.getInstance();
    GeotrackingInstance.addCardRoute(route);
};

const addGeotrackingCardRoutePlain = (routePlain: IGeotrackingCardLocationHistoryItem[]): void => {
    const GeotrackingInstance = GeotrackingStore.getInstance();
    GeotrackingInstance.addCardPlainRoute(routePlain);
};

declare const google: any;
const getGoogleRoute = async (request: IRoureRequest): Promise<IRoureResult> => {
    const directionsService = new google.maps.DirectionsService();
    return await directionsService
        .route({
            provideRouteAlternatives: false,
            travelMode: google.maps.TravelMode.DRIVING,
            // travelMode: google.maps.TravelMode.WALKING,
            ...request,
        })
        .then((response: any) => {
            return response;
        })
        .catch((e: any) => {
            return { status: e.code, error: e };
        });
};

const makeRoute = async (path: IGeotrackingCardLocationHistoryList): Promise<IRouteStatus> => {
    const historyLength = path.length;

    if (path.length < 2) {
        return 'OK';
    }

    const waypoints: Array<IWaypoint> = path
        .filter(
            (item: IGeotrackingCardLocationHistoryItem, index: number) => index !== 0 && index !== historyLength - 1,
        )
        .filter((i: IGeotrackingCardLocationHistoryItem) => isRealLocation(i))
        .map((item: IGeotrackingCardLocationHistoryItem) => ({
            location: { lat: item.lat, lng: item.lng },
            // stopover: false,
        }));

    const res = await getGoogleRoute({
        origin: { lat: path[0].lat, lng: path[0].lng },
        destination: { lat: path[path.length - 1].lat, lng: path[path.length - 1].lng },
        waypoints,
    });

    if (res.status === google.maps.DirectionsStatus.OK) {
        addGeotrackingCardRoute(res);
    } else {
        appSignalSendMessage({
            action: 'getGoogleRoute',
            error: res.error,
            message: 'The error of building a route!',
            tags: {
                target: 'Google Map',
            },
            params: {
                googleResponseStatus: res.status,
                googleResponseCode: res.error.code,
                googleResponseMessage: res.error.message,
                googleResponseName: res.error.name,
                googleResponseEndpoint: res.error.endpoint,
                waypoints: JSON.stringify(waypoints),
                path: JSON.stringify(path),
            },
        });
        console.warn(
            `Google Route Building Error:`,
            { ...res },
            ' path:',
            path.map((i) => ({ ...i })),
        );
    }
    return res.status;
};

// export const setUpTrackingTimer = (): void => {
//     const GeotrackingInstance = GeotrackingStore.getInstance();
//     GeotrackingInstance.setUpTrackingTimer();
// };

// export const clearTrackingTimer = (): void => {
//     const GeotrackingInstance = GeotrackingStore.getInstance();
//     GeotrackingInstance.clearTrackingTimer();
// };

export const makeRoutes = async (
    historyList: IGeotrackingCardLocationHistoryList,
    maxLength = appConfig.googleRoutChunkSize,
): Promise<void> => {
    addGeotrackingIsMakingRoutesLoader();
    let history = [...historyList];
    do {
        const chunk = history.slice(0, Math.min(maxLength, history.length));
        const status = await makeRoute(chunk);
        if (status === 'OK') {
            history = history.slice(maxLength - 1);
        } else if (status === 'OVER_QUERY_LIMIT') {
            const residueHistory = [...history];
            addGeotrackingIsMakingRoutesLoader();
            setTimeout(async () => {
                await makeRoutes(residueHistory);
                removeGeotrackingIsMakingRoutesLoader();
            }, appConfig.googleOverQueryLimitWait);
            history.length = 0;
        } else if (status === 'ZERO_RESULTS') {
            if (chunk.length > 2) {
                await makeRoutes(chunk, Math.ceil(chunk.length / 2));
            } else {
                addGeotrackingCardRoutePlain(chunk);
            }
            history = history.slice(maxLength - 1);
        } else {
            history.length = 0;
            console.error('ERROR backEnd STATUS', status);
            removeGeotrackingIsMakingRoutesLoader();
        }
    } while (history.length);
    removeGeotrackingIsMakingRoutesLoader();

    // const GeotrackingInstance = GeotrackingStore.getInstance();

    // if (!GeotrackingInstance.timerId) {
    //     setUpTrackingTimer();
    // }
};

export const clearGeotrackingStore = (): void => {
    const GeotrackingInstance = GeotrackingStore.getInstance();
    GeotrackingInstance.cleanStore();
};
