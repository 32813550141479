import { ReactNode } from 'react';
import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import backend from 'i18next-http-backend';
import {
    ITrButton,
    ITrCustom,
    ITrField,
    ITrInterfaces,
    ITrNotificationDescription,
    ITrNotificationMessage,
    ITrPage,
    ITrText,
    ITrValidator,
} from './types';
import { appConfig } from '../settings';
import { ILanguageId } from '../interfaces';

const notWarnI18Keys = ['Loading', 'Initialization', 'Reload'];
const keySeparator = '>';

function showError(languages: string[], ns: string, key: string, fallbackValue: string): void {
    const keys = key.split(keySeparator);
    const subKey = keys[keys.length - 1];

    if (!notWarnI18Keys.includes(subKey)) {
        if (process.env.NODE_ENV === 'development') {
            // ToDo: 30.08.2023 - return console.error after ExernalAPI add translation
            // console.error(
            //     `'i18next not key-> "${key}", languages-> "${languages}", nameSpace-> "${ns}", fallbackValue-> "${fallbackValue}"`,
            // );
        } else {
            console.warn(`'i18next not found key-> "${key}", languages-> "${languages}"`);
        }
    }
}

const prefixKeyNormalise = (prefixKey: string): string => {
    if (prefixKey.length !== 0 && prefixKey[prefixKey.length - 1] !== keySeparator) {
        return `${prefixKey}${keySeparator}`;
    } else {
        return prefixKey;
    }
};

function isTranslation(str: string): boolean {
    return !str.includes(keySeparator);
}

type iTCustomParams = {
    [id: string]: string | number | undefined;
};

export function tCustom(key: ITrCustom, options: iTCustomParams): string {
    const value = i18next.t(`custom${keySeparator}` + key, options);
    return isTranslation(value) ? value : key;
}

export function tCN(key: ReactNode, prefixKey = ''): ReactNode {
    const value = i18next.t(prefixKeyNormalise(prefixKey) + key);
    return isTranslation(value) ? value : key;
}

export function translateString(key: string, prefixKey = ''): string {
    const value = i18next.t(prefixKeyNormalise(prefixKey) + key);
    return isTranslation(value) ? value : key;
}

export function tI(t: ITrInterfaces): string {
    return translateString(t, 'interfaces');
}

export function tNM(t: ITrNotificationMessage): string {
    return translateString(t, `notification${keySeparator}message`);
}

export function tND(t: ITrNotificationDescription): ReactNode {
    return translateString(t, `notification${keySeparator}description`);
}

export function tNDRN(t: ReactNode): ReactNode {
    return tCN(t, `notification${keySeparator}description`);
}

export function tP(t: ITrPage): string {
    return translateString(t, 'pages');
}

export function tF(t: ITrField): string {
    return translateString(t, 'fields');
}

export function tB(t: ITrButton): string {
    return translateString(t, `buttons`);
}

export function tV(t: ITrValidator): string {
    return translateString(t, 'validator');
}

export function tT(t: ITrText): string {
    return translateString(t, 'text');
}

i18next
    .use(backend)
    .use(initReactI18next)
    .init(
        {
            appendNamespaceToMissingKey: false,
            backend: {
                loadPath: `/static/translate/{{lng}}.json`,
            },
            // debug: process.env.NODE_ENV === 'development',
            defaultNS: 'translation',
            fallbackLng: process.env.NODE_ENV !== 'development' ? appConfig.languageDefault : false,
            interpolation: { escapeValue: false },
            keySeparator,
            lng: appConfig.languageDefault,
            missingKeyHandler: showError,
            ns: ['translation'],
            preload: ['en'],
            saveMissing: true,
            saveMissingTo: 'all',
            supportedLngs: appConfig.languages.map((i) => i.id),
            updateMissing: false,
        },
        (err: Error, t: (...params: string[]) => string) => {
            if (err) return console.error('init i18next Error ', err, t);
        },
    );

if (process.env.NODE_ENV === 'development') {
    i18next.on('languageChanged', (lng) => {
        console.log(Date.now(), `--(*** i18n On languageChanged )- lng ->`, lng);
    });
    i18next.on('loaded', (loaded) => {
        console.log(Date.now(), `--(*** i18n On loaded )- loaded ->`, loaded);
    });
    i18next.on('initialized', (options) => {
        console.log(Date.now(), `--(*** i18n On initialized )- options ->`, options);
    });

    // i18next.on('missingKey', (lngs: string[], ns: string, key: string, fallbackValue: string): void => {
    //     console.log(
    //         `--(*** On missingKey )- key-> "${key}", languages-> "${lngs}", nameSpace-> "${ns}", fallbackValue-> "${fallbackValue}"`,
    //     );
    // });
}
i18next.on('failedLoading', (lng, ns, msg) => {
    console.error(Date.now(), `--(*** i18n On failedLoading )- lng, ns, msg ->`, lng, ns, msg);
});

export async function changeLanguageId(id: ILanguageId): Promise<void> {
    await i18next.changeLanguage(id);
}

export default i18next;
