import { action, computed, makeObservable, observable } from 'mobx';
import { appConfig } from '../settings';
import {
    IRateCountryFormInfo,
    IRateCountryFormInitialValues,
    IRateCountryLoadingStats,
    IRateInfo,
    IRateNetworkItem,
} from '../interfaces';

export class RateStore {
    private constructor() {
        makeObservable(this);
    }

    private static instance: RateStore;
    public static getInstance(): RateStore {
        if (!RateStore.instance) {
            RateStore.instance = new RateStore();
        }
        return RateStore.instance;
    }

    @observable rateInfo: IRateInfo = { id: '' };
    @action setRateInfo(info: IRateInfo): void {
        this.rateInfo = info;
    }

    @observable rateCountriesListOriginal: IRateCountryFormInfo[] = [];
    @action setCountriesRateListOriginal(list: IRateCountryFormInfo[]): void {
        this.rateCountriesListOriginal = list;
    }
    @computed get isFakeOperatorRateCountriesListOriginal(): boolean {
        return !!this.rateCountriesListOriginal.filter((i: IRateCountryFormInfo) => i.operatorId === appConfig.fakeId)
            .length;
    }

    @observable rateCountriesList: IRateCountryFormInfo[] = [];
    @action setCountriesRateList(list: IRateCountryFormInfo[]): void {
        this.rateCountriesList = list;
    }

    @action updateCountryRateValue(value: IRateCountryFormInitialValues): void {
        this.rateCountriesList = this.rateCountriesList.map((r) => {
            if (r.id === value.id) {
                r = { ...r, ...value };
            }
            return r;
        });
    }

    @observable rateNetworksListOriginal: IRateNetworkItem[] = [];
    @action setRateNetworksListOriginal(list: IRateNetworkItem[]): void {
        this.rateNetworksListOriginal = list;
    }

    @observable rateNetworksList: IRateNetworkItem[] = [];
    @action setRateNetworksList(list: IRateNetworkItem[]): void {
        this.rateNetworksList = list;
    }

    @action addNetworkRateItem(item: IRateNetworkItem): void {
        this.rateNetworksList = [item, ...this.rateNetworksList];
    }

    @action removeNetworkById(id: string): void {
        this.rateNetworksList = this.rateNetworksList.map((n: IRateNetworkItem) =>
            n.id === id ? { ...n, destroy: true } : n,
        );
    }

    @observable countryLoadingStats: IRateCountryLoadingStats = {
        expectedToLoad: 0,
        alreadyLoaded: 0,
    };

    @action changeLoadingStats(newValue: number, paramName: 'expectedToLoad' | 'alreadyLoaded'): void {
        this.countryLoadingStats[paramName] = newValue;
        if (newValue === 0 && paramName === 'expectedToLoad') {
            this.countryLoadingStats.alreadyLoaded = newValue;
        }
    }

    @action addAlreadyLoaded(): void {
        const currentAlreadyLoaded = this.countryLoadingStats.alreadyLoaded;

        this.countryLoadingStats.alreadyLoaded = currentAlreadyLoaded + 1;
    }

    @action cleanStore(): void {
        this.rateInfo = { id: '' };
        this.rateCountriesList = [];
        this.rateNetworksList = [];
    }
}
