import { action, computed, makeObservable, observable, reaction } from 'mobx';
import { UiStore } from './ui';
import { appConfig } from '../settings';
import { IFilterParams, IReportsList, ISelect } from '../interfaces';
import { getReportsList, mwCardSetsSelect, mwCompaniesSelect } from '../middleware';
import { apiParamsCleaner } from '../instruments';

export class ReportsStore {
    private constructor() {
        makeObservable(this);
        reaction(
            () => this.forcedUpdateTime,
            () => {
                const UiStoreInstance = UiStore.getInstance();
                const params = UiStoreInstance.params;
                const cleanParams = apiParamsCleaner(params, this.filterParams);
                getReportsList(cleanParams).then();
            },
            {
                delay: appConfig.APIDelay,
            },
        );
    }

    private static instance: ReportsStore;
    public static getInstance(): ReportsStore {
        if (!ReportsStore.instance) {
            ReportsStore.instance = new ReportsStore();
        }
        return ReportsStore.instance;
    }

    @observable list: IReportsList[] = [];
    @action setReportsList(list: IReportsList[]): void {
        this.list = list;
    }
    @computed get listAdvanced(): IReportsList[] {
        const companiesSelect = mwCompaniesSelect();
        const cardSetSelect = mwCardSetsSelect();
        return this.list.map((report: IReportsList) => {
            const foundCompany = companiesSelect.find((i: ISelect) => i.id === report.companyId);
            const foundCardSet = cardSetSelect.find((i: ISelect) => i.id === report.cardSetId);
            return { ...report, company: foundCompany?.name, cardSet: foundCardSet?.name };
        });
    }

    @action cleanStore(): void {
        this.list = [];
    }

    @observable forcedUpdateTime = 0;
    @action setForcedUpdateTime(): void {
        this.forcedUpdateTime = Date.now();
    }

    @observable filterParams: IFilterParams = {
        pageNumber: true,
        pageSize: true,
        search: true,
        userId: true,
    };
}
