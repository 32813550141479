import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { IBalanceLogItem, IFilterParams } from '../../interfaces';

export function useBalanceLogTable(): IBalanceLogItem[] {
    return useContext(MobXProviderContext).RootStore.balanceLogStore.list;
}

export function useBalanceLogFilterParams(): IFilterParams {
    return useContext(MobXProviderContext).RootStore.balanceLogStore.filterParams;
}
