import { action, computed, makeObservable, observable } from 'mobx';
import {
    IExternalAPIFrontFpbxSettings,
    IExternalAPIFrontNumbers,
    IExternalAPILog,
    IExternalAPIOrderDetailForm,
    IExternalAPIOrderDetailFront,
} from '../interfaces';

export class ExternalAPIStore {
    private constructor() {
        makeObservable(this);
    }

    private static instance: ExternalAPIStore;
    public static getInstance(): ExternalAPIStore {
        if (!ExternalAPIStore.instance) {
            ExternalAPIStore.instance = new ExternalAPIStore();
        }
        return ExternalAPIStore.instance;
    }

    @observable orderDetail: IExternalAPIOrderDetailFront = {};
    @action setOrderDetails(orderDetail: IExternalAPIOrderDetailFront): void {
        this.orderDetail = orderDetail;
    }

    @computed get orderDetailForm(): IExternalAPIOrderDetailForm {
        return {
            simCardsAllowBillShockSMS: !!Number(this.orderDetail.subscription?.simCards.sim?.allowBillShockSMS),
            simCardsAllowData: !!Number(this.orderDetail.subscription?.simCards.sim?.allowData),
            simCardsAllowDataRoaming: !!Number(this.orderDetail.subscription?.simCards.sim?.allowDataRoaming),
            simCardsAllowPremiumSMS: !!Number(this.orderDetail.subscription?.simCards.sim?.allowPremiumSMS),
            simCardsIccId: this.orderDetail.subscription?.simCards.sim?.iccId,
            simCardsMsisdn: this.orderDetail.subscription?.simCards.sim?.msisdn,
        };
    }

    @observable numbers: IExternalAPIFrontNumbers = {};
    @action setNumbers(numbers: IExternalAPIFrontNumbers): void {
        this.numbers = numbers;
    }

    @observable fpbxSettings: IExternalAPIFrontFpbxSettings = {};
    @action setFpbxSettings(settings: IExternalAPIFrontFpbxSettings): void {
        this.fpbxSettings = settings;
    }

    @observable logs: Array<IExternalAPILog> = [];
    @action clearLog(): void {
        this.logs = [];
    }
    @action addLog(log: IExternalAPILog): void {
        this.logs.unshift(log);
    }

    @action cleanStore(): void {
        this.numbers = {};
        this.orderDetail = {};
        this.logs = [];
    }
}
