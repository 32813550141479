import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Form, Row } from 'antd';
import { FieldData } from 'rc-field-form/es/interface';
import { IEditForm } from '../../../interfaces';
import { useOperatorInfo } from '../../../hooks';
import { apiMessagesSaveMessages, sendOperatorInfo, setOperatorForm } from '../../../middleware';
import { removeSingleFormError } from '../../../instruments';
import { Catcher } from '../../Catcher';
import { EditOperatorInfo } from './../Components';

export const EditOperatorForm: React.FC<IEditForm> = observer(({ setEditMode, isNew = false }: IEditForm) => {
    const [form] = Form.useForm();
    const operatorInfo = useOperatorInfo();

    const initialValues = { ...operatorInfo };
    const [isSending, setSending] = useState(false);

    const checkValid = async (): Promise<void> => {
        await form.validateFields();
    };

    const onFieldsChange = (changedFields: FieldData[]): void => {
        removeSingleFormError(changedFields);
    };

    const initialForm = (): void => {
        if (initialValues.id) {
            form.setFieldsValue(initialValues);
        }
    };

    useEffect(() => {
        initialForm();
    }, [operatorInfo.id]);

    const updateStore = (): void => {
        setOperatorForm({ ...operatorInfo, ...form.getFieldsValue() });
    };

    const saveOperator = async (): Promise<void> => {
        setSending(true);
        updateStore();
        const { isError, errors = [] } = await sendOperatorInfo(isNew);
        if (isError) {
            apiMessagesSaveMessages(errors);
            checkValid();
        } else {
            setEditMode && setEditMode(false);
        }

        setSending(false);
    };

    return (
        <Catcher>
            <Form
                form={form}
                name="operator"
                initialValues={initialValues}
                scrollToFirstError={true}
                layout={'vertical'}
                className={'formStyle'}
                onFieldsChange={onFieldsChange}
            >
                <Row>
                    <EditOperatorInfo save={saveOperator} isSending={isSending} />
                </Row>
            </Form>
        </Catcher>
    );
});
