import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Form, Input } from 'antd';
import { Rule } from 'antd/es/form';
import { Catcher, makeRulePassword } from '../../';
import { IFormFieldChange, IFormFields } from '../../../interfaces';
import {
    addNotification,
    apiMessagesGetFormRules,
    authGoToLogin,
    ruleMinPasswordLength,
    sendNewPassword,
    validatePasswordConfirmation,
} from '../../../middleware';
import { tB, tF, tNM, tT } from '../../../translate';
import '../../../customStyle/form.less';
import Styles from '../m_styles.less';
import '../styles.less';

export const ResetPasswordForm: React.FC = observer(() => {
    const [form] = Form.useForm();
    const initialValues = { passwordNew: '', passwordConfirmation: '' };
    const [isValid, setValid] = useState(false);
    const [isSending, setSending] = useState(false);

    const onFieldsChange = (fields: IFormFieldChange[], allFields: IFormFieldChange[]): void => {
        setValid(
            allFields
                .map((field: IFormFieldChange) => !!(field.touched && !field.errors?.length))
                .reduce((result: boolean, item: boolean) => result && item),
        );
    };

    const onFinish = async (data: IFormFields): Promise<void> => {
        const { password } = data;

        setSending(true);
        setValid(false);

        const isError = await sendNewPassword(password);

        addNotification({
            type: !isError ? 'info' : 'error',
            message: !isError ? tNM('The password has been reset!') : tNM('An unknown error has occurred.'),
            duration: 5,
            isTranslated: true,
        });

        authGoToLogin();

        setSending(false);
        setValid(true);
    };

    const onValueChangeValidation = (): void => {
        const fieldNames = Object.keys(form.getFieldsValue());
        const fieldsForValidation = fieldNames.filter((field) => {
            return form.isFieldTouched(field);
        });

        form.validateFields(fieldsForValidation);
    };

    const validateAPIAndPassword: Array<Rule> = [...makeRulePassword(), ...apiMessagesGetFormRules];

    return (
        <Catcher>
            <p>{tT('Please enter your new password.')}</p>
            <Form
                form={form}
                name="forgotPassword"
                className={'formStyle'}
                initialValues={initialValues}
                onFieldsChange={onFieldsChange}
                onValuesChange={onValueChangeValidation}
                onFinish={onFinish}
                layout={'vertical'}
            >
                <Form.Item hasFeedback name="password" rules={[...validateAPIAndPassword, ...ruleMinPasswordLength()]}>
                    <Input.Password placeholder={tF('New password')} />
                </Form.Item>
                <Form.Item
                    hasFeedback
                    name="passwordConfirmation"
                    rules={[...validateAPIAndPassword, ...validatePasswordConfirmation]}
                    dependencies={['passwordNew']}
                >
                    <Input.Password placeholder={tF('Confirm password')} />
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                        block
                        disabled={!isValid}
                        loading={isSending}
                    >
                        {tB('Continue')}
                    </Button>
                </Form.Item>
                <div className={Styles.description}>
                    {tT(
                        'We protect the security of your information during transmission by using Secure Sockets Layer (SSL) software.',
                    )}
                </div>
            </Form>
        </Catcher>
    );
});
