import React, { ReactNode } from 'react';
import { QRCode } from 'react-qrcode-logo';
import { observer } from 'mobx-react';
import { Button, Modal, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import TextArea from 'antd/lib/input/TextArea';
import { CloseOutlined, QrcodeOutlined } from '@ant-design/icons';
import logoGeniox from '../../../static/img/logo192.png';
import { LinkComponent } from '../../LinkComponent';
import { columnToolbar } from '../../SubComponents';
import cx from 'classnames';
import { IEsNotificationsQRcodesItem } from '../../../interfaces';
import { useEsNotificationsQrcodesTable, useUiSearch } from '../../../hooks';
import { dateText2Utc, lightText } from '../../../instruments';
import { tB, tF } from '../../../translate';
import { Catcher } from '../../Catcher';
import Styles from '../../styles/m_viewStyles.less';
import SupportStyles from '../m_styles.less';

interface IDataType {
    key: string;
    iccId: ReactNode;
    createdAt: ReactNode;
    updatedAt: ReactNode;
    matchingId: ReactNode;
    cardId: ReactNode;
    toolbar: JSX.Element;
}

export const ESPlusNotificationQRcodesTable: React.FC = observer(() => {
    const search = useUiSearch();
    const list = useEsNotificationsQrcodesTable();
    const [isOpen, setOpen] = React.useState<boolean>(false);
    const [currentUrl, setCurrentUrl] = React.useState<string>('');
    const [resetUrl, setResetUrl] = React.useState<string>('');
    const tableColumns: ColumnsType<IDataType> = [
        {
            title: tF('ICCID'),
            dataIndex: 'iccId',
        },
        {
            title: tF('Card'),
            dataIndex: 'cardId',
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
        },
        {
            title: 'Updated At',
            dataIndex: 'updatedAt',
        },
        {
            title: 'Matching Id',
            dataIndex: 'matchingId',
        },
        columnToolbar,
    ];

    const downloadQRCode = () => {
        const canvas: any = document.getElementById('myQRCode');
        const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');

        const downloadLink = document.createElement('a');

        downloadLink.href = pngUrl;
        downloadLink.download = 'qrCode.png';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    const handleGenerateQrCode = (url: string) => {
        setCurrentUrl(url);
        setResetUrl(url);
        setOpen(true);
    };

    const handleCloseModal = () => {
        setCurrentUrl('');
        setResetUrl('');
        setOpen(false);
    };

    const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = event.target;

        setCurrentUrl(value);
    };

    const handleReset = () => {
        setCurrentUrl(resetUrl);
    };

    const tableData: IDataType[] = list.map((i: IEsNotificationsQRcodesItem) => {
        return {
            key: i.id,
            iccId: lightText(i.iccId, search),
            cardId: <LinkComponent currentListComponent={'UsersList'} id={i.cardId} isLight />,
            createdAt: dateText2Utc(i.createdAt),
            updatedAt: dateText2Utc(i.updatedAt),
            matchingId: lightText(i.matchingId, search),
            toolbar: (
                <Tooltip placement="top" title={'Generate Qrcode'}>
                    <QrcodeOutlined
                        className={cx(Styles.qrCodeDownloadButton)}
                        onClick={() => handleGenerateQrCode(i.qrcodeUrl)}
                    />
                </Tooltip>
            ),
        };
    });

    return (
        <Catcher>
            <div className={Styles.tablePlace}>
                <Table
                    className={'table-theme'}
                    columns={tableColumns}
                    dataSource={tableData}
                    pagination={false}
                    size={'middle'}
                />
            </div>
            <Modal
                title={'QR code generation '}
                open={isOpen}
                onCancel={handleCloseModal}
                footer={[
                    <Button key={'Close'} icon={<CloseOutlined />} onClick={handleCloseModal}>
                        {tB('Close')}
                    </Button>,
                ]}
                wrapClassName={'modal'}
                width={'30vw'}
            >
                <div className={SupportStyles.qrCodeGenerator}>
                    <div className={SupportStyles.qrCodeContainer}>
                        <QRCode
                            value={currentUrl}
                            style={{ marginBottom: '5px' }}
                            id="myQRCode"
                            logoImage={logoGeniox}
                            removeQrCodeBehindLogo={true}
                        />
                        <Button type={'primary'} onClick={downloadQRCode}>
                            Download
                        </Button>
                    </div>
                    <div className={SupportStyles.textAreaContainer}>
                        <TextArea
                            placeholder={'url'}
                            value={currentUrl}
                            onChange={(event) => handleOnChange(event)}
                            className={SupportStyles.textArea}
                        />
                        <Button disabled={resetUrl === currentUrl} onClick={handleReset}>
                            Reset Url
                        </Button>
                    </div>
                </div>
            </Modal>
        </Catcher>
    );
});
