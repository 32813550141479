import { action, computed, makeObservable, observable } from 'mobx';
import { UiStore } from './ui';
import {
    ICardDeviceFile,
    ICardGeolocationInfo,
    ICardInfo,
    ICardStatistics,
    ICardSubscriber,
    IDeviceHistory,
    IDeviceInfo,
    IGetApiType,
} from '../interfaces';

export class CardStore {
    private constructor() {
        makeObservable(this);
    }

    private static instance: CardStore;
    public static getInstance(): CardStore {
        if (!CardStore.instance) {
            CardStore.instance = new CardStore();
        }
        return CardStore.instance;
    }

    @observable cardInfo: ICardInfo = { id: '' };
    @action setCardInfo(cardInfo: ICardInfo): void {
        this.cardInfo = cardInfo;
    }

    @observable spinnersInfo: IGetApiType[] = ['GET_CARD'];

    @computed get isSpinningInfo(): boolean {
        return UiStore.getInstance().isFoundSpinner(this.spinnersInfo);
    }

    @observable subscribers: ICardSubscriber[] = [];
    @action setSubscribers(subscribers: ICardSubscriber[]): void {
        this.subscribers = subscribers;
    }

    @observable geolocationInfo: ICardGeolocationInfo = {};
    @action setGeolocationInfo(geolocationInfo: ICardGeolocationInfo): void {
        this.geolocationInfo = geolocationInfo;
    }

    @observable deviceInfo: IDeviceInfo = {};
    @action setDeviceInfo(typeDevice: IDeviceInfo): void {
        this.deviceInfo = typeDevice;
    }

    @observable deviceHistory: IDeviceHistory = [];
    @action setDeviceHistory(deviceHistory: IDeviceHistory): void {
        this.deviceHistory = deviceHistory;
    }

    @computed get isSubscribersDispatchLock(): boolean {
        return !!this.subscribers.filter((i: ICardSubscriber) => i.dispatchLock).length;
    }

    @observable cardSets: Array<string> = [];
    @action setCardSets(cardSets: Array<string>): void {
        this.cardSets = cardSets;
    }

    @observable statistics: ICardStatistics = {};
    @action setStatistics(statistics: ICardStatistics): void {
        this.statistics = statistics;
    }

    @observable cardDeviceFilesList: Array<ICardDeviceFile> = [];
    @action setCardDeviceFilesList(cardDeviceFilesList: Array<ICardDeviceFile>): void {
        this.cardDeviceFilesList = cardDeviceFilesList;
    }

    @action cleanStore(): void {
        this.cardDeviceFilesList = [];
        this.cardInfo = { id: '' };
        this.deviceHistory = [];
        this.deviceInfo = {};
        this.geolocationInfo = {};
        this.statistics = {};
        this.subscribers = [];
    }
}
