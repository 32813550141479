import React from 'react';
import { observer } from 'mobx-react';
import { Catcher, ListWrapper } from '../';
import { useCardSetsFilterParams } from '../../hooks';
import { cleanCardSetsStore, getCardSetsRelationSelect } from '../../middleware';
import { CardSetsTableList } from './Components';

export const CardSetsList: React.FC = observer(() => {
    const filterParams = useCardSetsFilterParams();

    return (
        <Catcher>
            <ListWrapper
                cleanStore={cleanCardSetsStore}
                filterParams={filterParams}
                getRelationSelect={getCardSetsRelationSelect}
                uploadApiType={'POST_CARD_SET_LIST_CSV'}
            >
                <CardSetsTableList />
            </ListWrapper>
        </Catcher>
    );
});
