class ApiConfig {
    private _apiUrl: string;
    constructor() {
        this._apiUrl = process.env.REACT_APP_API_URL || '';
    }

    public apiUrl(): string {
        return this._apiUrl;
    }

    public accessUrl(): string {
        return `${this._apiUrl}/access/`;
    }
    public webSocketUrl(): string {
        return `wss://echo.websocket.org/`;
    }

    public setApiUrl(url: string): void {
        this._apiUrl = `//${url}`;
    }
}

export const apiConfig = new ApiConfig();
