import React, { ReactNode } from 'react';
import { observer } from 'mobx-react';
import { useUiSearch } from '../hooks';

export const lightText = (text?: string, searchString?: string, className = 'table-light-text'): ReactNode => {
    if (!searchString || !text) {
        return text;
    }

    try {
        const fixSearchString = new RegExp(
            searchString.replace(/[\[\]?*+|{}\\()@.\n\r]/gi, (a) => `\\${a}`),
            'gi',
        );
        return (
            <span
                dangerouslySetInnerHTML={{
                    __html: text.replace(fixSearchString, (s: string) => {
                        return `<span class=${className}>${s}</span>`;
                    }),
                }}
            />
        );
    } catch (e) {
        if (process.env.NODE_ENV === 'development') {
            console.warn(`Don't calc RegExp`, e);
        }
        return text;
    }
};

interface ILightSearch {
    text?: string;
    searchString?: string;
    className?: string;
}
export const LightSearch: React.FC<ILightSearch> = observer(({ text, className, searchString }: ILightSearch) => {
    const _searchString = searchString || useUiSearch();
    return <>{lightText(text, _searchString, className)}</>;
});
