import React, { ChangeEvent, ReactNode, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { Checkbox, DatePicker, Form, Input, InputRef, Select, Table } from 'antd';
import { Rule } from 'antd/es/form';
import { FormListFieldData } from 'antd/es/form/FormList';
import { ColumnType, ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { CloseCircleFilled, SearchOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import {
    IFormValidatorResponse,
    IIncludeCombinationCardSetCardItem,
    IRuleAny,
    ISelect,
    IShowCardColumn,
} from '../../../interfaces';
import {
    useCardSetInfo,
    useCardSetSpinnersInfo,
    useCardsSelect,
    useIncludeCombinationCardSetCardsActive,
    useIncludedCombinationCardSetTotalAmount,
    useRouterState,
    useUiPageSize,
} from '../../../hooks';
import {
    addIncludedList,
    apiMessagesGetFormRules,
    apiMessagesGetListError,
    getCardSetCombination,
    removeIncludedList,
    setUiLastViewComponentName,
    setUiPageSizeFromLocalStorage,
} from '../../../middleware';
import { disablePreviousDates, globalHexId, isNewId } from '../../../instruments';
import { tF, tI } from '../../../translate';
import {
    CardLayout,
    Catcher,
    FilterPageSize,
    FormSelectLight,
    InputDisabledSearch,
    RemoveButton,
} from '../../../Components';
import ViewStyles from '../../styles/m_viewStyles.less';
import Styles from './m_styles.less';

interface IDataType {
    key: string;
    card: ReactNode;
    dateEnd: ReactNode;
    dateStart: ReactNode;
    geolocation: ReactNode;
    showName: string;
    toolBar: ReactNode;
}

interface ICombinationCardSetCardEdit extends IShowCardColumn {
    isNew?: boolean;
    showInactive: boolean;
    setShowInactive: (value: boolean) => void;
}

export const CombinationCardSetCardEdit: React.FC<ICombinationCardSetCardEdit> = observer(
    ({ columnOption, showInactive, setShowInactive, isNew }: ICombinationCardSetCardEdit) => {
        const pageSize: number = useUiPageSize();
        const routerState = useRouterState();
        const { Option } = Select;
        const includeName = 'combinationCardSetCards';
        const cardsList = useCardsSelect();
        const { geolocationEditable, geolocationEnabled } = useCardSetInfo();
        const isWithGeolocation = geolocationEditable && geolocationEnabled;
        const componentSpinners = useCardSetSpinnersInfo();
        const list = useIncludeCombinationCardSetCardsActive(!showInactive);
        const total = useIncludedCombinationCardSetTotalAmount();

        const [searchText, setSearchText] = useState('');
        const [searchVisible, setSearchVisible] = useState<boolean>(false);
        const searchInput = useRef<InputRef>(null);

        const cleanSearch = (): void => {
            setSearchText('');
            setSearchVisible(false);
        };

        const toggleShowInactive = (): void => {
            setShowInactive(!showInactive);
        };

        const filteredListItem = (searchText?: string, fieldValue?: string): boolean => {
            if (!searchText) {
                return true;
            }

            return !!fieldValue && fieldValue.toLowerCase().includes(searchText.toLowerCase());
        };

        const filteredTitle: string = !searchText
            ? ''
            : `filtered count: ${list
                  .filter((c: IIncludeCombinationCardSetCardItem) => filteredListItem(searchText, c.showName))
                  .length.toString()}`;

        const FilterDropdown = (): ReactNode => {
            const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
                setSearchText(e.target.value);
            };

            const hideDropdown = (): void => {
                setSearchVisible(false);
            };

            const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
                if (event.key === 'Escape') {
                    setSearchText('');
                    hideDropdown();
                }
            };

            return (
                <Input
                    allowClear
                    onBlur={hideDropdown}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    onPressEnter={hideDropdown}
                    suffix={<SearchOutlined />}
                    ref={searchInput}
                    value={searchText}
                />
            );
        };

        const getColumnSearchProps = (): ColumnType<IDataType> => {
            const showDropdown = (): void => {
                setSearchVisible(true);
            };

            return {
                filterDropdown: FilterDropdown,
                filterDropdownVisible: searchVisible,
                onFilterDropdownVisibleChange: (visible: boolean): void => {
                    if (visible) {
                        setTimeout(() => searchInput?.current?.focus(), 100);
                    }
                },
                filterIcon: !!searchText ? (
                    <div>
                        <CloseCircleFilled style={{ left: 0 }} onClick={cleanSearch} title={tI('Clean the search')} />
                        <SearchOutlined onClick={showDropdown} title={tF('Search a card')} />
                    </div>
                ) : (
                    <SearchOutlined onClick={showDropdown} title={tF('Search a card')} />
                ),
                filteredValue: [searchText],
                onFilter: (value: string | number | boolean, record: IDataType): boolean => {
                    return filteredListItem(value as string, record.showName);
                },
            };
        };
        const cardsIds = list
            .filter((i: IIncludeCombinationCardSetCardItem) => i.cardId)
            .map((i: IIncludeCombinationCardSetCardItem) => i.cardId);

        const selectCardsListJSX = cardsList.map(
            (s: ISelect): JSX.Element => (
                <Option key={s.id} value={s.id} disabled={s.disabled || cardsIds.includes(s.id)}>
                    <div>{s.name}</div>
                </Option>
            ),
        );

        const addItem = (): void => {
            const id = globalHexId.getId();
            const newItem: IIncludeCombinationCardSetCardItem = {
                id,
                isActive: true,
                showName: `Id:${id}`,
                startDate: dayjs(),
            };
            addIncludedList(includeName, newItem);
        };
        const removeItem = (id: string): void => {
            removeIncludedList(includeName, id);
        };

        const fetchCardSetCombination = async () => {
            await getCardSetCombination(routerState.params.id, { stream: 'true' });
        };

        useEffect(() => {
            if (!isNew) {
                setUiPageSizeFromLocalStorage('CardSetEdit');
                setUiLastViewComponentName('CardSetEdit');
                fetchCardSetCombination();
            } else {
                setUiPageSizeFromLocalStorage('CardSetNew');
                setUiLastViewComponentName('CardSetNew');
            }
        }, []);

        const recordIds = list.map((i: IIncludeCombinationCardSetCardItem) => i.id);

        const apiMessagesListRules: Array<Rule> = [
            {
                validator(rule: IRuleAny): IFormValidatorResponse {
                    return apiMessagesGetListError({
                        listName: includeName,
                        idsList: recordIds,
                        fieldName: rule.field,
                    });
                },
            },
        ];

        const tableColumns: ColumnsType<IDataType> = [
            {
                title: tF('Card'),
                dataIndex: 'card',
                ellipsis: true,
                ...getColumnSearchProps(),
            },
            {
                title: tF('Start Date'),
                dataIndex: 'dateStart',
                width: 180,
            },
            {
                title: tF('End Date'),
                dataIndex: 'dateEnd',
                width: 180,
            },
            isWithGeolocation
                ? {
                      title: tF('Activate Geolocation for this card'),
                      dataIndex: 'geolocation',
                  }
                : {},
            {
                title: '',
                dataIndex: 'toolBar',
                className: ViewStyles.columnToolbarDelete,
            },
        ];

        const pagination: false | TablePaginationConfig =
            !pageSize || pageSize >= total
                ? false
                : {
                      pageSize,
                      className: 'customPagination',
                      showSizeChanger: false,
                  };
        return (
            <Catcher>
                <CardLayout
                    columnOption={columnOption}
                    count={total}
                    idSpinners={componentSpinners}
                    isTable
                    onClickAddButton={addItem}
                    title={'Cards'}
                    extraTitleComponent={
                        <>
                            <div className={Styles.combinedPageSize}>
                                <FilterPageSize />
                            </div>
                            <div className={Styles.inactiveFilter}>
                                <label>{tF('Show Only Inactive')}</label>
                                <Checkbox checked={showInactive} onClick={toggleShowInactive} />
                            </div>
                        </>
                    }
                    titleRight={` ${filteredTitle}`}
                >
                    <Form.List name={includeName}>
                        {(fields: FormListFieldData[], {}): JSX.Element => {
                            const dataSource: IDataType[] = fields.map((field: FormListFieldData, index: number) => {
                                const currentRecord: IIncludeCombinationCardSetCardItem = list?.[field.key] || {
                                    id: `id#${index}`,
                                };
                                const isNew = isNewId(currentRecord.id);
                                return {
                                    key: currentRecord.id,
                                    showName: currentRecord.showName,
                                    card: isNew ? (
                                        <FormSelectLight
                                            fieldName={[field.name, 'cardId']}
                                            isShortForm
                                            rules={apiMessagesListRules}
                                            selectJSX={selectCardsListJSX}
                                            isGetPopupContainerEnabled={false}
                                        />
                                    ) : (
                                        <Form.Item name={[field.name, 'showName']} rules={apiMessagesListRules}>
                                            {searchText ? (
                                                <InputDisabledSearch searchText={searchText}>
                                                    {currentRecord.showName}
                                                </InputDisabledSearch>
                                            ) : (
                                                <Input disabled />
                                            )}
                                        </Form.Item>
                                    ),
                                    dateStart: (
                                        <Form.Item
                                            {...field}
                                            name={[field.name, 'startDate']}
                                            key={'startDate'}
                                            rules={apiMessagesListRules}
                                        >
                                            <DatePicker disabledDate={disablePreviousDates} defaultValue={dayjs()} />
                                        </Form.Item>
                                    ),
                                    dateEnd: (
                                        <Form.Item
                                            {...field}
                                            name={[field.name, 'endDate']}
                                            key={'endDate'}
                                            rules={apiMessagesListRules}
                                        >
                                            <DatePicker disabledDate={disablePreviousDates} />
                                        </Form.Item>
                                    ),
                                    geolocation: isWithGeolocation ? (
                                        <Form.Item
                                            {...field}
                                            name={[field.name, 'geolocationEnabled']}
                                            valuePropName="checked"
                                            rules={apiMessagesGetFormRules}
                                        >
                                            <Checkbox>{tF('Activate Geolocation for this card')}</Checkbox>
                                        </Form.Item>
                                    ) : null,
                                    toolBar: (
                                        <RemoveButton onClick={(): void => removeItem(currentRecord.id)} isGhost />
                                    ),
                                };
                            });
                            return (
                                <Table
                                    dataSource={dataSource}
                                    columns={tableColumns}
                                    pagination={pagination}
                                    className={'table-theme sub-table-wide'}
                                    size={'small'}
                                />
                            );
                        }}
                    </Form.List>
                </CardLayout>
            </Catcher>
        );
    },
);
