import React from 'react';
import { observer } from 'mobx-react';
import { CardMainLayout, Catcher, UploadFile, cardLayoutOptions } from '../../';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { apiManager } from '../../../REST';
import { ICompanyCustomDocsTypes, IItemAPI } from '../../../interfaces';
import { useAuthAcceptanceDocumentsList, useCompanyInfo } from '../../../hooks';
import { addNotification, getAuthAcceptanceDocuments, saveCompanyCustomDocs } from '../../../middleware';
import { tT } from '../../../translate';
import Styles from '../../styles/m_itemCardStyles.less';

export const EditCompanyCustomDocs: React.FC = observer(() => {
    const { id: companyId } = useCompanyInfo();
    const list = useAuthAcceptanceDocumentsList();
    const termsAndConditions = list.find((value) => value.domain === 'terms_and_conditions');
    const privacyPolicy = list.find((value) => value.domain === 'privacy_policy');

    const uploadDoc = async (loaded: UploadRequestOption, docType: ICompanyCustomDocsTypes): Promise<void> => {
        const file = loaded.file;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const { isError: blobIsError, resp } = await apiManager.getApi('POST_BLOB', { id: companyId }, {}, file);

        const { id: blobId } = resp?.data as IItemAPI;

        if (blobIsError) {
            addNotification({
                type: 'error',
                message: 'Error load file!',
                isUsersError: true,
            });
            return;
        }

        const { isError: saveDocIsError } = await saveCompanyCustomDocs({
            companyId,
            blobId,
            docType,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            file,
        });

        if (saveDocIsError) {
            addNotification({
                type: 'error',
                message: 'Error load file!',
                isUsersError: true,
            });
            return;
        }

        addNotification({
            type: 'success',
            message: 'Upload successful!',
            isUsersError: true,
        });

        await getAuthAcceptanceDocuments(companyId);
    };

    const onLoadPrivacyPolicy = async (loaded: UploadRequestOption): Promise<void> => {
        await uploadDoc(loaded, 'PRIVACY_POLICY');
    };

    const onLoadTermsAndConditions = async (loaded: UploadRequestOption): Promise<void> => {
        await uploadDoc(loaded, 'TERMS_AND_CONDITIONS');
    };

    return (
        <Catcher>
            <CardMainLayout
                columnOption={cardLayoutOptions.cardCompanyDocs}
                idSpinners={['GET_COMPANY', 'POST_COMPANY', 'PATCH_COMPANY', 'POST_UPLOAD_ACCEPTANCE_DOCUMENTS']}
                name={'Custom Docs'}
            >
                <p className={Styles.paragraph}>
                    {tT(
                        'Here you can upload your companies Terms & Conditions and Privacy Policy documents. Only PDF files are supported for upload.',
                    )}
                </p>
                <div className={Styles.customDocsUploadContainer}>
                    <div className={Styles.uploadButtonContainer}>
                        <UploadFile
                            accept={'.pdf'}
                            apiType={'POST_BLOB'}
                            text={'Upload Privacy Policy'}
                            fileType={'text'}
                            customOnLoadFile={onLoadPrivacyPolicy}
                        />
                        <span>{privacyPolicy?.fileName}</span>
                    </div>
                    <div className={Styles.uploadButtonContainer}>
                        <UploadFile
                            accept={'.pdf'}
                            apiType={'POST_BLOB'}
                            text={'Upload Terms and Conditions'}
                            fileType={'text'}
                            customOnLoadFile={onLoadTermsAndConditions}
                        />
                        <span>{termsAndConditions?.fileName}</span>
                    </div>
                </div>
            </CardMainLayout>
        </Catcher>
    );
});
