import { OperatorsStore } from '../stores/operators';
import { setUiTotal } from './ui';
import { appConfig } from '../settings';
import { apiManager } from '../REST';
import {
    IBodyListResponse,
    IItemAPI,
    INetworkSelectAPI,
    INetworkSelectItem,
    INetworkSelectItemAPI,
    IOperatorGatewaySelectAPI,
    IOperatorsList,
    IParamsList,
    ISelect,
    ISelectList,
} from '../interfaces';
import { sortByName } from '../instruments';

export function setOperatorsList(data: IOperatorsList): void {
    const OperatorsStoreInstance = OperatorsStore.getInstance();
    OperatorsStoreInstance.setOperatorsList(data);
}

export function setOperatorsSelect(list: ISelectList): void {
    const OperatorsStoreInstance = OperatorsStore.getInstance();
    OperatorsStoreInstance.setOperatorsSelect(list);
}

export async function getOperatorsList(params: IParamsList): Promise<void> {
    const { resp } = await apiManager.getApi('GET_OPERATORS_LIST', { searchParamsList: params });
    const { data, meta } = resp as IBodyListResponse;
    meta && setUiTotal(meta.records_count);
    data && setOperatorsList(data);
}

export async function getOperatorsSelect(
    isNullOperator = false,
    all = false,
    mcc?: string[],
    filterIds?: string[],
): Promise<void> {
    const { resp } = await apiManager.getApi(all ? 'GET_OPERATORS_SELECT_ALL' : 'GET_OPERATORS_SELECT', {
        mcc,
    });
    const { data } = resp as IBodyListResponse;
    if (data) {
        const list: ISelectList = data
            .map((i: IItemAPI) => {
                return {
                    id: i.id,
                    name: i.attributes.name,
                };
            })
            .filter((i: ISelect) => !filterIds?.length || filterIds.includes(i.id))
            .sort(sortByName);
        isNullOperator && list.unshift({ id: appConfig.fakeId, name: 'Default' });
        setOperatorsSelect(list);
    }
}

export function setOperatorsForcedUpdateTime(): void {
    const OperatorsStoreInstance = OperatorsStore.getInstance();
    OperatorsStoreInstance.setForcedUpdateTime();
}

export function cleanOperatorsStore(): void {
    const OperatorsStoreInstance = OperatorsStore.getInstance();
    OperatorsStoreInstance.cleanStore();
}

function setNetworksSelect(data: INetworkSelectItem[]): void {
    const OperatorsStoreInstance = OperatorsStore.getInstance();
    OperatorsStoreInstance.setNetworksSelect(data);
}

function makeNetworksSelect(resp: INetworkSelectAPI): INetworkSelectItem[] {
    return (
        resp?.data
            ?.map((n: INetworkSelectItemAPI) => ({
                id: n.id,
                name: n.attributes.name || '',
                countryId: n.relationships?.country?.data?.id,
            }))
            .sort(sortByName) || []
    );
}
export async function getNetworksSelect(): Promise<void> {
    const { resp } = await apiManager.getApi('GET_NETWORKS_SELECT');
    resp && setNetworksSelect(makeNetworksSelect(resp as INetworkSelectAPI));
}

function setOperatorGatewaySelect(data: ISelect[]): void {
    const OperatorsStoreInstance = OperatorsStore.getInstance();
    OperatorsStoreInstance.setOperatorsGatewaySelect(data);
}

function makeOperatorsGatewaySelect(resp: IOperatorGatewaySelectAPI): ISelect[] {
    return (
        resp?.gateways
            ?.map((n) => ({
                id: n,
                name: n,
                value: n,
            }))
            .sort(sortByName) || []
    );
}

export async function getOperatorGatewaySelect(): Promise<void> {
    const { resp } = await apiManager.getApi('GET_OPERATORS_GATEWAYS_SELECT');
    resp && setOperatorGatewaySelect(makeOperatorsGatewaySelect(resp as IOperatorGatewaySelectAPI));
}

export async function deleteOperator(id: string): Promise<void> {
    const { isError } = await apiManager.getApi('DELETE_OPERATOR', { id });
    if (!isError) {
        setOperatorsForcedUpdateTime();
    }
}
