import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { FeatureFlagsList, IFeatureFlagAttributes } from '../../interfaces';

export function useFeatureFlagsList(): FeatureFlagsList {
    return useContext(MobXProviderContext).RootStore.featureFlagStore.featureFlags;
}

export function useFeatureFlagByKey(key: string): IFeatureFlagAttributes {
    const featureFlags: FeatureFlagsList = useContext(MobXProviderContext).RootStore.featureFlagStore.featureFlags;

    const foundFeatureFlag = featureFlags.find((item) => item.key === key);

    return foundFeatureFlag as IFeatureFlagAttributes;
}
