import React from 'react';
import { observer } from 'mobx-react';
import { useUiSearch } from '../../hooks';
import { lightText } from '../../instruments';
import { Catcher } from '../Catcher';

interface ILightSearch {
    text?: string;
    searchString?: string;
    className?: string;
}
export const LightSearch: React.FC<ILightSearch> = observer(({ text, className, searchString }: ILightSearch) => {
    const _searchString = searchString || useUiSearch();
    return <Catcher>{lightText(text, _searchString, className)}</Catcher>;
});
