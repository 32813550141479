import { action, computed, makeObservable, observable, reaction } from 'mobx';
import { appConfig } from '../settings';
import { IApiMessagesFoundListFieldId, IApiMessagesMessage } from '../interfaces';

export class ApiMessagesStore {
    private constructor() {
        makeObservable(this);
        reaction(
            () => this.lastUpdated,
            () => {
                this.lastUpdatedDelay = Date.now();
            },
            {
                delay: appConfig.APIMessageDelay,
            },
        );
    }

    private static instance: ApiMessagesStore;
    public static getInstance(): ApiMessagesStore {
        if (!ApiMessagesStore.instance) {
            ApiMessagesStore.instance = new ApiMessagesStore();
        }
        return ApiMessagesStore.instance;
    }

    @action cleanStore(): void {
        this.messages = [];
        this.setLastUpdated();
    }

    @observable lastUpdated: number = Date.now();
    @observable lastUpdatedDelay: number = Date.now();
    @action setLastUpdated(): void {
        this.lastUpdated = Date.now();
    }

    @observable messages: Array<IApiMessagesMessage> = [];
    @computed get isMessages(): boolean {
        return !!this.messages.length;
    }

    @computed get formMessages(): Array<IApiMessagesMessage> {
        return this.messages.filter((m) => !m.listName);
    }

    @computed get listMessages(): Array<IApiMessagesMessage> {
        return this.messages.filter((m) => m.listName);
    }

    @action deleteMessageByFieldName(fieldName: string): void {
        this.messages = this.messages.filter((e: IApiMessagesMessage) => e.field !== fieldName);
        this.setLastUpdated();
    }

    @action deleteAllMessageByListId(id: string): void {
        this.messages = this.messages.filter((e: IApiMessagesMessage) => e.listId !== id);
        this.setLastUpdated();
    }

    @action deleteMessageByListId(field: IApiMessagesFoundListFieldId): void {
        this.messages = this.messages.filter(
            (e: IApiMessagesMessage) =>
                !(e.listName === field.listName && e.listId === field.listId && e.listField === field.listField),
        );
        this.setLastUpdated();
    }

    @action addMessages(messages: Array<IApiMessagesMessage>): void {
        this.messages = [...this.messages, ...messages];
        this.setLastUpdated();
    }
}
