import React from 'react';
import { observer } from 'mobx-react';
import { useUiSpinnerCount } from '../../hooks';
import Styles from './m_styles.less';

export const LineSpinner: React.FC = observer(() => {
    const spinnerCount = useUiSpinnerCount();
    return !!spinnerCount ? (
        <div className={Styles.lineSpinner}>
            <div className={Styles.line} style={{ width: `${100 / spinnerCount}%` }} />
        </div>
    ) : null;
});
