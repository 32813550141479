import React, { FC, ReactNode, useState } from 'react';
import { RightCircleOutlined } from '@ant-design/icons';
import cx from 'classnames';
import { IDivMainProps } from '../interfaces';
import { tI } from '../translate';
import Styles from '../Components/styles/m_itemCardStyles.less';

type IUseCollapseIcon = {
    isVisible: boolean;
    setVisible: (v: boolean) => void;
    collapseIcon: ReactNode;
};

type ICollapseIcon = IDivMainProps & {
    children?: number;
    isVisible: boolean;
    setVisible: (v: boolean) => void;
};

const CollapseIcon: FC<ICollapseIcon> = ({ isVisible, setVisible, className, ...props }: ICollapseIcon) => {
    return (
        <div className={cx(className, Styles.collapseIconPlace)} title={isVisible ? tI('Hide') : tI('Show')} {...props}>
            <RightCircleOutlined
                onClick={() => setVisible(!isVisible)}
                className={cx(Styles.collapseIcon, {
                    [Styles.hideIcon]: isVisible,
                    [Styles.showIcon]: !isVisible,
                })}
            />
        </div>
    );
};

export const useCollapseIcon = (init = false, className?: string | undefined): IUseCollapseIcon => {
    const [isVisible, setVisible] = useState(init);

    return {
        isVisible,
        setVisible,
        collapseIcon: <CollapseIcon className={className} isVisible={isVisible} setVisible={setVisible} />,
    };
};
