import { EsNotificationsStore } from '../stores/esNotifications';
import { setUiTotal } from './ui';
import { IBodyItemResponse, IRequestAPIBody, apiManager } from '../REST';
import {
    IEsNotificationsCancelOrderBody,
    IEsNotificationsConfirmOrderBody,
    IEsNotificationsDownloadOrderBody,
    IEsNotificationsItemAPI,
    IEsNotificationsList,
    IEsNotificationsLogsItemApi,
    IEsNotificationsLogsList,
    IEsNotificationsQRcodesItem,
    IEsNotificationsQRcodesItemAPI,
    IEsNotificationsQrcodesList,
    IEsNotificationsReleaseOrderBody,
    IParamsList,
    IPostItem,
} from '../interfaces';
import { mapApi2UiField } from '../instruments';

export function setEsNotificationsList(list: IEsNotificationsList): void {
    const EsNotificationsStoreInstance = EsNotificationsStore.getInstance();
    EsNotificationsStoreInstance.setEsNotificationsList(list);
}

export function setEsNotificationsQrcodesList(list: IEsNotificationsQrcodesList): void {
    const EsNotificationsStoreInstance = EsNotificationsStore.getInstance();
    EsNotificationsStoreInstance.setEsNotificationsQrcodesList(list);
}

export function setEsNotificationsLogsList(list: IEsNotificationsLogsList): void {
    const EsNotificationsStoreInstance = EsNotificationsStore.getInstance();
    EsNotificationsStoreInstance.setEsNotificationsLogsList(list);
}

export async function getEsNotificationsList(params: IParamsList): Promise<void> {
    const { resp } = await apiManager.getApi('GET_ES_PLUS_NOTIFICATIONS_LIST', { searchParamsList: params });
    const { data, meta } = resp;
    meta && setUiTotal(meta.records_count);

    const list: IEsNotificationsList =
        (data as Array<IEsNotificationsItemAPI> | undefined)?.map((i: IEsNotificationsItemAPI) => {
            const { id, header, ...other } = i;
            return { id: id.toString(), ...mapApi2UiField({ ...header, ...other }) };
        }) || [];

    data && setEsNotificationsList(list);
}

export async function getEsNotificationsQrCodeList(params: IParamsList): Promise<void> {
    const { resp } = await apiManager.getApi('GET_ES_PLUS_NOTIFICATIONS_QR_CODES_LIST', { searchParamsList: params });
    const { data, meta } = resp;
    meta && setUiTotal(meta.records_count);

    const list: IEsNotificationsQrcodesList =
        ((data as Array<IEsNotificationsQRcodesItemAPI> | undefined)?.map((value: IEsNotificationsQRcodesItemAPI) => {
            const mappedValues = mapApi2UiField(value) as IEsNotificationsQRcodesItem;
            const qrcodeUrl = `LPA:1$geniox.validspereachdpplus.com$${mappedValues.matchingId}`;

            return { ...mappedValues, qrcodeUrl };
        }) as IEsNotificationsQrcodesList) || [];

    data && setEsNotificationsQrcodesList(list);
}

export async function postEsNotificationsCancelOrder(body: IEsNotificationsCancelOrderBody): Promise<IPostItem> {
    const apiBody = body as IRequestAPIBody;

    const { resp, isError } = await apiManager.getApi('POST_ES2_PLUS_CANCEL_ORDER', {}, apiBody);
    const response = resp as IBodyItemResponse;

    return { isError, resp: response };
}

export async function postEsNotificationsConfirmOrder(body: IEsNotificationsConfirmOrderBody): Promise<IPostItem> {
    const apiBody = body as IRequestAPIBody;

    const { resp, isError } = await apiManager.getApi('POST_ES2_PLUS_CONFIRM_ORDER', {}, apiBody);
    const response = resp as IBodyItemResponse;

    return { isError, resp: response };
}

export async function postEsNotificationsDownloadOrder(body: IEsNotificationsDownloadOrderBody): Promise<IPostItem> {
    const apiBody = body as IRequestAPIBody;

    const { resp, isError } = await apiManager.getApi('POST_ES2_PLUS_DOWNLOAD_ORDER', {}, apiBody);
    const response = resp as IBodyItemResponse;

    return { isError, resp: response };
}

export async function postEsNotificationsReleaseOrder(body: IEsNotificationsReleaseOrderBody): Promise<IPostItem> {
    const apiBody = body as IRequestAPIBody;

    const { resp, isError } = await apiManager.getApi('POST_ES2_PLUS_RELEASE_ORDER', {}, apiBody);
    const response = resp as IBodyItemResponse;

    return { isError, resp: response };
}

export async function getEsPlusNotificationsLogsList(params: IParamsList): Promise<void> {
    const { resp } = await apiManager.getApi('GET_ES_PLUS_NOTIFICATIONS_LOGS_LIST', { searchParamsList: params });
    const { data, meta } = resp;
    meta && setUiTotal(meta.records_count);

    const list: IEsNotificationsLogsList =
        ((data as Array<IEsNotificationsLogsItemApi> | undefined)?.map((value: IEsNotificationsLogsItemApi) => {
            return { ...mapApi2UiField(value) };
        }) as IEsNotificationsLogsList) || [];

    data && setEsNotificationsLogsList(list);
}

export function setEsNotificationsForcedUpdateTime(): void {
    const EsNotificationsStoreInstance = EsNotificationsStore.getInstance();
    EsNotificationsStoreInstance.setForcedUpdateTime();
}

export function setEsNotificationsLogsForcedUpdateTime(): void {
    const EsNotificationsStoreInstance = EsNotificationsStore.getInstance();
    EsNotificationsStoreInstance.setForcedUpdateTimeLogs();
}

export function setEsNotificationsQrcodesForcedUpdateTime(): void {
    const EsNotificationsStoreInstance = EsNotificationsStore.getInstance();
    EsNotificationsStoreInstance.setForcedUpdateTimeQrcodes();
}

export function cleanEsNotificationsStore(): void {
    const EsNotificationsStoreInstance = EsNotificationsStore.getInstance();
    EsNotificationsStoreInstance.cleanStore();
}
