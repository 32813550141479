import React, { ReactNode, useState } from 'react';
import { observer } from 'mobx-react';
import { Alert, Form } from 'antd';
import {
    CardInfoToolBar,
    CardMainLayout,
    Catcher,
    FormSelect,
    InputDebounce,
    UploadFile,
    cardLayoutOptions,
} from '../../';
import { IGetApiResponse } from '../../../REST';
import { IApiErrorMessage, IBodyItemResponse, IEditFormComponentInfo } from '../../../interfaces';
import { useCurrencySelect, useOperatorsSelect, useProfileIsRight, usePurchaseInfo } from '../../../hooks';
import {
    addNotification,
    apiMessagesGetFormRules,
    apiMessagesGetFormRulesFieldBlank,
    setPurchaseRate,
} from '../../../middleware';
import { tF, tNM } from '../../../translate';

export const EditPurchaseFieldsInfo: React.FC<IEditFormComponentInfo> = observer(
    ({ save, isSending, isNew }: IEditFormComponentInfo) => {
        const { id, name } = usePurchaseInfo();
        const [isMessage, setIsMessage] = useState(false);
        const [errorMessageBody, setErrorMessageBody] = useState<ReactNode>();
        const [isErrorMessage, setIsErrorMessage] = useState(false);
        const currencySelect = useCurrencySelect;
        const isAdmin = useProfileIsRight('ADMIN');
        const operatorsSelectHook = useOperatorsSelect;

        const cleanUploadError = (): void => {
            setIsMessage(false);
            setIsErrorMessage(false);
            setErrorMessageBody(undefined);
        };

        const onDownload = (apiResponse: IGetApiResponse): void => {
            const { isError, resp } = apiResponse;
            if (isError) {
                const errorList = resp.errors;
                const ErrorBodyJSX = errorList?.map((item: IApiErrorMessage, index: number) => {
                    return (
                        <div key={index}>
                            {item.source.pointer} {'->'} {item.detail}
                        </div>
                    );
                });
                setErrorMessageBody(ErrorBodyJSX);
                setIsErrorMessage(true);
                setIsMessage(true);
            } else {
                setIsMessage(true);
                addNotification({
                    type: 'success',
                    message: 'Upload successful!',
                });
                setPurchaseRate(resp as IBodyItemResponse);
            }
        };

        const nameText = tF('Name');

        return (
            <Catcher>
                <CardMainLayout
                    idSpinners={[
                        'GET_COUNTRIES_SELECT',
                        'GET_CURRENCY_SELECT',
                        'GET_NETWORKS_SELECT',
                        'GET_OPERATORS_SELECT_ALL',
                        'GET_OPERATORS_SELECT',
                        'GET_PURCHASE_RATE_LIST',
                        'GET_RATE_UNIT_SELECT',
                        'GET_ZONE_SELECT',
                        'PATCH_PURCHASE_RATE_LIST',
                        'POST_PURCHASE_RATE_CSV',
                        'POST_PURCHASE_RATE_LIST',
                    ]}
                    name={name}
                    columnOption={cardLayoutOptions.purchaseRate}
                >
                    <Form.Item name="name" label={nameText} rules={apiMessagesGetFormRules}>
                        <InputDebounce placeholder={nameText} isFirstInput />
                    </Form.Item>

                    <FormSelect
                        fieldName={'operatorId'}
                        isNotClear
                        label={tF('Operator')}
                        rules={apiMessagesGetFormRulesFieldBlank()}
                        useSelectHook={operatorsSelectHook}
                    />
                    <FormSelect
                        fieldName={'currencyId'}
                        isNotClear
                        label={tF('Currency')}
                        rules={apiMessagesGetFormRulesFieldBlank()}
                        useSelectHook={currencySelect}
                    />
                    <CardInfoToolBar onSave={save} isLoading={isSending} isNew={isNew}>
                        {isAdmin && !isNew ? (
                            <UploadFile apiType={'POST_PURCHASE_RATE_CSV'} id={id} onDownload={onDownload} />
                        ) : null}
                    </CardInfoToolBar>
                    {isMessage ? (
                        <Alert
                            type={isErrorMessage ? 'error' : 'success'}
                            message={tNM(isErrorMessage ? 'Upload Error!' : 'Upload successful!')}
                            description={isErrorMessage ? errorMessageBody : null}
                            showIcon
                            closable
                            afterClose={cleanUploadError}
                        />
                    ) : null}
                </CardMainLayout>
            </Catcher>
        );
    },
);
