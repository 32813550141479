import React from 'react';
import { observer } from 'mobx-react';
import { Row } from 'antd';
import { IShowForm } from '../../../interfaces';
import { useCompanyInfo, useCompanyIsMNOHost, useIsMyCompany, useProfile, useProfileIsRight } from '../../../hooks';
import { Catcher, cardLayoutOptions } from '../../../Components';
import {
    ShowCompanyCardSetsTable,
    ShowCompanyCustomize,
    ShowCompanyInfo,
    ShowCompanySalesRateTable,
    ShowCompanySubscriberVisibilityGrantedTable,
    ShowCompanySubscriptions,
    ShowCompanyUsersTable,
} from '../Components';
import Styles from '../../styles/m_itemCardStyles.less';

export const ShowCompany: React.FC<IShowForm> = observer(({ setEditMode }: IShowForm) => {
    const { tightLists } = cardLayoutOptions;
    const isMyCompany = useIsMyCompany();
    const isMNOHost = useCompanyIsMNOHost();
    const isLord = useProfileIsRight('LORD');
    const isAdmin = useProfileIsRight('ADMIN');
    const isFinance = useProfileIsRight('FINANCE');
    const { isMainMaternalCompany, companyId } = useProfile();
    const { id: currentCompanyId } = useCompanyInfo();

    return (
        <Catcher>
            <article className={Styles.main}>
                <Row>
                    <ShowCompanyInfo setEditMode={setEditMode} />
                    {(isFinance || isAdmin) && isMyCompany ? <ShowCompanySubscriptions /> : null}
                    {isLord ? <ShowCompanyCustomize /> : null}
                    {(isMainMaternalCompany || companyId === currentCompanyId) && isMNOHost ? (
                        <ShowCompanySubscriberVisibilityGrantedTable columnOption={tightLists} />
                    ) : null}
                    <ShowCompanySalesRateTable columnOption={tightLists} />
                    <ShowCompanyUsersTable columnOption={tightLists} />
                    {isMNOHost ? null : <ShowCompanyCardSetsTable columnOption={tightLists} />}
                </Row>
            </article>
        </Catcher>
    );
});
