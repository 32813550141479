import { CallDataRecordsStore } from '../stores/callDataRecords';
import { CallDataRecordsBetaStore } from '../stores/callDataRecordsBeta';
import { CompaniesStore } from '../stores/companies';
import { ProfileStore } from '../stores/profile';
import { UiStore } from '../stores/ui';
import {
    cleanCardSetsStore,
    cleanCardsStore,
    cleanCompaniesStore,
    cleanCountriesStore,
    cleanSubscribersStore,
    getCompaniesSelect,
    getOperatorsSelect,
    getUnitTypes,
    setUiTotal,
} from './';
import dayjs from 'dayjs';
import { appConfig } from '../settings';
import { apiManager } from '../REST';
import {
    ICallDataRecordsItemAPI,
    ICallDataRecordsListAPI,
    ICallDataRecordsListItem,
    IItemAPI,
    IParamsList,
} from '../interfaces';
import { dateText2Utc, mapApi2UiField } from '../instruments';

export function setCallDataRecordsListStore(apiList: ICallDataRecordsListAPI, isBeta?: boolean): void {
    if (apiList.data) {
        const cards: IItemAPI[] = apiList?.included?.filter((i) => i.type === 'card') || [];
        const operators: IItemAPI[] = apiList?.included?.filter((i) => i.type === 'operator') || [];
        const cardSets: IItemAPI[] = apiList?.included?.filter((i) => i.type === 'card_set') || [];
        const countries: IItemAPI[] = apiList?.included?.filter((i) => i.type === 'country') || [];
        const subscribers: IItemAPI[] = apiList?.included?.filter((i) => i.type === 'subscriber') || [];

        const UiInstance = UiStore.getInstance();
        const CompaniesInstance = CompaniesStore.getInstance();
        const filterCompanyId = UiInstance.companyId;
        const companiesSelect = CompaniesInstance.select;
        const filteredCompany = companiesSelect?.find((company) => company?.id === filterCompanyId);

        const list: ICallDataRecordsListItem[] =
            apiList.data.map((item: ICallDataRecordsItemAPI) => {
                const metadata = mapApi2UiField(item?.attributes?.metadata || {});
                const attributes = mapApi2UiField(item.attributes);
                delete attributes.metadata;
                const isUnAssigned = item.attributes?.unassigned_card_set;
                const cardSetId = item.relationships?.to_card_set?.data?.id;
                let cardSetName: string | undefined = '';
                if (isUnAssigned || !cardSetId) {
                    const ProfileInstance = ProfileStore.getInstance();
                    const company = ProfileInstance.profile.company;
                    cardSetName = `${filteredCompany?.name || company} unassigned`;
                } else {
                    cardSetName = cardSets.find((i: IItemAPI) => i.id === cardSetId)?.attributes?.name;
                }

                const { startDate, endDate } = attributes;
                const cardId = item.relationships?.card?.data?.id;
                const foundCardAttributes = mapApi2UiField(
                    cards.find((i: IItemAPI) => i.id === cardId)?.attributes || {},
                );
                const { name: cardName, shortId: cardShortId, cardPlmnList } = foundCardAttributes;
                const operatorId = item.relationships?.operator?.data?.id;
                const operatorName = operators.find((i: IItemAPI) => i.id === operatorId)?.attributes?.name;
                const countryId = item.relationships?.country?.data?.id;
                const countryName = countries.find((i: IItemAPI) => i.id === countryId)?.attributes?.name;
                const subscriberId = item.relationships?.subscriber?.data?.id;
                const foundSubscriberAttributes = mapApi2UiField(
                    subscribers.find((i: IItemAPI) => i.id === subscriberId)?.attributes || {},
                );
                const { imsi } = foundSubscriberAttributes;
                const visitedNetworkId = item.relationships?.visited_network?.data?.id;
                const visitedNetworkName = operators.find((i: IItemAPI) => i.id === visitedNetworkId)?.attributes?.name;
                return {
                    id: item.id,
                    ...attributes,
                    metadata: {
                        ...metadata,
                    },
                    cardId,
                    cardName,
                    cardShortId,
                    cardPlmnList,
                    operatorId,
                    operatorName,
                    cardSetId,
                    cardSetName,
                    countryId,
                    countryName,
                    subscriberId,
                    imsi,
                    visitedNetworkId,
                    visitedNetworkName,
                    cost: Number(item.attributes.cost).toFixed(5),
                    units: Number(item.attributes.units).toFixed(),
                    startDate: dateText2Utc(startDate),
                    endDate: dateText2Utc(endDate),
                };
            }) || [];

        if (isBeta) {
            const instance = CallDataRecordsBetaStore.getInstance();
            instance.setCallDataRecordsBetaList(list);
        } else {
            const instance = CallDataRecordsStore.getInstance();
            instance.setCallDataRecordsList(list);
        }
    }
}

export async function getCallDataRecordsList(params: IParamsList, isBeta?: boolean): Promise<void> {
    const { resp } = await apiManager.getApi('GET_CALL_DATA_RECORDS_LIST', { searchParamsList: params });
    const { data, meta } = resp;
    data && setCallDataRecordsListStore(resp as ICallDataRecordsListAPI, isBeta);
    !isBeta && meta && setUiTotal(meta.records_count);
}

export async function getCallDataRecordsLast5(params: IParamsList): Promise<void> {
    const startDate = dayjs().subtract(appConfig.last5CDRMonthLimit, 'M').format(appConfig.formatDate);

    const { resp } = await apiManager.getApi('GET_CALL_DATA_RECORDS_LAST_5', {
        searchParamsList: {
            ...params,
            startDate,
        },
    });
    const { data } = resp;
    data && setCallDataRecordsListStore(resp as ICallDataRecordsListAPI);
}

export function setCallDataRecordsForcedUpdateTime(): void {
    const CallDataRecordsStoreInstance = CallDataRecordsStore.getInstance();
    CallDataRecordsStoreInstance.setForcedUpdateTime();
}

export async function getCallDataRecordsRelationSelect(): Promise<void> {
    getCompaniesSelect();
    getOperatorsSelect(false, true);
    await getUnitTypes();
}

export function cleanCallDataRecordsStore(): void {
    cleanCompaniesStore();
    cleanCardSetsStore();
    cleanCountriesStore();
    cleanSubscribersStore();
    cleanCardsStore();
    const CallDataRecordsStoreInstance = CallDataRecordsStore.getInstance();
    CallDataRecordsStoreInstance.cleanStore();
}
