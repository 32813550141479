import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Catcher } from '../';
import { IGetApiType } from '../../REST';
import { IShowFormComponent } from '../../interfaces';
import { useRouterStore } from '../../hooks';
import {
    abortSelectedFetch,
    cleanRateRelationSelect,
    cleanRateStore,
    getRate,
    getRateEditRelationSelect,
    getRateShowRelationSelect,
} from '../../middleware';
import { EditRareForms, ShowRate } from './Components';

export const SalesRateList: React.FC<IShowFormComponent> = observer(({ isEdit, isNew }: IShowFormComponent) => {
    const routerStore = useRouterStore();
    const [id, setId] = useState('');
    const usedApiTypes: IGetApiType[] = [
        'GET_SALES_RATE_LIST',
        'GET_COMPANIES_SELECT',
        'GET_COUNTRIES_SELECT',
        'GET_CURRENCY_SELECT',
        'GET_NETWORKS_SELECT',
        'GET_OPERATORS_SELECT_ALL',
        'GET_OPERATORS_SELECT',
        'GET_RATE_UNIT_SELECT',
        'GET_ZONE_SELECT',
    ];

    useEffect(() => {
        return (): void => {
            abortSelectedFetch(usedApiTypes);
            cleanRateStore();
            cleanRateRelationSelect();
        };
    }, []);

    useEffect(() => {
        const { params } = routerStore.routerState;
        if (params.id) {
            setId(params.id);
        }
    });

    const _getRate = async (id: string): Promise<void> => {
        const isGetRate = await getRate(id);
        if (!isGetRate) {
            await routerStore.goTo('SalesRateLists');
        }
    };

    const getData = async (id: string): Promise<void> => {
        if (isEdit || isNew) {
            await getRateEditRelationSelect();
        } else {
            await getRateShowRelationSelect();
        }
        _getRate(id);
    };

    useEffect(() => {
        if (!isEdit && !isNew && id) {
            getData(id);
        } else if (!!id === !isNew) {
            getData(id);
        }
    }, [isEdit, isNew, id]);

    const setEdit = async (isEdit?: boolean): Promise<void> => {
        if (isNew) {
            await routerStore.goTo('SalesRateLists');
        } else if (isEdit) {
            await getRateEditRelationSelect();
            await _getRate(id);
            await routerStore.goTo('SalesRateListEdit', { id });
        } else {
            await getRateShowRelationSelect();
            await _getRate(id);
            await routerStore.goTo('SalesRateList', { id });
        }
    };

    return (
        <Catcher>
            {isEdit || isNew ? (
                <EditRareForms setEditMode={setEdit} isNew={isNew} loadingTypes={usedApiTypes} />
            ) : (
                <ShowRate setEditMode={setEdit} />
            )}
        </Catcher>
    );
});
