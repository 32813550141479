import React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import { IOperatorsTableListItem } from '../../../interfaces';
import { useOperatorsTable, useUiIsLoading, useUiSearch } from '../../../hooks';
import { deleteOperator } from '../../../middleware';
import { lightText } from '../../../instruments';
import { tF, tI } from '../../../translate';
import { Catcher, LinkComponent, TableItemToolbar, columnToolbar } from '../../../Components';
import '../../../customStyle/table.less';
import Styles from '../../styles/m_viewStyles.less';

export const OperatorsTableList: React.FC = observer(() => {
    const isLoading = useUiIsLoading();
    const operatorsList = useOperatorsTable();
    const search = useUiSearch();

    const tableColumns = [
        {
            title: tF('Name'),
            dataIndex: 'nameLink',
        },
        {
            title: tF('Tadig'),
            dataIndex: 'tadig',
        },
        {
            title: tF('MCC/MNC'),
            dataIndex: 'mccMnc',
        },
        columnToolbar,
    ];
    const tableData = operatorsList.map((o: IOperatorsTableListItem) => {
        const deleteItem = (): void => {
            deleteOperator(o.id);
        };
        const name = o.name ? o.name : `# ${o.id}`;

        return {
            key: o.id,
            ...o,
            nameLink: <LinkComponent currentListComponent={'OperatorsList'} id={o.id} isLight name={name} />,
            tadig: lightText(o.tadig, search),
            mccMnc: lightText(o.mccMnc, search),
            toolbar: <TableItemToolbar deleteItem={deleteItem} id={o.id} itemName={`"${name}" ${tI('operator')}`} />,
        };
    });

    return (
        <Catcher>
            <div className={Styles.tablePlace}>
                <Table
                    className={'table-theme'}
                    columns={tableColumns}
                    dataSource={tableData}
                    loading={isLoading}
                    pagination={false}
                    size={'middle'}
                />
            </div>
        </Catcher>
    );
});
