import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Divider, Row } from 'antd';
import {
    BankOutlined,
    FileProtectOutlined,
    FileTextOutlined,
    LockOutlined,
    MailOutlined,
    PhoneOutlined,
    SettingOutlined,
    StopOutlined,
    TeamOutlined,
    UserOutlined,
} from '@ant-design/icons';
import { ApiKeys } from './ApiKeys';
import { ShowUserFeatureFlags } from './ShowUserFeatureFlags';
import { IFieldLayout, IShowForm } from '../../../interfaces';
import {
    usePagesStoreCurrentPage,
    useProfile,
    useProfileFeatureFlags,
    useProfileIsRight,
    useRouterStore,
    useUser,
} from '../../../hooks';
import { dateText2Utc, fullName } from '../../../instruments';
import { ITrPage, tI, tP } from '../../../translate';
import {
    CardInfoToolBar,
    CardMainLayout,
    Catcher,
    FieldInfoAdvanced,
    LanguageSelector,
    LinkComponent,
    cardLayoutOptions,
} from '../../../Components';
import Styles from '../../styles/m_itemCardStyles.less';

export const ShowUser: React.FC<IShowForm> = observer(({ setEditMode }: IShowForm) => {
    const layout: IFieldLayout = {
        labelCol: {
            flex: '0 1 180px',
        },
        valCol: {
            flex: '1 1 270px',
        },
    };
    const {
        id,
        company,
        companyId,
        email,
        failedLoginCount,
        firstName = '',
        func,
        lastName = '',
        latestAcceptedAt,
        notes,
        phoneNumber,
        suspended,
        userType,
    } = useUser();
    const featureFlags = useProfileFeatureFlags();
    const routerStore = useRouterStore();
    const isMe = useProfile().id === id;
    const isAdmin = useProfileIsRight('ADMIN');
    const isRoot = useProfileIsRight('ROOT');
    const userName = `${firstName} ${lastName}`;
    const { title } = usePagesStoreCurrentPage();
    const showTitle = isMe
        ? tP(title as ITrPage)
        : firstName || lastName
        ? userName
        : `${tP(title as ITrPage)} ${id ? '#' + id : ''}`;

    useEffect(() => {
        if (document.title !== showTitle) {
            document.title = showTitle;
        }
    }, [showTitle]);

    const goUsersList = (): void => {
        routerStore.goTo('UsersList');
    };

    return (
        <Catcher>
            <Row>
                <CardMainLayout
                    columnOption={cardLayoutOptions.cardInfoEdit}
                    idSpinners={['GET_USER']}
                    name={fullName(firstName, lastName)}
                    icon={<UserOutlined />}
                >
                    <FieldInfoAdvanced
                        layout={layout}
                        icon={<SettingOutlined className={Styles.labelIcon} />}
                        title={'Function'}
                    >
                        {func}
                    </FieldInfoAdvanced>
                    <FieldInfoAdvanced
                        layout={layout}
                        icon={<BankOutlined className={Styles.labelIcon} />}
                        title={'Company'}
                    >
                        <LinkComponent currentListComponent={'CompaniesList'} id={companyId} name={company} />
                    </FieldInfoAdvanced>
                    <FieldInfoAdvanced
                        layout={layout}
                        icon={<MailOutlined className={Styles.labelIcon} />}
                        title={'Email'}
                    >
                        {email}
                    </FieldInfoAdvanced>
                    <FieldInfoAdvanced
                        layout={layout}
                        icon={<PhoneOutlined className={Styles.labelIcon} />}
                        title={'Phone number'}
                    >
                        {phoneNumber}
                    </FieldInfoAdvanced>
                    <Divider />
                    <FieldInfoAdvanced
                        layout={layout}
                        icon={<TeamOutlined className={Styles.labelIcon} />}
                        title={'User Type'}
                    >
                        {userType}
                    </FieldInfoAdvanced>
                    <FieldInfoAdvanced
                        layout={layout}
                        icon={<LockOutlined className={Styles.labelIcon} />}
                        title={'Failed login'}
                    >
                        {failedLoginCount}
                    </FieldInfoAdvanced>
                    <FieldInfoAdvanced
                        layout={layout}
                        icon={<StopOutlined className={Styles.labelIcon} />}
                        title={'Suspended'}
                    >
                        {suspended}
                    </FieldInfoAdvanced>
                    <FieldInfoAdvanced
                        layout={layout}
                        icon={<FileTextOutlined className={Styles.labelIcon} />}
                        title={'Notes'}
                    >
                        {notes}
                    </FieldInfoAdvanced>
                    <FieldInfoAdvanced
                        layout={layout}
                        icon={<FileProtectOutlined className={Styles.labelIcon} />}
                        title={'T&Cs and Privacy Policy accepted'}
                    >
                        {latestAcceptedAt ? dateText2Utc(latestAcceptedAt) : tI('Never')}
                    </FieldInfoAdvanced>
                    {isMe ? (
                        <>
                            <Divider />
                            <FieldInfoAdvanced
                                layout={layout}
                                icon={<FileTextOutlined className={Styles.labelIcon} />}
                                title={'Language'}
                            >
                                <LanguageSelector />
                            </FieldInfoAdvanced>
                            <Divider />
                        </>
                    ) : null}
                    <CardInfoToolBar isBackToList={!isMe} onCancel={goUsersList} setEditMode={setEditMode} />
                </CardMainLayout>
                {isAdmin && isMe ? <ApiKeys /> : null}
                {isRoot && isMe ? <ShowUserFeatureFlags /> : null}
            </Row>
        </Catcher>
    );
});
