import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Form, Input } from 'antd';
import { Catcher, makeRuleEmail } from '../../';
import { FieldData } from 'rc-field-form/es/interface';
import { IFormFields, IValidationField } from '../../../interfaces';
import { addNotification, sendPasswordUpdateEmail } from '../../../middleware';
import { tB, tF, tNM, tT } from '../../../translate';
import '../../../customStyle/form.less';
import '../../../customStyle/modal.less';
import '../styles.less';

interface IForgotPasswordForm {
    setOpenModal: (isOpen: boolean) => void;
}

export const ForgotPasswordForm: React.FC<IForgotPasswordForm> = observer(({ setOpenModal }: IForgotPasswordForm) => {
    const [form] = Form.useForm();
    const [initialValues] = useState({ email: '' });
    const [validatedFields] = useState(['email']);
    const [isValid, setValid] = useState(false);
    const [isSending, setSending] = useState(false);

    const onFieldsChange = (changedFields: FieldData[], allFields: Array<FieldData>): void => {
        setValid(
            (allFields as Array<IValidationField>)
                .filter((f: IValidationField) => validatedFields.includes(f.name[0]))
                .map((f: IValidationField) => f.touched && !f.errors.length)
                .reduce((result: boolean, item: boolean) => result && item),
        );
    };

    const onFinishHandler = async (data: IFormFields): Promise<void> => {
        const { email } = data;
        if (email) {
            setSending(true);
            const isError = await sendPasswordUpdateEmail(email);
            setSending(false);
            addNotification({
                type: !isError ? 'info' : 'error',
                message: !isError ? tNM('An email has been sent!') : tNM('An unknown error has occurred.'),
                duration: 5,
                isTranslated: true,
            });
        }
        setOpenModal(false);
    };

    return (
        <Catcher>
            <p>{tT('Step 1 Enter your email address and we email you instructions to reset your password.')}</p>
            <p>
                {tT(
                    'Step 2 We will email you a secure link to a secure page so you can create a new password. The link will expire after 20 minutes.',
                )}
            </p>
            <Form
                form={form}
                name="forgotPassword"
                className={'formStyle'}
                initialValues={initialValues}
                onFieldsChange={onFieldsChange}
                onFinish={onFinishHandler}
                layout={'vertical'}
            >
                <Form.Item name="email" rules={makeRuleEmail()}>
                    <Input placeholder={tF('Email')} />
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                        data-test="ButtonForgotPasswordModal"
                        block
                        disabled={!isValid}
                        loading={isSending}
                    >
                        {tB('Send')}
                    </Button>
                </Form.Item>
            </Form>
            <p>{tT('If you do not receive your email please check your spam and junk folders!')}</p>
        </Catcher>
    );
});
