import React from 'react';
import { observer } from 'mobx-react';
import { Popover, Table, Tooltip } from 'antd';
import { InfoCircleOutlined, WifiOutlined } from '@ant-design/icons';
import cx from 'classnames';
import { ICardWithIdSpinners, IDeviceInfo } from '../../../interfaces';
import { useDeviceHistoryTop5 } from '../../../hooks';
import { cleanObjectValue, dateText2Utc, objIsNotEmpty } from '../../../instruments';
import { tF } from '../../../translate';
import { CardLayout, Catcher } from '../../../Components';
import { DevicePopover } from '../Components';
import Styles from '../../styles/m_itemCardStyles.less';
import ViewStyles from '../../styles/m_viewStyles.less';

export const DeviceHistoryTable: React.FC<ICardWithIdSpinners> = observer(
    ({ columnOption, title, idSpinners }: ICardWithIdSpinners) => {
        const deviceHistory = useDeviceHistoryTop5();

        const tableColumns = [
            {
                title: tF('Device'),
                dataIndex: 'name',
                ellipsis: true,
            },
            {
                title: tF('IMEI'),
                dataIndex: 'imei',
            },
            {
                title: tF('Device type'),
                dataIndex: 'deviceType',
            },
            {
                title: tF('Start Date'),
                dataIndex: 'dateName',
                className: ViewStyles.columnCenter,
            },
        ];

        const tableData = deviceHistory?.map((d: IDeviceInfo, index) => {
            const contentDevicePopover = <DevicePopover info={d} />;
            const isCardDeviceInfo = objIsNotEmpty(cleanObjectValue(d));

            return {
                key: d.id,
                ...d,
                name: (
                    <>
                        {isCardDeviceInfo ? (
                            <Popover placement="right" title="Device Information" content={contentDevicePopover}>
                                <a>
                                    <span className={Styles.deviceName}>{d.deviceModelName}</span>
                                    <InfoCircleOutlined />
                                </a>
                            </Popover>
                        ) : (
                            <span className={Styles.deviceName}>{d.deviceModelName}</span>
                        )}
                        {index === deviceHistory.length - 1 ? (
                            <Tooltip placement="top" title={tF('Currently used device')}>
                                <WifiOutlined className={Styles.isActiveIcon} />
                            </Tooltip>
                        ) : null}
                    </>
                ),
                dateName: dateText2Utc(d.createdAt),
            };
        });

        return (
            <Catcher>
                <CardLayout columnOption={columnOption} title={title} isTable idSpinners={idSpinners}>
                    <Table
                        className={cx('table-theme sub-table', Styles.deviceHistoryTable)}
                        columns={tableColumns}
                        dataSource={tableData}
                        pagination={false}
                        size={'middle'}
                    />
                </CardLayout>
            </Catcher>
        );
    },
);
