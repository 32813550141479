import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Catcher } from '../../';
import { HashCompaniesTable } from './';
import { getCompaniesHashSettingsList, hashCompaniesInfoSetting } from '../../../middleware';
import Styles from '../../styles/m_itemCardStyles.less';

export const HashCompaniesInfo: React.FC = observer(() => {
    const [search, setSearch] = useState<string>('');

    useEffect(() => {
        getCompaniesHashSettingsList();
    }, []);

    const onSearch = async () => {
        await getCompaniesHashSettingsList(search);
    };

    const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
    };

    const handleClick = async (value: boolean, id: string) => {
        const isError = await hashCompaniesInfoSetting(id, value);

        if (!isError) {
            await getCompaniesHashSettingsList(search);
        }
    };

    return (
        <Catcher>
            <div className={Styles.main}>
                <HashCompaniesTable
                    onSearch={onSearch}
                    onChange={onChange}
                    handleClick={handleClick}
                    searchValue={search}
                />
            </div>
        </Catcher>
    );
});
