import React from 'react';
import { observer } from 'mobx-react';
import { PlusOutlined } from '@ant-design/icons';
import { Catcher, GoPageButton } from '../index';
import { usePagesStoreThisListRight } from '../../hooks';
import Styles from '../styles/m_viewStyles.less';

export const NewListItem: React.FC = observer(() => {
    const { isNew, NEW } = usePagesStoreThisListRight();

    return (
        <Catcher>
            {isNew && NEW ? (
                <GoPageButton className={Styles.addButton} componentName={NEW} title={'New'} icon={<PlusOutlined />} />
            ) : null}
        </Catcher>
    );
});
