import React from 'react';
import { ActivityExpandedRowLine } from './ActivityExpandedRowLine';
import { ActivityLogFieldValue } from './ActivityLogFieldValue';
import { ActivityLogLists } from './ActivityLogLists';
import { ActivityLogSubList, IActivityLogItem } from '../../../interfaces';
import Styles from '../../styles/m_viewStyles.less';

export function ActivityLogViewInfoRecords({ log }: { log: IActivityLogItem }) {
    const { meta } = log;

    return (
        <div className={Styles.expandedRow}>
            {Array.isArray(meta)
                ? meta.map((metaValue) => {
                      return (
                          <div className={Styles.expandedRowLineRecords} key={Number(metaValue.id)}>
                              {Object.entries(metaValue).map(([metaKey, recordValue], metaInd) => {
                                  if (typeof recordValue === 'object' && !Array.isArray(recordValue)) {
                                      return null;
                                  }

                                  if (Array.isArray(recordValue)) {
                                      const subRecords = recordValue.map((recordValue) => {
                                          return Object.entries(recordValue).map(
                                              ([recordKey, innerRecordValue], recordInd) => (
                                                  <ActivityExpandedRowLine
                                                      title={recordKey}
                                                      key={recordKey + recordInd.toString()}
                                                  >
                                                      <ActivityLogFieldValue
                                                          value={innerRecordValue}
                                                          prop={recordKey}
                                                      />
                                                  </ActivityExpandedRowLine>
                                              ),
                                          );
                                      });

                                      return (
                                          <ActivityExpandedRowLine title={metaKey} key={metaKey + metaInd.toString()}>
                                              <div className={Styles.expandedRowLineInnerRecords}>{subRecords}</div>
                                          </ActivityExpandedRowLine>
                                      );
                                  }

                                  return (
                                      <ActivityExpandedRowLine title={metaKey} key={metaKey + metaInd.toString()}>
                                          <ActivityLogFieldValue
                                              value={recordValue}
                                              prop={metaKey}
                                              entity={log.entity}
                                          />
                                      </ActivityExpandedRowLine>
                                  );
                              })}
                          </div>
                      );
                  })
                : null}
            {Object.entries(meta).map(([metaKey, metaValue]) => {
                if (metaKey === ActivityLogSubList.LISTS) {
                    return <ActivityLogLists key={metaKey} lists={metaValue} />;
                }
            })}
        </div>
    );
}
