import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Checkbox, Form } from 'antd';
import { Rule } from 'antd/es/form';
import { IEditFormComponentInfo } from '../../../interfaces';
import {
    useCatalogCompanyTypeFilterNotMNOSelect,
    useCatalogCompanyTypeSelect,
    useCheckCustomerPrivacy,
    useCompaniesSelect,
    useCompanyInfo,
    useCompanyIsMNOHost,
    useIsAbleToManageCompanyGeolocation,
    useMyCompanySelect,
    useProfile,
    useProfileIsRight,
    useUsersSelect,
} from '../../../hooks';
import { apiMessagesGetFormRules } from '../../../middleware';
import { formatDataTestAttribute } from '../../../instruments/testing';
import { tF, tP, tV } from '../../../translate';
import {
    CardInfoToolBar,
    CardMainLayout,
    Catcher,
    FormSelect,
    InputDebounce,
    TextAreaDebounce,
    cardLayoutOptions,
} from '../../../Components';

export const EditCompanyInfo: React.FC<IEditFormComponentInfo> = observer(
    ({ save, isSending, isMyCompany, isNew }: IEditFormComponentInfo) => {
        const [currentName, setName] = useState('');
        const { whiteLabel, companyId: myCompanyId } = useProfile();
        const { id: currentCompanyId, name, isHashed } = useCompanyInfo();
        const isLord = useProfileIsRight('LORD');
        const isCompanyMNOHost = useCompanyIsMNOHost();
        const { isMainMaternalCompany } = useProfile();
        const companyTypeSelectHook = useCatalogCompanyTypeSelect;
        const parentsSelectHook = useCompaniesSelect;
        const myCompanySelectHook = useMyCompanySelect;
        const usersSelectHook = useUsersSelect;
        const isCheckCustomerPrivacy = useCheckCustomerPrivacy();

        const validateAPIAndName: Array<Rule> = [
            { required: true, message: tV('Please input Name.') },
            ...apiMessagesGetFormRules,
        ];
        const validateAPIAndCompanyType: Array<Rule> = [
            { required: true, message: tV('Please select Company Type.') },
            ...apiMessagesGetFormRules,
        ];
        const validateAPIAndParent: Array<Rule> = [
            { required: true, message: tV('Please select Parent.') },
            ...apiMessagesGetFormRules,
        ];

        const isGeolocationManageable = useIsAbleToManageCompanyGeolocation(isMyCompany);
        const selectHook = isMyCompany ? myCompanySelectHook : parentsSelectHook;

        useEffect(() => {
            if (name !== undefined && currentName === '' && !isNew) {
                setName(name);
            }
        }, [name]);

        const nameTitle = tF('Name');
        const notesTitle = tF('Notes');

        return (
            <Catcher>
                <CardMainLayout
                    columnOption={cardLayoutOptions.cardEditCompany}
                    idSpinners={[
                        'GET_COMPANIES_CHILDREN_SELECT',
                        'GET_COMPANIES_SELECT',
                        'GET_COMPANY',
                        'GET_COMPANY_TYPES_SELECT',
                        'GET_PAYMENT_SELECT',
                    ]}
                    isEdit
                    name={myCompanyId === currentCompanyId ? tP('My Company') : currentName}
                    dataTest={'EditCompanyInfo'}
                >
                    <Form.Item
                        name="name"
                        label={nameTitle}
                        rules={validateAPIAndName}
                        data-test={formatDataTestAttribute('EditCompanyInfo', 'Name', 'FormItem')}
                    >
                        <InputDebounce
                            disabled={isHashed}
                            placeholder={nameTitle}
                            isFirstInput
                            data-test={formatDataTestAttribute('EditCompanyInfo', 'Name', 'Input')}
                        />
                    </Form.Item>
                    <FormSelect
                        fieldName={'companyTypeId'}
                        label={tF('Company Type')}
                        rules={validateAPIAndCompanyType}
                        useSelectHook={
                            isCompanyMNOHost || (isNew && isMainMaternalCompany)
                                ? companyTypeSelectHook
                                : useCatalogCompanyTypeFilterNotMNOSelect
                        }
                        disabled={isMyCompany || (!isNew && isCompanyMNOHost)}
                        dataTest={'EditCompanyInfoCompanyType'}
                    />
                    <FormSelect
                        fieldName={'parentId'}
                        label={tF('Parent')}
                        rules={validateAPIAndParent}
                        useSelectHook={selectHook}
                        disabled={isMyCompany}
                        dataTest={'EditCompanyInfoParent'}
                    />
                    <FormSelect
                        fieldName={'primaryContactId'}
                        label={tF('Primary contact')}
                        rules={apiMessagesGetFormRules}
                        useSelectHook={usersSelectHook}
                        dataTest={'EditCompanyInfoPrimaryContact'}
                    />
                    <FormSelect
                        fieldName={'technicalContactId'}
                        label={tF('Technical contact')}
                        rules={apiMessagesGetFormRules}
                        useSelectHook={usersSelectHook}
                        dataTest={'EditCompanyInfoTechnicalContact'}
                    />
                    <FormSelect
                        fieldName={'financialContactId'}
                        label={tF('Financial contact')}
                        rules={apiMessagesGetFormRules}
                        useSelectHook={usersSelectHook}
                        dataTest={'EditCompanyInfoParentFinancialContact'}
                    />

                    <Form.Item
                        name="notes"
                        label={notesTitle}
                        rules={apiMessagesGetFormRules}
                        data-test={'EditCompanyInfoNote'}
                    >
                        <TextAreaDebounce
                            placeholder={notesTitle}
                            autoSize={true}
                            data-test={'EditCompanyInfoNoteTextArea'}
                        />
                    </Form.Item>

                    <Form.Item
                        name="privacyCdr"
                        valuePropName="checked"
                        rules={apiMessagesGetFormRules}
                        data-test={'EditCompanyInfoPrivacyCdr'}
                    >
                        <Checkbox data-test={'EditCompanyInfoPrivacyCdrCheckBox'}>{tF('CDR Privacy')}</Checkbox>
                    </Form.Item>
                    {(!isMyCompany && whiteLabel) || (!isMyCompany && isLord) ? (
                        <Form.Item
                            name="whiteLabel"
                            valuePropName="checked"
                            rules={apiMessagesGetFormRules}
                            data-test={'EditCompanyInfoWhiteLabel'}
                        >
                            <Checkbox data-test={'EditCompanyInfoWhiteCheckBox'}>
                                {tF('White labeling for company')}
                            </Checkbox>
                        </Form.Item>
                    ) : null}
                    {isCheckCustomerPrivacy && !isNew ? (
                        <Form.Item
                            name="customerPrivacy"
                            valuePropName="checked"
                            rules={apiMessagesGetFormRules}
                            data-test={'EditCompanyInfoCustomerPrivacy'}
                        >
                            <Checkbox data-test={'EditCompanyInfoCustomerPrivacyCheckbox'}>
                                {tF('Customer Privacy')}
                            </Checkbox>
                        </Form.Item>
                    ) : null}
                    {isGeolocationManageable ? (
                        <>
                            <Form.Item
                                name="geolocationEnabled"
                                valuePropName="checked"
                                rules={apiMessagesGetFormRules}
                                data-test={'EditCompanyInfoGeolocationEnabled'}
                            >
                                <Checkbox data-test={'EditCompanyInfoGeolocationEnabledCheckBox'}>
                                    {tF('Activate Geolocation for all SIM cards in the account')}
                                </Checkbox>
                            </Form.Item>
                            <Form.Item
                                name="geotrackingEnabled"
                                valuePropName="checked"
                                rules={apiMessagesGetFormRules}
                                data-test={'EditCompanyInfoGeotrackingEnabled'}
                            >
                                <Checkbox data-test={'EditCompanyInfoGeotrackingEnabledCheckBox'}>
                                    {tF('Activate Geotracking for all SIM cards in the account')}
                                </Checkbox>
                            </Form.Item>
                        </>
                    ) : null}
                    <CardInfoToolBar dataTest={'EditCompanyInfo'} onSave={save} isLoading={isSending} isNew={isNew} />
                </CardMainLayout>
            </Catcher>
        );
    },
);
