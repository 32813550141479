import { UiStore } from '../stores/ui';
import { addNotification } from './ui';
import { apiManager } from '../REST';
import { IGetApiType } from '../REST';
import { IExportType, IFilterParams } from '../interfaces';
import { apiParamsCleaner } from '../instruments';

export async function makeReport(
    type: IGetApiType,
    filterParams: IFilterParams,
    exportType: IExportType,
    entityType?: string,
): Promise<void> {
    const UiStoreInstance = UiStore.getInstance();
    const params = UiStoreInstance.params;
    const cleanParams = apiParamsCleaner(params, filterParams);
    const { isError } = await apiManager.getApi(type, { searchParamsList: { ...cleanParams, exportType, entityType } });

    if (!isError) {
        addNotification({
            message: 'Added report request',
        });
    }
}
