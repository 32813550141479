import { tokenManager } from './tokenManager';
import { IAbortControllers, IGetRestApi, IHeaderType, IPostFileRestApi, IRequestDataFileBody } from './types';
import { objIsNotEmpty } from '../instruments';

const abortControllers: IAbortControllers = {};

export const restApi = {
    async getHeader(headerType: IHeaderType = 'access'): Promise<Headers> {
        const _headers: Headers = new Headers({ 'Content-Type': 'application/json' });
        switch (headerType) {
            case 'access':
                _headers.append('Authorization', await tokenManager.getToken());
                break;
            case 'refresh':
                _headers.append('Authorization', await tokenManager.getToken('refresh'));
                break;
            case 'uploadFile':
                _headers.append('Authorization', await tokenManager.getToken());
                break;
            case 'none':
            default:
                break;
        }
        return _headers;
    },
    async getPostFileHeader(headerType: IHeaderType = 'access'): Promise<Headers> {
        const _headers: Headers = new Headers();
        switch (headerType) {
            case 'access':
                _headers.append('Authorization', await tokenManager.getToken());
                break;
            case 'refresh':
                _headers.append('Authorization', await tokenManager.getToken('refresh'));
                break;
            case 'none':
            default:
                break;
        }
        return _headers;
    },
    async fetchAbort(apiType: string): Promise<void> {
        const findController = abortControllers[apiType];
        // ToDo: 04.05.2020 - I do not like this pattern. We have several requests for updating the token at the same time.
        if (findController && apiType !== 'REFRESH_TOKEN') {
            process.env.NODE_ENV === 'development' && console.warn(Date.now(), '-(Abort Start)->', apiType);
            await findController.abort();
            process.env.NODE_ENV === 'development' && console.warn(Date.now(), '-(Abort End)->', apiType);
        }
    },
    abortAll(): void {
        for (const key in abortControllers) {
            this.fetchAbort(key);
        }
    },

    async getRestApi({ url, method, headerType, body, apiType }: IGetRestApi): Promise<Response> {
        await this.fetchAbort(apiType);
        const abortController = new AbortController();
        abortControllers[apiType] = abortController;
        const signal = abortController.signal;
        const response = await fetch(url, {
            method,
            headers: await this.getHeader(headerType),
            signal: signal,
            body: JSON.stringify(body),
        });
        delete abortControllers[apiType];
        return response;
    },

    async postFileRestApi({ apiType, file, body, headerType, method, url }: IPostFileRestApi): Promise<Response> {
        await this.fetchAbort(apiType);
        const abortController = new AbortController();
        abortControllers[apiType] = abortController;
        const headers = await this.getPostFileHeader(headerType);
        const signal = abortController.signal;
        const formData = new FormData();
        formData.append('file', file ? file : '');
        if (objIsNotEmpty(body)) {
            const data = { ...body } as IRequestDataFileBody;
            for (const key in data) {
                formData.append(key, data[key]);
            }
        }

        const response = await fetch(url, {
            method,
            headers: headers,
            signal: signal,
            body: formData,
        });
        delete abortControllers[apiType];
        return response;
    },
};
