import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { CardLayout, Catcher, FormSelect, UploadFile, UploadFileShowName } from '../../';
import { IGetApiResponse } from '../../../REST';
import { IColumnOptionComponent, IItemAPI } from '../../../interfaces';
import { useCompanyInfo, useCompanyOperatorListId, useOperatorsSelect } from '../../../hooks';
import {
    addNotification,
    apiMessagesGetFormRules,
    setCompanySubscribersCsvBlobId,
    updateCompanyOperatorListStoreForm,
} from '../../../middleware';

export const EditCompanySubscriberVisibilityGranted: React.FC<IColumnOptionComponent> = observer(
    ({ columnOption }: IColumnOptionComponent) => {
        const [fileName, setFileName] = useState<string>('');
        const operatorsSelect = useOperatorsSelect;
        const operatorListCount = useCompanyOperatorListId().length;
        const { id } = useCompanyInfo();

        const onUpload = async (apiResponse: IGetApiResponse): Promise<void> => {
            const { isError, resp } = apiResponse;

            if (isError) {
                addNotification({
                    type: 'error',
                    message: 'Upload Error!',
                    isUsersError: true,
                });
            } else {
                const { id } = resp?.data as IItemAPI;
                if (id) {
                    setCompanySubscribersCsvBlobId(id);
                    addNotification({
                        type: 'success',
                        message: 'Upload successful!',
                    });
                } else {
                    addNotification({
                        type: 'error',
                        message: 'Upload Error!',
                        isUsersError: true,
                    });
                }
            }
        };

        const setFile = (file: File): void => {
            setFileName(file.name);
        };
        const cleanFile = (): void => {
            setCompanySubscribersCsvBlobId(undefined);
            setFileName('');
        };

        const UploadCSVFlow = (): JSX.Element => {
            return (
                <>
                    <UploadFile
                        apiType={'POST_BLOB'}
                        id={id}
                        onDownload={onUpload}
                        fileType={'text'}
                        setFile={setFile}
                    />
                    <UploadFileShowName name={fileName} handleDelete={cleanFile} />
                </>
            );
        };

        return (
            <Catcher>
                <CardLayout
                    columnOption={columnOption}
                    count={operatorListCount}
                    idSpinners={['GET_COMPANY']}
                    title={'Subscriber Visibility granted'}
                    OtherButton={<UploadCSVFlow />}
                >
                    <FormSelect
                        fieldName={'operatorList'}
                        isAddonBefore={true}
                        isNotClear={true}
                        label={'Operators'}
                        mode={'multiple'}
                        rules={apiMessagesGetFormRules}
                        useSelectHook={operatorsSelect}
                        onChange={updateCompanyOperatorListStoreForm}
                    />
                </CardLayout>
            </Catcher>
        );
    },
);
