import React from 'react';
import { Popover } from 'antd';
import { Catcher, FieldInfo } from '../../../';
import { FileStatus } from './FileStatus';
import cx from 'classnames';
import { IDivMainProps, IOperatorInteractionsResponseFile } from '../../../../interfaces';
import Styles from '../../../styles/m_itemCardStyles.less';

interface IFileInfo extends IDivMainProps {
    fileInfo?: IOperatorInteractionsResponseFile;
}

export const FileInfo: React.FC<IFileInfo> = ({ fileInfo, ...props }: IFileInfo) => {
    return (
        <Catcher>
            <div {...props}>
                <FieldInfo title={'Name'}>{fileInfo?.file}</FieldInfo>
                <FieldInfo title={'Gateway'}>{fileInfo?.gateway}</FieldInfo>
                <FieldInfo title={'Status'}>
                    <FileStatus status={fileInfo?.status} />
                </FieldInfo>
                <FieldInfo title={'Statistics'}>{fileInfo?.statistics}</FieldInfo>
                <FieldInfo title={'Created at'}>{fileInfo?.createdAt}</FieldInfo>
            </div>
        </Catcher>
    );
};

type IShowFileInfo = {
    fileInfo?: IOperatorInteractionsResponseFile;
};

export const ShowFileInfo: React.FC<IShowFileInfo> = ({ fileInfo }: IShowFileInfo) => {
    return fileInfo?.fileName ? (
        <Catcher>
            <Popover
                placement="top"
                title={fileInfo?.fileName}
                content={
                    <FileInfo
                        fileInfo={fileInfo}
                        className={cx(Styles.cardDevicePopoverWrapper, Styles.cardFileInfoPopoverWrapper)}
                    />
                }
                trigger="hover"
            >
                {fileInfo.fileName}
            </Popover>
        </Catcher>
    ) : null;
};
