import { PackagesStore } from '../stores/packages';
import { makeSelectList } from './catalogs';
import { mapApiToPackageInfo, mapApiToSoldPackageInfo } from './package';
import { IApiParams, IBodyListResponse, apiManager } from '../REST';
import { IPackagesForSale, IPackagesSaleGETAPI, IPackagesSold, IPackagesSoldGETAPI, IParamsList } from '../interfaces';

export function mapApiSalePackages(apiBody: IPackagesSaleGETAPI): IPackagesForSale {
    const { data, included } = apiBody;
    if (!data?.length) {
        return [];
    }

    return data?.map((data) => {
        return mapApiToPackageInfo({ data, included });
    });
}

export function mapApiSoldPackages(apiBody: IPackagesSoldGETAPI): IPackagesSold {
    const { data, included } = apiBody;
    if (!data?.length) {
        return [];
    }

    return data?.map((data) => {
        return mapApiToSoldPackageInfo({ data, included });
    });
}

export function setPackagesForSale(packages: IPackagesForSale): void {
    const PackagesInterface = PackagesStore.getInstance();
    PackagesInterface.setPackagesForSale(packages);
}

export function setPackagesForSaleTotal(total: number): void {
    const PackagesInterface = PackagesStore.getInstance();
    PackagesInterface.setForSaleTotal(total);
}

export async function getSalePackagesInfo(params?: IApiParams): Promise<boolean> {
    const { resp, isError } = await apiManager.getApi('GET_PACKAGES', { ...params });

    if (!isError && resp?.data && resp?.meta) {
        const { meta } = resp;
        setPackagesForSale(mapApiSalePackages(resp as IPackagesSaleGETAPI));
        setPackagesForSaleTotal(Number(meta?.records_count));
    }

    return isError;
}

export function setPackagesForcedUpdateTime(): void {
    const PackagesStoreInstance = PackagesStore.getInstance();
    PackagesStoreInstance.setForcedUpdateTime();
}

export async function deletePackage(id?: string, params?: IApiParams): Promise<void> {
    const { isError } = await apiManager.getApi('DELETE_PACKAGE', { id });

    if (!isError) {
        getSalePackagesInfo(params);
    }
}

export function setPackagesSold(packages: IPackagesSold): void {
    const PackagesInterface = PackagesStore.getInstance();
    PackagesInterface.setPackagesSold(packages);
}

export function setSoldTotal(total: number): void {
    const PackagesInterface = PackagesStore.getInstance();
    PackagesInterface.setSoldTotal(total);
}

export async function getSoldPackages(params?: IApiParams): Promise<boolean> {
    const { resp, isError } = await apiManager.getApi('GET_PACKAGES_SOLD', { ...params });

    if (!isError && resp?.data && resp?.meta) {
        const { meta } = resp;

        setPackagesSold(mapApiSoldPackages(resp as IPackagesSoldGETAPI));
        setSoldTotal(Number(meta?.records_count));
    }

    return isError;
}

export async function getPackagesToBuy(params?: IApiParams): Promise<boolean> {
    const { resp, isError } = await apiManager.getApi('GET_PACKAGES_TO_BUY', { ...params });

    if (!isError && resp?.data && resp?.meta) {
        const { meta } = resp;
        setPackagesForSale(mapApiSalePackages(resp as IPackagesSaleGETAPI));
        setPackagesForSaleTotal(Number(meta?.records_count));
    }

    return isError;
}

export function setPackageSelect(apiList: IBodyListResponse): void {
    const PackagesInterface = PackagesStore.getInstance();
    PackagesInterface.setPackagesSelect(makeSelectList(apiList));
}

export async function getPackagesSelect(params?: IParamsList): Promise<void> {
    const { resp, isError } = await apiManager.getApi('GET_PACKAGES_TO_BUY', { searchParamsList: params });

    if (!isError && resp?.data) {
        setPackageSelect(resp as IBodyListResponse);
    }
}

export async function getBoughtPackages(params?: IApiParams): Promise<boolean> {
    const { resp, isError } = await apiManager.getApi('GET_BOUGHT_PACKAGES', { ...params });

    if (!isError && resp?.data && resp?.meta) {
        const { meta } = resp;

        setPackagesSold(mapApiSoldPackages(resp as IPackagesSoldGETAPI));
        setSoldTotal(Number(meta?.records_count));
    }

    return isError;
}

export async function deletePackageInstance(id?: string, params?: IApiParams): Promise<void> {
    const { isError } = await apiManager.getApi('DELETE_PACKAGE_INSTANCE', { id });

    if (!isError) {
        getBoughtPackages(params);
    }
}

export function cleanPackagesStore(): void {
    const PackagesInterface = PackagesStore.getInstance();
    PackagesInterface.cleanStore();
}
