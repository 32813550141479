import React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import { Catcher, LinkComponent, TableItemToolbar, columnToolbar } from '../../';
import { INameObject, IRateListItem } from '../../../interfaces';
import { useRatesList, useUiIsLoading } from '../../../hooks';
import { deleteRateList } from '../../../middleware';
import { tF, tI } from '../../../translate';
import '../../../customStyle/table.less';
import Styles from '../../styles/m_viewStyles.less';

export const SalesRatesListTable: React.FC = observer(() => {
    const isLoading = useUiIsLoading();
    const list = useRatesList();

    const tableColumns = [
        {
            title: tF('Name'),
            dataIndex: 'nameLink',
        },
        {
            title: tF('Company'),
            dataIndex: 'companyLink',
            width: 250,
        },
        {
            title: tF('Active'),
            dataIndex: 'assignedCompaniesListLinks',
        },
        columnToolbar,
    ];
    const tableData = list.map((r: IRateListItem) => {
        const key = r.id;
        const deleteItem = (): void => {
            deleteRateList(r.id);
        };
        const AssignedCompaniesListLinks = r.assignedCompaniesList?.map((ac: INameObject, i: number) => (
            <LinkComponent
                currentListComponent={'CompaniesList'}
                id={ac.id}
                isLight
                name={ac.name}
                key={`${ac.id}-${i}`}
            />
        ));
        const name = r.name ? r.name : `# ${r.id}`;

        return {
            key,
            nameLink: <LinkComponent currentListComponent={'SalesRateLists'} id={r.id} isLight name={r.name} />,
            companyLink: (
                <LinkComponent currentListComponent={'CompaniesList'} id={r.companyId} isLight name={r.company} />
            ),
            assignedCompaniesListLinks: AssignedCompaniesListLinks,
            toolbar: (
                <TableItemToolbar deleteItem={deleteItem} id={r.id} itemName={`"${name}" ${tI('sales rate list')}`} />
            ),
        };
    });

    return (
        <Catcher>
            <div className={Styles.tablePlace}>
                <Table
                    dataSource={tableData}
                    columns={tableColumns}
                    pagination={false}
                    className={'table-theme'}
                    size={'middle'}
                    loading={isLoading}
                />
            </div>
        </Catcher>
    );
});
