import React, { useEffect } from 'react';
import { Row } from 'antd';
import { Catcher } from '../';
import { useRouterStore } from '../../hooks';
import { RootInfo } from './Components/';
import Styles from '../styles/m_itemCardStyles.less';

export const RootPage: React.FC = () => {
    const routerStore = useRouterStore();

    useEffect(() => {
        const { routeName } = routerStore.routerState;
        if (routeName !== 'RootPage') {
            routerStore.goTo('RootPage');
        }
    }, []);

    return (
        <Catcher>
            <article className={Styles.main}>
                <Row>
                    <RootInfo />
                </Row>
            </article>
        </Catcher>
    );
};
