import React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import { TablePaginationConfig } from 'antd/es/table';
import { PhoneOutlined } from '@ant-design/icons';
import { CardLayout, Catcher, LinkComponent, SkyPaginationTotal, cardLayoutOptions } from '../../';
import { appConfig } from '../../../settings';
import { ICountryOperatorItem } from '../../../interfaces';
import { useCountryInfo, useCountryOperatorList } from '../../../hooks';
import { tF, tI } from '../../../translate';

export const ShowCountryOperatorsTable: React.FC = observer(() => {
    const pageSize = appConfig.pageDefaultParams.includeViewPageSize;
    const operatorList = useCountryOperatorList();
    const { name } = useCountryInfo();

    const tableColumns = [
        {
            title: tF('Name'),
            dataIndex: 'nameLink',
        },
        {
            title: tF('Tadig'),
            dataIndex: 'tadig',
        },
        {
            title: tF('MNC'),
            dataIndex: 'mnc',
        },
    ];
    const tableData = operatorList.map((o: ICountryOperatorItem) => {
        return {
            key: o.id,
            ...o,
            nameLink: <LinkComponent currentListComponent={'OperatorsList'} id={o.id} name={o.name} />,
        };
    });

    const pagination: false | TablePaginationConfig =
        pageSize > operatorList.length
            ? false
            : {
                  total: operatorList.length,
                  pageSize: pageSize,
                  className: 'customPagination',
                  showSizeChanger: false,
                  size: 'default',
                  showTotal: SkyPaginationTotal,
              };

    return (
        <Catcher>
            <CardLayout
                columnOption={cardLayoutOptions.cardTableOperators}
                icon={<PhoneOutlined />}
                idSpinners={['GET_COUNTRY']}
                isTable
                title={'Operators'}
                titleRight={`${tI('in')} ${name ? name : ''} (${operatorList.length})`}
            >
                <Table
                    className={'table-theme'}
                    columns={tableColumns}
                    dataSource={tableData}
                    pagination={pagination}
                    size={'small'}
                />
            </CardLayout>
        </Catcher>
    );
});
