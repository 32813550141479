import React from 'react';
import { observer } from 'mobx-react';
import { Divider } from 'antd';
import {
    AimOutlined,
    BankOutlined,
    CheckCircleOutlined,
    ClockCircleOutlined,
    CreditCardOutlined,
    DeleteOutlined,
    DollarCircleOutlined,
    EuroOutlined,
    ExclamationCircleOutlined,
    FieldTimeOutlined,
    FileTextOutlined,
    InfoCircleOutlined,
    InteractionOutlined,
    SafetyOutlined,
    SendOutlined,
    SettingOutlined,
    UserOutlined,
    WarningOutlined,
} from '@ant-design/icons';
import cx from 'classnames';
import { IShowForm, OrderBy } from '../../../interfaces';
import {
    useCompanyInfo,
    useCustomerPrivacyCheck,
    useIsMyCompany,
    useProfile,
    useProfileIsRight,
    useRouterStore,
    useUiIsLoading,
} from '../../../hooks';
import { dateText2Utc } from '../../../instruments';
import { formatDataTestAttribute } from '../../../instruments/testing';
import { ITrInterfaces, tB, tF, tI, tP } from '../../../translate';
import {
    CardInfoToolBar,
    CardMainLayout,
    Catcher,
    FieldInfoAdvanced,
    GoPageButton,
    LinkComponent,
    cardLayoutOptions,
} from '../../../Components';
import Styles from '../../styles/m_itemCardStyles.less';

export const ShowCompanyInfo: React.FC<IShowForm> = observer(({ setEditMode }: IShowForm) => {
    const isLoading = useUiIsLoading();
    const isLord = useProfileIsRight('LORD');
    const isAdmin = useProfileIsRight('ADMIN');
    const { companyId } = useProfile();
    const routerStore = useRouterStore();
    const {
        id: currentCompanyId,
        balance,
        cdrSquashIntervalValue,
        cdrSquashIntervalName,
        companyType,
        currency,
        customerPrivacy = true,
        deletedAt,
        geolocationEnabled,
        geotrackingEnabled,
        name,
        notes,
        parent,
        parentId,
        paymentTypeName,
        primaryContact,
        primaryContactId,
        privacyCdr,
        sessionGrantedTypeId,
        sessionGrantedUnit,
        sessionValidityTime,
        spendingLimit,
        thresholdHigh,
        thresholdMedium,
        zeroCostData,
        zeroCostSms,
        zeroCostVoice,
    } = useCompanyInfo();
    const isCustomerPrivacyCheck = useCustomerPrivacyCheck(customerPrivacy);
    const isMyCompany = useIsMyCompany();

    const layout = {
        labelCol: {
            flex: '0 1 190px',
        },
        rowGutter: 8,
    };
    const onCancel = (): void => {
        routerStore.goTo('Dashboard');
    };

    return (
        <Catcher>
            <CardMainLayout
                columnOption={cardLayoutOptions.cardCompanyShowInfo}
                idSpinners={['GET_COMPANY']}
                name={companyId === currentCompanyId ? tP('My Company') : name}
                dataTest={'ShowCompany'}
            >
                <FieldInfoAdvanced
                    icon={<BankOutlined className={Styles.labelIcon} />}
                    layout={layout}
                    title={'Name'}
                    dataTest={'ShowCompanyName'}
                >
                    {name}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced
                    icon={<BankOutlined className={Styles.labelIcon} />}
                    layout={layout}
                    title={'Parent Company'}
                >
                    <LinkComponent currentListComponent={'CompaniesList'} id={parentId} name={parent} />
                </FieldInfoAdvanced>
                <FieldInfoAdvanced
                    icon={<SettingOutlined className={Styles.labelIcon} />}
                    layout={layout}
                    title={'Type'}
                >
                    {companyType}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced
                    icon={<UserOutlined className={Styles.labelIcon} />}
                    layout={layout}
                    title={'Primary contact'}
                >
                    {isCustomerPrivacyCheck ? (
                        '*****'
                    ) : (
                        <LinkComponent currentListComponent={'UsersList'} id={primaryContactId} name={primaryContact} />
                    )}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced
                    icon={<DollarCircleOutlined className={Styles.labelIcon} />}
                    layout={layout}
                    title={'Currency'}
                >
                    {currency}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced
                    icon={<EuroOutlined className={Styles.labelIcon} />}
                    layout={layout}
                    title={'Balance'}
                >
                    {balance}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced
                    icon={<CreditCardOutlined className={Styles.labelIcon} />}
                    layout={layout}
                    title={'Payment type'}
                >
                    {paymentTypeName}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced
                    isIconBlank
                    layout={layout}
                    title={'Balance limit'}
                    titleSuffix={<InfoCircleOutlined />}
                >
                    {spendingLimit}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced layout={layout} title={'1st balance warning'} titleSuffix={<InfoCircleOutlined />}>
                    {thresholdMedium}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced layout={layout} title={'2nd balance warning'} titleSuffix={<InfoCircleOutlined />}>
                    {thresholdHigh}
                </FieldInfoAdvanced>
                {isLord ? (
                    <>
                        <Divider />
                        <FieldInfoAdvanced
                            icon={<ClockCircleOutlined className={Styles.labelIcon} />}
                            layout={layout}
                            title={'CDR interval'}
                        >
                            {cdrSquashIntervalValue}{' '}
                            {cdrSquashIntervalName ? tI(cdrSquashIntervalName as ITrInterfaces) : null}
                        </FieldInfoAdvanced>
                        <Divider />
                        <FieldInfoAdvanced
                            icon={<ExclamationCircleOutlined className={Styles.labelIcon} />}
                            layout={layout}
                            title={'Zero cost Data'}
                            dataTest={'ShowCompanyZeroCostDataValue'}
                        >
                            {zeroCostData ? tF('Enabled') : tF('Disabled')}
                        </FieldInfoAdvanced>
                        <FieldInfoAdvanced
                            icon={<ExclamationCircleOutlined className={Styles.labelIcon} />}
                            layout={layout}
                            title={'Zero cost SMS'}
                            dataTest={'ShowCompanyZeroCostSmsValue'}
                        >
                            {zeroCostSms ? tF('Enabled') : tF('Disabled')}
                        </FieldInfoAdvanced>
                        <FieldInfoAdvanced
                            icon={<ExclamationCircleOutlined className={Styles.labelIcon} />}
                            layout={layout}
                            title={'Zero cost Voice'}
                            dataTest={'ShowCompanyZeroCostVoice'}
                        >
                            {zeroCostVoice ? tF('Enabled') : tF('Disabled')}
                        </FieldInfoAdvanced>
                        <Divider />
                    </>
                ) : null}
                {isAdmin ? (
                    <FieldInfoAdvanced
                        icon={<SafetyOutlined className={Styles.labelIcon} />}
                        layout={layout}
                        title={'CDR Privacy'}
                    >
                        {privacyCdr ? (
                            <CheckCircleOutlined
                                data-test={formatDataTestAttribute('ShowCompany', 'CheckCircleOutlined', 'Icon')}
                                title={tB('Enable')}
                            />
                        ) : (
                            <WarningOutlined
                                data-test={formatDataTestAttribute('ShowCompany', 'WarningOutlined', 'Icon')}
                                title={tB('Disable')}
                            />
                        )}
                    </FieldInfoAdvanced>
                ) : null}
                <FieldInfoAdvanced
                    icon={<FieldTimeOutlined className={Styles.labelIcon} />}
                    layout={layout}
                    title={'Session Validity Time'}
                >
                    {sessionValidityTime}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced
                    icon={<InteractionOutlined className={Styles.labelIcon} />}
                    layout={layout}
                    title={'Session Granted Unit'}
                >
                    {sessionGrantedUnit}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced
                    icon={<SendOutlined className={Styles.labelIcon} />}
                    layout={layout}
                    title={'Session Granted Type'}
                    dataTest={'ShowCompanySessionGrantedType'}
                >
                    {sessionGrantedTypeId //:TODO investigate session granted type
                        ? sessionGrantedTypeId === '1'
                            ? currency
                            : sessionGrantedTypeId === '2'
                            ? 'MB'
                            : '???'
                        : null}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced
                    icon={<AimOutlined className={Styles.labelIcon} />}
                    layout={layout}
                    title={'Geolocation'}
                    dataTest={'ShowCompanyGeolocation'}
                >
                    {geolocationEnabled ? tF('Enabled') : tF('Disabled')}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced
                    icon={<AimOutlined className={Styles.labelIcon} />}
                    layout={layout}
                    title={'Geotracking'}
                    dataTest={'ShowCompanyGeotracking'}
                >
                    {geotrackingEnabled ? tF('Enabled') : tF('Disabled')}
                </FieldInfoAdvanced>
                {isAdmin ? (
                    <>
                        <FieldInfoAdvanced
                            icon={<FileTextOutlined className={Styles.labelIcon} />}
                            layout={layout}
                            title={'Notes'}
                        >
                            {notes}
                        </FieldInfoAdvanced>
                    </>
                ) : null}
                {isAdmin && deletedAt ? (
                    <>
                        <FieldInfoAdvanced
                            icon={<DeleteOutlined className={cx(Styles.labelIcon, Styles.redIcon)} />}
                            layout={layout}
                            title={'Is deleted'}
                            dataTest={'ShowCompanyIsDeleted'}
                        >
                            {dateText2Utc(deletedAt)}
                        </FieldInfoAdvanced>
                    </>
                ) : null}
                <CardInfoToolBar
                    setEditMode={!deletedAt ? setEditMode : undefined}
                    onCancel={onCancel}
                    isBackToList={!isMyCompany}
                >
                    {isAdmin && !deletedAt ? (
                        <GoPageButton
                            className={Styles.mainButton}
                            componentName={'OrderNew'}
                            disabled={isLoading}
                            goToOptions={{ params: { id: currentCompanyId, by: OrderBy.COMPANY } }}
                            href={`/company/${currentCompanyId}/order/${OrderBy.COMPANY}`}
                            title={!isMyCompany ? 'Give Credits' : 'Top Up'}
                        />
                    ) : null}
                </CardInfoToolBar>
            </CardMainLayout>
        </Catcher>
    );
});
