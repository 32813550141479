import React from 'react';
import { observer } from 'mobx-react';
import { Col, Tooltip } from 'antd';
import { columnOption } from '../columnOption';
import cx from 'classnames';
import {
    useDashboardFinancesIsNotBigQueryUsage,
    useDashboardFinancesRollVolumes,
    useProfileFeatureFlagsIsBigQuery,
    useUiCurrentUnitType,
} from '../../../hooks';
import { showOriginalUnitByType, stringCount } from '../../../instruments';
import { ITrField, tF } from '../../../translate';
import { Catcher, LoadingApiByIds } from '../../../Components';
import Styles from '../m_dashboarStyles.less';

interface IInfoCard {
    children?: never;
}

export const FinancesInfoCard: React.FC<IInfoCard> = observer(() => {
    const isBigQuery = useProfileFeatureFlagsIsBigQuery();

    const dataIsNotBigQuery = useDashboardFinancesIsNotBigQueryUsage();
    const originalUnitsIsBigQuery = useDashboardFinancesRollVolumes();
    const currentUnitType = useUiCurrentUnitType();
    const UnitTypeName = currentUnitType?.name ? tF(currentUnitType.name as ITrField) : '';
    const title = `${tF('Usage')} ${UnitTypeName}`;
    const originalUnits = isBigQuery ? originalUnitsIsBigQuery : dataIsNotBigQuery.originalUnits;

    const subValue =
        currentUnitType?.id === '2'
            ? `${dataIsNotBigQuery.unassignedUnits} ${UnitTypeName}`
            : showOriginalUnitByType(dataIsNotBigQuery.unassignedUnits, currentUnitType, 2);
    const subtitle =
        dataIsNotBigQuery.unassignedUnits === undefined ? '' : ` (${subValue} ${tF('of unassigned')} ${UnitTypeName})`;

    const value =
        currentUnitType?.id === '2'
            ? `${originalUnits} ${UnitTypeName}`
            : showOriginalUnitByType(originalUnits, currentUnitType, 2);

    return (
        <Catcher>
            <Col className={Styles.cardPlace} {...columnOption.financesInfoCard}>
                <div className={cx(Styles.infoCard, Styles.financesCard)}>
                    <LoadingApiByIds idSpinners={['GET_FINANCE_ROLL']} />
                    <div className={Styles.info}>
                        <div className={Styles.title}>
                            {title}
                            {!isBigQuery ? (
                                <Tooltip
                                    title={`${stringCount(dataIsNotBigQuery.unassignedUnits)} ${
                                        currentUnitType?.value
                                    }`}
                                    placement="topLeft"
                                >
                                    <span>{subtitle}</span>
                                </Tooltip>
                            ) : null}
                        </div>
                        <div className={cx(Styles.value, Styles.financesInfoCardValue)}>
                            <Tooltip title={`${stringCount(originalUnits)} ${currentUnitType?.value}`}>
                                <span>{value}</span>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </Col>
        </Catcher>
    );
});
