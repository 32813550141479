import React from 'react';
import { Col } from 'antd';
import { Catcher, LoadingApi } from '../../';
import { EditRateOperatorsList, EditSalesRateListTitle } from './';
import cx from 'classnames';
import Styles from '../../styles/m_itemCardStyles.less';

export const EditSalesRateList: React.FC = () => {
    return (
        <Catcher>
            <Col xs={24}>
                <article className={cx(Styles.cardInfo, Styles.itemBlock)}>
                    <LoadingApi />
                    <EditSalesRateListTitle />
                    <EditRateOperatorsList />
                </article>
            </Col>
        </Catcher>
    );
};
