import { appConfig } from '../settings';

interface IUseHexIdProps {
    init?: number;
    len?: number;
    prefix?: string;
}

export class UseHexId {
    private id: number;
    private readonly len: number;
    private readonly prefix: string;
    constructor({ init = 0, len = 8, prefix = appConfig.newIdPrefix }: IUseHexIdProps) {
        this.id = init;
        this.len = len;
        this.prefix = prefix;
    }

    public getId = (): string => {
        this.id++;
        return this.prefix + this.id.toString(16).padStart(this.len, '0');
    };
}

export const globalHexId = new UseHexId({});
export const isNewId = (id: string): boolean => id.startsWith(appConfig.newIdPrefix);
