import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { IFilterParams, IOrdersListItem } from '../../interfaces';

export function useOrdersTable(): IOrdersListItem[] {
    return useContext(MobXProviderContext).RootStore.ordersStore.list;
}

export function useOrdersFilterParams(): IFilterParams {
    return useContext(MobXProviderContext).RootStore.ordersStore.filterParams;
}
