import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Divider } from 'antd';
import { Catcher, FilterUrlManager } from '../';
import { usePackagesForSaleTotal, usePackagesSoldTotal } from '../../hooks';
import {
    cleanPackageRelationSelect,
    cleanPackagesStore,
    getSalePackagesInfo,
    getSoldPackages,
    setPackagesForcedUpdateTime,
} from '../../middleware';
import { PackageTableComponent, TableForSales, TableForSoldPackages } from './Components';
import Styles from '../styles/m_viewStyles.less';

export const PackagesSell: React.FC = observer(() => {
    useEffect(() => {
        return (): void => {
            cleanPackagesStore();
        };
    }, []);

    return (
        <Catcher>
            <FilterUrlManager
                cleanStore={cleanPackageRelationSelect}
                setForcedUpdateTime={setPackagesForcedUpdateTime}
            />
            <div className={Styles.main}>
                <div className={Styles.content}>
                    <PackageTableComponent
                        Table={TableForSales}
                        useTotalHook={usePackagesForSaleTotal}
                        fetchRequest={getSalePackagesInfo}
                        title={'Packages for Sale'}
                        newComponent={'PackageNew'}
                    />
                    <Divider />
                    <PackageTableComponent
                        Table={TableForSoldPackages}
                        useTotalHook={usePackagesSoldTotal}
                        fetchRequest={getSoldPackages}
                        title={'Sold Packages'}
                    />
                </div>
            </div>
        </Catcher>
    );
});
