import React, { ReactText, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Table, Tooltip } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import {
    CardLayout,
    Catcher,
    ChangeStatusToolBar,
    LinkComponent,
    TableItemToolbar,
    cardLayoutOptions,
    columnToolbar,
    notSelectItem,
} from '../../';
import { ShowSubscriberStatus } from './ShowSubscriberStatus';
import { appConfig, isCompanyTypeIdIsRight } from '../../../settings';
import {
    ICardSubscriber,
    IChangeStatusCommands,
    IColumnOption,
    IGetApiType,
    IRowSelectionCheckboxProps,
} from '../../../interfaces';
import { useCardSubscribers, useProfile, useProfileIsRight } from '../../../hooks';
import {
    getCardSubscribersStatusBackground,
    isCardSubscribersDispatchLock,
    setCardSubscribersChangeStatus,
} from '../../../middleware';
import { tF, tI } from '../../../translate';
import '../../../customStyle/table.less';
import ViewStyles from '../../styles/m_viewStyles.less';

type ISubscribers = {
    columnOption?: IColumnOption;
};

export const Subscribers: React.FC<ISubscribers> = observer(
    ({ columnOption = cardLayoutOptions.cardTableSubscribers }: ISubscribers) => {
        const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
        const subscribers = useCardSubscribers();
        const { subtreeIds } = useProfile();
        const isCompanyRight = isCompanyTypeIdIsRight('1');
        const isLord = useProfileIsRight('LORD');
        const isRoot = useProfileIsRight('ROOT');
        const { isCompanyTypeIdMNOHost } = useProfile();
        const isCustomerCare = useProfileIsRight('CUSTOMER_CARE');
        const componentSpinners: IGetApiType[] = ['GET_CARD'];

        const checkLock = (id?: number): boolean => {
            return !!id && !!subtreeIds && !subtreeIds.includes(id);
        };

        const onSelectChange = (selectItems: ReactText[]): void => {
            const dispatchLockSubscribers = subscribers
                .filter((s: ICardSubscriber) => s.id && s.dispatchLock)
                .map((s: ICardSubscriber) => s.id);
            const select = selectItems.map((i) => i.toString()).filter((i) => !dispatchLockSubscribers.includes(i));
            setSelectedRowKeys(select);
        };

        const rowSelection = {
            selectedRowKeys,
            onChange: onSelectChange,
            getCheckboxProps: (record: ICardSubscriber): IRowSelectionCheckboxProps => ({
                disabled: checkLock(record.suspenderCompanyId) && !isLord,
            }),
        };

        const changeStatus = (command: IChangeStatusCommands): void => {
            setCardSubscribersChangeStatus({ command, idList: selectedRowKeys }).then();
            setSelectedRowKeys([]);
        };

        useEffect(() => {
            const interval = setInterval(() => {
                if (isCardSubscribersDispatchLock()) {
                    getCardSubscribersStatusBackground(true).then();
                }
            }, appConfig.updateViewInterval);
            return (): void => clearInterval(interval);
        }, []);

        useEffect(() => {
            if (subscribers.length) {
                const allSubscribers = subscribers.map((s) => s.id);
                setCardSubscribersChangeStatus({ command: 'refresh', idList: allSubscribers }).then();
            }
        }, [subscribers.length]);

        const tableColumns = [
            isLord
                ? {}
                : {
                      dataIndex: 'showLocked',
                      className: ViewStyles.columnCenter,
                  },
            {
                title: tF('Status'),
                dataIndex: 'showStatus',
                className: ViewStyles.columnCenter,
            },
            {
                title: tF('Operator'),
                dataIndex: 'operatorLink',
            },
            {
                title: tF('IMSI'),
                dataIndex: 'imsiLink',
            },
            {
                title: tF('Roaming'),
                dataIndex: 'roamingImsi',
            },
            {
                title: tF('ICCID'),
                dataIndex: 'iccId',
            },
            {
                title: tF('MSISDN'),
                dataIndex: 'msisdn',
            },
            {
                title: tF('Zone'),
                dataIndex: 'zoneLink',
            },
            isLord ? columnToolbar : {},
        ];

        const tableData = subscribers.map((s: ICardSubscriber) => {
            const isLock = checkLock(s.suspenderCompanyId);

            return {
                key: s.id,
                showStatus: (
                    <ShowSubscriberStatus
                        dispatchLock={s.dispatchLock}
                        subStatus={s.statusNew}
                        transactionError={isRoot ? s.transactionError : undefined}
                    />
                ),
                showLocked: isLock ? (
                    <Tooltip title={tI('The update is locked')} placement="top">
                        <LockOutlined className={ViewStyles.redIcon} />
                    </Tooltip>
                ) : null,
                operatorLink: (
                    <LinkComponent currentListComponent={'OperatorsList'} id={s.operatorId} name={s.operator} />
                ),
                imsiLink: <LinkComponent currentListComponent={'SubscribersList'} id={s.id} name={s.imsi} />,
                zoneLink: <LinkComponent currentListComponent={'ZonesList'} id={s.zoneId} name={s.zone} />,
                toolbar: (
                    <TableItemToolbar
                        id={s.id}
                        itemName={`"${s.imsi}" ${tI('subscriber')}`}
                        currentListComponent={'SubscribersList'}
                    />
                ),
                ...s,
            };
        });

        return (
            <Catcher>
                <CardLayout
                    columnOption={columnOption}
                    count={subscribers.length}
                    idSpinners={componentSpinners}
                    isTable
                    title={'Subscriber identities'}
                    titleToolBar={
                        !isCompanyTypeIdMNOHost && isCustomerCare ? (
                            <ChangeStatusToolBar
                                changeStatus={changeStatus}
                                disabled={!selectedRowKeys.length}
                                disabledHint={notSelectItem}
                                statuses={
                                    ['refresh', isCompanyRight && isLord && 'activate', 'block', 'unblock'].filter(
                                        Boolean,
                                    ) as IChangeStatusCommands[]
                                }
                            />
                        ) : null
                    }
                >
                    <Table
                        className={'table-theme'}
                        columns={tableColumns}
                        dataSource={tableData}
                        pagination={false}
                        rowSelection={!isCompanyTypeIdMNOHost && isCustomerCare ? rowSelection : undefined}
                        size={'middle'}
                    />
                </CardLayout>
            </Catcher>
        );
    },
);
