import { appSignalSendMessage, getCurrencyCodeById, moneyRange } from './index';
import { mapApi2UiField } from './mapField';
import { appConfig } from '../settings';
import {
    IChartData,
    IChartItemData,
    IChartTitle,
    ICost,
    ICostRevenueItem,
    IRankItemAPI,
    IRankItemStatsAPI,
    IRevenueItemANY,
    IStatItem,
} from '../interfaces';
import { ITrField, ITrPage, tF, tI, tP } from '../translate';

export const sortRankStatsByCurrencyId = (a: IStatItem, b: IStatItem): number => {
    return (a.currencyId ? a.currencyId : 0) - (b.currencyId ? b.currencyId : 0);
};

export const getCrossCourseValue = (currency_id?: number, value?: number): number => {
    if (!value || !currency_id) {
        return 0;
    }
    const currency = appConfig.currency.find((i) => +i.id === currency_id);
    if (currency?.crossCourse) {
        return value * currency.crossCourse;
    } else {
        const message = `Not found currency with id: ${currency_id}`;
        appSignalSendMessage({
            action: 'Cross Course',
            error: new Error(message),
            message: message,
            description: `Need ask FrontEnd team to add currency with id: ${currency_id} to AppConfig`,
        });
        return value;
    }
};

export function getChartTitleByUnitType(type?: string): string {
    switch (type) {
        case 'Data':
            return tI('by Data Usage');
        case 'SMS':
            return tI('by Sms Usage');
        case 'Voice':
            return tI('by Voice Usage');
        default:
            return tI('by Data Usage');
    }
}

export const getUsageChartTitle = (itemName: ITrPage): IChartTitle => {
    return [tP(itemName), tI('Usage'), { role: 'annotation' }, { role: 'annotationText' }];
};

export function getTextCost({ cost = '0', currencyCode = '', isRange = false }: ICost): string {
    const value = Number(cost);
    const findAbbreviation = appConfig.currency.find((c) => c.currencyCode === currencyCode);
    const textValue = isRange && value > 9999.99 ? moneyRange(value) : value.toFixed(2);
    return `${findAbbreviation ? findAbbreviation.abbreviation : ''}${textValue}`;
}

export const totalCostRankStats = (item: IRankItemAPI): number => {
    return (
        item.stats
            ?.filter((i: IRankItemStatsAPI) => i.currency_id && i.cost)
            .map((i: IRankItemStatsAPI) => getCrossCourseValue(i.currency_id ? i.currency_id : 0, i.cost ? +i.cost : 0))
            .reduce((a: number, c: number) => a + c) || 0
    );
};

export const sortByRankCosts = (a: IRankItemAPI, b: IRankItemAPI): number => {
    return totalCostRankStats(b) - totalCostRankStats(a);
};

export const mapRank2RevenueItem = (ranks: Array<IRankItemAPI>): Array<ICostRevenueItem> => {
    return ((ranks as Array<IRankItemAPI>) || [])
        .sort(sortByRankCosts)
        .filter((_, index: number) => index < appConfig.top5)
        .map((i: IRankItemAPI) => {
            const stats =
                i.stats?.map((s: IRankItemStatsAPI) => mapApi2UiField(s)).sort(sortRankStatsByCurrencyId) || [];
            return {
                name: i.name ? i.name : '',
                ...mapApi2UiField(i || {}),
                stats,
            };
        });
};

export const getRevenueChartTitleROW = (
    title: ITrPage,
    currencies: Array<IStatItem>,
    isHideCost?: boolean,
): IChartTitle => {
    const titleROW: IChartTitle = [tP(title), { role: 'annotationText' }];
    currencies.forEach((c: IStatItem) => {
        if (!isHideCost) {
            const chartCostTitle = appConfig.chartCostTitle as ITrField;
            titleROW.push(`${tF(chartCostTitle)} ${c.currencyAbbreviation}`);
            titleROW.push({ role: 'annotation' });
        }
        titleROW.push(`${tF('Revenue')} ${c.currencyAbbreviation}`);
        titleROW.push({ role: 'annotation' });
    });

    return titleROW;
};

export const getCurrencyListFromRankStats = (list: Array<ICostRevenueItem>): Array<IStatItem> => {
    const currencies: Array<IStatItem> = [];
    list.forEach((c: ICostRevenueItem) => {
        c.stats?.forEach((s: IStatItem) => {
            const foundCurrency = currencies.find((i: IStatItem) => i.currencyId === s.currencyId);
            if (!foundCurrency) {
                currencies.push({
                    currencyId: s.currencyId,
                    currencyAbbreviation: s.currencyAbbreviation || getCurrencyCodeById(s.currencyId),
                    cost: '0',
                    revenue: '0',
                });
            }
        });
    });
    return currencies.sort(sortRankStatsByCurrencyId);
};

export type IMakeRowTitle = (i: IRevenueItemANY) => [string, string | number];

type ImapRevenues2Chart = {
    revenues: Array<ICostRevenueItem>;
    title: ITrPage;
    isHideCost?: boolean;
    makeRowTitle: IMakeRowTitle;
};

export const mapRevenues2Chart = ({ revenues, title, isHideCost, makeRowTitle }: ImapRevenues2Chart): IChartData => {
    const currencies = getCurrencyListFromRankStats(revenues);
    const titleROW = getRevenueChartTitleROW(title, currencies, isHideCost);
    const cardsRevenueData: Array<IChartItemData> = revenues.map((i: IRevenueItemANY) => {
        const chartItem: IChartItemData = makeRowTitle(i);

        const calcCurrencies: Array<IStatItem> = currencies.map((c: IStatItem) => {
            const findCurrency = i.stats?.find((s: IStatItem) => s.currencyId === c.currencyId);
            if (findCurrency) {
                return {
                    ...c,
                    cost: findCurrency.cost ? findCurrency.cost : '0',
                    revenue: findCurrency.revenue ? findCurrency.revenue : '0',
                };
            } else {
                return c;
            }
        });
        calcCurrencies.forEach((c: IStatItem) => {
            if (!isHideCost) {
                chartItem.push(
                    c.cost ? +c.cost : 0,
                    c.cost && +c.cost
                        ? getTextCost({ cost: c.cost, currencyCode: c.currencyAbbreviation, isRange: true })
                        : '0',
                );
            }
            chartItem.push(
                c.revenue ? +c.revenue : 0,
                c.revenue && +c.revenue
                    ? getTextCost({ cost: c.revenue, currencyCode: c.currencyAbbreviation, isRange: true })
                    : '0',
            );
        });
        return chartItem;
    });
    return [titleROW, ...cardsRevenueData];
};

export const sourceDataRemoveCosts = (sourceData: Array<any>): Array<any> => {
    const costIndex = sourceData[0]?.indexOf('Cost');
    if (costIndex === -1) {
        return sourceData;
    } else {
        const isFoundDescription = !!sourceData[0][costIndex + 1].role;
        return sourceData.map((i: Array<any>) => {
            i.splice(costIndex, isFoundDescription ? 2 : 1);
            return i;
        });
    }
};
