import React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import { Catcher, LinkComponent, TableItemToolbar, columnToolbar } from '../../';
import { ICallDataRecordsListItem, ITableInclude } from '../../../interfaces';
import { useCallDataRecordsListBeta, usePagesStoreThisListRight, useUiIsSpinnerFound } from '../../../hooks';
import { tF, tI } from '../../../translate';
import Styles from '../../styles/m_viewStyles.less';

export const CallDataRecordsBetaTableList: React.FC<ITableInclude> = observer(({ isIncluded }: ITableInclude) => {
    const callDataRecordsList = useCallDataRecordsListBeta();
    const right = usePagesStoreThisListRight();
    const isLoading = useUiIsSpinnerFound(['GET_CALL_DATA_SQUASHED_RECORDS_LIST', 'GET_CALL_DATA_RECORDS_LIST']);

    const tableColumns = [
        {
            title: tF('Card Name'),
            dataIndex: 'cardNameLink',
        },
        {
            title: tF('Card'),
            dataIndex: 'cardShortIdLink',
        },
        {
            title: tF('Operator'),
            dataIndex: 'operatorNameLink',
        },
        {
            title: tF('Country'),
            dataIndex: 'countryLink',
        },
        {
            title: tF('Usage'),
            dataIndex: 'units',
            className: Styles.columnRight,
        },
        {
            title: tF('Cost'),
            dataIndex: 'cost',
            className: Styles.columnRight,
        },
        {
            title: tF('Start Date'),
            dataIndex: 'startDate',
            width: 180,
            className: Styles.columnRight,
        },
        !isIncluded ? columnToolbar : {},
    ];

    const tableData = callDataRecordsList.map((callDataRecord: ICallDataRecordsListItem) => {
        return {
            key: callDataRecord.id,
            ...callDataRecord,
            cardShortIdLink: (
                <LinkComponent
                    currentListComponent={'CardList'}
                    id={callDataRecord.cardId}
                    isLight
                    name={callDataRecord.cardShortId}
                />
            ),
            cardNameLink: (
                <LinkComponent
                    currentListComponent={'CardList'}
                    id={callDataRecord.cardId}
                    isLight
                    name={callDataRecord.cardName}
                />
            ),
            operatorNameLink: (
                <LinkComponent
                    currentListComponent={'OperatorsList'}
                    id={callDataRecord.operatorId}
                    isLight
                    name={callDataRecord.operatorName}
                />
            ),
            countryLink: (
                <LinkComponent
                    currentListComponent={'CountryList'}
                    id={callDataRecord.countryId}
                    isLight
                    name={callDataRecord.countryName}
                />
            ),
            toolbar: (
                <TableItemToolbar
                    id={callDataRecord.id}
                    itemName={`"${callDataRecord.id}" ${tI('Call Data Record')}`}
                    {...right}
                />
            ),
        };
    });

    const expandedRowRender = (record: ICallDataRecordsListItem): JSX.Element => {
        return (
            <div className={Styles.expandedRow}>
                <div className={Styles.expandedRowLine}>
                    <div className={Styles.expandedRowLineLabel}>{tF('Card Set')}</div>
                    <div className={Styles.expandedRowLineValue}>
                        <LinkComponent
                            currentListComponent={'CardSetsList'}
                            id={record.cardSetId}
                            isLight
                            name={record.cardSetName}
                        />
                    </div>
                </div>
                <div className={Styles.expandedRowLine}>
                    <div className={Styles.expandedRowLineLabel}>{tF('PLMN')}</div>
                    <div className={Styles.expandedRowLineValue}>
                        <LinkComponent
                            currentListComponent={'CardList'}
                            id={record.cardId}
                            isLight
                            name={record.cardPlmnList}
                        />
                    </div>
                </div>
                <div className={Styles.expandedRowLine}>
                    <div className={Styles.expandedRowLineLabel}>{tF('Operator')}</div>
                    <div className={Styles.expandedRowLineValue}>
                        <LinkComponent
                            currentListComponent={'OperatorsList'}
                            id={record.operatorId}
                            isLight
                            name={record.operatorName}
                        />
                    </div>
                </div>
                <div className={Styles.expandedRowLine}>
                    <div className={Styles.expandedRowLineLabel}>{tF('IMSI')}</div>
                    <div className={Styles.expandedRowLineValue}>
                        <LinkComponent
                            currentListComponent={'SubscribersList'}
                            id={record.subscriberId}
                            isLight
                            name={record.imsi}
                        />
                    </div>
                </div>
                <div className={Styles.expandedRowLine}>
                    <div className={Styles.expandedRowLineLabel}>{tF('Visited Network')}</div>
                    <div className={Styles.expandedRowLineValue}>
                        <LinkComponent
                            currentListComponent={'OperatorsList'}
                            id={record.visitedNetworkId}
                            isLight
                            name={record.visitedNetworkName}
                        />
                    </div>
                </div>
                <div className={Styles.expandedRowLine}>
                    <div className={Styles.expandedRowLineLabel}>{tF('Source')}</div>
                    <div className={Styles.expandedRowLineValue}>{record.source}</div>
                </div>
                <div className={Styles.expandedRowLine}>
                    <div className={Styles.expandedRowLineLabel}>{tF('End')}</div>
                    <div className={Styles.expandedRowLineValue}>{record.endDate}</div>
                </div>
            </div>
        );
    };

    const expandable = { expandedRowRender };

    return (
        <Catcher>
            <div className={Styles.tablePlace}>
                <Table
                    className={'table-theme'}
                    columns={tableColumns}
                    dataSource={tableData}
                    expandable={expandable}
                    loading={isLoading}
                    pagination={false}
                    size={'small'}
                />
            </div>
        </Catcher>
    );
});
