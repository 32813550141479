import React from 'react';
import { observer } from 'mobx-react';
import { Catcher, ListWrapper } from '../';
import { useZonesFilterParams } from '../../hooks';
import { cleanZonesStore } from '../../middleware';
import { ZonesTableList } from './Components';

export const ZonesList: React.FC = observer(() => {
    const filterParams = useZonesFilterParams();

    return (
        <Catcher>
            <ListWrapper cleanStore={cleanZonesStore} filterParams={filterParams}>
                <ZonesTableList />
            </ListWrapper>
        </Catcher>
    );
});
