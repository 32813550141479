import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import {
    IFilterParams,
    INetworkSelectItem,
    IOperator,
    IOperatorsTableList,
    ISelect,
    ISelectList,
} from '../../interfaces';

export function useOperatorsTable(): IOperatorsTableList {
    const operatorsList = useContext(MobXProviderContext).RootStore.operatorsStore.operatorsList;
    return operatorsList.map((c: IOperator) => ({
        id: c.id,
        name: c.attributes?.name,
        tadig: c.attributes?.tadig,
        mccMnc: `${c.attributes?.mcc}/${c.attributes?.mnc}`,
        gateway: c.attributes?.gateway,
    }));
}

export function useOperatorsSelect(): ISelectList {
    return useContext(MobXProviderContext).RootStore.operatorsStore.operatorsSelect;
}

export function useOperatorsFilterParams(): IFilterParams {
    return useContext(MobXProviderContext).RootStore.operatorsStore.filterParams;
}

export function useOperatorsNetworksSelect(): INetworkSelectItem[] {
    return useContext(MobXProviderContext).RootStore.operatorsStore.networksSelect;
}

export function useOperatorsNetworksByCountry(countryId: string): INetworkSelectItem[] {
    return useContext(MobXProviderContext).RootStore.operatorsStore.networksSelect.filter(
        (n: INetworkSelectItem) => n.countryId === countryId,
    );
}
export function useOperatorsNetworksCountByCountry(countryId: string): number {
    return useOperatorsNetworksByCountry(countryId).length;
}

export function useOperatorNetworksGatewaySelect(): ISelect[] {
    return useContext(MobXProviderContext).RootStore.operatorsStore.operatorGateWaySelect;
}
