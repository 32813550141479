import { useEffect, useState } from 'react';
import { appConfig } from '../settings';

export const useInputDebounce = <T>(value: T, delay = appConfig.delayInputDebounce): T => {
    const [debouncedValue, setDebouncedValue] = useState<T>(value);
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        return (): void => {
            clearTimeout(handler);
        };
    }, [value, delay]);
    return debouncedValue;
};
