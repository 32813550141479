import React, { UIEventHandler, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Select } from 'antd';
import { Catcher, Loading } from '../index';
import { appConfig } from '../../settings';
import {
    IFilterSelect,
    IFilterSelectComposed,
    IGetApiType,
    ISelect,
    ISelectList,
    ISelectWithTotal,
    SelectType,
} from '../../interfaces';
import { useUiIsSpinnerFound } from '../../hooks';
import { tI } from '../../translate';

const { Option } = Select;

interface ILazyLoadingHook {
    mode?: SelectType;
    idSpinners?: IGetApiType[];
    useSelectHook: () => ISelectWithTotal;
    pageLimit: number;
    fetchFunc: (...args: any) => any;
}

export interface ILazyLoadingSelectProps {
    idSpinners?: IGetApiType[];
    isBlockAllowClear?: boolean;
    mode?: SelectType;
    onChange?: (val: IFilterSelectComposed) => void;
    title?: string;
    value?: IFilterSelect;
    virtual?: boolean;
    fetchFunc: (...args: any) => any;
    pageLimit?: number;
    useSelectHook: () => ISelectWithTotal;
    className?: string;
}

export const useLazyLoadingHook = ({ mode, idSpinners, useSelectHook, pageLimit, fetchFunc }: ILazyLoadingHook) => {
    const [currentItems, setCurrentItems] = useState<ISelectList>([]);
    const [search, setSearch] = useState<string>('');
    const [page, setPage] = useState<number>(1);
    const selectMode = mode === 'single' ? undefined : (mode as 'multiple' | 'tags');
    const isLoading = useUiIsSpinnerFound(idSpinners || []);
    const { select, total } = useSelectHook();

    const totalPages = Math.ceil(total / pageLimit);

    useEffect(() => {
        setCurrentItems((prevState) => [...prevState, ...select]);
    }, [select]);

    const onScroll = (async (event: React.UIEvent<HTMLElement>) => {
        const target = event.target as HTMLElement;
        if (target?.scrollTop + target.offsetHeight === target.scrollHeight && page + 1 <= totalPages) {
            setPage((prevState) => prevState + 1);
            await fetchFunc({ pageSize: pageLimit, pageNumber: page + 1, search });
        }
    }) as never as UIEventHandler;

    function debounce(fn: (value: string) => void, time: number) {
        let timeoutId: ReturnType<typeof setTimeout> | null;
        function wrapper(args: string) {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                timeoutId = null;
                fn(args);
            }, time);
        }

        if (!isLoading) {
            return wrapper;
        }

        return;
    }

    async function reset() {
        setPage(1);
        setCurrentItems([]);
        setSearch('');
        await fetchFunc({ pageSize: pageLimit, pageNumber: 1, search: '' });
    }

    const onSearch = async (value: string) => {
        setCurrentItems([]);
        setSearch(value);
        setPage(1);
        await fetchFunc({ pageSize: pageLimit, pageNumber: 1, search: value });
    };

    return { onScroll, debounce, reset, onSearch, currentItems, isLoading, select, selectMode };
};

export const LazyLoadingSelect: React.FC<ILazyLoadingSelectProps> = observer(
    ({
        fetchFunc,
        idSpinners = [],
        isBlockAllowClear,
        mode,
        onChange,
        pageLimit = 100,
        title = tI('Filter'),
        useSelectHook,
        value,
        virtual = true,
        className,
    }: ILazyLoadingSelectProps) => {
        const { onScroll, debounce, reset, onSearch, currentItems, isLoading, select, selectMode } = useLazyLoadingHook(
            { fetchFunc, idSpinners, pageLimit, mode, useSelectHook },
        );

        const setOptionsJSX = currentItems.map(
            (s: ISelect): JSX.Element => (
                <Option key={s.id} value={s.id}>
                    <div>{s.name}</div>
                </Option>
            ),
        );

        return (
            <Catcher>
                <Select
                    onClear={reset}
                    allowClear={!isBlockAllowClear}
                    className={className}
                    filterOption={() => true}
                    getPopupContainer={(trigger): HTMLElement => trigger.parentElement}
                    dropdownRender={(menu) => {
                        if (currentItems.length) {
                            return (
                                <div>
                                    {menu}
                                    {isLoading ? <Loading isLoading={isLoading} size={'small'} /> : null}
                                </div>
                            );
                        }

                        return isLoading ? (
                            <div style={{ padding: '30px' }}>
                                <Loading isLoading={isLoading} size={'small'} />
                            </div>
                        ) : (
                            menu
                        );
                    }}
                    dropdownStyle={
                        select.length ? { minHeight: '266px', height: '266px' } : { minHeight: '80px', height: '80px' } //ResizeObserver error fix
                    }
                    onChange={onChange}
                    placeholder={title}
                    showSearch
                    value={value}
                    mode={selectMode}
                    virtual={virtual}
                    onPopupScroll={onScroll}
                    onSearch={debounce(onSearch, appConfig.delayInputDebounce)}
                >
                    {setOptionsJSX}
                </Select>
            </Catcher>
        );
    },
);
