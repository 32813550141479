import React, { MouseEvent } from 'react';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import { FormOutlined } from '@ant-design/icons';
import { Catcher } from '../';
import { IGetApiType } from '../../REST';
import { useEditComponentUrl, usePagesStoreIsRightEdit, useUiIsSpinnerFound } from '../../hooks';
import { tB } from '../../translate';
import Styles from '../styles/m_itemCardStyles.less';

interface IEditButtonProps {
    children?: never;
    isLoading?: boolean;
    loadingTypes?: IGetApiType[];
    setEditMode(val?: boolean): void;
    dataTest?: string;
}

export const EditButton: React.FC<IEditButtonProps> = observer(
    ({ isLoading, setEditMode, loadingTypes, dataTest }: IEditButtonProps) => {
        const isRightEdit = usePagesStoreIsRightEdit();
        const isApiLoading = loadingTypes && loadingTypes.length && useUiIsSpinnerFound(loadingTypes);
        const componentUrl = useEditComponentUrl();

        const onClick = (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>): void => {
            if (!(event.ctrlKey || event.metaKey || event.altKey || event.shiftKey)) {
                event.preventDefault();
                setEditMode(true);
            }
        };

        return (
            <Catcher>
                {isRightEdit ? (
                    <Button
                        className={Styles.mainButton}
                        disabled={isApiLoading || isLoading}
                        href={componentUrl}
                        icon={<FormOutlined />}
                        loading={isApiLoading || isLoading}
                        onClick={onClick}
                        type="primary"
                        data-test={dataTest}
                    >
                        {tB('Edit')}
                    </Button>
                ) : null}
            </Catcher>
        );
    },
);
