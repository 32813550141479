import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import {
    IDateRange,
    IGeolocationSearchParams,
    IGeotrackingCardListItem,
    IGeotrackingCardLocationHistoryList,
    IRoureResult,
} from '../../interfaces';

export function useGeotrackingCardList(): IGeotrackingCardListItem[] {
    return useContext(MobXProviderContext).RootStore.geotrackingStore.cardsList;
}

export function useIsGeotrackingMode(): boolean {
    return useContext(MobXProviderContext).RootStore.geotrackingStore.isGeotrackingMode;
}

export function useGeotrackingCardHistoryLocation(): IGeotrackingCardLocationHistoryList {
    return useContext(MobXProviderContext).RootStore.geotrackingStore.cardHistoryLocation;
}
export function useGeotrackingCardIsMakingRoutes(): boolean[] {
    return useContext(MobXProviderContext).RootStore.geotrackingStore.isMakingRoutes;
}
export function useGeotrackingCardRoutesList(): IRoureResult[] {
    return useContext(MobXProviderContext).RootStore.geotrackingStore.cardRoutesList;
}
export function useGeotrackingCardRoutesPlainList(): IGeotrackingCardLocationHistoryList[] {
    return useContext(MobXProviderContext).RootStore.geotrackingStore.cardRoutesPlainList;
}

export function useGeotrackingSearchParams(): IGeolocationSearchParams {
    return useContext(MobXProviderContext).RootStore.geotrackingStore.searchParams;
}

export function useGeotrackingTrackSearchParams(): IDateRange {
    return useContext(MobXProviderContext).RootStore.geotrackingStore.trackSearchParams;
}

export function useGeotrackingTrackedCardId(): string {
    return useContext(MobXProviderContext).RootStore.geotrackingStore.trackedCardId;
}

export function useIsUpdatingTrackingInfo(): boolean {
    return useContext(MobXProviderContext).RootStore.geotrackingStore.isUpdatingTrackingInfo;
}
