import React from 'react';
import { notification } from 'antd';
import appInfo from '../application.json';
import { UiStore } from '../stores/ui';
import { getProfileUserIsRoleRight } from './profile';
import { getRouterStoreCurrentComponent } from './routerPages';
import { v4 } from 'uuid';
import { IAnyColorTheme, IColorTheme, IRoleRight, appConfig, colorThemes } from '../settings';
import { apiConfig, apiManager } from '../REST';
import {
    IComponentName,
    IDateRange,
    IFilterParams,
    IFilterSelectComposed,
    IGetApiResponse,
    ILanguageId,
    INotificationStoreItem,
    INotificationType,
    INumberOrUndefined,
    IParamsList,
    ISetPageSearch,
    IUiIdSpinner,
} from '../interfaces';
import { appSignalSendMessage } from '../instruments';
import { ITrNotificationMessage, changeLanguageId, tNDRN, tNM } from '../translate';
import { ReloadAppButton } from '../Components';

export function addUiSpinner(idSpinner: IUiIdSpinner = v4()): IUiIdSpinner {
    const UiStoreInstance = UiStore.getInstance();
    return UiStoreInstance.addSpinner(idSpinner);
}
export function removeUiSpinner(idSpinner: IUiIdSpinner): void {
    const UiStoreInstance = UiStore.getInstance();
    UiStoreInstance.removeSpinner(idSpinner);
}

export function removeUiSpinnerByApiType(apiType: string): void {
    const UiStoreInstance = UiStore.getInstance();
    UiStoreInstance.removeSpinnerByApiType(apiType);
}
// ------------ ESPlusNotification ------------

export type INotification = {
    key?: string;
    message: ITrNotificationMessage | string;
    description?: React.ReactNode;
    type?: INotificationType;
    duration?: number;
    icon?: React.ReactNode;
    isNotSendAppSignal?: boolean;
    isTranslated?: boolean;
    isUsersError?: boolean;
    responseAPI?: Response;
    error?: Error;
};

function removeUiStoreNotification(key: string): void {
    const UiStoreInstance = UiStore.getInstance();
    UiStoreInstance.removeNotification(key);
}
export function removeUiNotification(key: string): void {
    removeUiStoreNotification(key);
    notification.destroy(key);
}

function addUiStoreNotification(item: INotificationStoreItem): INotificationStoreItem {
    const UiStoreInstance = UiStore.getInstance();
    return UiStoreInstance.addNotification(item);
}

export function addNotification({
    type = 'info',
    key = v4(),
    message,
    duration = appConfig.notificationDuration,
    description = null,
    icon = null,
    isNotSendAppSignal = false,
    isTranslated = false,
    isUsersError = false,
    responseAPI,
    error,
}: INotification): string {
    if (
        isUsersError ||
        appConfig.notificationUsersTypes.includes(type) ||
        getProfileUserIsRoleRight(appConfig.notificationAllRole as IRoleRight)
    ) {
        const storeNotification = addUiStoreNotification({
            key,
            message: isTranslated ? message : tNM(message as ITrNotificationMessage),
            description: description ? (isTranslated ? description : tNDRN(description)) : null,
        });
        const count = storeNotification.count && storeNotification.count > 1 ? ` (${storeNotification.count})` : '';
        notification[type]({
            key: storeNotification.key,
            message: storeNotification.message + count,
            description: storeNotification.description,
            duration,
            icon,
            placement: 'bottomRight',
            onClose: () => removeUiStoreNotification(storeNotification.key),
        });
        if (!isNotSendAppSignal && appConfig.notificationAllTypeForAppSignal.includes(type)) {
            appSignalSendMessage({
                action: 'Showed user message.',
                appSignalType: type,
                description: description?.toString(),
                error,
                message,
                responseAPI,
            });
        }
        return storeNotification.key;
    } else {
        !isNotSendAppSignal &&
            appSignalSendMessage({
                action: 'Hidden user message.',
                appSignalType: type,
                description: description?.toString(),
                error,
                message,
                responseAPI,
            });
        return key;
    }
}

export const onDownloadNotification = async (
    resp: IGetApiResponse,
    okFunction?: () => Promise<void>,
): Promise<void> => {
    if (resp.isError) {
        addNotification({
            type: 'error',
            message: 'Error load file!',
            isUsersError: true,
        });
    } else {
        addNotification({
            type: 'success',
            message: 'Upload successful!',
            isUsersError: true,
        });
        okFunction && (await okFunction());
    }
};

// ------------ checkVersion ------------
type IGetDeployVersion = {
    deployVersion?: string;
    apiHost?: string;
};
export const getDeployVersion = async (): Promise<IGetDeployVersion> => {
    const { resp } = await apiManager.getApi('APPLICATION');
    return { deployVersion: resp.version, apiHost: resp.apiHost };
};

export async function checkVersion(): Promise<void> {
    const { deployVersion } = await getDeployVersion();

    if (deployVersion && appInfo.version !== deployVersion) {
        notification.warning({
            message: `Please reload the page!`,
            key: 'Version Control',
            duration: 0,
            btn: ReloadAppButton,
            placement: 'bottomRight',
        });
    }
}

// ------------ appSignal ------------

export const setAppSignalKey = (key: string): void => {
    const UiStoreInstance = UiStore.getInstance();
    UiStoreInstance.setAppSignalKey(key);
};

export const getAPIAppSignalKey = async (): Promise<void> => {
    try {
        const { resp } = await apiManager.getApi('APP_SIGNAL');
        const { key } = await resp;
        key && setAppSignalKey(key);
    } catch (e) {
        console.error('-(getAPIAppSignalKey)-Error>', e);
    }
};

export const getAppSignalKey = (): string | undefined => {
    const UiStoreInstance = UiStore.getInstance();
    return UiStoreInstance.appSignalKey;
};

// ------------ checkProductionAPIHost ------------
export async function checkProductionAPIHost(): Promise<void> {
    const { apiHost } = await getDeployVersion();
    if (apiHost) {
        apiConfig.setApiUrl(apiHost);
    }
}

// ------------ Page List ------------
export const setUiForcedUpdateTime = (): void => {
    const { forcedUpdate } = getRouterStoreCurrentComponent();
    forcedUpdate && forcedUpdate();
};

export const setUiTotal = (total?: number): void => {
    const UiStoreInstance = UiStore.getInstance();
    UiStoreInstance.setTotal(Number(total));
};

export const setUiInitPageList = (filterParams?: IFilterParams): void => {
    const UiStoreInstance = UiStore.getInstance();
    UiStoreInstance.setInitPageList(filterParams);
};

export const updateUiLocalStoragePageSize = (
    routeName: IComponentName,
    pageSize: number,
    isDefault?: boolean,
): void => {
    const updatedSize = { [routeName]: pageSize };
    if (isDefault) {
        updatedSize.default = pageSize;
    }
    const pageSizeList = localStorage.getItem('pageSize');
    if (pageSizeList) {
        localStorage.setItem('pageSize', JSON.stringify({ ...JSON.parse(pageSizeList), ...updatedSize }));
    } else {
        localStorage.setItem('pageSize', JSON.stringify({ ...updatedSize }));
    }
};

const loadUiLocalStoragePageSize = (pageName: string): number => {
    const pageSizeList = localStorage.getItem('pageSize');
    if (!pageSizeList) {
        return appConfig.pageDefaultParams.pageSize;
    } else {
        const pageSizeListParse = JSON.parse(pageSizeList);
        if (typeof pageSizeListParse === 'number') {
            return pageSizeListParse;
        } else if (typeof pageSizeListParse === 'object') {
            return pageSizeListParse[pageName] || pageSizeListParse.default || appConfig.pageDefaultParams.pageSize;
        } else {
            return appConfig.pageDefaultParams.pageSize;
        }
    }
};

export const getDefaultPageParams = (pageName: string): IParamsList => {
    const pageSize = loadUiLocalStoragePageSize(pageName);
    return {
        pageNumber: appConfig.pageDefaultParams.pageNumber,
        pageSize,
    };
};

export const setUiPageSize = (pageSize: INumberOrUndefined): void => {
    const UiStoreInstance = UiStore.getInstance();
    UiStoreInstance.setPageSize(pageSize);
};

export const setUiPageSizeFromLocalStorage = (pageName: string): void => {
    const pageSize = loadUiLocalStoragePageSize(pageName);
    setUiPageSize(pageSize);
};

export function setUiPageNumber(pageNumber: INumberOrUndefined): void {
    const UiStoreInstance = UiStore.getInstance();
    UiStoreInstance.setPageNumber(pageNumber);
}

export const setUiToolBarPageNameSize = (routeName: IComponentName, pageSize: number): void => {
    setUiPageSize(pageSize);
    updateUiLocalStoragePageSize(routeName, pageSize, true);
};

export function setUiSearch(search: ISetPageSearch): void {
    const UiStoreInstance = UiStore.getInstance();
    UiStoreInstance.setSearch(search);
}

export function setUiDateRange(dateRange: IDateRange): void {
    const UiStoreInstance = UiStore.getInstance();
    UiStoreInstance.setDateRange(dateRange);
}

export function setUiCompanyId(company: IFilterSelectComposed): void {
    const UiStoreInstance = UiStore.getInstance();
    UiStoreInstance.setCompanyId(company);
}

export function setUiCompanyType(id: IFilterSelectComposed): void {
    const UiStoreInstance = UiStore.getInstance();
    UiStoreInstance.setCompanyType(id);
}

export function setUiPaymentType(paymentType: IFilterSelectComposed): void {
    const UiStoreInstance = UiStore.getInstance();
    UiStoreInstance.setPaymentType(paymentType);
}

export function setUiCardSetId(id: IFilterSelectComposed): void {
    const UiStoreInstance = UiStore.getInstance();
    UiStoreInstance.setCardSetId(id);
}

export function setUiPackageId(packageId: IFilterSelectComposed): void {
    const UiStoreInstance = UiStore.getInstance();
    UiStoreInstance.setPackageId(packageId);
}

export function setUiOperatorId(operatorId: IFilterSelectComposed): void {
    const UiStoreInstance = UiStore.getInstance();
    UiStoreInstance.setOperatorId(operatorId);
}

export function setUiSessionId(id: IFilterSelectComposed): void {
    const UiStoreInstance = UiStore.getInstance();
    UiStoreInstance.setSessionId(id);
}

export function setUiZoneId(id: IFilterSelectComposed): void {
    const UiStoreInstance = UiStore.getInstance();
    UiStoreInstance.setCurrentZoneId(id);
}
export function setUiUserId(id: IFilterSelectComposed): void {
    const UiStoreInstance = UiStore.getInstance();
    UiStoreInstance.setCurrentUserId(id);
}

export function setUiGatewayId(id: IFilterSelectComposed): void {
    const UiStoreInstance = UiStore.getInstance();
    UiStoreInstance.setCurrentGatewayId(id);
}
export function setUiCommandId(id: IFilterSelectComposed): void {
    const UiStoreInstance = UiStore.getInstance();
    UiStoreInstance.setCurrentCommandId(id);
}
export function setUiFileId(id: IFilterSelectComposed): void {
    const UiStoreInstance = UiStore.getInstance();
    UiStoreInstance.setCurrentFileId(id);
}
export function setUiUploadFileStatusId(id: IFilterSelectComposed): void {
    const UiStoreInstance = UiStore.getInstance();
    UiStoreInstance.setCurrentUploadFileStatusId(id);
}

export function setUiEntityId(id: IFilterSelectComposed): void {
    const UiStoreInstance = UiStore.getInstance();
    UiStoreInstance.setCurrentEntityId(id);
}

export function setUiUnitTypeIds(id: IFilterSelectComposed): void {
    const UiStoreInstance = UiStore.getInstance();
    UiStoreInstance.setUnitTypeIds(id);
}

export function setUiParentId(id: IFilterSelectComposed): void {
    const UiStoreInstance = UiStore.getInstance();
    UiStoreInstance.setParentId(id);
}

export function setUiSource(source: IFilterSelectComposed): void {
    const UiStoreInstance = UiStore.getInstance();
    UiStoreInstance.setCurrentSource(source);
}

export function setUiIsDelete(isDelete: boolean): void {
    const UiStoreInstance = UiStore.getInstance();
    UiStoreInstance.setIsDelete(isDelete);
}

export function setUiWithDeleted(withDeleted: boolean): void {
    const UiStoreInstance = UiStore.getInstance();
    UiStoreInstance.setWithDeleted(withDeleted);
}

export function setUiIsActiveThisMonth(isActiveThisMonth: boolean): void {
    const UiStoreInstance = UiStore.getInstance();
    UiStoreInstance.setUiIsActiveThisMonth(isActiveThisMonth);
}

export function setUiLastViewComponentName(lastViewComponentName: IComponentName): void {
    const UiStoreInstance = UiStore.getInstance();
    UiStoreInstance.setLastViewComponentName(lastViewComponentName);
}

export function setUiUrlStorePage({
    cardSetId,
    commandId,
    companyId,
    companyTypeId,
    endDate,
    entityId,
    fileId,
    gatewayId,
    isDelete,
    operatorId,
    pageNumber,
    pageSize,
    parentId,
    paymentType,
    routeName,
    search,
    sessionId,
    startDate,
    unitTypeIds,
    uploadFileStatusId,
    userId,
    withDeleted,
    zoneId,
}: IParamsList): void {
    routeName && pageSize && updateUiLocalStoragePageSize(routeName, pageSize, false);
    setUiCardSetId(cardSetId);
    setUiCommandId(commandId);
    setUiCompanyId(companyId);
    setUiCompanyType(companyTypeId);
    setUiDateRange({ startDate, endDate });
    setUiEntityId(entityId);
    setUiFileId(fileId);
    setUiGatewayId(gatewayId);
    setUiIsDelete(!!isDelete);
    setUiOperatorId(operatorId);
    setUiPageNumber(pageNumber);
    setUiPageSize(pageSize);
    setUiParentId(parentId);
    setUiPaymentType(paymentType);
    setUiSearch(search);
    setUiSessionId(sessionId);
    setUiUnitTypeIds(unitTypeIds);
    setUiUploadFileStatusId(uploadFileStatusId);
    setUiUserId(userId);
    setUiWithDeleted(!!withDeleted);
    setUiZoneId(zoneId);
}

export const saveUiLocalStorageDarkTheme = async (isDarkTheme: boolean): Promise<void> => {
    localStorage.setItem('isDarkTheme', (+isDarkTheme).toString());
};
export const getUiLocalStorageDarkTheme = async (): Promise<boolean> => {
    const darkThemeItem = await localStorage.getItem('isDarkTheme');
    return darkThemeItem ? !!+darkThemeItem : false;
};

export function setDomProperty(o: IAnyColorTheme | IColorTheme): void {
    const arr = Object.entries(o);
    arr.forEach((item) => {
        document.documentElement.style.setProperty(item[0], item[1]);
    });
}
export function setColorProperty(isDarkTheme: boolean): void {
    setDomProperty(colorThemes.any);
    setDomProperty(isDarkTheme ? colorThemes.dark : colorThemes.light);
}
export function setUiDarkTheme(isDarkTheme: boolean): void {
    const UiStoreInstance = UiStore.getInstance();
    UiStoreInstance.setDarkTheme(isDarkTheme);
    setColorProperty(isDarkTheme);
}

export const saveUiLocalStorageLanguage = async (languageId: ILanguageId): Promise<void> => {
    localStorage.setItem('language', languageId.toString());
};

export async function setUiLanguage(languageId: string | null): Promise<void> {
    const languages = appConfig.languages.map((i) => i.id);
    const currentLanguageId = (
        languageId && languages.includes(languageId) ? languageId : appConfig.languageDefault
    ) as ILanguageId;
    await changeLanguageId(currentLanguageId);
    setTimeout(() => {
        const UiStoreInstance = UiStore.getInstance();
        UiStoreInstance.setLanguage(currentLanguageId);
    });
}

export const getUiLocalStorageLanguage = async (): Promise<void> => {
    const languageId = await localStorage.getItem('language');
    await setUiLanguage(languageId);
};

export const getIsFindSpinners = (idSpinners: IUiIdSpinner[]): boolean => {
    let isLoading = false;
    const UiStoreInstance = UiStore.getInstance();
    const spinners = UiStoreInstance.spinners;
    spinners.forEach((spinner: IUiIdSpinner) => {
        idSpinners.forEach((id) => {
            if (spinner.includes(`${id} -`)) {
                isLoading = true;
            }
        });
    });

    return isLoading;
};

export const getUiStorageParams = (): IParamsList => {
    const UiStoreInstance = UiStore.getInstance();
    return UiStoreInstance.params;
};
