import { action, computed, makeObservable, observable } from 'mobx';
import { pagesMap } from '../Pages/pagesBook';
import { AuthStore } from './auth';
import { ProfileStore } from './profile';
import { ICompanyTypeId, IRole, isCompanyTypeIdIsDenied, isCompanyTypeIdIsRight, isRoleRight } from '../settings';
import { IAppStatus, IPage, IRoute, IRouterState, IRoutes } from '../interfaces';
import { rightAppSignalSendMessage } from '../instruments';
import { ITrPage } from '../translate';

const AuthStoreInstance = AuthStore.getInstance();
const ProfileStoreInstance = ProfileStore.getInstance();

const _genPageKey = (ob: IPage): string => ob.componentName + '-' + ob.url;
const _defaultPage: IPage = pagesMap.find(
    (p: IPage) => p.appStatus === 'INITIALIZATION' && p.isActive && p.isDefault,
) || {
    id: 'XXX',
    componentName: 'Initialization',
    title: 'Initialization',
    url: '/',
};

if (_defaultPage.id === 'XXX') {
    rightAppSignalSendMessage({
        action: 'Not found Initialization Component',
        tags: { error: '_defaultPage.id === "XXX"' },
    });
    console.error('Not found Initialization Component');
}

const _defaultComponent: IRouterState = {
    routeName: _defaultPage.componentName,
};

type IFilterAvailablePages = {
    pages: Array<IPage>;
    role: IRole;
    companyTypeId: ICompanyTypeId;
};

function filterAvailablePages({ pages, role, companyTypeId }: IFilterAvailablePages): Array<IPage> {
    return pages
        .filter((p: IPage) => !!p.isActive)
        .filter((p: IPage) => isRoleRight(p.roleRight, role))
        .filter((p: IPage) => !isCompanyTypeIdIsDenied(companyTypeId, p.companyTypeDenied))
        .filter((p: IPage) => isCompanyTypeIdIsRight(companyTypeId, p.companyTypeRight));
}

export class RouterPagesStore {
    private constructor() {
        makeObservable(this);
    }

    private static instance: RouterPagesStore;

    public static getInstance(): RouterPagesStore {
        if (!RouterPagesStore.instance) {
            RouterPagesStore.instance = new RouterPagesStore();
        }
        return RouterPagesStore.instance;
    }

    @computed get appStatus(): IAppStatus {
        return AuthStoreInstance.appStatus;
    }

    @computed get availablePages(): IPage[] {
        const _components: IPage[] = [];
        const _keyRoute: Array<string> = [];
        const role = ProfileStoreInstance.profile.role as IRole;
        const myCompanyTypeId = ProfileStoreInstance.profile.companyTypeId as ICompanyTypeId;

        const availablePages = (routes: IPage[]): void => {
            filterAvailablePages({ pages: routes, role: role, companyTypeId: myCompanyTypeId })
                .filter((p: IPage) => p.appStatus === this.appStatus)
                .forEach((r: IPage) => {
                    if (r.componentName && r.url) {
                        const _newKey = _genPageKey(r);
                        if (_keyRoute.indexOf(_newKey) < 0 || r.isMenuSeparator) {
                            _keyRoute.push(_newKey);
                            _components.push(r);
                        }
                    }
                    if (r.subMenu && Array.isArray(r.subMenu) && r.subMenu.length) {
                        availablePages(r.subMenu);
                    }
                });
        };
        if (pagesMap && Array.isArray(pagesMap) && pagesMap.length) {
            availablePages(pagesMap);
        } else {
            console.error('Need check pages Settings');
        }
        return _components;
    }

    @computed get defaultPage(): IPage {
        const defaultPage = this.availablePages.find((p: IPage) => p.isDefault);
        if (defaultPage) {
            return defaultPage;
        } else {
            console.warn(
                'STORE-> Not found default component ->',
                `-this.appStatus->`,
                this.appStatus,
                this.availablePages.map((i) => i.id),
            );
            rightAppSignalSendMessage({
                action: 'STORE-> Not found default component ->',
                tags: { appStatus: this.appStatus, pages: JSON.stringify(this.availablePages.map((i) => i.id)) },
            });

            return _defaultPage;
        }
    }
    @computed get defaultComponent(): IRouterState {
        return { routeName: this.defaultPage.componentName };
    }

    @observable requestedPage: IRouterState = { routeName: 'Dashboard' };
    @action setRequestedPage(page: IRouterState): void {
        this.requestedPage = page;
    }

    @observable requestedUrl = '';
    @action setRequestedUrl(url: string): void {
        this.requestedUrl = url;
    }

    @computed get availableComponents(): IRouterState[] {
        return this.availablePages.map((page: IPage) => ({
            routeName: page.componentName,
            forcedUpdate: page.forcedUpdate,
            title: page.title,
        }));
    }

    @observable currentComponent: IRouterState = _defaultComponent;
    @computed get currentPage(): IPage {
        const foundPage = this.availablePages.find((p: IPage) => p.componentName === this.currentComponent.routeName);

        if (foundPage) {
            return foundPage;
        } else {
            return this.defaultPage;
        }
    }

    @action setCurrentComponent(component: IRouterState): void {
        const foundComponent = this.availableComponents.find((c: IRouterState) => c.routeName === component.routeName);

        if (foundComponent) {
            this.currentComponent = {
                ...component,
                forcedUpdate: foundComponent.forcedUpdate,
                title: foundComponent.title,
            };
        } else {
            if (this.appStatus === 'READY' && !['Initialization', 'Login'].includes(component.routeName)) {
                rightAppSignalSendMessage({
                    action: 'The user is not right for the page!',
                    tags: {
                        pageAppStatus: this.appStatus,
                        pageMoveComponent: this.defaultComponent.routeName,
                        pageRequestComponent: component.routeName,
                        pageRequestUrl: this.requestedUrl,
                    },
                });
            }
            this.currentComponent = this.defaultComponent;
        }
    }

    @computed get allRoutes(): IRoutes {
        const _activeRoutes: IRoutes = [];
        const _genKey = (ob: IRoute): string => ob.name + '-' + ob.pattern;
        const _keyRoute: Array<string> = [];
        const handleRoute = (routes: IPage[]): void => {
            routes
                .filter((r: IPage) => r.isActive)
                .filter((r: IPage) => !r.isMenuSeparator)
                .forEach((r: IPage) => {
                    if (r.componentName && r.url) {
                        const _newRoute = { name: r.componentName, pattern: r.url };
                        const _newKey = _genKey(_newRoute);
                        if (_keyRoute.indexOf(_newKey) < 0) {
                            _keyRoute.push(_newKey);
                            _activeRoutes.push(_newRoute);
                        }
                        if (r.isDefault) {
                            const _newRoute = { name: 'notFound', pattern: r.url };
                            const _newKey = _genKey(_newRoute);
                            if (_keyRoute.indexOf(_newKey) < 0) {
                                _keyRoute.push(_newKey);
                                _activeRoutes.push(_newRoute);
                            }
                        }
                    }
                    if (r.subMenu && Array.isArray(r.subMenu) && r.subMenu.length) {
                        handleRoute(r.subMenu);
                    }
                });
        };
        if (pagesMap && Array.isArray(pagesMap) && pagesMap.length) {
            handleRoute(pagesMap);
        } else {
            console.error('Need check pages Settings');
        }
        return _activeRoutes;
    }

    @computed get menu(): IPage[] {
        if (pagesMap && Array.isArray(pagesMap) && pagesMap.length) {
            return this.handleMenu(pagesMap);
        } else {
            console.error('Need check privatePages Settings');
            return [];
        }
    }

    @observable isUseExternalUrlManager = false;

    @action setUseExternalUrlManager(value: boolean): void {
        this.isUseExternalUrlManager = value;
    }

    @observable isUseExternalPageTitleManager = false;

    @action setUseExternalPageTitleManager(value: boolean): void {
        this.isUseExternalPageTitleManager = value;
    }

    handleMenu = (page: IPage[]): IPage[] => {
        const role = ProfileStoreInstance.profile.role as IRole;
        const myCompanyTypeId = ProfileStoreInstance.profile.companyTypeId as ICompanyTypeId;

        return filterAvailablePages({ pages: page, role: role, companyTypeId: myCompanyTypeId })
            .filter((p: IPage) => p.id)
            .filter((p: IPage) => p.isMenu)
            .sort((pa, pb) => (pa.menuOrder ? pa.menuOrder : 0) - (pb.menuOrder ? pb.menuOrder : 0))
            .map((p) => ({
                id: p.id,
                name: p.componentName ? p.componentName : p.id,
                subMenu: p.subMenu && Array.isArray(p.subMenu) && p.subMenu.length ? this.handleMenu(p.subMenu) : [],
                title: p.title ? p.title : ((p.componentName ? p.componentName : p.id) as ITrPage),
                menuIcon: p.menuIcon,
                isMenuSeparator: p.isMenuSeparator,
                // ------------ may by Add IMenu type ------------
                url: p.url,
                componentName: p.componentName,
            }));
    };
}
