import React, { MouseEvent } from 'react';
import { observer } from 'mobx-react';
import { Catcher, LoadingApiByTypeAndUnit } from '../../';
import { HorizontalUsageChart } from './';
import { DashboardUnitTypes, ICartItemData } from '../../../interfaces';
import {
    useDashboardCountryUsageChart,
    useDashboardGraphTitleByUnitType,
    useDashboardUnitTypeObj,
    useRouterStore,
} from '../../../hooks';
import { tP } from '../../../translate';
import Styles from '../m_dashboarStyles.less';

interface ICountryUsage {
    useDashboardUsageData?: () => ICartItemData[];
    unitType?: DashboardUnitTypes;
}

export const CountryUsage: React.FC<ICountryUsage> = observer(
    ({ unitType = DashboardUnitTypes.DATA }: ICountryUsage) => {
        const routerStore = useRouterStore();
        const unitTypeObj = useDashboardUnitTypeObj(unitType);
        const subTitle = useDashboardGraphTitleByUnitType(unitTypeObj?.name);
        const title = `${tP('Top Countries')} ${subTitle}`;
        const apiTypeId = `GET_USAGE_COUNTRY_LIST`;
        const data = useDashboardCountryUsageChart(apiTypeId, unitTypeObj);
        const goToCardList = (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>): void => {
            event.preventDefault();
            routerStore.goTo('CountryList');
        };

        const titleLink = (): JSX.Element => (
            <a onClick={goToCardList} href={`/country`}>
                {title}
            </a>
        );

        const goToCountry = (id: string): void => {
            routerStore.goTo('Country', { params: { id } });
        };

        return (
            <Catcher>
                <LoadingApiByTypeAndUnit apiTypeId={apiTypeId} unitTypeId={unitTypeObj?.id} />
                <div className={Styles.chart}>
                    <HorizontalUsageChart
                        sourceData={data}
                        title={title}
                        unitTypeObj={unitTypeObj}
                        titleLink={titleLink()}
                        onSelectRow={goToCountry}
                    />
                </div>
            </Catcher>
        );
    },
);
