import React from 'react';
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import cx from 'classnames';
import Styles from './m_styles.less';

type IUploadFileShowName = {
    name?: string;
    handleDelete?: () => void;
};

export const UploadFileShowName: React.FC<IUploadFileShowName> = ({ name, handleDelete }: IUploadFileShowName) => {
    if (!name) {
        return null;
    } else {
        return (
            <span className={cx(Styles.fileName, { [Styles.fileNameForDelete]: handleDelete })}>
                {name}
                {handleDelete ? (
                    <Button
                        className={Styles.deleteButton}
                        type="primary"
                        shape="circle"
                        danger
                        icon={<CloseOutlined />}
                        size={'small'}
                        onClick={handleDelete}
                    />
                ) : null}
            </span>
        );
    }
};
