import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import { tokenManager } from '../../../REST';
import { useProfile, useRouterStore } from '../../../hooks';
import { disableUserImpersonation, setUiInitPageList } from '../../../middleware';

export const ImpersonateTopBar: React.FC = observer(() => {
    const { firstName, lastName } = useProfile();
    const routerStore = useRouterStore();

    const handleDisableImpersonation = async () => {
        const { isError } = await disableUserImpersonation();

        if (!isError) {
            await tokenManager.disableImpersonatedToken();
        }
    };

    const handleGoToImpersonatePage = async () => {
        await routerStore.goTo('ImpersonateUser');
    };

    useEffect(() => {
        return () => {
            setUiInitPageList();
            handleGoToImpersonatePage();
        };
    }, []);

    return firstName && lastName ? (
        <Button icon={<WarningOutlined />} danger ghost onClick={handleDisableImpersonation}>
            {`Disable impersonation as ${firstName} ${lastName} !`}
        </Button>
    ) : (
        <span>Loading...</span>
    );
});
