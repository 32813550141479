import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Form, Input, Table } from 'antd';
import { Rule } from 'antd/es/form';
import cx from 'classnames';
import { FieldData } from 'rc-field-form/es/interface';
import { appConfig } from '../../../settings';
import {
    IFormValidatorResponse,
    IRateCountryFormInitialValues,
    IRateNetworkItem,
    IRuleAny,
    ITempTableDataSourceDefaultItem,
} from '../../../interfaces';
import { useOperatorsNetworksSelect } from '../../../hooks';
import { addRateNetworkRateItem, apiMessagesGetListError, updateCompanyRateForm } from '../../../middleware';
import { debounce, globalHexId, onFormFieldsChange } from '../../../instruments';
import { AddButton, Catcher } from '../../../Components';
import { EditRateNetworksTableLists, UnitSelect, tableColumns } from './../Components';

interface IEditRateCountryForm {
    apiMessagesLastUpdated: number;
    countryName: string;
    expandedCountryRowKey?: string;
    initialValues: IRateCountryFormInitialValues;
    setExpandedCountryRowKey?: (id: string) => void;
}

export const EditRateCountryForm: React.FC<IEditRateCountryForm> = observer(
    ({
        apiMessagesLastUpdated,
        countryName,
        expandedCountryRowKey,
        initialValues,
        setExpandedCountryRowKey,
    }: IEditRateCountryForm) => {
        const [form] = Form.useForm();
        const [isSecondRender, setIsSecondRender] = useState<boolean>();
        const [expandedRowKeys, setExpandedRowKeys] = useState<Array<string | number>>([]);
        const networkRecordIds = initialValues.networks?.map((i) => i.id) || [];
        const companyRecordIds = [initialValues.id];
        const availableNetworksCount = useOperatorsNetworksSelect().filter(
            (i) => i.countryId === initialValues.countryId,
        ).length;
        const isAddNetwork = !!availableNetworksCount && availableNetworksCount > networkRecordIds.length;

        const apiMessagesNetworksListRules: Array<Rule> = [
            {
                validator(rule: IRuleAny): IFormValidatorResponse {
                    return apiMessagesGetListError({
                        listName: 'salesRateListEntry',
                        idsList: networkRecordIds,
                        fieldName: rule.field,
                    });
                },
            },
        ];
        const apiMessagesCountryListRules: Array<Rule> = [
            {
                validator(rule: IRuleAny): IFormValidatorResponse {
                    return apiMessagesGetListError({
                        listName: 'salesRateListEntry',
                        idsList: companyRecordIds,
                        fieldName: `salesRateListEntry.0.${rule.field}`,
                    });
                },
            },
        ];

        const checkValid = async (): Promise<void> => {
            await form.validateFields();
        };

        const onFieldsChange = (changedFields: FieldData[]): void => {
            onFormFieldsChange({
                changedFields,
                initialValues,
                listIds: { salesRateListEntry: networkRecordIds },
                setForm: updateCompanyRateForm,
                rateFormId: initialValues.id,
            });
        };

        const setMainExpandedRowKeys = (keys: Array<string | number>): void => {
            setExpandedRowKeys(keys);
            initialValues?.id && setExpandedCountryRowKey && setExpandedCountryRowKey(initialValues.id);
        };

        const addNetwork = (): void => {
            const network: IRateNetworkItem = {
                id: globalHexId.getId(),
                countryId: initialValues.countryId,
                operatorId: initialValues.operatorId,
            };
            addRateNetworkRateItem(network);
            setMainExpandedRowKeys([initialValues.id]);
        };

        const dataSource = [
            {
                key: initialValues.id,
                country: `${countryName} (${networkRecordIds.length})`,
                rateData: (
                    <Form.Item name="rateData" rules={apiMessagesCountryListRules}>
                        <Input
                            addonBefore={<UnitSelect name={'rateDataUnitId'} rules={apiMessagesCountryListRules} />}
                        />
                    </Form.Item>
                ),
                rateSms: (
                    <Form.Item name="rateSms" rules={apiMessagesCountryListRules}>
                        <Input
                            addonBefore={<UnitSelect name={'rateSmsUnitId'} rules={apiMessagesCountryListRules} />}
                        />
                    </Form.Item>
                ),
                rateVoiceMobile: (
                    <Form.Item name="rateVoiceMobile" rules={apiMessagesCountryListRules}>
                        <Input
                            addonBefore={
                                <UnitSelect name={'rateVoiceMobileUnitId'} rules={apiMessagesCountryListRules} />
                            }
                        />
                    </Form.Item>
                ),
                rateVoiceNet: (
                    <Form.Item name="rateVoiceNet" rules={apiMessagesCountryListRules}>
                        <Input
                            addonBefore={<UnitSelect name={'rateVoiceNetUnitId'} rules={apiMessagesCountryListRules} />}
                        />
                    </Form.Item>
                ),
                toolBar: isAddNetwork ? <AddButton onClick={(): void => addNetwork()} isHiddenTitle isGhost /> : null,
            },
        ];

        const expandedRowRender = (): JSX.Element => {
            return (
                <EditRateNetworksTableLists
                    networks={initialValues?.networks}
                    formRules={apiMessagesNetworksListRules}
                />
            );
        };

        const onExpand = (isExpand: boolean, record: ITempTableDataSourceDefaultItem): void => {
            if (isExpand) {
                setMainExpandedRowKeys([record.key]);
            } else {
                setMainExpandedRowKeys([]);
            }
        };

        const expandable = {
            expandedRowRender: expandedRowRender,
            rowExpandable: (): boolean => !!networkRecordIds.length,
            expandedRowKeys,
            onExpand,
        };

        useEffect(() => {
            setTimeout(() => {
                form.setFieldsValue(initialValues);
            });
        }, [initialValues?.networks?.length]);

        useEffect(() => {
            if (isSecondRender) {
                checkValid();
            }
        }, [apiMessagesLastUpdated]);

        useEffect(() => {
            if (expandedRowKeys.length && expandedCountryRowKey !== initialValues.id) {
                setExpandedRowKeys([]);
            }
        }, [expandedCountryRowKey]);

        useEffect(() => {
            if (!isSecondRender) {
                setIsSecondRender(true);
            }
        }, [isSecondRender]);

        return (
            <Catcher>
                <Form
                    form={form}
                    name={initialValues.id}
                    initialValues={initialValues}
                    onFieldsChange={debounce(onFieldsChange, appConfig.delayFormUpdateState)}
                    scrollToFirstError={true}
                    layout={'vertical'}
                >
                    <Table
                        className={cx('sub-table-rate')}
                        columns={tableColumns(1)}
                        dataSource={dataSource}
                        expandable={expandable}
                        pagination={false}
                        showHeader={false}
                        size={'small'}
                    />
                </Form>
            </Catcher>
        );
    },
);
