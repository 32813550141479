// noinspection SpellCheckingInspection
import { IExternalAPIOrderDetailFormKeys } from './externalAPIFront';

const valuseList = [0, 1];
const valuseListObj = Object.assign(valuseList);
export type valuseListKey = typeof valuseListObj;

export enum GetExternalAPICommand {
    ADD_NUMBER = 'add_number',
    ALLOW_DATA = 'allow_data',
    ALLOW_DATA_ROAMING = 'allow_data_roaming',
    ALLOW_PREMIUM_SMS = 'allow_premium_sms',
    BLOCK_DATA = 'block_data',
    BLOCK_DATA_ROAMING = 'block_data_roaming',
    BLOCK_PREMIUM_SMS = 'block_premium_sms',
    BLOCK_SIM = 'block_sim',
    CHANGE_FPBX_SETTINGS = 'change_fpbx_settings',
    CHANGE_MSISDN = 'change_msisdn',
    DELETE_NUMBER = 'delete_number',
    DISABLE_BILLSHOCK_PREVENTION = 'disable_billshock_prevention',
    ENABLE_BILLSHOCK_PREVENTION = 'enable_billshock_prevention',
    GET_FPBX_SETTINGS = 'get_fpbx_settings',
    GET_NUMBERS = 'get_numbers',
    ORDER_ACTIVATE = 'order_activate',
    ORDER_CREATE = 'order_create',
    ORDER_DETAIL = 'order_detail',
    ORDER_TERMINATE = 'order_terminate',
    SET_BLOCKING = 'set_blocking',
    SWAP_SIM = 'swap_sim',
    UNBLOCK_SIM = 'unblock_sim',
}

export enum GetExternalAPIParamsName {
    NEW_MSISDN = 'new_msisdn',
    NEW_ICCID = 'new_iccid',
    // OOO = 'subscriptionid',
}

export type IGetExternalAPIFieldParams = {
    field: IExternalAPIOrderDetailFormKeys;
    command:
        | {
              [val in valuseListKey]: GetExternalAPICommand;
          }
        | GetExternalAPICommand;
    params?: {
        fieldName: GetExternalAPIParamsName;
    };
};

const externalAPIFieldParams: Array<IGetExternalAPIFieldParams> = [
    {
        field: 'simCardsIccId',
        command: GetExternalAPICommand.SWAP_SIM,
        params: {
            fieldName: GetExternalAPIParamsName.NEW_ICCID,
        },
    },
    {
        field: 'simCardsMsisdn',
        command: GetExternalAPICommand.CHANGE_MSISDN,
        params: {
            fieldName: GetExternalAPIParamsName.NEW_MSISDN,
        },
    },
    {
        field: 'simCardsAllowData',
        command: {
            false: GetExternalAPICommand.BLOCK_DATA,
            true: GetExternalAPICommand.ALLOW_DATA,
        },
    },
    {
        field: 'simCardsAllowDataRoaming',
        command: {
            false: GetExternalAPICommand.BLOCK_DATA_ROAMING,
            true: GetExternalAPICommand.ALLOW_DATA_ROAMING,
        },
    },
    {
        field: 'simCardsAllowPremiumSMS',
        command: {
            false: GetExternalAPICommand.BLOCK_PREMIUM_SMS,
            true: GetExternalAPICommand.ALLOW_PREMIUM_SMS,
        },
    },
    {
        field: 'simCardsAllowBillShockSMS',
        command: {
            false: GetExternalAPICommand.DISABLE_BILLSHOCK_PREVENTION,
            true: GetExternalAPICommand.ENABLE_BILLSHOCK_PREVENTION,
        },
    },
];

type IGetExternalAPIParamsByField = {
    command?: GetExternalAPICommand;
    fieldName?: GetExternalAPIParamsName;
};

export const getExternalAPIParamsByField = (
    field: IExternalAPIOrderDetailFormKeys,
    value: valuseListKey,
): IGetExternalAPIParamsByField => {
    const params = externalAPIFieldParams.find((i: IGetExternalAPIFieldParams) => i.field === field);
    return {
        command: typeof params?.command === 'string' ? params.command : params?.command[value],
        fieldName: params?.params?.fieldName,
    };
};
