import React from 'react';
import { observer } from 'mobx-react';
import { CardInfoToolBar, CardMainLayout, Catcher, FieldInfo, LinkComponent, cardLayoutOptions } from '../../';
import { IShowForm } from '../../../interfaces';
import { useCardSetInfo, useCardSetSpinnersInfo } from '../../../hooks';
import { tF } from '../../../translate';

export const ShowCardSetInfo: React.FC<IShowForm> = observer(({ setEditMode }: IShowForm) => {
    const cardSetInfo = useCardSetInfo();
    const loadingTypes = useCardSetSpinnersInfo();

    return (
        <Catcher>
            <CardMainLayout
                idSpinners={loadingTypes}
                name={cardSetInfo.name}
                columnOption={cardLayoutOptions.cardSetInfo}
            >
                <FieldInfo title={'Name'} dataTest="cardSetInfoName">
                    {cardSetInfo.name}
                </FieldInfo>
                <FieldInfo title={'Company'} dataTest="cardSetInfoCompany">
                    <LinkComponent
                        currentListComponent={'CompaniesList'}
                        id={cardSetInfo.companyId}
                        name={cardSetInfo.company}
                    />
                </FieldInfo>
                <FieldInfo title={'Geolocation'} dataTest="cardSetInfoGeolocation">
                    {cardSetInfo.geolocationEnabled ? tF('Enabled') : tF('Disabled')}
                </FieldInfo>
                <FieldInfo title={'Notes'} dataTest="cardSetInfoNotes">
                    {cardSetInfo.notes}
                </FieldInfo>
                <CardInfoToolBar
                    dataTest="ShowCardSetInfo"
                    setEditMode={setEditMode}
                    loadingTypes={loadingTypes}
                    isBackToList
                />
            </CardMainLayout>
        </Catcher>
    );
});
