import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { IFilterParams, ISelectList, IZone, IZonesTable } from '../../interfaces';

export function useZonesSelect(): ISelectList {
    return useContext(MobXProviderContext).RootStore.zonesStore.zonesSelect;
}

export function useZonesTable(): IZonesTable {
    const zonesList = useContext(MobXProviderContext).RootStore.zonesStore.zonesList;
    return zonesList.map((zone: IZone) => {
        return { id: zone.id, name: zone.attributes?.name, countryCount: zone.attributes?.country_count };
    });
}

export function useZonesFilterParams(): IFilterParams {
    return useContext(MobXProviderContext).RootStore.zonesStore.filterParams;
}
