import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Dropdown } from 'antd';
import { CloseOutlined, MenuOutlined } from '@ant-design/icons';
import { getParenIdOfChildren } from '../../Pages/pagesBook';
import { makeMenuItems } from '../MainMenu';
import cx from 'classnames';
import { IComponentName } from '../../interfaces';
import { usePagesStoreMenu, useProfile, useRouterStore, useUiDarkTheme, useUiLanguage } from '../../hooks';
import Styles from './m_styles.less';

export const MobileMenu: React.FC = observer(() => {
    const [isVisible, setVisible] = useState(false);
    const languageId = useUiLanguage();
    const isDark = useUiDarkTheme();
    const menu = usePagesStoreMenu();
    const { firstName, lastName } = useProfile();
    const routerStore = useRouterStore();
    const parentComponentId = getParenIdOfChildren(routerStore.routerState.routeName as IComponentName);
    const selectKeys = parentComponentId ? [parentComponentId] : [];

    const onVisibleChange = (): void => {
        setVisible(!isVisible);
    };

    return (
        <Dropdown
            onOpenChange={onVisibleChange}
            menu={{
                items: makeMenuItems({
                    menu: menu,
                    profileLabel: `${firstName} ${lastName}`,
                    type: 'MobileMenu',
                    routerStore,
                    selectKeys,
                }),
            }}
            trigger={['click']}
            open={isVisible}
        >
            <div className={Styles.iconPlace}>
                <CloseOutlined
                    className={cx(Styles.menuIcon, {
                        [Styles.showIcon]: isVisible,
                        [Styles.hideIcon]: !isVisible,
                        [Styles.dark]: isDark,
                        [Styles.light]: !isDark,
                    })}
                />
                <MenuOutlined
                    className={cx(Styles.menuIcon, {
                        [Styles.hideIcon]: isVisible,
                        [Styles.showIcon]: !isVisible,
                        [Styles.dark]: isDark,
                        [Styles.light]: !isDark,
                    })}
                />
            </div>
        </Dropdown>
    );
});
