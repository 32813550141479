import React, { ReactNode } from 'react';
import { observer } from 'mobx-react';
import { CheckOutlined, CloseOutlined, QuestionOutlined } from '@ant-design/icons';
import Styles from './m_styles.less';

type IValueBool = {
    value?: boolean;
};
const IconBool: React.FC<IValueBool> = observer(({ value }: IValueBool) => {
    if (typeof value === 'undefined') {
        return <QuestionOutlined />;
    }
    if (value) {
        return <CheckOutlined className={Styles.boolTrueColor} />;
    } else {
        return <CloseOutlined className={Styles.boolFalseColor} />;
    }
});
export const showIconBoolOrNumber = (value?: boolean | number): ReactNode => {
    if (typeof value === 'number') {
        return value;
    } else {
        return <IconBool value={value} />;
    }
};
