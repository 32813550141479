import React from 'react';
import { observer } from 'mobx-react';
import { Catcher, LoadingApiByIds } from '../../';
import { HorizontalRevenueChart } from './HorizontalRevenueChart';
import { useDashboardCompaniesRevenueChart, useRouterStore } from '../../../hooks';
import { tI, tP } from '../../../translate';
import Styles from '../m_dashboarStyles.less';

export const CompanyRevenue: React.FC = observer(() => {
    const data = useDashboardCompaniesRevenueChart();
    const routerStore = useRouterStore();
    const title = `${tP('Top Companies')} ${tI('by Revenue')}`;
    const goToCompanyList = (): void => {
        routerStore.goTo('CompaniesList');
    };

    const titleLink = (): JSX.Element => (
        <a onClick={goToCompanyList} href={`/companies`}>
            {title}
        </a>
    );
    const goToCompany = (id: string): void => {
        routerStore.goTo('Company', { params: { id } });
    };

    return (
        <Catcher>
            <LoadingApiByIds idSpinners={['GET_REVENUE_COMPANY_LIST']} />
            <div className={Styles.chart}>
                <HorizontalRevenueChart
                    sourceData={data}
                    title={title}
                    titleLink={titleLink()}
                    onSelectRow={goToCompany}
                />
            </div>
        </Catcher>
    );
});
