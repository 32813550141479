import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { ListWrapper } from '../../ListWrapper';
import { ExternalAPISubscribersTableList } from './';
import { appConfig } from '../../../settings';
import { useSubscribersFilterParams } from '../../../hooks';
import {
    cleanSubscribersStore,
    getExternalAPICSubscribersRelationSelect,
    setSubscribersExternalAPIListFilterParamsStore,
} from '../../../middleware';
import { Catcher } from '../../Catcher';

export const ExternalAPIList: React.FC = observer(() => {
    const filterParams = useSubscribersFilterParams();

    useEffect(() => {
        setSubscribersExternalAPIListFilterParamsStore();
    }, []);

    return (
        <Catcher>
            <ListWrapper
                cleanStore={cleanSubscribersStore}
                defaultParams={{ operatorId: appConfig.externalAPICompanyIds[0] }}
                filterParams={filterParams}
                getRelationSelect={getExternalAPICSubscribersRelationSelect}
            >
                <ExternalAPISubscribersTableList />
            </ListWrapper>
        </Catcher>
    );
});
