import React from 'react';
import cx from 'classnames';
import { ITitleCard } from '../../interfaces';
import { formatDataTestAttribute } from '../../instruments/testing';
import { AddButton, Catcher, LoadingApiByIds } from '../../Components';
import Styles from '../styles/m_itemCardStyles.less';

export const TitleCard: React.FC<ITitleCard> = ({
    count,
    dataTest = '',
    extraComponent,
    icon,
    idSpinners,
    isMainCard,
    isTableCard,
    left,
    onClickAddButton,
    OtherButton,
    right,
    title,
    titleToolBar,
    unboundTitle,
}: ITitleCard) => {
    return (
        <Catcher>
            <header className={Styles.cardTitle} data-test="TitleCard">
                <div className={Styles.titlePlace}>
                    <div
                        className={cx(
                            { [Styles.titleMainCard]: isMainCard, [Styles.titleTable]: isTableCard },
                            Styles.titleLabel,
                        )}
                    >
                        {icon ? (
                            isMainCard ? (
                                <div
                                    className={Styles.iconMainPlace}
                                    data-test={formatDataTestAttribute(dataTest, 'TitleCard', 'MainIcon')}
                                >
                                    {icon}
                                </div>
                            ) : (
                                <div
                                    className={Styles.icon}
                                    data-test={formatDataTestAttribute(dataTest, 'TitleCard', 'Icon')}
                                >
                                    {icon}
                                </div>
                            )
                        ) : null}
                        <div className={Styles.titleTextWrap}>
                            {left ? (
                                <div
                                    className={Styles.titleLeft}
                                    data-test={formatDataTestAttribute(dataTest, 'TitleCard', 'TitleLeft')}
                                >
                                    {left}
                                </div>
                            ) : null}
                            <div
                                className={Styles.titleTitle}
                                data-test={formatDataTestAttribute(dataTest, 'TitleCard', 'Title')}
                            >
                                {title || unboundTitle}
                                {count !== undefined ? (
                                    <div className={Styles.titleTitleCount}>
                                        {count}
                                        {idSpinners?.length ? (
                                            <LoadingApiByIds onlySpinner idSpinners={idSpinners} />
                                        ) : null}
                                    </div>
                                ) : null}
                            </div>
                            {right ? (
                                <div
                                    className={Styles.titleRight}
                                    data-test={formatDataTestAttribute(dataTest, 'TitleCard', 'TitleRight')}
                                >
                                    {right}
                                </div>
                            ) : null}
                        </div>
                    </div>
                    {extraComponent}
                    {onClickAddButton || OtherButton ? (
                        <div className={Styles.rightButtonPlace}>
                            {OtherButton ? OtherButton : null}
                            {onClickAddButton ? (
                                <AddButton
                                    onClick={onClickAddButton}
                                    dataTest={formatDataTestAttribute(dataTest, 'TitleCard', 'AddButton')}
                                />
                            ) : null}
                        </div>
                    ) : null}
                </div>
                {titleToolBar ? <div className={Styles.titleToolBar}>{titleToolBar}</div> : null}
            </header>
        </Catcher>
    );
};
