import React from 'react';
import { observer } from 'mobx-react';
import { Checkbox, Form } from 'antd';
import { CardMainLayout, Catcher, InputDebounce, cardLayoutOptions } from '../../';
import { apiMessagesGetFormRules, rulesUrl } from '../../../middleware';
import { tF, tP } from '../../../translate';

export const EditCompanyCustomize: React.FC = observer(() => {
    const urlRules = [rulesUrl, ...apiMessagesGetFormRules];

    return (
        <Catcher>
            <CardMainLayout
                columnOption={cardLayoutOptions.cardEditCompanyCustomize}
                idSpinners={['GET_COMPANY', 'POST_COMPANY', 'PATCH_COMPANY']}
                name={tP('Customize Company')}
                dataTest={'EditCompanyCustomizeInfo'}
            >
                <Form.Item
                    name="slug"
                    label={tF('Custom login URL')}
                    rules={urlRules}
                    data-test={'EditCompanyCustomizeSlug'}
                >
                    <InputDebounce placeholder={tF('Custom login URL')} data-test={'EditCompanyCustomizeSlugInput'} />
                </Form.Item>
                <Form.Item
                    name="customLogoLinkUrl"
                    label={tF('Custom logo URL')}
                    rules={urlRules}
                    data-test={'EditCompanyCustomizeCustomLogoLinkUrl'}
                >
                    <InputDebounce
                        placeholder={tF('Custom logo URL')}
                        data-test={'EditCompanyCustomizeCustomLogoLinkUrlInput'}
                    />
                </Form.Item>

                <Form.Item
                    name="helpDeskEnabled"
                    valuePropName="checked"
                    rules={apiMessagesGetFormRules}
                    data-test={'EditCompanyCustomizeHelpDeskEnabled'}
                >
                    <Checkbox data-test={'EditCompanyCustomizeHelpDeskEnabledCheckBox'}>
                        {tF('Helpdesk widget')}
                    </Checkbox>
                </Form.Item>

                <Form.Item
                    name="customAboutEnabled"
                    valuePropName="checked"
                    rules={apiMessagesGetFormRules}
                    data-test={'EditCompanyCustomizeAboutEnabled'}
                >
                    <Checkbox data-test={'EditCompanyCustomizeAboutEnabledCheckBox'}>{tF('Custom About')}</Checkbox>
                </Form.Item>
            </CardMainLayout>
        </Catcher>
    );
});
