import React from 'react';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import { ButtonHTMLType } from 'antd/es/button';
import { SaveOutlined } from '@ant-design/icons';
import { Catcher } from '../';
import cx from 'classnames';
import { IGetApiType } from '../../REST';
import { useUiIsSpinnerFound } from '../../hooks';
import { ITrButton, tB } from '../../translate';
import Styles from '../styles/m_itemCardStyles.less';

interface ISaveButtonProps {
    buttonType?: ButtonHTMLType;
    children?: never;
    dataTest?: string;
    isDisabled?: boolean;
    isLoading?: boolean;
    isNew?: boolean;
    label?: ITrButton;
    loadingTypes?: IGetApiType[];
    onClick?: () => void;
    isDanger?: boolean;
}

export const SaveButton: React.FC<ISaveButtonProps> = observer(
    ({
        buttonType = 'button',
        isDanger,
        dataTest,
        isDisabled,
        isLoading,
        isNew,
        label,
        loadingTypes,
        onClick,
    }: ISaveButtonProps) => {
        const isApiLoading = loadingTypes && loadingTypes.length && useUiIsSpinnerFound(loadingTypes);
        const isSubmitButton = buttonType === 'submit';

        return (
            <Catcher>
                <Button
                    type="primary"
                    htmlType={isSubmitButton ? 'submit' : 'button'}
                    className={cx(Styles.mainButton, {
                        [Styles.buttonsDisable]: isLoading,
                    })}
                    disabled={isApiLoading || isLoading || isDisabled}
                    icon={<SaveOutlined />}
                    loading={isApiLoading || isLoading}
                    onClick={isSubmitButton ? undefined : onClick}
                    data-test={dataTest}
                    danger={isDanger}
                >
                    {tB(label ? label : isNew ? 'Save' : 'Update')}
                </Button>
            </Catcher>
        );
    },
);
