import React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import { Catcher, LinkComponent, TableItemToolbar, columnToolbar } from '../../';
import { IPurchaseRateListTableItem } from '../../../interfaces';
import { usePurchaseRatesList, useUiIsLoading } from '../../../hooks';
import { deletePurchaseRateList } from '../../../middleware';
import { tF, tI } from '../../../translate';
import '../../../customStyle/table.less';
import Styles from '../../styles/m_viewStyles.less';

export const PurchaseRatesListTable: React.FC = observer(() => {
    const isLoading = useUiIsLoading();
    const list = usePurchaseRatesList();

    const tableColumns = [
        {
            title: tF('Name'),
            dataIndex: 'nameLink',
        },
        {
            title: tF('Operator'),
            dataIndex: 'operatorLink',
        },
        columnToolbar,
    ];
    const tableData = list.map((p: IPurchaseRateListTableItem) => {
        const key = p.id;
        const deleteItem = (): void => {
            deletePurchaseRateList(p.id);
        };
        const name = p.name ? p.name : `# ${p.id}`;

        return {
            key,
            nameLink: <LinkComponent currentListComponent={'PurchaseRatesList'} id={p.id} isLight name={name} />,
            operatorLink: (
                <LinkComponent currentListComponent={'OperatorsList'} id={p.operatorId} isLight name={p.operator} />
            ),
            toolbar: (
                <TableItemToolbar
                    deleteItem={deleteItem}
                    id={p.id}
                    itemName={`"${name}" ${tI('purchase rate list')}`}
                />
            ),
        };
    });

    return (
        <Catcher>
            <div className={Styles.tablePlace}>
                <Table
                    className={'table-theme'}
                    columns={tableColumns}
                    dataSource={tableData}
                    loading={isLoading}
                    pagination={false}
                    size={'middle'}
                />
            </div>
        </Catcher>
    );
});
