import { action, makeObservable, observable, reaction } from 'mobx';
import { UiStore } from './ui';
import { appConfig } from '../settings';
import { IFilterParams, INetworkSelectItem, IOperatorsList, ISelect, ISelectList } from '../interfaces';
import { getOperatorsList } from '../middleware';
import { apiParamsCleaner } from '../instruments';

export class OperatorsStore {
    private constructor() {
        makeObservable(this);
        reaction(
            () => this.forcedUpdateTime,
            () => {
                const UiStoreInstance = UiStore.getInstance();
                const params = UiStoreInstance.params;
                const cleanParams = apiParamsCleaner(params, this.filterParams);
                getOperatorsList(cleanParams).then();
            },
            {
                delay: appConfig.APIDelay,
            },
        );
    }

    private static instance: OperatorsStore;
    public static getInstance(): OperatorsStore {
        if (!OperatorsStore.instance) {
            OperatorsStore.instance = new OperatorsStore();
        }
        return OperatorsStore.instance;
    }

    @observable operatorsList: IOperatorsList = [];
    @action setOperatorsList(list: IOperatorsList): void {
        this.operatorsList = list;
    }

    @observable operatorsSelect: ISelectList = [];
    @action setOperatorsSelect(list: ISelectList): void {
        this.operatorsSelect = list;
    }

    @observable networksSelect: INetworkSelectItem[] = [];
    @action setNetworksSelect(list: INetworkSelectItem[]): void {
        this.networksSelect = list;
    }

    @observable operatorGateWaySelect: ISelect[] = [];
    @action setOperatorsGatewaySelect(list: ISelect[]): void {
        this.operatorGateWaySelect = list;
    }

    @action cleanStore(): void {
        this.operatorsList = [];
        this.operatorsSelect = [];
        this.networksSelect = [];
        this.operatorGateWaySelect = [];
    }

    @observable forcedUpdateTime = 0;
    @action setForcedUpdateTime(): void {
        this.forcedUpdateTime = Date.now();
    }

    @observable filterParams: IFilterParams = {
        pageNumber: true,
        pageSize: true,
        search: true,
    };
}
