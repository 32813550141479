import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Col, Row } from 'antd';
import { columnOption } from './columnOption';
import cx from 'classnames';
import { dashboardType } from '../../interfaces';
import { useProfile, useRouterStore, useUiDateRange } from '../../hooks';
import {
    abortAllDashboardFetch,
    cleanDashboardStore,
    setDashboardForcedUpdateTime,
    setDashboardType,
    setUiDateRange,
} from '../../middleware';
import { getFirstDayCurrentMonthAsText, getTodayAsText } from '../../instruments';
import { Catcher, GoogleTrafficMap, rowGutter } from '../../Components';
import { CardSetUsage, CardsUsage, CountryUsage, DashboardHeader, TimelineUsage } from './Components';
import ViewStyles from '../styles/m_viewStyles.less';
import Styles from './m_dashboarStyles.less';

export const Dashboard: React.FC = observer(() => {
    const routerStore = useRouterStore();
    const { companyId } = useProfile();
    const dateRange = useUiDateRange();

    const { dashboardFullCard, dashboardChartCard } = columnOption;

    useEffect(() => {
        routerStore.goTo('Dashboard');
        !dateRange ||
            !dateRange.startDate ||
            (!dateRange.endDate &&
                setUiDateRange({
                    startDate: getFirstDayCurrentMonthAsText(),
                    endDate: getTodayAsText(),
                }));

        setDashboardForcedUpdateTime();
        setDashboardType(dashboardType.main);

        return (): void => {
            abortAllDashboardFetch();
            cleanDashboardStore();
        };
    }, []);

    return (
        <Catcher>
            <div className={ViewStyles.main}>
                <div className={cx(ViewStyles.content, ViewStyles.contentNotBorder)}>
                    <Row className={Styles.dashboardRow}>
                        <Col className={Styles.headerPlace} {...dashboardFullCard}>
                            <DashboardHeader companyId={companyId} />
                        </Col>
                    </Row>
                    <Row className={Styles.dashboardRow}>
                        <Col className={Styles.mapPlace} {...dashboardFullCard}>
                            <GoogleTrafficMap />
                        </Col>
                    </Row>
                    <Row gutter={rowGutter.dashboardChartCard} className={Styles.dashboardRow} justify={'center'}>
                        <Col className={Styles.chartPlace} {...dashboardChartCard}>
                            <CountryUsage />
                        </Col>
                        {/*<Col className={Styles.chartPlace} {...dashboardChartCard}>*/}
                        {/*    <CountryUsage unitType={DashboardUnitTypes.VOICE} />*/}
                        {/*</Col>*/}
                        <Col className={Styles.chartPlace} {...dashboardChartCard}>
                            <CardSetUsage />
                        </Col>
                        {/*<Col className={Styles.chartPlace} {...dashboardChartCard}>*/}
                        {/*    <CardSetUsage unitType={DashboardUnitTypes.VOICE} />*/}
                        {/*</Col>*/}
                        <Col className={Styles.chartPlace} {...dashboardChartCard}>
                            <CardsUsage />
                        </Col>
                        {/*<Col className={Styles.chartPlace} {...dashboardChartCard}>*/}
                        {/*    <CardsUsage unitType={DashboardUnitTypes.VOICE} />*/}
                        {/*</Col>*/}
                        <Col className={Styles.chartPlace} {...dashboardChartCard}>
                            <TimelineUsage />
                        </Col>
                    </Row>
                </div>
            </div>
        </Catcher>
    );
});
