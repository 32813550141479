import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Form, Input, Popconfirm, Select } from 'antd';
import { AuditOutlined, TagOutlined } from '@ant-design/icons';
import cx from 'classnames';
import { ICompanySubscriptionsBody, ISelect, ISelectList, OrderBy } from '../../../interfaces';
import {
    useCompanyInfo,
    useCompanySubscription,
    useProfileIsRight,
    useRouterStore,
    useUiIsLoading,
} from '../../../hooks';
import {
    addNotification,
    apiMessagesGetFormRulesNumber,
    deleteCompanySubscription,
    editCompanySubscription,
    getCompanySubscription,
    rulesNot0Number,
    rulesPositiveNumber,
    setCompanySubscription,
} from '../../../middleware';
import { tB, tI, tP } from '../../../translate';
import {
    CardInfoToolBar,
    CardMainLayout,
    Catcher,
    FieldInfoAdvanced,
    FormSelectLight,
    cardLayoutOptions,
} from '../../../Components';
import Styles from '../../styles/m_itemCardStyles.less';

function EditCompanySubscriptions({ cost, period, currency, onCancel, subscriptionId, companyId }: any) {
    const { Option } = Select;
    const isLoading = useUiIsLoading();
    const [form] = Form.useForm();
    const isLord = useProfileIsRight('LORD');

    const initialValues = { cost, period };

    const periodList = [
        { id: '1', name: 'Weekly', value: 'weekly', disabled: false },
        { id: '2', name: 'Monthly', value: 'monthly', disabled: false },
        { id: '3', name: 'Zero Balance', value: 'zero_balance', disabled: false },
    ] as ISelectList;

    const selectCompanyListJSX = periodList.map(
        (s: ISelect): JSX.Element => (
            <Option key={s.id} value={s.value} disabled={s.disabled}>
                <div>{s.name}</div>
            </Option>
        ),
    );

    const onFinish = async (values: any): Promise<void> => {
        const attributes: ICompanySubscriptionsBody = {
            amount_cents: values.cost * 100,
            period: values.period,
        };

        const isError = await editCompanySubscription(companyId, subscriptionId, attributes);

        if (isError) {
            addNotification({
                type: 'error',
                message: 'Update Error!',
                description: `There was an error while updating your subscription!`,
                duration: 5,
            });
            return;
        }

        addNotification({
            type: 'success',
            message: 'Subscription Updated',
            description: `Your Subscription has been successfully updated`,
            duration: 5,
        });

        await getCompanySubscription(companyId);

        onCancel();
    };

    return (
        <Catcher>
            <CardMainLayout
                columnOption={cardLayoutOptions.cartCompanySideInfo}
                name={tP('Active Subscriptions')}
                idSpinners={['UPDATE_COMPANY_SUBSCRIPTION', 'GET_COMPANY_SUBSCRIPTION']}
            >
                <Form
                    form={form}
                    name="editSubscirption"
                    initialValues={initialValues}
                    onFinish={onFinish}
                    scrollToFirstError={true}
                    layout={'vertical'}
                >
                    <Form.Item
                        name="cost"
                        label={'Amount'}
                        rules={[...apiMessagesGetFormRulesNumber, isLord ? rulesNot0Number : rulesPositiveNumber]}
                    >
                        <Input addonBefore={currency} placeholder="0" className={Styles.inputCurrencyAddonBefore} />
                    </Form.Item>
                    <FormSelectLight
                        initialValue={'monthly'}
                        fieldName={'period'}
                        label={'Subscription type'}
                        selectJSX={selectCompanyListJSX}
                        isNotClear={true}
                    />
                    <CardInfoToolBar onCancel={onCancel}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className={cx(Styles.mainButton)}
                            disabled={isLoading}
                            loading={isLoading}
                        >
                            Edit Subscription
                        </Button>
                    </CardInfoToolBar>
                </Form>
            </CardMainLayout>
        </Catcher>
    );
}

export const ShowCompanySubscriptions: React.FC = observer(() => {
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const { id: currentCompanyId, name, currency } = useCompanyInfo();
    const routerStore = useRouterStore();
    const { amountCents = 0, period, status, targetType, createdAt, id } = useCompanySubscription();

    const handleCreateNewSubscription = () => {
        routerStore.goTo('OrderNew', { params: { id: currentCompanyId, by: OrderBy.SUBSCRIPTION } });
    };

    const handleDeleteSubscription = async () => {
        const isError = await deleteCompanySubscription(currentCompanyId, `${id}`);

        if (isError) {
            addNotification({
                type: 'error',
                message: 'Error!',
                description: `There was an error while unsubscribing!`,
                duration: 5,
            });
            return;
        }

        addNotification({
            type: 'success',
            message: 'Subscription Updated',
            description: `You has successfully unsubscribed`,
            duration: 5,
        });

        setCompanySubscription({});
    };

    if (isEdit) {
        return (
            <EditCompanySubscriptions
                subscriptionId={id}
                companyName={name}
                cost={amountCents / 100}
                currency={currency}
                companyId={currentCompanyId}
                period={period}
                onCancel={() => setIsEdit(false)}
            />
        );
    }

    return (
        <Catcher>
            <CardMainLayout columnOption={cardLayoutOptions.cartCompanySideInfo} name={tP('Active Subscriptions')}>
                {id ? (
                    <>
                        <FieldInfoAdvanced
                            icon={<TagOutlined className={Styles.labelIcon} />}
                            title={'Amount'}
                            dataTest={'ShowCompanySlug'}
                        >
                            {amountCents / 100}
                        </FieldInfoAdvanced>
                        <FieldInfoAdvanced
                            icon={<TagOutlined className={Styles.labelIcon} />}
                            title={'Period'}
                            dataTest={'ShowCompanySlug'}
                        >
                            {period}
                        </FieldInfoAdvanced>
                        <FieldInfoAdvanced
                            icon={<TagOutlined className={Styles.labelIcon} />}
                            title={'Status'}
                            dataTest={'ShowCompanySlug'}
                        >
                            {status}
                        </FieldInfoAdvanced>
                        <FieldInfoAdvanced
                            icon={<TagOutlined className={Styles.labelIcon} />}
                            title={'Target Type'}
                            dataTest={'ShowCompanySlug'}
                        >
                            {targetType}
                        </FieldInfoAdvanced>
                        <FieldInfoAdvanced
                            icon={<TagOutlined className={Styles.labelIcon} />}
                            title={'Created at'}
                            dataTest={'ShowCompanySlug'}
                        >
                            {createdAt}
                        </FieldInfoAdvanced>
                    </>
                ) : (
                    <>
                        <div>
                            <h3>There is no active subscription for now</h3>
                            <Button
                                className={Styles.mainButton}
                                type="primary"
                                icon={<AuditOutlined />}
                                onClick={handleCreateNewSubscription}
                            >
                                Subscribe
                            </Button>
                        </div>
                    </>
                )}
                <CardInfoToolBar isHideCancel>
                    {id ? (
                        <>
                            <Popconfirm
                                title={tI('Do you really want to unsubscribe?')}
                                onConfirm={handleDeleteSubscription}
                                okText={tB('Confirm')}
                                cancelText={tB('Cancel')}
                                placement="bottom"
                            >
                                <Button className={Styles.mainButton}>Unsubscribe</Button>
                            </Popconfirm>
                            <Button
                                className={Styles.mainButton}
                                type="primary"
                                onClick={() => setIsEdit((prevState) => !prevState)}
                            >
                                {tB('Edit')}
                            </Button>
                        </>
                    ) : null}
                </CardInfoToolBar>
            </CardMainLayout>
        </Catcher>
    );
});
