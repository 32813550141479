import React, { Fragment, ReactNode } from 'react';
import { LinkComponent } from '../../';
import { IServerNotificationAPIGETPayload } from '../../../interfaces';
import { useProfile } from '../../../hooks';
import { ITrCustom, tCustom } from '../../../translate';

type INotificationMessage = {
    id: string;
    event: ITrCustom;
    payload: IServerNotificationAPIGETPayload;
};
type IMessageOptions = {
    id: string;
    balance?: number;
    cardId?: number;
    lastImei?: string;
    lockImei?: string;
    monthlyCost?: number;
    cardSetId?: number;
    subscriberId?: number;
    threshold?: number;
    currency?: string;
    lac?: string;
    cid?: string;
    accuracy?: string;
    x?: string;
    y?: string;
};

type IAddLinks = {
    message: string;
    options: IMessageOptions;
};

const addLink = (arrMessage: ReactNode[], text: string, link: ReactNode): ReactNode[] => {
    const newMessage: ReactNode[] = [];
    arrMessage.forEach((subMess) => {
        if (typeof subMess === 'string') {
            if (subMess.includes(text)) {
                const newArr = subMess.split(text);
                newArr.forEach((sm: string, i: number) => {
                    newMessage.push(sm);
                    if (i < newArr.length - 1) {
                        newMessage.push(link);
                    }
                });
            } else {
                newMessage.push(subMess);
            }
        } else {
            newMessage.push(subMess);
        }
    });

    return newMessage;
};

const AddLinks: React.FC<IAddLinks> = ({ message, options }: IAddLinks) => {
    let newMessage: ReactNode[] = [message];
    if (options.cardId) {
        const cardId = options.cardId.toString();
        newMessage = addLink(newMessage, cardId, <LinkComponent currentListComponent={'CardList'} id={cardId} />);
    }
    if (options.cardId && options.lastImei) {
        const cardId = options.cardId.toString();
        const lastImei = options.lastImei.toString();
        newMessage = addLink(
            newMessage,
            lastImei,
            <LinkComponent currentListComponent={'CardList'} id={cardId} name={lastImei} />,
        );
    }
    if (options.cardId && options.lockImei) {
        const cardId = options.cardId.toString();
        const lockImei = options.lockImei.toString();
        newMessage = addLink(
            newMessage,
            lockImei,
            <LinkComponent currentListComponent={'CardList'} id={cardId} name={lockImei} />,
        );
    }
    if (options.subscriberId) {
        const subscriberId = options.subscriberId.toString();
        newMessage = addLink(
            newMessage,
            subscriberId,
            <LinkComponent currentListComponent={'SubscribersList'} id={subscriberId} />,
        );
    }
    if (options.cardSetId) {
        const cardSetId = options.cardSetId.toString();
        newMessage = addLink(
            newMessage,
            cardSetId,
            <LinkComponent currentListComponent={'CardSetsList'} id={cardSetId} />,
        );
    }
    const newMessageWithKey = newMessage.map((sm: ReactNode, i: number) => {
        return <Fragment key={i}>{sm}</Fragment>;
    });
    return <Fragment>{newMessageWithKey}</Fragment>;
};

export const NotificationMessage: React.FC<INotificationMessage> = ({ id, event, payload }: INotificationMessage) => {
    const { currency = '' } = useProfile();
    const options: IMessageOptions = {
        id: id,
        balance: payload.balance,
        cardId: payload.card_id,
        lastImei: payload.last_imei,
        lockImei: payload.lock_imei,
        monthlyCost: payload.monthly_cost,
        cardSetId: payload.card_set_id,
        subscriberId: payload.subscriber_id,
        threshold: payload.threshold,
        currency,
    };

    const message = tCustom(event, options);
    const transactionMessage = message === event ? tCustom('Unidentified message from server', { id: id }) : message;

    return <AddLinks message={transactionMessage} options={options} />;
};
