import { RatesStore } from '../stores/rates';
import { cleanPurchaseRatesStore } from './purchases';
import { setUiTotal } from './ui';
import { apiManager } from '../REST';
import {
    ICompanyRateItem,
    IIncludeAssignedCompanyItemAPI,
    IItemAPI,
    IParamsList,
    IRateListItemAPI,
    IRatesListAPI,
} from '../interfaces';

function setRatesStore(apiList: IRatesListAPI): void {
    if (apiList.data) {
        const companies: IItemAPI[] = apiList?.included?.filter((i) => i.type === 'company') || [];
        const assignedCompanies = apiList?.included?.filter((i) => i.type === 'assigned_company') || [];
        const StoreInstance = RatesStore.getInstance();
        StoreInstance.setList(
            apiList.data.map((item: IRateListItemAPI) => {
                const companyId = item.relationships?.company?.data?.id;
                const company = companies.find((i) => i.id === companyId)?.attributes.name;
                const assignedCompaniesList: ICompanyRateItem[] =
                    item.relationships?.assigned_companies?.data?.map((ac: IIncludeAssignedCompanyItemAPI) => {
                        const assignedCompanyId =
                            (assignedCompanies.find((f) => f.id === ac.id) as IIncludeAssignedCompanyItemAPI)
                                ?.relationships?.company?.data?.id || '';
                        const assignedCompanyName = companies.find((f) => f.id === assignedCompanyId)?.attributes?.name;

                        return {
                            id: assignedCompanyId,
                            name: assignedCompanyName,
                        };
                    }) || [];
                return {
                    id: item.id,
                    name: item.attributes?.name,
                    companyId,
                    company,
                    assignedCompaniesList,
                };
            }),
        );
    }
}

export function setRatesForcedUpdateTime(): void {
    const StoreInstance = RatesStore.getInstance();
    StoreInstance.setForcedUpdateTime();
}

export async function getRatesList(params: IParamsList): Promise<void> {
    const { resp } = await apiManager.getApi('GET_SALES_RATES_LIST', { searchParamsList: params });
    resp && setRatesStore(resp as IRatesListAPI);
    const { meta } = resp;
    meta && setUiTotal(meta.records_count);
}

export function cleanRatesRelationStore(): void {
    cleanPurchaseRatesStore();
}

export function cleanRatesStore(): void {
    const StoreInstance = RatesStore.getInstance();
    StoreInstance.cleanStore();
    cleanRatesRelationStore();
}

export function setRatesSelect(data: IItemAPI[]): void {
    const StoreInstance = RatesStore.getInstance();
    StoreInstance.setRateSelect(
        data.map((i) => {
            return { id: i.id, name: i.attributes.name };
        }),
    );
}

export async function getRatesSelect(): Promise<void> {
    const { resp } = await apiManager.getApi('GET_SALES_RATES_SELECT');
    const { data } = resp;
    data && setRatesSelect(data as IItemAPI[]);
}
