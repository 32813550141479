import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { v4 } from 'uuid';
import { appConfig } from '../../settings';
import {
    ICurrenciesSelectItem,
    IIncludeAssignedCompanyItem,
    IIncludeCombinationCardSetCardItem,
    IIncludeCombinationCardSetCardLocationItem,
    IIncludeCombinationCardSetCardSubscribersItem,
    IIncludeMainItem,
    IIncludeTypeUi,
    IIncludedStoreCurrency,
    IIncludedStoreCurrencyList,
} from '../../interfaces';

export function useIncludedCurrencyList(): ICurrenciesSelectItem[] {
    const currencies: IIncludedStoreCurrencyList = useContext(MobXProviderContext).RootStore.includedStore.currencies;

    return currencies.map((c: IIncludedStoreCurrency) => {
        const appConfigCurrencyFind = appConfig.currency.find((a) => a.id === c.id);
        return {
            id: c.id,
            abbreviation: appConfigCurrencyFind ? appConfigCurrencyFind.abbreviation : c.attributes.abbreviation,
        };
    });
}
// ------------ New Include Main------------
export function useIncludeTypeList(type: IIncludeTypeUi): IIncludeMainItem[] {
    return useContext(MobXProviderContext).RootStore.includedStore.lists[type] || [];
}

export function useIncludeTypeListActive(type: IIncludeTypeUi): IIncludeMainItem[] {
    return useIncludeTypeList(type).filter((i) => !i.destroy);
}

// ------------ AssignedCompanies ------------
export function useIncludeAssignedCompanies(): IIncludeAssignedCompanyItem[] {
    return useIncludeTypeList('assignedCompanies') as IIncludeAssignedCompanyItem[];
}

export function useIncludeAssignedCompaniesActive(): IIncludeAssignedCompanyItem[] {
    return useIncludeTypeListActive('assignedCompanies') as IIncludeAssignedCompanyItem[];
}

// ------------ CombinationCardSetCards ------------
export function useIncludeCombinationCardSetCards(): IIncludeCombinationCardSetCardItem[] {
    return useIncludeTypeList('combinationCardSetCards') as IIncludeCombinationCardSetCardItem[];
}

export function useIncludeCombinationCardSetCardsLocationList(): IIncludeCombinationCardSetCardLocationItem[] {
    const cardSetCardsCombination = useIncludeCombinationCardSetCards();

    return cardSetCardsCombination
        ?.filter((i) => i.deviceInfo?.location?.x && i.deviceInfo?.location?.y)
        ?.map((i) => {
            return {
                ...i,
                id: i?.cardId || v4(),
                lat: i?.deviceInfo?.location?.x,
                lng: i?.deviceInfo?.location?.y,
            } as IIncludeCombinationCardSetCardLocationItem;
        });
}

export function useIncludeCombinationCardSetCardSubscribers(): IIncludeCombinationCardSetCardSubscribersItem[] {
    return useIncludeTypeList('combinationCardSetCardSubscribers') as IIncludeCombinationCardSetCardSubscribersItem[];
}

export function useIncludeCombinationCardSetCardsActive(showActive: boolean): IIncludeCombinationCardSetCardItem[] {
    const list = useIncludeTypeListActive('combinationCardSetCards') as IIncludeCombinationCardSetCardItem[];
    return list.filter((c: IIncludeCombinationCardSetCardItem) => (showActive ? c.isActive : !c.isActive));
}

export function useIncludedCombinationCardSetTotalAmount(): number {
    return useContext(MobXProviderContext).RootStore.includedStore.combinationCardSetTotal;
}
