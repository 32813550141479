import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Divider, Form, Input } from 'antd';
import { Rule, RuleObject } from 'antd/es/form';
import { SaveOutlined } from '@ant-design/icons';
import { CardLayout, Catcher } from '../../';
import cx from 'classnames';
import { FieldData } from 'rc-field-form/es/interface';
import {
    IAntDFormChangeFieldName,
    IFormFieldValue,
    IFormFields,
    IFormValidatorResponse,
    IUser,
} from '../../../interfaces';
import { useIsImpersonating, useUiIsLoading } from '../../../hooks';
import {
    addNotification,
    apiMessagesDeleteMessageByFieldName,
    apiMessagesGetFormRules,
    apiMessagesSaveMessages,
    patchPassword,
} from '../../../middleware';
import { tB, tF, tV } from '../../../translate';
import Styles from '../../styles/m_itemCardStyles.less';

export const ChangePassword: React.FC = observer(() => {
    const isImpersonating = useIsImpersonating();
    const [isChanged, setChanged] = useState(false);
    const [isValid, setValid] = useState(false);
    const [isSending, setSending] = useState(false);
    const isLoading = useUiIsLoading();
    const [form] = Form.useForm();
    const initialValues = {};
    const validateAPIAndPassword: Array<Rule> = [
        {
            required: true,
            message: tV('Please input password!'),
        },
        ...apiMessagesGetFormRules,
    ];

    const validateNewPassword: Array<Rule> = [
        ({ getFieldValue }): RuleObject => ({
            validator(rule: RuleObject, value): IFormValidatorResponse {
                if (!value || getFieldValue('currentPassword') !== value) {
                    return Promise.resolve();
                }

                return Promise.reject(tV('The new password should not coincide with the old password!'));
            },
        }),
        ...validateAPIAndPassword,
    ];

    const validatePasswordConfirmation: Array<Rule> = [
        ({ getFieldValue }): RuleObject => ({
            validator(rule: RuleObject, value: IFormFieldValue): IFormValidatorResponse {
                if (!value || getFieldValue('passwordNew') === value) {
                    return Promise.resolve();
                }

                return Promise.reject(tV('The two passwords that you entered do not match!'));
            },
        }),
        ...validateAPIAndPassword,
    ];

    useEffect(() => {
        if (!isLoading && !isChanged) {
            form.setFieldsValue(initialValues);
        }
    }, [initialValues]);

    const onFieldsChange = (changedFields: FieldData[]): void => {
        const fieldNameChange =
            changedFields && changedFields[0] && (changedFields[0].name as IAntDFormChangeFieldName);
        if (fieldNameChange) {
            apiMessagesDeleteMessageByFieldName(fieldNameChange);
        }
        setChanged(true);
        setValid(!form.getFieldsError().filter(({ errors }) => errors.length).length);
    };

    const onFinishChange = async (fields: IFormFields): Promise<void> => {
        setSending(true);
        setValid(false);
        const { isError, errors } = await patchPassword(fields as IUser);
        if (isError) {
            apiMessagesSaveMessages(errors);
            form.validateFields();
        } else {
            form.resetFields();
            addNotification({
                type: 'info',
                message: 'Your password has been successfully changed!',
                duration: 5,
            });
        }
        setSending(false);
    };

    const currentPasswordTitle = tF('Current password');
    const newPasswordTitle = tF('New password');
    const confirmPasswordTitle = tF('Confirm password');

    return (
        <Catcher>
            <CardLayout
                idSpinners={['GET_USER', 'PATCH_USER', 'POST_USER', 'PATCH_PASSWORD']}
                title={'Change password'}
            >
                <Form
                    form={form}
                    name="passwordChange"
                    initialValues={initialValues}
                    onFieldsChange={onFieldsChange}
                    onFinish={onFinishChange}
                    scrollToFirstError={true}
                    layout={'vertical'}
                    className={'formStyle'}
                    disabled={isImpersonating}
                >
                    <Form.Item
                        hasFeedback
                        label={currentPasswordTitle}
                        name="currentPassword"
                        rules={validateAPIAndPassword}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Divider />
                    {/*<Form.Item name="passwordNew" label={newPasswordTitle} rules={validateAPIAndPassword} hasFeedback>*/}
                    <Form.Item
                        dependencies={['currentPassword']}
                        hasFeedback
                        label={newPasswordTitle}
                        name="passwordNew"
                        rules={validateNewPassword}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        dependencies={['passwordNew']}
                        hasFeedback
                        label={confirmPasswordTitle}
                        name="passwordConfirmation"
                        rules={validatePasswordConfirmation}
                    >
                        <Input.Password />
                    </Form.Item>
                    <div className={Styles.cardToolBar}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            icon={<SaveOutlined />}
                            className={cx(Styles.mainButton, {
                                [Styles.buttonsDisable]: !isValid,
                            })}
                            disabled={!isValid}
                            loading={isSending}
                        >
                            {tB('Update')}
                        </Button>
                    </div>
                </Form>
            </CardLayout>
        </Catcher>
    );
});
