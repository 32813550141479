import React from 'react';
import { observer } from 'mobx-react';
import { Catcher } from '../';
import { IApiListMessage } from '../../interfaces';
import { apiMessagesGetField, apiMessagesGetListMessage } from '../../middleware';
import Styles from './m_styles.less';

interface IFieldAlert {
    fieldName: string;
    isFieldNameShown?: boolean;
}

export const FieldAlert: React.FC<IFieldAlert> = observer(({ fieldName, isFieldNameShown = true }: IFieldAlert) => {
    const message = apiMessagesGetField(fieldName);
    const alertMessage = isFieldNameShown ? `${fieldName}: ${message}` : `${message}`;
    return <Catcher>{message.length ? <div className={Styles.alertMessage}>{alertMessage}</div> : null}</Catcher>;
});

export const ListFieldAlert: React.FC<IApiListMessage> = ({ fieldName, idsList, listName }: IApiListMessage) => {
    const message = apiMessagesGetListMessage({ listName, idsList, fieldName });
    return <Catcher>{message ? <div className={Styles.alertMessage}>{message}</div> : null}</Catcher>;
};
