import React, { MouseEvent, ReactNode, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Tooltip } from 'antd';
import { columnOption } from '../columnOption';
import cx from 'classnames';
import { IGetApiType } from '../../../REST';
import { IStatItem } from '../../../interfaces';
import {
    useCompanyInfo,
    useCompanyStatistics,
    useCurrencyShortCodeById,
    useDashboardActivityRollIsNotBigQuery,
    useDashboardActivityUsage,
    useProfile,
    useProfileFeatureFlagsIsBigQuery,
    useRouterStore,
    useUiLanguage,
} from '../../../hooks';
import { cleanCompanyStore, getCompanyById, getCompanyStatisticsById } from '../../../middleware';
import { byteRange1000, minutesRange, stringCount } from '../../../instruments';
import { ITrPage, tF, tI, tP } from '../../../translate';
import { Catcher, IShowMoneyItem, ShowMoneyList } from '../../../Components';
import { DashboardInfoCard } from './../Components';
import Styles from '../m_dashboarStyles.less';

interface IMyCompanyLink {
    companyId?: string;
}

interface IDashboardHeader extends IMyCompanyLink {
    idSpinners?: IGetApiType[];
    boxTitlePrefix?: ITrPage;
}

const MyCompanyLink: React.FC<IMyCompanyLink> = observer(({ companyId }) => {
    const languageId = useUiLanguage();
    const routerStore = useRouterStore();

    const clickLinkMore = (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>): void => {
        event.preventDefault();
        if (companyId) {
            routerStore.goTo('Company', { params: { id: companyId } });
        }
    };
    return (
        <Catcher>
            {companyId ? (
                <a onClick={clickLinkMore} href={`/companies/${companyId}`}>
                    {tI('More...')}
                </a>
            ) : null}
        </Catcher>
    );
});

export const DashboardHeader: React.FC<IDashboardHeader> = observer(
    ({ companyId, idSpinners = ['GET_ACTIVITY_ROLL', 'GET_COMPANY_STATISTICS'], boxTitlePrefix }) => {
        const languageId = useUiLanguage();
        const activityIsNotBigQuery = useDashboardActivityRollIsNotBigQuery();
        const activityIsBigQuery = useDashboardActivityUsage();
        const isBigQuery = useProfileFeatureFlagsIsBigQuery();
        const { currency = '€', currencyId } = useCompanyInfo();
        const companyStatistics = useCompanyStatistics();
        const balanceDifference = companyStatistics?.balanceDifference ? +companyStatistics?.balanceDifference : 0;
        const balance = companyStatistics.balance ? +companyStatistics?.balance : 0;
        const shortCode = (currencyId && useCurrencyShortCodeById(currencyId)) || currency;
        const { isCompanyTypeIdMNOHost } = useProfile();

        useEffect(() => {
            if (companyId) {
                getCompanyById(companyId);
                if (!isCompanyTypeIdMNOHost) {
                    getCompanyStatisticsById(companyId);
                }
            }

            return (): void => {
                cleanCompanyStore();
            };
        }, [companyId]);

        const textTitlePrefix = boxTitlePrefix ? tP(boxTitlePrefix) : '';
        const voice = isBigQuery ? activityIsBigQuery.voice : activityIsNotBigQuery.voice;
        const voiceRatio = isBigQuery ? activityIsBigQuery.voiceRatio : activityIsNotBigQuery.voiceRatio;

        const sms = isBigQuery ? activityIsBigQuery.sms : activityIsNotBigQuery.sms;
        const smsRatio = isBigQuery ? activityIsBigQuery.smsRatio : activityIsNotBigQuery.smsRatio;

        const originalUnits = isBigQuery ? activityIsBigQuery.originalUnits : activityIsNotBigQuery.originalUnits;
        const originalUnitsRatio = isBigQuery
            ? activityIsBigQuery.originalUnitsRatio
            : activityIsNotBigQuery.originalUnitsRatio;

        const cardsCount = isBigQuery ? activityIsBigQuery.cardsCount : activityIsNotBigQuery.uniqCards;
        const cardsRatio = isBigQuery ? activityIsBigQuery.cardsRatio : activityIsNotBigQuery.uniqCardsRatio;

        let cost: ReactNode;
        const costRatio = isBigQuery ? activityIsBigQuery.costRatio : activityIsNotBigQuery.costRatio;

        const CostShow = () => {
            const items = activityIsBigQuery.costs.map(
                (i: IStatItem): IShowMoneyItem => ({ value: i.cost, symbol: i.currencySymbol }),
            );
            let stylesFont: string;
            switch (items.length) {
                case 1:
                case 2:
                case 4: {
                    stylesFont = Styles.font_1_8;
                    break;
                }
                case 3:
                case 5:
                case 6: {
                    stylesFont = Styles.font_1_2;
                    break;
                }
                default: {
                    stylesFont = Styles.font_1;
                }
            }
            return (
                <div className={cx(Styles.valueColumns, stylesFont)}>
                    <ShowMoneyList items={items} classNameItem={Styles.valueColumnsItem} />
                </div>
            );
        };

        if (isBigQuery) {
            cost = <CostShow />;
        } else {
            cost = `${shortCode} ${activityIsNotBigQuery.cost ? activityIsNotBigQuery.cost.toFixed(2) : 0}`;
        }

        return (
            <Catcher>
                <div className={Styles.cardsPanel}>
                    <div className={cx(Styles.cardsRow, Styles.activityUsageGrid)}>
                        {!isCompanyTypeIdMNOHost && (
                            <DashboardInfoCard
                                idSpinners={idSpinners}
                                isBigQuery
                                radio={costRatio}
                                title={`${textTitlePrefix}${textTitlePrefix ? ' ' + tI('by Cost') : tF('Cost')}`}
                                value={cost}
                            />
                        )}
                        {!isCompanyTypeIdMNOHost && (
                            <DashboardInfoCard
                                idSpinners={idSpinners}
                                isBigQuery={false}
                                more={<MyCompanyLink companyId={companyId} />}
                                radio={balanceDifference}
                                title={tF('Company Balance')}
                                value={`${shortCode} ${balance ? (+balance).toFixed(2) : 0}`}
                                layoutOptions={columnOption.dashboardInfoCardBig}
                            />
                        )}
                        <DashboardInfoCard
                            idSpinners={idSpinners}
                            isBigQuery
                            radio={cardsRatio}
                            title={`${textTitlePrefix}${
                                textTitlePrefix ? ' ' + tI('by Active Cards') : tF('Active Cards')
                            }`}
                            value={`${cardsCount ? cardsCount.toFixed(0) : 0}`}
                        />
                        <DashboardInfoCard
                            idSpinners={idSpinners}
                            isBigQuery
                            radio={originalUnitsRatio}
                            title={`${textTitlePrefix}${
                                textTitlePrefix ? ' ' + tI('by Data Usage') : tF('Data Usage')
                            }`}
                            value={
                                <Tooltip title={`${stringCount(originalUnits)} byte`}>
                                    <span>{originalUnits ? byteRange1000(originalUnits, 2) : '0 byte'}</span>
                                </Tooltip>
                            }
                        />
                        <DashboardInfoCard
                            idSpinners={idSpinners}
                            isBigQuery
                            radio={smsRatio}
                            title={`${textTitlePrefix}${textTitlePrefix ? ' ' + tI('by Sms Usage') : tF('Sms Usage')}`}
                            value={`${stringCount(sms)} sms`}
                        />
                        <DashboardInfoCard
                            idSpinners={idSpinners}
                            isBigQuery
                            radio={voiceRatio}
                            title={`${textTitlePrefix}${
                                textTitlePrefix ? ' ' + tI('by Voice Usage') : tF('Voice Usage')
                            }`}
                            value={
                                <Tooltip title={`${stringCount(voice)} sec`}>
                                    <span>{voice ? minutesRange(voice) : '0 sec'}</span>
                                </Tooltip>
                            }
                        />
                    </div>
                </div>
            </Catcher>
        );
    },
);
