import React from 'react';
import { ActivityExpandedRowLine, ActivityLogFieldValue, ActivityLogLists } from './';
import { ActivityLogSubList, IActivityLogItem } from '../../../interfaces';
import Styles from '../../styles/m_viewStyles.less';

export function ActivityLogViewInfo({ log }: { log: IActivityLogItem }) {
    const { meta, entity, userData } = log;

    return (
        <div className={Styles.expandedRow}>
            {Object.entries(meta).map(([metaKey, metaValue]) => {
                if (metaKey === ActivityLogSubList.LISTS) {
                    return <ActivityLogLists key={metaKey} lists={metaValue} />;
                }
                let title = metaKey;
                let valueName: string | undefined;
                if (metaKey === 'userId') {
                    title = 'User';
                    valueName = [userData?.firstName, userData?.lastName, userData?.email].join(' ').trim() || metaKey;
                } else if (metaKey === 'companyId') {
                    title = 'User Company';
                    valueName = userData?.companyName || metaKey;
                }

                return (
                    <ActivityExpandedRowLine key={metaKey} title={title}>
                        <ActivityLogFieldValue prop={metaKey} value={metaValue} valueName={valueName} entity={entity} />
                    </ActivityExpandedRowLine>
                );
            })}
        </div>
    );
}
