import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Select, Tooltip } from 'antd';
import cx from 'classnames';
import { appConfig } from '../../../settings';
import { IGetApiResponse, IGetApiType } from '../../../REST';
import { ISelect } from '../../../interfaces';
import {
    useOperatorInteractionsFilesFilterParams,
    useOperatorInteractionsGetewaysSelect,
    useUiIsSpinnerFound,
} from '../../../hooks';
import {
    cleanOperatorInteractionsStore,
    getOperatorInteractionsFilesListRelationSelect,
    isOperatorInteractionsFilesIsProcessing,
    onDownloadNotification,
    setForcedFilesListUpdateTime,
} from '../../../middleware';
import { tCustom, tI } from '../../../translate';
import { Catcher, GoPageButton, ListWrapper, UploadFile } from '../../../Components';
import { FilesTable } from './Components';
import Styles from '../../styles/m_viewStyles.less';

const { Option } = Select;

const LeftButtons: React.FC = () => {
    return (
        <GoPageButton
            componentName={'OperatorInteractionsResponsesList'}
            goToOptions={{ queryParams: { commandId: 'order_detail' } }}
            href={'/operator_interactions/responses?commandId=order_detail'}
            title={'Responses List'}
        />
    );
};
const RightButtons: React.FC = observer(() => {
    const [gateway, setGateway] = useState<string | undefined>();
    const idSpinnersUpload: IGetApiType[] = [
        'POST_OPERATOR_INTERACTIONS_FILES',
        'GET_OPERATOR_INTERACTIONS_GATEWAYS_SELECT',
    ];
    const isLoadingUpload = useUiIsSpinnerFound(idSpinnersUpload);
    const gateways = useOperatorInteractionsGetewaysSelect();

    const isDisableUpload = isLoadingUpload || !gateway;

    const onChangeSelect = (valie: string) => {
        setGateway(valie);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (isOperatorInteractionsFilesIsProcessing()) {
                setForcedFilesListUpdateTime();
            }
        }, appConfig.updateViewInterval);
        return (): void => clearInterval(interval);
    }, []);

    useEffect(() => {
        setGateway(gateways[0]?.id);
    }, [gateways]);

    const setForcedUpdate = async (): Promise<void> => {
        setForcedFilesListUpdateTime();
    };
    const onDownload = async (resp: IGetApiResponse): Promise<void> => {
        await onDownloadNotification(resp, setForcedUpdate);
    };

    return (
        <div className={Styles.uploadFileRadioPanel}>
            <Tooltip
                title={gateway ? tCustom('Upload CSV file for gateway', { gateway: gateway }) : tI('Select gateway')}
                placement={gateway ? 'topRight' : 'top'}
            >
                <Select
                    allowClear={false}
                    className={Styles.uploadFileRadioSelectorType}
                    disabled={isLoadingUpload || !gateways.length}
                    loading={isLoadingUpload}
                    onChange={onChangeSelect}
                    placeholder={'Gateway'}
                    showSearch
                    value={gateway}
                >
                    {gateways.map(
                        (s: ISelect): JSX.Element => (
                            <Option key={s.id} value={s.id}>
                                <div>{s.name}</div>
                            </Option>
                        ),
                    )}
                </Select>
                <UploadFile
                    apiType={'POST_OPERATOR_INTERACTIONS_FILES'}
                    body={{ gateway: gateway }}
                    className={cx(Styles.uploadFileButton, Styles.uploadFileRadioButtonRight)}
                    disabled={isDisableUpload}
                    fileType={'text'}
                    idSpinners={idSpinnersUpload}
                    onDownload={onDownload}
                    text={'Upload'}
                />
            </Tooltip>
        </div>
    );
});

export const OperatorInteractionsFilesList: React.FC = observer(() => {
    const idSpinners: IGetApiType[] = ['GET_OPERATOR_INTERACTIONS_FILES'];
    const filterParams = useOperatorInteractionsFilesFilterParams();

    return (
        <Catcher>
            <ListWrapper
                cleanStore={cleanOperatorInteractionsStore}
                filterParams={filterParams}
                getRelationSelect={getOperatorInteractionsFilesListRelationSelect}
                idSpinners={idSpinners}
                isReloadButton={true}
                LeftButtons={LeftButtons}
                RightButtons={RightButtons}
            >
                <FilesTable />
            </ListWrapper>
        </Catcher>
    );
});
