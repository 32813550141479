import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { BankOutlined, SettingOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
import { IFieldLayout, IGetApiType } from '../../../interfaces';
import { useProfile, useRouterStore, useUser } from '../../../hooks';
import { abortSelectedFetch, addNotification, cleanUserStore, getUserById } from '../../../middleware';
import { fullName } from '../../../instruments';
import { tI, tNM } from '../../../translate';
import { CardMainLayout, Catcher, FieldInfoAdvanced, LinkComponent, cardLayoutOptions } from '../../../Components';
import Styles from '../../styles/m_itemCardStyles.less';

export const RootInfo: React.FC = observer(() => {
    const routerStore = useRouterStore();
    const layout: IFieldLayout = {
        labelCol: {
            flex: '0 1 150px',
        },
        valCol: {
            flex: '1 1 270px',
        },
    };
    const usedApiTypes: IGetApiType[] = ['GET_COMPANIES_SELECT', 'GET_USER', 'GET_USER_TYPE_SELECT'];
    const myId = useProfile().id;

    const goOut = (): void => {
        abortSelectedFetch(usedApiTypes);
        cleanUserStore();
    };

    const goToDashboard = (): void => {
        routerStore.goTo('Dashboard');
        goOut();
    };

    const _getUserById = async (id: string): Promise<void> => {
        const isGetUser = await getUserById(id);
        if (!isGetUser) {
            addNotification({
                type: 'error',
                message: tNM('Not Found'),
                description: `${tI('User id')} - "${id}" ${tI('not found')}`,
                duration: 5,
                isTranslated: true,
            });
            goToDashboard();
        }
    };

    useEffect(() => {
        if (myId) {
            _getUserById(myId);
        }
    }, [myId]);

    const { company, companyId, firstName = '', func, lastName = '', userType, role } = useUser();

    return (
        <Catcher>
            <CardMainLayout
                columnOption={cardLayoutOptions.cardInfoEdit}
                idSpinners={['GET_USER']}
                name={fullName(firstName, lastName)}
                icon={<UserOutlined />}
            >
                <FieldInfoAdvanced
                    layout={layout}
                    icon={<BankOutlined className={Styles.labelIcon} />}
                    title={'Company'}
                >
                    <LinkComponent currentListComponent={'CompaniesList'} id={companyId} name={company} />
                </FieldInfoAdvanced>
                <FieldInfoAdvanced
                    layout={layout}
                    icon={<SettingOutlined className={Styles.labelIcon} />}
                    title={'Function'}
                >
                    {func}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced
                    layout={layout}
                    icon={<TeamOutlined className={Styles.labelIcon} />}
                    title={'User Type'}
                >
                    {userType}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced layout={layout} icon={<TeamOutlined className={Styles.labelIcon} />} title={'Role'}>
                    {role}
                </FieldInfoAdvanced>
            </CardMainLayout>
        </Catcher>
    );
});
