import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { useCompanyInfo } from './useCompanyStores';
import { IRoleRight, appConfig, isCompanyTypeCustomerPrivacyValid, isRoleRight } from '../../settings';
import { IAboutInfo, IFeatureFlags, ISelect, ISelectList, IUser, IUserAlarms } from '../../interfaces';

export function useProfile(): IUser {
    return useContext(MobXProviderContext).RootStore.profileStore.profile;
}

export function useProfileAbout(): IAboutInfo {
    return useContext(MobXProviderContext).RootStore.profileStore.about;
}

export function useProfileIsLoading(): boolean {
    return useContext(MobXProviderContext).RootStore.profileStore.isSpinning;
}

export function useProfileIsRightRole(right?: Array<IRoleRight>): boolean {
    return isRoleRight(right, useContext(MobXProviderContext).RootStore.profileStore.profile.role);
}

export function useProfileCustomLogo(isDark?: boolean): string {
    const { actualDarkLogoUrl, actualLogoUrl } = useContext(MobXProviderContext).RootStore.profileStore.profile;
    return isDark ? actualDarkLogoUrl : actualLogoUrl;
}

export function useProfileIsRight(right: IRoleRight): boolean {
    const { role } = useContext(MobXProviderContext).RootStore.profileStore.profile;
    return isRoleRight([right], role);
}

export function useProfileAlarms(): IUserAlarms {
    return useContext(MobXProviderContext).RootStore.profileStore.alarms;
}
function useIsCompanyTypeCustomerPrivacyValid(): boolean {
    const { companyTypeId } = useContext(MobXProviderContext).RootStore.profileStore.profile;
    return isCompanyTypeCustomerPrivacyValid(companyTypeId);
}

export function useIsMyCompany(): boolean {
    const { companyId } = useProfile();
    const { id } = useCompanyInfo();

    return companyId === id;
}

export function useCheckCustomerPrivacy(): boolean {
    const isLord = useProfileIsRight('LORD');
    const isAdmin = useProfileIsRight('ADMIN');
    const isCompanyTypeValid = useIsCompanyTypeCustomerPrivacyValid();
    const isMyCompany = useIsMyCompany();

    return (isCompanyTypeValid && isAdmin && isMyCompany) || isLord;
}

export function useAlarmConfigCurrency(): ISelectList {
    return appConfig.alarmConfigCurrency.map((i: ISelect) => {
        return i;
    });
}

export function useCheckAlarmControlToggle(list: string[]): boolean {
    const alarmConfig = useContext(MobXProviderContext).RootStore.profileStore.alarms;
    let isToggled = false;

    list.forEach((value) => {
        if (alarmConfig[value]) {
            isToggled = true;
        }
    });

    return isToggled;
}

export function useProfileIsGeotrackingEnabled(): boolean {
    const isLord = useProfileIsRight('LORD');
    const { geotrackingEnabled } = useContext(MobXProviderContext).RootStore.profileStore.profile;
    return geotrackingEnabled || isLord;
}
export function useProfileFeatureFlags(): IFeatureFlags {
    return useContext(MobXProviderContext).RootStore.profileStore.profileFeatureFlags;
}

export function useProfileFeatureFlagsIsBigQuery(): boolean {
    const featureFlags = useProfileFeatureFlags();
    return !!featureFlags.useBigquery;
}
