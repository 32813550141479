import React from 'react';
import { Chart } from 'react-google-charts';
import { observer } from 'mobx-react';
import { Col } from 'antd';
import { Catcher, LoadingApiByIds } from '../../';
import { columnOption } from '../columnOption';
import cx from 'classnames';
import { useChartsSettings, useDashboardFinancesRollCards } from '../../../hooks';
import { tF } from '../../../translate';
import Styles from '../m_dashboarStyles.less';

export const FinancesActiveCard: React.FC = observer(() => {
    const { active, inactive, total, pieActive } = useDashboardFinancesRollCards();
    const sourceData = [
        [tF('Cards'), 'val'],
        [tF('Inactive'), inactive],
        [tF('Active'), pieActive],
    ];

    const { backgroundColor, textColor, chartBackground, animation } = useChartsSettings();

    return (
        <Catcher>
            <Col className={Styles.cardPlace} {...columnOption.financesActiveCard}>
                <div className={cx(Styles.infoCard, Styles.financesCard)}>
                    <LoadingApiByIds idSpinners={['GET_FINANCE_ROLL']} />
                    <div className={Styles.info}>
                        <div className={Styles.title}>{`${total} ${tF('SIMs')}`}</div>
                        {total ? (
                            <div className={Styles.activeCardValuesPlace}>
                                <div className={Styles.value}>
                                    <div className={Styles.valueText}>{tF('Active')}:</div>
                                    <div className={cx(Styles.valueValue, Styles.green)}>{active}</div>
                                </div>
                                <div className={Styles.value}>
                                    <div className={Styles.valueText}>{tF('Inactive')}:</div>
                                    <div className={cx(Styles.valueValue, Styles.yellow)}>{total - active}</div>
                                </div>
                            </div>
                        ) : null}
                        <div className={Styles.graph}>
                            {inactive || pieActive ? (
                                <Chart
                                    chartType={'PieChart'}
                                    data={sourceData}
                                    height={'100%'}
                                    width={'100%'}
                                    options={{
                                        backgroundColor: chartBackground,
                                        colors: ['#ffa500', '#008000'],
                                        legend: {
                                            position: 'none',
                                            textStyle: { color: textColor },
                                        },
                                        pieSliceTextStyle: {
                                            color: backgroundColor,
                                        },
                                        pieSliceBorderColor: backgroundColor,
                                        tooltip: { trigger: 'selection' },
                                        animation,
                                    }}
                                />
                            ) : null}
                        </div>
                    </div>
                </div>
            </Col>
        </Catcher>
    );
});
