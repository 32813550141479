import React from 'react';
import { observer } from 'mobx-react';
import { Progress, Table } from 'antd';
import { Catcher, LinkComponent, LoadingApiByIds } from '../../';
import { PackageCountDown } from './';
import { IPackageSoldInfo, IPackagesTable } from '../../../interfaces';
import { usePackagesSold } from '../../../hooks';
import { tF } from '../../../translate';

export const TableForSoldPackages: React.FC<IPackagesTable> = observer(({}: IPackagesTable) => {
    const data = usePackagesSold();
    const tableColumns = [
        {
            title: tF('Package Owner'),
            dataIndex: 'companyLink',
        },
        {
            title: tF('Package Name'),
            dataIndex: 'packageLink',
        },
        {
            title: tF('Data left'),
            dataIndex: 'dataVolumeNameLeft',
        },
        {
            title: tF('Package Size'),
            dataIndex: 'dataVolumeName',
        },
        {
            title: tF('Expires in'),
            dataIndex: 'isExpiredName',
        },
        {
            title: tF('Zone'),
            dataIndex: 'zoneLink',
        },
        {
            title: tF('Assigned Cardset'),
            dataIndex: 'cardSetLink',
        },
        {
            title: tF('Credit Remains'),
            dataIndex: 'creditRemainsName',
        },
        {
            title: tF('Auto renewal'),
            dataIndex: 'renewalName',
        },
    ];

    const tableData = data?.map((p: IPackageSoldInfo) => {
        const { dataVolumeNameLeft, isExpired, bytesLeftPercentage, expiresDate, createdAt, renewal, ...rows } = p;

        return {
            key: p.id,
            ...rows,
            packageLink: <LinkComponent id={p.packageId} name={p.packageName} currentListComponent={'PackagesSell'} />,
            companyLink: <LinkComponent currentListComponent={'CompaniesList'} id={p.companyId} name={p.companyName} />,
            zoneLink: <LinkComponent currentListComponent={'ZonesList'} id={p.zoneId} name={p.zoneName} />,
            cardSetLink: <LinkComponent currentListComponent={'CardSetsList'} id={p.cardSetId} name={p.cardSetName} />,
            dataVolumeNameLeft: (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column-reverse',
                    }}
                >
                    <Progress
                        percent={bytesLeftPercentage}
                        showInfo={false}
                        trailColor={'#ff4d4f'}
                        strokeColor={'#1890ff'}
                    />
                    <span title={dataVolumeNameLeft}>{dataVolumeNameLeft}</span>
                </div>
            ),
            isExpiredName: (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column-reverse',
                        width: '150px',
                    }}
                >
                    <PackageCountDown
                        expiresDate={expiresDate}
                        createdAt={createdAt}
                        isExpired={isExpired}
                        isRenewed={renewal}
                    />
                </div>
            ),
        };
    });

    return (
        <Catcher>
            <div>
                <LoadingApiByIds idSpinners={['GET_PACKAGES_SOLD']} />
                <Table
                    className={'table-theme'}
                    columns={tableColumns}
                    dataSource={tableData}
                    pagination={false}
                    size={'middle'}
                />
            </div>
        </Catcher>
    );
});
