import React, { ReactNode } from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import cx from 'classnames';
import { IEsNotificationsItem } from '../../../interfaces';
import { useEsNotificationsTable, useUiSearch } from '../../../hooks';
import { dateText2Utc, lightText } from '../../../instruments';
import { tF } from '../../../translate';
import { Catcher } from '../../Catcher';
import Styles from '../../styles/m_viewStyles.less';

interface IDataType extends IEsNotificationsItem {
    key: string;
    idLight: ReactNode;
    eidLight: ReactNode;
    iccIdLight: ReactNode;
    imeiLight: ReactNode;
    notificationPointIdLight: ReactNode;
    notificationPointStatusLight: ReactNode;
}

export const ESPlusNotificationTable: React.FC = observer(() => {
    const search = useUiSearch();
    const list = useEsNotificationsTable();
    const tableColumns: ColumnsType<IDataType> = [
        {
            title: tF('ID'),
            dataIndex: 'idLight',
            className: Styles.columnRight,
        },
        {
            title: tF('EID'),
            dataIndex: 'eidLight',
        },
        {
            title: tF('ICCID'),
            dataIndex: 'iccIdLight',
        },
        {
            title: tF('IMEI'),
            dataIndex: 'imeiLight',
        },
        {
            title: tF('Point'),
            dataIndex: 'notificationPointIdLight',
        },
        {
            title: tF('Point Status'),
            dataIndex: 'notificationPointStatusLight',
        },
        {
            title: tF('Timestamp'),
            dataIndex: 'timestamp',
        },
    ];
    const tableData: IDataType[] = list.map((i: IEsNotificationsItem) => {
        const notificationPointDescriptions = getNotificationPointDescriptions(i.notificationPointId);
        return {
            key: i.id,
            ...i,
            idLight: lightText(i.id, search),
            eidLight: lightText(i.eid, search),
            iccIdLight: lightText(i.iccId, search),
            imeiLight: lightText(i.imei, search),
            notificationPointIdLight: lightText(notificationPointDescriptions, search),
            notificationPointStatusLight: lightText(i.notificationPointStatus, search),
            createdAt: dateText2Utc(i.createdAt),
            updatedAt: dateText2Utc(i.updatedAt),
            timestamp: dateText2Utc(i.timestamp),
        };
    });

    const expandedRowRender = (record: IDataType): JSX.Element => {
        return (
            <div className={Styles.expandedRow}>
                <div className={Styles.expandedRowLine}>
                    <div className={cx(Styles.expandedRowLineLabel, Styles.expandedRowLineLabel_300)}>{'TAC'}</div>
                    <div className={Styles.expandedRowLineValue}>{record.tac}</div>
                </div>
                <div className={Styles.expandedRowLine}>
                    <div className={cx(Styles.expandedRowLineLabel, Styles.expandedRowLineLabel_300)}>
                        {tF('function Call Identifier')}
                    </div>
                    <div className={Styles.expandedRowLineValue}>{record.functionCallIdentifier}</div>
                </div>
                <div className={Styles.expandedRowLine}>
                    <div className={cx(Styles.expandedRowLineLabel, Styles.expandedRowLineLabel_300)}>
                        {tF('function Requester Identifier')}
                    </div>
                    <div className={Styles.expandedRowLineValue}>{record.functionRequesterIdentifier}</div>
                </div>
                <div className={Styles.expandedRowLine}>
                    <div className={cx(Styles.expandedRowLineLabel, Styles.expandedRowLineLabel_300)}>
                        {tF('Profile Type')}
                    </div>
                    <div className={Styles.expandedRowLineValue}>{record.profileType}</div>
                </div>
                <div className={Styles.expandedRowLine}>
                    <div className={cx(Styles.expandedRowLineLabel, Styles.expandedRowLineLabel_300)}>
                        {tF('Result Data')}
                    </div>
                    <div className={Styles.expandedRowLineValue}>{record.resultData}</div>
                </div>
                <div className={Styles.expandedRowLine}>
                    <div className={cx(Styles.expandedRowLineLabel, Styles.expandedRowLineLabel_300)}>
                        {tF('Updated at')}
                    </div>
                    <div className={Styles.expandedRowLineValue}>{record.updatedAt}</div>
                </div>
                <div className={Styles.expandedRowLine}>
                    <div className={cx(Styles.expandedRowLineLabel, Styles.expandedRowLineLabel_300)}>
                        {tF('Created at')}
                    </div>
                    <div className={Styles.expandedRowLineValue}>{record.createdAt}</div>
                </div>
            </div>
        );
    };

    const expandable = { expandedRowRender };

    return (
        <Catcher>
            <Table
                className={'table-theme'}
                columns={tableColumns}
                dataSource={tableData}
                expandable={expandable}
                pagination={false}
                size={'middle'}
            />
        </Catcher>
    );
});

type NotificationPointDescriptions = {
    id: number;
    description: string;
};
const notificationPointDescriptions: Array<NotificationPointDescriptions> = [
    { id: 3, description: 'BPP DOWNLOAD' },
    { id: 4, description: 'BPP INSTALLATION' },
    { id: 100, description: 'BPP Enabled' },
    { id: 101, description: 'BPP Disabled' },
    { id: 102, description: 'BPP Deleted' },
];

const getNotificationPointDescriptions = (id: number | undefined): string => {
    const description = notificationPointDescriptions.find(
        (i: NotificationPointDescriptions) => i.id === id,
    )?.description;
    return `${id}${description ? ': ' + description : ''}`;
};
