import React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import { IOrdersListItem } from '../../../interfaces';
import { useOrdersTable, useUiIsLoading, useUiSearch } from '../../../hooks';
import { lightText, stringMoney } from '../../../instruments';
import { tF, tI } from '../../../translate';
import { Catcher, LinkComponent, TableItemToolbar, columnToolbar } from '../../../Components';
import '../../../customStyle/table.less';
import Styles from '../../styles/m_viewStyles.less';

export const OrdersTableList: React.FC = observer(() => {
    const isLoading = useUiIsLoading();
    const list = useOrdersTable();
    const search = useUiSearch();

    const tableColumns = [
        {
            title: tF('ID'),
            dataIndex: 'idLink',
        },
        {
            title: tF('Date'),
            dataIndex: 'dateLink',
        },
        {
            title: tF('Company'),
            dataIndex: 'companyLink',
        },
        {
            title: tF('User'),
            dataIndex: 'userLink',
        },
        {
            title: tF('Payment Method'),
            dataIndex: 'paymentType',
        },
        {
            title: tF('Description'),
            dataIndex: 'descriptionLink',
        },
        {
            title: tF('Cost'),
            dataIndex: 'costText',
            className: Styles.columnRight,
        },
        {
            title: tF('Status'),
            dataIndex: 'status',
            className: Styles.columnRight,
        },
        columnToolbar,
    ];
    const tableData = list.map((o: IOrdersListItem) => {
        return {
            key: o.id,
            ...o,
            idLink: <LinkComponent currentListComponent={'OrdersList'} id={o.id} />,
            dateLink: <LinkComponent currentListComponent={'OrdersList'} id={o.id} name={o.updatedAt} />,
            descriptionLink: (
                <LinkComponent currentListComponent={'OrdersList'} id={o.id} isLight name={o.description} />
            ),
            companyLink: (
                <LinkComponent currentListComponent={'CompaniesList'} id={o.companyId} isLight name={o.company} />
            ),
            userLink: <LinkComponent currentListComponent={'UsersList'} id={o.userId} isLight name={o.user} />,
            paymentType: lightText(o.paymentType, search),
            status: lightText(o.status, search),
            costText: stringMoney(o.cost, o.currency),
            toolbar: <TableItemToolbar id={o.id} itemName={`"${o.id}" ${tI('order')}`} />,
        };
    });

    return (
        <Catcher>
            <div className={Styles.tablePlace}>
                <Table
                    className={'table-theme'}
                    columns={tableColumns}
                    dataSource={tableData}
                    loading={isLoading}
                    pagination={false}
                    size={'middle'}
                />
            </div>
        </Catcher>
    );
});
