import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { IFilterParams, IOperatorInteractionsFile, IOperatorInteractionsResponse, ISelectList } from '../../interfaces';

export function useOperatorInteractionsFilesTable(): IOperatorInteractionsFile[] {
    return useContext(MobXProviderContext).RootStore.operatorInteractionsStore.filesList;
}

export function useOperatorInteractionsFilesFilterParams(): IFilterParams {
    return useContext(MobXProviderContext).RootStore.operatorInteractionsStore.filterFilesListParams;
}

export function useOperatorInteractionsResponsesTable(): IOperatorInteractionsResponse[] {
    return useContext(MobXProviderContext).RootStore.operatorInteractionsStore.responsesList;
}

export function useOperatorInteractionsResponsesFilterParams(): IFilterParams {
    return useContext(MobXProviderContext).RootStore.operatorInteractionsStore.filterResponsesListParams;
}
export function useOperatorInteractionsGetewaysSelect(): ISelectList {
    return useContext(MobXProviderContext).RootStore.operatorInteractionsStore.getewaysSelect;
}
export function useOperatorInteractionsCommandsSelect(): ISelectList {
    return useContext(MobXProviderContext).RootStore.operatorInteractionsStore.commandsSelect;
}

export function useOperatorInteractionsFilesSelect(): ISelectList {
    return useContext(MobXProviderContext).RootStore.operatorInteractionsStore.filesSelect;
}

export function useOperatorInteractionsCommandDefault(): string | undefined {
    return useContext(MobXProviderContext).RootStore.operatorInteractionsStore.commandDefault;
}

export function useOperatorInteractionsResponse(): IOperatorInteractionsResponse {
    return useContext(MobXProviderContext).RootStore.operatorInteractionsStore.responseItem;
}
