/* eslint-disable */
import { CardStore } from '../stores/card';
import {
    cleanApiMessages,
    getCallDataRecordsLast5,
    getCardSetsSelect,
    getIsFindSpinners,
    getProfileIsCompanyTypeIdMNOHost,
} from './';
import dayjs from 'dayjs';
import { appConfig } from '../settings';
import { IApiParams, IGetApiResponse, IRequestAPIBody, apiManager } from '../REST';
import {
    ICardBulkDeletion,
    ICardBulkDeletionBody,
    ICardBulkDeletionInfo,
    ICardDeviceFile,
    ICardDeviceFilesGetApi,
    ICardGeolocationInfo,
    ICardIncludeAPI,
    ICardIncludeCardDeviceAPI,
    ICardIncludeCountryAPI,
    ICardIncludeOperatorAPI,
    ICardIncludeSubscriberAPI,
    ICardIncludeTypeDeviceAPI,
    ICardInfo,
    ICardItemAPI,
    ICardStatisticsApi,
    ICardSubscriber,
    IChangeStatus,
    IDeviceAttribute,
    IDeviceHistory,
    IDeviceInfo,
    IItemAPI,
    IPatchItem,
    IRequestRelationshipMainItem,
    IUpdateSubscriberStatusAPI,
} from '../interfaces';
import { IMapField, byteConverter, mapApi2UiField, mapUi2ApiField } from '../instruments';

const setRelationCardGeolocationInfo = (data?: ICardItemAPI, included?: ICardIncludeAPI[]): void => {
    const geolocationList = included?.filter((i: ICardIncludeAPI) => i.type === 'device_location') as ICardIncludeAPI[];
    const geolocationId = data?.relationships?.device_location?.data?.id;
    const geolocationFound = geolocationList?.find((g: IRequestRelationshipMainItem) => g.id === geolocationId);
    const geolocationInfo = mapApi2UiField(geolocationFound?.attributes || {}) as ICardGeolocationInfo;

    const StoreInstance = CardStore.getInstance();
    StoreInstance.setGeolocationInfo(geolocationInfo);
};

const formDeviceType = (value?: ICardIncludeTypeDeviceAPI): IDeviceAttribute => {
    const attributes = mapApi2UiField(value?.attributes || {});
    delete attributes.band_details;
    const date = attributes?.created_at;

    const bandDetails =
        typeof value?.attributes?.band_details === 'string'
            ? JSON.parse(value.attributes?.band_details)
            : value?.attributes?.band_details;
    return {
        date,
        dateName: date ? dayjs(date).format(appConfig.formatDate) : '',
        name: `${attributes?.manufacturer ? attributes?.manufacturer + ' ' : ''}${attributes?.marketingName || ''}`,
        ...attributes,
        bandDetails,
    };
};

const setRelationCardTypeDevice = (data?: ICardItemAPI, included?: ICardIncludeAPI[]): void => {
    const deviceTypeId = data?.relationships?.type_device?.data?.id;
    const typeDeviceIncludes = included?.filter((i) => i.type === 'type_device') as ICardIncludeTypeDeviceAPI[];
    const deviceType = typeDeviceIncludes?.find((i) => i.id === deviceTypeId);
    const formedDeviceType = formDeviceType(deviceType);
    const StoreInstance = CardStore.getInstance();
    StoreInstance.setDeviceInfo(formedDeviceType);
};

const setRelationCardDeviceHistory = (data?: ICardItemAPI, included?: ICardIncludeAPI[]): void => {
    const cardDeviceIds = data?.relationships?.card_devices?.data?.map((i) => i.id);
    const cardDeviceIncludes = included?.filter(
        (i) => i.type === 'card_device' && cardDeviceIds?.includes(i.id),
    ) as ICardIncludeCardDeviceAPI[];

    const deviceHistory: IDeviceHistory = cardDeviceIncludes
        .map((i: ICardIncludeCardDeviceAPI, index) => {
            const x = { ...mapApi2UiField(i.attributes?.device_info || {}), imei: i.attributes?.imei } as IDeviceInfo;
            x.id = `${x.id}-${index}`;
            if (typeof i.attributes?.device_info?.band_details === 'string') {
                const bandDetails = JSON.parse(i.attributes?.device_info?.band_details);
                delete bandDetails.band_details;
                x.bandDetails = bandDetails;
            }
            x.createdAt = i.attributes?.created_at || i.attributes?.device_info?.created_at;

            return x;
        })
        .sort((a, b) => dayjs.utc(b.updatedAt).diff(dayjs.utc(a.createdAt)));

    const StoreInstance = CardStore.getInstance();
    StoreInstance.setDeviceHistory(deviceHistory);
};

const setRelationCardSubscribers = (data?: ICardItemAPI, included?: ICardIncludeAPI[]): void => {
    const subscribersList = included?.filter(
        (i: IRequestRelationshipMainItem) => i.type === 'subscriber',
    ) as ICardIncludeSubscriberAPI[];
    const operatorsList = included?.filter(
        (i: IRequestRelationshipMainItem) => i.type === 'operator',
    ) as ICardIncludeOperatorAPI[];
    const countriesList = included?.filter(
        (i: IRequestRelationshipMainItem) => i.type === 'country',
    ) as ICardIncludeCountryAPI[];
    const zonesList = included?.filter((i: IRequestRelationshipMainItem) => i.type === 'zone') as IItemAPI[];
    const subscribers: ICardSubscriber[] =
        data?.relationships?.subscribers?.data?.map((s: IRequestRelationshipMainItem) => {
            const subscriberFound = subscribersList.find((sl: IRequestRelationshipMainItem) => sl.id === s.id);
            const attributes = subscriberFound?.attributes ? mapApi2UiField(subscriberFound.attributes) : {};
            const operatorId = subscriberFound?.relationships?.operator?.data.id;
            const operatorFound = operatorsList?.find((o: IRequestRelationshipMainItem) => o.id === operatorId);
            const operator = operatorFound?.attributes?.name;
            const countryId = operatorFound?.relationships?.country?.data?.id;
            const countryFound = countriesList?.find((c: IRequestRelationshipMainItem) => c.id === countryId);
            const country = countryFound?.attributes?.name;
            const zoneId = countryFound?.relationships?.zone?.data?.id;
            const zoneFound = zonesList?.find((z: IRequestRelationshipMainItem) => z.id === zoneId);
            const zone = zoneFound?.attributes?.name;
            const subscriber: ICardSubscriber = {
                id: s.id,
                ...attributes,
                operatorId,
                operator,
                countryId,
                country,
                zoneId,
                zone,
            };

            return subscriber;
        }) || [];
    const StoreInstance = CardStore.getInstance();
    StoreInstance.setSubscribers(subscribers);
};

const setCardStatistics = (statistics: ICardStatisticsApi) => {
    const newStatistics = {
        cost: statistics?.cost,
        ...statistics?.usage,
    };

    const StoreInstance = CardStore.getInstance();
    StoreInstance.setStatistics(newStatistics);
};

const setRelationCard = (apiResponse: IGetApiResponse): void => {
    const { resp, isError } = apiResponse;
    if (!isError) {
        const { data, included } = resp;
        setRelationCardTypeDevice(data as ICardItemAPI, included);
        setRelationCardDeviceHistory(data as ICardItemAPI, included);
        setRelationCardSubscribers(data as ICardItemAPI, included);
        setRelationCardGeolocationInfo(data as ICardItemAPI, included);
    }
};

function mapCardInfo(data: IItemAPI): ICardInfo {
    const attributes = mapApi2UiField(data.attributes);
    const isHashed = attributes.name === '******';
    return { id: data.id, ...attributes, isHashed };
}

export async function getCard(id: string): Promise<boolean> {
    cleanApiMessages();
    const apiResponse = await apiManager.getApi('GET_CARD', { id: id });
    const { resp, isError } = apiResponse;

    const { data } = resp;
    if (data) {
        const cardInfo = mapCardInfo(data as IItemAPI);
        const CardStoreInstance = CardStore.getInstance();
        CardStoreInstance.setCardInfo(cardInfo);
    }

    setRelationCard(apiResponse);
    return !isError;
}

export async function getCardStatistics(id: string, companyId?: string): Promise<boolean> {
    const { resp, isError } = await apiManager.getApi('GET_CARD_STATISTICS', { id, companyId });

    if (!isError) {
        setCardStatistics(resp as ICardStatisticsApi);
    }

    return isError;
}

export function getCardShowRelationSelect(cardId: string): void {
    const isCompanyTypeIdMNOHost = getProfileIsCompanyTypeIdMNOHost();
    if (!isCompanyTypeIdMNOHost) {
        getCallDataRecordsLast5({
            cardId,
        });
    }
    getCardSetsSelect({ cardId });
}

export async function patchCard(id: string, fields: IMapField): Promise<IPatchItem> {
    const CardStoreInstance = CardStore.getInstance();

    const usageLimit = fields['usageLimit'];

    if (usageLimit) {
        fields['usageLimit'] = usageLimit ? byteConverter(parseInt(usageLimit), 1) : null;
    }

    const body: IRequestAPIBody = {
        data: {
            id: id,
            type: 'card',
            attributes: mapUi2ApiField(fields),
        },
    };

    const { resp, isError } = await apiManager.getApi('PATCH_CARD', { id: id }, body);
    const { errors, data } = await resp;
    const getData = data as IItemAPI;

    if (!isError && data) {
        CardStoreInstance.setCardInfo({ id: getData.id, ...mapApi2UiField(getData.attributes) });
    }
    return { isError, errors };
}

export function cleanCardStore(): void {
    const CardStoreInstance = CardStore.getInstance();
    CardStoreInstance.cleanStore();
    cleanApiMessages();
}

function updateCardSubscribersStatus(apiResponse: IGetApiResponse): void {
    const updates = apiResponse?.resp?.data as IUpdateSubscriberStatusAPI[];
    if (updates?.length) {
        const StoreInstance = CardStore.getInstance();

        const subscribers = StoreInstance.subscribers.map((s: ICardSubscriber) => {
            const foundUpdate = updates.find((u: IUpdateSubscriberStatusAPI) => u.id === s.id);

            if (foundUpdate) {
                return { ...s, ...(foundUpdate.attributes ? mapApi2UiField(foundUpdate.attributes) : null) };
            } else {
                return s;
            }
        });
        StoreInstance.setSubscribers(subscribers);
    }
}

export async function getCardSubscribersStatusBackground(all?: boolean): Promise<void> {
    const apiType = 'GET_SUBSCRIBERS_STATUS';
    const isLoading = getIsFindSpinners([apiType]);
    if (!isLoading) {
        const CardStoreInstance = CardStore.getInstance();
        const idList = CardStoreInstance.subscribers
            .filter((s: ICardSubscriber) => all || s.dispatchLock)
            .map((s: ICardSubscriber) => s.id);

        if (idList.filter((s) => s).length) {
            const apiResponse = await apiManager.getApi(apiType, { idList });
            updateCardSubscribersStatus(apiResponse);
        }
    }
}

export async function setCardSubscribersChangeStatus({ command, idList }: IChangeStatus): Promise<void> {
    const body: IRequestAPIBody = {
        data: {
            attributes: {
                command: command,
            },
            type: 'drive',
            relationships: {
                subscriber: {
                    data: idList.map((i) => ({ id: i, type: 'subscriber' })),
                },
            },
        },
    };
    const apiResponse = await apiManager.getApi('POST_SUBSCRIBERS_STATUS', {}, body);
    updateCardSubscribersStatus(apiResponse);
}

export async function deleteCardSubscribers(id: string, idList: string[]): Promise<IPatchItem> {
    const { isError } = await apiManager.getApi('DELETE_CARD_SUBSCRIBERS', { id, idList });

    if (!isError) {
        await getCard(id);
    }

    return { isError };
}

export async function getCardBySubscriberId(cardId: string): Promise<IGetApiResponse> {
    const { isError, resp } = await apiManager.getApi('GET_SUBSCRIBERS_BY_CARD_ID', { searchParamsList: { cardId } });

    return { isError, resp };
}

export async function deleteCard(id: string): Promise<IPatchItem> {
    const { isError } = await apiManager.getApi('DELETE_CARD', { id });

    return { isError };
}

export function isCardSubscribersDispatchLock(): boolean {
    const CardStoreInstance = CardStore.getInstance();
    return CardStoreInstance.isSubscribersDispatchLock;
}

export function formatDeletionInfo(response: IGetApiResponse): ICardBulkDeletionInfo {
    const { isError, resp } = response;
    const parsedResponse = mapApi2UiField(resp);
    const { destroyed, notDestroyed, totalCount } = parsedResponse as ICardBulkDeletion;

    const notDestroyedNameList =
        notDestroyed
            ?.map((value: ICardBulkDeletionBody, index) => {
                if (!value?.name) {
                    return `# ${index + 1}`;
                }

                return value?.name;
            })
            ?.join(', ') || '';

    return {
        isError,
        destroyedCount: destroyed?.length,
        notDestroyedCount: notDestroyed?.length,
        notDestroyedNameList,
        totalCount,
    };
}

export async function deleteCards(idList: string[], id: string): Promise<IGetApiResponse> {
    const { isError, resp } = await apiManager.getApi('DELETE_CARDS', { idList, id });

    return { isError, resp };
}

export function setCardDeviceFiles(cardDeviceFileList: ICardDeviceFile[]) {
    const CardStoreInstance = CardStore.getInstance();
    CardStoreInstance.setCardDeviceFilesList(cardDeviceFileList);
}

function formatCardDeviceFiles(respApi: ICardDeviceFilesGetApi): void {
    const cardDeviceFilesList: ICardDeviceFile[] = respApi.data.map((value) => {
        const { statistic, ...attributes } = value.attributes;

        const convertedAttributes = mapApi2UiField(attributes);
        const convertedStatistic = mapApi2UiField(statistic ?? {});

        const filePath = convertedAttributes.zipFile.split('/');
        const zipFile = filePath[filePath.length - 1];
        const createdAt = dayjs(convertedAttributes?.createdAt).format('YYYY/MM/DD HH:mm:ss');

        return {
            id: value.id,
            ...convertedAttributes,
            zipFile,
            createdAt,
            ...convertedStatistic,
        } as ICardDeviceFile;
    });

    setCardDeviceFiles(cardDeviceFilesList);
}

export async function getCardDeviceFiles(): Promise<void> {
    const params: IApiParams = {
        searchParamsList: {
            pageNumber: 1,
            pageSize: 2,
        },
    };
    const { isError, resp } = await apiManager.getApi('GET_CARD_DEVICE_FILES', params);
    const respApi = resp as unknown as ICardDeviceFilesGetApi;

    if (!isError) {
        formatCardDeviceFiles(respApi);
    }
}
