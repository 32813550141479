import React from 'react';
import { observer } from 'mobx-react';
import { Button, Tooltip } from 'antd';
import { FileExcelOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Catcher } from '../index';
import { IGetApiType } from '../../REST';
import { IFilterParams } from '../../interfaces';
import { useUiIsLoading } from '../../hooks';
import { getDownloadFile } from '../../middleware';
import { tCustom } from '../../translate';

interface IDownloadFilesButtons {
    filterParams?: IFilterParams;
    csvType?: IGetApiType;
    pdfType?: IGetApiType;
    id?: string;
}

export const DownloadFilesButtons: React.FC<IDownloadFilesButtons> = observer(
    ({ filterParams = {}, csvType, pdfType, id }: IDownloadFilesButtons) => {
        const isLoading = useUiIsLoading();
        const onClick = (type: IGetApiType): void => {
            getDownloadFile(type, filterParams, id);
        };

        return (
            <Catcher>
                {pdfType ? (
                    <Tooltip title={`${tCustom('Download file', { type: 'PDF' })}`} placement="topRight">
                        <Button
                            onClick={(): void => onClick(pdfType)}
                            icon={<FilePdfOutlined />}
                            loading={isLoading}
                            disabled={isLoading}
                        >
                            PDF
                        </Button>
                    </Tooltip>
                ) : null}
                {csvType ? (
                    <Tooltip title={`${tCustom('Download file', { type: 'CSV' })}`} placement="topRight">
                        <Button
                            onClick={(): void => onClick(csvType)}
                            icon={<FileExcelOutlined />}
                            loading={isLoading}
                            disabled={isLoading}
                        >
                            CSV
                        </Button>
                    </Tooltip>
                ) : null}
            </Catcher>
        );
    },
);
