import { action, makeObservable, observable } from 'mobx';
import { ICurrenciesListItem } from '../interfaces';

export class CurrencyStore {
    private constructor() {
        makeObservable(this);
    }

    private static instance: CurrencyStore;
    public static getInstance(): CurrencyStore {
        if (!CurrencyStore.instance) {
            CurrencyStore.instance = new CurrencyStore();
        }
        return CurrencyStore.instance;
    }

    initInfoStore: ICurrenciesListItem = {
        id: '',
    };

    @observable currencyInfo: ICurrenciesListItem = this.initInfoStore;
    @action setCurrencyInfo(currency: ICurrenciesListItem): void {
        this.currencyInfo = currency;
    }

    @action cleanStore(): void {
        this.currencyInfo = this.initInfoStore;
    }
}
