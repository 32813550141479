import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Select } from 'antd';
import {
    IFilterSelect,
    IFilterSelectComposed,
    IGetApiType,
    ISelect,
    ISelectList,
    SelectType,
} from '../../../interfaces';
import { useUiIsSpinnerFound } from '../../../hooks';
import { lightText } from '../../../instruments';
import { tI } from '../../../translate';
import { Catcher, filterOption } from '../../../Components';
import Styles from '../m_styles.less';

const { Option } = Select;

interface ISelectCompanyProps {
    idSpinners?: IGetApiType[];
    isBlockAllowClear?: boolean;
    mode?: SelectType;
    onChange?: (val: IFilterSelectComposed) => void;
    title?: string;
    useSelectHook: () => ISelectList;
    value: IFilterSelect;
    virtual?: boolean;
}

export const FilterSelect: React.FC<ISelectCompanyProps> = observer(
    ({
        idSpinners = [],
        isBlockAllowClear,
        mode,
        onChange,
        title = tI('Filter'),
        useSelectHook,
        value,
        virtual = false,
    }: ISelectCompanyProps) => {
        const [searchString, setSearchString] = useState<string>();
        const isLoading = !!idSpinners?.length && useUiIsSpinnerFound(idSpinners);
        const selectList = useSelectHook();
        const selectMode = mode === 'single' ? undefined : (mode as 'multiple' | 'tags');
        const onSearch = (search: string): void => {
            setSearchString(search.replace(/ +/g, ' ').trim());
        };

        const _onChange = (val: IFilterSelectComposed) => {
            setSearchString('');
            onChange && onChange(val);
        };

        const setOptionsJSX = selectList.map((s: ISelect): JSX.Element => {
            return (
                <Option key={s.id} value={s.id} name={s.name}>
                    {lightText(s.name, searchString)}
                </Option>
            );
        });

        return (
            <Catcher>
                <div className={Styles.labelFieldPrefix} title={title}>
                    {title}
                </div>
                <Select
                    allowClear={!isBlockAllowClear}
                    className={Styles.fieldFull}
                    disabled={isLoading}
                    filterOption={filterOption}
                    getPopupContainer={(trigger): HTMLElement => trigger.parentElement}
                    loading={isLoading}
                    mode={selectMode}
                    onChange={_onChange}
                    onSearch={onSearch}
                    placeholder={title}
                    showSearch
                    value={value}
                    virtual={virtual}
                >
                    {setOptionsJSX}
                </Select>
            </Catcher>
        );
    },
);
