import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { IFilterParams, IRateListItem, ISelectList } from '../../interfaces';

export function useRatesSelect(): ISelectList {
    return useContext(MobXProviderContext).RootStore.ratesStore.select;
}
export function useRatesList(): IRateListItem[] {
    return useContext(MobXProviderContext).RootStore.ratesStore.list;
}

export function useRatesFilterParams(): IFilterParams {
    return useContext(MobXProviderContext).RootStore.ratesStore.filterParams;
}
