import { action, makeObservable, observable, reaction } from 'mobx';
import { UiStore } from './ui';
import { appConfig } from '../settings';
import { IFilterParams, ISubscribersListItem, ISubscribersSelectListAPI } from '../interfaces';
import { getSubscribersList } from '../middleware';
import { apiParamsCleaner } from '../instruments';

export class SubscribersStore {
    private constructor() {
        makeObservable(this);
        reaction(
            () => this.forcedUpdateTime,
            () => {
                const UiStoreInstance = UiStore.getInstance();
                const params = UiStoreInstance.params;
                const cleanParams = apiParamsCleaner(params, this.filterParams);
                getSubscribersList(cleanParams).then();
            },
            {
                delay: appConfig.APIDelay,
            },
        );
    }

    private static instance: SubscribersStore;
    public static getInstance(): SubscribersStore {
        if (!SubscribersStore.instance) {
            SubscribersStore.instance = new SubscribersStore();
        }
        return SubscribersStore.instance;
    }

    @observable select: ISubscribersSelectListAPI = [];
    @action setSubscribersSelect(companiesList: ISubscribersSelectListAPI): void {
        this.select = companiesList;
    }

    @observable list: ISubscribersListItem[] = [];
    @action setSubscribersList(list: ISubscribersListItem[]): void {
        this.list = list;
    }

    @action cleanStore(): void {
        this.list = [];
        this.select = [];
        this.filterParams = this.defaultFilterParams;
    }

    @observable forcedUpdateTime = 0;
    @action setForcedUpdateTime(): void {
        this.forcedUpdateTime = Date.now();
    }

    defaultFilterParams: IFilterParams = {
        isActiveThisMonth: true,
        operatorId: true,
        pageNumber: true,
        pageSize: true,
        search: true,
    };

    @action setExternalAPIListFilterParams(): void {
        this.filterParams = { ...this.defaultFilterParams, isOperatorIdBlockClear: true };
    }

    @observable filterParams: IFilterParams = this.defaultFilterParams;
}
