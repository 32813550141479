import { PackagesUnitsLogsStore } from '../stores/packagesUnitsLogs';
import { getCardSetsSelect } from './cardSets';
import { getCompaniesSelect } from './companies';
import { getPackagesSelect } from './packages';
import { setUiTotal } from './ui';
import { apiManager } from '../REST';
import {
    IPackagesUnitsLogsItem,
    IPackagesUnitsLogsItemAPI,
    IPackagesUnitsLogsListAPI,
    IParamsList,
    IRequestRelationshipMainItem,
} from '../interfaces';
import { mapApi2UiField } from '../instruments';

export function setPackagesUnitsLogsList(resp: IPackagesUnitsLogsListAPI): void {
    const cardSetsList = resp.included?.filter((i: IRequestRelationshipMainItem) => i.type === 'card_set') || [];
    const companiesList = resp.included?.filter((i: IRequestRelationshipMainItem) => i.type === 'company') || [];
    const packageInstancesList =
        resp.included?.filter((i: IRequestRelationshipMainItem) => i.type === 'package_instance') || [];
    const packagesList = resp.included?.filter((i: IRequestRelationshipMainItem) => i.type === 'package') || [];
    const list: IPackagesUnitsLogsItem[] =
        resp?.data?.map((r: IPackagesUnitsLogsItemAPI) => {
            const packageInstanceId = r?.relationships?.package_instance?.data?.id;
            const packageInstance = packageInstancesList?.find((i) => i?.id === packageInstanceId);
            const packageData = packageInstance?.relationships?.package?.data as IRequestRelationshipMainItem;
            const cardSetData = packageInstance?.relationships?.card_set?.data as IRequestRelationshipMainItem;
            const companyData = packageInstance?.relationships?.company?.data as IRequestRelationshipMainItem;
            const packageId = packageData?.id;
            const cardSetId = cardSetData?.id;
            const companyId = companyData?.id;
            const packageName = packagesList?.find((i) => i?.id === packageId)?.attributes.name;
            const cardSetName = cardSetsList?.find((i) => i?.id === cardSetId)?.attributes.name;
            const companyName = companiesList?.find((i) => i?.id === companyId)?.attributes.name;
            return {
                id: r.id,
                ...mapApi2UiField(r.attributes),
                cardSetName,
                companyName,
                packageName,
                cardSetId,
                companyId,
                packageId,
            };
        }) || [];
    const PackagesUnitsLogsStoreInstance = PackagesUnitsLogsStore.getInstance();
    PackagesUnitsLogsStoreInstance.setPackagesUnitsLogsList(list);
}

export const getPackagesUnitsLogsList = async (params: IParamsList): Promise<void> => {
    const { resp } = await apiManager.getApi('GET_PACKAGES_UNITS_LOGS_LIST', { searchParamsList: params });
    const { data, meta } = resp;
    meta && setUiTotal(meta.records_count);
    data && setPackagesUnitsLogsList(resp as IPackagesUnitsLogsListAPI);
};

export function setPackagesUnitsLogsForcedUpdateTime(): void {
    const PackagesUnitsLogsStoreInstance = PackagesUnitsLogsStore.getInstance();
    PackagesUnitsLogsStoreInstance.setForcedUpdateTime();
}

export function cleanPackagesUnitsLogsStore(): void {
    const PackagesUnitsLogsStoreInstance = PackagesUnitsLogsStore.getInstance();
    PackagesUnitsLogsStoreInstance.cleanStore();
}

export function getPackagesUnitsLogsRelationSelect(): void {
    getCompaniesSelect();
    getCardSetsSelect({});
    getPackagesSelect({ pageSize: 100 });
}
