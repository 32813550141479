import React from 'react';
import { Col, Row } from 'antd';
import { AntdIconProps } from '@ant-design/icons/es/components/AntdIcon';
import cx from 'classnames';
import { IFieldLayout } from '../../interfaces';
import { formatDataTestAttribute } from '../../instruments/testing';
import { ITrField, tF } from '../../translate';
import { Catcher } from '../Catcher';
import Styles from '../styles/m_itemCardStyles.less';

type IFieldInfo = {
    children?: React.ReactNode;
    dataTest?: string;
    icon?: React.ReactElement<AntdIconProps>;
    isFieldTextArea?: boolean;
    isHidden?: boolean;
    isIconBlank?: boolean;
    layout?: IFieldLayout;
    title?: ITrField;
    titleSuffix?: React.ReactNode;
};

const defaultLayout: IFieldLayout = { labelCol: { flex: '0 1 180px' }, valCol: { flex: '1 1 200px' }, rowGutter: 8 };

export const FieldInfoAdvanced: React.FC<IFieldInfo> = ({
    children,
    dataTest = '',
    icon,
    isFieldTextArea,
    isHidden,
    isIconBlank,
    layout = defaultLayout,
    title,
    titleSuffix,
}: IFieldInfo) => {
    if (isHidden) {
        return null;
    }
    // ToDo: 01.03.2021 - add Styles.labelIcon for icon

    const MyLayout: IFieldLayout = { ...defaultLayout, ...layout };
    return (
        <Catcher>
            <Row gutter={MyLayout.rowGutter} className={Styles.showAdvancedField}>
                <Col {...MyLayout.labelCol} className={cx(Styles.label, { [Styles.labelTextArea]: isFieldTextArea })}>
                    {icon ? icon : null}
                    <div
                        className={cx({ [Styles.labelText]: !!icon || isIconBlank })}
                        data-test={formatDataTestAttribute(dataTest, 'FieldInfoAdvanced', 'Title')}
                    >
                        {title ? tF(title) : null}: {titleSuffix ? titleSuffix : null}
                    </div>
                </Col>
                <Col
                    {...MyLayout.valCol}
                    className={Styles.value}
                    data-test={formatDataTestAttribute(dataTest, 'FieldInfoAdvanced', 'Children')}
                >
                    {children}
                </Col>
            </Row>
        </Catcher>
    );
};
