import { SubscribersStore } from '../stores/subscribers';
import { getOperatorsSelect } from './operators';
import { setUiTotal } from './ui';
import { appConfig } from '../settings';
import { apiManager } from '../REST';
import {
    IParamsList,
    ISubscribersListAPI,
    ISubscribersListItem,
    ISubscribersListItemAPI,
    ISubscribersSelectListAPI,
} from '../interfaces';
import { mapApi2UiField } from '../instruments';

export function setSubscribersSelect(data: ISubscribersSelectListAPI): void {
    const StoreInstance = SubscribersStore.getInstance();
    StoreInstance.setSubscribersSelect(data);
}

export function cleanSubscribersStore(): void {
    const StoreInstance = SubscribersStore.getInstance();
    StoreInstance.cleanStore();
}
export function setSubscribersExternalAPIListFilterParamsStore(): void {
    const StoreInstance = SubscribersStore.getInstance();
    StoreInstance.setExternalAPIListFilterParams();
}

export function setSubscribersList(apiData: ISubscribersListAPI): void {
    const operatorList = apiData?.included?.filter((i) => i.type === 'operator');
    const storeData: ISubscribersListItem[] =
        apiData?.data?.map((item: ISubscribersListItemAPI) => {
            const operatorId = item?.relationships?.operator?.data?.id;
            const foundOperator = operatorList?.find((o) => o.id === operatorId);
            return {
                id: item.id,
                ...mapApi2UiField(item.attributes),
                operatorId,
                operator: foundOperator?.attributes?.name,
            };
        }) || [];
    const StoreInstance = SubscribersStore.getInstance();
    StoreInstance.setSubscribersList(storeData);
}

export function getSubscribersRelationSelect(): void {
    getOperatorsSelect();
}

export function getExternalAPICSubscribersRelationSelect(): void {
    getOperatorsSelect(false, false, undefined, appConfig.externalAPICompanyIds);
}

export async function getSubscribersList(params: IParamsList): Promise<void> {
    const { resp } = await apiManager.getApi('GET_SUBSCRIBERS_LIST', { searchParamsList: params });
    const { data, meta } = resp;
    meta && setUiTotal(meta.records_count);
    data && setSubscribersList(resp as ISubscribersListAPI);
}

export function setSubscribersForcedUpdateTime(): void {
    const StoreInterface = SubscribersStore.getInstance();
    StoreInterface.setForcedUpdateTime();
}

export async function deleteSubscriber(id: string): Promise<void> {
    const { isError } = await apiManager.getApi('DELETE_SUBSCRIBER', { id });
    if (!isError) {
        setSubscribersForcedUpdateTime();
    }
}
