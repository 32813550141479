import React, { useEffect, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { Catcher } from '../../';
import { GridAlgorithm, MarkerClusterer } from '@googlemaps/markerclusterer';
import { GridOptions } from '@googlemaps/markerclusterer/dist/algorithms/grid';
import { MarkerClustererOptions } from '@googlemaps/markerclusterer/dist/markerclusterer';
import { Renderer } from '@googlemaps/markerclusterer/dist/renderer';
import { MarkerProps, useGoogleMap } from '@react-google-maps/api';
import { v4 } from 'uuid';
import { IGeoLocation } from '../../../interfaces';
import Styles from '../m_googleMapsStyles.less';

interface IClustererMarker extends IGeoLocation {
    id?: string;
    name?: string;
}

interface IClustererMarkerOptions extends google.maps.MarkerOptions {
    id?: string;
    name?: string;
}

export interface IMarkerClustererNew<T> {
    children?: never;
    gridOptions?: GridOptions;
    markerList: Array<T>;
    onClickMarker?: (marker: T) => void;
    showMarkerInfo: (marker: T, markerProps?: MarkerProps) => JSX.Element;
}

const markerClusterColor = '#e03636';
const markerClustererSvg = window.btoa(`
<svg fill="${markerClusterColor}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
<style type="text/css">
.st0{fill:${markerClusterColor};}
.st1{fill:none;stroke:${markerClusterColor};stroke-width:2;stroke-miterlimit:10;}
.st2{opacity:0.1;fill:${markerClusterColor};}
.st3{opacity:0.3;fill:${markerClusterColor};}
.st4{opacity:0.5;fill:${markerClusterColor};}
</style>
<g>
  <circle class="st0" cx="16" cy="16" r="7"/>
  <path class="st2" d="M16,2c4,0,7.5,1.7,10.1,4.3l1.4-1.5C24.5,1.9,20.5,0,16,0C11.6,0,7.6,1.8,4.7,4.7l1.4,1.4C8.6,3.6,12.1,2,16,2tz"/>
  <path class="st3" d="M25.4,7C23,4.6,19.7,3,16,3c-3.6,0-6.8,1.5-9.2,3.8l1.4,1.4C10.2,6.2,13,5,16,5c3.2,0,6,1.3,8,3.5L25.4,7z"/>
  <path class="st4" d="M8.9,8.9l1.4,1.4C11.8,8.9,13.8,8,16,8c2.4,0,4.5,1,6,2.7l1.4-1.5C21.5,7.2,18.9,6,16,6C13.2,6,10.7,7.1,8.9,8.9z"/>
  <path class="st2" d="M31.6,12.4L29.7,13c0.2,1,0.3,2,0.3,3c0,6.4-4.4,11.9-10.3,13.5l0.5,1.9C27,29.6,32,23.4,32,16C32,14.8,31.8,13.6,31.6,12.4z"/>
  <path class="st2" d="M2,16c0-1.1,0.2-2.2,0.4-3.3l-1.9-0.6C0.2,13.4,0,14.7,0,16c0,7.4,5,13.6,11.8,15.4l0.5-1.9C6.4,27.9,2,22.4,2,16z"/>
  <path class="st3" d="M29,16c0-0.9-0.1-1.9-0.3-2.7l-1.9,0.6c0.1,0.7,0.2,1.4,0.2,2.2c0,5-3.4,9.3-8,10.6l0.5,1.9C24.9,27,29,22,29,16z"/>
  <path class="st3" d="M5,16c0-0.8,0.1-1.7,0.3-2.4L3.4,13C3.1,14,3,15,3,16c0,6,4.1,11,9.6,12.5l0.5-1.9C8.4,25.3,5,21,5,16z"/>
  <path class="st4" d="M26,16c0-0.6-0.1-1.3-0.2-1.9l-1.9,0.6C24,15.1,24,15.6,24,16c0,3.6-2.4,6.7-5.7,7.7l0.5,1.9C22.9,24.4,26,20.6,26,16z"/>
  <path class="st4" d="M8,16c0-0.5,0.1-1.1,0.2-1.6l-1.9-0.6C6.1,14.5,6,15.3,6,16c0,4.6,3.1,8.4,7.3,9.6l0.5-1.9C10.4,22.7,8,19.6,8,16z"/>
</g>
</svg>`);

export const CustomMarkersClusterer: React.FC<IMarkerClustererNew<any>> = ({
    gridOptions = {},
    markerList,
    onClickMarker,
    showMarkerInfo,
}: IMarkerClustererNew<any>) => {
    class CustomMarker extends google.maps.Marker {
        public id: string;
        public name: string;
        constructor(options: IClustererMarkerOptions) {
            super(options);
            const fakeId = v4();
            this.id = options.id || options.name || fakeId;
            this.name = options.name || options.id || fakeId;
        }
    }

    class CustomMarkerClusterer extends MarkerClusterer {
        constructor(options: MarkerClustererOptions) {
            super(options);
        }

        renderer: Renderer = {
            render: ({ count, position, markers }) => {
                const markerClusterer = new CustomMarker({
                    position,
                    icon: {
                        url: `data:image/svg+xml;base64,${markerClustererSvg}`,
                        scaledSize: new google.maps.Size(45, 45),
                    },
                    label: {
                        text: String(count),
                        color: 'rgba(0,0,255,0.9)',
                        fontSize: '12px',
                    },
                    zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
                });
                const info = () => {
                    return (
                        <div className={Styles.overlayView}>
                            <div className={Styles.overlayViewContent}>
                                <div className={Styles.overlayViewContentTitle}>{count} items</div>
                                {markers?.map((m: CustomMarker, i) => {
                                    return (
                                        <div key={i} className={Styles.overlayViewContentItem}>
                                            {i + 1} - {m.id} - {m.name}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                };
                const infoWindow = new google.maps.InfoWindow({
                    content: renderToString(info()),
                });
                markerClusterer.addListener('mouseout', () => {
                    infoWindow.close();
                });
                markerClusterer.addListener('mouseover', () => {
                    infoWindow.open(this.map, markerClusterer);
                });
                return markerClusterer;
            },
        };
    }

    const [listCustomMarker, setListCustomMarker] = useState<CustomMarker[]>([]);
    const map = useGoogleMap();

    useEffect(() => {
        if (map instanceof google.maps.Map) {
            const algorithm = new GridAlgorithm(gridOptions);
            const markers = markerList.map((marker: IClustererMarker) => {
                const markerItem = new CustomMarker({
                    id: marker.id,
                    name: marker.name || marker.id || `Lat: ${marker.lat} Lng: ${marker.lng}`,
                    optimized: true,
                    position: { lat: marker.lat, lng: marker.lng },
                    map,
                });

                const infoWindow = new google.maps.InfoWindow({
                    content: renderToString(showMarkerInfo(marker)),
                });

                onClickMarker &&
                    markerItem.addListener('click', () => {
                        onClickMarker(marker);
                    });
                markerItem.addListener('mouseout', () => {
                    infoWindow.close();
                });
                markerItem.addListener('mouseover', () => {
                    infoWindow.open(map, markerItem);
                });

                return markerItem;
            });

            setListCustomMarker(markers);

            const markerClusterer = new CustomMarkerClusterer({
                algorithm,
                map,
                markers,
            });
            markerClusterer.setMap(map);
        }

        return () => {
            google.maps.event.clearInstanceListeners(listCustomMarker);
            listCustomMarker.forEach((marker: CustomMarker) => {
                marker.setMap(null);
            });
        };
    }, [markerList, map]);

    return <Catcher>null</Catcher>;
};
