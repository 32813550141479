import React from 'react';
import { observer } from 'mobx-react';
import { Form, Select } from 'antd';
import { Rule } from 'antd/es/form';
import { Catcher } from '../';
import { filterOption } from './filterOption';
import cx from 'classnames';
import { INetworkSelectItem, ISelect } from '../../interfaces';
import { useOperatorsNetworksByCountry, usePurchaseUsedNetworksIdByCountry } from '../../hooks';

interface IFormSelect {
    countryId?: string;
    currentNetworkId?: string;
    defaultValue?: string;
    fieldName: string | [number, string];
    isAddonBefore?: boolean;
    isGetPopupContainerEnabled?: boolean;
    isNotClear?: boolean;
    label?: string;
    rules?: Array<Rule>;
}

export const FormSelectNetworksByCountry: React.FC<IFormSelect> = observer(
    ({
        countryId = '',
        currentNetworkId = '',
        defaultValue,
        fieldName,
        isAddonBefore,
        isGetPopupContainerEnabled = true,
        isNotClear,
        label = '',
        rules = [],
    }: IFormSelect) => {
        const { Option } = Select;
        const exceptionIds = usePurchaseUsedNetworksIdByCountry(countryId).filter(
            (n) => !currentNetworkId || n !== currentNetworkId,
        );
        const select = useOperatorsNetworksByCountry(countryId);
        const selectJSX = select
            .filter((s: INetworkSelectItem) => !exceptionIds || !exceptionIds.includes(s.id))
            .map(
                (s: ISelect): JSX.Element => (
                    <Option key={s.id} value={s.id} disabled={s.disabled}>
                        <div>{s.name}</div>
                    </Option>
                ),
            );

        return (
            <Catcher>
                <Form.Item
                    name={fieldName}
                    label={label}
                    rules={rules}
                    className={cx({
                        ['ant-form-item-addonSelect']: isAddonBefore,
                    })}
                >
                    <Select
                        allowClear={!isNotClear}
                        defaultValue={defaultValue}
                        filterOption={filterOption}
                        getPopupContainer={
                            isGetPopupContainerEnabled ? (trigger): HTMLElement => trigger.parentElement : undefined
                        }
                        placeholder={label}
                        showSearch
                    >
                        {selectJSX}
                    </Select>
                </Form.Item>
            </Catcher>
        );
    },
);
