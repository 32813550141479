import React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import cx from 'classnames';
import { IPackagesUnitsLogsItem } from '../../../interfaces';
import { usePackagesUnitsLogsTable, useUiIsLoading } from '../../../hooks';
import { byteRange1000, dateText2Utc } from '../../../instruments';
import { tF } from '../../../translate';
import { Catcher, LinkComponent } from '../../../Components';
import Styles from '../../styles/m_viewStyles.less';

export const PackagesUnitsLogsTableList: React.FC = observer(() => {
    const isLoading = useUiIsLoading();
    const list = usePackagesUnitsLogsTable();

    const tableColumns = [
        {
            title: tF('Date'),
            dataIndex: 'date',
        },
        {
            title: tF('Company'),
            dataIndex: 'companyLink',
        },
        {
            title: tF('Card Set'),
            dataIndex: 'cardSetLink',
        },
        {
            title: tF('Package Name'),
            dataIndex: 'packageLink',
        },
        {
            title: tF('Units'),
            dataIndex: 'units',
            className: Styles.columnRight,
        },
        {
            title: tF('Units After'),
            dataIndex: 'unitsAfter',
            className: Styles.columnRight,
        },
        {
            title: '',
            dataIndex: 'blank',
        },
        {
            title: tF('Bought At'),
            dataIndex: 'createdAt',
        },
    ];
    const tableData = list.map((p: IPackagesUnitsLogsItem) => {
        return {
            key: p.id,
            companyLink: (
                <LinkComponent currentListComponent={'CompaniesList'} id={p.companyId} isLight name={p.companyName} />
            ),
            cardSetLink: (
                <LinkComponent currentListComponent={'CardSetsList'} id={p.cardSetId} isLight name={p.cardSetName} />
            ),
            packageLink: (
                <LinkComponent
                    currentListComponent={'PackagesUnitsLogsList'}
                    id={p.packageId}
                    isLight
                    name={p.packageName}
                />
            ),
            ...p,
            date: dateText2Utc(p.updatedAt),
            units: p.units ? byteRange1000(+p.units) : '',
            unitsAfter: p.unitsAfter ? byteRange1000(+p.unitsAfter) : '',
        };
    });

    return (
        <Catcher>
            <div className={cx(Styles.tablePlace)}>
                <Table
                    dataSource={tableData}
                    columns={tableColumns}
                    pagination={false}
                    className={'table-theme'}
                    size={'middle'}
                    loading={isLoading}
                />
            </div>
        </Catcher>
    );
});
