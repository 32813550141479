import { action, makeObservable, observable } from 'mobx';
import { IMultipleValuesProps, IPackageInfo, IPackageSoldInfo, ISelect } from '../interfaces';

const multipleValuesInit: IMultipleValuesProps = {
    assignedId: [],
    assignedMapArr: [],
    assignedNameArr: [],
};

export class PackageStore {
    private constructor() {
        makeObservable(this);
    }

    private static instance: PackageStore;
    public static getInstance(): PackageStore {
        if (!PackageStore.instance) {
            PackageStore.instance = new PackageStore();
        }
        return PackageStore.instance;
    }

    initPackageInfo: IPackageInfo = {
        id: '',
        creditRemains: false,
        currencyId: '',
        dataVolume: 0,
        dataVolumeSelect: '',
        duration: 0,
        name: '',
        notes: '',
        price: 0,
        zoneId: '',
        companyId: '',
        creditRemainsName: '',
        assignedCompanies: multipleValuesInit,
        assignedCountries: multipleValuesInit,
        assignedOperators: multipleValuesInit,
        assignedNetworks: multipleValuesInit,
    };

    initPackageSoldInfo: IPackageSoldInfo = {
        id: '',
        creditRemains: false,
        creditRemainsName: '',
        packageId: '',
        packageName: '',
        dataVolume: 0,
        dataVolumeName: '',
        dataVolumeLeft: 0,
        dataVolumeNameLeft: '',
        expiresDate: '',
        isExpired: false,
        isExpiredName: '',
        zoneId: '',
        zoneName: '',
        companyId: '',
        companyName: '',
        cardSetId: '',
        cardSetName: '',
        assignedCompanies: multipleValuesInit,
        assignedCountries: multipleValuesInit,
        assignedOperators: multipleValuesInit,
        assignedNetworks: multipleValuesInit,
    };

    @observable packageInfo: IPackageInfo = this.initPackageInfo;
    @action setPackage(packageInfo: IPackageInfo): void {
        this.packageInfo = packageInfo;
    }

    @observable packageSoldInfo: IPackageSoldInfo = this.initPackageSoldInfo;
    @action setSoldPackage(packageSoldInfo: IPackageSoldInfo): void {
        this.packageSoldInfo = packageSoldInfo;
    }

    @observable paymentTypes: ISelect[] = [];
    @action setPaymentTypes(select: ISelect[]): void {
        this.paymentTypes = select;
    }

    @action cleanStore(): void {
        this.packageInfo = this.initPackageInfo;
        this.packageSoldInfo = this.initPackageSoldInfo;
    }
}
