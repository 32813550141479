import React from 'react';
import { observer } from 'mobx-react';
import { Catcher, ListWrapper } from '../../';
import { ReportsTableList } from './';
import { useReportsFilterParams } from '../../../hooks';
import { cleanReportsStore, getReportsRelationSelect } from '../../../middleware';

export const ReportsList: React.FC = observer(() => {
    const filterParams = useReportsFilterParams();

    return (
        <Catcher>
            <ListWrapper
                cleanStore={cleanReportsStore}
                filterParams={filterParams}
                getRelationSelect={getReportsRelationSelect}
            >
                <ReportsTableList />
            </ListWrapper>
        </Catcher>
    );
});
