/* eslint-disable */
import { CardSetStore } from '../stores/cardSet';
import {
    cleanApiMessages,
    cleanCardsStore,
    cleanIncludedStore,
    getActivityRollAsExternal,
    getCompaniesChildrenSelect,
    getCompaniesSelect,
    getIncludeAssignedCompaniesAPI,
    getIncludedCombinationCardSetCardsAPI,
    setCardSetsForcedUpdateTime,
    setCardsSelect,
    setIncludedAssignedCompaniesAPI,
    setIncludedCombinationCardSetCardAPI,
    setIncludedCombinationCardSetSubscribersAPI,
    updateIncludedList,
} from './';
import { IBodyItemResponse, IGetApiResponse, apiManager } from '../REST';
import {
    IBodyListResponse,
    ICardSetIncludedPatchAPI,
    ICardSetInfo,
    ICardSetItemDataPatchAPI,
    ICardSetsGetAPI,
    ICardSetsPatchAPI,
    IIncludeAssignedCompanyItem,
    IIncludeCardItemAPI,
    IIncludeCombinationCardSetCardItem,
    IIncludeNameItemAPI,
    IParamsList,
    IPatchItem,
    IRelationshipItem,
    IUseCardSetForm,
} from '../interfaces';
import { globalHexId, mapApi2UiField, mapUi2ApiField } from '../instruments';

export function cleaCardSetStore(): void {
    const StoreInstance = CardSetStore.getInstance();
    StoreInstance.cleanStore();
}

export function cleanCardSetRelationSelect(): void {
    cleanApiMessages();
    cleanIncludedStore();
    cleanCardsStore();
}

export function setCardSetInfo(info: ICardSetInfo): void {
    const StoreInstance = CardSetStore.getInstance();
    StoreInstance.setCardInfo(info);
}
export function makeCardSetInfo(resp: ICardSetsGetAPI): ICardSetInfo {
    const { data, included } = resp;
    const companyId = data?.relationships?.company?.data?.id || '';
    const company =
        (included?.filter((i: IRelationshipItem) => i.type === 'company') as IIncludeNameItemAPI[])?.find(
            (i: IIncludeNameItemAPI) => i.id === companyId,
        )?.attributes?.name || '';
    const companyParentId =
        (included?.find((i: IRelationshipItem) => i.type === 'company_parent') as IIncludeNameItemAPI)?.id || '';
    const attributes = mapApi2UiField(data?.attributes || {});
    const isHashed = attributes.name === '******';

    return {
        id: data?.id || '',
        companyId,
        company,
        companyParentId,
        ...attributes,
        isHashed,
    };
}

export function setCardSet(apiResponse: IGetApiResponse): void {
    const { resp, isError } = apiResponse;
    if (!isError) {
        setCardSetInfo(makeCardSetInfo(resp as ICardSetsGetAPI));
        setIncludedAssignedCompaniesAPI(resp as IBodyItemResponse);
    }
}

export function createCardSetInfo(): void {
    const newInfo: ICardSetInfo = {
        id: globalHexId.getId(),
    };
    setCardSetInfo(newInfo);
}

export function setCardSetCombination(apiResponse: IGetApiResponse): void {
    const { resp, isError } = apiResponse;
    if (!isError) {
        setIncludedCombinationCardSetCardAPI(resp as IBodyItemResponse);
    }
}
export async function getCardSetCombination(id: string, params: IParamsList = {}): Promise<boolean> {
    const apiResponse = await apiManager.getApi('GET_CARD_SET_COMBINATION', { id, searchParamsList: params });
    if (apiResponse.isError) {
        return false;
    } else {
        setCardSetCombination(apiResponse);
        return true;
    }
}

export async function getCardSetCombinationSubscribers(ids: string[]): Promise<void> {
    const { resp, isError } = await apiManager.getApi('GET_CARD_SET_COMBINATION_SUBSCRIBERS', { idList: ids });

    if (!isError) {
        setIncludedCombinationCardSetSubscribersAPI(resp as IBodyListResponse);
    }
}

export async function getCardSet(id: string): Promise<boolean> {
    const apiResponse = await apiManager.getApi('GET_CARD_SET', { id });
    if (apiResponse.isError) {
        return false;
    } else {
        setCardSet(apiResponse);
        return true;
    }
}

export async function getCardSetShowRelationSelect(id: string): Promise<void> {
    getActivityRollAsExternal({ cardSetId: id });
    //getCallDataRecordsLast5({ cardSetId: id });
}

export function getCardSetEditRelationSelect(): void {
    getCompaniesSelect(true);
}

export const sendCardSetData = async (isNew?: boolean): Promise<IPatchItem> => {
    const StoreInstance = CardSetStore.getInstance();
    const { id: cardSetId, ...cardSetInfo } = StoreInstance.cardSetInfo;
    const geolocationEnabled = cardSetInfo.geolocationEnabled;
    const cardSetInfoAPI = mapUi2ApiField(cardSetInfo);
    delete cardSetInfoAPI.company;

    const { assignedCompanyRelationships, assignedCompanyIncludes } = getIncludeAssignedCompaniesAPI();
    const { combinationCardSetCardsRelationships, combinationCardSetCardsIncludes } =
        getIncludedCombinationCardSetCardsAPI(geolocationEnabled);

    const includedAPI: ICardSetIncludedPatchAPI = [...assignedCompanyIncludes, ...combinationCardSetCardsIncludes];

    const data: ICardSetItemDataPatchAPI = {
        id: cardSetId,
        type: 'card_set',
        attributes: cardSetInfoAPI,
        relationships: {
            assigned_companies: {
                data: assignedCompanyRelationships,
            },
            card_combinations: {
                data: combinationCardSetCardsRelationships,
            },
        },
    };

    const body: ICardSetsPatchAPI = {
        data,
        included: includedAPI,
    };

    const { resp, isError } = await apiManager.getApi(
        isNew ? 'POST_CARD_SET' : 'PATCH_CARD_SET',
        isNew ? {} : { id: cardSetId },
        body,
    );
    const { errors } = await resp;
    return { isError, errors };
};

export async function deleteCardSet(id: string): Promise<void> {
    const { isError } = await apiManager.getApi('DELETE_CARD_SET', { id });
    if (!isError) {
        setCardSetsForcedUpdateTime();
    }
}

export function getCardSetCompanyRelations(companyId: string): void {
    getCardSetCombinationCard(companyId);
    getCompaniesChildrenSelect(companyId);
}

async function getCardSetCombinationCard(companyId: string): Promise<void> {
    const apiResponse = await apiManager.getApi('GET_COMBINATION_CARDS_SELECT', { id: companyId });
    if (apiResponse?.resp?.data) {
        setCardsSelect(apiResponse.resp.data as IIncludeCardItemAPI[]);
    }
}

function updateCardSetCombinationCardSetCards(list: IIncludeCombinationCardSetCardItem[]): void {
    updateIncludedList('combinationCardSetCards', list);
}
function updateCardSetAssignedCompany(list: IIncludeAssignedCompanyItem[]): void {
    updateIncludedList('assignedCompanies', list);
}

export function setCardSetForm(form: IUseCardSetForm): void {
    const info = { ...form };
    const assignedCompanies = form.assignedCompanies || [];
    updateCardSetAssignedCompany(assignedCompanies);
    const combinationCardSetCards = form.combinationCardSetCards || [];
    updateCardSetCombinationCardSetCards(combinationCardSetCards);
    delete info.assignedCompanies;
    delete info.combinationCardSetCards;
    setCardSetInfo(info);
}
