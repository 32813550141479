import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Catcher } from '../';
import dayjs from 'dayjs';
import { appConfig } from '../../settings';
import { IGetApiType } from '../../REST';
import { IShowFormComponent } from '../../interfaces';
import { useRouterStore } from '../../hooks';
import {
    abortSelectedFetch,
    cleanCountryStore,
    getCountryById,
    getCountryEditRelationSelect,
    getCountryShowActiveCards,
    getDashboardTelecomUsageList,
} from '../../middleware';
import { EditCountryForm, ShowCountry } from './Components';

export const Country: React.FC<IShowFormComponent> = observer(({ isEdit, isNew }: IShowFormComponent) => {
    const routerStore = useRouterStore();
    const [id, setId] = useState('');
    const usedApiTypes: IGetApiType[] = [
        'GET_COUNTRY',
        'GET_ACTIVITY_ROLL',
        'GET_USAGE_CARD_SETS_LIST',
        'GET_USAGE_CARDS_LIST',
        'GET_USAGE_TIMELINE_LIST',
        'GET_ZONE_SELECT',
    ];

    const goOut = (): void => {
        abortSelectedFetch(usedApiTypes);
        cleanCountryStore();
    };

    const goToList = (): void => {
        routerStore.goTo('CountryList');
        goOut();
    };

    const _getCountry = async (id: string): Promise<void> => {
        const isGetCountry = await getCountryById(id);
        if (!isGetCountry) {
            goToList();
        }
    };

    const fetchUsageData = async () => {
        getCountryShowActiveCards(id, { countryId: id });
        getDashboardTelecomUsageList({
            countryId: id,
            startDate: `${dayjs().startOf('month').format(appConfig.formatDate)}`,
        });
    };

    useEffect(() => {
        return (): void => {
            goOut();
        };
    }, []);

    useEffect(() => {
        if (isEdit || isNew) {
            getCountryEditRelationSelect();
        }
        if (id) {
            _getCountry(id);
            fetchUsageData();
        }
    }, [id]);

    useEffect(() => {
        if (routerStore.routerState.params.id) {
            setId(routerStore.routerState.params.id);
        }
    }, [routerStore.routerState.params.id]);

    const setEdit = (isEdit?: boolean): void => {
        if (isNew) {
            goToList();
        } else if (isEdit) {
            abortSelectedFetch(usedApiTypes);
            _getCountry(id);
            getCountryEditRelationSelect();
            routerStore.goTo('CountryEdit', { params: { id } });
        } else {
            _getCountry(id);
            routerStore.goTo('Country', { params: { id } });
        }
    };

    return (
        <Catcher>
            {isEdit || isNew ? <EditCountryForm setEditMode={setEdit} /> : <ShowCountry setEditMode={setEdit} />}
        </Catcher>
    );
});
