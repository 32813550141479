import {
    IChildrenActionPage,
    IComponentName,
    IPage,
    IPageAction,
    IPageList,
    IParentPages,
    ITitlePage,
} from '../interfaces';
import {
    setActivityLogForcedUpdateTime,
    setBalanceLogForcedUpdateTime,
    setCallDataRecordsForcedUpdateTime,
    setCallDataRecordsForcedUpdateTimeBeta,
    setCardSetsForcedUpdateTime,
    setCardsForcedUpdateTime,
    setCompaniesForcedUpdateTime,
    setCountriesForcedUpdateTime,
    setCurrenciesForcedUpdateTime,
    setEsNotificationsForcedUpdateTime,
    setEsNotificationsLogsForcedUpdateTime,
    setEsNotificationsQrcodesForcedUpdateTime,
    setForcedFilesListUpdateTime,
    setForcedResponsesListUpdateTime,
    setOperatorsForcedUpdateTime,
    setOrdersForcedUpdateTime,
    setPackagesUnitsLogsForcedUpdateTime,
    setPurchaseRatesForcedUpdateTime,
    setRatesForcedUpdateTime,
    setReportsForcedUpdateTime,
    setSubscribersForcedUpdateTime,
    setUsersForcedUpdateTime,
    setZonesForcedUpdateTime,
} from '../middleware';

const pagesBook: IPage[] = [
    // Public page
    {
        id: '000-Init',
        componentName: 'Initialization',
        title: 'Initialization',
        url: '/',
        isActive: true,
        isDefault: true,
        appStatus: 'INITIALIZATION',
    },
    {
        id: '001-Login',
        componentName: 'Login',
        title: 'Login',
        url: '/login',
        isActive: true,
        isDefault: true,
        appStatus: 'AUTHORIZATION',
    },
    {
        id: '002-ResetPassword',
        componentName: 'ResetPassword',
        title: 'Reset Password',
        url: '/reset_password',
        isActive: true,
        isDefault: false,
        appStatus: 'AUTHORIZATION',
    },
    {
        id: '004-SignUp',
        componentName: 'SignUp',
        title: 'SignUp',
        url: '/signup',
        isActive: false,
        isDefault: false,
        appStatus: 'AUTHORIZATION',
    },
    // Private page
    // {
    //     id: '0000-Terms',
    //     componentName: 'Terms',
    //     title: 'Terms',
    //     url: '/terms',
    //     isActive: false,
    //     isDefault: false,
    //     appStatus: 'ACCEPT_POLICY',
    // },
    {
        id: '0000.01-Support-Pages',
        componentName: 'SupportPage',
        title: 'Support Pages',
        url: '/support-page',
        isActive: true,
        isDefault: false,
        isMenu: false,
        menuOrder: 0.01,
        menuIcon: 'CustomerServiceOutlined',
        roleRight: ['ROOT'],
        subMenu: [
            {
                id: '0004-Support-page-upload-card-device',
                componentName: 'UploadCardDevice',
                title: 'Upload Card Device',
                url: '/support-page/upload-card-device',
                isActive: true,
                isMenu: false,
                menuOrder: 0.011,
                menuIcon: 'UploadOutlined',
                roleRight: ['ROOT'],
            },
            {
                id: '0004-Support-page-hash-companies-info',
                componentName: 'HashCompaniesInfo',
                title: 'Hash Companies Info',
                url: '/support-page/hash-companies-info',
                isActive: true,
                isMenu: false,
                menuOrder: 0.012,
                menuIcon: 'NumberOutlined',
                roleRight: ['ROOT'],
            },
            {
                id: '0004-Support-feature-flag-list',
                componentName: 'FeatureFlags',
                title: 'Feature Flags',
                url: '/support-page/feature-flags',
                isActive: true,
                isMenu: false,
                menuOrder: 0.013,
                menuIcon: 'FlagOutlined',
                roleRight: ['ROOT'],
                subMenu: [
                    {
                        id: '0004-Support-feature-flag-list',
                        componentName: 'FeatureFlagsEdit',
                        title: 'Feature Flags Edit',
                        url: '/support-page/feature-flags/:id/edit',
                        isActive: true,
                        isMenu: false,
                        menuOrder: 0.0133,
                        menuIcon: 'NumberOutlined',
                        roleRight: ['ROOT'],
                    },
                ],
            },
            {
                id: '0004-Support-gateway-config-list',
                componentName: 'GatewayConfig',
                title: 'Gateway Config',
                url: '/support-page/gateway-config',
                isActive: true,
                isMenu: false,
                menuOrder: 0.01333,
                menuIcon: 'GatewayOutlined',
                roleRight: ['ROOT'],
                subMenu: [
                    // {
                    //     id: '0004-Support-feature-flag-list',
                    //     componentName: 'FeatureFlagsEdit',
                    //     title: 'Feature Flags Edit',
                    //     url: '/support-page/feature-flags/:id/edit',
                    //     isActive: true,
                    //     isMenu: false,
                    //     menuOrder: 0.0133,
                    //     menuIcon: 'NumberOutlined',
                    //     roleRight: ['ROOT'],
                    // },
                ],
                forcedUpdate: setOperatorsForcedUpdateTime,
            },
            {
                id: '0004-Support-scp-file-upload',
                componentName: 'ScpFileUpload',
                title: 'Scp File Upload',
                url: '/support-page/scp-file-upload',
                isActive: true,
                isMenu: false,
                menuOrder: 0.01334,
                menuIcon: 'FileAddOutlined',
                roleRight: ['ROOT'],
                subMenu: [
                    // {
                    //     id: '0004-Support-feature-flag-list',
                    //     componentName: 'FeatureFlagsEdit',
                    //     title: 'Feature Flags Edit',
                    //     url: '/support-page/feature-flags/:id/edit',
                    //     isActive: true,
                    //     isMenu: false,
                    //     menuOrder: 0.0133,
                    //     menuIcon: 'NumberOutlined',
                    //     roleRight: ['ROOT'],
                    // },
                ],
                forcedUpdate: setOperatorsForcedUpdateTime,
            },
            {
                id: '0004-Support-page-impersonate-user',
                componentName: 'ImpersonateUser',
                title: 'Impersonate User',
                url: '/support-page/impersonate-user',
                isActive: true,
                isMenu: false,
                menuOrder: 0.014,
                menuIcon: 'UserSwitchOutlined',
                roleRight: ['ROOT'],
                forcedUpdate: setUsersForcedUpdateTime,
            },
            {
                id: '0004-Support-page-External-API-List',
                componentName: 'ExternalAPIList',
                title: 'External API List',
                url: '/support-page/external-api',
                isActive: true,
                isMenu: false,
                menuOrder: 0.015,
                menuIcon: 'ApiOutlined',
                roleRight: ['ROOT'],
                forcedUpdate: setSubscribersForcedUpdateTime,
            },
            {
                id: '0004-Support-page-ES-Plus-Notifications-List',
                componentName: 'ESPlusNotifications',
                title: 'ES2+ Notifications',
                url: '/support-page/notifications',
                isActive: true,
                isMenu: false,
                menuOrder: 0.016,
                menuIcon: 'NotificationOutlined',
                roleRight: ['ROOT'],
                forcedUpdate: setEsNotificationsForcedUpdateTime,
            },
            {
                id: '0004-Support-page-ES-Plus-Notifications-Logs-List',
                componentName: 'ESPlusNotificationsLogs',
                title: 'ES2+ Logs Notifications Logs',
                url: '/support-page/notifications-logs',
                isActive: true,
                isMenu: false,
                menuOrder: 0.017,
                menuIcon: 'NotificationOutlined',
                roleRight: ['ROOT'],
                forcedUpdate: setEsNotificationsLogsForcedUpdateTime,
            },
            {
                id: '0004-Support-page-ES-Plus-Notifications-QR-Codes-List',
                componentName: 'ESPlusNotificationQRcodes',
                title: 'ES2+ Logs Notification QR-codes',
                url: '/support-page/notifications-qrcodes',
                isActive: true,
                isMenu: false,
                menuOrder: 0.017,
                menuIcon: 'NotificationOutlined',
                roleRight: ['ROOT'],
                forcedUpdate: setEsNotificationsQrcodesForcedUpdateTime,
            },
            {
                id: '0004-Support-page-External-API',
                componentName: 'ExternalAPIEdit',
                title: 'External API' as ITitlePage,
                url: '/support-page/external-api/:id',
                isActive: true,
                isDefault: false,
                menuIcon: 'EyeOutlined',
            },
            {
                id: '0004-Support-page-operator-interactions-files-List',
                componentName: 'OperatorInteractionsFilesList',
                title: 'Operator Interactions Files List',
                url: '/operator_interactions/files',
                isActive: true,
                isMenu: false,
                menuOrder: 0.017,
                menuIcon: 'FileExcelOutlined',
                roleRight: ['ROOT'],
                forcedUpdate: setForcedFilesListUpdateTime,
            },
            {
                id: '0004-Support-page-operator-interactions-responses-List',
                componentName: 'OperatorInteractionsResponsesList',
                title: 'Operator Interactions Responses List',
                url: '/operator_interactions/responses',
                isActive: true,
                isMenu: false,
                menuOrder: 0.018,
                menuIcon: 'ApiOutlined',
                roleRight: ['ROOT'],
                forcedUpdate: setForcedResponsesListUpdateTime,
            },
            {
                id: '0004-Support-page-operator-interactions-responses',
                componentName: 'OperatorInteractionsResponse',
                title: 'Operator Interactions Response',
                url: '/operator_interactions/responses/:id',
                isActive: true,
                isDefault: false,
                menuIcon: 'EyeOutlined',
            },
        ],
    },
    {
        id: '0000.02-Separator',
        componentName: 'Separator',
        url: '#',
        isActive: false,
        isMenu: true,
        menuOrder: 0.02,
        isMenuSeparator: true,
        roleRight: ['ROOT'],
    },
    {
        id: '0000-MyMenu',
        componentName: 'My',
        title: 'MY_PROFILE_NAME',
        url: '#',
        isActive: true,
        isDefault: false,
        isMenu: true,
        menuOrder: 0.4,
        menuIcon: 'SolutionOutlined',
        subMenu: [
            {
                id: '0002-Profile-List',
                componentName: 'MyProfileList',
                url: '#',
            },
            {
                id: '0002-Profile-view',
                componentName: 'Profile',
                title: 'Profile',
                url: '/profile',
                isActive: true,
                isDefault: false,
                isMenu: true,
                menuOrder: 0.41,
                menuIcon: 'UserOutlined',
            },
            {
                id: '0002-Profile-edit',
                componentName: 'ProfileEdit',
                title: 'Edit Profile',
                url: '/profile/edit',
                isActive: true,
                isDefault: false,
                menuIcon: 'FormOutlined',
            },
            {
                id: '0002-MyCompany-List',
                componentName: 'MyCompanyList',
                url: '#',
            },
            {
                id: '0002-MyCompany-view',
                componentName: 'MyCompany',
                title: 'My Company',
                url: '/my_company',
                isActive: true,
                isDefault: false,
                isMenu: true,
                menuOrder: 0.42,
                menuIcon: 'BankOutlined',
            },
            {
                id: '0002-MyCompany-edit',
                componentName: 'MyCompanyEdit',
                title: 'Edit My Company',
                url: '/my_company/edit',
                isActive: true,
                isDefault: false,
                isMenu: false,
                menuOrder: 0.43,
                menuIcon: 'FormOutlined',
            },
            {
                id: '0002-MyAlarms',
                componentName: 'MyAlarms',
                title: 'My Alarms',
                url: '/my_alarms',
                isActive: true,
                isDefault: false,
                isMenu: true,
                menuOrder: 0.44,
                menuIcon: 'NotificationOutlined',
            },
        ],
    },
    {
        id: '0000.99-Separator',
        componentName: 'Separator',
        url: '#',
        isActive: true,
        isMenu: true,
        menuOrder: 0.99,
        isMenuSeparator: true,
    },
    {
        id: '0001-Dashboard-list',
        componentName: 'Dashboard',
        title: 'Dashboard',
        url: '/',
        isActive: true,
        isDefault: true,
        isMenu: true,
        menuOrder: 1,
        menuIcon: 'DashboardOutlined',
    },
    {
        id: '0003-Users-list',
        componentName: 'UsersList',
        title: 'Users',
        url: '/users',
        isActive: true,
        isDefault: false,
        isMenu: true,
        menuOrder: 3,
        menuIcon: 'TeamOutlined',
        roleRightDelete: ['ADMIN'],
        forcedUpdate: setUsersForcedUpdateTime,
    },
    {
        id: '0003-Users-new',
        componentName: 'UserNew',
        title: 'New User',
        url: '/users/new',
        isActive: true,
        isDefault: false,
        roleRight: ['ADMIN'],
        menuIcon: 'PlusSquareOutlined',
    },
    {
        id: '0003-Users-edit',
        componentName: 'UserEdit',
        title: 'Edit User',
        url: '/users/:id/edit',
        isActive: true,
        isDefault: false,
        roleRight: ['ADMIN'],
        menuIcon: 'FormOutlined',
    },
    {
        id: '0003-Users-view',
        componentName: 'User',
        title: 'User',
        url: '/users/:id',
        isActive: true,
        isDefault: false,
        menuIcon: 'EyeOutlined',
    },
    {
        id: '0004-Companies-list',
        componentName: 'CompaniesList',
        title: 'Companies',
        url: '/companies',
        isActive: true,
        isDefault: false,
        isMenu: true,
        menuOrder: 4,
        menuIcon: 'BankOutlined',
        roleRightDelete: ['LORD'],
        forcedUpdate: setCompaniesForcedUpdateTime,
        companyTypeDenied: ['MNO'],
    },
    {
        id: '0004-Companies-hierarchy',
        componentName: 'CompaniesHierarchy',
        title: 'Companies by Hierarchy',
        url: '/companies/hierarchy',
        isActive: true,
        isDefault: false,
        companyTypeDenied: ['MNO'],
        menuIcon: 'PartitionOutlined',
    },
    {
        id: '0004-Companies-new',
        componentName: 'CompanyNew',
        title: 'New Company',
        url: '/companies/new',
        isActive: true,
        isDefault: false,
        roleRight: ['ADMIN'],
        companyTypeDenied: ['MNO'],
        menuIcon: 'PlusSquareOutlined',
    },
    {
        id: '0004-Companies-view',
        componentName: 'Company',
        title: 'Company',
        url: '/companies/:id',
        isActive: true,
        isDefault: false,
        companyTypeDenied: ['MNO'],
        menuIcon: 'EyeOutlined',
    },
    {
        id: '0004-Companies-edit',
        componentName: 'CompanyEdit',
        title: 'Edit Company',
        url: '/companies/:id/edit',
        isActive: true,
        isDefault: false,
        roleRight: ['ADMIN'],
        companyTypeDenied: ['MNO'],
        menuIcon: 'FormOutlined',
    },
    {
        id: '0004.9-Separator',
        componentName: 'Separator',
        url: '#',
        isActive: true,
        isMenu: true,
        menuOrder: 4.9,
        isMenuSeparator: true,
    },
    {
        id: '0005-CardSets-list',
        componentName: 'CardSetsList',
        title: 'Card Sets',
        url: '/card_sets',
        isActive: true,
        isDefault: false,
        isMenu: true,
        menuOrder: 5,
        menuIcon: 'GroupOutlined',
        forcedUpdate: setCardSetsForcedUpdateTime,
        companyTypeDenied: ['MNO'],
    },
    {
        id: '0005-CardSets-new',
        componentName: 'CardSetNew',
        title: 'New Card Set',
        url: '/card_sets/new',
        isActive: true,
        isDefault: false,
        companyTypeDenied: ['MNO'],
        menuIcon: 'PlusSquareOutlined',
    },
    {
        id: '0005-CardSets-view',
        componentName: 'CardSet',
        title: 'Card Set',
        url: '/card_sets/:id',
        isActive: true,
        isDefault: false,
        companyTypeDenied: ['MNO'],
        menuIcon: 'EyeOutlined',
    },
    {
        id: '0005-CardSets-edit',
        componentName: 'CardSetEdit',
        title: 'Edit Card Set',
        url: '/card_sets/:id/edit',
        isActive: true,
        isDefault: false,
        companyTypeDenied: ['MNO'],
        menuIcon: 'FormOutlined',
    },
    {
        id: '0006-Cards-list',
        componentName: 'CardList',
        title: 'Cards',
        url: '/cards',
        isActive: true,
        isDefault: false,
        isMenu: true,
        menuOrder: 6,
        menuIcon: 'AppstoreOutlined',
        forcedUpdate: setCardsForcedUpdateTime,
    },
    {
        id: '0006-Cards-view',
        componentName: 'Card',
        title: 'Card',
        url: '/cards/:id',
        isActive: true,
        isDefault: false,
        menuIcon: 'EyeOutlined',
    },
    {
        id: '0006-Cards-edit',
        componentName: 'CardEdit',
        title: 'Edit Card',
        url: '/cards/:id/edit',
        isActive: true,
        isDefault: false,
        companyTypeDenied: ['MNO'],
        menuIcon: 'FormOutlined',
    },
    {
        id: '0007-CallDataRecordsShow-list',
        componentName: 'CallDataRecordsList',
        title: 'Call Data Record',
        url: '/call_data_records',
        isActive: true,
        isDefault: false,
        isMenu: true,
        menuOrder: 7,
        menuIcon: 'DatabaseOutlined',
        roleRightDelete: ['ROOOOT'],
        forcedUpdate: setCallDataRecordsForcedUpdateTime,
    },
    // Beta
    {
        id: '0008-CallDataRecordsBeta-list',
        componentName: 'CallDataRecordsListBeta',
        title: 'Call Data Records Beta',
        url: '/call_data_records_beta',
        isActive: true,
        isDefault: false,
        isMenu: true,
        menuOrder: 8,
        menuIcon: 'DatabaseOutlined',
        roleRight: ['LORD'],
        roleRightDelete: ['ROOOOT'],
        forcedUpdate: setCallDataRecordsForcedUpdateTimeBeta,
    },
    {
        id: '0008-BalanceLog-list',
        componentName: 'BalanceLogList',
        title: 'Balance log',
        url: '/balance_log',
        isActive: true,
        isDefault: false,
        isMenu: true,
        menuOrder: 8,
        menuIcon: 'SwapOutlined',
        roleRightDelete: ['ROOOOT'],
        forcedUpdate: setBalanceLogForcedUpdateTime,
        companyTypeDenied: ['MNO'],
    },
    {
        id: '0008.7-ActivityLog-list',
        componentName: 'ActivityLogList',
        title: 'Activity log',
        url: '/activity_log',
        isActive: true,
        isDefault: false,
        isMenu: true,
        menuOrder: 8.7,
        menuIcon: 'SwapOutlined',
        roleRight: ['ROOT'],
        roleRightDelete: ['ROOOOT'],
        forcedUpdate: setActivityLogForcedUpdateTime,
        companyTypeDenied: ['MNO'],
    },
    {
        id: '0008.8-PackagesUnitsLogs-list',
        componentName: 'PackagesUnitsLogsList',
        title: 'Packages Units Logs',
        url: '/packages_units_logs',
        isActive: true,
        isDefault: false,
        isMenu: true,
        menuOrder: 8.8,
        menuIcon: 'SwapOutlined',
        roleRight: ['ADMIN'],
        roleRightDelete: ['ROOOOT'],
        forcedUpdate: setPackagesUnitsLogsForcedUpdateTime,
        companyTypeRight: ['SKYMOND', 'RESELLER', 'SYSTEM INTEGRATOR', 'MVNO', 'END-USER'],
    },
    {
        id: '0009-Finances-list',
        componentName: 'Finances',
        title: 'Finances',
        url: '/finances',
        isActive: true,
        isDefault: false,
        isMenu: true,
        menuOrder: 9,
        menuIcon: 'FundViewOutlined',
    },
    {
        id: '0009.9-MyPackages',
        componentName: 'Packages',
        title: 'My Packages',
        url: '#',
        isActive: true,
        isDefault: false,
        isMenu: true,
        menuOrder: 9.9,
        menuIcon: 'ShoppingOutlined',
        roleRight: ['ADMIN'],
        companyTypeRight: ['SKYMOND', 'RESELLER', 'SYSTEM INTEGRATOR', 'MVNO', 'END-USER'],
        subMenu: [
            {
                id: '0023-Packages-buy',
                componentName: 'PackagesBuy',
                title: 'Buy Packages',
                url: '/packages/buy',
                isActive: true,
                isDefault: false,
                isMenu: true,
                menuOrder: 23.1,
                menuIcon: 'ImportOutlined',
                roleRight: ['ADMIN'],
                roleRightDelete: ['ADMIN'],
                companyTypeRight: ['SKYMOND', 'RESELLER', 'SYSTEM INTEGRATOR', 'MVNO', 'END-USER'],
            },
            {
                id: '0023-Packages-Instance-view',
                componentName: 'PackageInstance',
                title: 'Show Package Instance',
                url: '/packages-instance/:id',
                isActive: true,
                isDefault: false,
                roleRight: ['ADMIN'],
                companyTypeRight: ['SKYMOND', 'RESELLER', 'SYSTEM INTEGRATOR', 'MVNO', 'END-USER'],
                menuIcon: 'EyeOutlined',
            },
            {
                id: '0023-Packages-Instance-edit',
                componentName: 'PackageInstanceEdit',
                title: 'Edit Package',
                url: '/packages-instance/:id/edit',
                isActive: true,
                isDefault: false,
                roleRight: ['ADMIN'],
                companyTypeRight: ['SKYMOND', 'RESELLER', 'SYSTEM INTEGRATOR', 'MVNO', 'END-USER'],
                menuIcon: 'FormOutlined',
            },
            {
                id: '0023-Packages-sell',
                componentName: 'PackagesSell',
                title: 'Sell Packages',
                url: '/packages/sell',
                isActive: true,
                isDefault: false,
                isMenu: true,
                menuOrder: 23.2,
                menuIcon: 'ExportOutlined',
                roleRight: ['ADMIN'],
                roleRightDelete: ['ADMIN'],
                companyTypeRight: ['SKYMOND', 'RESELLER', 'SYSTEM INTEGRATOR', 'MVNO'],
            },
            {
                id: '0023-Packages-new',
                componentName: 'PackageNew',
                title: 'New Package',
                url: '/packages/new',
                isActive: true,
                isDefault: false,
                roleRight: ['ADMIN'],
                companyTypeRight: ['SKYMOND', 'RESELLER', 'SYSTEM INTEGRATOR', 'MVNO'],
                menuIcon: 'PlusSquareOutlined',
            },
            {
                id: '0023-Packages-edit',
                componentName: 'PackageEdit',
                title: 'Edit Package',
                url: '/packages/:id/edit',
                isActive: true,
                isDefault: false,
                roleRight: ['ADMIN'],
                companyTypeRight: ['SKYMOND', 'RESELLER', 'SYSTEM INTEGRATOR', 'MVNO'],
                menuIcon: 'FormOutlined',
            },
            {
                id: '0023-Packages-view',
                componentName: 'PackageRoot',
                title: 'Show Package',
                url: '/packages/:id',
                isActive: true,
                isDefault: false,
                roleRight: ['ADMIN'],
                companyTypeRight: ['SKYMOND', 'RESELLER', 'SYSTEM INTEGRATOR', 'MVNO'],
                menuIcon: 'EyeOutlined',
            },
            {
                id: '0023-Package-Instance-Buy',
                componentName: 'PackageInstanceBuy',
                title: 'Buy Package',
                url: '/packages-instance/:id/buy',
                isActive: true,
                isDefault: false,
                companyTypeRight: ['SKYMOND', 'RESELLER', 'SYSTEM INTEGRATOR', 'MVNO', 'END-USER'],
                menuIcon: 'EuroOutlined',
            },
            {
                id: '0023-Package-Instance-Buy-Preview',
                componentName: 'PackageInstanceBuyPreview',
                title: 'Package Preview',
                url: '/packages-instance/:id/preview',
                isActive: true,
                isDefault: false,
                companyTypeRight: ['SKYMOND', 'RESELLER', 'SYSTEM INTEGRATOR', 'MVNO', 'END-USER'],
                menuIcon: 'EyeOutlined',
            },
        ],
    },
    {
        id: '0009.9-Separator',
        componentName: 'Separator',
        url: '#',
        isActive: true,
        isMenu: true,
        menuOrder: 9.9,
        isMenuSeparator: true,
        roleRight: ['ADMIN'],
    },
    {
        id: '0010.0-Geotracking',
        componentName: 'GeoTracking',
        title: 'Geo-tracking',
        url: '/geo-tracking',
        isActive: true,
        isDefault: false,
        isMenu: true,
        menuOrder: 10,
        menuIcon: 'AimOutlined',
        roleRight: ['ADMIN'],
    },
    {
        id: '0013-Operators-list',
        componentName: 'OperatorsList',
        title: 'Operators',
        url: '/operators',
        isActive: true,
        isDefault: false,
        isMenu: true,
        menuOrder: 13,
        menuIcon: 'PhoneOutlined',
        roleRight: ['LORD'],
        roleRightDelete: ['LORD'],
        forcedUpdate: setOperatorsForcedUpdateTime,
        companyTypeDenied: ['MNO'],
    },
    {
        id: '0013-Operators-new',
        componentName: 'OperatorNew',
        title: 'New Operator',
        url: '/operators/new',
        isActive: true,
        isDefault: false,
        roleRight: ['LORD'],
        companyTypeDenied: ['MNO'],
        menuIcon: 'PlusSquareOutlined',
    },
    {
        id: '0013-Operators-view',
        componentName: 'Operator',
        title: 'Operator',
        url: '/operators/:id',
        isActive: true,
        isDefault: false,
        roleRight: ['LORD'],
        companyTypeDenied: ['MNO'],
        menuIcon: 'EyeOutlined',
    },
    {
        id: '0013-Operators-edit',
        componentName: 'OperatorEdit',
        title: 'Edit Operator',
        url: '/operators/:id/edit',
        isActive: true,
        isDefault: false,
        roleRight: ['LORD'],
        companyTypeDenied: ['MNO'],
        menuIcon: 'FormOutlined',
    },
    {
        id: '0014-SalesRateLists-list',
        componentName: 'SalesRateLists',
        title: 'Rates',
        url: '/sales_rate_lists',
        isActive: true,
        isDefault: false,
        isMenu: true,
        menuOrder: 14,
        menuIcon: 'WalletOutlined',
        roleRight: ['ADMIN'],
        roleRightDelete: ['ADMIN'],
        forcedUpdate: setRatesForcedUpdateTime,
        companyTypeDenied: ['MNO'],
    },
    {
        id: '0014-SalesRateLists-new',
        componentName: 'SalesRateListNew',
        title: 'New Sales Rate List',
        url: '/sales_rate_lists/new',
        isActive: true,
        isDefault: false,
        roleRight: ['ADMIN'],
        companyTypeDenied: ['MNO'],
        menuIcon: 'PlusSquareOutlined',
    },
    {
        id: '0014-SalesRateLists-edit',
        componentName: 'SalesRateListEdit',
        title: 'Edit Sales Rate List',
        url: '/sales_rate_lists/:id/edit',
        isActive: true,
        isDefault: false,
        roleRight: ['ADMIN'],
        companyTypeDenied: ['MNO'],
        menuIcon: 'FormOutlined',
    },
    {
        id: '0014-SalesRateLists-view',
        componentName: 'SalesRateList',
        title: 'Sales Rate List',
        url: '/sales_rate_lists/:id',
        isActive: true,
        isDefault: false,
        roleRight: ['ADMIN'],
        companyTypeDenied: ['MNO'],
        menuIcon: 'EyeOutlined',
    },
    {
        id: '0015-PurchaseRates-list',
        componentName: 'PurchaseRatesList',
        title: 'Purchase Rates',
        url: '/purchase_rate_lists',
        isActive: true,
        isDefault: false,
        isMenu: true,
        menuOrder: 15,
        menuIcon: 'ShoppingCartOutlined',
        roleRight: ['LORD', 'ADMIN'],
        roleRightDelete: ['LORD'],
        companyTypeRight: ['SKYMOND', 'MNO'],
        forcedUpdate: setPurchaseRatesForcedUpdateTime,
    },
    {
        id: '0015-PurchaseRates-new',
        componentName: 'PurchaseRateListNew',
        title: 'New Purchase Rate List',
        url: '/purchase_rate_lists/new',
        isActive: true,
        isDefault: false,
        roleRight: ['LORD'],
        menuIcon: 'PlusSquareOutlined',
    },
    {
        id: '0015-PurchaseRates-edit',
        componentName: 'PurchaseRateListEdit',
        title: 'Edit Purchase Rate List',
        url: '/purchase_rate_lists/:id/edit',
        isActive: true,
        isDefault: false,
        roleRight: ['LORD'],
        menuIcon: 'FormOutlined',
    },
    {
        id: '0015-PurchaseRates-view',
        componentName: 'PurchaseRateList',
        title: 'Purchase Rate List',
        url: '/purchase_rate_lists/:id',
        isActive: true,
        isDefault: false,
        roleRight: ['LORD', 'ADMIN'],
        companyTypeRight: ['SKYMOND', 'MNO'],
        menuIcon: 'EyeOutlined',
    },
    {
        id: '0016-Orders-list',
        componentName: 'OrdersList',
        title: 'Orders',
        url: '/orders',
        isActive: true,
        isDefault: false,
        isMenu: true,
        menuOrder: 16,
        menuIcon: 'AccountBookOutlined',
        roleRight: ['LORD'],
        roleRightDelete: ['ROOOOT'],
        forcedUpdate: setOrdersForcedUpdateTime,
        companyTypeDenied: ['MNO'],
    },
    {
        id: '0016-Orders-view',
        componentName: 'OrderView',
        title: 'Order',
        url: '/orders/:id',
        isActive: true,
        roleRight: ['ADMIN'],
        companyTypeDenied: ['MNO'],
        menuIcon: 'EyeOutlined',
    },
    {
        id: '0016-Orders-External',
        componentName: 'OrderExternal',
        title: 'External Order',
        url: '/order_external',
        isActive: true,
        roleRight: ['ADMIN'],
        companyTypeDenied: ['MNO'],
        menuIcon: 'ImportOutlined',
    },
    {
        id: '0016-Orders-NewOrder',
        componentName: 'OrderNew',
        title: 'New Order',
        url: '/company/:id/order/:by',
        isActive: true,
        roleRight: ['ADMIN'],
        companyTypeDenied: ['MNO'],
        menuIcon: 'CreditCardOutlined',
    },
    {
        id: '0015.9-Separator',
        componentName: 'Separator',
        url: '#',
        isActive: true,
        isMenu: true,
        menuOrder: 16.9,
        isMenuSeparator: true,
    },
    {
        id: '0017-Zones-list',
        componentName: 'ZonesList',
        title: 'Zones',
        url: '/zones',
        isActive: true,
        isDefault: false,
        isMenu: true,
        menuOrder: 17,
        menuIcon: 'GlobalOutlined',
        roleRight: ['LORD'],
        roleRightDelete: ['ROOOOT'],
        forcedUpdate: setZonesForcedUpdateTime,
        companyTypeDenied: ['MNO'],
    },
    {
        id: '0017-Zones-view',
        componentName: 'Zone',
        title: 'Zone',
        url: '/zones/:id',
        isActive: true,
        isDefault: false,
        roleRight: ['LORD'],
        companyTypeDenied: ['MNO'],
        menuIcon: 'EyeOutlined',
    },
    {
        id: '0018-Countries-list',
        componentName: 'CountryList',
        title: 'Countries',
        url: '/countries',
        isActive: true,
        isDefault: false,
        isMenu: true,
        menuOrder: 18,
        menuIcon: 'EnvironmentOutlined',
        roleRight: ['LORD'],
        roleRightDelete: ['LORD'],
        forcedUpdate: setCountriesForcedUpdateTime,
        companyTypeDenied: ['MNO'],
    },
    {
        id: '0018-Countries-new',
        componentName: 'CountryNew',
        title: 'New Country',
        url: '/countries/new',
        isActive: true,
        isDefault: false,
        roleRight: ['LORD'],
        companyTypeDenied: ['MNO'],
        menuIcon: 'PlusSquareOutlined',
    },
    {
        id: '0018-Countries-edit',
        componentName: 'CountryEdit',
        title: 'Edit Country',
        url: '/countries/:id/edit',
        isActive: true,
        isDefault: false,
        roleRight: ['LORD'],
        companyTypeDenied: ['MNO'],
        menuIcon: 'FormOutlined',
    },
    {
        id: '0018-Countries-view',
        componentName: 'Country',
        title: 'Country',
        url: '/countries/:id',
        isActive: true,
        isDefault: false,
        roleRight: ['ADMIN'],
        companyTypeDenied: ['MNO'],
        menuIcon: 'EyeOutlined',
    },
    {
        id: '0019-Currencies-list',
        componentName: 'CurrenciesList',
        title: 'Currencies',
        url: '/currencies',
        isActive: true,
        isDefault: false,
        isMenu: true,
        menuOrder: 19,
        menuIcon: 'EuroOutlined',
        roleRight: ['LORD'],
        roleRightDelete: ['ROOOOT'],
        forcedUpdate: setCurrenciesForcedUpdateTime,
        companyTypeDenied: ['MNO'],
    },
    {
        id: '0019-Currencies-view',
        componentName: 'Currency',
        title: 'Currency',
        url: '/currencies/:id',
        isActive: true,
        isDefault: false,
        roleRight: ['LORD'],
        companyTypeDenied: ['MNO'],
        menuIcon: 'EyeOutlined',
    },
    {
        id: '0019-Currencies-edit',
        componentName: 'CurrencyEdit',
        title: 'Edit Currency',
        url: '/currencies/:id/edit',
        isActive: true,
        isDefault: false,
        roleRight: ['LORD'],
        companyTypeDenied: ['MNO'],
        menuIcon: 'FormOutlined',
    },
    {
        id: '0020-Subscribers-list',
        componentName: 'SubscribersList',
        title: 'Subscribers',
        url: '/subscribers',
        isActive: true,
        isDefault: false,
        isMenu: true,
        menuOrder: 20,
        menuIcon: 'LinkOutlined',
        roleRight: ['LORD', 'ADMIN'],
        roleRightDelete: ['ROOOOT'],
        companyTypeRight: ['SKYMOND', 'MNO'],
        forcedUpdate: setSubscribersForcedUpdateTime,
    },
    {
        id: '0020-Subscribers-new',
        componentName: 'SubscriberNew',
        title: 'New Subscriber',
        url: '/subscribers/new',
        isActive: true,
        isDefault: false,
        roleRight: ['ROOOOT'],
        menuIcon: 'PlusSquareOutlined',
    },
    {
        id: '0020-Subscribers-edit',
        componentName: 'SubscriberEdit',
        title: 'Edit Subscribers',
        url: '/subscribers/:id/edit',
        isActive: true,
        isDefault: false,
        roleRight: ['ADMIN'],
        menuIcon: 'FormOutlined',
    },
    {
        id: '0020-Subscribers-view',
        componentName: 'Subscriber',
        title: 'Subscriber',
        url: '/subscribers/:id',
        isActive: true,
        isDefault: false,
        roleRight: ['ADMIN'],
        companyTypeRight: ['SKYMOND', 'MNO'],
        menuIcon: 'EyeOutlined',
    },
    // {
    //     id: '0021-DiameterSessions-list',
    //     componentName: 'DiameterSessionsList',
    //     title: 'CCR Sessions',
    //     url: '/diameter_sessions',
    //     isActive: true,
    //     isDefault: false,
    //     isMenu: true,
    //     menuOrder: 21,
    //     menuIcon: 'ApiOutlined',
    //     roleRight: ['ADMIN'],
    //     roleRightListAction: {
    //         VIEW: ['ADMIN'],
    //         NEW: ['ADMIN'],
    //         EDIT: ['ADMIN'],
    //     },
    //     forcedUpdate: setDiameterSessionUpdateTime,
    //     companyTypeDenied: ['MNO'],
    // },
    {
        id: '0022-Reports-page',
        componentName: 'ReportsPageList',
        title: 'Reports',
        url: '/reports',
        isActive: true,
        isDefault: false,
        isMenu: true,
        menuOrder: 22,
        menuIcon: 'FileDoneOutlined',
        roleRight: ['ADMIN'],
        roleRightDelete: ['ROOOOT'],
        forcedUpdate: setReportsForcedUpdateTime,
    },
    {
        id: '0022.9-Separator',
        componentName: 'Separator',
        url: '#',
        isActive: true,
        isMenu: true,
        menuOrder: 22.9,
        isMenuSeparator: true,
        roleRight: ['ADMIN'],
    },
    // ------------------------------------------------------------
    {
        id: '0099-About-page',
        componentName: 'About',
        title: 'About',
        url: '/about',
        isActive: true,
        isDefault: false,
        isMenu: true,
        menuOrder: 99,
        menuIcon: 'InfoCircleOutlined',
    },
];

const addDefaultAppStatus = (pages: IPage[]): IPage[] => {
    return pages.map((p: IPage) => ({
        ...p,
        appStatus: p.appStatus ? p.appStatus : 'READY',
        ...{ subMenu: p.subMenu && p.subMenu.length ? addDefaultAppStatus(p.subMenu) : undefined },
    }));
};
export const pagesMap: IPage[] = addDefaultAppStatus(pagesBook);

const calcAllPageList = (pages: IPage[]): IPage[] => {
    let list: IPage[] = [];
    pages.forEach((p: IPage) => {
        list.push(p);
        if (p.subMenu && Array.isArray(p.subMenu) && p.subMenu.length) {
            list = list.concat(calcAllPageList(p.subMenu));
        }
    });

    return list;
};
const allPageList = calcAllPageList(pagesBook);

export class ChildrenOfParentsPages {
    private readonly _map: IParentPages = {
        ActivityLogList: {},
        BalanceLogList: {},
        CallDataRecordsList: {},
        CallDataRecordsListBeta: {},
        CardList: { VIEW: 'Card', EDIT: 'CardEdit' },
        CardSetsList: { VIEW: 'CardSet', EDIT: 'CardSetEdit', NEW: 'CardSetNew' },
        CompaniesList: { VIEW: 'Company', EDIT: 'CompanyEdit', NEW: 'CompanyNew' },
        CountryList: { VIEW: 'Country', EDIT: 'CountryEdit', NEW: 'CountryNew' },
        CurrenciesList: { VIEW: 'Currency', EDIT: 'CurrencyEdit' },
        DiameterSessionsList: { VIEW: 'DiameterSessionsList', EDIT: 'DiameterSessionsList' },
        ExternalAPIList: { EDIT: 'ExternalAPIEdit' },
        FeatureFlags: { EDIT: 'FeatureFlagsEdit' },
        ImpersonateUser: {},
        GatewayConfig: {},
        ESPlusNotifications: {},
        ESPlusNotificationsLogs: {},
        ESPlusNotificationQRcodes: {},
        ScpFileUpload: {},
        MyCompany: { VIEW: 'MyCompany', EDIT: 'MyCompanyEdit' },
        OperatorInteractionsResponsesList: { VIEW: 'OperatorInteractionsResponse' },
        OperatorsList: { VIEW: 'Operator', EDIT: 'OperatorEdit', NEW: 'OperatorNew' },
        OrdersList: { VIEW: 'OrderView', EDIT: 'OrderView' },
        PackagesBuy: { VIEW: 'PackageInstance', EDIT: 'PackageInstanceEdit' },
        PackagesSell: { VIEW: 'PackageRoot', EDIT: 'PackageEdit', NEW: 'PackageNew' },
        PackagesUnitsLogsList: { VIEW: 'PackageInstanceBuyPreview' },
        Profile: { VIEW: 'Profile', EDIT: 'ProfileEdit' },
        PurchaseRatesList: { VIEW: 'PurchaseRateList', EDIT: 'PurchaseRateListEdit', NEW: 'PurchaseRateListNew' },
        ReportsPageList: {},
        SalesRateLists: { VIEW: 'SalesRateList', EDIT: 'SalesRateListEdit', NEW: 'SalesRateListNew' },
        SubscribersList: { VIEW: 'Subscriber', EDIT: 'SubscriberEdit', NEW: 'SubscriberNew' },
        UsersList: { VIEW: 'User', EDIT: 'UserEdit', NEW: 'UserNew' },
        ZonesList: { VIEW: 'Zone' },
    };

    private checkParent(parentPage: IPageList): void {
        if (!this._map[parentPage] && process.env.NODE_ENV === 'development') {
            console.error(`Parent page: "${parentPage}" not found`);
        }
    }

    public getParent(page: IComponentName): IPageList | undefined {
        let foundParent = undefined;
        Object.entries(this._map).forEach(([parent, children]) => {
            if (parent === page) {
                foundParent = page;
            }
            if (children) {
                Object.entries(children).forEach(([, child]) => {
                    if (child === page) {
                        foundParent = parent;
                    }
                });
            }
        });
        return (foundParent || page) as IPageList | undefined;
    }

    public getChildren(parentPage: IPageList): IChildrenActionPage | undefined {
        this.checkParent(parentPage);
        return this._map[parentPage];
    }

    public getChildrenOfTypeByParents(parentPage: IPageList, type: IPageAction): IComponentName | undefined {
        this.checkParent(parentPage);
        return this._map[parentPage]?.[type];
    }

    public getChildrenOfTypeByChildren(page: IComponentName, type: IPageAction): IComponentName | undefined {
        let childrenByType: IComponentName | undefined = undefined;
        for (const [parentPage, children] of Object.entries(this._map)) {
            let isFoundParent = false;
            for (const [, child] of Object.entries(children as IChildrenActionPage)) {
                if (child === page) {
                    isFoundParent = true;
                }
            }
            if (isFoundParent) {
                childrenByType = this._map[parentPage as IPageList]?.[type];
            }
        }
        return childrenByType;
    }
}

export const getReplaceComponentName = (p: IComponentName): IComponentName => {
    return p === 'MyProfileList' ? 'UsersList' : p === 'MyCompanyList' ? 'CompaniesList' : p;
};

export const getComponentInfo = (page: IComponentName): IPage => {
    return allPageList.find((c: IPage) => c.componentName === page) as IPage;
};

export const getParenName = (childrenPage: IComponentName): IPageList | undefined => {
    const childrenOfParentsPages = new ChildrenOfParentsPages();
    return childrenOfParentsPages.getParent(childrenPage);
};

export const getParenIdOfChildren = (childrenPage: IComponentName): string | undefined => {
    const parentName = getParenName(childrenPage);
    return parentName && getComponentInfo(parentName).id;
};

export const getChildren = (parentPage: IPageList): IChildrenActionPage | undefined => {
    const childrenOfParentsPages = new ChildrenOfParentsPages();
    return childrenOfParentsPages.getChildren(parentPage);
};

export const getChildrenOfTypeByChildren = (
    childrenPage: IComponentName,
    type: IPageAction,
): IComponentName | undefined => {
    const childrenOfParentsPages = new ChildrenOfParentsPages();
    return childrenOfParentsPages.getChildrenOfTypeByChildren(childrenPage, type);
};
