import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Button, Form } from 'antd';
import { PauseCircleOutlined } from '@ant-design/icons';
import { Catcher, FilterDateRange, FormSelect } from '../../index';
import cx from 'classnames';
import { IDateRange } from '../../../interfaces';
import {
    useCardsSelect,
    useGeotrackingTrackSearchParams,
    useGeotrackingTrackedCardId,
    useIsGeotrackingMode,
} from '../../../hooks';
import {
    apiMessagesGetFormRules,
    setCardHistoryLocation, // clearTrackingTimer,
    setGeoTrackingTrackSearchParams,
    setIsGeotrackingMode,
    setTrackedCardId,
} from '../../../middleware';
import { tB, tF } from '../../../translate';
import ToolBarStyles from '../../FilterToolbar/m_styles.less';
import TrackingStyles from '../m_geoTrackingStyles.less';

export const GeoTrackingTrackFilter = observer(() => {
    const [form] = Form.useForm();
    const searchParams = useGeotrackingTrackSearchParams();
    const isGeotrackingMode = useIsGeotrackingMode();
    const cardId = useGeotrackingTrackedCardId();
    const initialValues = {
        cardId,
    };

    const handleSelectCard = (ids: Array<string> | string): void => {
        setTrackedCardId(Array.isArray(ids) ? ids[0] : ids);
        setGeoTrackingTrackSearchParams({});
    };

    const handleDate = (date: IDateRange): void => {
        setGeoTrackingTrackSearchParams(date);
    };

    const handleClick = (): void => {
        setTrackedCardId(null);
        setIsGeotrackingMode(false);
        setGeoTrackingTrackSearchParams({});
        setCardHistoryLocation([]);
        // clearTrackingTimer();
    };

    useEffect(() => {
        form.setFieldsValue(initialValues);
    }, [JSON.stringify(initialValues)]);

    return (
        <Catcher>
            <div>
                <Form
                    form={form}
                    name="geoTrackingCurrentCard"
                    initialValues={initialValues}
                    scrollToFirstError={true}
                    layout={'vertical'}
                    className={'formStyle'}
                    validateTrigger={'onBlur'}
                >
                    <FormSelect
                        fieldName={'cardId'}
                        label={cardId ? tF('Currently tracked card') : tF('Select a card for tracking')}
                        rules={apiMessagesGetFormRules}
                        useSelectHook={useCardsSelect}
                        onChange={handleSelectCard}
                        isNotClear
                    />
                </Form>
                {isGeotrackingMode ? (
                    <>
                        <div className={cx(ToolBarStyles.filterToolbarPlace, TrackingStyles.filterDataContainer)}>
                            <div className={ToolBarStyles.filterToolbar}>
                                <FilterDateRange
                                    onChange={handleDate}
                                    dateRange={searchParams}
                                    labelSpacing={'0 0 8px'}
                                />
                            </div>
                        </div>
                        <Button onClick={handleClick} type="primary" danger block icon={<PauseCircleOutlined />}>
                            {tB('Stop tracking')}
                        </Button>
                    </>
                ) : null}
            </div>
        </Catcher>
    );
});
