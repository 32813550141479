import React from 'react';
import { observer } from 'mobx-react';
import { Row } from 'antd';
import { Catcher } from '../../';
import { ShowPurchasesZoneTable } from './';
import { useZonesSelect } from '../../../hooks';

export const ShowPurchasesList: React.FC = observer(() => {
    const zones = useZonesSelect();

    const ShowPurchasesListJSX = zones.map((zone) => {
        return <ShowPurchasesZoneTable key={zone.id} zoneId={zone.id} name={zone.name} />;
    });

    return (
        <Catcher>
            <Row>{ShowPurchasesListJSX}</Row>
        </Catcher>
    );
});
