import React from 'react';
import { observer } from 'mobx-react';
import { Catcher, ListWrapper, ReportFilesToolBar } from '../';
import { appConfig } from '../../settings';
import { useCallDataRecordsFilterParams, useProfile } from '../../hooks';
import { cleanCallDataRecordsStore, getCallDataRecordsRelationSelect } from '../../middleware';
import { CallDataRecordsTableList } from './Components/';

const Buttons: React.FC = observer(() => {
    const { isCompanyTypeIdMNOHost } = useProfile();
    const filterParams = useCallDataRecordsFilterParams();
    return (
        <ReportFilesToolBar
            filterParams={filterParams}
            type={'POST_CALL_DATA_RECORDS'}
            isPdf={!isCompanyTypeIdMNOHost}
            isCsv={true}
        />
    );
});

export const CallDataRecordsList: React.FC = observer(() => {
    const filterParams = useCallDataRecordsFilterParams();
    const { companyId } = useProfile();

    return (
        <Catcher>
            <ListWrapper
                cleanStore={cleanCallDataRecordsStore}
                defaultParams={{ companyId }}
                filterParams={filterParams}
                getRelationSelect={getCallDataRecordsRelationSelect}
                months={appConfig.maximumMonthsForRequestForCDRs}
                RightButtons={Buttons}
            >
                <CallDataRecordsTableList />
            </ListWrapper>
        </Catcher>
    );
});
