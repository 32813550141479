import { ProfileStore } from '../stores/profile';
import { PurchasesStore } from '../stores/purchases';
import { setUiTotal } from './ui';
import { cleanZonesStore } from './zones';
import dayjs from 'dayjs';
import { appConfig } from '../settings';
import { apiManager } from '../REST';
import {
    IIncludeAssignedCompanyItemAPI,
    IItemAPI,
    IMyPurchaseRateListItemAPI,
    IMyPurchaseRatesListAPI,
    IParamsList,
    IPurchaseRateListItemAPI,
    IPurchaseRatesListAPI,
    IRequestRelationshipMainItem,
} from '../interfaces';
import { mapApi2UiField } from '../instruments';
import { tI } from '../translate';

function setPurchaseRatesStore(apiList: IPurchaseRatesListAPI): void {
    if (apiList.data) {
        const operators: IItemAPI[] = apiList?.included?.filter((i) => i.type === 'operator') || [];
        const StoreInstance = PurchasesStore.getInstance();
        StoreInstance.setList(
            apiList.data.map((item: IPurchaseRateListItemAPI) => {
                const operatorId = item.relationships?.operator?.data?.id;
                const operator = operators.find((i) => i.id === operatorId)?.attributes?.name;
                return {
                    id: item.id,
                    name: item.attributes?.name,
                    operatorId,
                    operator,
                };
            }),
        );
    }
}

export function setPurchaseRatesForcedUpdateTime(): void {
    const StoreInstance = PurchasesStore.getInstance();
    StoreInstance.setForcedUpdateTime();
}

export async function getPurchaseRatesList(params: IParamsList): Promise<void> {
    const { resp } = await apiManager.getApi('GET_PURCHASE_RATES_LIST', { searchParamsList: params });
    resp && setPurchaseRatesStore(resp as IPurchaseRatesListAPI);
    const { meta } = resp;
    meta && setUiTotal(meta.records_count);
}

export function cleanPurchaseRatesRelationStore(): void {
    cleanZonesStore();
}
export function cleanPurchaseRatesStore(): void {
    const StoreInstance = PurchasesStore.getInstance();
    StoreInstance.cleanStore();
    cleanPurchaseRatesRelationStore();
}

export function setMyPurchasesList(apiList: IMyPurchaseRatesListAPI): void {
    if (apiList.data) {
        const ProfileInstance = ProfileStore.getInstance();
        const userCompanyId = ProfileInstance.profile.companyId;
        const companies =
            (apiList?.included?.filter((i: IRequestRelationshipMainItem) => i.type === 'company') as IItemAPI[]) || [];
        const assignedCompanies =
            (
                apiList?.included?.filter(
                    (i: IRequestRelationshipMainItem) => i.type === 'assigned_company',
                ) as IIncludeAssignedCompanyItemAPI[]
            ).filter((i: IIncludeAssignedCompanyItemAPI) => i.relationships?.company?.data?.id === userCompanyId) || [];
        const assignedCompanyAttributes = mapApi2UiField({ ...assignedCompanies[0]?.attributes });
        const StoreInstance = PurchasesStore.getInstance();
        StoreInstance.setMyList(
            apiList.data.map((item: IMyPurchaseRateListItemAPI) => {
                const companyId = item.relationships?.company?.data?.id;
                const companyFound = companies.find((i) => i.id === companyId);
                const companyName = companyFound?.attributes?.name;
                return {
                    id: item.id,
                    name: item.attributes?.name,
                    companyId,
                    company: companyName,
                    startDate: assignedCompanyAttributes.startDate
                        ? dayjs(assignedCompanyAttributes.startDate).format(appConfig.formatDate)
                        : assignedCompanyAttributes.startDate,
                    endDate: assignedCompanyAttributes.endDate
                        ? dayjs(assignedCompanyAttributes.endDate).format(appConfig.formatDate)
                        : tI('Never'),
                };
            }),
        );
    }
}

export async function getMyPurchasesList(): Promise<void> {
    const { resp } = await apiManager.getApi('GET_MY_PURCHASES_LIST');
    setMyPurchasesList(resp as IMyPurchaseRatesListAPI);
}
