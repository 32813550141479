import React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import { TablePaginationConfig } from 'antd/es/table';
import { GroupOutlined } from '@ant-design/icons';
import { Catcher, LinkComponent, SkyPaginationTotal } from '../../';
import { CardLayout, cardLayoutOptions } from '../../Layout';
import { appConfig } from '../../../settings';
import { IColumnOptionComponent, ICountryActiveCardItem } from '../../../interfaces';
import { useCountryActiveCardsList } from '../../../hooks';
import { tF } from '../../../translate';

export const ShowCountryActiveCardsTable: React.FC<IColumnOptionComponent> = observer(() => {
    const pageSize = appConfig.pageDefaultParams.includeViewPageSize;
    const list = useCountryActiveCardsList();
    const tableColumns = [
        {
            title: tF('Card Name'),
            dataIndex: 'name',
        },
        {
            title: tF('Printed ID'),
            dataIndex: 'printedId',
        },
    ];

    const tableData = list.map((card: ICountryActiveCardItem) => ({
        key: card.id,
        name: (
            <span>
                <LinkComponent currentListComponent={'CardList'} id={card.id} name={card.name} />
            </span>
        ),
        printedId: (
            <span>
                <LinkComponent currentListComponent={'CardList'} id={card.id} name={card.printedId} />
            </span>
        ),
        eid: <span>{card.eid}</span>,
    }));

    const pagination: false | TablePaginationConfig =
        pageSize > list.length
            ? false
            : {
                  total: list.length,
                  pageSize: pageSize,
                  className: 'customPagination',
                  showSizeChanger: false,
                  size: 'default',
                  showTotal: SkyPaginationTotal,
              };

    return (
        <Catcher>
            <CardLayout
                columnOption={cardLayoutOptions.cardTableOperators}
                count={list.length}
                icon={<GroupOutlined />}
                idSpinners={['GET_COUNTRY_ACTIVE_CARDS', 'GET_USAGE_BIGQUERY_FLAG', 'GET_USAGE_CARDS_LIST']}
                isTable
                title={'Last Month Active Cards'}
            >
                <Table
                    dataSource={tableData}
                    columns={tableColumns}
                    pagination={pagination}
                    className={'table-theme'}
                    size={'small'}
                />
            </CardLayout>
        </Catcher>
    );
});
