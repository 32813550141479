import { appSignalSendMessage } from './appSignal';
import { appConfig } from '../settings';

export const getCurrencyShortCodeById = (id: string): string => {
    return appConfig.currency.find((i) => i.id === id)?.shortCode || '';
};
export const getCurrencyCodeById = (id?: number): string => {
    return id ? appConfig.currency.find((i) => i.id === id.toString())?.currencyCode || '???' : '???';
};

export const getCurrencyShortCodeOrCodeById = (currency_id?: string): string => {
    const currency = appConfig.currency.find((i) => i.id === currency_id);
    if (!currency || !(currency.shortCode || currency.abbreviation || currency.currencyCode)) {
        const message = `Not found currency with id: ${currency_id}`;
        appSignalSendMessage({
            action: 'Not found currency',
            error: new Error(message),
            message: message,
            description: `Need ask FrontEnd team to add currency with id: ${currency_id} to AppConfig`,
        });

        return '';
    } else {
        return currency.shortCode || currency.abbreviation || currency.currencyCode;
    }
};
