import React from 'react';
import { IComponentName } from '../interfaces';
import { usePagesStoreAvailableComponents, usePagesStoreDefaultComponent } from '../hooks';
import {
    About,
    ActivityLogList,
    BalanceLogList,
    CallDataRecordsList,
    CallDataRecordsListBeta,
    Card,
    CardList,
    CardSet,
    CardSetsList,
    CompaniesHierarchy,
    CompaniesList,
    Company,
    CountriesList,
    Country,
    CurrenciesList,
    Currency,
    Dashboard,
    ESPlusNotificationQRcodes,
    ESPlusNotifications,
    ESPlusNotificationsLogs,
    ExternalAPIEdit,
    ExternalAPIList,
    FeatureFlags,
    FeatureFlagsEdit,
    Finances,
    GatewayConfig,
    GeoTracking,
    HashCompaniesInfo,
    ImpersonateUser,
    Initialization,
    LoginPage,
    MyAlarms,
    Operator,
    OperatorInteractionsFilesList,
    OperatorInteractionsResponse,
    OperatorInteractionsResponsesList,
    OperatorsList,
    OrderExternal,
    OrderNew,
    OrderView,
    OrdersList,
    PackageInstance,
    PackageInstanceBuy,
    PackageInstanceBuyPreview,
    PackageRoot,
    PackagesBuy,
    PackagesSell,
    PackagesUnitsLogs,
    PurchaseRateList,
    PurchaseRatesList,
    ReportsPage,
    ResetPasswordPage,
    RootPage,
    SalesRateList,
    SalesRatesList,
    ScpFileUpload,
    SignUpPage,
    Subscriber,
    SubscribersList,
    SupportPage,
    UploadCardDevice,
    User,
    UsersList,
    Zone,
    ZonesList,
} from '../Components/pages';

export type IComponentMap = {
    [id in IComponentName]?: JSX.Element;
};

const ComponentMap: IComponentMap = {
    // DiameterSessionsList: <DiameterSessionsList />,
    // Terms: <TermsPage />,
    About: <About />,
    ActivityLogList: <ActivityLogList />,
    BalanceLogList: <BalanceLogList />,
    CallDataRecordsList: <CallDataRecordsList />,
    CallDataRecordsListBeta: <CallDataRecordsListBeta />,
    Card: <Card />,
    CardEdit: <Card isEdit={true} />,
    CardList: <CardList />,
    CardSet: <CardSet />,
    CardSetEdit: <CardSet isEdit={true} />,
    CardSetNew: <CardSet isNew={true} />,
    CardSetsList: <CardSetsList />,
    CompaniesHierarchy: <CompaniesHierarchy />,
    CompaniesList: <CompaniesList />,
    Company: <Company />,
    CompanyEdit: <Company isEdit={true} />,
    CompanyNew: <Company isNew={true} />,
    Country: <Country />,
    CountryEdit: <Country isEdit={true} />,
    CountryList: <CountriesList />,
    CountryNew: <Country isNew={true} />,
    CurrenciesList: <CurrenciesList />,
    Currency: <Currency />,
    CurrencyEdit: <Currency isEdit={true} />,
    Dashboard: <Dashboard />,
    ExternalAPIEdit: <ExternalAPIEdit />,
    ExternalAPIList: <ExternalAPIList />,
    FeatureFlags: <FeatureFlags />,
    FeatureFlagsEdit: <FeatureFlagsEdit />,
    Finances: <Finances />,
    GatewayConfig: <GatewayConfig />,
    GeoTracking: <GeoTracking />,
    HashCompaniesInfo: <HashCompaniesInfo />,
    ScpFileUpload: <ScpFileUpload />,
    ImpersonateUser: <ImpersonateUser />,
    Initialization: <Initialization />,
    Login: <LoginPage />,
    MyAlarms: <MyAlarms />,
    MyCompany: <Company isMe={true} />,
    MyCompanyEdit: <Company isMe={true} isEdit={true} />,
    ESPlusNotifications: <ESPlusNotifications />,
    ESPlusNotificationsLogs: <ESPlusNotificationsLogs />,
    ESPlusNotificationQRcodes: <ESPlusNotificationQRcodes />,
    Operator: <Operator />,
    OperatorEdit: <Operator isEdit={true} />,
    OperatorInteractionsFilesList: <OperatorInteractionsFilesList />,
    OperatorInteractionsResponse: <OperatorInteractionsResponse />,
    OperatorInteractionsResponsesList: <OperatorInteractionsResponsesList />,
    OperatorNew: <Operator isNew={true} />,
    OperatorsList: <OperatorsList />,
    OrderExternal: <OrderExternal />,
    OrderNew: <OrderNew />,
    OrdersList: <OrdersList />,
    OrderView: <OrderView />,
    PackageEdit: <PackageRoot isEdit={true} />,
    PackageInstance: <PackageInstance />,
    PackageInstanceBuy: <PackageInstanceBuy />,
    PackageInstanceBuyPreview: <PackageInstanceBuyPreview />,
    PackageInstanceEdit: <PackageInstance isEdit={true} />,
    PackageNew: <PackageRoot isNew={true} />,
    PackageRoot: <PackageRoot />,
    PackagesBuy: <PackagesBuy />,
    PackagesSell: <PackagesSell />,
    PackagesUnitsLogsList: <PackagesUnitsLogs />,
    Profile: <User isMe={true} />,
    ProfileEdit: <User isMe={true} isEdit={true} />,
    PurchaseRateList: <PurchaseRateList />,
    PurchaseRateListEdit: <PurchaseRateList isEdit={true} />,
    PurchaseRateListNew: <PurchaseRateList isNew={true} />,
    PurchaseRatesList: <PurchaseRatesList />,
    ReportsPageList: <ReportsPage />,
    ResetPassword: <ResetPasswordPage />,
    RootPage: <RootPage />,
    SalesRateList: <SalesRateList />,
    SalesRateListEdit: <SalesRateList isEdit={true} />,
    SalesRateListNew: <SalesRateList isNew={true} />,
    SalesRateLists: <SalesRatesList />,
    SignUp: <SignUpPage />,
    Subscriber: <Subscriber />,
    SubscriberEdit: <Subscriber isEdit={true} />,
    SubscriberNew: <Subscriber isNew={true} />,
    SubscribersList: <SubscribersList />,
    SupportPage: <SupportPage />,
    UploadCardDevice: <UploadCardDevice />,
    User: <User />,
    UserEdit: <User isEdit={true} />,
    UserNew: <User isNew={true} />,
    UsersList: <UsersList />,
    Zone: <Zone />,
    ZonesList: <ZonesList />,
};

export function useComponentMap(): IComponentMap {
    const defaultComponent = usePagesStoreDefaultComponent();
    const availableComponents = usePagesStoreAvailableComponents();
    const availableComponentsName = availableComponents.map((i) => i.routeName);
    const components: IComponentMap = Object.fromEntries(
        Object.entries(ComponentMap)
            .map(([key, value]) => [key, value])
            .filter((page) => {
                return availableComponentsName.includes(page[0] as IComponentName);
            }),
    );
    components['notFound'] = ComponentMap[defaultComponent.routeName];
    return components;
}

export function useComponent(componentName: IComponentName): JSX.Element | undefined {
    const componentMap = useComponentMap();
    return componentMap[componentName];
}
