import React from 'react';
import { observer } from 'mobx-react';
import { CardLayout, Catcher, FieldInfo, cardLayoutOptions } from '../../';
import { useRateInfo } from '../../../hooks';

export const ShowRateMrcNrco: React.FC = observer(() => {
    const rateInfo = useRateInfo();
    return (
        <Catcher>
            <CardLayout
                idSpinners={['GET_RATE_UNIT_SELECT']}
                title={'MRC & NRC'}
                columnOption={cardLayoutOptions.salesRateInfo}
            >
                <FieldInfo title={'MRC per Active Card'}>
                    {rateInfo.currency} {rateInfo.mrcActiveCard}
                </FieldInfo>
                <FieldInfo title={'MRC per Card'}>
                    {rateInfo.currency} {rateInfo.mrcActiveCard}
                </FieldInfo>
                <FieldInfo title={'MRC per Active Ims'}>{rateInfo.mrcActiveImsi}</FieldInfo>
                <FieldInfo title={'NRC per Card'}>
                    {rateInfo.currency} {rateInfo.nrcCard}
                </FieldInfo>
                <FieldInfo title={'NRC IMSI Activation'}>
                    {rateInfo.currency} {rateInfo.nrcImsi}
                </FieldInfo>
                <FieldInfo title={'Name'}>{rateInfo.name}</FieldInfo>
                <FieldInfo title={'Name'}>{rateInfo.name}</FieldInfo>
            </CardLayout>
        </Catcher>
    );
});
