/* eslint-disable */
import { CountryStore } from '../stores/country';
import {
    cleanDashboardStore,
    getActivityRollAsExternal,
    getDashboardCardSetUsageList,
    getDashboardCardsUsageList,
    getDashboardTimelineUsageList,
} from './dashboard';
import { getFeatureFlagIsBigQuery } from './profile';
import { getUnitTypesSelect } from './unitType';
import { cleanZonesStore, getZonesSelect } from './zones';
import { IRequestAPIBody, apiManager } from '../REST';
import {
    DashboardUnitTypes,
    IBigQueryUsage,
    ICountryAPI,
    ICountryActiveCardItem,
    ICountryActiveCardItemAPI,
    ICountryIncludeOperatorItemAPI,
    ICountryInfo,
    ICountryOperatorItem,
    IParamsList,
    IPatchItem,
    IRequestRelationshipMainItem,
} from '../interfaces';
import { IMapField, getApiTypeWithUnitType, mapApi2UiField, mapUi2ApiField } from '../instruments';

function setCountryInfoStore(info: ICountryInfo): void {
    const CountryInterface = CountryStore.getInstance();
    CountryInterface.setCountryInfo(info);
}
function setCountryOperatorsList(list: ICountryOperatorItem[]): void {
    const CountryInterface = CountryStore.getInstance();
    CountryInterface.setOperatorsList(list);
}

function setCountryActiveCardsList(list: ICountryActiveCardItem[]): void {
    const CountryInterface = CountryStore.getInstance();
    CountryInterface.setActiveCardsList(list);
}

export async function getCountryById(id: string): Promise<boolean> {
    const { resp, isError } = await apiManager.getApi('GET_COUNTRY', { id: id });
    const response = resp as ICountryAPI;
    if (!isError && response?.data?.id) {
        const zoneId = response?.data?.relationships?.zone?.data?.id;
        const zone = response?.included?.filter((i) => i.type === 'zone').find((i) => i.id === zoneId)
            ?.attributes?.name;
        const countryInfo: ICountryInfo = {
            id: response.data.id,
            zoneId,
            zone,
            ...mapApi2UiField({ ...response?.data?.attributes }),
        };
        setCountryInfoStore(countryInfo);
        const operatorsIncluded = response.included?.filter(
            (i) => i.type === 'operator',
        ) as ICountryIncludeOperatorItemAPI[];
        const operatorsList: ICountryOperatorItem[] =
            response.data.relationships?.operators?.data?.map((r: IRequestRelationshipMainItem) => {
                const operatorAttribute = operatorsIncluded.find(
                    (i: ICountryIncludeOperatorItemAPI) => i.id === r.id,
                )?.attributes;
                const operator: ICountryOperatorItem = {
                    id: r.id,
                    ...(operatorAttribute ? mapApi2UiField(operatorAttribute) : {}),
                };
                return operator;
            }) || [];
        setCountryOperatorsList(operatorsList);
    }
    return !isError;
}

export async function getCountryEditRelationSelect(): Promise<void> {
    getZonesSelect();
}
export async function getCountryShowRelationSelect(id: string): Promise<void> {
    const params: IParamsList = { countryId: id };
    getActivityRollAsExternal(params);
    getDashboardCardsUsageList(params);
    getDashboardCardSetUsageList(params);
    getDashboardTimelineUsageList(params);
}

export async function getCountryShowActiveCards(id: string, params: IParamsList): Promise<void> {
    const isBigQuery = getFeatureFlagIsBigQuery();
    if (isBigQuery) {
        const unitTypeId = getUnitTypesSelect(DashboardUnitTypes.DATA)?.id;
        const usageDataName = getApiTypeWithUnitType(`GET_USAGE_CARDS_LIST`, unitTypeId);
        const { resp } = await apiManager.getApi(
            'GET_USAGE_CARDS_LIST',
            { searchParamsList: params, isBigQuery },
            undefined,
            undefined,
            usageDataName,
        );

        const data = resp as Array<IBigQueryUsage>;

        const activeCardList: ICountryActiveCardItem[] = data.map((value) => {
            const mappedFields = mapApi2UiField({ ...value });

            return {
                ...mappedFields,
                id: mappedFields.cardId,
            };
        });

        setCountryActiveCardsList(activeCardList);
    } else {
        const { resp, isError } = await apiManager.getApi('GET_COUNTRY_ACTIVE_CARDS', { id: id });
        if (!isError) {
            const activeCardListApi = resp.data as ICountryActiveCardItemAPI[];
            const activeCardList: ICountryActiveCardItem[] = activeCardListApi.map((value) => {
                return {
                    id: value.id,
                    ...mapApi2UiField({ ...value?.attributes }),
                };
            });
            setCountryActiveCardsList(activeCardList);
        }
    }
}

export async function patchCountry(id: string, fields: IMapField): Promise<IPatchItem> {
    const apiBody: IRequestAPIBody = {
        data: {
            type: 'country',
            attributes: mapUi2ApiField(fields),
        },
    };
    const { resp, isError } = await apiManager.getApi(id ? 'PATCH_COUNTRY' : 'POST_COUNTRY', { id: id }, apiBody);
    const { errors } = await resp;

    return { isError, errors };
}

export function cleanCountryStore(): void {
    const CountryInterface = CountryStore.getInstance();
    CountryInterface.cleanStore();
    cleanZonesStore();
    cleanDashboardStore();
}
