import { ExternalAPIStore } from '../stores/externalAPI';
import { IRequestAPIBody, apiManager } from '../REST';
import {
    GetExternalAPICommand,
    IExternalAPIFpbxSettingsAPI,
    IExternalAPIFrontFpbxSettings,
    IExternalAPIFrontNumbers,
    IExternalAPILog,
    IExternalAPINumbersAPI,
    IExternalAPINumbersAPINumbers,
    IExternalAPIOrderDetailAPI,
    IExternalAPIOrderDetailForm,
    IExternalAPIOrderDetailFormKeys,
    IExternalAPIOrderDetailFront,
    IExternalAPIOrderDetailOrderAPI,
    IExternalAPIOrderDetailOrderFront,
    IExternalAPIOrderDetailResponseAPI,
    IExternalAPIResponseError,
    IExternalRequestAPIBody,
    getExternalAPIParamsByField,
} from '../interfaces';
import { equalObjects, mapApi2UiField, mapUi2ApiField, sortByStringDecrease } from '../instruments';

// ------------ All ------------

const availableSubscriberIds: string[] = ['13631'];
const availableOperatorIds: string[] = ['1098'];

export const isAvailableSubscriberId = (subscriberId: string): boolean => {
    return true;
    // return availableSubscriberIds.includes(subscriberId);
};

export const isAvailableOperatorId = (operatorId: string): boolean => {
    return true;
    // return availableOperatorIds.includes(operatorId);
};

type IMakeRequestBodyProps = {
    subscriberId: string;
};

type IMakeGetBodyByCommandProps = IMakeRequestBodyProps & {
    subscriberId: string;
    command: GetExternalAPICommand;
};

const checkSubscriberId = (subscriberId: string) => {
    if (!isAvailableSubscriberId(subscriberId)) {
        throw new Error(`BAD ID: ${subscriberId} You can use only 13631`);
    }
};
export const clearExternalAPILogs = (): void => {
    const StoreInstance = ExternalAPIStore.getInstance();
    StoreInstance.clearLog();
};

export const clearExternalAPIStore = (): void => {
    const StoreInstance = ExternalAPIStore.getInstance();
    StoreInstance.cleanStore();
};

// ------------ RawResponse ------------

const addExternalAPILog = (log: IExternalAPILog): void => {
    const StoreInstance = ExternalAPIStore.getInstance();
    StoreInstance.addLog({ ...log, date: new Date() });
};

// ------------ OrderDetail ------------

const setExternalAPIOrderDetail = (orderDetail: IExternalAPIOrderDetailFront): void => {
    const StoreInstance = ExternalAPIStore.getInstance();
    StoreInstance.setOrderDetails(orderDetail);
};

const makeExternalAPIOrderDetailFront = (
    response?: IExternalAPIOrderDetailResponseAPI,
): IExternalAPIOrderDetailFront => {
    const orders: Array<IExternalAPIOrderDetailOrderFront> = Array.isArray(response?.subscription?.orders?.order)
        ? response?.subscription?.orders?.order
              .map((o: IExternalAPIOrderDetailOrderAPI) => mapApi2UiField(o))
              .sort((a: IExternalAPIOrderDetailOrderFront, b: IExternalAPIOrderDetailOrderFront) =>
                  sortByStringDecrease(a.orderNumber, b.orderNumber),
              ) || []
        : response?.subscription?.orders?.order
        ? [mapApi2UiField(response?.subscription?.orders?.order)]
        : [];

    return {
        subscription: {
            ...(response?.subscription ? mapApi2UiField(response.subscription) : {}),
            simCards: {
                sim: response?.subscription?.simcards?.sim ? mapApi2UiField(response.subscription.simcards.sim) : {},
            },
            fmc: response?.subscription?.fmc,
            orders,
            products: {
                bundleProduct: response?.subscription?.products?.bundleproduct
                    ? mapApi2UiField(response.subscription.products.bundleproduct)
                    : {},
            },
        },
    };
};

const makeGetBody = ({ subscriberId, command }: IMakeGetBodyByCommandProps): IExternalRequestAPIBody => {
    checkSubscriberId(subscriberId);
    return {
        id: subscriberId,
        data: {
            type: 'drive',
            attributes: {
                command,
            },
        },
    };
};

export const getExternalAPIOrderDetail = async (subscriberId: string): Promise<void> => {
    const command = GetExternalAPICommand.ORDER_DETAIL;
    const body: IRequestAPIBody = makeGetBody({ subscriberId, command });

    const apiResponse = (await apiManager.getApi(
        'POST_SUBSCRIBERS_API_REQUEST',
        { id: subscriberId },
        body,
    )) as IExternalAPIOrderDetailAPI;

    addExternalAPILog({
        ocsRequest: body,
        ocsResponse: apiResponse.resp?.response,
        command,
        ocsResponseError: {
            errorCode: apiResponse.resp?.response?.error?.errorcode,
            errorDescription: apiResponse.resp?.response?.error?.errordescription,
        },
        rawResponse: { ...apiResponse.resp?.raw_response, requestCurl: apiResponse.resp?.request_curl },
    });
    setExternalAPIOrderDetail(makeExternalAPIOrderDetailFront(apiResponse?.resp?.response));
};

// ------------ Form ------------
type ISendExternalAPIOrderDetailForm = {
    subscriberId: string;
    formFields: IExternalAPIOrderDetailForm;
    initialValues: IExternalAPIOrderDetailForm;
};
export const sendExternalAPIOrderDetailForm = async ({
    subscriberId,
    formFields,
    initialValues,
}: ISendExternalAPIOrderDetailForm) => {
    checkSubscriberId(subscriberId);
    if (equalObjects(formFields, initialValues)) {
        console.warn(`You don't need save:`, formFields);
    } else {
        for (const keyFields in formFields) {
            const field = keyFields as IExternalAPIOrderDetailFormKeys;
            const value = formFields[field];
            const { command, fieldName } = getExternalAPIParamsByField(field, value);
            if (formFields[field] !== initialValues[field] && command) {
                const body: IExternalRequestAPIBody = {
                    id: subscriberId,
                    data: {
                        type: 'drive',
                        attributes: {
                            command,
                        },
                    },
                };

                if (fieldName && typeof value === 'string') {
                    body.data.attributes.params = { [fieldName]: value };
                }

                const apiResponse = (await apiManager.getApi(
                    'POST_SUBSCRIBERS_API_REQUEST',
                    {},
                    body,
                )) as IExternalAPIOrderDetailAPI;

                addExternalAPILog({
                    ocsRequest: body,
                    ocsResponse: apiResponse.resp?.response,
                    ocsResponseError: {
                        errorCode: apiResponse.resp?.response?.error?.errorcode,
                        errorDescription: apiResponse.resp?.response?.error?.errordescription,
                    },
                    command,
                    rawResponse: { ...apiResponse.resp?.raw_response, requestCurl: apiResponse.resp?.request_curl },
                });
            }
        }
    }
};

// ------------ Numbers ------------

type IMakeChangeNumberBodyProps = IMakeRequestBodyProps & {
    command: GetExternalAPICommand;
    number: number;
};

const makeNumberBody = ({
    subscriberId,
    number,
    command,
}: IMakeChangeNumberBodyProps): IRequestAPIBody | IExternalRequestAPIBody => {
    checkSubscriberId(subscriberId);
    return {
        id: subscriberId,
        data: {
            type: 'drive',
            attributes: {
                command,
                params: {
                    number,
                },
            },
        },
    };
};

const makeExternalAPINumbersFront = (response?: IExternalAPINumbersAPINumbers): IExternalAPIFrontNumbers => {
    return {
        ...(response ? mapApi2UiField(response) : {}),
        numbers: response?.numbers?.number?.sort() || [],
    };
};

function setExternalAPINumbers(info: IExternalAPIFrontNumbers): void {
    const StoreInstance = ExternalAPIStore.getInstance();
    StoreInstance.setNumbers(info);
}

export const getExternalAPINumbers = async (subscriberId: string): Promise<void> => {
    const command = GetExternalAPICommand.GET_NUMBERS;
    const body: IRequestAPIBody = makeGetBody({ subscriberId, command });

    const apiResponse = (await apiManager.getApi('POST_SUBSCRIBERS_API_REQUEST', {}, body)) as IExternalAPINumbersAPI;

    addExternalAPILog({
        ocsRequest: body,
        ocsResponse: apiResponse.resp?.response,
        ocsResponseError: {
            errorCode: apiResponse.resp?.response?.error?.errorcode,
            errorDescription: apiResponse.resp?.response?.error?.errordescription,
        },
        command,
        rawResponse: { ...apiResponse.resp?.raw_response, requestCurl: apiResponse.resp?.request_curl },
    });

    apiResponse.resp?.response && setExternalAPINumbers(makeExternalAPINumbersFront(apiResponse.resp.response));
};

export const externalAPINumber = async ({
    command,
    number,
    subscriberId,
}: IMakeChangeNumberBodyProps): Promise<void> => {
    const body: IRequestAPIBody = makeNumberBody({
        subscriberId,
        number,
        command,
    });

    const apiResponse = (await apiManager.getApi(
        'POST_SUBSCRIBERS_API_REQUEST',
        { id: subscriberId },
        body,
    )) as IExternalAPINumbersAPI;
    addExternalAPILog({
        ocsRequest: body,
        ocsResponse: apiResponse.resp?.response,
        ocsResponseError: {
            errorCode: apiResponse.resp?.response?.error?.errorcode,
            errorDescription: apiResponse.resp?.response?.error?.errordescription,
        },
        command,
        rawResponse: { ...apiResponse.resp?.raw_response, requestCurl: apiResponse.resp?.request_curl },
    });
};

// ------------ FPBX SETTINGS ------------

const setExternalAPIFpbxSettingsStore = (settings: IExternalAPIFrontFpbxSettings): void => {
    const StoreInstance = ExternalAPIStore.getInstance();
    StoreInstance.setFpbxSettings(settings);
};

export const getExternalAPIFpbxSettings = async (subscriberId: string): Promise<void> => {
    const command = GetExternalAPICommand.GET_FPBX_SETTINGS;
    const body: IRequestAPIBody = makeGetBody({ subscriberId, command });

    const apiResponse = (await apiManager.getApi(
        'POST_SUBSCRIBERS_API_REQUEST',
        { id: subscriberId },
        body,
    )) as IExternalAPIFpbxSettingsAPI;
    const ocsResponseError: IExternalAPIResponseError = {
        errorCode: apiResponse.resp?.response?.error?.errorcode,
        errorDescription: apiResponse.resp?.response?.error?.errordescription,
    };

    addExternalAPILog({
        ocsRequest: body,
        ocsResponse: apiResponse.resp?.response,
        command,
        ocsResponseError,
        rawResponse: { ...apiResponse.resp?.raw_response, requestCurl: apiResponse.resp?.request_curl },
    });

    const response = apiResponse.resp?.response || {};
    delete response.error;

    const fpbxSettings: IExternalAPIFrontFpbxSettings = {
        username: '',
        password: '',
        host: '',
        port: '',
        outboundChannel: '',
        ...mapApi2UiField(response),
    };

    setExternalAPIFpbxSettingsStore(fpbxSettings);
};

export const setExternalAPIFpbxSettings = async (
    subscriberId: string,
    settings: IExternalAPIFrontFpbxSettings,
): Promise<void> => {
    const command = GetExternalAPICommand.CHANGE_FPBX_SETTINGS;
    const body: IExternalRequestAPIBody = {
        id: subscriberId,
        data: {
            type: 'drive',
            attributes: {
                command,
                params: mapUi2ApiField(settings),
            },
        },
    };
    const apiResponse = (await apiManager.getApi(
        'POST_SUBSCRIBERS_API_REQUEST',
        {},
        body,
    )) as IExternalAPIOrderDetailAPI;

    addExternalAPILog({
        ocsRequest: body,
        ocsResponse: apiResponse.resp?.response,
        ocsResponseError: {
            errorCode: apiResponse.resp?.response?.error?.errorcode,
            errorDescription: apiResponse.resp?.response?.error?.errordescription,
        },
        command,
        rawResponse: { ...apiResponse.resp?.raw_response, requestCurl: apiResponse.resp?.request_curl },
    });
};

export const setExternalAPIOrderActivate = async (subscriberId: string): Promise<void> => {
    const command = GetExternalAPICommand.ORDER_ACTIVATE;
    const body: IExternalRequestAPIBody = {
        id: subscriberId,
        data: {
            type: 'drive',
            attributes: {
                command,
                // params: { subscriptionid: subscriberId },
            },
        },
    };
    const apiResponse = (await apiManager.getApi(
        'POST_SUBSCRIBERS_API_REQUEST',
        {},
        body,
    )) as IExternalAPIOrderDetailAPI;

    addExternalAPILog({
        ocsRequest: body,
        ocsResponse: apiResponse.resp?.response,
        ocsResponseError: {
            errorCode: apiResponse.resp?.response?.error?.errorcode,
            errorDescription: apiResponse.resp?.response?.error?.errordescription,
        },
        command,
        rawResponse: { ...apiResponse.resp?.raw_response, requestCurl: apiResponse.resp?.request_curl },
    });
};
