import React from 'react';
import { observer } from 'mobx-react';
import { Catcher } from '../';
import { Loading, LoadingText } from './';
import { ILoadingApiById } from './interfaces';
import { useUiIsSpinnerFound } from '../../hooks';

// import Styles from './m_styles.less';

export const LoadingApiByIds: React.FC<ILoadingApiById> = observer(
    ({ idSpinners, size, tip = LoadingText(), isLoading, onlySpinner }: ILoadingApiById) => {
        if (!idSpinners || !idSpinners.length) {
            return null;
        }
        const isLocalLoading = isLoading || useUiIsSpinnerFound(idSpinners);

        return (
            <Catcher>
                {isLocalLoading ? (
                    // <div className={Styles.loadingWrapper}>
                    <Loading isLoading={true} size={size} tip={tip} onlySpinner={onlySpinner} />
                ) : // </div>
                null}
            </Catcher>
        );
    },
);
