import React from 'react';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import {
    CalendarOutlined,
    CompassOutlined,
    CopyOutlined,
    FieldNumberOutlined,
    FileTextOutlined,
    LinkOutlined,
    NumberOutlined,
    OrderedListOutlined,
    PaperClipOutlined,
    PhoneOutlined,
    QuestionCircleOutlined,
    ShareAltOutlined,
} from '@ant-design/icons';
import { CardInfoToolBar, CardMainLayout, Catcher, FieldInfo, FieldInfoAdvanced, cardLayoutOptions } from '../../';
import cx from 'classnames';
import { IExternalAPIOrderDetailOrderFront, IFieldLayout, IShowForm } from '../../../interfaces';
import { useExternalAPIOrderDetail, useRouterStore, useSubscriberInfo } from '../../../hooks';
import { ITrField, tP } from '../../../translate';
import Styles from '../../styles/m_itemCardStyles.less';

export const OrderDetailView: React.FC<IShowForm> = observer(({ setEditMode, loadingTypes }: IShowForm) => {
    const layout: IFieldLayout = {
        labelCol: {
            flex: '0 1 160px',
        },
        valCol: {
            flex: '1 1 270px',
        },
    };
    const routerStore = useRouterStore();
    const { id: subscriberId } = useSubscriberInfo();
    const orderDetail = useExternalAPIOrderDetail();

    const goToSubscriber = () => {
        routerStore.goTo('Subscriber', { params: { id: subscriberId } });
    };

    return (
        <Catcher>
            <CardMainLayout
                columnOption={cardLayoutOptions.cardSubscribersInfo}
                icon={<FileTextOutlined />}
                idSpinners={loadingTypes}
                isNotDocumentTitle
                name={'Order Detail'}
            >
                <FieldInfoAdvanced
                    icon={<NumberOutlined className={Styles.labelIcon} />}
                    layout={layout}
                    title={'Subscription Id' as ITrField}
                >
                    {orderDetail.subscription?.subscriptionId}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced
                    icon={<NumberOutlined className={Styles.labelIcon} />}
                    layout={layout}
                    title={'Account Id' as ITrField}
                >
                    {orderDetail.subscription?.accountId}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced
                    icon={<FieldNumberOutlined className={Styles.labelIcon} />}
                    layout={layout}
                    title={'Account Number' as ITrField}
                >
                    {orderDetail.subscription?.accountNumber}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced
                    icon={<CopyOutlined className={Styles.labelIcon} />}
                    layout={layout}
                    title={'Portfolio' as ITrField}
                >
                    {orderDetail.subscription?.portfolio}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced
                    icon={<ShareAltOutlined className={Styles.labelIcon} />}
                    layout={layout}
                    title={'Order Reference' as ITrField}
                >
                    {orderDetail.subscription?.orderReference}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced
                    icon={<CalendarOutlined className={Styles.labelIcon} />}
                    layout={layout}
                    title={'Order Date' as ITrField}
                >
                    {orderDetail.subscription?.orderDate}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced
                    icon={<CompassOutlined className={Styles.labelIcon} />}
                    layout={layout}
                    title={'Status' as ITrField}
                >
                    {orderDetail.subscription?.status}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced
                    icon={<PhoneOutlined className={Styles.labelIcon} />}
                    layout={layout}
                    title={'SIM Cards' as ITrField}
                />
                <div className={Styles.subFieldsPlace}>
                    <FieldInfo isRevertBold title={'ICCID'}>
                        {orderDetail.subscription?.simCards.sim?.iccId}
                    </FieldInfo>
                    <FieldInfo isRevertBold title={'MSISDN'}>
                        {orderDetail.subscription?.simCards.sim?.msisdn}
                    </FieldInfo>
                    <FieldInfo isRevertBold title={'Type' as ITrField}>
                        {orderDetail.subscription?.simCards.sim?.simType}
                    </FieldInfo>
                    <FieldInfo isRevertBold title={'Allow Data' as ITrField}>
                        {orderDetail.subscription?.simCards.sim?.allowData}
                    </FieldInfo>
                    <FieldInfo isRevertBold title={'Allow Data Over Usage' as ITrField}>
                        {orderDetail.subscription?.simCards.sim?.allowDataOverUsage}
                    </FieldInfo>
                    <FieldInfo isRevertBold title={'Allow Data Roaming' as ITrField}>
                        {orderDetail.subscription?.simCards.sim?.allowDataRoaming}
                    </FieldInfo>
                    <FieldInfo isRevertBold title={'Allow Premium SMS' as ITrField}>
                        {orderDetail.subscription?.simCards.sim?.allowPremiumSMS}
                    </FieldInfo>
                    <FieldInfo isRevertBold title={'Bill Shock Active' as ITrField}>
                        {orderDetail.subscription?.simCards.sim?.billShockActive}
                    </FieldInfo>
                    <FieldInfo isRevertBold title={'Bill Shock SMS' as ITrField}>
                        {orderDetail.subscription?.simCards.sim?.allowBillShockSMS}
                    </FieldInfo>
                    <FieldInfo isRevertBold title={'Bill Shock Release' as ITrField}>
                        {orderDetail.subscription?.simCards.sim?.billShockRelease}
                    </FieldInfo>
                    <FieldInfo isRevertBold title={'Bill Shock Trigger' as ITrField}>
                        {orderDetail.subscription?.simCards.sim?.billShockTrigger}
                    </FieldInfo>
                </div>

                <FieldInfoAdvanced
                    icon={<QuestionCircleOutlined className={Styles.labelIcon} />}
                    layout={layout}
                    title={'FMS' as ITrField}
                >
                    {orderDetail.subscription?.fmc ? orderDetail.subscription?.fmc : 'null'}
                </FieldInfoAdvanced>

                <FieldInfoAdvanced
                    icon={<OrderedListOutlined className={Styles.labelIcon} />}
                    layout={layout}
                    title={'Orders' as ITrField}
                />
                <div className={Styles.subFieldsPlace}>
                    {orderDetail.subscription?.orders.map((item: IExternalAPIOrderDetailOrderFront, index: number) => {
                        return (
                            <div key={index} className={Styles.listItem}>
                                <FieldInfo isRevertBold title={'Order Number' as ITrField}>
                                    {item.orderNumber}
                                </FieldInfo>
                                <FieldInfo isRevertBold title={'Order Type' as ITrField}>
                                    {item.orderType}
                                </FieldInfo>
                                <FieldInfo isRevertBold title={'Order Status Id' as ITrField}>
                                    {item.orderStatusId}
                                </FieldInfo>
                            </div>
                        );
                    })}
                </div>

                <FieldInfoAdvanced
                    icon={<PaperClipOutlined className={Styles.labelIcon} />}
                    layout={layout}
                    title={'Bundle Product' as ITrField}
                />
                <div className={Styles.subFieldsPlace}>
                    <FieldInfo isRevertBold title={'Id'}>
                        {orderDetail.subscription?.products.bundleProduct.bundleProductId}
                    </FieldInfo>
                    <FieldInfo isRevertBold title={'Name'}>
                        {orderDetail.subscription?.products.bundleProduct.bundleProductName}
                    </FieldInfo>
                    <FieldInfo isRevertBold title={'Type'}>
                        {orderDetail.subscription?.products.bundleProduct.bundleProductType}
                    </FieldInfo>
                    <FieldInfo isRevertBold title={'Start Order Id' as ITrField}>
                        {orderDetail.subscription?.products.bundleProduct.bundleStartOrderId}
                    </FieldInfo>
                    <FieldInfo isRevertBold title={'Start Date'}>
                        {orderDetail.subscription?.products.bundleProduct.bundleProductStartDate}
                    </FieldInfo>
                </div>
                <CardInfoToolBar isBackToList loadingTypes={loadingTypes} setEditMode={setEditMode}>
                    {!!subscriberId ? (
                        <Button
                            type="primary"
                            icon={<LinkOutlined />}
                            onClick={goToSubscriber}
                            className={cx(Styles.mainButton)}
                        >
                            {tP('Subscriber')}
                        </Button>
                    ) : null}
                </CardInfoToolBar>
            </CardMainLayout>
        </Catcher>
    );
});
