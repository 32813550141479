import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import { Catcher } from '../../';
import { EditRateCountriesList } from './';
import { tableColumns } from './tableColumns';
import cx from 'classnames';
import { ITempTableDataSourceAnyFieldItem, ITempTableDataSourceDefaultItem } from '../../../interfaces';
import { useCountriesWithZoneList, useZonesSelect } from '../../../hooks';
import { setCountryLoadingStats } from '../../../middleware';

interface IEditRateZoneList {
    operatorId: string;
    children?: never;
}

export const EditRateZoneList: React.FC<IEditRateZoneList> = observer(({ operatorId }: IEditRateZoneList) => {
    const [expandedRowKeys, setExpandedRowKeys] = useState<Array<string | number>>([]);
    const zoneSelect = useZonesSelect();
    const countrySelect = useCountriesWithZoneList();
    const dataSource = zoneSelect.map((z) => {
        const countryCount = countrySelect.filter((c) => c.zoneId === z.id).length;
        return {
            key: z.id,
            zone: `${z.name} (${countryCount.toString()})`,
            zoneId: z.id,
            countryCount: countryCount.toString(),
        };
    });

    const onExpand = (isExpand: boolean, record: ITempTableDataSourceDefaultItem): void => {
        setCountryLoadingStats(0, 'expectedToLoad');
        if (isExpand) {
            setExpandedRowKeys([record.key]);
        } else {
            setExpandedRowKeys([]);
        }
    };
    const expandedRowRender = (record: ITempTableDataSourceAnyFieldItem): JSX.Element => {
        return (
            <EditRateCountriesList
                operatorId={operatorId}
                zoneId={record.zoneId}
                expandedRowKeys={expandedRowKeys}
                recordKey={record.key}
            />
        );
    };

    const expandable = {
        expandedRowKeys,
        expandedRowRender,
        onExpand,
        rowExpandable: (record: ITempTableDataSourceAnyFieldItem): boolean => !!+record.countryCount,
        expandedRowClassName: (): string => 'expandedRowClassName',
        indentSize: 30,
    };

    return (
        <Catcher>
            <Table
                className={cx('sub-table-rate')}
                columns={tableColumns(2)}
                dataSource={dataSource}
                expandable={expandable}
                pagination={false}
                showHeader={false}
                size={'small'}
            />
        </Catcher>
    );
});
