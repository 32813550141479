import React from 'react';
import { EntityList, IPageList } from '../../../interfaces';
import { LightSearch, LinkComponent } from '../../../Components';

interface IActivityLogFieldValue {
    entity?: EntityList | string;
    prop?: string;
    value: string | boolean | null | unknown;
    valueName?: string;
}

type LinkMapByProp = {
    [key: string]: IPageList;
};

type ListMapByEntity = {
    [key in EntityList | string]: IPageList;
};

export const ActivityLogFieldValue = ({ prop = '', value, entity = '', valueName }: IActivityLogFieldValue) => {
    const linkMapByEntity: ListMapByEntity = {
        Card: 'CardList',
        Subscriber: 'SubscribersList',
        Operator: 'OperatorsList',
        Order: 'OrdersList',
        'Card Set': 'CardSetsList',
        User: 'UsersList',
    };

    const linkMapByProp: LinkMapByProp = {
        id: linkMapByEntity[entity],
        userId: 'UsersList',
        companyId: 'CompaniesList',
        currencyId: 'CurrenciesList',
        zoneId: 'ZonesList',
        operatorId: 'OperatorsList',
        cardId: 'CardList',
    };

    if (!value) {
        return null;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (value?.value) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return <LightSearch text={value.value} />;
    }

    if (typeof value === 'object') {
        return <LightSearch text={JSON.stringify(value)} />;
    }

    if (typeof value === 'boolean') {
        return <>{value ? 'Yes' : 'No'}</>;
    }

    if (!linkMapByProp[prop]) {
        return typeof value === 'string' ? <LightSearch text={value} /> : <>{value}</>;
    }

    return (
        <LinkComponent
            currentListComponent={linkMapByProp[prop]}
            id={String(value)}
            isLight
            name={valueName || String(value)}
        />
    );
};
