import React from 'react';
import { observer } from 'mobx-react';
import { CardLayout, cardLayoutOptions } from '../../Layout';
import { UploadFile } from '../../UploadFile';
import cx from 'classnames';
import { IGetApiResponse } from '../../../REST';
import { addNotification } from '../../../middleware';
import { Catcher } from '../../Catcher';
import Styles from '../../styles/m_itemCardStyles.less';

export const ScpFileUpload: React.FC = observer(() => {
    const handleDownload = (resp: IGetApiResponse) => {
        if (!resp.isError) {
            addNotification({
                type: 'success',
                message: 'Upload successful!',
                description: 'Your file is being processed now',
            });
        }
    };

    return (
        <Catcher>
            <div className={Styles.main}>
                <CardLayout
                    title={'Upload SCP81 File'}
                    columnOption={cardLayoutOptions.cardSupportPage}
                    dataTest={'UploadScpFile'}
                >
                    <p className={Styles.paragraph} data-test={'UploadScpFileInfo'}>
                        Here you can upload scp81 file
                    </p>
                    <div className={Styles.deviceFileContainer}>
                        <div className={Styles.deviceFileField}>
                            <div className={cx(Styles.label, Styles.long)} data-test={'UploadScpFileTitle'}>
                                Upload SCP81 File
                            </div>
                            <div className={Styles.buttonPlace}>
                                <UploadFile
                                    apiType={'POST_SCP_FILE'}
                                    text={'Upload'}
                                    fileType={'text'}
                                    onDownload={handleDownload}
                                />
                            </div>
                        </div>
                    </div>
                </CardLayout>
            </div>
        </Catcher>
    );
});
