import React from 'react';
import { observer } from 'mobx-react';
import { Layout } from 'antd';
import { MainUrlManager } from './MainUrlManager';
import { useComponent } from './PagesMap';
import { useAuthAppStatus, usePagesStoreCurrentComponent, useProfile } from '../hooks';
import { Catcher, DesktopMenu, LineSpinner, MainHeader, TicketingSystem } from '../Components';
import Styles from './m_main.less';

const { Header, Content } = Layout;
export const MainView: React.FC = observer(() => {
    const appStatus = useAuthAppStatus();
    const { routeName } = usePagesStoreCurrentComponent();
    const ShowComponent = useComponent(routeName);
    const { helpDeskEnabled } = useProfile();

    return (
        <Catcher>
            <MainUrlManager />
            {appStatus === 'READY' || appStatus === 'ACCEPT_POLICY' ? (
                <main>
                    {helpDeskEnabled ? <TicketingSystem /> : null}
                    <Layout className={Styles.layoutMain}>
                        <Header className={Styles.header}>
                            <LineSpinner />
                            <MainHeader />
                        </Header>
                        <Layout className={Styles.layoutContent}>
                            <DesktopMenu className={Styles.content} />
                            <Content className={Styles.content}>{ShowComponent}</Content>
                        </Layout>
                    </Layout>
                </main>
            ) : (
                <>{ShowComponent}</>
            )}
        </Catcher>
    );
});
