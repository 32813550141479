import React from 'react';
import { observer } from 'mobx-react';
import { Rule } from 'antd/es/form';
import { Catcher, FormSelect } from '../../';
import { ICatalogsUnitItem, ISelectList } from '../../../interfaces';
import { useCurrencyCodeById, useRateInfo, useUnitList } from '../../../hooks';
import { getUnitLabel } from '../../../middleware';

interface IUnitSelect {
    name?: string | [number, string];
    rules?: Array<Rule>;
}

export const UnitSelect: React.FC<IUnitSelect> = observer(
    ({ name = 'defaultRateDataUnitId', rules = [] }: IUnitSelect) => {
        const { currencyId } = useRateInfo();
        const currencyShortCode = useCurrencyCodeById(currencyId);
        const unitList = useUnitList();

        const useUnitListHook = (): ISelectList =>
            unitList.map((i: ICatalogsUnitItem) => {
                const viewCode = getUnitLabel(i.unit, currencyShortCode);

                return { id: i.id, name: viewCode };
            });

        return (
            <Catcher>
                <FormSelect
                    fieldName={name}
                    isAddonBefore={true}
                    isNotClear={true}
                    isShowSearch={false}
                    rules={rules}
                    useSelectHook={useUnitListHook}
                />
            </Catcher>
        );
    },
);
