import React, { ReactNode, useState } from 'react';
import { observer } from 'mobx-react';
import { Alert } from 'antd';
import { Catcher, UploadFile } from '../';
import { IGetApiResponse, IGetApiType } from '../../REST';
import { IApiErrorMessage } from '../../interfaces';
import { addNotification, setUiForcedUpdateTime } from '../../middleware';
import { tI } from '../../translate';
import Styles from '../styles/m_viewStyles.less';

type IUploadButton = {
    apiType: IGetApiType;
};

export const UploadButton: React.FC<IUploadButton> = observer(({ apiType }: IUploadButton) => {
    const [isErrorMessage, setIsErrorMessage] = useState(false);
    const [isMessage, setIsMessage] = useState(false);
    const [errorMessageBody, setErrorMessageBody] = useState<ReactNode>();
    const cleanUploadError = (): void => {
        setIsMessage(false);
        setIsErrorMessage(false);
        setErrorMessageBody(undefined);
    };

    const onDownload = (apiResponse: IGetApiResponse): void => {
        const { isError } = apiResponse;
        if (isError) {
            const { resp } = apiResponse;
            const errorList = resp.errors;
            const ErrorBodyJSX = errorList?.map((item: IApiErrorMessage, index: number) => {
                return (
                    <div key={index}>
                        {item.source.pointer} {'->'} {item.detail}
                    </div>
                );
            });
            setErrorMessageBody(ErrorBodyJSX);
            setIsErrorMessage(true);
            setIsMessage(true);
        } else {
            setIsMessage(true);
            addNotification({
                type: 'success',
                message: 'Upload successful!',
            });
            setUiForcedUpdateTime();
        }
    };

    return (
        <Catcher>
            <div className={Styles.advancedButtonsPlace}>
                <UploadFile apiType={apiType} onDownload={onDownload} />
                {isMessage ? (
                    <Alert
                        type={isErrorMessage ? 'error' : 'success'}
                        message={tI(isErrorMessage ? 'Upload ERROR!' : 'Upload successful!')}
                        description={isErrorMessage ? errorMessageBody : null}
                        showIcon
                        closable
                        afterClose={cleanUploadError}
                        className={Styles.alert}
                    />
                ) : null}
            </div>
        </Catcher>
    );
});
