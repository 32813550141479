import React from 'react';
import { Catcher } from '../../';
import { OverlayView } from '@react-google-maps/api';
import { IOverlayView, IUseDashboardCountryUsageCountryInfo } from '../../../interfaces';
import { useDashboardCountriesUsageForMapInfo } from '../../../hooks';
import { tF } from '../../../translate';
import Styles from '../m_googleMapsStyles.less';

interface ICountryOverlayView {
    id: number;
}

export const CountryOverlayView: React.FC<ICountryOverlayView> = ({ id }: ICountryOverlayView): IOverlayView => {
    const countriesInfo = useDashboardCountriesUsageForMapInfo();
    const country = countriesInfo.find((c: IUseDashboardCountryUsageCountryInfo) => c.id === id);
    if (!country?.geo?.center?.lat || !country?.geo?.center?.lng) {
        return null;
    } else {
        return (
            <Catcher>
                <OverlayView
                    position={{ lat: country?.geo?.center.lat, lng: country?.geo?.center.lng }}
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                >
                    <div className={Styles.overlayView}>
                        <h1>{country?.name}</h1>
                        <h2>
                            {tF('Data Usage')}:<span>{country?.textUnits}</span>
                        </h2>
                    </div>
                </OverlayView>
            </Catcher>
        );
    }
};
