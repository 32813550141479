export const string2number = (value: string | number | undefined | null): number | null =>
    typeof value === 'string'
        ? isNaN(parseFloat(value))
            ? null
            : parseFloat(value)
        : typeof value === 'undefined'
        ? null
        : value;

export const n0u2n = (value: number | undefined): number => {
    return value ?? 0;
};
