import React from 'react';
import { observer } from 'mobx-react';
import { Catcher, ListWrapper } from '../';
import { useCurrenciesFilterParams } from '../../hooks';
import { cleanCurrenciesStore } from '../../middleware';
import { CurrenciesTableList } from './Components';

export const CurrenciesList: React.FC = observer(() => {
    const filterParams = useCurrenciesFilterParams();

    return (
        <Catcher>
            <ListWrapper cleanStore={cleanCurrenciesStore} filterParams={filterParams}>
                <CurrenciesTableList />
            </ListWrapper>
        </Catcher>
    );
});
