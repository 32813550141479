import { CurrenciesStore } from '../stores/currencies';
import { setUiTotal } from './ui';
import { IBodyListResponse, apiManager } from '../REST';
import {
    ICurrenciesListItem,
    ICurrenciesListItemAPI,
    ICurrenciesSelectItemAPI,
    IParamsList,
    ISelectList,
} from '../interfaces';

export function setCurrenciesList(data: ICurrenciesListItemAPI[]): void {
    const StoreInterface = CurrenciesStore.getInstance();
    const storeData: ICurrenciesListItem[] = data.map((item: ICurrenciesListItemAPI) => {
        return { id: item.id, ...item.attributes };
    });
    StoreInterface.setCurrenciesList(storeData);
}

export async function getCurrenciesList(params: IParamsList): Promise<void> {
    const { resp } = await apiManager.getApi('GET_CURRENCY_LIST', { searchParamsList: params });
    const { data, meta } = resp as IBodyListResponse;
    meta && setUiTotal(meta.records_count);
    data && setCurrenciesList(data);
}

export function setCurrenciesForcedUpdateTime(): void {
    const StoreInterface = CurrenciesStore.getInstance();
    StoreInterface.setForcedUpdateTime();
}

export function cleanCurrenciesStore(): void {
    const StoreInterface = CurrenciesStore.getInstance();
    StoreInterface.cleanStore();
}

function setCurrenciesSelect(select: ICurrenciesSelectItemAPI[]): void {
    const StoreInterface = CurrenciesStore.getInstance();
    const storeData: ISelectList = select.map((item: ICurrenciesSelectItemAPI) => {
        return { id: item.id, name: item.attributes?.abbreviation ? item.attributes.abbreviation : item.id };
    });
    StoreInterface.setCurrenciesSelect(storeData);
}
export async function getCurrenciesSelect(): Promise<void> {
    const { resp } = await apiManager.getApi('GET_CURRENCY_SELECT');
    const { data } = resp as IBodyListResponse;
    data && setCurrenciesSelect(data);
}

export async function deleteCurrency(id: string): Promise<void> {
    const { isError } = await apiManager.getApi('DELETE_CURRENCY', { id });
    if (!isError) {
        setCurrenciesForcedUpdateTime();
    }
}
