import React from 'react';
import { observer } from 'mobx-react';
import { Form } from 'antd';
import { Catcher } from '../../';
import { TermsFormFields } from './TermsFormFields';
import '../../../customStyle/form.less';
import '../../../customStyle/modal.less';
import '../styles.less';

export const TermsForm: React.FC = observer(() => {
    const [form] = Form.useForm();

    return (
        <Catcher>
            <Form form={form} name="terms" className={'formStyle'} layout={'vertical'}>
                <TermsFormFields />
            </Form>
        </Catcher>
    );
});
