import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { IComponentName, IFilterParams, IParamsList, SelectType } from '../../interfaces';
import {
    usePagesStoreCurrentComponent,
    useRouterStore,
    useUiInitPageList,
    useUiLastViewComponentName,
    useUiViewInfo,
} from '../../hooks';
import {
    abortAllFetch,
    getDefaultPageParams,
    setRouterStoreUseExternalUrlManager,
    setUiForcedUpdateTime,
    setUiInitPageList,
    setUiLastViewComponentName,
    setUiUrlStorePage,
} from '../../middleware';
import { equalObjects, queryParamsCleanDefault, queryParamsNormalize, urlParamsCleaner } from '../../instruments';

interface IUrlManagerProps {
    cleanStore(): void;
    defaultParams?: IParamsList;
    filterParams?: IFilterParams;
    getRelationSelect?(): void;
    setForcedUpdateTime?(): void;
}

export const FilterUrlManager: React.FC<IUrlManagerProps> = observer(
    ({ cleanStore, defaultParams, filterParams, getRelationSelect, setForcedUpdateTime = setUiForcedUpdateTime }) => {
        const [filterCurrentComponentName, setFilterCurrentComponentName] = useState<IComponentName>();
        const routerStore = useRouterStore();
        const lastViewComponentName = useUiLastViewComponentName();
        const { queryParams } = routerStore.routerState;
        const requestPage = usePagesStoreCurrentComponent();
        const { routeName } = requestPage;
        const cleanQueryParams = urlParamsCleaner({ ...queryParams, routeName }, filterParams);
        const normalizeCleanQueryParams = queryParamsNormalize(cleanQueryParams, filterParams);
        const defaultPage = getDefaultPageParams(routeName);
        const { unitTypeIds, unitTypeArrId } = useUiInitPageList();
        defaultPage.unitTypeIds = filterParams?.unitTypeIds === SelectType.SINGLE ? unitTypeIds : unitTypeArrId;
        const storeParams = useUiViewInfo();
        const cleanStoreParams = urlParamsCleaner(storeParams, filterParams);

        const updateFirstStart = (): void => {
            let newParams: IParamsList = urlParamsCleaner(
                {
                    ...defaultPage,
                },
                filterParams,
            );
            if (routeName === routerStore.routerState.routeName) {
                const cleanFirstQueryParams = urlParamsCleaner(requestPage.queryParams || {}, filterParams);
                const normalizeFirstCleanQueryParams = queryParamsNormalize(cleanFirstQueryParams, filterParams);
                newParams = urlParamsCleaner(
                    {
                        ...defaultPage,
                        ...defaultParams,
                        ...normalizeFirstCleanQueryParams,
                    },
                    filterParams,
                );
            }
            setUiUrlStorePage({ ...newParams, routeName });
            const newQueryParams = filterParams?.isDateRangeCleanedDefaultURL
                ? queryParamsCleanDefault(newParams, filterParams)
                : newParams;
            routerStore.goTo(filterCurrentComponentName || routeName, { queryParams: newQueryParams });
            setForcedUpdateTime();
        };

        const updatePageUrl = (): void => {
            if (filterCurrentComponentName && filterCurrentComponentName === routerStore.routerState.routeName) {
                const newParams: IParamsList = urlParamsCleaner(
                    {
                        ...defaultPage,
                        ...storeParams,
                    },
                    filterParams,
                );
                if (!equalObjects(newParams, normalizeCleanQueryParams)) {
                    const cleanQueryParams = urlParamsCleaner(newParams, filterParams);
                    const newUrlParams = filterParams?.isDateRangeCleanedDefaultURL
                        ? queryParamsCleanDefault(cleanQueryParams, filterParams)
                        : cleanQueryParams;
                    routerStore.goTo(filterCurrentComponentName || routeName, { queryParams: newUrlParams });
                }
            }
        };
        const updatePageStore = (): void => {
            if (filterCurrentComponentName && filterCurrentComponentName === routerStore.routerState.routeName) {
                const newParams: IParamsList = urlParamsCleaner(
                    {
                        ...defaultPage,
                        ...normalizeCleanQueryParams,
                    },
                    filterParams,
                );
                if (!equalObjects(newParams, cleanStoreParams)) {
                    setUiUrlStorePage({ ...newParams, routeName });
                    setForcedUpdateTime();
                }
            }
        };

        useEffect(() => {
            setRouterStoreUseExternalUrlManager(true);
            setFilterCurrentComponentName(routeName);
            if (routeName !== lastViewComponentName) {
                setUiInitPageList(filterParams);
                setUiLastViewComponentName(routeName);
                updateFirstStart();
            } else {
                setForcedUpdateTime();
            }
            getRelationSelect && getRelationSelect();
            return (): void => {
                abortAllFetch();
                cleanStore();
                setRouterStoreUseExternalUrlManager(false);
            };
        }, []);

        useEffect(() => {
            updatePageStore();
        }, [JSON.stringify(normalizeCleanQueryParams)]);

        useEffect(() => {
            updatePageUrl();
        }, [JSON.stringify(cleanStoreParams)]);

        return null;
    },
);
