import React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import { TablePaginationConfig } from 'antd/es/table';
import { CardLayout, Catcher, LinkComponent, SkyPaginationTotal } from '../../';
import dayjs from 'dayjs';
import { appConfig } from '../../../settings';
import { IIncludeAssignedCompanyItem, IShowCardColumn } from '../../../interfaces';
import { useIncludeAssignedCompaniesActive } from '../../../hooks';
import { tF } from '../../../translate';

export const CompaniesAssignmentsShow: React.FC<IShowCardColumn> = observer(({ columnOption }: IShowCardColumn) => {
    const pageSize = appConfig.pageDefaultParams.includeViewPageSize;
    const list = useIncludeAssignedCompaniesActive();

    const tableColumns = [
        {
            title: tF('Company'),
            dataIndex: 'companyLink',
            ellipsis: true,
        },
        {
            title: tF('Start Date'),
            dataIndex: 'startDate',
            width: 180,
        },
        {
            title: tF('End Date'),
            dataIndex: 'endDate',
            width: 180,
        },
    ];

    const tableData = list.map((r: IIncludeAssignedCompanyItem) => {
        const key = r.id;

        return {
            key,
            companyLink: <LinkComponent currentListComponent={'CompaniesList'} id={r.companyId} name={r.company} />,
            startDate: r.startDate?.isValid() ? dayjs(r.startDate).format(appConfig.formatDate) : undefined,
            endDate: r.endDate?.isValid() ? dayjs(r.endDate).format(appConfig.formatDate) : undefined,
        };
    });

    const pagination: false | TablePaginationConfig =
        pageSize > list.length
            ? false
            : {
                  total: list.length,
                  pageSize: pageSize,
                  className: 'customPagination',
                  showSizeChanger: false,
                  size: 'default',
                  showTotal: SkyPaginationTotal,
              };

    return (
        <Catcher>
            <CardLayout
                columnOption={columnOption}
                count={list.length}
                idSpinners={['GET_CARD', 'GET_SALES_RATE_LIST']}
                isTable
                title={'Companies Assignments'}
            >
                <Table
                    dataSource={tableData}
                    columns={tableColumns}
                    pagination={pagination}
                    className={'table-theme sub-table'}
                    size={'middle'}
                />
            </CardLayout>
        </Catcher>
    );
});
