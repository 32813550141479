import React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import { TablePaginationConfig } from 'antd/es/table';
import { ReconciliationOutlined } from '@ant-design/icons';
import { appConfig } from '../../../settings';
import { IColumnOptionComponent, ICompanyRateItem } from '../../../interfaces';
import { useCompanySalesRateList } from '../../../hooks';
import { dateText2Utc } from '../../../instruments';
import { formatDataTestAttribute } from '../../../instruments/testing';
import { tF, tI } from '../../../translate';
import { CardLayout, Catcher, LinkComponent, SkyPaginationTotal } from '../../../Components';

export const ShowCompanySalesRateTable: React.FC<IColumnOptionComponent> = observer(
    ({ columnOption }: IColumnOptionComponent) => {
        const pageSize = appConfig.pageDefaultParams.includeViewPageSize;
        const list = useCompanySalesRateList();

        const tableColumns = [
            {
                title: (
                    <span data-test={formatDataTestAttribute('ShowCompanySalesRateTable', 'RateList', `Header`)}>
                        {tF('Rate List')}
                    </span>
                ),
                dataIndex: 'nameLink',
            },
            {
                title: (
                    <span data-test={formatDataTestAttribute('ShowCompanySalesRateTable', 'StartDate', `Header`)}>
                        {tF('Start Date')}
                    </span>
                ),
                dataIndex: 'dateStart',
                width: 180,
            },
            {
                title: (
                    <span data-test={formatDataTestAttribute('ShowCompanySalesRateTable', 'EndDate', `Header`)}>
                        {tF('End Date')}
                    </span>
                ),
                dataIndex: 'dateEnd',
                width: 180,
            },
        ];

        const tableData = list.map((s: ICompanyRateItem, index) => ({
            key: s.id,
            nameLink: (
                <span data-test={formatDataTestAttribute('ShowCompanySalesRateTable', 'NameLink', `${index}`)}>
                    <LinkComponent currentListComponent={'SalesRateLists'} id={s.rateId} name={s.rateName} />
                </span>
            ),
            dateStart: (
                <span data-test={formatDataTestAttribute('ShowCompanySalesRateTable', 'DateStart', `${index}`)}>
                    {dateText2Utc(s.startDate)}
                </span>
            ),
            dateEnd: (
                <span data-test={formatDataTestAttribute('ShowCompanySalesRateTable', 'DateEnd', `${index}`)}>
                    {s.endDate ? dateText2Utc(s.endDate) : tI('Never')}
                </span>
            ),
        }));

        const pagination: false | TablePaginationConfig =
            pageSize > list.length
                ? false
                : {
                      total: list.length,
                      pageSize: pageSize,
                      className: 'customPagination',
                      showSizeChanger: false,
                      size: 'default',
                      showTotal: SkyPaginationTotal,
                  };

        return (
            <Catcher>
                <CardLayout
                    columnOption={columnOption}
                    count={list.length}
                    icon={<ReconciliationOutlined />}
                    idSpinners={['GET_COMPANY']}
                    isTable
                    title={'Rates list'}
                    dataTest={'ShowCompanySalesRateTable'}
                >
                    <Table
                        className={'table-theme'}
                        columns={tableColumns}
                        data-test={'ShowCompanySalesRateTable'}
                        dataSource={tableData}
                        pagination={pagination}
                        size={'small'}
                    />
                </CardLayout>
            </Catcher>
        );
    },
);
