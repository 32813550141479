/* eslint-disable */
import { AlarmsStore } from '../stores/alarms';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { appConfig } from '../settings';
import { IApiParams, apiManager } from '../REST';
import { IRequestAPIBody, IServerNotificationAPIGETDataItem, IServerNotificationItem } from '../interfaces';
import { mergeByProperty } from '../instruments';

dayjs.extend(relativeTime);

function getAlarmsUnreadShowCount(): number {
    const AlarmsInterface = AlarmsStore.getInstance();
    return AlarmsInterface.showCount;
}

function getAlarmsServerNotificationsRead(): IServerNotificationItem[] {
    const AlarmsStoreInstance = AlarmsStore.getInstance();
    return AlarmsStoreInstance.serverNotificationsRead;
}

function setAlarmsServerNotificationRemoveReadItem(id: string): void {
    const AlarmsStoreInstance = AlarmsStore.getInstance();
    const readList = AlarmsStoreInstance.serverNotificationsRead.filter((i) => i.id !== id);
    AlarmsStoreInstance.setServerNotifications(readList, true);
}

export function setAlarmsServerNotificationsIsShowRead(isRead: boolean): void {
    const AlarmsStoreInstance = AlarmsStore.getInstance();
    AlarmsStoreInstance.setServerNotificationsIsShowRead(isRead);
}
export function setAlarmsServerNotifications(list: IServerNotificationItem[], isRead: boolean): void {
    const AlarmsStoreInstance = AlarmsStore.getInstance();

    if (!isRead) {
        AlarmsStoreInstance.setServerNotifications(list, isRead);
    } else {
        const readCount = getAlarmsUnreadShowCount();
        if (readCount === appConfig.checkServerNotificationsPageSize) {
            AlarmsStoreInstance.setServerNotifications(list, isRead);
        } else {
            const currentList = getAlarmsServerNotificationsRead();
            const newList: IServerNotificationItem[] = mergeByProperty(currentList, list, 'id');
            AlarmsStoreInstance.setServerNotifications(newList, isRead);
        }
    }
}
export function setAlarmsServerNotificationsCount(count: number, isRead: boolean, countAll: number): void {
    const AlarmsStoreInstance = AlarmsStore.getInstance();
    AlarmsStoreInstance.setServerNotificationsCount(count, isRead);
    const isShowRead = AlarmsStoreInstance.isShowServerNotificationsRead;
    // if active read Tabs no calc read count
    if (countAll && ((!isShowRead && !isRead) || (isShowRead && isRead))) {
        AlarmsStoreInstance.setServerNotificationsCount(countAll - count, !isRead);
    }
}

export function setAlarmsShowCountIncrease(): void {
    const AlarmsStoreInstance = AlarmsStore.getInstance();
    AlarmsStoreInstance.setShowCountIncrease();
}

export function setAlarmsShowCountReset(): void {
    const AlarmsStoreInstance = AlarmsStore.getInstance();
    AlarmsStoreInstance.setShowCountReset();
}

function mapAPIServerNotifications(data: IServerNotificationAPIGETDataItem[]): IServerNotificationItem[] {
    return data.map((value) => {
        const payload = value?.attributes?.payload || {};
        let { balance = 0, threshold = 0, monthly_cost = 0 } = payload;
        const newBalance = +balance?.toFixed(2);
        const newThreshold = +threshold?.toFixed(2);
        const newMonthlyCost = +monthly_cost?.toFixed(2);
        const newPayload = { ...payload, balance: newBalance, threshold: newThreshold, monthly_cost: newMonthlyCost };

        return {
            id: value?.id || 'xxx',
            isRead: value?.attributes?.read,
            event: value?.attributes?.event,
            dateAgo: `${dayjs(value?.attributes?.created_at).fromNow()}`,
            payload: newPayload,
        };
    });
}

export function setIsPopupOpen(isOpen: boolean): void {
    const AlarmsInterface = AlarmsStore.getInstance();
    AlarmsInterface.setIsPopupOpen(isOpen);
}

export async function getServerNotifications(isRead: boolean): Promise<number> {
    const params: IApiParams = {
        searchParamsList: { read: `${isRead}` },
    };

    if (!isRead && params.searchParamsList) {
        params.searchParamsList.stream = `${!isRead}`;
    }

    if (isRead && params.searchParamsList) {
        params.searchParamsList.pageSize = appConfig.checkServerNotificationsPageSize;
        params.searchParamsList.pageNumber = getAlarmsUnreadShowCount() / appConfig.checkServerNotificationsPageSize;
    }

    const { resp, isError } = await apiManager.getApi('GET_SERVER_NOTIFICATIONS', params);

    if (!isError) {
        resp?.data &&
            setAlarmsServerNotifications(
                mapAPIServerNotifications(resp.data as IServerNotificationAPIGETDataItem[]),
                isRead,
            );
        resp?.meta &&
            setAlarmsServerNotificationsCount(
                Number(resp?.meta?.records_count),
                isRead,
                Number(resp?.meta?.total_count),
            );
    }

    return Number(resp?.meta?.records_count);
}

export async function getServerNotificationsFirstStart(): Promise<void> {
    const unreadCount = await getServerNotifications(false);

    if (unreadCount) {
        setIsPopupOpen(true);
    }
}

export async function changeStatusServerNotificationsItem(id: string, isRead: boolean): Promise<void> {
    const body: IRequestAPIBody = {
        data: {
            id,
            attributes: { read: isRead },
            type: 'company_notification',
        },
    };

    const { isError } = await apiManager.getApi('PATCH_SERVER_NOTIFICATION', { id }, body);

    if (!isError) {
        if (!isRead) {
            setAlarmsServerNotificationRemoveReadItem(id);
        }
        await getServerNotifications(!isRead);
    }
}
