import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Form, Row } from 'antd';
import { Catcher, CombinationCardSetCardEdit, CompaniesAssignmentsEdit, cardLayoutOptions } from '../../';
import { EditCardSetFieldsInfo } from './';
import { FieldData } from 'rc-field-form/es/interface';
import { IEditForm, IUseCardSetForm } from '../../../interfaces';
import {
    useCardSetInfo,
    useCardSetIsLoadingInfo,
    useCompaniesChildrenSelect,
    useIncludeAssignedCompaniesActive,
    useIncludeCombinationCardSetCardsActive,
} from '../../../hooks';
import {
    apiMessagesSaveMessages,
    getCardSetCompanyRelations,
    sendCardSetData,
    setCardSetForm,
} from '../../../middleware';
import { onFormFieldsChange } from '../../../instruments';
import Styles from '../../styles/m_itemCardStyles.less';

export const EditCardSetIForm: React.FC<IEditForm> = observer(({ setEditMode, isNew }: IEditForm) => {
    const [showInactive, setShowInactive] = useState(false);
    const [isSending, setSending] = useState(false);

    const isLoading = useCardSetIsLoadingInfo();
    const [form] = Form.useForm();
    const initialInfo = useCardSetInfo();
    const assignedCompanies = useIncludeAssignedCompaniesActive();
    const idsAssignedCompanies = assignedCompanies.map((c) => c.id);
    const combinationCardSetCards = useIncludeCombinationCardSetCardsActive(!showInactive);
    const idsCombinationCardSetCards = combinationCardSetCards.map((c) => c.id);
    const initialValues: IUseCardSetForm = {
        ...initialInfo,
        assignedCompanies,
        combinationCardSetCards,
    };

    const checkValid = async (): Promise<void> => {
        await form.validateFields();
    };

    const initialForm = (): void => {
        form.setFieldsValue(initialValues);
    };

    useEffect(() => {
        if (initialInfo.companyId) {
            getCardSetCompanyRelations(initialInfo.companyId);
        }
    }, [initialInfo.companyId]);

    useEffect(() => {
        if (!isLoading && initialValues.id) {
            initialForm();
        }
    }, [initialInfo.companyId, assignedCompanies.length, combinationCardSetCards.length, isLoading]);

    const onFieldsChange = (changedFields: FieldData[]): void => {
        onFormFieldsChange({
            changedFields,
            initialValues,
            listIds: { assignedCompanies: idsAssignedCompanies, combinationCardSetCards: idsCombinationCardSetCards },
            setForm: setCardSetForm,
        });
    };

    const onFinish = async (): Promise<void> => {
        setSending(true);
        const { isError, errors = [] } = await sendCardSetData(isNew);
        if (isError) {
            apiMessagesSaveMessages(errors);
            checkValid();
        } else {
            setEditMode && setEditMode(false);
        }

        setSending(false);
    };

    return (
        <Catcher>
            <div className={Styles.main}>
                <Form.Provider>
                    <Form
                        form={form}
                        name="cardSetInfo"
                        initialValues={initialValues}
                        onFieldsChange={onFieldsChange}
                        onFinish={onFinish}
                        scrollToFirstError={true}
                        layout={'vertical'}
                    >
                        <Row>
                            <EditCardSetFieldsInfo isSending={isSending} save={onFinish} isNew={isNew} />
                            <CompaniesAssignmentsEdit
                                columnOption={cardLayoutOptions.cardAssignedCompanies}
                                companiesSelectHook={useCompaniesChildrenSelect}
                            />
                            <CombinationCardSetCardEdit
                                isNew={isNew}
                                columnOption={cardLayoutOptions.cardTableFull}
                                showInactive={showInactive}
                                setShowInactive={setShowInactive}
                            />
                        </Row>
                    </Form>
                </Form.Provider>
            </div>
        </Catcher>
    );
});
