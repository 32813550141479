import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { IGetApiType } from '../../../REST';
import { useRouterStore } from '../../../hooks';
import {
    abortSelectedFetch,
    addNotification,
    cleanOperatorInteractionsStore,
    getOperatorInteractionsResponse,
} from '../../../middleware';
import { tI, tNM, tP } from '../../../translate';
import { Catcher } from '../../Catcher';
import { ShowOperatorInteractionsResponse } from './Components';

export const OperatorInteractionsResponse: React.FC = observer(() => {
    const routerStore = useRouterStore();
    const [id, setId] = useState('');
    const usedApiTypes: IGetApiType[] = ['GET_OPERATOR_INTERACTIONS_RESPONSE'];

    const goOut = (): void => {
        abortSelectedFetch(usedApiTypes);
        cleanOperatorInteractionsStore();
    };

    const goToList = (): void => {
        routerStore.goTo('OperatorInteractionsResponsesList');
        goOut();
    };

    const _getById = async (id: string): Promise<void> => {
        const isGet = await getOperatorInteractionsResponse(id);
        if (!isGet) {
            addNotification({
                type: 'error',
                message: tNM('Not Found'),
                description: `${tP('Operator Interactions Response')} - "${id}" ${tI('not found')}`,
                duration: 5,
                isTranslated: true,
            });
            goToList();
        }
    };

    useEffect(() => {
        return (): void => {
            goOut();
        };
    }, []);

    useEffect(() => {
        if (id) {
            _getById(id);
        }
    }, [id]);

    useEffect(() => {
        if (routerStore.routerState.params.id) {
            setId(routerStore.routerState.params.id);
        }
    }, [routerStore.routerState.params.id]);

    return (
        <Catcher>
            <ShowOperatorInteractionsResponse />
        </Catcher>
    );
});
