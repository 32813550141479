import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { ICardListItem, IFilterParams, ISelectList } from '../../interfaces';

export function useCardsSelect(): ISelectList {
    return useContext(MobXProviderContext).RootStore.cardsStore.select;
}

export function useCardsList(): ICardListItem[] {
    return useContext(MobXProviderContext).RootStore.cardsStore.cardsList;
}

export function useCardsFilterParams(): IFilterParams {
    const { filterParams } = useContext(MobXProviderContext).RootStore.cardsStore;

    return {
        ...filterParams,
        cardSetId: !useContext(MobXProviderContext).RootStore.profileStore.profile.isCompanyTypeIdMNOHost,
    };
}
