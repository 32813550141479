import { action, makeObservable, observable } from 'mobx';
import { ICountryActiveCardItem, ICountryInfo, ICountryOperatorItem } from '../interfaces';

export class CountryStore {
    private constructor() {
        makeObservable(this);
    }

    private static instance: CountryStore;
    public static getInstance(): CountryStore {
        if (!CountryStore.instance) {
            CountryStore.instance = new CountryStore();
        }
        return CountryStore.instance;
    }

    initInfoStore: ICountryInfo = {
        id: '',
    };

    @observable info: ICountryInfo = this.initInfoStore;
    @action setCountryInfo(Country: ICountryInfo): void {
        this.info = Country;
    }

    @observable operatorsList: ICountryOperatorItem[] = [];
    @action setOperatorsList(operatorsList: ICountryOperatorItem[]): void {
        this.operatorsList = operatorsList;
    }

    @observable activeCardsList: ICountryActiveCardItem[] = [];
    @action setActiveCardsList(activeCardsList: ICountryActiveCardItem[]): void {
        this.activeCardsList = activeCardsList;
    }

    @action cleanStore(): void {
        this.info = this.initInfoStore;
        this.activeCardsList = [];
        this.operatorsList = [];
    }
}
