import React from 'react';
import { observer } from 'mobx-react';
import { Catcher, ListWrapper } from '../';
import { useDiameterSessionsListParams } from '../../hooks';
import { cleanDiameterSessionsStore } from '../../middleware';
import { DiameterSessionsTableList } from './Components/DiameterSessionsTableList';

export const DiameterSessionsList: React.FC = observer(() => {
    const filterParams = useDiameterSessionsListParams();

    return (
        <Catcher>
            <ListWrapper cleanStore={cleanDiameterSessionsStore} filterParams={filterParams}>
                <DiameterSessionsTableList />
            </ListWrapper>
        </Catcher>
    );
});
