import React, { ReactNode, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Badge, Button, Popover, Tabs } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Catcher } from '../';
import cx from 'classnames';
import {
    useIsPopupOpen,
    useUiLanguage,
    useUiServerNotificationsCount,
    useUiServerNotificationsIsShowRead,
    useUiServerNotificationsStatus,
} from '../../hooks';
import {
    getServerNotifications,
    getServerNotificationsFirstStart,
    setAlarmsServerNotificationsIsShowRead,
    setAlarmsShowCountIncrease,
    setAlarmsShowCountReset,
    setIsPopupOpen,
} from '../../middleware';
import { tI, tP } from '../../translate';
import { TabContent } from './Components';
import Styles from './m_styles.less';

export const ServerNotification: React.FC = observer(() => {
    const { TabPane } = Tabs;
    const languageId = useUiLanguage();
    const isShowRead = useUiServerNotificationsIsShowRead();
    const [lastTabIndex, setTabIndex] = useState(1);
    const isConnectServer = useUiServerNotificationsStatus();
    const countUnread = useUiServerNotificationsCount(false);
    const countRead = useUiServerNotificationsCount(true);
    const isPopupOpen = useIsPopupOpen();

    useEffect(() => {
        getServerNotificationsFirstStart();
    }, []);

    const handleTabChange = (value: string): void => {
        if (+value !== lastTabIndex) {
            setAlarmsShowCountReset();
            setAlarmsServerNotificationsIsShowRead(!isShowRead);
            setTabIndex(+value);
        }
    };

    const handleIncrease = (isRead: boolean): void => {
        setAlarmsShowCountIncrease();
        if (isRead) {
            getServerNotifications(isRead);
        }
    };

    const onVisibleChange = (onVisible: boolean): void => {
        setIsPopupOpen(onVisible);
        if (!onVisible) {
            setAlarmsShowCountReset();
        } else {
            if (lastTabIndex === 2) {
                setAlarmsServerNotificationsIsShowRead(false);
                setTabIndex(1);
            }
        }
    };

    // const NotificationsToolBar: ReactNode = (
    //     <div className={Styles.notificationsToolBar}>
    //         <div className={Styles.bold}>Mark ALL notifications as read</div>
    //         <CheckCircleOutlined
    //             className={cx(Styles.button, { [Styles.green]: !!countUnread })}
    //             onClick={(): void => readAllUiStoreServerNotification()}
    //         />
    //         {/*<CloseCircleOutlined*/}
    //         {/*    className={cx(Styles.button, Styles.red)}*/}
    //         {/*    onClick={(): void => removeUiStoreAllServerNotification()}*/}
    //         {/*    title={'remove ALL notifications'}*/}
    //         {/*/>*/}
    //     </div>
    // );
    const PopoverTitle = (): ReactNode => {
        return (
            <div className={Styles.popoverTitle}>
                <h3 className={cx(Styles.titleItem, Styles.title)}>{tP('Notifications')}</h3>
                <div className={Styles.titleItem}>
                    <Badge
                        count={`${countUnread}`}
                        className={cx({
                            [Styles.badgeStatusOk]: !countUnread,
                            [Styles.badgeStatusError]: !!countUnread,
                        })}
                    />
                </div>
            </div>
        );
    };

    const Notifications = (): ReactNode => {
        return (
            <Catcher>
                {/*{unreadCount ? NotificationsToolBar : null}*/}
                <div className={Styles.tabContainer}>
                    <Tabs
                        className={Styles.tabRight}
                        defaultActiveKey="1"
                        onChange={handleTabChange}
                        centered={true}
                        activeKey={`${lastTabIndex}`}
                    >
                        <TabPane tab={`${tI('Unread')} ${countUnread}`} key="1">
                            <TabContent
                                isShowRead={isShowRead}
                                total={countUnread}
                                handleIncrease={(): void => handleIncrease(isShowRead)}
                            />
                        </TabPane>
                        <TabPane tab={`${tI('Read')} ${countRead}`} key="2">
                            <TabContent
                                isShowRead={isShowRead}
                                total={countRead}
                                handleIncrease={(): void => handleIncrease(isShowRead)}
                            />
                        </TabPane>
                    </Tabs>
                </div>
            </Catcher>
        );
    };

    return (
        <Catcher>
            <div className={Styles.main}>
                <Badge count={countUnread}>
                    <Popover
                        placement="bottom"
                        title={PopoverTitle}
                        content={Notifications}
                        trigger="click"
                        onOpenChange={onVisibleChange}
                        open={isPopupOpen}
                    >
                        <Button
                            type="text"
                            icon={
                                <ExclamationCircleOutlined
                                    className={cx(Styles.icon, {
                                        [Styles.badStatusIcon]: !isConnectServer,
                                        [Styles.isActiveIcon]: !!countUnread && isConnectServer,
                                    })}
                                />
                            }
                        />
                    </Popover>
                </Badge>
            </div>
        </Catcher>
    );
});
