import React from 'react';
import { observer } from 'mobx-react';
import { Switch, Tooltip } from 'antd';
import { Catcher } from '../';
import moon from './img/moon.svg';
import sun from './img/sun.svg';
import cx from 'classnames';
import { useUiDarkTheme, useUiLanguage } from '../../hooks';
import { setUiDarkTheme } from '../../middleware';
import { tB } from '../../translate';
import Styles from './m_styles.less';

export const DarkThemeSwitch: React.FC = observer(() => {
    const isDark = useUiDarkTheme();
    const languageId = useUiLanguage();

    const onChange = (isDark: boolean): void => {
        setUiDarkTheme(isDark);
    };

    return (
        <Catcher>
            <div className={Styles.main}>
                <Tooltip title={`${tB('Dark Theme')}: ${!isDark ? 'Off' : 'On'}`} placement="bottomRight">
                    <Switch
                        className={cx(isDark ? Styles.dark : Styles.light)}
                        data-test="DarkThemeSwitch"
                        defaultChecked={isDark}
                        onChange={onChange}
                        checkedChildren={<img className={Styles.icon} src={sun} alt={'sun'} />}
                        unCheckedChildren={<img className={Styles.icon} src={moon} alt={'moon'} />}
                    />
                </Tooltip>
            </div>
        </Catcher>
    );
});
