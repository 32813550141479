import React from 'react';
import { observer } from 'mobx-react';
import { Catcher, ListWrapper } from '../';
import { ActivityLogTableList } from './ActivityLogTableList';
import { useActivityLogFilterParams, useProfileIsRight } from '../../hooks';
import { cleanActivityLogStore, getActivityLogRelationSelect } from '../../middleware';

export const ActivityLogList: React.FC = observer(() => {
    const filterParams = useActivityLogFilterParams();
    const isLord = useProfileIsRight('LORD');

    return (
        <Catcher>
            <ListWrapper
                cleanStore={cleanActivityLogStore}
                filterParams={filterParams}
                getRelationSelect={getActivityLogRelationSelect}
                isNotFilterToolbar={!isLord}
            >
                <ActivityLogTableList />
            </ListWrapper>
        </Catcher>
    );
});
