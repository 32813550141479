import React from 'react';
import { observer } from 'mobx-react';
import { Row } from 'antd';
import { CheckSquareOutlined, CloseSquareOutlined } from '@ant-design/icons';
import { CardInfoToolBar, CardMainLayout, Catcher, FieldInfoAdvanced } from '../../';
import { IFieldLayout, IShowForm } from '../../../interfaces';
import { useCurrencyInfo } from '../../../hooks';
import Styles from '../../styles/m_itemCardStyles.less';

export const ShowCurrency: React.FC<IShowForm> = observer(({ setEditMode }: IShowForm) => {
    const { abbreviation, description, selectable, symbol } = useCurrencyInfo();
    const layout: IFieldLayout = {
        labelCol: {
            flex: '0 1 100px',
        },
        valCol: {
            flex: '1 1 130px',
        },
    };

    return (
        <Catcher>
            <article className={Styles.main}>
                <Row>
                    <CardMainLayout idSpinners={['GET_CURRENCY']} name={abbreviation}>
                        <FieldInfoAdvanced layout={layout} title={'Abbreviation'}>
                            {abbreviation}
                        </FieldInfoAdvanced>
                        <FieldInfoAdvanced layout={layout} title={'Description'}>
                            {description}
                        </FieldInfoAdvanced>
                        <FieldInfoAdvanced layout={layout} title={'Selectable'}>
                            {selectable ? (
                                <CheckSquareOutlined className={Styles.checkIcon} />
                            ) : (
                                <CloseSquareOutlined className={Styles.uncheckIcon} />
                            )}
                        </FieldInfoAdvanced>
                        <FieldInfoAdvanced layout={layout} title={'Symbol'}>
                            {symbol}
                        </FieldInfoAdvanced>
                        <CardInfoToolBar setEditMode={setEditMode} isBackToList />
                    </CardMainLayout>
                </Row>
            </article>
        </Catcher>
    );
});
