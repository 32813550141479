import React from 'react';
import { observer } from 'mobx-react';
import { Loading, LoadingText } from './';
import { ILoadingApi } from './interfaces';
import { useUiIsLoading } from '../../hooks';

export const LoadingApi: React.FC<ILoadingApi> = observer(({ size, tip = LoadingText() }: ILoadingApi) => {
    const isLoading = useUiIsLoading();
    return <Loading isLoading={isLoading} size={size} tip={tip} />;
});
