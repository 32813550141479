import { useEffect, useState } from 'react';
import { appConfig } from '../settings';
import { IGeoLocation } from '../interfaces';
import { appSignalSendMessage } from '../instruments';

const defaultCenter: IGeoLocation = appConfig.googleMapCenter;

export const useGetDefaultMapCenter = (): IGeoLocation => defaultCenter;

export const useGetBrowserLocation = (): IGeoLocation => {
    const [location, setLocation] = useState<IGeoLocation>(defaultCenter);
    useEffect(() => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
                (pos: GeolocationPosition): void => {
                    setLocation({ lat: pos.coords.latitude, lng: pos.coords.longitude });
                },
                (err: GeolocationPositionError): void => {
                    if (process.env.NODE_ENV !== 'development') {
                        appSignalSendMessage({
                            action: 'useGetBrowserLocation',
                            message: err.message,
                            params: {
                                code: err.code,
                                message: err.message,
                                PERMISSION_DENIED: err.PERMISSION_DENIED,
                                POSITION_UNAVAILABLE: err.POSITION_UNAVAILABLE,
                                TIMEOUT: err.TIMEOUT,
                            },
                        });
                    }
                    console.warn('GetGeoLocation ERROR:', err);
                },
            );
        }
    }, []);
    return location;
};
