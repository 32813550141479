import React from 'react';
import { observer } from 'mobx-react';
import { Row } from 'antd';
import { Catcher } from '../../';
import {
    ShowCountryActiveCardsTable,
    ShowCountryInfo,
    ShowCountryOperatorsTable,
    ShowCountryTelecomUsageTable,
} from './';
import { IShowForm } from '../../../interfaces';
import Styles from '../../styles/m_itemCardStyles.less';

export const ShowCountry: React.FC<IShowForm> = observer(({ setEditMode }: IShowForm) => {
    return (
        <Catcher>
            <div className={Styles.main}>
                <Row>
                    <ShowCountryInfo setEditMode={setEditMode} />
                    <ShowCountryOperatorsTable />
                    <ShowCountryActiveCardsTable />
                    <ShowCountryTelecomUsageTable />
                </Row>
            </div>
        </Catcher>
    );
});
