import { Ref, useEffect, useRef } from 'react';
import { InputRef } from 'antd';

export const useInputRefFocus = (): Ref<InputRef> => {
    const inputRef = useRef<InputRef>(null);

    useEffect(() => {
        inputRef?.current?.focus();
    }, []);

    return inputRef;
};
