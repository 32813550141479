import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import format from 'dayjs/plugin/utc';
import { appConfig } from '../settings';
import { IParamsList } from '../interfaces';

dayjs.extend(format);
dayjs.extend(isBetween);

export const isValidTextDate = (d?: string): boolean => {
    if (!d) {
        return false;
    }
    return dayjs(d).isValid();
};

export const getFirstDayCurrentMonthAsText = (): string => {
    return dayjs().startOf('month').format(appConfig.formatDate);
};

export const checkIsToday = (strDate: string | undefined): boolean =>
    dayjs().format('YYYY-MM-DD') === dayjs(strDate).format('YYYY-MM-DD');

export const getTodayAsText = (): string => {
    return dayjs().format(appConfig.formatDate);
};
export const checkAvailableMaxDateToday = (date?: string): Dayjs => {
    const maxDate = dayjs();
    const receiveDate = isValidTextDate(date) ? dayjs(date) : dayjs();
    return receiveDate.isBefore(maxDate) ? receiveDate : maxDate;
};
export const checkAvailableMaxDateTodayAsText = (date?: string): string => {
    return checkAvailableMaxDateToday(date).format(appConfig.formatDate);
};

export const getAvailableMinDate = (): Dayjs => {
    return dayjs().subtract(appConfig.maximumMonthsForRequest, 'M').startOf('M');
};

export const checkAvailableMinDate = (date?: string): Dayjs => {
    const minDate = getAvailableMinDate();
    const receiveDate = isValidTextDate(date) ? dayjs(date) : dayjs();
    return receiveDate.isAfter(minDate) ? receiveDate : minDate;
};

export const checkAvailableMinDateText = (date?: string): string => {
    return checkAvailableMinDate(date).format(appConfig.formatDate);
};

export const dateText2Utc = (dateText?: any, isNotUTC?: boolean): string => {
    return dateText ? `${dayjs(dateText).utc().format(appConfig.formatDateTime)} ${isNotUTC ? '' : ' UTC'}` : '';
};

export const checkTodayIsBetweenParams = (params: IParamsList): boolean => {
    return dayjs(getTodayAsText()).isBetween(params.startDate, params.endDate, undefined, '[]');
};

export const checkIsBeforeTodayParams = (params: IParamsList): boolean => {
    return dayjs(params.startDate).isBefore(dayjs().format('YYYY-MM-DD'), 'day');
};
