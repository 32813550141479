import React, { ReactNode, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Catcher } from '../../';
import { EditRateCountry } from './';
import { useCountriesWithZoneList } from '../../../hooks';
import { setCountryLoadingStats } from '../../../middleware';
import Styles from '../m_styles.less';

interface IEditRateCountriesList {
    operatorId: string;
    zoneId: string;
    expandedRowKeys: (string | number)[];
    recordKey: string | number;
}

export const EditRateCountriesList: React.FC<IEditRateCountriesList> = observer(
    ({ zoneId, operatorId, expandedRowKeys, recordKey }: IEditRateCountriesList) => {
        const [expandedCountryRowKey, setExpandedCountryRowKey] = useState<string>();
        const [JSX, setJSX] = useState<Array<ReactNode>>();
        const countrySelect = useCountriesWithZoneList().filter((c) => c.zoneId === zoneId);

        useEffect(() => {
            if (!expandedRowKeys?.includes(recordKey)) {
                setJSX([]);
            } else {
                const countryListJSX = countrySelect.map((c) => {
                    return (
                        <EditRateCountry
                            countryId={c.id}
                            expandedCountryRowKey={expandedCountryRowKey}
                            key={c.id}
                            name={c.name ? c.name : c.id}
                            operatorId={operatorId}
                            setExpandedCountryRowKey={setExpandedCountryRowKey}
                        />
                    );
                });

                setCountryLoadingStats(countryListJSX.length, 'expectedToLoad');
                setJSX(countryListJSX);
            }
        }, [JSON.stringify(expandedRowKeys)]);

        return (
            <Catcher>
                <div className={Styles.wrapperCountryList}>{JSX}</div>
            </Catcher>
        );
    },
);
