import React from 'react';
import { observer } from 'mobx-react';
import { Divider } from 'antd';
import { FlagOutlined } from '@ant-design/icons';
import { IFieldLayout, IShowForm } from '../../../interfaces';
import { useProfileFeatureFlags } from '../../../hooks';
import { mapUi2ApiField, sortByName } from '../../../instruments';
import { ITrField } from '../../../translate';
import {
    CardInfoToolBar,
    CardMainLayout,
    Catcher,
    FieldInfoAdvanced,
    GoPageButton,
    cardLayoutOptions,
    showIconBoolOrNumber,
} from '../../../Components';

type IListFlags = {
    name: string;
    value?: any;
};

export const ShowUserFeatureFlags: React.FC<IShowForm> = observer(() => {
    const layout: IFieldLayout = {
        labelCol: {
            flex: '0 1 220px',
        },
        valCol: {
            flex: '1 1 70px',
        },
    };
    const featureFlags = useProfileFeatureFlags();
    const flagsList: Array<IListFlags> = [];
    const featureFlagsAPI = mapUi2ApiField(featureFlags);
    for (const key in featureFlagsAPI) {
        flagsList.push({ name: key, value: featureFlagsAPI[key] });
    }

    return (
        <Catcher>
            <CardMainLayout
                columnOption={cardLayoutOptions.cardInfoEdit}
                idSpinners={['GET_FEATURE_FLAG_RULES']}
                name={'My Feature Flags'}
                icon={<FlagOutlined />}
            >
                {flagsList.sort(sortByName).map((i) => (
                    <FieldInfoAdvanced key={i.name} layout={layout} title={i.name as ITrField}>
                        {showIconBoolOrNumber(i.value)}
                    </FieldInfoAdvanced>
                ))}
                <Divider />
                <CardInfoToolBar isHideCancel>
                    <GoPageButton componentName={'FeatureFlags'} />
                </CardInfoToolBar>
            </CardMainLayout>
        </Catcher>
    );
});
