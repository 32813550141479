import { IColumnOption } from '../../interfaces';

type ICardType =
    | 'dashboardChartCard'
    | 'dashboardFullCard'
    | 'dashboardInfoCard'
    | 'dashboardInfoCardBig'
    | 'financesActiveCard'
    | 'financesInfoCard'
    | 'financesRevenuesCosts';

type IDashboardColumnOptions = {
    [id in ICardType]: IColumnOption;
};

export const columnOption: IDashboardColumnOptions = {
    dashboardInfoCard: {
        xs: 24, // 480
        sm: 24, // 576
        md: 12, // 768
        lg: 12, // 992
        xl: 5, // 1200
        xxl: 6, // 1600
    },
    dashboardInfoCardBig: {
        xs: 24, // 480
        sm: 24, // 576
        md: 12, // 768
        lg: 12, // 992
        xl: 7, // 1200
        xxl: 6, // 1600
    },
    dashboardChartCard: {
        xs: 24, // 480
        sm: 24, // 576
        md: 24, // 768
        lg: 12, // 992
        xl: 12, // 1200
        xxl: 12, // 1600
    },
    dashboardFullCard: {
        xs: 24, // 480
        sm: 24, // 576
        md: 24, // 768
        lg: 24, // 992
        xl: 24, // 1200
        xxl: 24, // 1600
    },
    financesRevenuesCosts: {
        xs: 24, // 480
        sm: 24, // 576
        md: 24, // 768
        lg: 24, // 992
        xl: 24, // 1200
        xxl: 12, // 1600
    },
    financesInfoCard: {
        xs: 24, // 480
        sm: 24, // 576
        md: 24, // 768
        lg: 12, // 992
        xl: 12, // 1200
        xxl: 5, // 1600
    },
    financesActiveCard: {
        xs: 24, // 480
        sm: 24, // 576
        md: 24, // 768
        lg: 12, // 992
        xl: 12, // 1200
        xxl: 7, // 1600
    },
};
