import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { useIsMyCompany, useProfile, useProfileIsRight } from './useProfileStores';
import dayjs from 'dayjs';
import { appConfig, isCompanyTypeIdMNOHost } from '../../settings';
import {
    IAboutInfo,
    IBlobIds,
    ICompanyApiKeyItem,
    ICompanyApiKeyList,
    ICompanyCardSetsList,
    ICompanyInfo,
    ICompanyOperator,
    ICompanyOperatorList,
    ICompanyRateList,
    ICompanyStatistics,
    ICompanySubscriptionInfo,
    IUser,
} from '../../interfaces';

export function useCompanyInfo(): ICompanyInfo {
    return useContext(MobXProviderContext).RootStore.companyStore.companyInfo;
}

export function useCompanyApiKeyList(): ICompanyApiKeyList {
    // const tempArr = [
    //     {
    //         id: '9',
    //         label: '123tHVvXF********',
    //         createdAt: '2023-12-05T17:42:22.385Z',
    //     },
    //     {
    //         id: '10',
    //         label: '456tHVvXF********',
    //         createdAt: '2023-12-05T17:42:22.385Z',
    //     },
    //     {
    //         id: '11',
    //         label: '789tHVvXF********',
    //         createdAt: '2023-12-05T17:42:22.385Z',
    //     },
    // ];

    const apiKeyList = useContext(MobXProviderContext).RootStore.companyStore.apiKeysList;

    return apiKeyList.map((value: ICompanyApiKeyItem) => {
        return {
            ...value,
            createdAt: dayjs(value.createdAt).format(appConfig.formatDateTime),
        };
    });
}

export function useCompanyAbout(): IAboutInfo {
    return useContext(MobXProviderContext).RootStore.companyStore.about;
}

export function useCompanyIsMNOHost(): boolean {
    const companyTypeId = useContext(MobXProviderContext).RootStore.companyStore.companyInfo.companyTypeId;
    return isCompanyTypeIdMNOHost(companyTypeId);
}

export function useCompanyStatistics(): ICompanyStatistics {
    return useContext(MobXProviderContext).RootStore.companyStore.companyStatistics;
}

export function useCompanySalesRateList(): ICompanyRateList {
    return useContext(MobXProviderContext).RootStore.companyStore.activeRateList;
}

export function useCompanyOperatorListId(): Array<string> {
    return useContext(MobXProviderContext)
        .RootStore.companyStore.operatorList.filter((i: ICompanyOperator) => !i.destroy && i.operatorId)
        .map((i: ICompanyOperator) => i.operatorId);
}

export function useCompanyOperatorList(): ICompanyOperatorList {
    return useContext(MobXProviderContext).RootStore.companyStore.operatorList;
}

export function useCompanyUsersList(): IUser[] {
    return useContext(MobXProviderContext).RootStore.companyStore.usersList;
}

export function useIsAbleToManageCompanyGeolocation(isMyCompany = true): boolean | undefined {
    const isLord = useProfileIsRight('LORD');
    const profileInfo = useProfile();
    return (profileInfo?.geolocationEnabled && !isMyCompany) || isLord;
}

export function useCompanyCardSetsList(): ICompanyCardSetsList {
    return useContext(MobXProviderContext).RootStore.companyStore.cardSetsList;
}

export function useCustomerPrivacyCheck(customerPrivacy: boolean): boolean {
    const isLord = useProfileIsRight('LORD');
    const isMyCompany = useIsMyCompany();

    return customerPrivacy && !isLord && !isMyCompany;
}

export function useBlobIds(): IBlobIds {
    return useContext(MobXProviderContext).RootStore.companyStore.logoBlobIds;
}

export function useCompanySubscription(): ICompanySubscriptionInfo {
    return useContext(MobXProviderContext).RootStore.companyStore.subscription;
}
