import { action, makeObservable, observable } from 'mobx';
import { ICatalogsUnitItem, ISelect } from '../interfaces';

export class CatalogsStore {
    private constructor() {
        makeObservable(this);
    }

    private static instance: CatalogsStore;
    public static getInstance(): CatalogsStore {
        if (!CatalogsStore.instance) {
            CatalogsStore.instance = new CatalogsStore();
        }
        return CatalogsStore.instance;
    }

    @observable unitList: ICatalogsUnitItem[] = [];
    @action setUnitList(list: ICatalogsUnitItem[]): void {
        this.unitList = list;
    }

    @observable companyTypes: ISelect[] = [];
    @action setCompanyTypes(list: ISelect[]): void {
        this.companyTypes = list;
    }

    @observable paymentTypes: ISelect[] = [];
    @action setPaymentTypes(list: ISelect[]): void {
        this.paymentTypes = list;
    }
    @observable productTypes: ISelect[] = [];
    @action setProductTypes(list: ISelect[]): void {
        this.productTypes = list;
    }

    @action cleanStore(): void {
        this.companyTypes = [];
        this.paymentTypes = [];
        this.productTypes = [];
        this.unitList = [];
    }
}
