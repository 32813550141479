import React, { useEffect, useState } from 'react';
import InputNumber, { InputNumberProps } from 'antd/lib/input-number';
import { appConfig } from '../../settings';
import { useInputDebounce } from '../../hooks';
import { string2number } from '../../instruments';
import { Catcher } from '../Catcher';

export interface IInputNumberDebounce extends InputNumberProps {
    delay?: number;
}

type IInputNumberValue = string | number | null;

export const InputNumberDebounce: React.FC<IInputNumberDebounce> = ({
    delay = appConfig.delayInputDebounce,
    onChange,
    value,
    ...props
}: IInputNumberDebounce) => {
    const [localValue, setLocalValue] = useState<IInputNumberValue>(null);

    const debouncedValue: IInputNumberValue = useInputDebounce<IInputNumberValue>(localValue, delay);

    useEffect(() => {
        setLocalValue(string2number(value));
    }, [value]);

    useEffect(() => {
        if (onChange && (!!value || !!debouncedValue) && value !== debouncedValue) {
            onChange(localValue);
        }
    }, [debouncedValue]);

    const localOnChange = (value: IInputNumberValue): void => {
        setLocalValue(string2number(value));
    };

    return (
        <Catcher>
            <InputNumber {...props} onBlur={(): void => undefined} onChange={localOnChange} value={localValue} />
        </Catcher>
    );
};
