import React from 'react';
import { observer } from 'mobx-react';
import { DatePicker, Form, Select, Table } from 'antd';
import { Rule } from 'antd/es/form';
import { FormListFieldData } from 'antd/es/form/FormList';
import dayjs from 'dayjs';
import {
    IFormValidatorResponse,
    IIncludeAssignedCompanyItem,
    IRuleAny,
    ISelect,
    ISelectList,
    IShowCardColumn,
} from '../../../interfaces';
import { useCardSetSpinnersInfo, useCompaniesSelect, useIncludeAssignedCompaniesActive } from '../../../hooks';
import { addIncludedList, apiMessagesGetListError, removeIncludedList } from '../../../middleware';
import { disablePreviousDates, globalHexId } from '../../../instruments';
import { tF } from '../../../translate';
import { CardLayout, Catcher, FormSelectLight, RemoveButton } from '../../../Components';
import ViewStyles from '../../styles/m_viewStyles.less';

interface ICompaniesAssignmentsEdit extends IShowCardColumn {
    companiesSelectHook?: () => ISelectList;
}

export const CompaniesAssignmentsEdit: React.FC<ICompaniesAssignmentsEdit> = observer(
    ({ columnOption, companiesSelectHook = useCompaniesSelect }: ICompaniesAssignmentsEdit) => {
        const { Option } = Select;
        const componentSpinners = useCardSetSpinnersInfo();
        const selectCompany = companiesSelectHook();
        const addCompany = (): void => {
            const newACompany: IIncludeAssignedCompanyItem = { id: globalHexId.getId(), startDate: dayjs() };
            addIncludedList('assignedCompanies', newACompany);
        };
        const removeCompany = (id: string): void => {
            removeIncludedList('assignedCompanies', id);
        };

        const assignedCompanies = useIncludeAssignedCompaniesActive();
        const recordIds = assignedCompanies.map((i) => i.id);

        const apiMessagesListRules: Array<Rule> = [
            {
                validator(rule: IRuleAny): IFormValidatorResponse {
                    return apiMessagesGetListError({
                        listName: 'assignedCompanies',
                        idsList: recordIds,
                        fieldName: rule.field,
                    });
                },
            },
        ];
        const selectCompanyListJSX = selectCompany.map(
            (s: ISelect): JSX.Element => (
                <Option key={s.id} value={s.id} disabled={s.disabled}>
                    <div>{s.name}</div>
                </Option>
            ),
        );
        const tableColumns = [
            {
                title: tF('Company'),
                dataIndex: 'company',
                ellipsis: true,
            },
            {
                title: tF('Start Date'),
                dataIndex: 'dateStart',
                width: 180,
            },
            {
                title: tF('End Date'),
                dataIndex: 'dateEnd',
                width: 180,
            },
            {
                title: '',
                dataIndex: 'toolBar',
                className: ViewStyles.columnToolbarDelete,
            },
        ];

        return (
            <Catcher>
                <CardLayout
                    columnOption={columnOption}
                    count={assignedCompanies.length}
                    idSpinners={componentSpinners}
                    isTable
                    onClickAddButton={addCompany}
                    title={'Companies Assignments'}
                >
                    <Form.List name="assignedCompanies">
                        {(fields: FormListFieldData[], {}): JSX.Element => {
                            const dataSource = fields.map((field: FormListFieldData) => {
                                const currentCompany = assignedCompanies?.[field.key];
                                const assignedCompaniesId = currentCompany?.id || '';
                                const isNew = currentCompany?.id.includes('new:');
                                const isActive = currentCompany?.isActive || isNew;
                                const isDeletable = currentCompany?.isDeletable || isNew;

                                return {
                                    key: field.key,
                                    company: (
                                        <FormSelectLight
                                            fieldName={[field.name, 'companyId']}
                                            isShortForm
                                            rules={apiMessagesListRules}
                                            selectJSX={selectCompanyListJSX}
                                            isGetPopupContainerEnabled={false}
                                            disabled={!isActive}
                                        />
                                    ),
                                    dateStart: (
                                        <Form.Item
                                            {...field}
                                            name={[field.name, 'startDate']}
                                            key={'startDate'}
                                            rules={apiMessagesListRules}
                                        >
                                            <DatePicker disabledDate={disablePreviousDates} disabled={!isActive} />
                                        </Form.Item>
                                    ),
                                    dateEnd: (
                                        <Form.Item
                                            {...field}
                                            name={[field.name, 'endDate']}
                                            key={'endDate'}
                                            rules={apiMessagesListRules}
                                        >
                                            <DatePicker disabledDate={disablePreviousDates} disabled={!isActive} />
                                        </Form.Item>
                                    ),
                                    toolBar: (
                                        <RemoveButton
                                            onClick={(): void => removeCompany(assignedCompaniesId)}
                                            disabled={!isDeletable}
                                            isGhost
                                        />
                                    ),
                                };
                            });

                            return (
                                <Table
                                    dataSource={dataSource}
                                    columns={tableColumns}
                                    pagination={false}
                                    className={'table-theme sub-table'}
                                    size={'small'}
                                />
                            );
                        }}
                    </Form.List>
                </CardLayout>
            </Catcher>
        );
    },
);
