import React from 'react';
import { observer } from 'mobx-react';
import { Row } from 'antd';
import { Catcher, rowGutter } from '../../';
import { ShowSubscriberInfo } from './ShowSubscriberInfo';
import { IShowForm } from '../../../interfaces';
import Styles from '../../Dashboard/m_dashboarStyles.less';

export const ShowSubscriberView: React.FC<IShowForm> = observer(({ setEditMode }: IShowForm) => {
    return (
        <Catcher>
            <Row gutter={rowGutter.card} className={Styles.cardsRow}>
                <ShowSubscriberInfo setEditMode={setEditMode} />
            </Row>
        </Catcher>
    );
});
