import React, { ReactNode } from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { LinkComponent } from '../../LinkComponent';
import { IEsNotificationsLogsItem } from '../../../interfaces';
import { useEsNotificationsLogsTable, useUiSearch } from '../../../hooks';
import { dateText2Utc, lightText } from '../../../instruments';
import { tF } from '../../../translate';
import { Catcher } from '../../Catcher';
import Styles from '../../styles/m_viewStyles.less';

interface IDataType {
    key: number;
    eid: ReactNode;
    iccId: ReactNode;
    message: ReactNode;
    finalProfileStatusIndicator: ReactNode;
    operation: ReactNode;
    reasonCode: ReactNode;
    smdpAddress: ReactNode;
    status: ReactNode;
    subjectCode: ReactNode;
    subjectIdentifier: ReactNode;
    createdAt: ReactNode;
    updatedAt: ReactNode;
    matchingId: ReactNode;
    user: ReactNode;
}

export const ESPlusNotificationLogsTable: React.FC = observer(() => {
    const search = useUiSearch();
    const list = useEsNotificationsLogsTable();
    const tableColumns: ColumnsType<IDataType> = [
        {
            title: tF('EID'),
            dataIndex: 'eid',
        },
        {
            title: tF('ICCID'),
            dataIndex: 'iccId',
        },
        {
            title: 'Message',
            dataIndex: 'message',
        },
        {
            title: 'Final Profile Status Indicator',
            dataIndex: 'finalProfileStatusIndicator',
        },
        {
            title: 'Operation',
            dataIndex: 'operation',
        },
        {
            title: 'Reason Code',
            dataIndex: 'reasonCode',
        },
        {
            title: 'Smdp Address',
            dataIndex: 'smdpAddress',
        },
        {
            title: 'Status',
            dataIndex: 'status',
        },
        {
            title: 'Subject Code',
            dataIndex: 'subjectCode',
        },
        {
            title: 'Subject Identifier',
            dataIndex: 'subjectIdentifier',
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
        },
        {
            title: 'Updated At',
            dataIndex: 'updatedAt',
        },
        {
            title: 'Matching Id',
            dataIndex: 'matchingId',
        },
        {
            title: 'User',
            dataIndex: 'user',
        },
    ];
    const tableData: IDataType[] = list.map((i: IEsNotificationsLogsItem) => {
        return {
            key: i.id,
            eid: lightText(i.eid, search),
            iccId: lightText(i.iccId, search),
            message: lightText(i.message, search),
            finalProfileStatusIndicator: lightText(i.finalProfileStatusIndicator, search),
            operation: lightText(i.operation, search),
            reasonCode: lightText(i.reasonCode, search),
            smdpAddress: lightText(i.smdpAddress, search),
            status: lightText(i.status, search),
            subjectCode: lightText(i.subjectCode, search),
            subjectIdentifier: lightText(i.subjectIdentifier, search),
            createdAt: dateText2Utc(i.createdAt),
            updatedAt: dateText2Utc(i.updatedAt),
            matchingId: lightText(i.matchingId, search),
            user: <LinkComponent currentListComponent={'UsersList'} id={i.user.id} name={i.user.name} isLight />,
        };
    });

    return (
        <Catcher>
            <div className={Styles.tablePlace}>
                <Table
                    className={'table-theme'}
                    columns={tableColumns}
                    dataSource={tableData}
                    pagination={false}
                    size={'middle'}
                />
            </div>
        </Catcher>
    );
});
