import React, { ReactNode } from 'react';
import { Col, Row } from 'antd';
import {
    Catcher,
    FilterToolbar,
    FilterUrlManager,
    ListTitle,
    NewListItem,
    SkyPagination,
    UploadButton,
    cardLayoutOptions,
} from '../';
import { IGetApiType } from '../../REST';
import { IFilterParams, IParamsList } from '../../interfaces';
import Styles from '../styles/m_viewStyles.less';

type IListWrapper = {
    BottomBar?: React.FC;
    children: ReactNode;
    cleanStore(): void;
    customUsePaginationCount?: () => number;
    customUsePaginationIdSpinners?: () => IGetApiType[];
    defaultParams?: IParamsList;
    filterParams: IFilterParams;
    getRelationSelect?(): void;
    idSpinners?: IGetApiType[];
    isLockNewPage?: boolean;
    isNotFilterToolbar?: boolean;
    isNotPaginationToolbar?: boolean;
    isReloadButton?: boolean;
    LeftButtons?: React.FC;
    months?: number;
    RightButtons?: React.FC;
    uploadApiType?: IGetApiType | '';
};

export const ListWrapper: React.FC<IListWrapper> = ({
    BottomBar,
    children,
    cleanStore,
    customUsePaginationCount,
    customUsePaginationIdSpinners,
    defaultParams,
    filterParams,
    getRelationSelect,
    idSpinners = [],
    isLockNewPage,
    isNotFilterToolbar,
    isNotPaginationToolbar,
    isReloadButton,
    LeftButtons,
    months,
    RightButtons,
    uploadApiType,
}: IListWrapper) => {
    return (
        <Catcher>
            <FilterUrlManager
                cleanStore={cleanStore}
                filterParams={filterParams}
                getRelationSelect={getRelationSelect}
                defaultParams={defaultParams}
            />
            <div className={Styles.main} data-test="ListWrapper">
                <div className={Styles.content}>
                    <Row className={Styles.header} justify={'space-between'}>
                        <ListTitle LeftButtons={LeftButtons} idSpinners={idSpinners} />
                        {RightButtons || !isLockNewPage || uploadApiType ? (
                            <Col
                                {...cardLayoutOptions.listTitleRightToolbar}
                                className={Styles.rightToolbar}
                                data-test="ListToolbar"
                            >
                                {uploadApiType ? <UploadButton apiType={uploadApiType} /> : null}
                                {!isLockNewPage ? <NewListItem /> : null}
                                {RightButtons ? <RightButtons /> : null}
                            </Col>
                        ) : null}
                    </Row>
                    {isNotFilterToolbar ? null : (
                        <FilterToolbar
                            BottomBar={BottomBar}
                            filterParams={filterParams}
                            idSpinners={idSpinners}
                            isReloadButton={isReloadButton}
                            months={months}
                        />
                    )}
                    {children}
                    {isNotPaginationToolbar ? null : (
                        <SkyPagination
                            idSpinners={idSpinners}
                            customUsePaginationCount={customUsePaginationCount}
                            customUsePaginationIdSpinners={customUsePaginationIdSpinners}
                        />
                    )}
                </div>
            </div>
        </Catcher>
    );
};
