import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { ICallDataRecordsListItem, IFilterParams } from '../../interfaces';

export function useCallDataRecordsList(): ICallDataRecordsListItem[] {
    return useContext(MobXProviderContext).RootStore.callDataRecordsStore.callDataRecordsList;
}

export function useCallDataRecordsFilterParams(): IFilterParams {
    return useContext(MobXProviderContext).RootStore.callDataRecordsStore.filterParams;
}
