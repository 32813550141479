import { action, makeObservable, observable, reaction } from 'mobx';
import { UiStore } from './ui';
import { appConfig } from '../settings';
import { ICallDataRecordsListItem, IFilterParams, SelectType } from '../interfaces';
import { getCallDataRecordsList, getCardSetsSelect } from '../middleware';
import { apiParamsCleaner } from '../instruments';

export class CallDataRecordsStore {
    private constructor() {
        makeObservable(this);
        reaction(
            () => this.forcedUpdateTime,
            () => {
                const UiStoreInstance = UiStore.getInstance();
                const params = UiStoreInstance.params;
                const { companyId } = params;
                if (companyId !== this.currentCompanyId) {
                    this.setCurrentCompanyId(companyId);
                    getCardSetsSelect({ companyId }, true);
                }
                const cleanParams = apiParamsCleaner(params, this.filterParams);
                getCallDataRecordsList(cleanParams);
            },
            {
                delay: appConfig.APIDelay,
            },
        );
    }

    private static instance: CallDataRecordsStore;
    public static getInstance(): CallDataRecordsStore {
        if (!CallDataRecordsStore.instance) {
            CallDataRecordsStore.instance = new CallDataRecordsStore();
        }
        return CallDataRecordsStore.instance;
    }

    @observable callDataRecordsList: ICallDataRecordsListItem[] = [];
    @action setCallDataRecordsList(data: ICallDataRecordsListItem[]): void {
        this.callDataRecordsList = data;
    }

    @observable forcedUpdateTime = 0;
    @action setForcedUpdateTime(): void {
        this.forcedUpdateTime = Date.now();
    }

    @observable filterParams: IFilterParams = {
        cardSetId: true,
        companyId: true,
        operatorId: true,
        dateRange: true,
        defaultDateRangeType: 'day',
        isDateRangeCleanedDefaultURL: true,
        isDateRangeMaxDayToDay: true,
        isDateRangeMinDayOn: true,
        pageNumber: true,
        pageSize: true,
        search: true,
        source: true,
        unitTypeIds: SelectType.MULTIPLE,
    };

    @observable currentCompanyId: string | string[] | undefined;
    setCurrentCompanyId(companyId?: string | string[] | undefined): void {
        this.currentCompanyId = companyId;
    }

    @action cleanStore(): void {
        this.callDataRecordsList = [];
        this.currentCompanyId = '';
    }
}
