import React from 'react';
import { observer } from 'mobx-react';
import { CardInfoToolBar, CardMainLayout, Catcher, FieldInfoAdvanced } from '../../';
import { IFieldLayout, IGetApiType, IShowForm } from '../../../interfaces';
import { useCountryInfo } from '../../../hooks';

export const ShowCountryInfo: React.FC<IShowForm> = observer(({ setEditMode }: IShowForm) => {
    const loadingTypes: IGetApiType[] = ['GET_COUNTRY'];
    const info = useCountryInfo();
    const layout: IFieldLayout = {
        labelCol: {
            flex: '1',
        },
        valCol: {
            flex: '1 1',
        },
    };

    return (
        <Catcher>
            <CardMainLayout idSpinners={loadingTypes} name={info.name}>
                <FieldInfoAdvanced layout={layout} title={'Name'}>
                    {info.name}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced layout={layout} title={'ISO3'}>
                    {info.iso3}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced layout={layout} title={'MCC'}>
                    {info.mcc}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced layout={layout} title={'Zone'}>
                    {info.zone}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced layout={layout} title={'Dial Code'}>
                    +{info.dialCode}
                </FieldInfoAdvanced>
                <CardInfoToolBar setEditMode={setEditMode} isBackToList />
            </CardMainLayout>
        </Catcher>
    );
});
