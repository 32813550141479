import { Rule, RuleObject } from 'antd/es/form';
import { ApiMessagesStore } from '../stores/apiMessages';
import { addNotification, removeUiSpinnerByApiType } from './ui';
import { IGetApiType, restApi } from '../REST';
import {
    IAntDFormChangeFieldName,
    IApiErrorMessage,
    IApiErrorMessages,
    IApiListMessage,
    IApiMessagesDeleteMessageByListField,
    IApiMessagesFoundListFieldId,
    IApiMessagesMessage,
    IFormFieldValue,
    IFormValidatorResponse,
    IRuleAny,
} from '../interfaces';
import { getUi2ApiErrorListName, getUiField, isNumbersFormattedWithComma, upperCaseFirstLetter } from '../instruments';
import { ITrInterfaces, tI, tNDRN, tNM, tV } from '../translate';
import { makeRuleFieldBlank } from '../Components';

export function cleanApiMessages(): void {
    const StoreInstance = ApiMessagesStore.getInstance();
    StoreInstance.cleanStore();
}

export function apiMessagesIsMessages(): boolean {
    const StoreInstance = ApiMessagesStore.getInstance();
    return StoreInstance.isMessages;
}

function addMessages(messages: IApiMessagesMessage[]): void {
    const StoreInstance = ApiMessagesStore.getInstance();
    StoreInstance.addMessages(messages);
}

function makeMessage(apiErrorMessage: IApiErrorMessage): IApiMessagesMessage {
    const { source, detail } = apiErrorMessage;
    const fields = source.pointer.split('/');
    const isRelationships = fields[2] === 'relationships';
    const foundListName = getUiField(fields[fields.length - 4]);
    const foundListId = getUiField(fields[fields.length - 3]);
    const foundField = getUiField(fields[fields.length - 1]);
    const newField: IApiMessagesMessage = {
        field: foundField,
        message: detail,
    };
    if (isRelationships) {
        newField.listName = foundListName;
        newField.listId = foundListId;
        newField.listField = foundField;
        if (foundListName === 'salesRateList' && (foundField === 'base' || foundField === 'salesRateList')) {
            newField.listField = 'rateId';
        } else if (foundListName === 'assignedCompanies' && foundField === 'base') {
            newField.listField = 'companyId';
        } else if (foundListName === 'salesRateListEntry' && foundField === 'base') {
            newField.listField = 'networkId';
        }

        newField.field = ``;
    }
    return newField;
}

export const showBaseError = (list: IApiMessagesMessage[]): void => {
    list.forEach((i: IApiMessagesMessage) => {
        addNotification({
            type: 'warning',
            message: tNM(`Error!`),
            description: tNDRN(i.message),
            duration: 15,
            isTranslated: true,
        });
    });
};

export function apiMessagesSaveMessages(apiMessages?: IApiErrorMessages): void {
    const messages: IApiMessagesMessage[] = apiMessages?.map((m) => makeMessage(m)) || [];
    const errorBase = messages.filter((i: IApiMessagesMessage) => i.field == 'base');
    showBaseError(errorBase);
    const errorsFields = messages.filter((i: IApiMessagesMessage) => i.field !== 'base');
    cleanApiMessages();
    addMessages(errorsFields);
}

export function apiMessagesDeleteMessageByFieldName(fieldName: IAntDFormChangeFieldName): void {
    const StoreInstance = ApiMessagesStore.getInstance();
    if (StoreInstance.isMessages) {
        StoreInstance.deleteMessageByFieldName(fieldName.join('.'));
    }
}

export function apiMessagesDeleteAllMessageBylListId(id: string): void {
    const StoreInstance = ApiMessagesStore.getInstance();
    if (StoreInstance.isMessages) {
        StoreInstance.deleteAllMessageByListId(id);
    }
}

export function apiMessagesDeleteMessageByListFieldId({
    field,
    listIds = {},
}: IApiMessagesDeleteMessageByListField): void {
    const StoreInstance = ApiMessagesStore.getInstance();
    if (StoreInstance.isMessages) {
        const listName = getUi2ApiErrorListName(field[0]);
        const listIndex = field[1];
        const listId = listIds[listName]?.[listIndex];
        const listField = field[2];

        if (listName && listId && listField) {
            const updateField: IApiMessagesFoundListFieldId = {
                listName,
                listId,
                listField,
            };
            StoreInstance.deleteMessageByListId(updateField);
        }
    }
}

export function apiMessagesDeleteListItemMessage({ listName, listId, listField }: IApiMessagesFoundListFieldId): void {
    const StoreInstance = ApiMessagesStore.getInstance();
    if (StoreInstance.isMessages) {
        StoreInstance.deleteMessageByListId({
            listName,
            listId,
            listField,
        });
    }
}

export const apiMessagesGetField = (fieldName: string): Array<string> => {
    const StoreInstance = ApiMessagesStore.getInstance();
    return StoreInstance.formMessages
        .filter((e: IApiMessagesMessage) => e.field === fieldName)
        .map((e: IApiMessagesMessage) => e.message);
};

export function apiMessagesGetFormError(fieldName: string): Promise<string | void> {
    const StoreInstance = ApiMessagesStore.getInstance();
    if (StoreInstance.isMessages) {
        const formMessages = StoreInstance.formMessages;
        const findError = formMessages.find((e: IApiMessagesMessage) => e.field === fieldName);
        if (findError) {
            return Promise.reject(findError.message);
        } else {
            return Promise.resolve();
        }
    } else {
        return Promise.resolve();
    }
}

export const apiMessagesGetFormRules: Array<Rule> = [
    {
        validator(rule: IRuleAny): IFormValidatorResponse {
            return apiMessagesGetFormError(rule?.field);
        },
    },
];

export const apiMessagesGetFormRulesAndLen = (len: number): Array<Rule> => {
    return [
        {
            len: len,
        },
        ...apiMessagesGetFormRules,
    ];
};

export const apiMessagesGetFormRulesFieldBlank = (): Array<Rule> => [
    ...makeRuleFieldBlank(),
    ...apiMessagesGetFormRules,
];

export const ruleMinPasswordLength = (): Array<Rule> => [
    { min: 6, message: tV('Password must be minimum 6 characters long.') },
];

export const rulesNumber: Rule = () => ({
    validator(_: RuleObject, value: IFormFieldValue): IFormValidatorResponse {
        if (!isNaN(+value) && value == +value) {
            return Promise.resolve();
        }
        return Promise.reject(tV('Is not a valid number!'));
    },
});

export const rulesIsNumber: Rule = () => ({
    validator(_: RuleObject, value: IFormFieldValue): IFormValidatorResponse {
        if ((!isNaN(parseFloat(value)) && isFinite(value)) || !value) {
            return Promise.resolve();
        }
        return Promise.reject(tV('Please enter a number!'));
    },
});

export const rulesNumberMinMax = (min: number, max: number): Rule => ({
    validator(_: RuleObject, value: IFormFieldValue): IFormValidatorResponse {
        if (!value) {
            return Promise.resolve();
        }

        if (typeof min !== 'undefined' && +value < min) {
            return Promise.reject(`${tV('The value must be more than')} ${min} !`);
        } else if (typeof max !== 'undefined' && +value > max) {
            return Promise.reject(`${tV('The value must be less than')} ${max} !`);
        } else {
            return Promise.resolve();
        }
    },
});

export const rulesDividend = (dividend?: number, unit = ''): Rule => ({
    validator(_: RuleObject, value: IFormFieldValue): IFormValidatorResponse {
        if (!!dividend && typeof value !== 'undefined' && dividend % value) {
            return Promise.reject(
                `${dividend} ${unit ? tI(unit as ITrInterfaces) : ''} ${tV('is not divisible by')} ${value} !`,
            );
        } else {
            return Promise.resolve();
        }
    },
});

export const rulesInList = (list?: Array<string>, unit = ''): Rule => ({
    validator(_: RuleObject, value: IFormFieldValue): IFormValidatorResponse {
        if (!!list && list.length && !list.includes(value)) {
            return Promise.reject(
                `${unit ? upperCaseFirstLetter(tI(unit as ITrInterfaces)) : ''} ${tV(
                    'value is available only from the list',
                )}: [${list.map((i) => i)}]`,
            );
        } else {
            return Promise.resolve();
        }
    },
});

export const rulesMoreThanZero: Rule = () => ({
    validator(_: RuleObject, value: IFormFieldValue): IFormValidatorResponse {
        if (value > 0) {
            return Promise.resolve();
        }
        return Promise.reject(tV('Should be more than zero'));
    },
});

export const rulesPositiveNumber: Rule = () => ({
    validator(_: RuleObject, value: IFormFieldValue): IFormValidatorResponse {
        if (+value > 0 || isNaN(+value)) {
            return Promise.resolve();
        }
        return Promise.reject(tV('The value must be a positive number!'));
    },
});

export const rulesNot0Number: Rule = () => ({
    validator(_: RuleObject, value: IFormFieldValue): IFormValidatorResponse {
        if (value != 0) {
            return Promise.resolve();
        }
        return Promise.reject(tV('The value can not be zero!'));
    },
});

const urlCheck = (str: string): string => {
    return str
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/_/g, '-')
        .replace(/[^\w-^.]+/g, '');
};

export const rulesUrl: Rule = () => ({
    validator(_: RuleObject, value: IFormFieldValue): IFormValidatorResponse {
        if (!value || value === urlCheck(value)) {
            return Promise.resolve();
        }

        return Promise.reject(tV('The input is not valid URL!'));
    },
});

export const rulesNumbersFormattedWithComma: Rule = () => ({
    validator(_: RuleObject, value: IFormFieldValue): IFormValidatorResponse {
        if (!value || isNumbersFormattedWithComma(value)) {
            return Promise.resolve();
        }

        return Promise.reject('Numbers should be in a proper format - "1,2,3,4,5...."');
    },
});

export const validatePasswordConfirmation: Array<Rule> = [
    ({ getFieldValue }): RuleObject => ({
        validator(_: RuleObject, value): IFormValidatorResponse {
            if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
            }

            return Promise.reject(tV('The two passwords that you entered do not match!'));
        },
    }),
];

export const apiMessagesGetFormRulesNumber: Array<Rule> = [rulesNumber, ...apiMessagesGetFormRules];
export const apiMessagesGetFormRulesStandardNumber = (): Array<Rule> => [rulesIsNumber, ...apiMessagesGetFormRules];
export const apiMessagesGetFormRulesPositiveNumber: Array<Rule> = [
    rulesNumber,
    rulesPositiveNumber,
    ...apiMessagesGetFormRules,
];
export const apiMessagesGetFormRulesNot0Number: Array<Rule> = [
    rulesNumber,
    rulesNot0Number,
    ...apiMessagesGetFormRules,
];

export function apiMessagesGetCountListError(listName: string): number {
    const StoreInstance = ApiMessagesStore.getInstance();

    return StoreInstance.listMessages.filter((m: IApiMessagesMessage) => m.listName === listName).length;
}

export function apiMessagesGetListMessage({ listName, idsList, fieldName }: IApiListMessage): string | void {
    const StoreInstance = ApiMessagesStore.getInstance();
    if (StoreInstance.isMessages) {
        const listMessages = StoreInstance.listMessages;
        const fieldNameArr = fieldName.split('.');
        const shortFieldName = fieldNameArr[fieldNameArr.length - 1];
        const itemIndex = +fieldNameArr[1];
        const itemId = idsList[itemIndex];
        const filterMessages: IApiMessagesMessage[] = listMessages
            .filter((m: IApiMessagesMessage) => m.listName === listName && m.listId === itemId)
            .map((m: IApiMessagesMessage) => {
                return { ...m, field: `${m.listName}.${idsList.indexOf(m.listId || 'xxx')}.${m.listField}` };
            });
        const findError = filterMessages.find((e: IApiMessagesMessage) => e.listField === shortFieldName);
        if (findError) {
            return findError.message;
        } else {
            return;
        }
    } else {
        return;
    }
}

export function apiMessagesGetListError({ listName, idsList, fieldName }: IApiListMessage): Promise<string | void> {
    const message = apiMessagesGetListMessage({ listName, idsList, fieldName });
    if (message) {
        return Promise.reject(message);
    } else {
        return Promise.resolve();
    }
}

export function abortAllFetch(): void {
    restApi.abortAll();
}

export function abortSelectedFetch(types: IGetApiType[]): void {
    types.forEach((type: IGetApiType) => {
        removeUiSpinnerByApiType(type);
        process.env.NODE_ENV === 'development' && console.log(Date.now(), `--(fetchAbort)- START ->`, type);
        restApi.fetchAbort(type).then(
            () => {
                process.env.NODE_ENV === 'development' && console.log(Date.now(), `--(fetchAbort)- END ->`, type);
            },
            (e) => {
                process.env.NODE_ENV === 'development' && console.log(Date.now(), `--(fetchAbort)- ERROR ->`, type, e);
            },
        );
    });
}

const getCheckDigit = (value: string): string => {
    if (/[^0-9-\s]+/.test(value)) return '?';

    let nCheck = 0;
    let bEven = true;
    value = value.replace(/\D/g, '');

    for (let n = value.length - 1; n >= 0; n--) {
        const cDigit = value.charAt(n);
        let nDigit = parseInt(cDigit, 10);

        if (bEven) {
            if ((nDigit *= 2) > 9) nDigit -= 9;
        }

        nCheck += nDigit;
        bEven = !bEven;
    }
    return ((1000 - nCheck) % 10).toString();
};

const checkingICCIDControlAmount = (val: string): boolean => {
    const check = val.slice(0, val.length - 1);
    const key = val.slice(-1);
    const calcKey = getCheckDigit(check);
    return key === calcKey;
};

export const ruleCheckingICCIDControlAmount: Rule = () => ({
    message: 'Not correct ICCID (key)',
    validator(_: RuleObject, value: IFormFieldValue): IFormValidatorResponse {
        if (checkingICCIDControlAmount(value)) {
            return Promise.resolve();
        }
        return Promise.reject();
    },
});

export const ruleCheckingTelecom: Rule = () => ({
    message: 'Not correct ICCID (Telecom)',
    validator(_: RuleObject, value: IFormFieldValue): IFormValidatorResponse {
        const regex = new RegExp(/^(89)(\d{17,18})$/);
        if (regex.test(value)) {
            return Promise.resolve();
        }
        return Promise.reject();
    },
});

export const iccidRules: Array<Rule> = [
    rulesIsNumber,
    { min: 19 },
    ruleCheckingICCIDControlAmount,
    ruleCheckingTelecom,
];
