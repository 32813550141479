import React from 'react';
import { observer } from 'mobx-react';
import { CardLayout, Catcher, FieldInfoAdvanced, cardLayoutOptions } from '../../';
import { IFieldLayout, IShowForm } from '../../../interfaces';
import { useCardStatistics, useProfile } from '../../../hooks';
import { tF } from '../../../translate';

export const CardInfoUsage: React.FC<IShowForm> = observer(() => {
    const layout: IFieldLayout = {
        labelCol: {
            flex: '0 1 150px',
        },
        valCol: {
            flex: '1 1 180px',
        },
    };
    const cardStatistics = useCardStatistics();
    const { currency } = useProfile();

    return (
        <Catcher>
            <CardLayout
                columnOption={cardLayoutOptions.cardInfo}
                idSpinners={['GET_CARD', 'GET_CARD_STATISTICS']}
                title={'Card Data Usage Current Month'}
            >
                <FieldInfoAdvanced title={'Data'} layout={layout}>
                    {cardStatistics.byte}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced title={'Voice'} layout={layout}>
                    {cardStatistics.sec}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced title={'SMS'} layout={layout}>
                    {cardStatistics.sms}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced title={'Current Costs'} layout={layout}>
                    {cardStatistics.cost} {currency}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced title={'Usage Limit'} layout={layout}>
                    {cardStatistics.usageLimit || tF('Unlimited')}
                </FieldInfoAdvanced>
            </CardLayout>
        </Catcher>
    );
});
