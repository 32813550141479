import React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import { TablePaginationConfig } from 'antd/es/table';
import { TeamOutlined } from '@ant-design/icons';
import { appConfig } from '../../../settings';
import { IColumnOptionComponent, IUser } from '../../../interfaces';
import { useCompanyUsersList } from '../../../hooks';
import { formatDataTestAttribute } from '../../../instruments/testing';
import { tF } from '../../../translate';
import { CardLayout, Catcher, LinkComponent, SkyPaginationTotal } from '../../../Components';

export const ShowCompanyUsersTable: React.FC<IColumnOptionComponent> = observer(
    ({ columnOption }: IColumnOptionComponent) => {
        const pageSize = appConfig.pageDefaultParams.includeViewPageSize;
        const list = useCompanyUsersList();
        const userComponent = 'UsersList';

        const tableColumns = [
            {
                title: (
                    <span data-test={formatDataTestAttribute('ShowCompanyUsersTable', 'FirstNameLink', `Header`)}>
                        {tF('First name')}
                    </span>
                ),
                dataIndex: 'firstNameLink',
            },
            {
                title: (
                    <span data-test={formatDataTestAttribute('ShowCompanyUsersTable', 'LastNameLink', `Header`)}>
                        {tF('Last name')}
                    </span>
                ),
                dataIndex: 'lastNameLink',
            },
            {
                title: (
                    <span data-test={formatDataTestAttribute('ShowCompanyUsersTable', 'UserType', `Header`)}>
                        {tF('User Type')}
                    </span>
                ),
                dataIndex: 'userType',
            },
        ];

        const tableData = list.map((s: IUser, index) => {
            return {
                key: s.id,
                firstNameLink: (
                    <span data-test={formatDataTestAttribute('ShowCompanyUsersTable', 'FirstNameLink', `${index}`)}>
                        <LinkComponent currentListComponent={userComponent} id={s.id} name={s.firstName} />
                    </span>
                ),
                lastNameLink: (
                    <span data-test={formatDataTestAttribute('ShowCompanyUsersTable', 'LastNameLink', `${index}`)}>
                        <LinkComponent currentListComponent={userComponent} id={s.id} name={s.lastName} />
                    </span>
                ),
                userType: (
                    <span data-test={formatDataTestAttribute('ShowCompanyUsersTable', 'UserType', `${index}`)}>
                        {s.userType}
                    </span>
                ),
            };
        });

        const pagination: false | TablePaginationConfig =
            pageSize > list.length
                ? false
                : {
                      total: list.length,
                      pageSize: pageSize,
                      className: 'customPagination',
                      showSizeChanger: false,
                      size: 'default',
                      showTotal: SkyPaginationTotal,
                  };

        return (
            <Catcher>
                <CardLayout
                    columnOption={columnOption}
                    count={list.length}
                    icon={<TeamOutlined />}
                    idSpinners={['GET_COMPANY']}
                    isTable
                    title={'Users'}
                    dataTest={'ShowCompanyUsersTable'}
                >
                    <Table
                        className={'table-theme'}
                        columns={tableColumns}
                        data-test={'ShowCompanyUsersTable'}
                        dataSource={tableData}
                        pagination={pagination}
                        size={'small'}
                    />
                </CardLayout>
            </Catcher>
        );
    },
);
