import { OperatorInteractionsStore } from '../stores/operatorInteractions';
import { cleanApiMessages } from './apiMessages';
import { setUiCommandId, setUiTotal } from './ui';
import { appConfig } from '../settings';
import { apiManager } from '../REST';
import {
    IItemAPI,
    IOperatorCommandsSelectAPI,
    IOperatorGatewaySelectAPI,
    IOperatorInteractionsFile,
    IOperatorInteractionsFileAPI,
    IOperatorInteractionsFileListAPI,
    IOperatorInteractionsResponse,
    IOperatorInteractionsResponseAPI,
    IOperatorInteractionsResponseFile,
    IOperatorInteractionsResponseItemAPI,
    IOperatorInteractionsResponseListAPI,
    IParamsList,
    ISelectList,
    IUploadFilesStatusType,
} from '../interfaces';
import { dateText2Utc, mapApi2UiField, path2FileName, sortByName, sortReverseByName } from '../instruments';

export const getUploadFilesStatusTitle = (id?: IUploadFilesStatusType): string => {
    return appConfig.uploadFilesStatus.find((s) => s.id === id)?.text || 'Unknown';
};

export function cleanOperatorInteractionsStore(): void {
    const StoreInstance = OperatorInteractionsStore.getInstance();
    StoreInstance.cleanStore();
}

export function setForcedFilesListUpdateTime(): void {
    const StoreInterface = OperatorInteractionsStore.getInstance();
    StoreInterface.setForcedFilesListUpdateTime();
}
export function setForcedResponsesListUpdateTime(): void {
    const StoreInterface = OperatorInteractionsStore.getInstance();
    StoreInterface.setForcedResponsesListUpdateTime();
}

export function setOperatorInteractionsFilesList(list: IOperatorInteractionsFile[]): void {
    const StoreInterface = OperatorInteractionsStore.getInstance();
    StoreInterface.setFilesList(list);
}
export async function getOperatorInteractionsFilesList(params: IParamsList): Promise<void> {
    const { resp } = await apiManager.getApi('GET_OPERATOR_INTERACTIONS_FILES', { searchParamsList: params });
    const { data, meta } = resp as IOperatorInteractionsFileListAPI;
    const list: IOperatorInteractionsFile[] =
        data?.map((i: IOperatorInteractionsFileAPI) => {
            const item: IOperatorInteractionsFile = { id: i.id, ...mapApi2UiField(i.attributes || {}) };
            const fileName = path2FileName(item.file);
            return {
                ...item,
                fileName,
            };
        }) || [];
    setUiTotal(meta?.records_count || 0);
    setOperatorInteractionsFilesList(list);
}

export function setOperatorInteractionsResponsesList(list: IOperatorInteractionsResponse[]): void {
    const StoreInterface = OperatorInteractionsStore.getInstance();
    StoreInterface.setResponsesList(list);
}

const convertOperatorInteractionsResponsesAPI2Ui = (
    i: IOperatorInteractionsResponseItemAPI,
    included?: IItemAPI[],
): IOperatorInteractionsResponse => {
    const fileApi = included?.filter((f) => f.type === 'file').find((f) => f.id === i.relationships?.file?.data?.id);
    const fileInfo: IOperatorInteractionsResponseFile = mapApi2UiField(fileApi?.attributes || {});
    if (fileInfo?.file) {
        fileInfo.fileName = path2FileName(fileInfo.file);
    }
    if (fileInfo?.createdAt) {
        fileInfo.createdAt = dateText2Utc(fileInfo.createdAt);
    }
    const attributes = mapApi2UiField(i.attributes || {});
    const item: IOperatorInteractionsResponse = { id: i.id, ...attributes };
    return {
        ...item,
        file: fileInfo,
        createdAt: dateText2Utc(attributes.createdAt),
    };
};

export function isOperatorInteractionsFilesIsProcessing(): boolean {
    const StoreInterface = OperatorInteractionsStore.getInstance();
    return StoreInterface.isFilesListProcessing;
}

export async function getOperatorInteractionsResponsesList(params: IParamsList): Promise<void> {
    const { resp } = await apiManager.getApi('GET_OPERATOR_INTERACTIONS_RESPONSES', { searchParamsList: params });
    const { data, included, meta } = resp as IOperatorInteractionsResponseListAPI;
    const list: IOperatorInteractionsResponse[] =
        data?.map((i: IOperatorInteractionsResponseItemAPI) =>
            convertOperatorInteractionsResponsesAPI2Ui(i, included),
        ) || [];
    setUiTotal(meta?.records_count || 0);
    setOperatorInteractionsResponsesList(list);
}

export function setOperatorGetewaysSelect(list: ISelectList): void {
    const StoreInterface = OperatorInteractionsStore.getInstance();
    StoreInterface.setGetewaysSelectList(list);
}
export function setOperatorCommandsSelect(list: ISelectList): void {
    const StoreInterface = OperatorInteractionsStore.getInstance();
    StoreInterface.setCommandsSelectList(list);
}
export function setOperatorFilesSelect(list: ISelectList): void {
    const StoreInterface = OperatorInteractionsStore.getInstance();
    StoreInterface.setFilesSelectList(list);
}

async function getOperatorGetewaysSelect(): Promise<void> {
    const { resp } = await apiManager.getApi('GET_OPERATOR_INTERACTIONS_GATEWAYS_SELECT');
    const { gateways } = resp as IOperatorGatewaySelectAPI;
    const list: ISelectList =
        gateways
            ?.map((i: string) => {
                const name = i
                    .split('_')
                    .map((w) => w[0].toUpperCase() + w.substring(1))
                    .join(' ');
                return {
                    id: i,
                    name,
                    disable: false,
                };
            })
            .sort(sortByName) || [];
    setOperatorGetewaysSelect(list);
}
async function getOperatorCommandsSelect(): Promise<void> {
    const { resp } = await apiManager.getApi('GET_OPERATOR_INTERACTIONS_COMMANDS_SELECT');
    const { commands, default_command } = resp as IOperatorCommandsSelectAPI;

    const list: ISelectList =
        commands
            ?.map((i: string) => {
                const name = i
                    .split('_')
                    .map((w) => w[0].toUpperCase() + w.substring(1))
                    .join(' ');
                return {
                    id: i,
                    name,
                    disable: false,
                };
            })
            .sort(sortByName) || [];

    setOperatorCommandsSelect(list);
    // default_command && setUiCommandId(default_command);
    setForcedResponsesListUpdateTime();
}
async function getOperatorFileSelect(): Promise<void> {
    const { resp } = await apiManager.getApi('GET_OPERATOR_INTERACTIONS_FILES_SELECT');
    const { data } = resp as IOperatorInteractionsFileListAPI;
    const list: ISelectList =
        data
            ?.map((f) => ({
                id: f.id,
                name: `${dateText2Utc(f.attributes?.created_at, true)} ${path2FileName(f.attributes?.file)}`,
            }))
            .sort(sortReverseByName) || [];
    setOperatorFilesSelect(list);
}
export async function getOperatorInteractionsFilesListRelationSelect(): Promise<void> {
    getOperatorGetewaysSelect();
}

export async function getOperatorInteractionsResponsesListRelationSelect(): Promise<void> {
    getOperatorGetewaysSelect();
    getOperatorCommandsSelect();
    getOperatorFileSelect();
}

const setOperatorInteractionsResponse = (item: IOperatorInteractionsResponse): void => {
    const StoreInterface = OperatorInteractionsStore.getInstance();
    StoreInterface.setResponseItem(item);
};

export async function getOperatorInteractionsResponse(id: string): Promise<boolean> {
    cleanApiMessages();
    const { resp, isError } = await apiManager.getApi('GET_OPERATOR_INTERACTIONS_RESPONSE', { id: id });
    const { data, included } = resp as IOperatorInteractionsResponseAPI;
    if (!isError && data?.id) {
        setOperatorInteractionsResponse(convertOperatorInteractionsResponsesAPI2Ui(data, included));
    }
    return !isError;
}
