import React from 'react';
import cx from 'classnames';
import { IDivMainProps } from '../../interfaces';
import { lightText } from '../../instruments';
import Styles from './m_styles.less';

interface IInputDisabledSearch extends IDivMainProps {
    searchText?: string;
    children?: string;
}

export const InputDisabledSearch: React.FC<IInputDisabledSearch> = ({
    children,
    className,
    searchText,
    ...props
}: IInputDisabledSearch) => {
    return (
        <div className={cx(className, Styles.main)} {...props}>
            {children ? (searchText ? lightText(children, searchText) : children) : null}
        </div>
    );
};
