import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Catcher } from '../index';
import { IGetApiType } from '../../REST';
import { useProfileIsRight, useSubscriberInfo } from '../../hooks';
import {
    clearExternalAPIStore,
    getExternalAPIOrderDetail,
    isAvailableOperatorId,
    isAvailableSubscriberId,
} from '../../middleware';
import { ExternalAPILogs, FpbxSettings, Numbers, OrderDetailEdit, OrderDetailView } from './Components';

const loadingTypes: IGetApiType[] = ['POST_SUBSCRIBERS_API_REQUEST'];

export const ExternalAPIFlow: React.FC = observer(() => {
    const isRoot = useProfileIsRight('ROOT');
    const { id: subscriberId, operatorId } = useSubscriberInfo();
    const [isEditMode, setEditMode] = useState<boolean | undefined>(false);

    const isRightForm =
        isRoot &&
        !!operatorId &&
        isAvailableOperatorId(operatorId) &&
        !!subscriberId &&
        isAvailableSubscriberId(subscriberId);

    useEffect(() => {
        isRightForm && getExternalAPIOrderDetail(subscriberId);
    }, [subscriberId, isRightForm]);

    useEffect(() => {
        return (): void => {
            clearExternalAPIStore();
        };
    }, []);

    return isRightForm ? (
        <Catcher>
            {isEditMode ? (
                <OrderDetailEdit loadingTypes={loadingTypes} setEditMode={() => setEditMode(false)} />
            ) : (
                <OrderDetailView loadingTypes={loadingTypes} setEditMode={() => setEditMode(true)} />
            )}
            <Numbers />
            <FpbxSettings />
            <ExternalAPILogs />
        </Catcher>
    ) : null;
});
