import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { IActivityLogItem, IFilterParams, ISelectList } from '../../interfaces';

export function useActivityLogTable(): IActivityLogItem[] {
    return useContext(MobXProviderContext).RootStore.activityLogStore.list;
}

export function useActivityLogFilterParams(): IFilterParams {
    return useContext(MobXProviderContext).RootStore.activityLogStore.filterParams;
}

export function useEntitySelect(): ISelectList {
    return useContext(MobXProviderContext).RootStore.activityLogStore.entitySelect;
}
