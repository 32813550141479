import React from 'react';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import { Catcher, FileTypeIcons } from '../';
import { IGetApiType } from '../../REST';
import { IExportType, IFilterParams } from '../../interfaces';
import { useUiIsLoading } from '../../hooks';
import { makeReport } from '../../middleware';

export interface IReportFilesButtons {
    filterParams?: IFilterParams;
    type: IGetApiType;
    isCsv?: boolean;
    isPdf?: boolean;
    entityType?: string;
}

export const ReportFilesButtons: React.FC<IReportFilesButtons> = observer(
    ({ filterParams = {}, type, isPdf, isCsv, entityType }: IReportFilesButtons) => {
        const isLoading = useUiIsLoading();
        const onClick = (exportType: IExportType): void => {
            makeReport(type, filterParams, exportType, entityType);
        };

        return (
            <Catcher>
                {isPdf ? (
                    <Button
                        onClick={(): void => onClick('pdf')}
                        icon={<FileTypeIcons fileType={'pdf'} />}
                        loading={isLoading}
                        disabled={isLoading}
                    >
                        PDF
                    </Button>
                ) : null}
                {isCsv ? (
                    <Button
                        onClick={(): void => onClick('csv')}
                        icon={<FileTypeIcons fileType={'csv'} />}
                        loading={isLoading}
                        disabled={isLoading}
                    >
                        CSV
                    </Button>
                ) : null}
            </Catcher>
        );
    },
);
