import React, { MouseEvent } from 'react';
import { observer } from 'mobx-react';
import { Catcher, LoadingApiByIds } from '../../';
import { HorizontalUsageChart } from './';
import { useDashboardZoneUsageChart, useRouterStore } from '../../../hooks';
import { tI, tP } from '../../../translate';
import Styles from '../m_dashboarStyles.less';

export const ZoneUsage: React.FC = observer(() => {
    const data = useDashboardZoneUsageChart();
    const routerStore = useRouterStore();
    const title = `${tP('Top Zones')} ${tI('by Usage')}`;
    const goToZoneList = (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>): void => {
        event.preventDefault();
        routerStore.goTo('ZonesList');
    };

    const titleLink = (): JSX.Element => (
        <a onClick={goToZoneList} href={`/zones`}>
            {title}
        </a>
    );
    const goToZone = (id: string): void => {
        routerStore.goTo('Zone', { params: { id } });
    };

    return (
        <Catcher>
            <LoadingApiByIds idSpinners={['GET_USAGE_ZONE_LIST']} />
            <div className={Styles.chart}>
                <HorizontalUsageChart sourceData={data} title={title} titleLink={titleLink()} onSelectRow={goToZone} />
            </div>
        </Catcher>
    );
});
