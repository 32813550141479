import { action, makeObservable, observable, reaction } from 'mobx';
import { UiStore } from './ui';
import { appConfig } from '../settings';
import { ICurrenciesListItem, IFilterParams, ISelectList } from '../interfaces';
import { getCurrenciesList } from '../middleware';
import { apiParamsCleaner } from '../instruments';

export class CurrenciesStore {
    private constructor() {
        makeObservable(this);
        reaction(
            () => this.forcedUpdateTime,
            () => {
                const UiStoreInstance = UiStore.getInstance();
                const params = UiStoreInstance.params;
                const cleanParams = apiParamsCleaner(params, this.filterParams);
                getCurrenciesList(cleanParams).then();
            },
            {
                delay: appConfig.APIDelay,
            },
        );
    }

    private static instance: CurrenciesStore;
    public static getInstance(): CurrenciesStore {
        if (!CurrenciesStore.instance) {
            CurrenciesStore.instance = new CurrenciesStore();
        }
        return CurrenciesStore.instance;
    }

    @observable select: ISelectList = [];
    @action setCurrenciesSelect(select: ISelectList): void {
        this.select = select;
    }

    @observable list: ICurrenciesListItem[] = [];
    @action setCurrenciesList(list: ICurrenciesListItem[]): void {
        this.list = list;
    }

    @action cleanStore(): void {
        this.list = [];
        this.select = [];
    }

    @observable forcedUpdateTime = 0;
    @action setForcedUpdateTime(): void {
        this.forcedUpdateTime = Date.now();
    }

    @observable filterParams: IFilterParams = {
        pageNumber: true,
        pageSize: true,
        search: true,
    };
}
