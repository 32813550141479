import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Checkbox, Col, Form, Input, Modal, Row } from 'antd';
import { LockOutlined, UnlockOutlined, UserOutlined } from '@ant-design/icons';
import { Catcher, makeRuleEmail, makeRulePassword } from '../../';
import sslImage from '../../../static/img/ssl.png';
import { ForgotPasswordForm } from './ForgotPasswordForm';
import { TermsFormFields } from './TermsFormFields';
import { FieldData } from 'rc-field-form/es/interface';
import { appConfig } from '../../../settings';
import { ILoginRequest } from '../../../REST';
import { IValidationField } from '../../../interfaces';
import { authLoginUser, getAuthAcceptanceDocuments, getProfileInfo, setAuthAppStatus } from '../../../middleware';
import { tB, tF, tP, tT } from '../../../translate';
import Styles from '../m_styles.less';

export const LoginForm: React.FC = observer(() => {
    const [form] = Form.useForm();
    const [initialValues] = useState({ email: '', password: '', remember: false });
    const [validatedFields] = useState(['email', 'password']);
    const [isValid, setValid] = useState(false);
    const [isSending, setSending] = useState(false);
    const [isPolicyError, setPolicyError] = useState(false);
    const [isOpenForgotModal, setOpenForgotModal] = useState(false);
    const { hostname } = window.location;
    const isLogoEnabled = hostname === appConfig.defaultOCSHost;

    const onFieldsChange = (changedFields: FieldData[], allFields: Array<FieldData>): void => {
        setValid(
            (allFields as Array<IValidationField>)
                .filter((f: IValidationField) => validatedFields.includes(f.name[0]))
                .map((f: IValidationField) => f.touched && !f.errors.length)
                .reduce((res: boolean, item: boolean) => res && item),
        );
    };

    const sendLogin = async (): Promise<void> => {
        const user = form.getFieldsValue() as ILoginRequest;
        setSending(true);
        setValid(false);
        const { resp, isError } = await authLoginUser(user);
        setSending(false);
        setValid(!isError);
        if (isError) {
            form.setFieldsValue({ password: '' });
            await form.validateFields(['password']);
        } else {
            if (!resp.docs_accepted) {
                await getAuthAcceptanceDocuments(resp.company_id);
                setPolicyError(true);
            } else {
                await getProfileInfo();
                await setAuthAppStatus('READY');
            }
        }
    };

    useEffect(() => {
        const iframe: HTMLIFrameElement = document.createElement('iframe');
        //APPLY STYLES TO IFRAME AS SUITABLE
        iframe.width = '100%';
        iframe.height = '100%';
        iframe.style.position = 'absolute';
        iframe.frameBorder = '0';
        iframe.scrolling = 'no';
        iframe.src = 'about:blank';
        //ADD OTHER PROPERTIES OF IFRAME AS SUITABLE

        const bodyStyle = `
            display: flex;
            justify-content: center;
            height: 32px;
            width: 82px;
        `;

        const htmlStyle = `
            display: flex;
            justify-content: center;
        `;

        const content = `
            <html style="${htmlStyle}">
                <script type="text/javascript"> //<![CDATA[ 
                    var tlJsHost = ((window.location.protocol == "https:") ? "https://secure.trust-provider.com/" : "http://www.trustlogo.com/");
                    document.write(unescape("%3Cscript src='" + tlJsHost + "trustlogo/javascript/trustlogo.js' type='text/javascript'%3E%3C/script%3E"));
                    //]]>
                </script>
                <body style="${bodyStyle}">
                    <style type="text/css" scoped>
                        #tl_popupSC3 { background-color: white !important; width: 255px !important; height: 205px !important; } 
                        a {
                            width: 100px;
                            position: relative;
                            opacity: 0;
                            bottom: 7px;
                        }
                    </style>
                    <script language="JavaScript" type="text/javascript">
                        TrustLogo("https://ocs.geniox.com/static/media/ssl.f092c8d0.png", "SC3", "none");
                    </script>
                </body>
            </html>`;

        document.querySelector('#sslSection')?.appendChild(iframe);
        iframe?.contentWindow?.document.open('text/html', 'replace');
        iframe?.contentWindow?.document.write(content);
        iframe?.contentWindow?.document.close();
    }, []);

    return (
        <Catcher>
            <Form
                form={form}
                name={tP('Login')}
                className={'formStyle'}
                initialValues={initialValues}
                onFieldsChange={onFieldsChange}
                layout={'vertical'}
            >
                <Form.Item name="email" rules={makeRuleEmail()} label={tF('Your username')}>
                    <Input
                        prefix={<UserOutlined className="site-form-item-icon" />}
                        placeholder={tF('Email')}
                        disabled={isPolicyError}
                    />
                </Form.Item>
                <Form.Item name="password" rules={makeRulePassword()} label={tF('Password')}>
                    <Input.Password
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder={tF('Password')}
                        disabled={isPolicyError}
                    />
                </Form.Item>
                <Row>
                    <Col xs={12}>
                        <Form.Item>
                            <Form.Item name="remember" valuePropName="checked" className={Styles.checkField}>
                                <Checkbox disabled={isPolicyError}>{tF('Remember me')}</Checkbox>
                            </Form.Item>
                        </Form.Item>
                    </Col>
                    {!isPolicyError ? (
                        <Col xs={12}>
                            <div className={Styles.forgotLink}>
                                <a href={'#'} onClick={(): void => setOpenForgotModal(true)} data-test="ForgotPassword">
                                    {tF('Forgot Password?')}
                                </a>
                            </div>
                        </Col>
                    ) : null}
                </Row>

                {!isPolicyError ? (
                    <Form.Item>
                        <Button
                            icon={<UnlockOutlined />}
                            type="primary"
                            htmlType="submit"
                            className="login-form-button"
                            data-test="ButtonLogin"
                            block
                            disabled={!isValid}
                            loading={isSending}
                            onClick={sendLogin}
                        >
                            {tB('Log in')}
                        </Button>
                    </Form.Item>
                ) : null}
                {isPolicyError ? <TermsFormFields /> : null}
                <div className={Styles.description}>
                    {tT(
                        'We protect the security of your information during transmission by using Secure Sockets Layer (SSL) software.',
                    )}
                </div>
                {isLogoEnabled ? (
                    <div id="sslSection" className={Styles.sslLogo}>
                        <img alt={'ssl logo'} src={sslImage} />
                    </div>
                ) : null}
            </Form>
            <Modal
                title={tP('Forgotten Password')}
                open={isOpenForgotModal}
                onCancel={(): void => setOpenForgotModal(false)}
                wrapClassName={'modal'}
                footer={null}
            >
                <ForgotPasswordForm setOpenModal={setOpenForgotModal} />
            </Modal>
        </Catcher>
    );
});
