import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Form } from 'antd';
import { CardInfoToolBar, CardMainLayout, Catcher, InputDebounce, TextAreaDebounce, cardLayoutOptions } from '../../';
import { FieldData } from 'rc-field-form/es/interface';
import { IAntDFormChangeFieldName, IFormFields } from '../../../interfaces';
import { useCompanyAbout, useCompanyInfo } from '../../../hooks';
import {
    apiMessagesDeleteMessageByFieldName,
    apiMessagesGetFormRules,
    apiMessagesSaveMessages,
    saveCompanyAbout,
} from '../../../middleware';
import { tF, tP } from '../../../translate';

export const EditCompanyCustomizeAbout: React.FC = observer(() => {
    const [form] = Form.useForm();
    const [isSending, setSending] = useState(false);
    const [isChanged, setChanged] = useState(false);
    const { id } = useCompanyInfo();
    const aboutInfo = useCompanyAbout();

    useEffect(() => {
        if (!isChanged) {
            form.setFieldsValue(aboutInfo);
        }
    }, [aboutInfo]);

    const onFieldsChange = (changedFields: FieldData[]): void => {
        const fieldNameChange =
            changedFields && changedFields[0] && (changedFields[0].name as IAntDFormChangeFieldName);

        if (fieldNameChange) {
            apiMessagesDeleteMessageByFieldName(fieldNameChange);
        }

        setChanged(true);
    };

    const onFinish = async (fields: IFormFields): Promise<void> => {
        setSending(true);

        const { isError, errors } = await saveCompanyAbout(id, fields);

        if (isError) {
            apiMessagesSaveMessages(errors);
            form.validateFields().then();
        } else {
            setChanged(false);
        }
        setSending(false);
    };

    return (
        <Catcher>
            <CardMainLayout
                columnOption={cardLayoutOptions.cardEditCompanyCustomize}
                idSpinners={['GET_COMPANY', 'GET_COMPANY_ABOUT', 'PATCH_COMPANY_ABOUT']}
                name={tP('Custom About')}
                dataTest={'EditCompanyCustomizeAbout'}
            >
                <Form
                    form={form}
                    name="aboutEdit"
                    onFieldsChange={onFieldsChange}
                    scrollToFirstError={true}
                    layout={'vertical'}
                    className={'formStyle'}
                    initialValues={aboutInfo}
                    validateTrigger={'onBlur'}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="name"
                        label={tF('Company')}
                        rules={apiMessagesGetFormRules}
                        data-test={'EditCompanyAboutName'}
                    >
                        <InputDebounce placeholder={tF('Company')} data-test={'EditCompanyAboutNameInput'} />
                    </Form.Item>

                    <Form.Item
                        name="address"
                        label={tF('Address')}
                        rules={apiMessagesGetFormRules}
                        data-test={'EditCompanyAboutAddress'}
                    >
                        <TextAreaDebounce
                            placeholder={tF('Address')}
                            autoSize={true}
                            data-test={'EditCompanyAboutAddressTextArea'}
                        />
                    </Form.Item>

                    <Form.Item
                        name="website"
                        label={tF('Website')}
                        rules={apiMessagesGetFormRules}
                        data-test={'EditCompanyAboutWebsite'}
                    >
                        <InputDebounce placeholder={tF('Website')} data-test={'EditCompanyAboutWebsiteInput'} />
                    </Form.Item>

                    <Form.Item
                        name="email"
                        label={tF('Email')}
                        rules={apiMessagesGetFormRules}
                        data-test={'EditCompanyAboutEmail'}
                    >
                        <InputDebounce placeholder={tF('Email')} data-test={'EditCompanyAboutEmailInput'} />
                    </Form.Item>

                    <Form.Item
                        name="phoneNumber"
                        label={tF('Phone')}
                        rules={apiMessagesGetFormRules}
                        data-test={'EditCompanyAboutPhone'}
                    >
                        <InputDebounce placeholder={tF('Phone')} data-test={'EditCompanyAboutPhoneInput'} />
                    </Form.Item>

                    <Form.Item
                        name="registration"
                        label={tF('C of C registration')}
                        rules={apiMessagesGetFormRules}
                        data-test={'EditCompanyAboutRegistration'}
                    >
                        <InputDebounce
                            placeholder={tF('C of C registration')}
                            data-test={'EditCompanyAboutRegistrationInput'}
                        />
                    </Form.Item>

                    <Form.Item
                        name="iban"
                        label={tF('IBAN account')}
                        rules={apiMessagesGetFormRules}
                        data-test={'EditCompanyAboutIBAN'}
                    >
                        <InputDebounce placeholder={tF('IBAN account')} data-test={'EditCompanyAboutIBANInput'} />
                    </Form.Item>

                    <Form.Item
                        name="swift"
                        label={tF('Swift-Bic')}
                        rules={apiMessagesGetFormRules}
                        data-test={'EditCompanyAboutSwift'}
                    >
                        <InputDebounce placeholder={tF('Swift-Bic')} data-test={'EditCompanyAboutSwiftInput'} />
                    </Form.Item>

                    <Form.Item
                        name="vat"
                        label={tF('VAT')}
                        rules={apiMessagesGetFormRules}
                        data-test={'EditCompanyAboutVAT'}
                    >
                        <InputDebounce placeholder={tF('VAT')} data-test={'EditCompanyAboutVATInput'} />
                    </Form.Item>

                    <CardInfoToolBar isLoading={isSending} isSubmitButton={isChanged} labelSave={'Save About'} />
                </Form>
            </CardMainLayout>
        </Catcher>
    );
});
