import React, { memo } from 'react';
import { Form, Select } from 'antd';
import { Rule } from 'antd/es/form';
import { Catcher } from '../';
import { filterOption } from './filterOption';
import cx from 'classnames';

interface IFormSelect {
    fieldName: string | [number, string];
    isAddonBefore?: boolean;
    isGetPopupContainerEnabled?: boolean;
    isLoading?: boolean;
    isNotClear?: boolean;
    isShortForm?: boolean;
    label?: string;
    onChange?: (...params: any) => any;
    rules?: Array<Rule>;
    selectJSX: JSX.Element[];
    initialValue?: string;
    disabled?: boolean;
}

const FormSelect3: React.FC<IFormSelect> = ({
    fieldName,
    isAddonBefore,
    isGetPopupContainerEnabled = true,
    isLoading,
    isNotClear,
    label,
    onChange,
    rules = [],
    selectJSX,
    initialValue,
    disabled,
}: IFormSelect) => {
    return (
        <Catcher>
            <Form.Item
                name={fieldName}
                label={label}
                rules={rules}
                initialValue={initialValue}
                className={cx({
                    ['ant-form-item-addonSelect']: isAddonBefore,
                })}
            >
                <Select
                    allowClear={!isNotClear}
                    filterOption={filterOption}
                    getPopupContainer={
                        isGetPopupContainerEnabled ? (trigger): HTMLElement => trigger.parentElement : undefined
                    }
                    loading={isLoading}
                    onChange={onChange}
                    placeholder={label}
                    showSearch
                    disabled={disabled}
                >
                    {selectJSX}
                </Select>
            </Form.Item>
        </Catcher>
    );
};

export const FormSelectLight = memo(FormSelect3);
