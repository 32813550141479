import React from 'react';
import { observer } from 'mobx-react';
import { FinancesRevenuesIsBigQueryCosts } from './FinancesRevenuesIsBigQueryCosts';
import { FinancesRevenuesIsNotBigQueryCosts } from './FinancesRevenuesIsNotBigQueryCosts';
import { useProfileFeatureFlagsIsBigQuery } from '../../../hooks';

export const FinancesRevenuesCosts: React.FC = observer(() => {
    const isBigQuery = useProfileFeatureFlagsIsBigQuery();
    return isBigQuery ? <FinancesRevenuesIsBigQueryCosts /> : <FinancesRevenuesIsNotBigQueryCosts />;
});
