import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { IFilterParams, ISubscribersListItem, ISubscribersSelectItemAPI } from '../../interfaces';

export type IImsiSelectList = Array<IImsiSelect>;
export type IImsiSelectFind = IImsiSelect | undefined;

export interface IImsiSelect {
    id: string;
    imsi: string;
}

export function useSubscribersImsiSelect(): IImsiSelectList {
    const list = useContext(MobXProviderContext).RootStore.subscribersStore.select;
    return list.map((c: ISubscribersSelectItemAPI) => ({
        id: c.id,
        imsi: c.attributes.imsi,
    }));
}

export function useSubscribersTable(): ISubscribersListItem[] {
    return useContext(MobXProviderContext).RootStore.subscribersStore.list;
}

export function useSubscribersFilterParams(): IFilterParams {
    return useContext(MobXProviderContext).RootStore.subscribersStore.filterParams;
}
