import React, { useEffect, useState } from 'react';
import { Button, Tooltip } from 'antd';
import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import { Catcher } from '../';
import screenFull from 'screenfull';
import { IDivMainProps } from '../../interfaces';
import { useUiLanguage } from '../../hooks';
import { tI } from '../../translate';

export const FullscreenButton: React.FC<IDivMainProps> = ({ className }: IDivMainProps) => {
    const [isFullScreen, setFullScreen] = useState<boolean>(false);
    const languageId = useUiLanguage();

    const toggleScreenFull = (): void => {
        if (screenFull.isEnabled && !screenFull.isFullscreen) {
            screenFull.request();
        } else if (screenFull.isEnabled && screenFull.isFullscreen) {
            screenFull.exit();
        }
    };
    const detectIconChange = (): void => {
        if (screenFull.isEnabled) {
            setFullScreen(screenFull.isFullscreen);
        }
    };

    useEffect(() => {
        if (screenFull.isEnabled) {
            screenFull.on('change', detectIconChange);
        }
    }, []);

    return (
        <Catcher>
            <div className={className}>
                <Tooltip
                    title={isFullScreen ? tI('Exit fullscreen mode') : tI('Fullscreen mode')}
                    placement="bottomRight"
                >
                    <Button
                        onClick={toggleScreenFull}
                        icon={isFullScreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                    />
                </Tooltip>
            </div>
        </Catcher>
    );
};
