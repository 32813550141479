import { FeatureFlagStore } from '../stores/featureFlag';
import { getCompaniesSelect } from './companies';
import { getUsersSelect } from './users';
import { IBodyListResponse, IRequestAPIBody, apiManager } from '../REST';
import { FeatureFlagsList, IFeatureFlagApi, IPatchItem } from '../interfaces';
import { IMapField, mapApi2UiField } from '../instruments';

export function formatFeatureFlagList(resp: IBodyListResponse): FeatureFlagsList {
    const { feature_flags } = resp as IFeatureFlagApi;

    return feature_flags.map((value) => {
        const parsedAttributes = mapApi2UiField(value);
        const parsedConditions = mapApi2UiField(value.conditions);

        return {
            ...parsedAttributes,
            conditions: {
                companyIds: parsedConditions.companyIds?.join(','),
                userIds: parsedConditions.userIds?.join(','),
            },
        };
    }) as FeatureFlagsList;
}

export function setFeatureFlagList(list: FeatureFlagsList) {
    const FeatureFlagStoreInstance = FeatureFlagStore.getInstance();
    FeatureFlagStoreInstance.setFeatureFlagsList(list);
}

export async function getFeatureFlagsListAll(): Promise<FeatureFlagsList> {
    const { resp } = await apiManager.getApi('GET_FEATURE_FLAG_LIST_ALL');

    const flagList: FeatureFlagsList = formatFeatureFlagList(resp as IBodyListResponse);

    setFeatureFlagList(flagList);

    return flagList;
}

function formatFeatureFlagUpdateBody(fields: IMapField) {
    return {
        value: fields?.value,
        conditions: {
            company_ids: Array.from(new Set(fields.companyIds?.map((value: any) => value.trim()))) || [],
            user_ids: Array.from(new Set(fields.userIds?.map((value: any) => value.trim()))) || [],
        },
    };
}

export async function updateFeatureFlag(id: string, fields: IMapField): Promise<IPatchItem> {
    const apiBody = formatFeatureFlagUpdateBody(fields) as IRequestAPIBody;

    const { resp, isError } = await apiManager.getApi('PATCH_FEATURE_FLAGS', { id: id }, apiBody);

    return { isError, errors: resp.errors };
}

export async function getFeatureFlagsRelations(): Promise<void> {
    getUsersSelect({ isActivityLogUsers: false, pageSize: 100 });
    getCompaniesSelect();
}
