import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Catcher } from '../';
import { IShowFormComponent } from '../../interfaces';
import { useRouterStore } from '../../hooks';
import {
    abortAllFetch,
    cleanPurchaseRateRateList,
    getPurchaseRate,
    getPurchaseRateEditRelationSelect,
    getPurchaseRateShowRelationSelect,
} from '../../middleware';
import { EditPurchaseForms, ShowPurchase } from './Components';

export const PurchaseRateList: React.FC<IShowFormComponent> = observer(({ isEdit, isNew }: IShowFormComponent) => {
    const routerStore = useRouterStore();
    const { params, routeName } = routerStore.routerState;
    const [id, setId] = useState('');

    useEffect(() => {
        if (isEdit || isNew) {
            getPurchaseRateEditRelationSelect();
        } else {
            getPurchaseRateShowRelationSelect();
        }
        return (): void => {
            abortAllFetch();
            cleanPurchaseRateRateList();
        };
    }, []);

    useEffect(() => {
        if (params.id) {
            setId(params.id);
        }
    }, [routeName, params]);

    const _getPurchase = async (id: string): Promise<void> => {
        if (!(await getPurchaseRate(id))) {
            routerStore.goTo('PurchaseRatesList');
        }
    };

    useEffect(() => {
        _getPurchase(id);
    }, [id]);

    const setEdit = (isEdit?: boolean): void => {
        if (isNew) {
            routerStore.goTo('PurchaseRatesList');
        } else if (isEdit) {
            _getPurchase(id);
            getPurchaseRateEditRelationSelect();
            routerStore.goTo('PurchaseRateListEdit', { id });
        } else {
            _getPurchase(id);
            getPurchaseRateShowRelationSelect();
            routerStore.goTo('PurchaseRateList', { id });
        }
    };

    return (
        <Catcher>
            {isEdit || isNew ? (
                <EditPurchaseForms setEditMode={setEdit} isNew={isNew} />
            ) : (
                <ShowPurchase setEditMode={setEdit} />
            )}
        </Catcher>
    );
});
