/* eslint-disable */
import { PackageStore } from '../stores/package';
import { cleanApiMessages } from './apiMessages';
import { cleanCardSetRelationSelect } from './cardSet';
import { makeSelectList } from './catalogs';
import { cleanCompaniesStore, getCompaniesSelect } from './companies';
import { cleanCurrenciesStore, getCurrenciesSelect } from './currencies';
import { cleanZonesStore, getZonesSelect } from './zones';
import dayjs from 'dayjs';
import { appConfig } from '../settings';
import { IBodyItemResponse, apiManager } from '../REST';
import {
    IAssignedMap,
    IBodyListResponse,
    IIncludeCompanyItemAPI,
    IIncludeItemCurrencyAPI,
    IIncludePackageAssignedCompanyItemPostAPI,
    IIncludePackageItemApi,
    IIncludedPackagePostAPI,
    IItemAPI,
    IMultipleValuesProps,
    IPackageForm,
    IPackageGETAPI,
    IPackageInfo,
    IPackageInstanceForm,
    IPackageInstancePOSTAPI,
    IPackageInstancePostDataAPI,
    IPackageInstancePostDataAttributesAPI,
    IPackagePOSTAPI,
    IPackagePostDataAPI,
    IPackagePostDataAPIRelationships,
    IPackagePostDataAttributesAPI,
    IPackageSoldGETAPI,
    IPackageSoldInfo,
    IPatchItem,
    IRelationshipItem,
    IRequestAPIBody,
} from '../interfaces';
import { byteConverter, formatBytes, globalHexId, mapUi2ApiField, percentage, unionStringArr } from '../instruments';
import { tCustom, tF, tI } from '../translate';

export function getAssignedMultipleValues(propName: string): IMultipleValuesProps {
    const StoreInstance = PackageStore.getInstance();
    const objectWithKeys: { [key: string]: any } = StoreInstance.packageInfo;
    return objectWithKeys[propName];
}

interface IMultipleListOptions {
    assignedIds: number[] | undefined;
    getAssignedMultipleValuesPropName: string;
    relationAndIncludedType: 'companies_packages' | 'packages_countries' | 'packages_networks' | 'packages_operators';
    includeEntityName: string;
}

export function formMultipleListBodyValue({
    assignedIds,
    getAssignedMultipleValuesPropName,
    relationAndIncludedType,
    includeEntityName,
}: IMultipleListOptions) {
    const assignedMultipleValues = getAssignedMultipleValues(getAssignedMultipleValuesPropName);
    const assignedMap = assignedMultipleValues?.assignedMapArr;
    const oldAssignedId = assignedMultipleValues?.assignedId;
    const stringAssignedId = assignedIds?.map((value) => `${value}`) as string[];
    const joinedArr = unionStringArr(oldAssignedId, stringAssignedId);
    const assignedArr = joinedArr?.map((id) => {
        const destroy = oldAssignedId?.includes(`${id}`) && !stringAssignedId?.includes(id);
        const isAlreadyAssigned = oldAssignedId?.includes(`${id}`) && stringAssignedId?.includes(id);
        const foundMap = assignedMap?.find((map) => {
            return id === map.entityId;
        });

        return {
            id: destroy || isAlreadyAssigned ? `${foundMap?.entityRecordId}` : globalHexId.getId(),
            type: relationAndIncludedType,
            entityId: `${id}`,
            destroy,
            isAlreadyAssigned,
        };
    });

    const filteredAlreadyAssignedArr = assignedArr?.filter((value) => {
        return !value.isAlreadyAssigned;
    });

    const companiesPackages = filteredAlreadyAssignedArr?.map((value) => {
        return {
            id: `${value.id}`,
            type: `${value.type}`,
            destroy: value.destroy,
        };
    }) as IRelationshipItem[];

    const relationships: IPackagePostDataAPIRelationships = {
        [relationAndIncludedType]: {
            data: companiesPackages,
        },
    };

    const filteredDestroyedArr = filteredAlreadyAssignedArr?.filter((value) => {
        return !value.destroy;
    });

    const included = filteredDestroyedArr?.map((value) => {
        const attributes = {
            [includeEntityName]: `${value.entityId}`,
        };

        return {
            id: `${value.id}`,
            type: relationAndIncludedType,
            attributes,
        };
    }) as IIncludedPackagePostAPI[];

    return { relationships, included };
}

export function formPackageBody(values: IPackageForm): IPackagePOSTAPI {
    const {
        id: _,
        dataVolume,
        dataVolumeSelect,
        assignedCompaniesId,
        assignedCountriesId,
        assignedOperatorsId,
        assignedNetworksId,
        ...info
    } = values;
    const attributes: IPackagePostDataAttributesAPI = mapUi2ApiField({
        dataVolume: byteConverter(+dataVolume, +dataVolumeSelect),
        ...info,
    });
    const companyMultipleList = formMultipleListBodyValue({
        assignedIds: assignedCompaniesId,
        relationAndIncludedType: 'companies_packages',
        getAssignedMultipleValuesPropName: 'assignedCompanies',
        includeEntityName: 'company_id',
    });
    const countryMultipleList = formMultipleListBodyValue({
        assignedIds: assignedCountriesId,
        relationAndIncludedType: 'packages_countries',
        getAssignedMultipleValuesPropName: 'assignedCountries',
        includeEntityName: 'country_id',
    });

    const operatorMultipleList = formMultipleListBodyValue({
        assignedIds: assignedOperatorsId,
        relationAndIncludedType: 'packages_operators',
        getAssignedMultipleValuesPropName: 'assignedOperators',
        includeEntityName: 'operator_id',
    });

    const networkMultipleList = formMultipleListBodyValue({
        assignedIds: assignedNetworksId,
        relationAndIncludedType: 'packages_networks',
        getAssignedMultipleValuesPropName: 'assignedNetworks',
        includeEntityName: 'operator_id',
    });

    const data: IPackagePostDataAPI = {
        type: 'package',
        attributes,
        relationships: {
            ...companyMultipleList.relationships,
            ...countryMultipleList.relationships,
            ...operatorMultipleList.relationships,
            ...networkMultipleList.relationships,
        },
    };

    return {
        data,
        included: [
            ...companyMultipleList.included,
            ...countryMultipleList.included,
            ...operatorMultipleList.included,
            ...networkMultipleList.included,
        ],
    };
}

export async function savePackage(values: IPackageForm, isNew: boolean): Promise<IPatchItem> {
    const { id } = values;
    const body = formPackageBody(values) as IRequestAPIBody;
    const { resp, isError } = await apiManager.getApi(
        isNew ? 'POST_PACKAGE' : 'PATCH_PACKAGE',
        isNew ? {} : { id: id },
        body,
    );
    const { errors } = await resp;

    return { isError, errors };
}

export async function editPackageInstance(values: IPackageInstanceForm): Promise<IPatchItem> {
    const { id } = values;
    delete values.id;
    delete values.companyId;

    const attributes: IPackageInstancePostDataAttributesAPI = mapUi2ApiField(values);

    const data: IPackageInstancePostDataAPI = {
        type: 'package_instance',
        attributes,
    };

    const body: IPackageInstancePOSTAPI = {
        data,
    };

    const { resp, isError } = await apiManager.getApi('PATCH_PACKAGE_INSTANCE', { id: id }, body);
    const { errors } = await resp;

    return { isError, errors };
}

export function mapApiToSoldPackageInfo(apiBody: IPackageSoldGETAPI): IPackageSoldInfo {
    const id = apiBody?.data?.id || globalHexId.getId();
    const packageId = apiBody?.data?.relationships?.package?.data?.id;
    const packageFind = apiBody?.included?.find((value) => value.type === 'package' && value.id === packageId);
    const packageName = (packageFind as IItemAPI)?.attributes?.name;
    const bytes = (packageFind as IIncludePackageItemApi)?.attributes?.data_volume;
    const convertedBytes = formatBytes(bytes, 1000, 1)?.split(' ');
    const dataVolume = +convertedBytes[0];
    const dataVolumeName = `${convertedBytes[0]} ${convertedBytes[1]}`;
    const bytesLeft = apiBody?.data?.attributes?.data_volume;
    const convertedBytesLeft = formatBytes(bytesLeft, 1000, 1)?.split(' ');
    const dataVolumeLeft = +convertedBytesLeft[0];
    const dataVolumeNameLeft = `${convertedBytesLeft[0]} ${convertedBytesLeft[1]}`;
    const bytesLeftPercentage = percentage(bytesLeft || 0, bytes);
    const creditRemains = apiBody?.data?.attributes?.credit_remains;
    const creditRemainsName = creditRemains ? tI('Yes') : tI('No');
    const zoneId = apiBody?.data?.relationships?.zone?.data?.id;
    const zoneFind = apiBody?.included?.find((value) => value.type === 'zone' && value.id === zoneId);
    const zoneName = (zoneFind as IItemAPI)?.attributes?.name;
    const expiresDate = apiBody?.data?.attributes?.expire_date;
    const createdAt = apiBody?.data?.attributes?.created_at;
    const isExpired = apiBody?.data?.attributes?.is_expired;
    const companyId = apiBody?.data?.relationships?.company?.data?.id;
    const companyFind = apiBody?.included?.find((value) => value.type === 'company' && value.id === companyId);
    const companyName = (companyFind as IItemAPI)?.attributes?.name;
    const cardSetId = apiBody?.data?.relationships?.card_set?.data?.id;
    const cardSetFind = apiBody?.included?.find((value) => value.type === 'card_set' && value.id === cardSetId);
    const cardSetName = (cardSetFind as IItemAPI)?.attributes?.name;
    const renewal = apiBody?.data?.attributes?.renewal;
    const renewalName = renewal ? tI('Yes') : tI('No');
    // const assignedCompanies = packageInfoFormListValues(apiBody, 'company', 'companies_packages');
    const assignedCountries = packageInfoFormListValues(apiBody, 'country', 'package_instances_countries');
    const assignedOperators = packageInfoFormListValues(apiBody, 'operator', 'package_instances_operators');
    const assignedNetworks = packageInfoFormListValues(apiBody, 'network', 'package_instances_networks');

    return {
        id,
        dataVolume,
        dataVolumeName,
        dataVolumeLeft,
        dataVolumeNameLeft,
        creditRemains,
        creditRemainsName,
        zoneId,
        zoneName,
        expiresDate,
        isExpired,
        packageId,
        packageName,
        cardSetId,
        cardSetName,
        companyId,
        companyName,
        createdAt,
        bytesLeftPercentage,
        renewal,
        renewalName,
        // assignedCompanies,
        assignedCountries,
        assignedOperators,
        assignedNetworks,
    };
}

export function packageInfoFormListValues(
    apiBody: IPackageGETAPI | IPackageSoldGETAPI,
    type: string,
    recordType: string,
): IMultipleValuesProps {
    const assignedIncludes = apiBody?.included?.filter((value) => value.type === type);
    const assignedRecordsRelations = apiBody.data?.relationships as { [key: string]: any };
    const assignedRecordsId = assignedRecordsRelations?.[recordType]?.data?.map(
        (value: IRelationshipItem) => value?.id,
    );
    const assignedRecordsIncludes = apiBody.included?.filter(
        (value) => value.type === recordType,
    ) as IIncludePackageAssignedCompanyItemPostAPI[];
    const actualAssignedRecords = assignedRecordsIncludes?.filter((value) => {
        if (!value?.id) {
            return false;
        }
        return assignedRecordsId?.includes(value?.id);
    });
    const assignedMapArr = actualAssignedRecords?.map((record) => {
        const foundEntity = assignedIncludes?.find((value) => {
            const recordRelation = record?.relationships as { [key: string]: any };
            const recordId = recordRelation?.[type]?.data?.id;
            return value?.id === recordId;
        }) as IIncludeCompanyItemAPI;

        if (!foundEntity?.attributes) {
            return '';
        }

        return {
            name: foundEntity.attributes.name,
            entityId: foundEntity.id,
            entityRecordId: record?.id,
        };
    }) as IAssignedMap[];
    const assignedNameArr = assignedMapArr?.map((value) => value.name) as string[];
    const assignedId = assignedMapArr.map((value) => value.entityId || '');

    return {
        assignedId,
        assignedMapArr,
        assignedNameArr,
    };
}

export function mapApiToPackageInfo(apiBody: IPackageGETAPI): IPackageInfo {
    const id = apiBody?.data?.id || globalHexId.getId();
    const creditRemains = apiBody?.data?.attributes?.credit_remains;
    const monthDuration = apiBody?.data?.attributes?.month_duration;
    const duration = apiBody?.data?.attributes?.duration;
    const monthDurationName = monthDuration
        ? tCustom('Until the end of', { month: dayjs().format('MMMM') })
        : `${duration} ${tF('day')}${duration === 1 ? '' : 's'}`;
    const name = apiBody?.data?.attributes?.name;
    const notes = apiBody?.data?.attributes?.notes;
    const price = apiBody?.data?.attributes?.price;
    const zoneId = apiBody?.data?.relationships?.zone?.data?.id;
    const currencyId = apiBody?.data?.relationships?.currency?.data?.id;
    const bytes = apiBody?.data?.attributes?.data_volume;
    const companyId = apiBody?.data?.relationships?.company?.data?.id;
    const convertedBytes = formatBytes(bytes, 1000, 1)?.split(' ');
    const dataVolume = +convertedBytes[0];
    const dataVolumeSelect = appConfig.bytesTypes.find((value) => value.name === convertedBytes[1])?.id;
    const companyFind = apiBody?.included?.find((value) => value.type === 'company' && value.id === companyId);
    const companyName = (companyFind as IItemAPI)?.attributes?.name;
    const zoneFind = apiBody?.included?.find((value) => value.type === 'zone' && value.id === zoneId);
    const zoneName = (zoneFind as IItemAPI)?.attributes?.name;
    const currencyFind = apiBody?.included?.find((value) => value.type === 'currency' && value.id === currencyId);
    const currencyName = (currencyFind as IIncludeItemCurrencyAPI)?.attributes?.abbreviation;
    const creditRemainsName = creditRemains ? tI('Yes') : tI('No');
    const dataVolumeName = `${convertedBytes[0]} ${convertedBytes[1]}`;
    const assignedCompanies = packageInfoFormListValues(apiBody, 'company', 'companies_packages');
    const assignedCountries = packageInfoFormListValues(apiBody, 'country', 'packages_countries');
    const assignedOperators = packageInfoFormListValues(apiBody, 'operator', 'packages_operators');
    const assignedNetworks = packageInfoFormListValues(apiBody, 'network', 'packages_networks');

    return {
        id,
        creditRemains,
        currencyId,
        duration,
        name,
        notes,
        price,
        zoneId,
        dataVolume,
        dataVolumeSelect,
        companyId,
        companyName,
        creditRemainsName,
        dataVolumeName,
        zoneName,
        currencyName,
        assignedCompanies,
        assignedCountries,
        assignedOperators,
        assignedNetworks,
        monthDuration,
        monthDurationName,
    };
}

export function setPackageInfoStore(packageInfo: IPackageInfo) {
    const PackageInterface = PackageStore.getInstance();
    PackageInterface.setPackage(packageInfo);
}

export function setPackageSoldInfoStore(packageSoldInfo: IPackageSoldInfo) {
    const PackageInterface = PackageStore.getInstance();
    PackageInterface.setSoldPackage(packageSoldInfo);
}

export async function getPackageById(id: string): Promise<boolean> {
    const { resp, isError } = await apiManager.getApi('GET_ONE_PACKAGE', { id: id });
    const { data } = resp as IBodyItemResponse;
    if (!isError && data?.id) {
        setPackageInfoStore(mapApiToPackageInfo(resp as IPackageGETAPI));
    }

    return isError;
}

export async function getPackageToBuyById(id: string): Promise<boolean> {
    const { resp, isError } = await apiManager.getApi('GET_ONE_PACKAGE_TO_BUY', { id: id });
    const { data } = resp as IBodyItemResponse;
    if (!isError && data?.id) {
        setPackageInfoStore(mapApiToPackageInfo(resp as IPackageGETAPI));
    }

    return isError;
}

export async function getBoughtPackageById(id: string): Promise<boolean> {
    const { resp, isError } = await apiManager.getApi('GET_ONE_PACKAGE_INSTANCE', { id: id });
    const { data } = resp as IBodyItemResponse;
    if (!isError && data?.id) {
        setPackageSoldInfoStore(mapApiToSoldPackageInfo(resp as IPackageSoldGETAPI));
    }

    return isError;
}

function setPaymentTypeSelect(apiList: IBodyListResponse): void {
    const StoreInstance = PackageStore.getInstance();
    StoreInstance.setPaymentTypes(makeSelectList(apiList));
}

export async function getPaymentTypeSelect(): Promise<void> {
    const { resp } = await apiManager.getApi('GET_ORDER_PAYMENT_SELECT');
    resp && setPaymentTypeSelect(resp as IBodyListResponse);
}

export function getPackageRelationSelect(): void {
    cleanApiMessages();
    getCurrenciesSelect();
    getZonesSelect();
    getCompaniesSelect();
}

export function cleanPackageRelationSelect(): void {
    cleanApiMessages();
    cleanCurrenciesStore();
    cleanZonesStore();
    cleanCompaniesStore();
}

export function getPackageSoldRelationSelect(): void {
    cleanApiMessages();
    getCompaniesSelect();
}

export function cleanPackageSoldRelationSelect(): void {
    cleanApiMessages();
    cleanCardSetRelationSelect();
    cleanCompaniesStore();
}

export function cleanPackageStore(): void {
    const PackageInterface = PackageStore.getInstance();
    PackageInterface.cleanStore();
}
