import { action, makeObservable, observable } from 'mobx';
import { ISubscriberInfo } from '../interfaces';

export class SubscriberStore {
    private constructor() {
        makeObservable(this);
    }

    private static instance: SubscriberStore;
    public static getInstance(): SubscriberStore {
        if (!SubscriberStore.instance) {
            SubscriberStore.instance = new SubscriberStore();
        }
        return SubscriberStore.instance;
    }

    @observable info: ISubscriberInfo = { id: '' };
    @action setInfo(info: ISubscriberInfo): void {
        this.info = info;
    }

    @action cleanStore(): void {
        this.info = { id: '' };
    }
}
