import { CallDataRecordsBetaStore } from '../stores/callDataRecordsBeta';
import {
    abortSelectedFetch,
    cleanCardSetsStore,
    cleanCardsStore,
    cleanCompaniesStore,
    cleanCountriesStore,
    cleanSubscribersStore,
    getUiStorageParams,
    removeUiSpinnerByApiType,
    setCallDataRecordsListStore,
    setUiTotal,
} from './';
import { apiManager } from '../REST';
import { ICallDataRecordsListAPI, IGetApiType, IParamsList } from '../interfaces';
import { apiParamsCleaner, n0u2n } from '../instruments';

export function setCallDataRecordsForcedUpdateTimeBeta(): void {
    const CallDataRecordsStoreInstance = CallDataRecordsBetaStore.getInstance();
    CallDataRecordsStoreInstance.setForcedUpdateTime();
}
export function cleanCallDataRecordsStoreBeta(): void {
    cleanCompaniesStore();
    cleanCardSetsStore();
    cleanCountriesStore();
    cleanSubscribersStore();
    cleanCardsStore();
    const CallDataRecordsStoreInstance = CallDataRecordsBetaStore.getInstance();
    CallDataRecordsStoreInstance.cleanStore();
}

// ------------ params ------------

export const getCallDataRecordsBetaCleanParams = (): IParamsList => {
    const instance = CallDataRecordsBetaStore.getInstance();
    const { filterParams } = instance;
    const params = getUiStorageParams();
    return apiParamsCleaner(params, filterParams);
};

// const formatCDRsSquashedParams = (params: IParamsList): IParamsList => {
//     if (checkIsToday(params.endDate)) {
//         params.endDate = dayjs().subtract(1, 'days').format(appConfig.formatDate);
//     }
//     if (checkIsToday(params.startDate)) {
//         params.startDate = dayjs().add(1, 'days').format(appConfig.formatDate);
//     }
//     return params;
// };

export const setCallDataRecordsBetaSquashedCount = (count: number): void => {
    const instance = CallDataRecordsBetaStore.getInstance();
    instance.setSquashedCount(count);
};

const getCallDataRecordsBetaSquashedCount = async (params: IParamsList): Promise<number> => {
    const {
        resp: { count },
    } = await apiManager.getApi('GET_CALL_DATA_SQUASHED_RECORDS_COUNT', { searchParamsList: params });
    const numCount = n0u2n(count);
    setCallDataRecordsBetaSquashedCount(numCount);
    return n0u2n(numCount);
};

const abortGetCallDataRecordsBetaAllCountFetch = () => {
    const usedApiTypes: IGetApiType[] = ['GET_CALL_DATA_SQUASHED_RECORDS_COUNT', 'GET_CALL_DATA_RECORDS_COUNT'];
    usedApiTypes.forEach((apiType) => {
        removeUiSpinnerByApiType(apiType);
    });

    abortSelectedFetch(usedApiTypes);
};

export const getCallDataRecordsBetaAllCount = async (): Promise<void> => {
    abortGetCallDataRecordsBetaAllCountFetch();
    const params = getCallDataRecordsBetaCleanParams();
    const count = await getCallDataRecordsBetaSquashedCount(params);

    setUiTotal(count);
};

// ------------ List ------------

const abortGetCallDataRecordsBetaListFetch = () => {
    const usedApiTypes: IGetApiType[] = ['GET_CALL_DATA_RECORDS_LIST', 'GET_CALL_DATA_SQUASHED_RECORDS_LIST'];
    usedApiTypes.forEach((apiType) => {
        removeUiSpinnerByApiType(apiType);
    });

    abortSelectedFetch(usedApiTypes);
};

export const getCallDataRecordsListBeta = async (): Promise<void> => {
    abortGetCallDataRecordsBetaListFetch();
    const params = getCallDataRecordsBetaCleanParams();
    const { resp: squashedResp } = await apiManager.getApi('GET_CALL_DATA_SQUASHED_RECORDS_LIST', {
        searchParamsList: params,
    });

    setCallDataRecordsListStore(squashedResp as ICallDataRecordsListAPI, true);
};
