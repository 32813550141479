import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { ICurrenciesListItem, IFilterParams, ISelect, ISelectFind, ISelectList } from '../../interfaces';

export function useCurrenciesTable(): ICurrenciesListItem[] {
    return useContext(MobXProviderContext).RootStore.currenciesStore.list;
}

export function useCurrenciesFilterParams(): IFilterParams {
    return useContext(MobXProviderContext).RootStore.currenciesStore.filterParams;
}

export const useCurrencySelect = (): ISelectList => {
    return useContext(MobXProviderContext).RootStore.currenciesStore.select;
};

export const useCurrencyCodeById = (id?: string): string => {
    const findCurrency: ISelectFind = useContext(MobXProviderContext).RootStore.currenciesStore.select.find(
        (i: ISelect) => i.id === id,
    );
    return findCurrency ? (findCurrency.name ? findCurrency.name : findCurrency.id) : 'Cur.';
};
