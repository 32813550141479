import { action, makeObservable, observable, reaction } from 'mobx';
import { UiStore } from './ui';
import { appConfig } from '../settings';
import { IFilterParams, IPackagesUnitsLogsItem } from '../interfaces';
import { getPackagesUnitsLogsList } from '../middleware';
import { apiParamsCleaner } from '../instruments';

export class PackagesUnitsLogsStore {
    private constructor() {
        makeObservable(this);
        reaction(
            () => this.forcedUpdateTime,
            () => {
                const UiStoreInstance = UiStore.getInstance();
                const params = UiStoreInstance.params;
                const cleanParams = apiParamsCleaner(params, this.filterParams);
                getPackagesUnitsLogsList(cleanParams).then();
            },
            {
                delay: appConfig.APIDelay,
            },
        );
    }

    private static instance: PackagesUnitsLogsStore;
    public static getInstance(): PackagesUnitsLogsStore {
        if (!PackagesUnitsLogsStore.instance) {
            PackagesUnitsLogsStore.instance = new PackagesUnitsLogsStore();
        }
        return PackagesUnitsLogsStore.instance;
    }

    @observable list: IPackagesUnitsLogsItem[] = [];
    @action setPackagesUnitsLogsList(packagesUnitsLogsList: IPackagesUnitsLogsItem[]): void {
        this.list = packagesUnitsLogsList;
    }

    @observable forcedUpdateTime = 0;
    @action setForcedUpdateTime(): void {
        this.forcedUpdateTime = Date.now();
    }

    @observable filterParams: IFilterParams = {
        companyId: true,
        dateRange: true,
        defaultDateRangeType: 'month',
        isDateRangeCleanedDefaultURL: true,
        isDateRangeMaxDayToDay: true,
        pageNumber: true,
        pageSize: true,
        search: true,
        cardSetId: true,
        packageId: true,
    };

    @action cleanStore(): void {
        this.list = [];
    }
}
