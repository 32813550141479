import React from 'react';
import { FileDoneOutlined, FrownOutlined, HourglassOutlined, StopOutlined, SyncOutlined } from '@ant-design/icons';
import cx from 'classnames';
import { IReportStatus } from '../../interfaces';
import { useUiSearch } from '../../hooks';
import { lightText } from '../../instruments';
import { tI } from '../../translate';
import Styles from './m_styles.less';

type IReportStatusIcons = {
    status?: IReportStatus;
};

export const ReportStatusIcons: React.FC<IReportStatusIcons> = ({ status }: IReportStatusIcons): JSX.Element | null => {
    const search = useUiSearch();
    const icon = (): JSX.Element => {
        switch (status) {
            case 'done':
                return <FileDoneOutlined className={Styles.icon} />;
            case 'in_progress':
                return <SyncOutlined spin className={Styles.icon} />;
            case 'failed':
                return <FrownOutlined className={Styles.icon} />;
            case 'enqueued':
                return <HourglassOutlined className={cx(Styles.icon, Styles.slowRotate)} />;
            case 'canceled':
                return <StopOutlined className={Styles.icon} />;
            default:
                return <span />;
        }
    };

    const showStatus =
        status === 'done'
            ? tI('Done')
            : status === 'failed'
            ? tI('Failed')
            : status === 'enqueued'
            ? tI('In Queue')
            : status === 'in_progress'
            ? tI('In Progress')
            : status === 'canceled'
            ? tI('Canceled')
            : '';

    return (
        <div
            className={cx(Styles.statusPlace, {
                [Styles.done]: status === 'done',
                [Styles.enqueued]: status === 'enqueued',
                [Styles.in_progress]: status === 'in_progress',
                [Styles.failed]: status === 'failed',
                [Styles.canceled]: status === 'canceled',
            })}
            title={showStatus}
        >
            {icon()} {lightText(showStatus, search)}
        </div>
    );
};
