import { IItemAPI, IRelationshipItem } from './index';

export interface IOrderViewAPI {
    data?: IOrderViewItemAPI;
    included?: IItemAPI[];
}

export interface IOrderViewItemAPI extends IRelationshipItem {
    attributes: {
        payment_type?: string;
        product_type?: string;
        description?: string;
        cost?: string;
        status?: string;
        updated_at?: string;
        transaction_id?: string;
        notes?: string;
    };
    relationships?: {
        company?: {
            data: IRelationshipItem;
        };
        user?: {
            data: IRelationshipItem;
        };
        currency?: {
            data: IRelationshipItem;
        };
    };
}

export type IOrderView = {
    id: string;
    company?: string;
    companyId?: string;
    cost?: string;
    currency?: string;
    currencyId?: string;
    description?: string;
    notes?: string;
    paymentTypeId?: string;
    productType?: string;
    status?: string;
    transactionId?: string;
    updatedAt?: string;
    user?: string;
    userId?: string;
};

export type IGetOrder = {
    isError: boolean;
    order: IOrderView;
};

export type IPostOrderAPI = {
    data: {
        type: 'order';
        attributes: {
            cost?: number;
            product_type_id?: number;
            payment_type_id?: number;
        };
    };
};

export type IPostPackageOrderAttributes = {
    cost: number;
    paymentTypeId: number;
    companyId: number;
    options?: {
        renewal?: boolean;
    };
};

export type IPostOrderAttributes = {
    cost: number;
    description: string;
    productTypeId: number;
    paymentTypeId: number;
};

export type IOrderFormStatus = 'CREATED_ORDER' | 'REDIRECT';

export type IResponsePostOrderAPI = {
    data: {
        id: string;
        type: 'order';
        attributes: { cost: string; product_type: string; payment_type: string };
    };
};

export type IResponsePostCheckOutDataAPI = {
    id?: string;
    type?: 'response';
    attributes?: {
        payment_url?: string;
        paid?: string;
        payment_method?: string;
    };
};

export type IOrderExternalStatus = 'INIT' | 'NOT_GET_ORDER_ID' | 'FOUND_ORDER' | 'IS_FOUND' | 'NOT_FOUND';

export enum OrderBy {
    COMPANY = 'company',
    SUBSCRIPTION = 'subscription',
}
