import React from 'react';
import { observer } from 'mobx-react';
import { ESPlusNotificationQRcodesTable } from './ESPlusNotificationQRcodesTable';
import { useEsNotificationsFilterParams } from '../../../hooks';
import { cleanEsNotificationsStore } from '../../../middleware';
import { Catcher, DownloadFilesButtons, ListWrapper } from '../../../Components';

export const ESPlusNotificationQRcodesList: React.FC = observer(() => {
    const filterParams = useEsNotificationsFilterParams();
    const DownloadCSVFlow = (): JSX.Element => {
        return <DownloadFilesButtons csvType={'GET_ES_PLUS_NOTIFICATIONS_QR_CODES_LIST_CSV'} />;
    };

    return (
        <Catcher>
            <ListWrapper
                cleanStore={cleanEsNotificationsStore}
                filterParams={filterParams}
                RightButtons={DownloadCSVFlow}
            >
                <ESPlusNotificationQRcodesTable />
            </ListWrapper>
        </Catcher>
    );
});
