import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { IFilterParams, ISelectList, ISelectWithTotal, IUsersList } from '../../interfaces';

export function useUsersSelect(): ISelectList {
    return useContext(MobXProviderContext).RootStore.usersStore.select;
}

export function useUsersSelectWithTotal(): ISelectWithTotal {
    const select = useContext(MobXProviderContext).RootStore.usersStore.select;
    const total = useContext(MobXProviderContext).RootStore.usersStore.totalSelect;

    return {
        select,
        total,
    };
}

export function useUsersTable(): IUsersList {
    return useContext(MobXProviderContext).RootStore.usersStore.list;
}

export function useUsersFilterParams(): IFilterParams {
    return useContext(MobXProviderContext).RootStore.usersStore.filterParams;
}
