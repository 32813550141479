import React, { useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';
import { ChartWrapperOptions, ReactGoogleChartEvent } from 'react-google-charts/dist/types';
import { observer } from 'mobx-react';
import { Empty } from 'antd';
import { IChartData } from '../../../interfaces';
import { getCartColorTheme, useChartsSettings } from '../../../hooks';
import { IOnSelectRow, selectChartRow } from '../../../instruments/selectChartRow';
import { Catcher } from '../../Catcher';
import { ChartTypeSelector } from './../Components';
import Styles from '../m_dashboarStyles.less';

interface IBarChartProps {
    sourceData: IChartData;
    title?: string;
    titleLink?: JSX.Element | null;
    onSelectRow?: IOnSelectRow;
}

type IChartType = 'BarChart' | 'LineChart';

export const HorizontalRevenueChart: React.FC<IBarChartProps> = observer(
    ({ sourceData, title = '', titleLink = null, onSelectRow }: IBarChartProps) => {
        const chartEvents: Array<ReactGoogleChartEvent> = [];
        onSelectRow && chartEvents.push(selectChartRow(onSelectRow));
        const [selectedChartType, setSelectedChartType] = useState('bar');
        const [chartType, setChartType] = useState<IChartType>('BarChart');
        const { animation, annotationsOutside, chartBackground, hAxis, textColor, vAxis } = useChartsSettings();

        const optionsBar = {
            annotations: annotationsOutside,
            backgroundColor: chartBackground,
            legend: {
                position: 'top',
                maxLines: 2,
                textStyle: { color: textColor },
            },
            chartArea: {
                left: '30%',
                width: '60%',
                height: '75%',
            },
            colors: getCartColorTheme(sourceData),
            hAxis,
            vAxis,
            animation,
        };

        const [options, setOptions] = useState<Partial<ChartWrapperOptions>>(optionsBar);

        useEffect(() => {
            switch (selectedChartType) {
                case 'line':
                    setChartType('LineChart');
                    setOptions({
                        backgroundColor: chartBackground,
                        legend: {
                            position: 'top',
                            maxLines: 2,
                            textStyle: { color: textColor },
                        },
                        chartArea: {
                            width: '85%',
                            height: '75%',
                        },
                        colors: getCartColorTheme(sourceData),
                        hAxis,
                        vAxis: {
                            ...vAxis,
                            minValue: 0,
                        },
                        animation,
                    });
                    break;
                default:
                    setChartType('BarChart');
                    setOptions(optionsBar);
                    break;
            }
        }, [selectedChartType, textColor]);

        return (
            <Catcher>
                <div className={Styles.chartTitle}>{titleLink ? titleLink : title}</div>
                <div className={Styles.chartTypeSelector}>
                    <ChartTypeSelector
                        defaultChart={selectedChartType}
                        onChangeChartType={setSelectedChartType}
                        availableTypes={['bar', 'line']}
                    />
                </div>

                {sourceData.length < 2 ? (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                ) : (
                    <Chart
                        chartEvents={chartEvents}
                        data={sourceData}
                        height={'100%'}
                        width={'100%'}
                        chartType={chartType}
                        options={options}
                    />
                )}
            </Catcher>
        );
    },
);
