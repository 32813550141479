import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Checkbox, Form, Modal } from 'antd';
import { Rule } from 'antd/es/form';
import { DeleteOutlined } from '@ant-design/icons';
import { SubscribersTable } from './';
import cx from 'classnames';
import { FieldData } from 'rc-field-form/es/interface';
import { IAntDFormChangeFieldName, IEditFormComponentInfo, IFormFields, IGetApiType } from '../../../interfaces';
import { useCardInfo, useProfileIsRight, useRouterStore } from '../../../hooks';
import {
    addNotification,
    apiMessagesDeleteMessageByFieldName,
    apiMessagesGetFormRules,
    apiMessagesSaveMessages,
    deleteCard,
    deleteCardSubscribers,
    getCardBySubscriberId,
    patchCard,
    rulesIsNumber,
} from '../../../middleware';
import { cleanPathFields } from '../../../instruments';
import { tB, tF } from '../../../translate';
import {
    CardInfoToolBar,
    CardMainLayout,
    Catcher,
    InputDebounce,
    TextAreaDebounce,
    cardLayoutOptions,
} from '../../../Components';
import Styles from '../../styles/m_itemCardStyles.less';

export const EditCardInfo: React.FC<IEditFormComponentInfo> = observer(({ setEditMode }: IEditFormComponentInfo) => {
    const [isSending, setSending] = useState(false);
    const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    const [enabledUsageLimit, setEnabledUsageLimit] = useState<boolean | undefined>();
    const routerStore = useRouterStore();
    const isLord = useProfileIsRight('LORD');
    const isAdmin = useProfileIsRight('ADMIN');
    const componentSpinners: IGetApiType[] = ['GET_CARD'];
    const [form] = Form.useForm();
    const { isHashed, ...initialValues } = useCardInfo();
    const isNew = !initialValues.id;

    const showModalDelete = (): void => {
        setIsModalDeleteVisible(true);
    };

    const handleDelete = async (): Promise<void> => {
        const deleteResp = await deleteCardSubscribers(initialValues.id, selectedRowKeys);
        if (deleteResp.isError) {
            addNotification({
                type: 'error',
                message: 'An error has occurred while deleting subscribers',
                isUsersError: true,
            });
        }

        const getSubResp = await getCardBySubscriberId(initialValues.id);

        if (getSubResp.isError) {
            addNotification({
                type: 'error',
                message: 'An error has occurred while fetching subscribers',
                isUsersError: true,
            });
        }

        const subCount = Number(getSubResp?.resp?.meta?.records_count);

        if (subCount <= 0) {
            const { isError } = await deleteCard(initialValues.id);

            if (isError) {
                addNotification({
                    type: 'error',
                    message: 'An error has occurred while deleting the card',
                    isUsersError: true,
                });
            }

            routerStore.goTo('CardList');
        }

        setIsModalDeleteVisible(false);
    };

    const handleCancel = (): void => {
        setIsModalDeleteVisible(false);
    };

    const checkValid = (): void => {
        form.validateFields();
    };

    const toggleEnabledUsageLimit = (): void => {
        setEnabledUsageLimit((prev) => {
            const value = !prev;
            if (!value) {
                form.setFieldsValue({ usageLimit: '' });
            }
            return value;
        });
    };

    useEffect(() => {
        form.setFieldsValue({
            ...initialValues,
            enabledUsageLimit: Boolean(initialValues.usageLimit),
        });
        setEnabledUsageLimit(Boolean(initialValues.usageLimit));
        checkValid();
    }, [JSON.stringify(initialValues)]);

    const onFieldsChange = (changedFields: FieldData[]): void => {
        const fieldName = changedFields && changedFields[0] && (changedFields[0].name as IAntDFormChangeFieldName);

        if (fieldName) {
            apiMessagesDeleteMessageByFieldName(fieldName);
        }
        if (!form.getFieldValue('imei') && !!form.getFieldValue('lockImei')) {
            form.setFieldsValue({ lockImei: false });
        }
    };

    const onFinish = async (fields: IFormFields): Promise<void> => {
        setSending(true);
        const cleanFields = cleanPathFields(fields, initialValues, ['usageLimit']);
        const { isError, errors } = await patchCard(initialValues.id, cleanFields);

        if (isError) {
            apiMessagesSaveMessages(errors);
            checkValid();
        } else {
            setEditMode && setEditMode(false);
        }
        setSending(false);
    };

    const validateNumber: Array<Rule> = [rulesIsNumber, ...apiMessagesGetFormRules];

    const nameTitle = tF('Name');
    const printedId = tF('Printed ID');
    const nameEID = tF('EID');
    const nameIMEI = tF('IMEI');
    const nameUsageLimit = tF('Usage Limit');
    const nameNotes = tF('Notes');
    const nameBatchId = tF('Batch Id');

    return (
        <Catcher>
            <CardMainLayout
                columnOption={cardLayoutOptions.cardInfo}
                idSpinners={componentSpinners}
                isEdit
                name={initialValues.name}
            >
                <Form
                    form={form}
                    name="cardInfo"
                    initialValues={initialValues}
                    onFieldsChange={onFieldsChange}
                    onFinish={onFinish}
                    scrollToFirstError={true}
                    layout={'vertical'}
                >
                    <Form.Item name="name" label={nameTitle} rules={apiMessagesGetFormRules}>
                        <InputDebounce placeholder={nameTitle} isFirstInput disabled={isHashed} />
                    </Form.Item>
                    {isLord ? (
                        <Form.Item name="plmnList" label={tF('PLMN List')} rules={apiMessagesGetFormRules}>
                            <InputDebounce placeholder="PLMN" />
                        </Form.Item>
                    ) : null}
                    {isLord ? (
                        <Form.Item name="batchId" label={nameBatchId} rules={validateNumber}>
                            <InputDebounce placeholder={nameBatchId} delay={0} />
                        </Form.Item>
                    ) : null}
                    {isAdmin ? (
                        <>
                            <Form.Item name="printedId" label={printedId} rules={apiMessagesGetFormRules}>
                                <InputDebounce placeholder={printedId} delay={0} />
                            </Form.Item>
                            <Form.Item name="eid" label={nameEID} rules={apiMessagesGetFormRules}>
                                <InputDebounce placeholder={nameEID} delay={0} />
                            </Form.Item>
                            <Form.Item name="imei" label={nameIMEI} rules={apiMessagesGetFormRules}>
                                <InputDebounce placeholder={nameIMEI} delay={0} />
                            </Form.Item>
                            <Form.Item name="lockImei" valuePropName="checked" rules={apiMessagesGetFormRules}>
                                <Checkbox>{tF('Locked IMEI')}</Checkbox>
                            </Form.Item>
                            {(isLord || (isAdmin && initialValues.limitChangeAllowed)) && (
                                <>
                                    <Form.Item name="usageLimit" label={nameUsageLimit} rules={validateNumber}>
                                        <InputDebounce
                                            type="number"
                                            disabled={!enabledUsageLimit}
                                            placeholder={tF('Usage Limit Placeholder')}
                                            delay={0}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="enabledUsageLimit"
                                        valuePropName="checked"
                                        rules={apiMessagesGetFormRules}
                                    >
                                        <Checkbox onChange={toggleEnabledUsageLimit} checked={enabledUsageLimit}>
                                            {tF('Enabled Usage Limit')}
                                        </Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        name="limitForSubCompaniesAllowed"
                                        valuePropName="checked"
                                        rules={apiMessagesGetFormRules}
                                    >
                                        <Checkbox>{tF('Edit allowed for sub-companies')}</Checkbox>
                                    </Form.Item>
                                </>
                            )}
                            <Form.Item name="notes" label={nameNotes} rules={apiMessagesGetFormRules}>
                                <TextAreaDebounce placeholder={nameNotes} autoSize={true} delay={0} />
                            </Form.Item>
                        </>
                    ) : null}
                    <CardInfoToolBar isLoading={isSending} isNew={isNew} isSubmitButton>
                        {isLord ? (
                            <Button
                                type="primary"
                                icon={<DeleteOutlined />}
                                danger
                                onClick={showModalDelete}
                                className={cx(Styles.mainButton)}
                            >
                                {tB('Delete')}
                            </Button>
                        ) : null}
                    </CardInfoToolBar>
                </Form>
            </CardMainLayout>
            <Modal
                title="Select subscribers for delete"
                open={isModalDeleteVisible}
                wrapClassName={'modal'}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" type="primary" onClick={handleCancel}>
                        {tB('Cancel')}
                    </Button>,
                    <Button key="submit" onClick={handleDelete}>
                        {tB('Delete')}
                    </Button>,
                ]}
                width={1000}
            >
                <SubscribersTable selectedRowKeys={selectedRowKeys} setSelectedRowKeys={setSelectedRowKeys} />
            </Modal>
        </Catcher>
    );
});
