import React, { useEffect } from 'react';
import { CardDeviceFilesTable, UploadCardDeviceFile } from './';
import { getCardDeviceFiles } from '../../../middleware';
import { Catcher } from '../../Catcher';
import Styles from '../../styles/m_itemCardStyles.less';

export const UploadCardDevice: React.FC = () => {
    useEffect(() => {
        getCardDeviceFiles();
    }, []);

    return (
        <Catcher>
            <div className={Styles.main}>
                <UploadCardDeviceFile />
                <CardDeviceFilesTable />
            </div>
        </Catcher>
    );
};
