import React, { MouseEvent } from 'react';
import { observer } from 'mobx-react';
import { Catcher, LoadingApiByIds } from '../../';
import { HorizontalRevenueChart } from './';
import { useDashboardTelecomRevenueChart, useProfile, useRouterStore } from '../../../hooks';
import { tI, tP } from '../../../translate';
import Styles from '../m_dashboarStyles.less';

export const TelecomRevenue: React.FC = observer(() => {
    const data = useDashboardTelecomRevenueChart();
    const routerStore = useRouterStore();
    const { isCompanyTypeIdMNOHost } = useProfile();
    const title = `${tP('Top Operator')} ${tI(isCompanyTypeIdMNOHost ? 'by Revenue' : 'by Cost/Revenue')}`;
    const goToTelecomsList = (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>): void => {
        event.preventDefault();
        routerStore.goTo('TelecomsList');
    };

    const titleLink = (): JSX.Element => (
        <a onClick={goToTelecomsList} href={`/operators`}>
            {title}
        </a>
    );

    const goToTelecom = (id: string): void => {
        routerStore.goTo('Operator', { params: { id } });
    };

    return (
        <Catcher>
            <LoadingApiByIds idSpinners={['GET_REVENUE_TELECOM_LIST']} />
            <div className={Styles.chart}>
                <HorizontalRevenueChart
                    sourceData={data}
                    title={title}
                    titleLink={titleLink()}
                    onSelectRow={goToTelecom}
                />
            </div>
        </Catcher>
    );
});
