import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Catcher } from '../';
import { IGetApiType } from '../../REST';
import { IShowFormComponent } from '../../interfaces';
import { useCardSetSpinners, useRouterStore } from '../../hooks';
import {
    abortSelectedFetch,
    cleaCardSetStore,
    cleanCallDataRecordsStore,
    cleanCardSetRelationSelect,
    createCardSetInfo,
    getCardSet,
    getCardSetEditRelationSelect,
    getCardSetShowRelationSelect,
} from '../../middleware';
import { EditCardSetIForm, ShowCardSet } from './Components';

export const CardSet: React.FC<IShowFormComponent> = observer(({ isEdit, isNew }: IShowFormComponent) => {
    const routerStore = useRouterStore();
    const [id, setId] = useState('');
    const usedApiTypes: IGetApiType[] = useCardSetSpinners();

    const goOut = (): void => {
        abortSelectedFetch(usedApiTypes);
        cleaCardSetStore();
        cleanCardSetRelationSelect();
        cleanCallDataRecordsStore();
    };

    const goToList = (): void => {
        routerStore.goTo('CardSetsList');
        goOut();
    };

    const _getCardSet = async (id: string): Promise<void> => {
        if (!isEdit && !isNew) {
            getCardSetShowRelationSelect(id);
        }
        const isGetCardSet = await getCardSet(id);
        if (!isGetCardSet) {
            goToList();
        }
    };

    useEffect(() => {
        if (isNew) {
            createCardSetInfo();
        }
        if (isEdit || isNew) {
            getCardSetEditRelationSelect();
        }
        return (): void => {
            goOut();
        };
    }, []);

    useEffect(() => {
        if (!isEdit && !isNew && id) {
            _getCardSet(id);
        } else if (id) {
            _getCardSet(id);
        }
    }, [isEdit, isNew, id]);

    useEffect(() => {
        if (
            routerStore.routerState.params.id &&
            (routerStore.routerState.routeName === 'CardSet' || routerStore.routerState.routeName === 'CardSetEdit')
        ) {
            setId(routerStore.routerState.params.id);
        }
    }, [routerStore.routerState.params.id]);

    const setEdit = (isEdit?: boolean): void => {
        if (isNew) {
            goToList();
        } else if (isEdit) {
            abortSelectedFetch(usedApiTypes);
            routerStore.goTo('CardSetEdit', { params: { id } });
            getCardSetEditRelationSelect();
        } else {
            abortSelectedFetch(usedApiTypes);
            if (id) {
                _getCardSet(id);
                getCardSetShowRelationSelect(id);
                routerStore.goTo('CardSet', { params: { id } });
            }
        }
    };

    return (
        <Catcher>
            {isEdit || isNew ? (
                <EditCardSetIForm setEditMode={setEdit} isNew={isNew} />
            ) : (
                <ShowCardSet setEditMode={setEdit} />
            )}
        </Catcher>
    );
});
