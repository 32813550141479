import React from 'react';
import { Catcher, Loading } from '../../';
import { GoogleMap, GoogleMapProps, useJsApiLoader } from '@react-google-maps/api';
import { appConfig } from '../../../settings';
import { useGetDefaultMapCenter } from '../../../hooks';
import Styles from '../m_googleMapsStyles.less';

const Map = ({ children, center, zoom = 2, options, ...props }: GoogleMapProps) => {
    const { isLoaded } = useJsApiLoader(appConfig.googleMapInit);
    const location = useGetDefaultMapCenter();

    return (
        <Catcher>
            {isLoaded ? (
                <GoogleMap
                    center={center || location}
                    options={{ ...appConfig.googleMapOptions, ...options }}
                    mapContainerClassName={Styles.baseMap}
                    zoom={zoom}
                    {...props}
                >
                    {children}
                </GoogleMap>
            ) : (
                <Loading isLoading={true} />
            )}
        </Catcher>
    );
};

export default React.memo(Map);
