import { CardSetsStore } from '../stores/cardSets';
import { makeSelectList, setUiTotal } from './';
import { cleanCompaniesStore, getCompaniesSelect } from './companies';
import { IBodyListResponse, apiManager } from '../REST';
import { ICardSetsAPI, ICardSetsItemAPI, ICardSetsListItem, IItemAPI, IParamsList, ISelectList } from '../interfaces';
import { mapApi2UiField } from '../instruments';

export function setCardSetsList(resp: ICardSetsAPI): void {
    const CardSetsStoreInstance = CardSetsStore.getInstance();
    const companies = resp?.included?.filter((i: IItemAPI) => i.type === 'company');
    const assignedCompanies = resp?.included?.filter((i: IItemAPI) => i.type === 'assigned_company');
    const cardSetList: ICardSetsListItem[] =
        resp?.data?.map((s: ICardSetsItemAPI) => {
            const companyId = s.relationships?.company?.data?.id;
            const company = companies?.find((i: IItemAPI) => i.id === companyId)?.attributes?.name;
            const assignedCompanyId = s.relationships?.assigned_company?.data?.id;
            const assignedCompany = assignedCompanies?.find((i: IItemAPI) => i.id === assignedCompanyId)?.attributes
                ?.name;
            return {
                id: s.id,
                ...mapApi2UiField(s.attributes),
                companyId,
                company,
                assignedCompanyId,
                assignedCompany,
            };
        }) || [];

    CardSetsStoreInstance.setCardSetsList(cardSetList);
}

export function getCardSetsRelationSelect(): void {
    getCompaniesSelect();
}

export async function getCardSetsList(params: IParamsList): Promise<void> {
    const { resp } = await apiManager.getApi('GET_CARD_SETS_LIST', { searchParamsList: params });
    const { data, meta } = resp;
    meta && setUiTotal(meta.records_count);
    data && setCardSetsList(resp as ICardSetsAPI);
}

function setCardSetsSelect(apiList: IBodyListResponse, isOnlyAvailable?: boolean): void {
    const CardSetsStoreInstance = CardSetsStore.getInstance();
    CardSetsStoreInstance.setCardSetsSelect(makeSelectList(apiList, isOnlyAvailable));
}

export async function getCardSetsSelect(params: IParamsList, isOnlyAvailable?: boolean): Promise<void> {
    const { resp } = await apiManager.getApi('GET_CARD_SETS_SELECT', { searchParamsList: params });
    resp?.data && setCardSetsSelect(resp as IBodyListResponse, isOnlyAvailable);
}

export function setCardSetsForcedUpdateTime(): void {
    const CardSetsStoreInstance = CardSetsStore.getInstance();
    CardSetsStoreInstance.setForcedUpdateTime();
}

export function cleanCardSetsStore(): void {
    cleanCompaniesStore();
    const CardSetsStoreInstance = CardSetsStore.getInstance();
    CardSetsStoreInstance.cleanStore();
}

export function mwCardSetsSelect(): ISelectList {
    const CardSetsStoreInstance = CardSetsStore.getInstance();
    return CardSetsStoreInstance.select;
}
