import appInfo from '../application.json';
import { objIsNotEmpty } from './index';
import AppSignal from '@appsignal/javascript';
import { Span } from '@appsignal/javascript/dist/cjs/span';
import { HashMapValue } from '@appsignal/types';
import { HashMap } from '@appsignal/types/dist/cjs/types/common';
import { getAppSignalKey, getProfileUser } from '../middleware';

export type IAppSignalTags = HashMap<string>;
export type IParams = HashMap<HashMapValue>;
export type IAppSignalParams = HashMap<any>;
type IAppSignalSendMessage = {
    action?: string;
    appSignalType?: string;
    description?: string;
    error?: Error;
    message?: string;
    params?: IAppSignalParams;
    responseAPI?: Response;
    tags?: IAppSignalTags;
};

const response2Tags = ({ url, status, statusText }: Response): HashMap<string> => {
    return { url, status: status.toString(), statusText };
};

class AppSignalAPIError extends Error {
    constructor({ url, status, statusText }: Response) {
        super(`${statusText} - Status:${status}`);
        this.name = 'API Error';
        this.stack = url;
    }
}
class AppSignalMessageError extends Error {
    constructor(message: string) {
        super(message);
        this.name = message;
        this.stack = '';
    }
}

type IRightAppSignalSendMessage = {
    action: string;
    tags?: IAppSignalTags;
};

export class RightError extends Error {
    constructor(message: string, cause?: unknown) {
        super(message);
        this.cause = cause;
        this.name = 'Not enough right';
        this.stack = new Error().stack;
    }
}

export const rightAppSignalSendMessage = ({ action, tags }: IRightAppSignalSendMessage): void => {
    appSignalSendMessage({
        action,
        error: new RightError(action),
        tags,
    });
};

export const appSignalSendMessage = ({
    action,
    appSignalType,
    description,
    error,
    message,
    params = {},
    responseAPI,
    tags,
}: IAppSignalSendMessage): void => {
    const appSignal = new AppSignal({
        key: getAppSignalKey(),
        namespace: process.env.NODE_ENV,
        revision: appInfo.version,
    });
    const _params: IParams = {};
    const { email, role, company } = getProfileUser();
    const span: Span = appSignal.createSpan();
    if (params) {
        for (const key in params) {
            _params[key] = params[key].toString();
        }
    }
    if (appSignalType) {
        _params.appSignalType = appSignalType;
    }
    if (description) {
        _params.description = description;
    }
    if (objIsNotEmpty(_params)) {
        span.setParams(_params);
    }
    span.setTags({ href: window.location.href });
    company && span.setTags({ company: company });
    email && span.setTags({ user: email });
    role && span.setTags({ role });
    message && span.setTags({ message });
    tags && span.setTags(tags);
    responseAPI && span.setTags(response2Tags(responseAPI));
    action && span.setAction(action);
    if (error) {
        span.setError(error);
    } else if (responseAPI) {
        span.setError(new AppSignalAPIError(responseAPI));
    } else if (message) {
        span.setError(new AppSignalMessageError(message));
    }
    appSignal.send(span);
};
