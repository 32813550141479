import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { IComponentName, IRouterState } from '../interfaces';
import {
    useAuthAppStatus,
    usePagesStoreCurrentComponent,
    usePagesStoreCurrentPage,
    useRouterStore,
    useRouterStoreUseExternalPageTitleManager,
    useRouterStoreUseExternalUrlManager,
} from '../hooks';
import { authCheckInitialized, setRouterStoreCurrentComponent, setRouterStoreRequestedUrl } from '../middleware';
import { equalObjects } from '../instruments';

export const MainUrlManager: React.FC = observer(() => {
    const routerStore = useRouterStore();
    const { routerState } = routerStore;
    const currentComponent = usePagesStoreCurrentComponent();
    const { title, componentName } = usePagesStoreCurrentPage();
    const appStatus = useAuthAppStatus();
    const isUseExternalUrlManager = useRouterStoreUseExternalUrlManager();
    const isUseExternalPageTitleManager = useRouterStoreUseExternalPageTitleManager();

    useEffect(() => {
        setRouterStoreRequestedUrl(window.location.href);
        authCheckInitialized(routerState as IRouterState);
    }, []);

    useEffect(() => {
        if (routerState.routeName !== currentComponent.routeName) {
            setRouterStoreCurrentComponent({
                routeName: routerState.routeName as IComponentName,
                params: routerState.params,
                queryParams: routerState.queryParams,
            });
        }
    }, [routerState]);

    useEffect(() => {
        if (!isUseExternalUrlManager && !equalObjects(routerStore.routerState, currentComponent)) {
            routerStore
                .goTo(currentComponent.routeName, {
                    params: { ...currentComponent.params },
                    queryParams: { ...currentComponent.queryParams },
                })
                .then();
        }
    }, [appStatus, currentComponent]);

    useEffect(() => {
        if (title && !isUseExternalPageTitleManager && document.title !== title) {
            document.title = title;
        }
    }, [componentName, isUseExternalPageTitleManager]);

    return null;
});
