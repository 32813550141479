import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import { Catcher, Loading } from '../../';
import { EditRateZoneList } from './';
import { tableColumns } from './';
import cx from 'classnames';
import { ITempTableDataSourceDefaultItem } from '../../../interfaces';
import { useCountryListIsLoading, useOperatorsSelect, useZonesSelect } from '../../../hooks';

export const EditRateOperatorsList: React.FC = observer(() => {
    const [expandedRowKeys, setExpandedRowKeys] = useState<Array<string | number>>([]);
    const operatorsSelect = useOperatorsSelect();
    const isLoading = useCountryListIsLoading();
    const zonesCount = useZonesSelect().length.toString();

    const dataSource = operatorsSelect.map((o) => ({ key: o.id, operator: `${o.name} (${zonesCount})` }));

    const expandedRowRender = (record: ITempTableDataSourceDefaultItem): JSX.Element => {
        return <EditRateZoneList operatorId={record.key} />;
    };

    const onExpand = (isExpand: boolean, record: ITempTableDataSourceDefaultItem): void => {
        if (isExpand) {
            setExpandedRowKeys([record.key]);
        } else {
            setExpandedRowKeys([]);
        }
    };

    const expandable = {
        expandedRowKeys,
        expandedRowRender,
        onExpand,
        rowExpandable: (): boolean => !!zonesCount,
    };

    return (
        <Catcher>
            <Loading isLoading={isLoading} />
            <Table
                className={cx('sub-table-rate')}
                columns={tableColumns(3)}
                dataSource={dataSource}
                expandable={expandable}
                pagination={false}
                size={'small'}
            />
        </Catcher>
    );
});
