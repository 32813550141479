import React from 'react';
import { observer } from 'mobx-react';
import { Divider, Row } from 'antd';
import { CardInfoToolBar, CardMainLayout, Catcher, cardLayoutOptions } from '../../';
import { PackageSoldInfoBox } from './';
import { IShowForm } from '../../../interfaces';
import { usePackageSoldInfo, useProfile, useProfileIsRight } from '../../../hooks';
import Styles from '../../styles/m_itemCardStyles.less';

export const ShowPackageInstance: React.FC<IShowForm> = observer(({ setEditMode }: IShowForm) => {
    const isAdmin = useProfileIsRight('ADMIN');
    const { companyId: myCompanyId } = useProfile();
    const isLord = useProfileIsRight('LORD');
    const packageSoldInfo = usePackageSoldInfo();
    const { companyName, companyId, isExpired, creditRemains, dataVolume } = packageSoldInfo;
    const isAvailableForDelete = isExpired && (!creditRemains || (!!dataVolume && dataVolume <= 0));

    return (
        <Catcher>
            <article className={Styles.main}>
                <Row>
                    <CardMainLayout
                        columnOption={cardLayoutOptions.cardInfoEdit}
                        idSpinners={['GET_ONE_SOLD_PACKAGE', 'GET_ONE_PACKAGE_INSTANCE']}
                        name={companyName}
                    >
                        <PackageSoldInfoBox packageSoldInfo={packageSoldInfo} />
                        <Divider />
                        <CardInfoToolBar
                            dataTest={'ShowPackageInstance'}
                            setEditMode={
                                (!isAvailableForDelete && isLord) || (isAdmin && companyId === myCompanyId)
                                    ? setEditMode
                                    : undefined
                            }
                            isBackToList
                        />
                    </CardMainLayout>
                </Row>
            </article>
        </Catcher>
    );
});
