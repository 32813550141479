import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Form, Row } from 'antd';
import { FieldData } from 'rc-field-form/es/interface';
import { appConfig } from '../../../settings';
import { IEditForm, IUseRateForm } from '../../../interfaces';
import { useIncludeAssignedCompaniesActive, useRateInfo } from '../../../hooks';
import { apiMessagesSaveMessages, cleanApiMessages, sendRateData, setRateForm } from '../../../middleware';
import { debounce, onFormFieldsChange } from '../../../instruments';
import { Catcher, CompaniesAssignmentsEdit, cardLayoutOptions } from '../../../Components';
import { EditRateFieldsInfo, EditRateFieldsMrcNrc } from './../Components';

export const EditRareFormMain: React.FC<IEditForm> = observer(({ setEditMode, isNew = false }: IEditForm) => {
    const initialInfo = useRateInfo();
    const initAssignedCompanies = useIncludeAssignedCompaniesActive();
    const idsAssignedCompanies = initAssignedCompanies.map((c) => c.id);
    const initialValues: IUseRateForm = { ...initialInfo, assignedCompanies: initAssignedCompanies };
    const [form] = Form.useForm();
    const [isSending, setSending] = useState(false);

    const checkValid = (): void => {
        form.validateFields();
    };

    useEffect(() => {
        const initialValues: IUseRateForm = { ...initialInfo, assignedCompanies: initAssignedCompanies };
        form.setFieldsValue(initialValues);
        checkValid();
    }, [initialInfo, JSON.stringify(initAssignedCompanies)]);

    const onFieldsChange = (changedFields: FieldData[]): void => {
        onFormFieldsChange({
            changedFields,
            initialValues,
            listIds: { assignedCompanies: idsAssignedCompanies },
            setForm: setRateForm,
            checkValid,
        });
    };

    const saveRate = async (): Promise<void> => {
        cleanApiMessages();
        setSending(true);
        const { isError, errors = [] } = await sendRateData(isNew);
        if (isError) {
            apiMessagesSaveMessages(errors);
            checkValid();
        } else {
            setEditMode && setEditMode(false);
        }

        setSending(false);
    };

    return (
        <Catcher>
            <Form
                form={form}
                name="salesRateList"
                initialValues={initialValues}
                onFieldsChange={debounce(onFieldsChange, appConfig.delayFormUpdateState)}
                scrollToFirstError={true}
                layout={'vertical'}
                className={'formStyle'}
            >
                <Row>
                    <EditRateFieldsInfo setEditMode={setEditMode} save={saveRate} isSending={isSending} isNew={isNew} />
                    <EditRateFieldsMrcNrc />
                    <CompaniesAssignmentsEdit columnOption={cardLayoutOptions.cardTableFull} />
                </Row>
            </Form>
        </Catcher>
    );
});
