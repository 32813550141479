import { UsersStore } from '../stores/users';
import { getCompaniesSelect } from './companies';
import { setUiTotal } from './ui';
import { apiManager } from '../REST';
import {
    IGetApiResponse,
    IIncludeUserItemAPI,
    IItemAPI,
    IParamsList,
    IRequestAPIBody,
    IUsersListAPI,
} from '../interfaces';
import { getAPIUserFullName, mapApi2UiField, sortByName } from '../instruments';

function setUsersStore(apiUsers: IUsersListAPI): void {
    if (apiUsers.data) {
        const included: IItemAPI[] = apiUsers?.included || [];
        const UsersInstance = UsersStore.getInstance();
        UsersInstance.setUsersList(
            apiUsers.data.map((user: IIncludeUserItemAPI) => {
                const companyId = user.relationships?.company?.data?.id;
                const companyName = included.find((i) => i.type === 'company' && i.id === companyId)?.attributes.name;
                const userTypeId = user.relationships?.user_type?.data?.id;
                const userType = included.find((i) => i.type === 'user_type' && i.id === userTypeId)?.attributes.name;
                return {
                    id: user.id,
                    companyId,
                    companyName,
                    userType,
                    ...mapApi2UiField(user?.attributes ? user.attributes : {}),
                };
            }),
        );
    }
}

export function setUsersForcedUpdateTime(): void {
    const UsersInstance = UsersStore.getInstance();
    UsersInstance.setForcedUpdateTime();
}

export async function getUsersList(params: IParamsList): Promise<void> {
    const { resp } = await apiManager.getApi('GET_USERS_LIST', { searchParamsList: params });
    resp && setUsersStore(resp as IUsersListAPI);
    const { meta } = resp;
    meta && setUiTotal(meta.records_count);
}

export function setUsersSelectStore(apiUsers: IUsersListAPI): void {
    if (apiUsers.data) {
        const UsersInstance = UsersStore.getInstance();
        const list = apiUsers.data
            .map((user: IIncludeUserItemAPI) => {
                return {
                    id: user.id,
                    name: getAPIUserFullName(user),
                };
            })
            .filter((user) => user.id)
            .map((user) => ({ id: user.id, name: user.name ? user.name : user.id }))
            .sort(sortByName);

        UsersInstance.setUsersSelect(list, apiUsers.meta?.records_count);
    }
}

export async function getUsersByCompanySelect(companyId: string): Promise<void> {
    const { resp } = await apiManager.getApi('GET_USERS_BY_COMPANY_SELECT', { searchParamsList: { companyId } });
    resp && setUsersSelectStore(resp as IUsersListAPI);
}

export async function getUsersSelect(params?: IParamsList): Promise<void> {
    const { resp } = await apiManager.getApi('GET_USERS_LIST', { searchParamsList: params });
    resp && setUsersSelectStore(resp as IUsersListAPI);
}

export function cleanUsersStore(): void {
    const UsersInstance = UsersStore.getInstance();
    UsersInstance.cleanStore();
}

export async function deleteUser(id: string): Promise<void> {
    const { isError } = await apiManager.getApi('DELETE_USER', { id });
    if (!isError) {
        setUsersForcedUpdateTime();
    }
}

export async function impersonateUser(id: string): Promise<IGetApiResponse> {
    const body = {
        user_id: id,
    } as IRequestAPIBody;

    return await apiManager.getApi('POST_USER_IMPERSONATION', {}, body);
}

export async function disableUserImpersonation(): Promise<IGetApiResponse> {
    return await apiManager.getApi('DELETE_USER_IMPERSONATION');
}

export async function getUsersRelationSelect(): Promise<void> {
    getCompaniesSelect();
}
