import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Col, Row } from 'antd';
import { CardLayout, Catcher, cardLayoutOptions } from '../../';
import { GeoTrackingSearch, GeoTrackingTrackFilter } from './';
import dayjs from 'dayjs';
import {
    useGeotrackingCardList,
    useGeotrackingTrackSearchParams,
    useGeotrackingTrackedCardId,
    useProfile,
} from '../../../hooks';
import {
    getCardSetsSelect,
    getGeotrackingCardLocationHistory,
    setGeoTrackingTrackSearchParams,
} from '../../../middleware';
import Styles from '../m_geoTrackingStyles.less';

export const GeoTrackingFilterBar = observer(() => {
    const { companyId = '' } = useProfile();
    const id = useGeotrackingTrackedCardId();
    const geolocationCardList = useGeotrackingCardList();
    const searchParams = useGeotrackingTrackSearchParams();

    const handleHistoryLocationFetch = async (): Promise<void> => {
        const newDate = { ...searchParams };
        if (newDate.startDate === undefined && newDate.endDate === undefined) {
            const date = geolocationCardList.find((value) => {
                return value.id === id;
            })?.createdAt;
            newDate.startDate = dayjs(date).subtract(1, 'days').startOf('day').format().toString();
            newDate.endDate = dayjs(date).format().toString();
            setGeoTrackingTrackSearchParams(newDate);
        } else if (newDate.startDate && newDate.endDate) {
            await getGeotrackingCardLocationHistory({
                id,
                startDate: newDate.startDate,
                endDate: newDate.endDate,
            });
        }
    };

    useEffect(() => {
        getCardSetsSelect({ companyId }, true);
    }, [companyId]);

    useEffect(() => {
        if (id) {
            handleHistoryLocationFetch();
        }
    }, [id, searchParams]);

    return (
        <Catcher>
            <CardLayout
                columnOption={cardLayoutOptions.cardTableFull}
                title={'Geo-tracking'}
                idSpinners={[]}
                isMainCard
            >
                <div className={Styles.geoTrackingFilterBar}>
                    <Row gutter={[24, 24]}>
                        <Col md={12} sm={24} xs={24}>
                            <GeoTrackingSearch />
                        </Col>
                        <Col md={12} sm={24} xs={24}>
                            <GeoTrackingTrackFilter />
                        </Col>
                    </Row>
                </div>
            </CardLayout>
        </Catcher>
    );
});
