import { ReactNode } from 'react';
import { IGeoLocation } from './geotracking';
import { IIncludeTypeAPI } from './included';
import { IComponentName } from './pages';
import { IGetApiType } from '../REST';
import { ITrPage } from '../translate';

export * from '../REST/types';
export * from './activityLog';
export * from './and';
export * from './apiMessages';
export * from './auth';
export * from './balanceLog';
export * from './callDataRecords';
export * from './card';
export * from './cardSet';
export * from './cardSets';
export * from './catalogs';
export * from './companies';
export * from './company';
export * from './countries';
export * from './country';
export * from './currencies';
export * from './dashboard';
export * from './diameterSessions';
export * from './esNotifications';
export * from './externalAPIAPI';
export * from './externalAPIDic';
export * from './externalAPIFront';
export * from './featureFlagsList';
export * from './form';
export * from './geotracking';
export * from './htmlComponents';
export * from './included';
export * from './layout';
export * from './myAlarm';
export * from './operatorInteractions';
export * from './operators';
export * from './order';
export * from './orders';
export * from './package';
export * from './packages';
export * from './packagesUnitsLogs';
export * from './pages';
export * from './purchase';
export * from './purchases';
export * from './rate';
export * from './rates';
export * from './reports';
export * from './router';
export * from './subscriber';
export * from './subscribers';
export * from './table';
export * from './typeAny';
export * from './ui';
export * from './user';
export * from './users';
export * from './window';
export * from './zones';

export type IRoutes = IRoute[];

export interface IRoute {
    name: string;
    pattern: string;
}

export type INameObject = {
    id?: string;
    name?: string;
};

export type INumberOrUndefined = number | undefined;
export type IFilterSelect = string | undefined;
export type IFilterMultipleSelect = string[];
export type IFilterSelectComposed = IFilterSelect | IFilterMultipleSelect;
export type IFilterBoolean = boolean | undefined;
export type IExportType = 'csv' | 'pdf';

export enum SelectType {
    SINGLE = 'single',
    MULTIPLE = 'multiple',
    TAGS = 'tags',
}

export type IFilterParams = {
    cardSetId?: boolean;
    commandId?: boolean;
    companyId?: boolean;
    companyTypeId?: boolean;
    dateRange?: boolean;
    defaultDateRangeType?: 'month' | 'day';
    entityId?: boolean;
    exportType?: IExportType;
    fileId?: boolean;
    gatewayId?: boolean;
    isActiveThisMonth?: boolean;
    isCardSetIdBlockClear?: boolean;
    isCompanyIdBlockClear?: boolean;
    isCompanyTypeIdBlockClear?: boolean;
    isDateRangeCleanedDefaultURL?: boolean;
    isDateRangeMaxDayToDay?: boolean;
    isDateRangeMinDayOn?: boolean;
    isDelete?: boolean;
    isMaxDateRange?: boolean;
    isOperatorIdBlockClear?: boolean;
    isPackageIdBlockClear?: boolean;
    isPaymentTypeBlockClear?: boolean;
    isSessionIdBlockClear?: boolean;
    isUnitTypeIdsBlockClear?: boolean;
    isUserIdBlockClear?: boolean;
    isZoneIdBlockClear?: boolean;
    operatorId?: boolean;
    packageId?: boolean;
    pageNumber?: boolean;
    pageSize?: boolean;
    paymentType?: boolean;
    search?: boolean;
    sessionId?: boolean;
    source?: boolean;
    unitTypeIds?: SelectType;
    uploadFileStatusId?: boolean;
    userId?: boolean;
    withDeleted?: boolean;
    zoneId?: boolean;
};

export type IParamsList = {
    id?: string;
    // orderBy?: string;
    // orderDirection?: 'desc' | 'asc';
    cardId?: string;
    cardSetId?: IFilterSelectComposed;
    commandId?: IFilterSelectComposed;
    companyId?: IFilterSelectComposed;
    companyTypeId?: IFilterSelectComposed;
    countryId?: string;
    endDate?: string;
    entityId?: IFilterSelectComposed;
    entityType?: string;
    exportType?: IExportType;
    fileId?: IFilterSelectComposed;
    fromDate?: string;
    gatewayId?: IFilterSelectComposed;
    isActiveThisMonth?: boolean;
    isActivityLogUsers?: boolean;
    isDelete?: boolean;
    networkId?: IFilterSelectComposed;
    operatorId?: IFilterSelectComposed;
    packageId?: IFilterSelectComposed;
    pageNumber?: number;
    pageSize?: number;
    parentId?: string;
    paymentType?: IFilterSelectComposed;
    prev?: number;
    read?: string;
    routeName?: IComponentName;
    search?: string;
    sessionId?: IFilterSelectComposed;
    source?: IFilterSelectComposed;
    startDate?: string;
    stream?: string;
    toDate?: string;
    transactionId?: string;
    unitTypeIds?: IFilterSelectComposed;
    uploadFileStatusId?: IFilterSelectComposed;
    userId?: IFilterSelectComposed;
    withDeleted?: boolean;
    zoneId?: IFilterSelectComposed;
};
export type IDateRangeAPI = {
    start_date?: string;
    end_date?: string;
};

export type IDateRange = {
    startDate?: string | undefined;
    endDate?: string | undefined;
};

export type IDate = string | undefined;

export type IMainItem = {
    id: string;
};

export type ISelectListCountries = Array<ISelectCountries>;
export type ISelectList = Array<ISelect>;
export type ISelectWithTotal = {
    select: ISelectList;
    total: number;
};
export type ISelectFind = ISelect | undefined;
export interface ISelectCountries extends ISelect {
    mcc?: string;
}
export interface ISelect {
    id: string;
    name: string;
    value?: string;
    disabled?: boolean;
}

export type IColumnOption = {
    xs?: number; //   <  576
    sm?: number; //  >=  576
    md?: number; //  >=  768
    lg?: number; //  >=  992
    xl?: number; //  >= 1200
    xxl?: number; // >= 1600
};

export type IStringOrNullAPI = string | undefined;

export interface IRelationshipsItemPOSTAPI {
    id: string;
    type: IIncludeTypeAPI;
    destroy?: boolean;
}

export type IChangeStatusCommands = 'unblock' | 'block' | 'activate' | 'refresh';
export type IChangeStatus = {
    command: IChangeStatusCommands;
    idList: Array<string>;
};

export type ITempTableDataSourceDefaultItem = {
    key: string;
};

export type ITempTableDataSourceAnyFieldItem = {
    [field: string]: string;
};

export type ITempTableDataSourceItem = {
    [id: string]: ReactNode;
};

export type ITempTableColumnItem = {
    title?: string;
    dataIndex?: string;
    className?: string;
    width?: string | number;
};

export type IRowSelectionCheckboxProps = {
    disabled?: boolean;
};

export type ICardWithIdSpinners = {
    children?: never;
    columnOption?: IColumnOption;
    idSpinners: IGetApiType[];
    title?: ITrPage;
};

export type IGetListMapCenterAndZoom = {
    center: IGeoLocation;
    zoom: number;
};
