/* eslint-disable */
import { PurchaseStore } from '../stores/purchase';
import { ZonesStore } from '../stores/zones';
import {
    cleanApiMessages,
    cleanCountriesStore,
    cleanZonesStore,
    getCatalogsUnitList,
    getCountriesWithZoneList,
    getCurrenciesSelect,
    getNetworksSelect,
    getOperatorsSelect,
    getUnitLabel,
    getZonesSelect,
    setPurchaseRatesForcedUpdateTime,
} from './';
import { IBodyItemResponse, IRequestAPIBody, apiManager } from '../REST';
import {
    ICurrenciesListItemAPI,
    IIncludeNameItemAPI,
    IIncludeUnitItemAPI,
    IPatchItem,
    IPurchaseRateForm,
    IPurchaseRateFormInfo,
    IPurchaseRateFormListItem,
    IPurchaseRateIncludeAPI,
    IPurchaseRateIncludeCompanyAPI,
    IPurchaseRateIncludeCountryAPI,
    IPurchaseRateIncludeItemAPI,
    IPurchaseRateIncludeItemPOSTAPI,
    IPurchaseRateIncludeNetworkAPI,
    IPurchaseRateIncludeOperatorAPI,
    IPurchaseRateItemAPI,
    IRelationshipItem,
} from '../interfaces';
import { globalHexId, mapApi2UiField, mapUi2ApiField, sortByString } from '../instruments';

export const makeZoneName = (zoneId: string): string => {
    return `zone-${zoneId}`;
};

export async function deletePurchaseRateList(id: string): Promise<void> {
    const { isError } = await apiManager.getApi('DELETE_PURCHASE_RATE_LIST', { id });
    if (!isError) {
        setPurchaseRatesForcedUpdateTime();
    }
}

function setPurchaseRateInfo(info: IPurchaseRateFormInfo): void {
    const StoreInstance = PurchaseStore.getInstance();
    StoreInstance.setInfo(info);
}

const makePurchaseRateInfo = (data?: IPurchaseRateItemAPI, included?: IPurchaseRateIncludeAPI[]) => {
    const attributes = data?.attributes && mapApi2UiField(data?.attributes);
    const itemId = data?.id || '';
    const operatorId = data?.relationships?.operator?.data?.id || '';
    const operator =
        (included?.filter((i: IPurchaseRateIncludeAPI) => i.type === 'operator') as IIncludeNameItemAPI[])?.find(
            (i: IIncludeNameItemAPI) => i.id === operatorId,
        )?.attributes?.name || '';
    const companyId = data?.relationships?.company?.data?.id;
    const company = (
        included?.filter((i: IPurchaseRateIncludeAPI) => i.type === 'company') as IPurchaseRateIncludeCompanyAPI[]
    )?.find((i: IPurchaseRateIncludeCompanyAPI) => i.id === companyId);
    const companyName = company?.attributes?.name || '';
    const currencyId = data?.relationships?.currency?.data?.id;
    const currency =
        (included?.filter((i: IPurchaseRateIncludeAPI) => i.type === 'currency') as ICurrenciesListItemAPI[])?.find(
            (i: ICurrenciesListItemAPI) => i.id === currencyId,
        )?.attributes?.abbreviation || '';

    const info: IPurchaseRateFormInfo = {
        id: itemId,
        ...attributes,
        company: companyName,
        companyId,
        currency,
        currencyId,
        operator,
        operatorId,
    };
    setPurchaseRateInfo(info);
};

function setPurchaseRateRateList(list: IPurchaseRateFormListItem[]): void {
    const StoreInstance = PurchaseStore.getInstance();
    StoreInstance.setList(list);
}

const makePurchaseRatRateList = (data?: IPurchaseRateItemAPI, included?: IPurchaseRateIncludeAPI[]) => {
    const networks =
        (included?.filter((i: IPurchaseRateIncludeAPI) => i.type === 'network') as IPurchaseRateIncludeNetworkAPI[]) ||
        [];
    const countries =
        (included?.filter((i: IPurchaseRateIncludeAPI) => i.type === 'country') as IPurchaseRateIncludeCountryAPI[]) ||
        [];
    const rate_units =
        (included?.filter((i: IPurchaseRateIncludeAPI) => i.type === 'rate_unit') as IIncludeUnitItemAPI[]) || [];
    const zones =
        (included?.filter((i: IPurchaseRateIncludeAPI) => i.type === 'zone') as IPurchaseRateIncludeOperatorAPI[]) ||
        [];
    const purchaseIncludeList =
        (included?.filter(
            (i: IPurchaseRateIncludeAPI) => i.type === 'purchase_rate_list_entry',
        ) as IPurchaseRateIncludeItemAPI[]) || [];

    const purchases: IPurchaseRateFormListItem[] =
        data?.relationships?.purchase_rate_list_entries?.data
            ?.map((i) => {
                const purchaseIncludeFound = purchaseIncludeList.find((l) => l.id === i.id);
                const findAttributes =
                    purchaseIncludeFound?.attributes && mapApi2UiField(purchaseIncludeFound.attributes);
                const networkId = purchaseIncludeFound?.relationships?.network?.data?.id;
                const network = networks?.find((o) => o.id === networkId);
                const networkName = network?.attributes?.name;
                const countryId = network?.relationships?.country?.data?.id;
                const country = countries?.find((c) => c.id === countryId);
                const countryName = country?.attributes?.name;
                const zoneId = country?.relationships?.zone?.data?.id;
                const zone = zones?.find((z) => z.id === zoneId);
                const zoneName = zone?.attributes?.name;
                const rateData = findAttributes?.rateData || 0;
                const rateDataUnitId = purchaseIncludeFound?.relationships?.rate_data_unit?.data?.id;
                const rateDataUnit = getUnitLabel(rate_units.find((u) => u.id === rateDataUnitId)?.attributes?.unit);
                const rateSms = findAttributes?.rateSms || 0;
                const rateSmsUnitId = purchaseIncludeFound?.relationships?.rate_sms_unit?.data?.id;
                const rateSmsUnit = getUnitLabel(rate_units.find((u) => u.id === rateSmsUnitId)?.attributes?.unit);
                const incrementData = findAttributes?.incrementData || 0;
                const incrementVoiceMobile = findAttributes?.incrementVoiceMobile || 0;
                const rateVoiceMobile = findAttributes?.rateVoiceMobile || 0;
                const rateVoiceMobileUnitId = purchaseIncludeFound?.relationships?.rate_voice_mobile_unit?.data?.id;
                const rateVoiceNet = findAttributes?.rateVoiceNet || 0;
                const rateVoiceNetUnitId = purchaseIncludeFound?.relationships?.rate_voice_net_unit?.data?.id;
                const incrementVoiceNet = findAttributes?.incrementVoiceNet || 0;

                return {
                    id: i.id,
                    networkId,
                    network: networkName,
                    countryId,
                    country: countryName,
                    zoneId,
                    zone: zoneName,
                    incrementData,
                    incrementVoiceMobile,
                    incrementVoiceNet,
                    rateData,
                    rateDataUnit,
                    rateDataUnitId,
                    rateSms,
                    rateSmsUnit,
                    rateSmsUnitId,
                    rateVoiceMobile,
                    rateVoiceMobileUnitId,
                    rateVoiceNet,
                    rateVoiceNetUnitId,
                };
            })
            .sort((a, b) => sortByString(a.country, b.country)) || [];
    setPurchaseRateRateList(purchases);
};

export function setPurchaseRate(resp: IBodyItemResponse) {
    const { data, included } = resp;
    makePurchaseRateInfo(data as IPurchaseRateItemAPI, included);
    makePurchaseRatRateList(data as IPurchaseRateItemAPI, included);
}

function createPurchaseRateInfo(): void {
    const newInfo: IPurchaseRateFormInfo = {
        id: globalHexId.getId(),
        currency: 'EUR',
        currencyId: '47',
    };
    setPurchaseRateInfo(newInfo);
}

export async function getPurchaseRate(id?: string): Promise<boolean> {
    if (!id) {
        createPurchaseRateInfo();
        return true;
    } else {
        const { resp, isError } = await apiManager.getApi('GET_PURCHASE_RATE_LIST', { id });
        if (isError) {
            return false;
        } else {
            setPurchaseRate(resp as IBodyItemResponse);
            return true;
        }
    }
}

export function getPurchaseRateShowRelationSelect(): void {
    cleanApiMessages();
    getZonesSelect();
}

export function getPurchaseRateEditRelationSelect(): void {
    getPurchaseRateShowRelationSelect();
    getCurrenciesSelect();
    getOperatorsSelect(false, true);
    getCountriesWithZoneList();
    getNetworksSelect();
    getCatalogsUnitList();
}

export function cleanPurchaseRateRateList(): void {
    const StoreInstance = PurchaseStore.getInstance();
    StoreInstance.cleanStore();
    cleanZonesStore();
    cleanCountriesStore();
}

export function setPurchaseForm(form: IPurchaseRateForm): void {
    const StoreInstance = PurchaseStore.getInstance();
    const info: IPurchaseRateFormInfo = {
        ...StoreInstance.info,
        id: form.id,
        name: form.name,
        operatorId: form.operatorId,
        currencyId: form.currencyId,
    };
    setPurchaseRateInfo(info);

    const ZonesStoreInstance = ZonesStore.getInstance();
    const { zonesSelect } = ZonesStoreInstance;

    const zoneList = form as any;
    let updatedList: IPurchaseRateFormListItem[] = [];

    zonesSelect?.forEach((z) => {
        const zoneName = makeZoneName(z.id);
        if (zoneList[zoneName] && zoneList[zoneName].length) {
            const tmp = zoneList[zoneName] as IPurchaseRateFormListItem[];
            updatedList = [...updatedList, ...tmp];
        }
    });
    const newList = StoreInstance.list.map((r) => {
        const foundUpdate = updatedList.find((u) => u.id === r.id);
        return foundUpdate || r;
    });

    setPurchaseRateRateList(newList);
}

export function addPurchaseByZone(zoneId: string): void {
    const newPurchase: IPurchaseRateFormListItem = {
        id: globalHexId.getId(),
        zoneId: zoneId,
    };
    const StoreInstance = PurchaseStore.getInstance();
    StoreInstance.addListItem(newPurchase);
}
export function removePurchaseItem(id: string): void {
    const StoreInstance = PurchaseStore.getInstance();
    StoreInstance.removeListItem(id);
}

export const sendPurchaseRateData = async (isNew: boolean): Promise<IPatchItem> => {
    const StoreInstance = PurchaseStore.getInstance();
    const { info, list } = StoreInstance;
    const { id } = info;
    const purchaseIncludedList: IPurchaseRateIncludeItemAPI[] = [];
    const purchaseRelationList: IRelationshipItem[] = list.map((r) => {
        const relation: IPurchaseRateIncludeItemAPI = { id: r.id, type: 'purchase_rate_list_entry' };
        const {
            country_id,
            increment_data,
            increment_voice_mobile,
            increment_voice_net,
            network_id,
            rate_data,
            rate_minute_net,
            rate_sms,
            rate_voice_mobile,
            rate_voice_net,
        } = mapUi2ApiField(r);

        const include: IPurchaseRateIncludeItemPOSTAPI = {
            ...relation,
            attributes: {
                country_id,
                increment_data,
                increment_voice_mobile,
                increment_voice_net,
                network_id,
                rate_data,
                rate_minute_net,
                rate_sms,
                rate_voice_mobile,
                rate_voice_net,
            },
        };
        purchaseIncludedList.push(include);
        return relation;
    });

    const body: IRequestAPIBody = {
        data: {
            id: info.id || '',
            type: 'purchase_rate_list',
            attributes: {
                name: info.name,
                operator_id: info.operatorId || null, // ToDo: 27.06.2022 - need Check BackEnd
                currency_id: info.currencyId || null, // ToDo: 27.06.2022 - need Check BackEnd,
            },
            relationships: {
                purchase_rate_list_entries: {
                    data: purchaseRelationList,
                },
            },
        },
        included: purchaseIncludedList,
    };
    const { resp, isError } = await apiManager.getApi(
        isNew ? 'POST_PURCHASE_RATE_LIST' : 'PATCH_PURCHASE_RATE_LIST',
        isNew ? {} : { id: id },
        body,
    );
    const { errors } = await resp;
    return { isError, errors };
};
