import React from 'react';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useRateCountriesList } from '../../../hooks';
import { createRateList } from '../../../middleware';
import { tB, tP } from '../../../translate';
import Styles from '../../styles/m_itemCardStyles.less';

export const EditSalesRateListTitle: React.FC = observer(() => {
    const isCountriesList = !!useRateCountriesList().length;

    return (
        <div className={Styles.title}>
            <div className={Styles.labelText}>{tP('Sales Rate List')}</div>
            {!isCountriesList ? (
                <Button
                    onClick={(): void => createRateList()}
                    icon={<PlusOutlined />}
                    type="primary"
                    className={Styles.addButton}
                >
                    {tB('Add')}
                </Button>
            ) : null}
        </div>
    );
});
