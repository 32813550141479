import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Checkbox, Form, Row } from 'antd';
import {
    CardInfoToolBar,
    CardMainLayout,
    Catcher,
    FormSelect,
    InputDebounce,
    LazyLoadingSelect,
    cardLayoutOptions,
} from '../../';
import { FieldData } from 'rc-field-form/es/interface';
import { IAntDFormChangeFieldName, IEditForm, IFormFields, SelectType } from '../../../interfaces';
import { useCompaniesSelect, useFeatureFlagByKey, useRouterStore, useUsersSelectWithTotal } from '../../../hooks';
import {
    apiMessagesDeleteMessageByFieldName,
    apiMessagesGetFormRules,
    apiMessagesSaveMessages,
    getFeatureFlagRules,
    getFeatureFlagsListAll,
    getFeatureFlagsRelations,
    getUsersSelect,
    updateFeatureFlag,
} from '../../../middleware';
import Styles from '../../styles/m_itemCardStyles.less';

export const FeatureFlagsEdit: React.FC<IEditForm> = observer(() => {
    const [form] = Form.useForm();
    const router = useRouterStore();
    const featureFlagAttributes = useFeatureFlagByKey(router.routerState.params.id);
    const initialValues = {
        userIds: featureFlagAttributes?.conditions?.userIds
            ? featureFlagAttributes?.conditions?.userIds.split(',')
            : undefined,
        companyIds: featureFlagAttributes?.conditions?.companyIds
            ? featureFlagAttributes?.conditions?.companyIds.split(',')
            : undefined,
        value: featureFlagAttributes?.value,
    };

    const [isSending, setSending] = useState(false);
    const [isChanged, setChanged] = useState(false);

    useEffect(() => {
        getFeatureFlagsListAll();
        getFeatureFlagsRelations();
    }, []);

    useEffect(() => {
        if (!isChanged) {
            form.setFieldsValue(initialValues);
        }
    }, [initialValues]);

    const onFieldsChange = (changedFields: FieldData[]): void => {
        const fieldNameChange =
            changedFields && changedFields[0] && (changedFields[0].name as IAntDFormChangeFieldName);
        if (fieldNameChange) {
            apiMessagesDeleteMessageByFieldName(fieldNameChange);
        }

        setChanged(true);
    };

    const onFinish = async (fields: IFormFields): Promise<void> => {
        setSending(true);

        const { isError, errors } = await updateFeatureFlag(featureFlagAttributes.key, fields);

        if (isError) {
            apiMessagesSaveMessages(errors);
            form.validateFields();
        } else {
            await getFeatureFlagRules();
        }

        setSending(false);

        router.goTo('FeatureFlags');
    };

    const onCancel = () => {
        router.goTo('FeatureFlags');
    };

    const companyIdsTitle = 'Company Ids';
    const userIdsTitle = 'User Ids';
    const valueNumberTitle = 'Value';

    return (
        <Catcher>
            <article className={Styles.main}>
                <Row>
                    <CardMainLayout
                        columnOption={cardLayoutOptions.cardInfoEdit}
                        idSpinners={['GET_FEATURE_FLAG_LIST_ALL']}
                        isEdit
                        name={featureFlagAttributes?.key}
                    >
                        <Form
                            form={form}
                            name="featureFlagInfo"
                            initialValues={initialValues}
                            onFieldsChange={onFieldsChange}
                            onFinish={onFinish}
                            scrollToFirstError={true}
                            layout={'vertical'}
                        >
                            <Form.Item name="userIds" label={userIdsTitle}>
                                <LazyLoadingSelect
                                    idSpinners={['GET_USERS_LIST']}
                                    isBlockAllowClear={false}
                                    mode={SelectType.MULTIPLE}
                                    useSelectHook={useUsersSelectWithTotal}
                                    title={'Choose Users'}
                                    fetchFunc={async (props) => getUsersSelect({ isActivityLogUsers: false, ...props })}
                                />
                            </Form.Item>
                            <FormSelect
                                fieldName={'companyIds'}
                                label={companyIdsTitle}
                                mode={'multiple'}
                                rules={apiMessagesGetFormRules}
                                useSelectHook={useCompaniesSelect}
                            />
                            {typeof initialValues?.value === 'boolean' ? (
                                <Form.Item name="value" valuePropName="checked" rules={apiMessagesGetFormRules}>
                                    <Checkbox>Enabled Feature Flag</Checkbox>
                                </Form.Item>
                            ) : (
                                <Form.Item name="value" label={valueNumberTitle} rules={apiMessagesGetFormRules}>
                                    <InputDebounce placeholder={valueNumberTitle} type={'number'} />
                                </Form.Item>
                            )}
                            <CardInfoToolBar isLoading={isSending} isSubmitButton onCancel={onCancel} />
                        </Form>
                    </CardMainLayout>
                </Row>
            </article>
        </Catcher>
    );
});
