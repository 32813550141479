import React from 'react';
import { observer } from 'mobx-react';
import { Select } from 'antd';
import { Catcher } from '../';
import { appConfig } from '../../settings';
import { ISelect } from '../../interfaces';
import { useUiLanguage } from '../../hooks';
import { setUiLanguage } from '../../middleware';
import Styles from './m_styles.less';

export const LanguageSelector: React.FC = observer(() => {
    const { Option } = Select;
    const languageId = useUiLanguage();
    const onChange = async (languageId: string): Promise<void> => {
        await setUiLanguage(languageId);
    };

    const selectJSX = appConfig.languages.map(
        (l: ISelect): JSX.Element => (
            <Option key={l.id} value={l.id}>
                <div>{l.name}</div>
            </Option>
        ),
    );

    return (
        <Catcher>
            <Select
                className={Styles.selector}
                getPopupContainer={(trigger): HTMLElement => trigger.parentElement}
                onChange={onChange}
                value={languageId}
            >
                {selectJSX}
            </Select>
        </Catcher>
    );
});
