import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { IServerNotificationItem } from '../../interfaces';

export function useUiServerNotificationsIsShowRead(): boolean {
    return useContext(MobXProviderContext).RootStore.alarmsStore.isShowServerNotificationsRead;
}

export function useUiServerNotificationsStatus(): number {
    return useContext(MobXProviderContext).RootStore.alarmsStore.isConnectServerNotifications;
}

export function useUiServerNotifications(isRead: boolean): IServerNotificationItem[] {
    const { serverNotificationsRead, serverNotificationsUnRead } =
        useContext(MobXProviderContext).RootStore.alarmsStore;
    return isRead ? serverNotificationsRead : serverNotificationsUnRead;
}

export function useUiServerNotificationsCount(isRead: boolean): number {
    const { serverNotificationsReadCount, serverNotificationsUnReadCount } =
        useContext(MobXProviderContext).RootStore.alarmsStore;

    return isRead ? serverNotificationsReadCount : serverNotificationsUnReadCount;
}

export function useIsPopupOpen(): boolean {
    return useContext(MobXProviderContext).RootStore.alarmsStore.isPopupOpen;
}

export function useAlarmsShowCount(): number {
    return useContext(MobXProviderContext).RootStore.alarmsStore.showCount;
}
