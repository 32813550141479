/* eslint-disable */
import { AuthStore } from '../stores/auth';
import { RouterPagesStore } from '../stores/routerPages';
import {
    addNotification,
    cleanProfileStore,
    getProfileInfo,
    getRouterStoreCurrentComponent,
    getRouterStoreRequestedPage,
    getUiLocalStorageLanguage,
    setRouterStoreCurrentComponent,
    setRouterStoreRequestedPage,
} from './';
import { IRequestAPIBody, tokenManager } from '../REST';
import { IGetApiResponse, ILoginRequest, IToken, ITokenType, apiManager } from '../REST';
import {
    IAcceptanceDocumentsItem,
    IAppStatus,
    IGetAcceptanceDocuments,
    IGetAcceptanceDocumentsItemAPI,
    IRouterState,
} from '../interfaces';
import { tNM } from '../translate';

export function getAuthAppStatus(): IAppStatus {
    const AuthStoreInstance = AuthStore.getInstance();
    return AuthStoreInstance.appStatus;
}

export async function setAuthAppStatus(newAppStatus: IAppStatus): Promise<void> {
    const oldAppStatus = getAuthAppStatus();
    const oldCurrentComponent = getRouterStoreCurrentComponent();
    if (newAppStatus === 'ACCEPT_POLICY' && oldAppStatus === 'READY') {
        setRouterStoreRequestedPage(oldCurrentComponent);
    }
    const AuthStoreInstance = AuthStore.getInstance();
    AuthStoreInstance.setAppStatus(newAppStatus);

    if (
        (newAppStatus === 'READY' || newAppStatus === 'AUTHORIZATION') &&
        (oldAppStatus === 'INITIALIZATION' || oldAppStatus === 'AUTHORIZATION' || oldAppStatus === 'ACCEPT_POLICY')
    ) {
        await getUiLocalStorageLanguage();
        const requestedPage = getRouterStoreRequestedPage();
        setRouterStoreCurrentComponent(requestedPage);
    } else {
        setRouterStoreCurrentComponent(oldCurrentComponent);
    }
}

export async function authCheckInitialized(routerState: IRouterState): Promise<void> {
    setRouterStoreRequestedPage(routerState);
    if (await tokenManager.getToken()) {
        const profileResponse = await getProfileInfo();
        if (profileResponse.isError) {
            authLogOutUser();
        } else {
            setAuthAppStatus('READY');
        }
    } else {
        setAuthAppStatus('AUTHORIZATION');
    }
}

export async function authLoginUser(user: ILoginRequest): Promise<IGetApiResponse> {
    return await apiManager._logIn(user);
}

export async function authLogOutUser(): Promise<void> {
    const appStatus = getAuthAppStatus();
    await apiManager._logOut();
    await tokenManager.cleanToken();
    cleanProfileStore();
    if (appStatus === 'AUTHORIZATION') {
        setAuthAppStatus('INITIALIZATION');
        setAuthAppStatus('AUTHORIZATION');
    } else {
        setAuthAppStatus('AUTHORIZATION');
    }
}

export async function authGoToLogin(routerState: IRouterState = { routeName: 'Login' }): Promise<void> {
    await setAuthAppStatus('AUTHORIZATION');
    const RouterPagesStoreInstance = RouterPagesStore.getInstance();
    RouterPagesStoreInstance.setCurrentComponent(routerState);
}

export async function sendAuthAcceptanceDocuments(): Promise<void> {
    const AuthStoreInstance = AuthStore.getInstance();
    const { acceptanceDocumentsList } = AuthStoreInstance;

    const body = {
        data: {
            type: 'users_documents_acceptance',
            relationships: {
                acceptance_documents: {
                    data: acceptanceDocumentsList
                        .filter((i: IAcceptanceDocumentsItem) => i.isAccept)
                        .map((i: IAcceptanceDocumentsItem) => ({ id: i.id, type: 'acceptance_document' })),
                },
            },
        },
    } as IRequestAPIBody;

    const responseAccept = await apiManager.getApi('POST_ACCEPTANCE_DOCUMENTS', {}, body);
    if (responseAccept.isError) {
        addNotification({
            type: 'error',
            message: tNM('Document acceptance error'),
            description: JSON.stringify(responseAccept.resp),
            duration: 0,
            isTranslated: true,
        });
    } else {
        const profileResponse = await getProfileInfo();
        if (profileResponse.isError) {
            addNotification({
                type: 'error',
                message: tNM('Get Profile error'),
                description: JSON.stringify(profileResponse.resp),
                duration: 0,
                isTranslated: true,
            });
            console.error(profileResponse);
        } else {
            setAuthAppStatus('READY');
        }
    }
}

function setAuthAcceptanceDocumentsList(list: IAcceptanceDocumentsItem[]): void {
    const AuthStoreInstance = AuthStore.getInstance();
    AuthStoreInstance.setAcceptanceDocumentsList(list);
}

export async function getAuthAcceptanceDocuments(companyId?: string): Promise<void> {
    const { isError, resp } = await apiManager.getApi('GET_ACCEPTANCE_DOCUMENTS', { id: companyId });
    if (!isError) {
        const body = resp as IGetAcceptanceDocuments;
        const list: IAcceptanceDocumentsItem[] =
            (body.data?.map((i: IGetAcceptanceDocumentsItemAPI) => ({
                id: i.id ?? '',
                name: i.attributes?.domain === 'privacy_policy' ? 'Privacy Policy' : 'Terms and Conditions',
                fileName: i.attributes?.name,
                domain: i.attributes?.domain,
                contentFileUrl: i.attributes?.content_file_url,
            })) as IAcceptanceDocumentsItem[]) || [];

        setAuthAcceptanceDocumentsList(list);
    }
}

export function setAuthAcceptDocumentsItem(index: number): void {
    const AuthStoreInstance = AuthStore.getInstance();
    AuthStoreInstance.setAcceptItem(index);
}

export async function sendPasswordUpdateEmail(email: string): Promise<boolean> {
    const body = {
        email,
        redirect_path: `/reset_password`,
    };
    const { isError } = await apiManager.getApi('PASSWORD_UPDATE_EMAIL', {}, body);
    return isError;
}

export async function sendNewPassword(password: string): Promise<boolean> {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    if (!token) {
        return true;
    }
    const body = {
        password,
        reset_token: token,
    };
    const { isError } = await apiManager.getApi('PASSWORD_UPDATE', {}, body);
    return isError;
}

export async function checkTokens() {
    tokenManager.checkTokens();
}

export async function authSaveToken(token: IToken, tokenType: ITokenType = 'access'): Promise<void> {
    const AuthStoreInstance = AuthStore.getInstance();
    AuthStoreInstance.saveToken(token, tokenType);
}
