import { apiManager } from './apiManager';
import { IGetApiParams, IGetApiResponse } from './types';
import { IRealRole, appConfig } from '../settings';
import { addNotification, authLogOutUser, authSaveToken, getProfileInfo, setProfileTokenUserInfo } from '../middleware';

export type ITokenType = 'access' | 'refresh' | 'disabledAccess' | 'disabledRefresh';
export type IToken = string;
export type ILoadTokenResponse = string | null;

export type ITokenInfo = {
    exp?: number;
    aud?: string;
    sub?: string;
    user_id?: number;
    role?: IRealRole;
};

export const tokenManager = {
    tokenTypeList(): Array<ITokenType> {
        return ['access', 'refresh', 'disabledAccess', 'disabledRefresh'];
    },

    async loadToken(tokenType: ITokenType = 'access'): Promise<ILoadTokenResponse> {
        const token = localStorage.getItem(tokenType);
        return token ? token : '';
    },

    async saveToken(token: IToken, tokenType: ITokenType = 'access'): Promise<void> {
        if (token) {
            localStorage.setItem(tokenType, token);
            await authSaveToken(token, tokenType);
            if (tokenType === 'access') {
                const tokenInfo = this.getTokenInfo(token);
                setProfileTokenUserInfo(tokenInfo);
            }
        }
    },

    async removeToken(tokenType: ITokenType): Promise<void> {
        await localStorage.removeItem(tokenType);
        await authSaveToken('', tokenType);
    },
    async cleanToken(): Promise<void> {
        for (const tokenType of this.tokenTypeList()) {
            await this.removeToken(tokenType);
        }
    },

    async refreshAccessToken(): Promise<boolean> {
        const refreshToken = await this.getToken('refresh');
        if (refreshToken) {
            const refreshTokenInfo = this.getTokenInfo(refreshToken);
            const isExpValidRefreshToken = this.isExpValid(refreshTokenInfo.exp);
            if (isExpValidRefreshToken) {
                const { access, refresh, isError } = await apiManager._getAccessToken();
                if (!isError) {
                    if (refresh && this.isValidToken(refresh)) {
                        await this.saveToken(refresh, 'refresh');
                    } else {
                        return false;
                    }
                    if (access && this.isValidToken(access)) {
                        await this.saveToken(access);
                    } else {
                        return false;
                    }
                    return true;
                }
            }
        }
        return false;
    },

    async isValidAccessToken(): Promise<boolean> {
        const accessToken = await this.getToken('access');

        return this.isValidToken(accessToken);
    },

    isValidToken(token: ILoadTokenResponse, duration = 0): boolean {
        if (!token) {
            return false;
        } else {
            const tokenInfo = this.getTokenInfo(token);
            return this.isExpValid(tokenInfo.exp, duration);
        }
    },

    async getToken(tokenType: ITokenType = 'access'): Promise<IToken> {
        return (await this.loadToken(tokenType)) || '';
    },

    async getAccessTokenInfo(): Promise<ITokenInfo> {
        const token = await this.getToken();
        return this.getTokenInfo(token);
    },

    getTokenInfo(token: ILoadTokenResponse): ITokenInfo {
        if (!!token) {
            try {
                return JSON.parse(atob((token || '').split('.')[1]));
            } catch (e) {
                process.env.NODE_ENV === 'development' && console.warn('Parse Token->', token, 'Error->', e);
                return {};
            }
        } else {
            return {};
        }
    },

    isExpValid(exp?: number, duration = 0): boolean {
        if (!exp) {
            return false;
        } else {
            const life = +new Date(exp * 1000) - +new Date();
            return life > duration;
        }
    },

    async checkTokens(): Promise<boolean> {
        const refreshToken = await this.getToken('refresh');
        const isValidRefreshToken = this.isValidToken(refreshToken);
        const accessToken = await this.getToken('access');
        const isValidAccessToken = this.isValidToken(accessToken);
        const isValidAccessTokenDuration = this.isValidToken(accessToken, appConfig.delayTokenAccessNeedUpdate);
        const disabledToken = await this.getToken('disabledAccess');

        if ((!isValidAccessToken || !isValidAccessTokenDuration) && disabledToken) {
            await this.disableImpersonatedToken();
            addNotification({
                type: 'info',
                message: 'Impersonation token has expired!',
            });

            return true;
        }

        if (!isValidRefreshToken && !isValidAccessToken) {
            addNotification({
                type: 'info',
                message: 'Please login again.',
                description: 'You have been logged out due to inactivity for security reasons.',
                duration: 0,
            });
            await authLogOutUser();
            return false;
        } else if (isValidRefreshToken && !isValidAccessTokenDuration) {
            return await this.refreshAccessToken();
        } else {
            return true;
        }
    },

    async enableImpersonatedToken(token: IToken): Promise<void> {
        const currentAccessToken = await this.getToken('access');
        await this.saveToken(currentAccessToken, 'disabledAccess');
        // const currentRefreshToken = await this.getToken('refresh');
        // await this.saveToken(currentRefreshToken, 'disabledRefresh');
        // await this.removeToken('refresh');
        await this.saveToken(token, 'access');
    },

    async disableImpersonatedToken(): Promise<void> {
        const disabledAccessToken = await this.getToken('disabledAccess');
        await this.saveToken(disabledAccessToken, 'access');
        // const disabledRefreshToken = await this.getToken('disabledRefresh');
        // await this.saveToken(disabledRefreshToken, 'refresh');
        await getProfileInfo();
        await this.removeToken('disabledAccess');
        // await this.removeToken('disabledRefresh');
    },

    async getApiTokenCheck(apiParams: IGetApiParams, getApiResponse: IGetApiResponse): Promise<void> {
        let isValidToken = true;
        if (!apiParams.isNotCheckToken) {
            const isValidAccessToken = await tokenManager.isValidAccessToken();
            if (!isValidAccessToken) {
                isValidToken = await tokenManager.checkTokens();
            }
        }

        if (!isValidToken) {
            process.env.NODE_ENV === 'development' && console.warn('Token is not valid');
            getApiResponse.isError = true;
        }
    },
};
