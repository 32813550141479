import React from 'react';
import { observer } from 'mobx-react';
import { Col, Row } from 'antd';
import {
    CardSetsLocationGoogleMap,
    Catcher,
    CombinationCardSetCardShow,
    CompaniesAssignmentsShow,
    cardLayoutOptions,
} from '../../';
import { ShowCardSetInfo } from './ShowCardSetInfo';
import cx from 'classnames';
import { IShowForm } from '../../../interfaces';
import {
    useCardSetInfo,
    useCardSetSpinnersRelation,
    useIncludeCombinationCardSetCardsLocationList,
    useProfileIsRight,
} from '../../../hooks';
import { DashboardHeader } from '../../Dashboard/Components';
import DashboardStyles from '../../Dashboard/m_dashboarStyles.less';
import Styles from '../../styles/m_itemCardStyles.less';
import ViewStyles from '../../styles/m_viewStyles.less';

export const ShowCardSet: React.FC<IShowForm> = observer(({ setEditMode }: IShowForm) => {
    const { companyId } = useCardSetInfo();
    const idSpinners = useCardSetSpinnersRelation();
    const cardSetInfo = useCardSetInfo();
    const list = useIncludeCombinationCardSetCardsLocationList();
    const isLord = useProfileIsRight('LORD');

    return (
        <Catcher>
            <div className={Styles.main}>
                <Row>
                    <ShowCardSetInfo setEditMode={!cardSetInfo?.deletedAt ? setEditMode : undefined} />
                    <CompaniesAssignmentsShow columnOption={cardLayoutOptions.cardAssignedCompanies} />
                </Row>
                <div className={ViewStyles.main}>
                    <div className={cx(ViewStyles.content, ViewStyles.contentNotBorder)}>
                        <Row className={DashboardStyles.dashboardRow}>
                            <Col className={DashboardStyles.headerPlace} {...cardLayoutOptions.cardTableFull}>
                                <DashboardHeader
                                    companyId={companyId}
                                    idSpinners={idSpinners}
                                    boxTitlePrefix={'Card Set'}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
                {cardSetInfo.geolocationEnabled && list.length && isLord ? (
                    <CardSetsLocationGoogleMap
                        columnOption={cardLayoutOptions.cardTableFull}
                        idSpinners={['GET_CARD_SET', 'GET_CARD_SET_COMBINATION']}
                        title={'Card locations'}
                    />
                ) : null}
                <Row>
                    <CombinationCardSetCardShow columnOption={cardLayoutOptions.cardTableFull} />
                    {/* <CallDataRecordsLast5
                        columnOption={cardLayoutOptions.cardTableFull}
                        idSpinners={['GET_CALL_DATA_RECORDS_LIST', 'GET_CALL_DATA_RECORDS_LAST_5']}
                    /> */}
                </Row>
            </div>
        </Catcher>
    );
});
