import {
    GoogleChartControl,
    GoogleChartWrapper,
    GoogleViz,
    ReactGoogleChartEvent,
    ReactGoogleChartProps,
} from 'react-google-charts/dist/types';

export type IOnSelectRow = (id: string) => void;

type IEventCallbackArgs = {
    chartWrapper: GoogleChartWrapper;
    controlWrapper?: GoogleChartControl;
    props: ReactGoogleChartProps;
    google: GoogleViz;
    // eventArgs: any;
};

export const selectChartRow = (onSelectRow?: IOnSelectRow): ReactGoogleChartEvent => {
    const roleIsId = 'annotationText';
    return {
        eventName: 'select',
        callback: (eventCallbackArgs: IEventCallbackArgs): void => {
            if (onSelectRow) {
                const chartWrapper = eventCallbackArgs.chartWrapper;
                const findSelectRow = chartWrapper
                    .getChart()
                    .getSelection()
                    .find((a) => a.row !== undefined);
                if (findSelectRow) {
                    const selectRow = findSelectRow.row;
                    const dataTable = chartWrapper.getDataTable();
                    if (dataTable) {
                        const numberOfColumns = dataTable.getNumberOfColumns();
                        let columnId;
                        for (let col = 0; col < numberOfColumns; col++) {
                            if (dataTable.getColumnRole(col) === roleIsId) {
                                columnId = col;
                            }
                        }
                        if (columnId !== undefined) {
                            const findId = dataTable.getValue(selectRow, columnId);
                            if (typeof findId === 'number' && !isNaN(findId)) {
                                findId && onSelectRow(findId.toString());
                            }
                        }
                    }
                }
            }
        },
    };
};
