import React from 'react';
import { observer } from 'mobx-react';
import { Row } from 'antd';
import { ShowRateZoneTable } from './';
import cx from 'classnames';
import { ISelect } from '../../../interfaces';
import { useCollapseIcon, useZonesSelect } from '../../../hooks';
import { Catcher } from '../../Catcher';
import Styles from '../../styles/m_itemCardStyles.less';

interface IOperator {
    operatorId: string;
    name: string;
}
export const ShowRateOperator: React.FC<IOperator> = observer(({ operatorId, name }: IOperator) => {
    const zoneSelect = useZonesSelect();
    const { isVisible, setVisible, collapseIcon } = useCollapseIcon();
    const onVisibleChange = (): void => {
        setVisible(!isVisible);
    };

    return (
        <Catcher>
            <article className={cx(Styles.cardInfo, Styles.itemBlock)}>
                <div className={cx(Styles.title, { [Styles.titleOnly]: !isVisible })}>
                    {collapseIcon}
                    <div className={Styles.toolBar}>
                        <div className={Styles.labelUrl} onClick={onVisibleChange}>
                            {name}
                        </div>
                    </div>
                </div>
                <div>
                    {isVisible ? (
                        <Row>
                            {zoneSelect.map((z: ISelect) => (
                                <ShowRateZoneTable key={z.id} zoneId={z.id} name={z.name} operatorId={operatorId} />
                            ))}
                        </Row>
                    ) : null}
                </div>
            </article>
        </Catcher>
    );
});
