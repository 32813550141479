import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { AuthTokenTypes, IAcceptanceDocumentsItem, IAppStatus } from '../../interfaces';

export function useAuthAppStatus(): IAppStatus {
    return useContext(MobXProviderContext).RootStore.authStore.appStatus;
}
export function useAuthIsAcceptanceAllDocuments(): boolean {
    return useContext(MobXProviderContext).RootStore.authStore.isAcceptanceAllDocuments;
}
export function useAuthAcceptanceDocumentsList(): IAcceptanceDocumentsItem[] {
    return useContext(MobXProviderContext).RootStore.authStore.acceptanceDocumentsList;
}

export function useAuthTokens(): AuthTokenTypes {
    return useContext(MobXProviderContext).RootStore.authStore.tokens;
}
