import { Gutter } from 'antd/lib/grid/row';
import { IColumnOption } from '../../interfaces';

type ICardLayoutOptions = {
    [key: string]: IColumnOption;
};

export const cardLayoutOptions: ICardLayoutOptions = {
    cardSupportPage: {
        xs: 24, // 480
        sm: 24, // 576
        md: 12, // 768
        lg: 8, // 992
        xl: 8, // 1200
        xxl: 8, // 1600
    },
    cardCompanyShowInfo: {
        xs: 24, // 480
        sm: 24, // 576
        md: 24, // 768
        lg: 24, // 992
        xl: 14, // 1200
        xxl: 10, // 1600
    },
    cardDeviceFilesTable: {
        xs: 24, // 480
        sm: 24, // 576
        md: 24, // 768
        lg: 24, // 992
        xl: 24, // 1200
        xxl: 24, // 1600
    },
    cardCompanyShowCustomize: {
        xs: 24, // 480
        sm: 24, // 576
        md: 24, // 768
        lg: 24, // 992
        xl: 12, // 1200
        xxl: 12, // 1600
    },
    cartCompanySideInfo: {
        xs: 24, // 480
        sm: 24, // 576
        md: 24, // 768
        lg: 24, // 992
        xl: 10, // 1200
        xxl: 8, // 1600
    },
    cardEditCompany: {
        xs: 24, // 480
        sm: 24, // 576
        md: 24, // 768
        lg: 24, // 992
        xl: 12, // 1200
        xxl: 6, // 1600
    },
    cardCompanyLogo: {
        xs: 24, // 480
        sm: 24, // 576
        md: 24, // 768
        lg: 24, // 992
        xl: 24, // 1200
        xxl: 12, // 1600
    },
    cardCompanyDocs: {
        xs: 24, // 480
        sm: 24, // 576
        md: 24, // 768
        lg: 24, // 992
        xl: 12, // 1200
        xxl: 12, // 1600
    },
    cardEditCompanyCustomize: {
        xs: 24, // 480
        sm: 24, // 576
        md: 24, // 768
        lg: 24, // 992
        xl: 12, // 1200
        xxl: 12, // 1600
    },
    cardInfo: {
        xs: 24, // 480
        sm: 24, // 576
        md: 24, // 768
        lg: 16, // 992
        xl: 12, // 1200
        xxl: 8, // 1600
    },
    cardInfoEdit: {
        xs: 24, // 480
        sm: 24, // 576
        md: 24, // 768
        lg: 16, // 992
        xl: 12, // 1200
        xxl: 10, // 1600
    },
    cardCardShowInfo: {
        xs: 24, // 480
        sm: 24, // 576
        md: 24, // 768
        lg: 16, // 992
        xl: 12, // 1200
        xxl: 8, // 1600
    },
    cardSetInfo: {
        xs: 24, // 480
        sm: 24, // 576
        md: 24, // 768
        lg: 24, // 992
        xl: 10, // 1200
        xxl: 10, // 1600
    },
    cardAssignedCompanies: {
        xs: 24, // 480
        sm: 24, // 576
        md: 24, // 768
        lg: 24, // 992
        xl: 14, // 1200
        xxl: 14, // 1600
    },
    tightLists: {
        xs: 24, // 480
        sm: 24, // 576
        md: 24, // 768
        lg: 12, // 992
        xl: 12, // 1200
        xxl: 12, // 1600
    },
    topFiveUsedDevices: {
        xs: 24, // 480
        sm: 24, // 576
        md: 24, // 768
        lg: 24, // 992
        xl: 24, // 1200
        xxl: 16, // 1600
    },
    rateList: {
        xs: 24, // 480
        sm: 24, // 576
        md: 24, // 768
        lg: 24, // 992
        xl: 24, // 1200
        xxl: 12, // 1600
    },
    cardTable: {
        xs: 24, // 480
        sm: 24, // 576
        md: 24, // 768
        lg: 12, // 992
        xl: 12, // 1200
        xxl: 12, // 1600
    },
    cardTableFull: {
        xs: 24, // 480
        sm: 24, // 576
        md: 24, // 768
        lg: 24, // 992
        xl: 24, // 1200
        xxl: 24, // 1600
    },
    cardSubscribersInfo: {
        xs: 24, // 480
        sm: 24, // 576
        md: 24, // 768
        lg: 18, // 992
        xl: 12, // 1200
        xxl: 12, // 1600
    },
    cardTableSubscribers: {
        xs: 24, // 480
        sm: 24, // 576
        md: 24, // 768
        lg: 24, // 992
        xl: 24, // 1200
        xxl: 16, // 1600
    },
    cardTableOperators: {
        xs: 24, // 480
        sm: 24, // 576
        md: 24, // 768
        lg: 24, // 992
        xl: 12, // 1200
        xxl: 12, // 1600
    },
    filterToolBarFieldCol: {
        xs: 24, // 480
        sm: 24, // 576
        md: 24, // 768
        lg: 12, // 992
        xl: 8, // 1200
        xxl: 6, // 1600
    },
    listTitleTitle: {
        xs: 24, // 480
        sm: 24, // 576
        md: 12, // 768
        lg: 12, // 992
        xl: 12, // 1200
        xxl: 12, // 1600
    },
    listTitleRightToolbar: {
        xs: 24,
        sm: 24,
        md: 11,
        lg: 12,
        xl: 12,
        xxl: 12,
    },
    packageCard: {
        xs: 24, // 480
        sm: 24, // 576
        md: 24, // 768
        lg: 24, // 992
        xl: 12, // 1200
        xxl: 10, // 1600
    },
    salesRateInfo: {
        xs: 24, // 480
        sm: 24, // 576
        md: 24, // 768
        lg: 24, // 992
        xl: 12, // 1200
        xxl: 12, // 1600
    },
    purchaseRate: {
        xs: 24, // 480
        sm: 24, // 576
        md: 24, // 768
        lg: 18, // 992
        xl: 12, // 1200
        xxl: 8, // 1600
    },
};

export const viewOption = {
    networkOffset: 100, //px
    rateTable: {
        // height: 300, //px
        // heightPurchase: 600, //px
        incrementData: 80, //px
        widthNetCount: 30, //px
        widthRateCountry: 100, //px
        widthRateUnit: 40, //px
        widthRateVal: 70, //px
    },
    rateTableEdit: {
        widthCount: 50, //px
        widthExpandable: 17, //px
        widthMarginColumn: 30, //px
        widthNetwork: 150, //px
        widthToolBar: 50, //px
        widthValue: 150, //px
    },
};

type IColumnGutter = {
    [id: string]: Gutter | [Gutter, Gutter];
};

export const rowGutter: IColumnGutter = {
    dashboardInfoCart: [30, 30],
    dashboardChartCard: [30, 30],
    filterToolBarFieldCol: [8, 8],
    signUpForm: [8, 8],
    card: [30, 30],
};
