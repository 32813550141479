import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Form } from 'antd';
import { FileTextOutlined, PhoneOutlined } from '@ant-design/icons';
import { IFieldLayout, IShowForm } from '../../../interfaces';
import { useExternalAPIForm, useExternalAPIOrderDetail, useSubscriberInfo } from '../../../hooks';
import { getExternalAPIOrderDetail, iccidRules, sendExternalAPIOrderDetailForm } from '../../../middleware';
import { equalObjects, trimStringFormFields } from '../../../instruments';
import { ITrField, ITrInterfaces } from '../../../translate';
import {
    CardInfoToolBar,
    CardMainLayout,
    Catcher,
    FieldInfoAdvanced,
    FormCheckedField,
    FormStringDebounceField,
    cardLayoutOptions,
} from '../../../Components';
import Styles from '../../styles/m_itemCardStyles.less';

export const OrderDetailEdit: React.FC<IShowForm> = observer(({ setEditMode, loadingTypes }: IShowForm) => {
    const layout: IFieldLayout = {
        labelCol: {
            flex: '0 1 160px',
        },
        valCol: {
            flex: '1 1 270px',
        },
    };

    const [isChanged, setChanged] = useState<boolean>(false);
    const [isValid, setValid] = useState<boolean>(false);
    const { id } = useSubscriberInfo();
    const [form] = Form.useForm();
    const initialValues = useExternalAPIForm();
    const orderStatus = useExternalAPIOrderDetail().subscription?.status;

    console.log(Date.now(), '-()->', typeof orderStatus, `-orderStatus->`, orderStatus);

    const checkValid = (): void => {
        form.validateFields().then(
            () => {
                setValid(true);
            },
            () => {
                setValid(!form.getFieldsError().filter(({ errors }) => errors.length).length);
            },
        );
    };

    useEffect(() => {
        form.resetFields();
        checkValid();
        setChanged(false);
        const aaa = initialValues;
        console.log(Date.now(), '-(EFFECT)->', typeof aaa, `-aaa->`, { ...aaa });
    }, [initialValues]);

    const onFinish = async (formFields: any): Promise<void> => {
        await sendExternalAPIOrderDetailForm({
            subscriberId: id,
            formFields: trimStringFormFields(formFields),
            initialValues,
        });
        getExternalAPIOrderDetail(id);
        setEditMode && setEditMode();
    };

    const onValuesChange = () => {
        setChanged(!equalObjects(initialValues, trimStringFormFields(form.getFieldsValue())));
        checkValid();
    };

    // const setOrderActivate = () => {
    //     setExternalAPIOrderActivate(id);
    // };

    return (
        <Catcher>
            <CardMainLayout
                columnOption={cardLayoutOptions.cardSubscribersInfo}
                icon={<FileTextOutlined />}
                idSpinners={loadingTypes}
                isEdit
                isNotDocumentTitle
                name={'Order Detail'}
            >
                <Form
                    form={form}
                    name="orderDetail"
                    initialValues={initialValues}
                    onFinish={onFinish}
                    onValuesChange={onValuesChange}
                    scrollToFirstError={true}
                    layout={'horizontal'}
                >
                    <FieldInfoAdvanced
                        icon={<PhoneOutlined className={Styles.labelIcon} />}
                        layout={layout}
                        title={'SIM Cards' as ITrField}
                    />
                    <div className={Styles.subFieldsPlace}>
                        <FormStringDebounceField label={'ICCID'} name={'simCardsIccId'} rules={iccidRules} />
                        <FormStringDebounceField label={'MSISDN'} name={'simCardsMsisdn'} />
                        <FormCheckedField isSwitch label={'Allow Data' as ITrField} name={'simCardsAllowData'} />
                        <FormCheckedField
                            isSwitch
                            label={'Allow Data Roaming' as ITrField}
                            name={'simCardsAllowDataRoaming'}
                        />
                        <FormCheckedField
                            isSwitch
                            label={'Allow Premium SMS' as ITrField}
                            name={'simCardsAllowPremiumSMS'}
                        />
                        <FormCheckedField
                            isSwitch
                            label={'Bill Shock Prevention' as ITrField}
                            name={'simCardsAllowBillShockSMS'}
                            description={'Bill Shock SMS' as ITrInterfaces}
                        />
                    </div>

                    <CardInfoToolBar
                        loadingTypes={loadingTypes}
                        isDisabledSave={!isChanged || !isValid}
                        isSubmitButton
                        onCancel={setEditMode}
                    >
                        {/*<Button onClick={setOrderActivate}>{'OrderActivate'}</Button>*/}
                    </CardInfoToolBar>
                </Form>
            </CardMainLayout>
        </Catcher>
    );
});
