import { CompaniesStore } from '../stores/companies';
import { addIncludedCurrencyList } from './included';
import { getFeatureFlagIsBigQuery } from './profile';
import { setUiTotal } from './ui';
import { isCompanyTypesIsParentsCheckValid } from '../settings';
import { IBodyListResponse, apiManager } from '../REST';
import {
    ICompaniesHashSettingList,
    ICompaniesHierarchyAPI,
    ICompaniesHierarchyIsNotBigQuery,
    ICompaniesItemAPI,
    IItemAPI,
    IParamsList,
    IPatchItem,
    IRelationshipItem,
    IRequestAPIBody,
    ISelect,
    ISelectList,
} from '../interfaces';
import { mapApi2UiField, sortByName } from '../instruments';

export function setCompaniesList(resp: IBodyListResponse): void {
    const CompaniesStoreInstance = CompaniesStore.getInstance();
    CompaniesStoreInstance.setCompaniesList(
        resp?.data?.map((c: ICompaniesItemAPI) => {
            return { id: c.id, ...mapApi2UiField(c.attributes) };
        }) || [],
    );
}

export async function getCompaniesList(params: IParamsList): Promise<void> {
    const { resp } = await apiManager.getApi('GET_COMPANIES_LIST', { searchParamsList: params });
    const { data, meta } = resp;
    meta && setUiTotal(meta.records_count);
    data && setCompaniesList(resp as IBodyListResponse);
}

// ------------ companiesHierarchyList ------------
export function addCompaniesHierarchyListIsNotBigQuery(
    parentId: string | undefined,
    data: Array<ICompaniesHierarchyIsNotBigQuery>,
): void {
    const CompaniesStoreInstance = CompaniesStore.getInstance();
    CompaniesStoreInstance.addChildrenCompaniesHierarchyListIsNotBigQuery(parentId, data);
}
export async function getCompaniesHierarchyListIsNotBigQuery(params: IParamsList): Promise<void> {
    const { resp } = await apiManager.getApi('GET_COMPANIES_HIERARCHY', { searchParamsList: params });
    const { data, included } = resp as IBodyListResponse;
    included && addIncludedCurrencyList(included);
    data && addCompaniesHierarchyListIsNotBigQuery(params.parentId, data as Array<ICompaniesHierarchyIsNotBigQuery>);
}

export function addCompaniesHierarchyListIsBigQuery(parentId: string | undefined, data: Array<any>): void {
    const CompaniesStoreInstance = CompaniesStore.getInstance();
    CompaniesStoreInstance.addChildrenCompaniesHierarchyListIsBigQuery(parentId, data);
}

export async function getCompaniesHierarchyListIsBigQuery(params: IParamsList): Promise<void> {
    const { resp } = await apiManager.getApi('GET_COMPANIES_HIERARCHY', { searchParamsList: params });
    const { data, included } = resp as IBodyListResponse;
    included && addIncludedCurrencyList(included);
    data && addCompaniesHierarchyListIsBigQuery(params.parentId, data as Array<ICompaniesHierarchyAPI>);
}

export async function getCompaniesHierarchyList(params: IParamsList): Promise<void> {
    const isBigQuery = getFeatureFlagIsBigQuery();
    if (isBigQuery) {
        await getCompaniesHierarchyListIsBigQuery(params);
    } else {
        await getCompaniesHierarchyListIsNotBigQuery(params);
    }
}
export function setCompaniesSelect(data: IItemAPI[]): void {
    const CompaniesStoreInstance = CompaniesStore.getInstance();
    CompaniesStoreInstance.setCompaniesSelect(
        data
            ?.map((i) => {
                return { id: i.id, name: i.attributes?.name || i.id };
            })
            .sort(sortByName),
    );
}

export function setCompanyTypesSelect(data: IItemAPI[]): void {
    const CompaniesStoreInstance = CompaniesStore.getInstance();
    const select = data
        .map((i: IItemAPI) => ({
            id: i.id,
            name: i.attributes?.name,
        }))
        .sort(sortByName) as ISelect[];
    CompaniesStoreInstance.setCompanyTypeSelect(select);
}

export function setPaymentTypesSelect(data: IItemAPI[]): void {
    const CompaniesStoreInstance = CompaniesStore.getInstance();
    const select = data
        .map((i: IItemAPI) => ({
            id: i.attributes?.name ?? i.id,
            name: i.attributes?.name,
        }))
        .sort(sortByName) as ISelect[];
    CompaniesStoreInstance.setPaymentTypeSelect(select);
}

export async function getCompaniesSelect(isOnlyAvailable?: boolean): Promise<void> {
    const { resp } = await apiManager.getApi('GET_COMPANIES_SELECT');
    const { data } = resp as IBodyListResponse;
    data && setCompaniesSelect(isOnlyAvailable ? data.filter((i: IItemAPI) => !i?.attributes?.deleted_at) : data);
}

export function setCompaniesHashSettingsList(list: ICompaniesHashSettingList) {
    const CompaniesStoreInstance = CompaniesStore.getInstance();
    CompaniesStoreInstance.setCompaniesHashSettingsList(list);
}

function formCompaniesHashSettingList(resp: IBodyListResponse): ICompaniesHashSettingList {
    const { data, included } = resp;
    const activeCompanies = data?.filter((i: IItemAPI) => !i?.attributes?.deleted_at) || [];

    return activeCompanies?.map((company) => {
        const companySetting = included?.find((setting) => {
            const relationItemData = company?.relationships?.setting?.data as IRelationshipItem;

            return setting?.type === 'setting' && setting?.id === relationItemData?.id;
        });

        return {
            ...companySetting?.attributes,
            id: company.id,
            name: company.attributes.name,
        };
    }) as ICompaniesHashSettingList;
}

export async function getCompaniesHashSettingsList(search?: string): Promise<void> {
    const { resp } = await apiManager.getApi('GET_COMPANIES_SELECT', {
        searchParamsList: {
            search,
        },
    });
    setCompaniesHashSettingsList(formCompaniesHashSettingList(resp as IBodyListResponse));
}

export async function hashCompaniesInfoSetting(id: string, value: boolean): Promise<boolean> {
    const body = {
        data: {
            id,
            type: 'setting',
            attributes: {
                hash_info: value,
            },
        },
    } as IRequestAPIBody;

    const { isError } = await apiManager.getApi('PATCH_COMPANY_SETTINGS', { id }, body);

    return isError;
}

export async function getCompanyTypesSelect(): Promise<void> {
    const { resp } = await apiManager.getApi('GET_COMPANY_TYPES_SELECT');
    const { data } = resp as IBodyListResponse;
    data && setCompanyTypesSelect(data);
}

export async function getPaymentTypesSelect(): Promise<void> {
    const { resp } = await apiManager.getApi('GET_PAYMENT_SELECT');
    const { data } = resp as IBodyListResponse;
    data && setPaymentTypesSelect(data);
}

export function getCompaniesRelationSelect(): void {
    getCompanyTypesSelect();
    getPaymentTypesSelect();
}

export async function getCompaniesParents(): Promise<void> {
    const { resp } = await apiManager.getApi('GET_COMPANIES_SELECT');
    const { data } = resp as IBodyListResponse;
    const filteredData: IItemAPI[] =
        data?.filter(
            (i: IItemAPI) =>
                !i?.attributes?.deleted_at &&
                (i.relationships?.company_type?.data as IRelationshipItem)?.id &&
                isCompanyTypesIsParentsCheckValid((i.relationships?.company_type?.data as IRelationshipItem)?.id),
        ) || [];

    setCompaniesSelect(filteredData);
}

export function setCompaniesChildrenSelect(data: IItemAPI[]): void {
    const CompaniesStoreInstance = CompaniesStore.getInstance();
    CompaniesStoreInstance.setCompaniesChildrenSelect(
        data.map((i) => {
            return { id: i.id, name: i?.attributes?.name || i.id };
        }),
    );
}

export async function getCompaniesChildrenSelect(companyId: string, isOnlyAvailable?: boolean): Promise<void> {
    const { resp } = await apiManager.getApi('GET_COMPANIES_CHILDREN_SELECT', { id: companyId });
    const { data } = resp as IBodyListResponse;
    data && setCompaniesChildrenSelect(isOnlyAvailable ? data.filter((i: IItemAPI) => !i.attributes.deleted_at) : data);
}

export function setCompaniesForcedUpdateTime(): void {
    const CompaniesStoreInstance = CompaniesStore.getInstance();
    CompaniesStoreInstance.setForcedUpdateTime();
}

export function cleanCompaniesStore(): void {
    const CompaniesStoreInstance = CompaniesStore.getInstance();
    CompaniesStoreInstance.cleanStore();
}

export async function deleteCompany(id: string): Promise<IPatchItem> {
    const { isError } = await apiManager.getApi('DELETE_COMPANY', { id });

    if (!isError) {
        setCompaniesForcedUpdateTime();
    }

    return { isError };
}

export function mwCompaniesSelect(): ISelectList {
    const CompaniesStoreInstance = CompaniesStore.getInstance();
    return CompaniesStoreInstance.select;
}
