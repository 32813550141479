import React, { ChangeEvent, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Form, Input, Row, Select } from 'antd';
import { EuroCircleOutlined } from '@ant-design/icons';
import cx from 'classnames';
import {
    ICompanySubscriptionsBody,
    IErrorFields,
    IItemAPI,
    IOrderFormStatus,
    IPostOrderAttributes,
    IResponsePostCheckOutDataAPI,
    ISelect,
    OrderBy,
} from '../../interfaces';
import {
    useCompanyInfo,
    useInputRefFocus,
    useIsMyCompany,
    useProfileIsRight,
    useRouterStore,
    useUiIsLoading,
} from '../../hooks';
import {
    apiMessagesGetFormRules,
    apiMessagesGetFormRulesNumber,
    apiMessagesSaveMessages,
    checkOutOrder,
    cleanApiMessages,
    cleanOrderTopUpRelation,
    createCompanySubscription,
    getCompanySubscription,
    getOrderRelations,
    isAvailablePage,
    postCompanySubscriptionOrder,
    postOrder,
    rulesNot0Number,
    rulesPositiveNumber,
} from '../../middleware';
import { tB, tF, tP } from '../../translate';
import {
    CardInfoToolBar,
    CardMainLayout,
    Catcher,
    FieldAlert,
    FormSelectLight,
    cardLayoutOptions,
} from '../../Components';
import Styles from '../styles/m_itemCardStyles.less';

export const OrderNew: React.FC = observer(() => {
    const { Option } = Select;
    const [form] = Form.useForm();
    const routerStore = useRouterStore();
    const firstInputRef = useInputRefFocus();
    const isLoading = useUiIsLoading();
    const isLord = useProfileIsRight('LORD');
    const isAdmin = useProfileIsRight('ADMIN');
    const isMyCompany = useIsMyCompany();
    const [companyId, setCompanyId] = useState('');
    const [orderBy, setOrderBy] = useState('');
    const [ordersId, setOrdersId] = useState<string>();
    const [isSending, setSending] = useState(false);
    const [isValid, setValid] = useState(false);
    const [orderStatus, setOrderStatus] = useState<IOrderFormStatus>('CREATED_ORDER');
    const [cost, setCost] = useState('');
    const { name: companyName, currency } = useCompanyInfo();
    const initialValues = { company: companyName, cost: cost };
    const costRules = [...apiMessagesGetFormRulesNumber, rulesNot0Number];

    if (isMyCompany) {
        costRules.push(rulesPositiveNumber);
    }

    useEffect(() => {
        return (): void => {
            cleanOrderTopUpRelation();
        };
    }, []);

    useEffect(() => {
        const { params } = routerStore.routerState;
        if (params.id) {
            setCompanyId(params.id);
        }
        if (params.by) {
            setOrderBy(params.by);
        }
    }, [routerStore.routerState]);

    useEffect(() => {
        if (companyId) {
            getOrderRelations(companyId);
        }
    }, [companyId]);

    useEffect(() => {
        if (!isLoading) {
            form.setFieldsValue(initialValues);
        }
    }, [initialValues]);

    const goToCompany = (): void => {
        routerStore.goTo('Company', { params: { id: companyId } });
    };

    const goToOrder = (): void => {
        if (isAvailablePage('OrderView') && ordersId) {
            routerStore.goTo('OrderView', { params: { id: ordersId } });
        } else {
            goToCompany();
        }
    };

    useEffect(() => {
        if (orderStatus === 'REDIRECT') {
            goToOrder();
        }
    }, [orderStatus]);

    const onFinishEditOrderCompany = async (): Promise<void> => {
        setSending(true);

        const attributes: IPostOrderAttributes = {
            cost: +cost,
            description: form.getFieldValue('description'),
            productTypeId: isAdmin && !isMyCompany ? 2 : 1,
            paymentTypeId: isAdmin && !isMyCompany ? 3 : 1,
        };

        const { isError, errors = [], resp } = await postOrder(companyId, attributes);

        if (isError) {
            cleanApiMessages();
            apiMessagesSaveMessages(errors);
            await form.validateFields();
        } else {
            const ordersId = (resp?.data as IItemAPI).id;
            if (ordersId) {
                setOrdersId(ordersId);
                const { isError, errors = [], resp: respCheckOut } = await checkOutOrder(ordersId);

                if (isError) {
                    cleanApiMessages();
                    apiMessagesSaveMessages(errors);
                    await form.validateFields();
                } else {
                    const respData = (respCheckOut?.data || {}) as IResponsePostCheckOutDataAPI;
                    if (respData?.attributes?.payment_method === 'redirect' && respData?.attributes?.payment_url) {
                        window.open(respData?.attributes?.payment_url, '_blank');
                    }
                    setOrderStatus('REDIRECT');
                }
            } else {
                console.warn('Not received order Id');
            }
        }

        setSending(false);
    };
    const onFinishEditOrderSubscription = async (values: any): Promise<void> => {
        setSending(true);

        const attributes: ICompanySubscriptionsBody = {
            amount_cents: values.cost * 100,
            period: values.period,
        };

        const isCreateSubError = await createCompanySubscription(companyId, attributes);

        const subscriptionInfo = await getCompanySubscription(companyId);

        if (!isCreateSubError && subscriptionInfo?.id) {
            const { resp } = await postCompanySubscriptionOrder(subscriptionInfo?.id, attributes);

            const ordersId = (resp?.data as IItemAPI).id;

            if (ordersId) {
                setOrdersId(ordersId);
                const { isError, errors = [], resp: respCheckOut } = await checkOutOrder(ordersId);

                if (isError) {
                    cleanApiMessages();
                    apiMessagesSaveMessages(errors);
                    await form.validateFields();
                } else {
                    const respData = (respCheckOut?.data || {}) as IResponsePostCheckOutDataAPI;
                    if (respData?.attributes?.payment_method === 'redirect' && respData?.attributes?.payment_url) {
                        window.open(respData?.attributes?.payment_url, '_self');
                    }
                    setOrderStatus('REDIRECT');
                }
            } else {
                console.warn('Not received order Id');
            }
        } else {
            console.warn('there was an error while creating order');
        }

        setSending(false);
    };

    const onChangeCost = (a: ChangeEvent<HTMLInputElement>): void => {
        cleanApiMessages();
        setCost(a.target.value);
    };

    const onFieldsChange = async (): Promise<void> => {
        form.validateFields().then(
            () => {
                setValid(true);
            },
            (b: IErrorFields) => {
                setValid(
                    !b.errorFields.map((i) => !!i.errors.length).reduce((res: boolean, item: boolean) => res && item),
                );
            },
        );
    };

    const selectCompanyListJSX = [
        { id: '1', name: 'Weekly', value: 'weekly', disabled: false },
        { id: '2', name: 'Monthly', value: 'monthly', disabled: false },
        { id: '3', name: 'Zero Balance', value: 'zero_balance', disabled: false },
    ].map(
        (s: ISelect): JSX.Element => (
            <Option key={s.id} value={s.value} disabled={s.disabled}>
                <div>{s.name}</div>
            </Option>
        ),
    );

    return (
        <Catcher>
            <article className={Styles.main}>
                <Row>
                    <CardMainLayout
                        columnOption={cardLayoutOptions.cardInfoEdit}
                        idSpinners={['GET_COMPANY']}
                        name={
                            orderBy === OrderBy.SUBSCRIPTION
                                ? tP('Company Subscription Activation')
                                : !isMyCompany
                                ? tB('Give Credits')
                                : tB('Top Up')
                        }
                    >
                        <Form
                            form={form}
                            name="giveCredits"
                            initialValues={initialValues}
                            onFinish={
                                orderBy === OrderBy.SUBSCRIPTION
                                    ? onFinishEditOrderSubscription
                                    : onFinishEditOrderCompany
                            }
                            onChange={onFieldsChange}
                            scrollToFirstError={true}
                            layout={'vertical'}
                        >
                            <Form.Item name="company" label={tF('Company')} rules={apiMessagesGetFormRules}>
                                <Input placeholder={tF('Company')} disabled ref={firstInputRef} />
                            </Form.Item>
                            <Form.Item
                                name="cost"
                                label={tF('Cost')}
                                rules={[
                                    ...apiMessagesGetFormRulesNumber,
                                    isLord ? rulesNot0Number : rulesPositiveNumber,
                                ]}
                            >
                                <Input
                                    addonBefore={currency}
                                    className={Styles.inputCurrencyAddonBefore}
                                    onChange={onChangeCost}
                                    placeholder="0"
                                />
                            </Form.Item>
                            {orderBy === OrderBy.SUBSCRIPTION ? (
                                <>
                                    <FormSelectLight
                                        initialValue={'monthly'}
                                        fieldName={'period'}
                                        label={'Subscription type'}
                                        selectJSX={selectCompanyListJSX}
                                        isNotClear={true}
                                    />
                                    <p className={Styles.paragraph} style={{ fontSize: '10px' }}>
                                        By clicking subscribe you will be redirected to our payment gateway where you
                                        will be asked to enter you credit card details. The amount will be credited from
                                        your credit card according to the subscription type selected. Geniox does not
                                        store you credit card details, which remain secured with our PSD2 certified
                                        payment provider.
                                    </p>
                                </>
                            ) : (
                                <>
                                    <Form.Item
                                        name="description"
                                        label={tF('Description')}
                                        rules={apiMessagesGetFormRules}
                                    >
                                        <Input.TextArea placeholder={tF('Description')} />
                                    </Form.Item>
                                    <FieldAlert fieldName={'productTypeId'} />
                                    <FieldAlert fieldName={'paymentTypeId'} />
                                </>
                            )}
                            <CardInfoToolBar onCancel={goToCompany}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    icon={<EuroCircleOutlined />}
                                    className={cx(Styles.mainButton, {
                                        [Styles.buttonsDisable]: isSending,
                                    })}
                                    disabled={isSending || !isValid}
                                    loading={isSending}
                                >
                                    {orderBy === OrderBy.SUBSCRIPTION
                                        ? tB('Subscribe')
                                        : !isMyCompany
                                        ? tB('Give Credits')
                                        : tB('Top Up')}
                                </Button>
                            </CardInfoToolBar>
                        </Form>
                    </CardMainLayout>
                </Row>
            </article>
        </Catcher>
    );
});
