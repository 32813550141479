import React, { ChangeEventHandler } from 'react';
import { observer } from 'mobx-react';
import { Button, Input, Table } from 'antd';
import { TablePaginationConfig } from 'antd/es/table';
import { GroupOutlined } from '@ant-design/icons';
import { CardLayout, Catcher, LinkComponent, SkyPaginationTotal, cardLayoutOptions } from '../../';
import cx from 'classnames';
import { appConfig } from '../../../settings';
import { ICompaniesHashSetting } from '../../../interfaces';
import { useCompaniesHashSettingsList } from '../../../hooks';
import { tF } from '../../../translate';
import Styles from './m_styles.less';

const { Search } = Input;

interface IHashCompaniesTable {
    onSearch: () => void;
    onChange: ChangeEventHandler<HTMLInputElement>;
    handleClick: (value: boolean, id: string) => void;
    searchValue: string;
}

export const HashCompaniesTable: React.FC<IHashCompaniesTable> = observer(
    ({ onSearch, onChange, handleClick, searchValue }: IHashCompaniesTable) => {
        const pageSize = appConfig.pageDefaultParams.includeViewPageSize;
        const list = useCompaniesHashSettingsList();
        const tableColumns = [
            {
                title: tF('Name'),
                dataIndex: 'name',
            },
            {
                title: 'Hash',
                dataIndex: 'hash_info',
            },
        ];

        const tableData = list.map((setting: ICompaniesHashSetting) => ({
            ...setting,
            key: setting.id,
            name: <LinkComponent currentListComponent={'CompaniesList'} id={setting.id} name={setting.name} isLight />,
            hash_info: (
                <Button
                    type={!setting.hash_info ? 'primary' : 'default'}
                    onClick={() => handleClick(!setting.hash_info, setting.id)}
                >
                    {!setting.hash_info ? 'Activate' : 'Disable'}
                </Button>
            ),
        }));

        const pagination: false | TablePaginationConfig =
            pageSize > list.length
                ? false
                : {
                      total: list.length,
                      pageSize: pageSize,
                      className: 'customPagination',
                      showSizeChanger: false,
                      size: 'default',
                      position: ['bottomLeft'],
                      showTotal: SkyPaginationTotal,
                  };

        return (
            <Catcher>
                <CardLayout
                    columnOption={cardLayoutOptions.cardDeviceFilesTable}
                    count={list.length}
                    icon={<GroupOutlined />}
                    idSpinners={['GET_COMPANIES_SELECT', 'PATCH_COMPANY_SETTINGS']}
                    isTable
                    title={'Hash Companies Info'}
                    titleToolBar={
                        <Search
                            placeholder="Search"
                            className={cx(Styles.searchBar, 'input-search')}
                            onSearch={onSearch}
                            onChange={onChange}
                            value={searchValue}
                        />
                    }
                >
                    <Table
                        dataSource={tableData}
                        columns={tableColumns}
                        pagination={pagination}
                        className={'table-theme'}
                        size={'small'}
                    />
                </CardLayout>
            </Catcher>
        );
    },
);
