import React from 'react';
import { Form, Input, Table } from 'antd';
import { Rule } from 'antd/es/form';
import { FormListFieldData } from 'antd/es/form/FormList';
import { Catcher, RemoveButton } from '../../';
import { EditRateNetworkSelect, UnitSelect, tableColumns } from './';
import cx from 'classnames';
import { IRateNetworkItem, ITempTableDataSourceItem } from '../../../interfaces';
import { apiMessagesDeleteAllMessageBylListId, removeRateNetworkValueById } from '../../../middleware';
import Styles from '../../styles/m_itemCardStyles.less';

interface IEditRateNetworks {
    networks?: IRateNetworkItem[];
    formRules?: Array<Rule>;
}

export const EditRateNetworksTableLists: React.FC<IEditRateNetworks> = ({
    networks,
    formRules = [],
}: IEditRateNetworks) => {
    const usedNetworkIds = networks?.filter((n) => !!n.networkId).map((n) => n.networkId) || [];
    const removeRateNetwork = (id: string): void => {
        apiMessagesDeleteAllMessageBylListId(id);
        removeRateNetworkValueById(id);
    };
    const makeTableDataSource = (fields: FormListFieldData[]): Array<ITempTableDataSourceItem> => {
        return fields.map((field: FormListFieldData, index: number) => {
            const currentNetwork = networks?.[field.key];
            const currentRateNetworkId = currentNetwork?.id || '';
            const currentNetworkId = currentNetwork?.networkId;
            const exceptionNetworkIds = usedNetworkIds.filter((i) => !currentNetworkId || i !== currentNetworkId);
            const currentCountryId = currentNetwork?.countryId;
            if (!currentRateNetworkId) {
                return {};
            }
            const tableKey = `${currentRateNetworkId}-${index.toString()}`;

            return {
                key: tableKey,
                network: (
                    <div className={Styles.tableGroupSelect}>
                        <EditRateNetworkSelect
                            currentCountryId={currentCountryId}
                            exceptionNetworkIds={exceptionNetworkIds}
                            isShortForm
                            name={[field.name, 'networkId']}
                            rules={formRules}
                        />
                    </div>
                ),
                rateData: (
                    <Form.Item name={[field.name, 'rateData']} rules={formRules}>
                        <Input addonBefore={<UnitSelect name={[field.name, 'rateDataUnitId']} rules={formRules} />} />
                    </Form.Item>
                ),
                rateSms: (
                    <Form.Item name={[field.name, 'rateSms']} rules={formRules}>
                        <Input addonBefore={<UnitSelect name={[field.name, 'rateSmsUnitId']} rules={formRules} />} />
                    </Form.Item>
                ),
                rateVoiceMobile: (
                    <Form.Item name={[field.name, 'rateVoiceMobile']} rules={formRules}>
                        <Input
                            addonBefore={<UnitSelect name={[field.name, 'rateVoiceMobileUnitId']} rules={formRules} />}
                        />
                    </Form.Item>
                ),
                rateVoiceNet: (
                    <Form.Item name={[field.name, 'rateVoiceNet']} rules={formRules}>
                        <Input
                            addonBefore={<UnitSelect name={[field.name, 'rateVoiceNetUnitId']} rules={formRules} />}
                        />
                    </Form.Item>
                ),
                toolBar: (
                    <div className={Styles.removeSection}>
                        <RemoveButton
                            onClick={(): void => removeRateNetwork(currentRateNetworkId)}
                            isHiddenTitle
                            isGhost
                        />
                    </div>
                ),
            };
        });
    };

    return (
        <Catcher>
            <Form.List name="networks">
                {(fields: FormListFieldData[], {}): JSX.Element => {
                    return (
                        <Table
                            className={cx('sub-table-rate')}
                            columns={tableColumns()}
                            dataSource={makeTableDataSource(fields)}
                            pagination={false}
                            showHeader={false}
                            size={'small'}
                        />
                    );
                }}
            </Form.List>
        </Catcher>
    );
};
