import React from 'react';
import { observer } from 'mobx-react';
import { Catcher, ListWrapper } from '../';
import { useOrdersFilterParams } from '../../hooks';
import { cleanOrdersStore, getOrdersListRelationSelect } from '../../middleware';
import { OrdersTableList } from './Components';

export const OrdersList: React.FC = observer(() => {
    const filterParams = useOrdersFilterParams();
    return (
        <Catcher>
            <ListWrapper
                cleanStore={cleanOrdersStore}
                filterParams={filterParams}
                getRelationSelect={getOrdersListRelationSelect}
            >
                <OrdersTableList />
            </ListWrapper>
        </Catcher>
    );
});
