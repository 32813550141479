import { action, computed, makeObservable, observable, reaction } from 'mobx';
import { UiStore } from './ui';
import { appConfig } from '../settings';
import { IFilterParams, IOperatorInteractionsFile, IOperatorInteractionsResponse, ISelectList } from '../interfaces';
import { getOperatorInteractionsFilesList, getOperatorInteractionsResponsesList } from '../middleware';
import { apiParamsCleaner } from '../instruments';

export class OperatorInteractionsStore {
    private constructor() {
        makeObservable(this);
        reaction(
            () => this.forcedFilesListUpdateTime,
            () => {
                const UiStoreInstance = UiStore.getInstance();
                const params = UiStoreInstance.params;
                const cleanParams = apiParamsCleaner(params, this.filterFilesListParams);
                getOperatorInteractionsFilesList(cleanParams).then();
            },
            {
                delay: appConfig.APIDelay,
            },
        );
        reaction(
            () => this.forcedResponsesListUpdateTime,
            () => {
                const UiStoreInstance = UiStore.getInstance();
                const params = UiStoreInstance.params;
                const cleanParams = apiParamsCleaner(params, this.filterResponsesListParams);
                getOperatorInteractionsResponsesList(cleanParams).then();
            },
            {
                delay: appConfig.APIDelay,
            },
        );
    }

    private static instance: OperatorInteractionsStore;
    public static getInstance(): OperatorInteractionsStore {
        if (!OperatorInteractionsStore.instance) {
            OperatorInteractionsStore.instance = new OperatorInteractionsStore();
        }
        return OperatorInteractionsStore.instance;
    }

    defaultResponsesListFilterParams: IFilterParams = {
        commandId: true,
        fileId: true,
        gatewayId: true,
        pageNumber: true,
        pageSize: true,
        search: true,
        uploadFileStatusId: true,
    };

    defaultFilesListFilterParams: IFilterParams = {
        gatewayId: false,
        pageNumber: true,
        pageSize: true,
        search: false,
        uploadFileStatusId: false,
    };

    @observable filesList: IOperatorInteractionsFile[] = [];
    @action setFilesList(filesList: IOperatorInteractionsFile[]): void {
        this.filesList = filesList;
    }

    @computed get isFilesListProcessing(): boolean {
        return this.filesList?.map((i) => i.status).includes('processing');
    }

    @observable responseItem: IOperatorInteractionsResponse = { id: '' };
    @action setResponseItem(responseItem: IOperatorInteractionsResponse): void {
        this.responseItem = responseItem;
    }

    @observable responsesList: IOperatorInteractionsResponse[] = [];
    @action setResponsesList(responsesList: IOperatorInteractionsResponse[]): void {
        this.responsesList = responsesList;
    }

    @observable getewaysSelect: ISelectList = [];
    @action setGetewaysSelectList(list: ISelectList): void {
        this.getewaysSelect = list;
    }

    @observable commandsSelect: ISelectList = [];
    @action setCommandsSelectList(list: ISelectList): void {
        this.commandsSelect = list;
    }
    @observable filesSelect: ISelectList = [];
    @action setFilesSelectList(list: ISelectList): void {
        this.filesSelect = list;
    }
    @observable commandDefault?: string;
    @action setCommandDefault(command?: string): void {
        this.commandDefault = command;
    }

    @action cleanStore(): void {
        this.commandDefault = undefined;
        this.commandsSelect = [];
        this.filesList = [];
        this.filesSelect = [];
        this.filterFilesListParams = this.defaultFilesListFilterParams;
        this.filterResponsesListParams = this.defaultResponsesListFilterParams;
        this.getewaysSelect = [];
        this.responseItem = { id: '' };
        this.responsesList = [];
    }

    @observable forcedFilesListUpdateTime = 0;
    @action setForcedFilesListUpdateTime(): void {
        this.forcedFilesListUpdateTime = Date.now();
    }

    @observable forcedResponsesListUpdateTime = 0;
    @action setForcedResponsesListUpdateTime(): void {
        this.forcedResponsesListUpdateTime = Date.now();
    }

    @observable filterFilesListParams: IFilterParams = this.defaultFilesListFilterParams;
    @action setFilesListFilterParams(): void {
        this.filterFilesListParams = { ...this.defaultFilesListFilterParams };
    }

    @observable filterResponsesListParams: IFilterParams = this.defaultResponsesListFilterParams;
    @action setResponsesListFilterParams(): void {
        this.filterResponsesListParams = { ...this.defaultResponsesListFilterParams };
    }
}
