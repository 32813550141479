import React from 'react';
import { observer } from 'mobx-react';
import { Form, Select } from 'antd';
import { Rule } from 'antd/es/form';
import { filterOption } from './filterOption';
import cx from 'classnames';
import { ISelect, ISelectList } from '../../interfaces';
import { useUiIsLoading } from '../../hooks';
import { formatDataTestAttribute } from '../../instruments/testing';
import { Catcher } from '../Catcher';

// ToDo: 21.08.2020 -   Use throughout the project!

interface IFormSelect {
    dataTest?: string;
    defaultValue?: string;
    disabled?: boolean;
    exceptionIds?: Array<string | undefined>;
    fieldName: string | [number, string];
    isAddonBefore?: boolean;
    isGetPopupContainerEnabled?: boolean;
    isNotClear?: boolean;
    isShortForm?: boolean;
    isShowSearch?: boolean;
    label?: string;
    mode?: 'multiple' | 'tags' | undefined;
    onBlur?: (e: React.FocusEvent<HTMLElement>) => void;
    onChange?: (list: Array<string> | string) => void;
    rules?: Array<Rule>;
    useSelectHook: () => ISelectList;
}

export const FormSelect: React.FC<IFormSelect> = observer(
    ({
        defaultValue,
        disabled = false,
        exceptionIds = [],
        fieldName,
        isAddonBefore,
        isGetPopupContainerEnabled = true,
        isNotClear,
        isShowSearch = true,
        label = '',
        mode,
        onBlur,
        onChange,
        rules = [],
        useSelectHook,
        dataTest = '',
    }: IFormSelect) => {
        const { Option } = Select;
        const isLoading = useUiIsLoading();
        const select = useSelectHook();

        const selectJSX = select
            ?.filter((s) => !exceptionIds || !exceptionIds.includes(s.id))
            ?.map(
                (s: ISelect, index): JSX.Element => (
                    <Option
                        key={s.id}
                        value={s.id}
                        disabled={s.disabled}
                        data-test={formatDataTestAttribute(dataTest, 'FormSelect', `Option${index}`)}
                    >
                        <div>{s.name}</div>
                    </Option>
                ),
            );

        return (
            <Catcher>
                <Form.Item
                    name={fieldName}
                    label={label}
                    rules={rules}
                    className={cx({
                        ['ant-form-item-addonSelect']: isAddonBefore,
                    })}
                    data-test={formatDataTestAttribute(dataTest, 'FormSelect', 'FormItem')}
                >
                    <Select
                        allowClear={!isNotClear}
                        defaultValue={defaultValue}
                        disabled={disabled}
                        filterOption={filterOption}
                        getPopupContainer={
                            isGetPopupContainerEnabled ? (trigger): HTMLElement => trigger.parentElement : undefined
                        }
                        loading={isLoading}
                        mode={mode}
                        onChange={onChange}
                        onBlur={onBlur}
                        placeholder={label}
                        showSearch={isShowSearch}
                        data-test={formatDataTestAttribute(dataTest, 'FormSelect', 'Select')}
                    >
                        {selectJSX}
                    </Select>
                </Form.Item>
            </Catcher>
        );
    },
);
