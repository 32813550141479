import { action, makeObservable, observable, reaction } from 'mobx';
import { appConfig } from '../settings';
import { ICallDataRecordsListItem, IFilterParams, SelectType } from '../interfaces';
import {
    getCallDataRecordsBetaAllCount,
    getCallDataRecordsBetaCleanParams,
    getCallDataRecordsListBeta,
    getCardSetsSelect,
} from '../middleware';

export class CallDataRecordsBetaStore {
    private constructor() {
        makeObservable(this);
        reaction(
            () => this.forcedUpdateTime,
            () => {
                const params = getCallDataRecordsBetaCleanParams();
                const { companyId } = params;
                if (companyId !== this.currentCompanyId) {
                    this.setCurrentCompanyId(companyId);
                    getCardSetsSelect({ companyId }, true);
                }

                getCallDataRecordsListBeta();
                getCallDataRecordsBetaAllCount();
            },
            {
                delay: appConfig.APIDelay,
            },
        );
    }

    private static instance: CallDataRecordsBetaStore;
    public static getInstance(): CallDataRecordsBetaStore {
        if (!CallDataRecordsBetaStore.instance) {
            CallDataRecordsBetaStore.instance = new CallDataRecordsBetaStore();
        }
        return CallDataRecordsBetaStore.instance;
    }

    @observable list: ICallDataRecordsListItem[] = [];
    @action setCallDataRecordsBetaList(data: ICallDataRecordsListItem[]): void {
        this.list = data;
    }

    @observable squashedCount = 0;
    setSquashedCount(count: number): void {
        this.squashedCount = count;
    }

    @observable forcedUpdateTime = 0;
    @action setForcedUpdateTime(): void {
        this.forcedUpdateTime = Date.now();
    }

    @observable filterParams: IFilterParams = {
        cardSetId: true,
        companyId: true,
        operatorId: true,
        dateRange: true,
        defaultDateRangeType: 'day',
        isDateRangeCleanedDefaultURL: true,
        isDateRangeMaxDayToDay: true,
        isDateRangeMinDayOn: true,
        pageNumber: true,
        pageSize: true,
        search: true,
        source: true,
        unitTypeIds: SelectType.MULTIPLE,
    };

    @observable currentCompanyId: string | string[] | undefined;
    setCurrentCompanyId(companyId?: string | string[] | undefined): void {
        this.currentCompanyId = companyId;
    }

    @action cleanStore(): void {
        this.squashedCount = 0;
        this.list = [];
        this.currentCompanyId = '';
    }
}
