import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { useOperatorsSelect } from './useOperatorsStores';
import { appConfig } from '../../settings';
import {
    ICheckRate,
    IRateCountryFormInfo,
    IRateCountryFormInitialValues,
    IRateInfo,
    IRateNetworkItem,
    ISelect,
    ISelectList,
} from '../../interfaces';

export function useRateInfo(): IRateInfo {
    return useContext(MobXProviderContext).RootStore.rateStore.rateInfo;
}

export function useRateDefaultRate(): ICheckRate {
    const rateInfo: IRateInfo = useContext(MobXProviderContext).RootStore.rateStore;
    return {
        rateData: rateInfo.defaultRateData,
        rateDataUnitId: rateInfo.defaultRateDataUnitId,
        rateDataUnit: rateInfo.defaultRateDataUnit,
        rateSms: rateInfo.defaultRateSms,
        rateSmsUnitId: rateInfo.defaultRateSmsUnitId,
        rateSmsUnit: rateInfo.defaultRateSmsUnit,
    };
}

export function useRateList(): IRateCountryFormInfo[] {
    return useContext(MobXProviderContext).RootStore.rateStore.rateCountriesList;
}

export function useRateCountriesList(): IRateCountryFormInitialValues[] {
    return useRateList();
}

export function useRateCustomCountriesCount(): number {
    const defaultRate = useRateDefaultRate();
    return useRateList().filter((i: IRateCountryFormInitialValues) => {
        const { rateData, rateDataUnitId, rateDataUnit, rateSms, rateSmsUnitId, rateSmsUnit } = i;
        const current: ICheckRate = { rateData, rateDataUnitId, rateDataUnit, rateSms, rateSmsUnitId, rateSmsUnit };
        return JSON.stringify(defaultRate) !== JSON.stringify(current);
    }).length;
}

export function useRateNetworksList(): IRateNetworkItem[] {
    return useContext(MobXProviderContext).RootStore.rateStore.rateNetworksList.filter(
        (i: IRateNetworkItem) => !i.destroy,
    );
}

export function useRateCustomNetworksCount(): number {
    const defaultRate = useRateDefaultRate();
    return useRateNetworksList().filter((i: IRateCountryFormInitialValues) => {
        const { rateData, rateDataUnitId, rateDataUnit, rateSms, rateSmsUnitId, rateSmsUnit } = i;
        const current: ICheckRate = { rateData, rateDataUnitId, rateDataUnit, rateSms, rateSmsUnitId, rateSmsUnit };
        return JSON.stringify(defaultRate) !== JSON.stringify(current);
    }).length;
}

export function useRateCountryInitialValues(countryId: string, operatorId: string): IRateCountryFormInitialValues {
    const countries = useRateList();
    const countryInfo = countries.find(
        (r: IRateCountryFormInitialValues) => r.countryId === countryId && r.operatorId === operatorId,
    ) || { id: countryId };
    const networksAll = useRateNetworksList();
    const networks = networksAll.filter(
        (r: IRateCountryFormInitialValues) => r.countryId === countryId && r.operatorId === operatorId,
    );
    return {
        ...countryInfo,
        networks,
    };
}

export function useRateNetworksListByCountry(countryId: string, operatorId: string): IRateNetworkItem[] {
    const list = useRateNetworksList();
    return list.filter((r: IRateCountryFormInitialValues) => r.countryId === countryId && r.operatorId === operatorId);
}

export function useCountryListIsLoading(): boolean {
    const alreadyLoaded = useContext(MobXProviderContext).RootStore.rateStore.countryLoadingStats.alreadyLoaded;
    const expectedToLoad = useContext(MobXProviderContext).RootStore.rateStore.countryLoadingStats.expectedToLoad;

    return alreadyLoaded !== expectedToLoad;
}

export function useIsCountryListLoadingFinished(): boolean {
    const alreadyLoaded = useContext(MobXProviderContext).RootStore.rateStore.countryLoadingStats.alreadyLoaded;
    const expectedToLoad = useContext(MobXProviderContext).RootStore.rateStore.countryLoadingStats.expectedToLoad;

    return alreadyLoaded === expectedToLoad;
}
export function useRatesIsFakeOperator(): boolean {
    return useContext(MobXProviderContext).RootStore.rateStore.isFakeOperatorRateCountriesListOriginal;
}

export function useRateViewListOperatorsSelect(): ISelectList {
    const isFakeOperator = useRatesIsFakeOperator();
    const operatorsSelect = useOperatorsSelect();

    return operatorsSelect.filter((i: ISelect) => isFakeOperator || i.id !== appConfig.fakeId);
}
