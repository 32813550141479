import React from 'react';
import { observer } from 'mobx-react';
import { Catcher, LoadingApiByTypeAndUnit } from '../';
import { DashboardUnitTypes } from '../../interfaces';
import { useUnitTypeByType } from '../../hooks';
import { MarkersCountryTraffic, OverlayView, PolygonsCountryTraffic } from './Components';
import BaseMap from './Components/BaseMap';
import Styles from './m_googleMapsStyles.less';

export const GoogleTrafficMap: React.FC = observer(function Map() {
    const unitType = useUnitTypeByType(DashboardUnitTypes.DATA);

    return (
        <Catcher>
            <div className={Styles.googleTrafficMap}>
                <LoadingApiByTypeAndUnit apiTypeId={`GET_USAGE_COUNTRY_LIST`} unitTypeId={unitType?.id} />
                <BaseMap zoom={2}>
                    <MarkersCountryTraffic />
                    <PolygonsCountryTraffic />
                    <OverlayView />
                </BaseMap>
            </div>
        </Catcher>
    );
});
