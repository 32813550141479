import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Catcher } from '../../';
import { EditPurchaseZoneRate, EditPurchaseZoneTitle } from './';
import cx from 'classnames';
import { usePurchaseListByZoneLength } from '../../../hooks';
import Styles from '../../styles/m_itemCardStyles.less';

interface IZone {
    zoneId: string;
    name: string;
    updateForm?: () => void;
}
export const EditPurchaseZone: React.FC<IZone> = observer(({ zoneId, name, updateForm }: IZone) => {
    const [isVisible, setVisible] = useState(false);
    const length = usePurchaseListByZoneLength(zoneId);

    const onVisibleChange = (): void => {
        setVisible(!isVisible);
    };

    return (
        <Catcher>
            <article className={cx(Styles.cardInfo, Styles.itemBlock)}>
                <EditPurchaseZoneTitle
                    zoneId={zoneId}
                    name={name}
                    visibleChange={onVisibleChange}
                    isVisible={isVisible}
                    updateForm={updateForm}
                    length={length}
                />
                <div>{isVisible ? <EditPurchaseZoneRate zoneId={zoneId} updateForm={updateForm} /> : null}</div>
            </article>
        </Catcher>
    );
});
