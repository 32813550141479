import React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import { Catcher, LinkComponent, TableItemDeleteColumn, TableItemToolbar, columnDeleted, columnToolbar } from '../../';
import cx from 'classnames';
import { ICardSetsListItem } from '../../../interfaces';
import { useCardSetsList, useProfileIsRight, useUiIsDelete, useUiIsLoading } from '../../../hooks';
import { deleteCardSet } from '../../../middleware';
import { tF } from '../../../translate';
import '../../../customStyle/table.less';
import Styles from '../../styles/m_viewStyles.less';

export const CardSetsTableList: React.FC = observer(() => {
    const isLoading = useUiIsLoading();
    const cardSetsList = useCardSetsList();
    const isLord = useProfileIsRight('LORD');
    const isDelete = useUiIsDelete();

    const tableColumns = [
        {
            title: tF('Name'),
            dataIndex: 'linkName',
        },
        {
            title: tF('Company'),
            dataIndex: 'companyLink',
        },
        {
            title: tF('Assigned To'),
            dataIndex: 'assignedCompanyLink',
        },
        {
            title: tF('Cards'),
            dataIndex: 'cardsCount',
            className: Styles.columnRight,
        },
        columnDeleted(isLord && isDelete),
        columnToolbar,
    ];

    const tableData = cardSetsList.map((c: ICardSetsListItem) => {
        const key = c.id;
        const deleteItem = (): void => {
            deleteCardSet(key);
        };
        const name = c.name ? c.name : `# ${c.id}`;

        return {
            key,
            ...c,
            linkName: (
                <LinkComponent
                    currentListComponent={'CardSetsList'}
                    id={c.id}
                    name={c.name}
                    isLight
                    deletedAt={c.deletedAt}
                />
            ),
            deleted: <TableItemDeleteColumn deletedAt={c.deletedAt} />,
            companyLink: (
                <LinkComponent currentListComponent={'CompaniesList'} id={c.companyId} isLight name={c.company} />
            ),
            assignedCompanyLink: (
                <LinkComponent
                    currentListComponent={'CompaniesList'}
                    id={c.assignedCompanyId}
                    isLight
                    name={c.assignedCompany}
                />
            ),
            toolbar: (
                <TableItemToolbar
                    itemName={`"${name}" ${tF('Card Set')}`}
                    id={c.id}
                    deleteItem={deleteItem}
                    deletedAt={c.deletedAt}
                />
            ),
        };
    });

    return (
        <Catcher>
            <div className={cx(Styles.tablePlace)} data-test="CardSetsTableList">
                <Table
                    className={'table-theme'}
                    columns={tableColumns}
                    dataSource={tableData}
                    loading={isLoading}
                    pagination={false}
                    size={'middle'}
                />
            </div>
        </Catcher>
    );
});
