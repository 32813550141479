import { UserStore } from '../stores/user';
import { cleanApiMessages } from './apiMessages';
import { cleanCompaniesStore, getCompaniesSelect } from './companies';
import { appConfig } from '../settings';
import { apiManager } from '../REST';
import {
    IIncludeAboutItemAPI,
    IIncludeCompanyItemAPI,
    IIncludeItemCurrencyAPI,
    IIncludeNameItemAPI,
    IIncludeNameListAPI,
    IPatchItem,
    IRequestAPIBody,
    IUser,
    IUserAPI,
} from '../interfaces';
import { globalHexId, mapApi2UiField, mapUi2ApiField } from '../instruments';

export function mapAPIToUser(apiUser: IUserAPI): IUser {
    if (apiUser.data) {
        const companyId = apiUser?.data.relationships?.company?.data?.id;
        const companyFound = apiUser?.included?.find(
            (i) => i.id === companyId && i.type === 'company',
        ) as IIncludeCompanyItemAPI;
        const aboutFound = apiUser?.included?.find((i) => i.type === 'about') as IIncludeAboutItemAPI;
        const company = companyFound?.attributes?.name;
        const whiteLabel = !!companyFound?.attributes?.whitelabel;

        const actualLogoUrl = companyFound?.attributes.actual_logo_blob;
        const actualDarkLogoUrl = companyFound?.attributes.actual_dark_logo_blob;
        const balance = companyFound?.attributes.balance;
        const geolocationEnabled = companyFound?.attributes.geolocation_enabled;
        const geotrackingEnabled = companyFound?.attributes.geotracking_enabled;
        const subtreeIds = companyFound?.attributes.subtree_ids || [];

        const aboutInfo = aboutFound ? mapApi2UiField(aboutFound?.attributes) : appConfig.about;
        const customLogoLinkUrl = companyFound?.attributes.custom_logo_link_url;
        const helpDeskEnabled = companyFound?.attributes.help_desk_enabled;

        const companyTypeId = companyFound.relationships?.company_type?.data?.id;
        const companyType = (
            apiUser?.included?.find(
                (i) => i.id === companyTypeId && i.type === 'company_type',
            ) as IIncludeCompanyItemAPI
        )?.attributes.name;

        const currencyId = companyFound.relationships?.currency?.data?.id;
        const currencyFound = apiUser?.included?.find(
            (i) => i.id === currencyId && i.type === 'currency',
        ) as IIncludeItemCurrencyAPI;
        const currency = currencyFound?.attributes?.symbol || currencyFound?.attributes?.abbreviation;

        const userTypeId = apiUser?.data.relationships?.user_type?.data?.id;
        const userType = (
            apiUser?.included?.find((i) => i.id === userTypeId && i.type === 'user_type') as IIncludeCompanyItemAPI
        )?.attributes.name;
        let mapAttributes: IUser = { id: apiUser?.data?.id };
        if (apiUser?.data?.attributes) {
            mapAttributes = {
                ...mapAttributes,
                ...mapApi2UiField(apiUser?.data?.attributes),
            };
        }
        return {
            actualDarkLogoUrl,
            actualLogoUrl,
            balance,
            company,
            companyId,
            companyType,
            companyTypeId,
            currency,
            currencyId,
            aboutInfo,
            customLogoLinkUrl,
            geolocationEnabled,
            geotrackingEnabled,
            helpDeskEnabled,
            subtreeIds,
            userType,
            userTypeId,
            whiteLabel,
            ...mapAttributes,
        };
    }
    return { id: '' };
}

export function setUserStore(apiUser: IUserAPI): void {
    if (apiUser.data?.id) {
        const UserInstance = UserStore.getInstance();
        UserInstance.setUser(mapAPIToUser(apiUser));
    }
}

export async function getUserById(id: string): Promise<boolean> {
    const { resp, isError } = await apiManager.getApi('GET_USER', { id: id });
    !isError && resp && setUserStore(resp as IUserAPI);
    return !isError;
}

export function setUserTypesStore(data: IIncludeNameListAPI): void {
    if (data) {
        const UserInstance = UserStore.getInstance();
        UserInstance.setUserTypes(
            data.map((t: IIncludeNameItemAPI) => {
                return { id: t.id, name: t.attributes?.name };
            }),
        );
    }
}

export async function getUserTypes(): Promise<void> {
    const { resp, isError } = await apiManager.getApi('GET_USER_TYPE_SELECT');
    !isError && resp && setUserTypesStore(resp.data as IIncludeNameListAPI);
}

export async function saveUser(id: string, fields: IUser, isNew: boolean): Promise<IPatchItem> {
    const apiBody: IRequestAPIBody = {
        data: {
            id: isNew ? globalHexId.getId() : id,
            type: 'user',
            attributes: {
                ...mapUi2ApiField(fields),
            },
        },
    };

    if (isNew) {
        const { resp, isError } = await apiManager.getApi('POST_USER', {}, apiBody);
        const { errors } = await resp;
        return { isError, errors };
    }
    const { resp, isError } = await apiManager.getApi('PATCH_USER', { id: id }, apiBody);
    const { errors } = await resp;
    if (!isError && resp) {
        setUserStore(resp as IUserAPI);
    }
    return { isError, errors };
}

export async function patchPassword(fields: IUser): Promise<IPatchItem> {
    const apiBody: IRequestAPIBody = {
        data: {
            type: 'user',
            attributes: {
                ...mapUi2ApiField(fields),
            },
        },
    };
    const { resp, isError } = await apiManager.getApi('PATCH_PASSWORD', {}, apiBody);
    const { errors } = await resp;

    return { isError, errors };
}

export function getUserRelationSelect(): void {
    cleanApiMessages();
    getCompaniesSelect(true);
    getUserTypes();
}

export function cleanUserRelationSelect(): void {
    cleanCompaniesStore();
    cleanApiMessages();
}

export function cleanUserStore(): void {
    const UserInstance = UserStore.getInstance();
    UserInstance.cleanStore();
}
