import React from 'react';
import { observer } from 'mobx-react';
import { Progress, Table } from 'antd';
import { Catcher, LinkComponent, LoadingApiByIds, TableItemToolbar, columnToolbar } from '../../';
import { IPackageSoldInfo, IPackagesTable } from '../../../interfaces';
import { usePackagesSold, usePagesStoreThisListRight, useProfileIsRight } from '../../../hooks';
import { deletePackageInstance } from '../../../middleware';
import { tF, tI } from '../../../translate';
import { PackageCountDown } from '../../Package/Components';

export const TableForBought: React.FC<IPackagesTable> = observer(({ pageSize, pageNumber, search }: IPackagesTable) => {
    const right = usePagesStoreThisListRight();
    const data = usePackagesSold();
    const isLord = useProfileIsRight('LORD');

    const tableColumns = [
        {
            title: tF('Package Owner'),
            dataIndex: 'companyLink',
        },
        {
            title: tF('Package Name'),
            dataIndex: 'packageLink',
        },
        {
            title: tF('Data left'),
            dataIndex: 'dataVolumeNameLeft',
        },
        {
            title: tF('Package Size'),
            dataIndex: 'dataVolumeName',
        },
        {
            title: tF('Expires in'),
            dataIndex: 'isExpiredName',
        },
        {
            title: tF('Zone'),
            dataIndex: 'zoneLink',
        },
        {
            title: tF('Assigned Cardset'),
            dataIndex: 'cardSetLink',
        },
        {
            title: tF('Credit Remains'),
            dataIndex: 'creditRemainsName',
        },
        {
            title: tF('Auto renewal'),
            dataIndex: 'renewalName',
        },
        columnToolbar,
    ];

    const tableData = data?.map((p: IPackageSoldInfo) => {
        const {
            dataVolumeNameLeft,
            bytesLeftPercentage,
            isExpired,
            creditRemains,
            dataVolume,
            expiresDate,
            createdAt,
            renewal,
            ...rows
        } = p;
        const isAvailableForDelete = isExpired && (!creditRemains || (!!dataVolume && dataVolume <= 0)); //:TODO move to the package body
        const params = {
            searchParamsList: {
                pageNumber,
                pageSize,
                search,
            },
        };

        const deleteItem = (): void => {
            deletePackageInstance(p.id, params);
        };
        const showPackageName = p.packageName ? p.packageName : `# ${p.id}`;

        return {
            key: p.id,
            ...rows,
            companyLink: <LinkComponent currentListComponent={'CompaniesList'} id={p.companyId} name={p.companyName} />,
            packageLink: <LinkComponent currentListComponent={'PackagesBuy'} id={p.id} name={p.packageName} />,
            zoneLink: <LinkComponent currentListComponent={'ZonesList'} id={p.zoneId} name={p.zoneName} />,
            cardSetLink: <LinkComponent currentListComponent={'CardSetsList'} id={p.cardSetId} name={p.cardSetName} />,
            dataVolumeNameLeft: (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column-reverse',
                    }}
                >
                    <Progress
                        percent={bytesLeftPercentage}
                        showInfo={false}
                        trailColor={'#ff4d4f'}
                        strokeColor={'#1890ff'}
                    />
                    <span title={dataVolumeNameLeft}>{dataVolumeNameLeft}</span>
                </div>
            ),
            isExpiredName: (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column-reverse',
                        width: '150px',
                    }}
                >
                    <PackageCountDown
                        expiresDate={expiresDate}
                        createdAt={createdAt}
                        isExpired={isExpired}
                        isRenewed={renewal}
                    />
                </div>
            ),
            toolbar: (
                <TableItemToolbar
                    itemName={`"${showPackageName}" ${tI('package')}`}
                    id={p.id}
                    {...right}
                    currentListComponent={'PackagesBuy'}
                    deleteItem={deleteItem}
                    isDelete={isAvailableForDelete || isLord}
                    isEdit={!isAvailableForDelete}
                />
            ),
        };
    });

    return (
        <Catcher>
            <div>
                <LoadingApiByIds idSpinners={['GET_BOUGHT_PACKAGES']} />
                <Table
                    className={'table-theme'}
                    columns={tableColumns}
                    dataSource={tableData}
                    pagination={false}
                    size={'middle'}
                />
            </div>
        </Catcher>
    );
});
