import React from 'react';
import { observer } from 'mobx-react';
import { DatePicker, Form, Table } from 'antd';
import { Rule } from 'antd/es/form';
import { FormListFieldData } from 'antd/es/form/FormList';
import { ICompanyRateItem, IEditFormComponentList, IFormValidatorResponse, IRuleAny } from '../../../interfaces';
import { useCompanySalesRateList, useProfileIsRight, useRatesSelect } from '../../../hooks';
import { addCompanyRate, apiMessagesDeleteMessageByFieldName, apiMessagesGetListError } from '../../../middleware';
import { globalHexId } from '../../../instruments';
import { formatDataTestAttribute } from '../../../instruments/testing';
import { tF } from '../../../translate';
import { CardLayout, Catcher, FieldAlert, FormSelect, RemoveButton, cardLayoutOptions } from '../../../Components';
import Styles from '../../styles/m_itemCardStyles.less';
import ViewStyles from '../../styles/m_viewStyles.less';

export const EditCompanySalesRateList: React.FC<IEditFormComponentList> = observer(
    ({ deleteItem }: IEditFormComponentList) => {
        const rateListHook = useRatesSelect;
        const rateList = useCompanySalesRateList();
        const isLord = useProfileIsRight('LORD');
        const rateListIds = rateList?.map((i) => i.id) || [];
        const apiMessagesListRules: Array<Rule> = [
            {
                validator(rule: IRuleAny): IFormValidatorResponse {
                    return apiMessagesGetListError({
                        listName: 'salesRateList',
                        idsList: rateListIds,
                        fieldName: rule.field,
                    });
                },
            },
        ];
        const rateIds = rateList?.map((i) => i.rateId) || [];

        const addRate = (): void => {
            const newRate: ICompanyRateItem = { id: globalHexId.getId() };
            addCompanyRate(newRate);
            apiMessagesDeleteMessageByFieldName(['salesRateList']);
        };

        const tableColumns = [
            {
                title: (
                    <span data-test={formatDataTestAttribute('EditCompanySalesRateList', 'rateList', 'Header')}>
                        {tF('Rate List')}
                    </span>
                ),
                dataIndex: 'rateList',
                ellipsis: true,
            },
            {
                title: (
                    <span data-test={formatDataTestAttribute('EditCompanySalesRateList', 'dateStart', 'Header')}>
                        {tF('Start Date')}
                    </span>
                ),
                dataIndex: 'dateStart',
                width: 180,
            },
            {
                title: (
                    <span data-test={formatDataTestAttribute('EditCompanySalesRateList', 'dateEnd', 'Header')}>
                        {tF('End Date')}
                    </span>
                ),
                dataIndex: 'dateEnd',
                width: 180,
            },
            deleteItem
                ? {
                      title: '',
                      dataIndex: 'toolBar',
                      className: ViewStyles.columnToolbarDelete,
                  }
                : {},
        ];

        return (
            <Catcher>
                <CardLayout
                    columnOption={cardLayoutOptions.rateList}
                    count={rateList.length}
                    idSpinners={['GET_COMPANY']}
                    isTable
                    onClickAddButton={isLord ? addRate : undefined}
                    title={'Rates list'}
                    dataTest={'EditCompanySalesRateList'}
                >
                    {rateList.length ? (
                        <Form.List name="salesRateList">
                            {(fields: FormListFieldData[], {}): JSX.Element => {
                                const dataSource = fields.map((field: FormListFieldData, index) => {
                                    const currentRate = rateList?.[field.key];
                                    const recordId = currentRate?.id || '';
                                    const currentRateId = currentRate?.rateId || '';
                                    const exceptionRateIds = rateIds.filter(
                                        (i) => !currentRateId || i !== currentRateId,
                                    );

                                    const isNew = currentRate?.id.includes('new:');
                                    const isActive = currentRate?.isActive || isNew;
                                    const isDeletable = currentRate?.isDeletable || isNew;

                                    return {
                                        key: field.key,
                                        rateList: (
                                            <FormSelect
                                                dataTest={'EditCompanySalesRateListRateList' + index}
                                                disabled={!isActive}
                                                exceptionIds={exceptionRateIds}
                                                fieldName={[field.name, 'rateId']}
                                                isGetPopupContainerEnabled={false}
                                                isShortForm
                                                rules={apiMessagesListRules}
                                                useSelectHook={rateListHook}
                                            />
                                        ),
                                        dateStart: (
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'startDate']}
                                                key={'startDate'}
                                                rules={apiMessagesListRules}
                                            >
                                                <DatePicker
                                                    data-test={'EditCompanySalesRateListStartDate' + index}
                                                    disabled={!isActive}
                                                />
                                            </Form.Item>
                                        ),
                                        dateEnd: (
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'endDate']}
                                                key={'endDate'}
                                                rules={apiMessagesListRules}
                                            >
                                                <DatePicker
                                                    data-test={'EditCompanySalesRateListDateEnd' + index}
                                                    disabled={!isActive}
                                                />
                                            </Form.Item>
                                        ),
                                        toolBar:
                                            isLord && deleteItem ? (
                                                <RemoveButton
                                                    dataTest={'EditCompanySalesRateListRemoveButton' + index}
                                                    disabled={!isDeletable}
                                                    isGhost
                                                    onClick={(): void => deleteItem(recordId)}
                                                />
                                            ) : null,
                                    };
                                });
                                return (
                                    <Table
                                        dataSource={dataSource}
                                        columns={tableColumns}
                                        pagination={false}
                                        className={'table-theme sub-table'}
                                        size={'small'}
                                    />
                                );
                            }}
                        </Form.List>
                    ) : null}
                    <div className={Styles.alert}>
                        <FieldAlert fieldName={'salesRateList'} />
                    </div>
                </CardLayout>
            </Catcher>
        );
    },
);
