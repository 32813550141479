import { action, makeObservable, observable, reaction } from 'mobx';
import { UiStore } from './ui';
import { appConfig } from '../settings';
import {
    DashboardUnitTypes,
    IActivityRollIsNotBigQuery,
    ICardSetRevenue,
    ICardSetRevenueItem,
    ICardSetUsage,
    ICardsRevenue,
    ICardsRevenueItem,
    ICardsUsage,
    ICompanyRevenue,
    ICompanyRevenueItem,
    ICompanyUsage,
    ICountryRevenue,
    ICountryRevenueItem,
    ICountryUsage,
    IDashboardType,
    IFilterParams,
    IFinancesRollCards,
    IFinancesUsageAPI,
    IOperatorRevenueItem,
    IOverlayView,
    IParamsList,
    IStatItem,
    ITelecomRevenue,
    ITelecomUsage,
    ITimelineRevenue,
    ITimelineRevenueItem,
    ITimelineUsage,
    ITimelineZoneItem,
    IUsageActivityRoll,
    IUsageMap,
    IZoneRevenue,
    IZoneUsage,
    SelectType,
} from '../interfaces';
import {
    abortAllDashboardFetch,
    getDashboardActivityRoll,
    getDashboardCardSetRevenueList,
    getDashboardCardSetUsageList,
    getDashboardCardsRevenueList,
    getDashboardCardsUsageList,
    getDashboardCompanyRevenue,
    getDashboardCompanyUsageList,
    getDashboardCountryRevenueList,
    getDashboardCountryUsageList,
    getDashboardFinancesUsage,
    getDashboardRelationSelect,
    getDashboardTelecomRevenueList,
    getDashboardTelecomUsageList,
    getDashboardTimelineCompanyRevenueList,
    getDashboardTimelineUsageList,
    getDashboardZoneRevenueList,
    getDashboardZoneUsageList,
    getFinanceRelationSelect,
    getUnitTypesSelect,
} from '../middleware';
import { apiParamsCleaner } from '../instruments';

export class DashboardStore {
    private constructor() {
        makeObservable(this);
        reaction(
            () => this.forcedUpdateTime,
            () => {
                this.forcedUpdateReaction();
            },
            {
                delay: appConfig.APIDelay,
            },
        );
    }

    private static instance: DashboardStore;
    public static getInstance(): DashboardStore {
        if (!DashboardStore.instance) {
            DashboardStore.instance = new DashboardStore();
        }
        return DashboardStore.instance;
    }

    // ------------ Activity Usage ------------
    @observable activityRollIsNotBigQuery: IActivityRollIsNotBigQuery = {};
    @action setActivityRollIsNotBigQuery(data: IActivityRollIsNotBigQuery): void {
        this.activityRollIsNotBigQuery = data;
    }
    @observable usageActivity: IUsageActivityRoll = { costs: [], statsByUnitType: [] };
    @action setUsageActivity(data: IUsageActivityRoll): void {
        this.usageActivity = data;
    }

    // ------------ Finances Usage ------------
    @observable financesIsNotBigQueryUsage: IFinancesUsageAPI = {};
    @action setFinancesIsNotBigQueryUsage(data: IFinancesUsageAPI): void {
        this.financesIsNotBigQueryUsage = data;
    }
    @observable financesRollCards: IFinancesRollCards = { active: 0, inactive: 0 };
    @action setFinancesRollCards(data: IFinancesRollCards): void {
        this.financesRollCards = data;
    }
    @observable financesRollStats: Array<IStatItem> = [];
    @action setFinancesRollStats(data: Array<IStatItem>): void {
        this.financesRollStats = data;
    }
    @observable financesRollVolume = '0';
    @action setFinancesRollVolume(data: string): void {
        this.financesRollVolume = data;
    }

    // ------------ countryUsage ------------
    @observable countryUsageList: Array<ICountryUsage> = [];
    @action setCountryUsageList(data: Array<ICountryUsage>): void {
        this.countryUsageList = data;
    }

    @observable countryVoiceUsageList: Array<ICountryUsage> = [];
    @action setCountryVoiceUsageList(data: Array<ICountryUsage>): void {
        this.countryVoiceUsageList = data;
    }

    // ------------ Card Set Usage ------------
    @observable cardSetUsageList: Array<ICardSetUsage> = [];
    @action setCardSetUsageList(data: Array<ICardSetUsage>): void {
        this.cardSetUsageList = data;
    }

    @observable cardSetVoiceUsageList: Array<ICardSetUsage> = [];
    @action setCardSetVoiceUsageList(data: Array<ICardSetUsage>): void {
        this.cardSetVoiceUsageList = data;
    }

    // ------------ Cards Usage ------------
    @observable cardsUsageList: Array<ICardsUsage> = [];
    @action setCardsUsageList(data: Array<ICardsUsage>): void {
        this.cardsUsageList = data;
    }

    @observable cardsVoiceUsageList: Array<ICardsUsage> = [];
    @action setCardsVoiceUsageList(data: Array<ICardsUsage>): void {
        this.cardsVoiceUsageList = data;
    }

    // ------------ Timeline Usage ------------
    @observable timelineUsageList: Array<ITimelineUsage> = [];
    @action setTimelineUsageList(data: Array<ITimelineUsage>): void {
        this.timelineUsageList = data;
    }

    // ------------ companyUsage ------------
    @observable companyUsageList: Array<ICompanyUsage> = [];
    @action setCompanyUsageList(data: Array<ICompanyUsage>): void {
        this.companyUsageList = data;
    }
    // ------------ ZoneUsage ------------
    @observable zoneUsageList: Array<IZoneUsage> = [];
    @action setZoneUsageList(data: Array<IZoneUsage>): void {
        this.zoneUsageList = data;
    }
    // ------------ TelecomUsage ------------
    @observable telecomUsageList: Array<ITelecomUsage> = [];
    @action setTelecomUsageList(data: Array<ITelecomUsage>): void {
        this.telecomUsageList = data;
    }

    // ------------ CompaniesRevenue ------------
    @observable companyRevenueIsNotBigQueryList: Array<ICompanyRevenue> = [];
    @action setCompaniesRevenueIsNotBigQueryList(data: Array<ICompanyRevenue>): void {
        this.companyRevenueIsNotBigQueryList = data;
    }
    @observable companyRevenueIsBigQueryList: Array<ICompanyRevenueItem> = [];
    @action setCompaniesRevenueIsBigQueryList(data: Array<ICompanyRevenueItem>): void {
        this.companyRevenueIsBigQueryList = data;
    }
    // ------------ CardSet Revenue ------------
    @observable cardSetRevenueIsNotBigQueryList: Array<ICardSetRevenue> = [];
    @action setCardSetRevenueIsNotBigQueryList(data: Array<ICardSetRevenue>): void {
        this.cardSetRevenueIsNotBigQueryList = data;
    }
    @observable cardSetRevenueIsBigQueryList: Array<ICardSetRevenueItem> = [];
    @action setCardSetRevenueIsBigQueryList(data: Array<ICardSetRevenueItem>): void {
        this.cardSetRevenueIsBigQueryList = data;
    }
    // ------------ Cards Revenue ------------
    @observable cardsRevenueIsNotBigQueryList: Array<ICardsRevenue> = [];
    @action setCardsRevenueIsNotBigQueryList(data: Array<ICardsRevenue>): void {
        this.cardsRevenueIsNotBigQueryList = data;
    }
    @observable cardsRevenueIsBigQueryList: Array<ICardsRevenueItem> = [];
    @action setCardsRevenueIsBigQueryList(data: Array<ICardsRevenueItem>): void {
        this.cardsRevenueIsBigQueryList = data;
    }

    // ------------ EditPurchaseZoneRate Revenue ------------
    @observable countryRevenueIsNotBigQueryList: Array<ICountryRevenue> = [];
    @action setCountryRevenueIsNotBigQueryList(data: Array<ICountryRevenue>): void {
        this.countryRevenueIsNotBigQueryList = data;
    }
    @observable countryRevenueIsBigQueryList: Array<ICountryRevenueItem> = [];
    @action setCountryRevenueIsBigQueryList(data: Array<ICountryRevenueItem>): void {
        this.countryRevenueIsBigQueryList = data;
    }

    // ------------ Zone Revenue ------------
    @observable zoneRevenueIsNotBigQueryList: Array<IZoneRevenue> = [];
    @action setZoneRevenueIsNotBigQueryList(data: Array<IZoneRevenue>): void {
        this.zoneRevenueIsNotBigQueryList = data;
    }
    @observable zoneRevenueIsBigQueryList: Array<ITimelineZoneItem> = [];
    @action setZoneRevenueIsBigQueryList(data: Array<ITimelineZoneItem>): void {
        this.zoneRevenueIsBigQueryList = data;
    }

    // ------------ Operator/Telecom Revenue ------------
    @observable telecomRevenueIsNotBigQueryList: Array<ITelecomRevenue> = [];
    @action setTelecomRevenueIsNotBigQueryList(data: Array<ITelecomRevenue>): void {
        this.telecomRevenueIsNotBigQueryList = data;
    }
    @observable telecomRevenueIsBigQueryList: Array<IOperatorRevenueItem> = [];
    @action setTelecomRevenueIsBigQueryList(data: Array<IOperatorRevenueItem>): void {
        this.telecomRevenueIsBigQueryList = data;
    }

    // ------------ TimelineRevenue ------------
    @observable timelineRevenueIsNotBigQueryList: Array<ITimelineRevenue> = [];
    @action setTimelineRevenueNotBigQueryList(data: Array<ITimelineRevenue>): void {
        this.timelineRevenueIsNotBigQueryList = data;
    }
    @observable timelineRevenueIsBigQueryList: Array<ITimelineRevenueItem> = [];
    @action setTimelineRevenueIsBigQueryList(data: Array<ITimelineRevenueItem>): void {
        this.timelineRevenueIsBigQueryList = data;
    }

    // ------------ filterParams ------------
    @observable filterParamsFinance: IFilterParams = {
        companyId: true,
        dateRange: true,
        cardSetId: true,
        operatorId: true,
        defaultDateRangeType: 'month',
        isDateRangeCleanedDefaultURL: true,
        isDateRangeMaxDayToDay: true,
        isDateRangeMinDayOn: true,
        unitTypeIds: SelectType.SINGLE,
    };

    @observable filterParamsDashboard: IFilterParams = {
        dateRange: true,
    };

    // ------------ main Store ------------
    @observable dashboardType: IDashboardType = 'Main';
    @action setDashboardType(type: IDashboardType): void {
        this.dashboardType = type;
    }

    @observable forcedUpdateTime = 0;
    @action setForcedUpdateTime(): void {
        this.forcedUpdateTime = +Date.now();
    }

    @observable prevParams: IParamsList = {};

    async forcedUpdateReaction(): Promise<void> {
        const UiStoreInstance = UiStore.getInstance();
        const params = UiStoreInstance.params;
        const isUpdateTimeLine =
            this.dashboardType === 'Finances' &&
            (this.prevParams.companyId !== params.companyId || this.prevParams.unitTypeIds !== params.unitTypeIds);

        abortAllDashboardFetch(
            isUpdateTimeLine ? [] : ['GET_USAGE_TIMELINE_LIST', 'GET_REVENUE_TIMELINE_LIST', 'GET_COMPANY_STATISTICS'],
        );

        switch (this.dashboardType) {
            case 'Main':
                const cleanDashboardParams = apiParamsCleaner(params, this.filterParamsDashboard);
                await getDashboardRelationSelect();
                getDashboardActivityRoll();
                getDashboardTimelineUsageList({});
                // ------------ Data ------------
                const unitTypeDataIds: string | undefined = getUnitTypesSelect(DashboardUnitTypes.DATA)?.id;
                getDashboardCountryUsageList({ ...cleanDashboardParams, unitTypeIds: unitTypeDataIds });
                getDashboardCardSetUsageList({ ...cleanDashboardParams, unitTypeIds: unitTypeDataIds });
                getDashboardCardsUsageList({ ...cleanDashboardParams, unitTypeIds: unitTypeDataIds });
                // ------------ Voice ------------
                const unitTypeVoiceIds: string | undefined = getUnitTypesSelect(DashboardUnitTypes.VOICE)?.id;
                getDashboardCountryUsageList({ ...cleanDashboardParams, unitTypeIds: unitTypeVoiceIds });
                getDashboardCardSetUsageList({ ...cleanDashboardParams, unitTypeIds: unitTypeVoiceIds });
                getDashboardCardsUsageList({ ...cleanDashboardParams, unitTypeIds: unitTypeVoiceIds });
                break;
            case 'Finances': {
                const cleanFinanceParams = apiParamsCleaner(params, this.filterParamsFinance);
                await getFinanceRelationSelect(params);
                getDashboardCompanyUsageList(cleanFinanceParams);
                getDashboardCompanyRevenue(cleanFinanceParams);
                getDashboardCardSetUsageList(cleanFinanceParams);
                getDashboardCardSetRevenueList(cleanFinanceParams);
                getDashboardCountryUsageList(cleanFinanceParams);
                getDashboardCountryRevenueList(cleanFinanceParams);
                getDashboardCardsUsageList(cleanFinanceParams);
                getDashboardCardsRevenueList(cleanFinanceParams);
                getDashboardZoneUsageList(cleanFinanceParams);
                getDashboardZoneRevenueList(cleanFinanceParams);
                getDashboardTelecomUsageList(cleanFinanceParams);
                getDashboardTelecomRevenueList(cleanFinanceParams);
                getDashboardFinancesUsage(cleanFinanceParams);
                if (isUpdateTimeLine) {
                    getDashboardTimelineUsageList(cleanFinanceParams);
                    getDashboardTimelineCompanyRevenueList(cleanFinanceParams);
                }
                break;
            }
            default:
                break;
        }
        this.prevParams = params;
    }

    @action cleanStore(): void {
        this.usageActivity = { costs: [], statsByUnitType: [] };
        this.activityRollIsNotBigQuery = {};
        this.cardSetRevenueIsBigQueryList = [];
        this.cardSetRevenueIsNotBigQueryList = [];
        this.cardSetUsageList = [];
        this.cardsRevenueIsBigQueryList = [];
        this.cardsRevenueIsNotBigQueryList = [];
        this.cardsUsageList = [];
        this.companyRevenueIsBigQueryList = [];
        this.companyRevenueIsNotBigQueryList = [];
        this.companyUsageList = [];
        this.countryRevenueIsBigQueryList = [];
        this.countryRevenueIsNotBigQueryList = [];
        this.countryUsageList = [];
        this.mapOverlayView = null;
        this.prevParams = {};
        this.telecomRevenueIsBigQueryList = [];
        this.telecomRevenueIsNotBigQueryList = [];
        this.telecomUsageList = [];
        this.timelineRevenueIsNotBigQueryList = [];
        this.timelineUsageList = [];
        this.zoneRevenueIsBigQueryList = [];
        this.zoneRevenueIsNotBigQueryList = [];
        this.zoneUsageList = [];
        this.usageData.clear();
    }

    @action cleanFinanceHeader(): void {
        this.financesIsNotBigQueryUsage = {};
    }

    @observable usageData = new Map<string, IUsageMap>();
    @action setUsageData(dataName: string, value: IUsageMap): void {
        this.usageData.set(dataName, value);
    }

    @observable mapOverlayView: IOverlayView = null;

    @action setMapOverlayView(overlayView: IOverlayView): void {
        this.mapOverlayView = overlayView;
    }
}
