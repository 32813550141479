import { CardsStore } from '../stores/cards';
import { cleanCardSetsStore, getCardSetsSelect } from './cardSets';
import { setUiTotal } from './ui';
import { apiManager } from '../REST/';
import { ICardListItem, ICardListItemAPI, IIncludeCardItemAPI, IParamsList, ISelectList } from '../interfaces';
import { mapApi2UiField } from '../instruments';

export function setCardsList(list: ICardListItem[]): void {
    const CardsStoreInstance = CardsStore.getInstance();
    list && CardsStoreInstance.setCardsList(list);
}

export async function getCardListCount(params: IParamsList): Promise<void> {
    const { resp } = await apiManager.getApi('GET_CARD_LIST_COUNT', { searchParamsList: params });
    const { records_count: count } = resp as {
        records_count?: number;
    };
    setUiTotal(count ?? 0);
}

export async function getCardList(params: IParamsList): Promise<void> {
    const { resp } = await apiManager.getApi('GET_CARD_LIST', { searchParamsList: params });
    const { data, included } = resp;
    const cardSetsList = included?.filter((i) => i.type === 'card_set');
    const getData = data ? (data as ICardListItemAPI[]) : [];
    const list: ICardListItem[] = getData.map((c: ICardListItemAPI) => {
        const cardSets: ISelectList =
            c.relationships?.card_sets?.data?.map((s) => {
                const cardSetsName = cardSetsList?.find((f) => f.id === s.id)?.attributes?.name || s.id;
                return { id: s.id, name: cardSetsName };
            }) || [];
        return {
            id: c.id || 'xxx',
            ...mapApi2UiField(c.attributes || {}),
            cardSets,
        };
    });
    setCardsList(list);
}

export function setCardsForcedUpdateTime(): void {
    const CardsStoreInstance = CardsStore.getInstance();
    CardsStoreInstance.setForcedUpdateTime();
}

export function getCardsRelationSelect(): void {
    getCardSetsSelect({});
}

export function cleanCardsStore(): void {
    cleanCardSetsStore();
    const CardStoreInstance = CardsStore.getInstance();
    CardStoreInstance.cleanStore();
}

export function setCardsSelect(data: IIncludeCardItemAPI[]): void {
    const StoreInstance = CardsStore.getInstance();
    StoreInstance.setSelect(
        data.map((i: IIncludeCardItemAPI) => {
            const name =
                i.attributes?.name && i.attributes?.short_id
                    ? `${i.attributes.name} - ${i.attributes.short_id}`
                    : i.attributes?.name
                    ? i.attributes.name
                    : i.attributes?.short_id
                    ? i.attributes.short_id
                    : i.id;
            return { id: i.id, name };
        }),
    );
}
