import React from 'react';
import { observer } from 'mobx-react';
import { Catcher, ListWrapper } from '../';
import { useOperatorsFilterParams } from '../../hooks';
import { cleanOperatorsStore } from '../../middleware';
import { OperatorsTableList } from './Components';

export const OperatorsList: React.FC = observer(() => {
    const filterParams = useOperatorsFilterParams();

    return (
        <Catcher>
            <ListWrapper cleanStore={cleanOperatorsStore} filterParams={filterParams}>
                <OperatorsTableList />
            </ListWrapper>
        </Catcher>
    );
});
