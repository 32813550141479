import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Checkbox, Divider, Form, Row } from 'antd';
import { EuroCircleOutlined } from '@ant-design/icons';
import cx from 'classnames';
import { FieldData } from 'rc-field-form/es/interface';
import { IGetApiType } from '../../REST';
import {
    IAntDFormChangeFieldNameLong,
    IFormFields,
    IItemAPI,
    IPostPackageOrderAttributes,
    IResponsePostCheckOutDataAPI,
    IShowFormComponent,
} from '../../interfaces';
import {
    useCompaniesChildrenSelect,
    usePackageInfo,
    usePackagePaymentTypes,
    useProfile,
    useProfileIsRight,
    useRouterStore,
} from '../../hooks';
import {
    abortAllFetch,
    addNotification,
    apiMessagesGetFormRules,
    apiMessagesGetFormRulesFieldBlank,
    apiMessagesSaveMessages,
    checkOutOrder,
    cleanApiMessages,
    cleanPackageSoldRelationSelect,
    cleanPackageStore,
    getCompaniesChildrenSelect,
    getPackageToBuyById,
    getPaymentTypeSelect,
    postPackageOrder,
} from '../../middleware';
import { onFieldErrorHandle } from '../../instruments';
import { tB, tF, tI, tNM } from '../../translate';
import { CardInfoToolBar, CardMainLayout, Catcher, FieldAlert, FormSelect, cardLayoutOptions } from '../../Components';
import { PackageInfoBox } from './Components';
import Styles from '../styles/m_itemCardStyles.less';

export const PackageInstanceBuy: React.FC<IShowFormComponent> = observer(() => {
    const [form] = Form.useForm();
    const idSpinners: IGetApiType[] = ['GET_ONE_PACKAGE', 'GET_ONE_PACKAGE_TO_BUY'];
    const packageInfo = usePackageInfo();
    const { name, price = 0, id = '', companyId: packageCompanyId = '' } = packageInfo;
    const routerStore = useRouterStore();
    const isLord = useProfileIsRight('LORD');
    const [isSending, setSending] = useState(false);
    const { companyId = '' } = useProfile();

    const fetchPackageById = async (id: string): Promise<void> => {
        const isError = await getPackageToBuyById(id);
        if (isError) {
            addNotification({
                type: 'error',
                message: tNM('Not Found'),
                description: `${tI('package')} id - "${id}" ${tI('not found')}`,
                duration: 5,
                isTranslated: true,
                isUsersError: true,
            });
            routerStore.goTo('PackagesSell');
        }
    };

    useEffect(() => {
        const { params } = routerStore.routerState;

        fetchPackageById(params.id);
        getPaymentTypeSelect();

        return (): void => {
            abortAllFetch(); // ToDo: 07.04.2021 - On remake use abortSelectedFetch()
            cleanPackageSoldRelationSelect();
            cleanPackageStore();
        };
    }, []);

    useEffect(() => {
        if (packageCompanyId) {
            getCompaniesChildrenSelect(packageCompanyId);
        }
    }, [packageCompanyId]);

    const onFinishEditOrder = async (fields: IFormFields): Promise<void> => {
        setSending(true);
        const { paymentTypeId, companyId: fieldCompanyId, renewal } = fields;

        const attributes: IPostPackageOrderAttributes = {
            cost: +price,
            paymentTypeId,
            companyId: isLord ? fieldCompanyId : companyId,
            options: {
                renewal,
            },
        };

        const { isError, errors = [], resp } = await postPackageOrder(id, attributes);

        if (isError) {
            cleanApiMessages();
            apiMessagesSaveMessages(errors);
            form.validateFields();
        } else {
            const ordersId = (resp?.data as IItemAPI).id;
            if (ordersId) {
                const { isError, errors = [], resp: respCheckOut } = await checkOutOrder(ordersId);

                if (isError) {
                    cleanApiMessages();
                    apiMessagesSaveMessages(errors);
                    form.validateFields();
                } else {
                    const respData = (respCheckOut?.data || {}) as IResponsePostCheckOutDataAPI;
                    if (respData?.attributes?.payment_method === 'redirect' && !!respData?.attributes?.payment_url) {
                        window.open(respData?.attributes?.payment_url, '_blank');
                    }
                    routerStore.goTo('OrderView', { params: { id: ordersId } });
                }
            } else {
                console.warn('Not received order Id');
            }
        }

        setSending(false);
    };

    const onFieldChange = (fields: FieldData[]): void => {
        if (fields[0]?.name) {
            const { name } = fields[0];
            const fieldName = name as IAntDFormChangeFieldNameLong;
            onFieldErrorHandle(fieldName[0]);
        }
    };

    return (
        <Catcher>
            <article className={Styles.main}>
                <Row>
                    <CardMainLayout
                        columnOption={cardLayoutOptions.packageCard}
                        idSpinners={idSpinners}
                        name={name}
                        title={'Buy Package'}
                        dataTest={'PackageInstanceBuy'}
                    >
                        <PackageInfoBox packageInfo={packageInfo} />
                        <Divider />
                        <Form
                            form={form}
                            name="giveCredits"
                            onFinish={onFinishEditOrder}
                            onFieldsChange={onFieldChange}
                            scrollToFirstError={true}
                            layout={'vertical'}
                        >
                            {isLord ? (
                                <FormSelect
                                    fieldName={'companyId'}
                                    label={tF('For Company')}
                                    rules={apiMessagesGetFormRulesFieldBlank()}
                                    useSelectHook={useCompaniesChildrenSelect}
                                    isNotClear={true}
                                    dataTest={'PackageInstanceBuyCompany'}
                                />
                            ) : null}
                            <FormSelect
                                fieldName={'paymentTypeId'}
                                label={tF('Payment method')}
                                rules={apiMessagesGetFormRulesFieldBlank()}
                                useSelectHook={usePackagePaymentTypes}
                                isNotClear={true}
                                dataTest={'PackageInstanceBuyPaymentType'}
                            />
                            <Form.Item name="renewal" valuePropName="checked" rules={apiMessagesGetFormRules}>
                                <Checkbox data-test={'PackageInstanceBuyAutoRenewalCheckbox'}>
                                    {tF('Auto renewal')}
                                </Checkbox>
                            </Form.Item>
                            <FieldAlert fieldName={'orderable_type'} />
                            <CardInfoToolBar
                                loadingTypes={idSpinners}
                                onCancel={(): void => {
                                    routerStore.goTo('PackagesBuy');
                                }}
                                dataTest={'PackageInstanceBuy'}
                            >
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    icon={<EuroCircleOutlined />}
                                    className={cx(Styles.mainButton, {
                                        [Styles.buttonsDisable]: isSending,
                                    })}
                                    disabled={isSending}
                                    loading={isSending}
                                    data-test={'PackageInstanceSubmitButton'}
                                >
                                    {tB('Buy')}
                                </Button>
                            </CardInfoToolBar>
                        </Form>
                    </CardMainLayout>
                </Row>
            </article>
        </Catcher>
    );
});
