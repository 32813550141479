import { action, computed, makeObservable, observable } from 'mobx';
import {
    IAboutInfo,
    IBlobIds,
    ICompanyApiKeyList,
    ICompanyCardSetsList,
    ICompanyInfo,
    ICompanyOperatorList,
    ICompanyRateItem,
    ICompanyRateList,
    ICompanyStatistics,
    ICompanySubscriptionInfo,
    IUser,
} from '../interfaces';
import { isNewId } from '../instruments';

export class CompanyStore {
    private constructor() {
        makeObservable(this);
    }

    private static instance: CompanyStore;
    public static getInstance(): CompanyStore {
        if (!CompanyStore.instance) {
            CompanyStore.instance = new CompanyStore();
        }
        return CompanyStore.instance;
    }

    initInfoStore: ICompanyInfo = {
        id: '',
    };

    initCompanyStatistics: ICompanyStatistics = {
        id: '',
        companyId: '',
        balanceDifference: '',
        balance: '',
    };

    initLogoBlobIds: IBlobIds = {
        darkLogoBlobId: '',
        logoBlobId: '',
    };

    @observable companyInfo: ICompanyInfo = this.initInfoStore;
    @action setCompanyInfo(company: ICompanyInfo): void {
        this.companyInfo = company;
    }

    @observable logoBlobIds: IBlobIds = this.initLogoBlobIds;
    @action setLogoBlobIds(blobIds?: IBlobIds): void {
        this.logoBlobIds.darkLogoBlobId = blobIds?.darkLogoBlobId || '';
        this.logoBlobIds.logoBlobId = blobIds?.logoBlobId || '';
    }

    @action setCompanyInfoLogoBlobId(id?: string, isDark?: boolean): void {
        if (isDark) {
            this.companyInfo.darkLogoBlobId = id;
            this.logoBlobIds.darkLogoBlobId = id;
        } else {
            this.companyInfo.logoBlobId = id;
            this.logoBlobIds.logoBlobId = id;
        }
    }

    @action cleanCompanyInfoLogoBlobId(isDark?: boolean): void {
        if (isDark) {
            this.companyInfo.darkLogoBlobId = '';
            this.logoBlobIds.darkLogoBlobId = '';
        } else {
            this.companyInfo.logoBlobId = '';
            this.logoBlobIds.logoBlobId = '';
        }
    }

    @action cleanLogoUrl(isDark?: boolean): void {
        if (isDark) {
            this.companyInfo.darkLogoUrl = '';
        } else {
            this.companyInfo.logoUrl = '';
        }
    }

    @observable operatorList: ICompanyOperatorList = [];
    @action setCompanyOperatorList(list: ICompanyOperatorList): void {
        this.operatorList = list;
    }

    @observable rateList: ICompanyRateList = [];
    @computed get activeRateList(): ICompanyRateList {
        return this.rateList.filter((c: ICompanyRateItem) => !c.destroy);
    }
    @action setCompanyRateList(list: ICompanyRateList): void {
        this.rateList = list;
    }
    @action addRate(item: ICompanyRateItem): void {
        this.rateList = [item, ...this.rateList];
    }
    @action removeRateById(id: string): void {
        if (isNewId(id)) {
            this.rateList = this.rateList.filter((i: ICompanyRateItem) => i.id !== id);
        } else {
            this.rateList = this.rateList.map((i: ICompanyRateItem) => {
                if (i.id === id) {
                    return { ...i, destroy: true };
                } else {
                    return i;
                }
            });
        }
    }

    @observable usersList: IUser[] = [];
    @action setUsersList(users: IUser[]): void {
        this.usersList = users;
    }
    @observable cardSetsList: ICompanyCardSetsList = [];
    @action setCardSetsList(list: ICompanyCardSetsList): void {
        this.cardSetsList = list;
    }

    @observable companyStatistics: ICompanyStatistics = this.initCompanyStatistics;
    @action setCompanyStatistics(statistics: ICompanyStatistics): void {
        this.companyStatistics = statistics;
    }

    @observable companySubscribersCsvBlobId?: string = undefined;
    @action setCompanySubscribersCsvBlobId(id?: string): void {
        this.companySubscribersCsvBlobId = id;
    }

    @action clearCompanyStatistics(): void {
        this.companyStatistics = this.initCompanyStatistics;
    }

    @observable about: IAboutInfo = {};
    @action setAbout(about: IAboutInfo): void {
        this.about = about;
    }

    @observable apiKeysList: ICompanyApiKeyList = [];
    @action setApiKeysList(list: ICompanyApiKeyList): void {
        this.apiKeysList = list;
    }

    @observable subscription: ICompanySubscriptionInfo = {};
    @action setSubscription(subscription: ICompanySubscriptionInfo): void {
        this.subscription = subscription;
    }

    @action cleanStore(): void {
        this.companyInfo = this.initInfoStore;
        this.companyStatistics = this.initCompanyStatistics;
        this.rateList = [];
        this.operatorList = [];
        this.about = {};
        this.apiKeysList = [];
        this.subscription = {};
    }
}
