import React from 'react';
import { Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { Catcher } from '../index';
import { tI } from '../../translate';
import StylesView from '../styles/m_viewStyles.less';
import Styles from './m_styles.less';

export const columnDeleted = (isShow?: boolean) =>
    isShow
        ? {
              className: Styles.columnDeleteAt,
              dataIndex: 'deleted',
              title: tI('Deleted'),
              width: 50,
          }
        : {
              dataIndex: 'hidden',
              width: 0,
              className: StylesView.columnHidden,
          };

interface ITableItemDeleteAtColumn {
    deletedAt?: string;
}

export const TableItemDeleteColumn: React.FC<ITableItemDeleteAtColumn> = ({ deletedAt }: ITableItemDeleteAtColumn) => {
    return (
        <Catcher>
            <div className={Styles.toolbar}>
                {deletedAt ? (
                    <Tooltip title={`${tI('Deleted at')} ${deletedAt}`}>
                        <DeleteOutlined className={Styles.icon} />
                    </Tooltip>
                ) : null}
            </div>
        </Catcher>
    );
};
