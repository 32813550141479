import { FieldData } from 'rc-field-form/es/interface';
import { appConfig } from '../settings';
import { IIncludeUserItemAPI, ISelect } from '../interfaces';

export * from './appSignal';
export * from './charts';
export * from './checkErrors';
export * from './dateCalc';
export * from './file';
export * from './form';
export * from './getCurrencyShortCodeById';
export * from './googleMap';
export * from './hexId';
export * from './lightText';
export * from './mapError';
export * from './mapField';
export * from './mapStatApiItems';
export * from './number';
export * from './queryParams';
export * from './spinners';
export * from './timeInterval';

type IEqualObjectParams = Record<string, any>;
export function objIsNotEmpty(obj: any): boolean {
    return JSON.stringify(obj) !== '{}';
}
export function cleanObjectValue(obj: IEqualObjectParams): IEqualObjectParams {
    const newObject: IEqualObjectParams = {};
    for (const key in obj) {
        if (obj[key]) {
            newObject[key] = obj[key];
        }
    }
    return newObject;
}

export const equalObjects = (a: IEqualObjectParams, b: IEqualObjectParams): boolean => {
    if (a == b) {
        return true;
    }

    if (a == null || typeof a != 'object' || b == null || typeof b != 'object') {
        return false;
    }

    let propertiesInA = 0;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const key in a) {
        propertiesInA += 1;
    }
    let propertiesInB = 0;
    for (const key in b) {
        propertiesInB += 1;
        if (!(key in a) || !equalObjects(a[key], b[key])) {
            return false;
        }
    }
    return propertiesInA == propertiesInB;
};

export function equalValue(a: any, b: any): boolean {
    if (a === b) return true;

    if (a == null || b == null) return false;

    if (Array.isArray(a)) {
        return (
            Array.isArray(b) &&
            a.length === b.length &&
            a.every(function (item, index) {
                return equalValue(item, b[index]);
            })
        );
    }

    const aType = typeof a;
    const bType = typeof b;

    if (aType !== bType) return false;

    if (aType === 'object') {
        const aValue = a.valueOf ? a.valueOf() : Object.prototype.valueOf.call(a);
        const bValue = b.valueOf ? b.valueOf() : Object.prototype.valueOf.call(b);

        if (aValue !== a || bValue !== b) return equalValue(aValue, bValue);

        const aKeys = Object.keys(a);
        const bKeys = Object.keys(b);

        if (aKeys.length !== bKeys.length) return false;

        return aKeys.every(function (key) {
            return equalValue(a[key], b[key]);
        });
    }

    return false;
}

export const checkNumber = (value: any): number => {
    const outValue = Number(value);

    if (isNaN(outValue)) {
        return 0;
    } else {
        return outValue;
    }
};

export const jsonFieldToNumber = (value?: string | number): number | undefined => {
    if (typeof value === 'undefined') {
        return undefined;
    } else if (typeof value === 'number') {
        return !isNaN(value) ? value : undefined;
    } else if (typeof value === 'string') {
        return !isNaN(+value) ? +value : undefined;
    } else {
        return undefined;
    }
};

export const stringCount = (value: string | number = 0, decimal = 0): string => {
    const valueNumber = isNaN(Number(value)) ? 0 : Number(value);
    return `${valueNumber < 0 ? '- ' : ''}${Math.abs(valueNumber)
        .toFixed(decimal)
        .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')}`;
};

export const byteConverter = (amount: number, formatId: number): number => {
    switch (appConfig.bytesTypes[formatId]?.name) {
        case 'B':
            return amount;
        case 'KB':
            return amount * 1000;
        case 'MB':
            return amount * 1000000;
        case 'GB':
            return amount * 1000000000;
        default:
            return amount;
    }
};
type IRateLabelName = 'bytes' | 'long1' | 'long2' | 'sec' | 'short' | 'si' | 'symbol';

type IRateLabels = {
    [key in IRateLabelName]: Array<string>;
};

const rateLabels: IRateLabels = {
    bytes: ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    long1: [
        '',
        'thousand',
        'million',
        'billion',
        'trillion',
        'quadrillion',
        'quintillion',
        'sextillion',
        'septillion',
        'octillion',
        'nonillion',
    ],
    long2: [
        '',
        'thousand',
        'thousand million',
        'billion',
        'thousand billion',
        'trillion',
        'thousand trillion',
        'quadrillion',
        'thousand quadrillion',
        'quintillion',
    ],
    short: ['', 'K.', 'mln.', 'bln.', 'trln.', 'qudln.', 'qutln.', 'sxtln.', 'septln.', 'octln.', 'nonln.'],
    sec: ['sec', 'min', 'hour'],
    si: ['', 'kilo-', 'mega-', 'giga-', 'tera-', 'peta-', 'exa-', 'zetta-', 'yotta-'],
    symbol: [
        '',
        'thousand',
        'million',
        'billion',
        'trillion',
        'quadrillion',
        'quintillion',
        'sextillion',
        'septillion',
    ],
};

export const byteMb = (byte: any, divider = 1024, decimal = 0): string => {
    if (divider === 0) {
        console.error(`divider === 0`);
        return '0';
    } else {
        let newValue = checkNumber(byte) / divider / divider;
        newValue = Math.round(newValue * 10 ** decimal) / 10 ** decimal;
        return `${newValue}`;
    }
};
export const byteMb1000 = (byte: any, decimal = 3): string => {
    return byteMb(byte, 1000, decimal);
};

export const range = (value: any, divider = 1000, decimal = 0, labels = rateLabels.short): string => {
    let prefix = '';
    let newValue = checkNumber(value);
    if (newValue < 0) {
        prefix = '-';
        newValue = newValue * -1;
    }
    let range = 0;
    while (newValue / divider > 1 && range < labels.length - 1) {
        newValue = newValue / divider;
        range++;
    }
    newValue = Math.round(newValue * 10 ** decimal) / 10 ** decimal;
    const label = range > labels.length - 1 ? labels[labels.length - 1] : labels[range];
    return `${prefix}${stringCount(newValue, decimal)} ${label}`;
};

export const byteRange = (byte: any, divider = 1024, decimal = 0): string => {
    return range(byte, divider, decimal, rateLabels.bytes);
};

export const kbRange = (byte: any, divider = 1024, decimal = 0): string => {
    const kbs = [...rateLabels.bytes].slice(1);
    return range(byte, divider, decimal, kbs);
};

export const moneyRange = (cost: any, label = rateLabels.short.slice(0, 5)): string => {
    return range(cost, 1000, 0, label);
};

export const smsRange = (value: any): string => {
    return `${range(value, 1000, 0, rateLabels.symbol)} sms`;
};
export const minutesRange = (seconds: any = 0): string => {
    const checkedSeconds = checkNumber(seconds);

    if (checkedSeconds < 60) {
        return `${seconds} sec`;
    }

    return `${range(checkNumber(seconds) / 60, 1000, 0, rateLabels.short)} min`;
};

// export const timeRange = (seconds: any): string => {
//     return range(seconds, 60, 0, rateLabels.sec);
// };

export const byteRange1000 = (byte: any, decimal = 3): string => {
    return byteRange(byte, 1000, decimal);
};

export const byteAccuredConvertion = (byte: number, decimals = 3): string | null => {
    if (!byte) return null;
    const dm = decimals < 0 ? 0 : decimals;
    const labelIndex = Math.floor(Math.log(byte) / Math.log(1000));

    return `${parseFloat((byte / Math.pow(1000, labelIndex)).toFixed(dm))} ${rateLabels.bytes[labelIndex]}`;
};

export const showOriginalUnitByType = (value = '0', unitType?: ISelect, byteDecimal = 0): string => {
    switch (unitType?.id) {
        case '1':
            return byteRange1000(value, byteDecimal);
        case '2':
            return smsRange(value);
        case '3':
            // return timeRange(value);
            return minutesRange(value);
        default:
            // return stringCount(value);
            return byteRange1000(value);
    }
};

export function formatBytes(bytes = 0, k = 1000, decimals = 0): string {
    if (bytes === 0) return '0 B';

    const dm = decimals < 0 ? 0 : decimals;
    const sizes = rateLabels?.bytes?.slice(0, 4);

    let i = Math.floor(Math.log(bytes) / Math.log(k));

    if (i > 3) {
        i = 3;
    }

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

type ITick = number | { v: number; f: string };

export const calcChartTicks = (minValue = 0, maxValue = 100, partCount = 5, unitType?: ISelect): Array<ITick> => {
    const ticks: Array<ITick> = [];
    const lenMinValue = minValue.toFixed().length;
    const realMinValue = minValue === 0 ? 0 : Math.floor(minValue / 10 ** (lenMinValue - 1)) * 10 ** (lenMinValue - 1);
    const range = maxValue - realMinValue;
    const step = range / partCount;
    const lenStep = step.toFixed().length;
    const realStep = Math.round(step / 10 ** (lenStep - 1)) * 10 ** (lenStep - 1);

    let currentPosition = realMinValue;
    do {
        ticks.push({ v: currentPosition, f: showOriginalUnitByType(currentPosition.toFixed(), unitType) });
        currentPosition = currentPosition + realStep;
    } while (currentPosition < maxValue);

    return ticks;
};

export const fileBlob2Base64 = (file: File) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
};

export const stringMoney = (value: string | number = 0, currency = ''): string => {
    const valueNumber = isNaN(Number(value)) ? 0 : Number(value);
    return `${valueNumber < 0 ? '-' : ''} ${currency} ${Math.abs(valueNumber)
        .toFixed(2)
        .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')}`;
};

type ISortByName = {
    name?: string;
};
export const sortByName = (a: ISortByName, b: ISortByName): number => {
    const aName = a.name || '';
    const bName = b.name || '';
    if (aName < bName) {
        return -1;
    } else if (aName > bName) {
        return 1;
    } else {
        return 0;
    }
};

export const sortReverseByName = (a: ISortByName, b: ISortByName): number => {
    const aName = a.name || '';
    const bName = b.name || '';
    if (aName < bName) {
        return 1;
    } else if (aName > bName) {
        return -1;
    } else {
        return 0;
    }
};

export const sortByString = (a?: string, b?: string): number => {
    const aName = a || '';
    const bName = b || '';
    if (aName < bName) {
        return -1;
    } else if (aName > bName) {
        return 1;
    } else {
        return 0;
    }
};
export const sortByStringDecrease = (a?: string, b?: string): number => {
    const aName = a || '';
    const bName = b || '';
    if (aName > bName) {
        return -1;
    } else if (aName < bName) {
        return 1;
    } else {
        return 0;
    }
};

export const unionStringArr = (a: string[] = [], b: string[] = []) => {
    const c = a.concat(b).sort();
    return c.filter((value, pos) => {
        return c.indexOf(value) == pos;
    });
};

export const upperCaseFirstLetter = (string: string): string => {
    return string && string[0].toUpperCase() + string.slice(1);
};

export const debounce = (func: (changedFields: FieldData[]) => void, wait = 0): (() => void) => {
    let timeout: ReturnType<typeof setTimeout>;

    return function executedFunction(...args: FieldData[][]): void {
        const later = (): void => {
            clearTimeout(timeout);
            func(args[0]);
        };

        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
};

export const fullName = (firstName?: string, lastName?: string): string | undefined => {
    if (firstName && lastName) {
        return `${firstName} ${lastName}`;
    } else if ((!firstName && !lastName) || firstName) {
        return firstName;
    } else {
        return lastName;
    }
};

export const getAPIUserFullName = (apiUser: IIncludeUserItemAPI): string | undefined => {
    return fullName(apiUser?.attributes?.first_name, apiUser?.attributes?.last_name);
};

export const mergeByProperty = (target: any, source: any, prop: any) => {
    source.forEach((sourceElement: any) => {
        const targetElement: any = target.find((targetElement: any) => {
            return sourceElement[prop] === targetElement[prop];
        });
        targetElement ? Object.assign(targetElement, sourceElement) : target.push(sourceElement);
    });

    return target;
};

export const percentage = (partialValue: number, totalValue: number): number => {
    return (100 * partialValue) / totalValue;
};

export const isObjectEmpty = (obj = {}) => Object.keys(obj).length === 0;

export const sortArrayByKey = (arr: Array<IEqualObjectParams>, key: string): Array<IEqualObjectParams> => {
    if (arr[0][key] !== undefined) {
        return arr.sort((a, b) => {
            return sortByString(a[key], b[key]);
        });
    } else {
        console.warn(`key "${key}" not found, array not sorting`);
        return arr;
    }
};

export const sortArray = (arr: Array<IEqualObjectParams>): Array<IEqualObjectParams> => {
    if (arr.length === 0) {
        return arr;
    }
    if (typeof arr[0] === 'string') {
        return arr.sort();
    } else if (typeof arr[0] === 'number') {
        return arr.sort((a, b) => Number(a) - Number(b));
    } else {
        const keys = ['id', 'key', 'name'];
        keys.some((key) => {
            if (arr[0][key] !== undefined) {
                return sortArrayByKey(arr, key);
            }
        });
    }

    return arr;
};

export const equalArray = (arr1: Array<IEqualObjectParams>, arr2: Array<IEqualObjectParams>): boolean => {
    if (arr1.length !== arr2.length) {
        return false;
    }
    const arrSort1 = sortArray(arr1);
    const arrSort2 = sortArray(arr2);

    return !arrSort1.some((item, index) => {
        if (!equalValue(item, arrSort2[index])) {
            return true;
        }
    });
};

function isObject(object: IEqualObjectParams): boolean {
    return object != null && typeof object === 'object';
}

export const deepEqual = (object1: IEqualObjectParams, object2: IEqualObjectParams): boolean => {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
        return false;
    }
    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
            return false;
        }
    }
    return true;
};

type ITrimStringFormFields = {
    [id: string]: any;
};
export const trimStringFormFields = (fields: ITrimStringFormFields): ITrimStringFormFields => {
    const newFields: ITrimStringFormFields = {};
    for (const key in fields) {
        if (typeof fields[key] === 'string') {
            newFields[key] = fields[key].trim();
        } else {
            newFields[key] = fields[key];
        }
    }

    return newFields;
};

export const camelCaseToNormalCase = (text: string) => {
    const result = text.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
};

export const isNumbersFormattedWithComma = (str: string) => {
    str = str.replace(/,\s*$/, '');

    const numbers = str.split(',');

    return numbers.every((part) => !isNaN(Number(part)) && Number.isInteger(parseFloat(part)));
};
