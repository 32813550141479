import React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import { CheckSquareOutlined, CloseSquareOutlined } from '@ant-design/icons';
import { Catcher, LinkComponent, TableItemToolbar, columnToolbar } from '../../';
import { ICurrenciesListItem } from '../../../interfaces';
import { useCurrenciesTable, useUiIsLoading } from '../../../hooks';
import { deleteCurrency } from '../../../middleware';
import { tF, tI } from '../../../translate';
import '../../../customStyle/table.less';
import StylesCard from '../../styles/m_itemCardStyles.less';
import Styles from '../../styles/m_viewStyles.less';

export const CurrenciesTableList: React.FC = observer(() => {
    const isLoading = useUiIsLoading();
    const list = useCurrenciesTable();

    const tableColumns = [
        {
            title: tF('Abbreviation'),
            dataIndex: 'abbreviationLink',
            width: '100px',
        },
        {
            title: tF('Symbol'),
            dataIndex: 'symbol',
            width: '100px',
        },
        {
            title: tF('Description'),
            dataIndex: 'descriptionLink',
        },
        {
            title: tF('Selectable'),
            dataIndex: 'selectable',
            width: '100px',
        },

        columnToolbar,
    ];
    const tableData = list.map((c: ICurrenciesListItem) => {
        const deleteItem = (): void => {
            deleteCurrency(c.id);
        };

        const selectable = c.selectable ? (
            <CheckSquareOutlined className={StylesCard.checkIcon} />
        ) : (
            <CloseSquareOutlined className={StylesCard.uncheckIcon} />
        );
        const name = c.abbreviation ? c.abbreviation : `# ${c.id}`;

        return {
            key: c.id,
            ...c,
            selectable,
            abbreviationLink: (
                <LinkComponent currentListComponent={'CurrenciesList'} id={c.id} isLight name={c.abbreviation} />
            ),
            descriptionLink: (
                <LinkComponent currentListComponent={'CurrenciesList'} id={c.id} isLight name={c.description} />
            ),
            toolbar: <TableItemToolbar itemName={`"${name}" ${tI('currency')}`} id={c.id} deleteItem={deleteItem} />,
        };
    });

    return (
        <Catcher>
            <div className={Styles.tablePlace}>
                <Table
                    className={'table-theme'}
                    columns={tableColumns}
                    dataSource={tableData}
                    loading={isLoading}
                    pagination={false}
                    size={'middle'}
                />
            </div>
        </Catcher>
    );
});
