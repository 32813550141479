import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Row } from 'antd';
import { CallDataRecordsLast5, CardLocationGoogleMap, Catcher, cardLayoutOptions } from '../';
import { IGetApiType } from '../../REST';
import { IShowFormComponent } from '../../interfaces';
import {
    useCardLocationList,
    useIsGeolocationEnabled,
    useProfile,
    useProfileIsRight,
    useRouterStore,
} from '../../hooks';
import {
    abortSelectedFetch,
    cleanCallDataRecordsStore,
    cleanCardStore,
    getCard,
    getCardShowRelationSelect,
    getCardStatistics,
} from '../../middleware';
import { CardInfoUsage, DeviceHistoryTable, EditCardInfo, ShowCardInfo, Subscribers } from './Components';
import Styles from '../styles/m_itemCardStyles.less';

export const Card: React.FC<IShowFormComponent> = observer(({ isEdit }: IShowFormComponent) => {
    const routerStore = useRouterStore();
    const isGeolocationEnabled = useIsGeolocationEnabled();
    const cardLocationList = useCardLocationList();
    const [id, setId] = useState('');
    const isLord = useProfileIsRight('LORD');
    const isGeolocationShown = isGeolocationEnabled && cardLocationList.length && isLord;
    const { isCompanyTypeIdMNOHost, companyId } = useProfile();
    const idSpinnersMain: IGetApiType[] = ['GET_CARD'];
    const forStartEditApiTypes: IGetApiType[] = [
        ...idSpinnersMain,
        'GET_CARD_SETS_SELECT',
        'GET_SUBSCRIBERS_BY_CARD_ID',
    ];
    const usedApiTypes: IGetApiType[] = [
        ...forStartEditApiTypes,
        'GET_CALL_DATA_RECORDS_LAST_5',
        'GET_SUBSCRIBERS_STATUS',
    ];

    const goOut = (): void => {
        abortSelectedFetch(usedApiTypes);
        cleanCardStore();
        cleanCallDataRecordsStore();
    };

    const goToList = (): void => {
        routerStore.goTo('CardList');
        goOut();
    };

    const _getCard = async (id: string): Promise<void> => {
        if (!!id) {
            getCardShowRelationSelect(id);
            const isGetCard = await getCard(id);

            if (isGetCard) {
                await getCardStatistics(id, companyId);
            } else {
                goToList();
            }
        }
    };

    useEffect(() => {
        return (): void => {
            goOut();
        };
    }, []);

    useEffect(() => {
        if (id) {
            _getCard(id);
        }
    }, [id]);

    useEffect(() => {
        if (
            routerStore.routerState.params.id &&
            (routerStore.routerState.routeName === 'Card' || routerStore.routerState.routeName === 'CardEdit')
        ) {
            setId(routerStore.routerState.params.id);
        }
    }, [routerStore.routerState.params.id]);

    const setEdit = (isEdit?: boolean): void => {
        if (isEdit) {
            routerStore.goTo('CardEdit', { params: { id } });
        } else {
            routerStore.goTo('Card', { params: { id } });
        }
    };

    return (
        <Catcher>
            <article className={Styles.main}>
                <Row>
                    {isEdit ? (
                        <EditCardInfo setEditMode={setEdit} />
                    ) : (
                        <ShowCardInfo setEditMode={setEdit} loadingTypes={forStartEditApiTypes} />
                    )}
                    <Subscribers />
                    <CardInfoUsage />
                    {!isEdit && isLord ? (
                        <DeviceHistoryTable
                            columnOption={cardLayoutOptions.topFiveUsedDevices}
                            idSpinners={idSpinnersMain}
                            title={'Top 5 used devices'}
                        />
                    ) : null}
                    {!isEdit && isGeolocationShown ? (
                        <CardLocationGoogleMap
                            columnOption={cardLayoutOptions.cardTableFull}
                            idSpinners={idSpinnersMain}
                            title={'Device location'}
                        />
                    ) : null}
                    {!isEdit && !isCompanyTypeIdMNOHost ? (
                        <CallDataRecordsLast5
                            columnOption={cardLayoutOptions.cardTableFull}
                            idSpinners={['GET_CALL_DATA_RECORDS_LIST', 'GET_CALL_DATA_RECORDS_LAST_5']}
                        />
                    ) : null}
                </Row>
            </article>
        </Catcher>
    );
});
