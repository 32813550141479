import { action, makeObservable, observable, reaction } from 'mobx';
import { UiStore } from './ui';
import { appConfig } from '../settings';
import {
    ICompaniesHashSettingList,
    ICompaniesHierarchyAPI,
    ICompaniesHierarchyIsNotBigQuery,
    ICompaniesList,
    IFilterParams,
    ISelect,
} from '../interfaces';
import { getCompaniesList } from '../middleware';
import { apiParamsCleaner } from '../instruments';

export class CompaniesStore {
    private constructor() {
        makeObservable(this);
        reaction(
            () => this.forcedUpdateTime,
            () => {
                const UiStoreInstance = UiStore.getInstance();
                const params = UiStoreInstance.params;
                const cleanParams = apiParamsCleaner(params, this.filterParams);
                getCompaniesList(cleanParams).then();
            },
            {
                delay: appConfig.APIDelay,
            },
        );
    }

    private static instance: CompaniesStore;
    public static getInstance(): CompaniesStore {
        if (!CompaniesStore.instance) {
            CompaniesStore.instance = new CompaniesStore();
        }
        return CompaniesStore.instance;
    }

    @observable select: ISelect[] = [];
    @action setCompaniesSelect(select: ISelect[]): void {
        this.select = select;
    }

    @observable companiesHashSettingsList: ICompaniesHashSettingList = [];
    @action setCompaniesHashSettingsList(list: ICompaniesHashSettingList): void {
        this.companiesHashSettingsList = list;
    }
    @observable companyTypeSelect: ISelect[] = [];
    @action setCompanyTypeSelect(companyTypeSelect: ISelect[]): void {
        this.companyTypeSelect = companyTypeSelect;
    }

    @observable paymentTypeSelect: ISelect[] = [];
    @action setPaymentTypeSelect(paymentTypeSelect: ISelect[]): void {
        this.paymentTypeSelect = paymentTypeSelect;
    }

    @observable childrenSelect: ISelect[] = [];
    @action setCompaniesChildrenSelect(select: ISelect[]): void {
        this.childrenSelect = select;
    }

    @observable list: ICompaniesList = [];
    @action setCompaniesList(companiesList: ICompaniesList): void {
        this.list = companiesList;
    }

    @observable forcedUpdateTime = 0;
    @action setForcedUpdateTime(): void {
        this.forcedUpdateTime = Date.now();
    }

    @observable filterParams: IFilterParams = {
        pageNumber: true,
        withDeleted: true,
        pageSize: true,
        companyTypeId: true,
        paymentType: true,
        search: true,
    };

    // ------------ companiesHierarchyList ------------
    @observable companiesHierarchyListIsNotBigQuery: Array<ICompaniesHierarchyIsNotBigQuery> = [];

    @action addChildrenCompaniesHierarchyListIsNotBigQuery(
        parentId: string | undefined,
        companiesHierarchyListIsNotBigQuery: Array<ICompaniesHierarchyIsNotBigQuery>,
    ): void {
        this.companiesHierarchyListIsNotBigQuery = [
            ...this.companiesHierarchyListIsNotBigQuery,
            ...companiesHierarchyListIsNotBigQuery.map((l) => ({ ...l, parentId })),
        ];
    }

    @observable companiesHierarchyListIsBigQuery: Array<ICompaniesHierarchyAPI> = [];

    @action addChildrenCompaniesHierarchyListIsBigQuery(
        parentId: string | undefined,
        companiesHierarchyList: Array<ICompaniesHierarchyAPI>,
    ): void {
        this.companiesHierarchyListIsBigQuery = [
            ...this.companiesHierarchyListIsBigQuery,
            ...companiesHierarchyList.map((l) => ({ ...l, parentId })),
        ];
    }

    @action cleanStore(): void {
        this.list = [];
        this.companiesHierarchyListIsNotBigQuery = [];
        this.companiesHierarchyListIsBigQuery = [];
        this.childrenSelect = [];
        this.companyTypeSelect = [];
        this.paymentTypeSelect = [];
        this.companiesHashSettingsList = [];
        this.select = [];
    }
}
