import React from 'react';
import { appConfig } from '../../settings';
import { usePagesStoreCurrentPage } from '../../hooks';

type IShowComponentTitle = {
    text?: string;
};
export const ShowComponentTitle: React.FC<IShowComponentTitle> = ({ text }: IShowComponentTitle) => {
    const { title } = usePagesStoreCurrentPage();

    const newTitle = text || title || appConfig.defaultTitle;

    if (document.title !== newTitle) {
        document.title = newTitle;
    }

    return null;
};
