import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Row } from 'antd';
import { Catcher, FilterToolbar, FilterUrlManager, rowGutter } from '../../';
import { FinancesActiveCard, FinancesInfoCard, FinancesRevenuesCosts } from './';
import { useDashboardFilterParamsFinance, useUiCurrentUnitType } from '../../../hooks';
import { cleanDashboardStore, cleanFinanceHeader, setDashboardForcedUpdateTime } from '../../../middleware';
import Styles from '../m_dashboarStyles.less';

export const FinancesHeader: React.FC = observer(() => {
    const currentUnitType = useUiCurrentUnitType();
    const filterParams = useDashboardFilterParamsFinance();

    useEffect(() => {
        cleanFinanceHeader();
    }, [currentUnitType?.id]);

    return (
        <Catcher>
            <FilterUrlManager
                cleanStore={cleanDashboardStore}
                filterParams={filterParams}
                setForcedUpdateTime={setDashboardForcedUpdateTime}
            />

            <FilterToolbar
                filterParams={filterParams}
                onChange={setDashboardForcedUpdateTime}
                className={Styles.filterToolbar}
            />
            <div className={Styles.cardsPanel}>
                <Row gutter={rowGutter.card} className={Styles.cardsRow} justify="space-between">
                    <FinancesRevenuesCosts />
                    <FinancesInfoCard />
                    <FinancesActiveCard />
                </Row>
            </div>
        </Catcher>
    );
});
