import { OrdersStore } from '../stores/orders';
import { getCompaniesSelect } from './companies';
import { setUiTotal } from './ui';
import { apiManager } from '../REST';
import {
    IBodyListResponse,
    ICurrenciesListItemAPI,
    IIncludeUserItemAPI,
    IItemAPI,
    IOrdersListAPI,
    IOrdersListItem,
    IOrdersListItemAPI,
    IParamsList,
} from '../interfaces';
import { dateText2Utc, getAPIUserFullName, mapApi2UiField } from '../instruments';

export function cleanOrdersStore(): void {
    const StoreInstance = OrdersStore.getInstance();
    StoreInstance.cleanStore();
}

export function setOrdersList(apiData: IOrdersListAPI): void {
    const companiesList = apiData?.included?.filter((i) => i.type === 'company');
    const usersList = apiData?.included?.filter((i) => i.type === 'user');
    const currenciesList = apiData?.included?.filter((i) => i.type === 'currency');
    const storeData: IOrdersListItem[] =
        apiData?.data?.map((item: IOrdersListItemAPI) => {
            const companyId = item?.relationships?.company?.data?.id;
            const company = (companiesList?.find((o) => o.id === companyId) as IItemAPI)?.attributes?.name;
            const userId = item?.relationships?.user?.data?.id;
            const user = usersList?.find((o) => o.id === userId) as IIncludeUserItemAPI;
            const currencyId = item?.relationships?.currency?.data?.id;
            const currency = (currenciesList?.find((o) => o.id === currencyId) as ICurrenciesListItemAPI)?.attributes
                ?.abbreviation;
            return {
                id: item.id,
                ...mapApi2UiField(item.attributes || {}),
                companyId,
                company,
                userId,
                user: getAPIUserFullName(user),
                currencyId,
                currency,
                updatedAt: dateText2Utc(item?.attributes?.updated_at),
                paymentType: item?.attributes?.payment_type,
            };
        }) || [];
    const StoreInstance = OrdersStore.getInstance();
    StoreInstance.setList(storeData);
}

export async function getOrdersList(params: IParamsList): Promise<number> {
    const { resp } = await apiManager.getApi('GET_ORDERS_LIST', { searchParamsList: params });
    const { data, meta } = resp as IBodyListResponse;
    meta && setUiTotal(meta.records_count);
    data && setOrdersList(resp as IOrdersListAPI);
    return Number(meta?.records_count);
}

export function getOrdersListRelationSelect(): void {
    getCompaniesSelect();
}

export function setOrdersForcedUpdateTime(): void {
    const StoreInterface = OrdersStore.getInstance();
    StoreInterface.setForcedUpdateTime();
}
