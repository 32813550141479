import React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import { Catcher, viewOption } from '../../';
import cx from 'classnames';
import { IRateCountryViewTableRecord, IRateNetworkItem } from '../../../interfaces';
import { useRateInfo, useRateNetworksListByCountry } from '../../../hooks';
import { tF } from '../../../translate';
import StylesItem from '../m_styles.less';

export interface IShowRateCountryTable {
    record: IRateCountryViewTableRecord;
}

export const ShowRateCountryTable: React.FC<IShowRateCountryTable> = observer(({ record }: IShowRateCountryTable) => {
    const { countryId, operatorId, rateData, rateSms, rateVoiceMobile, rateVoiceNet } = record;
    const rateInfo = useRateInfo();

    const tableColumns = [
        {
            title: tF('Network'),
            dataIndex: 'network',
            className: StylesItem.rateColumnCountry,
            width: viewOption.rateTable.widthRateCountry,
        },
        {
            title: tF('Data'),
            dataIndex: 'rateDataShow',
            className: StylesItem.rateColumnVal,
            width: viewOption.rateTable.widthRateVal,
        },
        {
            title: tF('SMS'),
            dataIndex: 'rateSmsShow',
            className: StylesItem.rateColumnVal,
            width: viewOption.rateTable.widthRateVal,
        },
        {
            title: tF('Voice Mobile'),
            dataIndex: 'rateVoiceMobileShow',
            className: StylesItem.rateColumnVal,
            width: viewOption.rateTable.widthRateVal,
        },
        {
            title: tF('Voice Fix Net'),
            dataIndex: 'rateVoiceNetShow',
            className: StylesItem.rateColumnVal,
            width: viewOption.rateTable.widthRateVal,
        },
    ];

    const list = countryId && operatorId ? useRateNetworksListByCountry(countryId, operatorId) : [];

    const tableData = list.map((n: IRateNetworkItem) => {
        return {
            key: n.id,
            network: n.networkName,
            rateDataShow: (
                <span className={n.calculatedRateData === rateData ? StylesItem.defaultRate : StylesItem.customRate}>
                    {n.calculatedRateData} {n.calculatedRateData && rateInfo.currency}
                </span>
            ),
            rateSmsShow: (
                <span className={n.calculatedRateSms === rateSms ? StylesItem.defaultRate : StylesItem.customRate}>
                    {n.calculatedRateSms} {n.calculatedRateSms && rateInfo.currency}
                </span>
            ),
            rateVoiceMobileShow: (
                <span
                    className={
                        n.calculatedRateVoiceMobile === rateVoiceMobile ? StylesItem.defaultRate : StylesItem.customRate
                    }
                >
                    {n.calculatedRateVoiceMobile} {n.calculatedRateVoiceMobile && rateInfo.currency}
                </span>
            ),
            rateVoiceNetShow: (
                <span
                    className={
                        n.calculatedRateVoiceNet === rateVoiceNet ? StylesItem.defaultRate : StylesItem.customRate
                    }
                >
                    {n.calculatedRateVoiceNet} {n.calculatedRateVoiceNet && rateInfo.currency}
                </span>
            ),
        };
    });

    return (
        <Catcher>
            <Table
                className={cx('table-theme')}
                columns={tableColumns}
                dataSource={tableData}
                pagination={false}
                size={'small'}
            />
        </Catcher>
    );
});
