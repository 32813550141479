import React from 'react';
import { observer } from 'mobx-react';
import { ESPlusNotificationTable } from './ESPlusNotificationTable';
import { useEsNotificationsFilterParams } from '../../../hooks';
import { cleanEsNotificationsStore } from '../../../middleware';
import { Catcher, DownloadFilesButtons, ListWrapper } from '../../../Components';

export const ESPlusNotificationsList: React.FC = observer(() => {
    const filterParams = useEsNotificationsFilterParams();
    const DownloadCSVFlow = (): JSX.Element => {
        return <DownloadFilesButtons csvType={'GET_ES_PLUS_NOTIFICATIONS_LIST_CSV'} />;
    };

    return (
        <Catcher>
            <ListWrapper
                cleanStore={cleanEsNotificationsStore}
                filterParams={filterParams}
                RightButtons={DownloadCSVFlow}
            >
                <ESPlusNotificationTable />
            </ListWrapper>
        </Catcher>
    );
});
