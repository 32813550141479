import { generateError, getFirstDayCurrentMonthAsText, getTodayAsText } from './';
import { checkAvailableMaxDateTodayAsText, checkAvailableMinDateText, isValidTextDate } from './dateCalc';
import dayjs from 'dayjs';
import queryString from 'query-string';
import { appConfig } from '../settings';
import { IFilterParams, IParamsList } from '../interfaces';
import { getUnitTypesSelectById } from '../middleware';

export const getDefaultDateRate = (filterParams?: IFilterParams): IParamsList => ({
    endDate:
        filterParams?.defaultDateRangeType === 'month' || filterParams?.defaultDateRangeType === 'day'
            ? getTodayAsText()
            : undefined,
    startDate:
        filterParams?.defaultDateRangeType === 'day'
            ? getTodayAsText()
            : filterParams?.defaultDateRangeType === 'month'
            ? getFirstDayCurrentMonthAsText()
            : undefined,
});
export const queryParamsNormalize = (params: IParamsList, filterParams?: IFilterParams): IParamsList => {
    const newParams = { ...params };
    if (
        newParams.pageSize
        // && typeof newParams.pageSize === 'string'
    ) {
        newParams.pageSize = +newParams.pageSize;
    }
    if (
        newParams.pageNumber
        // && typeof newParams.pageNumber === 'string'
    ) {
        newParams.pageNumber = +newParams.pageNumber;
    }

    if (newParams.startDate && !isValidTextDate(newParams.startDate)) {
        delete newParams.startDate;
    }
    if (newParams.endDate && !isValidTextDate(newParams.endDate)) {
        delete newParams.endDate;
    }

    if (
        !newParams.endDate &&
        (filterParams?.isDateRangeMaxDayToDay ||
            filterParams?.defaultDateRangeType === 'month' ||
            filterParams?.defaultDateRangeType === 'day')
    ) {
        newParams.endDate = getTodayAsText();
    }

    if (filterParams?.isDateRangeMaxDayToDay && newParams.endDate) {
        newParams.endDate = checkAvailableMaxDateTodayAsText(newParams.endDate);
    }

    if (!newParams.startDate) {
        switch (filterParams?.defaultDateRangeType) {
            case 'month':
                newParams.startDate = getFirstDayCurrentMonthAsText();
                break;
            case 'day':
                newParams.startDate = getTodayAsText();
                break;
            default:
                filterParams?.defaultDateRangeType && generateError(filterParams.defaultDateRangeType);
                break;
        }
    }

    if (newParams.startDate && newParams.endDate && new Date(newParams.startDate) > new Date(newParams.endDate)) {
        const tmp = dayjs(newParams.startDate).format(appConfig.formatDate);
        newParams.startDate = dayjs(newParams.endDate).format(appConfig.formatDate);
        newParams.endDate = tmp;
    }

    if (newParams.startDate && filterParams?.isDateRangeMinDayOn) {
        newParams.startDate = checkAvailableMinDateText(newParams.startDate);
    }

    return newParams;
};

export const queryParamsCleanDefault = (params: IParamsList, filterParams?: IFilterParams): IParamsList => {
    const cleanedParams = { ...params };
    if (cleanedParams.pageNumber && cleanedParams.pageNumber === appConfig.pageDefaultParams.pageNumber) {
        delete cleanedParams.pageNumber;
    }
    if (cleanedParams.pageSize && cleanedParams.pageSize === appConfig.pageDefaultParams.pageSize) {
        delete cleanedParams.pageSize;
    }
    if (
        cleanedParams.endDate &&
        cleanedParams.endDate === appConfig.pageDefaultParams.today &&
        (filterParams?.defaultDateRangeType === 'month' || filterParams?.defaultDateRangeType === 'day')
    ) {
        delete cleanedParams.endDate;
    }
    if (
        cleanedParams.startDate &&
        ((cleanedParams.startDate === appConfig.pageDefaultParams.startThisMonth &&
            filterParams?.defaultDateRangeType === 'month') ||
            (cleanedParams.startDate === appConfig.pageDefaultParams.today &&
                filterParams?.defaultDateRangeType === 'day'))
    ) {
        delete cleanedParams.startDate;
    }
    return cleanedParams;
};

export const apiParamsCleaner = (params: IParamsList, filterParams?: IFilterParams): IParamsList => {
    const filter = filterParams
        ? Object.entries(filterParams)
              .filter(([, value]) => value)
              .map(([key]) => {
                  return key;
              })
        : [];

    return Object.fromEntries(
        Object.entries(params)
            .filter((a) => a[1])
            .filter((a) => {
                if (Array.isArray(a[1]) && !a[1].length) {
                    return false;
                }
                return a;
            })
            .filter(([key]) => {
                return (
                    !filter.length ||
                    (filter.includes(key) && key !== 'dateRange') ||
                    ((key === 'startDate' || key === 'endDate') && filter.includes('dateRange'))
                );
            })
            .map(([key, value]) => [key, value]),
    );
};

export const urlParamsCleaner = (params: IParamsList, filterParams?: IFilterParams): IParamsList => {
    const filter = filterParams
        ? Object.entries(filterParams)
              .filter(([, value]) => value)
              .map(([key]) => {
                  return key;
              })
        : [];

    return Object.fromEntries(
        Object.entries(params)
            .filter((a) => a[1])
            .filter((a) => a[0] !== 'pageNumber' || a[1] !== appConfig.pageDefaultParams.pageNumber)
            .filter((a) => a[0] !== 'pageSize' || a[1] !== appConfig.pageDefaultParams.pageSize)
            .filter(([key]) => {
                return (
                    !filter.length ||
                    (filter.includes(key) && key !== 'dateRange') ||
                    ((key === 'startDate' || key === 'endDate') && filter.includes('dateRange'))
                );
            })
            .map(([key, value]) => [key, value]),
    );
};

export const formatParamsToUrl = (
    query: string,
    value: string | number | true | string[],
    options?: object,
): string => {
    let str = '';

    if (Array.isArray(value)) {
        str = queryString.stringify({ [query]: value }, { ...options });
    } else {
        const valEncode = encodeURIComponent(value);
        str = `${query}=${valEncode}`;
    }

    return str;
};

export const params2Url = (params: IParamsList, isBigQuery?: boolean | undefined): string => {
    let string = '';
    Object.entries(params).forEach(([key, value]) => {
        let str = '';
        if (value) {
            switch (key) {
                case 'prev':
                    str = formatParamsToUrl('prev', value);
                    break;
                case 'pageNumber':
                    str = formatParamsToUrl('page[number]', value);
                    break;
                case 'pageSize':
                    str = formatParamsToUrl('page[size]', value);
                    break;
                case 'read':
                    str = formatParamsToUrl('filter[read]', value);
                    break;
                case 'search':
                    str = formatParamsToUrl('search', value);
                    break;
                case 'startDate':
                    str = formatParamsToUrl('filter[start_date]', dayjs(value as string).format(appConfig.formatDate));
                    break;
                case 'endDate':
                    str = formatParamsToUrl('filter[end_date]', dayjs(value as string).format(appConfig.formatDate));
                    break;
                case 'fromDate':
                    str = formatParamsToUrl('filter[from_date]', dayjs(value as string).format(appConfig.formatDate));
                    break;
                case 'toDate':
                    str = formatParamsToUrl('filter[to_date]', dayjs(value as string).format(appConfig.formatDate));
                    break;
                case 'companyId':
                    str = formatParamsToUrl('filter[company_id]', value);
                    break;
                case 'packageId':
                    str = formatParamsToUrl('filter[package_id]', value);
                    break;
                case 'countryId':
                    str = formatParamsToUrl('filter[country_id]', value);
                    break;
                case 'cardId':
                    str = formatParamsToUrl('filter[card_id]', value);
                    break;
                case 'cardSetId':
                    str = formatParamsToUrl('filter[card_set_id]', value);
                    break;
                case 'exportType':
                    str = formatParamsToUrl('export_type', value);
                    break;
                case 'entityType':
                    str = formatParamsToUrl('entity_type', value);
                    break;
                case 'parentId':
                    str = formatParamsToUrl('node_id', value);
                    break;
                case 'stream':
                    str = formatParamsToUrl('stream', true);
                    break;
                case 'sessionId':
                    str = formatParamsToUrl('filter[session_id]', value);
                    break;
                case 'operatorId':
                    str = formatParamsToUrl('filter[operator_id]', value);
                    break;
                case 'transactionId':
                    str = formatParamsToUrl('filter[transaction_id]', value);
                    break;
                case 'unitTypeIds':
                    const query = isBigQuery ? 'filter[unit_type]' : 'filter[original_unit_type_id]';
                    const unitTypeValue = isBigQuery ? getUnitTypesSelectById(value as string)?.value : value;
                    const parsedUnitTypeValue = Array.isArray(unitTypeValue) ? unitTypeValue : String(unitTypeValue);

                    str = formatParamsToUrl(query, parsedUnitTypeValue, {
                        arrayFormat: 'bracket',
                        encode: false,
                    });
                    break;
                case 'fileId':
                    str = formatParamsToUrl('filter[file_id]', value);
                    break;
                case 'uploadFileStatusId':
                    str = formatParamsToUrl('filter[status]', value);
                    break;
                case 'gatewayId':
                    str = formatParamsToUrl('filter[gateway]', value);
                    break;
                case 'commandId':
                    str = formatParamsToUrl('filter[operation_name]', value);
                    break;
                case 'userId':
                    str = formatParamsToUrl('filter[user_id]', value);
                    break;
                case 'entityId':
                    str = formatParamsToUrl('filter[entity]', value);
                    break;
                case 'networkId':
                    str = formatParamsToUrl('filter[network_id]', value);
                    break;
                case 'zoneId':
                    str = formatParamsToUrl('filter[zone_id]', value);
                    break;
                case 'isActiveThisMonth':
                    str = formatParamsToUrl('filter[active_this_month]', value);
                    break;
                case 'isDelete':
                    if (value) {
                        str = formatParamsToUrl('scope[]', 'only_deleted');
                    }
                    break;
                case 'isActivityLogUsers':
                    if (value) {
                        str = formatParamsToUrl('scope[]', 'with_activity_logs');
                    }
                    break;
                case 'withDeleted':
                    if (value) {
                        str = formatParamsToUrl('scope[]', 'with_deleted');
                    }
                    break;
                case 'paymentType':
                    str = formatParamsToUrl('filter[payment_type]', value);
                    break;
                case 'companyTypeId':
                    str = formatParamsToUrl('filter[company_type_id]', value);
                    break;
                default:
                    str = '';
            }
            if (str) {
                string = `${string ? string + '&' : '?'}${str}`;
            }
        }
    });

    return string;
};

export const removePageOptions = (options: string): string => {
    return options
        .split('&')
        .filter((o) => !o.includes('page'))
        .join('&');
};
