import React, { ReactNode } from 'react';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import { CaretDownOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { NotificationMessage } from './NotificationMessage';
import cx from 'classnames';
import { appConfig } from '../../../settings';
import { IServerNotificationAPIGETPayload, IServerNotificationItem } from '../../../interfaces';
import { useAlarmsShowCount, useUiServerNotifications } from '../../../hooks';
import { changeStatusServerNotificationsItem } from '../../../middleware';
import { ITrCustom, tB, tI } from '../../../translate';
import Styles from '../m_styles.less';

type ITabContent = {
    handleIncrease(): void;
    isShowRead: boolean;
    total: number;
};

export const TabContent: React.FC<ITabContent> = observer(({ handleIncrease, isShowRead, total }: ITabContent) => {
    const noItemsMessage = tI(isShowRead ? 'No notifications' : 'No new notifications');
    const list = useUiServerNotifications(isShowRead);
    const showUnreadCount = useAlarmsShowCount();
    const subList = isShowRead ? [...list] : [...list.slice(0, showUnreadCount)];

    if (!list.length) {
        return <div className={Styles.notNotifications}>{noItemsMessage}</div>;
    }
    let previousDate = '###';

    const Content: ReactNode = subList?.map((i: IServerNotificationItem, index: number) => {
        const changeStatus = (): void => {
            changeStatusServerNotificationsItem(i.id, !i.isRead);
        };

        const message = (
            <NotificationMessage
                id={i.id}
                event={i.event as ITrCustom}
                payload={i.payload as IServerNotificationAPIGETPayload}
            />
        );
        const Item = (
            <div key={i.id}>
                {i.dateAgo && previousDate !== i.dateAgo ? i.dateAgo : null}
                <div
                    className={cx(
                        Styles.notificationItem,
                        { [Styles.notificationEven]: index % 2 === 0 && i.isRead },
                        { [Styles.notificationRead]: !i.isRead },
                    )}
                >
                    {!i.isRead ? <div className={Styles.notificationDot} /> : null}
                    <div className={cx(Styles.message, { [Styles.unRead]: !i.isRead })}>{message}</div>
                    <div className={Styles.toolBar}>
                        <CheckCircleOutlined
                            className={cx(Styles.button, { [Styles.blue]: !i.isRead })}
                            onClick={changeStatus}
                            title={tI(!i.isRead ? 'mark notification as read' : 'mark notification as unread')}
                        />
                    </div>
                </div>
            </div>
        );

        if (i.dateAgo && previousDate !== i.dateAgo) {
            previousDate = i.dateAgo;
        }

        return Item;
    });

    return (
        <div className={Styles.notifications}>
            <div className={Styles.notificationContent}>{Content}</div>
            {subList.length !== total && total > appConfig.checkServerNotificationsPageSize ? (
                <Button onClick={handleIncrease} type="primary" icon={<CaretDownOutlined />}>
                    {tB('More')}
                </Button>
            ) : null}
        </div>
    );
});
