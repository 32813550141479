import React from 'react';
import { observer } from 'mobx-react';
import { Select } from 'antd';
import cx from 'classnames';
import { appConfig } from '../../../settings';
import { usePagesStoreCurrentComponent, useUiPageSize } from '../../../hooks';
import { setUiToolBarPageNameSize } from '../../../middleware';
import { tI } from '../../../translate';
import { Catcher } from '../../Catcher';
import Styles from '../m_styles.less';

const { Option } = Select;

interface IFilterPageSizeProps {
    onChange?: () => void;
}

export const FilterPageSize: React.FC<IFilterPageSizeProps> = observer(({ onChange }: IFilterPageSizeProps) => {
    const pageSize: number = useUiPageSize();
    const { routeName } = usePagesStoreCurrentComponent();

    const onChangePageSize = (pageSize: number): void => {
        setUiToolBarPageNameSize(routeName, pageSize);
        onChange && onChange();
    };

    return (
        <Catcher>
            <div className={cx(Styles.labelFieldPrefix, Styles.labelFieldPrefixShow)}>{tI('Show')}</div>
            <Select
                className={Styles.fieldPageSize}
                getPopupContainer={(trigger): HTMLElement => trigger.parentElement}
                onChange={onChangePageSize}
                placeholder={tI('Page size')}
                value={pageSize}
                data-test="ShowEntriesSelect"
            >
                {appConfig.pageDefaultParams.pageSizeValues.map(
                    (i: number): JSX.Element => (
                        <Option key={i} value={i}>
                            <div className={Styles.itemPageSize}>{i}</div>
                        </Option>
                    ),
                )}
            </Select>
            <div className={cx(Styles.labelFieldSuffix, Styles.labelFieldSuffixEntries)}>{tI('entries')}</div>
        </Catcher>
    );
});
