import { IGetApiType } from '../REST';
import { IFilterSelectComposed } from '../interfaces';

export const getApiTypeHeader = (apiTypeId: IGetApiType): string => {
    return `${apiTypeId}-`;
};

export const getApiTypeWithUnitType = (apiTypeId: IGetApiType, unitTypeId: IFilterSelectComposed): string => {
    return `${getApiTypeHeader(apiTypeId)}${unitTypeId}`;
};

export const getApiTypeWithDate = (apiTypeId: IGetApiType): string => {
    return `${getApiTypeHeader(apiTypeId)}${Date.now()}`;
};

export const getApiTypeWithNumber = (apiTypeId: IGetApiType, n: number): string => {
    return `${getApiTypeHeader(apiTypeId)}${n}`;
};
