import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import {
    ICountriesList,
    ICountryWithZone,
    ICountryWithZoneList,
    IFilterParams,
    ISelectListCountries,
} from '../../interfaces';

export function useCountriesSelect(): ISelectListCountries {
    return useContext(MobXProviderContext).RootStore.countriesStore.select;
}

export function useCountriesWithZoneList(): ICountryWithZoneList {
    return useContext(MobXProviderContext).RootStore.countriesStore.countriesWithZoneList;
}
export function useCountriesWithZoneListByZoneId(zoneId: string): ICountryWithZoneList {
    // ToDo: 17.09.2021 - check need use useCountriesWithZoneList().filter
    return useContext(MobXProviderContext).RootStore.countriesStore.countriesWithZoneList.filter(
        (c: ICountryWithZone) => c.zoneId === zoneId,
    );
}

export function useCountriesTable(): ICountriesList {
    return useContext(MobXProviderContext).RootStore.countriesStore.list;
}

export function useCountriesFilterParams(): IFilterParams {
    return useContext(MobXProviderContext).RootStore.countriesStore.filterParams;
}
