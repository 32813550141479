import { SubscriberStore } from '../stores/subscriber';
import { cleanCompaniesStore, cleanOperatorsStore, getNetworksSelect } from './';
import { IGetApiResponse, IRequestAPIBody, apiManager } from '../REST';
import { IIncludeCardItemAPI, IItemAPI, IPatchItem, ISubscriberAPI, ISubscriberInfo } from '../interfaces';
import { IMapField, globalHexId, mapApi2UiField, mapUi2ApiField } from '../instruments';

export function getSubscriberEditRelationSelect(): void {
    getNetworksSelect();
}

export async function patchSubscriber(id: string, fields: IMapField): Promise<IPatchItem> {
    const SubscriberStoreInstance = SubscriberStore.getInstance();
    const apiBody: IRequestAPIBody = {
        data: {
            id,
            type: 'subscriber',
            attributes: mapUi2ApiField(fields),
        },
    };
    const { resp, isError } = await apiManager.getApi('PATCH_SUBSCRIBER', { id: id }, apiBody);
    const { errors, data } = await resp;
    const getData = data as IItemAPI;

    if (!isError && data) {
        SubscriberStoreInstance.setInfo({ id: getData.id, ...mapApi2UiField(getData.attributes) });
    }
    return { isError, errors };
}

export function cleanSubscriberStore(): void {
    cleanCompaniesStore();
    cleanOperatorsStore();
    const StoreInstance = SubscriberStore.getInstance();
    StoreInstance.cleanStore();
}

function createSubscriberInfo(): void {
    const subscriberInfo: ISubscriberInfo = {
        id: globalHexId.getId(),
    };
    const StoreInstance = SubscriberStore.getInstance();
    StoreInstance.setInfo(subscriberInfo);
}

function setSubscriber({ resp, isError }: IGetApiResponse): void {
    if (!isError) {
        const typingResp = resp as ISubscriberAPI;
        const attributes = mapApi2UiField({ ...typingResp.data?.attributes });
        const operatorId = typingResp.data?.relationships?.operator?.data?.id;
        const operator = typingResp.included?.find((i) => i.type === 'operator' && i.id === operatorId)?.attributes
            ?.name;
        const countryId = typingResp.data?.relationships?.country?.data?.id;
        const country = typingResp.included?.find((i) => i.type === 'country' && i.id === countryId)?.attributes?.name;
        const cardId = typingResp.data?.relationships?.card?.data?.id;
        const printedId = (
            typingResp.included?.find((i) => i.type === 'card' && i.id === cardId) as IIncludeCardItemAPI
        ).attributes.printed_id;

        const info: ISubscriberInfo = {
            id: typingResp.data?.id || '',
            ...attributes,
            cardId,
            country,
            countryId,
            operator,
            operatorId,
            printedId,
        };
        const StoreInstance = SubscriberStore.getInstance();
        StoreInstance.setInfo(info);
    }
}

export async function getSubscriber(id?: string): Promise<boolean> {
    if (!id) {
        createSubscriberInfo();
        return true;
    } else {
        const apiResponse = await apiManager.getApi('GET_SUBSCRIBER', { id: id });
        if (apiResponse.isError) {
            return false;
        } else {
            setSubscriber(apiResponse);
            return true;
        }
    }
}
