import { IItemAPI } from './included';
import { ITypeAny } from './typeAny';
import { IRelationshipItem, IRequestRelationshipMainItem } from '../REST';

export enum ActivityLogSubList {
    LISTS = 'lists',
}
export enum ActivityLogSubListTitle {
    'cardsAdded' = 'cards_added',
    'records' = 'records',
}

type IActivityLogSubListTitleKeys = keyof typeof ActivityLogSubListTitle;
type IActivityLogSubListTitleValues = typeof ActivityLogSubListTitle;

export type EntityList = 'Card' | 'Subscriber' | 'Operator' | 'Order' | 'CardSet' | 'User';
type ActionList =
    | 'assigned'
    | 'bulk_destroyed'
    | 'created'
    | 'destroyed'
    | 'impersonation/end'
    | 'impersonation/start'
    | 'imported'
    | 'updated';

export type IActivityLogListItem = {
    [id: string]: string | number | null;
};
export type IActivityLogLists = {
    lists?: { [id in IActivityLogSubListTitleKeys]?: IActivityLogListItem[] };
};

export type IActivityLogItemMetaAttributes =
    | IActivityLogLists
    | IActivityLogItemMetaCardAttributes
    | IActivityLogItemMetaCardAttributes[]
    | IActivityLogItemMetaSubscribeAttributes
    | IActivityLogItemMetaSubscribeAttributes[]
    | IActivityLogItemMetaOrderAttributes
    | IActivityLogItemMetaOrderAttributes[]
    | IActivityLogItemMetaCardSetAttributes
    | IActivityLogItemMetaCardSetAttributes[]
    | IActivityLogItemMetaUserAttributes
    | IActivityLogItemMetaUserAttributes[];
export interface IActivityLogItem {
    id?: string;
    action?: ActionList;
    createdAt?: string;
    entity: EntityList;
    logableId?: string;
    meta: IActivityLogItemMetaAttributes;
    raw?: ITypeAny;
    userData?: {
        companyName?: string;
        email?: string;
        firstName?: string;
        lastName?: string;
    };
    userId?: string;
}

export interface IActivityLogListAPI {
    data?: IActivityLogItemAPI[];
    included?: IItemAPI[];
}

export interface IActivityLogItemAPI extends IRelationshipItem {
    attributes: {
        action?:
            | 'destroyed'
            | 'bulk_destroyed'
            | 'updated'
            | 'created'
            | 'imported'
            | 'impersonation/end'
            | 'impersonation/start';
        created_at?: string;
        entity?: 'Card' | 'Subscriber' | 'Operator' | 'Order' | 'CardSet' | 'User' | 'Company';
        user_id?: string;
        meta: IActivityLogItemMetaAPI;
    };
    relationships?: {
        company?: {
            data?: IRequestRelationshipMainItem;
        };
        balance_adjustable?: {
            data?: IRequestRelationshipMainItem;
        };
    };
}

export interface IActivityLogItemMetaAPI {
    records?:
        | IActivityLogItemMetaAPICardAttributes[]
        | IActivityLogItemMetaAPISubscribeAttributes[]
        | IActivityLogItemMetaAPIOrderAttributes[]
        | IActivityLogItemMetaAPICardSetAttributes[]
        | IActivityLogItemMetaAPIUserAttributes[];
    attributes?:
        | IActivityLogItemMetaAPICardAttributes
        | IActivityLogItemMetaAPISubscribeAttributes
        | IActivityLogItemMetaAPIOrderAttributes
        | IActivityLogItemMetaAPICardSetAttributes
        | IActivityLogItemMetaAPIUserAttributes;
    cards_added: IActivityLogItemMetaAPICardAttributes[];
    user_copy_attributes: IActivityLogItemMetaAPIUserAttributes;
}

export interface IActivityLogItemMetaAPIAttributeDefault {
    id?: ActivityLogItemMetaAttributeValue;
    notes?: ActivityLogItemMetaAttributeValue;
    created_at?: ActivityLogItemMetaAttributeValue;
    deleted_at?: ActivityLogItemMetaAttributeValue;
    updated_at?: ActivityLogItemMetaAttributeValue;
    first_seen?: ActivityLogItemMetaAttributeValue;
    last_seen?: ActivityLogItemMetaAttributeValue;
}

export interface IActivityLogItemMetaAPISubscribeAttributes extends IActivityLogItemMetaAPIAttributeDefault {
    imsi?: ActivityLogItemMetaAttributeValue;
    iccid?: {
        value: ActivityLogItemMetaAttributeValue;
    };
    msisdn?: ActivityLogItemMetaAttributeValue;
    status?: ActivityLogItemMetaAttributeValue;
    card_id?: ActivityLogItemMetaAttributeValue;
    locker_id?: ActivityLogItemMetaAttributeValue;
    status_new?: ActivityLogItemMetaAttributeValue;
    operator_id?: ActivityLogItemMetaAttributeValue;
    roaming_imsi?: ActivityLogItemMetaAttributeValue;
    dispatch_lock?: ActivityLogItemMetaAttributeValue;
    active_this_month?: ActivityLogItemMetaAttributeValue;
    suspender_company_id?: ActivityLogItemMetaAttributeValue;
}

export interface IActivityLogItemMetaAPICardAttributes extends IActivityLogItemMetaAPIAttributeDefault {
    eid?: ActivityLogItemMetaAttributeValue;
    imei?: ActivityLogItemMetaAttributeValue;
    name?: ActivityLogItemMetaAttributeValue;
    lock_imei?: ActivityLogItemMetaAttributeValue;
    plmn_list?: ActivityLogItemMetaAttributeValue;
    printed_id?: ActivityLogItemMetaAttributeValue;
    device_name?: ActivityLogItemMetaAttributeValue;
    device_type?: ActivityLogItemMetaAttributeValue;
    locked_imei?: ActivityLogItemMetaAttributeValue;
    usage_limit?: ActivityLogItemMetaAttributeValue;
    subscribers?: IActivityLogItemMetaAPISubscribeAttributes[];
}

export interface IActivityLogItemMetaAPIOrderAttributes extends IActivityLogItemMetaAPIAttributeDefault {
    cost?: ActivityLogItemMetaAttributeValue;
    paid?: ActivityLogItemMetaAttributeValue;
    user_id?: ActivityLogItemMetaAttributeValue;
    finished?: ActivityLogItemMetaAttributeValue;
    processed?: ActivityLogItemMetaAttributeValue;
    company_id?: ActivityLogItemMetaAttributeValue;
    currency_id?: ActivityLogItemMetaAttributeValue;
    description?: ActivityLogItemMetaAttributeValue;
    payment_url?: ActivityLogItemMetaAttributeValue;
    orderable_id?: ActivityLogItemMetaAttributeValue;
    payment_type?: ActivityLogItemMetaAttributeValue;
    product_type?: ActivityLogItemMetaAttributeValue;
    orderable_type?: ActivityLogItemMetaAttributeValue;
    productable_id?: ActivityLogItemMetaAttributeValue;
    transaction_id?: ActivityLogItemMetaAttributeValue;
    productable_type?: ActivityLogItemMetaAttributeValue;
}

export interface IActivityLogItemMetaAPICardSetAttributes extends IActivityLogItemMetaAPIAttributeDefault {
    name?: ActivityLogItemMetaAttributeValue;
    active?: ActivityLogItemMetaAttributeValue;
    company_id?: ActivityLogItemMetaAttributeValue;
    geolocation_enabled?: ActivityLogItemMetaAttributeValue;
}

export interface IActivityLogItemMetaAPIUserAttributes extends IActivityLogItemMetaAPIAttributeDefault {
    email?: ActivityLogItemMetaAttributeValue;
    last_name?: ActivityLogItemMetaAttributeValue;
    first_name?: ActivityLogItemMetaAttributeValue;
}

interface IActivityLogItemMetaAttributeDefault {
    id?: ActivityLogItemMetaAttributeValue;
    notes?: ActivityLogItemMetaAttributeValue;
    createdAt?: ActivityLogItemMetaAttributeValue;
    deletedAt?: ActivityLogItemMetaAttributeValue;
    updatedAt?: ActivityLogItemMetaAttributeValue;
    firstSeen?: ActivityLogItemMetaAttributeValue;
    lastSeen?: ActivityLogItemMetaAttributeValue;
}

interface IActivityLogItemMetaCardAttributes extends IActivityLogItemMetaAttributeDefault {
    eid?: ActivityLogItemMetaAttributeValue;
    imei?: ActivityLogItemMetaAttributeValue;
    name?: ActivityLogItemMetaAttributeValue;
    lockImei?: ActivityLogItemMetaAttributeValue;
    plmnList?: ActivityLogItemMetaAttributeValue;
    printedId?: ActivityLogItemMetaAttributeValue;
    deviceName?: ActivityLogItemMetaAttributeValue;
    deviceType?: ActivityLogItemMetaAttributeValue;
    lockedImei?: ActivityLogItemMetaAttributeValue;
    usageLimit?: ActivityLogItemMetaAttributeValue;
    subscribers?: IActivityLogItemMetaSubscribeAttributes[];
}

interface IActivityLogItemMetaSubscribeAttributes extends IActivityLogItemMetaAttributeDefault {
    imsi?: ActivityLogItemMetaAttributeValue;
    iccId?: ActivityLogItemMetaAttributeValue;
    msisdn?: ActivityLogItemMetaAttributeValue;
    status?: ActivityLogItemMetaAttributeValue;
    cardId?: ActivityLogItemMetaAttributeValue;
    lockerId?: ActivityLogItemMetaAttributeValue;
    statusNew?: ActivityLogItemMetaAttributeValue;
    operatorId?: ActivityLogItemMetaAttributeValue;
    roamingImsi?: ActivityLogItemMetaAttributeValue;
    dispatchLock?: ActivityLogItemMetaAttributeValue;
    activeThisMonth?: ActivityLogItemMetaAttributeValue;
    suspenderCompanyId?: ActivityLogItemMetaAttributeValue;
}

interface IActivityLogItemMetaOrderAttributes extends IActivityLogItemMetaAttributeDefault {
    cost?: ActivityLogItemMetaAttributeValue;
    paid?: ActivityLogItemMetaAttributeValue;
    userId?: ActivityLogItemMetaAttributeValue;
    finished?: ActivityLogItemMetaAttributeValue;
    processed?: ActivityLogItemMetaAttributeValue;
    companyId?: ActivityLogItemMetaAttributeValue;
    currencyId?: ActivityLogItemMetaAttributeValue;
    description?: ActivityLogItemMetaAttributeValue;
    paymentUrl?: ActivityLogItemMetaAttributeValue;
    orderableId?: ActivityLogItemMetaAttributeValue;
    paymentType?: ActivityLogItemMetaAttributeValue;
    productType?: ActivityLogItemMetaAttributeValue;
    orderableType?: ActivityLogItemMetaAttributeValue;
    productableId?: ActivityLogItemMetaAttributeValue;
    transactionId?: ActivityLogItemMetaAttributeValue;
    productableType?: ActivityLogItemMetaAttributeValue;
}

interface IActivityLogItemMetaCardSetAttributes extends IActivityLogItemMetaAttributeDefault {
    name?: ActivityLogItemMetaAttributeValue;
    active?: ActivityLogItemMetaAttributeValue;
    companyId?: ActivityLogItemMetaAttributeValue;
    geolocationEnabled?: ActivityLogItemMetaAttributeValue;
    cardSetPackageInstanceJoinsId?: ActivityLogItemMetaAttributeValue;
}

interface IActivityLogItemMetaUserAttributes extends IActivityLogItemMetaAttributeDefault {
    email?: ActivityLogItemMetaAttributeValue;
    lastName?: ActivityLogItemMetaAttributeValue;
    firstName?: ActivityLogItemMetaAttributeValue;
}

type ActivityLogItemMetaAttributeValue =
    | [string | number | boolean | null, string | number | boolean | null]
    | string
    | number
    | boolean
    | null;
