import React from 'react';
import { ActivityExpandedRowLine, ActivityLogFieldValue, ActivityLogLists } from './';
import { ActivityLogSubList, IActivityLogItem } from '../../../interfaces';
import { Catcher } from '../../Catcher';
import Styles from '../../styles/m_viewStyles.less';

export function ActivityLogUpdateInfo({ log }: { log: IActivityLogItem }) {
    const { meta, entity } = log;
    return (
        <Catcher>
            <div className={Styles.expandedRow}>
                <ActivityExpandedRowLine title={`${entity} Id`}>
                    <ActivityLogFieldValue entity={entity} value={log.logableId} prop={'id'} />
                </ActivityExpandedRowLine>
                {Object.entries(meta).map(([metaKey, metaValue]) => {
                    if (metaKey === ActivityLogSubList.LISTS) {
                        return <ActivityLogLists key={metaKey} lists={metaValue} />;
                    } else if (Array.isArray(metaValue)) {
                        return (
                            <ActivityExpandedRowLine title={metaKey} key={metaKey}>
                                &quot;
                                <ActivityLogFieldValue value={metaValue?.[0]} prop={metaKey} entity={log.entity} />
                                &quot; ---&gt; &quot;
                                <ActivityLogFieldValue value={metaValue?.[1]} prop={metaKey} entity={log.entity} />
                                &quot;
                            </ActivityExpandedRowLine>
                        );
                    }

                    return (
                        <ActivityExpandedRowLine title={metaKey} key={metaKey}>
                            <ActivityLogFieldValue value={metaValue} prop={metaKey} entity={log.entity} />
                        </ActivityExpandedRowLine>
                    );
                })}
            </div>
        </Catcher>
    );
}
