import React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import { Catcher, LinkComponent, TableItemDeleteColumn, TableItemToolbar, columnDeleted, columnToolbar } from '../../';
import { ICardListItem, IItemAPI, IMainItem, ISelect } from '../../../interfaces';
import { useCardsList, useProfile, useProfileIsRight, useUiIsLoading } from '../../../hooks';
import {
    addNotification,
    deleteCard,
    deleteCardSubscribers,
    getCardBySubscriberId,
    setUiForcedUpdateTime,
} from '../../../middleware';
import { tF, tI } from '../../../translate';
import '../../../customStyle/table.less';
import Styles from '../../styles/m_viewStyles.less';

export const CardTableList: React.FC = observer(() => {
    const isLord = useProfileIsRight('LORD');
    const isLoading = useUiIsLoading();
    const cardList = useCardsList();
    const { isCompanyTypeIdMNOHost } = useProfile();

    const MNOHostColumn = [
        {
            title: tF('Printed ID'),
            dataIndex: 'printedId',
            // ToDo: 19.08.2021 - Printed ID must be the ICCID of the  subscribers allowed in the creation of the company and will be displayed here, all other ICCIDs and Printed IDs must be hidden.
        },
    ];
    const allColumn = [
        {
            title: tF('Name'),
            dataIndex: 'nameLink',
        },
        {
            title: tF('Printed ID'),
            dataIndex: 'printedId',
        },
        {
            title: tF('Card Sets'),
            dataIndex: 'setList',
        },
    ];
    const tableColumns = [
        ...(isCompanyTypeIdMNOHost ? MNOHostColumn : allColumn),
        columnDeleted(isLord),
        columnToolbar,
    ];

    const handleDelete = async (id: string): Promise<void> => {
        const getSubResp = await getCardBySubscriberId(id);

        if (getSubResp.isError) {
            addNotification({
                type: 'error',
                message: 'An error has occurred while fetching subscribers',
                isUsersError: true,
            });
        }

        const data = getSubResp?.resp?.data as IItemAPI[];

        const subArr = data.map((value: IMainItem) => value.id) || [];
        if (subArr.length) {
            const deleteResp = await deleteCardSubscribers(id, subArr);
            if (deleteResp.isError) {
                addNotification({
                    type: 'error',
                    message: 'An error has occurred while deleting subscribers',
                    isUsersError: true,
                });
            } else {
                const { isError } = await deleteCard(id);

                if (isError) {
                    addNotification({
                        type: 'error',
                        message: 'An error has occurred while deleting the card',
                        isUsersError: true,
                    });
                }
            }
        } else {
            const { isError } = await deleteCard(id);

            if (isError) {
                addNotification({
                    type: 'error',
                    message: 'An error has occurred while deleting the card',
                    isUsersError: true,
                });
            }
        }
        setUiForcedUpdateTime();
    };

    const tableData = cardList.map((c: ICardListItem) => {
        const CardSetListLinks = c.cardSets?.map((s: ISelect) => {
            return <LinkComponent currentListComponent={'CardSetsList'} id={s.id} isLight name={s.name} key={s.id} />;
        });
        const printedId = c.printedId ? c.printedId : `# ${c.id}`;

        return {
            key: c.id,
            nameLink: <LinkComponent currentListComponent={'CardList'} id={c.id} isLight name={c.name} />,
            printedId: <LinkComponent currentListComponent={'CardList'} id={c.id} isLight name={printedId} />,
            setList: CardSetListLinks,
            deleted: <TableItemDeleteColumn deletedAt={c.deletedAt} />,
            toolbar: (
                <TableItemToolbar
                    deletedAt={c.deletedAt}
                    deleteItem={(): Promise<void> => handleDelete(c.id)}
                    id={c.id}
                    itemName={`"${printedId}" ${tI('card')}`}
                    popupMessage={
                        'Deleting this card will delete all IMSIs (subscribers) attached to it from the system. Do you want to proceed?'
                    }
                />
            ),
        };
    });

    return (
        <Catcher>
            <div className={Styles.tablePlace} data-test="CardListPlace">
                <Table
                    className={'table-theme'}
                    columns={tableColumns}
                    dataSource={tableData}
                    loading={isLoading}
                    pagination={false}
                    size={'middle'}
                />
            </div>
        </Catcher>
    );
});
