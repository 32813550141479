import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { IPackagesForSale, IPackagesSold, ISelectList } from '../../interfaces';

export function usePackagesForSale(): IPackagesForSale {
    return useContext(MobXProviderContext).RootStore.packagesStore.packagesForSale;
}

export function usePackagesForSaleTotal(): number {
    return useContext(MobXProviderContext).RootStore.packagesStore.forSaleTotal;
}

export function usePackagesSold(): IPackagesSold {
    return useContext(MobXProviderContext).RootStore.packagesStore.packagesSold;
}

export function usePackagesSoldTotal(): number {
    return useContext(MobXProviderContext).RootStore.packagesStore.soldTotal;
}

export function usePackagesSelect(): ISelectList {
    return useContext(MobXProviderContext).RootStore.packagesStore.select;
}
