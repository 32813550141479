import React from 'react';
import { observer } from 'mobx-react';
import { Catcher, ListWrapper } from '../';
import { usePurchaseRatesFilterParams } from '../../hooks';
import { cleanPurchaseRatesStore } from '../../middleware';
import { PurchaseRatesListTable } from './Components/';

export const PurchaseRatesList: React.FC = observer(() => {
    const filterParams = usePurchaseRatesFilterParams();

    return (
        <Catcher>
            <ListWrapper cleanStore={cleanPurchaseRatesStore} filterParams={filterParams}>
                <PurchaseRatesListTable />
            </ListWrapper>
        </Catcher>
    );
});
