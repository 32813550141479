import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Col, Row, Table } from 'antd';
import { CaretRightOutlined, TableOutlined } from '@ant-design/icons';
import cx from 'classnames';
import { IGetApiType } from '../../REST';
import { ICompaniesHierarchyTableIsNotBigQuery, ICompanyHierarchyRevenue } from '../../interfaces';
import {
    useCompaniesHierarchyIsHaveChildrenIsNotBigQuery,
    useCompaniesHierarchyTableIsNotBigQuery,
    useUiIsLoading,
} from '../../hooks';
import { abortSelectedFetch, cleanCompaniesStore, getCompaniesHierarchyList } from '../../middleware';
import { stringMoney } from '../../instruments';
import { tF, tP } from '../../translate';
import { Catcher, GoPageButton } from '../../Components';
import '../../customStyle/table.less';
import Styles from '../styles/m_viewStyles.less';

type IExpandIcons = {
    record: ICompaniesHierarchyTableIsNotBigQuery;
};

interface IShowRevenueList {
    revenue?: ICompanyHierarchyRevenue[];
}
export const ShowRevenueList: React.FC<IShowRevenueList> = ({ revenue }: IShowRevenueList) => {
    return (
        <Catcher>
            {revenue?.map((r: ICompanyHierarchyRevenue) => {
                return <div key={r.currency_code}>{stringMoney(r.amount, r.currency_code)}</div>;
            })}
        </Catcher>
    );
};

export const CompaniesHierarchyIsNotBigQuery: React.FC = observer(() => {
    const [expandedRowKeys, setExpandedRowKeys] = useState<Array<string>>([]);
    const isLoading = useUiIsLoading();
    const companiesHierarchyList = useCompaniesHierarchyTableIsNotBigQuery();
    const isHaveChildren = useCompaniesHierarchyIsHaveChildrenIsNotBigQuery();
    const usedApiTypes: IGetApiType[] = ['GET_COMPANIES_HIERARCHY'];

    useEffect(() => {
        getCompaniesHierarchyList({});
        return (): void => {
            abortSelectedFetch(usedApiTypes);
            cleanCompaniesStore();
        };
    }, []);

    const tableColumns = [
        {
            title: tF('Name'),
            dataIndex: 'name',
        },
        {
            title: tF('Balance'),
            dataIndex: 'balance',
            className: Styles.columnRight,
        },
        {
            title: tF('Payment type'),
            dataIndex: 'paymentType',
            className: Styles.columnRight,
        },
        {
            title: tF('Cost'),
            dataIndex: 'cost',
            className: Styles.columnRight,
        },
        {
            title: tF('Revenue'),
            dataIndex: 'RevenueList',
            className: Styles.columnRight,
        },
        {
            title: tF('Listed Cards'),
            dataIndex: 'cardsCount',
            className: Styles.columnRight,
        },
        {
            title: tF('Notes Count'),
            dataIndex: 'nodesCountShow',
            className: Styles.columnRight,
        },
    ];

    const tableData = companiesHierarchyList.map((c: ICompaniesHierarchyTableIsNotBigQuery) => {
        return {
            ...c,
        };
    });
    const expandRow = async (key: string): Promise<void> => {
        if (!isHaveChildren.findChildren(key)) {
            await getCompaniesHierarchyList({ parentId: key });
        }
        setExpandedRowKeys([...expandedRowKeys, key]);
    };
    const collapseRow = (key: string): void => {
        setExpandedRowKeys(expandedRowKeys.filter((r) => r !== key));
    };
    const expandIcon = ({ record }: IExpandIcons): JSX.Element => {
        return !record.nodesCount ? (
            <span className={Styles.squareIcon}>■</span>
        ) : expandedRowKeys.includes(record.key) ? (
            <CaretRightOutlined
                className={cx(Styles.collapseIcon, Styles.collapseIconRotate)}
                onClick={(): void => collapseRow(record.key)}
            />
        ) : (
            <CaretRightOutlined className={Styles.collapseIcon} onClick={(): Promise<void> => expandRow(record.key)} />
        );
    };

    return (
        <Catcher>
            <div className={Styles.main}>
                <div className={Styles.content}>
                    <Row className={Styles.header}>
                        <Col xs={24} className={Styles.title}>
                            <GoPageButton
                                componentName={'CompaniesList'}
                                icon={<TableOutlined />}
                                title={'Table View'}
                            />
                            {tP('Companies by Hierarchy')}
                        </Col>
                    </Row>
                    <div className={Styles.tablePlace}>
                        <Table
                            dataSource={tableData}
                            columns={tableColumns}
                            pagination={false}
                            className={'table-theme'}
                            size={'middle'}
                            loading={isLoading}
                            expandable={{ expandIcon, expandedRowKeys }}
                        />
                    </div>
                </div>
            </div>
        </Catcher>
    );
});
