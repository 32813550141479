import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Catcher, ListWrapper } from '../';
import { useRatesFilterParams } from '../../hooks';
import { cleanRatesStore, getMyPurchasesList } from '../../middleware';
import { SalesRatesListTable } from './Components';

export const SalesRatesList: React.FC = observer(() => {
    const filterParams = useRatesFilterParams();

    useEffect(() => {
        getMyPurchasesList();
    }, []);

    return (
        <Catcher>
            <ListWrapper cleanStore={cleanRatesStore} filterParams={filterParams}>
                <SalesRatesListTable />
            </ListWrapper>
        </Catcher>
    );
});
