import React from 'react';
import {
    AccountBookOutlined,
    AimOutlined,
    ApiOutlined,
    AppstoreOutlined,
    BankOutlined,
    CreditCardOutlined,
    CustomerServiceOutlined,
    DashboardOutlined,
    DatabaseOutlined,
    EnvironmentOutlined,
    EuroOutlined,
    ExportOutlined,
    EyeOutlined,
    FileAddOutlined,
    FileDoneOutlined,
    FileExcelOutlined,
    FlagOutlined,
    FormOutlined,
    FundViewOutlined,
    GatewayOutlined,
    GlobalOutlined,
    GroupOutlined,
    ImportOutlined,
    InfoCircleOutlined,
    IssuesCloseOutlined,
    LinkOutlined,
    NotificationOutlined,
    NumberOutlined,
    PartitionOutlined,
    PhoneOutlined,
    PlusSquareOutlined,
    ShoppingCartOutlined,
    ShoppingOutlined,
    SolutionOutlined,
    SwapOutlined,
    TeamOutlined,
    UploadOutlined,
    UserOutlined,
    UserSwitchOutlined,
    WalletOutlined,
} from '@ant-design/icons';
import { generateError } from '../../instruments';

export type IMenuIcons =
    | 'AccountBookOutlined'
    | 'AimOutlined'
    | 'ApiOutlined'
    | 'AppstoreOutlined'
    | 'BankOutlined'
    | 'CreditCardOutlined'
    | 'CustomerServiceOutlined'
    | 'DashboardOutlined'
    | 'DatabaseOutlined'
    | 'EnvironmentOutlined'
    | 'EuroOutlined'
    | 'ExportOutlined'
    | 'EyeOutlined'
    | 'FileAddOutlined'
    | 'FileDoneOutlined'
    | 'FileExcelOutlined'
    | 'FlagOutlined'
    | 'FormOutlined'
    | 'FundViewOutlined'
    | 'GatewayOutlined'
    | 'GlobalOutlined'
    | 'GroupOutlined'
    | 'ImportOutlined'
    | 'InfoCircleOutlined'
    | 'IssuesCloseOutlined'
    | 'LinkOutlined'
    | 'NotificationOutlined'
    | 'NumberOutlined'
    | 'PartitionOutlined'
    | 'PhoneOutlined'
    | 'PlusSquareOutlined'
    | 'ShoppingCartOutlined'
    | 'ShoppingOutlined'
    | 'SolutionOutlined'
    | 'SwapOutlined'
    | 'TeamOutlined'
    | 'UploadOutlined'
    | 'UserOutlined'
    | 'UserSwitchOutlined'
    | 'WalletOutlined';

export interface IMenuIconsProps {
    iconName: IMenuIcons;
}
export const MenuIcons: React.FC<IMenuIconsProps> = ({ iconName }: IMenuIconsProps): JSX.Element | null => {
    switch (iconName) {
        case 'AccountBookOutlined':
            return <AccountBookOutlined />;
        case 'AimOutlined':
            return <AimOutlined />;
        case 'ApiOutlined':
            return <ApiOutlined />;
        case 'AppstoreOutlined':
            return <AppstoreOutlined />;
        case 'BankOutlined':
            return <BankOutlined />;
        case 'CreditCardOutlined':
            return <CreditCardOutlined />;
        case 'CustomerServiceOutlined':
            return <CustomerServiceOutlined />;
        case 'DashboardOutlined':
            return <DashboardOutlined />;
        case 'DatabaseOutlined':
            return <DatabaseOutlined />;
        case 'EnvironmentOutlined':
            return <EnvironmentOutlined />;
        case 'EuroOutlined':
            return <EuroOutlined />;
        case 'ExportOutlined':
            return <ExportOutlined />;
        case 'EyeOutlined':
            return <EyeOutlined />;
        case 'FileAddOutlined':
            return <FileAddOutlined />;
        case 'FileExcelOutlined':
            return <FileExcelOutlined />;
        case 'FileDoneOutlined':
            return <FileDoneOutlined />;
        case 'FlagOutlined':
            return <FlagOutlined />;
        case 'FormOutlined':
            return <FormOutlined />;
        case 'FundViewOutlined':
            return <FundViewOutlined />;
        case 'GatewayOutlined':
            return <GatewayOutlined />;
        case 'GlobalOutlined':
            return <GlobalOutlined />;
        case 'GroupOutlined':
            return <GroupOutlined />;
        case 'ImportOutlined':
            return <ImportOutlined />;
        case 'InfoCircleOutlined':
            return <InfoCircleOutlined />;
        case 'IssuesCloseOutlined':
            return <IssuesCloseOutlined />;
        case 'LinkOutlined':
            return <LinkOutlined />;
        case 'NotificationOutlined':
            return <NotificationOutlined />;
        case 'NumberOutlined':
            return <NumberOutlined />;
        case 'PartitionOutlined':
            return <PartitionOutlined />;
        case 'PhoneOutlined':
            return <PhoneOutlined />;
        case 'PlusSquareOutlined':
            return <PlusSquareOutlined />;
        case 'ShoppingCartOutlined':
            return <ShoppingCartOutlined />;
        case 'ShoppingOutlined':
            return <ShoppingOutlined />;
        case 'SolutionOutlined':
            return <SolutionOutlined />;
        case 'SwapOutlined':
            return <SwapOutlined />;
        case 'TeamOutlined':
            return <TeamOutlined />;
        case 'UploadOutlined':
            return <UploadOutlined />;
        case 'UserOutlined':
            return <UserOutlined />;
        case 'UserSwitchOutlined':
            return <UserSwitchOutlined />;
        case 'WalletOutlined':
            return <WalletOutlined />;
        default:
            generateError(iconName);
            console.error(`Not found Icon - "${iconName}"`);
            return null;
    }
};
