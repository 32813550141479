import React from 'react';
import { observer } from 'mobx-react';
import { Button, Table } from 'antd';
import { Catcher, LinkComponent } from '../../';
import { tokenManager } from '../../../REST';
import { useRouterStore, useUsersTable } from '../../../hooks';
import { cleanProfileStore, getProfileInfo, impersonateUser, setUiInitPageList } from '../../../middleware';
import { tF } from '../../../translate';

export const ImpersonateUserTable: React.FC = observer(() => {
    const list = useUsersTable();
    const routerStore = useRouterStore();

    const impersonateAsUser = async (id: string) => {
        const { isError, resp } = await impersonateUser(id);

        const { token } = resp as any;

        if (!isError) {
            await tokenManager.enableImpersonatedToken(token);
            await cleanProfileStore();
            setUiInitPageList();
            await getProfileInfo();
            await routerStore.goTo('Dashboard');
        }
    };

    const tableColumns = [
        {
            title: 'Impersonate User',
            dataIndex: 'impersonateUser',
        },
        {
            title: tF('First name'),
            dataIndex: 'firstName',
        },
        {
            title: tF('Last name'),
            dataIndex: 'lastName',
        },
        {
            title: tF('Email'),
            dataIndex: 'email',
        },
        {
            title: tF('Company'),
            dataIndex: 'companyName',
        },
        {
            title: tF('Role'),
            dataIndex: 'userType',
        },
    ];

    const tableData = list.map((user) => ({
        ...user,
        key: user.id,
        firstName: <LinkComponent currentListComponent={'UsersList'} id={user.id} name={user.firstName} isLight />,
        lastName: <LinkComponent currentListComponent={'UsersList'} id={user.id} name={user.lastName} isLight />,
        email: <LinkComponent currentListComponent={'UsersList'} id={user.id} name={user.email} isLight />,
        companyName: (
            <LinkComponent currentListComponent={'CompaniesList'} id={user.companyId} name={user.companyName} isLight />
        ),
        impersonateUser: <Button danger ghost onClick={() => impersonateAsUser(user.id)}>{`Impersonate as`}</Button>,
    }));

    return (
        <Catcher>
            <Table
                dataSource={tableData}
                columns={tableColumns}
                pagination={false}
                className={'table-theme'}
                size={'middle'}
            />
        </Catcher>
    );
});
