import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { useProfileFeatureFlagsIsBigQuery } from './useProfileStores';
import { useUiCurrentUnitType } from './useUiStore';
import {
    IBigQueryUsage,
    ICountryActiveCardItem,
    ICountryInfo,
    ICountryOperatorItem,
    ICountryTelecomUsage,
    ITelecomUsage,
} from '../../interfaces';
import { showOriginalUnitByType } from '../../instruments';

export function useCountryInfo(): ICountryInfo {
    return useContext(MobXProviderContext).RootStore.countryStore.info;
}
export function useCountryOperatorList(): ICountryOperatorItem[] {
    return useContext(MobXProviderContext).RootStore.countryStore.operatorsList;
}

export function useCountryActiveCardsList(): ICountryActiveCardItem[] {
    return useContext(MobXProviderContext).RootStore.countryStore.activeCardsList;
}

export function useCountryTelecomUsageTable(): ICountryTelecomUsage[] {
    const isBigQuery = useProfileFeatureFlagsIsBigQuery();
    const telecomUsageList = useContext(MobXProviderContext).RootStore.dashboardStore.telecomUsageList;
    const currentUnitType = useUiCurrentUnitType();

    if (isBigQuery) {
        return telecomUsageList.map((item: IBigQueryUsage) => {
            const showUnit = showOriginalUnitByType(`${item.volume}`, currentUnitType);
            return { name: item.name, usage: showUnit, id: item.operator_id };
        });
    } else {
        return telecomUsageList.map((item: ITelecomUsage) => {
            const showUnit = showOriginalUnitByType(item.original_units, currentUnitType);
            return { name: item.operator_name, usage: showUnit, id: item.operator_id };
        });
    }
}
