import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Form } from 'antd';
import { FieldData } from 'rc-field-form/es/interface';
import { IAntDFormChangeFieldName, IEditForm, IFormFields } from '../../../interfaces';
import { useSubscriberInfo } from '../../../hooks';
import {
    apiMessagesDeleteMessageByFieldName,
    apiMessagesGetFormRules,
    apiMessagesGetFormRulesFieldBlank,
    apiMessagesSaveMessages,
    patchSubscriber,
} from '../../../middleware';
import { cleanPathFields } from '../../../instruments';
import { tF } from '../../../translate';
import { CardInfoToolBar, CardMainLayout, Catcher, InputDebounce } from '../../../Components';

export const EditSubscriberForm: React.FC<IEditForm> = observer(({ setEditMode }: IEditForm) => {
    const [form] = Form.useForm();
    const initialValues = useSubscriberInfo();
    const isNew = !initialValues.id;
    const [isSending, setSending] = useState(false);
    const [isChanged, setChanged] = useState(false);

    useEffect((): void => {
        if (!isChanged) {
            form.setFieldsValue(initialValues);
        }
    }, [initialValues]);

    const onFieldsChange = (changedFields: FieldData[]): void => {
        const fieldNameChange =
            changedFields && changedFields[0] && (changedFields[0].name as IAntDFormChangeFieldName);
        if (fieldNameChange) {
            apiMessagesDeleteMessageByFieldName(fieldNameChange);
        }

        setChanged(true);
    };

    const onFinish = async (fields: IFormFields): Promise<void> => {
        setSending(true);
        const cleanFields = cleanPathFields(fields, initialValues);
        const { isError, errors } = await patchSubscriber(initialValues.id, cleanFields);

        if (isError) {
            apiMessagesSaveMessages(errors);
        } else {
            setEditMode && setEditMode(false);
        }
        setSending(false);
    };

    const imsiTitle = tF('IMSI');
    const roamingImsiTitle = tF('Roaming');
    const iccidTitle = tF('ICCID');
    const msisdnTitle = tF('MSISDN');

    return (
        <Catcher>
            <CardMainLayout idSpinners={['GET_SUBSCRIBER', 'GET_OPERATOR']} isEdit name={initialValues.imsi}>
                <Form
                    form={form}
                    name="countryInfo"
                    initialValues={initialValues}
                    onFieldsChange={onFieldsChange}
                    onFinish={onFinish}
                    scrollToFirstError={true}
                    layout={'vertical'}
                >
                    <Form.Item name="imsi" label={imsiTitle} rules={apiMessagesGetFormRulesFieldBlank()}>
                        <InputDebounce placeholder={imsiTitle} isFirstInput />
                    </Form.Item>
                    <Form.Item name="roamingImsi" label={roamingImsiTitle} rules={apiMessagesGetFormRules}>
                        <InputDebounce placeholder={roamingImsiTitle} />
                    </Form.Item>
                    <Form.Item name="iccId" label={iccidTitle} rules={apiMessagesGetFormRules}>
                        <InputDebounce placeholder={iccidTitle} />
                    </Form.Item>
                    <Form.Item name="msisdn" label={msisdnTitle} rules={apiMessagesGetFormRules}>
                        <InputDebounce placeholder={msisdnTitle} />
                    </Form.Item>
                    <CardInfoToolBar isLoading={isSending} isNew={isNew} isSubmitButton />
                </Form>
            </CardMainLayout>
        </Catcher>
    );
});
