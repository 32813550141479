import React from 'react';
import { observer } from 'mobx-react';
import { IFieldLayout, IOperatorActivity, IOperatorActivityGuestRecord } from '../../../interfaces';
import { byteRange1000, dateText2Utc } from '../../../instruments';
import { ITrPage, tI } from '../../../translate';
import { CardLayout, Catcher, FieldInfoAdvanced, cardLayoutOptions } from '../../../Components';

interface IActivityMonth {
    title: ITrPage;
    useSelectHook: () => IOperatorActivity;
}

export const ActivityMonth: React.FC<IActivityMonth> = observer(({ title, useSelectHook }: IActivityMonth) => {
    const layout: IFieldLayout = {
        labelCol: {
            flex: '0 1 120px',
        },
        valCol: {
            flex: '1 1 165px',
        },
    };
    const select = useSelectHook();

    const visitedNetwork = select.guestRecords
        ?.filter((r: IOperatorActivityGuestRecord) => r.originalUnitType === 'byte')
        .map((r: IOperatorActivityGuestRecord) => {
            const originalUnits = r.originalUnits ? r.originalUnits : '0';
            const value = `${r.operatorName} ${tI('data')}: ${byteRange1000(originalUnits)}`;
            return (
                <p key={value}>
                    {r.operatorName} {tI('data')}:{' '}
                    <span title={`${originalUnits} ${tI('Bytes')}`}>{byteRange1000(originalUnits)}</span>
                </p>
            );
        });

    return (
        <Catcher>
            <CardLayout
                columnOption={cardLayoutOptions.tightLists}
                idSpinners={['GET_OPERATOR_ACTIVITY']}
                title={title}
            >
                <FieldInfoAdvanced layout={layout} title={'Start'}>
                    {dateText2Utc(select.startDate)}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced layout={layout} title={'End'}>
                    {dateText2Utc(select.endDate)}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced layout={layout} title={'CallDataRecords'} />
                <FieldInfoAdvanced isIconBlank layout={layout} title={'CDRs'}>
                    {select.count}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced isIconBlank layout={layout} title={'Data'}>
                    {byteRange1000(select.originalUnits ? select.originalUnits : 0)}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced layout={layout} title={'Per visited network'}>
                    {visitedNetwork}
                </FieldInfoAdvanced>
            </CardLayout>
        </Catcher>
    );
});
