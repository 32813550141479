import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Form, Switch } from 'antd';
import { Rule } from 'antd/es/form';
import { Catcher } from '../../';
import { IFormValidatorResponse, IRuleAny, IUserAlarmsKeys, ToggleItem } from '../../../interfaces';
import { useProfileAlarms } from '../../../hooks';
import { apiMessagesGetFormRules } from '../../../middleware';
import { tF } from '../../../translate';

type ToggleList = {
    toggleArr: ToggleItem[];
    isLoading?: boolean;
    setSelectCount?: (n: number) => void;
};

export const ToggleList: React.FC<ToggleList> = observer(({ toggleArr, isLoading, setSelectCount }: ToggleList) => {
    const initialValues = useProfileAlarms();
    const [selectItems, setSelectItems] = useState<string[]>([]);

    useEffect(() => {
        const ttt = toggleArr
            .filter((i: ToggleItem) => {
                const name = i.name as IUserAlarmsKeys;
                return initialValues && initialValues[name];
            })
            .map((i: ToggleItem) => i.name);

        setSelectItems(ttt);
        setSelectCount && setSelectCount(ttt.length);

        return (): void => {
            setSelectCount && setSelectCount(0);
        };
    }, []);

    const addSelects = (i: string): void => {
        const newItems = [...selectItems, i];
        setSelectItems(newItems);
        setSelectCount && setSelectCount(newItems.length);
    };
    const removeSelects = (i: string): void => {
        const newItems = selectItems.filter((j) => j !== i);
        setSelectItems(newItems);
        setSelectCount && setSelectCount(newItems.length);
    };

    const validateAnyItem: Array<Rule> = [
        ...apiMessagesGetFormRules,
        {
            validator(rule: IRuleAny, value: boolean): IFormValidatorResponse {
                if (value) {
                    addSelects(rule.field as string);
                } else {
                    removeSelects(rule.field as string);
                }

                return Promise.resolve();
            },
        },
    ];

    return (
        <Catcher>
            {toggleArr.map((value, index) => {
                return (
                    <Form.Item
                        className={'switchField'}
                        label={tF(value.label)}
                        name={value.name}
                        rules={validateAnyItem}
                        key={index}
                        valuePropName="checked"
                    >
                        <Switch loading={isLoading} />
                    </Form.Item>
                );
            })}
        </Catcher>
    );
});
