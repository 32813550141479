import React from 'react';
import { observer } from 'mobx-react';
import { Catcher } from '../../';
import { CountryOverlayView } from './';
import { Polygon } from '@react-google-maps/api';
import { IGoogleGeoPosition, IUseDashboardCountryUsageCountryPolygon } from '../../../interfaces';
import { useDashboardCountriesUsageForMapPolygons } from '../../../hooks';
import { setDashboardOverlayView } from '../../../middleware';

export const PolygonsCountryTraffic: React.FC = observer((): JSX.Element => {
    const countriesPolygons = useDashboardCountriesUsageForMapPolygons();
    const polygons = countriesPolygons
        .map((country: IUseDashboardCountryUsageCountryPolygon) => {
            const border = country.geo?.border
                ?.filter((i: IGoogleGeoPosition) => i.lat && i.lng)
                .map((i: IGoogleGeoPosition) => ({ lat: i.lat || 1, lng: i.lng || 1 }));

            if (border?.length) {
                return (
                    <Polygon
                        key={country.polygonId}
                        path={border}
                        options={{
                            fillColor: country.fillColor,
                            fillOpacity: 0.6,
                            strokeColor: country.fillColor,
                            strokeOpacity: 0.4,
                            strokeWeight: 1,
                        }}
                        onMouseOver={(): void => setDashboardOverlayView(<CountryOverlayView id={country.id} />)}
                        onMouseOut={(): void => setDashboardOverlayView(null)}
                    />
                );
            } else {
                return <div key={undefined} />;
            }
        })
        .filter((i) => i.key);

    return <Catcher>{polygons}</Catcher>;
});
