import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Catcher } from '../';
import { IShowFormComponent } from '../../interfaces';
import { usePackageSoldInfo, useRouterStore } from '../../hooks';
import {
    abortAllFetch,
    addNotification,
    cleanPackageSoldRelationSelect,
    cleanPackageStore,
    getBoughtPackageById,
    getCardSetsSelect,
    getPackageSoldRelationSelect,
} from '../../middleware';
import { EditPackageInstance, ShowPackageInstance } from './Components';
import Styles from '../styles/m_itemCardStyles.less';

export const PackageInstance: React.FC<IShowFormComponent> = observer(({ isEdit, isNew }: IShowFormComponent) => {
    const routerStore = useRouterStore();
    const [id, setId] = useState<string>();
    const { companyId } = usePackageSoldInfo();

    const fetchPackageById = async (id: string, _isEdit = false): Promise<void> => {
        const isError = await getBoughtPackageById(id);
        if (isError) {
            addNotification({
                type: 'error',
                message: 'Not found',
                description: `Package id - "${id}" not found`,
                duration: 5,
                isUsersError: true,
            });
            routerStore.goTo('PackagesBuy');
        } else if (_isEdit) {
            getPackageSoldRelationSelect();
        }
    };

    const setEdit = (isEdit?: boolean): void => {
        if (id) {
            if (isEdit) {
                getPackageSoldRelationSelect();
                fetchPackageById(id, true);
                routerStore.goTo('PackageInstanceEdit', { params: { id } });
            } else if (isNew) {
                routerStore.goTo('PackagesBuy');
            } else {
                fetchPackageById(id);
                routerStore.goTo('PackageInstance', { params: { id } });
            }
        } else {
            routerStore.goTo('PackagesBuy');
        }
    };

    useEffect(() => {
        const { params } = routerStore.routerState;
        if (params.id) {
            setId(params.id);
        }
    });

    useEffect(() => {
        if (isEdit || isNew) {
            getPackageSoldRelationSelect();
        }
        return (): void => {
            abortAllFetch(); // ToDo: 07.04.2021 - On remake use abortSelectedFetch()
            cleanPackageSoldRelationSelect();
            cleanPackageStore();
        };
    }, []);

    useEffect(() => {
        getCardSetsSelect({ companyId }, true);
    }, [companyId]);

    useEffect(() => {
        if (id) {
            fetchPackageById(id, isEdit);
        }
    }, [id]);

    return (
        <Catcher>
            <div className={Styles.main}>
                {isEdit || isNew ? (
                    <EditPackageInstance setEditMode={setEdit} isNew={isNew} />
                ) : (
                    <ShowPackageInstance setEditMode={setEdit} />
                )}
            </div>
        </Catcher>
    );
});
