import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { RouterStore } from 'mobx-state-router';
import { RouterState } from 'mobx-state-router/src/stores/RouterState';
import { getChildren, getChildrenOfTypeByChildren, getComponentInfo, getParenName } from '../../Pages/pagesBook';
import { useProfileIsRightRole } from './useProfileStores';
import { IComponentName, IGetChildrenPage, IPage, IPageList, IRightList, IRouterState } from '../../interfaces';
import { isAvailablePage } from '../../middleware';
import { sortByString } from '../../instruments';

export function useRouterStore(): RouterStore {
    return useContext(MobXProviderContext).RootStore.routerStore;
}

export function useRouterStoreUseExternalUrlManager(): boolean {
    return useContext(MobXProviderContext).RootStore.routerPagesStore.isUseExternalUrlManager;
}

export function useRouterStoreUseExternalPageTitleManager(): boolean {
    return useContext(MobXProviderContext).RootStore.routerPagesStore.isUseExternalPageTitleManager;
}

export function usePagesStoreAvailablePages(): IPage[] {
    return useContext(MobXProviderContext).RootStore.routerPagesStore.availablePages;
}
export function usePagesStoreSearchPages(): IPage[] {
    return usePagesStoreAvailablePages()
        .filter((p: IPage) => !p.subMenu)
        .filter((p: IPage) => !!p.title)
        .sort((a: IPage, b: IPage) => sortByString(a.title, b.title));
}

export function usePagesStoreMenu(): IPage[] {
    return useContext(MobXProviderContext).RootStore.routerPagesStore.menu;
}

export function usePagesStoreCurrentPage(): IPage {
    return useContext(MobXProviderContext).RootStore.routerPagesStore.currentPage;
}

export function usePagesStoreCurrentComponent(): IRouterState {
    return useContext(MobXProviderContext).RootStore.routerPagesStore.currentComponent;
}

export function usePagesStoreAvailableComponents(): IRouterState[] {
    return useContext(MobXProviderContext).RootStore.routerPagesStore.availableComponents;
}

export function usePagesStoreDefaultComponent(): IRouterState {
    return useContext(MobXProviderContext).RootStore.routerPagesStore.defaultComponent;
}

export function usePagesStoreRequestedPage(): IRouterState {
    return useContext(MobXProviderContext).RootStore.routerPagesStore.requestedPage;
}

export function usePagesStoreListRight(page: IPageList): IRightList {
    const componentMap = useContext(MobXProviderContext).RootStore.routerPagesStore.availablePages;
    const listComponent = componentMap.find((c: IPage) => c.componentName === page) as IPage;
    const isDelete = useProfileIsRightRole(listComponent?.roleRightDelete);

    const childrenComponents = getChildren(page);

    return {
        isList: !!listComponent,
        isView:
            childrenComponents?.VIEW && !!componentMap.find((c: IPage) => c.componentName === childrenComponents.VIEW),
        isNew: childrenComponents?.NEW && !!componentMap.find((c: IPage) => c.componentName === childrenComponents.NEW),
        isEdit:
            childrenComponents?.EDIT && !!componentMap.find((c: IPage) => c.componentName === childrenComponents.EDIT),
        isDelete: !!listComponent && isDelete,
        ...childrenComponents,
    };
}

export const useChildrenOfPage = (parent: IPageList = 'Dashboard', id?: string): IGetChildrenPage => {
    const { isView, VIEW, isEdit, EDIT } = usePagesStoreListRight(parent);

    let viewComponentLink = VIEW ? getComponentInfo(VIEW).url : '/';
    let editComponentLink = EDIT ? getComponentInfo(EDIT).url : '/';
    if (id) {
        viewComponentLink = viewComponentLink.replace(/:id/, id);
        editComponentLink = editComponentLink.replace(/:id/, id);
    }
    return {
        editComponentIsRight: isEdit,
        editComponentLink,
        editComponentName: EDIT ? EDIT : 'Dashboard',
        viewComponentIsRight: isView,
        viewComponentLink,
        viewComponentName: VIEW ? VIEW : 'Dashboard',
    };
};

export function usePagesStoreThisListRight(): IRightList {
    const { routeName } = usePagesStoreCurrentComponent();
    return usePagesStoreListRight(routeName as IPageList);
}

export function useRouterState(): RouterState {
    const routerStore = useRouterStore();
    return routerStore.routerState;
}
export function usePagesStoreEditComponent(): IComponentName | undefined {
    const { routeName } = usePagesStoreCurrentComponent();
    return getChildrenOfTypeByChildren(routeName, 'EDIT');
}
export function useEditComponentUrl(): string | undefined {
    const editComponent = usePagesStoreEditComponent();
    const componentInfo = editComponent && getComponentInfo(editComponent);
    const { params } = useRouterState();
    const id = params?.id;
    if (id && componentInfo?.url) {
        return componentInfo.url.replace(':id', id);
    }
}

export function usePagesStoreIsRightEdit(): boolean {
    const editComponent = usePagesStoreEditComponent();
    return !!editComponent && isAvailablePage(editComponent);
}

export function usePagesStoreListName(): IComponentName | undefined {
    const { routeName } = usePagesStoreCurrentComponent();
    return getParenName(routeName);
}
