import React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import cx from 'classnames';
import { IBalanceLogItem } from '../../../interfaces';
import { useBalanceLogTable, useUiIsLoading } from '../../../hooks';
import { dateText2Utc, stringMoney } from '../../../instruments';
import { tF } from '../../../translate';
import { Catcher, LinkComponent } from '../../../Components';
import '../../../customStyle/table.less';
import Styles from '../../styles/m_viewStyles.less';

export const BalanceLogTableList: React.FC = observer(() => {
    const isLoading = useUiIsLoading();
    const list = useBalanceLogTable();

    const tableColumns = [
        {
            title: tF('Date'),
            dataIndex: 'date',
        },
        {
            title: tF('Company'),
            dataIndex: 'companyLink',
        },
        {
            title: tF('Description'),
            dataIndex: 'description',
        },
        {
            title: '',
            dataIndex: 'cost',
            className: Styles.columnRed,
        },
        {
            title: tF('New balance'),
            dataIndex: 'balanceAfterShow',
            className: Styles.columnRight,
        },
    ];
    const tableData = list.map((b: IBalanceLogItem) => {
        const key = b.id;

        return {
            key,
            companyLink: (
                <LinkComponent currentListComponent={'CompaniesList'} id={b.companyId} isLight name={b.company} />
            ),
            ...b,
            balanceAfterShow: stringMoney(b.balanceAfter),
            date: dateText2Utc(b.updatedAt),
        };
    });

    return (
        <Catcher>
            <div className={cx(Styles.tablePlace)}>
                <Table
                    className={'table-theme'}
                    columns={tableColumns}
                    dataSource={tableData}
                    loading={isLoading}
                    pagination={false}
                    size={'middle'}
                />
            </div>
        </Catcher>
    );
});
