import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { useProfileIsRight } from './useProfileStores';
import { ICardSetInfo, IGetApiType } from '../../interfaces';

export function useCardSetInfo(): ICardSetInfo {
    return useContext(MobXProviderContext).RootStore.cardSetStore.cardSetInfo;
}

export function useCardSetSpinners(): IGetApiType[] {
    return useContext(MobXProviderContext).RootStore.cardSetStore.spinners;
}

export function useCardSetSpinnersInfo(): IGetApiType[] {
    return useContext(MobXProviderContext).RootStore.cardSetStore.spinnersInfo;
}
export function useCardSetSpinnersRelation(): IGetApiType[] {
    return useContext(MobXProviderContext).RootStore.cardSetStore.spinnersRelation;
}

export function useCardSetIsLoadingInfo(): boolean {
    return useContext(MobXProviderContext).RootStore.cardSetStore.isSpinningInfo;
}

export function useIsAbleToManageCardSetGeolocation(): boolean | undefined {
    const isLord = useProfileIsRight('LORD');
    const cardSetInfo = useCardSetInfo();
    return cardSetInfo.geolocationEditable || isLord;
}
