import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Checkbox, Divider, Form, Row } from 'antd';
import { FieldData } from 'rc-field-form/es/interface';
import { IAntDFormChangeFieldNameLong, IEditForm, IFormFields, IPackageInstanceForm } from '../../../interfaces';
import { useCardSetsSelect, useCompaniesSelect, usePackageSoldInfo, useRouterStore } from '../../../hooks';
import {
    apiMessagesGetFormRules,
    apiMessagesSaveMessages,
    cleanApiMessages,
    editPackageInstance,
} from '../../../middleware';
import { onFieldErrorHandle } from '../../../instruments';
import { tF } from '../../../translate';
import { CardInfoToolBar, CardMainLayout, Catcher, FormSelect, cardLayoutOptions } from '../../../Components';
import { PackageSoldInfoBox } from './../Components';

export const EditPackageInstance: React.FC<IEditForm> = observer(({ isNew = false }: IEditForm) => {
    const [form] = Form.useForm();
    const [isSending, setSending] = useState(false);
    const routerStore = useRouterStore();
    const packageSoldInfo = usePackageSoldInfo();
    const initialValues = { ...packageSoldInfo };

    const checkValid = (): void => {
        form.validateFields();
    };

    useEffect(() => {
        form.setFieldsValue(initialValues);
    }, [JSON.stringify(initialValues)]);

    async function onFinish(fields: IFormFields): Promise<void> {
        cleanApiMessages();
        setSending(true);
        const { params } = routerStore.routerState;
        const formValues: IPackageInstanceForm = {
            ...fields,
            id: params?.id,
        } as IPackageInstanceForm;

        const { isError, errors = [] } = await editPackageInstance(formValues);
        if (isError) {
            apiMessagesSaveMessages(errors);
            checkValid();
        } else {
            routerStore.goTo('PackagesBuy');
        }
        setSending(false);
    }

    const onFieldChange = (fields: FieldData[]): void => {
        if (fields[0]?.name) {
            const { name } = fields[0];
            const fieldName = name as IAntDFormChangeFieldNameLong;
            onFieldErrorHandle(fieldName[0]);
        }
    };

    return (
        <Catcher>
            <Row>
                <CardMainLayout
                    idSpinners={[
                        'PATCH_PACKAGE_INSTANCE',
                        'GET_ONE_PACKAGE_INSTANCE',
                        'GET_COMPANIES_SELECT',
                        'GET_CARD_SETS_SELECT',
                    ]}
                    isEdit
                    name={packageSoldInfo.packageName}
                    columnOption={cardLayoutOptions.packageCard}
                >
                    <PackageSoldInfoBox packageSoldInfo={packageSoldInfo} />
                    <Divider />
                    <Form
                        form={form}
                        name="packageInstance"
                        initialValues={initialValues}
                        onFieldsChange={onFieldChange}
                        onFinish={onFinish}
                        scrollToFirstError={true}
                        layout={'vertical'}
                        className={'formStyle'}
                        validateTrigger={'onBlur'}
                    >
                        <FormSelect
                            fieldName={'companyId'}
                            label={'Company'}
                            rules={apiMessagesGetFormRules}
                            useSelectHook={useCompaniesSelect}
                            disabled={!isNew}
                        />
                        <FormSelect
                            fieldName={'cardSetId'}
                            label={'Assigned Card Set'}
                            useSelectHook={useCardSetsSelect}
                            isNotClear={true}
                            dataTest={'EditPackageInstanceCardSet'}
                        />
                        <Form.Item name="renewal" valuePropName="checked" rules={apiMessagesGetFormRules}>
                            <Checkbox data-test={'EditPackageInstanceRenewalCheckbox'}>{tF('Auto renewal')}</Checkbox>
                        </Form.Item>
                        <Divider />
                        <CardInfoToolBar
                            isLoading={isSending}
                            isNew={isNew}
                            isSubmitButton
                            dataTest={'EditPackageInstance'}
                        />
                    </Form>
                </CardMainLayout>
            </Row>
        </Catcher>
    );
});
