import { CurrencyStore } from '../stores/currency';
import { cleanApiMessages } from './';
import { IBodyItemResponse, apiManager } from '../REST';
import { ICurrenciesListItemAPI, IPatchItem, IRequestAPIBody } from '../interfaces';
import { IMapField } from '../instruments';

function setCurrencyInfoStore(data: ICurrenciesListItemAPI): void {
    const StoreInterface = CurrencyStore.getInstance();

    StoreInterface.setCurrencyInfo({ id: data.id, ...data?.attributes });
}

export async function getCurrencyById(id: string): Promise<boolean> {
    cleanApiMessages();
    const { resp, isError } = await apiManager.getApi('GET_CURRENCY', { id: id });
    const { data } = resp as IBodyItemResponse;

    if (!isError && data?.id) {
        setCurrencyInfoStore(data);
    }
    return !isError;
}

export function cleanCurrencyStore(): void {
    const StoreInterface = CurrencyStore.getInstance();
    StoreInterface.cleanStore();
    cleanApiMessages();
}

export async function patchCurrency(id: string, fields: IMapField): Promise<IPatchItem> {
    const body: IRequestAPIBody = {
        data: {
            id: id,
            type: 'currency',
            attributes: fields,
        },
    };
    const { resp, isError } = await apiManager.getApi('PATCH_CURRENCY', { id: id }, body);
    const { errors, data } = resp as IBodyItemResponse;

    if (!isError && data) {
        setCurrencyInfoStore(data);
    }
    return { isError, errors };
}
