import React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import { TablePaginationConfig } from 'antd/es/table';
import { GroupOutlined } from '@ant-design/icons';
import { CardLayout, Catcher, SkyPaginationTotal, cardLayoutOptions } from '../../index';
import { appConfig } from '../../../settings';
import { ICardDeviceFile } from '../../../interfaces';
import { useCardDeviceFilesList } from '../../../hooks';
import { tF } from '../../../translate';

export const CardDeviceFilesTable: React.FC = observer(() => {
    const pageSize = appConfig.pageDefaultParams.includeViewPageSize;
    const list = useCardDeviceFilesList();
    const tableColumns = [
        {
            title: tF('Zip file'),
            dataIndex: 'zipFile',
        },
        {
            title: tF('Created at'),
            dataIndex: 'createdAt',
        },
        {
            title: tF('Status'),
            dataIndex: 'status',
        },
        {
            title: tF('Total Count'),
            dataIndex: 'totalCount',
        },
        {
            title: tF('Total Saved'),
            dataIndex: 'totalSaved',
        },
        {
            title: tF('Total Errors'),
            dataIndex: 'totalErrors',
        },
        {
            title: tF('Error Message'),
            dataIndex: 'errorMessage',
        },
    ];

    const tableData = list.map((file: ICardDeviceFile) => ({
        key: file.id,
        zipFile: file.zipFile,
        createdAt: file.createdAt,
        status: file.status,
        totalCount: file.totalCount,
        totalSaved: file.totalSaved,
        totalErrors: file.totalErrors,
        errorMessage: file.errorMessage,
    }));

    const pagination: false | TablePaginationConfig =
        pageSize > list.length
            ? false
            : {
                  total: list.length,
                  pageSize: pageSize,
                  className: 'customPagination',
                  showSizeChanger: false,
                  size: 'default',
                  showTotal: SkyPaginationTotal,
              };

    return (
        <Catcher>
            <CardLayout
                columnOption={cardLayoutOptions.cardDeviceFilesTable}
                count={list.length}
                icon={<GroupOutlined />}
                idSpinners={['GET_CARD_DEVICE_FILES']}
                isTable
                title={'GSMA TAC/IMEI file'}
            >
                <Table
                    dataSource={tableData}
                    columns={tableColumns}
                    pagination={pagination}
                    className={'table-theme'}
                    size={'small'}
                />
            </CardLayout>
        </Catcher>
    );
});
