import { IGeoLocation } from './geotracking';

export type IRevenues = IRevenueItem[];
export type IRevenueItem = {
    currency_code: string;
    amount: string;
};

export enum DashboardUnitTypes {
    VOICE = 'Voice',
    SMS = 'SMS',
    DATA = 'Data',
}

export type IDashboardType = 'Main' | 'Finances';

export interface IDashboardTypes {
    [id: string]: IDashboardType;
}
export const dashboardType: IDashboardTypes = {
    main: 'Main',
    finances: 'Finances',
};

export type IChartTitleItem = string | { role: 'annotationText' | 'annotation' };
export type IChartTitle = Array<IChartTitleItem>;
export type IChartItemData = Array<string | number | undefined>;
export type IChartData = Array<IChartTitle | IChartItemData>;

export type IUsageMap = Array<ICardSetUsage> | Array<ICardsUsage> | Array<ICountryUsage> | Array<IBigQueryUsage>;

export type ICartItemData = {
    [id: string]: string | number;
};

export type IRankItemStatsAPI = {
    currency_id: number | null;
    currency_abbreviation: string | null;
    cost: string | null;
    revenue: string | null;
};
export type IRankItemAPI = {
    name: string | null;
    stats: Array<IRankItemStatsAPI> | null;
};
export type IRankCardRevenueItemAPI = IRankItemAPI & {
    card_id: number | null;
    eid: string | null;
    printed_id: string | null;
};
export type IRankCardSetRevenueItemAPI = IRankItemAPI & {
    card_set_id: number | null;
};
export type IRankCompanyRevenueItemAPI = IRankItemAPI & {
    company_id: number | null;
};
export type IRankCountryRevenueItemAPI = IRankItemAPI & {
    country_id: number | null;
    iso3: string | null;
    printed_id: string | null;
};
export type IRankOperatorRevenueItemAPI = IRankItemAPI & {
    operator_id: number | null;
};
export type IRankTimelineRevenueItemAPI = IRankItemAPI & {
    start_date: string | null;
    month: string | null;
};
export type IRankZoneRevenueItemAPI = IRankItemAPI & {
    zone_id: number | null;
};

export type IStatItemAPI = {
    cost?: string;
    currency_abbreviation?: string;
    currency_id?: number;
    currency_symbol?: string;
    revenue?: string;
};
export type IStatItem = {
    cost?: string;
    currencyAbbreviation?: string;
    currencyId?: number;
    currencySymbol?: string;
    revenue?: string;
};

export type ICostRevenueItem = {
    name?: string;
    stats?: Array<IStatItem>;
};

export type ICardSetRevenueItem = ICostRevenueItem & { cardSetId?: number };
export type ICardsRevenueItem = ICostRevenueItem & { cardId?: number; eid?: string; printedId?: string };
export type ICompanyRevenueItem = ICostRevenueItem & { companyId?: number };
export type ICountryRevenueItem = ICostRevenueItem & { countryId?: number; iso3?: string };
export type IOperatorRevenueItem = ICostRevenueItem & { operatorId?: number };
export type ITimelineRevenueItem = ICostRevenueItem & { startDate?: string; month?: string };
export type ITimelineZoneItem = ICostRevenueItem & { zoneId?: number };

export type IRevenueItemANY = ICardSetRevenueItem &
    ICardsRevenueItem &
    ICompanyRevenueItem &
    ICountryRevenueItem &
    IOperatorRevenueItem &
    ITimelineRevenueItem &
    ITimelineZoneItem;

export interface IUsageActivityUnitType {
    unitType?: string;
    volume?: number;
    volumeRatio?: number;
}
export interface IUsageActivityUnitTypeAPI {
    unit_type?: string;
    volume?: string;
    volume_ratio?: number;
}
export interface IUsageActivityRollAPI {
    cards_count?: number;
    cards_ratio?: number;
    cost_ratio?: number;
    costs?: Array<IStatItemAPI>;
    stats_by_unit_type?: Array<IUsageActivityUnitTypeAPI>;
}
export interface IUsageActivityRoll {
    cardsCount?: number;
    cardsRatio?: number;
    costRatio?: number;
    costs: Array<IStatItem>;
    statsByUnitType: Array<IUsageActivityUnitType>;
}
export interface IFinancesRollAPI {
    cards?: IFinancesRollCardsAPI;
    costs_and_revenues?: Array<IStatItemAPI>;
    volume?: string;
}

export type IFinancesRollCardsAPI = {
    active?: number;
    inactive?: number;
};
export type IFinancesRollCards = {
    active: number;
    inactive: number;
};
export type IFinancesRollCardsHook = IFinancesRollCards & {
    total: number;
    pieActive: number;
};

export interface IBigQueryUsage {
    company_id?: number;
    card_set_id?: number;
    currency_id?: number;
    operator_id?: number;
    subscriber_id?: number;
    card_id?: number;
    network_id?: number;
    country_id?: number;
    zone_id?: number;
    volume?: number;
    cost?: number;
    count?: number;
    name?: string;
    month?: string;
    start_date?: string;
    iso3?: string;
}

export interface IBigQueryActivityUsage {
    stats_by_unit_type: [
        {
            unit_type?: string;
            cost?: number;
            volume?: number;
            volume_ratio?: number;
            cost_ratio?: number;
        },
    ];
    cards_count?: number;
    cards_ratio?: number;
}

export interface ICardSetUsage {
    card_set_id?: number;
    card_set_name?: string;
    original_units?: string;
}
export interface ICardsUsage {
    card_id?: number;
    card_printed_id?: string;
    original_units?: string;
}
export interface ICompanyUsage {
    company_id?: number;
    company_name?: string;
    original_units?: string;
}
export interface ITelecomUsage {
    operator_id?: number;
    operator_name?: string;
    original_units?: string;
}
export interface IZoneUsage {
    zone_id?: number;
    zone_name?: string;
    original_units?: string;
}
export interface ITimelineUsage {
    time?: string;
    original_units?: string;
    month?: string;
}
export interface ICountryUsage {
    country_id?: number;
    country_name?: string;
    country_iso3?: string;
    original_units?: string;
}

export type IFinancesUsageCostItemAPI = {
    currency_code?: string;
    amount?: string;
};
export type IFinancesUsageOriginalUnitsItemAPI = {
    currency_code?: string;
    original_units?: string;
};
export type IFinancesUsageOriginalRevenueItemAPI = {
    currency_code?: string;
    amount?: string;
};

export interface IFinancesUsageAPI {
    cards?: {
        total?: number;
        active?: number;
    };
    unassigned_units?: string;
    original_units?: Array<IFinancesUsageOriginalUnitsItemAPI>;
    cost?: Array<IFinancesUsageCostItemAPI>;
    revenue?: Array<IFinancesUsageOriginalRevenueItemAPI>;
}
export interface IActivityRollIsNotBigQuery {
    uniq_cards?: number;
    original_units?: string;
    cost?: string;
    uniq_cards_ratio?: string;
    original_units_ratio?: string;
    cost_ratio?: string;
    sms?: string;
    sms_ratio?: string;
    voice?: string;
    voice_ratio?: string;
}
export interface ITimelineRevenue {
    month?: string;
    time?: string;
    amount?: string;
    abbreviation?: string;
    revenue?: IRevenues;
}
export interface ICountryRevenue {
    country_id?: number;
    country_name?: string;
    country_iso3?: string;
    amount?: string;
    abbreviation?: string;
    revenue?: IRevenues;
}

export interface ITelecomRevenue {
    operator_id?: number;
    operator_name?: string;
    amount?: string;
    abbreviation?: string;
    revenue?: IRevenues;
}
export interface IZoneRevenue {
    zone_id?: number;
    zone_name?: string;
    amount?: string;
    abbreviation?: string;
    revenue?: IRevenues;
}
export interface ICompanyRevenue {
    company_id?: number;
    company_name?: string;
    amount?: string;
    abbreviation?: string;
    revenue?: IRevenues;
}
export interface ICardsRevenue {
    card_id?: number;
    card_printed_id?: string;
    amount?: string;
    abbreviation?: string;
    revenue?: IRevenues;
}
export interface ICardSetRevenue {
    card_set_id?: number;
    card_set_name?: string;
    amount?: string;
    abbreviation?: string;
    revenue?: IRevenues;
}

export type ICurrencyValue = { currency?: string; value?: string };

export type IDashboardFinancesUsageCards = {
    total: number;
    active: number;
};

export interface IDashboardFinancesUsage {
    cards: IDashboardFinancesUsageCards;
    unassignedUnits?: string;
    originalUnits?: string;
    revenues?: ICurrencyValue[];
    costs?: ICurrencyValue[];
}

export interface IDashboardActivityRollIsNotBigQuery {
    cost?: number;
    costRatio?: number;
    uniqCards?: number;
    uniqCardsRatio?: number;
    originalUnits?: number;
    originalUnitsRatio?: number;
    sms?: number;
    smsRatio?: number;
    voice?: number;
    voiceRatio?: number;
}

export interface IDashboardActivityRoll {
    cardsCount?: number;
    cardsRatio?: number;
    costRatio?: number;
    costs: Array<IStatItem>;
    originalUnits?: number;
    originalUnitsRatio?: number;
    sms?: number;
    smsRatio?: number;
    voice?: number;
    voiceRatio?: number;
}

export type IGoogleGeoPosition = {
    lat?: number;
    lng?: number;
};

export interface ICardLocationMapInfo extends IGeoLocation {
    name?: string;
    id?: string;
}

export type IDashboardCountryUsageList = Array<ICountryUsageListItem>;

export interface ICountryUsageListItem {
    countryId?: number;
    countryName?: string;
    countryIso3?: string;
    originalUnits?: string;
}

export type IJsonCountryBorderPoint = number[];
export type IJsonCountryBorderPoints = IJsonCountryBorderPoint[];
export type IJsonCountryBordersPoints = IJsonCountryBorderPoints[];
export type IJsonCountryBorder = IJsonCountryBordersPoints | IJsonCountryBorderPoints;

export type IJSONCountryInfo = {
    alpha3?: string | undefined | null;
    ioc?: string | undefined | null;
    geo?: {
        center: IGoogleGeoPosition;
    };
};

export type IUseDashboardCountryUsageCountryInfo = {
    id: number;
    code?: string;
    name?: string;
    originalUnits?: string;
    textUnits?: string;
    geo?: {
        center: IGoogleGeoPosition;
        border: Array<IGoogleGeoPosition>;
    };
};

export type IUseDashboardCountryUsageCountryPolygonsList = Array<IUseDashboardCountryUsageCountryPolygon>;
export type IUseDashboardCountryUsageCountryPolygon = {
    id: number;
    polygonId: string;
    code?: string;
    name?: string;
    originalUnits?: string;
    textUnits?: string;
    strokeColor?: string;
    fillColor?: string;
    geo?: {
        border?: Array<IGoogleGeoPosition>;
    };
};

export interface ICurrenciesRevenue {
    [id: string]: number;
}

export interface ITextCurrenciesRevenue {
    [id: string]: string;
}

export type ICost = {
    cost?: string;
    currencyCode?: string;
    isRange?: boolean;
};

export type IFinancesCost = {
    value?: string;
    currency?: string;
};

export type IOverlayView = JSX.Element | null;
