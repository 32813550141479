import React, { MouseEvent } from 'react';
import { observer } from 'mobx-react';
import { Popconfirm, Tooltip } from 'antd';
import { CloseOutlined, EyeOutlined, FormOutlined } from '@ant-design/icons';
import { Catcher } from '../index';
import { IPageList, IRightList } from '../../interfaces';
import { useChildrenOfPage, usePagesStoreCurrentComponent, usePagesStoreListRight, useRouterStore } from '../../hooks';
import { ITrInterfaces, tI } from '../../translate';
import Styles from './m_styles.less';

export const columnToolbar = {
    className: Styles.columnToolbar,
    dataIndex: 'toolbar',
    width: 100,
};
interface ITableItemToolbar {
    id: string;
    currentListComponent?: IPageList;
    deletedAt?: string;
    deleteItem?: () => void;
    isBigButtons?: boolean;
    isDelete?: boolean;
    isEdit?: boolean;
    isView?: boolean;
    itemName?: string;
    popupMessage?: ITrInterfaces;
}
export const TableItemToolbar: React.FC<ITableItemToolbar> = ({
    id,
    currentListComponent,
    deletedAt = '',
    deleteItem,
    isBigButtons,
    isDelete,
    isEdit,
    isView,
    itemName = '',
    popupMessage = 'Are you sure?',
}: ITableItemToolbar) => {
    const currentComponent = usePagesStoreCurrentComponent();
    const currentList = currentListComponent || (currentComponent.routeName as IPageList);
    const right = usePagesStoreListRight(currentList);

    const calcRight: IRightList = {
        isView: isView !== void 0 ? isView : right.isView,
        isEdit: isEdit !== void 0 ? isEdit : right.isEdit,
        isDelete: isDelete !== void 0 ? isDelete : right.isDelete,
    };

    if ((calcRight.isEdit || calcRight.isView) && !id) {
        process.env.NODE_ENV === 'development' && console.warn('Check TableItemToolbar Components parameters !!!');
    }

    if (calcRight.isView || (calcRight.isEdit && !deletedAt) || (calcRight.isDelete && !deletedAt && deleteItem)) {
        return (
            <Catcher>
                <div className={Styles.toolbar} data-test={'TableItemToolbar'}>
                    {calcRight.isView && id ? (
                        <ViewEditButton
                            itemName={itemName}
                            id={id}
                            isBigButtons={isBigButtons}
                            currentListComponent={currentList}
                        />
                    ) : (
                        <div className={isBigButtons ? Styles.bigButton : Styles.button} />
                    )}
                    {calcRight.isEdit && !deletedAt && id ? (
                        <ViewEditButton
                            itemName={itemName}
                            id={id}
                            isBigButtons={isBigButtons}
                            currentListComponent={currentList}
                            isEdit={calcRight.isEdit}
                        />
                    ) : (
                        <div className={isBigButtons ? Styles.bigButton : Styles.button} />
                    )}
                    {calcRight.isDelete && !deletedAt && deleteItem && id ? (
                        <Popconfirm
                            title={tI(popupMessage)}
                            onConfirm={deleteItem}
                            okText={tI('Yes')}
                            cancelText={tI('No')}
                            placement="bottom"
                        >
                            <Tooltip title={`${tI('Delete')} ${itemName}`}>
                                <CloseOutlined className={isBigButtons ? Styles.bigButton : Styles.button} />
                            </Tooltip>
                        </Popconfirm>
                    ) : (
                        <div className={isBigButtons ? Styles.bigButton : Styles.button} />
                    )}
                </div>
            </Catcher>
        );
    } else {
        return null;
    }
};

interface IViewEditButton {
    id: string;
    currentListComponent: IPageList;
    isBigButtons?: boolean;
    isEdit?: boolean;
    itemName: string;
}
const ViewEditButton: React.FC<IViewEditButton> = observer(
    ({ itemName, id, isBigButtons, isEdit, currentListComponent }: IViewEditButton) => {
        if (!!id) {
            const childrenPages = useChildrenOfPage(currentListComponent, id);
            const routerStore = useRouterStore();
            const goTo = (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>): void => {
                event.preventDefault();
                routerStore.goTo(isEdit ? childrenPages.editComponentName : childrenPages.viewComponentName, {
                    params: { id },
                });
            };
            const hrefLink = isEdit ? childrenPages.editComponentLink : childrenPages.viewComponentLink;
            return (
                <Tooltip title={`${isEdit ? tI('Edit') : tI('View')} ${itemName}`}>
                    <a onClick={goTo} href={hrefLink}>
                        {isEdit ? (
                            <FormOutlined className={isBigButtons ? Styles.bigButton : Styles.button} />
                        ) : (
                            <EyeOutlined className={isBigButtons ? Styles.bigButton : Styles.button} />
                        )}
                    </a>
                </Tooltip>
            );
        } else {
            return null;
        }
    },
);
