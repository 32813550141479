import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import {
    IPurchaseRateFormIds,
    IPurchaseRateFormInfo,
    IPurchaseRateFormList,
    IPurchaseRateFormListItem,
    IPurchaseRateFormPurchases,
} from '../../interfaces';
import { makeZoneName } from '../../middleware';

export function usePurchaseInfo(): IPurchaseRateFormInfo {
    return useContext(MobXProviderContext).RootStore.purchaseStore.info;
}

export function usePurchaseList(): IPurchaseRateFormListItem[] {
    return useContext(MobXProviderContext).RootStore.purchaseStore.list;
}

export function usePurchaseListByZone(zoneId: string): IPurchaseRateFormListItem[] {
    return usePurchaseList().filter((p: IPurchaseRateFormListItem) => p.zoneId === zoneId);
}

export function usePurchaseListByZoneLength(zoneId: string): number {
    return usePurchaseListByZone(zoneId).length;
}

export function usePurchaseUsedNetworksIdByCountry(countryId: string): string[] {
    return useContext(MobXProviderContext)
        .RootStore.purchaseStore.list.filter(
            (i: IPurchaseRateFormListItem) => i.countryId && i.networkId && i.countryId === countryId,
        )
        .map((i: IPurchaseRateFormListItem) => i.networkId);
}

export function usePurchaseRateFormList(): IPurchaseRateFormPurchases {
    const rateByZone: IPurchaseRateFormList = {};
    const idByZone: IPurchaseRateFormIds = {};
    useContext(MobXProviderContext).RootStore.purchaseStore.list.forEach((i: IPurchaseRateFormListItem) => {
        if (i.zoneId) {
            const zoneName = makeZoneName(i.zoneId);
            if (rateByZone[zoneName]) {
                rateByZone[zoneName].push(i);
                idByZone[zoneName].push(i.id);
            } else {
                rateByZone[zoneName] = [i];
                idByZone[zoneName] = [i.id];
            }
        }
    });

    return { rateByZone, idByZone };
}
