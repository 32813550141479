import { useEffect, useState } from 'react';
import { useAuthTokens } from './stores/useAuthStores';
import { tokenManager } from '../REST';

export function useIsImpersonating(): boolean {
    const [isImpersonating, setIsImpersonating] = useState<boolean>(false);
    const { access, disabledAccess, refresh } = useAuthTokens();

    const getDisabledToken = async () => {
        const token = await tokenManager.getToken('disabledAccess');

        setIsImpersonating(!!token);
    };

    useEffect(() => {
        getDisabledToken();
    }, [access, refresh, disabledAccess]);

    return isImpersonating;
}
