import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Button, List, Modal } from 'antd';
import { CloseOutlined, CopyOutlined, DeleteOutlined, KeyOutlined } from '@ant-design/icons';
import { LoadingApiByIds } from '../../Loading';
import cx from 'classnames';
import { appConfig } from '../../../settings';
import { ICompanyApiKeyItem, IGetApiType } from '../../../interfaces';
import { useCompanyApiKeyList } from '../../../hooks';
import { addNotification, deleteCompanyApiKey, getCompanyApiKeysList, postCompanyApiKey } from '../../../middleware';
import { tB } from '../../../translate';
import { AddButton, CardMainLayout, RemoveButton, cardLayoutOptions } from '../../../Components';
import Styles from '../m_styles.less';

type ModalTypes = 'delete' | 'add';

export const ApiKeys: React.FC = observer(() => {
    const [isOpen, setIsOpen] = useState<boolean>();
    const [currentKeyId, setCurrentKeyId] = useState<string>();
    const [modalType, setModalType] = useState<ModalTypes | null>(null);
    const [tempNewCreatedKey, setTempNewCreatedKey] = useState<ICompanyApiKeyItem | null>();
    const apiKeyList = useCompanyApiKeyList();
    const isModalDelete = modalType === 'delete';
    const currentKeyData = isModalDelete ? apiKeyList.find((key: any) => currentKeyId === key.id) : tempNewCreatedKey;
    const idSpinners = ['GET_COMPANY_API_KEY', 'DELETE_COMPANY_API_KEY', 'POST_COMPANY_API_KEY'] as IGetApiType[];
    const handleDeleteKey = (id: string) => {
        setModalType('delete');
        setIsOpen(true);
        setCurrentKeyId(id);
    };

    const handleCloseModal = () => {
        setIsOpen(() => false);
        setTempNewCreatedKey(() => null);
        setModalType(null);
    };

    const confirmDeleteKey = async () => {
        if (currentKeyId) {
            const isError = await deleteCompanyApiKey(currentKeyId);

            if (isError) {
                addNotification({
                    type: 'error',
                    message: `There was an error while deleting your key.`,
                    description: `Please try again later`,
                    isTranslated: true,
                });
                return;
            }

            handleCloseModal();

            await getCompanyApiKeysList();
        }
    };

    const handleCopyClick = (key?: string) => {
        navigator.clipboard.writeText(key || '').then(() => {
            addNotification({
                type: 'info',
                message: 'Key has been copied to clipboard!',
                duration: appConfig.notificationDuration,
            });
        });
    };

    const handleCreateKey = async (): Promise<void> => {
        const { newApiKey, isError } = await postCompanyApiKey();

        if (isError) {
            addNotification({
                type: 'error',
                message: `There was an error while creating a new key.`,
                description: `Please try again later`,
                isTranslated: true,
            });
            return;
        }

        await getCompanyApiKeysList();

        setTempNewCreatedKey(newApiKey);
        setIsOpen(true);
        setModalType('add');
    };

    const modalTitle = isModalDelete ? 'Key Deletion' : 'Add New Key';
    const modalActonButton = isModalDelete ? (
        <RemoveButton
            type="primary"
            key={'Delete'}
            title={'Delete'}
            onClick={confirmDeleteKey}
            icon={<DeleteOutlined />}
        ></RemoveButton>
    ) : null;

    const modalContentTitle = isModalDelete ? (
        <h2>
            {' '}
            <DeleteOutlined className={Styles.apiKeyModalDeleteIcon} /> Do you really want to delete this key?
        </h2>
    ) : (
        <h2>The key has been successfully created</h2>
    );

    const modalContentDescription = isModalDelete ? (
        <p className={Styles.apiKeyModalDescription}>Upon deletion, this key will be impossible to recover!</p>
    ) : (
        <p className={Styles.apiKeyModalDescription}>
            Please write down your key somewhere safe. <strong>This is the only time you will see this key!</strong>
        </p>
    );

    const modalContentKey = isModalDelete ? (
        <p className={cx(Styles.apiKeyModalKeySection, Styles.apiKeyModalKeySectionDelete)}>{currentKeyData?.label}</p>
    ) : (
        <p className={cx(Styles.apiKeyModalKeySection, Styles.apiKeyModalKeySectionAdd)}>
            <Button
                className={Styles.apiKeyModalCopyToClipboardButton}
                onClick={() => handleCopyClick(currentKeyData?.token)}
                size={'middle'}
                icon={<CopyOutlined />}
            ></Button>
            {currentKeyData?.token}
        </p>
    );

    return (
        <>
            <CardMainLayout
                columnOption={cardLayoutOptions.cardInfoEdit}
                idSpinners={idSpinners}
                name={'Company Api Keys'}
                icon={<KeyOutlined />}
                OtherButton={<AddButton title={'Add'} type={'ghost'} onClick={handleCreateKey} />}
            >
                <List
                    itemLayout={'horizontal'}
                    dataSource={apiKeyList}
                    className={Styles.apiKeyList}
                    renderItem={(item: ICompanyApiKeyItem) => (
                        <List.Item
                            actions={[
                                <RemoveButton
                                    key={item.id}
                                    title={'Delete'}
                                    type={'ghost'}
                                    onClick={() => handleDeleteKey(item.id)}
                                />,
                            ]}
                        >
                            <List.Item.Meta
                                title={<span className={Styles.apiKeyItemTitle}>{item.label}</span>}
                                description={`Created at: ${item.createdAt}`}
                            />
                        </List.Item>
                    )}
                ></List>
            </CardMainLayout>
            <Modal
                title={modalTitle}
                open={isOpen}
                onCancel={handleCloseModal}
                footer={[
                    modalActonButton,
                    <Button key={'Close'} icon={<CloseOutlined />} onClick={handleCloseModal}>
                        {tB('Close')}
                    </Button>,
                ]}
                wrapClassName={'modal'}
                width={'60vw'}
            >
                <div className={Styles.apiKeyModalContent}>
                    <LoadingApiByIds idSpinners={idSpinners} />
                    {modalContentTitle}
                    {modalContentDescription}
                    {modalContentKey}
                </div>
            </Modal>
        </>
    );
});
