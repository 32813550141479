import React from 'react';
import { observer } from 'mobx-react';
import cx from 'classnames';
import { tP } from '../../translate';
import { PublicPagesWrapper, ResetPasswordForm } from './Components';
import '../../customStyle/form.less';
import Styles from './m_styles.less';
import './styles.less';

export const ResetPasswordPage: React.FC = observer(() => {
    return (
        <PublicPagesWrapper>
            <div className={cx(Styles.card)}>
                <div className={Styles.title}>{tP('Reset Password')}</div>
                <div className={Styles.form}>
                    <ResetPasswordForm />
                </div>
            </div>
        </PublicPagesWrapper>
    );
});
