import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Card } from 'antd';
import { Catcher } from '../';
import cx from 'classnames';
import { IGetApiType } from '../../REST';
import { useProfileIsGeotrackingEnabled } from '../../hooks';
import { clearGeotrackingStore, getGeotrackingCardList } from '../../middleware';
import { tT } from '../../translate';
import { DemoOverlay, GeoTrackingFilterBar, GoogleGeoTrackingMap } from './Components';
import CardStyles from '../styles/m_itemCardStyles.less';
import GeoTrackingStyles from './m_geoTrackingStyles.less';

export const GeoTracking = observer(() => {
    const isEnabled = useProfileIsGeotrackingEnabled();
    const idSpinners: IGetApiType[] = ['GET_GEOTRACKING_CARD_LOCATION_HISTORY', 'GET_GEOTRACKING_CARD_LIST'];

    useEffect(() => {
        if (isEnabled) {
            getGeotrackingCardList();
        }

        return (): void => {
            clearGeotrackingStore();
        };
    }, []);

    return (
        <Catcher>
            <article className={cx(CardStyles.main, GeoTrackingStyles.container)}>
                <DemoOverlay>
                    <Card title="Geotracking is disabled for this company">
                        <p>
                            {tT(
                                'This feature requires extra activation. Please contact our support to activate it or to receive more information about costs and conditions.',
                            )}
                        </p>
                    </Card>
                </DemoOverlay>
                <GeoTrackingFilterBar />
                <GoogleGeoTrackingMap idSpinners={idSpinners} />
            </article>
        </Catcher>
    );
});
