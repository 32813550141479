import React from 'react';
import { observer } from 'mobx-react';
import { Divider } from 'antd';
import {
    BankOutlined,
    CarOutlined,
    CopyrightOutlined,
    GlobalOutlined,
    HomeOutlined,
    InfoOutlined,
    MailOutlined,
    PhoneOutlined,
} from '@ant-design/icons';
import { IFieldLayout } from '../../../interfaces';
import { useProfileAbout } from '../../../hooks';
import { tP } from '../../../translate';
import { CardMainLayout, Catcher, FieldInfoAdvanced, cardLayoutOptions } from '../../../Components';
import Styles from '../../styles/m_itemCardStyles.less';

export const AboutInfo: React.FC = observer(() => {
    const layout: IFieldLayout = {
        labelCol: {
            flex: '0 1 160px',
        },
        rowGutter: 8,
    };

    const { address, email, iban, name, phoneNumber, registration, swift, vat, website } = useProfileAbout();

    return (
        <Catcher>
            <CardMainLayout
                columnOption={cardLayoutOptions.cardCompanyShowInfo}
                name={tP('About')}
                icon={<InfoOutlined />}
            >
                <FieldInfoAdvanced
                    icon={<CopyrightOutlined className={Styles.labelIcon} />}
                    layout={layout}
                    title={'Company'}
                >
                    {name}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced
                    icon={<HomeOutlined className={Styles.labelIcon} />}
                    layout={layout}
                    title={'Address'}
                >
                    {address}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced
                    icon={<GlobalOutlined className={Styles.labelIcon} />}
                    layout={layout}
                    title={'Website'}
                >
                    {website}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced icon={<MailOutlined className={Styles.labelIcon} />} layout={layout} title={'Email'}>
                    {email}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced
                    icon={<PhoneOutlined className={Styles.labelIcon} />}
                    layout={layout}
                    title={'Phone'}
                >
                    {phoneNumber}
                </FieldInfoAdvanced>
                <Divider />
                <FieldInfoAdvanced
                    icon={<GlobalOutlined className={Styles.labelIcon} />}
                    layout={layout}
                    title={'C of C registration'}
                >
                    {registration}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced
                    icon={<BankOutlined className={Styles.labelIcon} />}
                    layout={layout}
                    title={'IBAN account'}
                >
                    {iban}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced
                    icon={<PhoneOutlined className={Styles.labelIcon} />}
                    layout={layout}
                    title={'Swift-Bic'}
                >
                    {swift}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced icon={<CarOutlined className={Styles.labelIcon} />} layout={layout} title={'VAT'}>
                    {vat}
                </FieldInfoAdvanced>
            </CardMainLayout>
        </Catcher>
    );
});
