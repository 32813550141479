import React, { ReactNode, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Alert, Form } from 'antd';
import {
    CardInfoToolBar,
    CardMainLayout,
    Catcher,
    FieldAlert,
    FormSelect,
    InputDebounce,
    TextAreaDebounce,
    UploadFile,
    cardLayoutOptions,
} from '../../';
import { UnitSelect } from './';
import { IGetApiResponse } from '../../../REST';
import { IApiErrorMessage, IEditFormComponentInfo } from '../../../interfaces';
import { useCompaniesSelect, useCurrencySelect, useProfileIsRight, useRateInfo } from '../../../hooks';
import {
    addNotification,
    apiMessagesGetField,
    apiMessagesGetFormRules,
    apiMessagesGetFormRulesStandardNumber,
    setRate,
} from '../../../middleware';
import { tF, tNM } from '../../../translate';

export const EditRateFieldsInfo: React.FC<IEditFormComponentInfo> = observer(
    ({ save, isSending, isNew }: IEditFormComponentInfo) => {
        const isAdmin = useProfileIsRight('ADMIN');
        const [currentName, setName] = useState('');
        const { id, name } = useRateInfo();
        const [isErrorMessage, setIsErrorMessage] = useState(false);
        const [isMessage, setIsMessage] = useState(false);
        const [errorMessageBody, setErrorMessageBody] = useState<ReactNode>();
        const currencySelect = useCurrencySelect;
        const companiesSelectHook = useCompaniesSelect;
        const calculatedRateDataMessage = apiMessagesGetField('calculatedRateData');
        const calculatedRateSmsMessage = apiMessagesGetField('calculatedRateSms');
        const isNotTheSame = calculatedRateDataMessage[0] !== calculatedRateSmsMessage[0];

        const cleanUploadError = (): void => {
            setIsMessage(false);
            setIsErrorMessage(false);
            setErrorMessageBody(null);
        };

        const onDownload = (apiResponse: IGetApiResponse): void => {
            const { isError } = apiResponse;
            if (isError) {
                const { resp } = apiResponse;
                const errorList = resp.errors;
                const ErrorBodyJSX = errorList?.map((item: IApiErrorMessage, index: number) => {
                    return (
                        <div key={index}>
                            {item.source.pointer} {'->'} {item.detail}
                        </div>
                    );
                });
                setErrorMessageBody(ErrorBodyJSX);
                setIsErrorMessage(true);
                setIsMessage(true);
            } else {
                setIsMessage(true);
                addNotification({
                    type: 'success',
                    message: 'Upload successful!',
                });
                setRate(apiResponse);
            }
        };

        useEffect(() => {
            if (name !== undefined && currentName === '' && !isNew) {
                setName(name);
            }
        }, [name]);

        const nameTitle = tF('Name');
        const nameCompany = tF('Company');
        const nameCurrency = tF('Currency');
        const nameGigabyte = tF('Gigabyte');
        const nameSMS = tF('SMS');
        const nameVoiceMobile = tF('Voice Mobile');
        const nameVoiceNet = tF('Voice Fix Net');
        const nameNotes = tF('Notes');

        return (
            <Catcher>
                <CardMainLayout
                    idSpinners={[
                        'GET_COMPANIES_SELECT',
                        'GET_COUNTRIES_SELECT',
                        'GET_CURRENCY_SELECT',
                        'GET_NETWORKS_SELECT',
                        'GET_OPERATORS_SELECT_ALL',
                        'GET_OPERATORS_SELECT',
                        'GET_RATE_UNIT_SELECT',
                        'GET_SALES_RATE_LIST',
                        'GET_ZONE_SELECT',
                        'PATCH_SALES_RATE_LIST',
                        'POST_SALES_RATE_CSV',
                        'POST_SALES_RATE_LIST',
                    ]}
                    isEdit
                    name={currentName}
                    columnOption={cardLayoutOptions.salesRateInfo}
                >
                    <Form.Item name="name" label={nameTitle} rules={apiMessagesGetFormRules}>
                        <InputDebounce placeholder={nameTitle} isFirstInput />
                    </Form.Item>
                    <FormSelect
                        fieldName={'companyId'}
                        label={nameCompany}
                        rules={apiMessagesGetFormRules}
                        useSelectHook={companiesSelectHook}
                    />
                    <FormSelect
                        fieldName={'currencyId'}
                        label={nameCurrency}
                        rules={apiMessagesGetFormRules}
                        useSelectHook={currencySelect}
                    />
                    {/*<div className={Styles.title}>{nameDefaultRatePer}:</div>*/}
                    <Form.Item
                        name="defaultRateData"
                        label={nameGigabyte}
                        rules={apiMessagesGetFormRulesStandardNumber()}
                    >
                        <InputDebounce
                            addonBefore={<UnitSelect name={'defaultRateDataUnitId'} rules={apiMessagesGetFormRules} />}
                        />
                    </Form.Item>
                    <Form.Item name="defaultRateSms" label={nameSMS} rules={apiMessagesGetFormRulesStandardNumber()}>
                        <InputDebounce
                            addonBefore={<UnitSelect name={'defaultRateSmsUnitId'} rules={apiMessagesGetFormRules} />}
                        />
                    </Form.Item>
                    <Form.Item
                        name="defaultRateVoiceMobile"
                        label={nameVoiceMobile}
                        rules={apiMessagesGetFormRulesStandardNumber()}
                    >
                        <InputDebounce
                            addonBefore={
                                <UnitSelect name={'defaultRateVoiceMobileUnitId'} rules={apiMessagesGetFormRules} />
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        name="defaultRateVoiceNet"
                        label={nameVoiceNet}
                        rules={apiMessagesGetFormRulesStandardNumber()}
                    >
                        <InputDebounce
                            addonBefore={
                                <UnitSelect name={'defaultRateVoiceNetUnitId'} rules={apiMessagesGetFormRules} />
                            }
                        />
                    </Form.Item>
                    <Form.Item name="notes" label={nameNotes} rules={apiMessagesGetFormRules}>
                        <TextAreaDebounce placeholder={nameNotes} autoSize={true} />
                    </Form.Item>
                    <FieldAlert fieldName={'calculatedRateData'} isFieldNameShown={false} />
                    {isNotTheSame ? <FieldAlert fieldName={'calculatedRateSms'} isFieldNameShown={false} /> : null}
                    <CardInfoToolBar onSave={save} isLoading={isSending} isNew={isNew}>
                        {isAdmin && !isNew ? (
                            <UploadFile apiType={'POST_SALES_RATE_CSV'} id={id} onDownload={onDownload} />
                        ) : null}
                    </CardInfoToolBar>
                    {isMessage ? (
                        <Alert
                            type={isErrorMessage ? 'error' : 'success'}
                            message={tNM(isErrorMessage ? 'Upload Error!' : 'Upload successful!')}
                            description={isErrorMessage ? errorMessageBody : null}
                            showIcon
                            closable
                            afterClose={cleanUploadError}
                        />
                    ) : null}
                </CardMainLayout>
            </Catcher>
        );
    },
);
