import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import BaseMap from './BaseMap';
import { appConfig } from '../../../settings';
import { IGeoLocation, IGeotrackingCardListItem } from '../../../interfaces';
import { useGeotrackingCardList } from '../../../hooks';
import { setTrackedCardId } from '../../../middleware';
import { getCenter, getZoom, isRealLocation } from '../../../instruments';
import { tI } from '../../../translate';
import { CardLayout, Catcher, IComponentWithIdSpinners, cardLayoutOptions } from '../../../Components';
import { CustomMarkersClusterer, ShowCardsLocationMarkerInfo } from './../Components';
import Styles from '../m_googleMapsStyles.less';

export const GeotrackingCardList: React.FC<IComponentWithIdSpinners> = observer(
    ({ idSpinners }: IComponentWithIdSpinners) => {
        const [zoom, setZoom] = useState(2);
        const [center, setCenter] = useState<IGeoLocation>(appConfig.googleMapCenter);
        const list = useGeotrackingCardList();
        const goodLocationItems = list.filter((i) => isRealLocation(i));
        const badLocationItemsCount = list.filter((i) => !isRealLocation(i)).length;
        const titleRightBadLocation = !!badLocationItemsCount
            ? `, ${tI('location errors')}: ${badLocationItemsCount}`
            : '';
        const itemCount = list.length;
        const titleRight = !!itemCount ? `(${tI('locations')}: ${itemCount}${titleRightBadLocation})` : '';

        useEffect(() => {
            if (goodLocationItems.length) {
                const center = getCenter(goodLocationItems);
                const zoom = getZoom(goodLocationItems);
                setCenter(center);
                setZoom(zoom);
            }
        }, [list]);

        return (
            <Catcher>
                <CardLayout
                    columnOption={cardLayoutOptions.cardTableFull}
                    idSpinners={idSpinners}
                    isTable
                    title={'Card tracking'}
                    titleRight={titleRight}
                >
                    <div className={Styles.geoTrackingMap}>
                        <BaseMap zoom={zoom} center={center}>
                            <CustomMarkersClusterer
                                markerList={goodLocationItems}
                                showMarkerInfo={(marker, markerProps) =>
                                    ShowCardsLocationMarkerInfo({ marker, position: marker.position, ...markerProps })
                                }
                                onClickMarker={(m: IGeotrackingCardListItem) => {
                                    m.id && setTrackedCardId(m.id);
                                }}
                            />
                        </BaseMap>
                    </div>
                </CardLayout>
            </Catcher>
        );
    },
);
