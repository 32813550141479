import React from 'react';
import { observer } from 'mobx-react';
import { Button, Form, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Catcher, FormSelect } from '../../';
import { IFormFieldChange } from '../../../interfaces';
import { useCardSetsSelect, useGeotrackingSearchParams, useIsGeotrackingMode } from '../../../hooks';
import {
    apiMessagesGetFormRules,
    getGeotrackingCardList,
    setGeoTrackingSearchParams,
    setTrackedCardId,
} from '../../../middleware';
import { tB, tF } from '../../../translate';

export const GeoTrackingSearch = observer(() => {
    const [form] = Form.useForm();
    const searchParams = useGeotrackingSearchParams();
    const isGeotrackingMode = useIsGeotrackingMode();

    const onFieldChange = (fields: IFormFieldChange[]): void => {
        const fieldName = Array.isArray(fields[0]?.name) && fields[0]?.name[0];
        if (fieldName && typeof fieldName === 'string') {
            const { value = '' } = fields[0];
            setGeoTrackingSearchParams(fieldName, value);
        }
    };

    const onSearch = async (): Promise<void> => {
        setTrackedCardId(null);
        await getGeotrackingCardList(searchParams);
    };

    return (
        <Catcher>
            <div>
                <Form
                    form={form}
                    name="geoTrackingSearch"
                    onFieldsChange={onFieldChange}
                    scrollToFirstError={true}
                    layout={'vertical'}
                    className={'formStyle'}
                    validateTrigger={'onBlur'}
                    onSubmitCapture={onSearch}
                >
                    <FormSelect
                        fieldName={'cardSetId'}
                        label={tF('Select a card set')}
                        rules={apiMessagesGetFormRules}
                        useSelectHook={useCardSetsSelect}
                        disabled={isGeotrackingMode}
                    />
                    <Form.Item name="search" label={tF('Search a card')}>
                        <Input placeholder={tF('Enter ICCID or card name')} disabled={isGeotrackingMode} />
                    </Form.Item>
                    <Button
                        type="primary"
                        disabled={isGeotrackingMode}
                        block
                        icon={<SearchOutlined />}
                        htmlType="submit"
                    >
                        {tB('Search')}
                    </Button>
                </Form>
            </div>
        </Catcher>
    );
});
