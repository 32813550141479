// Router
import { RouterStore, createRouterState } from 'mobx-state-router';
// Stores
import { ActivityLogStore } from './activityLog';
import { AlarmsStore } from './alarms';
import { ApiMessagesStore } from './apiMessages';
import { AuthStore } from './auth';
import { BalanceLogStore } from './balaceLog';
import { CallDataRecordsStore } from './callDataRecords';
import { CallDataRecordsBetaStore } from './callDataRecordsBeta';
import { CardStore } from './card';
import { CardSetStore } from './cardSet';
import { CardSetsStore } from './cardSets';
import { CardsStore } from './cards';
import { CatalogsStore } from './catalogs';
import { CompaniesStore } from './companies';
import { CompanyStore } from './company';
import { CountriesStore } from './countries';
import { CountryStore } from './country';
import { CurrenciesStore } from './currencies';
import { CurrencyStore } from './currency';
import { DashboardStore } from './dashboard';
import { DiameterSessionsStore } from './diameterSessions';
import { EsNotificationsStore } from './esNotifications';
import { ExternalAPIStore } from './externalAPI';
import { FeatureFlagStore } from './featureFlag';
import { GeotrackingStore } from './geotracking';
import { IncludedStore } from './included';
import { OperatorStore } from './operator';
import { OperatorInteractionsStore } from './operatorInteractions';
import { OperatorsStore } from './operators';
import { OrdersStore } from './orders';
import { PackageStore } from './package';
import { PackagesStore } from './packages';
import { PackagesUnitsLogsStore } from './packagesUnitsLogs';
import { ProfileStore } from './profile';
import { PurchaseStore } from './purchase';
import { PurchasesStore } from './purchases';
import { RateStore } from './rate';
import { RatesStore } from './rates';
import { ReportsStore } from './reports';
import { RouterPagesStore } from './routerPages';
import { SubscriberStore } from './subscriber';
import { SubscribersStore } from './subscribers';
import { UiStore } from './ui';
import { UnitTypesStore } from './unitType';
import { UserStore } from './user';
import { UsersStore } from './users';
import { ZonesStore } from './zones';

const notFound = createRouterState('notFound');

export default class RootStore {
    activityLogStore = ActivityLogStore.getInstance();
    alarmsStore = AlarmsStore.getInstance();
    apiMessagesStore = ApiMessagesStore.getInstance();
    authStore = AuthStore.getInstance();
    balanceLogStore = BalanceLogStore.getInstance();
    callDataRecordsStore = CallDataRecordsStore.getInstance();
    callDataRecordsStoreBeta = CallDataRecordsBetaStore.getInstance();
    cardSetsStore = CardSetsStore.getInstance();
    cardSetStore = CardSetStore.getInstance();
    cardsStore = CardsStore.getInstance();
    cardStore = CardStore.getInstance();
    catalogsStore = CatalogsStore.getInstance();
    companiesStore = CompaniesStore.getInstance();
    companyStore = CompanyStore.getInstance();
    countriesStore = CountriesStore.getInstance();
    countryStore = CountryStore.getInstance();
    currenciesStore = CurrenciesStore.getInstance();
    currencyStore = CurrencyStore.getInstance();
    dashboardStore = DashboardStore.getInstance();
    diameterSessionsStore = DiameterSessionsStore.getInstance();
    esNotificationsStore = EsNotificationsStore.getInstance();
    externalAPIStore = ExternalAPIStore.getInstance();
    featureFlagStore = FeatureFlagStore.getInstance();
    geotrackingStore = GeotrackingStore.getInstance();
    includedStore = IncludedStore.getInstance();
    operatorInteractionsStore = OperatorInteractionsStore.getInstance();
    operatorsStore = OperatorsStore.getInstance();
    operatorStore = OperatorStore.getInstance();
    ordersStore = OrdersStore.getInstance();
    packagesStore = PackagesStore.getInstance();
    packageStore = PackageStore.getInstance();
    packagesUnitsLogsStore = PackagesUnitsLogsStore.getInstance();
    profileStore = ProfileStore.getInstance();
    purchasesStore = PurchasesStore.getInstance();
    purchaseStore = PurchaseStore.getInstance();
    ratesStore = RatesStore.getInstance();
    rateStore = RateStore.getInstance();
    reportsStore = ReportsStore.getInstance();
    routerPagesStore = RouterPagesStore.getInstance();
    routerStore = new RouterStore(RouterPagesStore.getInstance().allRoutes, notFound);
    subscribersStore = SubscribersStore.getInstance();
    subscriberStore = SubscriberStore.getInstance();
    uiStore = UiStore.getInstance();
    unitTypeStore = UnitTypesStore.getInstance();
    usersStore = UsersStore.getInstance();
    userStore = UserStore.getInstance();
    zonesStore = ZonesStore.getInstance();
}
