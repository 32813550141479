import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { DashboardUnitTypes, ISelect, ISelectList } from '../../interfaces';

export function useUnitTypesSelect(): ISelectList {
    return useContext(MobXProviderContext).RootStore.unitTypeStore.select;
}

export function useUnitTypeByType(type: DashboardUnitTypes): ISelect | undefined {
    const unitTypeMap = useContext(MobXProviderContext).RootStore.unitTypeStore.unitTypeMap;
    return unitTypeMap.get(type);
}
