import React, { useEffect } from 'react';
import { Col, Row } from 'antd';
import { Catcher, rowGutter } from '../';
import { columnOption } from './columnOption';
import cx from 'classnames';
import { dashboardType } from '../../interfaces';
import { abortAllDashboardFetch, cleanDashboardStore, setDashboardType } from '../../middleware';
import {
    CardSetRevenue,
    CardSetUsage,
    CardsRevenue,
    CardsUsage,
    CompanyRevenue,
    CompanyUsage,
    CountryRevenue,
    CountryUsage,
    FinancesHeader,
    TelecomRevenue,
    TelecomUsage,
    TimelineRevenue,
    TimelineUsage,
    ZoneRevenue,
    ZoneUsage,
} from './Components';
import ViewStyles from '../styles/m_viewStyles.less';
import Styles from './m_dashboarStyles.less';

export const Finances: React.FC = () => {
    useEffect(() => {
        setDashboardType(dashboardType.finances);
        return (): void => {
            abortAllDashboardFetch();
            cleanDashboardStore();
        };
    }, []);
    const { dashboardFullCard, dashboardChartCard } = columnOption;

    return (
        <Catcher>
            <div className={cx(Styles.main, ViewStyles.main)}>
                <div className={cx(ViewStyles.content, ViewStyles.contentNotBorder)}>
                    <Row gutter={rowGutter.dashboardChartCard} className={Styles.dashboardRow}>
                        <Col className={Styles.headerPlace} {...dashboardFullCard}>
                            <FinancesHeader />
                        </Col>
                    </Row>
                    <Row gutter={rowGutter.dashboardChartCard} className={Styles.dashboardRow}>
                        <Col className={Styles.chartPlace} {...dashboardChartCard}>
                            <CompanyUsage />
                        </Col>
                        <Col className={Styles.chartPlace} {...dashboardChartCard}>
                            <CompanyRevenue />
                        </Col>
                        <Col className={Styles.chartPlace} {...dashboardChartCard}>
                            <CardSetUsage />
                        </Col>
                        <Col className={Styles.chartPlace} {...dashboardChartCard}>
                            <CardSetRevenue />
                        </Col>
                        <Col className={Styles.chartPlace} {...dashboardChartCard}>
                            <CountryUsage />
                        </Col>
                        <Col className={Styles.chartPlace} {...dashboardChartCard}>
                            <CountryRevenue />
                        </Col>
                        <Col className={Styles.chartPlace} {...dashboardChartCard}>
                            <CardsUsage />
                        </Col>
                        <Col className={Styles.chartPlace} {...dashboardChartCard}>
                            <CardsRevenue />
                        </Col>
                        <Col className={Styles.chartPlace} {...dashboardChartCard}>
                            <ZoneUsage />
                        </Col>
                        <Col className={Styles.chartPlace} {...dashboardChartCard}>
                            <ZoneRevenue />
                        </Col>
                        <Col className={Styles.chartPlace} {...dashboardChartCard}>
                            <TelecomUsage />
                        </Col>
                        <Col className={Styles.chartPlace} {...dashboardChartCard}>
                            <TelecomRevenue />
                        </Col>
                        <Col className={Styles.chartPlace} {...dashboardChartCard}>
                            <TimelineUsage />
                        </Col>
                        <Col className={Styles.chartPlace} {...dashboardChartCard}>
                            <TimelineRevenue />
                        </Col>
                    </Row>
                </div>
            </div>
        </Catcher>
    );
};
