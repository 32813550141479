import { viewOption } from '../../Layout';
import cx from 'classnames';
import { ITempTableColumnItem } from '../../../interfaces';
import { tF } from '../../../translate';
import ViewStyles from '../../styles/m_viewStyles.less';

const marginColumns = (marginCount?: number): Array<ITempTableColumnItem> => {
    if (marginCount) {
        const mc = [];
        for (let i = 0; i < marginCount; i++) {
            mc.push({
                title: '',
                dataIndex: `marginColumn${i}`,
                width: viewOption.rateTableEdit.widthMarginColumn,
            });
        }
        return mc;
    } else {
        return [];
    }
};

export const tableColumns = (marginCount?: number): Array<ITempTableColumnItem> => [
    ...marginColumns(marginCount),
    {
        title: ``,
        dataIndex: 'space',
    },
    {
        title: tF('Operator'),
        dataIndex: 'operator',
        width: viewOption.rateTableEdit.widthNetwork,
    },
    {
        title: tF('Zone'),
        dataIndex: 'zone',
        width: viewOption.rateTableEdit.widthNetwork,
    },
    {
        title: tF('Country'),
        dataIndex: 'country',
        width: viewOption.rateTableEdit.widthNetwork,
    },
    {
        title: tF('Network'),
        dataIndex: 'network',
        width: viewOption.rateTableEdit.widthNetwork + viewOption.networkOffset,
        className: cx(ViewStyles.columnCenter),
    },
    {
        title: `${tF('Unit')} - ${tF('Gigabyte')}`,
        dataIndex: 'rateData',
        width: viewOption.rateTableEdit.widthValue,
    },
    {
        title: `${tF('Unit')} - ${tF('SMS')}`,
        dataIndex: 'rateSms',
        width: viewOption.rateTableEdit.widthValue,
    },
    {
        title: tF('Voice Mobile'),
        dataIndex: 'rateVoiceMobile',
        width: viewOption.rateTableEdit.widthValue,
    },
    {
        title: tF('Voice Fix Net'),
        dataIndex: 'rateVoiceNet',
        width: viewOption.rateTableEdit.widthValue,
    },
    {
        title: '',
        dataIndex: 'toolBar',
        className: cx(ViewStyles.columnToolbarDelete, ViewStyles.columnShortToolbarDelete),
        width: viewOption.rateTableEdit.widthToolBar,
    },
];
