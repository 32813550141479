import React from 'react';
import { ApartmentOutlined, BankOutlined, EnvironmentOutlined, PhoneOutlined } from '@ant-design/icons';
import { Catcher, FieldInfoAdvanced } from '../../';
import { PackageCountDown, PackageItemList } from './';
import { IPackageSoldInfo } from '../../../interfaces';
import Styles from '../../styles/m_itemCardStyles.less';

interface IPackageSoldInfoBox {
    packageSoldInfo: IPackageSoldInfo;
}

export const PackageSoldInfoBox: React.FC<IPackageSoldInfoBox> = ({ packageSoldInfo }: IPackageSoldInfoBox) => {
    const {
        dataVolumeName,
        dataVolumeNameLeft,
        zoneName,
        creditRemainsName,
        companyName,
        expiresDate,
        createdAt,
        isExpired,
        assignedCountries,
        assignedNetworks,
        assignedOperators,
        renewal,
    } = packageSoldInfo;

    const layout = {
        labelCol: {
            flex: '0 1 170px',
        },
    };

    return (
        <Catcher>
            <FieldInfoAdvanced icon={<BankOutlined className={Styles.labelIcon} />} layout={layout} title={'Company'}>
                {companyName}
            </FieldInfoAdvanced>
            <FieldInfoAdvanced isIconBlank layout={layout} title={'Data volume'}>
                {dataVolumeName}
            </FieldInfoAdvanced>
            <FieldInfoAdvanced isIconBlank layout={layout} title={'Data left'}>
                {dataVolumeNameLeft}
            </FieldInfoAdvanced>
            <FieldInfoAdvanced isIconBlank layout={layout} title={'Zone'}>
                {zoneName}
            </FieldInfoAdvanced>
            <FieldInfoAdvanced isIconBlank layout={layout} title={'Credit Remains'}>
                {creditRemainsName}
            </FieldInfoAdvanced>
            <FieldInfoAdvanced isIconBlank layout={layout} title={'Expires in'}>
                <PackageCountDown
                    expiresDate={expiresDate}
                    createdAt={createdAt}
                    isExpired={isExpired}
                    isRenewed={renewal}
                />
            </FieldInfoAdvanced>
            <FieldInfoAdvanced
                icon={<EnvironmentOutlined className={Styles.labelIcon} />}
                layout={layout}
                title={'Assigned Countries'}
            >
                <PackageItemList valueList={assignedCountries?.assignedNameArr} />
            </FieldInfoAdvanced>
            <FieldInfoAdvanced
                icon={<PhoneOutlined className={Styles.labelIcon} />}
                layout={layout}
                title={'Assigned Operators'}
            >
                <PackageItemList valueList={assignedOperators?.assignedNameArr} />
            </FieldInfoAdvanced>
            <FieldInfoAdvanced
                icon={<ApartmentOutlined className={Styles.labelIcon} />}
                layout={layout}
                title={'Assigned Networks'}
            >
                <PackageItemList valueList={assignedNetworks?.assignedNameArr} />
            </FieldInfoAdvanced>
        </Catcher>
    );
};
