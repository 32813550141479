import React from 'react';
import { Catcher } from '../';
import cx from 'classnames';
import { IDivMainProps } from '../../interfaces';
import { ITrField, tF } from '../../translate';
import Styles from '../styles/m_itemCardStyles.less';

type IFieldInfo = IDivMainProps & {
    children?: React.ReactNode;
    dataTest?: string;
    isRevertBold?: boolean;
    title?: ITrField;
    titleSuffix?: React.ReactNode;
};

export const FieldInfo: React.FC<IFieldInfo> = ({
    children,
    className,
    dataTest,
    isRevertBold,
    title,
    titleSuffix,
    ...props
}: IFieldInfo) => {
    return (
        <Catcher>
            <p
                className={cx(className, !!isRevertBold ? Styles.showRevertField : Styles.showField)}
                data-test={dataTest}
                {...props}
            >
                {title ? (
                    <span>
                        {tF(title)}
                        {titleSuffix ? ` ${titleSuffix}` : null}:
                    </span>
                ) : null}
                {children}
            </p>
        </Catcher>
    );
};
