import { ZonesStore } from '../stores/zones';
import { setUiTotal } from './ui';
import { apiManager } from '../REST';
import { IBodyListResponse, IItemAPI, IParamsList, ISelectList, IZonesList } from '../interfaces';
import { sortByName } from '../instruments';

export function setZonesSelectList(data: IItemAPI[]): void {
    const list: ISelectList =
        data
            .map((z: IItemAPI) => {
                return {
                    id: z.id,
                    name: z.attributes?.name || z.id,
                };
            })
            .sort(sortByName) || [];

    const ZonesStoreInstance = ZonesStore.getInstance();
    ZonesStoreInstance.setZonesSelectList(list);
}

export function setZonesList(data: IZonesList): void {
    const ZonesStoreInstance = ZonesStore.getInstance();
    ZonesStoreInstance.setZonesList(data);
}

export function setZonesForcedUpdateTime(): void {
    const ZonesStoreInstance = ZonesStore.getInstance();
    ZonesStoreInstance.setForcedUpdateTime();
}

export async function getZonesList(params: IParamsList): Promise<void> {
    const { resp } = await apiManager.getApi('GET_ZONE_LIST', { searchParamsList: params });
    const { data, meta } = resp as IBodyListResponse;
    meta && setUiTotal(meta.records_count);
    data && setZonesList(data as IZonesList);
}

export async function getZonesSelect(): Promise<void> {
    const { resp } = await apiManager.getApi('GET_ZONE_SELECT');
    const { data } = resp as IBodyListResponse;
    data && setZonesSelectList(data);
}

export function cleanZonesStore(): void {
    const ZonesStoreInstance = ZonesStore.getInstance();
    ZonesStoreInstance.cleanStore();
}
