import React from 'react';
import { Catcher, CustomButton } from '../';
import cx from 'classnames';
import { IChangeStatusCommands } from '../../interfaces';
import { ITrNotificationMessage } from '../../translate';
import Styles from '../styles/m_itemCardStyles.less';

type IChangeStatusToolBar = {
    changeStatus: (status: IChangeStatusCommands) => void;
    disabled?: boolean;
    disabledHint?: ITrNotificationMessage;
    loading?: boolean;
    onCancel?: () => void;
    statuses: IChangeStatusCommands[];
};

export const ChangeStatusToolBar: React.FC<IChangeStatusToolBar> = ({
    changeStatus,
    disabled,
    disabledHint,
    loading,
    onCancel,
    statuses,
}: IChangeStatusToolBar) => {
    const buttons = statuses.map((status: IChangeStatusCommands) => (
        <CustomButton
            key={status}
            disabled={disabled}
            disabledHint={disabledHint}
            loading={loading}
            onClick={(): void => changeStatus(status)}
            type={status}
        />
    ));

    return (
        <Catcher>
            <div className={cx(Styles.cardToolBar, Styles.statusToolBar)}>
                {buttons}
                {onCancel ? <CustomButton disabled={disabled} type={'cancel'} onClick={onCancel} /> : null}
            </div>
        </Catcher>
    );
};
