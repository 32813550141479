import { ApiError, IRequestAPIBody, getApiParams, restApi, tokenManager } from './';
import {
    HTTP_STATUS,
    IApiManagerAppSignalError,
    IApiManagerAppSignalResponse,
    IApiParams,
    IGetApiResponse,
    IGetApiType,
    ILoginRequest,
    ITokenResponse,
} from './types';
import { appConfig } from '../settings';
import { abortAllFetch, addNotification, addUiSpinner, authGoToLogin, removeUiSpinner } from '../middleware';
import { appSignalSendMessage, getApiTypeWithDate } from '../instruments';
import { tND, tNM } from '../translate';

export const apiManager = {
    async getApi(
        apiType: IGetApiType,
        params?: IApiParams,
        body?: IRequestAPIBody,
        file?: Blob,
        idSpinner?: string,
    ): Promise<IGetApiResponse> {
        const getApiResponse: IGetApiResponse = { resp: {}, isError: false };
        const apiParams = await getApiParams(apiType, params, body);
        const idApiSpinner = apiParams.isNotLoader
            ? 'notSpinner'
            : addUiSpinner(idSpinner || getApiTypeWithDate(apiType));

        await tokenManager.getApiTokenCheck(apiParams, getApiResponse);

        if (apiParams.okStatus.includes(HTTP_STATUS.INCORRECT_REQUEST)) {
            addNotification({
                type: 'error',
                message: 'Error APIParams',
                description: JSON.stringify(apiParams),
                duration: 0,
                isTranslated: true,
            });
            console.error('Error:', apiParams);
        } else {
            try {
                const response = apiParams.isPostFile
                    ? await restApi.postFileRestApi({ file: file, ...apiParams })
                    : await restApi.getRestApi(apiParams);
                if (apiType !== 'LOG_OUT' && response.status === HTTP_STATUS.SERVER_ERROR_500) {
                    console.table(response);
                    console.error('Error:', response);
                    addNotification({
                        type: 'error',
                        message: tNM(`Please restart the page.`),
                        description: `${tND('Your browser interrupted the connection to our servers.')} (${
                            response.status
                        })`,
                        duration: 0,
                        isTranslated: true,
                        responseAPI: response,
                    });
                    getApiResponse.isError = true;
                } else if (apiType !== 'LOG_OUT' && response.status === HTTP_STATUS.TOKEN_GET_POST_PATCH_ERROR_401) {
                    getApiResponse.isError = true;
                    this._appSignalSendResponse({ apiType, apiParams, response });
                    if (apiParams.headerType === 'access') {
                        const disabledAccessToken = await tokenManager.getToken('disabledAccess');
                        if (disabledAccessToken) {
                            await this._handleInvalidImpersonatedToken();
                        } else {
                            await this._tryRefreshAccessToken();
                        }
                        await this._consoleAlertLater(response.toString());
                    }
                } else if (apiType === 'REFRESH_TOKEN' && response.status !== HTTP_STATUS.TOKEN_UPDATE_OK_201) {
                    getApiResponse.isError = true;
                    this._appSignalSendResponse({ apiType, apiParams, response });
                    this._consoleAlertLater(response.toString());
                    await this._goToLogin();
                } else if (
                    apiParams.errorStatus.includes(response.status) &&
                    !apiParams.okStatus.includes(response.status)
                ) {
                    if (['GET_USER'].includes(apiType) && response.status === HTTP_STATUS.GET_NOT_FOUND_404) {
                        addNotification({
                            type: 'warning',
                            message: `DATA Not found!`,
                            description: 'Non-existent data was requested.',
                            duration: 5,
                        });
                    } else if (response.status === HTTP_STATUS.TOKEN_LOGIN_ERROR_404) {
                        addNotification({
                            type: 'warning',
                            message: `BAD LOGIN!`,
                            description: 'Check your Login or Password',
                            duration: 5,
                        });
                    } else {
                        const resp = await response.json();
                        getApiResponse.resp = resp;
                        const message = tNM(
                            apiParams.method === 'POST'
                                ? `Create Error!`
                                : apiParams.method === 'PATCH'
                                ? `Update Error!`
                                : `Error!`,
                        );
                        addNotification({
                            type: 'warning',
                            message: message,
                            description: tND('Check data'),
                            duration: 5,
                            isTranslated: true,
                        });
                        if (process.env.NODE_ENV === 'development') {
                            console.log(Date.now(), '-(Check)->', resp, `<-resp-`, 'typeof-', typeof resp);
                        }
                    }
                    getApiResponse.isError = true;
                } else if (apiParams.okStatus.includes(response.status)) {
                    if (apiParams.isGetFile) {
                        const filename = response?.headers
                            ?.get('content-disposition')
                            ?.split(';')
                            ?.find((n) => n.includes('filename='))
                            ?.replace('filename="', '')
                            ?.replace('"', '')
                            ?.trim();
                        const file = { content: new Blob(), filename, isAPIComplete: false };
                        file.content = await response.blob();
                        file.isAPIComplete = true;
                        getApiResponse.file = file;
                        getApiResponse.resp = {};
                    } else if (apiParams.method === 'DELETE') {
                        if (response.status === HTTP_STATUS.GET_NOT_FOUND_404) {
                            addNotification({
                                type: 'warning',
                                message: `The document was deleted before!`,
                                duration: 5,
                            });
                        }
                        if (response.status === HTTP_STATUS.GET_OK_200) {
                            getApiResponse.resp = await response?.json();
                        }
                    } else if (
                        response.status !== HTTP_STATUS.TOKEN_LOGOUT_OK_500 &&
                        response.status !== HTTP_STATUS.SUCCESS_WITHOUT_BODY_204
                    ) {
                        getApiResponse.resp = await response.json();
                        if (apiType === 'APPLICATION') {
                            const apiHost = response.headers.get(appConfig.headerApiParams);
                            if (apiHost) {
                                getApiResponse.resp.apiHost = apiHost;
                            }
                        }
                    }
                    getApiResponse.isError = false;
                } else {
                    if (response.status === HTTP_STATUS.END_POINT_ERROR_404) {
                        if (process.env.NODE_ENV === 'development') {
                            addNotification({
                                type: 'error',
                                message: `${tNM('Error endPoint')} - ${response.status}`,
                                description: apiParams.url,
                                duration: 0,
                                isTranslated: true,
                            });
                            console.error(Date.now(), 'Error:', response.status, apiParams.apiType, apiParams.url);
                        } else {
                            addNotification({
                                type: 'warning',
                                message: `Not Found`,
                                description: 'Item not found!',
                                duration: 5,
                            });
                        }
                        getApiResponse.isError = true;
                    } else {
                        addNotification({
                            type: 'error',
                            message: `${tNM('BAD STATUS')} - ${response.status}`,
                            description: apiParams.apiType,
                            duration: 0,
                            isTranslated: true,
                        });
                        getApiResponse.isError = true;
                        console.error(Date.now(), 'Error:', response.status, apiParams.apiType);
                    }
                }
            } catch (catchError: any) {
                const error: Error = catchError as Error;
                if (process.env.NODE_ENV === 'development') {
                    console.table(error);
                }
                if (error?.name === 'AbortError') {
                    process.env.NODE_ENV === 'development' &&
                        console.warn(Date.now(), `--(development) - error->`, error.name, error.message, error);
                } else if (error.message === 'Failed to fetch' && error?.stack?.includes('Failed to fetch')) {
                    getApiResponse.isError = true;
                    getApiResponse.resp = { errors: [], ...error };
                    console.warn('Warn:', error);
                    this._appSignalSendError({
                        apiType,
                        apiParams,
                        error: new ApiError(error.message),
                        // ToDo: 04.07.2023 - add catch 504 error
                    });
                    addNotification({
                        type: 'error',
                        message: `Error connection!`,
                        description: 'Check your internet connection.',
                        duration: 5,
                        isNotSendAppSignal: true,
                    });
                } else {
                    getApiResponse.isError = true;
                    getApiResponse.resp = { errors: [], ...error };
                    console.warn('Warn:', error);
                    addNotification({
                        type: 'warning',
                        message: tNM(`Try later!`),
                        description: `${tND('No connection to the server.')} error: ${error}`,
                        duration: 5,
                        isTranslated: true,
                    });
                }
            }
        }
        removeUiSpinner(idApiSpinner);
        return getApiResponse;
    },
    async _goToLogin(): Promise<void> {
        await tokenManager.cleanToken();
        await authGoToLogin();
    },
    async _tryRefreshAccessToken(): Promise<void> {
        await tokenManager.removeToken('access');
        if (!(await tokenManager.refreshAccessToken())) {
            await this._goToLogin();
        }
    },
    async _handleInvalidImpersonatedToken(): Promise<void> {
        await abortAllFetch();
        await tokenManager.disableImpersonatedToken();
        addNotification({
            type: 'info',
            message: 'Impersonation token has expired!',
        });
    },
    _consoleAlertLater(message: string | undefined): void {
        setTimeout(() => {
            console.warn('Alert:', message);
        }, 1000);
    },
    async _logOut(): Promise<boolean> {
        const { isError } = await this.getApi('LOG_OUT');
        return !isError;
    },
    async _getAccessToken(): Promise<ITokenResponse> {
        const { resp, isError } = await this.getApi('REFRESH_TOKEN');
        const { access, refresh } = resp;
        !access && console.error('Error: Not fetch access Token');
        return { access, refresh, isError };
    },
    async _logIn(user: ILoginRequest): Promise<IGetApiResponse> {
        const body = { auth: user };
        const { resp, isError } = await this.getApi('LOG_IN', {}, body);
        const { access, refresh } = resp;
        refresh && (await tokenManager.saveToken(refresh, 'refresh'));
        access && (await tokenManager.saveToken(access, 'access'));
        return { resp, isError };
    },
    _appSignalSendResponse({ apiType, apiParams, response }: IApiManagerAppSignalResponse) {
        appSignalSendMessage({
            action: `${apiType} - ${response?.status}${response?.statusText ? ' (' + response.statusText + ')' : ''}`,
            params: apiParams,
            responseAPI: response,
        });
    },
    _appSignalSendError({ apiType, apiParams, error }: IApiManagerAppSignalError) {
        appSignalSendMessage({
            action: `${apiType} - ${error.name}${error?.message ? ' (' + error.message + ')' : ''}`,
            params: apiParams,
            error: error,
        });
    },
};
