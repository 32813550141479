// noinspection SpellCheckingInspection

export type IMapField = {
    [id: string]: any;
};

type IDic = {
    [id: string]: string[];
};

export class MapAPIField {
    private _map: IDic = {
        // all
        createdAt: ['created_at'],
        deletedAt: ['deleted_at'],
        endDate: ['end_date'],
        fileType: ['file_type'],
        startDate: ['start_date'],
        iccId: ['iccid'],
        // user
        companyId: ['company_id', 'company'],
        currentPassword: ['current_password'],
        docsAcceptedAt: ['docs_accepted_at'],
        failedLoginCount: ['failed_login_count'],
        firstName: ['first_name'],
        func: ['function'],
        lastName: ['last_name'],
        latestAcceptedAt: ['latest_accepted_at'],
        outerAssignment: ['outer_assignment'],
        passwordConfirmation: ['password_confirmation'],
        passwordNew: ['password'],
        phoneNumber: ['phonenumber'],
        registration: ['c_registration'],
        userTypeId: ['user_type_id'],
        userId: ['user_id'],
        // cardInfo
        batchId: ['batch_id'],
        dispatchLock: ['dispatch_lock'],
        firstSeen: ['first_seen'],
        lastSeen: ['last_seen'],
        limitChangeAllowed: ['limit_change_allowed'],
        limitForSubCompaniesAllowed: ['limit_for_sub_companies_allowed'],
        lockImei: ['lock_imei'],
        plmnList: ['plmn_list'],
        printedId: ['printed_id'],
        roamingImsi: ['roaming_imsi'],
        statusNew: ['status_new'],
        suspenderCompanyId: ['suspender_company_id'],
        transactionError: ['transaction_error'],
        usageLimit: ['usage_limit'],
        // Company
        actualDarkLogoUrl: ['actual_dark_logo_blob'],
        actualLogoUrl: ['actual_logo_blob'],
        addressBuilding: ['address_building'],
        addressNumber: ['address_number'],
        addressStreet: ['address_street'],
        bankName: ['bank_name'],
        billingToDepartment: ['billing_to_department'],
        billingToEmail: ['billing_to_email'],
        cardsCount: ['cards_count'],
        cdrSquashInterval: ['cdr_squash_interval'],
        companyName: ['company_name'],
        companyParentId: ['company_parent_id'],
        companyParentName: ['company_parent_name'],
        companyTypeId: ['company_type_id', 'company_type'],
        companyTypeName: ['company_type_name'],
        creditCardNumber: ['credit_card_number'],
        creditCardType: ['credit_card_type'],
        customAboutEnabled: ['custom_about_enabled'],
        customerPrivacy: ['customer_privacy'],
        customLogoLinkUrl: ['custom_logo_link_url'],
        darkLogoBlobId: ['dark_logo_blob_id'],
        darkLogoUrl: ['dark_logo_blob'],
        financialContactId: ['financial_contact_id'],
        helpDeskEnabled: ['help_desk_enabled'],
        ibanBankAccountNumber: ['iban_bank_account_number'],
        logoBlobId: ['logo_blob_id'],
        logoUrl: ['logo_blob'],
        nameOfCreditCard: ['name_of_credit_card'],
        parentId: ['parent_id', 'parent'],
        paymentTerms: ['payment_terms'],
        paymentTypeId: ['payment_type_id', 'payment_type'],
        paymentTypeName: ['payment_type'],
        primaryContactId: ['primary_contact_id'],
        privacyCdr: ['privacy_cdr'],
        rateId: ['rate_id'],
        salesRateList: ['sales_rate_list', 'apply_company_sales_rate_lists'],
        sessionGrantedTypeId: ['session_granted_type_id', 'session_granted_type'],
        sessionGrantedUnit: ['session_granted_unit'],
        sessionValidityTime: ['session_validity_time'],
        spendingLimit: ['spending_limit'],
        subscribersCsvBlobId: ['subscribers_csv_blob_id'],
        swiftBicBankCode: ['swift_bic_bank_code'],
        technicalContactId: ['technical_contact_id'],
        thresholdHigh: ['threshold_high'],
        thresholdMedium: ['threshold_medium'],
        vatNumber: ['vat_number'],
        vatPercentage: ['vat_percentage'],
        whiteLabel: ['whitelabel'],
        zeroCostData: ['zero_cost_data'],
        zeroCostSms: ['zero_cost_sms'],
        zeroCostVoice: ['zero_cost_voice'],
        zipCode: ['zip_code'],
        // Sales Rate List
        assignedCompanies: ['assigned_company'],
        calculatedRateData: ['calculated_rate_data'],
        calculatedRateSms: ['calculated_rate_sms'],
        calculatedRateVoiceMobile: ['calculated_rate_voice_mobile'],
        calculatedRateVoiceNet: ['calculated_rate_voice_net'],
        countryId: ['country_id', 'country'],
        currencyId: ['currency_id', 'currency'],
        defaultRateData: ['default_rate_data'],
        defaultRateDataUnitId: ['default_rate_data_unit_id', 'default_rate_data_unit'],
        defaultRateSms: ['default_rate_sms'],
        defaultRateSmsUnitId: ['default_rate_sms_unit_id', 'default_rate_sms_unit'],
        defaultRateVoiceMobile: ['default_rate_voice_mobile'],
        defaultRateVoiceNet: ['default_rate_voice_net'],
        mrcActiveCard: ['mrc_active_card'],
        mrcActiveImsi: ['mrc_active_imsi'],
        mrcCard: ['mrc_card'],
        networkId: ['network_id', 'network'],
        nrcCard: ['nrc_card'],
        nrcImsi: ['nrc_imsi'],
        operatorId: ['operator_id', 'operator'],
        rateData: ['rate_data'],
        rateDataUnitId: ['rate_data_unit_id', 'rate_data_unit'],
        rateSms: ['rate_sms'],
        rateSmsUnitId: ['rate_sms_unit_id', 'rate_sms_unit'],
        rateVoiceMobile: ['rate_voice_mobile'],
        rateVoiceMobileUnit: ['rate_voice_mobile_unit'],
        rateVoiceMobileUnitId: ['rate_voice_mobile_unit_id'],
        rateVoiceMobileUnitVal: ['rate_voice_mobile_unit_val'],
        rateVoiceNet: ['rate_voice_net'],
        rateVoiceNetUnit: ['rate_voice_net_unit'],
        rateVoiceNetUnitId: ['rate_voice_net_unit_id'],
        rateVoiceNetUnitVal: ['rate_voice_net_unit_val'],
        // Purchase Rate List
        incrementData: ['increment_data'],
        incrementVoiceMobile: ['increment_voice_mobile'],
        incrementVoiceNet: ['increment_voice_net'],
        purchaseRateListEntry: ['purchase_rate_list_entry'],
        // DiameterSessions
        ccRequestNumber: ['cc_request_number'],
        ccRequestType: ['cc_request_type'],
        eventTimestamp: ['event_timestamp'],
        salesRateListEntry: ['sales_rate_list_entry'],
        sessionId: ['session_id'],
        sgsnMccMnc: ['sgsn_mcc_mnc'],
        totalUsedUnits: ['total_used_units'],
        // Card Sets
        activeSubscribersCount: ['active_subscribers_count'],
        cardId: ['card_id', 'card'],
        cardSetId: ['card_set_id'],
        combinationCardSetCards: ['card_combinations', 'card_combination'],
        dataUnits: ['data_units'],
        isActive: ['is_active'],
        isDeletable: ['is_deletable'],
        originalUnitsSum: ['original_units_sum'],
        shortId: ['short_id'],
        subscribersCount: ['subscribers_count'],
        // Operator
        guestRecordsCount: ['guest_records_count'],
        hostRecordsCount: ['host_records_count'],
        // BalanceLog
        balanceAfter: ['balance_after'],
        balanceBefore: ['balance_before'],
        productTypeId: ['product_type_id', 'product_type'],
        RatedCallDataRecord: ['rated_call_data_record'],
        transactionId: ['transaction_id'],
        updatedAt: ['updated_at'],
        // SubscriberAPIRequest
        accountId: ['accountid'],
        accountNumber: ['accountnumber'],
        allowBillShockSMS: ['allowbillshocksms'],
        allowData: ['allowdata'],
        allowDataOverUsage: ['allowdataoverusage'],
        allowDataRoaming: ['allowdataroaming'],
        allowPremiumSMS: ['allowpremiumsms'],
        billShockActive: ['billshockactive'],
        billShockRelease: ['billshockrelease'],
        billShockTrigger: ['billshocktrigger'],
        bundleProductId: ['bundleproductid'],
        bundleProductName: ['bundleproductname'],
        bundleProductStartDate: ['startdate'],
        bundleProductType: ['bundleproducttype'],
        bundleStartOrderId: ['startbundleorderid'],
        numberListRequestId: ['numberlistrequestid'],
        orderDate: ['orderdate'],
        orderNumber: ['iccordernumberid', 'ordernumber'],
        orderReference: ['orderreference'],
        orderStatusId: ['orderstatusid'],
        orderType: ['ordertype'],
        simCards: ['simcards'],
        simType: ['simtype'],
        subscriptionId: ['subscriptionid'],
        outboundChannel: ['outbound_channel'],
        // DeviceAttribute
        allocationDate: ['allocation_date'],
        brandDetails: ['brand_details'],
        brandName: ['brand_name'],
        deviceModelName: ['device_model_name'],
        deviceType: ['device_type'],
        marketingName: ['marketing_name'],
        nonRemovableEuicc: ['nonremovable_euicc'],
        nonRemovableUicc: ['nonremovable_uicc'],
        operatingSystem: ['operating_system'],
        organisationId: ['organisation_id'],
        removableEuicc: ['removable_euicc'],
        removableUicc: ['removable_uicc'],
        simSlot: ['sim_slot'],
        // EsNotifications
        notificationPointId: ['notification_point_id'],
        notificationPointStatus: ['notification_point_status'],
        profileType: ['profile_type'],
        resultData: ['result_data'],
        // FeatureFlag
        newCdrsRepo: ['new_cdrs_repo'],
        newResponseFormat: ['new_response_format'],
        readBigTablesFromReplica: ['read_big_tables_from_replica'],
        useBigquery: ['use_bigquery'],
        v3CsvExporter: ['v3_csv_exporter'],
        v3PdfExporter: ['v3_pdf_exporter'],
        // use_bigquery
        cardsRatio: ['cards_ratio'],
        costRatio: ['cost_ratio'],
        unitType: ['unit_type'],
        volumeRatio: ['volume_ratio'],
        currencyAbbreviation: ['currency_abbreviation'],
        // Other
        activeThisMonth: ['active_this_month'],
        amountCents: ['amount_cents'],
        balanceDifference: ['balance_difference'],
        calledStationId: ['called_station_id'],
        cardCombinationId: ['card_combination_id'],
        chargingCharacteristics: ['charging_characteristics'],
        chargingId: ['charging_id'],
        companiesPackages: ['companies_packages'],
        companyIds: ['company_ids'],
        creditRemains: ['credit_remains'],
        dataVolume: ['data_volume'],
        deviceName: ['device_name'],
        dialCode: ['dial_code'],
        errorMessage: ['error_message'],
        finalProfileStatusIndicator: ['final_profile_status_indicator'],
        geolocationEditable: ['geolocation_editable'],
        geolocationEnabled: ['geolocation_enabled'],
        geotrackingEnabled: ['geotracking_enabled'],
        ggsnAddress: ['ggsn_address'],
        hashInfo: ['hash_info'],
        imsiMccNnc: ['imsi_mcc_mnc'],
        lockedImei: ['locked_imei'],
        lockerId: ['locker_id'],
        logableId: ['logable_id'],
        matchingId: ['matching_id'],
        monthDuration: ['month_duration'],
        multipleServicesCreditControl: ['multiple_services_credit_control'],
        notDestroyed: ['not_destroyed'],
        operationId: ['operation_id'],
        operationName: ['operation_name'],
        operatorCdrId: ['operator_cdr_id'],
        orderableId: ['orderable_id'],
        orderableType: ['orderable_type'],
        originStateId: ['origin_state_id'],
        packagesCountries: ['packages_countries'],
        packagesNetworks: ['packages_networks'],
        packagesOperators: ['packages_operators'],
        paymentUrl: ['payment_url'],
        pdpAddress: ['pdp_address'],
        pdpContextType: ['pdp_context_type'],
        productableId: ['productable_id'],
        productableType: ['productable_type'],
        ratingGroup: ['rating_group'],
        reasonCode: ['reason_code'],
        reportingReason: ['reporting_reason'],
        sgsnAddress: ['sgsn_address'],
        smdpAddress: ['smdp_address'],
        subjectCode: ['subject_code'],
        subjectIdentifier: ['subject_identifier'],
        subscriptionIdValue: ['subscription_id_value'],
        targetType: ['target_type'],
        totalCount: ['total_count'],
        totalErrors: ['total_errors'],
        totalSaved: ['total_saved'],
        unitsAfter: ['units_after'],
        unitsBefore: ['units_before'],
        usedServiceUnit: ['used_service_unit'],
        userEquipmentInfoValue: ['user_equipment_info_value'],
        userIds: ['user_ids'],
        zipFile: ['zip_file'],
        zoneId: ['zone_id', 'zone'],
    };

    public getUiField(apiField: string): string {
        return Object.entries(this._map).find(([key, vals]) => !!key && vals.includes(apiField))?.[0] || apiField;
    }

    public getApiField(uiField: string): string {
        const values: string[] = this._map[uiField] || [];
        return values[0] || uiField;
    }
}

export const uiFieldsIsNumber = [
    'cdrSquashInterval',
    'defaultRateData',
    'defaultRateSms',
    'incrementData',
    'incrementVoiceMobile',
    'rateData',
    'rateSms',
    'sessionGrantedUnit',
    'sessionValidityTime',
    'spendingLimit',
    'thresholdHigh',
    'thresholdMedium',
    'usageLimit',
];

export const apiFieldsIsNumber = [
    'cdrSquashInterval',
    'cost',
    'dataVolume',
    'duration',
    'incrementData',
    'incrementVoiceMobile',
    'incrementVoiceNet',
    'paymentTypeId',
    'price',
    'productTypeId',
    'sessionGrantedUnit',
    'sessionValidityTime',
    'usageLimit',
];

type IApiType = string | number | boolean | null;
type IUiType = string | number | boolean | undefined;

export const api2UiValue = (field: string, value: IApiType): IUiType => {
    if (!!value && uiFieldsIsNumber.includes(field) && !isNaN(Number(value))) {
        return field === 'usageLimit' ? Number(value) / 1000 : Number(value);
    } else if (value === null) {
        return undefined;
    } else {
        return value;
    }
};

const ui2ApiValue = (field: string, value: IUiType): IApiType => {
    if (typeof value === 'boolean') {
        return value;
    } else if (!value && typeof value !== 'number') {
        return null;
    } else if (typeof value === 'number') {
        if (apiFieldsIsNumber.includes(field)) {
            return value;
        } else {
            return value.toString();
        }
    } else {
        if (apiFieldsIsNumber.includes(field)) {
            if (!isNaN(Number(value))) {
                return Number(value);
            } else {
                console.warn(`Field: ${field} value: "${value}" is not a number!`);
                return null;
            }
        } else {
            return value;
        }
    }
};

export const mapUi2ApiField = (ui: IMapField): IMapField => {
    const mapField = new MapAPIField();
    const api: IMapField = {};
    Object.entries(ui).forEach(([key, value]) => {
        api[mapField.getApiField(key)] = ui2ApiValue(key, value);
    });
    return api;
};

export const mapApi2UiField = (api: IMapField): IMapField => {
    const mapField = new MapAPIField();
    const ui: IMapField = {};
    Object.entries(api).forEach(([key, value]) => {
        const uiName = mapField.getUiField(key);
        ui[uiName] = api2UiValue(uiName, value);
    });
    return ui;
};

export const mapApi2UiList = (list: IMapField[] = []): IMapField[] => {
    return list
        .map((i) => mapApi2UiField(i))
        .map((i) => {
            const ui: IMapField = {};
            Object.entries(i).forEach(([key, value]) => {
                ui[key] = Array.isArray(value) ? mapApi2UiList(value) : value;
            });
            return ui;
        });
};

export const getUiField = (apiField: string): string => {
    const mapField = new MapAPIField();
    return mapField.getUiField(apiField);
};

export const getApiField = (uiField: string): string => {
    const mapField = new MapAPIField();
    return mapField.getApiField(uiField);
};

export const cleanPathFields = <T>(formFields: T, initFields: IMapField, blackList: Array<string> = []): T => {
    return Object.fromEntries(
        Object.entries(formFields as any).filter(
            ([key, value]) => blackList.includes(key) || initFields[key] !== value,
        ),
    ) as T;
};
