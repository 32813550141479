import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Col, Row, Spin } from 'antd';
import { appConfig } from '../../settings';
import { apiConfig } from '../../REST';
import { IAcceptanceDocumentsItem } from '../../interfaces';
import { useAuthAcceptanceDocumentsList, useProfile, useUiIsSpinnerFound } from '../../hooks';
import { getAuthAcceptanceDocuments } from '../../middleware';
import { Catcher, cardLayoutOptions } from '../../Components';
import { AboutInfo, DownloadFile } from './Components/';
import Styles from '../styles/m_itemCardStyles.less';
import AboutStyles from './m_styles.less';

export const About: React.FC = observer(() => {
    const { companyId } = useProfile();
    const documentsList = useAuthAcceptanceDocumentsList();
    const manual = appConfig.downloadFile.manual;
    const apiUrl = apiConfig.apiUrl();
    const isLoading = useUiIsSpinnerFound(['GET_ACCEPTANCE_DOCUMENTS']);

    useEffect(() => {
        getAuthAcceptanceDocuments(companyId);
    }, []);

    const downloadDocsJSX = documentsList.map((f: IAcceptanceDocumentsItem, index: number) => {
        const defaultData =
            f.domain === 'terms_and_conditions' ? appConfig.downloadFile.terms : appConfig.downloadFile.policy;

        return (
            <DownloadFile
                key={index}
                title={f.name || defaultData.title}
                file={`${apiUrl}${f.contentFileUrl}` || defaultData.file}
                fileLabel={'View/Download PDF'}
                enableDownload={false}
                target={'_blank'}
            />
        );
    });

    const downloadManualJSX = (
        <DownloadFile
            title={manual.title}
            file={manual.file}
            fileLabel={'View/Download PDF'}
            enableDownload={false}
            target={'_blank'}
        />
    );

    return (
        <Catcher>
            <article className={Styles.main}>
                <Row>
                    <AboutInfo />
                    <Col {...cardLayoutOptions.cardCompanyShowInfo}>
                        {isLoading ? (
                            <div className={AboutStyles.spinnerContainer}>
                                <Spin />
                            </div>
                        ) : (
                            <>
                                <div>{downloadDocsJSX}</div>
                                <div>{downloadManualJSX}</div>
                            </>
                        )}
                    </Col>
                </Row>
            </article>
        </Catcher>
    );
});
