import React, { Fragment, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { ViewMap } from 'mobx-state-router';
import { RouterPagesStore } from '../stores/routerPages';
import { MainView } from './MainView';
import { IRoute } from '../interfaces';
import { usePagesStoreCurrentComponent, useUiChangedTheme } from '../hooks';
import { checkProductionAPIHost } from '../middleware';
import { Loading } from '../Components';
import Styles from './m_main.less';

function useMainViewMap(): ViewMap {
    const routerPagesStore = RouterPagesStore.getInstance();
    const _mainViewMap: ViewMap = {};
    routerPagesStore.allRoutes.forEach((p: IRoute) => (_mainViewMap[p.name] = <MainView />));
    return _mainViewMap;
}

export const RouterViewWithPagesStore: React.FC = observer(() => {
    const { routeName } = usePagesStoreCurrentComponent();
    const viewMap: ViewMap = useMainViewMap();

    const view = viewMap[routeName];

    return view ? <Fragment>{view}</Fragment> : null;
});

export const Main: React.FC = observer(() => {
    const [isLoadingApiHost, setLoadingApiHost] = useState(true);
    const isChangedTheme = useUiChangedTheme();

    const checkAPIHost = async (): Promise<void> => {
        await checkProductionAPIHost();
        setLoadingApiHost(false);
    };

    useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            checkAPIHost();
        } else {
            setLoadingApiHost(false);
        }
    }, []);

    return (
        <div className={Styles.mainApp}>
            <Loading isLoading={isChangedTheme || isLoadingApiHost} />
            {!isLoadingApiHost ? <RouterViewWithPagesStore /> : null}
        </div>
    );
});
