import React from 'react';
import { observer } from 'mobx-react';
import { Select } from 'antd';
import { BarChartOutlined, LineChartOutlined, PieChartOutlined } from '@ant-design/icons';
import { tB } from '../../../translate';
import Styles from '../m_dashboarStyles.less';

const { Option } = Select;

type IValue = 'bar' | 'pie' | 'line';
type IChartTypesItem = {
    value: IValue;
    title: string;
};

interface IChartTypeSelector {
    defaultChart?: string;
    onChangeChartType?: (chartType: string) => void;
    availableTypes?: Array<IValue>;
}
export const ChartTypeSelector: React.FC<IChartTypeSelector> = observer(
    ({ defaultChart = 'bar', onChangeChartType, availableTypes = [] }: IChartTypeSelector) => {
        const onChangeType = (newType: string): void => {
            onChangeChartType && onChangeChartType(newType);
        };
        const chartTypes: Array<IChartTypesItem> = [
            { value: 'bar', title: tB('Bar') },
            { value: 'line', title: tB('Line') },
            { value: 'pie', title: tB('Pie') },
        ];

        const options = chartTypes
            .filter((item: IChartTypesItem) => availableTypes?.includes(item.value))
            .map((item: IChartTypesItem) => {
                let Icon: JSX.Element;
                switch (item.value) {
                    case 'pie':
                        Icon = <PieChartOutlined className={Styles.selectorIcon} />;
                        break;
                    case 'line':
                        Icon = <LineChartOutlined className={Styles.selectorIcon} />;
                        break;
                    default:
                        Icon = <BarChartOutlined className={Styles.selectorIcon} />;
                        break;
                }

                return (
                    <Option
                        key={item.value}
                        value={item.value}
                        // disabled={!availableTypes?.includes(item.value)}
                    >
                        {Icon}
                        {item.title}
                    </Option>
                );
            });

        return (
            <Select
                className={Styles.selector}
                defaultValue={defaultChart}
                getPopupContainer={(trigger): HTMLElement => trigger.parentElement}
                onChange={onChangeType}
            >
                {options}
            </Select>
        );
    },
);
