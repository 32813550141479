import React, { ReactNode } from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Catcher, LinkComponent, TableRawDataField } from '../../../';
import { FileStatus, ShowFileInfo } from './';
import { IOperatorInteractionsResponse } from '../../../../interfaces';
import { useOperatorInteractionsResponsesTable, useUiIsLoading, useUiSearch } from '../../../../hooks';
import { lightText } from '../../../../instruments';
import { tF } from '../../../../translate';
import Styles from '../../../styles/m_viewStyles.less';

interface IDataType {
    key: string;
    id: ReactNode;
    createdAt: string;
    data: ReactNode;
    gateway: string;
    iccId: ReactNode;
    operationId: ReactNode;
    operationName: string;
    status: ReactNode;
}

export const ResponsesTable: React.FC = observer(() => {
    const isLoading = useUiIsLoading();
    const list = useOperatorInteractionsResponsesTable();
    const search = useUiSearch();

    const tableColumns: ColumnsType<IDataType> = [
        {
            title: tF('ID'),
            dataIndex: 'id',
            width: 70,
            className: Styles.columnRight,
        },
        {
            title: tF('File'),
            dataIndex: 'fileInfo',
        },
        {
            title: tF('ICCID'),
            dataIndex: 'iccId',
        },
        {
            title: 'MSISDN',
            dataIndex: 'msisdn',
        },
        {
            title: 'Gateway',
            dataIndex: 'gateway',
        },
        {
            title: 'OperationId',
            dataIndex: 'operationId',
        },
        {
            title: 'Operation name',
            dataIndex: 'operationName',
        },
        {
            title: tF('Data'),
            dataIndex: 'data',
        },
        {
            title: tF('Status'),
            dataIndex: 'status',
        },
        {
            title: tF('Created at'),
            dataIndex: 'createdAt',
        },
    ];

    const tableData: IDataType[] = list.map((r: IOperatorInteractionsResponse) => {
        return {
            key: r.id,
            id: <LinkComponent currentListComponent={'OperatorInteractionsResponsesList'} id={r.id} />,
            fileInfo: <ShowFileInfo fileInfo={r.file} />,
            createdAt: r.createdAt || '',
            data: <TableRawDataField data={r.data} />,
            gateway: r.gateway?.toString() || '',
            iccId: lightText(r.iccId, search),
            msisdn: r.msisdn?.toString() || '',
            operationId: (
                <LinkComponent
                    currentListComponent={'OperatorInteractionsResponsesList'}
                    id={r.id}
                    name={r.operationId}
                    isLight
                />
            ),
            operationName: r.operationName?.toString() || '',
            status: <FileStatus status={r.status} />,
        };
    });

    return (
        <Catcher>
            <div className={Styles.tablePlace}>
                <Table
                    className={'table-theme'}
                    columns={tableColumns}
                    dataSource={tableData}
                    loading={isLoading}
                    size={'middle'}
                    pagination={false}
                />
            </div>
        </Catcher>
    );
});
