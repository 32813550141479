import React from 'react';
import { observer } from 'mobx-react';
import { Tabs } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { useRouterStore } from '../../hooks';
import { tP } from '../../translate';
import { PublicPagesWrapper, SignUpForm } from './Components';
import '../../customStyle/form.less';
import './styles.less';

export const SignUpPage: React.FC = observer(() => {
    const { TabPane } = Tabs;
    const routerStore = useRouterStore();
    const goToLogin = (): void => {
        routerStore.goTo('Login');
    };

    const onChangeTabs = (component: string): void => {
        if (component === 'Login') {
            goToLogin();
        }
    };

    return (
        <PublicPagesWrapper>
            <Tabs onChange={onChangeTabs} type="card" activeKey={'SignUp'} tabBarGutter={0} className={'login-tabs'}>
                <TabPane
                    tab={
                        <a onClick={goToLogin} href={'/login'}>
                            <LockOutlined />
                            {tP('Login')}
                        </a>
                    }
                    key="Login"
                />
                <TabPane
                    tab={
                        <>
                            <LockOutlined />
                            {tP('Sign Up')}
                        </>
                    }
                    key="SignUp"
                >
                    <SignUpForm />
                </TabPane>
            </Tabs>
        </PublicPagesWrapper>
    );
});
