import { action, makeObservable, observable, reaction } from 'mobx';
import { UiStore } from './ui';
import { appConfig } from '../settings';
import { IFilterParams, IMyPurchaseRateListTableItem, IPurchaseRateListTableItem } from '../interfaces';
import { getPurchaseRatesList } from '../middleware';
import { apiParamsCleaner } from '../instruments';

export class PurchasesStore {
    private constructor() {
        makeObservable(this);
        reaction(
            () => this.forcedUpdateTime,
            () => {
                const UiStoreInstance = UiStore.getInstance();
                const params = UiStoreInstance.params;
                const cleanParams = apiParamsCleaner(params, this.filterParams);
                getPurchaseRatesList(cleanParams);
            },
            {
                delay: appConfig.APIDelay,
            },
        );
    }

    private static instance: PurchasesStore;
    public static getInstance(): PurchasesStore {
        if (!PurchasesStore.instance) {
            PurchasesStore.instance = new PurchasesStore();
        }
        return PurchasesStore.instance;
    }

    @observable list: IPurchaseRateListTableItem[] = [];
    @action setList(list: IPurchaseRateListTableItem[]): void {
        this.list = list;
    }

    @observable myList: IMyPurchaseRateListTableItem[] = [];
    @action setMyList(list: IMyPurchaseRateListTableItem[]): void {
        this.myList = list;
    }

    @action cleanStore(): void {
        this.list = [];
        this.myList = [];
    }

    @observable forcedUpdateTime = 0;
    @action setForcedUpdateTime(): void {
        this.forcedUpdateTime = Date.now();
    }

    @observable filterParams: IFilterParams = {
        pageNumber: true,
        pageSize: true,
        search: true,
    };
}
