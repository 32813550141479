import React from 'react';
import { observer } from 'mobx-react';
import { useProfileIsGeotrackingEnabled } from '../../../hooks';
import Styles from '../m_geoTrackingStyles.less';

interface IDemoOverlay {
    children?: JSX.Element;
}

export const DemoOverlay: React.FC<IDemoOverlay> = observer(({ children }: IDemoOverlay) => {
    const isEnabled = useProfileIsGeotrackingEnabled();

    if (isEnabled) {
        return null;
    }

    return (
        <div className={Styles.demoOverlayContainer}>
            <div className={Styles.demoOverlayContent}>{children}</div>
        </div>
    );
});
