import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Row } from 'antd';
import { CallDataRecordsLast5, Catcher, cardLayoutOptions } from '../';
import { IGetApiType } from '../../REST';
import { IShowFormComponent } from '../../interfaces';
import {
    useOperatorActivityCurrentMonth,
    useOperatorActivityPrevMonth,
    useOperatorSpinners,
    useProfileFeatureFlagsIsBigQuery,
    useRouterStore,
} from '../../hooks';
import {
    abortSelectedFetch,
    cleanCallDataRecordsStore,
    cleanOperatorStore,
    getFeatureFlagIsBigQuery,
    getOperator,
    getOperatorEditRelationSelect,
    getOperatorShowRelationSelect,
} from '../../middleware';
import { ActivityMonth, EditOperatorForm, ShowOperatorInfo } from './Components';
import Styles from '../styles/m_itemCardStyles.less';

export const Operator: React.FC<IShowFormComponent> = observer(({ isEdit, isNew }: IShowFormComponent) => {
    const routerStore = useRouterStore();
    const [id, setId] = useState('');
    const usedApiTypes: IGetApiType[] = useOperatorSpinners();
    const isBigQuery = useProfileFeatureFlagsIsBigQuery();

    const activityCurrentMonth = useOperatorActivityCurrentMonth;
    const activityPrevMonth = useOperatorActivityPrevMonth;

    const goOut = (): void => {
        abortSelectedFetch(usedApiTypes);
        cleanOperatorStore();
        cleanCallDataRecordsStore();
    };
    const goToList = (): void => {
        routerStore.goTo('OperatorsList').then();
        goOut();
    };

    const _getOperator = async (id: string): Promise<void> => {
        const isGetOperator = await getOperator(id);
        if (!isGetOperator) {
            goToList();
        }
    };

    const fetchOperatorRelationSelect = async (id: string) => {
        const isBigQuery = getFeatureFlagIsBigQuery();
        getOperatorShowRelationSelect(id, isBigQuery);
    };

    useEffect(() => {
        return (): void => {
            goOut();
        };
    }, []);

    useEffect(() => {
        if (isEdit || isNew) {
            getOperatorEditRelationSelect();
        }
        if (!isEdit && !!id) {
            fetchOperatorRelationSelect(id);
        }
        if (id) {
            _getOperator(id).then();
        }
    }, [id]);

    useEffect(() => {
        if (routerStore.routerState.params.id) {
            setId(routerStore.routerState.params.id);
        }
    }, [routerStore.routerState.params.id]);

    const setEdit = (isEdit?: boolean): void => {
        if (isNew) {
            routerStore.goTo('OperatorsList').then();
        } else if (isEdit) {
            abortSelectedFetch(usedApiTypes);
            _getOperator(id).then();
            getOperatorEditRelationSelect();
            routerStore.goTo('OperatorEdit', { params: { id } }).then();
        } else {
            _getOperator(id).then();
            getOperatorShowRelationSelect(id, isBigQuery);
            routerStore.goTo('Operator', { params: { id } }).then();
        }
    };

    return (
        <Catcher>
            <article className={Styles.main}>
                {isEdit || isNew ? (
                    <EditOperatorForm setEditMode={setEdit} isNew={isNew} />
                ) : (
                    <>
                        <Row>
                            <ShowOperatorInfo setEditMode={setEdit} />
                        </Row>
                        <Row>
                            <CallDataRecordsLast5
                                columnOption={cardLayoutOptions.cardTableFull}
                                idSpinners={['GET_CALL_DATA_RECORDS_LIST', 'GET_CALL_DATA_RECORDS_LAST_5']}
                            />
                        </Row>
                        <Row>
                            <ActivityMonth
                                title={'API/CDR overview for the running month'}
                                useSelectHook={activityCurrentMonth}
                            />
                            <ActivityMonth
                                title={'API/CDR overview for last month'}
                                useSelectHook={activityPrevMonth}
                            />
                        </Row>
                    </>
                )}
            </article>
        </Catcher>
    );
});
