import React, { MouseEvent } from 'react';
import { observer } from 'mobx-react';
import cx from 'classnames';
import { IPageList } from '../../interfaces';
import { useChildrenOfPage, useRouterStore, useUiSearch } from '../../hooks';
import { setUiLastViewComponentName } from '../../middleware';
import { dateText2Utc, lightText } from '../../instruments';
import { tI } from '../../translate';
import Styles from './m_styles.less';

interface ILinkComponent {
    id?: string;
    currentListComponent: IPageList;
    deletedAt?: string;
    isDelete?: boolean;
    isFilterCurrentView?: boolean;
    isLight?: boolean;
    isOpenAsEdit?: boolean;
    name?: string | null;
}

export const LinkComponent: React.FC<ILinkComponent> = observer(
    ({
        id,
        currentListComponent,
        deletedAt,
        isDelete,
        isFilterCurrentView,
        isLight,
        isOpenAsEdit,
        name,
    }: ILinkComponent) => {
        const routerStore = useRouterStore();
        const childrenPages = useChildrenOfPage(currentListComponent, id);
        const componentName = isOpenAsEdit ? childrenPages.editComponentName : childrenPages.viewComponentName;
        const componentIsRight = isOpenAsEdit ? childrenPages.editComponentIsRight : childrenPages.viewComponentIsRight;
        const componentLink = isOpenAsEdit ? childrenPages.editComponentLink : childrenPages.viewComponentLink;
        const search = useUiSearch();
        const isMyLink =
            componentName === routerStore?.routerState?.routeName && id === routerStore?.routerState?.params?.id;
        const showName = name ? name : `#\u00A0${id}`;
        const lightName = isLight ? lightText(showName, search) : showName;
        const title = (
            !!deletedAt
                ? `${name} - ${tI('Deleted at')}: ${dateText2Utc(deletedAt)}`
                : isDelete
                ? `${name} - ${tI('Deleted')}`
                : lightName
        ) as string | undefined;

        if (!id && !name) {
            return null;
        } else if (componentIsRight && id && !isMyLink) {
            const goTo = (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>): void => {
                if (!(event.ctrlKey || event.metaKey || event.altKey || event.shiftKey)) {
                    event.preventDefault();
                    if (isFilterCurrentView) {
                        setUiLastViewComponentName('About');
                    }
                    routerStore.goTo(componentName, { params: { id } }).then();
                }
            };

            return (
                <a
                    onClick={goTo}
                    href={componentLink}
                    className={cx(
                        Styles.item,
                        Styles.linkItem,
                        isDelete || !!deletedAt ? Styles.deleteItem : Styles.activeItem,
                    )}
                    title={title}
                >
                    {lightName}
                </a>
            );
        } else {
            return (
                <span title={title} className={cx(Styles.item, isDelete ? Styles.deleteItem : Styles.textItem)}>
                    {lightName}
                </span>
            );
        }
    },
);
