/* eslint-disable */
import { CompanyStore } from '../stores/company';
import {
    cleanApiMessages,
    cleanCatalogsStore,
    cleanCompaniesStore,
    cleanCountriesStore,
    cleanCurrenciesStore,
    cleanRatesStore,
    getAuthAcceptanceDocuments,
    getCatalogsCompanyTypeSelect,
    getCatalogsPaymentTypeSelect,
    getCompaniesParents,
    getCountriesSelect,
    getCurrenciesSelect,
    getFeatureFlagRules,
    getOperatorsSelect,
    getProfileIsCompanyTypeIdMNOHost,
    getRatesSelect,
    getUsersByCompanySelect,
} from './';
import dayjs from 'dayjs';
import { appConfig } from '../settings';
import { IBodyItemResponse, ILogoResp, ILogoRespAPI, IRequestAPIBody, apiManager } from '../REST';
import {
    IAboutAPI,
    IAboutInfo,
    ICompanyApiKeyItem,
    ICompanyApiKeyList,
    ICompanyApiKeyListAPI,
    ICompanyCardSetsList,
    ICompanyCustomDocs,
    ICompanyForm,
    ICompanyGetAPI,
    ICompanyIncludeCompanyOperatorGETAPI,
    ICompanyIncludeOperatorPOSTAPI,
    ICompanyIncludeRateAPI,
    ICompanyInfo,
    ICompanyOperator,
    ICompanyOperatorList,
    ICompanyPostDataAttributesAPI,
    ICompanyRateItem,
    ICompanyRateList,
    ICompanyStatistics,
    ICompanySubscriptionInfo,
    ICompanySubscriptionsBody,
    ICompanySubscriptionsGetAPi,
    ICompanySubscriptionsGetAPiItem,
    IIMainUniversalUiItem,
    IIMainUniversalUiItemList,
    IIncludeItemCardSetAPI,
    IIncludeItemCompanySalesRateAPI,
    IIncludeItemCurrencyAPI,
    IIncludeTypeAPI,
    IIncludeUserItemAPI,
    IItemAPI,
    IMakeRelationships,
    IMakeRelationshipsInclude,
    IPatchItem,
    IPostItem,
    IRelationshipItem,
    IRelationshipsItemPOSTAPI,
    ITypeAny,
    IUser,
} from '../interfaces';
import {
    IMapField,
    getAPIUserFullName,
    globalHexId,
    intervalAPI2Ui,
    intervalUi2API,
    isNewId,
    mapApi2UiField,
    mapUi2ApiField,
} from '../instruments';

function mapAPIToCompanyInfo(apiCompany: ICompanyGetAPI): ICompanyInfo {
    const companyTypeId = apiCompany?.data?.relationships?.company_type?.data?.id;
    const companyTypeFind = apiCompany?.included?.find((i) => i.type === 'company_type' && i.id === companyTypeId);
    const companyType = (companyTypeFind as IItemAPI)?.attributes?.name;
    const parentId = apiCompany?.data?.relationships?.company_parent?.data?.id;
    const parentFind = apiCompany?.included?.find((i) => i.type === 'company_parent' && i.id === parentId);
    const parent = (parentFind as IItemAPI)?.attributes?.name;
    const primaryContactId = apiCompany?.data?.relationships?.primary_contact?.data?.id;
    const primaryContactFind = apiCompany?.included?.find(
        (i) => i.type === 'user' && i.id === primaryContactId,
    ) as IIncludeUserItemAPI;
    const primaryContact = getAPIUserFullName(primaryContactFind);
    const technicalContactId = apiCompany?.data?.relationships?.technical_contact?.data?.id;
    const financialContactId = apiCompany?.data?.relationships?.financial_contact?.data?.id;
    const countryId = apiCompany?.data?.relationships?.country?.data?.id;
    const currencyId = apiCompany?.data?.relationships?.currency?.data?.id;
    const currencyFind = apiCompany?.included?.find((i) => i.type === 'currency' && i.id === currencyId);
    const currency = (currencyFind as IIncludeItemCurrencyAPI)?.attributes?.abbreviation;
    const paymentTypeId = apiCompany?.data?.relationships?.payment_type?.data?.id;
    const paymentTypeFind = apiCompany?.included?.find(
        (i) => i.type === 'payment_type' && i.id === paymentTypeId,
    ) as IItemAPI;
    const paymentTypeName = paymentTypeFind?.attributes?.name;
    const sessionGrantedTypeId = apiCompany?.data?.relationships?.session_granted_type?.data?.id;
    const isHashed = apiCompany?.data?.attributes?.name === '******';

    const {
        interval: cdrSquashIntervalValue,
        type: cdrSquashIntervalType,
        name: cdrSquashIntervalName,
    } = intervalAPI2Ui(apiCompany?.data?.attributes?.cdr_squash_interval);

    let mapAttributes: ICompanyInfo = { id: apiCompany?.data?.id || '' };

    if (apiCompany?.data?.attributes) {
        mapAttributes = {
            ...mapAttributes,
            ...mapApi2UiField(apiCompany?.data?.attributes),
            cdrSquashIntervalValue,
            cdrSquashIntervalName,
            cdrSquashIntervalType,
            companyType,
            companyTypeId,
            countryId,
            currency,
            currencyId,
            financialContactId,
            parent,
            parentId,
            paymentTypeId,
            paymentTypeName,
            primaryContact,
            primaryContactId,
            sessionGrantedTypeId,
            technicalContactId,
            privacyCdr: apiCompany?.data?.attributes?.privacy_cdr,
            isHashed,
        };
    }

    return mapAttributes;
}

function setCompanyInfoStore(company: ICompanyInfo): void {
    const CompanyInterface = CompanyStore.getInstance();
    CompanyInterface.setCompanyInfo(company);
}

function setCompanyApiKeyList(list: ICompanyApiKeyList): void {
    const CompanyInterface = CompanyStore.getInstance();
    CompanyInterface.setApiKeysList(list);
}

export function setCompanyInfoStoreLogoBlobId(id: string, isDark?: boolean): void {
    const CompanyInterface = CompanyStore.getInstance();
    CompanyInterface.setCompanyInfoLogoBlobId(id, isDark);
}
export function setCompanyInfoStoreCleanLogoBlobId(isDark?: boolean): void {
    const CompanyInterface = CompanyStore.getInstance();
    CompanyInterface.cleanCompanyInfoLogoBlobId(isDark);
}

export function setCompanyInfoStoreCleanLogoUrl(isDark?: boolean): void {
    const CompanyInterface = CompanyStore.getInstance();
    CompanyInterface.cleanLogoUrl(isDark);
}

function setCompanyRateListStore(list: ICompanyRateList): void {
    const CompanyInterface = CompanyStore.getInstance();
    CompanyInterface.setCompanyRateList(list);
}

function updateCompanyRateListStore(list: ICompanyRateList): void {
    const CompanyInterface = CompanyStore.getInstance();
    const { rateList } = CompanyInterface;
    const newRates: ICompanyRateList = rateList.map((r: ICompanyRateItem) => {
        const newRate = list.find((i: ICompanyRateItem) => i.id === r.id);
        return newRate || r;
    });

    CompanyInterface.setCompanyRateList(newRates);
}

export function updateCompanyOperatorListStore(list: ICompanyOperatorList): void {
    const CompanyInterface = CompanyStore.getInstance();
    CompanyInterface.setCompanyOperatorList(list);
}

export function updateCompanyOperatorListStoreForm(list: Array<string> | string): void {
    const CompanyInterface = CompanyStore.getInstance();
    const operatorList = CompanyInterface.operatorList;
    const newList = Array.isArray(list) ? [...list] : [list];
    const newOperatorList = operatorList
        .map((i: ICompanyOperator) => ({ ...i, destroy: true }))
        .map((i: ICompanyOperator) => {
            const foundList = i.operatorId ? newList.indexOf(i.operatorId) : -1;
            if (foundList > -1) {
                newList.splice(foundList, 1);
                return { ...i, destroy: false };
            } else {
                return { ...i };
            }
        });

    newList.forEach((i: string) => {
        newOperatorList.push({ id: globalHexId.getId(), operatorId: i });
    });
    CompanyInterface.setCompanyOperatorList(newOperatorList);
}

function makeCompanySalesRateList(resp: ICompanyGetAPI): ICompanyRateList {
    const includeCSRL = resp.included?.filter((i) => i.type === 'company_sales_rate_list');
    const nameList = resp.included?.filter((i) => i.type === 'sales_rate_list');
    return (
        resp?.data?.relationships?.company_sales_rate_lists?.data?.map((c) => {
            const id = c.id;
            const includeCSRLFind = includeCSRL?.find((i) => i.id === id);
            const dateRange = (includeCSRLFind as IIncludeItemCompanySalesRateAPI)?.attributes;
            const rateId = (includeCSRLFind as IIncludeItemCompanySalesRateAPI)?.relationships?.sales_rate_list?.data
                ?.id;
            const rateFind = nameList?.find((i) => i.id === rateId);
            const rateName = (rateFind as IItemAPI)?.attributes?.name;
            const item: ICompanyRateItem = {
                ...mapApi2UiField(includeCSRLFind?.attributes || {}),
                id,
                rateId,
                rateName,
                startDate: dateRange?.start_date ? dayjs(dateRange?.start_date) : undefined,
                endDate: dateRange?.end_date ? dayjs(dateRange?.end_date) : undefined,
            };
            return item;
        }) || []
    );
}

export function addCompanyRate(item: ICompanyRateItem): void {
    const CompanyInterface = CompanyStore.getInstance();
    CompanyInterface.addRate(item);
}

export function removeCompanyRateById(id: string): void {
    const CompanyInterface = CompanyStore.getInstance();
    CompanyInterface.removeRateById(id);
}

function setCompanyUsersListStore(list: IUser[]): void {
    const CompanyInterface = CompanyStore.getInstance();
    CompanyInterface.setUsersList(list);
}

function makeCompanyUsersList(resp: ICompanyGetAPI): IUser[] {
    const nameList = resp.included?.filter((i) => i.type === 'user');
    const nameTypeList = resp.included?.filter((i) => i.type === 'user_type');
    return (
        resp?.data?.relationships?.users?.data?.map((u) => {
            const id = u.id;
            const nameFind = nameList?.find((i) => i.id === id);
            const userTypeId = (nameFind as IIncludeUserItemAPI)?.relationships?.user_type?.data?.id;
            const userType = (nameTypeList as IItemAPI[])?.find((i) => i.id === userTypeId)?.attributes?.name;

            return {
                id,
                userTypeId,
                userType,
                ...mapApi2UiField(nameFind?.attributes ? nameFind.attributes : {}),
            };
        }) || []
    );
}

function setCompanyCardSetsListStore(list: ICompanyCardSetsList): void {
    const CompanyInterface = CompanyStore.getInstance();
    CompanyInterface.setCardSetsList(list);
}

function makeCompanyCardSetsList(resp: ICompanyGetAPI): ICompanyCardSetsList {
    const assignedCompanyList = resp.included?.filter((i) => i.type === 'assigned_company');
    const cardSetsList = resp.included?.filter((i) => i.type === 'card_set');
    return (
        resp.data?.relationships?.card_sets?.data?.map((l) => {
            const id = l.id;
            const nameFind = (cardSetsList as IIncludeItemCardSetAPI[])?.find((n) => n.id === id);
            const assignedCompanyId = nameFind?.relationships?.assigned_company?.data?.id;
            const assignedCompanyFind = assignedCompanyList?.find((c) => c.id === assignedCompanyId);
            return {
                id,
                name: nameFind?.attributes?.name,
                cardsCount: nameFind?.attributes?.cards_count,
                assignedCompanyId,
                assignedCompany: (assignedCompanyFind as IItemAPI)?.attributes?.name,
            };
        }) || []
    );
}

// function makeItemListFromApi(resp: IBodyItemResponse, type: IIncludeTypeAPI) {
//     // ToDo: 11.08.2021 - need Make Universal
//     const include = resp.included?.filter(i => i.type === type);
//     const relationships = resp.data?.relationships?.[type]?.data;
//     if (relationships && Array.isArray(relationships)) {
//         return (
//             relationships?.map((i: IRelationshipItem) => {
//                 const main = mapApi2UiField(i || {});
//                 const attributes = mapApi2UiField(include?.find(a => a.id === i.id)?.attributes || {});
//                 const okItem = { ...main, ...attributes };
//                 delete okItem.type;
//                 return okItem;
//             }) || []
//         );
//     } else {
//         console.error('Error relationships ->', relationships);
//         return [];
//     }
// }

function makeCompanyOperatorList(resp: ICompanyGetAPI): ICompanyOperatorList {
    const typeCO = 'companies_operators';
    const typeO = 'operator';
    const includeCO = resp.included?.filter((i) => i.type === typeCO);
    const includeO = resp.included?.filter((i) => i.type === typeO);
    const relationships = resp.data?.relationships?.[typeCO]?.data;
    return (
        relationships?.map((i: IRelationshipItem) => {
            const main = mapApi2UiField(i || {});
            const findCO = includeCO?.find((a) => a.id === i.id);
            const attributesCO = mapApi2UiField(findCO?.attributes || {});
            attributesCO.type && delete attributesCO.type;
            const operatorId =
                findCO && (findCO as ICompanyIncludeCompanyOperatorGETAPI).relationships?.operator?.data?.id;
            const findC = includeO?.find((a) => a.id === operatorId);
            const attributesC = mapApi2UiField(findC?.attributes || {});
            attributesC.type && delete attributesC.type;
            return { ...main, ...attributesCO, ...attributesC, id: i.id, operatorId: operatorId };
        }) || []
    );
}

function setBlobIds(apiCompany: ICompanyGetAPI): void {
    const logoBlobId = apiCompany?.data?.relationships?.logo_blob?.data?.id;
    const darkLogoBlobId = apiCompany?.data?.relationships?.dark_logo_blob?.data?.id;

    const CompanyInterface = CompanyStore.getInstance();
    CompanyInterface.setLogoBlobIds({ logoBlobId, darkLogoBlobId });
}

export async function getCompanyById(id: string): Promise<boolean> {
    const { resp, isError } = await apiManager.getApi('GET_COMPANY', { id: id });
    const respApi = resp as ICompanyGetAPI;

    if (!isError && respApi?.data?.id) {
        getCompanyAbout(respApi.data.id).then();
        getCompanySubscription(respApi.data.id).then();
        getFeatureFlagRules().then();
        setCompanyInfoStore(mapAPIToCompanyInfo(respApi));
        setCompanyRateListStore(makeCompanySalesRateList(respApi));
        setCompanyUsersListStore(makeCompanyUsersList(respApi));
        setCompanyCardSetsListStore(makeCompanyCardSetsList(respApi));
        updateCompanyOperatorListStore(makeCompanyOperatorList(respApi) as ICompanyOperatorList);
        setBlobIds(respApi);
    }
    return !isError;
}

export function setCompanyStatistics(statistics: ICompanyStatistics): void {
    const CompanyInterface = CompanyStore.getInstance();
    CompanyInterface.setCompanyStatistics(statistics);
}

export function setCompanySubscribersCsvBlobId(id?: string): void {
    const CompanyInterface = CompanyStore.getInstance();
    CompanyInterface.setCompanySubscribersCsvBlobId(id);
}

export async function getCompanyStatisticsById(id: string): Promise<boolean> {
    const { resp, isError } = await apiManager.getApi('GET_COMPANY_STATISTICS', { id: id });
    const respApi = resp as IBodyItemResponse;
    if (!isError && respApi?.data?.id) {
        const statistics: ICompanyStatistics = {
            id: respApi.data.id,
            companyId: id,
            ...mapApi2UiField(respApi?.data?.attributes),
        };

        setCompanyStatistics(statistics);
    }
    return isError;
}

export async function deleteBlob(id?: string): Promise<boolean> {
    const { isError } = await apiManager.getApi('DELETE_BLOB', { id: id });

    return isError;
}

export function setCompanyForm(form: ICompanyForm): void {
    const { salesRateList, operatorList: _, ...info } = form;
    updateCompanyRateListStore(salesRateList);
    setCompanyInfoStore(info);
}

const makeAPIUniversalRelationships = (list: IIMainUniversalUiItemList, type: IIncludeTypeAPI): IMakeRelationships => {
    const includedList: Array<IMakeRelationshipsInclude> = [];
    const newList = list
        .filter((i: IIMainUniversalUiItem) => !(i.destroy && isNewId(i.id)))
        .map((i: IIMainUniversalUiItem) => {
            const relation: IRelationshipsItemPOSTAPI = {
                id: i.id,
                type: type,
                destroy: i.destroy,
            };
            const includeAttributes = mapUi2ApiField(i);
            delete includeAttributes.id;
            delete includeAttributes.type;
            delete includeAttributes.name;
            delete includeAttributes.destroy;
            const include: IMakeRelationshipsInclude = {
                ...relation,
                type: type,
                attributes: includeAttributes,
            };
            includedList.push(include);
            return relation;
        });
    return { relationships: { [type]: { data: newList } }, included: includedList };
};

export const saveCompany = async (isNew: boolean): Promise<IPatchItem> => {
    const StoreInstance = CompanyStore.getInstance();
    const { id, ...companyInfo } = StoreInstance.companyInfo;

    companyInfo.cdrSquashInterval = intervalUi2API(
        companyInfo.cdrSquashIntervalValue,
        companyInfo.cdrSquashIntervalType,
    );

    delete companyInfo.companyType;
    delete companyInfo.parent;
    delete companyInfo.primaryContact;
    delete companyInfo.cdrSquashIntervalValue;
    delete companyInfo.cdrSquashIntervalType;
    delete companyInfo.cdrSquashIntervalName;

    companyInfo.subscribersCsvBlobId = StoreInstance.companySubscribersCsvBlobId;

    const attributes: ICompanyPostDataAttributesAPI = mapUi2ApiField(companyInfo);

    const companiesRelationshipsRates = makeAPIUniversalRelationships(
        StoreInstance.rateList,
        'apply_company_sales_rate_lists',
    );

    const companiesRelationshipsOperators = makeAPIUniversalRelationships(
        StoreInstance.operatorList,
        'companies_operators',
    );

    const companiesRelationshipsRateInclude: Array<ICompanyIncludeRateAPI> =
        companiesRelationshipsRates?.included?.map((i: ITypeAny) => {
            return {
                ...i,
                attributes: {
                    sales_rate_list_id: i.attributes.rate_id,
                    start_date: i.attributes.start_date
                        ? dayjs(i.attributes.start_date).format(appConfig.formatDate)
                        : undefined,
                    end_date: i.attributes.end_date
                        ? dayjs(i.attributes.end_date).format(appConfig.formatDate)
                        : undefined,
                },
            };
        }) || [];

    const companiesRelationshipsOperatorsIncluded: Array<ICompanyIncludeOperatorPOSTAPI> =
        companiesRelationshipsOperators?.included?.map((i: ITypeAny) => {
            return { ...i };
        }) || [];

    const body: IRequestAPIBody = {
        data: {
            type: 'company',
            attributes: attributes,
            relationships: {
                ...companiesRelationshipsRates.relationships,
                ...companiesRelationshipsOperators.relationships,
            },
        },
        included: [...companiesRelationshipsRateInclude, ...companiesRelationshipsOperatorsIncluded],
    };

    const { resp, isError } = await apiManager.getApi(
        isNew ? 'POST_COMPANY' : 'PATCH_COMPANY',
        isNew ? {} : { id: id },
        body,
    );

    const { errors } = await resp;
    return { isError, errors };
};

export function setCompanyAboutStore(about: IAboutInfo): void {
    const StoreInstance = CompanyStore.getInstance();
    StoreInstance.setAbout(about);
}

export async function getCompanyLogo(slug: string): Promise<ILogoResp> {
    const response = await apiManager.getApi('GET_COMPANY_LOGO', { id: slug });
    const { isError, resp } = response;

    const logos = resp as ILogoRespAPI;
    return (
        isError
            ? {}
            : {
                  logoWhite: logos.logo_url,
                  logoBlack: logos.dark_logo_url,
                  website: logos.website,
                  customLogoLink: logos.custom_logo_link_url,
              }
    ) as ILogoResp;
}

export async function getCompanyAbout(companyId: string): Promise<void> {
    const response = await apiManager.getApi('GET_COMPANY_ABOUT', { id: companyId });
    const { isError, resp } = response;
    if (!isError) {
        const aboutResp = resp as IAboutAPI;
        const about: IAboutInfo = {
            ...mapApi2UiField(aboutResp?.data?.attributes || {}),
        };
        setCompanyAboutStore(about);
    }
}

export async function saveCompanyAbout(id: string, fields: IMapField): Promise<IPatchItem> {
    const apiBody: IRequestAPIBody = {
        data: {
            type: 'about',
            id: id,
            attributes: mapUi2ApiField(fields),
        },
    };

    const { resp, isError } = await apiManager.getApi('PATCH_COMPANY_ABOUT', { id: id }, apiBody);
    const { errors } = await resp;
    return { isError, errors };
}

export async function saveCompanyCustomDocs({
    companyId,
    blobId,
    docType,
    file,
}: ICompanyCustomDocs): Promise<IPatchItem> {
    const apiBody: IRequestAPIBody = {
        data: {
            attributes: {
                name: file?.name,
                key: file?.name,
                domain: docType,
                company_id: companyId,
                document_file_blob_id: blobId,
            },
            type: 'acceptance_document',
        },
    };

    const { resp, isError } = await apiManager.getApi('POST_UPLOAD_ACCEPTANCE_DOCUMENTS', {}, apiBody);
    const { errors } = await resp;
    return { isError, errors };
}

function formatCompanyApiKeyList(list: ICompanyApiKeyListAPI): ICompanyApiKeyList {
    const { api_keys } = list;

    return api_keys.map((value) => {
        return mapApi2UiField(value) as ICompanyApiKeyItem;
    });
}

export async function getCompanyApiKeysList(): Promise<void> {
    const { resp, isError } = await apiManager.getApi('GET_COMPANY_API_KEY');

    if (!isError) {
        const list = resp as ICompanyApiKeyListAPI;
        setCompanyApiKeyList(formatCompanyApiKeyList(list));
    }
}

export async function postCompanyApiKey(): Promise<{ newApiKey: ICompanyApiKeyItem; isError: boolean }> {
    const { resp, isError } = await apiManager.getApi('POST_COMPANY_API_KEY');

    const newApiKey = mapApi2UiField(resp) as ICompanyApiKeyItem;

    return { newApiKey, isError };
}

export async function deleteCompanyApiKey(id: string): Promise<boolean> {
    const { isError } = await apiManager.getApi('DELETE_COMPANY_API_KEY', { id });

    return isError;
}

function formatCompanySubscription(resp: ICompanySubscriptionsGetAPi): ICompanySubscriptionInfo {
    const subscriptionInfo = resp?.user_subscriptions?.[0] as ICompanySubscriptionsGetAPiItem;

    return { ...mapApi2UiField(subscriptionInfo || {}) };
}

export function setCompanySubscription(subscription: ICompanySubscriptionInfo): void {
    const StoreInstance = CompanyStore.getInstance();
    StoreInstance.setSubscription(subscription);
}

export async function getCompanySubscription(id: string): Promise<ICompanySubscriptionInfo> {
    const { resp, isError } = await apiManager.getApi('GET_COMPANY_SUBSCRIPTION', { id });

    const subscriptions = formatCompanySubscription(resp as ICompanySubscriptionsGetAPi);

    if (!isError) {
        setCompanySubscription(subscriptions);
    }

    return subscriptions;
}

export async function createCompanySubscription(
    companyId: string,
    attributes: ICompanySubscriptionsBody,
): Promise<boolean> {
    const apiBody = {
        user_subscription: {
            ...attributes,
        },
    } as unknown as IRequestAPIBody;

    const { isError } = await apiManager.getApi('CREATE_COMPANY_SUBSCRIPTION', { id: companyId }, apiBody);

    return isError;
}

export async function editCompanySubscription(
    companyId: string,
    subscriptionId: string,
    attributes: ICompanySubscriptionsBody,
): Promise<boolean> {
    const apiBody = {
        user_subscription: {
            ...attributes,
        },
    } as unknown as IRequestAPIBody;

    const { isError } = await apiManager.getApi(
        'UPDATE_COMPANY_SUBSCRIPTION',
        { id: companyId, subscriptionId },
        apiBody,
    );

    return isError;
}

export const postCompanySubscriptionOrder = async (
    subscriptionId: string,
    attributes: ICompanySubscriptionsBody,
): Promise<IPostItem> => {
    const body: IRequestAPIBody = {
        data: {
            type: 'order',
            attributes: mapUi2ApiField(attributes),
        },
    };

    const { resp, isError } = await apiManager.getApi('POST_COMPANY_SUBSCRIPTION_ORDER', { subscriptionId }, body);
    const response = resp as IBodyItemResponse;
    const { errors } = response;
    return { isError, errors, resp: response };
};

export const deleteCompanySubscription = async (companyId: string, subscriptionId: string): Promise<boolean> => {
    const { isError } = await apiManager.getApi('DELETE_COMPANY_SUBSCRIPTION', { id: companyId, subscriptionId });

    return isError;
};

export function getCompanyRelationSelect(): void {
    cleanApiMessages();
    getCatalogsCompanyTypeSelect().then();
    getCatalogsPaymentTypeSelect().then();
    getCompaniesParents().then();
    getCountriesSelect().then();
    const isCompanyTypeIdMNOHost = getProfileIsCompanyTypeIdMNOHost();
    if (!isCompanyTypeIdMNOHost) {
        getCurrenciesSelect().then();
    }
    getRatesSelect().then();
    getOperatorsSelect().then();
}

export function getCompanyRelationSelectById(id: string): void {
    getUsersByCompanySelect(id).then();
    getCompanyRelationSelect();
    getAuthAcceptanceDocuments(id).then();
}

export function cleanCompanyRelationSelect(): void {
    cleanApiMessages();
    cleanCatalogsStore();
    cleanCompaniesStore();
    cleanCountriesStore();
    cleanCurrenciesStore();
    cleanRatesStore();
}

export function clearCompanyStatistics(): void {
    const CompanyInterface = CompanyStore.getInstance();
    CompanyInterface.clearCompanyStatistics();
}

export function cleanCompanyStore(): void {
    const CompanyInterface = CompanyStore.getInstance();
    CompanyInterface.cleanStore();
}
