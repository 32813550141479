import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Catcher, TitleCard } from '../';
import { ITitleMainInfo } from '../../interfaces';
import { usePagesStoreCurrentComponent, useRouterStoreUseExternalPageTitleManager } from '../../hooks';
import { setRouterStoreUseExternalPageTitleManager } from '../../middleware';
import { ITrPage, tI, tP } from '../../translate';

export const TitleMainInfo: React.FC<ITitleMainInfo> = observer(
    ({ title, name, isEdit, icon, isNotDocumentTitle, OtherButton, dataTest = '' }: ITitleMainInfo) => {
        const { title: routeTitle, params } = usePagesStoreCurrentComponent();
        const isUseExternalPageTitleManager = useRouterStoreUseExternalPageTitleManager();
        const showRouteName = title ? title : (routeTitle as ITrPage);
        const showId = params?.id ? `#${params.id}` : '';
        const showLeft = name ? '' : showRouteName ? tP(showRouteName) : '';
        const showTitle = name ? name : showId;
        const showRight = isEdit && showLeft !== routeTitle ? ` (${tI('Edit')})` : ``;

        useEffect(() => {
            setRouterStoreUseExternalPageTitleManager(true);

            return (): void => {
                setRouterStoreUseExternalPageTitleManager(false);
            };
        }, []);

        const documentTitle = showLeft + showTitle + showRight;
        if (!isNotDocumentTitle && isUseExternalPageTitleManager && document.title !== documentTitle) {
            document.title = documentTitle;
        }

        return (
            <Catcher>
                <TitleCard
                    icon={icon}
                    isMainCard
                    left={showLeft}
                    OtherButton={OtherButton}
                    right={showRight}
                    title={showTitle}
                    dataTest={dataTest}
                />
            </Catcher>
        );
    },
);
