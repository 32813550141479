import React from 'react';
import { observer } from 'mobx-react';
import { Catcher } from '../../';
import { useDashboardOverlayView } from '../../../hooks';

export const OverlayView: React.FC = observer(() => {
    const overlayView = useDashboardOverlayView();

    return <Catcher>{overlayView}</Catcher>;
});
