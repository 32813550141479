import React from 'react';
import { observer } from 'mobx-react';
import { Checkbox, Collapse, Form } from 'antd';
import { Rule } from 'antd/es/form';
import { CardLayout, cardLayoutOptions } from '../../Layout';
import { CardInfoToolBar } from '../../SubComponents';
import { IBodyItemResponse, IEsNotificationsConfirmOrderBody } from '../../../interfaces';
import { useUiIsSpinnerFound } from '../../../hooks';
import { addNotification, apiMessagesGetFormRules, postEsNotificationsConfirmOrder } from '../../../middleware';
import { Catcher } from '../../Catcher';
import { InputDebounce } from '../../Forms';

const { Panel } = Collapse;

export const Es2ConfirmOrderForm: React.FC = observer(() => {
    const [form] = Form.useForm();
    const isLoading = useUiIsSpinnerFound(['POST_ES2_PLUS_CONFIRM_ORDER']);

    const ruleRequired: Array<Rule> = [{ required: true, message: 'Required' }, ...apiMessagesGetFormRules];

    const onFinish = async (fields: any): Promise<void> => {
        const body = {
            header: {
                functionCallIdentifier: fields.functionCallIdentifier,
                functionRequesterIdentifier: fields.functionRequesterIdentifier,
            },
            iccid: fields.iccid,
            eid: fields.eid,
            matchingId: fields.matchingId,
            releaseFlag: !!fields.releaseFlag,
            confirmationCode: fields.confirmationCode,
            smdsAddress: fields.smdsAddress,
        } as IEsNotificationsConfirmOrderBody;

        const { isError, resp } = await postEsNotificationsConfirmOrder(body);

        const { header, ReasonCode } = resp as IBodyItemResponse;

        if (isError) {
            addNotification({
                type: 'error',
                message: 'Error!',
                description: `Something went wrong`,
                duration: 5,
            });

            return;
        }

        if (ReasonCode) {
            addNotification({
                type: 'error',
                message: 'Error!',
                description: JSON.stringify(header),
                duration: 10,
            });
        } else {
            addNotification({
                type: 'success',
                message: 'Confirmed',
                description: `Your order has been confirmed successfully`,
                duration: 5,
            });
        }
    };

    return (
        <Catcher>
            <CardLayout
                title={'Confirm Order'}
                idSpinners={['POST_ES2_PLUS_CONFIRM_ORDER']}
                dataTest={'EditCompanyContactInfo'}
                columnOption={cardLayoutOptions.cardEditCompany}
            >
                <Form
                    form={form}
                    name="confirm_order"
                    initialValues={{ functionCallIdentifier: 'TX-567', functionRequesterIdentifier: '2' }}
                    onFinish={onFinish}
                    scrollToFirstError={true}
                    layout={'vertical'}
                    className={'formStyle'}
                >
                    <Form.Item name="confirmationCode" label={'confirmationCode'}>
                        <InputDebounce autoComplete={'off'} placeholder={'confirmationCode'} isFirstInput />
                    </Form.Item>
                    <Form.Item name="eid" label={'eid'}>
                        <InputDebounce autoComplete={'off'} placeholder={'eid'} />
                    </Form.Item>
                    <Form.Item name="iccid" label={'iccid'} rules={ruleRequired}>
                        <InputDebounce autoComplete={'off'} placeholder={'iccid'} />
                    </Form.Item>
                    <Form.Item name="matchingId" label={'matchingId'} rules={ruleRequired}>
                        <InputDebounce autoComplete={'off'} placeholder={'matchingId'} />
                    </Form.Item>
                    <Form.Item name="smdsAddress" label={'smdsAddress'}>
                        <InputDebounce autoComplete={'off'} placeholder={'smdsAddress'} />
                    </Form.Item>
                    <Collapse style={{ marginTop: '10px', marginBottom: '10px' }} bordered={false}>
                        <Panel key={'1'} header={'Headers'}>
                            <Form.Item name="functionCallIdentifier" label={'functionCallIdentifier'}>
                                <InputDebounce autoComplete={'off'} placeholder={'functionCallIdentifier'} />
                            </Form.Item>
                            <Form.Item name="functionRequesterIdentifier" label={'functionRequesterIdentifier'}>
                                <InputDebounce autoComplete={'off'} placeholder={'functionRequesterIdentifier'} />
                            </Form.Item>
                        </Panel>
                    </Collapse>
                    <Form.Item name="releaseFlag" valuePropName="checked">
                        <Checkbox>Release flag</Checkbox>
                    </Form.Item>
                    <CardInfoToolBar
                        isLoading={isLoading}
                        isHideCancel={true}
                        isSubmitButton={true}
                        labelSave={'Confirm'}
                    />
                </Form>
            </CardLayout>
        </Catcher>
    );
});
