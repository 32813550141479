import React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import { Catcher, LinkComponent, LoadingApiByIds, TableItemToolbar, columnToolbar } from '../../';
import { IPackageInfo, IPackagesTable } from '../../../interfaces';
import { usePackagesForSale, usePagesStoreThisListRight } from '../../../hooks';
import { deletePackage } from '../../../middleware';
import { tF, tI } from '../../../translate';

export const TableForSales: React.FC<IPackagesTable> = observer(({ pageSize, pageNumber, search }: IPackagesTable) => {
    const right = usePagesStoreThisListRight();
    const data = usePackagesForSale();

    const tableColumns = [
        {
            title: tF('Package Name'),
            dataIndex: 'packageLink',
        },
        {
            title: tF('Offered By'),
            dataIndex: 'companyLink',
        },
        {
            title: tF('Price'),
            dataIndex: 'price',
        },
        {
            title: tF('Currency'),
            dataIndex: 'currencyName',
        },
        {
            title: tF('Data volume'),
            dataIndex: 'dataVolumeName',
        },
        {
            title: tF('Duration'),
            dataIndex: 'monthDurationName',
        },
        {
            title: tF('Zone'),
            dataIndex: 'zoneLink',
        },
        {
            title: tF('Credit Remains'),
            dataIndex: 'creditRemainsName',
        },
        columnToolbar,
    ];

    const tableData = data?.map((p: IPackageInfo) => {
        const params = {
            searchParamsList: {
                pageNumber,
                pageSize,
                search,
            },
        };
        const deleteItem = (): void => {
            deletePackage(p.id, params);
        };

        return {
            key: p.id,
            ...p,
            packageLink: <LinkComponent id={p.id} name={p.name} currentListComponent={'PackagesSell'} />,
            companyLink: <LinkComponent currentListComponent={'CompaniesList'} id={p.companyId} name={p.companyName} />,
            zoneLink: <LinkComponent currentListComponent={'ZonesList'} id={p.zoneId} name={p.zoneName} />,
            toolbar: (
                <TableItemToolbar
                    {...right}
                    itemName={`"${p.name ? p.name : '# ' + p.id} ${tI('package')}`}
                    id={p.id}
                    currentListComponent={'PackagesSell'}
                    deleteItem={deleteItem}
                />
            ),
        };
    });

    return (
        <Catcher>
            <div>
                <LoadingApiByIds idSpinners={['GET_PACKAGES']} />
                <Table
                    className={'table-theme'}
                    columns={tableColumns}
                    dataSource={tableData}
                    pagination={false}
                    size={'middle'}
                />
            </div>
        </Catcher>
    );
});
