import React from 'react';
import { Col } from 'antd';
import { Catcher, LoadingApi, LoadingApiByIds, TitleMainInfo } from '../';
import { cardLayoutOptions } from './cardLayoutOptions';
import { ICardMainLayout } from '../../interfaces';
import Styles from '../styles/m_itemCardStyles.less';

export const CardMainLayout: React.FC<ICardMainLayout> = ({
    children,
    columnOption,
    dataTest = '',
    icon,
    idSpinners,
    isEdit,
    isNotDocumentTitle,
    name,
    OtherButton,
    title,
}: ICardMainLayout) => {
    return (
        <Catcher>
            <Col
                {...(columnOption
                    ? columnOption
                    : isEdit
                    ? cardLayoutOptions.cardInfoEdit
                    : cardLayoutOptions.cardInfo)}
            >
                <section className={Styles.cardLayout}>
                    {!idSpinners ? (
                        <LoadingApi />
                    ) : idSpinners.length ? (
                        <LoadingApiByIds idSpinners={idSpinners} />
                    ) : null}
                    <TitleMainInfo
                        dataTest={dataTest}
                        icon={icon}
                        isEdit={isEdit}
                        isNotDocumentTitle={isNotDocumentTitle}
                        name={name}
                        OtherButton={OtherButton}
                        title={title}
                    />
                    <article className={Styles.cardContent}>{children ? children : null}</article>
                </section>
            </Col>
        </Catcher>
    );
};
