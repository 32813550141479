import React from 'react';
import { Catcher, ShowRawDataInfo } from '../';
import cx from 'classnames';
import { tNM } from '../../translate';
import Styles from '../styles/m_viewStyles.less';

type IResponsesTableDataProps = {
    data?: any;
    isLight?: boolean;
};

export const TableRawDataField: React.FC<IResponsesTableDataProps> = ({ data, isLight }: IResponsesTableDataProps) => {
    const isError = !!data?.error;
    return (
        <Catcher>
            <div className={Styles.dataContext}>
                {data ? (
                    <>
                        <div className={Styles.iconPlace}>
                            <ShowRawDataInfo transactionInfo={data} isError={isError} isLight={isLight} />
                        </div>
                        <div className={cx(Styles.type, isError ? Styles.errorType : Styles.okType)}>
                            {tNM(isError ? 'Error!' : 'Data')}:
                        </div>
                        <div className={Styles.shortDataContext}>{JSON.stringify(data)}</div>
                    </>
                ) : null}
            </div>
        </Catcher>
    );
};
