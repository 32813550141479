import React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import { TablePaginationConfig } from 'antd/es/table';
import { PhoneOutlined } from '@ant-design/icons';
import { CardLayout, Catcher, LinkComponent, SkyPaginationTotal } from '../../';
import { appConfig } from '../../../settings';
import { IColumnOptionComponent, ICompanyOperator } from '../../../interfaces';
import { useCompanyOperatorList } from '../../../hooks';
import { ITrPage, tF } from '../../../translate';

export const ShowCompanySubscriberVisibilityGrantedTable: React.FC<IColumnOptionComponent> = observer(
    ({ columnOption }: IColumnOptionComponent) => {
        const pageSize = appConfig.pageDefaultParams.includeViewPageSize;
        const list = useCompanyOperatorList();
        const tableColumns = [
            {
                title: tF('Operator'),
                dataIndex: 'operator',
            },
        ];
        const tableData = list.map((o: ICompanyOperator) => {
            return {
                key: o.id,
                operator: <LinkComponent currentListComponent={'OperatorsList'} id={o.operatorId} name={o.name} />,
            };
        });

        const pagination: false | TablePaginationConfig =
            pageSize > list.length
                ? false
                : {
                      total: list.length,
                      pageSize: pageSize,
                      className: 'customPagination',
                      showSizeChanger: false,
                      size: 'default',
                      showTotal: SkyPaginationTotal,
                  };
        return (
            <Catcher>
                <CardLayout
                    columnOption={columnOption}
                    count={list.length}
                    icon={<PhoneOutlined />}
                    idSpinners={['GET_COMPANY']}
                    isTable
                    title={'Subscriber Visibility granted' as ITrPage} // ToDo: 04.08.2021 - Translate
                    dataTest={'ShowCompany'}
                >
                    <Table
                        className={'table-theme'}
                        columns={tableColumns}
                        dataSource={tableData}
                        pagination={pagination}
                        size={'small'}
                    />
                </CardLayout>
            </Catcher>
        );
    },
);
