import React, { ReactText, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import { TablePaginationConfig } from 'antd/es/table';
import { DeleteOutlined } from '@ant-design/icons';
import { CardChangeSubscriberStatus } from './CardChangeSubscriberStatus';
import dayjs from 'dayjs';
import { appConfig, isCompanyTypeIdIsRight } from '../../../settings';
import {
    ICardBulkDeletionInfo,
    IChangeStatusCommands,
    IGetApiResponse,
    IGetApiType,
    IIncludeCombinationCardSetCardItem,
    IShowCardColumn,
} from '../../../interfaces';
import {
    useCardSetInfo,
    useIncludeCombinationCardSetCards,
    useIncludedCombinationCardSetTotalAmount,
    useProfileIsRight,
    useRouterStore,
    useUiPageSize,
} from '../../../hooks';
import {
    addNotification,
    deleteCards,
    formatDeletionInfo,
    getCardSet,
    getCardSetCombination,
    setUiLastViewComponentName,
    setUiPageSizeFromLocalStorage,
} from '../../../middleware';
import { byteRange1000 } from '../../../instruments';
import { tCustom, tF, tI, tNM } from '../../../translate';
import {
    CardLayout,
    Catcher,
    ChangeStatusToolBar,
    DownloadFilesButtons,
    FilterPageSize,
    LinkComponent,
    RemoveButton,
    SkyPaginationTotal,
    UploadFile,
    notSelectItem,
} from '../../../Components';
import ViewStyles from '../../styles/m_viewStyles.less';
import Styles from './m_styles.less';

export const CombinationCardSetCardShow: React.FC<IShowCardColumn> = observer(({ columnOption }: IShowCardColumn) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    const [selectedStatus, setSelectedStatus] = useState<IChangeStatusCommands | undefined>();
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [isNotFirstRender, setIsNotFirstRender] = useState<boolean>(false);
    const pageSize: number = useUiPageSize();
    const isLord = useProfileIsRight('LORD');
    const routerStore = useRouterStore();
    const isCompanyRight = isCompanyTypeIdIsRight('1');
    const cardSetInfo = useCardSetInfo();
    const componentSpinners: IGetApiType[] = ['GET_CARD_SET_COMBINATION'];
    const list = useIncludeCombinationCardSetCards();
    const total = useIncludedCombinationCardSetTotalAmount();
    const { geolocationEnabled } = useCardSetInfo();
    const totalPageNumber = Math.ceil(total / pageSize);

    const tableColumns = [
        {
            title: tF('Name'),
            dataIndex: 'nameLink',
        },
        {
            title: tF('Id'),
            dataIndex: 'shortIdLink',
        },
        {
            title: tF('Usage this month'),
            dataIndex: 'dataUnits',
            className: ViewStyles.columnRight,
        },
        {
            title: tF('Active identities'),
            dataIndex: 'subscribers',
            className: ViewStyles.columnRight,
        },
        {
            title: tF('Start Date'),
            dataIndex: 'startDate',
            width: 180,
            className: ViewStyles.columnRight,
        },
        {
            title: tF('End Date'),
            dataIndex: 'endDate',
            width: 180,
            className: ViewStyles.columnRight,
        },
        geolocationEnabled
            ? {
                  title: 'Geolocation',
                  dataIndex: 'geolocationEnabled',
                  className: ViewStyles.columnCenter,
              }
            : {},
    ];

    const onSelectChange = (selectItems: ReactText[]): void => {
        // const newKeys = [...selectItems.map(i => i.toString())];
        // const newArr = newKeys.concat(selectedRowKeys.filter(item => newKeys.indexOf(item) < 0));
        setSelectedRowKeys(selectItems.map((i) => i.toString()));
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const changeStatus = (command: IChangeStatusCommands): void => {
        setSelectedStatus(command);
    };

    const onFinishStatusEdit = (): void => {
        setSelectedRowKeys([]);
        setSelectedStatus(undefined);

        addNotification({
            type: 'success',
            message: 'Cards status has been changed successfully!',
        });
    };

    const showDeletionResultNotification = ({
        isError,
        destroyedCount,
        notDestroyedCount,
        notDestroyedNameList,
        totalCount,
    }: ICardBulkDeletionInfo): void => {
        if (isError) {
            addNotification({
                type: 'error',
                message: 'An error has occurred while deleting cards',
                isUsersError: true,
            });
        } else if (notDestroyedCount === totalCount || destroyedCount <= 0) {
            addNotification({
                type: 'error',
                message: 'Deletion error!',
                description: `No cards have been deleted. EIDs not present in this cardset.`,
                isUsersError: true,
            });
        } else if (destroyedCount === totalCount && notDestroyedCount <= 0) {
            addNotification({
                type: 'success',
                message: tNM('Deleted successfully!'),
                description: tCustom('All cards have been successfully deleted', { amount: destroyedCount }),
                isTranslated: true,
            });
        } else if (notDestroyedCount !== totalCount && destroyedCount !== totalCount) {
            addNotification({
                type: 'warning',
                message: tNM('Partially deleted!'),
                description: tCustom(
                    'Cards have been deleted. Cards have not been deleted since these are not included in this cardset',
                    { amount: destroyedCount, total: totalCount, cardList: `"${notDestroyedNameList}"` },
                ),
                duration: 10,
                isTranslated: true,
            });
        }
    };

    const fetchCardSetCombination = async (): Promise<void> => {
        await getCardSetCombination(routerStore.routerState.params.id, { pageNumber, pageSize });
    };

    const fetchAfterDelete = async (isError: boolean): Promise<void> => {
        if (!isError) {
            if (totalPageNumber === pageNumber && selectedRowKeys.length === list.length && totalPageNumber > 1) {
                return setPageNumber((prevState) => prevState - 1);
            }

            await Promise.all([getCardSet(cardSetInfo.id), fetchCardSetCombination()]);
        }
    };

    const onManualDelete = async (): Promise<void> => {
        const response = await deleteCards(selectedRowKeys, cardSetInfo?.id);
        const deletionInfo = formatDeletionInfo(response);

        showDeletionResultNotification(deletionInfo);

        await fetchAfterDelete(response?.isError);
    };

    const onDownloadDelete = async (apiResponse: IGetApiResponse): Promise<void> => {
        const deletionInfo = formatDeletionInfo(apiResponse);

        showDeletionResultNotification(deletionInfo);

        await fetchAfterDelete(apiResponse?.isError);
    };

    const onSetFile = (file: File): void => {
        // console.log(file);
    };

    const onPageChange = (page: number, pageSize?: number | undefined): void => {
        setPageNumber(page);
    };

    useEffect(() => {
        setUiPageSizeFromLocalStorage('CardSet');
        setUiLastViewComponentName('CardSet');
        setIsNotFirstRender(true);
    }, []);

    useEffect(() => {
        if (isNotFirstRender) {
            setSelectedRowKeys([]);
            fetchCardSetCombination();
        }
    }, [pageNumber, pageSize, isNotFirstRender]);

    const tableData = list.map((r: IIncludeCombinationCardSetCardItem) => {
        const key = r.cardId;
        return {
            ...r,
            key,
            nameLink: <LinkComponent currentListComponent={'CardList'} id={r.cardId} name={r.name} />,
            shortIdLink: <LinkComponent currentListComponent={'CardList'} id={r.cardId} name={r.shortId} />,
            dataUnits: byteRange1000(r.dataUnits),
            subscribers: `${r.activeSubscribersCount} / ${r.subscribersCount}`,
            startDate: r.startDate?.isValid() ? dayjs(r.startDate).format(appConfig.formatDate) : undefined,
            endDate: r.endDate?.isValid() ? dayjs(r.endDate).format(appConfig.formatDate) : tI('Never'),
            geolocationEnabled: `${r.geolocationEnabled ? tF('Enabled') : tF('Disabled')}`,
        };
    });

    const pagination: false | TablePaginationConfig =
        pageSize > total
            ? false
            : {
                  total: total,
                  pageSize: pageSize,
                  className: 'customPagination',
                  showSizeChanger: false,
                  size: 'default',
                  current: pageNumber,
                  showTotal: SkyPaginationTotal,
                  onChange: onPageChange,
              };

    const DownloadCSVFlow = (): JSX.Element => {
        return <DownloadFilesButtons csvType={'GET_CARD_SET_CSV'} id={cardSetInfo?.id} />;
    };

    return (
        <Catcher>
            <CardLayout
                columnOption={columnOption}
                count={total}
                idSpinners={componentSpinners}
                isTable
                extraTitleComponent={
                    <div className={Styles.combinedPageSize}>
                        <FilterPageSize />
                    </div>
                }
                OtherButton={<DownloadCSVFlow />}
                title={'Cards'}
                titleToolBar={
                    <div className={Styles.combinedToolBar}>
                        <ChangeStatusToolBar
                            changeStatus={changeStatus}
                            disabled={!selectedRowKeys.length}
                            disabledHint={notSelectItem}
                            statuses={
                                [isCompanyRight && isLord && 'activate', 'block', 'unblock'].filter(
                                    Boolean,
                                ) as IChangeStatusCommands[]
                            }
                        />
                        {isLord ? (
                            <div className={Styles.deleteButtons}>
                                <RemoveButton
                                    disabled={!selectedRowKeys.length}
                                    disabledHint={notSelectItem}
                                    icon={<DeleteOutlined />}
                                    onClick={onManualDelete}
                                    title="Delete"
                                    type="primary"
                                />
                                <UploadFile
                                    apiType={'DELETE_CARDS_CSV'}
                                    setFile={onSetFile}
                                    onDownload={onDownloadDelete}
                                    idSpinners={['DELETE_CARDS', 'DELETE_CARDS_CSV']}
                                    id={cardSetInfo?.id}
                                    text={'CSV Delete'}
                                />
                            </div>
                        ) : null}
                    </div>
                }
            >
                <Table
                    className={'table-theme'}
                    columns={tableColumns}
                    dataSource={tableData}
                    pagination={pagination}
                    rowSelection={rowSelection}
                    size={'middle'}
                />
                {!!selectedRowKeys && selectedStatus ? (
                    <CardChangeSubscriberStatus
                        cardIds={selectedRowKeys}
                        onCancel={() => setSelectedStatus(undefined)}
                        onFinishStatusEdit={onFinishStatusEdit}
                        status={selectedStatus}
                    />
                ) : null}
            </CardLayout>
        </Catcher>
    );
});
