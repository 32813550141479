import React from 'react';
import { Catcher } from '../../';
import cx from 'classnames';
import { IDownloadFile } from '../../../settings';
import { ITrField, tF } from '../../../translate';
import Styles from '../../styles/m_itemCardStyles.less';

export const DownloadFile: React.FC<IDownloadFile> = ({
    title,
    file,
    fileLabel = 'Download',
    enableDownload = true,
    target,
}: IDownloadFile) => {
    const showTitle = tF(title as ITrField);
    const showFileLabel = tF(fileLabel as ITrField);
    return (
        <Catcher>
            <div className={cx(Styles.downloadCard, Styles.itemBlock)}>
                <div className={Styles.title}>{showTitle}</div>
                <a href={file} download={enableDownload} className={Styles.url} target={target}>
                    {showFileLabel}
                </a>
            </div>
        </Catcher>
    );
};
