import { action, makeObservable, observable, reaction } from 'mobx';
import { UiStore } from './ui';
import { appConfig } from '../settings';
import { ICardListItem, IFilterParams, ISelect } from '../interfaces';
import { getCardList, getCardListCount, setUiTotal } from '../middleware';
import { apiParamsCleaner } from '../instruments';

export class CardsStore {
    private constructor() {
        makeObservable(this);
        reaction(
            () => this.forcedUpdateTime,
            () => {
                const UiStoreInstance = UiStore.getInstance();
                const params = UiStoreInstance.params;
                const cleanParams = apiParamsCleaner(params, this.filterParams);
                getCardList(cleanParams);
                getCardListCount(cleanParams);
            },
            {
                delay: appConfig.APIDelay,
            },
        );
    }

    private static instance: CardsStore;
    public static getInstance(): CardsStore {
        if (!CardsStore.instance) {
            CardsStore.instance = new CardsStore();
        }
        return CardsStore.instance;
    }

    @observable cardsList: ICardListItem[] = [];
    @action setCardsList(cardsList: ICardListItem[]): void {
        this.cardsList = cardsList;
    }

    @observable forcedUpdateTime = 0;
    @action setForcedUpdateTime(): void {
        this.forcedUpdateTime = Date.now();
    }

    // ------------ List params ------------
    @observable filterParams: IFilterParams = {
        pageNumber: true,
        pageSize: true,
        search: true,
        cardSetId: true,
    };

    @observable select: ISelect[] = [];
    @action setSelect(select: ISelect[]): void {
        this.select = select;
    }
    @action cleanStore(): void {
        this.cardsList = [];
        this.select = [];
        setUiTotal(0);
    }
}
