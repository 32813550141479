import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import {
    IEsNotificationsList,
    IEsNotificationsLogsList,
    IEsNotificationsQrcodesList,
    IFilterParams,
} from '../../interfaces';

export function useEsNotificationsTable(): IEsNotificationsList {
    return useContext(MobXProviderContext).RootStore.esNotificationsStore.list;
}

export function useEsNotificationsQrcodesTable(): IEsNotificationsQrcodesList {
    return useContext(MobXProviderContext).RootStore.esNotificationsStore.qrcodesList;
}

export function useEsNotificationsLogsTable(): IEsNotificationsLogsList {
    return useContext(MobXProviderContext).RootStore.esNotificationsStore.logsList;
}

export function useEsNotificationsFilterParams(): IFilterParams {
    return useContext(MobXProviderContext).RootStore.esNotificationsStore.filterParams;
}
