import React from 'react';
import cx from 'classnames';
import { IIncludeCombinationCardSetCardLocationItem } from '../../../interfaces';
import { dateText2Utc, getMarkerName } from '../../../instruments';
import Styles from '../m_googleMapsStyles.less';

interface IGoogleCardSetLocationMapShowMarker {
    marker: IIncludeCombinationCardSetCardLocationItem;
}

export const ShowCardsSetLocationMarkerInfo = ({ marker }: IGoogleCardSetLocationMapShowMarker): JSX.Element => {
    const name = getMarkerName(marker);
    const signal = JSON.stringify(marker.deviceInfo?.signal);
    return (
        <div className={Styles.overlayView}>
            <div className={Styles.overlayViewContent}>
                <div className={Styles.overlayViewContentTitle} title={JSON.stringify(marker)}>
                    {name}
                </div>
                {marker.deviceInfo?.accuracy ? (
                    <div className={Styles.overlayViewContentItem}>accuracy: {marker.deviceInfo?.accuracy}</div>
                ) : null}
                {marker.deviceInfo?.cid ? (
                    <div className={Styles.overlayViewContentItem}>cid: {marker.deviceInfo?.cid}</div>
                ) : null}
                {marker.deviceInfo?.lac ? (
                    <div className={Styles.overlayViewContentItem}>lac: {marker.deviceInfo?.lac}</div>
                ) : null}
                {marker.deviceInfo?.signal ? (
                    <div
                        className={cx(Styles.overlayViewContentItem, Styles.overlayViewContentItemLong)}
                        title={signal}
                    >
                        signal: {signal}
                    </div>
                ) : null}
                {marker.deviceInfo?.createdAt ? (
                    <div className={Styles.overlayViewContentItem}>
                        created at: {dateText2Utc(marker.deviceInfo?.createdAt)}
                    </div>
                ) : null}
            </div>
        </div>
    );
};
