import React, { useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';
import { ChartWrapperOptions } from 'react-google-charts/dist/types';
import { observer } from 'mobx-react';
import { Empty } from 'antd';
import { IChartData } from '../../../interfaces';
import { getCartColorTheme, useChartsSettings } from '../../../hooks';
import { Catcher } from '../../Catcher';
import { ChartTypeSelector } from './../Components';
import Styles from '../m_dashboarStyles.less';

interface IBarChartProps {
    sourceData: IChartData;
    title?: string;
    titleLink?: JSX.Element | null;
}

type IChartType = 'ComboChart' | 'LineChart';

export const VerticalRevenueChart: React.FC<IBarChartProps> = observer(
    ({ sourceData, title = '', titleLink = null }: IBarChartProps) => {
        const [selectedChartType, setSelectedChartType] = useState('bar');
        const [chartType, setChartType] = useState<IChartType>('ComboChart');
        const { animation, backgroundColor, chartBackground, hAxis, textColor, vAxis } = useChartsSettings();

        const optionsBar = {
            backgroundColor: chartBackground,
            seriesType: 'bars',
            legend: {
                position: 'top',
                maxLines: 2,
                textStyle: { color: textColor },
            },
            chartArea: {
                width: '85%',
                height: '75%',
            },
            colors: getCartColorTheme(sourceData),
            hAxis,
            vAxis,
            animation,
        };

        const [options, setOptions] = useState<Partial<ChartWrapperOptions>>(optionsBar);

        useEffect(() => {
            switch (selectedChartType) {
                case 'line':
                    setChartType('LineChart');
                    setOptions({
                        backgroundColor: chartBackground,
                        legend: {
                            position: 'top',
                            maxLines: 2,
                            textStyle: { color: textColor },
                        },
                        chartArea: {
                            width: '85%',
                            height: '75%',
                        },
                        colors: getCartColorTheme(sourceData),
                        hAxis,
                        vAxis: {
                            ...vAxis,
                            minValue: 0,
                        },
                        animation,
                    });
                    break;
                default:
                    setChartType('ComboChart');
                    setOptions(optionsBar);
                    break;
            }
        }, [selectedChartType, backgroundColor]);

        return (
            <Catcher>
                <div className={Styles.chartTitle}>{titleLink ? titleLink : title}</div>
                <div className={Styles.chartTypeSelector}>
                    <ChartTypeSelector
                        defaultChart={selectedChartType}
                        onChangeChartType={setSelectedChartType}
                        availableTypes={['bar', 'line']}
                    />
                </div>

                {sourceData.length < 2 ? (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                ) : (
                    <Chart data={sourceData} height={'100%'} width={'100%'} chartType={chartType} options={options} />
                )}
            </Catcher>
        );
    },
);
