import React from 'react';
import { observer } from 'mobx-react';
import { Col } from 'antd';
import { Catcher, LoadingApiByIds, cardLayoutOptions } from '../';
import { IGetApiType } from '../../REST';
import { usePagesStoreCurrentComponent, useUiTotal } from '../../hooks';
import { ITrPage, tP } from '../../translate';
import Styles from '../styles/m_viewStyles.less';

type IListTitle = {
    LeftButtons?: React.FC;
    idSpinners?: IGetApiType[];
};

export const ListTitle: React.FC<IListTitle> = observer(({ LeftButtons, idSpinners = [] }: IListTitle) => {
    const total = useUiTotal();
    const { title } = usePagesStoreCurrentComponent();
    const titleShow = title ? tP(title as ITrPage) : '';

    return (
        <Catcher>
            <Col {...cardLayoutOptions.listTitleTitle} className={Styles.title} data-test="ListTitle">
                {LeftButtons ? <LeftButtons /> : null}
                {titleShow}
                <div className={Styles.counter}>
                    <LoadingApiByIds idSpinners={idSpinners} onlySpinner />({total})
                </div>
            </Col>
        </Catcher>
    );
});
