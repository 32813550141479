import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Checkbox, Form, Input } from 'antd';
import { InputProps } from 'antd/es/input';
import { Rule } from 'rc-field-form/es/interface';
import { formatDataTestAttribute } from '../../../instruments/testing';

interface ISelectWithCheckbox {
    initCheckValue?: boolean | undefined;
    inputProps?: InputProps;
    checkBoxName?: string;
    checkBoxLabel?: string;
    validatorsArr?: Array<Rule>;
    elementName: string;
    dataTest?: string;
}

export const InputWithCheckbox: React.FC<ISelectWithCheckbox> = observer(
    ({
        checkBoxName,
        checkBoxLabel,
        initCheckValue,
        validatorsArr,
        inputProps,
        elementName,
        dataTest = '',
    }: ISelectWithCheckbox) => {
        const [isDisabled, setIsDisabled] = useState<boolean | undefined>(initCheckValue);
        const validation: Array<Rule> | undefined = !isDisabled ? validatorsArr : undefined;

        const handleCheck = (): void => {
            setIsDisabled(!isDisabled);
        };

        const handleUpdate = (value: boolean | undefined): void => {
            setIsDisabled(value);
        };

        useEffect(() => {
            handleUpdate(initCheckValue);
        }, [initCheckValue]);

        return (
            <>
                <Form.Item rules={validation} label={inputProps?.placeholder} name={elementName}>
                    <Input
                        {...inputProps}
                        disabled={isDisabled}
                        data-test={formatDataTestAttribute(dataTest, 'InputWithCheckbox', 'Input')}
                    />
                </Form.Item>
                <Form.Item name={checkBoxName} valuePropName="checked">
                    <Checkbox
                        onChange={handleCheck}
                        data-test={formatDataTestAttribute(dataTest, 'InputWithCheckbox', 'Checkbox')}
                    >
                        {checkBoxLabel}
                    </Checkbox>
                </Form.Item>
            </>
        );
    },
);
