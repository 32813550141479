import React, { useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';
import { ChartWrapperOptions, ReactGoogleChartEvent } from 'react-google-charts/dist/types';
import { observer } from 'mobx-react';
import { Empty } from 'antd';
import { IChartData, ISelect } from '../../../interfaces';
import { getCartColorTheme, useChartsSettings, useUiCurrentUnitType } from '../../../hooks';
import { calcChartTicks } from '../../../instruments';
import { IOnSelectRow, selectChartRow } from '../../../instruments/selectChartRow';
import { Catcher } from '../../Catcher';
import { ChartTypeSelector } from './../Components';
import Styles from '../m_dashboarStyles.less';

interface IBarChartProps {
    children?: never;
    onSelectRow?: IOnSelectRow;
    sourceData: IChartData;
    title?: string;
    titleLink?: JSX.Element | null;
    unitTypeObj?: ISelect;
}

type IChartType = 'BarChart' | 'LineChart' | 'PieChart';
const defaultOptions = {
    backgroundColor: 'transparent',
};

export const HorizontalUsageChart: React.FC<IBarChartProps> = observer(
    ({ sourceData, title = '', titleLink = null, onSelectRow, unitTypeObj }: IBarChartProps) => {
        const [options, setOptions] = useState<Partial<ChartWrapperOptions>>(defaultOptions);
        const chartEvents: Array<ReactGoogleChartEvent> = [];
        onSelectRow && chartEvents.push(selectChartRow(onSelectRow));
        const [selectedChartType, setSelectedChartType] = useState('bar');
        const [chartType, setChartType] = useState<IChartType>('BarChart');
        const { animation, annotationsOutside, backgroundColor, chartBackground, hAxis, textColor, vAxis } =
            useChartsSettings();

        const currentUnitTypeObj = useUiCurrentUnitType();
        const currentUnitType = unitTypeObj || currentUnitTypeObj;

        useEffect(() => {
            const maxValue = sourceData.reduce((mm, i) => Math.max(mm, typeof i[1] === 'number' ? i[1] : 0), 0);
            const minValue = sourceData.reduce(
                (mm, i) => Math.min(mm, typeof i[1] === 'number' ? i[1] : maxValue),
                maxValue,
            );

            const calcMin = maxValue - (maxValue - minValue);
            const calcMax = maxValue + (maxValue - minValue) / 10;

            switch (selectedChartType) {
                case 'bar':
                    setChartType('BarChart');
                    setOptions({
                        annotations: annotationsOutside,
                        backgroundColor: chartBackground,
                        legend: 'none',
                        chartArea: {
                            left: '30%',
                            width: '65%',
                            height: '75%',
                        },
                        colors: getCartColorTheme(sourceData),
                        hAxis: {
                            ...hAxis,
                            minValue: calcMin,
                            maxValue: calcMax,
                            ticks: calcChartTicks(calcMin, maxValue, 5, currentUnitType),
                        },
                        vAxis: {
                            ...vAxis,
                            format: undefined,
                        },
                        animation,
                    });
                    break;
                case 'pie':
                    setChartType('PieChart');
                    setOptions({
                        backgroundColor: chartBackground,
                        legend: {
                            position: 'right',
                            textStyle: { color: textColor },
                        },
                        pieSliceTextStyle: { color: backgroundColor },
                        pieSliceBorderColor: chartBackground,
                        chartArea: {
                            width: '85%',
                            height: '85%',
                        },
                        hAxis,
                        vAxis: {
                            ...vAxis,
                            format: undefined,
                        },
                        animation,
                    });
                    break;
                case 'line':
                    setChartType('LineChart');
                    setOptions({
                        pointSize: 6,
                        backgroundColor: chartBackground,
                        legend: 'none',
                        chartArea: {
                            width: '85%',
                            height: '75%',
                        },
                        colors: getCartColorTheme(sourceData),
                        hAxis,
                        vAxis: {
                            ...vAxis,
                            format: 'short',
                            minValue: calcMin,
                            maxValue: calcMax,
                            ticks: calcChartTicks(calcMin, maxValue, 5, currentUnitType),
                        },
                        animation,
                    });
                    break;
                default:
                    setChartType('BarChart');
                    setOptions(defaultOptions);
                    break;
            }
        }, [sourceData, selectedChartType, backgroundColor]);

        return (
            <Catcher>
                <div className={Styles.chartTitle}>{titleLink ? titleLink : title}</div>
                <div className={Styles.chartTypeSelector}>
                    <ChartTypeSelector
                        defaultChart={selectedChartType}
                        onChangeChartType={setSelectedChartType}
                        availableTypes={['bar', 'pie', 'line']}
                    />
                </div>
                {sourceData.length < 2 ? (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                ) : (
                    <Chart
                        chartEvents={chartEvents}
                        chartType={chartType}
                        data={sourceData}
                        height={'100%'}
                        width={'100%'}
                        options={options}
                    />
                )}
            </Catcher>
        );
    },
);
