import React from 'react';
import { observer } from 'mobx-react';
import { Row } from 'antd';
import { Catcher, CompaniesAssignmentsShow, DownloadFilesButtons, cardLayoutOptions } from '../../';
import { ShowRateInfo, ShowRateMrcNrco, ShowRateOperatorsList } from './';
import { IShowForm } from '../../../interfaces';
import { useRateInfo } from '../../../hooks';
import { tP } from '../../../translate';
import Styles from '../../styles/m_itemCardStyles.less';
import RateListStyles from '../m_styles.less';

export const ShowRate: React.FC<IShowForm> = observer(({ setEditMode }: IShowForm) => {
    const { id } = useRateInfo();
    return (
        <Catcher>
            <div className={Styles.main}>
                <div className={RateListStyles.titleContainer}>
                    <h1 className={RateListStyles.title}>{tP('Sales Rate List')}</h1>
                    <DownloadFilesButtons csvType={'GET_SALES_RATE_CSV'} id={id} />
                </div>
                <Row>
                    <ShowRateInfo setEditMode={setEditMode} />
                    <ShowRateMrcNrco />
                    <CompaniesAssignmentsShow columnOption={cardLayoutOptions.cardTableFull} />
                </Row>
                <ShowRateOperatorsList />
            </div>
        </Catcher>
    );
});
