import React from 'react';
import { CardLayout, UploadFile, cardLayoutOptions } from '../../';
import cx from 'classnames';
import { IGetApiResponse } from '../../../REST';
import { getCardDeviceFiles, onDownloadNotification } from '../../../middleware';
import { tI, tT } from '../../../translate';
import Styles from '../../styles/m_itemCardStyles.less';

export const UploadCardDeviceFile: React.FC = () => {
    const onDownload = async (resp: IGetApiResponse): Promise<void> => {
        await onDownloadNotification(resp, getCardDeviceFiles);
    };
    //:TODO create mixins for this component
    return (
        <CardLayout
            title={'Card Device File'}
            columnOption={cardLayoutOptions.cardSupportPage}
            dataTest={'UploadCardDeviceFile'}
        >
            <p className={Styles.paragraph} data-test={'UploadCardDeviceFileInfo'}>
                {tT(
                    'The file to upload must be compressed (ZIP) and including the json file from the GSMA TAC DB. Maximum file size allowed 100MB.',
                )}
            </p>
            <div className={Styles.deviceFileContainer}>
                <div className={Styles.deviceFileField}>
                    <div className={cx(Styles.label, Styles.long)} data-test={'UploadCardDeviceFileTitle'}>
                        {tI('Upload GSMA TAC file')}
                    </div>
                    <div className={Styles.buttonPlace}>
                        <UploadFile
                            apiType={'POST_CARD_DEVICE_FILES'}
                            onDownload={onDownload}
                            text={'Upload'}
                            fileType={'text'}
                        />
                    </div>
                </div>
            </div>
        </CardLayout>
    );
};
