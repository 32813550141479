import React from 'react';
import { observer } from 'mobx-react';
import { Catcher } from '../../';
import { EditPurchaseZone } from './EditPurchaseZone';
import { useZonesSelect } from '../../../hooks';

interface IEditPurchaseZoneList {
    updateForm?: () => void;
}

export const EditPurchaseZonesList: React.FC<IEditPurchaseZoneList> = observer(
    ({ updateForm }: IEditPurchaseZoneList) => {
        const zoneSelect = useZonesSelect();

        const zonesJSX = zoneSelect.map((zone) => {
            return <EditPurchaseZone key={zone.id} zoneId={zone.id} name={zone.name} updateForm={updateForm} />;
        });
        return <Catcher>{zonesJSX}</Catcher>;
    },
);
