import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import {
    IExternalAPIFrontFpbxSettings,
    IExternalAPIFrontNumbers,
    IExternalAPILog,
    IExternalAPIOrderDetailForm,
    IExternalAPIOrderDetailFront,
} from '../../interfaces';

export function useExternalAPIOrderDetail(): IExternalAPIOrderDetailFront {
    return useContext(MobXProviderContext).RootStore.externalAPIStore.orderDetail;
}

export function useExternalAPIForm(): IExternalAPIOrderDetailForm {
    return useContext(MobXProviderContext).RootStore.externalAPIStore.orderDetailForm;
}

export function useExternalAPINumber(): IExternalAPIFrontNumbers {
    return useContext(MobXProviderContext).RootStore.externalAPIStore.numbers;
}

export function useExternalAPIFpbxSettings(): IExternalAPIFrontFpbxSettings {
    return useContext(MobXProviderContext).RootStore.externalAPIStore.fpbxSettings;
}

export function useExternalAPILogs(): Array<IExternalAPILog> {
    return useContext(MobXProviderContext).RootStore.externalAPIStore.logs;
}
