import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Layout, Spin } from 'antd';
import { Catcher } from '../../';
import localLogoBlack from '../../../static/img/Logo geniox_black.png';
import localLogoWhite from '../../../static/img/Logo geniox_white.png';
import { appConfig } from '../../../settings';
import { useUiDarkTheme } from '../../../hooks';
import { getCompanyLogo, getIsFindSpinners } from '../../../middleware';
import Styles from '../m_styles.less';

interface IPublicPagesWrapperProps {
    children?: JSX.Element;
}

export const PublicPagesWrapper: React.FC<IPublicPagesWrapperProps> = observer(
    ({ children }: IPublicPagesWrapperProps) => {
        const [logoWhite, setLogoWhite] = useState<string>();
        const [logoBlack, setLogoBlack] = useState<string>();
        const [websiteLink, setWebsiteLink] = useState(appConfig.defaultWebsite);
        const apiType = 'GET_COMPANY_LOGO';
        const isLoading = getIsFindSpinners([apiType]);

        const isDark = useUiDarkTheme();

        const { hostname } = window.location;

        const getLogo = async (slag: string): Promise<void> => {
            const { logoWhite, logoBlack, website, customLogoLink } = await getCompanyLogo(slag);
            setLogoWhite(logoWhite || logoBlack || localLogoWhite);
            setLogoBlack(logoBlack || logoWhite || localLogoBlack);
            !!website && !!customLogoLink && setWebsiteLink(`https://${customLogoLink}/`);
        };

        useEffect(() => {
            getLogo(hostname).then();
        }, [hostname]);

        return (
            <Catcher>
                <Layout className={Styles.publicLayout}>
                    {!isLoading ? (
                        <a href={`${websiteLink}`} className={Styles.logoLink}>
                            <img alt={websiteLink} className={Styles.logo} src={isDark ? logoBlack : logoWhite} />
                        </a>
                    ) : (
                        <Spin />
                    )}
                    {children}
                </Layout>
            </Catcher>
        );
    },
);
