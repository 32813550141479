import React, { ReactText, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Modal, Table } from 'antd';
import { Catcher, ChangeStatusToolBar, LoadingApiByIds, notSelectItem } from '../../';
import { IChangeStatusCommands, IIncludeCombinationCardSetCardSubscribersItem } from '../../../interfaces';
import { useIncludeCombinationCardSetCardSubscribers } from '../../../hooks';
import { getCardSetCombinationSubscribers, setCardSubscribersChangeStatus } from '../../../middleware';
import { tF, tP } from '../../../translate';
import Styles from '../../styles/m_itemCardStyles.less';

type ICardChangeSubscriberStatus = {
    cardIds: string[];
    status: IChangeStatusCommands;
    onCancel: () => void;
    onFinishStatusEdit: () => void;
};

interface ITableIncludeCombinationCardSetCardSubscribersItem extends IIncludeCombinationCardSetCardSubscribersItem {
    key?: string;
    children: IIncludeCombinationCardSetCardSubscribersItem[];
}

export const CardChangeSubscriberStatus: React.FC<ICardChangeSubscriberStatus> = observer(
    ({ cardIds, status, onCancel, onFinishStatusEdit }: ICardChangeSubscriberStatus) => {
        const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
        const visibleModal = !!cardIds.length && !!status;
        const newTableData: ITableIncludeCombinationCardSetCardSubscribersItem[] = [];
        const operatorIds: Array<string | undefined> = [];

        const list = useIncludeCombinationCardSetCardSubscribers();

        const changeStatus = (command: IChangeStatusCommands): void => {
            setCardSubscribersChangeStatus({ command, idList: selectedRowKeys }).finally(() => onFinishStatusEdit());
        };
        const onSelectChange = (selectItems: ReactText[]): void => {
            setSelectedRowKeys(selectItems.map((i) => i.toString()).filter((i) => !operatorIds.includes(i)));
        };

        const rowSelection = {
            selectedRowKeys,
            onChange: onSelectChange,
            checkStrictly: false,
        };

        const tableColumns = [
            {
                title: tF('Operator'),
                dataIndex: 'operatorName',
            },
            {
                title: tF(`Subscriber`),
                dataIndex: 'imsi',
                width: 200,
            },
        ];

        useEffect(() => {
            getCardSetCombinationSubscribers(cardIds);
        }, []);

        list.forEach((i: IIncludeCombinationCardSetCardSubscribersItem) => {
            const foundTableItem = newTableData.find(
                (t: ITableIncludeCombinationCardSetCardSubscribersItem) => t.operatorId === i.operatorId,
            );
            if (foundTableItem) {
                foundTableItem.children.push({ key: i.subscriberId, ...i });
            } else {
                const newTableItem: ITableIncludeCombinationCardSetCardSubscribersItem = {
                    key: i.operatorId,
                    ...i,
                    imsi: '',
                    children: [{ key: i.subscriberId, ...i }],
                };
                newTableData.push(newTableItem);
                operatorIds.push(i.operatorId);
            }
        });

        return (
            <Catcher>
                {visibleModal ? (
                    <Modal
                        title={`${tP('Subscribers')} (${selectedRowKeys.length}/${list.length})`}
                        open={true}
                        onCancel={onCancel}
                        footer={
                            <div className={Styles.modalToolBar}>
                                <ChangeStatusToolBar
                                    changeStatus={changeStatus}
                                    disabled={!selectedRowKeys.length}
                                    disabledHint={notSelectItem}
                                    statuses={[status]}
                                />
                            </div>
                        }
                        wrapClassName={'subscriber-update-modal'}
                    >
                        <LoadingApiByIds idSpinners={['GET_CARD_SET_COMBINATION_SUBSCRIBERS']} />
                        <Table
                            dataSource={newTableData}
                            columns={tableColumns}
                            pagination={false}
                            className={'table-theme'}
                            size={'middle'}
                            rowSelection={rowSelection}
                        />
                    </Modal>
                ) : null}
            </Catcher>
        );
    },
);
