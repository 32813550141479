import { ISelect } from '../interfaces';
import { equalValue } from '../instruments';

export const companyTypeRulesDic = [
    { id: '1', name: 'System Integrator', alias: 'SYSTEM INTEGRATOR' },
    { id: '2', name: 'Skymond', alias: 'SKYMOND' },
    { id: '3', name: 'MVNO', alias: 'MVNO' },
    { id: '4', name: 'Reseller', alias: 'RESELLER' },
    { id: '5', name: 'End-user', alias: 'END-USER' },
    { id: '6', name: 'MNO', alias: 'MNO' },
] as const;

export const getCompanyTypeIdsByAliasList = (aliasList: Array<ICompanyTypeAlias>): Array<ICompanyTypeId> => {
    return companyTypeRulesDic.filter((i) => aliasList.includes(i.alias)).map((i) => i.id);
};

const companyTypeRules = {
    customerPrivacyInvalidIds: getCompanyTypeIdsByAliasList(['MVNO', 'RESELLER']),
    mainMaternalId: getCompanyTypeIdsByAliasList(['SKYMOND']),
    MNOIds: getCompanyTypeIdsByAliasList(['MNO']),
    parentsInvalidIds: getCompanyTypeIdsByAliasList(['END-USER']),
};

export type ICompanyTypeId = (typeof companyTypeRulesDic)[number]['id'];
export type ICompanyTypeName = (typeof companyTypeRulesDic)[number]['name'];
export type ICompanyTypeAlias = (typeof companyTypeRulesDic)[number]['alias'];

export const isCompanyTypeIdMainMaternalCompany = (companyTypeId?: string): boolean => {
    return !!companyTypeId && companyTypeRules.mainMaternalId.includes(companyTypeId as ICompanyTypeId);
};

export const isCompanyTypeIdMNOHost = (companyTypeId?: string): boolean => {
    return !!companyTypeId && companyTypeRules.MNOIds.includes(companyTypeId as ICompanyTypeId);
};

export function isCompanyTypesIsParentsCheckValid(companyTypeId?: string): boolean {
    if (companyTypeId) {
        return !companyTypeRules.parentsInvalidIds.includes(companyTypeId as ICompanyTypeId);
    }
    return false;
}

export function isCompanyTypeCustomerPrivacyValid(companyTypeId?: string): boolean {
    if (companyTypeId) {
        return !companyTypeRules.customerPrivacyInvalidIds.includes(companyTypeId as ICompanyTypeId);
    }
    return false;
}

export const isCompanyTypeIdIsInAliasList = (
    companyTypeId: ICompanyTypeId,
    aliasList: Array<ICompanyTypeAlias>,
): boolean => {
    return !!companyTypeRulesDic.filter((i) => aliasList.includes(i.alias)).find((i) => i.id === companyTypeId);
};

export const isCompanyTypeIdIsRight = (
    companyTypeId: ICompanyTypeId,
    aliasList?: Array<ICompanyTypeAlias>,
): boolean => {
    if (!aliasList || !aliasList.length) {
        return true;
    } else {
        return isCompanyTypeIdIsInAliasList(companyTypeId, aliasList);
    }
};

export const isCompanyTypeIdIsDenied = (
    companyTypeId: ICompanyTypeId,
    aliasList?: Array<ICompanyTypeAlias>,
): boolean => {
    if (!aliasList || !aliasList.length) {
        return false;
    } else {
        return isCompanyTypeIdIsInAliasList(companyTypeId, aliasList);
    }
};

export const isValidLocalCompanyTypeDictionary = (apiList: ISelect[]): boolean => {
    const dicList = companyTypeRulesDic.map((i) => ({ id: i.id, name: i.name }));

    return !apiList.some((item: ISelect) => {
        const itemDic = dicList.find((dicItem) => dicItem.id === item.id);
        if (!equalValue(item, itemDic)) {
            return true;
        }
    });
};
