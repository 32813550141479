import React from 'react';

interface IPackageItemList {
    valueList?: string[];
}

export const PackageItemList: React.FC<IPackageItemList> = ({ valueList }: IPackageItemList) => {
    const renderList = (valueList: string[] = []): React.ReactNode => {
        if (!valueList.length) {
            return <p>None</p>;
        }

        return valueList?.map((value, index) => <p key={index}>{value}</p>);
    };

    return <>{renderList(valueList)}</>;
};
