import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Catcher, FeatureFlagList } from '../../';
import { getFeatureFlagsListAll, getFeatureFlagsRelations } from '../../../middleware';
import Styles from '../../styles/m_itemCardStyles.less';

export const FeatureFlags: React.FC = observer(() => {
    useEffect(() => {
        getFeatureFlagsListAll();
        getFeatureFlagsRelations();
    }, []);

    return (
        <Catcher>
            <div className={Styles.main}>
                <FeatureFlagList />
            </div>
        </Catcher>
    );
});
