import React, { useEffect, useRef, useState } from 'react';
import { Progress } from 'antd';
import dayjs from 'dayjs';
import adv from 'dayjs/plugin/duration';
import { percentage } from '../../../instruments';

dayjs.extend(adv);

interface ICountDown {
    createdAt?: string;
    expiresDate?: string;
    isExpired?: boolean;
    isRenewed?: boolean;
}

export const PackageCountDown: React.FC<ICountDown> = ({
    createdAt = dayjs().format(),
    expiresDate = dayjs().format(),
    isExpired,
    isRenewed,
}: ICountDown) => {
    const [time, setTime] = useState('loading...');
    const [isTimerExpired, setIsTimerExpired] = useState(isExpired);
    const [barPercentage, setBarPercentage] = useState(0);
    const timer = useRef<any>();

    const newTime = (): void => {
        const end = dayjs(expiresDate);
        const current = dayjs();
        const days = end.diff(current, 'days');
        const hours = end.subtract(days, 'days').diff(current, 'hours');
        const minutes = end.subtract(days, 'days').subtract(hours, 'hours').diff(current, 'minutes');
        const seconds = end
            .subtract(days, 'days')
            .subtract(hours, 'hours')
            .subtract(minutes, 'minutes')
            .diff(current, 'seconds');
        const newDay = days <= 0 ? '0d' : `${days}d`;
        const newHours = hours <= 0 ? '00h' : `${hours < 10 ? '0' : ''}${hours}h`;
        const newMinutes = minutes <= 0 ? '00m' : `${minutes < 10 ? '0' : ''}${minutes}m`;
        const newSeconds = seconds <= 0 ? '00s' : `${seconds < 10 ? '0' : ''}${seconds}s`;
        const newTime = `${newDay} ${newHours} ${newMinutes} ${newSeconds}`;
        if (newTime === '0d 00h 00m 00s') {
            clearInterval(timer.current);
            setIsTimerExpired(true);
        } else {
            setTime(newTime);
        }
    };

    const newBar = (): void => {
        const start = isRenewed ? dayjs().startOf('month') : dayjs(createdAt);
        const end = dayjs(expiresDate);
        const totalDays = dayjs.duration(end.diff(start)).asDays();
        const current = dayjs();
        const currentDays = dayjs.duration(current.diff(start)).asDays();
        const daysLeftPercentage = 100 - percentage(currentDays, totalDays);

        setBarPercentage(daysLeftPercentage);
    };

    const handleInterval = (): void => {
        newBar();
        newTime();
    };

    useEffect(() => {
        return (): void => {
            clearInterval(timer.current);
        };
    }, []);

    useEffect(() => {
        clearInterval(timer.current);

        if (!isTimerExpired && createdAt && expiresDate) {
            timer.current = setInterval(handleInterval, 1000);
        }
    }, [createdAt, expiresDate, isExpired]);

    return (
        <>
            <Progress
                percent={barPercentage}
                showInfo={false}
                status={'normal'}
                trailColor={'#ff4d4f'}
                strokeColor={'#1890ff'}
            />
            <span>{!isTimerExpired ? time : 'EXPIRED'}</span>
        </>
    );
};
