import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Divider, Row } from 'antd';
import { CardInfoToolBar, CardMainLayout, Catcher, cardLayoutOptions } from '../';
import { IGetApiType, IShowFormComponent } from '../../interfaces';
import { usePackageInfo, useRouterStore } from '../../hooks';
import { abortAllFetch, addNotification, cleanPackageStore, getPackageToBuyById } from '../../middleware';
import { tI, tNM } from '../../translate';
import { PackageInfoBox } from './Components';
import Styles from '../styles/m_itemCardStyles.less';

export const PackageInstanceBuyPreview: React.FC<IShowFormComponent> = observer(() => {
    const idSpinners: IGetApiType[] = ['GET_ONE_PACKAGE', 'GET_ONE_PACKAGE_TO_BUY'];
    const packageInfo = usePackageInfo();
    const { name } = packageInfo;
    const routerStore = useRouterStore();

    const fetchPackageById = async (id: string): Promise<void> => {
        const isError = await getPackageToBuyById(id);

        if (isError) {
            addNotification({
                type: 'error',
                message: tNM('Not Found'),
                description: `${tI('package')} id - "${id}" ${tI('not found')}`,
                duration: 5,
                isTranslated: true,
                isUsersError: true,
            });
            routerStore.goTo('PackagesSell');
        }
    };

    useEffect(() => {
        const { params } = routerStore.routerState;

        fetchPackageById(params.id);

        return (): void => {
            abortAllFetch();
            cleanPackageStore();
        };
    }, []);

    return (
        <Catcher>
            <article className={Styles.main}>
                <Row>
                    <CardMainLayout
                        columnOption={cardLayoutOptions.packageCard}
                        idSpinners={idSpinners}
                        name={name}
                        title={'Buy Package'}
                    >
                        <PackageInfoBox packageInfo={packageInfo} />
                        <Divider />
                        <CardInfoToolBar
                            loadingTypes={idSpinners}
                            onCancel={(): void => {
                                routerStore.goTo('PackagesBuy');
                            }}
                        />
                    </CardMainLayout>
                </Row>
            </article>
        </Catcher>
    );
});
