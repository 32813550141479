import React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import { IDiameterSessionsListItem } from '../../../interfaces';
import { useDiameterSessionsList, useUiIsLoading } from '../../../hooks';
import { setDiameterSessionUpdateTime, setUiSessionId } from '../../../middleware';
import { byteMb1000, dateText2Utc } from '../../../instruments';
import { tF } from '../../../translate';
import { Catcher, GoComponent, LinkComponent } from '../../../Components';
import '../../../customStyle/table.less';
import Styles from '../../styles/m_viewStyles.less';

export const DiameterSessionsTableList: React.FC = observer(() => {
    const isLoading = useUiIsLoading();
    const list = useDiameterSessionsList();

    const onClickSessionId = (id: string): void => {
        setUiSessionId(id);
        setDiameterSessionUpdateTime();
    };
    const tableColumns = [
        {
            title: tF('CCR Type'),
            dataIndex: 'ccRequestType',
        },
        {
            title: tF('CCR'),
            dataIndex: 'ccRequestNumber',
        },
        {
            title: tF('Session Id'),
            dataIndex: 'sessionIdLink',
        },
        {
            title: tF('User Name'),
            dataIndex: 'cardLink',
        },
        {
            title: `${tF('Total Used')} (Mb)`,
            dataIndex: 'totalUsedUnitsMb',
            className: Styles.columnRight,
        },
        {
            title: tF('SGSN MMC MNC'),
            dataIndex: 'sgsn',
        },
        {
            title: tF('Event TimeStamp'),
            dataIndex: 'ts',
        },
    ];
    const tableData = list.map((i: IDiameterSessionsListItem, index: number) => {
        return {
            key: i.id + index.toString(),
            ...i,
            sessionIdLink: <GoComponent onClick={(): void => onClickSessionId(i.sessionId || '')} name={i.sessionId} />,
            cardLink: <LinkComponent currentListComponent={'CardList'} id={i.cardId} name={i.msisdn} />,
            totalUsedUnitsMb: byteMb1000(i.totalUsedUnits),
            sgsn: `${i.sgsnMccMnc} - ${i.operator}`,
            ts: dateText2Utc(i.eventTimestamp),
        };
    });

    const expandedRowRender = (record: IDiameterSessionsListItem): JSX.Element => {
        return (
            <div className={Styles.expandedRow}>
                <div className={Styles.expandedRowLine}>
                    <div className={Styles.expandedRowLineLabel}>{tF('Subscription Id')}</div>
                    <div className={Styles.expandedRowLineValue}>{record.subscriptionIdValue}</div>
                </div>
                <div className={Styles.expandedRowLine}>
                    <div className={Styles.expandedRowLineLabel}>{tF('MSCC')}</div>
                    <div className={Styles.expandedRowLineValue}>
                        {JSON.stringify(record.multipleServicesCreditControl).replace(/":/gi, '"=>')}
                    </div>
                </div>
                <div className={Styles.expandedRowLine}>
                    <div className={Styles.expandedRowLineLabel}>{tF('IMEIVS')}</div>
                    <div className={Styles.expandedRowLineValue}>{record.userEquipmentInfoValue}</div>
                </div>
                <div className={Styles.expandedRowLine}>
                    <div className={Styles.expandedRowLineLabel}>{tF('Charging Id')}</div>
                    <div className={Styles.expandedRowLineValue}>{record.chargingId}</div>
                </div>
                <div className={Styles.expandedRowLine}>
                    <div className={Styles.expandedRowLineLabel}>{tF('PDP Address')}</div>
                    <div className={Styles.expandedRowLineValue}>{record.pdpAddress}</div>
                </div>
                <div className={Styles.expandedRowLine}>
                    <div className={Styles.expandedRowLineLabel}>{tF('SGSN Address')}</div>
                    <div className={Styles.expandedRowLineValue}>{record.sgsnAddress}</div>
                </div>
                <div className={Styles.expandedRowLine}>
                    <div className={Styles.expandedRowLineLabel}>{tF('GGSN Address')}</div>
                    <div className={Styles.expandedRowLineValue}>{record.ggsnAddress}</div>
                </div>
                <div className={Styles.expandedRowLine}>
                    <div className={Styles.expandedRowLineLabel}>{tF('IMSI MCC MNC')}</div>
                    <div className={Styles.expandedRowLineValue}>{record.imsiMccNnc}</div>
                </div>
                <div className={Styles.expandedRowLine}>
                    <div className={Styles.expandedRowLineLabel}>{tF('NSAPI')}</div>
                    <div className={Styles.expandedRowLineValue}>{record.nsapi}</div>
                </div>
                <div className={Styles.expandedRowLine}>
                    <div className={Styles.expandedRowLineLabel}>{tF('APN')}</div>
                    <div className={Styles.expandedRowLineValue}>{record.calledStationId}</div>
                </div>
                <div className={Styles.expandedRowLine}>
                    <div className={Styles.expandedRowLineLabel}>{tF('Charging Characteristics')}</div>
                    <div className={Styles.expandedRowLineValue}>{record.chargingCharacteristics}</div>
                </div>
                <div className={Styles.expandedRowLine}>
                    <div className={Styles.expandedRowLineLabel}>{tF('PDP Context')}</div>
                    <div className={Styles.expandedRowLineValue}>{record.pdpContextType}</div>
                </div>
                <div className={Styles.expandedRowLine}>
                    <div className={Styles.expandedRowLineLabel}>{tF('Origin State ID')}</div>
                    <div className={Styles.expandedRowLineValue}>{record.originStateId}</div>
                </div>
            </div>
        );
    };

    const expandable = {
        expandedRowRender: expandedRowRender,
    };

    return (
        <Catcher>
            <div className={Styles.tablePlace}>
                <Table
                    className={'table-theme'}
                    columns={tableColumns}
                    dataSource={tableData}
                    expandable={expandable}
                    loading={isLoading}
                    pagination={false}
                    size={'middle'}
                />
            </div>
        </Catcher>
    );
});
