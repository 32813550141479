import React from 'react';
import { Form } from 'antd';
import { CardLayout, Catcher, InputDebounce, InputNumberDebounce, cardLayoutOptions } from '../../';
import { apiMessagesGetFormRules } from '../../../middleware';

export const EditRateFieldsMrcNrc: React.FC = () => {
    return (
        <Catcher>
            <CardLayout
                idSpinners={['GET_RATE_UNIT_SELECT']}
                title={'MRC & NRC'}
                columnOption={cardLayoutOptions.salesRateInfo}
            >
                <Form.Item name="mrcActiveCard" label={'Mrc isActive card'} rules={apiMessagesGetFormRules}>
                    <InputNumberDebounce step={0.01} min={0} />
                </Form.Item>
                <Form.Item name="mrcCard" label={'Mrc card'} rules={apiMessagesGetFormRules}>
                    <InputNumberDebounce step={0.01} min={0} />
                </Form.Item>
                <Form.Item name="mrcActiveImsi" label={'Mrc isActive imsi'} rules={apiMessagesGetFormRules}>
                    <InputDebounce placeholder={'Mrc isActive imsi'} />
                </Form.Item>
                <Form.Item name="nrcCard" label={'Nrc card'} rules={apiMessagesGetFormRules}>
                    <InputNumberDebounce step={0.01} min={0} />
                </Form.Item>
                <Form.Item name="nrcImsi" label={'Nrc imsi'} rules={apiMessagesGetFormRules}>
                    <InputNumberDebounce step={0.01} min={0} />
                </Form.Item>
            </CardLayout>
        </Catcher>
    );
};
