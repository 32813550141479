import React from 'react';
import { observer } from 'mobx-react';
import cx from 'classnames';
import { tP } from '../../translate';
import { TermsForm } from './Components';
import Styles from './m_styles.less';

export const TermsPage: React.FC = observer(() => {
    return (
        <div className={cx(Styles.termsLayout)}>
            <div className={cx(Styles.card)}>
                <div className={cx(Styles.title, Styles.titleAlert)}>
                    {tP('Updated Terms & Conditions and Data Processing Agreement')}
                </div>
                <div className={Styles.form}>
                    <TermsForm />
                </div>
            </div>
        </div>
    );
});
