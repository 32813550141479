import React from 'react';
import { observer } from 'mobx-react';
import { Catcher, ListWrapper, ReportFilesToolBar } from '../';
import { useCallDataRecordsFilterParamsBeta, useProfile } from '../../hooks';
import { cleanCallDataRecordsStoreBeta, getCallDataRecordsRelationSelect } from '../../middleware';
import { CallDataRecordsBetaTableList } from './Components';

const ReportToolBar: React.FC = observer(() => {
    const { isCompanyTypeIdMNOHost } = useProfile();
    const filterParams = useCallDataRecordsFilterParamsBeta();
    return (
        <ReportFilesToolBar
            filterParams={filterParams}
            entityType={'squashed'}
            type={'POST_CALL_DATA_RECORDS'}
            isPdf={!isCompanyTypeIdMNOHost}
            isCsv={true}
        />
    );
});

export const CallDataRecordsListBeta: React.FC = observer(() => {
    const filterParams = useCallDataRecordsFilterParamsBeta();
    const { companyId } = useProfile();

    return (
        <Catcher>
            <ListWrapper
                cleanStore={cleanCallDataRecordsStoreBeta}
                defaultParams={{ companyId }}
                filterParams={filterParams}
                getRelationSelect={getCallDataRecordsRelationSelect}
                RightButtons={ReportToolBar}
            >
                <CallDataRecordsBetaTableList />
            </ListWrapper>
        </Catcher>
    );
});
