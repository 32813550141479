import React from 'react';
import { observer } from 'mobx-react';
import { Divider } from 'antd';
import {
    BankOutlined,
    CarOutlined,
    CopyrightOutlined,
    GlobalOutlined,
    HomeOutlined,
    InfoCircleOutlined,
    MailOutlined,
    PhoneOutlined,
    TagOutlined,
    ToolOutlined,
} from '@ant-design/icons';
import { CardMainLayout, Catcher, FieldInfoAdvanced, cardLayoutOptions } from '../../';
import { useCompanyAbout, useCompanyInfo, useProfileIsRight } from '../../../hooks';
import { tF, tP } from '../../../translate';
import Styles from '../../styles/m_itemCardStyles.less';

export const ShowCompanyCustomize: React.FC = observer(() => {
    const isLord = useProfileIsRight('LORD');
    const { slug, helpDeskEnabled, customAboutEnabled, customLogoLinkUrl } = useCompanyInfo();
    const { address, email, iban, name, phoneNumber, registration, swift, vat, website } = useCompanyAbout();

    const layout = {
        labelCol: {
            flex: '0 1 190px',
        },
        rowGutter: 8,
    };

    return (
        <Catcher>
            <CardMainLayout
                columnOption={cardLayoutOptions.cardCompanyShowCustomize}
                idSpinners={['GET_COMPANY', 'GET_COMPANY_ABOUT']}
                name={tP('Customize Company')}
                dataTest={'ShowCompany'}
            >
                <FieldInfoAdvanced
                    icon={<TagOutlined className={Styles.labelIcon} />}
                    layout={layout}
                    title={'Custom login URL'}
                    dataTest={'ShowCompanySlug'}
                >
                    {slug}
                </FieldInfoAdvanced>
                <FieldInfoAdvanced
                    icon={<TagOutlined className={Styles.labelIcon} />}
                    layout={layout}
                    title={'Custom logo URL'}
                    dataTest={'ShowCompanyCustomLogoLinkUrl'}
                >
                    {customLogoLinkUrl}
                </FieldInfoAdvanced>

                <FieldInfoAdvanced
                    icon={<ToolOutlined className={Styles.labelIcon} />}
                    layout={layout}
                    title={'Helpdesk widget'}
                    dataTest={'ShowCompanyHelpdeskWidget'}
                >
                    {helpDeskEnabled ? tF('Enabled') : tF('Disabled')}
                </FieldInfoAdvanced>

                <FieldInfoAdvanced
                    icon={<InfoCircleOutlined className={Styles.labelIcon} />}
                    layout={layout}
                    title={'Custom About'}
                    dataTest={'ShowCompanyCustomAbout'}
                >
                    {customAboutEnabled ? tF('Enabled') : tF('Disabled')}
                </FieldInfoAdvanced>

                {isLord && customAboutEnabled ? (
                    <>
                        <Divider />
                        <h2 className={Styles.subTitle}>{tP('Custom About')}</h2>
                        <FieldInfoAdvanced
                            icon={<CopyrightOutlined className={Styles.labelIcon} />}
                            layout={layout}
                            title={'Company'}
                        >
                            {name}
                        </FieldInfoAdvanced>
                        <FieldInfoAdvanced
                            icon={<HomeOutlined className={Styles.labelIcon} />}
                            layout={layout}
                            title={'Address'}
                        >
                            {address?.split('\n').map((s: string, i: number) => (
                                <p key={i}>{s}</p>
                            ))}
                        </FieldInfoAdvanced>
                        <FieldInfoAdvanced
                            icon={<GlobalOutlined className={Styles.labelIcon} />}
                            layout={layout}
                            title={'Website'}
                        >
                            {website}
                        </FieldInfoAdvanced>
                        <FieldInfoAdvanced
                            icon={<MailOutlined className={Styles.labelIcon} />}
                            layout={layout}
                            title={'Email'}
                        >
                            {email}
                        </FieldInfoAdvanced>
                        <FieldInfoAdvanced
                            icon={<PhoneOutlined className={Styles.labelIcon} />}
                            layout={layout}
                            title={'Phone'}
                        >
                            {phoneNumber}
                        </FieldInfoAdvanced>
                        <FieldInfoAdvanced
                            icon={<GlobalOutlined className={Styles.labelIcon} />}
                            layout={layout}
                            title={'C of C registration'}
                        >
                            {registration}
                        </FieldInfoAdvanced>
                        <FieldInfoAdvanced
                            icon={<BankOutlined className={Styles.labelIcon} />}
                            layout={layout}
                            title={'IBAN account'}
                        >
                            {iban}
                        </FieldInfoAdvanced>
                        <FieldInfoAdvanced
                            icon={<PhoneOutlined className={Styles.labelIcon} />}
                            layout={layout}
                            title={'Swift-Bic'}
                        >
                            {swift}
                        </FieldInfoAdvanced>
                        <FieldInfoAdvanced
                            icon={<CarOutlined className={Styles.labelIcon} />}
                            layout={layout}
                            title={'VAT'}
                        >
                            {vat}
                        </FieldInfoAdvanced>{' '}
                    </>
                ) : null}
            </CardMainLayout>
        </Catcher>
    );
});
