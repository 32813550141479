import React from 'react';
import { observer } from 'mobx-react';
import { Catcher, LoadingApi } from '../../';
import { ShowRateOperator } from './ShowRateOperator';
import { ISelect } from '../../../interfaces';
import { useRateViewListOperatorsSelect } from '../../../hooks';

export const ShowRateOperatorsList: React.FC = observer(() => {
    const operatorsSelect = useRateViewListOperatorsSelect();

    return (
        <Catcher>
            <LoadingApi />
            {operatorsSelect.map((o: ISelect, i: number) => {
                return <ShowRateOperator key={o.id || `xxx-${i}`} operatorId={o.id} name={o.name} />;
            })}
        </Catcher>
    );
});
