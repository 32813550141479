import { apiConfig } from './config';
import { HTTP_STATUS, IApiParams, IGetApiParams, IGetApiType, IRequestAPIBody } from './types';
import queryString from 'query-string';
import { generateError, params2Url, removePageOptions } from '../instruments';

export async function getApiParams(
    apiType: IGetApiType,
    params?: IApiParams,
    body?: IRequestAPIBody,
): Promise<IGetApiParams> {
    const apiParams: IGetApiParams = {
        url: '/',
        method: 'GET',
        headerType: 'access',
        apiType: apiType,
        okStatus: [],
        errorStatus: [],
        isGetFile: false,
    };
    const accessUrl = apiConfig.accessUrl();
    let options = '';
    if (params?.searchParamsList) {
        options = params2Url(params.searchParamsList, params.isBigQuery);
    }
    const id = params?.id;
    const companyId = params?.companyId;
    const subscriptionId = params?.subscriptionId;
    switch (apiType) {
        case 'APPLICATION':
            apiParams.url = `/application.json`;
            apiParams.headerType = 'none';
            apiParams.okStatus = [HTTP_STATUS.GET_OK_200];
            apiParams.isNotCheckToken = true;
            apiParams.isNotLoader = true;
            break;
        case 'APP_SIGNAL':
            apiParams.url = `/appSignal.json`;
            apiParams.headerType = 'none';
            apiParams.okStatus = [HTTP_STATUS.GET_OK_200];
            apiParams.isNotCheckToken = true;
            break;
        case 'LOG_IN':
            apiParams.url = `${accessUrl}user/session`;
            apiParams.method = 'POST';
            apiParams.headerType = 'none';
            apiParams.okStatus = [HTTP_STATUS.TOKEN_LOGIN_OK_201];
            apiParams.errorStatus = [HTTP_STATUS.TOKEN_LOGIN_ERROR_404];
            apiParams.isNotCheckToken = true;
            break;
        case 'LOG_OUT':
            apiParams.url = `${accessUrl}user/session`;
            apiParams.method = 'DELETE';
            apiParams.okStatus = [
                HTTP_STATUS.TOKEN_LOGOUT_OK_500,
                HTTP_STATUS.TOKEN_LOGOUT_OK_204,
                HTTP_STATUS.TOKEN_GET_POST_PATCH_ERROR_401,
            ];
            apiParams.isNotCheckToken = true;
            break;
        case 'PASSWORD_UPDATE_EMAIL':
            apiParams.url = `${accessUrl}user/session/password`;
            apiParams.method = 'POST';
            apiParams.okStatus = [HTTP_STATUS.SUCCESS_WITHOUT_BODY_204];
            apiParams.isNotCheckToken = true;
            break;
        case 'PASSWORD_UPDATE':
            apiParams.url = `${accessUrl}user/session/password`;
            apiParams.method = 'PATCH';
            apiParams.okStatus = [HTTP_STATUS.SUCCESS_WITHOUT_BODY_204];
            apiParams.isNotCheckToken = true;
            break;
        case 'REFRESH_TOKEN':
            apiParams.url = `${accessUrl}user/session/refresh`;
            apiParams.method = 'POST';
            apiParams.headerType = 'refresh';
            apiParams.okStatus = [HTTP_STATUS.TOKEN_UPDATE_OK_201];
            // apiParams.errorStatus = [HTTP_STATUS.TOKEN_UPDATE_ERROR_401];
            apiParams.isNotLoader = true;
            apiParams.isNotCheckToken = true;
            break;
        case 'POST_BLOB':
            apiParams.url = `${accessUrl}blobs`;
            apiParams.method = 'POST';
            apiParams.isPostFile = true;
            break;
        case 'DELETE_BLOB':
            apiParams.url = `${accessUrl}blobs/${id}`;
            apiParams.method = 'DELETE';
            break;
        case 'GET_ACCEPTANCE_DOCUMENTS':
            apiParams.url = `${accessUrl}acceptance_documents?company_id=${id}`;
            break;
        case 'POST_ACCEPTANCE_DOCUMENTS':
            apiParams.url = `${accessUrl}acceptance_documents/accept`;
            apiParams.method = 'POST';
            break;
        case 'POST_UPLOAD_ACCEPTANCE_DOCUMENTS':
            apiParams.url = `${accessUrl}acceptance_documents`;
            apiParams.method = 'POST';
            break;
        case 'GET_USER_TYPE_SELECT':
            apiParams.url = `${accessUrl}nodes/user_types`;
            break;
        case 'GET_USER':
            apiParams.url = `${accessUrl}users/${id}`;
            apiParams.errorStatus = [HTTP_STATUS.GET_NOT_FOUND_404];
            break;
        case 'PATCH_USER':
            apiParams.url = `${accessUrl}users/${id}`;
            apiParams.method = 'PATCH';
            break;
        case 'POST_USER':
            apiParams.url = `${accessUrl}users`;
            apiParams.method = 'POST';
            break;
        case 'DELETE_USER':
            apiParams.url = `${accessUrl}users/${id}`;
            apiParams.method = 'DELETE';
            break;
        case 'PATCH_PASSWORD':
            apiParams.url = `${accessUrl}user/password`;
            apiParams.method = 'PATCH';
            break;
        case 'GET_USER_PROFILE':
            apiParams.url = `${accessUrl}user/actual`;
            break;
        case 'GET_PROFILE_ALARM':
            apiParams.url = `${accessUrl}companies/${id}/company_config`;
            break;
        case 'GET_USERS_BY_COMPANY_SELECT':
            apiParams.url = `${accessUrl}nodes/company_users${options}`;
            break;
        case 'GET_USERS_LIST':
            apiParams.url = `${accessUrl}users${options}`;
            break;
        case 'POST_USER_IMPERSONATION':
            apiParams.url = `${accessUrl}user/impersonation`;
            apiParams.method = 'POST';
            break;
        case 'DELETE_USER_IMPERSONATION':
            apiParams.url = `${accessUrl}user/impersonation`;
            apiParams.method = 'DELETE';
            break;
        case 'GET_CARD_LIST_COUNT':
            apiParams.url = `${accessUrl}cards/count${options}`;
            break;
        case 'GET_CARD_LIST':
            apiParams.url = `${accessUrl}cards${options}`;
            break;
        case 'GET_CURRENCY_SELECT':
            apiParams.url = `${accessUrl}nodes/currencies`;
            break;
        case 'GET_CURRENCY_LIST':
            apiParams.url = `${accessUrl}currencies${options}`;
            break;
        case 'GET_CURRENCY':
            apiParams.url = `${accessUrl}currencies/${id}`;
            break;
        case 'PATCH_CURRENCY':
            apiParams.url = `${accessUrl}currencies/${id}`;
            apiParams.method = 'PATCH';
            break;
        case 'DELETE_CURRENCY':
            apiParams.url = `${accessUrl}currencies/${id}`;
            apiParams.method = 'DELETE';
            break;
        case 'GET_SUBSCRIBERS_LIST':
            apiParams.url = `${accessUrl}subscribers${options}`;
            break;
        case 'GET_SUBSCRIBER':
            apiParams.url = `${accessUrl}subscribers/${id}`;
            break;
        case 'PATCH_SUBSCRIBER':
            apiParams.url = `${accessUrl}subscribers/${id}`;
            apiParams.method = 'PATCH';
            break;
        case 'DELETE_SUBSCRIBER':
            apiParams.url = `${accessUrl}subscribers/${id}`;
            apiParams.method = 'DELETE';
            break;
        case 'GET_SUBSCRIBERS_BY_CARD_ID':
            apiParams.url = `${accessUrl}subscribers${options}`;
            break;
        case 'POST_SUBSCRIBERS_API_REQUEST':
            apiParams.url = `${accessUrl}subscribers/api_request`;
            apiParams.method = 'POST';
            break;
        case 'GET_RATE_UNIT_SELECT':
            apiParams.url = `${accessUrl}nodes/rate_units`;
            break;
        case 'GET_COMPANIES_LIST':
            apiParams.url = `${accessUrl}companies${options}`;
            break;
        case 'GET_COMPANIES_SELECT':
            apiParams.url = `${accessUrl}nodes/companies${options}`;
            break;
        case 'DELETE_COMPANY':
            apiParams.url = `${accessUrl}companies/${id}`;
            apiParams.method = 'DELETE';
            break;
        case 'GET_COMPANY_TYPES_SELECT':
            apiParams.url = `${accessUrl}nodes/company_types`;
            break;
        case 'GET_COMPANIES_CHILDREN_SELECT':
            apiParams.url = `${accessUrl}nodes/companies/children/?company_id=${id}`;
            break;
        case 'PATCH_COMPANY_SETTINGS':
            apiParams.url = `${accessUrl}companies/${id}/setting`;
            apiParams.method = 'PATCH';
            break;
        case 'GET_COMPANY':
            apiParams.url = `${accessUrl}companies/${id}`;
            break;
        case 'GET_COMPANY_ABOUT':
            apiParams.url = `${accessUrl}companies/${id}/about`;
            break;
        case 'GET_COMPANY_LOGO':
            apiParams.url = `${accessUrl}companies/logos?slug=${id}`;
            apiParams.headerType = 'none';
            apiParams.isNotCheckToken = true;
            break;
        case 'POST_COMPANY_API_KEY':
            apiParams.url = `${accessUrl}companies/api_keys`;
            apiParams.method = 'POST';
            break;
        case 'GET_COMPANY_API_KEY':
            apiParams.url = `${accessUrl}companies/api_keys`;
            break;
        case 'DELETE_COMPANY_API_KEY':
            apiParams.url = `${accessUrl}companies/api_keys/${id}`;
            apiParams.method = 'DELETE';
            break;
        case 'PATCH_COMPANY_ABOUT':
            apiParams.url = `${accessUrl}companies/${id}/about`;
            apiParams.method = 'PATCH';
            break;
        case 'GET_COMPANY_STATISTICS':
            apiParams.url = `${accessUrl}companies/${id}/statistics`;
            break;
        case 'PATCH_COMPANY':
            apiParams.url = `${accessUrl}companies/${id}`;
            apiParams.method = 'PATCH';
            break;
        case 'POST_COMPANY':
            apiParams.url = `${accessUrl}companies`;
            apiParams.method = 'POST';
            break;
        case 'GET_COMPANIES_HIERARCHY':
            apiParams.url = `${accessUrl}companies/hierarchy${options}`;
            break;
        case 'GET_CARD':
            apiParams.url = `${accessUrl}cards/${id}`;
            break;
        case 'GET_CARD_STATISTICS':
            apiParams.url = `${accessUrl}companies/${companyId}/cards/${id}/statistics`;
            apiParams.method = 'GET';
            break;
        case 'DELETE_CARD':
            apiParams.url = `${accessUrl}cards/${id}`;
            apiParams.method = 'DELETE';
            break;
        case 'DELETE_CARDS':
            options = queryString.stringify({ ids: params?.idList }, { arrayFormat: 'bracket' });
            apiParams.url = `${accessUrl}cards/bulk_destroy?${options}&card_set_id=${id}`;
            apiParams.method = 'POST';
            break;
        case 'DELETE_CARDS_CSV':
            apiParams.url = `${accessUrl}cards/bulk_destroy.csv?card_set_id=${id}`;
            apiParams.method = 'POST';
            apiParams.isPostFile = true;
            break;
        case 'DELETE_CARD_SUBSCRIBERS':
            options = queryString.stringify({ ids: params?.idList }, { arrayFormat: 'bracket' });
            apiParams.url = `${accessUrl}cards/${id}/subscribers/bulk_destroy?${options}`;
            apiParams.method = 'DELETE';
            break;
        case 'PATCH_CARD':
            apiParams.url = `${accessUrl}cards/${id}`;
            apiParams.method = 'PATCH';
            break;
        case 'POST_CARDS_LIST_CSV':
            apiParams.url = `${accessUrl}cards/import`;
            apiParams.method = 'POST';
            apiParams.isPostFile = true;
            break;
        case 'GET_DIAMETER_SESSIONS_LIST':
            apiParams.url = `${accessUrl}diameter_sessions${options}`;
            break;
        case 'GET_CARD_SETS_LIST':
            apiParams.url = `${accessUrl}card_sets${options}`;
            break;
        case 'GET_CARD_SETS_SELECT':
            apiParams.url = `${accessUrl}nodes/card_sets${options}`;
            break;
        case 'GET_CARD_SET':
            apiParams.url = `${accessUrl}card_sets/${id}`;
            break;
        case 'GET_CARD_SET_COMBINATION':
            apiParams.url = `${accessUrl}card_sets/${id}/cards${options}`;
            break;
        case 'GET_CARD_SET_COMBINATION_SUBSCRIBERS':
            options = queryString.stringify({ card_ids: params?.idList }, { arrayFormat: 'bracket' });
            apiParams.url = `${accessUrl}/cards/subscribers?${options}`;
            break;
        case 'GET_COMBINATION_CARDS_SELECT':
            apiParams.url = `${accessUrl}nodes/companies/available_cards/?company_id=${id}`;
            break;
        case 'PATCH_CARD_SET':
            apiParams.url = `${accessUrl}card_sets/${id}`;
            apiParams.method = 'PATCH';
            break;
        case 'POST_CARD_SET':
            apiParams.url = `${accessUrl}card_sets`;
            apiParams.method = 'POST';
            break;
        case 'DELETE_CARD_SET':
            apiParams.url = `${accessUrl}card_sets/${id}`;
            apiParams.method = 'DELETE';
            break;
        case 'GET_ENTITY_SELECT':
            apiParams.url = `${accessUrl}nodes/entity${options}`;
            break;
        case 'GET_ES_PLUS_NOTIFICATIONS_LIST':
            apiParams.url = `${accessUrl}es_plus/notifications${options}`;
            break;
        case 'GET_ES_PLUS_NOTIFICATIONS_LIST_CSV':
            apiParams.url = `${accessUrl}es_plus/notifications.csv`;
            apiParams.isGetFile = true;
            break;
        case 'GET_ES_PLUS_NOTIFICATIONS_LOGS_LIST':
            apiParams.url = `${accessUrl}es_plus/request_logs${options}`;
            break;
        case 'GET_ES_PLUS_NOTIFICATIONS_LOGS_LIST_CSV':
            apiParams.url = `${accessUrl}es_plus/request_logs.csv`;
            apiParams.isGetFile = true;
            break;
        case 'GET_REPORT_LIST':
            apiParams.url = `${accessUrl}data_exports${options}`;
            break;
        case 'DELETE_REPORT':
            apiParams.url = `${accessUrl}data_exports/${id}`;
            apiParams.method = 'PATCH';
            break;
        case 'GET_BALANCE_LOG_LIST':
            apiParams.url = `${accessUrl}balance_logs${options}`;
            break;
        case 'GET_CALL_DATA_RECORDS_COUNT':
            apiParams.url = `${accessUrl}call_data_records/count${options}`;
            break;
        case 'GET_CALL_DATA_RECORDS_LIST':
            apiParams.url = `${accessUrl}call_data_records${options}`;
            break;
        case 'GET_CALL_DATA_SQUASHED_RECORDS_COUNT':
            apiParams.url = `${accessUrl}squashed_rcdrs/count${options}`;
            break;
        case 'GET_CALL_DATA_SQUASHED_RECORDS_LIST':
            apiParams.url = `${accessUrl}squashed_rcdrs${options}`;
            break;
        case 'GET_CALL_DATA_RECORDS_LAST_5':
            apiParams.url = `${accessUrl}nodes/call_data_records${options}`;
            break;
        case 'POST_CALL_DATA_RECORDS':
            apiParams.url = `${accessUrl}cdr_exports${removePageOptions(options)}`;
            apiParams.method = 'POST';
            break;
        case 'GET_COUNTRIES_LIST':
            apiParams.url = `${accessUrl}countries${options}`;
            break;
        case 'GET_COUNTRY':
            apiParams.url = `${accessUrl}countries/${id}`;
            break;
        case 'GET_COUNTRY_ACTIVE_CARDS':
            apiParams.url = `${accessUrl}countries/${id}/active_cards`;
            break;
        case 'PATCH_COUNTRY':
            apiParams.url = `${accessUrl}countries/${id}`;
            apiParams.method = 'PATCH';
            break;
        case 'POST_COUNTRY':
            apiParams.url = `${accessUrl}countries`;
            apiParams.method = 'POST';
            break;
        case 'DELETE_COUNTRY':
            apiParams.url = `${accessUrl}countries/${id}`;
            apiParams.method = 'DELETE';
            break;
        case 'GET_COUNTRIES_SELECT':
            apiParams.url = `${accessUrl}nodes/countries${options}`;
            break;
        case 'GET_FEATURE_FLAG_LIST_ALL':
            apiParams.url = `${accessUrl}feature_flags/all`;
            break;
        case 'GET_FEATURE_FLAG_RULES':
            apiParams.url = `${accessUrl}feature_flags`;
            break;
        case 'PATCH_FEATURE_FLAGS':
            apiParams.url = `${accessUrl}feature_flags/${id}`;
            apiParams.method = 'PATCH';
            apiParams.okStatus = [HTTP_STATUS.SUCCESS_WITHOUT_BODY_204];
            break;
        case 'GET_OPERATOR':
            apiParams.url = `${accessUrl}operators/${id}`;
            break;
        case 'PATCH_OPERATOR':
            apiParams.url = `${accessUrl}operators/${id}`;
            apiParams.method = 'PATCH';
            break;
        case 'POST_OPERATOR':
            apiParams.url = `${accessUrl}operators`;
            apiParams.method = 'POST';
            break;
        case 'DELETE_OPERATOR':
            apiParams.url = `${accessUrl}operators/${id}`;
            apiParams.method = 'DELETE';
            break;
        case 'GET_OPERATOR_ACTIVITY':
            apiParams.url = `${accessUrl}operators/activities/${id}${options}`;
            break;
        case 'GET_OPERATORS_SELECT':
            let string = '';
            if (params?.mcc?.length) {
                params?.mcc?.forEach((value, index) => {
                    if (!index) {
                        string += `?filter[mcc][]=${value}`;
                    } else {
                        string += `&filter[mcc][]=${value}`;
                    }
                });
            }
            apiParams.url = `${accessUrl}nodes/operators${string}`;
            break;
        case 'GET_OPERATORS_SELECT_ALL':
            apiParams.url = `${accessUrl}nodes/operators?gateways`;
            break;
        case 'GET_OPERATORS_GATEWAYS_SELECT':
            apiParams.url = `${accessUrl}nodes/operators/gateways`;
            break;
        case 'GET_OPERATORS_LIST':
            apiParams.url = `${accessUrl}operators${options}`;
            break;
        case 'GET_OPERATOR_INTERACTIONS_GATEWAYS_SELECT':
            apiParams.url = `${accessUrl}nodes/operators/gateways?full_api=true`;
            break;
        case 'GET_OPERATOR_INTERACTIONS_RESPONSE':
            apiParams.url = `${accessUrl}operator_interactions/responses/${id}`;
            break;
        case 'GET_OPERATOR_INTERACTIONS_RESPONSES':
            apiParams.url = `${accessUrl}operator_interactions/responses${options}`;
            break;
        case 'GET_OPERATOR_INTERACTIONS_RESPONSES_CSV':
            apiParams.url = `${accessUrl}operator_interactions/responses.csv${removePageOptions(options)}`;
            apiParams.isGetFile = true;
            break;
        case 'GET_OPERATOR_INTERACTIONS_FILES':
            apiParams.url = `${accessUrl}operator_interactions/files${options}`;
            break;
        case 'GET_OPERATOR_INTERACTIONS_FILES_SELECT':
            apiParams.url = `${accessUrl}operator_interactions/files?paginate=true`;
            break;
        case 'POST_OPERATOR_INTERACTIONS_FILES':
            apiParams.url = `${accessUrl}operator_interactions/files`;
            apiParams.method = 'POST';
            apiParams.isPostFile = true;
            break;
        case 'GET_OPERATOR_INTERACTIONS_COMMANDS_SELECT':
            apiParams.url = `${accessUrl}nodes/operator_interactions/responses/commands`;
            break;
        case 'GET_NETWORKS_SELECT':
            apiParams.url = `${accessUrl}nodes/networks`;
            break;
        case 'GET_PAYMENT_SELECT':
            apiParams.url = `${accessUrl}nodes/payment_types`;
            break;
        case 'GET_ORDER_PAYMENT_SELECT':
            apiParams.url = `${accessUrl}nodes/orders/payment_types`;
            break;
        case 'GET_ORDER_PRODUCT_SELECT':
            apiParams.url = `${accessUrl}nodes/orders/product_types`;
            break;
        case 'GET_ORDERS_LIST':
            apiParams.url = `${accessUrl}orders${options}`;
            break;
        case 'GET_ORDER':
            apiParams.url = `${accessUrl}orders/${id}`;
            break;
        case 'POST_ORDER':
            apiParams.url = `${accessUrl}companies/${id}/orders`;
            apiParams.method = 'POST';
            break;
        case 'POST_PACKAGE_ORDER':
            apiParams.url = `${accessUrl}packages/${id}/orders`;
            apiParams.method = 'POST';
            break;
        case 'POST_ORDER_CHECKOUT':
            apiParams.url = `${accessUrl}orders/${id}/checkout`;
            apiParams.method = 'POST';
            break;
        case 'GET_UNIT_TYPE_SELECT':
            apiParams.url = `${accessUrl}nodes/call_data_records/unit_types`;
            break;
        case 'GET_ZONE_LIST':
            apiParams.url = `${accessUrl}zones${options}`;
            break;
        case 'GET_ZONE_SELECT':
            apiParams.url = `${accessUrl}nodes/zones`;
            break;
        case 'DELETE_SALES_RATE_LIST':
            apiParams.url = `${accessUrl}sales_rate_lists/${id}`;
            apiParams.method = 'DELETE';
            break;
        case 'GET_SALES_RATE_LIST':
            apiParams.url = `${accessUrl}sales_rate_lists/${id}`;
            break;
        case 'GET_SALES_RATE_CSV':
            apiParams.url = `${accessUrl}sales_rate_lists/${id}.csv`;
            apiParams.isGetFile = true;
            break;
        case 'GET_CARD_SET_CSV':
            apiParams.url = `${accessUrl}card_sets/${id}/subscribers.csv`;
            apiParams.isGetFile = true;
            break;
        case 'POST_SALES_RATE_CSV':
            apiParams.url = `${accessUrl}sales_rate_lists/${id}/import_entries`;
            apiParams.method = 'POST';
            apiParams.isPostFile = true;
            break;
        case 'PATCH_SALES_RATE_LIST':
            apiParams.url = `${accessUrl}sales_rate_lists/${id}`;
            apiParams.method = 'PATCH';
            break;
        case 'POST_SALES_RATE_LIST':
            apiParams.url = `${accessUrl}sales_rate_lists`;
            apiParams.method = 'POST';
            break;
        case 'GET_SALES_RATES_LIST':
            apiParams.url = `${accessUrl}sales_rate_lists${options}`;
            break;
        case 'GET_MY_PURCHASES_LIST':
            apiParams.url = `${accessUrl}sales_rate_lists/purchase_rate_lists`;
            break;
        case 'GET_SALES_RATES_SELECT':
            apiParams.url = `${accessUrl}nodes/sales_rate_lists`;
            break;
        case 'GET_FINANCE_ROLL':
            apiParams.url = `${accessUrl}finance_roll${options}`;
            break;
        case 'GET_REVENUE_CARD_SET_LIST':
            apiParams.url = `${accessUrl}ranks/card_set_revenue${options}`;
            break;
        case 'GET_REVENUE_CARDS_LIST':
            apiParams.url = `${accessUrl}ranks/card_revenue${options}`;
            break;
        case 'GET_REVENUE_COMPANY_LIST':
            apiParams.url = `${accessUrl}ranks/company_revenue${options}`;
            break;
        case 'GET_REVENUE_COUNTRY_LIST':
            apiParams.url = `${accessUrl}ranks/country_revenue${options}`;
            break;
        case 'GET_REVENUE_ZONE_LIST':
            apiParams.url = `${accessUrl}ranks/zone_revenue${options}`;
            break;
        case 'GET_REVENUE_TELECOM_LIST':
            apiParams.url = `${accessUrl}ranks/telecom_revenue${options}`;
            break;
        case 'GET_REVENUE_TIMELINE_LIST':
            apiParams.url = `${accessUrl}ranks/timeline_revenue${options}`;
            break;
        case 'GET_ACTIVITY_ROLL':
            apiParams.url = `${accessUrl}activity_roll${options}`;
            break;
        case 'GET_USAGE_CARD_SETS_LIST':
            apiParams.url = `${accessUrl}ranks/card_set_usage${options}`;
            break;
        case 'GET_USAGE_CARDS_LIST':
            apiParams.url = `${accessUrl}ranks/card_usage${options}`;
            break;
        case 'GET_ACTIVITY_LOGS':
            apiParams.url = `${accessUrl}activity_logs${options}`;
            break;
        case 'GET_USAGE_COUNTRY_LIST':
            apiParams.url = `${accessUrl}ranks/country_usage${options}`;
            break;
        case 'GET_USAGE_COMPANY_LIST':
            apiParams.url = `${accessUrl}ranks/company_usage${options}`;
            break;
        case 'GET_USAGE_TELECOM_LIST':
            apiParams.url = `${accessUrl}ranks/telecom_usage${options}`;
            break;
        case 'GET_USAGE_TIMELINE_LIST':
            apiParams.url = `${accessUrl}ranks/timeline_usage${options}`;
            break;
        case 'GET_USAGE_ZONE_LIST':
            apiParams.url = `${accessUrl}ranks/zone_usage${options}`;
            break;
        case 'GET_SUBSCRIBERS_STATUS':
            options = queryString.stringify({ id: params?.idList }, { arrayFormat: 'bracket' });
            apiParams.url = `${accessUrl}subscribers/refresh?${options}`;
            break;
        case 'POST_SUBSCRIBERS_STATUS':
            apiParams.url = `${accessUrl}subscribers/drive`;
            apiParams.method = 'POST';
            apiParams.isNotLoader = true;
            break;
        case 'GET_PURCHASE_RATES_LIST':
            apiParams.url = `${accessUrl}purchase_rate_lists${options}`;
            break;
        case 'DELETE_PURCHASE_RATE_LIST':
            apiParams.url = `${accessUrl}purchase_rate_lists/${id}`;
            apiParams.method = 'DELETE';
            break;
        case 'GET_PURCHASE_RATE_LIST':
            apiParams.url = `${accessUrl}purchase_rate_lists/${id}`;
            break;
        case 'PATCH_PURCHASE_RATE_LIST':
            apiParams.url = `${accessUrl}purchase_rate_lists/${id}`;
            apiParams.method = 'PATCH';
            break;
        case 'POST_PURCHASE_RATE_LIST':
            apiParams.url = `${accessUrl}purchase_rate_lists`;
            apiParams.method = 'POST';
            break;
        case 'POST_PURCHASE_RATE_CSV':
            apiParams.url = `${accessUrl}purchase_rate_lists/${id}/import_entries`;
            apiParams.method = 'POST';
            apiParams.isPostFile = true;
            break;
        case 'GET_CARD_DEVICE_FILES':
            apiParams.url = `${accessUrl}card_device_files${options}`;
            break;
        case 'POST_CARD_DEVICE_FILES':
            apiParams.url = `${accessUrl}card_device_files`;
            apiParams.method = 'POST';
            apiParams.isPostFile = true;
            break;
        case 'POST_CARD_SET_LIST_CSV':
            apiParams.url = `${accessUrl}card_sets/import`;
            apiParams.method = 'POST';
            apiParams.isPostFile = true;
            break;
        case 'POST_PACKAGE':
            apiParams.url = `${accessUrl}companies/packages`;
            apiParams.method = 'POST';
            break;
        case 'PATCH_PACKAGE':
            apiParams.url = `${accessUrl}companies/packages/${id}`;
            apiParams.method = 'PATCH';
            break;
        case 'DELETE_PACKAGE':
            apiParams.url = `${accessUrl}companies/packages/${id}`;
            apiParams.method = 'DELETE';
            break;
        case 'GET_ONE_PACKAGE':
            apiParams.url = `${accessUrl}companies/packages/${id}`;
            apiParams.method = 'GET';
            break;
        case 'GET_PACKAGES':
            apiParams.url = `${accessUrl}companies/packages${options}`;
            apiParams.method = 'GET';
            break;
        case 'GET_PACKAGE_INSTANCES':
            apiParams.url = `${accessUrl}companies/package_instances${options}`;
            apiParams.method = 'GET';
            break;
        case 'GET_PACKAGES_SOLD':
            apiParams.url = `${accessUrl}companies/sold_package_instances${options}`;
            apiParams.method = 'GET';
            break;
        case 'GET_ONE_SOLD_PACKAGE':
            apiParams.url = `${accessUrl}companies/sold_package_instances/${id}`;
            apiParams.method = 'GET';
            break;
        case 'EDIT_SOLD_PACKAGE':
            apiParams.url = `${accessUrl}companies/sold_package_instances/${id}`;
            apiParams.method = 'PATCH';
            break;
        case 'GET_PACKAGES_TO_BUY':
            apiParams.url = `${accessUrl}companies/packages_to_buy${options}`;
            apiParams.method = 'GET';
            break;
        case 'GET_ONE_PACKAGE_TO_BUY':
            apiParams.url = `${accessUrl}companies/packages_to_buy/${id}`;
            apiParams.method = 'GET';
            break;
        case 'GET_BOUGHT_PACKAGES':
            apiParams.url = `${accessUrl}companies/package_instances${options}`;
            apiParams.method = 'GET';
            break;
        case 'GET_BOUGHT_PACKAGES_SELECT':
            apiParams.url = `${accessUrl}companies/package_instances`;
            apiParams.method = 'GET';
            break;
        case 'GET_ONE_PACKAGE_INSTANCE':
            apiParams.url = `${accessUrl}companies/package_instances/${id}`;
            apiParams.method = 'GET';
            break;
        case 'PATCH_PACKAGE_INSTANCE':
            apiParams.url = `${accessUrl}companies/package_instances/${id}`;
            apiParams.method = 'PATCH';
            break;
        case 'DELETE_PACKAGE_INSTANCE':
            apiParams.url = `${accessUrl}companies/package_instances/${id}`;
            apiParams.method = 'DELETE';
            break;
        case 'GET_PACKAGES_UNITS_LOGS_LIST':
            apiParams.url = `${accessUrl}package_units_logs${options}`;
            apiParams.method = 'GET';
            break;
        case 'PATCH_PROFILE_ALARM':
            apiParams.url = `${accessUrl}companies/${id}/company_config`;
            apiParams.method = 'PATCH';
            break;
        case 'GET_SERVER_NOTIFICATIONS':
            apiParams.url = `${accessUrl}company_notifications${options}`;
            apiParams.method = 'GET';
            apiParams.isNotLoader = true;
            break;
        case 'PATCH_SERVER_NOTIFICATION':
            apiParams.url = `${accessUrl}company_notifications/${id}`;
            apiParams.method = 'PATCH';
            apiParams.isNotLoader = true;
            break;
        case 'GET_GEOTRACKING_CARD_LOCATION_HISTORY':
            apiParams.url = `${accessUrl}cards/${id}/device_locations${options}`;
            apiParams.method = 'GET';
            break;
        case 'GET_GEOTRACKING_CARD_LIST':
            options +=
                (options ? '&' : '?') + queryString.stringify({ scope: params?.scope }, { arrayFormat: 'bracket' });
            apiParams.url = `${accessUrl}nodes/cards${options}`;
            apiParams.method = 'GET';
            break;
        case 'GET_SUBSCRIBERS_LIST_CSV':
            apiParams.url = `${accessUrl}subscribers.csv`;
            apiParams.method = 'GET';
            apiParams.isGetFile = true;
            break;
        case 'GET_USAGE_BIGQUERY_FLAG':
            apiParams.url = `${accessUrl}feature_flags`;
            break;
        case 'CREATE_COMPANY_SUBSCRIPTION':
            apiParams.url = `${accessUrl}companies/${id}/user_subscriptions`;
            apiParams.method = 'POST';
            break;
        case 'DELETE_COMPANY_SUBSCRIPTION':
            apiParams.url = `${accessUrl}companies/${id}/user_subscriptions/${subscriptionId}`;
            apiParams.method = 'DELETE';
            break;
        case 'UPDATE_COMPANY_SUBSCRIPTION':
            apiParams.url = `${accessUrl}companies/${id}/user_subscriptions/${subscriptionId}`;
            apiParams.method = 'PATCH';
            break;
        case 'GET_COMPANY_SUBSCRIPTION':
            apiParams.url = `${accessUrl}companies/${id}/user_subscriptions`;
            break;
        case 'POST_COMPANY_SUBSCRIPTION_ORDER':
            apiParams.url = `${accessUrl}user_subscriptions/${subscriptionId}/orders`;
            apiParams.method = 'POST';
            break;
        case 'POST_ES2_PLUS_CANCEL_ORDER':
            apiParams.url = `${accessUrl}es_plus/notifications/cancel_order`;
            apiParams.method = 'POST';
            break;
        case 'POST_ES2_PLUS_CONFIRM_ORDER':
            apiParams.url = `${accessUrl}es_plus/notifications/confirm_order`;
            apiParams.method = 'POST';
            break;
        case 'POST_ES2_PLUS_DOWNLOAD_ORDER':
            apiParams.url = `${accessUrl}es_plus/notifications/download_order`;
            apiParams.method = 'POST';
            break;
        case 'POST_ES2_PLUS_RELEASE_ORDER':
            apiParams.url = `${accessUrl}es_plus/notifications/release_order`;
            apiParams.method = 'POST';
            break;
        case 'GET_ES_PLUS_NOTIFICATIONS_QR_CODES_LIST':
            apiParams.url = `${accessUrl}es_plus/ipp_qr_codes`;
            apiParams.method = 'GET';
            break;
        case 'GET_ES_PLUS_NOTIFICATIONS_QR_CODES_LIST_CSV':
            apiParams.url = `${accessUrl}es_plus/ipp_qr_codes.csv`;
            apiParams.method = 'GET';
            apiParams.isGetFile = true;
            break;
        case 'POST_SCP_FILE':
            apiParams.url = `${accessUrl}subscriber_identities/import`;
            apiParams.method = 'POST';
            apiParams.isPostFile = true;
            break;
        default:
            generateError(apiType);
            console.error(`Not found apiType - "${apiType}"`);
            break;
    }
    switch (apiParams.method) {
        case 'DELETE':
            if (!apiParams.okStatus.length) {
                apiParams.okStatus = [HTTP_STATUS.DELETE_OK_204, HTTP_STATUS.GET_NOT_FOUND_404];
            }
            break;
        case 'GET':
            if (!apiParams.okStatus.length) {
                apiParams.okStatus = [HTTP_STATUS.GET_OK_200];
            }
            break;
        case 'PATCH':
            if (!apiParams.okStatus.length) {
                apiParams.okStatus = [HTTP_STATUS.PATCH_DATA_OK_200];
            }
            if (!apiParams.errorStatus.length) {
                apiParams.errorStatus = [HTTP_STATUS.PATCH_DATA_ERROR_422];
            }
            apiParams.body = body;
            break;
        case 'POST':
            if (!apiParams.okStatus.length) {
                apiParams.okStatus = [
                    HTTP_STATUS.GET_OK_200,
                    HTTP_STATUS.POST_DATA_OK_201,
                    HTTP_STATUS.POST_DATA_ACCEPTED_202,
                ];
            }
            if (!apiParams.errorStatus.length) {
                apiParams.errorStatus = [HTTP_STATUS.POST_DATA_ERROR_422];
            }
            if (apiType !== 'REFRESH_TOKEN') {
                apiParams.body = body;
            }
            break;
        default:
            break;
    }

    return apiParams;
}
