import { action, makeObservable, observable, reaction } from 'mobx';
import { UiStore } from './ui';
import { appConfig } from '../settings';
import { IFilterParams, ISelectList, IZonesList } from '../interfaces';
import { getZonesList } from '../middleware';
import { apiParamsCleaner } from '../instruments';

export class ZonesStore {
    private constructor() {
        makeObservable(this);
        reaction(
            () => this.forcedUpdateTime,
            () => {
                const UiStoreInstance = UiStore.getInstance();
                const params = UiStoreInstance.params;
                const cleanParams = apiParamsCleaner(params, this.filterParams);
                getZonesList(cleanParams).then();
            },
            {
                delay: appConfig.APIDelay,
            },
        );
    }

    private static instance: ZonesStore;
    public static getInstance(): ZonesStore {
        if (!ZonesStore.instance) {
            ZonesStore.instance = new ZonesStore();
        }
        return ZonesStore.instance;
    }

    @observable zonesSelect: ISelectList = [];
    @action setZonesSelectList(list: ISelectList): void {
        this.zonesSelect = list;
    }
    @observable zonesList: IZonesList = [];
    @action setZonesList(companiesList: IZonesList): void {
        this.zonesList = companiesList;
    }

    @observable forcedUpdateTime = 0;
    @action setForcedUpdateTime(): void {
        this.forcedUpdateTime = Date.now();
    }

    @observable filterParams: IFilterParams = {
        pageNumber: true,
        pageSize: true,
        search: true,
    };

    @action cleanStore(): void {
        this.zonesSelect = [];
        this.zonesList = [];
    }
}
