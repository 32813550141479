import React from 'react';
import { observer } from 'mobx-react';
import { Form, Select } from 'antd';
import { Rule } from 'antd/es/form';
import { Catcher, filterOption } from '../../';
import { ISelect } from '../../../interfaces';
import { useOperatorsNetworksSelect, useUiIsLoading } from '../../../hooks';
import { tF } from '../../../translate';

interface ICompanySelect {
    currentCountryId?: string;
    exceptionNetworkIds?: Array<string | undefined>;
    field?: { fieldKey?: number; key?: number | string; name?: number };
    isShortForm?: boolean;
    name?: string | [number, string];
    rules?: Array<Rule>;
}

export const EditRateNetworkSelect: React.FC<ICompanySelect> = observer(
    ({
        currentCountryId,
        exceptionNetworkIds = [],
        field = { key: 'networkId' },
        isShortForm,
        name = 'networkId',
        rules = [],
    }: ICompanySelect) => {
        const { Option } = Select;
        const isLoading = useUiIsLoading();
        const list = useOperatorsNetworksSelect();
        const optionsJSX = list
            .filter((n) => !currentCountryId || n.countryId === currentCountryId)
            .filter((n) => !exceptionNetworkIds || !exceptionNetworkIds.includes(n.id))
            .map(
                (s: ISelect): JSX.Element => (
                    <Option key={s.id} value={s.id}>
                        <div>{s.name}</div>
                    </Option>
                ),
            );

        const nameNetwork = tF('Network');

        return (
            <Catcher>
                <Form.Item {...field} name={name} rules={rules}>
                    <Select
                        allowClear
                        filterOption={filterOption}
                        getPopupContainer={(trigger): HTMLElement =>
                            isShortForm ? document.body : trigger.parentElement
                        }
                        loading={isLoading}
                        placeholder={nameNetwork}
                        showSearch
                    >
                        {optionsJSX}
                    </Select>
                </Form.Item>
            </Catcher>
        );
    },
);
