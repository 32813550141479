import React from 'react';
import { observer } from 'mobx-react';
import { Col, Row } from 'antd';
import {
    Catcher,
    DarkThemeSwitch,
    FullscreenButton,
    ImpersonateTopBar,
    MainSearch,
    MobileMenu,
    ProfileMenu,
    ServerNotification,
} from '../';
import logoWhite from '../../static/img/Logo geniox_black.png';
import logoBlack from '../../static/img/Logo geniox_white.png';
import { SupportButton } from '../SupportPage';
import cx from 'classnames';
import { appConfig } from '../../settings';
import { apiConfig } from '../../REST';
import { useIsImpersonating, useProfile, useProfileCustomLogo, useUiDarkTheme, useUiLanguage } from '../../hooks';
import LogoStyles from '../styles/m_logo.less';
import Styles from './m_styles.less';

export const MainHeader: React.FC = observer(() => {
    const isDark = useUiDarkTheme();
    const isImpersonating = useIsImpersonating();
    const { customLogoLinkUrl } = useProfile();
    const customLogo = useProfileCustomLogo(false);
    const customLogoDark = useProfileCustomLogo(true);
    const apiUrl = apiConfig.apiUrl();
    const logo = customLogo ? customLogo : customLogoDark ? customLogoDark : '';
    const logoDark = customLogoDark ? customLogoDark : customLogo ? customLogo : '';
    const customLogoUrl = !!logo ? `${apiUrl}${logo}` : logoBlack;
    const customLogoUrlDark = !!logoDark ? `${apiUrl}${logoDark}` : logoWhite;
    const languageId = useUiLanguage();

    return (
        <Catcher>
            <header className={Styles.main}>
                <div className={Styles.left}>
                    <a
                        href={customLogoLinkUrl ? `https://${customLogoLinkUrl}/` : appConfig.defaultWebsite}
                        className={LogoStyles.logoPlace}
                    >
                        <div className={LogoStyles.logoPlace}>
                            <img
                                alt={appConfig.defaultWebsite}
                                className={cx(LogoStyles.logoImg, Styles.logo)}
                                src={isDark ? customLogoUrlDark : customLogoUrl}
                            />
                        </div>
                    </a>
                </div>
                <div className={Styles.center}>
                    <MainSearch />
                </div>
                <div className={Styles.right}>
                    <SupportButton />
                    <ServerNotification />
                    <Row>
                        <Col xs={24} sm={0}>
                            <MobileMenu />
                        </Col>
                        <Col xs={0} sm={24}>
                            <ProfileMenu />
                        </Col>
                    </Row>
                    {isImpersonating ? <ImpersonateTopBar /> : null}
                    <DarkThemeSwitch />
                    <FullscreenButton className={cx(Styles.fullscreenButton)} />
                </div>
            </header>
        </Catcher>
    );
});
