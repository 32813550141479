import React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import { TablePaginationConfig } from 'antd/es/table';
import { GroupOutlined } from '@ant-design/icons';
import { appConfig } from '../../../settings';
import { IColumnOptionComponent, ICompanyCardSetsItem } from '../../../interfaces';
import { useCompanyCardSetsList, useUiIsLoading } from '../../../hooks';
import { formatDataTestAttribute } from '../../../instruments/testing';
import { tF } from '../../../translate';
import { CardLayout, Catcher, LinkComponent, SkyPaginationTotal } from '../../../Components';

export const ShowCompanyCardSetsTable: React.FC<IColumnOptionComponent> = observer(
    ({ columnOption }: IColumnOptionComponent) => {
        const pageSize = appConfig.pageDefaultParams.includeViewPageSize;
        const isLoading = useUiIsLoading();
        const list = useCompanyCardSetsList();
        const tableColumns = [
            {
                title: (
                    <span data-test={formatDataTestAttribute('ShowCompanyCardSetsTable', 'CardSetLink', `Header`)}>
                        {tF('Card Sets')}
                    </span>
                ),
                dataIndex: 'cardSetLink',
            },
            {
                title: (
                    <span
                        data-test={formatDataTestAttribute('ShowCompanyCardSetsTable', 'AssignedCompanyLink', `Header`)}
                    >
                        {tF('Assigned to')}
                    </span>
                ),
                dataIndex: 'assignedCompanyLink',
            },
            {
                title: (
                    <span data-test={formatDataTestAttribute('ShowCompanyCardSetsTable', 'CardsCount', `Header`)}>
                        {tF('Amount of cards')}
                    </span>
                ),
                dataIndex: 'cardsCount',
            },
        ];

        const tableData = list.map((s: ICompanyCardSetsItem, index) => ({
            key: s.id,
            cardSetLink: (
                <span data-test={formatDataTestAttribute('ShowCompanyCardSetsTable', 'CardSetLink', `${index}`)}>
                    <LinkComponent currentListComponent={'CardSetsList'} id={s.id} name={s.name} />
                </span>
            ),
            assignedCompanyLink: (
                <span
                    data-test={formatDataTestAttribute('ShowCompanyCardSetsTable', 'AssignedCompanyLink', `${index}`)}
                >
                    <LinkComponent
                        currentListComponent={'CompaniesList'}
                        id={s.assignedCompanyId}
                        name={s.assignedCompany}
                    />
                </span>
            ),
            cardsCount: (
                <span data-test={formatDataTestAttribute('ShowCompanyCardSetsTable', 'CardsCount', `${index}`)}>
                    {s.cardsCount}
                </span>
            ),
        }));

        const pagination: false | TablePaginationConfig =
            pageSize > list.length
                ? false
                : {
                      total: list.length,
                      pageSize: pageSize,
                      className: 'customPagination',
                      showSizeChanger: false,
                      size: 'default',
                      showTotal: SkyPaginationTotal,
                  };

        return (
            <Catcher>
                <CardLayout
                    columnOption={columnOption}
                    count={list.length}
                    icon={<GroupOutlined />}
                    idSpinners={['GET_COMPANY']}
                    isTable
                    title={'Card Sets'}
                    dataTest={'ShowCompanyCardSetsTable'}
                >
                    <Table
                        className={'table-theme'}
                        columns={tableColumns}
                        data-test={'ShowCompanyCardSetsTable'}
                        dataSource={tableData}
                        loading={isLoading}
                        pagination={pagination}
                        size={'small'}
                    />
                </CardLayout>
            </Catcher>
        );
    },
);
