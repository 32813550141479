import React from 'react';
import { observer } from 'mobx-react';
import { Collapse, Form, Select } from 'antd';
import { Rule } from 'antd/es/form';
import { CardLayout, cardLayoutOptions } from '../../Layout';
import { CardInfoToolBar } from '../../SubComponents';
import { IBodyItemResponse, IEsNotificationsCancelOrderBody, ISelect } from '../../../interfaces';
import { useUiIsSpinnerFound } from '../../../hooks';
import { addNotification, apiMessagesGetFormRules, postEsNotificationsCancelOrder } from '../../../middleware';
import { Catcher } from '../../Catcher';
import { InputDebounce } from '../../Forms';

const { Option } = Select;
const { Panel } = Collapse;

export const Es2CancelOrderForm: React.FC = observer(() => {
    const [form] = Form.useForm();
    const isLoading = useUiIsSpinnerFound(['POST_ES2_PLUS_CANCEL_ORDER']);

    const ruleRequired: Array<Rule> = [{ required: true, message: 'Required' }, ...apiMessagesGetFormRules];

    const finalProfileStatusIdOptionsJSX = [
        { id: 'Unavailable', name: 'Unavailable' },
        { id: 'Available', name: 'Available' },
    ].map(
        (s: ISelect): JSX.Element => (
            <Option key={s.id} value={s.id}>
                <div>{s.name}</div>
            </Option>
        ),
    );

    const onFinish = async (fields: any): Promise<void> => {
        const body = {
            header: {
                functionCallIdentifier: fields.functionCallIdentifier,
                functionRequesterIdentifier: fields.functionRequesterIdentifier,
            },
            iccid: fields.iccid,
            eid: fields.eid,
            matchingId: fields.matchingId,
            finalProfileStatusIndicator: fields.finalProfileStatusIndicator,
        } as IEsNotificationsCancelOrderBody;

        const { isError, resp } = await postEsNotificationsCancelOrder(body);

        const { header, ReasonCode } = resp as IBodyItemResponse;

        if (isError) {
            addNotification({
                type: 'error',
                message: 'Error!',
                description: `Something went wrong`,
                duration: 5,
            });

            return;
        }

        if (ReasonCode) {
            addNotification({
                type: 'error',
                message: 'Error!',
                description: JSON.stringify(header),
                duration: 10,
            });
        } else {
            addNotification({
                type: 'success',
                message: 'Canceled',
                description: `Your order has been canceled successfully`,
                duration: 5,
            });
        }
    };

    return (
        <Catcher>
            <CardLayout
                title={'Cancel Order'}
                idSpinners={['POST_ES2_PLUS_CANCEL_ORDER']}
                columnOption={cardLayoutOptions.cardEditCompany}
            >
                <Form
                    form={form}
                    name="cancel_order"
                    initialValues={{
                        functionCallIdentifier: 'TX-567',
                        functionRequesterIdentifier: '2',
                        finalProfileStatusIndicator: 'Available',
                    }}
                    onFinish={onFinish}
                    scrollToFirstError={true}
                    layout={'vertical'}
                    className={'formStyle'}
                >
                    <Form.Item name="eid" label={'eid'}>
                        <InputDebounce autoComplete={'off'} placeholder={'eid'} isFirstInput />
                    </Form.Item>
                    <Form.Item name="finalProfileStatusIndicator" label={'finalProfileStatusIndicator'}>
                        <Select placeholder={'finalProfileStatusIndicator'}>{finalProfileStatusIdOptionsJSX}</Select>
                    </Form.Item>
                    <Form.Item name="iccid" label={'iccid'} rules={ruleRequired}>
                        <InputDebounce autoComplete={'off'} placeholder={'iccid'} />
                    </Form.Item>
                    <Form.Item name="matchingId" label={'matchingId'}>
                        <InputDebounce autoComplete={'off'} placeholder={'matchingId'} />
                    </Form.Item>
                    <Collapse style={{ marginTop: '10px', marginBottom: '10px' }} bordered={false}>
                        <Panel key={'1'} header={'Headers'}>
                            <Form.Item name="functionCallIdentifier" label={'functionCallIdentifier'}>
                                <InputDebounce autoComplete={'off'} placeholder={'functionCallIdentifier'} />
                            </Form.Item>
                            <Form.Item name="functionRequesterIdentifier" label={'functionRequesterIdentifier'}>
                                <InputDebounce autoComplete={'off'} placeholder={'functionRequesterIdentifier'} />
                            </Form.Item>
                        </Panel>
                    </Collapse>
                    <CardInfoToolBar
                        isLoading={isLoading}
                        isHideCancel={true}
                        isSubmitButton={true}
                        labelSave={'Cancel'}
                    />
                </Form>
            </CardLayout>
        </Catcher>
    );
});
